import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

const Toggle = ({
  color,
  size,
  onClick,
  withIcons,
  withLabel,
  label,
  isToggled,
  btnRef,
  toggleUsingData
}) => {
  const [toggledOn, setToggledOn] = useState(isToggled);

  function handleClick(e) {
    if (e.code && e.code !== 'Enter') {
      return;
    }
    if (!toggleUsingData) {
      setToggledOn(!toggledOn);
    }
    onClick(btnRef, !isToggled);
  }

  function toggleStyle(cls1, cls2) {
    if (toggleUsingData && isToggled) {
      return cls1;
    }
    if (toggleUsingData && !isToggled) {
      return cls2;
    }
    if (toggledOn) {
      return cls1;
    }

    return cls2;
  }

  return (
    <div className="tw-mt-5 sm:tw-mt-0 sm:tw-flex-shrink-0 sm:tw-flex sm:tw-items-center">
      {/* On: `bg-${color}-600`, Off: "bg-gray-200" */}
      <span
        role="checkbox"
        tabIndex="0"
        aria-checked={toggleUsingData ? isToggled : toggledOn}
        onClick={handleClick}
        onKeyDown={handleClick}
        className={`${
          size === 'small'
            ? 'tw-group tw-relative tw-inline-flex tw-items-center tw-justify-center tw-flex-shrink-0 tw-h-5 tw-w-10 tw-cursor-pointer focus:tw-outline-none'
            : `tw-relative tw-inline-block tw-flex-no-shrink tw-h-6 tw-w-11 tw-border-2 tw-border-solid tw-border-transparent tw-rounded-full tw-cursor-pointer tw-transition-colors tw-ease-in-out tw-duration-200 focus:tw-outline-none focus:tw-shadow-outline ${toggleStyle(
                `tw-bg-${color}-600`,
                'tw-bg-gray-200'
              )}`
        }`}
      >
        {size === 'small' ? (
          // On: `bg-${color}-600`, Off: "bg-gray-200"
          <span
            aria-hidden="true"
            className={`tw-absolute tw-h-4 tw-w-9 tw-mx-auto tw-rounded-full tw-transition-colors tw-ease-in-out tw-duration-200 ${toggleStyle(
              `tw-bg-${color}-600`,
              'tw-bg-gray-200'
            )}`}
          />
        ) : null}
        {/* On: "translate-x-5", Off: "translate-x-0" */}
        <span
          aria-hidden="true"
          className={`${
            size === 'small'
              ? `tw-absolute tw-left-0 tw-inline-block tw-h-5 tw-w-5 tw-border tw-border-gray-200 tw-rounded-full tw-bg-white tw-shadow tw-transform group-focus:tw-shadow-outline group-focus:tw-border-blue-300 tw-transition-transform tw-ease-in-out tw-duration-200 ${toggleStyle(
                  'tw-translate-x-5',
                  'tw-translate-x-0'
                )}`
              : `tw-inline-block tw-h-5 tw-w-5 tw-rounded-full tw-bg-white tw-shadow tw-transform tw-transition tw-ease-in-out tw-duration-200 ${toggleStyle(
                  'tw-translate-x-5',
                  'tw-translate-x-0'
                )}`
          }`}
        >
          {withIcons ? (
            <Fragment>
              {/* On: "opacity-0 ease-out duration-100", Off: "opacity-100 ease-in duration-200" */}
              <span
                className={`tw-absolute tw-inset-0 tw-h-full tw-w-full tw-flex tw-items-center tw-justify-center tw-transition-opacity ${toggleStyle(
                  'tw-opacity-0 tw-ease-out tw-duration-100',
                  'tw-opacity-100 tw-ease-in tw-duration-200'
                )}`}
              >
                <svg
                  className="tw-h-3 tw-w-3 tw-text-gray-400"
                  fill="none"
                  viewBox="0 0 12 12"
                >
                  <path
                    d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
              {/* On: "opacity-100 ease-in duration-200", Off: "opacity-0 ease-out duration-100" */}
              <span
                className={`tw-absolute tw-inset-0 tw-h-full tw-w-full tw-flex tw-items-center tw-justify-center tw-transition-opacity ${toggleStyle(
                  'tw-opacity-100 tw-ease-in tw-duration-200',
                  'tw-opacity-0 tw-ease-out tw-duration-100'
                )}`}
              >
                <svg
                  className={`tw-h-3 tw-w-3 tw-text-${color}-600`}
                  fill="currentColor"
                  viewBox="0 0 12 12"
                >
                  <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                </svg>
              </span>
            </Fragment>
          ) : null}
        </span>
      </span>
      {withLabel ? (
        <label className="ml-3 mb-0">
          <span className="block text-sm leading-5 font-small text-gray-700">
            {label}
          </span>
        </label>
      ) : null}
    </div>
  );
};

Toggle.defaultProps = {
  color: 'alpha',
  size: 'large',
  withIcons: false,
  withLabel: false,
  label: '',
  isToggled: false,
  toggleUsingData: true,
  btnRef: null
};

Toggle.propTypes = {
  onClick: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['small', 'large']),
  withIcons: PropTypes.bool,
  withLabel: PropTypes.bool,
  label: PropTypes.string,
  color: PropTypes.oneOf([
    'alpha',
    'bravo',
    'charlie',
    'success',
    'warning',
    'error',
    'gray'
  ]),
  isToggled: PropTypes.bool,
  toggleUsingData: PropTypes.bool,
  btnRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      row: PropTypes.instanceOf(Element),
      parentIndex: PropTypes.number
    })
  ])
};

export default Toggle;
