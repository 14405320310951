import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { CurrentAccountContext } from 'components/shared/context/CurrentAccountContext';
import {
  createLoadedSelector,
  createErrorMessageSelector
} from 'appState/selectors';
import {
  appointmentTypeCreateSchema,
  GroupAppointmentTypeCreateSchema
} from 'lib/validation/schema';
import {
  appointmentTypeCreate,
  flashErrorMessage,
  flashSuccessMessage
} from 'appState/actions/ActionCreators';
import Button from 'components/Theme/Button';
import Card from 'components/Theme/Card';
import PageHeader from 'components/Theme/PageHeader';
import { CREATE_APPOINTMENT_TYPE } from 'appState/actions/constants/appointmentType.actions';
import isEmpty from 'lodash.isempty';
import AppointmentTypeForm from './_form';

const NewAppointmentType = ({ isNestedForm, onCancelAction }) => {
  const currentAccount = useContext(CurrentAccountContext);
  const dispatch = useDispatch();
  const createApptTypeLoadedSelector = createLoadedSelector([
    CREATE_APPOINTMENT_TYPE
  ]);
  const createApptTypeErrorSelector = createErrorMessageSelector([
    CREATE_APPOINTMENT_TYPE
  ]);
  const structuredSelector = createStructuredSelector({
    createApptTypeLoaded: state => createApptTypeLoadedSelector(state),
    createApptTypeError: state => createApptTypeErrorSelector(state)
  });

  const { createApptTypeLoaded, createApptTypeError } = useSelector(
    structuredSelector
  );

  const createNewAppointmentType = (values, userArray) => {
    dispatch(
      appointmentTypeCreate({ params: values, permittedStaffIds: userArray })
    );
  };

  const handleCancelAction = () => {
    if (onCancelAction) {
      return onCancelAction();
    }

    // replace this with the new URL
    window.location = '/ui/appointment-types';
  };

  const appointmentFormik = useFormik({
    initialValues: {
      name: '',
      permittedStaffIds: [],
      allowSchedulling: '',
      duration: '',
      padding: '',
      notice: '',
      futureBookingRestrictionDays: '',
      url: '',
      contactFormType: '',
      hasBookingRedirect: false,
      hasFutureBookingRestricted: false,
      hasBookingForm: false,
      maxAttendees: ''
    },
    enableReinitialize: true,
    validationSchema: currentAccount.group_scheduling_enabled
      ? GroupAppointmentTypeCreateSchema
      : appointmentTypeCreateSchema,
    onSubmit: values => {
      createNewAppointmentType(values);
    }
  });

  useEffect(() => {
    if (createApptTypeLoaded) {
      if (isEmpty(createApptTypeError)) {
        dispatch(flashSuccessMessage('Appointment Type Created.'));
        if(!isNestedForm) {
          window.location = '/ui/appointment-types';
        }
      } else {
        dispatch(flashErrorMessage(createApptTypeError));
      }
    }
  }, [createApptTypeLoaded, createApptTypeError]);

  return (
    <div>
      {!isNestedForm && (
        <PageHeader
          title="New Appointment Type"
          breadcrumbs={[
            {
              name: 'Settings',
              action: () => {
                window.location = '/account/account_settings';
              }
            },
            {
              name: 'Appointment Types',
              action: () => {
                window.location = '/ui/appointment-types';
              }
            },
            {
              name: 'New Appointment Type',
              action: () => {}
            }
          ]}
          backgroundColor="white"
        />
      )}
      <Card
        containerClass={
          isNestedForm
            ? 'tw-w-full'
            : 'tw-rounded-lg tw-bg-white tw-m-8 tw-mb-20'
        }
        customMarginContainer
      >
        <AppointmentTypeForm formik={appointmentFormik} />
        <footer
          className="card-footer tw-mt-4"
          style={{ borderRadius: '0 0 10px 10px' }}
        >
          <div className="tw-inline-block tw-w-1/2">
            <span className="tw-mt-3 tw-w-full sm:tw-mt-0 sm:tw-w-auto">
              <Button
                text="Cancel"
                testId="cancel"
                type="white"
                size="lg"
                onClick={handleCancelAction}
              />
            </span>
          </div>
          <div className="tw-inline-block tw-w-1/2 tw-text-right">
            <span className="tw-w-full sm:tw-ml-3 sm:tw-w-auto">
              <Button
                text={`Save${isNestedForm ? ' Appointment Type' : ''}`}
                size="lg"
                onClick={appointmentFormik.handleSubmit}
              />
            </span>
          </div>
        </footer>
      </Card>
    </div>
  );
};

NewAppointmentType.defaultProps = {
  isNestedForm: false,
  onCancelAction: null
};

NewAppointmentType.propTypes = {
  isNestedForm: PropTypes.bool,
  onCancelAction: PropTypes.func
};

export default NewAppointmentType;
