// linting rules disabled for immer js
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import sortedProducts from 'lib/utils/sortedProducts';
import {
  MARKET_PLACE_LIST_FETCH_SUCCESS,
  PRODUCT_FETCH_SUCCESS,
  CLEAR_CURRENT_PRODUCT_FROM_STATE
} from 'app-state/actions/constants/marketPlace.actions';

const initialState = {
  productList: [],
  featuredProductList: [],
  currentProduct: {},
  categoryProductList: [],
  currentAppointmentType: []
};

export default function MarketPlaceReducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case MARKET_PLACE_LIST_FETCH_SUCCESS: {
        const { productList, featuredProductList } = action.payload;
        draft.productList = sortedProducts(productList);
        draft.featuredProductList = featuredProductList;
        return;
      }
      case PRODUCT_FETCH_SUCCESS: {
        const { currentProduct, categoryProductList } = action.payload;
        draft.currentProduct = currentProduct;
        draft.categoryProductList = categoryProductList;
        return;
      }
      case CLEAR_CURRENT_PRODUCT_FROM_STATE: {
        draft.currentProduct = {};
        return;
      }
      default:
        return draft;
    }
  });
}
