import { Controller } from 'stimulus';
import { FETCH_DEFAULT_HEADERS } from 'controllers/constants';

export default class extends Controller {
  static targets = ['notifications', 'pagination'];

  initialize() {
    const options = {
      rootMargin: '20px'
    };

    this.intersectionObserver = new IntersectionObserver(
      notifications => this.processIntersectionEntries(notifications),
      options
    );
  }

  connect() {
    this.intersectionObserver.observe(this.paginationTarget);
  }

  disconnect() {
    this.intersectionObserver.unobserve(this.paginationTarget);
  }

  processIntersectionEntries(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.loadMore();
      }
    });
  }

  loadMore() {
    const { lastPage, totalPages, nextUrl } = this.paginationTarget.dataset;

    if (
      nextUrl === null ||
      lastPage === 'true' ||
      this.loading ||
      totalPages === '0'
    ) {
      return;
    }
    this.loading = true;

    fetch(nextUrl, {
      method: 'GET',
      headers: FETCH_DEFAULT_HEADERS,
      credentials: 'same-origin'
    })
      .then(response => response.json())
      .then(json => {
        this.notificationsTarget.insertAdjacentHTML(
          'beforeend',
          json.notifications
        );
        this.paginationTarget.dataset.nextUrl = json.next_page_url;
        this.paginationTarget.dataset.lastPage = json.last_page;
        this.paginationTarget.dataset.totalPages = json.total_pages;
        this.loading = false;
      })
      .catch(function(error) {
        console.error(error);
      });
  }
}
