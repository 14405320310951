import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';
import { createLoadedSelector } from 'appState/selectors';
import {
  fetchPinnedFormsActivities,
  fetchFormsActivities,
  formsActivitiesPageChange
} from 'appState/actions/ActionCreators';
import { FETCH_FORMS_ACTIVITIES } from 'appState/actions/constants/activity.actions';
import Notification from 'components/Theme/Notification';
import NotificationIcon from 'components/Theme/Icon/NotificationIcon';
import ContactFormCreate from 'components/ContactForms/Create';
import PinnedActivities from 'components/Contact/Profile/PinnedActivities';
import RecentActivities from 'components/Contact/Profile/RecentActivities';
import { CurrentAccountContext } from 'components/shared/context/CurrentAccountContext';

const FormsTab = ({ contactId }) => {
  const dispatch = useDispatch();
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const loadedSelector = createLoadedSelector([FETCH_FORMS_ACTIVITIES]);

  const structuredSelector = createStructuredSelector({
    currentContact: state => state.contact.currentContact,
    pinnedFormsActivities: state => state.activity.pinnedFormsActivities,
    formsActivities: state => state.activity.formsActivities,
    formsTotal: state => state.activity.formsTotal,
    formsTotalPages: state => state.activity.formsTotalPages,
    limit: state => state.activity.limit,
    isLoaded: state => loadedSelector(state)
  });

  const {
    currentContact,
    pinnedFormsActivities,
    formsActivities,
    formsTotal,
    formsTotalPages,
    limit,
    isLoaded
  } = useSelector(structuredSelector);

  const currentAccount = useContext(CurrentAccountContext);

  useEffect(() => {
    if (isEmpty(pinnedFormsActivities) && !isEmpty(currentContact))
      dispatch(
        fetchPinnedFormsActivities({
          contactId: currentContact.id
        })
      );
  }, [dispatch, currentContact]);

  useEffect(() => {
    if (isEmpty(formsActivities) && !isEmpty(currentContact))
      dispatch(
        fetchFormsActivities({
          contactId: currentContact.id
        })
      );
  }, [dispatch, currentContact]);

  return (
    <div className="tw-py-5">
      <div className="tw-grid tw-grid-cols-12 tw-gap-6">
        <div className="tw-col-span-12">
          <ContactFormCreate
            contactId={contactId}
            setShowSuccess={setShowSuccess}
            setShowError={setShowError}
          />
          <div className="tw-border-t tw-border-b-0 tw-border-l-0 tw-border-r-0 tw-border-solid tw-border-gray-200 tw-col-span-12" />
        </div>
        <div className="tw-col-span-12 tw-px-4">
          <PinnedActivities
            tabName="forms"
            activities={pinnedFormsActivities}
          />
          <RecentActivities
            activities={formsActivities}
            total={formsTotal}
            totalPages={formsTotalPages}
            limit={limit}
            currentContact={currentContact}
            currentAccount={currentAccount}
            isLoaded={isLoaded}
            resetAction={FETCH_FORMS_ACTIVITIES}
            pageChangeAction={formsActivitiesPageChange}
          />
        </div>
      </div>
      <Notification
        message="Form not created"
        show={showError}
        setShowAction={setShowError}
        type="colored"
        headerText="Error!"
        color="error"
        HeaderIcon={color => {
          return (
            <NotificationIcon
              color={color}
              tint="400"
              width="5"
              height="5"
              filled
            />
          );
        }}
      />
      <Notification
        message="Form created"
        show={showSuccess}
        setShowAction={setShowSuccess}
        type="colored"
        headerText="Success!"
        color="success"
        HeaderIcon={color => {
          return (
            <NotificationIcon color={color} tint="400" width="5" height="5" />
          );
        }}
      />
    </div>
  );
};

FormsTab.propTypes = {
  contactId: PropTypes.string.isRequired
};

export default FormsTab;
