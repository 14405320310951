import React, { useContext, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash.isempty';
import { formikShape } from 'lib/utils/prop-types-extensions';
import { capitalize } from 'lib/utils/string';
import { CurrentAccountContext } from 'components/shared/context/CurrentAccountContext';
import { UsersContext } from 'components/shared/context/UsersContext';
import { fetchContactFormTypes } from 'appState/actions/ActionCreators';
import MultiSelect from 'components/Theme/MultiSelect';
import Select from 'components/Theme/Select';
import TextInput from 'components/Theme/TextInput';
import groupBy from 'lodash.groupby';

const setupTimeOptions = [
  {
    displayName: '15 minutes',
    value: '15_min'
  },
  {
    displayName: '30 minutes',
    value: '30_min'
  },
  {
    displayName: '45 minutes',
    value: '45_min'
  },
  {
    displayName: '60 minutes',
    value: '60_min'
  }
];

const paddingTimeOptions = [
  { displayName: 'None', value: '0_min' },
  ...setupTimeOptions
];

const setNoticeOptions = [
  {
    displayName: 'None',
    value: '0_hrs'
  },
  {
    displayName: '2 Hours',
    value: '2_hrs'
  },
  {
    displayName: '6 Hours',
    value: '6_hrs'
  },
  {
    displayName: '12 Hours',
    value: '12_hrs'
  },
  {
    displayName: '24 Hours',
    value: '24_hrs'
  },
  {
    displayName: '48 Hours',
    value: '48_hrs'
  }
];

const AppointmentTypeForm = ({ type, formik }) => {
  const { users } = useContext(UsersContext);
  const currentAccount = useContext(CurrentAccountContext);
  const dispatch = useDispatch();

  const userSelectOptions = useMemo(() => {
    return users.map(user => ({
      displayName: user.full_name,
      value: user.id.toString()
    }));
  }, [users]);

  const structuredSelector = createStructuredSelector({
    contactFormTypes: state => state.contactFormType.contactFormTypes
  });

  const { contactFormTypes } = useSelector(structuredSelector);

  const contactFormTypeOptions = useMemo(() => {
    const options = contactFormTypes.map(formType => {
      return {
        displayName: formType.name,
        value: formType.id,
        label: formType.product_name
      };
    });

    const groupOptions = groupBy(options, 'label');
    return Object.keys(groupOptions).map(label => {
      return {
        label,
        options: groupOptions[label]
      };
    });
  }, [contactFormTypes]);

  const METHOD_NAME = type === 'new' ? 'POST' : 'PUT';

  useEffect(() => {
    if (isEmpty(contactFormTypes))
      dispatch(fetchContactFormTypes({ accountId: currentAccount.id }));
  }, [dispatch, currentAccount, contactFormTypes]);

  const handleFromUserIdsChange = event => {
    formik.setFieldValue('permittedStaffIds', event);
  };

  return (
    <form action="#" method={METHOD_NAME}>
      <div className="tw-mx-auto sm:tw-p-6 lg:tw-px-8">
        <h3 className="tw-font-medium tw-leading-5 tw-text-gray-700">
          {capitalize(type)} Appointment Type
        </h3>
        <div className="tw-mt-4 tw-mx-2">
          <TextInput
            id="name"
            value={formik.values.name}
            labelText="Name"
            onChange={formik.handleChange}
            required
            onBlur={formik.handleBlur}
            showError={formik.touched.name && !!formik.errors.name}
            error={formik.errors.name}
            widthClass="lg:tw-w-1/3 sm:tw-w-1/2 xxs:tw-w-full"
          />
        </div>
        <div className="tw-w-1/3 tw-mt-4 tw-mx-2">
          <MultiSelect
            id="permittedStaffIds"
            value={formik.values.permittedStaffIds}
            options={userSelectOptions}
            labelText="Permitted Staff Members"
            onChange={handleFromUserIdsChange}
            onBlur={formik.handleBlur}
            required
            showError={
              formik.touched.permittedStaffIds &&
              !!formik.errors.permittedStaffIds
            }
            error={formik.errors.permittedStaffIds}
          />
        </div>
        <div className="tw-mt-8 tw-mx-2">
          <Select
            id="duration"
            value={formik.values.duration}
            options={setupTimeOptions}
            labelText="Duration"
            required
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            showError={formik.touched.duration && !!formik.errors.duration}
            error={formik.errors.duration}
            widthClass="lg:tw-w-1/4 sm:tw-w-1/2 xxs:tw-w-full"
          />
        </div>
        <div className="tw-mt-8 tw-mx-2">
          <Select
            id="padding"
            value={formik.values.padding}
            options={paddingTimeOptions}
            labelText="Padding Between Appointments"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            showError={formik.touched.padding && !!formik.errors.padding}
            error={formik.errors.padding}
            widthClass="lg:tw-w-1/4 sm:tw-w-1/2 xxs:tw-w-full"
            required
          />
        </div>
        {currentAccount.group_scheduling_enabled && (
          <div className="tw-mt-8 tw-mx-2">
            <TextInput
              id="maxAttendees"
              value={formik.values.maxAttendees}
              labelText="Max number of attendees"
              onChange={formik.handleChange}
              required
              onBlur={formik.handleBlur}
              showError={
                formik.touched.maxAttendees && !!formik.errors.maxAttendees
              }
              error={formik.errors.maxAttendees}
              widthClass="lg:tw-w-1/3 sm:tw-w-1/2 xxs:tw-w-full"
            />
          </div>
        )}
        <div className="tw-mt-8 tw-mx-2">
          <Select
            id="notice"
            value={formik.values.notice}
            options={setNoticeOptions}
            labelText="Advanced Notice"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            showError={formik.touched.notice && !!formik.errors.notice}
            error={formik.errors.notice}
            widthClass="lg:tw-w-1/4 sm:tw-w-1/2 xxs:tw-w-full"
            required
          />
        </div>
        <div className="tw-mt-8 tw-mx-2">
          <input
            id="allowSchedulling"
            name="allowSchedulling"
            type="checkbox"
            className="tw-mr-4"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            checked={formik.values.allowSchedulling}
            value
          />
          <label
            htmlFor="allowSchedulling"
            onClick={formik.handleChange}
            className="tw-font-body tw-block-inline tw-text-sm tw-font-medium tw-leading-5 tw-text-gray-700 tw-cursor-pointer"
          >
            Allow your contacts to self-schedule this appointment type?
          </label>
        </div>
        <div className="tw-mt-8 tw-mx-2">
          <input
            id="hasFutureBookingRestricted"
            name="hasFutureBookingRestricted"
            type="checkbox"
            className="tw-mr-4"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            checked={formik.values.hasFutureBookingRestricted}
            value
          />
          <label
            htmlFor="hasFutureBookingRestricted"
            onClick={formik.handleChange}
            className="tw-font-body tw-block-inline tw-text-sm tw-font-medium tw-leading-5 tw-text-gray-700 tw-cursor-pointer"
          >
            Restrict Future Booking Date
          </label>
          {formik.values.hasFutureBookingRestricted && (
            <TextInput
              id="futureBookingRestrictionDays"
              value={formik.values.futureBookingRestrictionDays}
              labelText="Number of days in the future to allow self-scheduling:"
              onChange={formik.handleChange}
              required
              onBlur={formik.handleBlur}
              showError={
                formik.touched.futureBookingRestrictionDays &&
                !!formik.errors.futureBookingRestrictionDays
              }
              error={formik.errors.futureBookingRestrictionDays}
              widthClass="tw-mt-4 lg:tw-w-1/4 sm:tw-w-1/2 xxs:tw-w-full"
            />
          )}
        </div>
        <div className="tw-mt-8 tw-mx-2">
          <input
            id="hasBookingRedirect"
            name="hasBookingRedirect"
            type="checkbox"
            className="tw-mr-4"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            checked={formik.values.hasBookingRedirect}
            value
          />
          <label
            htmlFor="hasBookingRedirect"
            onClick={formik.handleChange}
            className="tw-font-body tw-block-inline tw-text-sm tw-font-medium tw-leading-5 tw-text-gray-700 tw-cursor-pointer"
          >
            Redirect to custom page after successful booking
          </label>
          {formik.values.hasBookingRedirect && (
            <TextInput
              id="url"
              value={formik.values.url}
              labelText="URL Of Redirect Page (including http:// and/or www)"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
              showError={formik.touched.url && !!formik.errors.url}
              error={formik.errors.url}
              widthClass="tw-mt-4 lg:tw-w-1/4 sm:tw-w-1/2 xxs:tw-w-full"
            />
          )}
        </div>
        <div className="tw-mt-8 tw-mx-2">
          <input
            id="hasBookingForm"
            name="hasBookingForm"
            type="checkbox"
            className="tw-mr-4"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            checked={formik.values.hasBookingForm}
            value
          />
          <label
            htmlFor="hasBookingForm"
            onClick={formik.handleChange}
            className="tw-font-body tw-block-inline tw-text-sm tw-font-medium tw-leading-5 tw-text-gray-700 tw-cursor-pointer"
          >
            Use A Booking Form For This Appointment Type
          </label>
          {formik.values.hasBookingForm && (
            <Select
              id="contactFormType"
              value={formik.values.contactFormType}
              options={contactFormTypeOptions}
              labelText="Select Booking Form"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
              showError={
                formik.touched.contactFormType &&
                !!formik.errors.contactFormType
              }
              error={formik.errors.contactFormType}
              widthClass="tw-mt-4 lg:tw-w-1/4 sm:tw-w-1/2 xxs:tw-w-full"
            />
          )}
        </div>
      </div>
    </form>
  );
};

AppointmentTypeForm.defaultProps = {
  type: 'new',
  formik: {}
};

AppointmentTypeForm.propTypes = {
  type: PropTypes.oneOf(['new', 'edit']),
  formik: PropTypes.shape(formikShape)
};

export default AppointmentTypeForm;
