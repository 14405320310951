import { PRODUCT_LIST_SORT } from 'config/constants';

function sortWeightByMySubscriptionType(subscription, sub) {
  if (subscription?.default_lead_product_id === sub.product.id) {
    return 1;
  }
  if (subscription?.default_client_product_id === sub.product.id) {
    return 2;
  }
  if (sub.product.lifecycles.join('') === ['lead'].join('')) {
    return 3;
  }
  if (sub.product?.lifecycles?.includes('lead')) {
    return 4;
  }
  if (sub.product?.lifecycles?.includes('client')) {
    return 5;
  }
  if (sub.product?.lifecycles.join('') === ['former_client'].join('')) {
    return 6;
  }
  return 7;
}
const sorted = (array, subscription) => {
  const updatedArray = [...array];
  return updatedArray.sort((a, b) => {
    return (
      sortWeightByMySubscriptionType(subscription, a) -
      sortWeightByMySubscriptionType(subscription, b)
    );
  });
};

const sortedProducts = (list, subscription) => {
  let sortedObject = {};
  Object.keys(list)
    .sort((a, b) => {
      return PRODUCT_LIST_SORT[a] - PRODUCT_LIST_SORT[b];
    })
    .map(key => {
      const sortedArray = subscription
        ? sorted(list[key], subscription)
        : list[key];
      sortedObject = { ...sortedObject, [key]: sortedArray };
      return sortedObject;
    });
  return sortedObject;
};

export default sortedProducts;
