import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Autosuggest from 'react-autosuggest';
import isEmpty from 'lodash.isempty';
import get from 'lodash.get';
import {
  createErrorMessageSelector,
  createLoadedSelector,
  createLoadingSelector
} from 'appState/selectors';
import {
  BROADCAST_CREATE_CUSTOM_GROUP,
  BROADCAST_SEARCH_CONTACTS_BY_FILE
} from 'app-state/selectors/constants/broadcast.selectors';
import {
  broadcastFetchAudienceFilters,
  broadcastsFetchContacts,
  broadcastUpdateAudience,
  broadcastUpdate,
  broadcastSetHasContentChanged,
  broadcastFetchAudienceCount,
  broadcastFetchContactsCounts,
  broadcastSetSelectedFilters,
  broadcastSetSelectedSuggestions,
  broadcastSearchContactsByFile,
  broadcastCreateCustomGroup,
  flashErrorMessage
} from 'appState/actions/ActionCreators';
import useToggleHeaderFooter from 'components/shared/hooks/useToggleHeaderFooter';
import useDebounce from 'components/shared/hooks/useDebounce';
import { buildAudienceFullSummary } from 'lib/utils/audienceSummary';
import SelectAudienceBySkeletonItems from './SelectAudienceBySkeletonItems';
import AudienceEmptyAlertModal from './AudienceEmptyAlertModal';
import BroadcastStep from '../BroadcastStep';
import Card from '../Card';
import Tooltip from 'components/Theme/Tooltip';

const selectedBg = { backgroundColor: 'rgba(72, 176, 247, 0.05)' };
const unselectedBg = { backgroundColor: '#fff' };
const selectedBorder = 'border-info';
const unselectedBorder = 'border-secondary';

const autoSuggestTheme = {
  container: {
    position: 'relative'
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none'
  },
  suggestionsContainer: {
    display: 'none'
  },
  suggestionsContainerOpen: {
    display: 'block',
    position: 'absolute',
    top: '100%',
    left: 0,
    zIndex: 100,
    backgroundColor: '#fff',
    right: 0,
    marginTop: '2px',
    borderRadius: '2px',
    border: '1px solid #f1f2f3',
    boxShadow: '0 1px 4px rgba(0,0,0,0.06)'
  },
  suggestion: {
    cursor: 'pointer',
    display: 'block',
    margin: '4px',
    padding: '6px 12px',
    transition: '0.15s linear'
  },
  suggestionHighlighted: {
    backgroundColor: '#f9fafb'
  }
};

const SelectAudience = () => {
  const [autosuggestionValue, setAutosuggestionValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const debouncedAutosuggestionValue = useDebounce(autosuggestionValue, 400);
  const dispatch = useDispatch();
  const history = useHistory();
  const { path } = useRouteMatch();
  useToggleHeaderFooter(path);

  const loadedSelectorCreateCustomGroup = createLoadedSelector([
    BROADCAST_CREATE_CUSTOM_GROUP
  ]);

  const findContactsFromFileLoadedSelector = createLoadedSelector([
    BROADCAST_SEARCH_CONTACTS_BY_FILE
  ]);

  const findContactsFromFileLoadingSelector = createLoadingSelector([
    BROADCAST_SEARCH_CONTACTS_BY_FILE
  ]);

  const findContactsFromFileErrorSelector = createErrorMessageSelector([
    BROADCAST_SEARCH_CONTACTS_BY_FILE
  ]);

  const errorSelectorCreateCustomGroup = createErrorMessageSelector([
    BROADCAST_CREATE_CUSTOM_GROUP
  ]);

  const structuredSelector = createStructuredSelector({
    currentBroadcast: state => state.broadcastCenter.currentBroadcast,
    audienceFilters: state => state.broadcastCenter.audienceFilters,
    contacts: state => state.broadcastCenter.contacts,
    contactsFromFile: state => state.broadcastCenter.contactsFromFile,
    byContactIds: state => state.broadcastCenter.byContactIds,
    audienceCount: state => state.broadcastCenter.audienceCount,
    contactsCounts: state => state.broadcastCenter.contactsCounts,
    isTemplate: state => state.broadcastCenter.isTemplate,
    hasContentChanged: state => state.broadcastCenter.hasContentChanged,
    createCustomGroupError: state => errorSelectorCreateCustomGroup(state),
    createCustomGroupLoaded: state => loadedSelectorCreateCustomGroup(state),
    findContactsFromFileLoaded: state =>
      findContactsFromFileLoadedSelector(state),
    findContactsFromFileError: state =>
      findContactsFromFileErrorSelector(state),
    findContactsFromFileLoading: state =>
      findContactsFromFileLoadingSelector(state)
  });

  const {
    currentBroadcast,
    audienceFilters,
    contacts,
    contactsFromFile,
    byContactIds,
    audienceCount,
    contactsCounts,
    isTemplate,
    hasContentChanged,
    createCustomGroupLoaded,
    createCustomGroupError,
    findContactsFromFileLoading,
    findContactsFromFileLoaded,
    findContactsFromFileError
  } = useSelector(structuredSelector);

  const [selectAudienceBy, setSelectAudienceBy] = useState('');
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [audienceSummary, setAudienceSummary] = useState('');
  const [selectedSuggestions, setSelectedSuggestions] = useState([]);
  const [showCreateCustomGroupModal, setShowCreateCustomGroupModal] = useState(
    false
  );
  const [createCustomGroupName, setCreateCustomGroupName] = useState('');
  const fileRef = useRef();
  const filePreviewRef = useRef();
  const [excludePausedContacts, setExcludePausedContacts] = useState(false);
  const [includeStaffMembers, setIncludeStaffMembers] = useState(false);

  function saveAudience(overrideNavigation = false) {
    dispatch(
      broadcastUpdate({
        broadcastId: currentBroadcast.id,
        selectAudienceBy,
        includeStaffMembers
      })
    );

    const updatePayload = {
      broadcast: currentBroadcast,
      selectAudienceBy,
      history,
      overrideNavigation,
      excludePausedContacts
    };

    if (selectedFilters)
      updatePayload.filterIds = selectedFilters.map(f => f.id);
    if (selectedSuggestions)
      updatePayload.manuallySelectedContactIds = selectedSuggestions.map(
        s => s.id
      );

    dispatch(broadcastUpdateAudience(updatePayload));
  }

  function isParentIdSelected(_selectedFilters, filterOrFilters) {
    const selectedFilterIds = _selectedFilters.map(f => f.id);
    if (Array.isArray(filterOrFilters)) {
      return filterOrFilters.some(f =>
        selectedFilterIds.includes(f.parent_filter_id)
      );
    }
    return selectedFilterIds.includes(filterOrFilters.parent_filter_id);
  }

  function noLifecycleOrGroupsSelected(_selectedFilters) {
    return _selectedFilters.every(
      f => f.category !== 'lifecycle' && f.category !== 'groups'
    );
  }

  const allLifecycleAndGroupFilters = audienceFilters.filter(
    f => f.category === 'lifecycle' || f.category === 'groups'
  );

  function getAudienceCount(filters, audienceId) {
    dispatch(
      broadcastFetchAudienceCount({
        audienceId,
        filterIds: filters.map(f => f.id)
      })
    );
  }

  function getContactsCounts(filters, audienceId) {
    dispatch(
      broadcastFetchContactsCounts({
        audienceId,
        filterIds: filters.map(f => f.id),
        perFilter: true
      })
    );
  }

  const onFilterChange = (e, filter) => {
    let values = selectedFilters;

    if (e && e.target && e.target.checked) {
      values = values ? [...values, filter] : [filter];
    } else {
      values = values.filter(v => v.id !== filter.id);
    }

    if (noLifecycleOrGroupsSelected(values)) {
      // deselect all filters if no lifecycle or groups filters selected
      values = [];
    }

    // Filter out values whose parent is not selected
    const valuesFilteredByParent = values.filter(
      v =>
        !v.parent_filter_id ||
        isParentIdSelected(values, v) ||
        selectAudienceBy === 'groups' // because groups filters have no parent_filter
    );

    setSelectedFilters(valuesFilteredByParent);
    if (!hasContentChanged) dispatch(broadcastSetHasContentChanged(true));
  };

  function filtersByCategoryAndType(category = null, type = null) {
    let filters = audienceFilters;
    if (category) filters = filters.filter(f => f.category === category);
    if (type) filters = filters.filter(f => f.type === type);

    return filters;
  }

  function filtersGroupedByParent(_filters) {
    const filters = _filters.reduce((filtersMemoized, f) => {
      const filtersMemo = filtersMemoized;
      if (!filtersMemo[get(f, 'parent_filter.name')])
        filtersMemo[get(f, 'parent_filter.name')] = [];
      filtersMemo[get(f, 'parent_filter.name')].push(f);
      return filtersMemo;
    }, {});
    return filters;
  }

  function filterGroupsByParentArray(filters) {
    const groupedfiltersObj = filtersGroupedByParent(filters);
    return Object.keys(groupedfiltersObj).map(key => {
      return { name: key, filters: groupedfiltersObj[key] };
    });
  }

  function filtersGroupedByOwner(_filters) {
    const filters = _filters.reduce((filtersMemoized, f) => {
      const filtersMemo = filtersMemoized;
      if (!filtersMemo[get(f, 'owner_name')])
        filtersMemo[get(f, 'owner_name')] = [];
      filtersMemo[get(f, 'owner_name')].push(f);
      return filtersMemo;
    }, {});
    return filters;
  }

  function filtersGroupedByOwnerArray(filters) {
    const groupedfiltersObj = filtersGroupedByOwner(filters);
    return Object.keys(groupedfiltersObj).map(key => {
      return { name: key, filters: groupedfiltersObj[key] };
    });
  }

  const isCheckboxChecked = (filter, _selectAudienceBy = null) => {
    if (selectedFilters) {
      const isInSelectedFilters = selectedFilters.some(f => f.id === filter.id);
      if (_selectAudienceBy)
        return isInSelectedFilters && selectAudienceBy === _selectAudienceBy;
      return isInSelectedFilters;
    }
    return null;
  };

  const isCheckboxShown = filter => {
    const selectedFiltersWithSameName = selectedFilters.filter(
      f => f.name === filter.name
    );

    return !selectedFiltersWithSameName.some(f =>
      f.restricted_categories.includes(filter.category)
    );
  };

  const isLifecycleStageSelected =
    selectedFilters &&
    selectedFilters.some(
      f => f.category === 'lifecycle' && f.name.toLowerCase() !== 'none'
    );

  const isCustomOrSmartGroupSelected =
    selectedFilters && selectedFilters.some(f => f.category === 'groups');

  const selectAudience = (filter, e) => {
    if (!e || (e && e.code === 'Enter')) setSelectAudienceBy(filter);
    if (filter.toLowerCase() === 'everyone') {
      const everyoneFilter = audienceFilters.filter(
        f => f.name.toLowerCase() === 'everyone'
      );
      setSelectedFilters(everyoneFilter);
    } else {
      // eslint-disable-next-line no-lonely-if
      if (filter !== selectAudienceBy) setSelectedFilters([]);
    }
    if (filter !== selectAudienceBy) {
      setSelectedSuggestions([]);
      setAudienceSummary('');
      if (!hasContentChanged) dispatch(broadcastSetHasContentChanged(true));
    }
  };

  const onAutosuggestionChange = (event, { newValue }) => {
    setAutosuggestionValue(newValue);
  };

  const getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0 ? [] : suggestions;
  };

  function onSuggestionsFetchRequested({ value }) {
    getSuggestions(value);
  }

  function onSuggestionsClearRequested() {
    setSuggestions([]);
  }

  function isSuggestionSubscribed(suggestion) {
    const isEmail = currentBroadcast.template_type === 'Email';
    const isEmailSubscribed = suggestion.subscribed === true;
    const isSmsSubscribed =
      suggestion.subscribed_to_sms === true &&
      suggestion.can_be_sent_sms === true;
    return (isEmail && isEmailSubscribed) || (!isEmail && isSmsSubscribed);
  }

  function isSuggestionSelectable(suggestion) {
    const isDisabled =
      suggestion.under_14 || !isSuggestionSubscribed(suggestion);
    const selectedSuggestionIds =
      selectedSuggestions && selectedSuggestions.map(s => s.id);
    return (
      selectedSuggestions &&
      !selectedSuggestionIds.includes(suggestion.id) &&
      !isDisabled
    );
  }

  function onSuggestionSelected(e, { suggestion }) {
    if (suggestion && isSuggestionSelectable(suggestion)) {
      setSelectedSuggestions([suggestion, ...selectedSuggestions]);
      if (!hasContentChanged) dispatch(broadcastSetHasContentChanged(true));
    }
    setAutosuggestionValue('');
  }

  function handleCreateCustomGroupModalClose() {
    setShowCreateCustomGroupModal(false);
    setCreateCustomGroupName('');
  }

  function handleCreateCustomGroupAction() {
    dispatch(
      broadcastCreateCustomGroup({
        selectedContactIds: selectedSuggestions.map(s => s.id),
        name: createCustomGroupName
      })
    );
  }

  function onRemoveSelectedSuggestion(suggestionId) {
    const updatedSelections = selectedSuggestions.filter(
      s => s.id !== suggestionId
    );
    setSelectedSuggestions(updatedSelections);
    if (!hasContentChanged) dispatch(broadcastSetHasContentChanged(true));
  }

  function getSuggestionValue(suggestion) {
    return suggestion.full_name;
  }

  function getErrorMessage(content) {
    return (
      <Fragment>
        <i className="fa fa-exclamation-triangle text-danger" />
        &nbsp;&nbsp;
        <span className="text-danger">{content}</span>
      </Fragment>
    );
  }

  function buildUnsubscribedErrorMessage(suggestion, isEmail) {
    if (isEmail && !suggestion.subscribed)
      return getErrorMessage('Contact unsubscribed from Email');

    if (!suggestion.subscribed_to_sms)
      return getErrorMessage('Contact unsubscibed from SMS');

    if (!suggestion.can_be_sent_sms)
      return getErrorMessage('No valid phone number');
  }

  function getSuggestionsItems(suggestion) {
    const isEmail = currentBroadcast.template_type === 'Email';
    const {
      subscribed,
      subscribed_to_sms,
      can_be_sent_sms,
      under_14,
      full_name,
      id,
      current_lifecycle_badge,
      email,
      formatted_phone
    } = suggestion;

    const isEmailDisabled = !subscribed;
    const isSmsDisabled = !subscribed_to_sms || !can_be_sent_sms;

    const isDisabledClass =
      under_14 || (isEmail && isEmailDisabled) || (!isEmail && isSmsDisabled)
        ? 'text-disabled'
        : '';

    return (
      <div className={isDisabledClass} data-for={id?.toString()} data-tip>
        <div className="d-flex">
          <span className="mt-2">
            {JSON.stringify(full_name).replace(/"/g, '')}
          </span>
          {current_lifecycle_badge && (
            <span
              className="pl-2"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: current_lifecycle_badge
              }}
            />
          )}
        </div>
        <div>
          {email && (
            <Fragment>
              <i className="fa fa-envelope" style={{ opacity: '0.5' }} />
              &nbsp;&nbsp;
              <small>{email}</small>&nbsp;&nbsp;&nbsp;
            </Fragment>
          )}

          {formatted_phone && (
            <Fragment>
              <i className="fa fa-phone" style={{ opacity: '0.5' }} />
              &nbsp;
              <small>{formatted_phone}</small>
            </Fragment>
          )}
        </div>
        <div className="mb-2">
          {buildUnsubscribedErrorMessage(suggestion, isEmail)}
        </div>
      </div>
    );
  }

  function renderSuggestion(suggestion) {
    if (!suggestion.under_14) return getSuggestionsItems(suggestion);

    return (
      <Tooltip
        id={suggestion.id?.toString()}
        place="right"
        classNames="tw-bg-white"
        paddingClass="tw-p-2"
        widthClass="tw-max-w-lg"
        TooltipElement={() => {
          return getSuggestionsItems(suggestion);
        }}
      >
        <p className="tw-text-gray-500 tw-text-xs tw-font-normal tw-bg-white tw-mb-0">
          This contact is 13 or younger. Communications from UpLaunch are not
          permitted.
        </p>
      </Tooltip>
    );
  }

  const filtersByCategory = [
    {
      title: 'Assigned Staff Members',
      description:
        'Only send to contacts who are assigned to the following staff...',
      filters: filtersByCategoryAndType('assigned_staff')
    },
    {
      title: 'Journeys',
      description: 'Only send to contacts who are in the following journeys...',
      filters: filtersByCategoryAndType('journey')
    },
    {
      title: 'Appointments Booked',
      description: 'Only send to contacts who have booked appointments...',
      filters: filtersByCategoryAndType('appointment_booked')
    },
    {
      title: 'Appointments Not Booked',
      description: 'Only send to contacts who have not booked appointments...',
      filters: filtersByCategoryAndType('appointment_not_booked')
    },
    {
      title: 'Appointments Attended',
      description: 'Only send to contacts who have attended appointments...',
      filters: filtersByCategoryAndType('appointment_attended')
    },
    {
      title: 'Appointments Not Attended',
      description:
        'Only send to contacts who have not attended appointments...',
      filters: filtersByCategoryAndType('appointment_not_attended')
    },
    {
      title: 'Age',
      description:
        'Only send to contacts who are in the selected age ranges...',
      filters: filtersByCategoryAndType('age')
    },
    {
      title: 'Gender',
      description:
        'Only send to contacts who are one of the selected genders...',
      filters: filtersByCategoryAndType('gender')
    }
  ];

  function renderFiltersByCategory(title, description, filters) {
    return (
      <Fragment key={title}>
        <h6 className="mb-4">{title}</h6>
        <h6 className="my-4 mx-3" style={{ opacity: '0.6' }}>
          {description}
        </h6>
        <div className="row mb-30">
          {filters.map(filter => {
            return (
              <Fragment key={filter.id}>
                {isCheckboxShown(filter) && (
                  <div className="row">
                    <div className="col-12 mx-5 mb-3">
                      <div className="row mb-0">
                        <input
                          id={filter.id}
                          type="checkbox"
                          value={filter.id}
                          checked={isCheckboxChecked(filter)}
                          onChange={e => onFilterChange(e, filter)}
                          className="mr-3 mt-2"
                        />
                        <label
                          className="fs-14 fw-300 filter-label cursor-pointer"
                          htmlFor={filter.id}
                        >
                          {filter.name}
                        </label>
                      </div>

                      <div
                        className="row ml-3"
                        style={{ marginTop: '-14px' }}
                      />
                    </div>
                  </div>
                )}
              </Fragment>
            );
          })}
        </div>
      </Fragment>
    );
  }

  const inputProps = {
    placeholder: 'Search by name, email or phone',
    value: autosuggestionValue,
    className: 'form-control',
    onChange: onAutosuggestionChange
  };

  useEffect(() => {
    dispatch(broadcastFetchAudienceFilters());
  }, [dispatch]);

  useEffect(() => {
    dispatch(broadcastSetSelectedFilters({ selectedFilters }));
  }, [selectedFilters]);

  useEffect(() => {
    dispatch(broadcastSetSelectedSuggestions({ selectedSuggestions }));
  }, [selectedSuggestions]);

  useEffect(() => {
    if (!isEmpty(contacts)) setSuggestions(contacts.slice(0, 5));
  }, [contacts]);

  useEffect(() => {
    if (createCustomGroupLoaded && isEmpty(createCustomGroupError)) {
      handleCreateCustomGroupModalClose();
      app.toast('Custom group created successfully', {
        type: 'success',
        icon: 'fa-floppy-o'
      });
    }
    if (createCustomGroupLoaded && !isEmpty(createCustomGroupError)) {
      app.toast(createCustomGroupError, {
        type: 'danger',
        icon: 'fa-floppy-o'
      });
    }
  }, [createCustomGroupLoaded]);

  useEffect(() => {
    if (findContactsFromFileLoaded && !isEmpty(findContactsFromFileError)) {
      dispatch(flashErrorMessage(findContactsFromFileError));
    }
  }, [findContactsFromFileLoaded, findContactsFromFileError]);

  useEffect(() => {
    if (
      !isTemplate &&
      !isEmpty(currentBroadcast) &&
      !currentBroadcast.has_been_edited &&
      hasContentChanged
    ) {
      dispatch(
        broadcastUpdate({
          broadcastId: currentBroadcast.id,
          hasBeenEdited: true
        })
      );
    }
  }, [
    hasContentChanged,
    isTemplate,
    currentBroadcast.has_been_edited,
    dispatch
  ]);

  useEffect(() => {
    if (selectedFilters && get(currentBroadcast, 'audience')) {
      const fullAudienceSummary = buildAudienceFullSummary(
        {
          audience: { filters: selectedFilters }
        },
        (e, filter) => onFilterChange(e, filter)
      );
      setAudienceSummary(fullAudienceSummary);
      getAudienceCount(selectedFilters, currentBroadcast.audience.id);
    }
  }, [currentBroadcast.audience, selectedFilters]);

  useEffect(() => {
    if (currentBroadcast.select_audience_by)
      setSelectAudienceBy(currentBroadcast.select_audience_by);
  }, [currentBroadcast.select_audience_by]);

  useEffect(() => {
    if (
      currentBroadcast.audience &&
      !isEmpty(currentBroadcast.audience.filters)
    ) {
      setSelectedFilters(currentBroadcast.audience.filters);
    }
  }, [currentBroadcast.audience, dispatch]);

  useEffect(() => {
    if (
      currentBroadcast.audience &&
      !isEmpty(currentBroadcast.audience.manually_selected_contact_ids) &&
      isEmpty(selectedSuggestions) &&
      selectAudienceBy === 'searching'
    ) {
      dispatch(
        broadcastsFetchContacts({
          contactIds: currentBroadcast.audience.manually_selected_contact_ids
        })
      );
    }
  }, [currentBroadcast.audience, selectAudienceBy, dispatch]);

  useEffect(() => {
    if (byContactIds && !isEmpty(contacts)) {
      setSelectedSuggestions(contacts);
    }
  }, [byContactIds, contacts]);

  useEffect(() => {
    if (!isEmpty(contactsFromFile)) {
      const suggestionsFromFile = contactsFromFile.filter(c =>
        isSuggestionSubscribed(c)
      );
      const selectedIds = {};
      const newSelectedSuggestions = [
        ...selectedSuggestions,
        ...suggestionsFromFile
      ].filter(suggestion => {
        if (selectedIds[suggestion.id]) {
          return false;
        }
        selectedIds[suggestion.id] = true;
        return true;
      });
      setSelectedSuggestions(newSelectedSuggestions);
      if (fileRef?.current) fileRef.current.value = '';
      if (filePreviewRef?.current) filePreviewRef.current.value = '';
    }
  }, [contactsFromFile]);

  useEffect(() => {
    if (debouncedAutosuggestionValue.trim() !== '') {
      dispatch(
        broadcastsFetchContacts({
          query: debouncedAutosuggestionValue
        })
      );
    }
  }, [debouncedAutosuggestionValue, dispatch]);

  useEffect(() => {
    if (!isEmpty(audienceFilters) && currentBroadcast.template_type)
      getContactsCounts(
        allLifecycleAndGroupFilters,
        currentBroadcast.audience.id
      );
  }, [audienceFilters, currentBroadcast]);

  useEffect(() => {
    if (!isEmpty(audienceFilters)) {
      if (currentBroadcast.select_audience_by === 'everyone') {
        const everyoneFilter = audienceFilters.filter(
          f => f.name.toLowerCase() === 'everyone'
        );
        setSelectedFilters(everyoneFilter);
      }
    }
  }, [currentBroadcast.select_audience_by, audienceFilters, dispatch]);

  function handleFileUpload() {
    if (fileRef.current !== null && fileRef.current.files.length !== 0)
      dispatch(
        broadcastSearchContactsByFile({
          file: fileRef.current.files[0],
          ageRestriction: 'are_fourteen_and_above'
        })
      );
  }

  return (
    <div>
      <BroadcastStep saveAction={saveAudience}>
        {selectAudienceBy !== 'searching' && (
          <Card title="Summary" containerStyle={{ marginBottom: '10px' }}>
            <p>
              {currentBroadcast.template_type === 'Email' ? 'Email' : 'SMS'}
              {' will be sent to '}
              <strong>
                {selectedFilters && selectedFilters.length ? audienceCount : 0}{' '}
                contacts
              </strong>
            </p>
            <div>{audienceSummary}</div>
          </Card>
        )}

        <Card
          title="Audience"
          subtitle="Who should we send this to?"
          containerStyle={{ marginBottom: '10px' }}
        >
          {!isEmpty(audienceFilters) ? (
            <div className="row mt-25">
              <div className="col-sm-12 col-md-3 col-xl-2 mb-4">
                <div
                  className={`d-flex flex-row b-2 p-20 big-rounded select_audience_by_main_category cursor-pointer ${
                    selectAudienceBy === 'everyone'
                      ? selectedBorder
                      : unselectedBorder
                  }`}
                  style={
                    selectAudienceBy === 'everyone' ? selectedBg : unselectedBg
                  }
                  id="select_audience_by_everyone"
                >
                  <div
                    onClick={() => selectAudience('everyone')}
                    onKeyDown={e => selectAudience('everyone', e)}
                    role="button"
                    tabIndex={-1}
                  >
                    <span className="fw-400 fs-20">Everyone</span>
                    <br />
                    <span className="fw-200 fs-14">
                      Select all contacts who have not opted out of marketing.
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-3 col-xl-2 mb-4">
                <div
                  className={`d-flex flex-row b-2 p-20 big-rounded select_audience_by_main_category cursor-pointer ${
                    selectAudienceBy === 'lifecycle'
                      ? selectedBorder
                      : unselectedBorder
                  }`}
                  style={
                    selectAudienceBy === 'lifecycle' ? selectedBg : unselectedBg
                  }
                  id="select_audience_by_lifecycle"
                >
                  <div
                    onClick={() => selectAudience('lifecycle')}
                    onKeyDown={e => selectAudience('lifecycle', e)}
                    role="button"
                    tabIndex={-1}
                  >
                    <span className="fw-400 fs-20">By Lifecycle</span>
                    <br />
                    <span className="fw-200 fs-14">
                      Select an audience base on Lifecycle Stage (ex. Leads).
                    </span>
                  </div>
                </div>
              </div>
              {!isEmpty(filtersByCategoryAndType('groups')) && (
                <div className="col-sm-12 col-md-3 col-xl-2 mb-4">
                  <div
                    className={`d-flex flex-row b-2 p-20 big-rounded select_audience_by_main_category cursor-pointer ${
                      selectAudienceBy === 'groups'
                        ? selectedBorder
                        : unselectedBorder
                    }`}
                    style={
                      selectAudienceBy === 'groups' ? selectedBg : unselectedBg
                    }
                    id="select_audience_by_group"
                  >
                    <div
                      onClick={() => selectAudience('groups')}
                      onKeyDown={e => selectAudience('groups', e)}
                      role="button"
                      tabIndex={-1}
                    >
                      <span className="fw-400 fs-20">By Group</span>
                      <br />
                      <span className="fw-200 fs-14">
                        Select an audience based on Custom or Smart Groups.
                      </span>
                    </div>
                  </div>
                </div>
              )}
              <div className="col-sm-12 col-md-3 col-xl-2 mb-4">
                <div
                  className={`d-flex flex-row b-2 p-20 big-rounded select_audience_by_main_category cursor-pointer ${
                    selectAudienceBy === 'searching'
                      ? selectedBorder
                      : unselectedBorder
                  }`}
                  style={
                    selectAudienceBy === 'searching' ? selectedBg : unselectedBg
                  }
                  id="select_audience_by_search"
                >
                  <div
                    onClick={() => selectAudience('searching')}
                    onKeyDown={e => selectAudience('searching', e)}
                    role="button"
                    tabIndex={-1}
                  >
                    <span className="fw-400 fs-20">By Manual</span>
                    <br />
                    <span className="fw-200 fs-14">
                      Select audience by searching for individual people or by
                      file.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="row mt-25">
              <SelectAudienceBySkeletonItems count={4} />
            </div>
          )}
          <Fragment>
            <div className="tw-mt-2">
              <input
                id="excludePausedContacts"
                type="checkbox"
                checked={excludePausedContacts}
                onChange={() =>
                  setExcludePausedContacts(!excludePausedContacts)
                }
              />
              <label
                className="tw-text-base tw-font-normal tw-cursor-pointer ml-2"
                htmlFor="excludePausedContacts"
              >
                Exclude any paused contacts from my audience.
              </label>
            </div>
          </Fragment>
          <Fragment>
            <div className="tw-mt-2">
              <input
                id="includeStaffMembers"
                type="checkbox"
                checked={includeStaffMembers}
                onChange={() => setIncludeStaffMembers(!includeStaffMembers)}
              />
              <label
                className="tw-text-base tw-font-normal tw-cursor-pointer ml-2"
                htmlFor="includeStaffMembers"
              >
                Include all staff members
              </label>
            </div>
          </Fragment>
          {selectAudienceBy === 'lifecycle' && (
            <div id="lifecycle-filter-wrapper" className="mt-20">
              <h5 className="my-4 fw-300">Lifecycle Stages</h5>
              <h6 className="my-4 mx-3" style={{ opacity: '0.6' }}>
                Only send to contacts who are in one of the selected lifecycle
                stages...
              </h6>
              <div className="row">
                {filtersByCategoryAndType('lifecycle').map(filter => {
                  return (
                    <div className="row" key={filter.id}>
                      <div className="col-12 mx-5 mb-3">
                        <div className="row mb-0">
                          <input
                            id={filter.id}
                            type="checkbox"
                            value={filter.id}
                            checked={isCheckboxChecked(filter)}
                            onChange={e => onFilterChange(e, filter)}
                            className="mr-3 mt-2 lifecycle-filters"
                          />
                          <label
                            className="fs-14 fw-700 filter-label cursor-pointer"
                            data-lifecycle-label
                            htmlFor={filter.id}
                          >
                            {filter.name}
                          </label>
                        </div>
                        <div
                          className="row ml-3"
                          style={{ marginTop: '-14px' }}
                        >
                          <p className="fs-10 fw-200">
                            {contactsCounts[filter.id]} contact(s)
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {selectAudienceBy === 'groups' && (
            <div id="group-filter-wrapper" className="mt-20">
              {!isEmpty(
                filtersByCategoryAndType('groups', 'CustomGroupFilter')
              ) && (
                <Fragment>
                  <h5 className="my-4 fw-300">Custom Groups</h5>
                  <h6 className="my-4 mx-3" style={{ opacity: '0.6' }}>
                    Only send to contacts who are in one of the selected custom
                    groups...
                  </h6>
                  <div className="row">
                    {filtersByCategoryAndType(
                      'groups',
                      'CustomGroupFilter'
                    ).map(filter => {
                      return (
                        <div className="row" key={filter.id}>
                          <div className="col-12 mx-5 mb-3">
                            <div className="row mb-0">
                              <input
                                id={filter.id}
                                type="checkbox"
                                value={filter.id}
                                checked={isCheckboxChecked(filter)}
                                onChange={e => onFilterChange(e, filter)}
                                className="mr-3 mt-2 lifecycle-filters"
                              />
                              <label
                                className="fs-14 fw-700 filter-label cursor-pointer"
                                data-lifecycle-label
                                htmlFor={filter.id}
                              >
                                {filter.name}
                              </label>
                            </div>
                            <div
                              className="row ml-3"
                              style={{ marginTop: '-14px' }}
                            >
                              <p className="fs-10 fw-200">
                                {contactsCounts[filter.id]} contact(s)
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </Fragment>
              )}
              {!isEmpty(
                filtersByCategoryAndType('groups', 'SmartGroupFilter')
              ) && (
                <Fragment>
                  <h5 className="my-4 fw-300">Smart Groups</h5>
                  <h6 className="my-4 mx-3" style={{ opacity: '0.6' }}>
                    Only send to contacts who are in one of the selected
                    SmartGroups...
                  </h6>
                  <div className="row">
                    {filtersByCategoryAndType('groups', 'SmartGroupFilter').map(
                      filter => {
                        return (
                          <div className="row" key={filter.id}>
                            <div className="col-12 mx-5 mb-3">
                              <div className="row mb-0">
                                <input
                                  id={filter.id}
                                  type="checkbox"
                                  value={filter.id}
                                  checked={isCheckboxChecked(filter)}
                                  onChange={e => onFilterChange(e, filter)}
                                  className="mr-3 mt-2 lifecycle-filters"
                                />
                                <label
                                  className="fs-14 fw-700 filter-label cursor-pointer"
                                  data-lifecycle-label
                                  htmlFor={filter.id}
                                >
                                  {filter.name}
                                </label>
                              </div>
                              <div
                                className="row ml-3"
                                style={{ marginTop: '-14px' }}
                              >
                                <p className="fs-10 fw-200">
                                  {contactsCounts[filter.id]} contact(s)
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </Fragment>
              )}
            </div>
          )}
          {selectAudienceBy === 'searching' && (
            <Fragment>
              <div
                className="row pt-50 pb-30 bg-white"
                id="search-typeahead"
                style={{
                  position: 'sticky',
                  top: '80px',
                  zIndex: 2
                }}
              >
                <div className="col-12 col-md-6">
                  <label>Search By File</label>
                  <div className="input-group file-group">
                    <input
                      type="text"
                      className="form-control file-value file-browser"
                      placeholder="Choose file..."
                      readOnly=""
                      ref={filePreviewRef}
                    />
                    <input ref={fileRef} type="file" accept=".csv" />
                    <span className="input-group-append">
                      <div
                        role="button"
                        tabIndex="0"
                        className="btn btn-secondary"
                        onClick={handleFileUpload}
                        onKeyDown={handleFileUpload}
                      >
                        {findContactsFromFileLoading
                          ? 'Finding Contacts'
                          : 'Find Contacts'}
                      </div>
                    </span>
                  </div>
                  <div
                    className="alert alert-light alert-dismissible fade show mt-10 pr-40"
                    role="alert"
                  >
                    <button
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    Use a .csv file to search for contacts. If matches are found
                    in UpLaunch, by email or phone, those contacts will be added
                    to your audience. No new contacts will be created.{' '}
                    <strong>
                      NOTE: A &#39;email&#39; or &#39;phone&#39; column header
                      is required to process the .csv file.
                    </strong>
                  </div>
                </div>
                <div className="col-12 col-md-6 form-group">
                  <div id="search-typeahead-wrapper">
                    <label>Search Contacts</label>
                    <span className="twitter-typeahead mb-30">
                      <Autosuggest
                        suggestions={suggestions}
                        onSuggestionsFetchRequested={
                          onSuggestionsFetchRequested
                        }
                        onSuggestionsClearRequested={
                          onSuggestionsClearRequested
                        }
                        onSuggestionSelected={onSuggestionSelected}
                        getSuggestionValue={getSuggestionValue}
                        renderSuggestion={renderSuggestion}
                        inputProps={inputProps}
                        theme={autoSuggestTheme}
                        focusInputOnSuggestionClick={false}
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div
                role="button"
                onKeyDown={() => window.scrollTo(0, 0)}
                tabIndex="0"
                className="py-2 px-3 bg-secondary cursor-pointer"
                data-tooltip="Scroll To Top"
                onClick={() => window.scrollTo(0, 0)}
                style={{
                  position: 'fixed',
                  bottom: '100px',
                  right: '30px',
                  borderRadius: '5px',
                  zIndex: 3
                }}
              >
                <i className="fa fa-chevron-up" />
              </div>

              <div className="row">
                {!isEmpty(selectedSuggestions) ? (
                  <div className="col-12">
                    <div
                      role="button"
                      onKeyDown={() => setShowCreateCustomGroupModal(true)}
                      tabIndex="0"
                      className="btn btn-white btn-sm float-right mb-20"
                      onClick={() => setShowCreateCustomGroupModal(true)}
                    >
                      Create Custom Group From List
                    </div>
                  </div>
                ) : null}
                <div className="col-12">
                  <div
                    className="media-list media-list-divided media-list-hover media-list-xs b-1 rounded"
                    id="broadcast-select-audience-by-search-inner-wrapper"
                  >
                    {!isEmpty(selectedSuggestions) ? (
                      selectedSuggestions.map(suggestion => {
                        return (
                          <div
                            className="media media-new1"
                            data-id={suggestion.id}
                            key={suggestion.id}
                          >
                            <span className="avatar mt-10">
                              {suggestion.avatar_letters}
                            </span>
                            <div className="media-body mb-10">
                              <p>
                                <strong>{suggestion.full_name}</strong>
                                {suggestion.current_lifecycle_badge && (
                                  <span
                                    // eslint-disable-next-line react/no-danger
                                    dangerouslySetInnerHTML={{
                                      __html: suggestion.current_lifecycle_badge
                                    }}
                                  />
                                )}
                                <span className="float-right">
                                  <a
                                    className="remove-search-contact cursor-pointer"
                                    id={suggestion.id}
                                    onClick={() =>
                                      onRemoveSelectedSuggestion(suggestion.id)
                                    }
                                    href="#"
                                  >
                                    <i className="fa fa-trash fs-16 mt-10" />
                                  </a>
                                </span>
                              </p>
                              <p className="text-truncate">
                                {suggestion.email}
                              </p>
                              <p className="text-truncate">
                                {suggestion.formatted_phone}
                              </p>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div
                        className="p-50 text-center"
                        style={{ color: '#8b95a5' }}
                      >
                        No Contacts Selected
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div
                className="modal fade show"
                id="audience-create-custom-group-modal"
                tabIndex="-1"
                aria-modal="true"
                aria-hidden="true"
                style={{
                  display: `${showCreateCustomGroupModal ? 'block' : 'none'}`
                }}
              >
                <div className="modal-dialog modal-sm shadow-4">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title" id="myModalLabel">
                        Create Custom Group
                      </h4>
                      <button
                        type="button"
                        className="close"
                        onClick={handleCreateCustomGroupModalClose}
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <p>
                        Create a custom group from the manually selected
                        contacts list.
                      </p>
                      <label>Custom Group Name</label>
                      <div className="input-group file-group">
                        <input
                          type="text"
                          className="form-control file-value file-browser"
                          placeholder="Custom Group Name"
                          value={createCustomGroupName}
                          onChange={e =>
                            setCreateCustomGroupName(e.target.value)
                          }
                        />
                      </div>
                      <div className="float-right mt-10">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={handleCreateCustomGroupModalClose}
                        >
                          Close
                        </button>
                        <button
                          type="button"
                          className="btn btn-info ml-10"
                          onClick={handleCreateCustomGroupAction}
                        >
                          Create
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          )}
        </Card>

        {(isLifecycleStageSelected || isCustomOrSmartGroupSelected) &&
          selectAudienceBy !== 'searching' && (
            <Card
              title="Filters"
              subtitle="Who should we send this to?"
              cardControls={['slide-up']}
              containerStyle={{ marginBottom: '30px' }}
            >
              <div id="select-audience-filters-tab">
                <div className="card-body py-2 mx-3">
                  {selectAudienceBy === 'lifecycle' &&
                    isLifecycleStageSelected && (
                      <div id="lifecycle-campaign-filters-wrapper">
                        <h6 className="mb-4">Campaigns</h6>
                        <div className="mb-30">
                          {filterGroupsByParentArray(
                            filtersByCategoryAndType('campaign')
                          ).map(group => {
                            return (
                              <span
                                className="campaign-filter-wrappers"
                                id="campaign-filter-wrapper-<%= lifecycle.id %>"
                                key={group.name}
                              >
                                {isParentIdSelected(
                                  selectedFilters,
                                  group.filters
                                ) && (
                                  <Fragment>
                                    <h6
                                      className="my-4 mx-3"
                                      style={{ opacity: '0.6' }}
                                    >
                                      Only send to {group.name}s who are in the
                                      following campaigns...
                                    </h6>
                                    <div className="mx-3">
                                      {filtersGroupedByOwnerArray(
                                        group.filters
                                      ).map(ownerGroup => {
                                        return (
                                          <Fragment key={ownerGroup.name}>
                                            <strong
                                              className="text-info"
                                              style={{
                                                textTransform: 'uppercase'
                                              }}
                                            >
                                              {ownerGroup.name}
                                            </strong>
                                            <div className="row">
                                              {ownerGroup.filters.map(
                                                filter => {
                                                  return (
                                                    <div
                                                      className="row"
                                                      key={filter.id}
                                                    >
                                                      <div className="col-12 mx-5 mb-3">
                                                        <div className="row mb-0">
                                                          <input
                                                            id={filter.id}
                                                            type="checkbox"
                                                            value={filter.id}
                                                            checked={isCheckboxChecked(
                                                              filter,
                                                              'lifecycle'
                                                            )}
                                                            onChange={e =>
                                                              onFilterChange(
                                                                e,
                                                                filter
                                                              )
                                                            }
                                                            className="mr-3 mt-2 campaign-filters"
                                                          />
                                                          <label
                                                            className="fs-14 fw-300 filter-label cursor-pointer"
                                                            data-lifecycle-label
                                                            htmlFor={filter.id}
                                                          >
                                                            {filter.name}
                                                          </label>
                                                        </div>
                                                        <div
                                                          className="row ml-3"
                                                          style={{
                                                            marginTop: '-14px'
                                                          }}
                                                        />
                                                      </div>
                                                    </div>
                                                  );
                                                }
                                              )}
                                            </div>
                                          </Fragment>
                                        );
                                      })}
                                    </div>
                                  </Fragment>
                                )}
                              </span>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  {selectAudienceBy === 'groups' &&
                    isCustomOrSmartGroupSelected && (
                      <div id="group-campaign-filters-wrapper">
                        <h6 className="mb-4">Campaigns</h6>
                        <h6 className="my-4 mx-3" style={{ opacity: '0.6' }}>
                          Only send to contacts who are in the following
                          campaigns...
                        </h6>
                        <div className="mb-30 d-flex flex-wrap">
                          <div className="mx-3">
                            {filtersGroupedByOwnerArray(
                              filtersByCategoryAndType('campaign')
                            ).map(ownerGroup => {
                              return (
                                <Fragment key={ownerGroup.name}>
                                  <strong
                                    className="text-info"
                                    style={{
                                      textTransform: 'uppercase'
                                    }}
                                  >
                                    {ownerGroup.name}
                                  </strong>
                                  <div className="row">
                                    {ownerGroup.filters.map(filter => {
                                      return (
                                        <div className="row" key={filter.id}>
                                          <div className="row">
                                            <div className="col-12 mx-5 mb-3">
                                              <div className="row mb-0">
                                                <input
                                                  id={filter.id}
                                                  type="checkbox"
                                                  value={filter.id}
                                                  checked={isCheckboxChecked(
                                                    filter,
                                                    'groups'
                                                  )}
                                                  onChange={e =>
                                                    onFilterChange(e, filter)
                                                  }
                                                  className="mr-3 mt-2 campaign-filters"
                                                />
                                                <label
                                                  className="fs-14 fw-300 filter-label cursor-pointer"
                                                  data-lifecycle-label
                                                  htmlFor={filter.id}
                                                >
                                                  {filter.name}
                                                </label>
                                              </div>
                                              <div
                                                className="row ml-3"
                                                style={{ marginTop: '-14px' }}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </Fragment>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    )}

                  {filtersByCategory.map(f =>
                    renderFiltersByCategory(f.title, f.description, f.filters)
                  )}
                </div>
              </div>
            </Card>
          )}
        <AudienceEmptyAlertModal />
      </BroadcastStep>
    </div>
  );
};

export default SelectAudience;
