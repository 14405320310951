import React, { useRef, useState, useCallback } from 'react';
import Badge from 'components/Theme/Badge';
import PropTypes from 'prop-types';
import useOnClickOutside from 'components/shared/hooks/useOnClickOutside';
import { toCamel, titleize } from 'lib/utils/string';

const MultiSelect = ({
  id,
  color,
  value,
  options,
  labelText,
  placeholder,
  required,
  containerStyle,
  labelStyle,
  onChange,
  onBlur,
  showError,
  error,
  helpText,
  hideLabel,
  widthClass,
  showAddNewOption,
  addNewOptionText,
  onAddNewOptionClick
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const multiSelectDropdownRef = useRef();

  useOnClickOutside(multiSelectDropdownRef, () => setShowDropdown(false));

  function removeItem(item) {
    const filtered = value.filter(e => e !== item.value);
    setShowDropdown(false);
    onChange(filtered);
  }

  const handleOnRemove = useCallback(item => removeItem(item), [value]);

  function getDisplayValue() {
    if (!value || !value.length) return;

    const updatedOption = options.reduce((item, i) => {
      return item.concat(i.options || i);
    }, []);

    const updatedSelectedOptions = value.reduce((item, i) => {
      return item.concat(updatedOption.filter(option => option.value === i))
    },[])

    return updatedSelectedOptions
  }

  const selectedItem = getDisplayValue();

  function getSelectedItem() {
    return (
      <>
        {selectedItem &&
          selectedItem.map((item, index) => {
            return (
              <Badge
                key={item.value}
                containerStyle={{
                  marginLeft: index === 0 ? '' : '.1rem',
                  marginTop: '.1rem',
                  marginBottom: '.1rem'
                }}
                color="alpha"
                deletable
                onDelete={() => handleOnRemove(item)}
                shape="round"
                size="large"
                value={item.displayName}
              />
            );
          })}
      </>
    );
  }

  function toggleDropdown(e) {
    if (e?.which && e.which !== 13) return;
    setShowDropdown(!showDropdown);
  }

  function getName() {
    return toCamel(id);
  }

  function isValueSelected(item) {
    return value?.length && value.some(i => i === item.value);
  }

  function addItem(item, e) {
    if (e?.which && e.which !== 13) return;
    if (value?.length) {
      onChange([...value, item.value]);
    } else {
      onChange([item.value]);
    }
    setShowDropdown(false);
  }

  function showNewOptions() {
    return (
      <>
        {showAddNewOption && (
          <div
            data-testid="multi-select-show-option"
            className="tw-cursor-pointer tw-w-full tw-border-gray-100 tw-border-b"
            onClick={onAddNewOptionClick}
            onKeyDown={onAddNewOptionClick}
            role="button"
            tabIndex={-1}
          >
            <div className="tw-flex tw-w-full tw-items-center tw-p-2 tw-pl-2 tw-border-transparent tw-border-l-2 tw-relative tw-text-alpha-500 hover:tw-bg-alpha-100 tw-font-semibold">
              <div className="tw-w-full tw-items-center tw-flex">
                <div className="tw-mx-2 tw-leading-6">{addNewOptionText}</div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }

  function getOptions(items) {
    return (
      <>
        {items.map(item => {
          if (item.options?.length) {
            return (
              <div data-testid={`multi-group-${item.label}`} key={item.label}>
                <div className="tw-p-2 tw-font-medium">
                  {titleize(item.label)}
                </div>
                {getOptions(item.options)}
              </div>
            );
          }
          const isSelected = isValueSelected(item);

          return (
            <div
              data-testid={`multi-option-${item.value}`}
              key={item.value}
              className={`tw-cursor-pointer tw-w-full tw-border-gray-100 tw-border-b ${
                isSelected ? `tw-bg-${color}-100` : `hover:tw-bg-${color}-100`
              }`}
              onClick={() => !isSelected && addItem(item)}
              onKeyDown={e => !isSelected && addItem(item, e)}
              role="button"
              tabIndex={-1}
            >
              <div
                className={`tw-flex tw-w-full tw-items-center tw-p-2 tw-pl-2 tw-border-transparent tw-border-l-2 tw-relative hover:tw-border-${color}-100`}
              >
                <div className="tw-w-full tw-items-center tw-flex">
                  <div className="tw-mx-2 tw-leading-6">{item.displayName}</div>
                </div>
              </div>
            </div>
          );
        })}
      </>
    );
  }

  function renderOptions() {
    return (
      <div className="tw-flex tw-flex-col tw-w-full">
        {getOptions(options)}
        {showNewOptions()}
      </div>
    );
  }

  function getLabel() {
    return (
      <label
        data-testid="multi-select-label"
        htmlFor={id}
        className={`${
          hideLabel
            ? 'tw-sr-only'
            : 'tw-font-body tw-block tw-text-sm tw-font-medium tw-leading-5 tw-text-gray-700'
        }`}
        style={labelStyle}
      >
        {labelText}
        <span className="tw-text-error-700">{required && '*'}</span>
      </label>
    );
  }

  function getControl() {
    return (
      <div className="tw-flex tw-flex-col tw-items-center tw-relative">
        <div className="tw-w-full">
          <div
            className={`tw-font-body tw-block ${
              value && value.length > 0
                ? 'tw-form-multiselect'
                : 'tw-form-select'
            } tw-w-full tw-transition tw-duration-150 tw-ease-in-out sm:tw-text-sm sm:tw-leading-5 tw-border tw-border-solid tw-border-gray-300 tw-border-${
              showError ? 'red' : 'gray'
            }-300 focus:tw-outline-none focus:tw-shadow-outline-${
              showError ? 'red' : 'blue'
            } focus:tw-border-${showError ? 'red' : 'blue'}-300`}
            style={{ minHeight: '39px' }}
          >
            <div className="tw-flex tw-flex-auto tw-flex-wrap">
              {getSelectedItem()}
              <div
                data-testid="multi-select-input"
                className="tw-flex-1"
                onClick={toggleDropdown}
                onKeyDown={e => toggleDropdown(e)}
                role="button"
                tabIndex={-1}
              >
                <input
                  id={id}
                  name={getName(id)}
                  readOnly
                  onBlur={onBlur}
                  placeholder={value?.length > 0 ? '' : placeholder}
                  className="tw-bg-transparent tw-border-0 tw-appearance-none tw-outline-none tw-h-full tw-w-full tw-text-gray-800"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function getDropDown() {
    return (
      <div
        id="dropdown"
        className="tw-absolute tw-w-full tw-shadow tw-mt-2 tw-left-0 tw-bg-white tw-z-40 tw-rounded tw-max-h-64 tw-overflow-y-auto"
        ref={multiSelectDropdownRef}
      >
        {renderOptions()}
      </div>
    );
  }

  return (
    <div className={`${widthClass}`} style={containerStyle}>
      <div className="tw-relative tw-w-full">
        {getLabel()}
        {getControl()}
        {showDropdown && getDropDown()}
        {helpText && (
          <p className="tw-font-body tw-my-2 tw-text-sm tw-text-gray-500">
            {helpText}
          </p>
        )}
        {showError && (
          <p className="tw-font-body tw-my-2 tw-text-sm tw-text-red-600">
            {error}
          </p>
        )}
      </div>
    </div>
  );
};

MultiSelect.defaultProps = {
  value: [],
  color: 'alpha',
  placeholder: 'Please Select Your Options',
  required: false,
  containerStyle: {},
  labelStyle: {},
  onChange: null,
  onBlur: null,
  showError: false,
  error: '',
  helpText: '',
  hideLabel: false,
  widthClass: 'tw-w-full',
  showAddNewOption: false,
  addNewOptionText: '+ Add New Item',
  onAddNewOptionClick: () => {}
};

const styleProps = PropTypes.objectOf(
  PropTypes.oneOfType([PropTypes.string, PropTypes.number])
);
const optionShape = PropTypes.shape({
  displayName: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ])
});

MultiSelect.propTypes = {
  id: PropTypes.string.isRequired,
  color: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
    )
  ]),
  options: PropTypes.arrayOf(optionShape).isRequired,
  labelText: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  containerStyle: styleProps,
  labelStyle: styleProps,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  showError: PropTypes.bool,
  error: PropTypes.string,
  helpText: PropTypes.string,
  hideLabel: PropTypes.bool,
  widthClass: PropTypes.string,
  showAddNewOption: PropTypes.bool,
  addNewOptionText: PropTypes.string,
  onAddNewOptionClick: PropTypes.func
};

export default MultiSelect;
