import Badge from 'components/Theme/Badge';
import statusImage from 'assets/images/uplaunch-failed-status-mascot.svg';
import Tooltip from 'components/Theme/Tooltip';
import React from 'react';
import PropTypes from 'prop-types';

export default function EmailUnsubscribedTooltip(props) {
  const { contact } = props;
  function unsubscribeText() {
    return contact?.under14 ? 'Unsubscribed: Child' : 'Unsubscribed';
  }

  return (
    <Tooltip
      id="email-unsubscribed-tooltip"
      place="right"
      classNames="tw-bg-white"
      TooltipElement={(id, _color) => (
        <span
          data-tip
          data-for={id}
          data-event="click focus"
          className="tw-cursor-pointer"
        >
          <Badge
            badgeStyle={{ marginTop: '0' }}
            color="error"
            badgeClass="tw-ml-1"
            hasDot={false}
            onClick={null}
            shape="round"
            size="small"
            textStyle={{}}
            value={unsubscribeText()}
            icon="exclamation-circle"
          />
        </span>
      )}
    >
      <div className="tw-grid tw-grid-cols-12">
        <div className="tw-col-span-6">
          <div className="tw-font-body tw-text-lg tw-leading-7 tw-font-medium tw-text-gray-900">
            Houston we have a problem!
          </div>
          <p className="tw-font-body tw-mb-0 tw-mt-2 tw-text-sm tw-leading-5 tw-text-gray-500">
            Contact has unsubscribed from email campaigns.
          </p>
          <p className="tw-font-body tw-mb-0 tw-mt-3 tw-text-sm tw-leading-5 tw-text-gray-500 tw-italic">
            Pro Tip: If this contact did not intend to unsubscribe, click the 3
            dots on ‘Contact Information’ and ‘Resubscribe’ to resubscribe them.
          </p>
        </div>
        <img
          src={statusImage}
          className="tw-col-span-6"
          alt="UpLaunch Communication Status"
        />
      </div>
    </Tooltip>
  );
}

const contactShape = {
  id: PropTypes.number,
  assignedStaff: PropTypes.string,
  email: PropTypes.string,
  subscribed: PropTypes.bool,
  formattedPhone: PropTypes.string,
  subscribedToSms: PropTypes.bool,
  fullAddress: PropTypes.string,
  birthday: PropTypes.string,
  gender: PropTypes.string,
  tshirtSize: PropTypes.string,
  referrerFullName: PropTypes.string,
  membershipStartedAt: PropTypes.string,
  initialCampaign: PropTypes.string,
  spamComplaint: PropTypes.bool,
  under14: PropTypes.bool
};

EmailUnsubscribedTooltip.propTypes = {
  contact: PropTypes.shape(contactShape).isRequired
};
