import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Styled from './Card.styles';

const Card = props => {
  const {
    children,
    title,
    subtitle,
    HeaderComponent,
    cardControls,
    containerStyle,
    headerStyle,
    bodyStyle
  } = props;
  const hasSlideUp = cardControls.includes('slide-up');
  const slideUpStyles = hasSlideUp ? { dispay: 'none' } : {};

  return (
    <Fragment>
      <div
        className={`col-12 ${hasSlideUp ? 'card-slided-up' : ''}`}
        style={containerStyle}
      >
        <Styled.Card className="card mb-0">
          <header className="card-header" style={headerStyle}>
            {hasSlideUp && (
              <ul className="card-controls align-content-start">
                <li>
                  <a className="card-btn-slide rotate-180" href="#" />
                </li>
              </ul>
            )}
            {HeaderComponent ? (
              <Fragment>
                {typeof HeaderComponent === 'function' ? (
                  HeaderComponent()
                ) : (
                  <HeaderComponent />
                )}
              </Fragment>
            ) : (
              <h4 className={`card-title ${hasSlideUp ? 'mr-auto' : ''}`}>
                <strong>{title}</strong>
                {subtitle && <small className="sidetitle">{subtitle}</small>}
              </h4>
            )}
          </header>
          <div className="card-content" style={slideUpStyles}>
            <div className="card-body" style={bodyStyle}>
              <div className="row">
                <div className="col-12">{children}</div>
              </div>
            </div>
          </div>
        </Styled.Card>
      </div>
    </Fragment>
  );
};

Card.defaultProps = {
  children: null,
  title: '',
  subtitle: '',
  HeaderComponent: null,
  cardControls: [],
  containerStyle: {},
  headerStyle: {},
  bodyStyle: {}
};

const styleTypes = PropTypes.oneOfType([PropTypes.string, PropTypes.number]);

Card.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  HeaderComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  cardControls: PropTypes.arrayOf(PropTypes.string),
  containerStyle: PropTypes.objectOf(styleTypes),
  headerStyle: PropTypes.objectOf(styleTypes),
  bodyStyle: PropTypes.objectOf(styleTypes)
};

export default Card;
