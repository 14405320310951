import React, { useEffect, useState } from 'react';
import MainWindow from 'components/SmsInbox/MainWindow';
import PropTypes from 'prop-types';
import Card from 'components/Theme/Card';
import MainWindowFooter from 'components/SmsInbox/MainWindow/Footer';
import useInfiniteScroll from 'components/shared/hooks/useInfiniteScroll';
import ActionCable from 'components/ActionCable';
import {
  createErrorMessageSelector,
  createLoadedSelector
} from 'appState/selectors';
import {
  smsInboxFetchConversation,
  smsInboxConversationPageChange,
  resetIsLoaded
} from 'appState/actions/ActionCreators';
import isEmpty from 'lodash.isempty';
import { createStructuredSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import { scrollToBottom } from 'lib/utils/scroll';

const SmsTab = ({ contact }) => {
  const dispatch = useDispatch();

  const [addedImage, setAddedImage] = useState(false);

  const actionTypeSelectorsConversation = [
    'smsInbox/SMS_INBOX_FETCH_CONVERSATION',
    'smsInbox/SMS_INBOX_CONVERSATION_PAGE_CHANGE'
  ];

  const conversationErrorSelector = createErrorMessageSelector(
    actionTypeSelectorsConversation
  );
  const conversationLoadedSelector = createLoadedSelector(
    actionTypeSelectorsConversation
  );
  const conversationFetchLoadedSelector = createLoadedSelector([
    'smsInbox/SMS_INBOX_FETCH_CONVERSATION'
  ]);
  const conversationFetchErrorSelector = createLoadedSelector([
    'smsInbox/SMS_INBOX_FETCH_CONVERSATION'
  ]);

  const structuredSelector = createStructuredSelector({
    currentConversation: state => state.smsInbox.currentConversation,
    conversationPage: state => state.smsInbox.conversationPage,
    conversationTotalPages: state => state.smsInbox.conversationTotalPages,
    conversationError: state => conversationErrorSelector(state),
    isConversationLoaded: state => conversationLoadedSelector(state),
    conversationFetchError: state => conversationFetchErrorSelector(state),
    isConversationFetchLoaded: state => conversationFetchLoadedSelector(state)
  });

  const {
    conversationPage,
    conversationTotalPages,
    isConversationLoaded,
    conversationFetchError,
    isConversationFetchLoaded,
    currentConversation
  } = useSelector(structuredSelector);

  useEffect(() => {
    if (isConversationFetchLoaded && isEmpty(conversationFetchError))
      scrollToBottom('chat-main-content');
  }, [isConversationFetchLoaded, conversationFetchError]);

  const [
    isFetchingConversation,
    setIsFetchingConversation,
    conversationLastScrollHeight
  ] = useInfiniteScroll(
    'chat-main-content',
    // eslint-disable-next-line no-use-before-define
    fetchMoreConversationMessages,
    true
  );

  const resetAllIsLoadedConversation = () => {
    actionTypeSelectorsConversation.forEach(type =>
      dispatch(resetIsLoaded(type))
    );
  };

  useEffect(() => {
    if (!isEmpty(contact)) {
      dispatch(
        smsInboxFetchConversation({
          contactId: contact.id
        })
      );
    }
  }, [contact, dispatch]);

  useEffect(() => {
    const hasMore = conversationPage < conversationTotalPages;

    if (!hasMore) return;

    // maintain scroll position when loading more messages in currentConversation
    const element = document.getElementById('chat-main-content');
    const newScrollHeight = element.scrollHeight;
    element.scrollTop = newScrollHeight - conversationLastScrollHeight - 100;

    if (isConversationLoaded) {
      setIsFetchingConversation(false);
      resetAllIsLoadedConversation();
    }
  }, [isConversationLoaded]);

  function fetchMoreConversationMessages() {
    const hasMore = conversationPage < conversationTotalPages;

    if (!hasMore) return;

    if (!isEmpty(currentConversation))
      dispatch(
        smsInboxConversationPageChange({
          contactId: contact.id,
          page: conversationPage + 1
        })
      );
  }

  function conversationWindow() {
    return (
      <Card
        innerContainerClass="tw--mx-6 tw--mb-3"
        footer={
          <MainWindowFooter contact={contact} setAddedImage={setAddedImage} />
        }
        bodyStyle={{
          backgroundColor: 'white',
          overflowY: 'scroll',
          height: `calc(100vh - ${addedImage ? '430' : '355'}px)`
        }}
        bodyScrollId="chat-main-content"
        containerClass="tw-shadow sm:tw-rounded-md"
        containerStyle={{ width: '100%' }}
      >
        {isFetchingConversation && !isConversationLoaded && (
          <div className="h-100px center-vh">
            <div className="spinner-circle spinner-info" />
          </div>
        )}
        <div>
          <MainWindow className="mt-20" contactId={contact.id} />
        </div>
      </Card>
    );
  }

  function handleReceivedSms() {
    dispatch(
      smsInboxFetchConversation({
        contactId: contact.id
      })
    );
  }

  function handleSocketConnected() {
    // eslint-disable-next-line no-console
    console.log('sms inbox api socket connected...');
  }

  return (
    <ActionCable
      channel={{ channel: 'SmsInboxApiChannel' }}
      onReceived={handleReceivedSms}
      onConnected={handleSocketConnected}
    >
      <div className="">
        <span className="md:tw-block tw-hidden">
          {conversationWindow(false)}
        </span>
        <span className="md:tw-hidden tw-block">
          {conversationWindow(true)}
        </span>
      </div>
    </ActionCable>
  );
};

SmsTab.defaultProps = { contact: {} };
const contactShape = {
  name: PropTypes.string
};
SmsTab.propTypes = {
  contact: PropTypes.shape(contactShape)
};

export default SmsTab;
