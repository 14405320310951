// linting rules disabled for immer js
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import { FETCH_BLOGS_SUCCESS } from 'appState/actions/constants/blog.actions';

const initialState = {
  blogTypes: []
};

export default function BlogReducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case FETCH_BLOGS_SUCCESS: {
        const { posts } = action.payload.activities;
        draft.blogTypes = posts;
        return;
      }
      default:
        return draft;
    }
  });
}
