import React from 'react';
import { action } from '@storybook/addon-actions';
import { withA11y } from '@storybook/addon-a11y';
import { withKnobs, text, select } from '@storybook/addon-knobs';
import Link from './index';

export default {
  component: Link,
  title: 'Link',
  decorators: [withA11y, withKnobs]
};

let colorSelect;

function includeColorSelect(defaultValue) {
  const label = 'Color';
  const options = [
    'alpha',
    'bravo',
    'charlie',
    'success',
    'warning',
    'error',
    'gray'
  ];
  colorSelect = select(label, options, defaultValue);
}

export const standard = () => {
  includeColorSelect('alpha');
  return (
    <Link
      text={text('Text', 'Learn more about our other product options')}
      onClick={action('link-click')}
      color={colorSelect}
    />
  );
};
