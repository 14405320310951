import React, { useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import Image from 'components/Theme/Image';
import TextInput from 'components/Theme/TextInput';
import Button from 'components/Theme/Button';
import { titleize } from 'lib/utils/string';
import { CheckInput } from 'components/ProductSubscriptionSetup/shared/FormInputs';
import { useFormik } from 'formik';
import { optInFormSchemaWithCheckbox } from 'lib/validation/schema';
import { CurrentAccountContext } from 'components/shared/context/CurrentAccountContext';

const FORM_ACTION_URL = 'new_lead_contacts';
const TERMS_OF_SERVICE_URL = 'https://www.uplaunch.com/terms-of-service';

const OptInForm = ({
  buttonText,
  accountLogo,
  title,
  privacyPolicyUrl,
  journeyId
}) => {
  const fileRef = useRef();
  const currentAccount = useContext(CurrentAccountContext);

  const contactProperty = {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    allow_contact: false,
    terms_conditions: false
  };

  const formik = useFormik({
    initialValues: {
      new_lead_contact: contactProperty
    },
    enableReinitialize: true,
    validationSchema: optInFormSchemaWithCheckbox,
    onSubmit: () => {
      fileRef.current.submit();
    }
  });

  function submitForm(e) {
    e.preventDefault();
    formik.submitForm();
  }

  function getAnchor() {
    return (
      /* eslint-disable react/jsx-no-target-blank */
      <span>
        <a target="_blank" className="text-info" href={privacyPolicyUrl}>
          Privacy Policy
        </a>
        and
        <a target="_blank" className="text-info" href={TERMS_OF_SERVICE_URL}>
          Terms of Service
        </a>
      </span>
      /* eslint-enable react/jsx-no-target-blank */
    );
  }

  function getComplianceLabel() {
    return `I grant ${titleize(currentAccount.name)}
      permission to contact me via email and/or SMS about offers and services.
      By selecting yes, message and data rates may apply. Message frequency varies.
      Reply HELP to receive help with SMS. You can opt out of SMS by responding
      STOP and out of emails by unsubscribing at the bottom of the email.`;
  }

  function getFormBody() {
    const formUrl = currentAccount.account_url + FORM_ACTION_URL;

    return (
      <form
        ref={fileRef}
        action={formUrl}
        method="post"
        name="search-theme-form"
        id="search-theme-form"
        onSubmit={submitForm}
      >
        <div className="tw-pt-2">
          <TextInput
            id="journey_id"
            name="journey_id"
            value={journeyId}
            type="hidden"
            hideLabel
          />
          <TextInput
            id="first_name"
            name="new_lead_contact[first_name]"
            onChange={formik.handleChange}
            value={formik.values.new_lead_contact.first_name}
            showError={
              formik.touched?.new_lead_contact?.first_name &&
              !!formik.errors?.new_lead_contact?.first_name
            }
            error={formik.errors?.new_lead_contact?.first_name}
            placeholder="First Name"
            type="text"
            hideLabel
            widthClass="tw-pt-4"
          />
          <TextInput
            id="new_lead_contact_last_name"
            name="new_lead_contact[last_name]"
            onChange={formik.handleChange}
            value={formik.values.new_lead_contact.last_name}
            showError={
              formik.touched?.new_lead_contact?.last_name &&
              !!formik.errors?.new_lead_contact?.last_name
            }
            error={formik.errors?.new_lead_contact?.last_name}
            placeholder="Last Name"
            type="text"
            hideLabel
            widthClass="tw-pt-4"
          />
          <TextInput
            id="new_lead_contact_email"
            name="new_lead_contact[email]"
            onChange={formik.handleChange}
            value={formik.values.new_lead_contact.email}
            showError={
              formik.touched?.new_lead_contact?.email &&
              !!formik.errors?.new_lead_contact?.email
            }
            error={formik.errors?.new_lead_contact?.email}
            placeholder="Email Address"
            type="text"
            hideLabel
            widthClass="tw-pt-4"
          />
          <TextInput
            id="new_lead_contact_phone"
            name="new_lead_contact[phone]"
            onChange={formik.handleChange}
            value={formik.values.new_lead_contact.phone}
            showError={
              formik.touched?.new_lead_contact?.phone &&
              !!formik.errors?.new_lead_contact?.phone
            }
            error={formik.errors?.new_lead_contact?.phone}
            placeholder="Phone Number"
            type="text"
            hideLabel
            widthClass="tw-pt-4"
          />
          <div className="tw-pt-6">
            <CheckInput
              name="new_lead_contact[allow_contact]"
              id="new_lead_contact_allow_contact"
              labelText={getComplianceLabel()}
              onChange={formik.handleChange}
              value={formik.values.new_lead_contact.allow_contact}
              checked={formik.values.new_lead_contact.allow_contact}
              showError={
                formik.touched?.new_lead_contact?.allow_contact &&
                !!formik.errors?.new_lead_contact?.allow_contact
              }
            />
            <CheckInput
              name="new_lead_contact[terms_conditions]"
              id="new_lead_contact_terms_conditions"
              labelText={
                <>
                  I agree to the {getAnchor()}, and understand that I can
                  opt-out and/or request deletion of my data at any time.
                </>
              }
              onChange={formik.handleChange}
              value={formik.values.new_lead_contact.terms_conditions}
              checked={formik.values.new_lead_contact.terms_conditions}
              showError={
                formik.touched?.new_lead_contact?.terms_conditions &&
                !!formik.errors?.new_lead_contact?.terms_conditions
              }
            />
          </div>

          <div className="tw-pt-8">
            <Button
              text={buttonText}
              color="gray"
              type="primary"
              size="md"
              actionType="submit"
            />
          </div>
        </div>
      </form>
    );
  }

  return (
    <div className="tw-flex tw-flex-col sm:tw-p-10 md:tw-p-20 tw-p-10">
      <div className="tw-p-8 tw-pl-0 tw-pt-0">
        <Image
          alt="Image"
          imageURL={accountLogo}
          className="tw-rounded-lg"
          maxHeight="100px"
          maxWidth="100px"
        />
      </div>
      <h1 className="tw-text-4xl tw-font-bold tw-text-gray-900">{title}</h1>
      <p className="tw-font-body md:tw-text-2xl sm:tw-text-sm  tw-leading-6 tw-text-gray-600 tw-text-justify">
        Get Started Today with a Free one-on-one visit, tour and wellness
        consulation. Simply fill out the form below and we&apos;ll get you on
        path to a healthier, stronger you.
      </p>
      {getFormBody()}
    </div>
  );
};

OptInForm.defaultProps = {
  buttonText: '',
  accountLogo: '',
  title: '',
  privacyPolicyUrl: '',
  journeyId: ''
};

OptInForm.propTypes = {
  buttonText: PropTypes.string,
  accountLogo: PropTypes.string,
  title: PropTypes.string,
  privacyPolicyUrl: PropTypes.string,
  journeyId: PropTypes.string
};
// eslint-disable-next-line
export const OptInLandingForm = React.memo(OptInForm);
