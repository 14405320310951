import styled from 'styled-components';

const MainContent = styled.div`
  height: calc(100vh - 170px);
`;

const Row = styled.div`
  padding-bottom: 80px;
`;

const Styled = {
  MainContent,
  Row
};

export default Styled;
