import React, {
  Fragment,
  useContext,
  useCallback,
  useEffect,
  useState,
  memo
} from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';
import { formatTimestamp } from 'lib/utils/dateTime';
import { CurrentUserContext } from 'components/shared/context/CurrentUserContext';
import Badge from 'components/Theme/Badge';
import Image from 'components/Theme/Image';
import ReactTooltip from 'react-tooltip';

function getNormalizedSmsType(smsType) {
  const transactionalTypes = [
    'client_transactional',
    'transactional',
    'uplaunch_action'
  ];
  const outboundTypes = [
    'campaign',
    'customized_campaign',
    'outbound',
    'staff'
  ];
  const bulkTypes = ['bulk', 'broadcast'];

  if (transactionalTypes.indexOf(smsType) !== -1) return 'transactional';
  if (outboundTypes.indexOf(smsType) !== -1) return 'outbound';
  if (bulkTypes.indexOf(smsType) !== -1) return 'broadcast';
  return smsType;
}

const SmsMessage = ({ sms, windowWidth }) => {
  const [imageDimensions, setImageDimensions] = useState({
    maxHeight: '400px',
    maxWidth: '400px'
  });
  const [smsBodyBgClass, setSmsBodyBgClass] = useState('');
  const smsType = getNormalizedSmsType(sms.smsType);
  const currentUser = useContext(CurrentUserContext);
  const userTimeZone = currentUser.account_time_zone;

  const getSmsBodyBgClass = useCallback(() => {
    if (smsType === 'inbound') setSmsBodyBgClass('tw-bg-gray-200');
    if (smsType === 'outbound') setSmsBodyBgClass('tw-bg-alpha-600');
    if (smsType === 'transactional') setSmsBodyBgClass('tw-bg-gray-400');
    if (smsType === 'broadcast') setSmsBodyBgClass('tw-bg-gray-600');
  }, [smsType]);

  function getSmsBodyBorderClass() {
    if (sms.mms && sms.left)
      return 'tw-rounded-l-full tw-rounded-tr-full tw-rounded-br tw-float-right';
    if (sms.mms)
      return 'tw-rounded-l-full tw-rounded-tr-full tw-rounded-br tw-text-white tw-float-right';
    if (sms.first && sms.last && sms.right)
      return 'tw-rounded-full tw-mb-1 tw-text-white tw-float-right tw-clear-both';
    if (sms.first && sms.last) return 'tw-rounded-full tw-mb-1';
    if (sms.first && sms.right)
      return 'tw-rounded-l-full tw-rounded-tr-full tw-rounded-br tw-text-white tw-float-right tw-mb-1';
    if (sms.first && sms.left)
      return 'tw-rounded-r-full tw-rounded-tl-full tw-rounded-bl tw-mb-1';
    if (sms.last && sms.right)
      return 'tw-rounded-l-full tw-rounded-br-full tw-rounded-tr tw-text-white tw-float-right';
    if (sms.last && sms.left)
      return 'tw-rounded-r-full tw-rounded-bl-full tw-rounded-tl';
    if (sms.right)
      return 'tw-rounded-l-full tw-rounded-r tw-text-white tw-float-right tw-mb-1';
    if (sms.left) return 'tw-rounded-r-full tw-rounded-l tw-mb-1';
    return '';
  }

  const getImageDimensions = useCallback(() => {
    if (windowWidth < 400) {
      setImageDimensions({ maxHeight: '350px', maxWidth: '350px' });
    }
    if (windowWidth < 350) {
      setImageDimensions({ maxHeight: '300px', maxWidth: '300px' });
    }
    if (windowWidth < 300) {
      setImageDimensions({ maxHeight: '250px', maxWidth: '250px' });
    }
    if (windowWidth < 250) {
      setImageDimensions({ maxHeight: '200px', maxWidth: '200px' });
    }
  }, [windowWidth]);

  useEffect(() => {
    // eslint-disable-next-line camelcase
    if (sms.media_urls?.[0]?.content_type?.includes('image')) {
      getImageDimensions();
    }
    getSmsBodyBgClass();
  }, [sms.media_urls, getImageDimensions, getSmsBodyBgClass]);

  return (
    <Fragment>
      {(sms.body || !isEmpty(sms.media_urls)) && (
        <div
          className={`tw-flex tw-px-2 sm:tw-px-8 ${sms.last ? 'tw-pb-5' : ''} ${
            sms.left ? '' : 'tw-flex-row-reverse'
          }`}
        >
          <div>
            {sms.body && (
              <Fragment>
                <p
                  className={`${smsBodyBgClass} ${getSmsBodyBorderClass()} tw-inline-block tw-mb-1 tw-px-6 tw-py-4 tw-text-sm tw-leading-4 tw-break-word`}
                  data-tip
                  data-for={`tooltip-${sms.id}`}
                >
                  {['broadcast', 'transactional'].includes(smsType) ? (
                    <span className="tw-mr-3">
                      <small>
                        <strong
                          className={
                            smsType === 'transactional' ? 'tw-text-white' : ''
                          }
                        >
                          {smsType && smsType.toUpperCase()} MESSAGE:
                        </strong>
                      </small>
                    </span>
                  ) : null}
                  {sms.body}
                </p>
              </Fragment>
            )}
            {!isEmpty(sms.media_urls) && (
              <span
                className={`tw-gap-1 tw-rounded-l-full ${
                  sms.last ? 'tw-rounded-br-full' : ''
                } tw-inline-block tw-pt-1 tw-clear-both tw-float-right`}
                data-provide="photoswipe"
              >
                {sms.media_urls.map(media => {
                  return (
                    <Fragment key={media.url}>
                      {media.content_type.includes('image') && (
                        <a
                          className={`col-${
                            sms.media_urls.count > 3
                              ? '4'
                              : 12 / sms.media_urls.count
                          } ${
                            sms.media_urls.count >= 2 ? '' : 'tw-inline-block'
                          }`}
                          href="#"
                        >
                          <Image
                            alt="MMS Attachment"
                            imageURL={media.url}
                            className="tw-rounded-l-lg tw-rounded-br-lg max-w-xs md:max-w-sm max-h-sm md:max-h-m"
                            maxHeight={imageDimensions.maxHeight}
                            maxWidth={imageDimensions.maxWidth}
                          />
                        </a>
                      )}
                      {media.content_type.includes('video') && (
                        // eslint-disable-next-line jsx-a11y/media-has-caption
                        <video controls width="320" height="240">
                          <source src={media.url} type="video/mp4" />
                        </video>
                      )}
                    </Fragment>
                  );
                })}
              </span>
            )}
            {sms.last ? (
              <p
                className={`tw-p-0 tw-text-gray-400 tw-mb-1 ${
                  smsType !== 'inbound' ? 'tw-clear-both tw-float-right' : ''
                }`}
              >
                <time dateTime="2017">
                  {sms.read_at === null && smsType === 'inbound' ? (
                    <Badge
                      badgeStyle={{}}
                      clickable={false}
                      color="alpha"
                      containerStyle={{ marginRight: '4px' }}
                      deletable={false}
                      hasDot={false}
                      onClick={null}
                      onDelete={null}
                      size="small"
                      textStyle={{}}
                      value="Unread"
                    />
                  ) : null}
                  <small>
                    {smsType === 'inbound' ? 'Received' : 'Sent'}{' '}
                    {formatTimestamp(sms.created_at, userTimeZone)}{' '}
                    {smsType === 'inbound' ? 'From' : 'By'}{' '}
                    <strong className="tw-text-gray-600">
                      {smsType === 'inbound'
                        ? `${sms.contact.first_name} ${sms.contact.last_name}`
                        : `${sms.user.first_name} ${sms.user.last_name}`}
                    </strong>
                  </small>{' '}
                  {sms.status ? ' - ' : null}
                  {sms.status}
                </time>
              </p>
            ) : (
              <ReactTooltip
                place={sms.left ? 'right' : 'left'}
                type="dark"
                effect="solid"
                id={`tooltip-${sms.id}`}
              >
                {sms.read_at === null && smsType === 'inbound' ? (
                  <Badge
                    badgeStyle={{}}
                    clickable={false}
                    color="alpha"
                    containerStyle={{ marginRight: '4px' }}
                    deletable={false}
                    hasDot={false}
                    onClick={null}
                    onDelete={null}
                    size="small"
                    textStyle={{}}
                    value="Unread"
                  />
                ) : null}
                <small className="tw-text-white">
                  {smsType === 'inbound' ? 'Received' : 'Sent'}{' '}
                  {formatTimestamp(sms.created_at, userTimeZone)}{' '}
                  {smsType === 'inbound' ? 'From' : 'By'}{' '}
                  {smsType === 'inbound'
                    ? `${sms.contact.first_name} ${sms.contact.last_name}`
                    : `${sms.user.first_name} ${sms.user.last_name}`}
                </small>{' '}
                {sms.status ? ' - ' : null}
                {sms.status}
              </ReactTooltip>
            )}
          </div>
        </div>
      )}
    </Fragment>
  );
};

const smsShape = {
  body: PropTypes.string,
  sms_type: PropTypes.string,
  media_urls: PropTypes.arrayOf(PropTypes.object),
  contact: PropTypes.object,
  user: PropTypes.object,
  created_at: PropTypes.string
};

SmsMessage.defaultProps = {
  windowWidth: 0
};

SmsMessage.propTypes = {
  sms: PropTypes.shape(smsShape).isRequired,
  windowWidth: PropTypes.number
};

export default memo(SmsMessage);
