import React from 'react';

const AudienceEmptyAlertModal = () => {
  return (
    <div
      className="modal fade show"
      id="audience-error-alert-modal"
      tabIndex="-1"
      aria-modal="true"
      aria-hidden="true"
      style={{ display: 'none' }}
    >
      <div className="modal-dialog modal-sm">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="myModalLabel">
              Broadcast Audience Error
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              id="dismiss-save-as-draft-modal"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <p>
              You must select at least one filter or have one manually selected
              contact through search.
            </p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AudienceEmptyAlertModal;
