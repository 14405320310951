import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { broadcastCallSaveEmailAction } from 'appState/actions/ActionCreators';

const EmailSubjectConfirmationModal = () => {
  const dispatch = useDispatch();

  const structuredSelector = createStructuredSelector({
    emailSubject: state => state.broadcastCenter.emailSubject
  });

  const { emailSubject } = useSelector(structuredSelector);

  function onClickSave() {
    dispatch(broadcastCallSaveEmailAction());
  }

  const [isEmailSubjectEmpty, setIsEmailSubjectEmpty] = useState(false);

  useEffect(() => {
    setIsEmailSubjectEmpty(emailSubject && emailSubject.trim() === '');
  }, [emailSubject]);

  return (
    <div
      className="modal fade show"
      id="email-subject-modal"
      tabIndex="-1"
      aria-modal="true"
      aria-hidden="true"
      style={{ display: 'none' }}
    >
      <div className="modal-dialog modal-sm">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="myModalLabel">
              Email Subject Confirmation
            </h4>
            <button type="button" className="close" data-dismiss="modal">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <p>
              {isEmailSubjectEmpty
                ? 'Your email subject is empty. Please add an email Subject.'
                : 'Looks like your email subject has not changed. Is this the email subject you want?'}
            </p>
          </div>
          <div className="modal-footer">
            {isEmailSubjectEmpty ? (
              <button
                type="button"
                className="btn btn-info"
                data-dismiss="modal"
              >
                OK
              </button>
            ) : (
              <Fragment>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  data-dismiss="modal"
                  onClick={onClickSave}
                >
                  Looks Good
                </button>
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailSubjectConfirmationModal;
