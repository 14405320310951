export const FETCH_PRODUCT = 'product/FETCH_PRODUCT';
export const FETCH_PRODUCT_REQUEST = `${FETCH_PRODUCT}_REQUEST`;
export const FETCH_PRODUCT_SUCCESS = `${FETCH_PRODUCT}_SUCCESS`;
export const FETCH_PRODUCT_FAILURE = `${FETCH_PRODUCT}_FAILURE`;

export const CREATE_PRODUCT = 'product/CREATE_PRODUCT';
export const CREATE_PRODUCT_REQUEST = `${CREATE_PRODUCT}_REQUEST`;
export const CREATE_PRODUCT_SUCCESS = `${CREATE_PRODUCT}_SUCCESS`;
export const CREATE_PRODUCT_FAILURE = `${CREATE_PRODUCT}_FAILURE`;

export const UPDATE_PRODUCT = 'product/UPDATE_PRODUCT';
export const UPDATE_PRODUCT_REQUEST = `${UPDATE_PRODUCT}_REQUEST`;
export const UPDATE_PRODUCT_SUCCESS = `${UPDATE_PRODUCT}_SUCCESS`;
export const UPDATE_PRODUCT_FAILURE = `${UPDATE_PRODUCT}_FAILURE`;

export const FETCH_MY_PRODUCTS = 'product/FETCH_MY_PRODUCTS';
export const FETCH_MY_PRODUCTS_REQUEST = `${FETCH_MY_PRODUCTS}_REQUEST`;
export const FETCH_MY_PRODUCTS_SUCCESS = `${FETCH_MY_PRODUCTS}_SUCCESS`;
export const FETCH_MY_PRODUCTS_FAILURE = `${FETCH_MY_PRODUCTS}_FAILURE`;

export const FETCH_INCLUDED_FORM = 'product/FETCH_INCLUDED_FORM';
export const FETCH_INCLUDED_FORM_REQUEST = `${FETCH_INCLUDED_FORM}_REQUEST`;
export const FETCH_INCLUDED_FORM_SUCCESS = `${FETCH_INCLUDED_FORM}_SUCCESS`;
export const FETCH_INCLUDED_FORM_FAILURE = `${FETCH_INCLUDED_FORM}_FAILURE`;

export const CREATE_INCLUDED_FORM = 'product/CREATE_INCLUDED_FORM';
export const CREATE_INCLUDED_FORM_REQUEST = `${CREATE_INCLUDED_FORM}_REQUEST`;
export const CREATE_INCLUDED_FORM_SUCCESS = `${CREATE_INCLUDED_FORM}_SUCCESS`;
export const CREATE_INCLUDED_FORM_FAILURE = `${CREATE_INCLUDED_FORM}_FAILURE`;

export const UPDATE_INCLUDED_FORM = 'product/UPDATE_INCLUDED_FORM';
export const UPDATE_INCLUDED_FORM_REQUEST = `${UPDATE_INCLUDED_FORM}_REQUEST`;
export const UPDATE_INCLUDED_FORM_SUCCESS = `${UPDATE_INCLUDED_FORM}_SUCCESS`;
export const UPDATE_INCLUDED_FORM_FAILURE = `${UPDATE_INCLUDED_FORM}_FAILURE`;

export const FETCH_STAFF_SMS_MERGE_FIELDS =
  'product/FETCH_STAFF_SMS_MERGE_FIELDS';
export const FETCH_STAFF_SMS_MERGE_FIELDS_REQUEST = `${FETCH_STAFF_SMS_MERGE_FIELDS}_REQUEST`;
export const FETCH_STAFF_SMS_MERGE_FIELDS_SUCCESS = `${FETCH_STAFF_SMS_MERGE_FIELDS}_SUCCESS`;
export const FETCH_STAFF_SMS_MERGE_FIELDS_FAILURE = `${FETCH_INCLUDED_FORM}_FAILURE`;

export const FETCH_STAFF_EMAIL_MERGE_FIELDS =
  'product/FETCH_STAFF_EMAIL_MERGE_FIELDS';
export const FETCH_STAFF_EMAIL_MERGE_FIELDS_REQUEST = `${FETCH_STAFF_EMAIL_MERGE_FIELDS}_REQUEST`;
export const FETCH_STAFF_EMAIL_MERGE_FIELDS_SUCCESS = `${FETCH_STAFF_EMAIL_MERGE_FIELDS}_SUCCESS`;
export const FETCH_STAFF_EMAIL_MERGE_FIELDS_FAILURE = `${FETCH_INCLUDED_FORM}_FAILURE`;

export const DELETE_PRODUCT = 'product/DELETE_PRODUCT';
export const DELETE_PRODUCT_REQUEST = `${DELETE_PRODUCT}_REQUEST`;
export const DELETE_PRODUCT_SUCCESS = `${DELETE_PRODUCT}_SUCCESS`;
export const DELETE_PRODUCT_FAILURE = `${DELETE_PRODUCT}_FAILURE`;

export const TOGGLE_PUBLISH_PRODUCT = 'partner/TOGGLE_PUBLISH_PRODUCT';
export const TOGGLE_PUBLISH_PRODUCT_REQUEST = `${TOGGLE_PUBLISH_PRODUCT}_REQUEST`;
export const TOGGLE_PUBLISH_PRODUCT_SUCCESS = `${TOGGLE_PUBLISH_PRODUCT}_SUCCESS`;
export const TOGGLE_PUBLISH_PRODUCT_FAILURE = `${TOGGLE_PUBLISH_PRODUCT}_FAILURE`;

export const FETCH_PRODUCTS_CATEGORIES = 'product/FETCH_PRODUCTS_CATEGORIES';
export const FETCH_PRODUCTS_CATEGORIES_REQUEST = `${FETCH_PRODUCTS_CATEGORIES}_REQUEST`;
export const FETCH_PRODUCTS_CATEGORIES_SUCCESS = `${FETCH_PRODUCTS_CATEGORIES}_SUCCESS`;
export const FETCH_PRODUCTS_CATEGORIES_FAILURE = `${FETCH_PRODUCTS_CATEGORIES}_FAILURE`;
