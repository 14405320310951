import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { CurrentAccountContext } from 'components/shared/context/CurrentAccountContext';

const useAccountDisabled = () => {
  const currentAccount = useContext(CurrentAccountContext);
  const [accountDisabled, setAccountDisabled] = useState(
    !!currentAccount.disabled_at
  );
  const structuredSelector = createStructuredSelector({
    isAccountDisabled: state => state.overlordUtilities.isAccountDisabled
  });

  const { isAccountDisabled } = useSelector(structuredSelector);

  useEffect(() => {
    if (isAccountDisabled !== null && accountDisabled !== isAccountDisabled)
      setAccountDisabled(isAccountDisabled);
  }, [accountDisabled, isAccountDisabled]);

  return accountDisabled;
};

export default useAccountDisabled;
