import React, { useContext, Fragment } from 'react';
import PropTypes from 'prop-types';
import Badge from 'components/Theme/Badge';
import Card from 'components/Theme/Card';
import { CurrentAccountContext } from 'components/shared/context/CurrentAccountContext';
import { formatTimestamp } from 'lib/utils/dateTime';
import ButtonWithDropdown from 'components/Theme/ButtonWithDropdown';
import { titleize, toSentence, textTruncate } from 'lib/utils/string';
import isEmpty from 'lodash.isempty';
import Tooltip from 'components/Theme/Tooltip';

const ProductSubscriptionCard = ({
  productSubscription,
  showAction,
  onActionClick,
  showFullDetail,
  showCheckbox,
  onCheckboxChange,
  checked,
  showBadge,
  badgeText,
  handleBtnDisableVal
}) => {
  const currentAccount = useContext(CurrentAccountContext);
  const { product } = productSubscription;
  const handleCheckboxClick = e => {
    onCheckboxChange(product, e.target.checked);
  };
  function handleButtonGroupSelect(val) {
    onActionClick(productSubscription, val);
  }
  const imageUrl = product.hero_image_url
    ? product.hero_image_url
    : 'https://ucarecdn.com/18692cee-646a-473a-aa6f-8c961389a289/';

  function actionOptions() {
    if (productSubscription && productSubscription.setup_at) {
      const opts = [
        { displayName: 'Pause', value: 'pause campaigns' },
        { displayName: 'Add Contacts', value: 'add-contacts' },
        { displayName: 'View Contacts', value: 'view-contacts' },
        { displayName: 'Customize Campaigns', value: 'customize-campaigns' },
        { displayName: 'Instructions', value: 'instructions' },
        { displayName: 'Remove', value: 'remove' }
      ];
      if (!product.lifecycles.includes('lead')) {
        opts.splice(4, 1);
      }
      if (productSubscription.campaigns_paused_at) {
        opts.splice(0, 1, {
          displayName: 'Unpause',
          value: 'unpause campaigns'
        });
      }
      if (productSubscription.processing_add_contacts_at) {
        opts.splice(1, 2, {
          displayName: 'Adding Contacts',
          value: null,
          disabled: true
        });
      }
      return opts;
    }
    return [{ displayName: 'Remove', value: 'remove' }];
  }

  function showProductName(name) {
    if (name.length > 50) {
      return (
        <Tooltip
          id={name}
          classNames="tw-bg-white"
          TooltipElement={() => {
            return (
              <h3
                data-tip
                data-for={name}
                className="tw-font-body tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900"
              >
                {textTruncate(name, 50)}
              </h3>
            );
          }}
        >
          <p className="tw-text-gray-500 tw-mb-0">{name}</p>
        </Tooltip>
      );
    }

    return (
      <h3 className="tw-font-body tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
        {name}
      </h3>
    );
  }

  return (
    <Card
      key={product.id}
      color="alpha"
      containerClass="tw-bg-white tw-rounded-lg tw-mr-4 tw-mb-4 xxs:tw-col-span-12 sm:tw-col-span-5 md:tw-col-span-4 lg:tw-col-span-3 xxl:tw-col-span-2"
      customMarginContainer
      imageUrl={imageUrl}
      imageClass="tw-rounded-t-lg xs:tw-object-cover tw-w-full xs:tw-h-60 sm:tw-h-60 lg:tw-h-40 xl:tw-h-48"
      headerClass="tw-mb-10"
      showBadge={showBadge}
      badgeContent={badgeText}
    >
      <div
        className="tw-flex tw-flex-col tw-justify-between"
        style={{ height: '350px' }}
      >
        <div className="tw-m-5 tw-h-28">
          <div className="tw-my-2">
            {!isEmpty(product.lifecycles) ? (
              <Badge
                shape="rectangle"
                size="small"
                value={toSentence(
                  product.lifecycles.map(l => titleize(l, '_')),
                  '&'
                )}
                containerStyle={{
                  textTransform: 'uppercase'
                }}
              />
            ) : (
              <div className="tw-h-6" />
            )}
          </div>
          {showProductName(product.name)}
          <p className="tw-font-body tw-mt-1 tw-mb-0 tw-text-sm tw-leading-5 tw-text-gray-500">
            {product.campaign_count}
          </p>
          {productSubscription.campaigns_paused_at ? (
            <div className="tw-my-2">
              <Badge
                shape="rectangle"
                size="small"
                value={
                  productSubscription.campaigns_resume_at
                    ? `Paused Until: ${formatTimestamp(
                        productSubscription.campaigns_resume_at,
                        currentAccount.timezone,
                        false,
                        'MMMM Do',
                        true
                      )}`
                    : 'Paused Indefinitely'
                }
                containerStyle={{
                  textTransform: 'uppercase'
                }}
                color="warning"
              />
            </div>
          ) : null}
        </div>
        {showCheckbox && (
          <Fragment>
            <div className="tw-ml-4">
              <input
                type="checkbox"
                className="checked:tw-bg-blue-600 checked:tw-border-transparent"
                onClick={event => handleCheckboxClick(event)}
                checked={checked}
                disabled={!checked && !handleBtnDisableVal}
              />
            </div>
          </Fragment>
        )}
        {showFullDetail && (
          <div>
            <div className="tw-m-5">
              <div>
                <div className="tw-font-medium">
                  Added:{' '}
                  {formatTimestamp(
                    productSubscription.created_at,
                    currentAccount.timezone,
                    false,
                    'MM/DD/YYYY',
                    true
                  )}
                </div>
                <div>
                  {productSubscription.setup_at ? (
                    <Fragment>
                      <div className="tw-font-medium">
                        Setup:{' '}
                        {formatTimestamp(
                          productSubscription.setup_at,
                          currentAccount.timezone,
                          false,
                          'MM/DD/YYYY',
                          true
                        )}
                      </div>
                      <div className="tw-text-gray-500">
                        {productSubscription.contacts_in_campaign_text ? (
                          `${productSubscription.contacts_in_campaign_text} in campaigns`
                        ) : (
                          <div className="tw-h-6" />
                        )}
                      </div>
                    </Fragment>
                  ) : (
                    <div className="tw-h-12" />
                  )}
                </div>
              </div>
              {showAction && (
                <div className="tw-mt-4">
                  <ButtonWithDropdown
                    buttonText={
                      productSubscription.setup_at ? 'Edit Settings' : 'Setup'
                    }
                    value="setup"
                    options={actionOptions()}
                    type="white"
                    customMargin
                    showSvg
                    onClick={handleButtonGroupSelect}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </Card>
  );
};

ProductSubscriptionCard.defaultProps = {
  productSubscription: {},
  showAction: true,
  onActionClick: () => null,
  showFullDetail: true,
  showCheckbox: false,
  onCheckboxChange: () => null,
  checked: false,
  handleBtnDisableVal: false,
  showBadge: false,
  badgeText: ''
};

ProductSubscriptionCard.propTypes = {
  productSubscription: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object])
  ),
  showAction: PropTypes.bool,
  onActionClick: PropTypes.func,
  showFullDetail: PropTypes.bool,
  showCheckbox: PropTypes.bool,
  checked: PropTypes.bool,
  onCheckboxChange: PropTypes.func,
  handleBtnDisableVal: PropTypes.bool,
  showBadge: PropTypes.bool,
  badgeText: PropTypes.string
};

export default ProductSubscriptionCard;
