import React from 'react';
import PropTypes from 'prop-types';
import HeroIcon from 'components/Theme/HeroIcon';

const MultiSelectCards = ({
  options,
  color,
  isSelectable,
  canSelectMore,
  onChange,
  values
}) => {
  function isSelected(value) {
    return values.indexOf(value) > -1;
  }

  function onSelect(value) {
    if (isSelectable) {
      const array = [...values];
      if (isSelected(value)) {
        array.splice(array.indexOf(value), 1);
      } else if (canSelectMore) {
        array.push(value);
      }
      onChange(array);
    }
  }

  return (
    <fieldset>
      <div>
        {options.map(option => {
          return (
            <div
              key={`${option.value}-${isSelected(option.value)}`}
              data-testid={option.title}
              className={`tw-group tw-relative tw-bg-white tw-rounded-lg tw-shadow-sm ${
                isSelectable ? 'tw-cursor-pointer' : 'tw-cursor-not-allowed'
              } tw-mb-3`}
              onClick={() => onSelect(option.value)}
              onKeyUp={() => onSelect(option.value)}
              role="button"
              tabIndex={-1}
            >
              <div
                className={`tw-rounded-lg tw-border-${
                  isSelected(option.value) ? `${color}-base` : 'gray-300'
                } ${
                  isSelected(option.value) ? 'tw-bg-alpha-100' : ''
                } tw-border-solid tw-border-2 tw-bg-white tw-px-6 tw-py-4 tw-flex tw-justify-between`}
              >
                <div className="tw-flex tw-items-center">
                  {isSelected(option.value) && (
                    <div className="flex-shrink-0 tw-h-5 tw-w-5 tw-mr-5">
                      <HeroIcon icon="check-circle" />
                    </div>
                  )}
                  <div className="tw-flex-1 tw-flex tw-items-center">
                    <div className="tw-text-sm">
                      <span className="tw-font-medium tw-text-gray-900">
                        {option.title}
                      </span>
                      <div className="tw-text-gray-500">
                        <span className="sm:tw-inline">{option.subtitle}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tw-mt-2 tw-flex tw-text-sm sm:tw-mt-0 sm:tw-block sm:tw-ml-4 sm:tw-text-right">
                  {option.rightText}
                </div>
              </div>
              <div
                className="tw-border-indigo-500 tw-absolute tw-inset-0 tw-rounded-lg tw-border-2 tw-pointer-events-none"
                aria-hidden="true"
              />
            </div>
          );
        })}
      </div>
    </fieldset>
  );
};

MultiSelectCards.defaultProps = {
  color: 'alpha',
  isSelectable: true,
  values: [],
  canSelectMore: true
};

const optionShape = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  rightText: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

MultiSelectCards.propTypes = {
  options: PropTypes.arrayOf(optionShape).isRequired,
  values: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  ),
  color: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isSelectable: PropTypes.bool,
  canSelectMore: PropTypes.bool
};

export default MultiSelectCards;
