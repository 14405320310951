import React from 'react';
import PropTypes from 'prop-types';

const Progress = ({
  valueMin,
  valueMax,
  valueNow,
  color,
  text,
  textClass,
  containerClass
}) => {
  function percentComplete() {
    return (valueNow / valueMax) * 100;
  }

  return (
    <div
      className={`tw-rounded-md tw-overflow-hidden tw-flex tw-relative tw-border tw-border-solid tw-border-gray-300 ${containerClass}`}
    >
      <div
        className={`tw-bg-${color}-300 tw-flex tw-flex-col tw-justify-center`}
        role="progressbar"
        style={{ width: `${percentComplete()}%` }}
        aria-valuenow={valueNow}
        aria-valuemin={valueMin}
        aria-valuemax={valueMax}
      />
      {text ? (
        <span
          className={`${textClass}`}
          style={{ position: 'absolute', left: '10px' }}
        >{`${valueNow} / ${valueMax} ${text}`}</span>
      ) : null}
    </div>
  );
};

Progress.defaultProps = {
  valueMin: 0,
  valueMax: 100,
  valueNow: 0,
  color: 'alpha',
  text: '',
  textClass: 'tw-text-gray-800',
  containerClass: 'tw-h-6 tw-bg-gray-100 tw-mb-4'
};

Progress.propTypes = {
  valueMin: PropTypes.number,
  valueMax: PropTypes.number,
  valueNow: PropTypes.number,
  color: PropTypes.string,
  text: PropTypes.string,
  textClass: PropTypes.string,
  containerClass: PropTypes.string
};

export default Progress;
