/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useMemo } from 'react';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash.isempty';
import {
  fetchAccountCampaigns,
  fetchZenPlannerProgram,
  fetchSelectedLinkedZpPrograms,
  createSyncZpPlannerWithProduct,
  flashSuccessMessage,
  flashErrorMessage
} from 'appState/actions/ActionCreators';
import {
  createErrorMessageSelector,
  createLoadedSelector
} from 'app-state/selectors';
import { CREATE_SYNC_ZP_PLANNER_WITH_PRODUCT } from 'app-state/actions/constants/zenplannersync.actions';
import MultiSelect from 'components/Theme/MultiSelect';
import Card from 'components/Theme/Card';
import ZenPlannerDetail from './ZenPlannerDetail';

const ZenSyncPlanner = ({ customClass, customClassForCard }) => {
  const dispatch = useDispatch();
  const errorSelector = createErrorMessageSelector([
    CREATE_SYNC_ZP_PLANNER_WITH_PRODUCT
  ]);
  const createSyncZpPlannerLoadedSelector = createLoadedSelector([
    CREATE_SYNC_ZP_PLANNER_WITH_PRODUCT
  ]);

  const structuredSelector = createStructuredSelector({
    automationCampaigns: state => state.contact.automationCampaigns,
    zenPlannerPrograms: state => state.zenPlannerSync.zenPlannerPrograms,
    selectedPrograms: state => state.zenPlannerSync.selectedPrograms,
    linkedZpPrograms: state => state.zenPlannerSync.linkedZpPrograms,
    isCreatedSyncZpPlannerLoaded: state =>
      createSyncZpPlannerLoadedSelector(state),
    error: state => errorSelector(state)
  });

  const {
    zenPlannerPrograms,
    selectedPrograms,
    linkedZpPrograms,
    automationCampaigns,
    isCreatedSyncZpPlannerLoaded,
    error
  } = useSelector(structuredSelector);

  const formik = useFormik({
    initialValues: {
      zenPlannerSelectedPrograms: selectedPrograms || [],
      uplaunchProducts: linkedZpPrograms || []
    },
    enableReinitialize: true,
    onSubmit: () => {
      const { zenPlannerSelectedPrograms, uplaunchProducts } = formik.values;
      const inValidItems = uplaunchProducts.filter(u => u.isValid === false);
      if (inValidItems.length > 0) {
        dispatch(flashErrorMessage('Please fill mandatory fields'));
        return;
      }
      dispatch(
        createSyncZpPlannerWithProduct({
          selectedPrograms: zenPlannerSelectedPrograms,
          linkedZpPrograms: uplaunchProducts
        })
      );
    }
  });

  useEffect(() => {
    if (isEmpty(automationCampaigns)) {
      dispatch(fetchAccountCampaigns());
      dispatch(fetchZenPlannerProgram());
      dispatch(fetchSelectedLinkedZpPrograms());
    }
  }, [dispatch, automationCampaigns]);

  useEffect(() => {
    if (isCreatedSyncZpPlannerLoaded && isEmpty(error)) {
      dispatch(
        flashSuccessMessage(
          'Zen Planner Sync with Uplaunch Product saved successfully.'
        )
      );
      window.location = '/account/zen_planner_settings';
    }
  }, [dispatch, isCreatedSyncZpPlannerLoaded, error]);

  const campaignSelectOptions = useMemo(() => {
    return automationCampaigns.map(campaign => {
      return {
        displayName: campaign.name,
        value: campaign.id,
        productName: campaign.product_name,
        productId: campaign.product_id,
        lifeCycle: campaign.lifecycle
      };
    });
  }, [automationCampaigns]);

  const handleOnZenChange = value => {
    formik.setFieldValue('zenPlannerSelectedPrograms', value);
  };

  const getZenPlannerHeader = programs => {
    if (isEmpty(programs)) return;
    return (
      <div className="tw-grid tw-grid-flow-col tw-auto-cols-max tw-font-bold tw-px-1 tw-py-2">
        <div className="">Zen Planner Program + Status</div>
        <div className="">Uplaunch Product</div>
        <div className="">Uplaunch Campaign</div>
      </div>
    );
  };

  const getZenDetail = programs => {
    if (isEmpty(programs)) return;

    return programs.map(id => {
      const program = zenPlannerPrograms.find(obj => id === obj.id);
      if (program)
        return (
          <ZenPlannerDetail
            programName={program.name}
            key={id}
            formik={formik}
            id={id}
            campaignSelectOptions={campaignSelectOptions}
          />
        );
      return [];
    });
  };

  const getZenProgramOptions = () => {
    return zenPlannerPrograms.map(z => {
      return {
        displayName: z.name,
        value: z.id
      };
    });
  };
  const { zenPlannerSelectedPrograms, uplaunchProducts } = formik.values;

  return (
    <div className={customClass}>
      <Card
        hasDetachedActions
        showPrimaryAction
        primaryActionText="Save"
        primaryAction={formik.handleSubmit}
        containerClass={customClassForCard}
        disablePrimaryAction={uplaunchProducts < 1}
      >
        <div className="sm:tw-px-7 lg:tw-px-10 tw-mt-6">
          <h2 className="tw-font-body tw-text-2xl tw-font-bold tw-leading-7 tw-text-gray-700 sm:tw-text-3xl sm:tw-leading-9 sm:tw-truncate">
            Linking Uplaunch Products to Zen Planner Programs
          </h2>
          <p className="tw-text-sm tw-py-2">
            Below you will see the Zen Programs you've previously selected in
            the left column. Map each Program's status to an Uplaunch Product in
            the middle column. In the right column, select the specific Campaign
            from that Product to be linked to the status on the left. Selecting
            a product for each Program's status is optional. However, you cannot
            select a campaign until you've selected a product and if you do
            select a product, you must select a campaign.
          </p>
          <MultiSelect
            key="zen-planner-programs"
            id="zen-planner-programs"
            value={formik.values.zenPlannerSelectedPrograms}
            options={getZenProgramOptions()}
            labelText="Zen Planner Program"
            onChange={value => handleOnZenChange(value)}
            widthClass="tw-flex tw-flex-col tw-items-center tw-col-span-12 sm:tw-col-span-6 sm:tw-px-1 lg:tw-px-2 tw-mb-8"
          />
          {getZenPlannerHeader(zenPlannerSelectedPrograms)}
          {getZenDetail(zenPlannerSelectedPrograms)}
        </div>
      </Card>
    </div>
  );
};

ZenSyncPlanner.defaultProps = {
  customClass: '',
  customClassForCard: ''
};

ZenSyncPlanner.propTypes = {
  customClass: PropTypes.string,
  customClassForCard: PropTypes.string
};
export default ZenSyncPlanner;
