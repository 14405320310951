// linting rules disabled for immer js
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  FETCH_PARTNERS_SUCCESS,
  FETCH_PARTNER_SUCCESS
} from 'appState/actions/constants/partner.actions';
import {
  DELETE_PRODUCT_SUCCESS,
  TOGGLE_PUBLISH_PRODUCT_SUCCESS
} from 'app-state/actions/constants/product.actions';

const initialState = {
  partners: [],
  currentPartner: {},
  products: []
};

export default function PartnerReducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case FETCH_PARTNERS_SUCCESS: {
        const { partners } = action.payload;
        draft.partners = partners;
        return;
      }
      case FETCH_PARTNER_SUCCESS: {
        const { currentPartner, products } = action.payload;
        draft.currentPartner = currentPartner;
        draft.products = products;
        return;
      }
      case DELETE_PRODUCT_SUCCESS: {
        const { currentProductId } = action.payload;
        draft.products = draft.products.filter(
          product => product.id !== currentProductId
        );
        return;
      }
      case TOGGLE_PUBLISH_PRODUCT_SUCCESS: {
        const { product } = action.payload;
        const { products } = draft;
        const index = products.findIndex(pr => pr.id === product.id);
        products[index].published = product.published;
        draft.products = products;
        return;
      }
      default:
        return draft;
    }
  });
}
