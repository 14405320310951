import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { CurrentUserContext } from 'components/shared/context/CurrentUserContext';
import { nameInitials, textTruncate } from 'lib/utils/string';
import { formatTimestamp } from 'lib/utils/dateTime';
import { smsInboxFetchConversation } from 'appState/actions/ActionCreators';

const MessageListItem = props => {
  const dispatch = useDispatch();
  const { conversation, selectConversation, selectedConversationId } = props;
  const unsubscribedConversation = !conversation.subscribed_to_sms;
  const hasUnreadMessages = conversation.unread_count > 0;
  const currentUser = useContext(CurrentUserContext);
  const userTimeZone = currentUser.account_time_zone;

  function handleMessageListItemClick() {
    selectConversation(conversation.id);
    dispatch(smsInboxFetchConversation({ contactId: conversation.id }));
  }

  return (
    <Fragment>
      <a
        href="#"
        onClick={handleMessageListItemClick}
        className={`media align-items-center contact-list-full-chat ${
          selectedConversationId === conversation.id ? 'active' : ''
        }`}
      >
        <span
          className={`avatar ${
            conversation.unread_count > 0 ? 'status-info' : null
          }`}
        >
          {nameInitials(conversation.name)}
        </span>
        <div className="media-body">
          <div className="flexbox align-items-center">
            <strong
              className={
                unsubscribedConversation ? 'text-unsubscribed' : 'title'
              }
            >
              {textTruncate(conversation.name, 18)}
              {unsubscribedConversation ? (
                <span className="badge badge-sm badge-danger ml-10 float-right mt-1">
                  U
                </span>
              ) : null}
            </strong>
            <span>
              <time
                dateTime="2017-07-14 20:00"
                className={
                  unsubscribedConversation
                    ? 'text-unsubscribed'
                    : 'last-message-timestamp'
                }
                style={{ marginRight: '5px' }}
              >
                {formatTimestamp(
                  conversation.last_message_timestamp,
                  userTimeZone,
                  false,
                  'h:mm',
                  true
                )}
              </time>

              {hasUnreadMessages ? (
                <span
                  className="badge badge-pill badge-info"
                  data-provide="tooltip"
                  title={`${conversation.unread_count} unread messages`}
                >
                  {conversation.unread_count}
                </span>
              ) : null}
            </span>
          </div>
          <p
            className={`text-truncate ${
              unsubscribedConversation
                ? 'text-unsubscribed'
                : 'last-message-text'
            }`}
          >
            {conversation.last_message
              ? conversation.last_message
              : conversation.media_count}
          </p>
        </div>
      </a>
    </Fragment>
  );
};

const conversationShape = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string,
  assigned_staff_id: PropTypes.number,
  media_count: PropTypes.number,
  last_message: PropTypes.string,
  last_message_timestamp: PropTypes.string,
  unread_count: PropTypes.number,
  subscribed: PropTypes.bool,
  subscribed_to_sms: PropTypes.bool,
  phone_number_valid_for_sms: PropTypes.bool
};

MessageListItem.propTypes = {
  conversation: PropTypes.shape(conversationShape).isRequired,
  selectConversation: PropTypes.func.isRequired,
  selectedConversationId: PropTypes.number.isRequired
};

export default MessageListItem;
