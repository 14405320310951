import { call, put, takeLatest } from 'redux-saga/effects';
import buildFormData from 'lib/utils/buildFormData';
import { axiosDefault, axiosMultipartForm } from 'lib/utils/axios-setup';
import {
  FETCH_PRODUCT_REQUEST,
  FETCH_PRODUCT_SUCCESS,
  FETCH_PRODUCT_FAILURE,
  FETCH_STAFF_SMS_MERGE_FIELDS_REQUEST,
  FETCH_STAFF_SMS_MERGE_FIELDS_SUCCESS,
  FETCH_STAFF_SMS_MERGE_FIELDS_FAILURE,
  FETCH_STAFF_EMAIL_MERGE_FIELDS_REQUEST,
  FETCH_STAFF_EMAIL_MERGE_FIELDS_SUCCESS,
  FETCH_STAFF_EMAIL_MERGE_FIELDS_FAILURE,
  FETCH_INCLUDED_FORM_REQUEST,
  FETCH_INCLUDED_FORM_SUCCESS,
  FETCH_INCLUDED_FORM_FAILURE,
  CREATE_INCLUDED_FORM_REQUEST,
  CREATE_INCLUDED_FORM_SUCCESS,
  CREATE_INCLUDED_FORM_FAILURE,
  UPDATE_INCLUDED_FORM_REQUEST,
  UPDATE_INCLUDED_FORM_SUCCESS,
  UPDATE_INCLUDED_FORM_FAILURE,
  CREATE_PRODUCT_REQUEST,
  CREATE_PRODUCT_SUCCESS,
  CREATE_PRODUCT_FAILURE,
  UPDATE_PRODUCT_REQUEST,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAILURE,
  DELETE_PRODUCT_REQUEST,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAILURE,
  TOGGLE_PUBLISH_PRODUCT_REQUEST,
  TOGGLE_PUBLISH_PRODUCT_SUCCESS,
  TOGGLE_PUBLISH_PRODUCT_FAILURE,
  FETCH_PRODUCTS_CATEGORIES_REQUEST,
  FETCH_PRODUCTS_CATEGORIES_SUCCESS,
  FETCH_PRODUCTS_CATEGORIES_FAILURE
} from 'appState/actions/constants/product.actions';

function* fetchProduct({ payload: { productId } }) {
  try {
    const response = yield call(axiosDefault.get, `/products/${productId}`);

    yield put({
      type: FETCH_PRODUCT_SUCCESS,
      payload: {
        currentProduct: response.data
      }
    });
  } catch (e) {
    yield put({
      type: FETCH_PRODUCT_FAILURE,
      payload: {
        error: e
      },
      error: true
    });
  }
}

function* fetchIncludedForm({ payload: { includedFormId } }) {
  try {
    const response = yield call(
      axiosDefault.get,
      `/overlord/contact_form_types/${includedFormId}`
    );

    yield put({
      type: FETCH_INCLUDED_FORM_SUCCESS,
      payload: {
        currentIncludedForm: response.data
      }
    });
  } catch (e) {
    yield put({
      type: FETCH_INCLUDED_FORM_FAILURE,
      payload: {
        error: e
      },
      error: true
    });
  }
}

function* createIncludedForm({ payload: { name, fields, productId } }) {
  try {
    const params = {
      contact_form_type: {
        name,
        product_id: productId,
        contact_form_fields_attributes: fields
      }
    };
    const response = yield call(
      axiosDefault.post,
      `/overlord/contact_form_types/`,
      params
    );

    yield put({
      type: CREATE_INCLUDED_FORM_SUCCESS,
      payload: {
        currentIncludedForm: response.data
      }
    });
  } catch (e) {
    yield put({
      type: CREATE_INCLUDED_FORM_FAILURE,
      payload: {
        error: e
      },
      error: true
    });
  }
}

function* updateIncludedForm({
  payload: {
    includedFormId,
    productId = null,
    name = null,
    fields = null,
    deletedFields = null,
    staffSmsNotificationParams = null,
    addToCampaignParams = null,
    staffEmailNotificationParams = null
  }
}) {
  try {
    const params = { contact_form_type: {} };
    if (name) {
      params.contact_form_type.name = name;
    }
    if (fields) {
      params.contact_form_type.contact_form_fields_attributes = [
        ...fields,
        ...deletedFields
      ];
    }

    if (productId) {
      params.contact_form_type.product_id = productId;
    }

    if (staffSmsNotificationParams) {
      params.contact_form_type.uplaunch_action_attributes = {
        staff_sms_body: staffSmsNotificationParams.staffSmsBody,
        staff_notification_sms_recipients:
          staffSmsNotificationParams.staffNotificationSmsRecipients,
        has_staff_notification_sms:
          staffSmsNotificationParams.hasStaffNotificationSms
      };
    }

    if (staffSmsNotificationParams?.id) {
      params.contact_form_type.uplaunch_action_attributes.id =
        staffSmsNotificationParams.id;
    }

    if (addToCampaignParams) {
      params.contact_form_type.uplaunch_action_attributes = {
        has_change_campaign_module: addToCampaignParams.hasChangeCampaignModule,
        product_campaign_id: addToCampaignParams.productCampaignId
      };
    }

    if (staffEmailNotificationParams) {
      params.contact_form_type.uplaunch_action_attributes = {
        has_staff_notification_email:
          staffEmailNotificationParams.hasStaffNotificationEmail,
        staff_email_subject: staffEmailNotificationParams.staffEmailSubject,
        staff_email_html: staffEmailNotificationParams.staffEmailHtml,
        staff_email_json: staffEmailNotificationParams.staffEmailJson,
        staff_notification_email_recipients:
          staffEmailNotificationParams.staffNotificationEmailRecipients
      };
    }

    if (staffEmailNotificationParams?.id) {
      params.contact_form_type.uplaunch_action_attributes.id =
        staffEmailNotificationParams.id;
    }

    if (addToCampaignParams?.id) {
      params.contact_form_type.uplaunch_action_attributes.id =
        addToCampaignParams.id;
    }

    const response = yield call(
      axiosDefault.put,
      `/overlord/contact_form_types/${includedFormId}`,
      params
    );

    yield put({
      type: UPDATE_INCLUDED_FORM_SUCCESS,
      payload: {
        currentIncludedForm: response.data
      }
    });
  } catch (e) {
    yield put({
      type: UPDATE_INCLUDED_FORM_FAILURE,
      payload: {
        error: e
      },
      error: true
    });
  }
}

function* fetchStaffSmsMergeFields() {
  try {
    const response = yield call(
      axiosMultipartForm.get,
      `/overlord/contact_form_types/staff_sms_merge_fields`
    );

    yield put({
      type: FETCH_STAFF_SMS_MERGE_FIELDS_SUCCESS,
      payload: {
        staffSmsMergeFields: response.data
      }
    });
  } catch (e) {
    yield put({
      type: FETCH_STAFF_SMS_MERGE_FIELDS_FAILURE,
      payload: {
        error: e
      },
      error: true
    });
  }
}

function* fetchStaffEmailMergeFields() {
  try {
    const response = yield call(
      axiosMultipartForm.get,
      `/overlord/contact_form_types/staff_email_merge_fields`
    );

    yield put({
      type: FETCH_STAFF_EMAIL_MERGE_FIELDS_SUCCESS,
      payload: {
        staffEmailMergeFields: response.data
      }
    });
  } catch (e) {
    yield put({
      type: FETCH_STAFF_EMAIL_MERGE_FIELDS_FAILURE,
      payload: {
        error: e
      },
      error: true
    });
  }
}

function* createProduct({ payload: { productParams } }) {
  try {
    const response = yield call(
      axiosMultipartForm.post,
      `/products/`,
      productParams
    );

    yield put({
      type: CREATE_PRODUCT_SUCCESS,
      payload: {
        currentProduct: response.data
      }
    });
  } catch (e) {
    yield put({
      type: CREATE_PRODUCT_FAILURE,
      payload: {
        error: e
      },
      error: true
    });
  }
}

function* deleteProduct({ payload: { currentProductId, deleteConfirm } }) {
  try {
    yield call(
      axiosDefault.delete,
      `products/${currentProductId}?delete_confirm=${deleteConfirm}`
    );

    yield put({
      type: DELETE_PRODUCT_SUCCESS,
      payload: {
        currentProductId
      }
    });
  } catch (e) {
    yield put({
      type: DELETE_PRODUCT_FAILURE,
      payload: {
        error: e
      },
      error: true
    });
  }
}

function* togglePublishProduct({ payload: { productId, publish } }) {
  const params = { publish };
  try {
    const response = yield call(
      axiosDefault.patch,
      `/products/${productId}/toggle_publish`,
      params
    );

    yield put({
      type: TOGGLE_PUBLISH_PRODUCT_SUCCESS,
      payload: { product: response.data }
    });
  } catch (e) {
    yield put({
      type: TOGGLE_PUBLISH_PRODUCT_FAILURE,
      payload: {
        error: e,
        fallbackError: `Error while ${
          publish ? 'publishing' : 'unpublishing'
        } product.`
      },
      error: true
    });
  }
}

function* updateProduct({ payload: { productId, productParams } }) {
  try {
    const params = {
      product: {
        name: productParams.name,
        description: productParams.description,
        category: productParams.category,
        lifecycles: productParams.lifecycles,
        hero_image: productParams.heroImage,
        featured: productParams.featured,
        purchase_page_image_one: productParams.purchasePageImageOne,
        purchase_page_image_two: productParams.purchasePageImageTwo,
        content_feature_one_title: productParams.contentFeatureOneTitle,
        content_feature_two_title: productParams.contentFeatureTwoTitle,
        content_feature_three_title: productParams.contentFeatureThreeTitle,
        content_feature_one_subtitle: productParams.contentFeatureOneSubtitle,
        content_feature_two_subtitle: productParams.contentFeatureTwoSubtitle,
        content_feature_three_subtitle:
          productParams.contentFeatureThreeSubtitle,
        content_feature_one_icon: productParams.contentFeatureOneIcon,
        content_feature_two_icon: productParams.contentFeatureTwoIcon,
        content_feature_three_icon: productParams.contentFeatureThreeIcon,
        dashboard_top_metric_position_one:
          productParams.dashboardTopMetricPositionOne,
        dashboard_top_metric_position_two:
          productParams.dashboardTopMetricPositionTwo,
        dashboard_top_metric_position_three:
          productParams.dashboardTopMetricPositionThree,
        pricing_information_attributes: {
          price: productParams.price,
          stripe_plan: productParams.stripePlan,
          onboarding_fee_id: productParams.onboardingFeeId
        },
        sms_enabled_countries_attributes: [],
        new_lead_campaign_id: productParams.newLeadCampaign
      }
    };
    const formData = new FormData();
    buildFormData(formData, params);
    const response = yield call(
      axiosMultipartForm.put,
      `/products/${productId}`,
      formData
    );

    yield put({
      type: UPDATE_PRODUCT_SUCCESS,
      payload: {
        currentProduct: response.data
      }
    });
  } catch (e) {
    yield put({
      type: UPDATE_PRODUCT_FAILURE,
      payload: { error: e },
      error: true
    });
  }
}

function* fetchProductsCategories() {
  try {
    const response = yield call(
      axiosDefault.get,
      '/products/product_categories'
    );
    yield put({
      type: FETCH_PRODUCTS_CATEGORIES_SUCCESS,
      payload: {
        productsCategories: response.data
      }
    });
  } catch (e) {
    yield put({
      type: FETCH_PRODUCTS_CATEGORIES_FAILURE,
      payload: {
        error: e
      },
      error: true
    });
  }
}

export function* productFetch() {
  yield takeLatest(FETCH_PRODUCT_REQUEST, fetchProduct);
}

export function* staffSmsMergeFieldsFetch() {
  yield takeLatest(
    FETCH_STAFF_SMS_MERGE_FIELDS_REQUEST,
    fetchStaffSmsMergeFields
  );
}

export function* staffEmailMergeFieldsFetch() {
  yield takeLatest(
    FETCH_STAFF_EMAIL_MERGE_FIELDS_REQUEST,
    fetchStaffEmailMergeFields
  );
}

export function* includedFormFetch() {
  yield takeLatest(FETCH_INCLUDED_FORM_REQUEST, fetchIncludedForm);
}

export function* includedFormCreate() {
  yield takeLatest(CREATE_INCLUDED_FORM_REQUEST, createIncludedForm);
}

export function* includedFormUpdate() {
  yield takeLatest(UPDATE_INCLUDED_FORM_REQUEST, updateIncludedForm);
}

export function* productUpdate() {
  yield takeLatest(UPDATE_PRODUCT_REQUEST, updateProduct);
}

export function* productCreate() {
  yield takeLatest(CREATE_PRODUCT_REQUEST, createProduct);
}

export function* productDelete() {
  yield takeLatest(DELETE_PRODUCT_REQUEST, deleteProduct);
}

export function* toggleProudctPublish() {
  yield takeLatest(TOGGLE_PUBLISH_PRODUCT_REQUEST, togglePublishProduct);
}

export function* productsCategories() {
  yield takeLatest(FETCH_PRODUCTS_CATEGORIES_REQUEST, fetchProductsCategories);
}
