/**
 * Purchase Page
 */

export const PURCHASE_PAGE_CREATE_SUBSCRIPTION_REQUEST =
  'purchase/PURCHASE_PAGE_CREATE_SUBSCRIPTION_REQUEST';
export const PURCHASE_PAGE_CREATE_SUBSCRIPTION_SUCCESS =
  'purchase/PURCHASE_PAGE_CREATE_SUBSCRIPTION_SUCCESS';
export const PURCHASE_PAGE_CREATE_SUBSCRIPTION_FAILURE =
  'purchase/PURCHASE_PAGE_CREATE_SUBSCRIPTION_FAILURE';

export const PURCHASE_PAGE_FETCH_PRODUCT_PLAN_INFO_REQUEST =
  'purchase/PURCHASE_PAGE_FETCH_PRODUCT_PLAN_INFO_REQUEST';
export const PURCHASE_PAGE_FETCH_PRODUCT_PLAN_INFO_SUCCESS =
  'purchase/PURCHASE_PAGE_FETCH_PRODUCT_PLAN_INFO_SUCCESS';
export const PURCHASE_PAGE_FETCH_PRODUCT_PLAN_INFO_FAILURE =
  'purchase/PURCHASE_PAGE_FETCH_PRODUCT_PLAN_INFO_FAILURE';
