import PropTypes from 'prop-types';

const formikShape = {
  dirty: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  isValidating: PropTypes.bool.isRequired,
  resetForm: PropTypes.func.isRequired,
  setErrors: PropTypes.func.isRequired,
  setFieldError: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  submitCount: PropTypes.number.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setStatus: PropTypes.func.isRequired,
  setSubmitting: PropTypes.func.isRequired,
  setTouched: PropTypes.func.isRequired,
  setValues: PropTypes.func.isRequired,
  status: PropTypes.any,
  touched: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  validateForm: PropTypes.func.isRequired,
  validateField: PropTypes.func.isRequired
};

const userShape = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  full_name: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  job_title: PropTypes.string,
  manager: PropTypes.bool,
  admin: PropTypes.bool,
  account_time_zone: PropTypes.string,
  authentication_token: PropTypes.string,
  overlord: PropTypes.bool,
  partner: PropTypes.bool,
  formatted_phone: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

const appointmentShape = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  user_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  user: PropTypes.string,
  contact: PropTypes.string,
  contact_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  starts_at: PropTypes.string,
  appointment_type_id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  appointment_type: PropTypes.string,
  notifications: PropTypes.bool,
  noshowed_at: PropTypes.string,
  canceled_at: PropTypes.string
};

const taskShape = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  canceled_at: PropTypes.string,
  completed_at: PropTypes.string,
  contact_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  created_at: PropTypes.string,
  description: PropTypes.string,
  due_date: PropTypes.string,
  lifecycle: PropTypes.string,
  parsed_description: PropTypes.string,
  product_campaign_step_id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  staff_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  type: PropTypes.string,
  updated_at: PropTypes.string,
  user: PropTypes.string
};

export { formikShape, userShape, appointmentShape, taskShape };
