export const MARKET_PLACE_LIST_FETCH_REQUEST =
  'marketplace/MARKET_PLACE_LIST_FETCH_REQUEST';
export const MARKET_PLACE_LIST_FETCH_SUCCESS =
  'marketplace/MARKET_PLACE_LIST_FETCH_SUCCESS';
export const MARKET_PLACE_LIST_FETCH_FAILURE =
  'marketplace/MARKET_PLACE_LIST_FETCH_FAILURE';

export const PRODUCT_FETCH_REQUEST = 'marketplace/PRODUCT_FETCH_REQUEST';
export const PRODUCT_FETCH_SUCCESS = 'marketplace/PRODUCT_FETCH_SUCCESS';
export const PRODUCT_FETCH_FAILURE = 'marketplace/PRODUCT_FETCH_FAILURE';

export const CLEAR_CURRENT_PRODUCT_FROM_STATE =
  'marketplace/CLEAR_CURRENT_PRODUCT_FROM_STATE';
