import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { toCamel } from 'lib/utils/string';

const TextInput = ({
  id,
  value,
  defaultValue,
  labelText,
  placeholder,
  autocomplete,
  required,
  type,
  inputStyle,
  containerStyle,
  labelStyle,
  onChange,
  onBlur,
  onKeyDown,
  showError,
  error,
  helpText,
  LeadingIcon,
  TrailingIcon,
  leadingAddonText,
  trailingAddonText,
  hideLabel,
  widthClass,
  name
}) => {
  function getName() {
    return toCamel(id);
  }

  const hasAddon = leadingAddonText || trailingAddonText;
  const onEventCall = () => {
    if (document.activeElement.type === 'number') {
      document.activeElement.blur();
    }
  };

  useEffect(() => {
    document.addEventListener('mousewheel', onEventCall);
    return () => {
      document.removeEventListener('mousewheel', onEventCall);
    };
  }, []);

  return (
    <div className={widthClass} style={containerStyle}>
      <label
        htmlFor={id}
        className={`${
          hideLabel
            ? 'tw-sr-only'
            : 'tw-font-body tw-block tw-text-sm tw-font-medium tw-leading-5 tw-text-gray-700'
        }`}
        style={labelStyle}
      >
        {labelText}
        <span className="tw-text-error-700">{required ? '*' : null}</span>
      </label>
      <div className={`tw-mt-1 ${hasAddon ? 'tw-flex' : 'tw-relative'}`}>
        {leadingAddonText && (
          <span className="tw-font-body tw-inline-flex tw-items-center tw-px-3 tw-rounded-l-md tw-border-l tw-border-t tw-border-b tw-border-r-0 tw-border-solid tw-border-gray-300 tw-border-gray-300 tw-bg-gray-50 tw-text-gray-500 sm:tw-text-sm">
            {leadingAddonText}
          </span>
        )}
        {LeadingIcon && (
          <div className="tw-absolute tw-inset-y-0 tw-left-0 tw-pl-3 tw-flex tw-items-center tw-pointer-events-none">
            {typeof LeadingIcon === 'function' ? (
              LeadingIcon()
            ) : (
              <LeadingIcon />
            )}
          </div>
        )}
        <input
          id={id}
          data-testid={id}
          className={`tw-font-body tw-form-input tw-block tw-w-full tw-py-2 tw-px-3 tw-border tw-border-solid tw-border-${
            showError ? 'red' : 'gray'
          }-300 tw-shadow-sm focus:tw-outline-none focus:tw-shadow-outline-${
            showError ? 'red' : 'blue'
          } focus:tw-border-${
            showError ? 'red' : 'blue'
          }-300 tw-transition tw-duration-150 tw-ease-in-out tw-text-sm tw-leading-5 ${
            LeadingIcon ? 'tw-pl-10' : ''
          } ${TrailingIcon ? 'tw-pr-10' : ''} ${
            // eslint-disable-next-line no-nested-ternary
            leadingAddonText
              ? 'tw-rounded-none tw-rounded-r-md'
              : trailingAddonText
              ? 'tw-rounded-none tw-rounded-l-md'
              : 'tw-rounded-md'
          }`}
          style={inputStyle}
          placeholder={placeholder}
          autoComplete={autocomplete}
          type={type}
          name={name || getName()}
          value={value}
          defaultValue={defaultValue}
          onChange={onChange}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
        />
        {showError && !trailingAddonText ? (
          <Fragment>
            <div className="tw-absolute tw-inset-y-0 tw-right-0 tw-pr-3 tw-flex tw-items-center tw-pointer-events-none">
              <svg
                className="tw-h-5 tw-w-5 tw-text-red-500"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          </Fragment>
        ) : null}
        {TrailingIcon && (
          <div className="tw-absolute tw-inset-y-0 tw-right-0 tw-pr-3 tw-flex tw-items-center tw-pointer-events-none">
            {typeof TrailingIcon === 'function' ? (
              TrailingIcon()
            ) : (
              <TrailingIcon />
            )}
          </div>
        )}
        {trailingAddonText && (
          <span className="tw-font-body tw-inline-flex tw-items-center tw-px-3 tw-rounded-r-md tw-border-r tw-border-t tw-border-b tw-border-l-0 tw-border-solid tw-border-gray-300 tw-bg-gray-50 tw-text-gray-500 tw-text-sm">
            {trailingAddonText}
          </span>
        )}
      </div>
      {helpText ? (
        <p className="tw-font-body tw-my-2 tw-text-sm tw-text-gray-500">
          {helpText}
        </p>
      ) : null}
      {showError ? (
        <p className="tw-font-body tw-my-2 tw-text-sm tw-text-red-600">
          {error}
        </p>
      ) : null}
    </div>
  );
};

TextInput.defaultProps = {
  value: undefined,
  defaultValue: undefined,
  placeholder: '',
  autocomplete: '',
  required: false,
  type: 'text',
  inputStyle: {},
  containerStyle: {},
  labelStyle: {},
  onChange: null,
  onBlur: null,
  onKeyDown: null,
  showError: false,
  error: '',
  helpText: '',
  LeadingIcon: null,
  TrailingIcon: null,
  leadingAddonText: '',
  trailingAddonText: '',
  hideLabel: false,
  widthClass: 'tw-col-span-10',
  name: ''
};

const styleProps = PropTypes.objectOf(
  PropTypes.oneOfType([PropTypes.string, PropTypes.number])
);
const componentProps = PropTypes.oneOfType([PropTypes.node, PropTypes.func]);

TextInput.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.string,
  labelText: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  autocomplete: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  inputStyle: styleProps,
  containerStyle: styleProps,
  labelStyle: styleProps,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  showError: PropTypes.bool,
  error: PropTypes.string,
  helpText: PropTypes.string,
  LeadingIcon: componentProps,
  TrailingIcon: componentProps,
  leadingAddonText: PropTypes.string,
  trailingAddonText: PropTypes.string,
  hideLabel: PropTypes.bool,
  widthClass: PropTypes.string,
  name: PropTypes.string
};

export default TextInput;
