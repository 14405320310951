import React, { useState, useEffect, useMemo } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import { stateToHTML } from 'draft-js-export-html';
import isEmpty from 'lodash.isempty';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import PropTypes from 'prop-types';
import CustomDropDown from './CustomDropDown/index';

const DraftEditor = ({
  editorStateString,
  handleEditorTextVal,
  customDropDownData,
  customDropDownName,
  editorOptions,
  labelText,
  onBlur,
  showError,
  error,
  helpText
}) => {
  const [uploadedImages, setuploadedImages] = useState([]);
  const [editorState, setEditorState] = useState();
  let customDropDownList = [];

  useEffect(() => {
    if (!editorState) {
      // initialize either an EditorState with content or one that is empty
      if (editorStateString) {
        const editorContent = stateFromHTML(editorStateString);
        setEditorState(EditorState.createWithContent(editorContent));
      } else {
        setEditorState(EditorState.createEmpty());
      }
    }
  }, [editorState, editorStateString]);

  useEffect(() => {
    if (editorState) {
      // clear EditorState when empty editorStateString is passed back
      if (isEmpty(editorStateString)) {
        const newEditorState = EditorState.push(
          editorState,
          ContentState.createFromText('')
        );
        setEditorState(newEditorState);
      }
    }
  }, [editorStateString]);

  const uploadImageCallBack = file => {
    const uploadedImageList = uploadedImages;
    const imageObject = {
      file,
      localSrc: URL.createObjectURL(file)
    };
    uploadedImageList.push(imageObject);
    setuploadedImages([...uploadedImages], uploadedImageList);
    return new Promise(resolve => {
      resolve({ data: { link: imageObject.localSrc } });
    });
  };

  const getCustomizedOptions = useMemo(() => {
    const customizedOptions = {};

    if (editorOptions.includes('inline')) {
      customizedOptions.inline = {
        inDropdown: true,
        className: 'tw-rounded-lg'
      };
    }

    if (editorOptions.includes('list')) {
      customizedOptions.list = {
        inDropdown: true,
        className: 'tw-rounded-lg'
      };
    }

    if (editorOptions.includes('textAlign')) {
      customizedOptions.textAlign = {
        inDropdown: true,
        className: 'tw-rounded-lg'
      };
    }

    if (editorOptions.includes('link')) {
      customizedOptions.link = {
        inDropdown: true,
        showOpenOptionOnHover: true,
        defaultTargetOption: '_self',
        className: 'tw-rounded-lg'
      };
    }

    if (editorOptions.includes('history')) {
      customizedOptions.history = { className: 'tw-rounded-lg' };
    }

    if (editorOptions.includes('image')) {
      customizedOptions.image = {
        previewImage: true,
        uploadCallback: uploadImageCallBack,
        alt: { present: true, mandatory: false },
        inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
        className: 'tw-rounded-lg'
      };
    }

    return customizedOptions;
  }, [editorOptions]);

  customDropDownList = Object.values(customDropDownData).map(
    (dropdownItem, index) => {
      return (
        <CustomDropDown
          key={customDropDownName[index]}
          dropDownName={customDropDownName[index]}
          optionData={dropdownItem}
        />
      );
    }
  );

  function handleEditorStateChange(_editorState) {
    const contentState = _editorState.getCurrentContent();
    const contentHTML = stateToHTML(contentState);
    handleEditorTextVal(contentHTML);

    const newEditorState = EditorState.push(_editorState, contentState);
    setEditorState(newEditorState);
  }

  return (
    <div className="editor">
      {labelText && (
        <label className="tw-font-body tw-block tw-text-sm tw-font-medium tw-leading-5 tw-text-gray-700 tw-mb-2">
          {labelText}
        </label>
      )}
      <Editor
        editorState={editorState}
        toolbar={{
          options: editorOptions,
          ...getCustomizedOptions
        }}
        onEditorStateChange={handleEditorStateChange}
        onBlur={onBlur}
        wrapperClassName="tw-rounded-lg tw-border tw-border-solid tw-border-gray-300 tw-p-3 focus:tw-border-blue-300 focus:tw-shadow-outline-blue-300"
        editorClassName="tw-border-1 tw-border-r-0 tw-border-b-0 tw-border-l-0 tw-border-solid tw-border-gray-300 tw-text-sm tw-text-black"
        toolbarClassName="tw-border-0 tw-p-0"
        toolbarCustomButtons={customDropDownList}
      />
      {helpText ? (
        <p className="tw-font-body tw-my-2 tw-text-sm tw-text-gray-500">
          {helpText}
        </p>
      ) : null}
      {showError ? (
        <p className="tw-font-body tw-my-2 tw-text-sm tw-text-red-600">
          {error}
        </p>
      ) : null}
    </div>
  );
};

const allOptions = [
  'inline',
  'blockType',
  'fontSize',
  'fontFamily',
  'list',
  'textAlign',
  'colorPicker',
  'link',
  'emoji',
  'image',
  'history',
  'remove'
];

DraftEditor.defaultProps = {
  editorStateString: '',
  handleEditorTextVal: null,
  customDropDownData: {},
  customDropDownName: [],
  editorOptions: allOptions,
  labelText: '',
  onBlur: null,
  showError: false,
  error: '',
  helpText: ''
};

const dropdownDataShape = {
  key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.string,
  text: PropTypes.string,
  url: PropTypes.string
};

DraftEditor.propTypes = {
  editorStateString: PropTypes.string,
  handleEditorTextVal: PropTypes.func,
  customDropDownData: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.shape(dropdownDataShape))
  ),
  customDropDownName: PropTypes.arrayOf(PropTypes.string),
  editorOptions: PropTypes.arrayOf(PropTypes.oneOf(allOptions)),
  labelText: PropTypes.string,
  onBlur: PropTypes.func,
  showError: PropTypes.bool,
  error: PropTypes.string,
  helpText: PropTypes.string
};

export default DraftEditor;
