import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import isEmpty from 'lodash.isempty';
import { CurrentAccountContext } from 'components/shared/context/CurrentAccountContext';
import {
  createErrorMessageSelector,
  createLoadingSelector,
  createLoadedSelector
} from 'appState/selectors';
import {
  createContactForm,
  fetchContactFormTypes,
  resetIsLoaded,
  fetchFormsActivities
} from 'appState/actions/ActionCreators';
import Button from 'components/Theme/Button';
import Select from 'components/Theme/Select';
import schemaCreator from 'lib/validation/schemaCreator';
import FormField from './FormField';

const ContactFormCreate = ({ contactId, setShowError, setShowSuccess }) => {
  const [selectedFormType, setSelectedFormType] = useState('');
  const [sortedSelectedFormType, setSortedSelectedFormType] = useState([]);
  const [contactFormSchema, setContactFormSchema] = useState({});
  const dispatch = useDispatch();
  const errorSelector = createErrorMessageSelector([
    'contactForm/CREATE_CONTACT_FORM'
  ]);
  const loadingSelector = createLoadingSelector([
    'contactForm/CREATE_CONTACT_FORM'
  ]);
  const loadedSelector = createLoadedSelector([
    'contactForm/CREATE_CONTACT_FORM'
  ]);

  const structuredSelector = createStructuredSelector({
    currentContact: state => state.contact.currentContact,
    contactFormTypes: state => state.contactFormType.contactFormTypes,
    error: state => errorSelector(state),
    isLoading: state => loadingSelector(state),
    isLoaded: state => loadedSelector(state)
  });

  const {
    currentContact,
    contactFormTypes,
    error,
    isLoading,
    isLoaded
  } = useSelector(structuredSelector);

  const contactFormTypeOptions = contactFormTypes.map(formType => {
    return { displayName: formType.name, value: formType.id };
  });

  const currentAccount = useContext(CurrentAccountContext);

  useEffect(() => {
    if (isEmpty(contactFormTypes)) dispatch(fetchContactFormTypes());
  }, [dispatch]);

  useEffect(() => {
    if (selectedFormType && selectedFormType.fields) {
      const yupSchema = selectedFormType.fields.reduce(schemaCreator, {});
      setContactFormSchema(Yup.object().shape(yupSchema));
    }
  }, [selectedFormType]);

  function handleSubmit(values) {
    const contactFormParams = {
      accountId: currentAccount.id,
      contactId,
      formId: selectedFormType.id,
      contactForm: {
        contact_form_type_id: selectedFormType.id
      }
    };

    if (Object.keys(values).length)
      Object.keys(values).forEach(function assignParams(key) {
        contactFormParams.contactForm[key] = values[key];
      });

    setSelectedFormType('');
    return dispatch(createContactForm(contactFormParams));
  }

  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,
    validationSchema: contactFormSchema,
    onSubmit: values => {
      handleSubmit(values);
    }
  });

  function handleContactFormTypeSelect(e) {
    const contactFormTypeId = e.target.value;
    const selectedType = contactFormTypes.filter(
      formType => formType.id.toString() === contactFormTypeId
    )[0];
    setSelectedFormType(selectedType);
  }

  useEffect(() => {
    if (isLoaded && isEmpty(error)) {
      setShowSuccess(true);
      if (!currentContact.dummy)
        dispatch(
          fetchFormsActivities({
            contactId: currentContact.id
          })
        );
      Object.keys(formik.values).forEach(function emptyParams(key) {
        formik.setFieldValue(key, '', false);
      });
    }

    if (isLoaded && !isEmpty(error)) {
      setShowError(true);
    }

    dispatch(resetIsLoaded('contactForm/CREATE_CONTACT_FORM'));

    return () => {
      dispatch(resetIsLoaded('contactForm/CREATE_CONTACT_FORM'));
    };
  }, [error, isLoaded, dispatch]);

  useEffect(() => {
    const sortedSelectedType = selectedFormType?.fields?.length
      ? selectedFormType.fields.slice().sort((a, b) => a.position - b.position)
      : null;
    setSortedSelectedFormType(sortedSelectedType);
  }, [selectedFormType]);

  return (
    <div className="tw-py-5">
      <div className="tw-grid tw-grid-cols-12 tw-gap-6">
        <div className="tw-col-span-12">
          <Select
            error="Please select a form type"
            helpText="Please select the type of form you would like to fill out for this contact."
            hideLabel={false}
            id="email"
            labelText="Form Type"
            onChange={handleContactFormTypeSelect}
            options={contactFormTypeOptions}
            placeholder="Nothing Selected"
            showError={false}
            type="text"
            value={selectedFormType ? selectedFormType.id : ''}
            widthClass="tw-max-w-full"
          />

          <div className="tw-mt-7">
            {sortedSelectedFormType?.length
              ? sortedSelectedFormType.map(field => {
                  return (
                    <div key={field.id} className="tw-mb-8">
                      <FormField
                        field={field}
                        formik={formik}
                        widthClass="tw-max-w-full"
                      />
                    </div>
                  );
                })
              : null}
          </div>

          <div className="tw-float-right">
            <Button
              color="alpha"
              containerStyle={{
                marginBottom: '5px',
                float: 'right'
              }}
              disabled={false}
              isLoaded={!isLoading}
              onClick={formik.handleSubmit}
              text="Submit"
              type="primary"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

ContactFormCreate.propTypes = {
  contactId: PropTypes.string.isRequired,
  setShowError: PropTypes.func.isRequired,
  setShowSuccess: PropTypes.func.isRequired
};

export default ContactFormCreate;
