import { call, put, takeLatest } from 'redux-saga/effects';
import { axiosDefault } from 'lib/utils/axios-setup';
import {
  FETCH_AUTO_COMPLETE_SUGGESTIONS_REQUEST,
  FETCH_AUTO_COMPLETE_SUGGESTIONS_SUCCESS,
  FETCH_AUTO_COMPLETE_SUGGESTIONS_FAILURE
} from 'appState/actions/constants/autoComplete.actions';

function setDisplayName(displayName, suggestion) {
  let displayString = '';
  if (typeof displayName === 'string') {
    displayString = suggestion[displayName];
  } else {
    displayName.forEach(element => {
      displayString += `${displayString.length ? ' - ' : ''}${
        suggestion[element] ? suggestion[element] : ''
      }`;
    });
  }
  return displayString;
}

function decorateSuggestions(suggestions, suggestionDataMap) {
  return suggestions.map(suggestion => {
    return {
      displayName: setDisplayName(suggestionDataMap.displayName, suggestion),
      value: suggestion[suggestionDataMap.value]
    };
  });
}

function* fetchAutoCompleteSuggestions({
  payload: { url, query, suggestionDataMap }
}) {
  try {
    const response = yield call(
      axiosDefault.get,
      `${url}?q=${query}
      `
    );

    yield put({
      type: FETCH_AUTO_COMPLETE_SUGGESTIONS_SUCCESS,
      payload: {
        suggestions: decorateSuggestions(response.data, suggestionDataMap),
        suggestionDataMap
      }
    });
  } catch (e) {
    yield put({
      type: FETCH_AUTO_COMPLETE_SUGGESTIONS_FAILURE,
      payload: { error: e },
      error: true
    });
  }
}

// eslint-disable-next-line import/prefer-default-export
export function* autoCompleteSuggestionsFetch() {
  yield takeLatest(
    FETCH_AUTO_COMPLETE_SUGGESTIONS_REQUEST,
    fetchAutoCompleteSuggestions
  );
}
