import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import isEmpty from 'lodash.isempty';
import Chip from 'components/Chip';
import { buildAudienceFullSummary } from 'lib/utils/audienceSummary';
import { broadcastsFetchContacts } from 'appState/actions/ActionCreators';

const AudienceSummaryModal = props => {
  const { templates } = props;
  const [fullAudienceSummary, setFullAudienceSummary] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const dispatch = useDispatch();

  const structuredSelector = createStructuredSelector({
    broadcastTemplateId: state =>
      state.broadcastCenter.selectedBroadcastTemplateId,
    contacts: state => state.broadcastCenter.contacts
  });
  const { broadcastTemplateId, contacts } = useSelector(structuredSelector);

  useEffect(() => {
    const template = templates.filter(t => t.id === broadcastTemplateId)[0];
    setSelectedTemplate(template);
    setFullAudienceSummary(buildAudienceFullSummary(template));
  }, [broadcastTemplateId]);

  useEffect(() => {
    const template = templates.filter(t => t.id === broadcastTemplateId)[0];
    if (
      template &&
      template.audience &&
      !isEmpty(template.audience.manually_selected_contact_ids)
    ) {
      dispatch(
        broadcastsFetchContacts({
          contactIds: template.audience.manually_selected_contact_ids
        })
      );
    }
  }, [broadcastTemplateId, dispatch]);

  return (
    <div
      className="modal fade show"
      id="audience-summary-modal"
      tabIndex="-1"
      aria-modal="true"
      aria-hidden="true"
      style={{ display: 'none' }}
    >
      <div className="modal-dialog modal-md">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="myModalLabel">
              Additional Broadcast Template Details
            </h4>
            <button type="button" className="close" data-dismiss="modal">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            {selectedTemplate && (
              <Fragment>
                {selectedTemplate.description && (
                  <Fragment>
                    <h6 className="d-block d-sm-none">Description</h6>
                    <p className="d-block d-sm-none">
                      {selectedTemplate.description}
                    </p>
                  </Fragment>
                )}
                {selectedTemplate &&
                selectedTemplate.select_audience_by === 'searching' &&
                !isEmpty(contacts) ? (
                  <Fragment>
                    <h6>Audience Summary</h6>
                    <p>
                      Email will be sent to the following manually selected
                      contacts
                    </p>
                    <div className="d-flex flex-wrap">
                      {contacts.map(contact => {
                        return (
                          <Chip text={contact.full_name} key={contact.id} />
                        );
                      })}
                    </div>
                  </Fragment>
                ) : (
                  <Fragment>
                    <h6>Audience Summary</h6>
                    <p>Email will be sent to contacts</p>
                    <div>{fullAudienceSummary}</div>
                  </Fragment>
                )}
              </Fragment>
            )}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-info" data-dismiss="modal">
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

AudienceSummaryModal.propTypes = {
  templates: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default AudienceSummaryModal;
