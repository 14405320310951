import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { fetchLogger } from 'appState/actions/ActionCreators';
import Card from 'components/Theme/Card';
import HeaderComponent from 'components/SyncWizard/shared/HeaderComponent';

const structuredSelector = createStructuredSelector({
  logger: state => state.syncWizard.logger
});

const Logger = () => {
  const { logger } = useSelector(structuredSelector);
  const dispatch = useDispatch();
  const history = useHistory();

  const navigateToSyncWizard = () => {
    history.push('/ui/sync-wizard/stats');
  };

  useEffect(() => {
    dispatch(fetchLogger());
  }, [dispatch]);

  return (
    <div className="tw-mx-7 tw-mt-7 tw-mb-24">
      <Card containerClass="tw-bg-white tw-rounded-lg">
        <HeaderComponent
          title="Sync Wizard Test Logs"
          subtitle=""
          actionText="Sync Wizard Stats"
          withAction
          onClick={navigateToSyncWizard}
        />
        <span
          className="tw-block tw-mx-auto tw-p-8 tw-bg-gray-100 tw-mt-10 tw-rounded-lg tw-mb-20"
          style={{
            height: '450px',
            maxWidth: '800px',
            clear: 'both',
            overflowX: 'hidden',
            overflowY: 'scroll'
          }}
        >
          <div dangerouslySetInnerHTML={{ __html: logger }} />
        </span>
      </Card>
    </div>
  );
};

export default Logger;
