// linting rules disabled for immer js
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  SMS_INBOX_FETCH_REQUEST,
  SMS_INBOX_FETCH_SUCCESS,
  SMS_INBOX_FETCH_FAILURE,
  SMS_INBOX_PAGE_CHANGE_REQUEST,
  SMS_INBOX_PAGE_CHANGE_SUCCESS,
  SMS_INBOX_ADD_MESSAGE,
  SMS_INBOX_MARK_AS_READ_SUCCESS,
  SMS_INBOX_FETCH_CONVERSATION_REQUEST,
  SMS_INBOX_FETCH_CONVERSATION_SUCCESS,
  SMS_INBOX_FETCH_CONVERSATION_FAILURE,
  SMS_INBOX_CONVERSATION_PAGE_CHANGE_REQUEST,
  SMS_INBOX_CONVERSATION_PAGE_CHANGE_SUCCESS,
  SMS_INBOX_CURRENT_CONVERSATION_ADD_MESSAGE,
  SMS_INBOX_MESSAGE_LIST_ITEM_LAST_MESSAGE_UPDATE,
  SMS_INBOX_SEND_MESSAGE_SUCCESS
} from 'appState/actions/constants/smsInbox.actions';

const LIMIT = 30;

const initialState = {
  conversations: [],
  allReadConversations: [],
  allUnreadConversations: [],
  currentConversation: [],
  page: 1,
  per: LIMIT,
  total: 0,
  isChangingPage: false,
  totalPages: 1,
  conversationPage: 1,
  conversationPer: LIMIT,
  conversationTotal: 0,
  conversationIsChangingPage: false,
  conversationTotalPages: 1,
  scrollToMessageId: 0
};

export default function SmsInboxReducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case SMS_INBOX_FETCH_REQUEST: {
        const page =
          (action.payload && action.payload.page) || initialState.page;
        const per = (action.payload && action.payload.per) || initialState.per;
        draft.page = page;
        draft.per = per;
        return;
      }
      case SMS_INBOX_FETCH_SUCCESS: {
        const { conversations, total, per } = action.payload;
        const totalPages = Math.ceil(total / per);

        draft.conversations = conversations;
        draft.total = total;
        draft.totalPages = totalPages;
        return;
      }
      case SMS_INBOX_FETCH_FAILURE: {
        draft.isChangingPage = false;
        return;
      }
      case SMS_INBOX_PAGE_CHANGE_REQUEST: {
        const { page, per } = action.payload;
        draft.page = page;
        draft.per = per || LIMIT;
        draft.isChangingPage = true;
        return;
      }
      case SMS_INBOX_PAGE_CHANGE_SUCCESS: {
        const { conversations } = action.payload;

        draft.conversations.push(...conversations);
        draft.isChangingPage = false;
        return;
      }
      case SMS_INBOX_FETCH_CONVERSATION_REQUEST: {
        const page =
          (action.payload && action.payload.page) ||
          initialState.conversationPage;
        const per =
          (action.payload && action.payload.per) ||
          initialState.conversationPer;
        draft.conversationPage = page;
        draft.conversationPer = per;
        return;
      }
      case SMS_INBOX_FETCH_CONVERSATION_SUCCESS: {
        const {
          currentConversation,
          conversationTotal,
          conversationPer
        } = action.payload;
        const totalPages = Math.ceil(conversationTotal / conversationPer);

        draft.currentConversation = currentConversation.reverse();
        draft.conversationTotal = conversationTotal;
        draft.conversationTotalPages = totalPages;
        return;
      }
      case SMS_INBOX_FETCH_CONVERSATION_FAILURE: {
        draft.conversationIsChangingPage = false;
        return;
      }
      case SMS_INBOX_CONVERSATION_PAGE_CHANGE_REQUEST: {
        const { page, per } = action.payload;

        draft.conversationPage = page;
        draft.conversationPer = per || LIMIT;
        draft.conversationIsChangingPage = true;
        return;
      }
      case SMS_INBOX_CONVERSATION_PAGE_CHANGE_SUCCESS: {
        const { currentConversation } = action.payload;

        draft.currentConversation = [
          ...currentConversation.reverse(),
          ...draft.currentConversation
        ];
        draft.conversationIsChangingPage = false;
        return;
      }
      case SMS_INBOX_ADD_MESSAGE: {
        const { newMessage } = action.payload;
        const readConversationKey = 'allReadConversations';
        const unreadConversationKey = 'allUnreadConversations';

        // the new message is not a push notification or the logged in user
        // is the assigned staff member, add the message to read conversations
        draft[readConversationKey] = draft[readConversationKey].filter(
          conversation => conversation.id !== newMessage.id
        );
        draft[readConversationKey].unshift(newMessage);

        draft[unreadConversationKey] = draft[unreadConversationKey].filter(
          conversation => conversation.id !== newMessage.id
        );
        return;
      }
      case SMS_INBOX_MARK_AS_READ_SUCCESS: {
        const { contactId } = action.payload;
        draft.conversations = draft.conversations.map(c => {
          if (c.id === contactId) c.unread_count = 0;
          return c;
        });

        draft.currentConversation = draft.currentConversation.map(sms => {
          if (sms.contact.id === contactId) sms.read_at = new Date();
          return sms;
        });

        return;
      }
      case SMS_INBOX_CURRENT_CONVERSATION_ADD_MESSAGE: {
        const { sms } = action.payload;
        if (
          draft.currentConversation.filter(c => c.id === sms.id).length === 0 &&
          draft.currentConversation[0].contact.id === sms.contact.id
        ) {
          draft.currentConversation = [...draft.currentConversation, sms];
          draft.scrollToMessageId = sms.id;
        }
        return;
      }
      case SMS_INBOX_MESSAGE_LIST_ITEM_LAST_MESSAGE_UPDATE: {
        const { sms } = action.payload;
        draft.conversations = draft.conversations.map(c => {
          if (sms.contact.id === c.id) {
            c.last_message = sms.body;
            c.last_message_timestamp = sms.created_at;
          }
          return c;
        });

        return;
      }
      case SMS_INBOX_SEND_MESSAGE_SUCCESS: {
        return;
      }
      default:
        return draft;
    }
  });
}
