// linting rules disabled for immer js
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */

import produce from 'immer';
import {
  FETCH_APPOINTMENT_TYPE_SUCCESS,
  CREATE_APPOINTMENT_TYPE_SUCCESS,
  UPDATE_APPOINTMENT_TYPE_SUCCESS,
  DELETE_APPOINTMENT_TYPE_SUCCESS,
  FETCH_ALL_APPOINTMENT_TYPE_SUCCESS
} from '../actions/constants/appointmentType.actions';

const initialState = {
  currentAppointmentType: {},
  appointmentTypes: []
};

export default function AppointmentTypeReducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case FETCH_ALL_APPOINTMENT_TYPE_SUCCESS: {
        draft.appointmentTypes = action.payload;
        return;
      }
      case FETCH_APPOINTMENT_TYPE_SUCCESS: {
        const { currentAppointmentType } = action.payload;
        draft.currentAppointmentType = currentAppointmentType;
        return;
      }
      case CREATE_APPOINTMENT_TYPE_SUCCESS: {
        const { currentAppointmentType } = action.payload;
        draft.currentAppointmentType = currentAppointmentType;
        return;
      }
      case UPDATE_APPOINTMENT_TYPE_SUCCESS: {
        const { currentAppointmentType } = action.payload;
        draft.currentAppointmentType = currentAppointmentType;
        return;
      }
      case DELETE_APPOINTMENT_TYPE_SUCCESS: {
        const { appointmentTypeId } = action.payload;
        draft.appointmentTypes = draft.appointmentTypes.filter(
          a => a.id !== appointmentTypeId
        );
        return;
      }
      default:
        return draft;
    }
  });
}
