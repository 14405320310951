export const FETCH_MY_PRODUCT_SUBSCRIPTIONS =
  'productSubscription/FETCH_MY_PRODUCT_SUBSCRIPTIONS';
export const FETCH_MY_PRODUCT_SUBSCRIPTIONS_REQUEST = `${FETCH_MY_PRODUCT_SUBSCRIPTIONS}_REQUEST`;
export const FETCH_MY_PRODUCT_SUBSCRIPTIONS_SUCCESS = `${FETCH_MY_PRODUCT_SUBSCRIPTIONS}_SUCCESS`;
export const FETCH_MY_PRODUCT_SUBSCRIPTIONS_FAILURE = `${FETCH_MY_PRODUCT_SUBSCRIPTIONS}_FAILURE`;

export const FETCH_PRODUCT_SUBSCRIPTION =
  'productSubscription/FETCH_PRODUCT_SUBSCRIPTION';
export const FETCH_PRODUCT_SUBSCRIPTION_REQUEST = `${FETCH_PRODUCT_SUBSCRIPTION}_REQUEST`;
export const FETCH_PRODUCT_SUBSCRIPTION_SUCCESS = `${FETCH_PRODUCT_SUBSCRIPTION}_SUCCESS`;
export const FETCH_PRODUCT_SUBSCRIPTION_FAILURE = `${FETCH_PRODUCT_SUBSCRIPTION}_FAILURE`;

export const UPDATE_PRODUCT_SUBSCRIPTION =
  'productSubscription/UPDATE_PRODUCT_SUBSCRIPTION';
export const UPDATE_PRODUCT_SUBSCRIPTION_REQUEST = `${UPDATE_PRODUCT_SUBSCRIPTION}_REQUEST`;
export const UPDATE_PRODUCT_SUBSCRIPTION_SUCCESS = `${UPDATE_PRODUCT_SUBSCRIPTION}_SUCCESS`;
export const UPDATE_PRODUCT_SUBSCRIPTION_FAILURE = `${UPDATE_PRODUCT_SUBSCRIPTION}_FAILURE`;

export const PAUSE_PRODUCT_SUBSCRIPTION =
  'productSubscription/PAUSE_PRODUCT_SUBSCRIPTION';
export const PAUSE_PRODUCT_SUBSCRIPTION_REQUEST = `${PAUSE_PRODUCT_SUBSCRIPTION}_REQUEST`;
export const PAUSE_PRODUCT_SUBSCRIPTION_SUCCESS = `${PAUSE_PRODUCT_SUBSCRIPTION}_SUCCESS`;
export const PAUSE_PRODUCT_SUBSCRIPTION_FAILURE = `${PAUSE_PRODUCT_SUBSCRIPTION}_FAILURE`;

export const UNPAUSE_PRODUCT_SUBSCRIPTION =
  'productSubscription/UNPAUSE_PRODUCT_SUBSCRIPTION';
export const UNPAUSE_PRODUCT_SUBSCRIPTION_REQUEST = `${UNPAUSE_PRODUCT_SUBSCRIPTION}_REQUEST`;
export const UNPAUSE_PRODUCT_SUBSCRIPTION_SUCCESS = `${UNPAUSE_PRODUCT_SUBSCRIPTION}_SUCCESS`;
export const UNPAUSE_PRODUCT_SUBSCRIPTION_FAILURE = `${UNPAUSE_PRODUCT_SUBSCRIPTION}_FAILURE`;

export const REMOVE_PRODUCT_SUBSCRIPTION =
  'productSubscription/REMOVE_PRODUCT_SUBSCRIPTION';
export const REMOVE_PRODUCT_SUBSCRIPTION_REQUEST = `${REMOVE_PRODUCT_SUBSCRIPTION}_REQUEST`;
export const REMOVE_PRODUCT_SUBSCRIPTION_SUCCESS = `${REMOVE_PRODUCT_SUBSCRIPTION}_SUCCESS`;
export const REMOVE_PRODUCT_SUBSCRIPTION_FAILURE = `${REMOVE_PRODUCT_SUBSCRIPTION}_FAILURE`;

export const CREATE_PRODUCT_SUBSCRIPTION =
  'productSubscription/CREATE_PRODUCT_SUBSCRIPTION';
export const CREATE_PRODUCT_SUBSCRIPTION_REQUEST = `${CREATE_PRODUCT_SUBSCRIPTION}_REQUEST`;
export const CREATE_PRODUCT_SUBSCRIPTION_SUCCESS = `${CREATE_PRODUCT_SUBSCRIPTION}_SUCCESS`;
export const CREATE_PRODUCT_SUBSCRIPTION_FAILURE = `${CREATE_PRODUCT_SUBSCRIPTION}_FAILURE`;

export const FETCH_ADD_CONTACTS_FILTERS =
  'productSubscription/FETCH_ADD_CONTACTS_FILTERS';
export const FETCH_ADD_CONTACTS_FILTERS_REQUEST = `${FETCH_ADD_CONTACTS_FILTERS}_REQUEST`;
export const FETCH_ADD_CONTACTS_FILTERS_SUCCESS = `${FETCH_ADD_CONTACTS_FILTERS}_SUCCESS`;
export const FETCH_ADD_CONTACTS_FILTERS_FAILURE = `${FETCH_ADD_CONTACTS_FILTERS}_FAILURE`;

export const ADD_CONTACTS_TO_PRODUCT =
  'productSubscription/ADD_CONTACTS_TO_PRODUCT';
export const ADD_CONTACTS_TO_PRODUCT_REQUEST = `${ADD_CONTACTS_TO_PRODUCT}_REQUEST`;
export const ADD_CONTACTS_TO_PRODUCT_SUCCESS = `${ADD_CONTACTS_TO_PRODUCT}_SUCCESS`;
export const ADD_CONTACTS_TO_PRODUCT_FAILURE = `${ADD_CONTACTS_TO_PRODUCT}_FAILURE`;

export const VIEW_CONTACTS_IN_PRODUCT_CAMPAIGN =
  'productSubscription/VIEW_CONTACTS_IN_PRODUCT_CAMPAIGN';
export const VIEW_CONTACTS_IN_PRODUCT_CAMPAIGN_REQUEST = `${VIEW_CONTACTS_IN_PRODUCT_CAMPAIGN}_REQUEST`;
export const VIEW_CONTACTS_IN_PRODUCT_CAMPAIGN_SUCCESS = `${VIEW_CONTACTS_IN_PRODUCT_CAMPAIGN}_SUCCESS`;
export const VIEW_CONTACTS_IN_PRODUCT_CAMPAIGN_FAILURE = `${VIEW_CONTACTS_IN_PRODUCT_CAMPAIGN}_FAILURE`;

export const FETCH_CONTACTS_COUNT = 'productSubscription/FETCH_CONTACTS_COUNT';
export const FETCH_CONTACTS_COUNT_REQUEST = `${FETCH_CONTACTS_COUNT}_REQUEST`;
export const FETCH_CONTACTS_COUNT_SUCCESS = `${FETCH_CONTACTS_COUNT}_SUCCESS`;
export const FETCH_CONTACTS_COUNT_FAILURE = `${FETCH_CONTACTS_COUNT}_FAILURE`;

export const UPDATE_PROCESS_ADD_CONTACTS_AT =
  'productSubscription/UPDATE_PROCESS_ADD_CONTACTS_AT';
export const CLEAR_VIEW_CONTACTS_MODAL_CONTACTS =
  'product/CLEAR_VIEW_CONTACTS_MODAL_CONTACTS';
