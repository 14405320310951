import React from 'react';
import { withA11y } from '@storybook/addon-a11y';
import { withKnobs } from '@storybook/addon-knobs';
import Tooltip from './index';

export default {
  component: Tooltip,
  title: 'Tooltip',
  decorators: [withA11y, withKnobs]
};

export const QuestionMark = () => {
  return (
    <div className="tw-mt-32">
      <Tooltip place="right">
        <h2 className="tw-text-gray-900">Sample Tooltip Content</h2>
        <p className="tw-text-gray-500 tw-mb-0">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat...
        </p>
      </Tooltip>
    </div>
  );
};
