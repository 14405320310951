import React, { useContext, useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash.isempty';
import { fetchAppointment } from 'appState/actions/ActionCreators';
import { createStructuredSelector } from 'reselect';
import { CurrentAccountContext } from 'components/shared/context/CurrentAccountContext';
import { useParams } from 'react-router-dom';
import {
  createErrorMessageSelector,
  createLoadedSelector
} from 'appState/selectors';
import Skeleton from 'react-loading-skeleton';
import Button from 'components/Theme/Button';
import moment from 'moment';
import Notification from 'components/Theme/Notification';

const ConfirmationPage = () => {
  const dispatch = useDispatch();
  const currentAccount = useContext(CurrentAccountContext);
  const params = useParams();
  const { appointmentId } = params;
  const errorSelector = createErrorMessageSelector([
    'appointment_booker/FETCH_APPOINTMENT'
  ]);
  const loadedSelector = createLoadedSelector([
    'appointment_booker/FETCH_APPOINTMENT'
  ]);
  const structuredSelector = createStructuredSelector({
    currentAppointment: state => state.appointmentBooker.currentAppointment,
    error: state => errorSelector(state),
    isLoaded: state => loadedSelector(state)
  });

  const { currentAppointment, error, isLoaded } = useSelector(
    structuredSelector
  );
  const [showError, setShowError] = useState(false);

  const [socialMediaIconCount, setSocialMediaIconCount] = useState(0);

  useEffect(() => {
    if (isEmpty(currentAppointment) && currentAccount) {
      dispatch(
        fetchAppointment({ accountId: currentAccount.id, appointmentId })
      );
    }
  }, [currentAppointment, currentAccount, dispatch]);

  useEffect(() => {
    if (isLoaded && !isEmpty(error)) {
      setShowError(true);
    }
  }, [error, isLoaded, dispatch]);

  useEffect(() => {
    if (!isEmpty(currentAccount)) {
      let iconCount = 0;
      if (!isEmpty(currentAccount.facebookUrl)) {
        iconCount += 1;
      }
      if (!isEmpty(currentAccount.twitterUrl)) {
        iconCount += 1;
      }
      if (!isEmpty(currentAccount.instagramUrl)) {
        iconCount += 1;
      }
      if (!isEmpty(currentAccount.youtubeUrl)) {
        iconCount += 1;
      }
      setSocialMediaIconCount(iconCount);
    }
  }, [currentAccount, dispatch]);

  return (
    <Fragment>
      <div className="tw-bg-white tw-rounded-lg tw-p-10">
        <div className="tw-mt-3 tw-text-center sm:tw-mt-5">
          <img
            alt="account logo"
            style={{ maxWidth: '90%', maxHeight: '250px' }}
            src={currentAccount.logo_url}
          />
          <h3
            className="tw-mt-10 tw-text-xl tw-leading-8 tw-font-extrabold tw-text-gray-900 sm:tw-text-3xl sm:tw-leading-10"
            id="modal-headline"
          >
            {!isEmpty(currentAppointment) ? (
              `Thanks, ${currentAppointment.contact}!`
            ) : (
              <Skeleton height={30} width={300} />
            )}
          </h3>
          <div className="tw-mt-2">
            {!isEmpty(currentAppointment) ? (
              <p className="tw-text-base tw-leading-7 tw-text-gray-500">
                Your{' '}
                <i>
                  {currentAppointment && currentAppointment.appointment_type}
                </i>{' '}
                appointment with {currentAccount && currentAccount.name} is
                confirmed for{' '}
                {moment(currentAppointment.starts_at).format('MMMM Do YYYY')} at{' '}
                {moment(currentAppointment.starts_at).format('h:mm A')}.
              </p>
            ) : (
              <Skeleton height={30} width={500} />
            )}
          </div>
        </div>
        {!isEmpty(currentAccount) && socialMediaIconCount > 0 ? (
          <div className="tw-mt-15">
            <div className="tw-relative">
              <div className="tw-absolute tw-inset-0 tw-flex tw-items-center">
                <div className="tw-w-full tw-border-solid tw-border-t tw-border-b-0 tw-border-l-0 tw-border-r-0 tw-border-gray-300" />
              </div>
              <div className="tw-relative tw-flex tw-justify-center tw-text-sm tw-leading-5">
                <span className="tw-px-2 tw-bg-white tw-font-medium tw-text-gray-500">
                  Check us out on social media
                </span>
              </div>
            </div>

            <div
              className={`tw-mt-6 tw-grid tw-grid-cols-${socialMediaIconCount} tw-gap-3`}
            >
              {!isEmpty(currentAccount.facebookUrl) ? (
                <Button
                  color="alpha"
                  isLoaded
                  onClick={() => {
                    window.location = currentAccount.facebookUrl;
                  }}
                  size="w-100"
                  text={
                    <svg
                      className="tw-w-6 tw-h-6 tw-mt-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
                        clipRule="evenodd"
                      />
                    </svg>
                  }
                  titleStyle={{}}
                  type="secondary"
                />
              ) : null}
              {!isEmpty(currentAccount.twitterUrl) ? (
                <Button
                  color="alpha"
                  isLoaded
                  onClick={() => {
                    window.location = currentAccount.twitterUrl;
                  }}
                  size="w-100"
                  text={
                    <svg
                      className="tw-w-6 tw-h-6 tw-mt-2"
                      fill="currentColor"
                      viewBox="0 0 22 20"
                    >
                      <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                    </svg>
                  }
                  titleStyle={{}}
                  type="secondary"
                />
              ) : null}
              {!isEmpty(currentAccount.youtubeUrl) ? (
                <Button
                  color="alpha"
                  isLoaded
                  onClick={() => {
                    window.location = currentAccount.youtubeUrl;
                  }}
                  size="w-100"
                  text={
                    <svg
                      className="tw-w-6 tw-h-6 tw-mt-2"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M23.495 6.205a3.007 3.007 0 0 0-2.088-2.088c-1.87-.501-9.396-.501-9.396-.501s-7.507-.01-9.396.501A3.007 3.007 0 0 0 .527 6.205a31.247 31.247 0 0 0-.522 5.805 31.247 31.247 0 0 0 .522 5.783 3.007 3.007 0 0 0 2.088 2.088c1.868.502 9.396.502 9.396.502s7.506 0 9.396-.502a3.007 3.007 0 0 0 2.088-2.088 31.247 31.247 0 0 0 .5-5.783 31.247 31.247 0 0 0-.5-5.805zM9.609 15.601V8.408l6.264 3.602z"
                      />
                    </svg>
                  }
                  titleStyle={{}}
                  type="secondary"
                />
              ) : null}
              {!isEmpty(currentAccount.instagramUrl) ? (
                <Button
                  color="alpha"
                  isLoaded
                  onClick={() => {
                    window.location = currentAccount.instagramUrl;
                  }}
                  size="w-100"
                  text={
                    <svg
                      className="tw-w-6 tw-h-6 tw-mt-2"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z"
                      />
                    </svg>
                  }
                  titleStyle={{}}
                  type="secondary"
                />
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
      <Notification
        HeaderIcon={color => {
          return (
            <svg
              className={`tw-h-5 tw-w-5 tw-text-${color}-400`}
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                clipRule="evenodd"
              />
            </svg>
          );
        }}
        color="error"
        headerText="Error!"
        message={error}
        setShowAction={setShowError}
        show={showError}
        type="colored"
      />
    </Fragment>
  );
};

export default ConfirmationPage;
