import React from 'react';
import PropTypes from 'prop-types';

const Avatar = ({
  text,
  size,
  color,
  hasStatus,
  statusColor,
  containerStyle,
  avatarStyle,
  statusStyle
}) => {
  function getSize() {
    switch (size) {
      case 'xs': {
        return 'tw-h-6 tw-w-6';
      }
      case 'sm': {
        return 'tw-h-8 tw-w-8';
      }
      case 'md': {
        return 'tw-h-10 tw-w-10';
      }
      case 'lg': {
        return 'tw-h-12 tw-w-12';
      }
      case 'xl': {
        return 'tw-h-14 tw-w-14';
      }
      default: {
        return 'tw-h-10 tw-w-10';
      }
    }
  }

  function getTextSize() {
    switch (size) {
      case 'xs': {
        return 'tw-text-xs';
      }
      case 'sm': {
        return 'tw-text-sm';
      }
      case 'md': {
        return '';
      }
      case 'lg': {
        return 'tw-text-lg';
      }
      case 'xl': {
        return 'tw-text-xl';
      }
      default: {
        return '';
      }
    }
  }

  function getStatusSize() {
    switch (size) {
      case 'xs': {
        return 'tw-h-1.5 tw-w-1.5';
      }
      case 'sm': {
        return 'tw-h-2 tw-w-2';
      }
      case 'md': {
        return 'tw-h-2.5 tw-w-2.5';
      }
      case 'lg': {
        return 'tw-h-3 tw-w-3';
      }
      case 'xl': {
        return 'tw-h-3.5 tw-w-3.5';
      }
      default: {
        return 'tw-h-2.5 tw-w-2.5';
      }
    }
  }

  return (
    <span
      className={`${getSize()} tw-rounded-full tw-bg-${color}-500 ${
        hasStatus
          ? 'tw-inline-block tw-relative'
          : 'tw-inline-flex tw-items-center tw-justify-center'
      }`}
      style={containerStyle}
    >
      <span
        className={`${getTextSize()} tw-font-medium tw-leading-none tw-text-white ${
          hasStatus ? 'tw-flex tw-h-full tw-items-center tw-justify-center' : ''
        }`}
        style={avatarStyle}
      >
        {text}
      </span>
      {hasStatus ? (
        <span
          className={`${getStatusSize()} tw-absolute tw-bottom-0 tw-right-0 tw-block tw-rounded-full tw-text-white tw-shadow-solid tw-bg-${statusColor}-400`}
          style={statusStyle}
        />
      ) : null}
    </span>
  );
};

Avatar.defaultProps = {
  size: 'md',
  color: 'gray',
  hasStatus: false,
  statusColor: 'gray',
  containerStyle: {},
  avatarStyle: {},
  statusStyle: {}
};

const styleProps = PropTypes.objectOf(
  PropTypes.oneOfType([PropTypes.string, PropTypes.number])
);

Avatar.propTypes = {
  text: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  color: PropTypes.oneOf([
    'alpha',
    'bravo',
    'charlie',
    'success',
    'warning',
    'error',
    'gray'
  ]),
  hasStatus: PropTypes.bool,
  statusColor: PropTypes.oneOf([
    'alpha',
    'bravo',
    'charlie',
    'success',
    'warning',
    'error',
    'gray'
  ]),
  containerStyle: styleProps,
  avatarStyle: styleProps,
  statusStyle: styleProps
};

export default Avatar;
