import styled from 'styled-components';

const Footer = styled.footer`
  position: fixed;
  bottom: 0;
  left: 70px;
  right: 0;
  width: calc(100% - 70px);
  z-index: 111;

  @media (max-width: 991.98px) {
    left: 0;
    width: 100%;
  }
`;

const Styled = {
  Footer
};

export default Styled;
