import React from 'react';
import PropTypes from 'prop-types';
import { formatTimestamp } from 'lib/utils/dateTime';
import MultiSelectCards from 'components/Theme/MultiSelectCards';

const StepTwo = ({
  productSubscriptions,
  currentTimezone,
  onChange,
  productsRequiredToRemoveCount,
  selectedRequiredAmount,
  selectedProducts
}) => {
  return (
    <div>
      <div className="tw-my-3 tw-gray-500">
        Changing your plan will result in{' '}
        <b>losing {productsRequiredToRemoveCount} products</b>. When you remove
        a product, all campaigns for that product will be automatically
        concluded for your contacts. Select the products you want to remove to
        continue.
      </div>
      <div className="tw-max-h-64 tw-overflow-y-scroll tw-mt-5">
        <MultiSelectCards
          values={selectedProducts}
          onChange={onChange}
          canSelectMore={!selectedRequiredAmount}
          options={productSubscriptions.map(productSubscription => {
            const launchedAt = formatTimestamp(
              productSubscription.created_at,
              currentTimezone,
              false,
              'MM/DD/YYYY',
              true
            );
            return {
              title: productSubscription.name,
              subtitle: `Launched At: ${launchedAt}`,
              rightText: productSubscription.contacts_in_campaign_text,
              value: productSubscription.product_id
            };
          })}
        />
      </div>
    </div>
  );
};

StepTwo.defaultProps = {
  productSubscriptions: () => null,
  currentTimezone: '',
  onChange: () => null,
  productsRequiredToRemoveCount: 0,
  selectedProducts: [],
  selectedRequiredAmount: true
};

StepTwo.propTypes = {
  productSubscriptions: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  ),
  selectedProducts: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  ),
  currentTimezone: PropTypes.string,
  onChange: PropTypes.func,
  productsRequiredToRemoveCount: PropTypes.number,
  selectedRequiredAmount: PropTypes.bool
};

export default StepTwo;
