import React from 'react';
import PropTypes from 'prop-types';

const BULLET_TYPES = {
  SOLID: 'solid',
  RADIO: 'radio',
  CHECKED: 'checked'
};

const ProgressBar = ({
  completed,
  steps,
  showSteps,
  showStepNumber,
  fillerColor,
  compressed,
  bulletStyle,
  styled,
  className
}) => {
  function getBulletStyle(stepNumber) {
    if (styled)
      if (stepNumber === completed) return BULLET_TYPES.RADIO;
      else return BULLET_TYPES.CHECKED;
    return bulletStyle;
  }

  const responsiveDesign = !showSteps;

  return (
    <div className={`tw-table ${className || ''}`}>
      {steps.map((step, index) => (
        <Step
          key={step.text}
          stepNumber={index + 1}
          completed={completed}
          totalSteps={steps.length}
          fillerColor={fillerColor}
          compressed={styled || compressed}
          bulletStyle={getBulletStyle(index + 1)}
          styled={styled}
          responsiveDesign={responsiveDesign}
        />
      ))}
      {showSteps && (
        <div className="tw-table-row tw-h-15">
          {steps.map((step, index) => (
            <StepText
              key={step.text}
              stepNumber={index + 1}
              stepText={step.text}
              showStepNumber={showStepNumber}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const Step = ({
  stepNumber,
  completed,
  totalSteps,
  fillerColor,
  compressed,
  bulletStyle,
  styled,
  responsiveDesign
}) => {
  const completeFirstBar = stepNumber <= completed;
  const completeSecondBar = stepNumber < completed;

  function getBulletClass(bullet) {
    if (styled && bullet === BULLET_TYPES.RADIO) return 'tw-px-1.5 tw-py-1.5';
    return 'tw-px-1 tw-py-1';
  }

  return (
    <div className="tw-table-cell">
      <Bar
        complete={completeFirstBar}
        radiusClass={stepNumber === 1 ? `tw-rounded-l-full` : null}
        fillerColor={stepNumber === 1 ? 'transparent' : fillerColor}
        compressed={compressed}
        responsiveDesign={responsiveDesign}
      />
      <Bullet
        complete={completeFirstBar}
        fillerColor={fillerColor}
        bulletStyle={bulletStyle}
        bulletClasses={getBulletClass(bulletStyle)}
      />
      <Bar
        complete={completeSecondBar}
        radiusClass={stepNumber === totalSteps ? `tw-rounded-r-full` : null}
        fillerColor={stepNumber === totalSteps ? 'transparent' : fillerColor}
        compressed={compressed}
        responsiveDesign={responsiveDesign}
      />
    </div>
  );
};

const Bar = ({
  complete,
  radiusClass,
  fillerColor,
  compressed,
  responsiveDesign
}) => {
  const barColor =
    fillerColor === 'black'
      ? 'gray-700'
      : fillerColor === 'transparent'
      ? fillerColor
      : `${fillerColor}-500`;

  const widthClasses = responsiveDesign
    ? 'lg:tw-px-11 md:tw-px-5 xs:tw-px-3 xxs:tw-px-1.5'
    : 'tw-px-11';

  return (
    <div className="tw-table-cell tw-align-middle">
      <div
        className={`
          tw-bg-${
            complete || barColor === 'transparent' ? barColor : 'gray-200'
          }
          tw-h-${compressed ? '0.5' : '1'}
          tw-table-cell
          tw-align-middle
          ${widthClasses}
          ${radiusClass}
        `}
      />
    </div>
  );
};

const Bullet = ({ complete, fillerColor, bulletStyle, bulletClasses }) => {
  const bulletColor =
    fillerColor === 'black' ? 'gray-700' : `${fillerColor}-500`;

  const radioBullet = bulletStyle === BULLET_TYPES.RADIO;
  const checkedBullet = bulletStyle === BULLET_TYPES.CHECKED;

  const bgClasses =
    complete && radioBullet
      ? `tw-bg-transparent`
      : `tw-bg-${complete ? bulletColor : 'gray-200'}`;

  const containedElement = checkedBullet ? (
    <div className="tw-h-3 tw-w-3">
      {complete && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="-1 4 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
            clipRule="evenodd"
          />
        </svg>
      )}
    </div>
  ) : (
    <div
      className={`tw-bg-${
        complete ? bulletColor : 'gray-200'
      } tw-h-2 tw-w-2 tw-rounded-full tw-mx-auto`}
    />
  );

  return (
    <div
      className={`
        tw-h-4
        tw-text-white
        tw-rounded-full
        tw-table-cell
        tw-align-middle
        tw-text-center
        tw-border
        tw-border-solid
        tw-border-${complete ? bulletColor : 'gray-200'}
        ${bgClasses}
        ${bulletClasses}
      `}
    >
      {containedElement}
    </div>
  );
};

const StepText = ({ stepText, stepNumber, showStepNumber }) => {
  return (
    <div
      className="
        tw-text-gray-700
        tw-text-sm
        tw-leading-5
        tw-font-normal
        tw-table-cell
        tw-text-center
        tw-align-middle
      "
    >
      {showStepNumber ? <div> Step {stepNumber} </div> : null}
      <div
        className="
        tw-text-gray-700
        tw-font-medium
      "
      >
        {stepText}
      </div>
    </div>
  );
};

const stepShaper = PropTypes.shape({
  number: PropTypes.string,
  text: PropTypes.string
});

ProgressBar.defaultProps = {
  steps: [],
  completed: 0,
  showSteps: true,
  showStepNumber: true,
  fillerColor: 'alpha',
  compressed: false,
  bulletStyle: BULLET_TYPES.SOLID,
  className: '',
  styled: false
};

ProgressBar.propTypes = {
  steps: PropTypes.arrayOf(stepShaper),
  completed: PropTypes.number,
  showSteps: PropTypes.bool,
  showStepNumber: PropTypes.bool,
  compressed: PropTypes.bool,
  className: PropTypes.string,
  styled: PropTypes.bool,
  bulletStyle: PropTypes.oneOf(Object.values(BULLET_TYPES)),
  fillerColor: PropTypes.oneOf([
    'alpha',
    'bravo',
    'charlie',
    'success',
    'warning',
    'error',
    'gray',
    'black'
  ])
};

Step.defaultProps = {
  stepNumber: 1,
  completed: 0,
  totalSteps: 1,
  compressed: false,
  bulletStyle: BULLET_TYPES.SOLID,
  styled: false,
  responsiveDesign: false
};

Step.propTypes = {
  stepNumber: PropTypes.number,
  completed: PropTypes.number,
  totalSteps: PropTypes.number,
  fillerColor: PropTypes.string.isRequired,
  compressed: PropTypes.bool,
  styled: PropTypes.bool,
  bulletStyle: PropTypes.oneOf(Object.values(BULLET_TYPES)),
  responsiveDesign: PropTypes.bool
};

Bar.defaultProps = {
  complete: false,
  radiusClass: null,
  compressed: false,
  responsiveDesign: false
};

Bar.propTypes = {
  complete: PropTypes.bool,
  radiusClass: PropTypes.string,
  fillerColor: PropTypes.string.isRequired,
  compressed: PropTypes.bool,
  responsiveDesign: PropTypes.bool
};

Bullet.defaultProps = {
  complete: false,
  bulletStyle: BULLET_TYPES.SOLID,
  bulletClasses: ''
};

Bullet.propTypes = {
  complete: PropTypes.bool,
  fillerColor: PropTypes.string.isRequired,
  bulletClasses: PropTypes.string,
  bulletStyle: PropTypes.oneOf(Object.values(BULLET_TYPES))
};

StepText.defaultProps = {
  stepNumber: 1,
  showStepNumber: true
};

StepText.propTypes = {
  stepText: PropTypes.string.isRequired,
  stepNumber: PropTypes.number,
  showStepNumber: PropTypes.bool
};

export { BULLET_TYPES };
export default ProgressBar;
