import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const BulletSteps = ({
  steps,
  showStepNumbers,
  color,
  currentStep,
  setCurrentStep,
  containerClassNames,
  stepClassNames,
  containerStyle,
  stepStyle,
  disableClick
}) => {
  const [selectedStep, setSelectedStep] = useState(currentStep);
  const disableClickArray = [];

  useEffect(() => {
    setSelectedStep(currentStep);
  }, [currentStep]);

  let stepClickDisabled = false;
  if (steps && steps.length) {
    steps.forEach((item, index) => {
      if (stepClickDisabled) {
        disableClickArray.push(index);
      }
      if (item.isBtnDisable) {
        stepClickDisabled = true;
      }
    });
  }

  function handleStepClick(index) {
    const clickDisabled = disableClick || disableClickArray.indexOf(index) > -1;
    if (clickDisabled) return;

    if (setCurrentStep) {
      setCurrentStep(index);
    } else {
      setSelectedStep(index);
    }
  }

  const Step = (step, index) => {
    // Upcoming Step
    let stepBlock = (
      <a
        data-testid={`current-step-${index}`}
        className="tw-block tw-w-2.5 tw-h-2.5 tw-bg-gray-200 tw-rounded-full hover:tw-bg-gray-400 focus:tw-bg-gray-400"
        onClick={() => handleStepClick(index)}
        onKeyDown={null}
        role="button"
        tabIndex={-1}
      />
    );

    if (index < selectedStep) {
      // Completed Step
      stepBlock = (
        <a
          data-testid={`completed-step-${index}`}
          className={`tw-block tw-w-2.5 tw-h-2.5 tw-bg-${color}-600 tw-rounded-full hover:tw-bg-${color}-900 focus:tw-bg-${color}-900`}
          onClick={() => handleStepClick(index)}
          onKeyDown={null}
          role="button"
          tabIndex={-1}
        />
      );
    }

    if (index === selectedStep) {
      // Current Step
      stepBlock = (
        <div className="tw-relative tw-flex tw-items-center tw-justify-center">
          <span className="tw-absolute tw-w-5 tw-h-5 tw-p-px tw-flex">
            <span
              className={`tw-w-full tw-h-full tw-rounded-full tw-bg-${color}-200`}
            />
          </span>
          <span
            className={`tw-relative tw-block tw-w-2.5 tw-h-2.5 tw-bg-${color}-600 tw-rounded-full`}
          />
        </div>
      );
    }

    return (
      <li
        className={`tw-ml-5 tw-cursor-pointer ${stepClassNames}`}
        key={`${index}-${step.text}`}
        style={stepStyle}
      >
        {stepBlock}
      </li>
    );
  };

  return (
    <nav
      className={`tw-flex tw-items-center tw-justify-center ${containerClassNames}`}
      style={containerStyle}
    >
      {showStepNumbers ? (
        <p className="tw-text-sm tw-leading-5 tw-font-medium tw-mb-0 tw-font-body">
          Step {selectedStep ? selectedStep + 1 : 1} of {steps.length}
        </p>
      ) : null}
      <ul
        className={`tw-flex tw-items-center ${
          showStepNumbers ? 'tw-ml-8' : ''
        }`}
        style={{
          listStyle: 'none',
          paddingInlineStart: '0px'
        }}
      >
        {steps.map((step, index) => Step(step, index))}
      </ul>
    </nav>
  );
};

BulletSteps.defaultProps = {
  showStepNumbers: false,
  color: 'alpha',
  currentStep: 0,
  setCurrentStep: null,
  containerClassNames: '',
  stepClassNames: '',
  containerStyle: {},
  stepStyle: {},
  disableClick: false
};

const styleTypes = PropTypes.oneOfType([PropTypes.string, PropTypes.number]);
const nodeType = PropTypes.oneOfType([PropTypes.func, PropTypes.node]);

BulletSteps.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      text: PropTypes.string,
      buttonText: PropTypes.string,
      content: nodeType,
      action: PropTypes.function
    })
  ).isRequired,
  showStepNumbers: PropTypes.bool,
  currentStep: PropTypes.number,
  setCurrentStep: PropTypes.func,
  color: PropTypes.oneOf([
    'alpha',
    'bravo',
    'charlie',
    'success',
    'warning',
    'error',
    'gray',
    'black'
  ]),
  containerClassNames: PropTypes.string,
  stepClassNames: PropTypes.string,
  containerStyle: PropTypes.objectOf(styleTypes),
  stepStyle: PropTypes.objectOf(styleTypes),
  disableClick: PropTypes.bool
};

export default BulletSteps;
