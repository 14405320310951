export const CREATE_TASK = 'task/CREATE_TASK';
export const CREATE_TASK_REQUEST = `${CREATE_TASK}_REQUEST`;
export const CREATE_TASK_SUCCESS = `${CREATE_TASK}_SUCCESS`;
export const CREATE_TASK_FAILURE = `${CREATE_TASK}_FAILURE`;

export const UPDATE_TASK = 'task/UPDATE_TASK';
export const UPDATE_TASK_REQUEST = `${UPDATE_TASK}_REQUEST`;
export const UPDATE_TASK_SUCCESS = `${UPDATE_TASK}_SUCCESS`;
export const UPDATE_TASK_FAILURE = `${UPDATE_TASK}_FAILURE`;

export const FETCH_TASK = 'task/FETCH_TASK';
export const FETCH_TASK_REQUEST = `${FETCH_TASK}_REQUEST`;
export const FETCH_TASK_SUCCESS = `${FETCH_TASK}_SUCCESS`;
export const FETCH_TASK_FAILURE = `${FETCH_TASK}_FAILURE`;
