import React, { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useParams, useRouteMatch } from 'react-router-dom';
import { titleize } from 'lib/utils/string';
import { useFormik } from 'formik';
import isEmpty from 'lodash.isempty';
import {
  createErrorMessageSelector,
  createLoadingSelector,
  createLoadedSelector
} from 'appState/selectors';
import {
  fetchProduct,
  createProduct,
  updateProduct,
  setOverlordLayoutHeaderText,
  fetchProductsCategories
} from 'appState/actions/ActionCreators';
import Card from 'components/Theme/Card';
import HeroIcon from 'components/Theme/HeroIcon';
import AutoCompleteDropDown from 'components/Theme/AutoCompleteDropDown';
import PageHeader from 'components/Theme/PageHeader';
import Select from 'components/Theme/Select';
import Table from 'components/Theme/Table';
import TextArea from 'components/Theme/TextArea';
import TextInput from 'components/Theme/TextInput';
import FileInput from 'components/Theme/FileInput';
import MultiSelect from 'components/Theme/MultiSelect';
import Notification from 'components/Theme/Notification';
import Toggle from 'components/Theme/Toggle';
import { productSchema } from 'lib/validation/schema';
import iconNames from 'components/Theme/HeroIcon/iconNames';

const ProductBuilder = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { id, partnerId } = params;
  const { path } = useRouteMatch();
  const isCreating = path.includes('create');

  const iconOptions = Object.keys(iconNames).map(i => ({
    displayName: titleize(i, '-'),
    value: i,
    icon: <HeroIcon icon={i} />
  }));

  const [showCreateError, setShowCreateError] = useState(false);
  const [iconSuggestions, setIconSuggestions] = useState([]);
  const [showUpdateError, setShowUpdateError] = useState(false);
  const [showCreateSuccess, setShowCreateSuccess] = useState(false);
  const [showUpdateSuccess, setShowUpdateSuccess] = useState(false);

  const createErrorSelector = createErrorMessageSelector([
    'product/CREATE_PRODUCT'
  ]);
  const updateErrorSelector = createErrorMessageSelector([
    'product/UPDATE_PRODUCT'
  ]);
  const loadingSelectorCreate = createLoadingSelector([
    'product/CREATE_PRODUCT'
  ]);
  const loadingSelectorUpdate = createLoadingSelector([
    'product/UPDATE_PRODUCT'
  ]);
  const loadedSelectorCreate = createLoadedSelector(['product/CREATE_PRODUCT']);
  const loadedSelectorUpdate = createLoadedSelector(['product/UPDATE_PRODUCT']);

  useEffect(() => {
    if (isCreating) dispatch(fetchProductsCategories());
    else dispatch(fetchProduct({ productId: id }));
  }, [id, dispatch]);

  const structuredSelector = createStructuredSelector({
    currentProduct: state => state.product.currentProduct,
    isCreateLoading: state => loadingSelectorCreate(state),
    isCreateLoaded: state => loadedSelectorCreate(state),
    createError: state => createErrorSelector(state),
    updateError: state => updateErrorSelector(state),
    isUpdateLoading: state => loadingSelectorUpdate(state),
    isUpdateLoaded: state => loadedSelectorUpdate(state)
  });

  const {
    currentProduct,
    createError,
    updateError,
    isCreateLoading,
    isCreateLoaded,
    isUpdateLoading,
    isUpdateLoaded
  } = useSelector(structuredSelector);

  useEffect(() => {
    let headerText = 'Product';
    if (currentProduct?.name) headerText = `Product - ${currentProduct.name}`;
    dispatch(setOverlordLayoutHeaderText(headerText));
  }, [currentProduct]);

  function handleSubmit(values) {
    const productParams = { partner_id: partnerId };
    if (Object.keys(values).length)
      Object.keys(values).forEach(function assignParams(key) {
        productParams[key] = values[key];
      });
    if (isCreating) return dispatch(createProduct({ productParams }));
    return dispatch(updateProduct({ productId: id, productParams }));
  }

  function leadCampaignsOptions() {
    if (currentProduct.lead_product_campaigns) {
      return currentProduct.lead_product_campaigns.map(campaign => {
        return { displayName: campaign.name, value: campaign.id };
      });
    }
    return [];
  }

  function clientCampaignsOptions() {
    if (currentProduct.client_product_campaigns) {
      return currentProduct.client_product_campaigns.map(campaign => {
        return { displayName: campaign.name, value: campaign.id };
      });
    }
    return [];
  }

  function campaignsOptions() {
    if (currentProduct.product_campaigns) {
      return currentProduct.product_campaigns.map(campaign => {
        return { displayName: campaign.name, value: campaign.id };
      });
    }
    return [];
  }

  const formik = useFormik({
    initialValues: {
      name: currentProduct?.name || '',
      featured: currentProduct?.featured || false,
      description: currentProduct?.description || '',
      category: currentProduct?.category || '',
      lifecycles: currentProduct?.lifecycles || '',
      heroImage: currentProduct?.hero_image || '',
      purchasePageImageOne: currentProduct?.purchase_page_image_one || '',
      purchasePageImageTwo: currentProduct?.purchase_page_image_two || '',
      contentFeatureOneTitle: currentProduct?.content_feature_one_title || '',
      contentFeatureTwoTitle: currentProduct?.content_feature_two_title || '',
      contentFeatureThreeTitle:
        currentProduct?.content_feature_three_title || '',
      contentFeatureOneSubtitle:
        currentProduct?.content_feature_one_subtitle || '',
      contentFeatureTwoSubtitle:
        currentProduct?.content_feature_two_subtitle || '',
      contentFeatureThreeSubtitle:
        currentProduct?.content_feature_three_subtitle || '',
      dashboardTopMetricPositionOne:
        currentProduct?.dashboard_top_metric_position_one || '',
      dashboardTopMetricPositionTwo:
        currentProduct?.dashboard_top_metric_position_two || '',
      dashboardTopMetricPositionThree:
        currentProduct?.dashboard_top_metric_position_three || '',
      price: currentProduct?.pricing_information?.price || '',
      onboardingFeeId:
        currentProduct?.pricing_information?.onboarding_fee_id || '',
      stripePlan: currentProduct?.pricing_information?.stripe_plan || '',
      newLeadCampaign: currentProduct.new_lead_campaign_id || null
    },
    enableReinitialize: true,
    validationSchema: productSchema,
    onSubmit: values => {
      handleSubmit(values);
    }
  });

  useEffect(() => {
    // Set initial field values from server on componentDidMount
    if (!isEmpty(currentProduct)) {
      formik.setFieldValue(
        'contentFeatureOneIcon',
        currentProduct.content_feature_one_icon
      );

      formik.setFieldValue(
        'contentFeatureTwoIcon',
        currentProduct.content_feature_two_icon
      );

      formik.setFieldValue(
        'contentFeatureThreeIcon',
        currentProduct.content_feature_three_icon
      );

      formik.setFieldValue('heroImage', currentProduct.hero_image);

      formik.setFieldValue(
        'purchasePageImageOne',
        currentProduct.purchase_page_image_one
      );
      formik.setFieldValue(
        'purchasePageImageTwo',
        currentProduct.purchase_page_image_two
      );
    }
  }, [currentProduct]);

  function onLifecyclesChange(lifecycles) {
    formik.setFieldValue('lifecycles', lifecycles);
  }

  useEffect(() => {
    if (isCreateLoaded && isEmpty(createError)) {
      setShowCreateSuccess(true);
      window.location.href = `/overlord/partners/${partnerId}/products/${currentProduct.currentProductId}/`;
    }

    if (isCreateLoaded && !isEmpty(createError)) {
      setShowCreateError(true);
    }
  }, [createError, isCreateLoaded, dispatch]);

  useEffect(() => {
    if (isUpdateLoaded && isEmpty(updateError)) {
      setShowUpdateSuccess(true);
    }

    if (isUpdateLoaded && !isEmpty(updateError)) {
      setShowUpdateError(true);
    }
  }, [updateError, isUpdateLoaded, dispatch]);

  function onIconChange(iconQuery) {
    const q = iconQuery.toLowerCase();
    setIconSuggestions(
      iconOptions
        .filter(i => i.value.indexOf(q) !== -1)
        .sort((a, b) => {
          return a.value.indexOf(q) - b.value.indexOf(q);
        })
    );
  }

  function onIconSelection(iconValue) {
    formik.setFieldValue('contentFeatureOneIcon', iconValue);
    setIconSuggestions([]);
  }

  function onIconTwoSelection(iconValue) {
    formik.setFieldValue('contentFeatureTwoIcon', iconValue);
    setIconSuggestions([]);
  }

  function onIconThreeSelection(iconValue) {
    formik.setFieldValue('contentFeatureThreeIcon', iconValue);
    setIconSuggestions([]);
  }

  function onHeroImageChange(image) {
    formik.setFieldValue('heroImage', image);
  }

  function onPurchasePageImageOneChange(image) {
    formik.setFieldValue('purchasePageImageOne', image);
  }

  function onPurchasePageImageTwoChange(image) {
    formik.setFieldValue('purchasePageImageTwo', image);
  }

  function onFeaturedChange(_ref, featured) {
    formik.setFieldValue('featured', featured);
  }

  function extractFileName(file) {
    if (file instanceof File) {
      return file.name;
    }
    if (file && file.url) {
      // eslint-disable-next-line no-useless-escape
      const match = /[^/\\&\?]+\.\w{3,4}(?=([\?&].*$|$))/.exec(file.url);
      const filename = match !== null ? match[0] : '';
      return filename;
    }
    return '';
  }

  function onEditProductCampaign(campaignId) {
    if (currentProduct.product_campaigns) {
      const campaign = currentProduct.product_campaigns.filter(
        c => c.id === campaignId
      )[0];
      window.location = `/partners/${campaign.partner_id}/products/${campaign.product_id}/product_campaigns/${campaign.id}`;
    }
  }

  function editContactFormType(contactFormTypeId) {
    if (currentProduct.product_campaigns) {
      const contactFormType = currentProduct.contact_form_types.filter(
        c => c.id === contactFormTypeId
      )[0];
      window.location = `/overlord/partners/${partnerId}/products/${id}/included_forms/${contactFormType.id}/edit`;
    }
  }

  function newCampaign() {
    if (!isEmpty(currentProduct)) {
      window.location = `/partners/${partnerId}/products/${id}/product_campaigns/new`;
    }
  }

  function newForm() {
    if (!isEmpty(currentProduct)) {
      window.location = `/overlord/partners/${partnerId}/products/${id}/included_forms/create`;
    }
  }

  function handleDashboardMetricOneChange(values) {
    formik.setFieldValue('dashboardTopMetricPositionOne', values);
  }

  function handleDashboardMetricTwoChange(values) {
    formik.setFieldValue('dashboardTopMetricPositionTwo', values);
  }

  function handleDashboardMetricThreeChange(values) {
    formik.setFieldValue('dashboardTopMetricPositionThree', values);
  }

  function getMetricSelectors() {
    const leadMetricSelector = (
      <MultiSelect
        id="dashboardTopMetricPositionOne"
        value={formik.values.dashboardTopMetricPositionOne}
        options={leadCampaignsOptions()}
        labelText="New Lead Metric"
        onChange={handleDashboardMetricOneChange}
        onBlur={formik.handleBlur}
        showError={
          formik.touched.dashboardTopMetricPositionOne &&
          !!formik.errors.dashboardTopMetricPositionOne
        }
        error={formik.errors.dashboardTopMetricPositionOne}
        widthClass="tw-col-span-12 sm:tw-col-span-6"
      />
    );
    const clientMetricSelector = (
      <MultiSelect
        id="dashboardTopMetricPositionTwo"
        value={formik.values.dashboardTopMetricPositionTwo}
        options={clientCampaignsOptions()}
        labelText="New Client Metric"
        onChange={handleDashboardMetricTwoChange}
        onBlur={formik.handleBlur}
        showError={
          formik.touched.dashboardTopMetricPositionTwo &&
          !!formik.errors.dashboardTopMetricPositionTwo
        }
        error={formik.errors.dashboardTopMetricPositionTwo}
        widthClass="tw-col-span-12 sm:tw-col-span-6"
      />
    );
    const cancellationMetricSelector = (
      <MultiSelect
        id="dashboardTopMetricPositionThree"
        value={formik.values.dashboardTopMetricPositionThree}
        options={campaignsOptions()}
        labelText="Pending Cancellation Metric"
        onChange={handleDashboardMetricThreeChange}
        onBlur={formik.handleBlur}
        showError={
          formik.touched.dashboardTopMetricPositionThree &&
          !!formik.errors.dashboardTopMetricPositionThree
        }
        error={formik.errors.dashboardTopMetricPositionThree}
        widthClass="tw-col-span-12 sm:tw-col-span-6"
      />
    );

    return (
      <Fragment>
        {formik.values.lifecycles.includes('lead') && leadMetricSelector}
        {formik.values.lifecycles.includes('client') && clientMetricSelector}
        {formik.values.lifecycles.includes('former_client') &&
          cancellationMetricSelector}
      </Fragment>
    );
  }

  return (
    <div>
      <PageHeader
        title="Settings"
        breadcrumbs={[
          {
            name: 'Overlord',
            action: () => {
              window.location = '/overlord/';
            }
          },
          {
            name: 'Partners',
            action: () => {
              window.location = `/overlord/partners`;
            }
          },
          {
            name: "Partner's Products",
            action: () => {
              window.location = `/overlord/partners/${partnerId}`;
            }
          }
        ]}
        primaryActionText={isCreating ? 'Save' : 'Update'}
        primaryAction={formik.handleSubmit}
        isLoaded={!isCreateLoading && !isUpdateLoading}
      />
      <div className="tw-max-w-7xl tw-mx-auto tw-py-20 sm:tw-px-6 lg:tw-px-8">
        <div>
          <div className="md:tw-grid md:tw-grid-cols-4 md:tw-gap-6">
            <div className="md:tw-col-span-1">
              <div className="tw-px-4 sm:tw-px-0">
                <h3 className="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900">
                  Product Details
                </h3>
                <p className="tw-mt-1 tw-text-sm tw-leading-5 tw-text-gray-600">
                  Some text here about what these details are.
                </p>
              </div>
            </div>
            <div className="tw-mt-5 md:tw-mt-0 md:tw-col-span-3">
              <Card containerClass="tw-shadow sm:tw-rounded-md tw-bg-white">
                <div className="tw-px-4 tw-py-5 sm:tw-p-6">
                  <div className="tw-grid tw-grid-cols-12 tw-gap-6">
                    <TextInput
                      id="name"
                      value={formik.values.name}
                      labelText="Name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      showError={formik.touched.name && !!formik.errors.name}
                      error={formik.errors.name}
                      widthClass="tw-col-span-12 sm:tw-col-span-6"
                    />

                    <TextArea
                      id="description"
                      rows={3}
                      value={formik.values.description}
                      labelText="Description"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      showError={
                        formik.touched.description &&
                        !!formik.errors.description
                      }
                      error={formik.errors.description}
                      widthClass="tw-col-span-12"
                    />

                    <Select
                      id="category"
                      value={formik.values.category}
                      options={currentProduct.product_categories.map(cat => {
                        return { displayName: cat, value: cat };
                      })}
                      labelText="Category"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      showError={
                        formik.touched.category && !!formik.errors.category
                      }
                      error={formik.errors.category}
                      widthClass="tw-col-span-12 sm:tw-col-span-6"
                    />
                    <MultiSelect
                      id="lifecycles"
                      value={formik.values.lifecycles}
                      options={[
                        { displayName: 'Lead', value: 'lead' },
                        { displayName: 'Client', value: 'client' },
                        { displayName: 'Former Client', value: 'former_client' }
                      ]}
                      labelText="Lifecycles Covered"
                      onChange={onLifecyclesChange}
                      onBlur={formik.handleBlur}
                      showError={
                        formik.touched.lifecycles && !!formik.errors.lifecycles
                      }
                      error={formik.errors.lifecycles}
                      widthClass="tw-col-span-12 sm:tw-col-span-6"
                    />
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>

        {!isCreating && (
          <Fragment>
            <div className="tw-hidden sm:tw-block">
              <div className="tw-py-5">
                <div className="tw-border-t tw-border-b-0 tw-border-l-0 tw-border-r-0 tw-border-solid tw-border-gray-200" />
              </div>
            </div>

            <div className="tw-mt-10 sm:tw-mt-0">
              <div className="md:tw-grid md:tw-grid-cols-4 md:tw-gap-6">
                <div className="md:tw-col-span-1">
                  <div className="tw-px-4 sm:tw-px-0">
                    <h3 className="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900">
                      Campaigns
                    </h3>
                    <p className="tw-mt-1 tw-text-sm tw-leading-5 tw-text-gray-600">
                      This lists out the campaigns that are currently on this
                      product.
                    </p>
                  </div>
                </div>
                <div className="tw-mt-5 md:tw-mt-0 md:tw-col-span-3">
                  <Table
                    rows={currentProduct?.product_campaigns}
                    rowConditionals={() => ({})}
                    includedColumns={['name']}
                    editable
                    onEdit={campaign => onEditProductCampaign(campaign)}
                    emptyMessage="No campaigns tied to this product"
                    hasFooter
                    footerAction={newCampaign}
                    footerActionText="New Campaign"
                  />
                </div>
              </div>
            </div>

            <div className="tw-hidden sm:tw-block">
              <div className="tw-py-5">
                <div className="tw-border-t tw-border-b-0 tw-border-l-0 tw-border-r-0 tw-border-solid tw-border-gray-200" />
              </div>
            </div>

            <div className="tw-mt-10 sm:tw-mt-0">
              <div className="md:tw-grid md:tw-grid-cols-4 md:tw-gap-6">
                <div className="md:tw-col-span-1">
                  <div className="tw-px-4 sm:tw-px-0">
                    <h3 className="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900">
                      Included Forms
                    </h3>
                    <p className="tw-mt-1 tw-text-sm tw-leading-5 tw-text-gray-600">
                      This lists out the included contact form types that will
                      show up in any account who has this product.
                    </p>
                  </div>
                </div>
                <div className="tw-mt-5 md:tw-mt-0 md:tw-col-span-3">
                  <Table
                    rows={currentProduct?.contact_form_types}
                    rowConditionals={() => ({})}
                    includedColumns={['name']}
                    editable
                    onEdit={contactFormTypeId =>
                      editContactFormType(contactFormTypeId)
                    }
                    emptyMessage="No included forms tied to this product"
                    hasFooter
                    footerAction={newForm}
                    footerActionText="New Form"
                  />
                </div>
              </div>
            </div>

            <div className="tw-hidden sm:tw-block">
              <div className="tw-py-5">
                <div className="tw-border-t tw-border-b-0 tw-border-l-0 tw-border-r-0 tw-border-solid tw-border-gray-200" />
              </div>
            </div>
            {formik.values.lifecycles.includes('lead') && (
              <Fragment>
                <div className="tw-mt-10 sm:tw-mt-0">
                  <div className="md:tw-grid md:tw-grid-cols-4 md:tw-gap-6">
                    <div className="md:tw-col-span-1">
                      <div className="tw-px-4 sm:tw-px-0">
                        <h3 className="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900">
                          Entry Campaign
                        </h3>
                        <p className="tw-mt-1 tw-text-sm tw-leading-5 tw-text-gray-600">
                          Complete these settings to setup the entry campaign
                          for this product.
                        </p>
                      </div>
                    </div>
                    <div className="tw-mt-5 md:tw-mt-0 md:tw-col-span-3">
                      <Card containerClass="tw-shadow sm:tw-rounded-md tw-bg-white">
                        <div className="tw-px-4 tw-py-5 sm:tw-p-6">
                          <div className="tw-grid tw-grid-cols-12 tw-gap-6">
                            <Select
                              id="newLeadCampaign"
                              value={formik.values.newLeadCampaign}
                              options={currentProduct.product_campaigns
                                .filter(pc => {
                                  return pc.lifecycle === 'lead';
                                })
                                .map(pc => {
                                  return {
                                    displayName: pc.name,
                                    value: pc.id
                                  };
                                })}
                              labelText="New Lead Entry Campaign"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              showError={
                                formik.touched.newLeadCampaign &&
                                !!formik.errors.newLeadCampaign
                              }
                              error={formik.errors.newLeadCampaign}
                              widthClass="tw-col-span-12 sm:tw-col-span-6"
                            />
                          </div>
                        </div>
                      </Card>
                    </div>
                  </div>
                </div>
                <div className="tw-hidden sm:tw-block">
                  <div className="tw-py-5">
                    <div className="tw-border-t tw-border-b-0 tw-border-l-0 tw-border-r-0 tw-border-solid tw-border-gray-200" />
                  </div>
                </div>
              </Fragment>
            )}

            <div className="tw-mt-10 sm:tw-mt-0">
              <div className="md:tw-grid md:tw-grid-cols-4 md:tw-gap-6">
                <div className="md:tw-col-span-1">
                  <div className="tw-px-4 sm:tw-px-0">
                    <h3 className="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900">
                      Marketing Settings
                    </h3>
                    <p className="tw-mt-1 tw-text-sm tw-leading-5 tw-text-gray-600">
                      Complete these settings to setup the purchase page for
                      this product.
                    </p>
                  </div>
                </div>
                <div className="tw-mt-5 md:tw-mt-0 md:tw-col-span-3">
                  <Card containerClass="tw-shadow sm:tw-rounded-md tw-bg-white">
                    <div className="tw-px-4 tw-py-5 sm:tw-p-6">
                      <div className="tw-grid tw-grid-cols-12 tw-gap-6">
                        <div className="tw-col-span-12">
                          <div className="tw-rounded-md tw-bg-gray-100 tw-px-6 tw-py-5 tw-flex tw-items-center tw-justify-between">
                            <span
                              className="tw-flex-grow tw-flex tw-flex-col"
                              id="toggleLabel"
                            >
                              <span className="tw-font-body tw-block tw-text-sm tw-font-medium tw-leading-5 tw-text-gray-700">
                                Featured?
                              </span>
                              <span className="tw-text-sm tw-leading-normal tw-text-gray-600">
                                Do you want this product to show up in the
                                featured list for the marketplace?
                              </span>
                            </span>
                            <Toggle
                              color="alpha"
                              onClick={onFeaturedChange}
                              isToggled={formik.values.featured}
                            />
                          </div>
                          <div className="tw-hidden sm:tw-block">
                            <div className="tw-py-5">
                              <div className="tw-border-t tw-border-b-0 tw-border-l-0 tw-border-r-0 tw-border-solid tw-border-gray-200" />
                            </div>
                          </div>
                        </div>
                        <FileInput
                          id="hero_image"
                          value={extractFileName(formik.values.heroImage)}
                          labelText="Hero Image"
                          onChange={onHeroImageChange}
                          onBlur={formik.handleBlur}
                          showError={
                            formik.touched.heroImage &&
                            !!formik.errors.heroImage
                          }
                          error={formik.errors.heroImage}
                          widthClass="tw-col-span-12 sm:tw-col-span-4"
                        />
                        <FileInput
                          id="image"
                          value={extractFileName(
                            formik.values.purchasePageImageOne
                          )}
                          labelText="Purchase Page Image One"
                          onChange={onPurchasePageImageOneChange}
                          onBlur={formik.handleBlur}
                          showError={
                            formik.touched.purchasePageImageOne &&
                            !!formik.errors.purchasePageImageOne
                          }
                          error={formik.errors.purchasePageImageOne}
                          widthClass="tw-col-span-12 sm:tw-col-span-4"
                        />
                        <FileInput
                          id="image"
                          value={extractFileName(
                            formik.values.purchasePageImageTwo
                          )}
                          labelText="Purchase Page Image Two"
                          onChange={onPurchasePageImageTwoChange}
                          onBlur={formik.handleBlur}
                          showError={
                            formik.touched.purchasePageImageTwo &&
                            !!formik.errors.purchasePageImageTwo
                          }
                          error={formik.errors.purchasePageImageTwo}
                          widthClass="tw-col-span-12 sm:tw-col-span-4"
                        />
                      </div>
                      <legend className="tw-text-base tw-font-medium tw-text-gray-900 tw-mt-10">
                        Content Feature 1
                      </legend>
                      <div className="tw-hidden sm:tw-block">
                        <div className="tw-pb-5">
                          <div className="tw-border-t tw-border-b-0 tw-border-l-0 tw-border-r-0 tw-border-solid tw-border-gray-200" />
                        </div>
                      </div>
                      <div className="tw-grid tw-grid-cols-12 tw-gap-6">
                        <TextInput
                          id="content_feature_one_title"
                          value={formik.values.contentFeatureOneTitle}
                          labelText="Title"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          showError={
                            formik.touched.contentFeatureOneTitle &&
                            !!formik.errors.contentFeatureOneTitle
                          }
                          error={formik.errors.contentFeatureOneTitle}
                          widthClass="tw-col-span-10 sm:tw-col-span-4"
                        />
                        <TextInput
                          id="content_feature_one_subtitle"
                          value={formik.values.contentFeatureOneSubtitle}
                          labelText="Subtitle"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          showError={
                            formik.touched.contentFeatureOneSubtitle &&
                            !!formik.errors.contentFeatureOneSubtitle
                          }
                          error={formik.errors.contentFeatureOneSubtitle}
                          widthClass="tw-col-span-10 sm:tw-col-span-4"
                        />
                        <AutoCompleteDropDown
                          suggestions={iconSuggestions}
                          handleSelection={onIconSelection}
                          handleChange={onIconChange}
                          displayValue={formik.values.contentFeatureOneIcon}
                          DisplayIcon={
                            <HeroIcon
                              icon={
                                formik.values.contentFeatureOneIcon || 'search'
                              }
                            />
                          }
                          placeholder="Select Icon"
                          labelText="Icon"
                          widthClass="tw-col-span-10 sm:tw-col-span-4"
                        />
                      </div>
                      <legend className="tw-text-base tw-font-medium tw-text-gray-900 tw-mt-10">
                        Content Feature 2
                      </legend>
                      <div className="tw-hidden sm:tw-block">
                        <div className="tw-pb-5">
                          <div className="tw-border-t tw-border-b-0 tw-border-l-0 tw-border-r-0 tw-border-solid tw-border-gray-200" />
                        </div>
                      </div>
                      <div className="tw-grid tw-grid-cols-12 tw-gap-6">
                        <TextInput
                          id="content_feature_two_title"
                          value={formik.values.contentFeatureTwoTitle}
                          labelText="Title"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          showError={
                            formik.touched.contentFeatureTwoTitle &&
                            !!formik.errors.contentFeatureTwoTitle
                          }
                          error={formik.errors.contentFeatureTwoTitle}
                          widthClass="tw-col-span-10 sm:tw-col-span-4"
                        />
                        <TextInput
                          id="content_feature_two_subtitle"
                          value={formik.values.contentFeatureTwoSubtitle}
                          labelText="Subtitle"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          showError={
                            formik.touched.contentFeatureTwoSubtitle &&
                            !!formik.errors.contentFeatureTwoSubtitle
                          }
                          error={formik.errors.contentFeatureTwoSubtitle}
                          widthClass="tw-col-span-10 sm:tw-col-span-4"
                        />
                        <AutoCompleteDropDown
                          suggestions={iconSuggestions}
                          handleSelection={onIconTwoSelection}
                          handleChange={onIconChange}
                          displayValue={formik.values.contentFeatureTwoIcon}
                          DisplayIcon={
                            <HeroIcon
                              icon={
                                formik.values.contentFeatureTwoIcon || 'search'
                              }
                            />
                          }
                          placeholder="Select Icon"
                          labelText="Icon"
                          widthClass="tw-col-span-10 sm:tw-col-span-4"
                        />
                      </div>
                      <legend className="tw-text-base tw-font-medium tw-text-gray-900 tw-mt-10">
                        Content Feature 3
                      </legend>
                      <div className="tw-hidden sm:tw-block">
                        <div className="tw-pb-5">
                          <div className="tw-border-t tw-border-b-0 tw-border-l-0 tw-border-r-0 tw-border-solid tw-border-gray-200" />
                        </div>
                      </div>
                      <div className="tw-grid tw-grid-cols-12 tw-gap-6">
                        <TextInput
                          id="content_feature_three_title"
                          value={formik.values.contentFeatureThreeTitle}
                          labelText="Title"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          showError={
                            formik.touched.contentFeatureThreeTitle &&
                            !!formik.errors.contentFeatureThreeTitle
                          }
                          error={formik.errors.contentFeatureThreeTitle}
                          widthClass="tw-col-span-10 sm:tw-col-span-4"
                        />
                        <TextInput
                          id="content_feature_three_subtitle"
                          value={formik.values.contentFeatureThreeSubtitle}
                          labelText="Subtitle"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          showError={
                            formik.touched.contentFeatureThreeSubtitle &&
                            !!formik.errors.contentFeatureThreeSubtitle
                          }
                          error={formik.errors.contentFeatureThreeSubtitle}
                          widthClass="tw-col-span-10 sm:tw-col-span-4"
                        />
                        <AutoCompleteDropDown
                          suggestions={iconSuggestions}
                          handleSelection={onIconThreeSelection}
                          handleChange={onIconChange}
                          displayValue={formik.values.contentFeatureThreeIcon}
                          DisplayIcon={
                            <HeroIcon
                              icon={
                                formik.values.contentFeatureThreeIcon ||
                                'search'
                              }
                            />
                          }
                          placeholder="Select Icon"
                          labelText="Icon"
                          widthClass="tw-col-span-10 sm:tw-col-span-4"
                        />
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </div>

            <div className="tw-hidden sm:tw-block">
              <div className="tw-py-5">
                <div className="tw-border-t tw-border-b-0 tw-border-l-0 tw-border-r-0 tw-border-solid tw-border-gray-200" />
              </div>
            </div>

            <div className="tw-mt-10 sm:tw-mt-0">
              <div className="md:tw-grid md:tw-grid-cols-4 md:tw-gap-6">
                <div className="md:tw-col-span-1">
                  <div className="tw-px-4 sm:tw-px-0">
                    <h3 className="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900">
                      Account Dashboard Metrics
                    </h3>
                    <p className="tw-mt-1 tw-text-sm tw-leading-5 tw-text-gray-600">
                      Set what campaign should be included in the account
                      dashboard &lsquo;New Lead&rsquo;, &lsquo;New Client&rsquo;
                      or &lsquo;Pending Cancellation&rsquo; metrics.
                    </p>
                  </div>
                </div>
                <div className="tw-mt-5 md:tw-mt-0 md:tw-col-span-3">
                  <Card containerClass="tw-shadow sm:tw-rounded-md">
                    <div className="tw-px-4 tw-py-5 tw-bg-white sm:tw-p-6">
                      <div className="tw-grid tw-grid-cols-12 tw-gap-6">
                        {currentProduct.product_campaigns
                          ? getMetricSelectors()
                          : null}
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </div>

            <div className="tw-hidden sm:tw-block">
              <div className="tw-py-5">
                <div className="tw-border-t tw-border-b-0 tw-border-l-0 tw-border-r-0 tw-border-solid tw-border-gray-200" />
              </div>
            </div>

            <div className="tw-mt-10 sm:tw-mt-0">
              <div className="md:tw-grid md:tw-grid-cols-4 md:tw-gap-6">
                <div className="md:tw-col-span-1">
                  <div className="tw-px-4 sm:tw-px-0">
                    <h3 className="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900">
                      Pricing Information (Legacy)
                    </h3>
                    <p className="tw-mt-1 tw-text-sm tw-leading-5 tw-text-gray-600">
                      The price, onboarding fee and stripe plan tied to this
                      product. This will be only used when purchasing a legacy
                      plan
                    </p>
                  </div>
                </div>
                <div className="tw-mt-5 md:tw-mt-0 md:tw-col-span-3">
                  <Card containerClass="tw-shadow sm:tw-rounded-md sm:tw-overflow-hidden">
                    <div className="tw-px-4 tw-py-5 tw-bg-white sm:tw-p-6">
                      <div className="tw-grid tw-grid-cols-10 tw-gap-6">
                        <TextInput
                          id="price"
                          value={formik.values.price}
                          labelText="Price"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          showError={
                            formik.touched.price && !!formik.errors.price
                          }
                          error={formik.errors.price}
                          widthClass="tw-col-span-10 sm:tw-col-span-5"
                        />

                        <Select
                          id="onboardingFeeId"
                          value={formik.values.onboardingFeeId}
                          options={[]}
                          labelText="Onboarding Fee"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          showError={
                            formik.touched.onboardingFeeId &&
                            !!formik.errors.onboardingFeeId
                          }
                          error={formik.errors.onboardingFeeId}
                          widthClass="tw-col-span-10 sm:tw-col-span-5"
                        />

                        <TextInput
                          id="stripe_plan"
                          value={formik.values.stripePlan}
                          labelText="Stripe Plan"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          showError={
                            formik.touched.stripePlan &&
                            !!formik.errors.stripePlan
                          }
                          error={formik.errors.stripePlan}
                        />
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </div>
          </Fragment>
        )}
      </div>
      <Notification
        message={createError}
        show={showCreateError}
        setShowAction={setShowCreateError}
        type="colored"
        headerText="Error!"
        color="error"
        HeaderIcon={color => {
          return (
            <svg
              className={`tw-h-5 tw-w-5 tw-text-${color}-400`}
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                clipRule="evenodd"
              />
            </svg>
          );
        }}
      />
      <Notification
        message={updateError}
        show={showUpdateError}
        setShowAction={setShowUpdateError}
        type="colored"
        headerText="Error!"
        color="error"
        HeaderIcon={color => {
          return <HeroIcon icon="x-circle" color={color} />;
        }}
      />
      <Notification
        message="Successfully created the product."
        show={showCreateSuccess}
        setShowAction={setShowCreateSuccess}
        type="colored"
        headerText="Success!"
        color="success"
        HeaderIcon={color => {
          return <HeroIcon icon="check-circle" color={color} />;
        }}
      />
      <Notification
        message="Successfully updated the product."
        show={showUpdateSuccess}
        setShowAction={setShowUpdateSuccess}
        type="colored"
        headerText="Success!"
        color="success"
        HeaderIcon={color => {
          return <HeroIcon icon="check-circle" color={color} />;
        }}
      />
    </div>
  );
};

export default ProductBuilder;
