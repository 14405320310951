import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import isEmpty from 'lodash.isempty';
import {
  createErrorMessageSelector,
  createLoadedSelector
} from 'appState/selectors';
import {
  purchaseNumberForAccount,
  resetIsLoaded
} from 'appState/actions/ActionCreators';

const AvailablePhoneNumberChanger = props => {
  const [phoneNumber, setPhoneNumber] = useState();

  const errorSelector = createErrorMessageSelector([
    'overlord/FETCH_AVAILABLE_PHONE_NUMBERS',
    'overlord/PURCHASE_NUMBER_FOR_ACCOUNT'
  ]);
  const loadedSelectorAvailableNumbers = createLoadedSelector([
    'overlord/FETCH_AVAILABLE_PHONE_NUMBERS'
  ]);
  const loadedSelectorNumberPurchased = createLoadedSelector([
    'overlord/PURCHASE_NUMBER_FOR_ACCOUNT'
  ]);
  const structuredSelector = createStructuredSelector({
    error: state => errorSelector(state),
    isAvailableNumbersLoaded: state => loadedSelectorAvailableNumbers(state),
    isNumberPurchasedLoaded: state => loadedSelectorNumberPurchased(state)
  });

  const {
    error,
    isAvailableNumbersLoaded,
    isNumberPurchasedLoaded
  } = useSelector(structuredSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isNumberPurchasedLoaded && isEmpty(error)) {
      app.toast(`Successfully purchased phone number: ${phoneNumber}`, {
        type: 'primary',
        icon: 'fa-floppy-o'
      });
    }
    return () => {
      dispatch(resetIsLoaded('overlord/PURCHASE_NUMBER_FOR_ACCOUNT'));
    };
  }, [phoneNumber, isNumberPurchasedLoaded, error, dispatch]);

  useEffect(() => {
    if (isNumberPurchasedLoaded && !isEmpty(error)) {
      app.toast(
        `Error purchasing phone number: ${phoneNumber}. Please try again.`,
        {
          type: 'danger',
          icon: 'fa-floppy-o'
        }
      );
    }
    return () => {
      dispatch(resetIsLoaded('overlord/PURCHASE_NUMBER_FOR_ACCOUNT'));
    };
  }, [phoneNumber, isNumberPurchasedLoaded, error, dispatch]);

  const selectNumberForPurchase = () => {
    const { currentAccount } = props;
    dispatch(
      purchaseNumberForAccount({
        accountId: currentAccount.id,
        phoneNumber
      })
    );
  };

  const { availableNumbers } = props;
  const numbersList = availableNumbers.map(number => {
    return (
      <div key={number} className="media media-single">
        <span className="avatar d-none d-sm-inline">
          <i className="fa fa-wpforms" />
        </span>
        <div className="media-body">
          <h6>{number}</h6>
        </div>
        <div className="media-right d-xs-inline">
          <button
            type="button"
            className="btn btn-info pick-phone-number-button"
            data-toggle="modal"
            data-target="#pick-phone-number-modal"
            onClick={() => setPhoneNumber(number)}
          >
            Pick Number
          </button>
        </div>
      </div>
    );
  });

  return (
    <div>
      {isAvailableNumbersLoaded ? (
        <div className="media-list media-list-hover media-list-divided media-list-bordered">
          <header className="media-list-header">
            <div className="media media-single">
              <span>
                <h5 className="pt-10">Available Phone Numbers</h5>
              </span>
            </div>
          </header>
          {availableNumbers && availableNumbers.length ? (
            <div>{numbersList}</div>
          ) : (
            <p className="text-danger p-20">No numbers were found.</p>
          )}
        </div>
      ) : (
        <div className="d-flex justify-content-center">
          <div className="spinner-circle-shadow spinner-info" />
        </div>
      )}

      <div
        className="modal fade"
        id="pick-phone-number-modal"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="myModalLabel">
                Pick Phone Number
              </h4>
              <button type="button" className="close" data-dismiss="modal">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <p>Are you sure you want to pick this phone number?</p>
              <p>
                This will release the old phone number and will set the new one
                to be used.
              </p>
              <p>
                Make sure to copy down the phone number:{' '}
                <strong id="picked-number-wrapper">{phoneNumber}</strong> to
                update the client about the change.
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-bold btn-pure btn-secondary"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-bold btn-info"
                id="pick-phone-number-confirmation-button"
                data-dismiss="modal"
                onClick={selectNumberForPurchase}
              >
                Pick Number
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const currentAccountShape = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

AvailablePhoneNumberChanger.propTypes = {
  availableNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentAccount: PropTypes.shape(currentAccountShape).isRequired
};

export default AvailablePhoneNumberChanger;
