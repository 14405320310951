export const FETCH_APPOINTMENT_BOOKER =
  'appointment_booker/FETCH_APPOINTMENT_BOOKER';
export const FETCH_APPOINTMENT_BOOKER_REQUEST = `${FETCH_APPOINTMENT_BOOKER}_REQUEST`;
export const FETCH_APPOINTMENT_BOOKER_SUCCESS = `${FETCH_APPOINTMENT_BOOKER}_SUCCESS`;
export const FETCH_APPOINTMENT_BOOKER_FAILURE = `${FETCH_APPOINTMENT_BOOKER}_FAILURE`;

export const CREATE_APPOINTMENT_FROM_BOOKER =
  'appointment_booker/CREATE_APPOINTMENT_FROM_BOOKER';
export const CREATE_APPOINTMENT_FROM_BOOKER_REQUEST = `${CREATE_APPOINTMENT_FROM_BOOKER}_REQUEST`;
export const CREATE_APPOINTMENT_FROM_BOOKER_SUCCESS = `${CREATE_APPOINTMENT_FROM_BOOKER}_SUCCESS`;
export const CREATE_APPOINTMENT_FROM_BOOKER_FAILURE = `${CREATE_APPOINTMENT_FROM_BOOKER}_FAILURE`;

export const FETCH_APPOINTMENT = 'appointment_booker/FETCH_APPOINTMENT';
export const FETCH_APPOINTMENT_REQUEST = `${FETCH_APPOINTMENT}_REQUEST`;
export const FETCH_APPOINTMENT_SUCCESS = `${FETCH_APPOINTMENT}_SUCCESS`;
export const FETCH_APPOINTMENT_FAILURE = `${FETCH_APPOINTMENT}_FAILURE`;

export const UPDATE_APPOINTMENT = 'appointment_booker/UPDATE_APPOINTMENT';
export const UPDATE_APPOINTMENT_REQUEST = `${UPDATE_APPOINTMENT}_REQUEST`;
export const UPDATE_APPOINTMENT_SUCCESS = `${UPDATE_APPOINTMENT}_SUCCESS`;
export const UPDATE_APPOINTMENT_FAILURE = `${UPDATE_APPOINTMENT}_FAILURE`;

export const RESET_APPOINTMENT_BOOKER = 'appointment_booker/RESET_APPOINTMENT_BOOKER'
