import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Pagination from 'components/Theme/Pagination';
import PastAutomationItem from './PastAutomationItem';

const LIMIT = 5;
const PastAutomation = ({ automations, timezone }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const pastAutomations = automations.filter(item => {
    return item.completed_at;
  });
  const isMaxLimit = pastAutomations.length > LIMIT;
  const getPagination = () => {
    const totalPages = Math.ceil(pastAutomations.length / LIMIT);
    return (
      <Pagination
        totalPages={totalPages}
        limit={LIMIT}
        isScrollFixed
        action={e => {
          setPageNumber(e.page);
        }}
      />
    );
  };
  const getPaginationData = () => {
    const paginationData = isMaxLimit
      ? pastAutomations.slice((pageNumber - 1) * LIMIT, pageNumber * LIMIT)
      : pastAutomations;

    return paginationData.map(item => {
      return (
        <PastAutomationItem
          key={item.title}
          itemName={item.title}
          startDate={item.campaign_started_at}
          endDate={item.completed_at}
          timezone={timezone}
        />
      );
    });
  };

  return (
    <>
      <div className="tw-max-h-96 tw-overflow-y-auto">
        {getPaginationData()}
      </div>
      {isMaxLimit && getPagination()}
    </>
  );
};

PastAutomation.defaultProps = {
  automations: []
};
PastAutomation.propTypes = {
  automations: PropTypes.arrayOf(PropTypes.object),
  timezone: PropTypes.string.isRequired
};

export default PastAutomation;
