import React, { useEffect, useState } from 'react';
import {
  resetTestEmailSent,
  broadcastSetTestEmailAddress
} from 'appState/actions/ActionCreators';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import isEmpty from 'lodash.isempty';
import {
  createErrorMessageSelector,
  createLoadedSelector
} from 'appState/selectors';

const BroadcastSendTestEmailModal = () => {
  const [testEmailAddress, setTestEmailAddress] = useState('');
  const dispatch = useDispatch();

  const errorSelector = createErrorMessageSelector([
    'broadcastCenter/BROADCAST_SEND_TEST_EMAIL'
  ]);
  const loadedSelector = createLoadedSelector([
    'broadcastCenter/BROADCAST_SEND_TEST_EMAIL'
  ]);
  const structuredSelector = createStructuredSelector({
    currentBroadcast: state => state.broadcastCenter.currentBroadcast,
    error: state => errorSelector(state),
    isLoaded: state => loadedSelector(state)
  });

  const { error, isLoaded } = useSelector(structuredSelector);

  function sendBroadcastTestEmail() {
    dispatch(broadcastSetTestEmailAddress(testEmailAddress));
  }

  useEffect(() => {
    if (isLoaded && isEmpty(error)) {
      app.toast(`Your test email was sent to ${testEmailAddress}`, {
        type: 'success',
        icon: 'fa-check'
      });
    }

    if (isLoaded && !isEmpty(error)) {
      app.toast(
        'There was an error sending your test email. Please try again.',
        {
          type: 'danger',
          icon: 'fa-times'
        }
      );
    }

    dispatch(resetTestEmailSent());
    dispatch(broadcastSetTestEmailAddress(''));
  }, [isLoaded, error, dispatch]);

  return (
    <div
      className="modal modal-top fade"
      id="send-test-modal"
      tabIndex="-2"
      aria-hidden="true"
      style={{ display: 'none' }}
      data-backdrop="true"
    >
      <div className="modal-dialog">
        <div className="modal-content b-3 border-dark">
          <div className="modal-header">
            <h5 className="modal-title">Send Test Email</h5>
            <button type="button" className="close" data-dismiss="modal">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Enter Email Address"
                id="test-email-address"
                value={testEmailAddress}
                onChange={e => setTestEmailAddress(e.target.value)}
              />
              <span className="input-group-append">
                <button
                  className="btn btn-light"
                  type="button"
                  id="send-test-email-action"
                  data-dismiss="modal"
                  onClick={sendBroadcastTestEmail}
                >
                  Send Test
                </button>
              </span>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-bold btn-pure btn-secondary"
              data-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BroadcastSendTestEmailModal;
