import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  Fragment
} from 'react';
import PropTypes from 'prop-types';
import Spinner from 'components/Theme/Spinner';

const Image = ({ alt, imageURL, className, maxHeight, maxWidth }) => {
  const imagePreviewEl = useRef(null);
  const [imageLoaded, setImageLoaded] = useState(false);

  const loadMediaPreview = useCallback(() => {
    imagePreviewEl.current.src = imageURL;
  }, [imageURL]);

  useEffect(() => {
    loadMediaPreview();
  }, [loadMediaPreview]);

  return (
    <Fragment>
      {!imageLoaded && (
        <div
          className={`tw-flex tw-items-center tw-justify-center tw-bg-gray-100 ${className}`}
          style={{ height: maxHeight, width: maxWidth }}
        >
          <Spinner visible={!imageLoaded} color="gray" tint="400" />
        </div>
      )}
      <img
        alt={alt}
        className={className}
        ref={imagePreviewEl}
        onLoad={() => setImageLoaded(true)}
        onError={() => setImageLoaded(true)}
        style={imageLoaded ? { maxHeight, maxWidth } : { display: 'none' }}
      />
    </Fragment>
  );
};

Image.defaultProps = {
  alt: '',
  imageURL: '',
  className: '',
  maxHeight: '400px',
  maxWidth: '400px'
};

Image.propTypes = {
  alt: PropTypes.string,
  imageURL: PropTypes.string,
  className: PropTypes.string,
  maxHeight: PropTypes.string,
  maxWidth: PropTypes.string
};

export default Image;
