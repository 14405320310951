import React, { Fragment, useState, useEffect, useRef } from 'react';
import get from 'lodash.get';
import isEmpty from 'lodash.isempty';
import Table from 'components/Theme/Table';
import Pagination from 'components/Theme/Pagination';
import PropTypes from 'prop-types';
import { MATCH_LIMIT } from 'config/constants';
import HeaderComponent from 'components/SyncWizard/shared/HeaderComponent';

const includedColumns = {
  source: 'source',
  firstName: 'first name',
  lastName: 'last name',
  email: 'email',
  status: 'ZP status'
};

const AddContacts = ({
  syncData,
  updateUplaunchEvent,
  updateZenPlannerEvent,
  displayUplaunchTable,
  isSticky
}) => {
  const { newToUplaunchContacts } = syncData.contacts;
  const { newToZenPlannerContacts } = syncData.contacts;

  const currentPageData = displayUplaunchTable
    ? newToUplaunchContacts
    : newToZenPlannerContacts;

  const [currentPage, setCurrentPage] = useState(1);
  const prevDisplayRef = useRef();
  const [currentData, setCurrentData] = useState(
    currentPageData.slice(0, MATCH_LIMIT)
  );
  const [totalItems, setTotalItems] = useState(currentPageData.length);
  const totalPages = Math.ceil(totalItems / MATCH_LIMIT);
  let prevDisplay;

  const pageChange = (data = null) => {
    const pageData = get(data, 'page');
    let pageNum;
    if (prevDisplay !== undefined && prevDisplay !== displayUplaunchTable)
      pageNum = 1;
    else pageNum = pageData || currentPage;
    const pageCal = (pageNum - 1) * MATCH_LIMIT;
    const multipleRows = currentPageData.slice(pageCal, pageCal + MATCH_LIMIT);
    setTotalItems(currentPageData.length);
    if (!isEmpty(multipleRows)) {
      setCurrentPage(pageNum);
      setCurrentData(multipleRows);
    }
  };

  const updateSelectActionValue = event => {
    const updatedData = [...currentPageData];
    const updatedDataIndex = updatedData.findIndex(
      row => row.id === event.target.id.split('select_')[1]
    );
    const tableRow = updatedData[updatedDataIndex];
    updatedData[updatedDataIndex] = {
      ...tableRow,
      selected: event.target.value
    };
    updateUplaunchEvent(updatedData);
  };

  const updateBtnState = (btnRef, btnState) => {
    const updatedData = [...currentPageData];
    const updatedDataIndex = updatedData.findIndex(
      row => row.id === btnRef.row.id
    );
    const tableRow = updatedData[updatedDataIndex];
    updatedData[updatedDataIndex] = { ...tableRow, connect: btnState };
    updateZenPlannerEvent(updatedData);
  };

  useEffect(() => {
    prevDisplayRef.current = displayUplaunchTable;
    pageChange();
  }, [currentPageData]);
  prevDisplay = prevDisplayRef.current;

  return (
    <Fragment>
      <HeaderComponent
        title={
          displayUplaunchTable
            ? 'Step 4: Add New Contacts to UpLaunch'
            : 'Step 4: Add Contacts To Zen Planner'
        }
      />
      <div className="tw-mx-10 tw-mt-10 tw-mb-20">
        <div className="tw-rounded-md tw-bg-alpha-100 tw-p-4">
          <div className="tw-flex">
            <div className="tw-flex-shrink-0">
              <svg
                className="tw-h-5 tw-w-5 tw-text-alpha-400"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div className="tw-ml-3">
              <h3 className="tw-text-sm tw-leading-5 tw-font-medium tw-text-alpha-800">
                Who's here?
              </h3>
              <div className="tw-mt-2 tw-text-sm tw-leading-5 tw-text-alpha-700">
                <ul className="tw-list-disc tw-pl-5">
                  <li>
                    We found Zen Planner contacts that are not in UpLaunch.
                  </li>
                  <li className="tw-mt-1">
                    Select a lifecycle campaign (from your default journey) for
                    these contacts.
                  </li>
                  <li className="tw-mt-1">Campaigns will run immediately.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {displayUplaunchTable ? (
          <div>
            <div className="tw-max-w-10xl tw-mt-12">
              <Table
                title=""
                subtitle=""
                rows={currentData}
                includedColumns={includedColumns}
                hasStatus
                hasRowActionSelect
                onSelectActionChange={updateSelectActionValue}
                hasFooter
                showStatusColor
                isSticky={isSticky}
                FooterComponent={() =>
                  totalPages && totalPages > 1 ? (
                    <Pagination
                      total={totalItems}
                      totalPages={totalPages}
                      limit={MATCH_LIMIT}
                      action={pageChange}
                    />
                  ) : null
                }
                emptyMessage="No action required"
                popOverInfo="ZP status"
                customActionHeader="Uplaunch Campaign"
              />
            </div>
          </div>
        ) : (
          <div>
            <div className="tw-max-w-10xl">
              <Table
                title=""
                subtitle=""
                rows={currentData}
                includedColumns={[
                  'source',
                  'firstName',
                  'lastName',
                  'email',
                  'status'
                ]}
                hasStatus
                hasActionButton
                btnProps={[
                  {
                    color: 'alpha',
                    text: '+Add',
                    action: updateBtnState
                  },
                  {
                    color: 'alpha',
                    text: 'ignore',
                    action: updateBtnState
                  }
                ]}
                hasFooter
                FooterComponent={() => (
                  <Pagination
                    total={totalItems}
                    totalPages={totalPages}
                    limit={MATCH_LIMIT}
                    action={pageChange}
                  />
                )}
                emptyMessage="No contacts found."
                customActionHeader="Connect"
              />
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

AddContacts.defaultProps = {
  updateUplaunchEvent: () => null,
  updateZenPlannerEvent: () => null,
  displayUplaunchTable: true,
  syncData: {
    contacts: {
      newToUplaunchContacts: [],
      newToZenPlannerContacts: []
    }
  }
};

AddContacts.propTypes = {
  syncData: PropTypes.shape({
    contacts: PropTypes.shape({
      exactMatch: PropTypes.array.isRequired,
      possibleMatchWithSameEmail: PropTypes.array.isRequired,
      possibleMatchWithSameName: PropTypes.array.isRequired,
      newToUplaunch: PropTypes.array.isRequired,
      newToUplaunchContacts: PropTypes.array,
      newToZenPlanner: PropTypes.array.isRequired,
      newToZenPlannerContacts: PropTypes.array,
      recordsCount: PropTypes.shape({
        alreadySynced: PropTypes.number.isRequired,
        zenPlanner: PropTypes.number.isRequired,
        upLaunch: PropTypes.number.isRequired,
        exactMatch: PropTypes.number.isRequired,
        possibleMatch: PropTypes.number.isRequired,
        newToUplaunch: PropTypes.number.isRequired,
        newToZenPlanner: PropTypes.number.isRequired
      }).isRequired
    }).isRequired
  }),
  updateUplaunchEvent: PropTypes.func,
  updateZenPlannerEvent: PropTypes.func,
  displayUplaunchTable: PropTypes.bool,
  isSticky: PropTypes.bool.isRequired
};

export default AddContacts;
