import React from 'react';
import { withA11y } from '@storybook/addon-a11y';
import { withKnobs, text } from '@storybook/addon-knobs';
import Image from './index';

export default {
  component: Image,
  title: 'Image',
  decorators: [withA11y, withKnobs]
};

export const standard = () => {
  return (
    <Image
      alt={text('Alt Text', 'Image')}
      imageURL="https://res.cloudinary.com/uplaunch/image/upload/v1576252697/account_14/photo-1467173572719-f14b9fb86e5f.jpg"
      className="tw-rounded-lg"
      maxHeight={text('Max Height', '400px')}
      maxWidth={text('Max Width', '400px')}
    />
  );
};
