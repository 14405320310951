import * as Yup from 'yup';
import { EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';

const broadcastEmailSchema = Yup.object().shape({
  emailSubject: Yup.string().required(
    'Please add a subject line to this email.'
  )
});

const broadcastTemplateSchema = Yup.object().shape({
  templateTitle: Yup.string().required(
    'Please add a broadcast template title.'
  ),
  templateDescription: Yup.string().required(
    'Please add a broadcast template description.'
  )
});

const purchasePageSchema = Yup.object().shape({
  businessName: Yup.string().required('Please add a business name.'),
  subdomain: Yup.string().required('Please add a subdomain.'),
  firstName: Yup.string().required('Please add a first name.'),
  lastName: Yup.string().required('Please add a last name.'),
  email: Yup.string().required('Please add an email address.'),
  phone: Yup.string().required('Please add a phone number.'),
  password: Yup.string().required('Please add a password.'),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match.')
    .required('Please confirm your password.')
});

const planSchema = Yup.object().shape({
  tier: Yup.string().required('Please select a tier.'),
  name: Yup.string().required('Please add a plan name.'),
  type: Yup.string().required('Please select a type.'),
  chargeCampaignSms: Yup.bool().required(
    'Please select whether campaign sms should be billed.'
  ),
  maxUsers: Yup.number()
    .integer('Please enter an integer for max users allowed.')
    .min(0)
    .required('Max users is required.'),
  maxProducts: Yup.number()
    .integer('Please enter an integer for max products allowed.')
    .min(0)
    .required('Max products is required.'),
  maxJourneys: Yup.number()
    .integer('Please enter an integer for max journeys allowed.')
    .min(0)
    .required('Max journeys is required.'),
  maxAppointmentTypes: Yup.number()
    .integer('Please enter an integer for max appointment types allowed.')
    .min(0)
    .required('Max appointment types is required.'),
  description: Yup.string().required('Please add a description of the plan.'),
  typeformId: Yup.string().required('Please add a typeform id.'),
  contactImportTypeformId: Yup.string().required(
    'Please add a contact import typeform id.'
  ),
  features: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required('Feature can not be empty.')
      })
    )
    .min(1, 'Please add multiple features.'),
  price: Yup.number()
    .positive('Price must be a positive number.')
    .nullable()
    .required('Price is required.'),
  onboardingFeeId: Yup.string()
    .nullable()
    .required('Onboarding fee is required.'),
  stripePlan: Yup.string()
    .nullable()
    .required('Stripe plan is required.')
});

const smsEnabledCountrySchema = Yup.object().shape({
  name: Yup.string().required('Please add a SMS enabled country name.'),
  freeCreditPack: Yup.number().required('Please select a free credit pack.'),
  additionalCreditPack: Yup.number().required(
    'Please select an additional credit pack.'
  ),
  phoneNumberSurcharge: Yup.number().required(
    'Please add a phone number surcharge.'
  )
});

const gdprSchema = {
  gdpr: Yup.bool()
    .required(
      'This contact cannot be created without express opt-in permission. Please receive communication opt-in permission before attempting to add this contact.'
    )
    .oneOf(
      [true],
      'This contact cannot be created without express opt-in permission. Please receive communication opt-in permission before attempting to add this contact.'
    )
};

const contactNewSchemaShape = {
  firstName: Yup.string().required('Required.'),
  lastName: Yup.string().required('Required.'),
  email: Yup.string()
    .email()
    .required('Valid Email required.'),
  assignedStaffId: Yup.number().required('Required.')
};

const modularContactNewSchema = Yup.object().shape(contactNewSchemaShape);

const modularContactNewGdprSchema = Yup.object().shape({
  ...contactNewSchemaShape,
  ...gdprSchema
});

contactNewSchemaShape.journeyId = Yup.string().required('Required.');

const contactNewSchema = Yup.object().shape(contactNewSchemaShape);

const contactNewGdprSchema = Yup.object().shape({
  ...contactNewSchemaShape,
  ...gdprSchema
});

const subscriptionSchema = Yup.object().shape({
  stripeCustomerId: Yup.string().when('comped', {
    is: false,
    then: Yup.string().required(
      'Stripe customer id is required when not comped.'
    ),
    otherwise: Yup.string().notRequired.nullable
  }),
  stripeSubscriptionId: Yup.string().when('comped', {
    is: false,
    then: Yup.string().required(
      'Stripe subscription id is required when not comped.'
    ),
    otherwise: Yup.string().notRequired.nullable
  }),
  comped: Yup.bool().required('Please select true or false.'),
  paymentFailedAt: Yup.string().notRequired.nullable,
  becameDelinquentAt: Yup.string().notRequired.nullable
});

const pauseOptionSchema = Yup.object().shape({
  pauseOption: Yup.string().required('You must select a pause option.'),
  xDays: Yup.number().when('pauseOption', {
    is: 'x-days',
    then: Yup.number().required('Must select amount of days to be paused.'),
    otherwise: Yup.number()
  }),
  untilXDate: Yup.string().when('pauseOption', {
    is: 'until-x-date',
    then: Yup.string().required('Must select a resume date.'),
    otherwise: Yup.string()
  })
});

const productSubscriptionSchema = Yup.object().shape({
  productId: Yup.number().required(
    'A product subscription must have a product.'
  )
});

const contactFormSchema = Yup.object().shape({});

const taskSchema = Yup.object().shape({
  title: Yup.string().required('A task must have a title.'),
  description: Yup.string()
    .test('wysiwyg has content', 'A task must have a description.', value => {
      const editorContent = stateFromHTML(value);
      const editorState = EditorState.createWithContent(editorContent);
      return (
        editorState &&
        editorState.getCurrentContent() &&
        editorState.getCurrentContent().hasText()
      );
    })
    .nullable()
    .required(),
  staffId: Yup.string().required('A task must be assigned to a staff member'),
  dueDate: Yup.string().required('A task must have a due date')
});

const noteSchema = Yup.object().shape({
  note: Yup.string().required('A note is required.')
});

const emailSchema = Yup.object().shape({
  emailSubject: Yup.string().required('Subject is required.'),
  emailBody: Yup.string()
    .test('wysiwyg has content', 'Email body is required.', value => {
      const editorContent = stateFromHTML(value);
      const editorState = EditorState.createWithContent(editorContent);
      return (
        editorState &&
        editorState.getCurrentContent() &&
        editorState.getCurrentContent().hasText()
      );
    })
    .nullable()
    .required('Email body is required.')
});

const appointmentCreateSchema = Yup.object().shape({
  appointmentTypeId: Yup.string().required('Appointment type required'),
  userId: Yup.string().required('User is required'),
  appointmentDate: Yup.string().required('Appointment date is required'),
  appointmentTime: Yup.string().required('Appointment time is required')
});

const GroupAppointmentTypeCreateSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  permittedStaffIds: Yup.string().required('Permitted staff id is required'),
  duration: Yup.string().required('Appointment duration is required'),
  notice: Yup.string().required('Advanced notice is required'),
  padding: Yup.string().required('Padding is required.'),
  maxAttendees: Yup.number()
    .integer('Please enter an integer for max attendees allowed.')
    .min(1)
    .required('Max attendees is required.'),
  contactFormType: Yup.string()
    .nullable()
    .when('hasBookingForm', {
      is: true,
      then: Yup.string().required('Booking form is required')
    }),
  url: Yup.string()
    .nullable()
    .when('hasBookingRedirect', {
      is: true,
      then: Yup.string().required('Redirect url is required')
    }),
  futureBookingRestrictionDays: Yup.string()
    .nullable()
    .when('hasFutureBookingRestricted', {
      is: true,
      then: Yup.string().required('Restriction days is required')
    })
});

const appointmentTypeCreateSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  permittedStaffIds: Yup.string().required('Permitted staff id is required'),
  duration: Yup.string().required('Appointment duration is required'),
  notice: Yup.string().required('Advanced notice is required'),
  padding: Yup.string().required('Padding is required.'),
  contactFormType: Yup.string()
    .nullable()
    .when('hasBookingForm', {
      is: true,
      then: Yup.string().required('Booking form is required')
    }),
  url: Yup.string()
    .nullable()
    .when('hasBookingRedirect', {
      is: true,
      then: Yup.string().required('Redirect url is required')
    }),
  futureBookingRestrictionDays: Yup.string()
    .nullable()
    .when('hasFutureBookingRestricted', {
      is: true,
      then: Yup.string().required('Restriction days is required')
    })
});

const productSetupStepOneSchema = Yup.object().shape({
  name: Yup.string().required('Please add a name.'),
  landingPageTitle: Yup.string(),
  cta: Yup.string(),
  campaignOwnerId: Yup.string(),
  hasAppointment: Yup.bool(),
  appointmentTypeId: Yup.string().when('hasAppointment', {
    is: true,
    then: Yup.string().required('Select an appointment.')
  })
});

const productSetupStepTwoSchema = Yup.object().shape({
  launchDate: Yup.date().min(
    new Date(),
    'Launch Date must be later than today.'
  )
});

const productSchema = Yup.object().shape({
  name: Yup.string().required('Product name is required.'),
  category: Yup.string().required('Product category is required.'),
  lifecycles: Yup.string().required('Product lifecycles is required.')
});

const appointmentUpdateSchema = Yup.object().shape({
  userId: Yup.string().required('User is required'),
  appointmentDate: Yup.string().required('Appointment date is required'),
  appointmentTime: Yup.string().required('Appointment time is required')
});

const setupPageSchema = {
  file: Yup.mixed()
    .test(
      'fileType',
      'Unsupported file format.',
      value => value === null || ['text/csv'].includes(value.type)
    )
    .required('CSV File is required.'),
  assignedStaffId: Yup.string().required('Assigned Staff is required.'),
  lifecycle: Yup.string().required('Lifecycle is required.')
};

const newImportStepOneSchema = Yup.object().shape({
  ...setupPageSchema
});

const newImportStepOneGdprSchema = Yup.object().shape({
  ...setupPageSchema,
  ...gdprSchema
});

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const optInFormSchemaWithCheckbox = Yup.object().shape({
  new_lead_contact: Yup.object().shape({
    first_name: Yup.string().required('First name is required.'),
    last_name: Yup.string().required('Last name is required..'),
    email: Yup.string()
      .email('Valid email address reqired')
      .required('Email address is required.'),
    phone: Yup.string()
      .matches(phoneRegExp, 'Phone number is not valid')
      .min(10, 'Phone number should be 10 digit')
      .max(10, 'Phone number should be 10 digit'),
    allow_contact: Yup.bool()
      .required('This must be accepted.')
      .oneOf([true], 'This must be accepted.'),
    terms_conditions: Yup.bool()
      .required('This must be accepted.')
      .oneOf([true], 'This must be accepted.')
  })
});

const includedFormSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  fields: Yup.array().of(
    Yup.object().shape({
      question: Yup.string().when('_destroy', {
        is: true,
        otherwise: Yup.string().required('Question is required.')
      }),
      field_type: Yup.string().when('_destroy', {
        is: true,
        otherwise: Yup.string().required('Field type is required')
      })
    })
  )
});

const staffSmsNotificationSchema = Yup.object().shape({
  staffNotificationSmsRecipients: Yup.string().required(
    'Staff notification sms recipients is required'
  ),
  hasStaffNotificationSms: Yup.bool(),
  staffSmsBody: Yup.string().when('hasStaffNotificationSms', {
    is: true,
    then: Yup.string()
      .max(320, 'SMS body must be below 320 characters long')
      .required('SMS body is required when sending the notification.'),
    otherwise: Yup.string().notRequired.nullable
  })
});

const addToCustomGroupSchema = Yup.object().shape({
  hasCustomGroupModule: Yup.bool(),
  emailTagIds: Yup.array().when('hasCustomGroupModule', {
    is: true,
    then: Yup.array()
      .min(1, 'One custom group must be selected.')
      .required(),
    otherwise: Yup.array().notRequired.nullable
  })
});

const addToCampaignSchema = Yup.object().shape({
  hasChangeCampaignModule: Yup.bool(),
  productCampaignId: Yup.string().when('hasChangeCampaignModule', {
    is: true,
    then: Yup.string().required('Campaign selection required.'),
    otherwise: Yup.string().notRequired.nullable
  })
});

const staffEmailNotificationSchema = Yup.object().shape({
  staffNotificationEmailRecipients: Yup.string().required(
    'Staff notification email recipients is required'
  ),
  hasStaffNotificationEmail: Yup.bool(),
  staffEmailSubject: Yup.string().when('hasStaffNotificationEmail', {
    is: true,
    then: Yup.string().required('Email subject is required.'),
    otherwise: Yup.string().notRequired.nullable
  })
});

const leadSourceSchema = Yup.object().shape({
  name: Yup.string()
    .required('Lead Source name is required')
    .min(4, 'Name should atleast be 4 characters')
    .max(32, 'Maximum length reached')
});

const bulkUpdateContactsSchema = Yup.object().shape({
  file: Yup.mixed()
    .test(
      'fileType',
      'Unsupported file format.',
      value => value === null || ['text/csv'].includes(value.type)
    )
    .required('CSV File is required.'),
  leadSource: Yup.string().required('Lead Source is required')
});

export {
  broadcastEmailSchema,
  broadcastTemplateSchema,
  purchasePageSchema,
  planSchema,
  smsEnabledCountrySchema,
  contactNewSchema,
  modularContactNewSchema,
  subscriptionSchema,
  productSubscriptionSchema,
  taskSchema,
  contactFormSchema,
  pauseOptionSchema,
  noteSchema,
  appointmentCreateSchema,
  appointmentTypeCreateSchema,
  GroupAppointmentTypeCreateSchema,
  productSetupStepOneSchema,
  productSetupStepTwoSchema,
  productSchema,
  appointmentUpdateSchema,
  emailSchema,
  newImportStepOneSchema,
  newImportStepOneGdprSchema,
  optInFormSchemaWithCheckbox,
  includedFormSchema,
  staffSmsNotificationSchema,
  addToCustomGroupSchema,
  addToCampaignSchema,
  staffEmailNotificationSchema,
  modularContactNewGdprSchema,
  contactNewGdprSchema,
  leadSourceSchema,
  bulkUpdateContactsSchema
};
