import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Transition from 'components/Transition';
import useOnClickOutside from 'components/shared/hooks/useOnClickOutside';
import { toCamel } from 'lib/utils/string';
import Select from 'components/Theme/Select';
import range from 'lodash/range';
import HeroIcon from 'components/Theme/HeroIcon';

const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];
const DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const DatePicker = ({
  id,
  value,
  placeholder,
  labelText,
  required,
  datePickerStyle,
  containerStyle,
  labelStyle,
  onChange,
  onBlur,
  showError,
  error,
  helpText,
  hideLabel,
  widthClass,
  adjustContainerHeight,
  showYearDropdown,
  minDate,
  clearable
}) => {
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [datePickerValue, setDatePickerValue] = useState('');
  const [year, setYear] = useState(moment().year());
  const [month, setMonth] = useState(0);
  const [numberOfDays, setNumberOfDays] = useState([]);
  const [blankDays, setBlankDays] = useState([]);
  const dateRef = useRef(null);
  const datePickerDropdownRef = useRef();
  const dateValueRef = useRef();

  const years = range(moment().year() - 100, moment().year() + 5, 1);

  useOnClickOutside(datePickerDropdownRef, () => setShowDatePicker(false));

  function getName() {
    return toCamel(id);
  }

  const yearSelectOptions = years.map(option => {
    return { displayName: option, value: option };
  });

  function getNumberOfDays() {
    const yearAndMonth = moment(`${year}-${month + 1}`, 'YYYY-MM');
    const daysInMonth = yearAndMonth.daysInMonth();

    // find where to start calendar day of week
    const dayOfWeek = yearAndMonth.day();
    const blankdaysArray = [];

    for (let i = 1; i <= dayOfWeek; i += 1) {
      blankdaysArray.push(i);
    }

    const daysArray = [];
    for (let i = 1; i <= daysInMonth; i += 1) {
      daysArray.push(i);
    }

    setBlankDays(blankdaysArray);
    setNumberOfDays(daysArray);
  }

  useEffect(() => {
    if (month > 11) {
      setMonth(0);
      setYear(year + 1);
    } else if (month < 0) {
      setMonth(11);
      setYear(year - 1);
    } else {
      getNumberOfDays();
    }
  }, [month]);

  useEffect(() => {
    const date = value ? moment(value).format('ll') : moment().format('ll');
    const dateMonth = moment(date).month();
    const dateYear = moment(date).year();

    setYear(dateYear);
    setMonth(dateMonth);
    setDatePickerValue(value ? moment(value).format('ll') : '');
  }, [value]);

  useEffect(() => {
    getNumberOfDays();
  }, [year]);

  function isSelectedDate(day) {
    const date = datePickerValue
      ? moment(datePickerValue).format('ll')
      : moment().format('ll');
    const d = moment([year, month, day]).format('ll');

    return date === d || false;
  }

  function checkPastDate(selectedDate) {
    return (
      minDate &&
      parseInt(moment(minDate).format('X')) >
        parseInt(moment(selectedDate).format('X'))
    );
  }

  const getDateValue = date => {
    const selectedDate = moment([year, month, date]).format('ll');
    if (checkPastDate(selectedDate)) {
      return;
    }
    setDatePickerValue(selectedDate);
    onChange(selectedDate);
    dateRef.current.value = selectedDate;
    setShowDatePicker(false);
  };

  function hasDisableClass(defaultYear, defaultMonth, date) {
    const selectedDate = moment([defaultYear, defaultMonth, date]).format('ll');
    return checkPastDate(selectedDate) || false;
  }

  function handleDateInputClick(e) {
    if (e.code === 'Escape') return setShowDatePicker(false);
    return setShowDatePicker(!showDatePicker);
  }

  useEffect(() => {
    if (dateValueRef.current.value) {
      const previousDateArray = dateValueRef.current.value.split(' ');
      previousDateArray.splice(2, 1, year);
      const newDateString = previousDateArray.join(' ');
      dateValueRef.current.value = newDateString;
      setDatePickerValue(newDateString);
      onChange(newDateString);
    }
    getNumberOfDays();
  }, [year]);

  function handleOnIconClick(isClearable) {
    if (isClearable) {
      setDatePickerValue('');
      onChange('');
    }
  }

  function getIcon() {
    const isClearable = clearable && datePickerValue.length > 0;

    return (
      <div className="tw-absolute tw-top-0 tw-right-0 tw-px-3 tw-py-2">
        <HeroIcon
          icon={isClearable ? 'x' : 'calendar'}
          color="gray"
          onClick={() => {
            handleOnIconClick(isClearable);
          }}
        />
      </div>
    );
  }

  return (
    <div className={widthClass} style={containerStyle}>
      <label
        htmlFor={id}
        className={`${
          hideLabel
            ? 'tw-sr-only'
            : 'tw-font-body tw-block tw-text-sm tw-font-medium tw-leading-5 tw-text-gray-700'
        }`}
        style={labelStyle}
      >
        {labelText}
        <span className="tw-text-error-700">{required ? ' *' : null}</span>
      </label>
      <div className="tw-relative tw-rounded-md tw-shadow-sm">
        <input type="hidden" name="date" ref={dateRef} />
        <input
          id={id}
          data-testid={id}
          name={getName()}
          ref={dateValueRef}
          type="text"
          readOnly
          value={datePickerValue}
          placeholder={placeholder}
          onBlur={onBlur}
          onClick={handleDateInputClick}
          className={`tw-font-body tw-w-full tw-block tw-pl-3 tw-pr-10 tw-py-2 tw-rounded-md tw-shadow-sm focus:tw-outline-none focus:tw-shadow-outline-blue focus:tw-border-blue-300 tw-text-sm tw-leading-5 tw-border tw-border-solid tw-border-${
            showError ? 'red' : 'gray'
          }-300 focus:tw-outline-none focus:tw-shadow-outline-${
            showError ? 'red' : 'blue'
          } focus:tw-border-${showError ? 'red' : 'blue'}-300`}
          style={datePickerStyle}
        />
        {getIcon()}
        <Transition
          show={showDatePicker}
          enter="tw-transform tw-transition tw-ease-out tw-duration-300"
          enterFrom="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
          enterTo="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
          leave="tw-transform tw-transition tw-ease-in tw-duration-200"
          leaveFrom="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
          leaveTo="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
        >
          <div
            ref={datePickerDropdownRef}
            className={`${
              adjustContainerHeight ? 'tw-relative tw-mt-2' : 'tw-mt-12'
            } tw-z-10 tw-bg-white tw-rounded-md tw-shadow tw-p-4 tw-absolute tw-top-0 tw-left-0`}
            style={{ width: '19rem' }}
          >
            <div className="tw-flex tw-justify-between tw-items-center tw-mb-2">
              <div className={`${showYearDropdown && 'tw-flex tw-mb-4'}`}>
                <span
                  className={`${showYearDropdown &&
                    'tw-mt-4'} tw-font-body tw-text-lg tw-font-bold tw-text-gray-800`}
                >
                  {MONTH_NAMES[month]}
                </span>
                {showYearDropdown ? (
                  <Select
                    id="selectYear"
                    value={year}
                    onChange={e => setYear(parseInt(e.target.value, 10))}
                    options={yearSelectOptions}
                    widthClass="tw-w-24 tw-ml-2"
                  />
                ) : (
                  <span className="tw-font-body tw-ml-1 tw-text-lg tw-text-gray-600 tw-font-normal">
                    {year}
                  </span>
                )}
              </div>
              <div>
                <div
                  type="button"
                  data-testid="date-picker-month-dec"
                  className="tw-transition tw-ease-in-out tw-duration-100 tw-inline-flex tw-cursor-pointer hover:tw-bg-gray-200 tw-p-1 tw-rounded-full"
                  onClick={() => {
                    setMonth(month - 1);
                  }}
                  onKeyDown={e => {
                    if (e.code === 'Enter') {
                      setMonth(month - 1);
                    }
                  }}
                  role="button"
                  tabIndex={-1}
                >
                  <svg
                    className="tw-h-6 tw-w-6 tw-text-gray-500 tw-inline-flex"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M15 19l-7-7 7-7"
                    />
                  </svg>
                </div>
                <div
                  className="tw-transition tw-ease-in-out tw-duration-100 tw-inline-flex tw-cursor-pointer hover:tw-bg-gray-200 tw-p-1 tw-rounded-full"
                  data-testid="date-picker-month-inc"
                  onClick={() => {
                    setMonth(month + 1);
                  }}
                  onKeyDown={e => {
                    if (e.code === 'Enter') {
                      setMonth(month + 1);
                    }
                  }}
                  role="button"
                  tabIndex={-1}
                >
                  <svg
                    className="tw-h-6 tw-w-6 tw-text-gray-500 tw-inline-flex"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </div>
            </div>

            <div className="tw-flex tw-flex-wrap tw-mb-3 tw--mx-1">
              {DAYS.map(day => (
                <div key={day} style={{ width: '14.26%' }} className="tw-px-1">
                  <div className="tw-font-body tw-text-gray-800 tw-font-medium tw-text-center tw-text-xs">
                    {day}
                  </div>
                </div>
              ))}
            </div>

            <div className="tw-flex tw-flex-wrap tw--mx-1">
              {blankDays.map(blankDay => (
                <div
                  key={blankDay}
                  style={{ width: '14.28%' }}
                  className="tw-text-center tw-border tw-p-1 tw-border-transparent tw-text-sm"
                />
              ))}

              {numberOfDays.map(date => (
                <div
                  key={date}
                  style={{ width: '14.28%' }}
                  className={`tw-px-1 tw-mb-1 ${
                    hasDisableClass(year, month, date)
                      ? 'tw-pointer-events-none tw-font-thin'
                      : null
                  }`}
                >
                  <div
                    role="button"
                    data-testid={`date-picker-day-${date}`}
                    onClick={() => getDateValue(date)}
                    onKeyDown={e => {
                      if (e.code === 'Enter') getDateValue(date);
                    }}
                    tabIndex={-1}
                    className={`tw-font-body tw-cursor-pointer tw-text-center tw-text-sm tw-leading-none tw-rounded-full tw-leading-loose tw-transition tw-ease-in-out tw-duration-100 ${
                      isSelectedDate(date) === true
                        ? 'tw-bg-blue-500 tw-text-white'
                        : 'tw-text-gray-700 hover:tw-bg-blue-200'
                    }`}
                  >
                    {date}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Transition>
      </div>
      {helpText ? (
        <p className="tw-font-body tw-my-2 tw-text-sm tw-text-gray-500">
          {helpText}
        </p>
      ) : null}
      {showError ? (
        <p className="tw-font-body tw-my-2 tw-text-sm tw-text-red-600">
          {error}
        </p>
      ) : null}
    </div>
  );
};

DatePicker.defaultProps = {
  id: 'datepicker',
  value: '',
  placeholder: 'Select Date',
  labelText: 'Select Date',
  required: false,
  datePickerStyle: {},
  containerStyle: {},
  labelStyle: {},
  onChange: null,
  onBlur: null,
  showError: false,
  error: '',
  helpText: '',
  hideLabel: false,
  widthClass: 'tw-col-span-10',
  adjustContainerHeight: false,
  showYearDropdown: false,
  minDate: '',
  clearable: true
};

const styleProps = PropTypes.objectOf(
  PropTypes.oneOfType([PropTypes.string, PropTypes.number])
);

DatePicker.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  labelText: PropTypes.string,
  required: PropTypes.bool,
  datePickerStyle: styleProps,
  containerStyle: styleProps,
  labelStyle: styleProps,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  showError: PropTypes.bool,
  error: PropTypes.string,
  helpText: PropTypes.string,
  hideLabel: PropTypes.bool,
  widthClass: PropTypes.string,
  adjustContainerHeight: PropTypes.bool,
  showYearDropdown: PropTypes.bool,
  minDate: PropTypes.string,
  clearable: PropTypes.bool
};

export default DatePicker;
