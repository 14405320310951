import React, { Fragment } from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';

const Tooltip = ({
  id,
  classNames,
  children,
  place,
  afterHide,
  TooltipElement,
  tooltipElementColor,
  paddingClass,
  widthClass
}) => {
  return (
    <Fragment>
      {typeof TooltipElement === 'function' ? (
        TooltipElement(id, tooltipElementColor)
      ) : (
        <TooltipElement id={id} color={tooltipElementColor} />
      )}
      <ReactTooltip
        className={`tw-opacity-100 tw-font-body tw-rounded-lg tw-shadow-lg tw-overflow-hidden ${widthClass} ${classNames} ${paddingClass}`}
        id={id}
        place={place}
        afterHide={afterHide}
        effect="solid"
        globalEventOff="click"
        clickable
      >
        <div>{children}</div>
      </ReactTooltip>
    </Fragment>
  );
};

function getTooltipElement(id, color) {
  return (
    <svg
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      className={`tw-h-4 tw-ml-4 tw-cursor-pointer tw-text-${color}-600 question-mark-circle tw-w-6 tw-h-6`}
      data-tip
      data-for={id}
      data-border
      data-border-color="#d2d6dc"
      data-background-color="#fff"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  );
}

Tooltip.defaultProps = {
  id: 'registerTip',
  classNames: '',
  children: null,
  place: 'top',
  afterHide: null,
  TooltipElement: (id, color) => getTooltipElement(id, color),
  tooltipElementColor: 'alpha',
  paddingClass: 'tw-p-5',
  widthClass: 'tw-max-w-md'
};

const colorPropType = PropTypes.oneOf([
  'alpha',
  'bravo',
  'charlie',
  'success',
  'warning',
  'error',
  'gray'
]);

Tooltip.propTypes = {
  id: PropTypes.string,
  classNames: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  place: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  afterHide: PropTypes.func,
  TooltipElement: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  tooltipElementColor: colorPropType,
  paddingClass: PropTypes.string,
  widthClass: PropTypes.string
};

export default Tooltip;
