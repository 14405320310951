import removeDiacritics from './removeDiacritics';

const capitalize = s =>
  typeof s === 'string' ? s.charAt(0).toUpperCase() + s.slice(1) : s;

const parameterize = s =>
  removeDiacritics(s)
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, '-')
    .replace(/(^-|-$)/g, '');

function titleize(sentence, splitBy = ' ') {
  if (!sentence.split) return sentence;
  const titleizeWord = string => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };
  const result = [];
  sentence.split(splitBy).forEach(w => {
    result.push(titleizeWord(w));
  });
  return result.join(' ');
}

const toSentence = (arr, connector = 'and') => {
  if (arr.length <= 1) return arr[0] || '';
  return `${arr.slice(0, arr.length - 1).join(', ')} ${connector} ${arr.slice(
    -1
  )}`;
};

const toCamel = (s, d = '') => {
  return s.replace(/([-_][a-z])/gi, $1 => {
    return $1
      .toUpperCase()
      .replace('-', d)
      .replace('_', d);
  });
};

const toSnake = s => {
  return s.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
};

const parseUnderscores = s => titleize(s.split('_').join(' '));

const nameInitials = s => {
  if (!s.split) return s.charAt(0).toUpperCase();
  const initializeWord = string => {
    return string.charAt(0).toUpperCase();
  };
  const result = [];
  s.split(' ').forEach((w, i) => {
    if (i <= 1) result.push(initializeWord(w));
  });
  return result.join('');
};

const textTruncate = (str, length = 85, ending = '...') => {
  if (str && str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  }
  return str;
};

// last name ends in an s or z so that it is s' or z' and not s’s or z’s
const resolveApostrophe = ln => {
  if (typeof ln === 'string') {
    const lastChar = ln.charAt(ln.length - 1).toLowerCase();
    if (lastChar === 's' || lastChar === 'z') {
      return `${ln}'`;
    }
    return `${ln}'s`;
  }
};

export {
  capitalize,
  parameterize,
  titleize,
  toSentence,
  toCamel,
  parseUnderscores,
  nameInitials,
  textTruncate,
  toSnake,
  resolveApostrophe
};
