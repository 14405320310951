/* eslint-disable react/no-danger */
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  createErrorMessageSelector,
  createLoadedSelector
} from 'appState/selectors';
import isEmpty from 'lodash.isempty';
import PropTypes from 'prop-types';
import {
  cancelTask,
  fetchTasksActivities,
  resetIsLoaded,
  fetchNextTask
} from 'appState/actions/ActionCreators';
import { CurrentAccountContext } from 'components/shared/context/CurrentAccountContext';
import Modal from 'components/Theme/Modal';
import { formatTimestamp } from 'lib/utils/dateTime';
import { CANCEL_TASK } from 'app-state/actions/constants/activity.actions';
import getUserName from 'lib/utils/getUserName';
import { DATE_TIME_FORMAT_TASK } from 'config/constants';
import TextArea from 'components/Theme/TextArea';

const TaskCancelModal = ({ task, show, setShow }) => {
  const dispatch = useDispatch();
  const currentAccount = useContext(CurrentAccountContext);
  const errorSelector = createErrorMessageSelector([CANCEL_TASK]);
  const loadedSelector = createLoadedSelector([CANCEL_TASK]);
  const structuredSelector = createStructuredSelector({
    currentContact: state => state.contact.currentContact,
    error: state => errorSelector(state),
    isLoaded: state => loadedSelector(state)
  });

  const { currentContact, error, isLoaded } = useSelector(structuredSelector);
  const [noteText, setNoteText] = useState('');
  useEffect(() => {
    if (isLoaded && isEmpty(error)) {
      dispatch(
        fetchTasksActivities({
          accountId: currentAccount.id,
          contactId: currentContact.id
        })
      );
      dispatch(fetchNextTask({ contactId: currentContact.id }));
    }
    return () => {
      dispatch(resetIsLoaded(CANCEL_TASK));
    };
  }, [error, isLoaded, dispatch, currentAccount, currentContact]);

  function handleCancelTaskAction() {
    dispatch(cancelTask({ taskId: task.sourceable_id, note: noteText }));
  }

  return (
    <Modal
      headerText="Cancel this Task?"
      primaryAction={handleCancelTaskAction}
      primaryActionText="Yes"
      secondaryAction={() => {
        setShow(false);
      }}
      secondaryActionText="No"
      showPrimaryAction
      showSecondaryAction
      canHandleClickOutside
      show={show}
      setShow={setShow}
      color="error"
      HeaderIcon={color => {
        return (
          <svg
            className={`tw-h-6 tw-w-6 tw-text-${color}-600`}
            stroke="currentColor"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
            />
          </svg>
        );
      }}
    >
      <p className="tw-mb-5">
        <span className="tw-font-medium">Title:</span> {task.task_title}
        <br />
        <span className="tw-font-medium">Description:</span>{' '}
        <span
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: task.parsed_task_description }}
        />
        <span className="tw-font-medium">Due Date:</span>{' '}
        {task.task_due_date &&
          formatTimestamp(
            task.task_due_date,
            currentAccount.time_zone,
            false,
            DATE_TIME_FORMAT_TASK,
            true
          )}
        <br />
        <span className="tw-font-medium">Assigned Staff:</span>{' '}
        {getUserName(task)}
        <br />
      </p>
      <TextArea
        id="note"
        value={noteText}
        labelText="Notes"
        placeholder="Add Notes"
        hideLabel
        onChange={e => {
          setNoteText(e.target.value);
        }}
      />
    </Modal>
  );
};

const userObjectShape = {
  first_name: PropTypes.string,
  last_name: PropTypes.string
};

const taskObjectShape = {
  task_title: PropTypes.string,
  task_description: PropTypes.string,
  parsed_task_description: PropTypes.string,
  task_due_date: PropTypes.string,
  user: PropTypes.shape(userObjectShape)
};

TaskCancelModal.defaultProps = {
  task: {},
  show: false,
  setShow: () => null
};

TaskCancelModal.propTypes = {
  task: PropTypes.shape(taskObjectShape),
  show: PropTypes.bool,
  setShow: PropTypes.func
};

export default TaskCancelModal;
