import React, { Fragment, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import isEmpty from 'lodash.isempty';
import { broadcastTemplateCreate } from 'appState/actions/ActionCreators';
import { CurrentAccountContext } from 'components/shared/context/CurrentAccountContext';
import Styled from './NewBroadcastCard.styles';

const NewBroadcastCard = props => {
  const { title, description, image, templateType } = props;
  const currentAccount = useContext(CurrentAccountContext);
  const [selectedTemplateType, setSelectedTemplateType] = useState('');
  const isTemplate = templateType === 'template';
  const dispatch = useDispatch();
  const history = useHistory();

  function startNewBroadcast() {
    history.push(`/ui/broadcast-center/${templateType}/select-template`);
  }

  function createNewTemplate() {
    dispatch(
      broadcastTemplateCreate({ templateType: selectedTemplateType, history })
    );
  }

  function selectTemplateType(e) {
    let selectedType;
    if (e.target.textContent.includes('Email')) selectedType = 'email';
    if (e.target.textContent.includes('SMS')) selectedType = 'sms';
    setSelectedTemplateType(selectedType);
  }

  useEffect(() => {
    if (!isEmpty(selectedTemplateType)) createNewTemplate();
  }, [selectedTemplateType]);

  return (
    <div
      className={`col-md-12 ${
        currentAccount.sms_enabled ? 'col-lg-4' : 'col-lg-6'
      }`}
    >
      <Styled.Card className="card">
        <header className="card-header">
          <h4 className="card-title mb-0">
            <strong>{title}</strong>
          </h4>
        </header>
        <div className="card-content">
          <div className="card-body" style={{ overflowY: 'hidden' }}>
            <div className="col-sm-12">
              <img
                src={image}
                style={{
                  display: 'block',
                  margin: '0 auto'
                }}
                className="pb-20"
                alt="new broadcast card svg"
              />
            </div>
            <div className="col-sm-12">
              <div>
                <p style={{ textAlign: 'center' }}>{description}</p>
              </div>
            </div>
          </div>
          <footer
            className="card-footer text-right"
            style={{ borderRadius: '0 0 10px 10px' }}
          >
            {isTemplate ? (
              <Fragment>
                <button
                  type="button"
                  className="btn btn-flat btn-info dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Create New {templateType}
                </button>
                <div
                  className="dropdown-menu dropdown-menu-right"
                  x-placement="bottom-end"
                >
                  <a
                    className="dropdown-item"
                    href="#"
                    id="create-email-template"
                    onClick={selectTemplateType}
                  >
                    Create Email Template
                  </a>
                  {currentAccount.sms_enabled && (
                    <a
                      className="dropdown-item"
                      href="#"
                      id="create-sms-template"
                      onClick={selectTemplateType}
                    >
                      Create SMS Template
                    </a>
                  )}
                </div>
              </Fragment>
            ) : (
              <a
                className="btn btn-flat btn-info"
                href="#"
                onClick={startNewBroadcast}
              >
                Create New {templateType}
              </a>
            )}
          </footer>
        </div>
      </Styled.Card>
    </div>
  );
};

NewBroadcastCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  templateType: PropTypes.string.isRequired
};

export default NewBroadcastCard;
