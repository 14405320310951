import React, { useState } from 'react';
import PropTypes from 'prop-types';
import uplaunchLogo from 'images/uplaunch-orange.svg';
import { capitalize } from 'lib/utils/string';

const NavBar = ({ navLinks }) => {
  const [activeTab, setActiveTab] = useState('');

  function handleNavLinkClick(e, title, onClick) {
    if (e.code && e.code !== 'Enter') return;
    setActiveTab(title.toLowerCase());
    onClick(title.toLowerCase());
  }

  return (
    <nav className="tw-bg-white tw-border-b tw-border-t-0 tw-border-l-0 tw-border-r-0 tw-border-solid tw-border-gray-200">
      <div className="tw-px-4 sm:tw-px-6 lg:tw-px-8">
        <div className="tw-flex tw-justify-between tw-h-20">
          <div className="tw-flex">
            <div className="tw-flex-shrink-0 tw-flex tw-items-center">
              <img
                className="tw-block tw-h-12 tw-w-auto"
                src={uplaunchLogo}
                alt="UpLaunch logo"
              />
            </div>
            <div className="tw-hidden sm:tw--my-px sm:tw-ml-10 sm:tw-flex">
              {navLinks.map((navLink, index) => {
                return (
                  <a
                    key={navLink.title}
                    data-testid={navLink.title}
                    className={`tw-font-body tw-cursor-pointer tw-inline-flex tw-items-center tw-px-1 tw-pt-1 tw-border-b-2 tw-border-t-0 tw-border-r-0 tw-border-l-0 tw-border-solid tw-text-sm tw-font-medium tw-leading-5 focus:tw-outline-none focus:tw-border-alpha-700 tw-transition tw-duration-150 tw-ease-in-out ${
                      activeTab === navLink.title
                        ? 'tw-border-alpha-500 tw-text-gray-900'
                        : 'tw-border-transparent tw-text-gray-500 hover:tw-text-gray-700 hover:tw-border-gray-300 focus:tw-text-gray-700 focus:tw-border-gray-300'
                    } ${index !== 0 ? 'tw-ml-8' : ''}`}
                    onClick={e =>
                      handleNavLinkClick(e, navLink.title, navLink.onClick)
                    }
                    onKeyDown={e =>
                      handleNavLinkClick(e, navLink.title, navLink.onClick)
                    }
                    role="button"
                    tabIndex={-1}
                  >
                    {capitalize(navLink.title)}
                  </a>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

NavBar.defaultProps = {
  navLinks: []
};

const navLinkShape = PropTypes.shape({
  title: PropTypes.string,
  onClick: PropTypes.func
});

NavBar.propTypes = {
  navLinks: PropTypes.arrayOf(navLinkShape)
};

export default NavBar;
