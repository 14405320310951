import React, { useEffect, useMemo, useState } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { createHtmlPortalNode, OutPortal } from 'react-reverse-portal';
import configureStore from 'appState/stores/configureStore';
import {
  setCurrentAccount,
  setSyncWizardAccount
} from 'appState/actions/ActionCreators';
import { EnvVariablesProvider } from 'components/shared/context/EnvVariablesContext';
import { PortalProvider } from 'components/shared/context/PortalContext';
import { CurrentAccountProvider } from 'components/shared/context/CurrentAccountContext';
import { CurrentUserProvider } from 'components/shared/context/CurrentUserContext';
import { UsersProvider } from 'components/shared/context/UsersContext';
import { axiosSetup } from 'lib/utils/axios-setup';
import {
  setStore,
  fetchAndStoreTabActions
} from 'lib/services/activityFeedDecorator';
import OverlordUtilities from './OverlordUtilities';
import BroadcastCenter from './BroadcastCenter';
import ContactNew from './Contacts/New';
import ContactsImport from './Contacts/Import';
import ZenPlannerUlProducts from './ZenPlannerIntergration';
import CsvImportHistory from './CsvImport/History';
import SmsInbox from './SmsInbox';
import ContactProfile from './Contact/Profile';
import FourOFour from './FourOFour';
import ScrollToTop from './ScrollToTop';
import SyncWizard from './SyncWizard';
import MarketPlace from './MarketPlace';
import PlanBilling from './PlanBilling';
import MyProducts from './MyProducts/Index';
import ProductSubscriptionSetup from './ProductSubscriptionSetup';
import FlashMessages from './FlashMessages';
import ProductAddContacts from './MyProducts/ProductAddContacts/index';
import AppointmentTypes from './AppointmentTypes';
import LeadSources from './LeadSources';
import OptInPage from './OptInLandingPage';
import NewAppointmentType from './AppointmentTypes/New';
import EditAppointmentType from './AppointmentTypes/Edit';

const DEFAULT_PATH = '/ui';

const store = configureStore();
setStore(store);

const App = props => {
  const { env, currentAccount, currentUser } = props;

  const baseUrl = env.API_BASE;
  const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
  // eslint-disable-next-line camelcase
  const { authentication_token } = currentUser;

  axiosSetup({
    baseUrl,
    csrfToken,
    authentication_token,
    accountId: currentAccount.id
  });
  fetchAndStoreTabActions();

  const [portalNodes, setPortalNodes] = useState({
    modalNode: null,
    notificationNode: null
  });
  const modalPortalNode = useMemo(() => createHtmlPortalNode(), []);
  const notificationPortalNode = useMemo(() => createHtmlPortalNode(), []);
  const buttonDropdownPortalNode = useMemo(() => createHtmlPortalNode(), []);

  useEffect(() => {
    if (modalPortalNode && notificationPortalNode && buttonDropdownPortalNode) {
      setPortalNodes({
        modalNode: modalPortalNode,
        notificationNode: notificationPortalNode,
        buttonDropdownNode: buttonDropdownPortalNode
      });
    }
  }, [modalPortalNode, notificationPortalNode, buttonDropdownPortalNode]);

  useEffect(() => {
    store.dispatch(setCurrentAccount(currentAccount));
    store.dispatch(setSyncWizardAccount(currentAccount));
  }, [currentAccount]);

  return (
    <div id="tailwind-selector-id">
      <div className="theme-app-default">
        <div style={{ minHeight: 'calc(100vh - 120px)' }}>
          <Provider store={store}>
            <EnvVariablesProvider value={env}>
              <PortalProvider value={portalNodes}>
                <FlashMessages />
                <BrowserRouter>
                  <ScrollToTop />
                  <div>
                    <Switch>
                      <Redirect
                        exact
                        from={DEFAULT_PATH}
                        to={`${DEFAULT_PATH}/overlord-utilities`}
                      />
                      <Route exact path={DEFAULT_PATH} />
                      <Route
                        path={[
                          `${DEFAULT_PATH}/sms-inbox`,
                          `${DEFAULT_PATH}/sms-inbox/:contactId`
                        ]}
                        render={() => (
                          <CurrentAccountProvider value={currentAccount}>
                            <CurrentUserProvider value={currentUser}>
                              <SmsInbox />
                            </CurrentUserProvider>
                          </CurrentAccountProvider>
                        )}
                      />
                      <Route
                        path={[
                          `${DEFAULT_PATH}/broadcast-center`,
                          `${DEFAULT_PATH}/broadcast-templates`
                        ]}
                        render={() => (
                          <CurrentAccountProvider value={currentAccount}>
                            <CurrentUserProvider value={currentUser}>
                              <BroadcastCenter />
                            </CurrentUserProvider>
                          </CurrentAccountProvider>
                        )}
                      />
                      <Route
                        path={`${DEFAULT_PATH}/contacts/new`}
                        render={() => (
                          <CurrentAccountProvider value={currentAccount}>
                            <CurrentUserProvider value={currentUser}>
                              <UsersProvider>
                                <ContactNew />
                              </UsersProvider>
                            </CurrentUserProvider>
                          </CurrentAccountProvider>
                        )}
                      />
                      <Route
                        path={`${DEFAULT_PATH}/contacts/csv_import/new`}
                        render={() => (
                          <CurrentAccountProvider value={currentAccount}>
                            <CurrentUserProvider value={currentUser}>
                              <UsersProvider>
                                <ContactsImport />
                              </UsersProvider>
                            </CurrentUserProvider>
                          </CurrentAccountProvider>
                        )}
                      />
                      <Route
                        path={`${DEFAULT_PATH}/link_zen_to_ul`}
                        render={() => (
                          <CurrentAccountProvider value={currentAccount}>
                            <CurrentUserProvider value={currentUser}>
                              <UsersProvider>
                                <ZenPlannerUlProducts />
                              </UsersProvider>
                            </CurrentUserProvider>
                          </CurrentAccountProvider>
                        )}
                      />
                      <Route
                        path={`${DEFAULT_PATH}/contacts/csv_import/:id/edit`}
                        render={() => (
                          <CurrentAccountProvider value={currentAccount}>
                            <CurrentUserProvider value={currentUser}>
                              <UsersProvider>
                                <ContactsImport />
                              </UsersProvider>
                            </CurrentUserProvider>
                          </CurrentAccountProvider>
                        )}
                      />
                      <Route
                        path={`${DEFAULT_PATH}/contacts/csv_import/:id/status`}
                        render={() => (
                          <CurrentAccountProvider value={currentAccount}>
                            <CurrentUserProvider value={currentUser}>
                              <UsersProvider>
                                <ContactsImport />
                              </UsersProvider>
                            </CurrentUserProvider>
                          </CurrentAccountProvider>
                        )}
                      />
                      <Route
                        path={`${DEFAULT_PATH}/contacts/csv_import/history`}
                        render={() => (
                          <CurrentAccountProvider value={currentAccount}>
                            <CurrentUserProvider value={currentUser}>
                              <UsersProvider>
                                <CsvImportHistory />
                              </UsersProvider>
                            </CurrentUserProvider>
                          </CurrentAccountProvider>
                        )}
                      />
                      <Route
                        path={[
                          `${DEFAULT_PATH}/contacts/:id`,
                          `${DEFAULT_PATH}/contacts/:id/dummy`
                        ]}
                        render={() => (
                          <CurrentAccountProvider value={currentAccount}>
                            <CurrentUserProvider value={currentUser}>
                              <UsersProvider>
                                <ContactProfile />
                              </UsersProvider>
                            </CurrentUserProvider>
                          </CurrentAccountProvider>
                        )}
                      />
                      <Route
                        path={`${DEFAULT_PATH}/sync-wizard`}
                        render={() => (
                          <CurrentAccountProvider value={currentAccount}>
                            <SyncWizard />
                          </CurrentAccountProvider>
                        )}
                      />
                      <Route
                        path={`${DEFAULT_PATH}/marketplace`}
                        render={() => (
                          <CurrentAccountProvider value={currentAccount}>
                            <CurrentUserProvider value={currentUser}>
                              <UsersProvider>
                                <MarketPlace />
                              </UsersProvider>
                            </CurrentUserProvider>
                          </CurrentAccountProvider>
                        )}
                      />
                      <Route
                        path={`${DEFAULT_PATH}/billing`}
                        render={() => (
                          <CurrentAccountProvider value={currentAccount}>
                            <CurrentUserProvider value={currentUser}>
                              <UsersProvider>
                                <PlanBilling />
                              </UsersProvider>
                            </CurrentUserProvider>
                          </CurrentAccountProvider>
                        )}
                      />
                      <Route
                        path={`${DEFAULT_PATH}/my-products/:id/setup`}
                        render={() => (
                          <CurrentAccountProvider value={currentAccount}>
                            <CurrentUserProvider value={currentUser}>
                              <UsersProvider>
                                <ProductSubscriptionSetup />
                              </UsersProvider>
                            </CurrentUserProvider>
                          </CurrentAccountProvider>
                        )}
                      />
                      <Route
                        path={`${DEFAULT_PATH}/my-products/:id/add-contacts`}
                        render={() => (
                          <CurrentAccountProvider value={currentAccount}>
                            <ProductAddContacts />
                          </CurrentAccountProvider>
                        )}
                      />
                      <Route
                        path={`${DEFAULT_PATH}/my-products`}
                        render={() => (
                          <CurrentAccountProvider value={currentAccount}>
                            <CurrentUserProvider value={currentUser}>
                              <UsersProvider>
                                <MyProducts />
                              </UsersProvider>
                            </CurrentUserProvider>
                          </CurrentAccountProvider>
                        )}
                      />
                      <Route
                        path={`${DEFAULT_PATH}/appointment-types/:id/edit`}
                        render={() => (
                          <CurrentAccountProvider value={currentAccount}>
                            <CurrentUserProvider value={currentUser}>
                              <UsersProvider>
                                <EditAppointmentType />
                              </UsersProvider>
                            </CurrentUserProvider>
                          </CurrentAccountProvider>
                        )}
                      />
                      <Route
                        path={`${DEFAULT_PATH}/appointment-types/new`}
                        render={() => (
                          <CurrentAccountProvider value={currentAccount}>
                            <CurrentUserProvider value={currentUser}>
                              <UsersProvider>
                                <NewAppointmentType />
                              </UsersProvider>
                            </CurrentUserProvider>
                          </CurrentAccountProvider>
                        )}
                      />
                      <Route
                        path={`${DEFAULT_PATH}/appointment-types`}
                        render={() => (
                          <CurrentAccountProvider value={currentAccount}>
                            <CurrentUserProvider value={currentUser}>
                              <UsersProvider>
                                <AppointmentTypes />
                              </UsersProvider>
                            </CurrentUserProvider>
                          </CurrentAccountProvider>
                        )}
                      />
                      <Route
                        path={`${DEFAULT_PATH}/lead-sources`}
                        render={() => (
                          <CurrentAccountProvider value={currentAccount}>
                            <CurrentUserProvider value={currentUser}>
                              <UsersProvider>
                                <LeadSources />
                              </UsersProvider>
                            </CurrentUserProvider>
                          </CurrentAccountProvider>
                        )}
                      />
                      <Route
                        path={`${DEFAULT_PATH}/overlord-utilities`}
                        render={() => (
                          <CurrentAccountProvider value={currentAccount}>
                            <CurrentUserProvider value={currentUser}>
                              <UsersProvider>
                                <OverlordUtilities />
                              </UsersProvider>
                            </CurrentUserProvider>
                          </CurrentAccountProvider>
                        )}
                      />
                      <Route
                        path={`${DEFAULT_PATH}/join/:id`}
                        render={() => <OptInPage />}
                      />
                      <Route component={FourOFour} />
                    </Switch>
                  </div>
                </BrowserRouter>
                {modalPortalNode && <OutPortal node={modalPortalNode} />}
                {notificationPortalNode && (
                  <OutPortal node={notificationPortalNode} />
                )}
                {buttonDropdownPortalNode && (
                  <OutPortal node={buttonDropdownPortalNode} />
                )}
              </PortalProvider>
            </EnvVariablesProvider>
          </Provider>
        </div>
      </div>
    </div>
  );
};

const currentAccountShape = {
  name: PropTypes.string
};

const currentUserShape = {
  authenticationToken: PropTypes.string
};

App.propTypes = {
  env: PropTypes.objectOf(PropTypes.string).isRequired,
  currentAccount: PropTypes.shape(currentAccountShape).isRequired,
  currentUser: PropTypes.shape(currentUserShape).isRequired
};

export default App;
