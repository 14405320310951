import {
  fetchPartner,
  setOverlordLayoutHeaderText,
  deleteProduct,
  togglePublishProduct,
  flashErrorMessage
} from 'appState/actions/ActionCreators';
import Table from 'components/Theme/Table';
import { useParams } from 'react-router-dom';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  createErrorMessageSelector,
  createLoadedSelector
} from 'appState/selectors';
import Skeleton from 'react-loading-skeleton';
import isEmpty from 'lodash.isempty';
import Button from 'components/Theme/Button';
import Modal from 'components/Theme/Modal';
import TextInput from 'components/Theme/TextInput';
import { TOGGLE_PUBLISH_PRODUCT } from 'app-state/actions/constants/product.actions';

const PartnerDashboard = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState('');
  const [currentProductId, setCurrentProductId] = useState(null);
  const dispatch = useDispatch();
  const { id } = useParams();
  const toggleLoadedSelector = createLoadedSelector([TOGGLE_PUBLISH_PRODUCT]);
  const toggleErrorSelector = createErrorMessageSelector([
    TOGGLE_PUBLISH_PRODUCT
  ]);

  useEffect(() => {
    dispatch(fetchPartner({ partnerId: id }));
  }, []);

  const structuredSelector = createStructuredSelector({
    currentPartner: state => state.partner.currentPartner,
    products: state => state.partner.products,
    togglePublishLoaded: state => toggleLoadedSelector(state),
    togglePublishError: state => toggleErrorSelector(state)
  });

  const {
    currentPartner,
    products,
    togglePublishLoaded,
    togglePublishError
  } = useSelector(structuredSelector);

  useEffect(() => {
    if (!isEmpty(currentPartner))
      dispatch(setOverlordLayoutHeaderText(`Partner - ${currentPartner.name}`));
  }, [currentPartner]);

  useEffect(() => {
    if (togglePublishLoaded) {
      if (!isEmpty(togglePublishError)) {
        dispatch(flashErrorMessage(togglePublishError));
      }
    }
  });

  function viewProduct(productId) {
    window.location = `/overlord/partners/${currentPartner.id}/products/${productId}`;
  }

  function productDelete(productId) {
    setShowDeleteModal(true);
    setCurrentProductId(productId);
  }

  function handleDeleteProduct() {
    dispatch(deleteProduct({ currentProductId, deleteConfirm }));
    setCurrentProductId(null);
    setDeleteConfirm('');
  }

  function newProduct() {
    window.location = `/overlord/partners/${currentPartner.id}/products/create`;
  }

  function changeDeleteConfirmValue(e) {
    setDeleteConfirm(e.target.value);
  }

  const handleToggleClick = (btnRef, isToggle) => {
    const productParams = {
      productId: btnRef.row.id,
      publish: isToggle
    };
    dispatch(togglePublishProduct(productParams));
  };

  return (
    <Fragment>
      <div className="tw-mb-10">
        <div className="tw-mt-5 tw-grid tw-grid-cols-1 tw-gap-5 sm:tw-grid-cols-3">
          <div className="tw-bg-white tw-overflow-hidden tw-shadow-lg tw-rounded-lg">
            <div className="tw-px-4 tw-py-5 sm:tw-p-6">
              <dl>
                <dt className="tw-text-sm tw-leading-5 tw-font-medium tw-text-gray-500 tw-truncate">
                  Total Products
                </dt>
                <dd className="tw-mt-1 tw-text-3xl tw-leading-9 tw-font-semibold tw-text-gray-900">
                  {currentPartner ? (
                    currentPartner.dashboard_metrics?.products
                  ) : (
                    <Skeleton width={100} height={20} />
                  )}
                </dd>
              </dl>
            </div>
          </div>
          <div className="tw-bg-white tw-overflow-hidden tw-shadow-lg tw-rounded-lg">
            <div className="tw-px-4 tw-py-5 sm:tw-p-6">
              <dl>
                <dt className="tw-text-sm tw-leading-5 tw-font-medium tw-text-gray-500 tw-truncate">
                  Accounts Using Products
                </dt>
                <dd className="tw-mt-1 tw-text-3xl tw-leading-9 tw-font-semibold tw-text-gray-900">
                  {currentPartner ? (
                    currentPartner.dashboard_metrics?.accounts_using_products
                  ) : (
                    <Skeleton width={100} height={20} />
                  )}
                </dd>
              </dl>
            </div>
          </div>
          <div className="tw-bg-white tw-overflow-hidden tw-shadow-lg tw-rounded-lg">
            <div className="tw-px-4 tw-py-5 sm:tw-p-6">
              <dl>
                <dt className="tw-text-sm tw-leading-5 tw-font-medium tw-text-gray-500 tw-truncate">
                  Contacts in Campaigns
                </dt>
                <dd className="tw-mt-1 tw-text-3xl tw-leading-9 tw-font-semibold tw-text-gray-900">
                  {currentPartner ? (
                    currentPartner.dashboard_metrics?.contacts_in_campaigns
                  ) : (
                    <Skeleton width={100} height={20} />
                  )}
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
      <div className="tw-pb-5 tw-border-b tw-border-gray-200 tw-space-y-3 sm:tw-flex sm:tw-items-center sm:tw-justify-between sm:tw-space-x-4 sm:tw-space-y-0">
        <h3 className="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
          Products
        </h3>
        <div>
          <Button text="New Product" type="alpha" onClick={newProduct} />
        </div>
      </div>
      <Table
        rows={products}
        includedColumns={['id', 'name', 'product_lifecycles']}
        onEdit={viewProduct}
        editable
        deletable
        hasToggleAction
        onClickToggleBtn={handleToggleClick}
        customActionHeader="Published"
        customToggleActionKeyName="published"
        disabledTooltipText="You may only delete products not in use by any accounts."
        onDelete={productDelete}
        emptyMessage="No products created for this partner yet."
        customActionKeyName="product_deletable"
      />
      <Modal
        color="error"
        headerText="Delete Product?"
        bodyText="Are you sure you want to delete this contact?"
        primaryAction={() => handleDeleteProduct()}
        showHeaderIcon={false}
        primaryActionText="Delete"
        secondaryActionText="Cancel"
        secondaryAction={() => setDeleteConfirm('')}
        setShow={setShowDeleteModal}
        show={showDeleteModal}
        showSecondaryAction
        canHandleClickOutside={false}
      >
        <p className="tw-mb-5">
          Please type <b>DELETE</b> if you want to delete this product.
        </p>
        <div>
          <TextInput
            id="deleteConfirmation"
            value={deleteConfirm}
            hideLabel
            labelText="Confirmation"
            placeholder="Please Confirm"
            onChange={changeDeleteConfirmValue}
            onBlur={changeDeleteConfirmValue}
          />
        </div>
      </Modal>
    </Fragment>
  );
};

export default PartnerDashboard;
