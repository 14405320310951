import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useFormik } from 'formik';
import isEmpty from 'lodash.isempty';
import moment from 'moment-timezone';
import { productsFetch } from 'appState/actions/ActionCreators';
import Button from 'components/Theme/Button';
import Select from 'components/Theme/Select';
import { productSubscriptionSchema } from 'lib/validation/schema';

const ProductSubscription = ({
  subscriptionId,
  productSubscriptionId,
  isCreating,
  setIsEditing,
  productSubscriptions,
  setProductSubscriptions
}) => {
  const [productOptions, setProductOptions] = useState([]);
  const [currentProductSubscription, setCurrentProductSubscription] = useState(
    {}
  );
  const dispatch = useDispatch();

  const structuredSelector = createStructuredSelector({
    products: state => state.subscription.products
  });

  const { products } = useSelector(structuredSelector);

  useEffect(() => {
    if (isEmpty(products)) dispatch(productsFetch());

    if (productSubscriptionId) {
      setCurrentProductSubscription(
        productSubscriptions.filter(sub => {
          return productSubscriptionId === sub.id;
        })[0]
      );
    }
  }, [productSubscriptionId, currentProductSubscription, dispatch]);

  useEffect(() => {
    if (!isEmpty(products) && !isEmpty(productSubscriptions)) {
      setProductOptions(
        products.map(p => ({
          id: p.id,
          displayName: p.name,
          disabled: productSubscriptions
            // eslint-disable-next-line no-underscore-dangle
            .filter(r => !r._destroy)
            .map(s => s.product_id.toString())
            .includes(p.id.toString()),
          value: p.id
        }))
      );
    }
    if (!isEmpty(products)) {
      setProductOptions(
        products.map(p => ({
          id: p.id,
          displayName: p.name,
          value: p.id
        }))
      );
    }
  }, [products, productSubscriptions]);

  function handleSubmit(values) {
    const newProductSubscription = {};
    if (subscriptionId) newProductSubscription.subscription_id = subscriptionId;
    if (productSubscriptionId)
      newProductSubscription.id = productSubscriptionId;
    if (!isEmpty(values)) {
      // eslint-disable-next-line no-underscore-dangle
      newProductSubscription._id =
        productSubscriptions.length > 0
          ? (productSubscriptions[productSubscriptions.length - 1].id ||
              // eslint-disable-next-line no-underscore-dangle
              productSubscriptions[productSubscriptions.length - 1]._id) + 1
          : 1;
      if (values.productId) {
        newProductSubscription.product_id = values.productId;
        newProductSubscription.name = products.filter(
          p => p.id.toString() === values.productId
        )[0].name;
      }

      newProductSubscription.created_at = moment().format();
    }

    if (isCreating) {
      setProductSubscriptions([
        ...productSubscriptions,
        newProductSubscription
      ]);
    } else {
      setProductSubscriptions(
        productSubscriptions.map(sub => {
          if (productSubscriptionId === sub.id) return newProductSubscription;
          return sub;
        })
      );
    }

    setIsEditing(false);
  }

  const formik = useFormik({
    initialValues: {
      productId:
        (currentProductSubscription && currentProductSubscription.product_id) ||
        ''
    },
    enableReinitialize: true,
    validationSchema: productSubscriptionSchema,
    onSubmit: values => {
      handleSubmit(values);
    }
  });

  return (
    <div className="tw-mx-auto tw-max-w-7xl">
      <div className="tw-shadow sm:tw-rounded-md sm:tw-overflow-hidden">
        <div className="tw-px-4 tw-py-5 tw-bg-white sm:tw-p-6">
          <div className="tw-grid tw-grid-cols-10 tw-gap-6">
            <Select
              id="product_id"
              value={formik.values.productId}
              options={productOptions}
              labelText="Products"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              showError={formik.touched.productId && !!formik.errors.productId}
              error={formik.errors.productId}
              widthClass="tw-col-span-10 sm:tw-col-span-10"
            />
          </div>
        </div>
        <div className="tw-px-4 tw-py-3 tw-bg-gray-50 tw-text-right sm:tw-px-6">
          <span className="tw-mr-3 tw-inline-flex tw-rounded-md tw-shadow-sm">
            <Button
              text="Cancel"
              type="white"
              onClick={() => setIsEditing(false)}
            />
          </span>
          <span className="tw-inline-flex tw-rounded-md tw-shadow-sm">
            <Button
              text={isCreating ? 'Add' : 'Change'}
              onClick={formik.handleSubmit}
            />
          </span>
        </div>
      </div>
    </div>
  );
};

ProductSubscription.defaultProps = {
  subscriptionId: 0,
  productSubscriptionId: 0,
  productSubscriptions: [],
  setProductSubscriptions: () => null
};

const productSubscriptionShape = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  productId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  subscriptionId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
});

ProductSubscription.propTypes = {
  subscriptionId: PropTypes.number,
  productSubscriptionId: PropTypes.number,
  isCreating: PropTypes.bool.isRequired,
  setIsEditing: PropTypes.func.isRequired,
  productSubscriptions: PropTypes.arrayOf(productSubscriptionShape),
  setProductSubscriptions: PropTypes.func
};

export default ProductSubscription;
