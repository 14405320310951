import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import useOnClickOutside from 'components/shared/hooks/useOnClickOutside';

const SmsRequiredFieldsModal = props => {
  const { showModal, handleCloseClicked, mmsAvailable } = props;
  const [show, setShow] = useState(false);
  const modalRef = useRef();

  useEffect(() => {
    setShow(showModal);
  }, [showModal]);

  useEffect(() => {
    const bodyElement = document.body;
    const modalBackdrop = document.createElement('div');
    modalBackdrop.classList.add('modal-backdrop', 'fade', 'show');

    if (show) {
      bodyElement.classList.add('modal-open');
      if (document.getElementsByClassName('modal-backdrop').length === 0)
        bodyElement.appendChild(modalBackdrop);
    } else {
      bodyElement.classList.remove('modal-open');
      if (document.getElementsByClassName('modal-backdrop').length > 0) {
        const backdrop = document.getElementsByClassName('modal-backdrop')[0];
        bodyElement.removeChild(backdrop);
      }
    }
  }, [show]);

  function handleClose() {
    setShow(false);
    handleCloseClicked();
  }

  useOnClickOutside(modalRef, () => handleClose());

  return (
    <div
      className="modal fade show"
      id="sms-required-fields-modal"
      tabIndex="-1"
      aria-modal="true"
      aria-hidden={!show}
      style={{ display: show ? 'block' : 'none' }}
    >
      <div className="modal-dialog modal-sm" ref={modalRef}>
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="myModalLabel">
              SMS Message{mmsAvailable ? ' or Media' : ''} Required
            </h4>
            <button type="button" className="close" onClick={handleClose}>
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <p>
              {mmsAvailable
                ? 'Either the SMS message or a media attachment is required to send a SMS Broadcast.'
                : 'A message is required to send a SMS Broadcast.'}
            </p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-info"
              onClick={handleClose}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

SmsRequiredFieldsModal.defaultProps = {
  handleCloseClicked: null,
  showModal: false
};

SmsRequiredFieldsModal.propTypes = {
  handleCloseClicked: PropTypes.func,
  showModal: PropTypes.bool,
  mmsAvailable: PropTypes.bool.isRequired
};

export default SmsRequiredFieldsModal;
