import React from 'react';
import { action } from '@storybook/addon-actions';
import { withA11y } from '@storybook/addon-a11y';
import { withKnobs, text, boolean } from '@storybook/addon-knobs';
import TextInput from './index';

export default {
  component: TextInput,
  title: 'Text Input',
  decorators: [withA11y, withKnobs]
};

export const withLabel = () => (
  <TextInput
    id="email"
    defaultValue=""
    placeholder={text('Placeholder', 'you@example.com')}
    labelText={text('Label Text', 'Email Address')}
    type={text('Type', 'text')}
    showError={boolean('Show Error', false)}
    error={text('Error Text', 'Password is required.')}
    helpText={text('Help Text', '')}
    hideLabel={boolean('Hide Label', false)}
    widthClass={text('Width Class', 'tw-max-w-xs')}
    leadingAddonText={text('Leading Addon Text', '')}
    trailingAddonText={text('Trailing Addon Text', '')}
    onChange={action('text-input-change')}
    onBlur={action('text-input-blur')}
  />
);

export const withLabelAndHelpText = () => (
  <TextInput
    id="password"
    defaultValue=""
    placeholder={text('Placeholder', '')}
    labelText={text('Label Text', 'Password')}
    type={text('Type', 'password')}
    showError={boolean('Show Error', false)}
    error={text('Error Text', 'Password is required.')}
    helpText={text('Help Text', 'Make your password short and easy to guess.')}
    hideLabel={boolean('Hide Label', false)}
    widthClass={text('Width Class', 'tw-max-w-xs')}
    leadingAddonText={text('Leading Addon Text', '')}
    trailingAddonText={text('Trailing Addon Text', '')}
    onChange={action('text-input-change')}
    onBlur={action('text-input-blur')}
  />
);

export const withError = () => (
  <TextInput
    id="password"
    defaultValue=""
    placeholder={text('Placeholder', '')}
    labelText={text('Label Text', 'Password')}
    type={text('Type', 'password')}
    showError={boolean('Show Error', true)}
    error={text('Error Text', 'Password is required.')}
    helpText={text('Help Text', '')}
    hideLabel={boolean('Hide Label', false)}
    widthClass={text('Width Class', 'tw-max-w-xs')}
    leadingAddonText={text('Leading Addon Text', '')}
    trailingAddonText={text('Trailing Addon Text', '')}
    onChange={action('text-input-change')}
    onBlur={action('text-input-blur')}
  />
);

export const withHiddenLabel = () => (
  <TextInput
    id="email"
    defaultValue=""
    placeholder={text('Placeholder', 'you@example.com')}
    labelText={text('Label Text', 'Email Address')}
    type={text('Type', 'text')}
    showError={boolean('Show Error', false)}
    error={text('Error Text', 'Password is required.')}
    helpText={text('Help Text', '')}
    hideLabel={boolean('Hide Label', true)}
    widthClass={text('Width Class', 'tw-max-w-xs')}
    leadingAddonText={text('Leading Addon Text', '')}
    trailingAddonText={text('Trailing Addon Text', '')}
    onChange={action('text-input-change')}
    onBlur={action('text-input-blur')}
  />
);

export const withLeadingIcon = () => (
  <TextInput
    id="email"
    defaultValue=""
    placeholder={text('Placeholder', 'you@example.com')}
    labelText={text('Label Text', 'Email Address')}
    type={text('Type', 'text')}
    showError={boolean('Show Error', false)}
    error={text('Error Text', 'Password is required.')}
    helpText={text('Help Text', '')}
    hideLabel={boolean('Hide Label', false)}
    widthClass={text('Width Class', 'tw-max-w-xs')}
    leadingAddonText={text('Leading Addon Text', '')}
    trailingAddonText={text('Trailing Addon Text', '')}
    onChange={action('text-input-change')}
    onBlur={action('text-input-blur')}
    LeadingIcon={() => (
      <svg
        className="tw-h-5 tw-w-5 tw-text-gray-400"
        fill="currentColor"
        viewBox="0 0 20 20"
      >
        <path
          fillRule="evenodd"
          d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884zM18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"
          clipRule="evenodd"
        />
      </svg>
    )}
  />
);

export const withTrailingIcon = () => (
  <TextInput
    id="email"
    defaultValue=""
    placeholder={text('Placeholder', 'you@example.com')}
    labelText={text('Label Text', 'Email Address')}
    type={text('Type', 'text')}
    showError={boolean('Show Error', false)}
    error={text('Error Text', 'Password is required.')}
    helpText={text('Help Text', '')}
    hideLabel={boolean('Hide Label', false)}
    widthClass={text('Width Class', 'tw-max-w-xs')}
    leadingAddonText={text('Leading Addon Text', '')}
    trailingAddonText={text('Trailing Addon Text', '')}
    onChange={action('text-input-change')}
    onBlur={action('text-input-blur')}
    TrailingIcon={() => (
      <svg
        className="tw-h-5 tw-w-5 tw-text-gray-400"
        fill="currentColor"
        viewBox="0 0 20 20"
      >
        <path
          fillRule="evenodd"
          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
          clipRule="evenodd"
        />
      </svg>
    )}
  />
);

export const withLeadingAddon = () => (
  <TextInput
    id="subsomain"
    defaultValue=""
    placeholder={text('Placeholder', 'example.com')}
    labelText={text('Label Text', 'Subdomain')}
    type={text('Type', 'text')}
    showError={boolean('Show Error', false)}
    error={text('Error Text', 'Password is required.')}
    helpText={text('Help Text', '')}
    hideLabel={boolean('Hide Label', false)}
    widthClass={text('Width Class', 'tw-max-w-xs')}
    leadingAddonText={text('Leading Addon Text', 'https://')}
    trailingAddonText={text('Trailing Addon Text', '')}
    onChange={action('text-input-change')}
    onBlur={action('text-input-blur')}
  />
);

export const withTrailingAddon = () => (
  <TextInput
    id="subsomain"
    defaultValue=""
    placeholder={text('Placeholder', 'https://example')}
    labelText={text('Label Text', 'Subdomain')}
    type={text('Type', 'text')}
    showError={boolean('Show Error', false)}
    error={text('Error Text', 'Password is required.')}
    helpText={text('Help Text', '')}
    hideLabel={boolean('Hide Label', false)}
    widthClass={text('Width Class', 'tw-max-w-xs')}
    leadingAddonText={text('Leading Addon Text', '')}
    trailingAddonText={text('Trailing Addon Text', '.com')}
    onChange={action('text-input-change')}
    onBlur={action('text-input-blur')}
  />
);
