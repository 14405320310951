import { call, put, takeLatest } from 'redux-saga/effects';
import { axiosDefault } from 'lib/utils/axios-setup';
import {
  CREATE_CONTACT_FORM_REQUEST,
  CREATE_CONTACT_FORM_SUCCESS,
  CREATE_CONTACT_FORM_FAILURE,
  UPDATE_CONTACT_FORM_REQUEST,
  UPDATE_CONTACT_FORM_SUCCESS,
  UPDATE_CONTACT_FORM_FAILURE,
  DELETE_CONTACT_FORM_REQUEST,
  DELETE_CONTACT_FORM_SUCCESS,
  DELETE_CONTACT_FORM_FAILURE
} from 'appState/actions/constants/contactForm.actions';
import {
  isDummyContact,
  createDummyContactForm
} from 'lib/services/dummyContactResponses';

function* createContactForm({ payload: { contactId, formId, contactForm } }) {
  if (yield call(isDummyContact))
    return yield call(createDummyContactForm, {
      contactId,
      formId,
      contactForm
    });

  try {
    const response = yield call(
      axiosDefault.post,
      `/contact_forms?contact_id=${contactId}`,
      { contact_form: contactForm, form_id: formId }
    );

    yield put({
      type: CREATE_CONTACT_FORM_SUCCESS,
      payload: {
        contactFormTypes: response.data
      }
    });
  } catch (e) {
    yield put({
      type: CREATE_CONTACT_FORM_FAILURE,
      payload: { error: e, fallbackError: 'Error creating the contact form.' },
      error: true
    });
  }
}

function* updateContactForm({ payload: { formId } }) {
  try {
    const response = yield call(axiosDefault.put, `/contact_forms/${formId}`, {
      contact_form: {
        read_at: new Date()
      }
    });

    yield put({
      type: UPDATE_CONTACT_FORM_SUCCESS,
      payload: {
        contactForm: response.data
      }
    });
  } catch (e) {
    yield put({
      type: UPDATE_CONTACT_FORM_FAILURE,
      payload: { error: e, fallbackError: 'Contact Form not updated.' },
      error: true
    });
  }
}

function* deleteContactForm({ payload: { formId } }) {
  try {
    yield call(axiosDefault.delete, `/contact_forms/${formId}`);

    yield put({
      type: DELETE_CONTACT_FORM_SUCCESS,
      payload: { data: true }
    });
  } catch (e) {
    yield put({
      type: DELETE_CONTACT_FORM_FAILURE,
      payload: {
        error: e,
        fallbackError: 'Contact Form cannot be deleted.'
      },
      error: true
    });
  }
}

export function* contactFormTypesFetch() {
  yield takeLatest(CREATE_CONTACT_FORM_REQUEST, createContactForm);
}

export function* contactFormDelete() {
  yield takeLatest(DELETE_CONTACT_FORM_REQUEST, deleteContactForm);
}

export function* currentContactFormUpdate() {
  yield takeLatest(UPDATE_CONTACT_FORM_REQUEST, updateContactForm);
}
