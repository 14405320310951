import React from 'react';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Styled from './Header.styles';

const Header = () => {
  const structuredSelector = createStructuredSelector({
    step: state => state.broadcastCenter.step,
    currentBroadcast: state => state.broadcastCenter.currentBroadcast,
    isTemplate: state => state.broadcastCenter.isTemplate
  });

  const { step, currentBroadcast, isTemplate } = useSelector(
    structuredSelector
  );

  let wizardNavProcessClasses = {};

  function setWizardNavProcessClasses() {
    if (step === 'select-template') {
      wizardNavProcessClasses = {
        selectTemplate: 'processing',
        selectAudience: '',
        editContent: '',
        schedule: ''
      };
    } else if (step === 'select-audience') {
      wizardNavProcessClasses = {
        selectTemplate: 'complete',
        selectAudience: 'processing',
        editContent: '',
        schedule: ''
      };
    } else if (step === 'email' || step === 'sms') {
      wizardNavProcessClasses = {
        selectTemplate: 'complete',
        selectAudience: 'complete',
        editContent: 'processing',
        schedule: ''
      };
    } else if (step === 'schedule' || step === 'save-details') {
      wizardNavProcessClasses = {
        selectTemplate: 'complete',
        selectAudience: 'complete',
        editContent: 'complete',
        schedule: 'processing'
      };
    }
  }

  setWizardNavProcessClasses();

  return (
    <Styled.Header className="header d-flex" isTemplate={isTemplate}>
      <div className="header-info mt-10 mb-10 d-flex">
        <div className="left mt-15 col-sm-12 col-md-5 col-xl-7 col-xxl-8 mx-0 p-0">
          <Styled.TruncatedTitle className="header-title d-inline-block">
            <strong className="fw-800 mr-10">
              {/* eslint-disable-next-line no-nested-ternary */}
              {step === 'select-template'
                ? 'Broadcast Center'
                : isTemplate
                ? 'Template:'
                : 'Broadcast:'}
            </strong>
            {step !== 'select-template' && isTemplate && currentBroadcast.title}
            {step !== 'select-template' &&
              isTemplate &&
              !currentBroadcast.title &&
              'No Title'}
            {step !== 'select-template' && !isTemplate && currentBroadcast.name}
          </Styled.TruncatedTitle>
        </div>
        <ul
          className={`w-400px mb-0 nav nav-process nav-process-circle nav-process-${
            isTemplate ? 'purple' : 'info'
          } d-none d-md-flex`}
        >
          {!isTemplate && (
            <li
              id="select_template"
              className={`nav-item ${wizardNavProcessClasses.selectTemplate}`}
            >
              <span className="nav-title">Template</span>
              <a className="nav-link" href="#" />
            </li>
          )}
          <li
            id="select_audience"
            className={`nav-item ${wizardNavProcessClasses.selectAudience}`}
          >
            <span className="nav-title">Audience</span>
            <a className="nav-link" href="#" />
          </li>
          <li
            id="edit_content"
            className={`nav-item ${wizardNavProcessClasses.editContent}`}
          >
            <span className="nav-title">Message</span>
            <a className="nav-link" href="#" />
          </li>
          <li
            id="send_schedule"
            className={`nav-item ${wizardNavProcessClasses.schedule}`}
          >
            <span className="nav-title">{isTemplate ? 'Save' : 'Send'}</span>
            <a className="nav-link" href="#" />
          </li>
        </ul>
      </div>
    </Styled.Header>
  );
};

export default Header;
