import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { CurrentUserContext } from 'components/shared/context/CurrentUserContext';
import { formatTimestamp } from 'lib/utils/dateTime';
import { capitalize } from 'lib/utils/string';
import broadcastEmailImg from 'images/broadcast/broadcast-email.svg';
import broadcastSmsImg from 'images/broadcast/broadcast-sms.svg';
import ReactTooltip from 'react-tooltip';
import DeleteModal from '../DeleteModal';
import BroadcastListItemActions from '../BroadcastListItemActions';
import Styled from './BroadcastListItem.styles';

const BroadcastListItem = props => {
  const {
    broadcast,
    badgeType,
    timestampAttr,
    timestampPretext,
    previewAction,
    children
  } = props;
  const currentUser = useContext(CurrentUserContext);
  const userTimeZone = currentUser.account_time_zone;
  const isScheduledBroadcast = broadcast.status === 'scheduled';

  return (
    <div>
      <div className="row">
        <div className="col-12">
          <Styled.Card className="card mb-15">
            <div className="card-body">
              <div className="row">
                <div className="col-12 col-md-6 col-lg-6">
                  <div className="media p-0">
                    {broadcast.type === 'EmailBroadcast' ? (
                      <img
                        src={broadcastEmailImg}
                        alt="Broadcast Email"
                        width="50"
                        height="50"
                      />
                    ) : (
                      <img
                        src={broadcastSmsImg}
                        alt="Broadcast SMS"
                        width="50"
                        height="50"
                      />
                    )}
                    <div className="media-body text-truncate">
                      {broadcast.status === 'sent' ? (
                        <a
                          onClick={() => previewAction()}
                          href="#"
                          data-toggle="modal"
                          data-target="#show-preview-modal"
                          className="sent-item-name"
                          data-broadcast={JSON.stringify(broadcast)}
                          style={{ borderBottom: '1px dotted' }}
                        >
                          <span className="fw-300 fs-18">{broadcast.name}</span>
                        </a>
                      ) : (
                        <span className="fw-300 fs-18">{broadcast.name}</span>
                      )}

                      <div className="d-flex align-items-center lh-25">
                        <div
                          className={`w-60px badge badge-${badgeType} mr-2 lh-15`}
                        >
                          {capitalize(broadcast.status)}
                        </div>
                        <span
                          data-tip={formatTimestamp(
                            timestampAttr,
                            userTimeZone,
                            false,
                            'MMMM Do [at] h:mm A',
                            true
                          )}
                          data-class="broadcast-list-item-tooltip"
                          data-place="bottom"
                          data-background-color="#e4e7ea"
                          data-text-color="black"
                          data-effect="solid"
                        >
                          {timestampPretext ? `${timestampPretext} ` : ''}
                          {formatTimestamp(
                            timestampAttr,
                            userTimeZone,
                            !isScheduledBroadcast
                          )}
                        </span>
                        <span className="divider-dash">
                          by {broadcast.last_updated_by_full_name}
                        </span>
                      </div>
                      {broadcast.error_message_message && (
                        <div>
                          <br />
                          <p>
                            <strong>Error Message:</strong>{' '}
                            {broadcast.error_message_message}
                          </p>
                          <p>
                            <strong>Error Description:</strong>{' '}
                            {broadcast.error_message_description}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {children}
                {!['sent', 'sending', 'scheduled'].includes(
                  broadcast.status
                ) ? (
                  <div className="col-sm-6 align-self-center p-0">
                    <BroadcastListItemActions broadcast={broadcast} />
                  </div>
                ) : null}
              </div>
            </div>
          </Styled.Card>
        </div>
      </div>
      {broadcast.status !== 'sent' ? <DeleteModal /> : null}
      <ReactTooltip />
    </div>
  );
};

BroadcastListItem.defaultProps = {
  children: null,
  timestampPretext: '',
  previewAction: null
};

const broadcastShape = {
  error_message_message: PropTypes.string,
  error_message_description: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  status: PropTypes.string,
  last_updated_by_full_name: PropTypes.string
};

BroadcastListItem.propTypes = {
  broadcast: PropTypes.shape(broadcastShape).isRequired,
  badgeType: PropTypes.string.isRequired,
  timestampAttr: PropTypes.string.isRequired,
  timestampPretext: PropTypes.string,
  previewAction: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func])
};

export default BroadcastListItem;
