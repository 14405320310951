import React from 'react';
import PropTypes from 'prop-types';
import { toCamel } from 'lib/utils/string';

const RadioButtons = ({
  id,
  type,
  color,
  options,
  onChange,
  onBlur,
  selected,
  label,
  containerClasses
}) => {
  function getName() {
    return toCamel(id);
  }

  return (
    <fieldset className={containerClasses}>
      <legend className="tw-font-body tw-text-sm tw-font-medium tw-text-gray-700">
        {label}
      </legend>
      <div className={`tw-flex tw-flex-${type === 'vertical' ? 'col' : 'row'}`}>
        {options.map(option => (
          <div key={option.value} className="tw-flex tw-items-center">
            <input
              id={id}
              name={getName(id)}
              type="radio"
              onChange={e => onChange(e.target.value)}
              onBlur={onBlur}
              className={`tw-form-radio tw-border tw-border-solid tw-h-4 tw-w-4 tw-text-${color}-600 tw-transition tw-duration-150 tw-ease-in-out`}
              checked={selected === option.value}
              value={option.value}
            />
            <label
              htmlFor={getName(id)}
              className="tw-ml-3 tw-mt-2 tw-mr-5"
              onClick={() => onChange(option.value)}
            >
              <span className="tw-block tw-text-sm tw-leading-5 tw-text-gray-700">
                {option.displayName}
              </span>
            </label>
          </div>
        ))}
      </div>
    </fieldset>
  );
};

RadioButtons.defaultProps = {
  color: 'alpha',
  label: '',
  onChange: null,
  onBlur: null,
  containerClasses: 'tw-mt-6',
  type: 'vertical'
};

const optionShape = PropTypes.shape({
  displayName: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ])
});

RadioButtons.propTypes = {
  label: PropTypes.string,
  type: PropTypes.oneOf(['vertical', 'horizontal']),
  id: PropTypes.string.isRequired,
  color: PropTypes.oneOf([
    'alpha',
    'bravo',
    'charlie',
    'success',
    'warning',
    'error',
    'gray'
  ]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  options: PropTypes.arrayOf(optionShape).isRequired,
  containerClasses: PropTypes.string,
  selected: PropTypes.string.isRequired
};

export default RadioButtons;
