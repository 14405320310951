import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import useToggleHeaderFooter from 'components/shared/hooks/useToggleHeaderFooter';
import BeePage from 'components/BeePage';
import { useFormik } from 'formik';
import isEmpty from 'lodash.isempty';
import {
  broadcastFetchPreview,
  broadcastSendTestEmail,
  broadcastSetPreviewEmailFired,
  broadcastSaveEmail,
  broadcastSetEmailSubject,
  broadcastUpdate,
  broadcastSetHasContentChanged
} from 'appState/actions/ActionCreators';
import { broadcastEmailSchema } from 'lib/validation/schema';
import { CurrentUserContext } from 'components/shared/context/CurrentUserContext';
import BroadcastPreviewModal from 'components/BroadcastCenter/Broadcast/BroadcastPreviewModal';
import BroadcastSendTestEmailModal from 'components/BroadcastCenter/Broadcast/BroadcastSendTestEmailModal';
import EmailSubjectConfirmationModal from 'components/BroadcastCenter/Broadcast/Email/EmailSubjectConfirmationModal';
import Styled from './Email.styles';
import BroadcastStep from '../BroadcastStep';
import Card from '../Card';

const Email = () => {
  const [emailSubject, setEmailSubject] = useState('');
  const [fullScreenClass, setFullScreenClass] = useState('');
  const history = useHistory();
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const currentUser = useContext(CurrentUserContext);

  useToggleHeaderFooter(path);

  const structuredSelector = createStructuredSelector({
    currentBroadcast: state => state.broadcastCenter.currentBroadcast,
    isTemplate: state => state.broadcastCenter.isTemplate,
    hasContentChanged: state => state.broadcastCenter.hasContentChanged
  });

  const { currentBroadcast, isTemplate, hasContentChanged } = useSelector(
    structuredSelector
  );

  function handlePreviewClick() {
    dispatch(broadcastSetPreviewEmailFired(true));
    const beeContainerElement = document.getElementById('email-bee-container');
    beeContainerElement.dataset.buttonClicked = 'preview';
  }

  function handleSendTestEmailClick() {
    const beeContainerElement = document.getElementById('email-bee-container');
    beeContainerElement.dataset.buttonClicked = 'send-test-email';
  }

  function getBroadcastPreview(emailBody) {
    dispatch(
      broadcastFetchPreview({
        broadcastId: currentBroadcast.id,
        userId: currentUser.id,
        emailBody
      })
    );
  }

  function sendBroadcastTestEmail(emailBody) {
    dispatch(
      broadcastSendTestEmail({
        currentUserId: currentUser.id,
        emailBody
      })
    );
  }

  useEffect(() => {
    if (currentBroadcast.email_subject) {
      setEmailSubject(currentBroadcast.email_subject);
      dispatch(broadcastSetEmailSubject(currentBroadcast.email_subject));
    }
  }, [currentBroadcast.email_subject]);

  useEffect(() => {
    if (
      !isTemplate &&
      !isEmpty(currentBroadcast) &&
      !currentBroadcast.has_been_edited &&
      hasContentChanged
    ) {
      dispatch(
        broadcastUpdate({
          broadcastId: currentBroadcast.id,
          hasBeenEdited: true
        })
      );
    }
  }, [
    hasContentChanged,
    isTemplate,
    currentBroadcast.has_been_edited,
    dispatch
  ]);

  const formik = useFormik({
    initialValues: {
      emailSubject
    },
    enableReinitialize: true,
    validationSchema: broadcastEmailSchema
  });

  const saveEmailBroadcast = () => args => {
    if (!args.isAutoSaving) formik.handleSubmit();

    dispatch(
      broadcastSaveEmail({
        broadcastId: currentBroadcast.id,
        history,
        ...args
      })
    );
  };

  const onEmailSubjectChange = e => {
    const subject = e.target.value;
    formik.handleChange(e);
    setEmailSubject(subject);
    dispatch(broadcastSetEmailSubject(subject));
    if (!hasContentChanged) dispatch(broadcastSetHasContentChanged(true));
  };

  function getHeaderComponent() {
    return (
      <div className="d-flex full-width align-items-center pt-10 pb-10">
        <div className="flex-grow-1 ml-15">
          <Styled.SubjectInputForm
            id="email-subject-form"
            data-toggle="validator"
            data-focus="false"
            className="broadcast-center-form"
            style={{ width: '99%' }}
          >
            <Styled.SubjectInputWrapper
              id="subject-field-wrapper"
              className="form-group no-margin no-padding form-type-material"
            >
              <input
                onKeyDown={e =>
                  e.code === 'Enter' ? e.preventDefault() : null
                }
                type="text"
                id="emailSubject"
                name="emailSubject"
                className={`form-control required ${
                  formik.touched.emailSubject && formik.errors.emailSubject
                    ? 'is-invalid'
                    : ''
                }`}
                data-error="Please add a subject line to this email."
                required
                placeholder="Enter Your Subject Here"
                onChange={e => onEmailSubjectChange(e)}
                onBlur={formik.handleBlur}
                value={formik.values.emailSubject}
                autoComplete="on"
              />
              {formik.touched.emailSubject && formik.errors.emailSubject ? (
                <span className="invalid-feedback">
                  {formik.errors.emailSubject}
                </span>
              ) : null}
            </Styled.SubjectInputWrapper>
          </Styled.SubjectInputForm>
        </div>
        <div id="email-actions-wrapper">
          <div className="card-header-actions d-flex justify-content-end">
            <button
              className="btn btn-sm btn-secondary"
              id="show-preview-email-action"
              type="button"
              data-toggle="modal"
              data-target="#show-preview-modal"
              onClick={handlePreviewClick}
            >
              <i className="fa fa-eye" /> Preview
            </button>
            <button
              className="btn btn-sm btn-secondary"
              type="button"
              data-toggle="modal"
              data-target="#send-test-modal"
              onClick={handleSendTestEmailClick}
            >
              <i className="fa fa-send" /> Send Test{' '}
            </button>
            <ul className="card-controls mr-20 ml-15">
              <li>
                <a
                  className="card-btn-fullscreen"
                  href="#"
                  onClick={() =>
                    setFullScreenClass(
                      fullScreenClass === 'full-screen' ? '' : 'full-screen'
                    )
                  }
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <BroadcastStep>
        <Card
          title="Email"
          bodyStyle={{ padding: 0 }}
          headerStyle={{ padding: 0 }}
          HeaderComponent={getHeaderComponent}
        >
          <Styled.BeeWrapper
            className={`d-none d-md-block ${fullScreenClass}`}
            hasRoundedCorners
          >
            <BeePage
              saveAction={() => saveEmailBroadcast()}
              previewAction={getBroadcastPreview}
              sendTestAction={sendBroadcastTestEmail}
            />
          </Styled.BeeWrapper>
        </Card>

        <BroadcastPreviewModal />
        <BroadcastSendTestEmailModal />
        <EmailSubjectConfirmationModal />
      </BroadcastStep>
    </div>
  );
};

export default Email;
