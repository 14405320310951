import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { scrollToTop } from 'lib/utils/scroll';

const Tabs = ({
  tabs,
  color,
  type,
  onTabChange,
  tabSelected,
  appendTabHash
}) => {
  const [selectedTab, setSelectedTab] = useState(tabs && tabs[0]);
  const [showTabShadow, setShowTabShadow] = useState(true);

  function handleTabSelect(tab) {
    setSelectedTab(tab);
    onTabChange(tab);
  }

  useEffect(() => {
    if (tabSelected) {
      let tab;
      if (typeof tabSelected === 'number') tab = tabs[tabSelected];
      if (typeof tabSelected === 'string')
        tab = tabs.find(t => t.value === tabSelected);
      setSelectedTab(tab);
      scrollToTop();
    }
  }, [tabSelected, tabs]);

  function scrollEventHandler() {
    const tabNavWrapper = document.getElementById('tab-nav-inner-wrapper');
    if (
      tabNavWrapper.scrollWidth ===
      tabNavWrapper.scrollLeft + tabNavWrapper.clientWidth
    ) {
      setShowTabShadow(false);
    } else {
      setShowTabShadow(true);
    }
  }

  function detectEndOfScroll() {
    document
      .getElementById('tab-nav-inner-wrapper')
      .addEventListener('scroll', scrollEventHandler);
  }

  function setTabHash() {
    return appendTabHash ? `#${selectedTab.value}` : null;
  }

  useEffect(() => {
    detectEndOfScroll();
    return () => {
      document.removeEventListener('scroll', scrollEventHandler);
    };
  }, []);

  function getTab(tab, index) {
    return (
      <a
        href={setTabHash()}
        key={tab.value}
        data-testid={tab.value}
        role="button"
        tabIndex={-1}
        onClick={() => {
          handleTabSelect(tab);
        }}
        onKeyDown={e => {
          if (e.code === 'Enter') handleTabSelect(tab);
        }}
        className={`tw-cursor-pointer tw-whitespace-no-wrap tw-py-4 tw-px-1 tw-border-b-2 tw-border-t-0 tw-border-l-0 tw-border-r-0 tw-border-solid tw-font-medium tw-text-sm tw-leading-5 focus:tw-outline-none ${
          index === 0 ? '' : 'tw-ml-0'
        } ${
          tab.value === selectedTab.value
            ? `tw-border-${color}-500 tw-text-${color}-600 focus:tw-text-${color}-800 focus:tw-border-${color}-700`
            : 'tw-border-transparent tw-text-gray-500 hover:tw-text-gray-700 hover:tw-border-gray-300 focus:tw-text-gray-700 focus:tw-border-gray-300'
        }`}
      >
        {tab.text}
      </a>
    );
  }

  function getTabSecondary(tab, index) {
    return (
      <a
        href={setTabHash()}
        key={tab.value}
        data-testid={tab.value}
        role="button"
        tabIndex={-1}
        onClick={() => {
          handleTabSelect(tab);
        }}
        onKeyDown={e => {
          if (e.code === 'Enter') handleTabSelect(tab);
        }}
        className={`tw-cursor-pointer tw-px-3 tw-py-2 tw-font-medium tw-text-sm tw-leading-5 tw-rounded-md focus:tw-outline-none ${
          index === 0 ? '' : 'tw-ml-4'
        } ${
          tab.value === selectedTab.value
            ? `tw-text-${color}-700 tw-bg-${color}-100 focus:tw-text-${color}-800 focus:tw-bg-${color}-200`
            : 'tw-text-gray-500 hover:tw-text-gray-700 focus:tw-text-indigo-600 focus:tw-bg-indigo-50'
        }`}
      >
        {tab.text}
      </a>
    );
  }

  function getTabGray(tab, index) {
    return (
      <a
        href={setTabHash()}
        key={tab.value}
        data-testid={tab.value}
        role="button"
        tabIndex={-1}
        onClick={() => {
          handleTabSelect(tab);
        }}
        onKeyDown={e => {
          if (e.code === 'Enter') handleTabSelect(tab);
        }}
        className={`tw-cursor-pointer tw-px-3 tw-py-2 tw-font-medium tw-text-sm tw-leading-5 tw-rounded-md focus:tw-outline-none ${
          index === 0 ? '' : 'tw-ml-4'
        } ${
          tab.value === selectedTab.value
            ? 'tw-text-gray-700 tw-bg-gray-100 focus:tw-bg-gray-200'
            : 'tw-text-gray-500 hover:tw-text-gray-700 focus:tw-text-gray-700 focus:tw-bg-gray-100'
        }`}
      >
        {tab.text}
      </a>
    );
  }

  function getTabWithIcons(tab, index) {
    const isSelected = tab.value === selectedTab.value;
    return (
      <a
        href={setTabHash()}
        key={tab.value}
        data-testid={tab.value}
        role="button"
        tabIndex={-1}
        onClick={() => {
          handleTabSelect(tab);
        }}
        onKeyDown={e => {
          if (e.code === 'Enter') handleTabSelect(tab);
        }}
        className={`tw-cursor-pointer tw-group tw-inline-flex tw-items-center tw-py-4 tw-px-1 tw-border-b-2 tw-border-t-0 tw-border-l-0 tw-border-r-0 tw-border-solid tw-font-medium tw-text-sm tw-leading-5 focus:tw-outline-none ${
          index === 0 ? '' : 'tw-ml-8'
        } ${
          isSelected
            ? `tw-border-${color}-500 tw-text-${color}-600 focus:tw-text-${color}-800 focus:tw-border-${color}-700`
            : 'tw-border-transparent tw-text-gray-500 hover:tw-text-gray-700 hover:tw-border-gray-300 focus:tw-text-gray-700 focus:tw-border-gray-300'
        }`}
      >
        {tab.icon && typeof tab.icon === 'function' ? (
          tab.icon(isSelected, color)
        ) : (
          <tab.icon isSelected={isSelected} color={color} />
        )}
        <span>{tab.text}</span>
      </a>
    );
  }

  return (
    <div>
      <div>
        <div
          className={`${
            ['primary', 'withIcons'].includes(type)
              ? 'tw-border-solid tw-border-b tw-border-t-0 tw-border-l-0 tw-border-r-0 tw-border-gray-200'
              : ''
          }`}
        >
          <nav
            className={`tw-flex ${
              showTabShadow ? 'tw-shadow-inner-right' : ''
            } ${['primary', 'withIcons'].includes(type) ? 'tw--mb-px' : ''}`}
            id="tab-nav-wrapper"
          >
            <div
              className="tw-overflow-x-auto tw-flex"
              id="tab-nav-inner-wrapper"
            >
              {tabs.map((tab, index) => {
                switch (type) {
                  case 'primary': {
                    return getTab(tab, index);
                  }
                  case 'secondary': {
                    return getTabSecondary(tab, index);
                  }
                  case 'gray': {
                    return getTabGray(tab, index);
                  }
                  case 'withIcons': {
                    return getTabWithIcons(tab, index);
                  }
                  default: {
                    return getTab(tab, index);
                  }
                }
              })}
            </div>
          </nav>
        </div>
      </div>
      {selectedTab.content ? (
        <div className="tw-h-full tw-w-full">
          {typeof selectedTab.content === 'function' ? (
            selectedTab.content()
          ) : (
            <selectedTab.content />
          )}
        </div>
      ) : null}
    </div>
  );
};

Tabs.defaultProps = {
  color: 'alpha',
  type: 'primary',
  onTabChange: () => {},
  tabSelected: 0,
  appendTabHash: false
};

const tabShape = {
  text: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  content: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.node])
};

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape(tabShape)).isRequired,
  color: PropTypes.oneOf([
    'alpha',
    'bravo',
    'charlie',
    'success',
    'warning',
    'error',
    'gray'
  ]),
  type: PropTypes.oneOf(['primary', 'secondary', 'gray', 'withIcons']),
  onTabChange: PropTypes.func,
  tabSelected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  appendTabHash: PropTypes.bool
};

export default Tabs;
