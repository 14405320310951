import React from 'react';
import Skeleton from 'react-loading-skeleton';

const SkeletonMessageListItem = () => {
  return (
    <div className="media align-items-center contact-list-full-chat">
      <Skeleton circle width={36} height={36} />
      <div className="media-body">
        <div className="flexbox align-items-center">
          <strong className="title">
            <Skeleton height={14} width={96} />
          </strong>
          <span>
            <time
              dateTime="2017-07-14 20:00"
              className="last-message-timestamp"
              style={{ marginRight: '5px' }}
            >
              <Skeleton height={10} width={26} />
            </time>
          </span>
        </div>
        <p className="text-truncate last-message-text">
          <Skeleton height={12} width={172} />
        </p>
      </div>
    </div>
  );
};

export default SkeletonMessageListItem;
