import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import Button from 'components/Theme/Button';
import Badge from 'components/Theme/Badge';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { titleize, textTruncate } from 'lib/utils/string';
import { smsInboxMarkAsRead } from 'appState/actions/ActionCreators';

const Header = props => {
  const { contact, showMessageList } = props;
  const [showMarkAsRead, setShowMarkAsRead] = useState(false);
  const dispatch = useDispatch();

  const structuredSelector = createStructuredSelector({
    conversationTotal: state => state.smsInbox.conversationTotal
  });

  const { conversationTotal } = useSelector(structuredSelector);

  useEffect(() => {
    setShowMarkAsRead(contact.unread_count > 0);
  }, [contact]);

  function handleMarkAsReadButton() {
    setShowMarkAsRead(false);
    dispatch(smsInboxMarkAsRead({ contactId: contact.id }));
  }

  return (
    <div className="tw-bg-white tw-rounded-t-md tw-px-4 tw-py-3 tw-border-b tw-border-t-0 tw-border-l-0 tw-border-r-0 tw-border-solid tw-border-gray-200 sm:tw-px-6">
      <div className="tw-flex tw-items-center tw-justify-between tw-flex-wrap sm:tw-flex-no-wrap">
        <div className="tw-flex tw-items-center">
          <svg
            onClick={() => showMessageList(true)}
            className="tw-w-6 tw-h-6 tw-mr-7 tw-cursor-pointer tw-block md:tw-hidden"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M15 19l-7-7 7-7"
            />
          </svg>
          <div>
            <h3 className="tw-font-body tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
              {contact && contact.name ? (
                <a href={`/contacts/${contact.id}`}>
                  {textTruncate(titleize(contact.name), 33)}
                </a>
              ) : (
                <Skeleton height={14} width={80} />
              )}
              {contact && contact.name && !contact.subscribed_to_sms ? (
                <Badge
                  badgeStyle={{ backgroundColor: '#F56565' }}
                  clickable={false}
                  color="error"
                  containerStyle={{ marginLeft: '.45rem' }}
                  deletable={false}
                  hasDot={false}
                  onClick={null}
                  onDelete={null}
                  shape="rectangle"
                  size="small"
                  textStyle={{ color: 'white' }}
                  value="Unsubscribed"
                />
              ) : null}
            </h3>
            <p className="tw-font-body tw-mt-1 tw-mb-0 tw-text-sm tw-leading-5 tw-text-gray-500">
              {conversationTotal ? (
                `${conversationTotal} messages in this thread.`
              ) : (
                <Skeleton height={10} width={150} />
              )}
            </p>
          </div>
        </div>
        <div className="tw-flex-shrink-0">
          <span className="tw-inline-flex">
            {showMarkAsRead ? (
              <Button
                buttonRef={null}
                buttonStyle={{}}
                color="alpha"
                disabled={false}
                disabledStyle={{}}
                disabledTitleStyle={{}}
                isLoaded
                onClick={handleMarkAsReadButton}
                size="xs"
                text="Mark As Read"
                titleStyle={{}}
                type="secondary"
              />
            ) : null}
          </span>
        </div>
      </div>
    </div>
  );
};

Header.defaultProps = {
  contact: {
    name: ''
  },
  showMessageList: () => null
};

const contactShape = {
  name: PropTypes.string
};

Header.propTypes = {
  contact: PropTypes.shape(contactShape),
  showMessageList: PropTypes.func
};

export default Header;
