export function reassignParamsFor(contact) {
  const newContact = {};
  Object.keys(contact).forEach(key => {
    const newKey = key
      .split('_')
      .map((item, index) => {
        if (index) return item[0].toUpperCase() + item.substring(1);
        return item;
      })
      .join('');
    newContact[newKey] = contact[key];
  });

  return newContact;
}
