import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';

const useProcessingActivity = utilityNames => {
  const structuredSelector = createStructuredSelector({
    processingActivities: state => state.overlordUtilities.processingActivities
  });

  const { processingActivities } = useSelector(structuredSelector);

  const getProcessingActivity = useCallback(() => {
    return (
      processingActivities.filter(activity =>
        utilityNames.includes(activity.utility)
      )[0] || {}
    );
  }, [processingActivities, utilityNames]);

  const [processingActivity, setProcessingActivity] = useState(
    getProcessingActivity()
  );
  useEffect(() => {
    setProcessingActivity(getProcessingActivity());
  }, [getProcessingActivity]);

  return processingActivity;
};

export default useProcessingActivity;
