import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  broadcastsFetchEmails,
  broadcastResetEmails,
  broadcastsFetchContacts,
  broadcastFetchPreview,
  broadcastSaveAsTemplate,
  broadcastSendAgain
} from 'appState/actions/ActionCreators';
import { CurrentUserContext } from 'components/shared/context/CurrentUserContext';
import BroadcastListItem from '../BroadcastListItem';

const SentListItem = props => {
  const { broadcast, setSelectedBroadcast } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const currentUser = useContext(CurrentUserContext);

  const getBroadcastEmails = metric => {
    if (metric === 'erred') {
      dispatch(broadcastResetEmails());
      dispatch(
        broadcastsFetchEmails({
          broadcastId: broadcast.id,
          broadcastMetric: metric,
          broadcastType: 'email'
        })
      );
    } else {
      dispatch(
        broadcastsFetchContacts({
          broadcastId: broadcast.id,
          broadcastMetric: metric,
          broadcastType: broadcast.type === 'EmailBroadcast' ? 'email' : 'sms'
        })
      );
    }
  };

  const getBroadcastPreview = () => {
    dispatch(
      broadcastFetchPreview({
        broadcastId: broadcast.id,
        userId: currentUser.id
      })
    );
  };

  function handleSaveAsTemplateButton() {
    dispatch(broadcastSaveAsTemplate({ broadcastId: broadcast.id, history }));
  }

  function handleSendAgainButton() {
    dispatch(broadcastSendAgain({ broadcastId: broadcast.id, history }));
  }

  function handleDropdownClick() {
    setSelectedBroadcast(broadcast);
  }

  return (
    <div>
      <BroadcastListItem
        broadcast={broadcast}
        badgeType="success"
        timestampAttr={broadcast.sent_at}
        previewAction={getBroadcastPreview}
      >
        <div className="col-md-6 col-sm-6">
          <hr className="hr-sm d-block d-sm-none" />
          <div className="media p-0 align-items-center">
            <div className="media-body mr-0">
              <div className="d-flex justify-content-end">
                <div className="w-80px d-flex flex-column align-items-center">
                  <a
                    onClick={() => getBroadcastEmails('sent')}
                    href="#"
                    className="metrics-modal-link text-center"
                    data-target="#broadcast-center-metrics-modal"
                    data-toggle="modal"
                    style={{ borderBottom: '1px dotted' }}
                  >
                    <small>Sent</small>
                    <h3 className="mb-0">{broadcast.sent_count}</h3>
                  </a>
                </div>
                {broadcast.type === 'EmailBroadcast' && (
                  <Fragment>
                    <div className="w-80px d-flex flex-column align-items-center">
                      <a
                        onClick={() => getBroadcastEmails('opened')}
                        href="#"
                        className="metrics-modal-link text-center"
                        data-target="#broadcast-center-metrics-modal"
                        data-toggle="modal"
                        data-id={broadcast.id}
                        style={{ borderBottom: '1px dotted' }}
                      >
                        <small>Opened</small>
                        <h3 className="mb-0">{broadcast.opened_percentage}%</h3>
                      </a>
                    </div>
                    <div className="w-80px d-flex flex-column align-items-center">
                      <a
                        onClick={() => getBroadcastEmails('clicked')}
                        href="#"
                        className="metrics-modal-link text-center"
                        data-target="#broadcast-center-metrics-modal"
                        data-toggle="modal"
                        style={{ borderBottom: '1px dotted' }}
                      >
                        <small>Clicked</small>
                        <h3 className="mb-0">
                          {broadcast.clicked_percentage}%
                        </h3>
                      </a>
                    </div>
                    <div className="w-80px d-flex flex-column align-items-center">
                      <a
                        onClick={() => getBroadcastEmails('unsubscribed')}
                        href="#"
                        className="metrics-modal-link text-center"
                        data-target="#broadcast-center-metrics-modal"
                        data-toggle="modal"
                        style={{ borderBottom: '1px dotted' }}
                      >
                        <small>Unsubscribed</small>
                        <h3 className="mb-0">
                          {broadcast.unsubscribed_percentage}%
                        </h3>
                      </a>
                    </div>
                  </Fragment>
                )}
                {broadcast.error_count > 0 && (
                  <div className="w-80px d-flex flex-column align-items-center">
                    <a
                      onClick={() => getBroadcastEmails('erred')}
                      href="#"
                      className="metrics-modal-link text-center"
                      data-target="#broadcast-center-metrics-modal"
                      data-toggle="modal"
                      style={{ borderBottom: '1px dotted' }}
                    >
                      <small>Errors</small>
                      <h3 className="mb-0">{broadcast.error_count}</h3>
                    </a>
                  </div>
                )}
                <div className="align-self-center dropdown ml-15">
                  <a
                    className="text-lighter"
                    href="#"
                    data-toggle="dropdown"
                    onClick={handleDropdownClick}
                  >
                    <i className="ti-more-alt rotate-90" />
                  </a>
                  <div className="dropdown-menu dropdown-menu-right">
                    <a
                      className="dropdown-item cursor-pointer"
                      href="#"
                      onClick={handleSendAgainButton}
                    >
                      <i className="fa fa-fw fa-paper-plane-o" />
                      Send It Again
                    </a>
                    <a
                      className="dropdown-item cursor-pointer"
                      href="#"
                      onClick={handleSaveAsTemplateButton}
                    >
                      <i className="fa fa-fw fa-save" />
                      Save As Template
                    </a>
                    <div className="dropdown-divider" />
                    <a
                      className="dropdown-item cursor-pointer preview-sent-item-modal"
                      data-toggle="modal"
                      data-target="#show-preview-modal"
                      id="preview-sent-item-modal"
                      data-broadcast={JSON.stringify(broadcast)}
                      href="#"
                      onClick={() => getBroadcastPreview()}
                    >
                      <i className="fa fa-fw fa-eye" />
                      Preview
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </BroadcastListItem>
    </div>
  );
};

const broadcastShape = {
  id: PropTypes.number,
  opened_percentage: PropTypes.number,
  clicked_percentage: PropTypes.number,
  unsubscribed_percentage: PropTypes.number,
  error_count: PropTypes.number,
  sent_at: PropTypes.string,
  sent_count: PropTypes.number,
  template_type: PropTypes.string
};

SentListItem.propTypes = {
  broadcast: PropTypes.shape(broadcastShape).isRequired,
  setSelectedBroadcast: PropTypes.func.isRequired
};

export default SentListItem;
