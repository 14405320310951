import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  createErrorMessageSelector,
  createLoadedSelector
} from 'appState/selectors';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';
import Modal from 'components/Theme/Modal';
import Select from 'components/Theme/Select';
import {
  reassignTask,
  fetchTask,
  fetchTasksActivities,
  resetIsLoaded
} from 'appState/actions/ActionCreators';
import { CurrentAccountContext } from 'components/shared/context/CurrentAccountContext';
import { UsersContext } from 'components/shared/context/UsersContext';
import { formatTimestamp } from 'lib/utils/dateTime';
import { REASSIGN_TASK } from 'app-state/actions/constants/activity.actions';
import getUserName from 'lib/utils/getUserName';

const TaskReassignModal = ({ task, show, setShow }) => {
  const [staffId, setStaffId] = useState();
  const dispatch = useDispatch();
  const currentAccount = useContext(CurrentAccountContext);
  const errorSelector = createErrorMessageSelector([REASSIGN_TASK]);
  const loadedSelector = createLoadedSelector([REASSIGN_TASK]);

  const structuredSelector = createStructuredSelector({
    currentTask: state => state.contact.currentTask,
    currentContact: state => state.contact.currentContact,
    error: state => errorSelector(state),
    isLoaded: state => loadedSelector(state)
  });

  const { currentTask, currentContact, error, isLoaded } = useSelector(
    structuredSelector
  );
  const { users } = useContext(UsersContext);

  useEffect(() => {
    if (isLoaded && isEmpty(error)) {
      dispatch(
        fetchTasksActivities({
          accountId: currentAccount.id,
          contactId: currentContact.id
        })
      );
    }
    return () => {
      dispatch(resetIsLoaded(REASSIGN_TASK));
    };
  }, [error, isLoaded, dispatch, currentAccount, currentContact]);

  function handleReassignTaskAction() {
    const params = { taskId, staffId };
    dispatch(reassignTask(params));
  }

  useEffect(() => {
    if (currentTask && currentTask.id !== task.sourceable_id)
      dispatch(fetchTask({ taskId: currentTask.id }));
  }, [dispatch, currentTask, task]);

  const userSelectOptions = users.map(user => {
    return { displayName: user.full_name, value: user.id };
  });

  const changeDropDownValue = e => {
    setStaffId(e.target.value);
  };

  return (
    <Modal
      headerText="Reassign this Task?"
      primaryAction={handleReassignTaskAction}
      primaryActionText="Yes"
      secondaryAction={() => {
        setShow(false);
      }}
      showPrimaryAction
      showSecondaryAction
      secondaryActionText="No"
      canHandleClickOutside
      show={show}
      setShow={setShow}
      color="alpha"
      HeaderIcon={color => {
        return (
          <svg
            className={`tw-h-6 tw-w-6 tw-text-${color}-600`}
            stroke="currentColor"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
            />
          </svg>
        );
      }}
    >
      <div>
        <p className="tw-mb-0">
          <span className="tw-font-medium">Title:</span> {task.task_title}
          <br />
          <span className="tw-font-medium">Description:</span>{' '}
          <span
            dangerouslySetInnerHTML={{ __html: task.parsed_task_description }}
          />
          <span className="tw-font-medium">Due Date:</span>{' '}
          {task.task_due_date &&
            formatTimestamp(task.task_due_date, currentAccount.timezone)}
          <br />
          <span className="tw-font-medium">Current Assigned Staff:</span>{' '}
          {getUserName(task)}
          <br />
        </p>
        <br />
        <div>
          <Select
            id="staff_id"
            value={staffId}
            options={userSelectOptions}
            labelText="Assign Task To"
            onChange={changeDropDownValue}
            widthClass="tw-col-span-12 sm:tw-col-span-6"
          />
        </div>
      </div>
    </Modal>
  );
};

const userObjectShape = {
  first_name: PropTypes.string,
  last_name: PropTypes.string
};

const taskObjectShape = {
  task_title: PropTypes.string,
  task_description: PropTypes.string,
  parsed_task_description: PropTypes.string,
  task_due_date: PropTypes.string,
  user: PropTypes.shape(userObjectShape)
};

TaskReassignModal.defaultProps = {
  task: {},
  show: false,
  setShow: () => null
};

TaskReassignModal.propTypes = {
  task: PropTypes.shape(taskObjectShape),
  show: PropTypes.bool,
  setShow: PropTypes.func
};

export default TaskReassignModal;
