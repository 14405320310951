const errorReducer = (state = {}, action) => {
  const { type, payload, fallbackError } = action;
  const matches = /(.*)_(REQUEST|FAILURE)/.exec(type);

  if (!matches) return state;

  const [, requestName, requestState] = matches;

  let newState;
  if (requestState === 'FAILURE') {
    newState = { [requestName]: payload };
  } else if (fallbackError) {
    newState = { [requestName]: fallbackError };
  } else {
    newState = { [requestName]: '' };
  }

  return {
    ...state,
    ...newState
  };
};

export default errorReducer;
