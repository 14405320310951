import get from 'lodash.get';

export const createErrorMessageSelector = actions => state => {
  const errors = actions.map(action => state.error[action]);
  if (errors && errors[0]) {
    // fallback to errors[0] if error.message does not exist (i.e. no
    // error message returned from the server)
    const errorMessage = get(errors[0], 'error.response.data.error.message');
    if (Array.isArray(errorMessage)) {
      return errorMessage[0];
    }

    return (
      get(errors[0], 'error.response.data.error.message') ||
      get(errors[0], 'error.response.data.error') ||
      get(errors[0], 'error.response.data.errors[0].message') ||
      get(errors[0], 'error.response.data.errors[0]') ||
      get(errors[0], 'fallbackError')
    );
  }
  return '';
};

export const createLoadedSelector = actions => state =>
  actions.some(action => state.loaded[action]);

export const createAllLoadedSelector = actions => state =>
  actions.every(action => state.loaded[action]);

export const createLoadingSelector = actions => state =>
  actions.some(action => state.loading[action]);
