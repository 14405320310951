import React, { useEffect } from 'react';
import isEmpty from 'lodash.isempty';
import { createStructuredSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchIncludedForm,
  setOverlordLayoutHeaderText,
  updateIncludedForm,
  flashErrorMessage,
  flashSuccessMessage,
  fetchStaffSmsMergeFields
} from 'appState/actions/ActionCreators';
import { UPDATE_INCLUDED_FORM } from 'app-state/actions/constants/product.actions';
import {
  createErrorMessageSelector,
  createLoadedSelector
} from 'appState/selectors';
import { useParams } from 'react-router-dom';
import RadioButtons from 'components/Theme/RadioButtons';
import TextArea from 'components/Theme/TextArea';
import { useFormik } from 'formik';
import { staffSmsNotificationSchema } from 'lib/validation/schema';
import Breadcrumbs from 'components/Theme/Breadcrumbs';
import Button from 'components/Theme/Button';
import Toggle from 'components/Theme/Toggle';
import Select from 'components/Theme/Select';
import { STAFF_NOTIFICATION_RECIPIENT_OPTIONS } from 'config/constants';

const StaffSmsNotification = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { id, partnerId, productId } = params;

  const updateErrorSelector = createErrorMessageSelector([
    UPDATE_INCLUDED_FORM
  ]);

  const loadedSelectorUpdate = createLoadedSelector([UPDATE_INCLUDED_FORM]);

  const structuredSelector = createStructuredSelector({
    currentIncludedForm: state => state.product.currentIncludedForm,
    staffSmsMergeFields: state => state.product.staffSmsMergeFields,
    updateError: state => updateErrorSelector(state),
    isUpdateLoaded: state => loadedSelectorUpdate(state)
  });

  const {
    currentIncludedForm,
    staffSmsMergeFields,
    updateError,
    isUpdateLoaded
  } = useSelector(structuredSelector);

  const breadcrumbs = [
    {
      name: 'Overlord',
      action: () => {
        window.location = '/overlord/';
      }
    },
    {
      name: 'Partner',
      action: () => {
        window.location = `/overlord/partners/${partnerId}`;
      }
    },
    {
      name: 'Product',
      action: () => {
        window.location = `/overlord/partners/${partnerId}/products/${productId}`;
      }
    },
    {
      name: 'Included Form',
      action: () => {
        window.location = `/overlord/partners/${partnerId}/products/${productId}/included_forms/${id}`;
      }
    },
    {
      name: 'Settings',
      action: () => {
        window.location = `/overlord/partners/${partnerId}/products/${productId}/included_forms/${id}/settings`;
      }
    },
    {
      name: 'Staff SMS Notification',
      action: () => console.log('staff notification email clicked')
    }
  ];

  useEffect(() => {
    if (isUpdateLoaded) {
      if (isEmpty(updateError)) {
        dispatch(
          flashSuccessMessage('Successfully updated staff sms notification.')
        );
      } else if (!isEmpty(updateError)) {
        dispatch(flashErrorMessage(updateError));
      }
    }
  }, [isUpdateLoaded, updateError]);

  useEffect(() => {
    dispatch(fetchIncludedForm({ includedFormId: id }));
  }, [id, dispatch]);

  useEffect(() => {
    dispatch(fetchStaffSmsMergeFields());
  }, []);

  useEffect(() => {
    if (!isEmpty(currentIncludedForm)) {
      dispatch(
        setOverlordLayoutHeaderText(`${currentIncludedForm.name} Settings`)
      );
    } else {
      dispatch(setOverlordLayoutHeaderText('Settings'));
    }
  }, [currentIncludedForm]);

  function handleSubmit(values) {
    const staffSmsNotificationParams = {};
    if (Object.keys(values).length)
      Object.keys(values).forEach(function assignParams(key) {
        staffSmsNotificationParams[key] = values[key];
      });
    if (currentIncludedForm?.uplaunch_action) {
      staffSmsNotificationParams.id = currentIncludedForm.uplaunch_action.id;
    }
    dispatch(
      updateIncludedForm({
        includedFormId: id,
        staffSmsNotificationParams
      })
    );
  }

  const formik = useFormik({
    initialValues: {
      staffNotificationSmsRecipients:
        currentIncludedForm?.uplaunch_action
          ?.staff_notification_sms_recipients || 'admin',
      staffSmsBody: currentIncludedForm?.uplaunch_action?.staff_sms_body || '',
      hasStaffNotificationSms:
        currentIncludedForm?.uplaunch_action?.has_staff_notification_sms
    },
    enableReinitialize: true,
    validationSchema: staffSmsNotificationSchema,
    onSubmit: values => {
      handleSubmit(values);
    }
  });

  function handleMergeFieldInsert(mergeField) {
    const textArea = document.getElementById('staff_sms_body');
    const caretPos = textArea.selectionStart;
    const caretEnd = textArea.selectionEnd;
    const textAreaTxt = textArea.value;
    const txtToAdd = mergeField;
    textArea.value =
      textAreaTxt.substring(0, caretPos) +
      txtToAdd +
      textAreaTxt.substring(caretEnd);
    textArea.focus();
    textArea.selectionStart = caretPos + txtToAdd.length;
    textArea.selectionEnd = caretPos + txtToAdd.length;
    formik.setFieldValue('staffSmsBody', textArea.value);
  }

  function handleHasStaffNotificationSmsToggle() {
    formik.setFieldValue(
      'hasStaffNotificationSms',
      !formik.values.hasStaffNotificationSms
    );
  }

  const staffSmsMergeFieldOptions = staffSmsMergeFields.map(field => {
    return { displayName: field[0], value: field[1] };
  });

  return (
    <div>
      <div className="tw-px-4 tw-py-4 sm:tw-px-6">
        <div className="tw-mb-5">
          <div className="tw-mb-5">
            {breadcrumbs?.length && (
              <div>
                <Breadcrumbs crumbs={breadcrumbs} isBackgroundWhite />
              </div>
            )}
          </div>
          <div className="tw-mt-2 md:tw-flex md:tw-items-center md:tw-justify-between">
            <div className="tw-flex-1 tw-min-w-0">
              <Toggle
                color="alpha"
                onClick={handleHasStaffNotificationSmsToggle}
                isToggled={formik.values.hasStaffNotificationSms}
                size="large"
                withIcons
                withLabel
                label="Send Staff SMS Notification?"
              />
            </div>
            <div className="tw-mt-4 tw-flex tw-flex-shrink-0 md:tw-mt-0 md:tw-ml-4">
              <Button
                text="Update"
                color="alpha"
                onClick={formik.handleSubmit}
                containerStyle={{ marginLeft: '0.75rem' }}
                disabled={isEmpty(currentIncludedForm)}
                isLoaded={!isEmpty(currentIncludedForm)}
              />
            </div>
          </div>
        </div>
        <div className="tw-border-b tw-border-t-0 tw-border-l-0 tw-border-r-0 tw-border-solid tw-border-gray-200" />
        <div className="tw-mt-10">
          <RadioButtons
            id="staff_notification_sms_recipients"
            label="Send A Staff SMS Notification To:"
            type="horizontal"
            options={STAFF_NOTIFICATION_RECIPIENT_OPTIONS}
            onChange={value => {
              formik.setFieldValue('staffNotificationSmsRecipients', value);
            }}
            onBlur={formik.handleBlur}
            selected={formik.values.staffNotificationSmsRecipients}
          />
        </div>
        <div className="tw-mt-10">
          <div className="tw-grid tw-grid-cols-12 tw-gap-x-6 gap-y-0">
            <Select
              labelText=""
              placeholder="Insert Merge Field"
              id="merge_fields"
              options={staffSmsMergeFieldOptions}
              value=""
              onChange={e => {
                handleMergeFieldInsert(e.target.value);
              }}
              widthClass="tw-col-start-11 sm:tw-col-start-9 tw-col-span-2 sm:tw-col-span-4 tw-mb-0"
            />

            <TextArea
              id="staff_sms_body"
              value={formik.values.staffSmsBody}
              labelText="Staff SMS Body"
              required
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              showError={
                formik.touched.staffSmsBody && !!formik.errors.staffSmsBody
              }
              error={formik.errors.staffSmsBody}
              widthClass="tw-col-span-12"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaffSmsNotification;
