import React, { useEffect, useRef } from 'react';
import { createStructuredSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import {
  createErrorMessageSelector,
  createLoadedSelector
} from 'appState/selectors';
import { broadcastResetPreviewData } from 'appState/actions/ActionCreators';
import useOnClickOutside from 'components/shared/hooks/useOnClickOutside';
import BroadcastPreview from 'components/BroadcastCenter/Broadcast/Index/BroadcastPreview';
import getPathName from 'lib/utils/router';

let handleCloseTimeout;

const BroadcastPreviewModal = () => {
  const dispatch = useDispatch();
  const modalRef = useRef();
  const { path } = useRouteMatch();
  const pathName = getPathName(path);

  const errorSelector = createErrorMessageSelector([
    'broadcastCenter/BROADCAST_FETCH_PREVIEW'
  ]);
  const loadedSelector = createLoadedSelector([
    'broadcastCenter/BROADCAST_FETCH_PREVIEW'
  ]);
  const structuredSelector = createStructuredSelector({
    error: state => errorSelector(state),
    isLoaded: state => loadedSelector(state)
  });

  const { error, isLoaded } = useSelector(structuredSelector);

  function handleClose() {
    const previewModalElement = document.getElementById('show-preview-modal');
    const bodyElement = document.body;
    const isModalOpen = bodyElement.className.includes('modal-open');

    // Reset preview everywhere except for the schedule/save-details screens
    if (!['schedule', 'save-details'].includes(pathName))
      // setTimeout to wait for modal hide/close animation
      handleCloseTimeout = setTimeout(() => {
        if (isModalOpen && previewModalElement)
          dispatch(broadcastResetPreviewData());
      }, 300);
  }

  useEffect(() => {
    return () => {
      clearTimeout(handleCloseTimeout);
    };
  }, []);

  useOnClickOutside(modalRef, () => handleClose());

  return (
    <div
      className="modal fade show"
      id="show-preview-modal"
      tabIndex="-1"
      aria-modal="true"
      style={{ display: 'none' }}
    >
      <div className="modal-dialog modal-lg" ref={modalRef}>
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="myModalLabel">
              Preview
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={handleClose}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            {!isLoaded && !error && (
              <span id="preview-email-modal-content">
                <div className="h-400px center-vh">
                  <svg
                    className="spinner-circle-material-svg"
                    viewBox="0 0 50 50"
                  >
                    <circle className="circle" cx="25" cy="25" r="20" />
                  </svg>
                </div>
              </span>
            )}
            {isLoaded && <BroadcastPreview />}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-info"
              data-dismiss="modal"
              onClick={handleClose}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BroadcastPreviewModal;
