import React, { Fragment, useState, useEffect } from 'react';
import Table from 'components/Theme/Table';
import Pagination from 'components/Theme/Pagination';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import { MATCH_LIMIT } from 'config/constants';
import HeaderComponent from 'components/SyncWizard/shared/HeaderComponent';

const includedColumns = {
  source: 'source',
  firstName: 'first name',
  lastName: 'last name',
  email: 'email',
  status: 'lifecycle/status'
};

const ExactMatch = ({ syncData, onClick, isSticky }) => {
  const exactMatchSyncData = syncData.contacts.exactMatch;
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState(
    exactMatchSyncData.slice(0, MATCH_LIMIT)
  );
  const totalItems = exactMatchSyncData.length;
  const totalPages = Math.ceil(totalItems / MATCH_LIMIT);

  const updatePage = (data = null) => {
    const pageData = get(data, 'page');
    const pageNum = pageData || currentPage;
    const pageCal = (pageNum - 1) * MATCH_LIMIT;
    const multipleRows = exactMatchSyncData.slice(
      pageCal,
      pageCal + MATCH_LIMIT
    );
    if (pageData) {
      setCurrentPage(pageNum);
    }
    setCurrentData(multipleRows);
  };

  const rowIndexOffset = (currentPage - 1) * MATCH_LIMIT;

  useEffect(() => {
    updatePage();
  }, [exactMatchSyncData]);

  return (
    <Fragment>
      <HeaderComponent title="Step 1: Connect Your Contacts Who Match Exactly" />
      <div className="tw-mx-10 tw-mt-10 tw-mb-20">
        <div className="tw-rounded-md tw-bg-alpha-100 tw-p-4">
          <div className="tw-flex">
            <div className="tw-flex-shrink-0">
              <svg
                className="tw-h-5 tw-w-5 tw-text-alpha-400"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div className="tw-ml-3">
              <h3 className="tw-text-sm tw-leading-5 tw-font-medium tw-text-alpha-800">
                Who's here?
              </h3>
              <div className="tw-mt-2 tw-text-sm tw-leading-5 tw-text-alpha-700">
                <ul className="tw-list-disc tw-pl-5">
                  <li>
                    We found exact email and lifecycle matches to contacts in
                    Zen Planner.
                  </li>
                  <li className="tw-mt-1">Names may not match.</li>
                  <li className="tw-mt-1">
                    You should toggle off any contacts you do not want to be
                    connected.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="tw-max-w-10xl tw-mt-12">
          <Table
            title=""
            subtitle=""
            rows={currentData}
            includedColumns={includedColumns}
            hasToggleActionPerRecord
            disabledTogglePositions={[0]}
            onClickToggleBtn={onClick}
            hasStatus
            hasFooter
            showStatusColor
            isSticky={isSticky}
            rowIndexOffset={rowIndexOffset}
            FooterComponent={() =>
              totalPages && totalPages > 1 ? (
                <Pagination
                  total={totalItems}
                  totalPages={totalPages}
                  limit={MATCH_LIMIT}
                  action={updatePage}
                />
              ) : null
            }
            customActionHeader="Connect"
            emptyMessage="No action required"
            popOverInfo="lifecycle/status"
          />
        </div>
      </div>
    </Fragment>
  );
};

ExactMatch.propTypes = {
  syncData: PropTypes.shape({
    contacts: PropTypes.shape({
      exactMatch: PropTypes.array.isRequired,
      possibleMatchWithSameEmail: PropTypes.array,
      possibleMatchWithSameName: PropTypes.array,
      newToUplaunch: PropTypes.array,
      newToZenPlanner: PropTypes.array,
      recordsCount: PropTypes.shape({
        alreadySynced: PropTypes.number,
        zenPlanner: PropTypes.number,
        upLaunch: PropTypes.number,
        exactMatch: PropTypes.number,
        possibleMatch: PropTypes.number,
        newToUplaunch: PropTypes.number,
        newToZenPlanner: PropTypes.number
      })
    }).isRequired
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  isSticky: PropTypes.bool.isRequired
};

export default ExactMatch;
