// linting rules disabled for immer js
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  FETCH_ZEN_PLANNER_PROGRAMS_SUCCESS,
  FETCH_SELECTED_LINKED_ZEN_PLANNER_SUCCESS
} from 'appState/actions/constants/zenplannersync.actions';

const initialState = {
  zenPlannerPrograms: [],
  selectedPrograms: [],
  linkedZpPrograms: []
};

export default function ZenPlannerSyncReducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case FETCH_ZEN_PLANNER_PROGRAMS_SUCCESS: {
        const { zenPlannerPrograms } = action.payload;
        draft.zenPlannerPrograms = zenPlannerPrograms;
        return;
      }
      case FETCH_SELECTED_LINKED_ZEN_PLANNER_SUCCESS: {
        const { selectedPrograms, linkedZpPrograms } = action.payload;
        draft.selectedPrograms = selectedPrograms;
        draft.linkedZpPrograms = linkedZpPrograms;
        return;
      }
      default:
        return draft;
    }
  });
}
