/* eslint-disable react/display-name */
import React from 'react';
import { withA11y } from '@storybook/addon-a11y';
import { withKnobs, select } from '@storybook/addon-knobs';
import ShowMore from './index';

export default {
  component: ShowMore,
  title: 'ShowMore',
  decorators: [withA11y, withKnobs]
};

let colorSelect;

function includeColorSelect(defaultValue) {
  const label = 'Color';
  const options = [
    'alpha',
    'bravo',
    'charlie',
    'success',
    'warning',
    'error',
    'gray'
  ];
  colorSelect = select(label, options, defaultValue);
}

const sampleChildren = () => {
  return (
    <div>
      <div className="tw-flex">
        <div className="tw-flex-shrink-0 tw-mr-2">
          <svg
            className="tw-h-5 tw-w-5 tw-text-gray-500"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
            <path d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
          </svg>
        </div>
        <div className="tw-flex-1 tw-text-sm tw-leading-5 ">
          No Address Stored
        </div>
      </div>
      <div className="tw-flex">
        <div className="tw-flex-shrink-0 tw-mr-2">
          <svg
            className="tw-h-5 tw-w-5 tw-text-gray-500"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
          </svg>
        </div>
        <div className="tw-flex-1 tw-text-sm tw-leading-5 ">
          No Birthday Stored
        </div>
      </div>
      <div className="tw-flex">
        <div className="tw-flex-shrink-0 tw-mr-2">
          <svg
            className="tw-h-5 tw-w-5 tw-text-gray-500"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
          </svg>
        </div>
        <div className="tw-flex-1 tw-text-sm tw-leading-5 ">
          No Gender Stored
        </div>
      </div>
    </div>
  );
};

export const primary = () => {
  includeColorSelect('alpha');
  return (
    <div>
      <div className="tw-flex">
        <div className="tw-flex-shrink-0 tw-mr-2">
          <svg
            className="tw-h-5 tw-w-5 tw-text-gray-500"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
          </svg>
        </div>
        <div className="tw-flex-1 tw-text-sm tw-leading-5 ">
          No Phone Number
        </div>
      </div>
      <ShowMore color={colorSelect}>{sampleChildren()}</ShowMore>
    </div>
  );
};
