import React from 'react';
import Icon from '../index';
import iconPropTypes from '../iconPropTypes';
import iconDefaultProps from '../iconDefaultProps';

const DocumentAddIcon = ({ color, tint, filled, onClick, width, height }) => {
  return (
    <Icon
      color={color}
      tint={tint}
      onClick={onClick}
      filled={filled}
      width={width}
      height={height}
      pathDefinition={
        filled
          ? 'M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V8z'
          : 'M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z'
      }
    />
  );
};

DocumentAddIcon.defaultProps = iconDefaultProps;
DocumentAddIcon.propTypes = iconPropTypes;

export default DocumentAddIcon;
