/* Contact New */
export const CREATE_CONTACT_REQUEST = 'contact/CREATE_CONTACT_REQUEST';
export const CREATE_CONTACT_SUCCESS = 'contact/CREATE_CONTACT_SUCCESS';
export const CREATE_CONTACT_FAILURE = 'contact/CREATE_CONTACT_FAILURE';

/* Contact Edit */
export const UPDATE_CONTACT = 'contact/UPDATE_CONTACT';
export const UPDATE_CONTACT_REQUEST = `${UPDATE_CONTACT}_REQUEST`;
export const UPDATE_CONTACT_SUCCESS = `${UPDATE_CONTACT}_SUCCESS`;
export const UPDATE_CONTACT_FAILURE = `${UPDATE_CONTACT}_FAILURE`;

/* Contact Remove Spam Complaint */
export const REMOVE_SPAM_COMPLAINT = 'contact/REMOVE_SPAM_COMPLAINT';
export const REMOVE_SPAM_COMPLAINT_REQUEST = `${REMOVE_SPAM_COMPLAINT}_REQUEST`;
export const REMOVE_SPAM_COMPLAINT_SUCCESS = `${REMOVE_SPAM_COMPLAINT}_SUCCESS`;
export const REMOVE_SPAM_COMPLAINT_FAILURE = `${REMOVE_SPAM_COMPLAINT}_FAILURE`;

/* Contact Import Listt */
export const FETCH_IMPORT_HISTORY = 'contact/FETCH_IMPORT_HISTORY';
export const FETCH_IMPORT_HISTORY_REQUEST = `${FETCH_IMPORT_HISTORY}_REQUEST`;
export const FETCH_IMPORT_HISTORY_SUCCESS = `${FETCH_IMPORT_HISTORY}_SUCCESS`;
export const FETCH_IMPORT_HISTORY_FAILURE = `${FETCH_IMPORT_HISTORY}_FAILURE`;

/* Contact Profile */
export const FETCH_CONTACT = 'contact/FETCH_CONTACT';
export const FETCH_CONTACT_REQUEST = `${FETCH_CONTACT}_REQUEST`;
export const FETCH_CONTACT_SUCCESS = `${FETCH_CONTACT}_SUCCESS`;
export const FETCH_CONTACT_FAILURE = `${FETCH_CONTACT}_FAILURE`;

export const FETCH_NEXT_CAMPAIGN_TOUCHPOINT =
  'contact/FETCH_NEXT_CAMPAIGN_TOUCHPOINT';
export const FETCH_NEXT_CAMPAIGN_TOUCHPOINT_REQUEST = `${FETCH_NEXT_CAMPAIGN_TOUCHPOINT}_REQUEST`;
export const FETCH_NEXT_CAMPAIGN_TOUCHPOINT_SUCCESS = `${FETCH_NEXT_CAMPAIGN_TOUCHPOINT}_SUCCESS`;
export const FETCH_NEXT_CAMPAIGN_TOUCHPOINT_FAILURE = `${FETCH_NEXT_CAMPAIGN_TOUCHPOINT}_FAILURE`;

export const FETCH_CONTACT_AUTOMATIONS = 'contact/FETCH_CONTACT_AUTOMATIONS';
export const FETCH_CONTACT_AUTOMATIONS_REQUEST = `${FETCH_CONTACT_AUTOMATIONS}_REQUEST`;
export const FETCH_CONTACT_AUTOMATIONS_SUCCESS = `${FETCH_CONTACT_AUTOMATIONS}_SUCCESS`;
export const FETCH_CONTACT_AUTOMATIONS_FAILURE = `${FETCH_CONTACT_AUTOMATIONS}_FAILURE`;

export const FETCH_CONTACT_CUSTOM_GROUPS =
  'contact/FETCH_CONTACT_CUSTOM_GROUPS';
export const FETCH_CONTACT_CUSTOM_GROUPS_REQUEST = `${FETCH_CONTACT_CUSTOM_GROUPS}_REQUEST`;
export const FETCH_CONTACT_CUSTOM_GROUPS_SUCCESS = `${FETCH_CONTACT_CUSTOM_GROUPS}_SUCCESS`;
export const FETCH_CONTACT_CUSTOM_GROUPS_FAILURE = `${FETCH_CONTACT_CUSTOM_GROUPS}_FAILURE`;
export const UPDATE_CONTACT_CUSTOM_GROUPS_REQUEST =
  'custom_group/UPDATE_CONTACT_CUSTOM_GROUPS_REQUEST';
export const UPDATE_CONTACT_CUSTOM_GROUPS_SUCCESS =
  'custom_group/UPDATE_CONTACT_CUSTOM_GROUPS_SUCCESS';
export const UPDATE_CONTACT_CUSTOM_GROUPS_FAILURE =
  'custom_group/UPDATE_CONTACT_CUSTOM_GROUPS_FAILURE';

export const FETCH_CURRENT_CONTACT_CTA = 'contact/FETCH_CURRENT_CONTACT_CTA';
export const FETCH_CURRENT_CONTACT_CTA_REQUEST = `${FETCH_CURRENT_CONTACT_CTA}_REQUEST`;
export const FETCH_CURRENT_CONTACT_CTA_SUCCESS = `${FETCH_CURRENT_CONTACT_CTA}_SUCCESS`;
export const FETCH_CURRENT_CONTACT_CTA_FAILURE = `${FETCH_CURRENT_CONTACT_CTA}_FAILURE`;

export const SET_CURRENT_CONTACT_TAB = 'contact/SET_CURRENT_CONTACT_TAB';

export const SET_CSV_VALIDATION_ERROR = 'contacts/SET_CSV_VALIDATION_ERROR';

export const SCHEDULE_CSV_IMPORT = 'contact/SCHEDULE_CSV_IMPORT';
export const SCHEDULE_CSV_IMPORT_REQUEST = `${SCHEDULE_CSV_IMPORT}_REQUEST`;
export const SCHEDULE_CSV_IMPORT_SUCCESS = `${SCHEDULE_CSV_IMPORT}_SUCCESS`;
export const SCHEDULE_CSV_IMPORT_FAILURE = `${SCHEDULE_CSV_IMPORT}_FAILURE`;

export const FETCH_CURRENT_AUTOMATION_DETAILS =
  'contact/FETCH_CURRENT_AUTOMATION_DETAILS';
export const FETCH_CURRENT_AUTOMATION_DETAILS_REQUEST = `${FETCH_CURRENT_AUTOMATION_DETAILS}_REQUEST`;
export const FETCH_CURRENT_AUTOMATION_DETAILS_SUCCESS = `${FETCH_CURRENT_AUTOMATION_DETAILS}_SUCCESS`;
export const FETCH_CURRENT_AUTOMATION_DETAILS_FAILURE = `${FETCH_CURRENT_AUTOMATION_DETAILS}_FAILURE`;

export const PAUSE_AUTOMATION = 'contact/PAUSE_AUTOMATION';
export const PAUSE_AUTOMATION_REQUEST = `${PAUSE_AUTOMATION}_REQUEST`;
export const PAUSE_AUTOMATION_SUCCESS = `${PAUSE_AUTOMATION}_SUCCESS`;
export const PAUSE_AUTOMATION_FAILURE = `${PAUSE_AUTOMATION}_FAILURE`;

export const UNPAUSE_AUTOMATION = 'contact/UNPAUSE_AUTOMATION';
export const UNPAUSE_AUTOMATION_REQUEST = `${UNPAUSE_AUTOMATION}_REQUEST`;
export const UNPAUSE_AUTOMATION_SUCCESS = `${UNPAUSE_AUTOMATION}_SUCCESS`;
export const UNPAUSE_AUTOMATION_FAILURE = `${UNPAUSE_AUTOMATION}_FAILURE`;

export const UPDATE_CURRENT_AUTOMATION_COMPLETE_REQUEST =
  'contact/UPDATE_CURRENT_AUTOMATION_COMPLETE_REQUEST';
export const UPDATE_CURRENT_AUTOMATION_COMPLETE_SUCCESS =
  'contact/UPDATE_CURRENT_AUTOMATION_COMPLETE_SUCCESS';
export const UPDATE_CURRENT_AUTOMATION_COMPLETE_FAILURE =
  'contact/UPDATE_CURRENT_AUTOMATION_COMPLETE_FAILURE';
export const NEW_AUTOMATION_LIFECYCLE_CHECK_REQUEST =
  'contact/NEW_AUTOMATION_LIFECYCLE_CHECK_REQUEST';
export const NEW_AUTOMATION_LIFECYCLE_CHECK_SUCCESS =
  'contact/NEW_AUTOMATION_LIFECYCLE_CHECK_SUCCESS';
export const NEW_AUTOMATION_LIFECYCLE_CHECK_FAILURE =
  'contact/NEW_AUTOMATION_LIFECYCLE_CHECK_FAILURE';

export const FETCH_ACCOUNT_CAMPAIGNS_REQUEST =
  'contact/FETCH_ACCOUNT_CAMPAIGNS_REQUEST';
export const FETCH_ACCOUNT_CAMPAIGNS_SUCCESS =
  'contact/FETCH_ACCOUNT_CAMPAIGNS_SUCCESS';
export const FETCH_ACCOUNT_CAMPAIGNS_FAILURE =
  'contact/FETCH_ACCOUNT_CAMPAIGNS_FAILURE';

export const FETCH_CAMPAIGN_STEP_LIST_REQUEST =
  'contact/FETCH_CAMPAIGN_STEP_LIST_REQUEST';
export const FETCH_CAMPAIGN_STEP_LIST_SUCCESS =
  'contact/FETCH_CAMPAIGN_STEP_LIST_SUCCESS';
export const FETCH_CAMPAIGN_STEP_LIST_FAILURE =
  'contact/FETCH_CAMPAIGN_STEP_LIST_FAILURE';

export const CREATE_AUTOMATION = 'contact/CREATE_AUTOMATION';
export const CREATE_AUTOMATION_REQUEST = `${CREATE_AUTOMATION}_REQUEST`;
export const CREATE_AUTOMATION_SUCCESS = `${CREATE_AUTOMATION}_SUCCESS`;
export const CREATE_AUTOMATION_FAILURE = `${CREATE_AUTOMATION}_FAILURE`;

export const FETCH_CAMPAIGNS_LIST = 'contact/FETCH_CAMPAIGNS_LIST';
export const FETCH_CAMPAIGNS_LIST_REQUEST = `${FETCH_CAMPAIGNS_LIST}_REQUEST`;
export const FETCH_CAMPAIGNS_LIST_SUCCESS = `${FETCH_CAMPAIGNS_LIST}_SUCCESS`;
export const FETCH_CAMPAIGNS_LIST_FAILURE = `${FETCH_CAMPAIGNS_LIST}_FAILURE`;

export const NEW_LIFECYCLE_CONFIRMATION_REQUEST =
  'contact/NEW_LIFECYCLE_CONFIRMATION_REQUEST';
export const NEW_LIFECYCLE_CONFIRMATION_SUCCESS =
  'contact/NEW_LIFECYCLE_CONFIRMATION_SUCCESS';
export const NEW_LIFECYCLE_CONFIRMATION_FAILURE =
  'contact/NEW_LIFECYCLE_CONFIRMATION_FAILURE';

export const UPDATE_CONTACT_LIFECYCLE_REQUEST =
  'contact/UPDATE_CONTACT_LIFECYCLE_REQUEST';
export const UPDATE_CONTACT_LIFECYCLE_SUCCESS =
  'contact/UPDATE_CONTACT_LIFECYCLE_SUCCESS';
export const UPDATE_CONTACT_LIFECYCLE_FAILURE =
  'contact/UPDATE_CONTACT_LIFECYCLE_FAILURE';

export const CREATE_CONTACT_NOTE = 'contact/CREATE_CONTACT_NOTE';
export const CREATE_CONTACT_NOTE_REQUEST = `${CREATE_CONTACT_NOTE}_REQUEST`;
export const CREATE_CONTACT_NOTE_SUCCESS = `${CREATE_CONTACT_NOTE}_SUCCESS`;
export const CREATE_CONTACT_NOTE_FAILURE = `${CREATE_CONTACT_NOTE}_FAILURE`;
export const CREATE_FILE_UPLOAD_REQUEST = 'contact/CREATE_FILE_UPLOAD_REQUEST';
export const CREATE_FILE_UPLOAD_SUCCESS = 'contact/CREATE_FILE_UPLOAD_SUCCESS';
export const CREATE_FILE_UPLOAD_FAILURE = 'contact/CREATE_FILE_UPLOAD_FAILURE';

export const FETCH_APPOINTMENT_TYPES = 'contact/FETCH_APPOINTMENT_TYPES';
export const FETCH_APPOINTMENT_TYPES_REQUEST = `${FETCH_APPOINTMENT_TYPES}_REQUEST`;
export const FETCH_APPOINTMENT_TYPES_SUCCESS = `${FETCH_APPOINTMENT_TYPES}_SUCCESS`;
export const FETCH_APPOINTMENT_TYPES_FAILURE = `${FETCH_APPOINTMENT_TYPES}_FAILURE`;
export const SET_CURRENT_APPOINTMENT_TYPE_ID =
  'contact/SET_CURRENT_APPOINTMENT_TYPE_ID';

export const CREATE_APPOINTMENT_REQUEST = 'contact/CREATE_APPOINTMENT_REQUEST';
export const CREATE_APPOINTMENT_SUCCESS = 'contact/CREATE_APPOINTMENT_SUCCESS';
export const CREATE_APPOINTMENT_FAILURE = 'contact/CREATE_APPOINTMENT_FAILURE';

export const FETCH_NEXT_APPOINTMENT = 'contact/FETCH_NEXT_APPOINTMENT';
export const FETCH_NEXT_APPOINTMENT_REQUEST = `${FETCH_NEXT_APPOINTMENT}_REQUEST`;
export const FETCH_NEXT_APPOINTMENT_SUCCESS = `${FETCH_NEXT_APPOINTMENT}_SUCCESS`;
export const FETCH_NEXT_APPOINTMENT_FAILURE = `${FETCH_NEXT_APPOINTMENT}_FAILURE`;

export const FETCH_NEXT_TASK = 'contact/FETCH_NEXT_TASK';
export const FETCH_NEXT_TASK_REQUEST = `${FETCH_NEXT_TASK}_REQUEST`;
export const FETCH_NEXT_TASK_SUCCESS = `${FETCH_NEXT_TASK}_SUCCESS`;
export const FETCH_NEXT_TASK_FAILURE = `${FETCH_NEXT_TASK}_FAILURE`;

export const FETCH_UPLOADED_FILES_LIST_REQUEST =
  'contact/FETCH_UPLOADED_FILES_LIST_REQUEST';
export const FETCH_UPLOADED_FILES_LIST_SUCCESS =
  'contact/FETCH_UPLOADED_FILES_LIST_SUCCESS';
export const FETCH_UPLOADED_FILES_LIST_FAILURE =
  'contact/FETCH_UPLOADED_FILES_LIST_FAILURE';

// enable new contact profile
export const ENABLE_NEW_CONTACT_PROFILE_REQUEST =
  'overlord/ENABLE_NEW_CONTACT_PROFILE_REQUEST';
export const ENABLE_NEW_CONTACT_PROFILE_SUCCESS =
  'overlord/ENABLE_NEW_CONTACT_PROFILE_SUCCESS';
export const ENABLE_NEW_CONTACT_PROFILE_FAILURE =
  'overlord/ENABLE_NEW_CONTACT_PROFILE_FAILURE';

export const VALIDATE_CONTACTS_CSV_REQUEST =
  'contact/VALIDATE_CONTACTS_CSV_REQUEST';
export const VALIDATE_CONTACTS_CSV_SUCCESS =
  'contact/VALIDATE_CONTACTS_CSV_SUCCESS';
export const VALIDATE_CONTACTS_CSV_FAILURE =
  'contact/VALIDATE_CONTACTS_CSV_FAILURE';

export const SAVE_CONTACTS_CSV_REQUEST = 'contact/SAVE_CONTACTS_CSV_REQUEST';
export const SAVE_CONTACTS_CSV_SUCCESS = 'contact/SAVE_CONTACTS_CSV_SUCCESS';
export const SAVE_CONTACTS_CSV_FAILURE = 'contact/SAVE_CONTACTS_CSV_FAILURE';

export const CREATE_CSV_IMPORT_REQUEST = 'contact/CREATE_CSV_IMPORT_REQUEST';
export const CREATE_CSV_IMPORT_SUCCESS = 'contact/CREATE_CSV_IMPORT_SUCCESS';
export const CREATE_CSV_IMPORT_FAILURE = 'contact/CREATE_CSV_IMPORT_FAILURE';

export const UPDATE_CSV_IMPORT_REQUEST = 'contact/UPDATE_CSV_IMPORT_REQUEST';
export const UPDATE_CSV_IMPORT_SUCCESS = 'contact/UPDATE_CSV_IMPORT_SUCCESS';
export const UPDATE_CSV_IMPORT_FAILURE = 'contact/UPDATE_CSV_IMPORT_FAILURE';

export const FETCH_CSV_IMPORT_REQUEST = 'contact/FETCH_CSV_IMPORT_REQUEST';
export const FETCH_CSV_IMPORT_SUCCESS = 'contact/FETCH_CSV_IMPORT_SUCCESS';
export const FETCH_CSV_IMPORT_FAILURE = 'contact/FETCH_CSV_IMPORT_FAILURE';

export const VALIDATE_SINGLE_CONTACT_CSV_REQUEST =
  'contact/VALIDATE_SINGLE_CONTACT_CSV_REQUEST';
export const VALIDATE_SINGLE_CONTACT_CSV_SUCCESS =
  'contact/VALIDATE_SINGLE_CONTACT_CSV_SUCCESS';
export const VALIDATE_SINGLE_CONTACT_CSV_FAILURE =
  'contact/VALIDATE_SINGLE_CONTACT_CSV_FAILURE';

export const DELETE_CSV_IMPORT_REQUEST = 'contact/DELETE_CSV_IMPORT_REQUEST';
export const DELETE_CSV_IMPORT_SUCCESS = 'contact/DELETE_CSV_IMPORT_SUCCESS';
export const DELETE_CSV_IMPORT_FAILURE = 'contact/DELETE_CSV_IMPORT_FAILURE';

export const CREATE_EMAIL = 'contact/CREATE_EMAIL';
export const CREATE_EMAIL_REQUEST = `${CREATE_EMAIL}_REQUEST`;
export const CREATE_EMAIL_SUCCESS = `${CREATE_EMAIL}_SUCCESS`;
export const CREATE_EMAIL_FAILURE = `${CREATE_EMAIL}_FAILURE`;

export const DELETE_CONTACT = 'contact/DELETE_CONTACT';
export const DELETE_CONTACT_REQUEST = `${DELETE_CONTACT}_REQUEST`;
export const DELETE_CONTACT_SUCCESS = `${DELETE_CONTACT}_SUCCESS`;
export const DELETE_CONTACT_FAILURE = `${DELETE_CONTACT}_FAILURE`;

export const UNSUBSCRIBE_CONTACT = 'contact/UNSUBSCRIBE_CONTACT';
export const UNSUBSCRIBE_CONTACT_REQUEST = `${UNSUBSCRIBE_CONTACT}_REQUEST`;
export const UNSUBSCRIBE_CONTACT_SUCCESS = `${UNSUBSCRIBE_CONTACT}_SUCCESS`;
export const UNSUBSCRIBE_CONTACT_FAILURE = `${UNSUBSCRIBE_CONTACT}_FAILURE`;

export const RESUBSCRIBE_CONTACT = 'contact/RESUBSCRIBE_CONTACT';
export const RESUBSCRIBE_CONTACT_REQUEST = `${RESUBSCRIBE_CONTACT}_REQUEST`;
export const RESUBSCRIBE_CONTACT_SUCCESS = `${RESUBSCRIBE_CONTACT}_SUCCESS`;
export const RESUBSCRIBE_CONTACT_FAILURE = `${RESUBSCRIBE_CONTACT}_FAILURE`;
