import React, { useContext, useEffect, useState } from 'react';
import UtilityCard from 'components/OverlordUtilities/shared/UtilityCard';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import isEmpty from 'lodash.isempty';
import {
  createErrorMessageSelector,
  createLoadedSelector
} from 'appState/selectors';
import {
  changeAccountAdmin,
  resetIsLoaded
} from 'appState/actions/ActionCreators';
import { CurrentAccountContext } from 'components/shared/context/CurrentAccountContext';
import { UsersContext } from 'components/shared/context/UsersContext';
import { titleize } from 'lib/utils/string';
import useProcessingActivity from 'components/shared/hooks/useProcessingActivity';
import useAccountDisabled from 'components/shared/hooks/useAccountDisabled';

const UTILITY_NAMES = ['admin_changer'];

const AccountAdminChanger = () => {
  const currentAccount = useContext(CurrentAccountContext);
  const processingActivity = useProcessingActivity(UTILITY_NAMES);
  const accountDisabled = useAccountDisabled();
  const { users, isUsersLoaded, usersError } = useContext(UsersContext);

  const errorSelector = createErrorMessageSelector([
    'overlord/ACCOUNT_ADMIN_CHANGER'
  ]);
  const loadedSelector = createLoadedSelector([
    'overlord/ACCOUNT_ADMIN_CHANGER'
  ]);
  const structuredSelector = createStructuredSelector({
    error: state => errorSelector(state),
    isLoaded: state => loadedSelector(state)
  });

  const { error, isLoaded } = useSelector(structuredSelector);

  const userSelectOptions = users.map(user => {
    return (
      <option key={user.id} value={user.id}>
        {user.id} - {user.full_name}
      </option>
    );
  });

  const [userId, setUserId] = useState();
  const [admin, setAdmin] = useState({});

  const dispatch = useDispatch();

  const changeAdmin = () => {
    if (userId) {
      dispatch(
        changeAccountAdmin({
          userId
        })
      );
    }
  };

  useEffect(() => {
    if (isLoaded && isEmpty(error)) {
      app.toast(
        `Successfully changed Admin of ${titleize(
          currentAccount.name
        )} account`,
        {
          type: 'primary',
          icon: 'fa-floppy-o'
        }
      );
    }
    return () => {
      dispatch(resetIsLoaded('overlord/ACCOUNT_ADMIN_CHANGER'));
    };
  }, [isLoaded, currentAccount.name, users, userId, error, dispatch]);

  useEffect(() => {
    if (isLoaded && isEmpty(error)) {
      const newAdmin =
        users && users.filter(user => user.id === parseInt(userId, 10))[0];
      if (newAdmin) setAdmin({ fullName: newAdmin.full_name, id: newAdmin.id });
    }
  }, [isLoaded, users, userId, error]);

  useEffect(() => {
    const currentAdmin = users && users.filter(user => user.admin)[0];
    if (currentAdmin)
      setAdmin({ fullName: currentAdmin.full_name, id: currentAdmin.id });
  }, [users]);

  const getCardBody = () => {
    const { fullName, id } = admin;
    return (
      <div>
        <p className="mb-10">
          Select the user you want to be the new admin.{' '}
          <strong>
            Current: {id} - {fullName}
          </strong>
        </p>
        <div className="form-group">
          <label htmlFor="userId">Select New Admin User</label>
          {users && users.length ? (
            <select
              onChange={e => setUserId(e.target.value)}
              id="userId"
              className="form-control"
              data-size="20"
              data-provide="selectpicker"
              defaultValue=""
            >
              <option value="" disabled hidden>
                Nothing selected
              </option>
              {userSelectOptions}
            </select>
          ) : null}
        </div>
      </div>
    );
  };
  return (
    <UtilityCard
      title="Account Admin Changer"
      subtitle="Use this tool to change the admin of an account."
      submitBtnText="Update Admin"
      submitBtnAction={changeAdmin}
      bodyComponent={getCardBody}
      isLoaded={isUsersLoaded}
      processingActivity={processingActivity}
      accountDisabled={accountDisabled}
      error={usersError || error}
    />
  );
};

export default AccountAdminChanger;
