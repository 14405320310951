import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import isEmpty from 'lodash.isempty';
import { broadcastsFetchCurrentBroadcast } from 'appState/actions/ActionCreators';
import Styled from './BroadcastStep.styles';
import Header from '../Header';
import Footer from '../Footer';

const BroadcastStep = props => {
  const { children, saveAction, sendingType, smsMediaLoaded } = props;
  const { id } = useParams();
  const dispatch = useDispatch();

  const structuredSelector = createStructuredSelector({
    currentBroadcast: state => state.broadcastCenter.currentBroadcast,
    step: state => state.broadcastCenter.step,
    isTemplate: state => state.broadcastCenter.isTemplate
  });

  const { currentBroadcast, step, isTemplate } = useSelector(
    structuredSelector
  );

  useEffect(() => {
    if (id && isEmpty(currentBroadcast) && step !== 'select-template')
      dispatch(broadcastsFetchCurrentBroadcast({ broadcastId: id }));
  }, [currentBroadcast, id, step, dispatch]);

  return (
    <div>
      <Header />
      <Styled.MainContent
        id="content"
        className="pt-0 main-content container-fluid"
        largerContent={step === 'select-template'}
        isTemplate={isTemplate}
      >
        {isTemplate && (
          <Styled.TemplateBorderBadgeContainer>
            <div className="badge badge-purple fs-12">Editing Template</div>
          </Styled.TemplateBorderBadgeContainer>
        )}
        <Styled.Row className="row">{children}</Styled.Row>
      </Styled.MainContent>
      <Footer
        saveAction={saveAction}
        sendingType={sendingType}
        smsMediaLoaded={smsMediaLoaded}
      />
    </div>
  );
};

BroadcastStep.defaultProps = {
  saveAction: null,
  sendingType: null,
  smsMediaLoaded: true
};

BroadcastStep.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  saveAction: PropTypes.func,
  sendingType: PropTypes.oneOf(['send_now', 'schedule', 'save_as_template']),
  smsMediaLoaded: PropTypes.bool
};

export default BroadcastStep;
