import React, { useContext, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import isEmpty from 'lodash.isempty';
import { createLoadedSelector } from 'appState/selectors';
import {
  fetchPinnedEmailActivities,
  fetchEmailActivities,
  emailActivitiesPageChange
} from 'appState/actions/ActionCreators';
import { FETCH_EMAIL_ACTIVITIES } from 'appState/actions/constants/activity.actions';
import PinnedActivities from 'components/Contact/Profile/PinnedActivities';
import RecentActivities from 'components/Contact/Profile/RecentActivities';
import { CurrentAccountContext } from 'components/shared/context/CurrentAccountContext';
import EmailEditor from './EmailEditor';

const EmailTab = () => {
  const dispatch = useDispatch();
  const currentAccount = useContext(CurrentAccountContext);

  const loadedSelector = createLoadedSelector([FETCH_EMAIL_ACTIVITIES]);

  const structuredSelector = createStructuredSelector({
    currentContact: state => state.contact.currentContact,
    pinnedEmailActivities: state => state.activity.pinnedEmailActivities,
    emailActivities: state => state.activity.emailActivities,
    emailTotal: state => state.activity.emailTotal,
    emailTotalPages: state => state.activity.emailTotalPages,
    limit: state => state.activity.limit,
    isLoaded: state => loadedSelector(state)
  });

  const {
    currentContact,
    pinnedEmailActivities,
    emailActivities,
    emailTotal,
    emailTotalPages,
    limit,
    isLoaded
  } = useSelector(structuredSelector);

  useEffect(() => {
    if (isEmpty(pinnedEmailActivities) && !isEmpty(currentContact))
      dispatch(
        fetchPinnedEmailActivities({
          accountId: currentAccount.id,
          contactId: currentContact.id
        })
      );
  }, [dispatch, currentContact]);

  useEffect(() => {
    if (isEmpty(emailActivities) && !isEmpty(currentContact))
      dispatch(
        fetchEmailActivities({
          accountId: currentAccount.id,
          contactId: currentContact.id
        })
      );
  }, [dispatch, currentContact]);

  return (
    <Fragment>
      <div>
        <EmailEditor />
        <div className="tw-py-5">
          <hr className="tw-relative tw-bottom-1 tw-h-0 tw-bg-gray-100" />
          <div className="tw-grid tw-grid-cols-12 tw-gap-6">
            <div className="tw-col-span-12 tw-px-4">
              <PinnedActivities
                tabName="email"
                activities={pinnedEmailActivities}
              />
              <RecentActivities
                activities={emailActivities}
                total={emailTotal}
                totalPages={emailTotalPages}
                limit={limit}
                currentContact={currentContact}
                currentAccount={currentAccount}
                isLoaded={isLoaded}
                resetAction={FETCH_EMAIL_ACTIVITIES}
                pageChangeAction={emailActivitiesPageChange}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EmailTab;
