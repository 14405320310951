import React from 'react';
import PropTypes from 'prop-types';

const CurrentAccountContext = React.createContext();

const CurrentAccountProvider = props => {
  const { children, value } = props;
  return (
    <CurrentAccountContext.Provider value={value}>
      {children}
    </CurrentAccountContext.Provider>
  );
};

CurrentAccountProvider.defaultProps = {
  children: null,
  value: null
};

CurrentAccountProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

export { CurrentAccountContext, CurrentAccountProvider };
