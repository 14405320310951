import styled from 'styled-components';

const Card = styled.div`
  border-radius: 10px;
  box-shadow: 0 5px 10px rgba(154, 160, 185, 0.05),
    0 15px 40px rgba(166, 173, 201, 0.2);
  overflow-y: 'hidden';
`;

const Phone = styled.div`
  width: 220px;
  height: 454.19px;
  border: 2px solid #ccc;
  border-radius: 30px;
  position: absolute;
  left: 0px;
  background: #fff;
`;

const Screen = styled.div`
  width: 200px;
  height: 358.62px;
  border: 1px solid #ccc;
  position: absolute;
  left: 8px;
  top: 50px;
  box-sizing: border-box;
  overflow: hidden;
`;

const Speaker = styled.div`
  width: 50px;
  height: 6px;
  border: 1px solid #ccc;
  border-radius: 6px;
  position: absolute;
  left: 50%;
  top: 25px;
  margin: 0 -25px;
`;

const Home = styled.div`
  width: 36px;
  height: 36px;
  border: 1px solid #ccc;
  position: absolute;
  bottom: 10px;
  left: 50%;
  margin: 0 -18px;
  border-radius: 50%;
`;

const Styled = {
  Card,
  Phone,
  Screen,
  Speaker,
  Home
};

export default Styled;
