// linting rules disabled for immer js
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  CREATE_LEAD_SOURCE_SUCCESS,
  FETCH_LEAD_SOURCES_SUCCESS,
  BULK_UPDATE_CONTACTS_SUCCESS
} from '../actions/constants/leadSources.actions';

const initialState = {
  defaultLeadSources: [],
  customLeadSources: [],
  rejectedBulkUpdateContacts: []
};

export default function LeadSourceReducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case FETCH_LEAD_SOURCES_SUCCESS: {
        const { defaultLeadSources } = action.payload;
        const { customLeadSources } = action.payload;
        draft.defaultLeadSources = defaultLeadSources;
        draft.customLeadSources = customLeadSources;
        return;
      }
      case CREATE_LEAD_SOURCE_SUCCESS: {
        const leadSource = action.payload;
        draft.customLeadSources = [...draft.customLeadSources, leadSource];
        return;
      }
      case BULK_UPDATE_CONTACTS_SUCCESS: {
        const rejectedBulkUpdateContacts = action.payload;
        draft.rejectedBulkUpdateContacts = rejectedBulkUpdateContacts;
        return;
      }
      default:
        return draft;
    }
  });
}
