import React, { useContext, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { formatTimestamp } from 'lib/utils/dateTime';
import { textTruncate } from 'lib/utils/string';
import { CurrentAccountContext } from 'components/shared/context/CurrentAccountContext';
import Progress from 'components/Theme/Progress';
import Badge from 'components/Theme/Badge';
import HeroIcon from 'components/Theme/HeroIcon';
import useOnClickOutside from 'components/shared/hooks/useOnClickOutside';

const AutomationListItem = ({
  automation,
  handleDetailsButtonClick,
  handlePauseButtonClick,
  handleUnpauseButtonClick,
  handleRemoveButtonClick
}) => {
  const currentAccount = useContext(CurrentAccountContext);
  const [coords, setCoords] = useState({});
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef();
  const dropdownButtonRef = useRef();

  function toggleDropdown() {
    const dropdownSpanRect = dropdownButtonRef.current?.getBoundingClientRect();

    setCoords({
      left: dropdownSpanRect.x - 200,
      top: dropdownSpanRect.y + dropdownSpanRect.height + window.scrollY
    });
    setShowDropdown(!showDropdown);
  }
  useOnClickOutside(dropdownRef, () => {
    setShowDropdown(false);
  });

  function automationButtonGroupOptions() {
    const opts = [];
    if (automation.paused_at) {
      opts.push({ displayName: 'Unpause', value: 'unpause' });
    } else {
      opts.push({ displayName: 'Pause', value: 'pause' });
    }
    opts.push({ displayName: 'Remove', value: 'remove' });
    return opts;
  }

  function handleButtonGroupSelect(val) {
    if (val === 'details') {
      handleDetailsButtonClick(automation.id);
    } else if (val === 'pause') {
      handlePauseButtonClick(automation);
    } else if (val === 'remove') {
      handleRemoveButtonClick(automation);
    } else if (val === 'unpause') {
      handleUnpauseButtonClick(automation);
    }
  }
  return (
    <span>
      <div className="tw-mb-4 md:tw-flex lg:tw-block xl:tw-flex tw-items-center tw-justify-between tw-text-sm tw-leading-5">
        <div className="tw-flex-1 tw-flex tw-items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="tw-h-4 tw-w-4 tw-text-gray-500"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M3 6c0-1.1.9-2 2-2h8l4-4h2v16h-2l-4-4H5a2 2 0 0 1-2-2H1V6h2zm8 9v5H8l-1.67-5H5v-2h8v2h-2z" />
          </svg>
          <div className="tw-flex-1">
            <span
              onClick={() => {
                handleButtonGroupSelect('details');
              }}
              aria-hidden="true"
              className="tw-ml-2 tw-flex-1 tw-truncate tw-block tw-font-medium tw-text-alpha-600 tw-cursor-pointer"
            >
              {textTruncate(automation.title, 50)}
            </span>
            {automation.next_step_processing_at ? (
              <span className="tw-ml-2 tw-flex-1 tw-truncate">
                Next Touch Point:{' '}
                {formatTimestamp(
                  automation.next_step_processing_at,
                  currentAccount.timezone,
                  false,
                  'MMMM Do YYYY',
                  true
                )}
              </span>
            ) : null}
          </div>
        </div>
        {automation.paused_at ? (
          <div className="tw-inline-block">
            <Badge
              badgeStyle={{ marginTop: '0' }}
              color="charlie"
              badgeClass="tw-justify-between tw-mb-1"
              hasDot={false}
              onClick={null}
              shape="round"
              size="small"
              textStyle={{}}
              value="Paused"
            />
          </div>
        ) : null}
        <span ref={dropdownButtonRef}>
          <HeroIcon
            icon="dots-vertical"
            color="alpha"
            onClick={toggleDropdown}
          />
        </span>
        <div
          ref={dropdownRef}
          className={`tw-origin-top-right tw-absolute tw-mt-2 tw--mr-1 tw-w-56 tw-rounded-md tw-shadow-lg tw-z-10 ${
            showDropdown ? 'tw-block' : 'tw-hidden'
          }`}
          style={{ top: coords.top, left: coords.left }}
        >
          <div className="tw-rounded-md tw-bg-white tw-shadow-xs">
            <div className="tw-py-1">
              {automationButtonGroupOptions().map(option => (
                <a
                  href="#"
                  key={option.value}
                  data-testid={option.value}
                  onClick={() => {
                    handleButtonGroupSelect(option.value);
                  }}
                  className={`tw-block tw-px-4 tw-py-2 tw-text-sm tw-leading-5 tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900 focus:tw-outline-none focus:tw-bg-gray-100 focus:tw-text-gray-900 ${option.className}`}
                >
                  {option.displayName}
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Progress
        valueNow={automation.completed_step_count}
        valueMin={0}
        valueMax={automation.total_step_count}
        text="steps completed"
        color="gray"
        containerClass="tw-ml-6 tw-h-6 tw-bg-white tw-mb-1"
      />
    </span>
  );
};

const automationShape = {
  journey_id: PropTypes.number,
  contact_id: PropTypes.number,
  product_campaign_id: PropTypes.number,
  next_step_processing_at: PropTypes.string,
  name: PropTypes.string,
  lifecycle: PropTypes.string,
  percentage_complete: PropTypes.number,
  completed_at: PropTypes.string,
  paused_at: PropTypes.string,
  resume_at: PropTypes.string,
  processed_step_data: PropTypes.array,
  created_at: PropTypes.string,
  updated_at: PropTypes.string
};

AutomationListItem.propTypes = {
  automation: PropTypes.shape(automationShape).isRequired,
  handleDetailsButtonClick: PropTypes.func.isRequired,
  handlePauseButtonClick: PropTypes.func.isRequired,
  handleUnpauseButtonClick: PropTypes.func.isRequired,
  handleRemoveButtonClick: PropTypes.func.isRequired
};

export default AutomationListItem;
