// linting rules disabled for immer js
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  FETCH_APPOINTMENT_BOOKER_SUCCESS,
  FETCH_APPOINTMENT_SUCCESS,
  UPDATE_APPOINTMENT_SUCCESS,
  CREATE_APPOINTMENT_FROM_BOOKER_SUCCESS,
  RESET_APPOINTMENT_BOOKER
} from 'appState/actions/constants/appointmentBooker.actions';

const initialState = {
  currentWeekOffset: 0,
  availabilities: {},
  currentAppointmentType: {},
  currentAppointment: {}
};

export default function AppointmentBookerReducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case FETCH_APPOINTMENT_BOOKER_SUCCESS: {
        const {
          availabilities,
          currentWeekOffset,
          currentAppointmentType,
          currentBookingForm,
          currentContact,
          currentAppointment
        } = action.payload;
        draft.availabilities = availabilities;
        draft.currentWeekOffset = currentWeekOffset;
        draft.currentAppointmentType = currentAppointmentType;
        draft.currentBookingForm = currentBookingForm;
        draft.currentContact = currentContact;
        draft.currentAppointment = currentAppointment;
        return;
      }
      case FETCH_APPOINTMENT_SUCCESS: {
        draft.currentAppointment = action.payload.currentAppointment;
        return;
      }

      case UPDATE_APPOINTMENT_SUCCESS: {
        draft.currentAppointment = {};
        return;
      }
      case RESET_APPOINTMENT_BOOKER: {
        draft.currentAppointment = {};
        draft.currentAppointmentType = {};
        return;
      }
      default:
        return draft;
    }
  });
}
