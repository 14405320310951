import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  broadcastTemplateReassign,
  broadcastFetchProducts
} from 'appState/actions/ActionCreators';
import { createStructuredSelector } from 'reselect';
import isEmpty from 'lodash.isempty';
import {
  createErrorMessageSelector,
  createLoadedSelector
} from 'appState/selectors';
import { capitalize } from 'lib/utils/string';

const ReassignTemplateModal = () => {
  const [productId, setProductId] = useState(null);
  const dispatch = useDispatch();

  const errorSelector = createErrorMessageSelector([
    'broadcastCenter/BROADCAST_TEMPLATE_REASSIGN'
  ]);
  const loadedSelector = createLoadedSelector([
    'broadcastCenter/BROADCAST_TEMPLATE_REASSIGN'
  ]);
  const structuredSelector = createStructuredSelector({
    broadcastTemplateId: state =>
      state.broadcastCenter.selectedBroadcastTemplateId,
    products: state => state.broadcastCenter.products,
    error: state => errorSelector(state),
    isLoaded: state => loadedSelector(state)
  });

  const { broadcastTemplateId, products, error, isLoaded } = useSelector(
    structuredSelector
  );

  useEffect(() => {
    if (isLoaded && isEmpty(error)) {
      app.toast('Your broadcast template has been reassigned.', {
        type: 'primary',
        icon: 'fa-floppy-o'
      });
    }
    return () => {
      setProductId(null);
    };
  }, [isLoaded, error]);

  useEffect(() => {
    if (isEmpty(products)) {
      dispatch(broadcastFetchProducts({}));
    }
  }, [dispatch]);

  useEffect(() => {
    if (isLoaded && !isEmpty(error)) {
      app.toast(capitalize(error), {
        type: 'danger',
        icon: 'fa-floppy-o'
      });
    }
    return () => {
      setProductId(null);
    };
  }, [isLoaded, error]);

  const reassignBroadcastTemplate = () => {
    dispatch(broadcastTemplateReassign({ broadcastTemplateId, productId }));
  };

  const productSelectOptions = products.map(product => {
    return (
      <option key={product.id} value={product.id}>
        {product.name}
      </option>
    );
  });

  return (
    <div
      className="modal fade show"
      id="reassign-template-modal"
      tabIndex="-1"
      aria-modal="true"
      aria-hidden="true"
      style={{ display: 'none' }}
    >
      <div className="modal-dialog modal-sm">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="myModalLabel">
              Reassign Broadcast Template
            </h4>
            <button type="button" className="close" data-dismiss="modal">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <p>
              Reassigning a broadcast template to a product will allow everyone
              who has that product to use the template in their own broadcast
              center.
            </p>
            <div className="form-row mt-10">
              <div className="col-lg-12 form-group">
                <label htmlFor="products">Select Product</label>
                {products?.length && (
                  <select
                    onChange={e => setProductId(e.target.value)}
                    id="product-id"
                    className="form-control"
                  >
                    <option value="">Nothing selected</option>
                    {productSelectOptions}
                  </select>
                )}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-danger"
              data-dismiss="modal"
              onClick={() => reassignBroadcastTemplate()}
            >
              Reassign
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReassignTemplateModal;
