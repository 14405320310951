import {
  fetchPartners,
  setOverlordLayoutHeaderText
} from 'appState/actions/ActionCreators';
import Table from 'components/Theme/Table';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Breadcrumb from 'components/Theme/Breadcrumb';
import Breadcrumbs from 'components/Theme/Breadcrumbs';

const PartnersIndex = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setOverlordLayoutHeaderText('Partners'));
    dispatch(fetchPartners());
  }, []);

  const structuredSelector = createStructuredSelector({
    partners: state => state.partner.partners
  });

  const { partners } = useSelector(structuredSelector);

  function viewPartner(id) {
    window.location = `/overlord/partners/${id}`;
  }

  const breadcrumbs = [
    {
      name: 'Overlord',
      action: () => {
        window.location = '/overlord/';
      }
    },
    {
      name: 'Partners',
      action: () => {
        console.log('partners clicked');
      }
    }
  ];

  return (
    <div className="tw-px-4 tw-py-4 sm:tw-px-6">
      <div className="tw-mb-5">
        <div className="tw-mb-5">
          <nav className="sm:tw-hidden">
            <Breadcrumb name="Back" isBackgroundWhite />
          </nav>

          {breadcrumbs && breadcrumbs.length ? (
            <div>
              <nav className="sm:tw-hidden">
                <Breadcrumb name="Back" isBackgroundWhite />
              </nav>
              <Breadcrumbs crumbs={breadcrumbs} isBackgroundWhite />
            </div>
          ) : null}
        </div>
        <div className="tw-mb-5 tw-border-b tw-border-t-0 tw-border-l-0 tw-border-r-0 tw-border-solid tw-border-gray-200" />
      </div>
      <Table
        rows={partners}
        includedColumns={['id', 'name']}
        onEdit={viewPartner}
        editable
        emptyMessage="No partners created in platform yet."
      />
    </div>
  );
};

export default PartnersIndex;
