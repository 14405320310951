import React from 'react';
import PropTypes from 'prop-types';
import BroadcastListItem from '../BroadcastListItem';

const DraftListItem = props => {
  const { broadcast } = props;

  return (
    <BroadcastListItem
      broadcast={broadcast}
      badgeType="secondary"
      timestampAttr={broadcast.updated_at}
      timestampPretext="created "
    />
  );
};

const broadcastShape = {
  updated_at: PropTypes.string
};

DraftListItem.propTypes = {
  broadcast: PropTypes.shape(broadcastShape).isRequired
};

export default DraftListItem;
