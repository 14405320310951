import React, { useState, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash.isempty';
import HeroIcon from 'components/Theme/HeroIcon';
import Link from 'components/Theme/Link';
import Modal from 'components/Theme/Modal';
import Table from 'components/Theme/Table';
import { createLoadedSelector } from 'appState/selectors';
import {
  planUpdate,
  planDelete,
  resetIsLoaded
} from 'appState/actions/ActionCreators';

const DELETE = 'DELETE';
const CANNOT_DELETE = 'CANNOT_DELETE';
const HIDE = 'HIDE';

const modalInitialState = {
  bodyText: '',
  showPrimaryAction: false,
  secondaryActionText: '',
  show: false
};

const modalReducer = (state, action) => {
  switch (action.type) {
    case DELETE: {
      return {
        ...state,
        bodyText: action.bodyText,
        showPrimaryAction: true,
        secondaryActionText: 'Cancel',
        show: true
      };
    }
    case CANNOT_DELETE: {
      return {
        ...state,
        bodyText: action.bodyText,
        showPrimaryAction: false,
        secondaryActionText: 'OK',
        show: true
      };
    }
    case HIDE: {
      return {
        ...state,
        show: false
      };
    }
    default:
      return state;
  }
};

const AccordionContent = ({ tierPlans, tier }) => {
  const [currentPlan, setCurrentPlan] = useState({});
  const [planRows, setPlanRows] = useState(tierPlans);
  const dispatch = useDispatch();
  const history = useHistory();
  const { path } = useRouteMatch();

  const [modal, dispatchModal] = useReducer(modalReducer, modalInitialState);

  const updatePlanLoadedSelector = createLoadedSelector(['plan/UPDATE_PLAN']);

  const structuredSelector = createStructuredSelector({
    isUpdatePlanLoaded: state => updatePlanLoadedSelector(state)
  });

  const { isUpdatePlanLoaded } = useSelector(structuredSelector);

  useEffect(() => {
    if (isUpdatePlanLoaded) dispatch(resetIsLoaded('plan/UPDATE_PLAN'));

    return () => {
      dispatch(resetIsLoaded('plan/UPDATE_PLAN'));
    };
  }, [isUpdatePlanLoaded]);

  const handleToggleClick = (btnRef, isToggle) => {
    if (isToggle) {
      const planParams = {
        tier: btnRef.row.tier,
        planId: btnRef.row.id,
        visible: isToggle
      };
      dispatch(planUpdate(planParams));
      setPlanRows(prevTableRows =>
        prevTableRows.map(tableRow => {
          if (tableRow.id === btnRef.row.id)
            return { ...tableRow, connect: true };
          return { ...tableRow, connect: false };
        })
      );
    }
  };
  const createPlan = currentTier => {
    history.push({ pathname: `${path}/create`, state: { currentTier } });
  };

  const handleEdit = planId => {
    const currentTier = tierPlans.find(plan => plan.id === planId).tier;
    history.push({
      pathname: `${path}/${planId}/edit`,
      state: { currentTier }
    });
  };

  const getPurchaseUrl = value => {
    const purchaseUrlText =
      !isEmpty(value) && typeof value === 'string' ? value : '';
    return (
      <Link
        icon=""
        href={purchaseUrlText}
        target="_blank"
        text={purchaseUrlText}
      />
    );
  };

  function handleDeletePlanAction() {
    dispatch(planDelete({ planId: currentPlan.id }));
  }

  function handleDeletePlan(planId) {
    const plan = planRows?.length ? planRows.find(p => p.id === planId) : null;
    setCurrentPlan(plan);
    if (plan.connect)
      dispatchModal({
        type: CANNOT_DELETE,
        bodyText: 'Cannot delete an active plan!'
      });
    else
      dispatchModal({
        type: DELETE,
        bodyText: `Are you sure you want to delete ${
          plan?.name ? ` the ${plan.name}` : ' this'
        } plan?`
      });
  }

  function handleModalSetShow(show) {
    if (!show) dispatchModal({ type: HIDE });
  }

  return (
    <div>
      <Table
        rows={planRows}
        rowConditionals={value => ({
          max_users: value === 0 ? 'Unlimited' : value,
          purchase_url: getPurchaseUrl(value)
        })}
        hasToggleAction
        onClickToggleBtn={handleToggleClick}
        customActionHeader="Visible"
        includedColumns={['name', 'type', 'max_users', 'purchase_url']}
        editable
        onEdit={handleEdit}
        deletable
        onDelete={handleDeletePlan}
        emptyMessage="No plans created yet"
        hasFooter
        footerAction={() => {
          createPlan(tier);
        }}
        footerActionText="Add New Plan"
      />
      <Modal
        headerText="Delete Plan"
        bodyText={modal.bodyText}
        primaryAction={handleDeletePlanAction}
        primaryActionText="Delete"
        showPrimaryAction={modal.showPrimaryAction}
        showSecondaryAction
        secondaryActionText={modal.secondaryActionText}
        show={modal.show}
        setShow={handleModalSetShow}
        color="error"
        HeaderIcon={color => {
          return (
            <HeroIcon
              icon="exclamation"
              widthClasses="tw-w-6"
              heightClasses="tw-h-6"
              color={color}
            />
          );
        }}
      />
    </div>
  );
};

AccordionContent.defaultProps = {
  tierPlans: [],
  tier: ''
};

AccordionContent.propTypes = {
  tierPlans: PropTypes.arrayOf(PropTypes.object),
  tier: PropTypes.string
};

export default AccordionContent;
