import styled from 'styled-components';

const Spinner = styled.div`
  margin-top: 5px;
  margin-right: 10px;
  height: 15px;
  width: 15px;
`;

const Styled = {
  Spinner
};

export default Styled;
