/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import get from 'lodash.get';
import isEmpty from 'lodash.isempty';
import BroadcastPreviewModal from 'components/BroadcastCenter/Broadcast/BroadcastPreviewModal';
import {
  createErrorMessageSelector,
  createLoadedSelector,
  createAllLoadedSelector
} from 'appState/selectors';
import Chip from 'components/Chip';
import { broadcastTemplateSchema } from 'lib/validation/schema';
import { capitalize } from 'lib/utils/string';
import { buildAudienceFullSummary } from 'lib/utils/audienceSummary';
import {
  broadcastSchedule,
  broadcastFetchAudienceCount,
  broadcastFetchPreview,
  broadcastsFetchCurrentBroadcastTemplate,
  broadcastSaveAsTemplate,
  broadcastUpdateTemplate,
  broadcastsFetchContacts,
  broadcastTemplateSaveDetails,
  resetIsLoaded
} from 'appState/actions/ActionCreators';
import { CurrentUserContext } from 'components/shared/context/CurrentUserContext';
import { CurrentAccountContext } from 'components/shared/context/CurrentAccountContext';
import DatePicker from 'react-datepicker';
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';
import useToggleHeaderFooter from 'components/shared/hooks/useToggleHeaderFooter';
import BroadcastPhonePreview from 'components/BroadcastCenter/Broadcast/BroadcastPhonePreview';
import EditEmailSubjectModal from './EditEmailSubjectModal';
import SendScheduleConfirmationModal from './SendScheduleConfirmationModal';
import Styled from './Schedule.styles';
import BroadcastStep from '../BroadcastStep';
import Card from '../Card';

const Schedule = () => {
  const { path } = useRouteMatch();
  useToggleHeaderFooter(path);
  const dispatch = useDispatch();
  const history = useHistory();
  const currentUser = useContext(CurrentUserContext);
  const currentAccount = useContext(CurrentAccountContext);
  const errorSelector = createErrorMessageSelector([
    'broadcastCenter/BROADCAST_SCHEDULE'
  ]);
  const loadedSelector = createLoadedSelector([
    'broadcastCenter/BROADCAST_SCHEDULE'
  ]);
  const loadedTemplatePageDependenciesSelector = createAllLoadedSelector([
    'broadcastCenter/BROADCAST_FETCH_AUDIENCE_COUNT',
    'broadcastCenter/BROADCAST_FETCH_PREVIEW'
  ]);
  const loadedBroadcastPageDependenciesSelector = createAllLoadedSelector([
    'broadcastCenter/BROADCAST_FETCH_AUDIENCE_COUNT',
    'broadcastCenter/BROADCASTS_FETCH_CURRENT_BROADCAST_TEMPLATE',
    'broadcastCenter/BROADCAST_FETCH_PREVIEW'
  ]);
  const errorBroadcastTemplateCreateSelector = createErrorMessageSelector([
    'broadcastCenter/BROADCAST_SAVE_AS_TEMPLATE'
  ]);
  const loadedBroadcastTemplateCreateSelector = createLoadedSelector([
    'broadcastCenter/BROADCAST_SAVE_AS_TEMPLATE'
  ]);
  const errorBroadcastUpdateTemplateSelector = createErrorMessageSelector([
    'broadcastCenter/BROADCAST_UPDATE_TEMPLATE'
  ]);
  const loadedBroadcastUpdateTemplateSelector = createLoadedSelector([
    'broadcastCenter/BROADCAST_UPDATE_TEMPLATE'
  ]);

  const structuredSelector = createStructuredSelector({
    currentBroadcast: state => state.broadcastCenter.currentBroadcast,
    currentBroadcastTemplate: state =>
      state.broadcastCenter.currentBroadcastTemplate,
    audienceCount: state => state.broadcastCenter.audienceCount,
    contacts: state => state.broadcastCenter.contacts,
    emailBody: state => state.broadcastCenter.previewEmailBody,
    smsBody: state => state.broadcastCenter.previewSmsBody,
    smsMedia: state => state.broadcastCenter.previewSmsMedia,
    isTemplate: state => state.broadcastCenter.isTemplate,
    error: state => errorSelector(state),
    isLoaded: state => loadedSelector(state),
    isTemplatePageDependenciesLoaded: state =>
      loadedTemplatePageDependenciesSelector(state),
    isBroadcastPageDependenciesLoaded: state =>
      loadedBroadcastPageDependenciesSelector(state),
    errorTemplateCreated: state => errorBroadcastTemplateCreateSelector(state),
    isTemplateCreateLoaded: state =>
      loadedBroadcastTemplateCreateSelector(state),
    errorTemplateUpdated: state => errorBroadcastUpdateTemplateSelector(state),
    isTemplateUpdateLoaded: state =>
      loadedBroadcastUpdateTemplateSelector(state)
  });

  const {
    currentBroadcast,
    currentBroadcastTemplate,
    audienceCount,
    contacts,
    emailBody,
    smsBody,
    smsMedia,
    isTemplate,
    error,
    isLoaded,
    isTemplatePageDependenciesLoaded,
    isBroadcastPageDependenciesLoaded,
    errorTemplateCreated,
    isTemplateCreateLoaded,
    errorTemplateUpdated,
    isTemplateUpdateLoaded
  } = useSelector(structuredSelector);

  const nextQuarterHour = new Date(
    Math.ceil(new Date().getTime() / 900000) * 900000
  );
  const [sendingType, setSendingType] = useState('send_now');
  const [scheduleForDateTime, setScheduleForDateTime] = useState(
    currentBroadcast.schedule_for || nextQuarterHour
  );
  const [audienceSummary, setAudienceSummary] = useState('');
  const [overwriteExisting, setOverwriteExisting] = useState(false);

  useEffect(() => {
    if (isLoaded && isEmpty(error)) {
      let message;
      if (sendingType === 'send_now') {
        message = 'Your broadcast has been sent successfully.';
      } else if (sendingType === 'schedule') {
        message = 'Your broadcast has been scheduled successfully.';
      } else if (sendingType === 'save_as_template') {
        message = 'Your broadcast has been saved as a template successfully.';
      }
      app.toast(message, {
        type: 'primary',
        icon: 'fa-floppy-o'
      });
    }
    if (isLoaded && !isEmpty(error)) {
      app.toast(capitalize(error), {
        type: 'danger',
        icon: 'fa-floppy-o'
      });
    }

    return () => {
      dispatch(resetIsLoaded('broadcastCenter/BROADCAST_SCHEDULE'));
    };
  }, [isLoaded, error]);

  useEffect(() => {
    if (isTemplateCreateLoaded && isEmpty(errorTemplateCreated)) {
      app.toast('Your broadcast template was created successfully.', {
        type: 'primary',
        icon: 'fa-floppy-o'
      });
    }
    if (isTemplateCreateLoaded && !isEmpty(errorTemplateCreated)) {
      app.toast(capitalize(errorTemplateCreated), {
        type: 'danger',
        icon: 'fa-floppy-o'
      });
    }

    return () => {
      dispatch(resetIsLoaded('broadcastCenter/BROADCAST_SAVE_AS_TEMPLATE'));
    };
  }, [isTemplateCreateLoaded, errorTemplateCreated]);

  useEffect(() => {
    if (isTemplateUpdateLoaded && isEmpty(errorTemplateUpdated)) {
      app.toast('Your broadcast template was updated successfully.', {
        type: 'primary',
        icon: 'fa-floppy-o'
      });
    }
    if (isTemplateUpdateLoaded && !isEmpty(errorTemplateUpdated)) {
      app.toast(capitalize(errorTemplateUpdated), {
        type: 'danger',
        icon: 'fa-floppy-o'
      });
    }

    return () => {
      dispatch(resetIsLoaded('broadcastCenter/BROADCAST_UPDATE_TEMPLATE'));
    };
  }, [isTemplateUpdateLoaded, errorTemplateUpdated]);

  useEffect(() => {
    if (!isEmpty(currentBroadcast)) {
      dispatch(
        broadcastFetchAudienceCount({
          audienceId: currentBroadcast.audience.id,
          filterIds: currentBroadcast.audience.filters.map(f => f.id)
        })
      );
    }
  }, [dispatch, currentBroadcast]);

  useEffect(() => {
    if (!isEmpty(currentBroadcast) && currentBroadcast.broadcast_template_id) {
      dispatch(
        broadcastsFetchCurrentBroadcastTemplate({
          broadcastTemplateId: currentBroadcast.broadcast_template_id
        })
      );
    }
  }, [dispatch, currentBroadcast]);

  useEffect(() => {
    if (!isEmpty(currentBroadcast)) {
      dispatch(
        broadcastsFetchContacts({
          contactIds: currentBroadcast.audience.manually_selected_contact_ids
        })
      );
    }
  }, [dispatch, currentBroadcast]);

  useEffect(() => {
    if (currentBroadcast) {
      const fullAudienceSummary = buildAudienceFullSummary(currentBroadcast);
      setAudienceSummary(fullAudienceSummary);
    }
  }, [currentBroadcast]);

  function scheduleBroadcast(e, overrideNavigation = false) {
    const params = {
      broadcastId: currentBroadcast.id,
      currentUserId: currentUser.id,
      scheduleForDateTime,
      sendingType,
      history,
      overrideNavigation
    };

    dispatch(broadcastSchedule(params));
  }

  function saveNewTemplate(title, description) {
    dispatch(
      broadcastSaveAsTemplate({
        broadcastId: currentBroadcast.id,
        title,
        description,
        createdFromSchedule: true
      })
    );
  }

  function updateTemplate(title, description) {
    dispatch(
      broadcastUpdateTemplate({
        broadcastId: currentBroadcast.id,
        broadcastTemplateId: currentBroadcastTemplate.id,
        title,
        description
      })
    );
  }

  function saveTemplateDetails(title, description) {
    dispatch(
      broadcastTemplateSaveDetails({
        templateTitle: title,
        templateDescription: description,
        history
      })
    );
  }

  const getBroadcastOrTemplateRoute = isTemplate
    ? 'broadcast-templates'
    : 'broadcast-center';

  function handleRedirectToSelectAudience() {
    history.push(
      `/ui/${getBroadcastOrTemplateRoute}/${currentBroadcast.id}/select-audience`
    );
  }

  function handleRedirectToContent() {
    if (currentBroadcast.template_type === 'Email') {
      history.push(
        `/ui/${getBroadcastOrTemplateRoute}/${currentBroadcast.id}/email`
      );
    } else {
      history.push(
        `/ui/${getBroadcastOrTemplateRoute}/${currentBroadcast.id}/sms`
      );
    }
  }

  useEffect(() => {
    let previewFetched = false;
    if (
      get(currentBroadcast, 'id') &&
      get(currentUser, 'id') &&
      !previewFetched
    ) {
      dispatch(
        broadcastFetchPreview({
          broadcastId: currentBroadcast.id,
          userId: currentUser.id
        })
      );
      previewFetched = true;
    }
  }, [dispatch, currentBroadcast, currentUser]);

  const formik = useFormik({
    initialValues: {
      templateTitle:
        overwriteExisting && !isEmpty(currentBroadcastTemplate)
          ? currentBroadcastTemplate.title || ''
          : (overwriteExisting || isTemplate) &&
            !isEmpty(currentBroadcast.title)
          ? currentBroadcast.title
          : '',
      templateDescription:
        overwriteExisting && !isEmpty(currentBroadcastTemplate)
          ? currentBroadcastTemplate.description || ''
          : (overwriteExisting || isTemplate) &&
            !isEmpty(currentBroadcast.description)
          ? currentBroadcast.description
          : ''
    },
    enableReinitialize: true,
    validationSchema: broadcastTemplateSchema,
    onSubmit: values => {
      return isTemplate
        ? saveTemplateDetails(values.templateTitle, values.templateDescription)
        : !isEmpty(currentBroadcastTemplate) && overwriteExisting
        ? updateTemplate(values.templateTitle, values.templateDescription)
        : saveNewTemplate(values.templateTitle, values.templateDescription);
    }
  });

  const isPageDependenciesLoaded = isTemplate
    ? isTemplatePageDependenciesLoaded
    : isBroadcastPageDependenciesLoaded;

  return (
    <div>
      <BroadcastStep
        sendingType={sendingType}
        saveAction={isTemplate ? formik.handleSubmit : null}
      >
        <div className="col-md-12 col-lg-5">
          {!isTemplate && (
            <Card
              title="Send Options"
              subtitle="When should we send this?"
              containerStyle={{
                marginBottom: '20px',
                marginRight: '0px',
                marginLeft: '0px',
                paddingLeft: '0px',
                paddingRight: '0px'
              }}
            >
              {isPageDependenciesLoaded ? (
                <div>
                  {audienceCount === 0 && sendingType !== 'save_as_template'
                    ? setSendingType('save_as_template')
                    : null}
                  {audienceCount === 0 ? (
                    <div className="row">
                      <div className="mx-15 alert alert-warning" role="alert">
                        Sending or scheduling a broadcast requires an audience
                        with at least one contact. If you would like to send or
                        schedule your broadcast please{' '}
                        <a
                          href="#"
                          className="text-info"
                          onClick={handleRedirectToSelectAudience}
                        >
                          click here
                        </a>{' '}
                        to update your audience.
                      </div>
                    </div>
                  ) : null}
                  <div className="form-check">
                    <input
                      type="radio"
                      name="sending-type"
                      id="send-now"
                      value="send_now"
                      onChange={e => setSendingType(e.target.value)}
                      checked={sendingType === 'send_now'}
                      disabled={audienceCount === 0}
                    />
                    <Styled.Label
                      className="form-check-label cursor-pointer"
                      htmlFor="send-now"
                      disabled={audienceCount === 0}
                    >
                      Send it now
                    </Styled.Label>
                  </div>
                  <div className="form-check">
                    <input
                      type="radio"
                      name="sending-type"
                      id="schedule"
                      value="schedule"
                      onChange={e => setSendingType(e.target.value)}
                      checked={sendingType === 'schedule'}
                      disabled={audienceCount === 0}
                    />
                    <Styled.Label
                      className="form-check-label cursor-pointer"
                      htmlFor="schedule"
                      disabled={audienceCount === 0}
                    >
                      Schedule it for later{' '}
                      {currentBroadcast.template_type === 'SMS'
                        ? '(Between 9am - 8.45pm only)'
                        : null}
                    </Styled.Label>
                  </div>
                  <div>
                    {sendingType === 'schedule' ? (
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <div
                              className="mb-20"
                              id="schedule-broadcast-datetime-wrapper"
                            >
                              {currentBroadcast.template_type}
                              <DatePicker
                                selected={scheduleForDateTime}
                                onChange={date => setScheduleForDateTime(date)}
                                className="form-control"
                                minDate={new Date()}
                                showTimeSelect
                                minTime={
                                  currentBroadcast.template_type === 'SMS'
                                    ? setHours(setMinutes(new Date(), 0), 9)
                                    : setHours(setMinutes(new Date(), 0), 0)
                                }
                                maxTime={
                                  currentBroadcast.template_type === 'SMS'
                                    ? setHours(setMinutes(new Date(), 45), 20)
                                    : setHours(setMinutes(new Date(), 45), 23)
                                }
                                dateFormat="MMMM d, yyyy h:mm aa"
                                shouldCloseOnSelect={false}
                                timeIntervals={15}
                                inline
                                tabIndex={-1}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null}
            </Card>
          )}
          <Card
            title={isTemplate ? 'Save Details' : 'Save as Template'}
            subtitle={
              isTemplate
                ? 'What should this template be called?'
                : 'Create a reusable starting point'
            }
            containerStyle={{
              marginBottom: '20px',
              marginRight: '0px',
              marginLeft: '0px',
              paddingLeft: '0px',
              paddingRight: '0px'
            }}
          >
            {!isEmpty(currentBroadcastTemplate) &&
            !currentBroadcastTemplate.product_default ? (
              <div>
                <div
                  className={`alert alert-${
                    overwriteExisting ? 'warning' : 'info'
                  } alert-dismissible fade show d-flex`}
                  role="alert"
                  style={{
                    marginTop: '-20px',
                    marginRight: '-20px',
                    marginLeft: '-20px'
                  }}
                >
                  {overwriteExisting
                    ? `This will overwrite your existing${
                        currentBroadcastTemplate.title
                          ? ` ${currentBroadcastTemplate.title}`
                          : ''
                      }
                          ${
                            currentBroadcastTemplate &&
                            currentBroadcastTemplate.title &&
                            currentBroadcastTemplate.title
                              .toLowerCase()
                              .endsWith('template')
                              ? ''
                              : ' template'
                          }. Overwriting will delete your existing template and replace it with this one. You can use this new template as a starting point when creating a new ${
                        currentBroadcast.template_type
                      } Broadcast.`
                    : `Save this broadcast as a resuable template. You will be able to use it as a starting point when creating a new ${currentBroadcast.template_type} Broadcast.`}
                </div>
                <p>
                  This broadcast was created using the{' '}
                  {currentBroadcastTemplate.title}
                  {currentBroadcastTemplate &&
                  currentBroadcastTemplate.title &&
                  currentBroadcastTemplate.title
                    .toLowerCase()
                    .endsWith('template')
                    ? ''
                    : ' template'}
                  .
                </p>
                <div className="form-check">
                  <input
                    type="checkbox"
                    name="overwrite-existing-template"
                    id="overwrite-existing-template"
                    value={overwriteExisting}
                    onChange={() => setOverwriteExisting(!overwriteExisting)}
                    checked={overwriteExisting}
                  />
                  <Styled.Label
                    className="form-check-label cursor-pointer"
                    htmlFor="overwrite-existing-template"
                  >
                    Overwrite existing template?
                  </Styled.Label>
                </div>
              </div>
            ) : null}
            <div className="form-group">
              <label htmlFor="templateTitle">Template Title</label>
              <input
                type="text"
                className={`form-control required ${
                  formik.touched.templateTitle && formik.errors.templateTitle
                    ? 'is-invalid'
                    : ''
                }`}
                placeholder="Template Title"
                id="templateTitle"
                name="templateTitle"
                onChange={formik.handleChange}
                value={formik.values.templateTitle}
              />
              {formik.touched.templateTitle && formik.errors.templateTitle ? (
                <span className="invalid-feedback d-block">
                  {formik.errors.templateTitle}
                </span>
              ) : null}
            </div>
            <div className="form-group">
              <label htmlFor="templateDescription">Template Description</label>
              <input
                type="text"
                className={`form-control required ${
                  formik.touched.templateDescription &&
                  formik.errors.templateDescription
                    ? 'is-invalid'
                    : ''
                }`}
                placeholder="Template Description"
                id="templateDescription"
                name="templateDescription"
                onChange={formik.handleChange}
                value={formik.values.templateDescription}
              />
              {formik.touched.templateDescription &&
              formik.errors.templateDescription ? (
                <span className="invalid-feedback d-block">
                  {formik.errors.templateDescription}
                </span>
              ) : null}
            </div>
            {!isTemplate && (
              <a
                href="#"
                className="btn btn-secondary float-right"
                onClick={formik.handleSubmit}
              >
                {overwriteExisting && currentBroadcastTemplate
                  ? 'Update Template'
                  : 'Save Template'}
              </a>
            )}
          </Card>
        </div>
        <div className="col-md-12 col-lg-7 mb-40">
          <Card
            title={`${isTemplate ? 'Template' : 'Broadcast'} Summary`}
            containerStyle={{
              marginRight: '0px',
              marginLeft: '0px',
              paddingLeft: '0px',
              paddingRight: '0px'
            }}
          >
            {isPageDependenciesLoaded ? (
              <div>
                {currentBroadcast.template_type === 'Email' ? (
                  <div className="d-block">
                    <h6 className="d-inline">Subject:</h6>{' '}
                    {currentBroadcast.email_subject}
                    <a
                      href="#"
                      className="text-info ml-10"
                      data-toggle="modal"
                      data-target="#edit-email-subject-modal"
                    >
                      Edit
                    </a>
                  </div>
                ) : null}

                <div className="d-block mb-40">
                  <h6 className="d-inline">From:</h6> {currentUser.full_name} (
                  {currentBroadcast.template_type === 'Email'
                    ? currentUser.email
                    : currentAccount.formatted_phone}
                  )
                </div>
                <div className="d-block mb-40">
                  <h6 className="d-inline">Selected Audience</h6>
                  <a
                    href="#"
                    className="text-info ml-10"
                    onClick={handleRedirectToSelectAudience}
                  >
                    Edit
                  </a>
                  <p className="mt-20">
                    {currentBroadcast.template_type === 'Email'
                      ? 'Email'
                      : 'SMS'}
                    {' will be sent to '}
                    <strong>{audienceCount} contacts</strong>
                  </p>
                  {currentBroadcast?.audience?.include_staff_members && (
                    <p>
                      {currentBroadcast.template_type === 'Email'
                        ? 'Email'
                        : 'SMS'}
                      {' will be sent to staff members'}
                    </p>
                  )}
                  {currentBroadcast.audience &&
                    currentBroadcast.audience.exclude_paused_contacts && (
                      <p className="mt-20">
                        Email will not be sent to paused contacts
                      </p>
                    )}
                  {currentBroadcast.select_audience_by === 'searching' &&
                  !isEmpty(contacts) ? (
                    <div className="d-flex flex-wrap">
                      {contacts.map(contact => {
                        return (
                          <Chip text={contact.full_name} key={contact.id} />
                        );
                      })}
                    </div>
                  ) : (
                    <div>{audienceSummary}</div>
                  )}
                </div>
                <div className="d-block">
                  <h6 className="d-inline">Content Preview</h6>
                  <a
                    href="#"
                    className="text-info ml-10"
                    onClick={handleRedirectToContent}
                  >
                    Edit
                  </a>
                  <a
                    href="#"
                    data-toggle="modal"
                    data-target="#show-preview-modal"
                    className="text-info ml-10"
                  >
                    View Full Preview
                  </a>
                </div>
                <Styled.PhoneContentContainer>
                  <BroadcastPhonePreview
                    emailBody={emailBody}
                    smsBody={smsBody}
                    smsMedia={smsMedia}
                    isEmail={currentBroadcast.template_type === 'Email'}
                    phoneScale={1.5}
                  />
                </Styled.PhoneContentContainer>

                <SendScheduleConfirmationModal
                  saveAction={scheduleBroadcast}
                  sendingType={sendingType}
                  scheduleForDateTime={scheduleForDateTime}
                  templateType={currentBroadcast.template_type}
                  audienceCount={audienceCount}
                />
                <EditEmailSubjectModal />
                <BroadcastPreviewModal />
              </div>
            ) : null}
          </Card>
        </div>
      </BroadcastStep>
    </div>
  );
};

export default Schedule;
