/* eslint-disable import/prefer-default-export */
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  FETCH_BACKGROUNDJOB_DATA_REQUEST,
  FETCH_BACKGROUNDJOB_DATA_SUCCESS,
  FETCH_BACKGROUNDJOB_DATA_FAILURE
} from 'app-state/actions/constants/backGroundJob.actions';
import { axiosDefault } from 'lib/utils/axios-setup';

function* fetchBackGroundJob({ payload: { backGroundJobUrl } = {} }) {
  try {
    const response = yield call(
      axiosDefault.get,
      `${backGroundJobUrl}sidekiq/stats`
    );
    yield put({
      type: FETCH_BACKGROUNDJOB_DATA_SUCCESS,
      payload: {
        busy: response?.data?.sidekiq?.busy,
        dead: response?.data?.sidekiq?.dead,
        default_latency: response?.data?.sidekiq?.default_latency,
        enqueued: response?.data?.sidekiq?.enqueued,
        failed: response?.data?.sidekiq?.failed,
        processed: response?.data?.sidekiq?.processed,
        processes: response?.data?.sidekiq?.processes,
        retries: response?.data?.sidekiq?.retries,
        scheduled: response?.data?.sidekiq?.scheduled
      }
    });
  } catch (e) {
    yield put({
      type: FETCH_BACKGROUNDJOB_DATA_FAILURE,
      payload: { error: e, fallbackError: 'Error loading optinform' },
      error: true
    });
  }
}

export function* fetchBackGroundJobData() {
  yield takeLatest(FETCH_BACKGROUNDJOB_DATA_REQUEST, fetchBackGroundJob);
}
