import React, { useState } from 'react';
import PropTypes from 'prop-types';
import HeroIcon from '../HeroIcon';

const Badge = ({
  value,
  badgeStyle,
  badgeClass,
  containerStyle,
  textStyle,
  color,
  size,
  shape,
  clickable,
  onClick,
  deletable,
  onDelete,
  hasDot,
  icon,
  tint,
  bgTint,
  txTint
}) => {
  const [show, setShow] = useState(true);

  function handleDelete(...args) {
    setShow(false);

    if (deletable && onDelete) onDelete(args);
  }

  function handleClick(...args) {
    if (clickable && onClick) onClick(args);
  }

  function getBadgePaddingX() {
    if (shape === 'round') {
      if (size === 'large') {
        return 'tw-px-3';
      }
      // size === 'small'
      return 'tw-px-2.5';
    }
    // shape === 'rectangle'
    if (size === 'large') {
      return 'tw-px-2.5';
    }
    // size === 'small'
    return 'tw-px-2';
  }

  function getBadgeRoundedClass() {
    if (shape === 'round') {
      return 'tw-rounded-full';
    }
    // shape === 'rectangle'
    if (size === 'large') {
      return 'tw-rounded-md';
    }
    // size === 'small'
    return 'tw-rounded';
  }

  function getDotMarginLeft() {
    if (shape === 'round') {
      if (size === 'large') {
        return 'tw--ml-1';
      }
      // size === 'small'
      return 'tw--ml-1';
    }
    // shape === 'rectangle'
    if (size === 'large') {
      return 'tw--ml-0.5';
    }
    // size === 'small'
    return '';
  }

  function getIconMarginLeft() {
    if (shape === 'round') {
      if (size === 'large') {
        return 'tw--ml-1.5';
      }
      // size === 'small'
      return 'tw--ml-1.5';
    }
    // shape === 'rectangle'
    if (size === 'large') {
      return 'tw--ml-1';
    }
    // size === 'small'
    return '';
  }

  function getHeight() {
    if (size === 'large') {
      return '5';
    }
    return '4';
  }

  function getWidth() {
    if (size === 'large') {
      return '5';
    }
    return '4';
  }

  return (
    <span style={containerStyle}>
      {show && (
        <span
          style={badgeStyle}
          className={`tw-font-body tw-inline-flex tw-items-center ${getBadgePaddingX()} tw-py-0.5 ${getBadgeRoundedClass()} tw-text-${
            size === 'large' ? 'sm' : 'xs'
          } tw-font-medium tw-leading-${
            size === 'large' ? '5' : '4'
          } tw-bg-${color}-${bgTint} tw-text-${color}-${txTint} ${badgeClass}`}
          onClick={handleClick}
          onKeyDown={null}
          role="button"
          tabIndex={-1}
        >
          {hasDot && (
            <svg
              className={`${getDotMarginLeft()} tw-mr-1.5 tw-h-2 tw-w-2 tw-text-${color}-400`}
              fill="currentColor"
              viewBox="0 0 8 8"
            >
              <circle cx="4" cy="4" r="3" />
            </svg>
          )}

          {!hasDot && icon && (
            <span
              className={`tw-mr-1 ${getIconMarginLeft()} tw-flex tw-items-center`}
            >
              <HeroIcon
                icon={icon}
                color={color}
                tint={tint}
                height={getHeight()}
                width={getWidth()}
              />
            </span>
          )}
          <span style={textStyle}>{value}</span>
          {deletable && (
            <span
              data-testid={`badge-delete-${value}`}
              className={`tw-flex-shrink-0${
                size === 'large' ? ' tw-mr-0.5' : ''
              } tw-cursor-pointer tw-ml-1.5 tw-inline-flex tw-text-${color}-500 focus:tw-outline-none focus:tw-text-${color}-700`}
              onClick={handleDelete}
              onKeyDown={null}
              role="button"
              tabIndex={-1}
            >
              <svg
                className="tw-h-2 tw-w-2"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 8 8"
              >
                <path
                  strokeLinecap="round"
                  strokeWidth="1.5"
                  d="M1 1l6 6m0-6L1 7"
                />
              </svg>
            </span>
          )}
        </span>
      )}
    </span>
  );
};

Badge.defaultProps = {
  value: '',
  badgeStyle: {},
  badgeClass: '',
  containerStyle: {},
  textStyle: {},
  color: 'alpha',
  size: 'large',
  shape: 'round',
  clickable: false,
  onClick: null,
  deletable: false,
  onDelete: null,
  hasDot: false,
  icon: null,
  tint: 700,
  bgTint: 100,
  txTint: 800
};

const styleProps = PropTypes.objectOf(
  PropTypes.oneOfType([PropTypes.string, PropTypes.number])
);

Badge.propTypes = {
  value: PropTypes.string,
  badgeStyle: styleProps,
  badgeClass: PropTypes.string,
  containerStyle: styleProps,
  textStyle: styleProps,
  color: PropTypes.oneOf([
    'alpha',
    'bravo',
    'charlie',
    'success',
    'warning',
    'error',
    'gray'
  ]),
  size: PropTypes.oneOf(['small', 'large']),
  shape: PropTypes.oneOf(['round', 'rectangle']),
  clickable: PropTypes.bool,
  onClick: PropTypes.func,
  deletable: PropTypes.bool,
  onDelete: PropTypes.func,
  hasDot: PropTypes.bool,
  icon: PropTypes.node,
  tint: PropTypes.number,
  bgTint: PropTypes.number,
  txTint: PropTypes.number
};

export default Badge;
