import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import EmailPreviewModal from 'components/Theme/ActivityFeed/EmailPreviewModal';
import SmsPreviewModal from './SmsPreviewModal';

const NextCampaignTouchpointModal = ({ nextTouchpointData, show, setShow }) => {
  const [isEmail, setIsEmail] = useState(false);
  const [decoratedTouchpointData, setDecoratedTouchpointData] = useState({});

  useEffect(() => {
    if (nextTouchpointData) {
      setIsEmail(nextTouchpointData?.email?.subject);
      setDecoratedTouchpointData({
        email: {
          email_subject: nextTouchpointData.email?.subject,
          email_body: nextTouchpointData.email?.body
        },
        sms: nextTouchpointData.sms
      });
    }
  }, [nextTouchpointData]);

  return (
    <Fragment>
      {isEmail ? (
        <EmailPreviewModal
          activeItem={decoratedTouchpointData?.email}
          show={show}
          setShow={setShow}
        />
      ) : (
        <SmsPreviewModal
          smsBody={nextTouchpointData.sms?.body}
          // eslint-disable-next-line camelcase
          smsMedia={nextTouchpointData.sms?.media_urls?.[0]}
          show={show}
          setShow={setShow}
          phoneScale={1.5}
        />
      )}
    </Fragment>
  );
};

NextCampaignTouchpointModal.defaultProps = {
  nextTouchpointData: {},
  show: false,
  setShow: () => null
};

const smsShape = {
  body: PropTypes.string,
  media_urls: PropTypes.arrayOf(PropTypes.object)
};

const emailShape = {
  subject: PropTypes.string,
  body: PropTypes.string
};

const nextTouchpointShape = {
  sms: PropTypes.shape(smsShape),
  email: PropTypes.shape(emailShape)
};

NextCampaignTouchpointModal.propTypes = {
  nextTouchpointData: PropTypes.shape(nextTouchpointShape),
  show: PropTypes.bool,
  setShow: PropTypes.func
};

export default NextCampaignTouchpointModal;
