import React, {
  useContext,
  useMemo,
  useEffect,
  useState,
  Fragment
} from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash.get';
import isEmpty from 'lodash.isempty';
import {
  createErrorMessageSelector,
  createLoadedSelector
} from 'app-state/selectors';
import {
  CREATE_JOURNEY,
  UPDATE_JOURNEY
} from 'app-state/actions/constants/journey.actions';
import { UsersContext } from 'components/shared/context/UsersContext';
import { CurrentUserContext } from 'components/shared/context/CurrentUserContext';
import SummarizedView from '../shared/SummarizedView';
import * as StepConstants from '../shared/StepConstants';
import {
  flashErrorMessage,
  resetIsLoaded
} from 'app-state/actions/ActionCreators';
import { formikShape } from 'lib/utils/prop-types-extensions';

const StepThree = ({ product, data, handleSuccessRedirect }) => {
  const dispatch = useDispatch();
  const { users } = useContext(UsersContext);
  const currentUser = useContext(CurrentUserContext);
  const createJourneyErrorSelector = createErrorMessageSelector([
    CREATE_JOURNEY
  ]);
  const createJourneyLoadedSelector = createLoadedSelector([CREATE_JOURNEY]);
  const updateJourneyErrorSelector = createErrorMessageSelector([
    UPDATE_JOURNEY
  ]);
  const updateJourneyLoadedSelector = createLoadedSelector([UPDATE_JOURNEY]);
  const structuredSelector = createStructuredSelector({
    createJourneyError: state => createJourneyErrorSelector(state),
    isCreateJourneyLoaded: state => createJourneyLoadedSelector(state),
    updateJourneyError: state => updateJourneyErrorSelector(state),
    isUpdateJourneyLoaded: state => updateJourneyLoadedSelector(state)
  });
  const {
    createJourneyError,
    isCreateJourneyLoaded,
    updateJourneyError,
    isUpdateJourneyLoaded
  } = useSelector(structuredSelector);

  useEffect(() => {
    if (isCreateJourneyLoaded) {
      if (isEmpty(createJourneyError)) handleSuccessRedirect();
      else if (!isEmpty(createJourneyError)) {
        dispatch(flashErrorMessage(createJourneyError));
      }
    }
    dispatch(resetIsLoaded(CREATE_JOURNEY));
    return () => {
      dispatch(resetIsLoaded(CREATE_JOURNEY));
    };
  }, [isCreateJourneyLoaded, createJourneyError]);

  useEffect(() => {
    if (isUpdateJourneyLoaded) {
      if (isEmpty(updateJourneyError)) handleSuccessRedirect();
      else if (!isEmpty(updateJourneyError)) {
        dispatch(flashErrorMessage(updateJourneyError));
      }
    }
    dispatch(resetIsLoaded(UPDATE_JOURNEY));
    return () => {
      dispatch(resetIsLoaded(UPDATE_JOURNEY));
    };
  }, [isUpdateJourneyLoaded, createJourneyError]);

  const staffId =
    data.assignedStaff === StepConstants.ASSIGNED_STAFF
      ? currentUser.id
      : data.assignedStaffId;
  const assignedStaffName = useMemo(
    () => get(users.find(user => user.id == staffId), 'full_name'),
    [users, staffId]
  );

  let bookedStaffValue;
  switch (data.bookedStaff) {
    case StepConstants.ANY_ASSIGNED_STAFF:
      bookedStaffValue = `ANY of the staff members assigned to your "${data.appointmentTypeName}" appointment type`;
      break;
    case StepConstants.ANY_ASSIGNED_STAFF_REASSIGN:
      bookedStaffValue = `ANY of the staff members assigned to your "${data.appointmentTypeName}" appointment type, AND reassign the contact to the staff member handling the appointment`;
      break;
    default:
      bookedStaffValue = 'Only their assigned staff member';
      break;
  }

  const summarizedSteps = [
    { title: 'Name', value: data.name },
    {
      title: 'Appointment Type',
      value: data.hasAppointment ? data.appointmentTypeName : 'Not Required'
    }
  ];

  if (product.lifecycles && product.lifecycles.includes('lead')) {
    summarizedSteps.splice(2, 0, {
      title: 'Landing Page Title',
      value: data.landingPageTitle
    });
    summarizedSteps.splice(3, 0, {
      title: 'Landing Page Button CTA',
      value: data.landingPageCta
    });
    summarizedSteps.splice(4, 0, {
      title: 'Leads Assigned To',
      value: assignedStaffName
    });
    if (data.hasAppointment)
      summarizedSteps.push({
        title: 'Leads Can Book with',
        value: bookedStaffValue
      });
  }
  return (
    <Fragment>
      <SummarizedView title="Launch Product" steps={summarizedSteps} />
    </Fragment>
  );
};

StepThree.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string,
    product_lifecycle: PropTypes.string,
    lifecycles: PropTypes.array
  }).isRequired,
  data: PropTypes.shape({
    appointmentTypeName: PropTypes.string,
    assignedStaff: PropTypes.string,
    assignedStaffId: PropTypes.string,
    bookedStaff: PropTypes.string,
    cta: PropTypes.string,
    currentAppointmentTypeId: PropTypes.string,
    description: PropTypes.string,
    campaignOwnerId: PropTypes.string,
    landingPageTitle: PropTypes.string,
    launchTime: PropTypes.string,
    name: PropTypes.string,
    hasAppointment: PropTypes.bool
  }).isRequired,
  handleSuccessRedirect: PropTypes.func.isRequired
};

export default StepThree;
