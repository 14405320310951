import React, { useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import ReactTags from 'react-tag-autocomplete';
import { createCustomGroups } from 'appState/actions/ActionCreators';
import { CurrentAccountContext } from 'components/shared/context/CurrentAccountContext';
import Badge from 'components/Theme/Badge';
import Modal from 'components/Theme/Modal';

const AddNewCustomGroupModal = ({ show, setShow, customGroups }) => {
  const [localCustomGroups, setLocalCustomGroups] = useState([]);
  const currentAccount = useContext(CurrentAccountContext);
  const dispatch = useDispatch();

  const formattedCustomGroups = customGroups.map(cg => {
    return { ...cg, disabled: true };
  });

  function handleOnCustomGroupDelete(i) {
    const groups = localCustomGroups.slice(0);
    groups.splice(i, 1);
    setLocalCustomGroups(groups);
  }

  function handleOnCustomGroupAdd(group) {
    setLocalCustomGroups([...localCustomGroups, group]);
  }

  function selectedCustomGroupBadge({ tag, onDelete }) {
    return (
      <Badge
        color="alpha"
        badgeClass="tw-mr-2"
        deletable
        hasDot={false}
        onClick={null}
        onDelete={onDelete}
        shape="round"
        size="large"
        textStyle={{}}
        value={tag.name}
      />
    );
  }

  function handleOnSaveClick() {
    dispatch(
      createCustomGroups({
        customGroups: localCustomGroups
      })
    );
    setLocalCustomGroups([]);
  }

  return (
    <Modal
      headerText="Add New Custom Groups"
      show={show}
      setShow={setShow}
      primaryAction={handleOnSaveClick}
      showHeaderIcon={false}
      showSecondaryAction
    >
      <ReactTags
        tags={localCustomGroups}
        suggestions={formattedCustomGroups}
        onDelete={handleOnCustomGroupDelete}
        onAddition={handleOnCustomGroupAdd}
        allowBackspace={false}
        allowNew
        noSuggestionsText="Click enter and then save to add new Custom Group."
        tagComponent={selectedCustomGroupBadge}
        autoresize={false}
        onBlur={() => null}
        inputAttributes={{ autoFocus: true }}
        minQueryLength={1}
        placeholderText="Add new group"
        classNames={{
          root:
            'tw-relative tw-pt-1 tw-pl-1 tw-font-body tw-form-input tw-block tw-py-2 tw-px-3',
          rootFocused: 'is-focused',
          selected: 'tw-inline',
          selectedTag:
            'tw-inline-block tw-mr-2 tw-mt-2 tw-py-2 tw-px-3 tw-bg-gray-200 tw-rounded-md',
          selectedTagName: '',
          search:
            'tw-relative tw-inline-block tw-px-1 tw-py-3 tw-mb-2 tw-max-w-full',
          searchWrapper: '',
          searchInput: 'tw-inline tw-border-0 focus:tw-outline-none',
          suggestions:
            'tw-absolute tw-left-0 tw-bg-white tw-shadow-lg tw-rounded-md custom-group-suggestions tw-border-solid tw-border tw-border-gray-100 tw-min-w-300',
          suggestionActive: 'tw-text-gray-700 tw-bg-gray-200',
          suggestionDisabled: 'is-disabled'
        }}
      />
    </Modal>
  );
};

const customGroupShape = PropTypes.shape({
  displayName: PropTypes.string,
  value: PropTypes.number,
  id: PropTypes.number,
  name: PropTypes.string
});

AddNewCustomGroupModal.defaultProps = {
  show: false,
  setShow: () => null,
  customGroups: []
};

AddNewCustomGroupModal.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  customGroups: PropTypes.arrayOf(customGroupShape)
};

export default AddNewCustomGroupModal;
