import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { CurrentAccountContext } from 'components/shared/context/CurrentAccountContext';

const useOnboardingDisabled = () => {
  const currentAccount = useContext(CurrentAccountContext);
  const [onboardingDisabled, setOnboardingDisabled] = useState(
    !currentAccount.onboarding
  );
  const structuredSelector = createStructuredSelector({
    isOnboardingDisabled: state => state.overlordUtilities.isOnboardingDisabled
  });

  const { isOnboardingDisabled } = useSelector(structuredSelector);

  useEffect(() => {
    if (
      isOnboardingDisabled !== null &&
      onboardingDisabled !== isOnboardingDisabled
    )
      setOnboardingDisabled(isOnboardingDisabled);
  }, [onboardingDisabled, isOnboardingDisabled]);

  return onboardingDisabled;
};

export default useOnboardingDisabled;
