import React, { useEffect, useContext, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  createErrorMessageSelector,
  createLoadedSelector,
  createLoadingSelector
} from 'appState/selectors';
import isEmpty from 'lodash.isempty';
import groupBy from 'lodash.groupby';
import {
  fetchJourneys,
  createContact,
  fetchAutoCompleteSuggestions,
  fetchAccountCampaigns,
  fetchLeadSources
} from 'appState/actions/ActionCreators';
import PageHeader from 'components/Theme/PageHeader';
import TextInput from 'components/Theme/TextInput';
import Select from 'components/Theme/Select';
import { useFormik } from 'formik';
import {
  contactNewSchema,
  modularContactNewSchema,
  contactNewGdprSchema,
  modularContactNewGdprSchema
} from 'lib/validation/schema';
import { UsersContext } from 'components/shared/context/UsersContext';
import { CurrentAccountContext } from 'components/shared/context/CurrentAccountContext';
import Button from 'components/Theme/Button';
import Notification from 'components/Theme/Notification';
import DatePicker from 'components/Theme/DatePicker';
import AutoCompleteDropdown from 'components/Theme/AutoCompleteDropDown';
import { CheckInput } from 'components/ProductSubscriptionSetup/shared/FormInputs';

const ContactNew = () => {
  const errorSelector = createErrorMessageSelector(['overlord/JOURNEYS_FETCH']);
  const contactErrorSelector = createErrorMessageSelector([
    'contact/CREATE_CONTACT'
  ]);
  const journeysLoadedSelector = createLoadedSelector([
    'overlord/JOURNEYS_FETCH'
  ]);
  const contactCreateLoadingSelector = createLoadingSelector([
    'contact/CREATE_CONTACT'
  ]);
  const campaignsLoadedSelector = createLoadedSelector([
    'contact/FETCH_ACCOUNT_CAMPAIGNS'
  ]);

  const structuredSelector = createStructuredSelector({
    journeys: state => state.overlordUtilities.journeys,
    journeysError: state => errorSelector(state),
    automationCampaigns: state => state.contact.automationCampaigns,
    contactError: state => contactErrorSelector(state),
    isJourneysLoaded: state => journeysLoadedSelector(state),
    isCampaignsLoaded: state => campaignsLoadedSelector(state),
    isContactCreateLoading: state => contactCreateLoadingSelector(state),
    referralSuggestions: state => state.autoComplete.suggestions,
    defaultLeadSources: state => state.leadSource.defaultLeadSources,
    customLeadSources: state => state.leadSource.customLeadSources
  });
  const [showError, setShowError] = useState(false);
  const [leadSourceOptions, setLeadSourceOptions] = useState([]);

  const {
    journeys,
    journeysError,
    contactError,
    isJourneysLoaded,
    isContactCreateLoading,
    referralSuggestions,
    automationCampaigns,
    isCampaignsLoaded,
    defaultLeadSources,
    customLeadSources
  } = useSelector(structuredSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchJourneys());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchAccountCampaigns());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchLeadSources());
  }, [dispatch]);

  useEffect(() => {
    const defaultOptions = defaultLeadSources.map(leadSource => {
      return { displayName: leadSource.name, value: leadSource.key };
    });

    let customOptions = [];
    if (!isEmpty(customLeadSources)) {
      customOptions = customLeadSources.map(leadSource => {
        return { displayName: leadSource.name, value: leadSource.key };
      });
    }

    setLeadSourceOptions([...defaultOptions, ...customOptions]);
  }, [defaultLeadSources, customLeadSources]);

  useEffect(() => {
    if (isEmpty(contactError) && isEmpty(journeysError)) {
      setShowError(false);
    } else {
      setShowError(true);
    }
  }, [contactError, journeysError, dispatch]);

  function handleSubmit(values) {
    const createContactParams = {};
    if (Object.keys(values).length)
      Object.keys(values).forEach(function assignParams(key) {
        createContactParams[key] = values[key];
      });
    dispatch(createContact(createContactParams));
  }

  const currentAccount = useContext(CurrentAccountContext);

  function customValidateSchema() {
    if (currentAccount.mod_campaigns_enabled) {
      return currentAccount.gdpr_compliant
        ? modularContactNewGdprSchema
        : modularContactNewSchema;
    }

    return currentAccount.gdpr_compliant
      ? contactNewGdprSchema
      : contactNewSchema;
  }
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      journeyId: '',
      assignedStaffId: '',
      initialCampaignId: '',
      gdpr: currentAccount.gdpr_compliant
    },
    enableReinitialize: true,
    validationSchema: customValidateSchema(),
    onSubmit: values => {
      handleSubmit(values);
    }
  });

  const { users, isUsersLoaded, usersError } = useContext(UsersContext);

  const userSelectOptions = users.map(user => {
    return { displayName: user.full_name, value: user.id };
  });

  const journeySelectOptions = journeys.map(journey => {
    return { displayName: journey.name, value: journey.id };
  });

  function handleBirthdayChange(updatedValue) {
    formik.setFieldValue('birthday', updatedValue);
  }

  const handleReferrerSelection = value => {
    formik.setFieldValue('referrerId', value);
  };

  const handleReferrerChange = query => {
    dispatch(
      fetchAutoCompleteSuggestions({
        url: 'contacts',
        query,
        suggestionDataMap: { displayName: 'full_name', value: 'id' }
      })
    );
  };

  const campaignSelectOptions = useMemo(() => {
    const options = automationCampaigns.map(campaign => {
      return {
        displayName: campaign.name,
        value: campaign.id,
        label: campaign.product_name
      };
    });

    const groupOptions = groupBy(options, 'label');

    const campaignOptions = Object.keys(groupOptions).map(label => {
      return {
        label,
        options: groupOptions[label]
      };
    });
    return campaignOptions;
  }, [automationCampaigns]);

  return (
    <div className="tw-min-h-screen">
      <PageHeader
        title="New Contact"
        breadcrumbs={[
          {
            name: 'Contacts',
            action: () => {
              window.location = '/contacts';
            }
          },
          {
            name: 'New Contact',
            // eslint-disable-next-line no-console
            action: () => console.log('Contact New clicked...')
          }
        ]}
      />

      <form action="#" method="POST">
        <div className="tw-max-w-7xl tw-mx-auto tw-py-20 sm:tw-px-6 lg:tw-px-8">
          <div>
            <div className="md:tw-grid md:tw-grid-cols-4 md:tw-gap-6">
              <div className="md:tw-col-span-1">
                <div className="tw-px-4 sm:tw-px-0">
                  <h3 className="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900">
                    Required Details
                  </h3>
                  <p className="tw-mt-1 tw-text-sm tw-leading-5 tw-text-gray-600">
                    Enter the contact&rsquo;s required information.
                  </p>
                </div>
              </div>
              <div className="tw-mt-5 md:tw-mt-0 md:tw-col-span-3">
                <div className="tw-shadow sm:tw-rounded-md sm:tw-overflow-hidden">
                  <div className="tw-px-4 tw-py-5 tw-bg-white sm:tw-p-6">
                    <div className="tw-grid tw-grid-cols-12 tw-gap-6">
                      <TextInput
                        id="firstName"
                        required
                        value={formik.values.firstName}
                        labelText="First Name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        showError={
                          formik.touched.firstName && !!formik.errors.firstName
                        }
                        error={formik.errors.firstName}
                        widthClass="tw-col-span-12 sm:tw-col-span-6"
                      />
                      <TextInput
                        id="lastName"
                        required
                        value={formik.values.lastName}
                        labelText="Last Name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        showError={
                          formik.touched.lastName && !!formik.errors.lastName
                        }
                        error={formik.errors.lastName}
                        widthClass="tw-col-span-12 sm:tw-col-span-6"
                      />

                      <TextInput
                        id="email"
                        required
                        value={formik.values.email}
                        labelText="Email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        showError={
                          formik.touched.email && !!formik.errors.email
                        }
                        error={formik.errors.email}
                        widthClass="tw-col-span-12 sm:tw-col-span-6"
                      />

                      <Select
                        id="assignedStaffId"
                        required
                        value={formik.values.assignedStaffId}
                        options={
                          isUsersLoaded && !usersError ? userSelectOptions : []
                        }
                        labelText="Assigned Staff"
                        placeholder="Select Assigned Staff"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        showError={
                          formik.touched.assignedStaffId &&
                          !!formik.errors.assignedStaffId
                        }
                        error={formik.errors.assignedStaffId}
                        widthClass="tw-col-span-12 sm:tw-col-span-6"
                      />
                      {!currentAccount.mod_campaigns_enabled && (
                        <Select
                          id="journeyId"
                          required
                          value={formik.values.journeyId}
                          options={
                            isJourneysLoaded && !journeysError
                              ? journeySelectOptions
                              : []
                          }
                          labelText="Journey"
                          placeholder="Select Journey"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          showError={
                            formik.touched.journeyId &&
                            !!formik.errors.journeyId
                          }
                          error={formik.errors.journeyId}
                          widthClass="tw-col-span-12 sm:tw-col-span-6"
                        />
                      )}
                    </div>
                    <div className="tw-my-8 tw-w-full md:tw-w-11/12">
                      <CheckInput
                        name="gdpr"
                        id="gdpr"
                        value={formik.values.gdpr}
                        checked={formik.values.gdpr}
                        labelText="This contact has expressly opted in to receive communication from me."
                        onChange={formik.handleChange}
                        onBlur={formik.onBlur}
                        showError={formik.touched.gdpr && !!formik.errors.gdpr}
                        error={formik.errors.gdpr}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="tw-hidden sm:tw-block">
            <div className="tw-py-5">
              <div className="tw-border-t tw-border-b-0 tw-border-l-0 tw-border-r-0 tw-border-solid tw-border-gray-200" />
            </div>
          </div>

          <div>
            <div className="md:tw-grid md:tw-grid-cols-4 md:tw-gap-6">
              <div className="md:tw-col-span-1">
                <div className="tw-px-4 sm:tw-px-0">
                  <h3 className="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900">
                    Optional Information
                  </h3>
                  <p className="tw-mt-1 tw-text-sm tw-leading-5 tw-text-gray-600">
                    Enter the contact&rsquo;s other information.
                  </p>
                </div>
              </div>
              <div className="tw-mt-5 md:tw-mt-0 md:tw-col-span-3">
                <div className="tw-shadow sm:tw-rounded-md">
                  <div className="tw-px-4 tw-py-5 tw-bg-white sm:tw-p-6">
                    <div className="tw-grid tw-grid-cols-12 tw-gap-6">
                      <Select
                        id="initialCampaignId"
                        value={formik.values.initialCampaignId}
                        options={isCampaignsLoaded ? campaignSelectOptions : []}
                        labelText="Initial Campaign"
                        placeholder="Select Initial Campaign"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        showError={
                          formik.touched.initialCampaignId &&
                          !!formik.errors.initialCampaignId
                        }
                        error={formik.errors.initialCampaignId}
                        widthClass="tw-col-span-12 sm:tw-col-span-6"
                        helpText="Note: Your contact will not be put into a lifecycle if you do not select a campaign."
                        helpTextFontClass="tw-text-xs tw-italic"
                      />
                      <TextInput
                        id="phone"
                        value={formik.values.phone}
                        labelText="Phone"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        showError={
                          formik.touched.phone && !!formik.errors.phone
                        }
                        error={formik.errors.phone}
                        widthClass="tw-col-span-12 sm:tw-col-span-6"
                      />

                      <Select
                        id="gender"
                        value={formik.values.gender}
                        options={[
                          { displayName: 'Male', value: 'Male' },
                          { displayName: 'Female', value: 'Female' },
                          { displayName: 'Not Specified', value: 'Other' }
                        ]}
                        labelText="Gender"
                        placeholder="Select Gender"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        showError={
                          formik.touched.gender && !!formik.errors.gender
                        }
                        error={formik.errors.gender}
                        widthClass="tw-col-span-12 sm:tw-col-span-6"
                      />

                      <DatePicker
                        id="birthday"
                        value={formik.values.birthday}
                        labelText="Birthday"
                        onChange={handleBirthdayChange}
                        onBlur={formik.handleBlur}
                        showError={
                          formik.touched.birthday && !!formik.errors.birthday
                        }
                        error={formik.errors.birthday}
                        widthClass="tw-col-span-12 sm:tw-col-span-6"
                        showYearDropdown
                      />

                      <TextInput
                        id="tshirtSize"
                        value={formik.values.tshirtSize}
                        labelText="T-Shirt Size"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        showError={
                          formik.touched.tshirtSize &&
                          !!formik.errors.tshirtSize
                        }
                        error={formik.errors.tshirtSize}
                        helpText="i.e. XS, S, M, L, XL, XXL"
                        widthClass="tw-col-span-12 sm:tw-col-span-6"
                      />
                      <AutoCompleteDropdown
                        suggestions={referralSuggestions}
                        labelText="Referred By"
                        id="referrerIdForUpdate"
                        handleChange={handleReferrerChange}
                        handleSelection={handleReferrerSelection}
                        displayValue=""
                        widthClass="tw-col-span-12 sm:tw-col-span-6"
                      />
                      {currentAccount.mod_campaigns_enabled &&
                        currentAccount.lead_source_enabled && (
                          <Select
                            id="leadSource"
                            options={leadSourceOptions}
                            value={formik.values.leadSource}
                            labelText="Lead Source"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            widthClass="tw-col-span-12 sm:tw-col-span-6 xxs:tw-my-4 xs:tw-my-0"
                          />
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="tw-hidden sm:tw-block">
            <div className="tw-py-5">
              <div className="tw-border-t tw-border-b-0 tw-border-l-0 tw-border-r-0 tw-border-solid tw-border-gray-200" />
            </div>
          </div>

          <div>
            <div className="md:tw-grid md:tw-grid-cols-4 md:tw-gap-6">
              <div className="md:tw-col-span-1">
                <div className="tw-px-4 sm:tw-px-0">
                  <h3 className="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900">
                    Address
                  </h3>
                  <p className="tw-mt-1 tw-text-sm tw-leading-5 tw-text-gray-600">
                    Enter the contact&rsquo;s address.
                  </p>
                </div>
              </div>
              <div className="tw-mt-5 md:tw-mt-0 md:tw-col-span-3">
                <div className="tw-shadow sm:tw-rounded-md sm:tw-overflow-hidden">
                  <div className="tw-px-4 tw-py-5 tw-bg-white sm:tw-p-6">
                    <div className="tw-grid tw-grid-cols-12 tw-gap-6">
                      <TextInput
                        id="address1"
                        value={formik.values.address1}
                        labelText="Address (Line 1)"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        showError={
                          formik.touched.address1 && !!formik.errors.address1
                        }
                        error={formik.errors.address1}
                        widthClass="tw-col-span-12 sm:tw-col-span-6"
                      />

                      <TextInput
                        id="address2"
                        value={formik.values.address2}
                        labelText="Address (Line 2)"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        showError={
                          formik.touched.address2 && !!formik.errors.address2
                        }
                        error={formik.errors.address2}
                        widthClass="tw-col-span-12 sm:tw-col-span-6"
                      />

                      <TextInput
                        id="city"
                        value={formik.values.city}
                        labelText="City"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        showError={formik.touched.city && !!formik.errors.city}
                        error={formik.errors.city}
                        widthClass="tw-col-span-12 sm:tw-col-span-6"
                      />

                      <TextInput
                        id="state"
                        value={formik.values.state}
                        labelText="State"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        showError={
                          formik.touched.state && !!formik.errors.state
                        }
                        error={formik.errors.state}
                        widthClass="tw-col-span-12 sm:tw-col-span-6"
                      />

                      <TextInput
                        id="postalCode"
                        value={formik.values.postalCode}
                        labelText="Postal Code"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        showError={
                          formik.touched.postalCode &&
                          !!formik.errors.postalCode
                        }
                        error={formik.errors.postalCode}
                        widthClass="tw-col-span-12 sm:tw-col-span-6"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="tw-hidden sm:tw-block">
            <div className="tw-py-5">
              <div className="tw-border-t tw-border-b-0 tw-border-l-0 tw-border-r-0 tw-border-solid tw-border-gray-200" />
            </div>
          </div>
          <div className="tw-py-3 tw-text-right">
            <Button
              text="Cancel"
              // eslint-disable-next-line no-console
              onClick={() => console.log('hi')}
              type="white"
            />
            <span className="ml-3">
              <Button
                text="Create"
                isLoaded={!isContactCreateLoading}
                onClick={formik.handleSubmit}
              />
            </span>
          </div>
        </div>
      </form>
      <Notification
        HeaderIcon={color => {
          return (
            <svg
              className={`tw-h-5 tw-w-5 tw-text-${color}-400`}
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                clipRule="evenodd"
              />
            </svg>
          );
        }}
        color="error"
        headerText="Error!"
        message={contactError || journeysError}
        setShowAction={setShowError}
        show={showError}
        timeout={0}
        type="colored"
      />
    </div>
  );
};

export default ContactNew;
