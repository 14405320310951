import React, { Fragment } from 'react';
import Skeleton from 'react-loading-skeleton';
import Styled from './SkeletonListItem.styles';

const SkeletonListItem = () => {
  return (
    <Fragment>
      <div className="row">
        <div className="col-12">
          <Styled.Card className="card mb-15">
            <div className="card-body">
              <div className="row">
                <div className="col-12 col-md-6 col-lg-6">
                  <div className="media p-0">
                    <Skeleton height={50} width={50} />
                    <div className="media-body text-truncate">
                      <Skeleton />
                      <div className="d-flex align-items-center lh-25">
                        <Skeleton width={50} height={18} />
                        <span className="ml-10">
                          <Skeleton width={50} />
                        </span>
                        <span className="divider-dash">
                          <Skeleton width={75} />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 align-self-center p-0">
                  <div
                    className="d-flex justify-content-end align-self-center"
                    style={{ paddingRight: '15px' }}
                  >
                    <Skeleton width={10} height={20} />
                  </div>
                </div>
              </div>
            </div>
          </Styled.Card>
        </div>
      </div>
    </Fragment>
  );
};

export default SkeletonListItem;
