const contactFormFieldDecorator = fields => {
  return fields.map(formField => {
    return {
      ...formField,
      validationType: formField.field_type === 'yes_or_no' ? 'bool' : 'string',
      validations: formField.required
        ? [
            {
              type: 'required',
              params: ['Please fill out this field.']
            }
          ]
        : []
    };
  });
};

export default contactFormFieldDecorator;
