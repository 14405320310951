import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';
import ActivityFeed from 'components/Theme/ActivityFeed';

const PinnedActivities = ({ tabName, activities }) => {
  return (
    <Fragment>
      {!isEmpty(activities) ? (
        <Fragment>
          <h3 className="tw-font-body tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900 tw-pb-4 tw-pt-2">
            Pinned Activity
          </h3>
          <ActivityFeed
            actionColor="alpha"
            containerStyle={{
              paddingBottom: '10px'
            }}
            itemColor="gray"
            items={activities}
            showIcons
            widthClass="tw-w-full"
            enableShowMore
            showMoreProps={{ maxHeight: '3000px' }}
            tabName={tabName}
          />
          <br />
        </Fragment>
      ) : null}
    </Fragment>
  );
};

const colorPropType = PropTypes.oneOf([
  'alpha',
  'bravo',
  'charlie',
  'success',
  'warning',
  'error',
  'gray'
]);

const infoAlertShape = {
  message: PropTypes.string,
  type: PropTypes.oneOf(['standard', 'colored']),
  headerText: PropTypes.string,
  HeaderIcon: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  color: colorPropType
};

const statusObjectShape = {
  status: PropTypes.string,
  title: PropTypes.string,
  friendly_message: PropTypes.string,
  failed_code: PropTypes.string,
  failed_message: PropTypes.string,
  failed_link: PropTypes.string
};

const actionShape = {
  displayName: PropTypes.string,
  value: PropTypes.string
};

const activityShape = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  sourceable_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  index: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  headerText: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.string
  ]),
  bodyText: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.string
  ]),
  action: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  actionText: PropTypes.string,
  actions: PropTypes.arrayOf(PropTypes.shape(actionShape)),
  timestamp: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  isPinned: PropTypes.bool.isRequired,
  pinnedAction: PropTypes.func,
  infoAlert: PropTypes.shape(infoAlertShape),
  statusObject: PropTypes.shape(statusObjectShape)
});

PinnedActivities.defaultProps = {
  tabName: 'feed',
  activities: []
};

PinnedActivities.propTypes = {
  tabName: PropTypes.string,
  activities: PropTypes.arrayOf(activityShape)
};

export default PinnedActivities;
