import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import isEmpty from 'lodash.isempty';
import {
  fetchSyncStatus,
  statsFetch,
  setSyncToRequired,
  fetchLastSyncOutputLog,
  startLastSyncOutputLogWatcher,
  stopLastSyncOutputLogWorker,
  fetchMode
} from 'appState/actions/ActionCreators';
import Card from 'components/Theme/Card';
import Link from 'components/Theme/Link';
import Table from 'components/Theme/Table';
import LogoContent from 'components/SyncWizard/shared/LogoContent';
import HeaderComponent from 'components/SyncWizard/shared/HeaderComponent';

const Stats = () => {
  const [showLogs, setShowLogs] = useState(false);

  const structuredSelector = createStructuredSelector({
    syncingStatus: state => state.syncWizard.syncingStatus,
    testMode: state => state.syncWizard.testMode,
    stats: state => state.syncWizard.stats,
    lastSyncOutputLog: state => state.syncWizard.lastSyncOutputLog
  });
  const { testMode, syncingStatus, stats, lastSyncOutputLog } = useSelector(
    structuredSelector
  );

  const dispatch = useDispatch();
  const history = useHistory();
  const lastSyncOutputLogEndRef = useRef(null);

  useEffect(() => {
    if (isEmpty(testMode)) dispatch(fetchMode());
  }, [dispatch]);

  useEffect(() => {
    if (!syncingStatus) dispatch(fetchSyncStatus());
    if (syncingStatus === 'completed') dispatch(statsFetch());
  }, [syncingStatus, dispatch]);

  const { connected, new_to_uplaunch: newToUplaunch } = stats;
  const tableRows = [
    {
      id: 'syncpage-0',
      'Contact Status': 'Contacts Connected',
      'Contact Count': connected
    },
    {
      id: 'syncpage-1',
      'Contact Status': 'Contacts Created',
      'Contact Count': newToUplaunch
    }
  ];

  const navigateToSyncWizard = () => {
    dispatch(setSyncToRequired(history));
  };

  const navigateToTestLogs = () => {
    history.push('/ui/sync-wizard/test-mode-logs');
  };

  useEffect(() => {
    if (syncingStatus === 'completed') {
      // Stop polling for sync output log once sync status has completed
      dispatch(stopLastSyncOutputLogWorker());
      dispatch(fetchLastSyncOutputLog());
    }

    if (syncingStatus === 'in_progress')
      dispatch(startLastSyncOutputLogWatcher());

    return () => {
      dispatch(stopLastSyncOutputLogWorker());
    };
  }, [syncingStatus, dispatch]);

  const { body } = document;
  function disableBodyScroll() {
    body.style.overflowY = 'hidden';
  }
  function enableBodyScroll() {
    body.style.overflowY = 'auto';
  }

  const scrollToBottomOfLog = () => {
    if (lastSyncOutputLogEndRef && lastSyncOutputLogEndRef.current)
      lastSyncOutputLogEndRef.current.scrollIntoView({
        behavior: 'auto',
        block: 'end'
      });
  };

  useEffect(() => {
    scrollToBottomOfLog();
  }, [lastSyncOutputLog, dispatch]);

  const Logs = () => {
    return (
      <span
        className="tw-block tw-mx-auto tw-p-8 tw-bg-gray-100 tw-mt-10 tw-rounded-lg"
        onMouseOver={disableBodyScroll}
        onFocus={disableBodyScroll}
        onMouseOut={enableBodyScroll}
        onBlur={enableBodyScroll}
        style={{
          height: '450px',
          maxWidth: '800px',
          clear: 'both',
          overflowX: 'hidden',
          overflowY: 'scroll'
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: lastSyncOutputLog }} />
        <div ref={lastSyncOutputLogEndRef} />
      </span>
    );
  };

  const showStatsPage = syncingStatus === 'completed';
  return (
    <div>
      {syncingStatus === 'required' ? <Redirect to="/ui/sync-wizard" /> : null}
      {showStatsPage ? (
        <div className="tw-mx-7 tw-mt-7 tw-mb-24">
          <Card containerClass="tw-bg-white tw-rounded-lg">
            <HeaderComponent
              title="Contacts synced!"
              subtitle=""
              actionText="Sync Again"
              withAction
              onClick={navigateToSyncWizard}
              withSecondaryAction={testMode}
              secondaryActionText="Test Mode Logs"
              secondaryOnClick={navigateToTestLogs}
            />

            <LogoContent contactsStats={stats} />
            <div className="tw-container tw-mx-auto tw-w-1/3 tw-text-md tw-max-w-7xl tw-my-8">
              <Table
                key="Synced<->ZenPlanner<->Uplaunch-contact-stats"
                rows={tableRows}
                includedColumns={['Contact Status', 'Contact Count']}
                emptyMessage="Synced<->ZenPlanner <-> Uplaunch Contact Stats"
              />
              <div className="tw-mt-5 tw-mb-10 tw-float-right">
                {testMode ? null : (
                  <Link
                    text={showLogs ? 'Hide Sync Logs' : 'Show Sync Logs'}
                    onClick={() => setShowLogs(!showLogs)}
                    color="alpha"
                  />
                )}
              </div>
            </div>
            <div className="tw-mb-10">{showLogs ? <Logs /> : null}</div>
          </Card>
        </div>
      ) : (
        <div className="tw-mx-7 tw-mt-7 tw-mb-24">
          <Card
            containerClass="tw-bg-white tw-rounded-lg"
            bodyStyle={{ height: '800px' }}
          >
            <HeaderComponent
              title="We are now syncing your contacts!"
              subtitle="Your UpLaunch and Zen Planner contacts are now busy syncing! This process is taking place in the background which means you are free to leave this page in the meantime. This process could take up to 5 minutes. Once synced, you will see a Zen Planner icon displayed next to your synced contact’s names."
              isLoading={isEmpty(syncingStatus)}
            />
            <Logs />
          </Card>
        </div>
      )}
    </div>
  );
};

export default Stats;
