/* eslint-disable react/no-this-in-sfc */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useRouteMatch, useHistory } from 'react-router-dom';
import get from 'lodash.get';
import isEmpty from 'lodash.isempty';
import {
  broadcastFetchSmsMergeTags,
  broadcastSaveSms,
  broadcastUpdate,
  broadcastSetHasContentChanged
} from 'appState/actions/ActionCreators';
import loadingGif from 'images/broadcast/loading.gif';
import { CurrentAccountContext } from 'components/shared/context/CurrentAccountContext';
import useToggleHeaderFooter from 'components/shared/hooks/useToggleHeaderFooter';
import BroadcastPreview from 'components/BroadcastCenter/Broadcast/Index/BroadcastPreview';
import { SegmentedMessage } from 'sms-segments-calculator';
import SmsRequiredFieldsModal from './SmsRequiredFieldsModal';
import BroadcastStep from '../BroadcastStep';
import Card from '../Card';

const reader = new FileReader();

const Sms = () => {
  const currentAccount = useContext(CurrentAccountContext);
  const { path } = useRouteMatch();
  useToggleHeaderFooter(path);
  const textAreaEl = useRef(null);
  const selectPickerEl = useRef(null);
  const imagePreviewEl = useRef(null);
  const inputFileEl = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const structuredSelector = createStructuredSelector({
    currentBroadcast: state => state.broadcastCenter.currentBroadcast,
    smsMergeFields: state => state.broadcastCenter.smsMergeFields,
    isTemplate: state => state.broadcastCenter.isTemplate,
    hasContentChanged: state => state.broadcastCenter.hasContentChanged
  });

  const {
    currentBroadcast,
    smsMergeFields,
    isTemplate,
    hasContentChanged
  } = useSelector(structuredSelector);

  const [smsBody, setSmsBody] = useState('');
  const [smsLink, setSmsLink] = useState('');
  const [fileField, setFileField] = useState({});
  const [removeTempMedia, setRemoveTempMedia] = useState(false);
  const [areRequiredFieldsEmpty, setAreRequiredFieldsEmpty] = useState(false);
  const [showRequiredFieldsModal, setShowRequiredFieldsModal] = useState(false);
  const [mediaLoaded, setMediaLoaded] = useState(true);

  function handleReaderCompleteEvents(event) {
    if (event.type === 'loadstart') {
      setMediaLoaded(false);
      if (imagePreviewEl.current) {
        imagePreviewEl.current.style.width = '100px';
        imagePreviewEl.current.style.height = '100px';
        imagePreviewEl.current.style.maxWidth = '100px';
        imagePreviewEl.current.style.maxHeight = '100px';
      }
    } else {
      setMediaLoaded(true);
    }
  }

  function getSegmentCount() {
    const segmentedMessage = new SegmentedMessage(smsBody);
    return segmentedMessage.segmentsCount;
  }

  const segementCount = getSegmentCount();

  function addListeners(_reader) {
    _reader.addEventListener('loadstart', handleReaderCompleteEvents);
    _reader.addEventListener('loadend', handleReaderCompleteEvents);
    _reader.addEventListener('error', handleReaderCompleteEvents);
    _reader.addEventListener('abort', handleReaderCompleteEvents);
  }

  function readURLCrop(file) {
    if (file) {
      addListeners(reader);
      if (file.type && file.type.match('image')) {
        reader.onload = function fileLoaded(e) {
          const img = new Image();
          img.onload = function imgLoaded() {
            // Scale image to canvas size (100x100) and crop whichever is larger between width/height
            const canvasSize = 100;
            const sourceWidth = this.width;
            const sourceHeight = this.height;
            const scaleX = sourceWidth / canvasSize;
            const scaleY = sourceHeight / canvasSize;
            const smallerScale = scaleX < scaleY ? scaleX : scaleY;
            const scaledSourceWidth = sourceWidth / smallerScale;
            const scaledSourceHeight = sourceHeight / smallerScale;
            if (imagePreviewEl && imagePreviewEl.current) {
              imagePreviewEl.current.style.width = `${scaledSourceWidth}px`;
              imagePreviewEl.current.style.height = `${scaledSourceHeight}px`;
              imagePreviewEl.current.style.maxWidth = `${scaledSourceWidth}px`;
              imagePreviewEl.current.style.maxHeight = `${scaledSourceHeight}px`;
              imagePreviewEl.current.setAttribute('src', e.target.result);
            }
          };
          img.src = e.target.result;
        };
        reader.readAsDataURL(file);
      } else if (file.type && file.type.match('video')) {
        reader.onload = function fileLoaded() {
          const blob = new Blob([reader.result], { type: file.type });
          const url = URL.createObjectURL(blob);
          const video = document.createElement('video');

          const snapImage = function snapImage() {
            const canvas = document.createElement('canvas');
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            canvas
              .getContext('2d')
              .drawImage(video, 0, 0, canvas.width, canvas.height);
            const image = canvas.toDataURL();
            const success = image.length > 100000;
            if (success) {
              const canvasSize = 100;
              const sourceWidth = canvas.width;
              const sourceHeight = canvas.height;
              const scaleX = sourceWidth / canvasSize;
              const scaleY = sourceHeight / canvasSize;
              const smallerScale = scaleX < scaleY ? scaleX : scaleY;
              const scaledSourceWidth = sourceWidth / smallerScale;
              const scaledSourceHeight = sourceHeight / smallerScale;
              if (imagePreviewEl && imagePreviewEl.current) {
                imagePreviewEl.current.style.width = `${scaledSourceWidth}px`;
                imagePreviewEl.current.style.height = `${scaledSourceHeight}px`;
                imagePreviewEl.current.style.maxWidth = `${scaledSourceWidth}px`;
                imagePreviewEl.current.style.maxHeight = `${scaledSourceHeight}px`;
                imagePreviewEl.current.setAttribute('src', image);
              }
            }
            return success;
          };

          const timeupdate = function timeUpdate() {
            if (snapImage()) {
              video.removeEventListener('timeupdate', timeupdate);
              video.pause();
            }
          };
          video.addEventListener('loadeddata', function loadedVideo() {
            if (snapImage()) {
              video.removeEventListener('timeupdate', timeupdate);
            }
          });

          video.addEventListener('timeupdate', timeupdate);
          video.preload = 'metadata';
          video.src = url;
          // Load video in Safari / IE11
          video.muted = true;
          video.playsInline = true;
          video.play();
        };
        reader.readAsArrayBuffer(file);
      }
    }
  }

  function loadMediaPreview(url) {
    fetch(url)
      .then(res => {
        return res.blob();
      })
      .then(res => {
        if (res) {
          readURLCrop(res);
        }
      });
  }

  useEffect(() => {
    if (currentBroadcast.sms_body) setSmsBody(currentBroadcast.sms_body);
  }, [dispatch, currentBroadcast.sms_body]);

  useEffect(() => {
    if (currentBroadcast.sms_link) setSmsLink(currentBroadcast.sms_link);
  }, [dispatch, currentBroadcast.sms_link]);

  useEffect(() => {
    if (currentBroadcast.media_urls)
      loadMediaPreview(get(currentBroadcast, 'media_urls[0].url'));
  }, [currentBroadcast.media_urls]);

  useEffect(() => {
    const selectedFiles = get(inputFileEl, 'current.files');

    if (
      isEmpty(smsBody) &&
      isEmpty(smsLink) &&
      selectedFiles &&
      selectedFiles.length === 0 &&
      (isEmpty(currentBroadcast.media_urls) || removeTempMedia)
    ) {
      setAreRequiredFieldsEmpty(true);
    } else {
      setAreRequiredFieldsEmpty(false);
    }
  }, [
    smsBody,
    smsLink,
    fileField,
    currentBroadcast.media_urls,
    removeTempMedia
  ]);

  const smsMergeFieldSelectOptions = smsMergeFields.map(mergeField => {
    return (
      <option key={mergeField[0]} value={mergeField[1]}>
        {mergeField[0]}
      </option>
    );
  });

  function saveBroadcast(overrideNavigation = false) {
    if (!areRequiredFieldsEmpty) {
      setShowRequiredFieldsModal(false);

      const smsSaveParams = {
        broadcastId: currentBroadcast.id,
        history,
        overrideNavigation,
        removeTempMedia,
        isTemplate
      };

      const selectedFiles = get(inputFileEl, 'current.files');

      if (smsBody) smsSaveParams.smsBody = smsBody;
      if (smsLink) smsSaveParams.smsLink = smsLink;
      if (selectedFiles && selectedFiles.length > 0)
        smsSaveParams.file = fileField;

      dispatch(broadcastSaveSms(smsSaveParams));
    } else {
      setShowRequiredFieldsModal(true);
    }
  }

  function handleModalCloseClicked() {
    setShowRequiredFieldsModal(false);
  }

  const handleSmsMergeFieldDropdownChange = mergeField => {
    const caretPos = document.getElementById('broadcast-sms-editor')
      .selectionStart;
    const caretEnd = document.getElementById('broadcast-sms-editor')
      .selectionEnd;
    const textAreaTxt = document.getElementById('broadcast-sms-editor').value;
    const txtToAdd = mergeField;
    document.getElementById('broadcast-sms-editor').value =
      textAreaTxt.substring(0, caretPos) +
      txtToAdd +
      textAreaTxt.substring(caretEnd);

    textAreaEl.current.focus();
    document.getElementById('broadcast-sms-editor').selectionStart =
      caretPos + txtToAdd.length;
    document.getElementById('broadcast-sms-editor').selectionEnd =
      caretPos + txtToAdd.length;
    selectPickerEl.current.value = '';
    setSmsBody(document.getElementById('broadcast-sms-editor').value);
    if (!hasContentChanged) dispatch(broadcastSetHasContentChanged(true));
  };

  function handleRemoveMMSMedia() {
    imagePreviewEl.current.setAttribute('src', loadingGif);
    inputFileEl.current.value = null;
    setFileField({});
    setRemoveTempMedia(true);
  }

  function selectAndPreviewFile(e) {
    setFileField(e.target.files[0]);
    readURLCrop(e.target.files[0]);
    if (e.target.files[0]) setRemoveTempMedia(false);
  }

  function validateFileSize(e) {
    const fi = e.target;
    if (fi && fi.files && fi.files.length > 0) {
      const attachedFile = fi.files[0];
      const fsize = fi.files[0].size;
      const file = Math.round(fsize / 1024);
      // The size of the file.
      if (attachedFile.type.match('image') && file >= 25000) {
        inputFileEl.current.value = '';
        app.toast('File size too big. Max image size is 25MB.', {
          type: 'danger',
          icon: 'fa-close'
        });
      } else if (attachedFile.type.match('video') && file >= 50000) {
        inputFileEl.current.value = '';
        app.toast('File size too big. Max video size is 50MB.', {
          type: 'danger',
          icon: 'fa-close'
        });
      } else {
        selectAndPreviewFile(e);
      }
    }
  }

  function handleFileSelect(e) {
    if (get(e, 'target.files[0].name') !== fileField.name) {
      imagePreviewEl.current.setAttribute('src', loadingGif);
      setFileField({});
    }
    validateFileSize(e);
  }

  function handleSmsBodyChange(e) {
    setSmsBody(e.target.value);
    if (!hasContentChanged) dispatch(broadcastSetHasContentChanged(true));
  }

  function handleSmsLinkChange(e) {
    setSmsLink(e.target.value);
    if (!hasContentChanged) dispatch(broadcastSetHasContentChanged(true));
  }

  useEffect(() => {
    if (
      !isTemplate &&
      !isEmpty(currentBroadcast) &&
      !currentBroadcast.has_been_edited &&
      hasContentChanged
    ) {
      dispatch(
        broadcastUpdate({
          broadcastId: currentBroadcast.id,
          hasBeenEdited: true
        })
      );
    }
  }, [
    hasContentChanged,
    isTemplate,
    currentBroadcast.has_been_edited,
    dispatch
  ]);

  useEffect(() => {
    dispatch(broadcastFetchSmsMergeTags({ broadcastId: currentBroadcast.id }));
  }, [dispatch, currentBroadcast]);

  return (
    <div>
      <BroadcastStep saveAction={saveBroadcast} smsMediaLoaded={mediaLoaded}>
        <div className="col-md-12 col-lg-6">
          <Card
            title="SMS Message"
            subtitle="What would you like to say?"
            containerStyle={{
              marginBottom: '20px',
              marginRight: '0px',
              marginLeft: '0px',
              paddingLeft: '0px',
              paddingRight: '0px'
            }}
          >
            <div className="form-row">
              <div className="form-group col-md-12">
                <label>Message</label>
                <div className="float-right mb-10">
                  {smsMergeFields && smsMergeFields.length ? (
                    <select
                      ref={selectPickerEl}
                      onChange={e =>
                        handleSmsMergeFieldDropdownChange(e.target.value)
                      }
                      id="userId"
                      className="form-control"
                      defaultValue=""
                    >
                      <option value="" disabled>
                        Insert Merge Field
                      </option>
                      {smsMergeFieldSelectOptions}
                    </select>
                  ) : null}
                </div>
                <textarea
                  ref={textAreaEl}
                  className="form-control mb-30"
                  placeholder="Write your message here..."
                  id="broadcast-sms-editor"
                  data-provide="maxlength"
                  data-warning-class="badge badge-info"
                  data-always-show="true"
                  maxLength="320"
                  onChange={handleSmsBodyChange}
                  value={smsBody}
                />
                <label className="form-label">
                  Add SMS Link (https://google.com)
                </label>
                <input
                  type="text"
                  name="sms-link"
                  id="broadcast-sms-link-input"
                  className="form-control"
                  value={smsLink}
                  maxLength="1000"
                  onChange={handleSmsLinkChange}
                />
                {currentAccount.mms_available && (
                  <div
                    className="file-group d-flex justify-content-end"
                    style={{ paddingTop: '16px' }}
                  >
                    <div
                      className={`big-rounded mr-20 ${
                        (!removeTempMedia &&
                          (fileField && !isEmpty(fileField.name))) ||
                        (!removeTempMedia &&
                          currentBroadcast &&
                          !isEmpty(currentBroadcast.media_urls))
                          ? 'd-block'
                          : 'd-none'
                      }`}
                      style={{
                        width: '100px',
                        height: '100px',
                        position: 'relative'
                      }}
                      id="image_upload_preview_wrapper"
                    >
                      <span
                        className="fa-stack cursor-pointer"
                        style={{
                          verticalAlign: 'top',
                          position: 'absolute',
                          right: '-10px',
                          top: '-10px'
                        }}
                        id="remove-mms-media"
                        onClick={handleRemoveMMSMedia}
                        onKeyDown={null}
                        role="button"
                        tabIndex={-1}
                      >
                        <i
                          className="fa fa-times-circle fa-stack-2x text-danger"
                          style={{ zIndex: '101' }}
                        />
                        <i
                          className="fa fa-circle fa-stack-2x text-white"
                          style={{ zIndex: '100' }}
                        />
                      </span>
                      <div
                        className="big-rounded"
                        style={{
                          width: '100px',
                          height: '100px',
                          overflow: 'hidden',
                          backgroundColor: 'rgba(0,0,0,0.25)'
                        }}
                      >
                        <img
                          id="image_upload_preview"
                          ref={imagePreviewEl}
                          alt="Preview MMS"
                          src={loadingGif}
                        />
                      </div>
                    </div>
                    <a
                      className="publisher-btn file-browser"
                      href="#"
                      data-provide="tooltip"
                      title="Image size max: 25MB. Video size max 50MB."
                      data-placement="top"
                      data-tooltip-color="secondary"
                    >
                      <div className="btn btn-info">Attach Media</div>
                    </a>
                    <input
                      ref={inputFileEl}
                      type="file"
                      id="inputFile"
                      accept="image/*, video/*, video/mp4, video/x-m4v"
                      onChange={handleFileSelect}
                    />
                  </div>
                )}
              </div>
              <div className="bg-secondary">
                <div className="pl-3 pb-2 pt-2 fs-14">
                  <span className="tw-font-medium"> Segment:</span>
                  <span> {segementCount}</span>
                  <p className="mb-0" style={{ lineHeight: '1.25' }}>
                    <em>
                      Disclaimer*: One standard SMS segment is 160 characters.
                      If using an emoji, the segment decreases to 70 characters.
                      This is an estimation of the number of segments used. Your
                      merge fields will be added to the message and could
                      increase your segments per message.
                    </em>
                  </p>
                </div>
              </div>
            </div>
          </Card>
        </div>
        <div className="col-md-12 col-lg-6">
          <Card
            title="Preview"
            subtitle="What your SMS will look like"
            containerStyle={{
              mmarginRight: '0px',
              marginLeft: '0px',
              paddingLeft: '0px',
              paddingRight: '0px'
            }}
          >
            <BroadcastPreview
              content={{
                smsBody,
                smsLink,
                smsMediaFile: fileField,
                smsMediaUrl: get(currentBroadcast, 'media_urls[0].url'),
                removeTempMedia,
                contact: { first_name: 'Otto', last_name: 'Mation' }
              }}
            />
          </Card>
        </div>
      </BroadcastStep>

      <SmsRequiredFieldsModal
        handleCloseClicked={handleModalCloseClicked}
        showModal={showRequiredFieldsModal}
        mmsAvailable={currentAccount.mms_available}
      />
    </div>
  );
};

export default Sms;
