import React from 'react';
import PropTypes from 'prop-types';
import HeroIcon from 'components/Theme/HeroIcon';

const StepOne = ({ currentPlan, plan }) => {
  function getContent(contentName, contentPrice) {
    return (
      <div className="tw-text-sm">
        <span className="tw-font-medium tw-text-gray-900">{contentName}</span>
        <div className="tw-text-gray-500">
          <span>{contentPrice}</span>
        </div>
      </div>
    );
  }
  return (
    <>
      <div className="tw-mb-4 tw-gray-500">
        You are making the following changes to your account:
      </div>
      <div className="tw-flex tw-justify-between tw-items-center">
        <div className="tw-flex tw-item-center">
          {getContent(
            `${currentPlan.name} - Current Plan`,
            `${currentPlan.pricing_information.price_with_currency}/month`
          )}
        </div>
        <div className="tw-flex-shrink-0 tw-px-2">
          <HeroIcon
            icon="arrow-circle-right"
            color="gray"
            tint="900"
            height="6"
            width="6"
          />
        </div>
        <div className="tw-flex tw-justify-between tw-rounded-lg tw-border-alpha-base tw-bg-alpha-100 tw-border-solid tw-border-2 tw-px-3 tw-py-4">
          <div className="tw-flex tw-flex-1 tw-item-center">
            {getContent(
              `${plan.name} - New Plan`,
              `${plan.pricing_information.price_with_currency}/month`
            )}
            <div className="tw-flex-shrink-0 tw-px-2 tw--mt-2">
              <HeroIcon icon="check-circle-filled" color="success" filled />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

StepOne.defaultProps = {
  plan: () => null,
  currentPlan: () => null
};

StepOne.propTypes = {
  plan: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  ),
  currentPlan: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  )
};

export default StepOne;
