import React from 'react';
import { action } from '@storybook/addon-actions';
import { withA11y } from '@storybook/addon-a11y';
import { withKnobs, text, object, boolean } from '@storybook/addon-knobs';
import ButtonWithDropdown from './index';

export default {
  component: ButtonWithDropdown,
  title: 'ButtonWithDropdown',
  decorators: [withA11y, withKnobs]
};

export const standard = () => (
  <ButtonWithDropdown
    value={text('Value', '')}
    options={object('Select Options', [
      { displayName: 'Save and schedule', value: 'save_and_schedule' },
      { displayName: 'Save and publish', value: 'save_and_publish' },
      { displayName: 'Export PDF', value: 'export_pdf' }
    ])}
    buttonText={text('Label', 'Save Changes')}
    type={text('Type', 'white')}
    showSvg={boolean('Show Svg', true)}
    onChange={action('select-change')}
  />
);
