import React from 'react';
import { action } from '@storybook/addon-actions';
import { withA11y } from '@storybook/addon-a11y';
import { withKnobs, text, boolean } from '@storybook/addon-knobs';
import DatePicker from './index';

export default {
  component: DatePicker,
  title: 'DatePicker',
  decorators: [withA11y, withKnobs]
};

export const withLabel = () => (
  <DatePicker
    id="datepicker"
    value={text('Value', '2019-09-17T23:44:42.131Z')}
    placeholder={text('Placeholder', 'Select Date')}
    labelText={text('Label Text', 'Select Date')}
    showError={boolean('Show Error', false)}
    error={text('Error Text', 'Please select a date')}
    helpText={text('Help Text', '')}
    hideLabel={boolean('Hide Label', false)}
    widthClass={text('Width Class', 'tw-max-w-xs')}
    onChange={action('select-change')}
    onBlur={action('select-blur')}
    clearable={boolean('Clear Button', true)}
  />
);

export const withLabelAndHelpText = () => (
  <DatePicker
    id="datepicker"
    value={text('Value', '2019-09-17T23:44:42.131Z')}
    placeholder={text('Placeholder', 'Select Date')}
    labelText={text('Label Text', 'Select Date')}
    showError={boolean('Show Error', false)}
    error={text('Error Text', 'Please select a date')}
    helpText={text('Help Text', 'Select a date to continue.')}
    hideLabel={boolean('Hide Label', false)}
    widthClass={text('Width Class', 'tw-max-w-xs')}
    onChange={action('select-change')}
    onBlur={action('select-blur')}
    clearable={boolean('Clear Button', true)}
  />
);

export const withError = () => (
  <DatePicker
    id="datepicker"
    value={text('Value', '2019-09-17T23:44:42.131Z')}
    placeholder={text('Placeholder', 'Select Date')}
    labelText={text('Label Text', 'Select Date')}
    showError={boolean('Show Error', true)}
    error={text('Error Text', 'Please select a date')}
    helpText={text('Help Text', '')}
    hideLabel={boolean('Hide Label', false)}
    widthClass={text('Width Class', 'tw-max-w-xs')}
    onChange={action('select-change')}
    onBlur={action('select-blur')}
    clearable={boolean('Clear Button', true)}
  />
);

export const withHiddenLabel = () => (
  <DatePicker
    id="datepicker"
    value={text('Value', '2019-09-17T23:44:42.131Z')}
    placeholder={text('Placeholder', 'Select Date')}
    labelText={text('Label Text', 'Select Date')}
    showError={boolean('Show Error', false)}
    error={text('Error Text', 'Please select a date')}
    helpText={text('Help Text', '')}
    hideLabel={boolean('Hide Label', true)}
    widthClass={text('Width Class', 'tw-max-w-xs')}
    onChange={action('select-change')}
    onBlur={action('select-blur')}
    clearable={boolean('Clear Button', true)}
  />
);
