export const FETCH_SUBSCRIPTIONS = 'subscription/FETCH_SUBSCRIPTIONS';
export const FETCH_SUBSCRIPTIONS_REQUEST = `${FETCH_SUBSCRIPTIONS}_REQUEST`;
export const FETCH_SUBSCRIPTIONS_SUCCESS = `${FETCH_SUBSCRIPTIONS}_SUCCESS`;
export const FETCH_SUBSCRIPTIONS_FAILURE = `${FETCH_SUBSCRIPTIONS}_FAILURE`;

export const CURRENT_SUBSCRIPTIONS_RESET =
  'subscription/CURRENT_SUBSCRIPTIONS_RESET';

export const FETCH_SUBSCRIPTION = 'subscription/FETCH_SUBSCRIPTION';
export const FETCH_SUBSCRIPTION_REQUEST = `${FETCH_SUBSCRIPTION}_REQUEST`;
export const FETCH_SUBSCRIPTION_SUCCESS = `${FETCH_SUBSCRIPTION}_SUCCESS`;
export const FETCH_SUBSCRIPTION_FAILURE = `${FETCH_SUBSCRIPTION}_FAILURE`;

export const UPDATE_SUBSCRIPTION = 'subscription/UPDATE_SUBSCRIPTION';
export const UPDATE_SUBSCRIPTION_REQUEST = `${UPDATE_SUBSCRIPTION}_REQUEST`;
export const UPDATE_SUBSCRIPTION_SUCCESS = `${UPDATE_SUBSCRIPTION}_SUCCESS`;
export const UPDATE_SUBSCRIPTION_FAILURE = `${UPDATE_SUBSCRIPTION}_FAILURE`;

export const FETCH_PRODUCTS = 'subscription/FETCH_PRODUCTS';
export const FETCH_PRODUCTS_REQUEST = `${FETCH_PRODUCTS}_REQUEST`;
export const FETCH_PRODUCTS_SUCCESS = `${FETCH_PRODUCTS}_SUCCESS`;
export const FETCH_PRODUCTS_FAILURE = `${FETCH_PRODUCTS}_FAILURE`;

export const PAGE_CHANGE_SUBSCRIPTIONS =
  'subscription/PAGE_CHANGE_SUBSCRIPTIONS';
export const PAGE_CHANGE_SUBSCRIPTIONS_REQUEST = `${PAGE_CHANGE_SUBSCRIPTIONS}_REQUEST`;
export const PAGE_CHANGE_SUBSCRIPTIONS_SUCCESS = `${PAGE_CHANGE_SUBSCRIPTIONS}_SUCCESS`;
export const PAGE_CHANGE_SUBSCRIPTIONS_FAILURE = `${PAGE_CHANGE_SUBSCRIPTIONS}_FAILURE`;

export const DOWNGRADE_SUBSCRIPTION = 'subscription/DOWNGRADE_SUBSCRIPTION';
export const DOWNGRADE_SUBSCRIPTION_REQUEST = `${DOWNGRADE_SUBSCRIPTION}_REQUEST`;
export const DOWNGRADE_SUBSCRIPTION_SUCCESS = `${DOWNGRADE_SUBSCRIPTION}_SUCCESS`;
export const DOWNGRADE_SUBSCRIPTION_FAILURE = `${DOWNGRADE_SUBSCRIPTION}_FAILURE`;

export const FETCH_MY_SUBSCRIPTION = 'subscription/FETCH_MY_SUBSCRIPTION';
export const FETCH_MY_SUBSCRIPTION_REQUEST = `${FETCH_MY_SUBSCRIPTION}_REQUEST`;
export const FETCH_MY_SUBSCRIPTION_SUCCESS = `${FETCH_MY_SUBSCRIPTION}_SUCCESS`;
export const FETCH_MY_SUBSCRIPTION_FAILURE = `${FETCH_MY_SUBSCRIPTION}_FAILURE`;

export const UPGRADE_SUBSCRIPTION = 'subscription/UPGRADE_SUBSCRIPTION';
export const UPGRADE_SUBSCRIPTION_REQUEST = `${UPGRADE_SUBSCRIPTION}_REQUEST`;
export const UPGRADE_SUBSCRIPTION_SUCCESS = `${UPGRADE_SUBSCRIPTION}_SUCCESS`;
export const UPGRADE_SUBSCRIPTION_FAILURE = `${UPGRADE_SUBSCRIPTION}_FAILURE`;

export const UPDATE_CARD_SUBSCRIPTION = 'subscription/UPDATE_CARD_SUBSCRIPTION';
export const UPDATE_CARD_SUBSCRIPTION_REQUEST = `${UPDATE_CARD_SUBSCRIPTION}_REQUEST`;
export const UPDATE_CARD_SUBSCRIPTION_SUCCESS = `${UPDATE_CARD_SUBSCRIPTION}_SUCCESS`;
export const UPDATE_CARD_SUBSCRIPTION_FAILURE = `${UPDATE_CARD_SUBSCRIPTION}_FAILURE`;

export const UPDATE_DEFAULT_PRODUCT = 'subscription/UPDATE_DEFAULT_PRODUCT';
export const UPDATE_DEFAULT_PRODUCT_REQUEST = `${UPDATE_DEFAULT_PRODUCT}_REQUEST`;
export const UPDATE_DEFAULT_PRODUCT_SUCCESS = `${UPDATE_DEFAULT_PRODUCT}_SUCCESS`;
export const UPDATE_DEFAULT_PRODUCT_FAILURE = `${UPDATE_DEFAULT_PRODUCT}_FAILURE`;

export const CANCEL_DOWNGRADE_SUBSCRIPTION =
  'subscription/CANCEL_DOWNGRADE_SUBSCRIPTION';
export const CANCEL_DOWNGRADE_SUBSCRIPTION_REQUEST = `${CANCEL_DOWNGRADE_SUBSCRIPTION}_REQUEST`;
export const CANCEL_DOWNGRADE_SUBSCRIPTION_SUCCESS = `${CANCEL_DOWNGRADE_SUBSCRIPTION}_SUCCESS`;
export const CANCEL_DOWNGRADE_SUBSCRIPTION_FAILURE = `${CANCEL_DOWNGRADE_SUBSCRIPTION}_FAILURE`;

export const UNDO_CANCELLATION_SUBSCRIPTION =
  'subscription/UNDO_CANCELLATION_SUBSCRIPTION';
export const UNDO_CANCELLATION_SUBSCRIPTION_REQUEST = `${UNDO_CANCELLATION_SUBSCRIPTION}_REQUEST`;
export const UNDO_CANCELLATION_SUBSCRIPTION_SUCCESS = `${UNDO_CANCELLATION_SUBSCRIPTION}_SUCCESS`;
export const UNDO_CANCELLATION_SUBSCRIPTION_FAILURE = `${UNDO_CANCELLATION_SUBSCRIPTION}_FAILURE`;
