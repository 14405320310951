import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Transition from 'components/Transition';
import BroadcastIcon from 'components/Theme/Icon/BroadcastIcon';

const Banner = ({
  color,
  announcement,
  smallAnnouncement,
  showDismissBtn,
  primaryBtnText,
  primaryBtnAction,
  secondaryBtnText,
  secondaryBtnAction,
  hideIcon
}) => {
  const [showBanner, setShowBanner] = useState(true);

  return (
    <Transition
      show={showBanner}
      enter="tw-transform tw-transition tw-ease-out tw-duration-300"
      enterFrom="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
      enterTo="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
      leave="tw-transform tw-transition tw-ease-in tw-duration-200"
      leaveFrom="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
      leaveTo="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
    >
      <div className={`tw-bg-${color}-600`}>
        <div className="tw-mx-auto tw-py-3 tw-px-3 sm:tw-px-6 lg:tw-px-8">
          <div className="tw-flex tw-items-center tw-justify-between tw-flex-wrap">
            <div className="tw-w-0 tw-flex-1 tw-flex tw-items-center">
              {!hideIcon && (
                <span
                  className={`tw-flex tw-p-2 tw-rounded-lg tw-bg-${color}-800`}
                >
                  <BroadcastIcon height={6} width={6} color="white" />
                </span>
              )}
              <div className="tw-ml-3 tw-font-medium tw-text-white tw-truncate tw-mb-0 md:tw-text-md tw-text-lg">
                <span className="md:tw-hidden">{smallAnnouncement}</span>
                <span className="tw-hidden md:tw-inline">{announcement}</span>
              </div>
            </div>
            {secondaryBtnAction && secondaryBtnText && (
              <div className="tw-order-3 tw-mr-2 tw-mt-2 tw-flex-shrink-0 tw-w-full xs:tw-order-2 xs:tw-mt-0 xs:tw-w-auto">
                <button
                  onClick={secondaryBtnAction}
                  type="button"
                  className={`tw-flex tw-items-center tw-justify-center tw-px-4 tw-py-2 tw-border tw-border-transparent tw-rounded-md tw-shadow-sm tw-text-sm tw-font-medium tw-text-white tw-bg-${color}-500 tw-no-underline tw-cursor-pointer`}
                >
                  {secondaryBtnText}
                </button>
              </div>
            )}
            {primaryBtnAction && primaryBtnText && (
              <div className="tw-order-3 tw-mt-2 tw-flex-shrink-0 tw-w-full xs:tw-order-2 xs:tw-mt-0 xs:tw-w-auto">
                <button
                  onClick={primaryBtnAction}
                  type="button"
                  className={`tw-flex tw-items-center tw-justify-center tw-px-4 tw-py-2 tw-border tw-border-transparent tw-rounded-md tw-shadow-sm tw-text-sm tw-font-medium tw-text-${color}-600 tw-bg-white hover:tw-bg-${color}-50 tw-no-underline tw-cursor-pointer`}
                >
                  {primaryBtnText}
                </button>
              </div>
            )}
            {showDismissBtn && (
              <div className="tw-order-2 tw-flex tw-flex-wrap tw-flex-shrink-0 sm:tw-order-3 sm:tw-ml-3">
                <a
                  onClick={() => setShowBanner(false)}
                  onKeyDown={() => setShowBanner(false)}
                  role="button"
                  tabIndex={0}
                  className={`tw--mr-1 tw-flex tw-p-2 tw-rounded-md tw-bg-${color}-600 hover:tw-bg-${color}-500 focus:tw-outline-none sm:tw--mr-2 tw-cursor-pointer`}
                >
                  <span className="tw-sr-only">Dismiss</span>
                  <svg
                    className="tw-h-6 tw-w-6 tw-text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </Transition>
  );
};

Banner.defaultProps = {
  color: 'alpha',
  announcement: '',
  smallAnnouncement: '',
  showDismissBtn: true,
  primaryBtnText: '',
  primaryBtnAction: null,
  secondaryBtnText: '',
  secondaryBtnAction: null,
  hideIcon: false
};

Banner.propTypes = {
  color: PropTypes.string,
  announcement: PropTypes.string,
  smallAnnouncement: PropTypes.string,
  showDismissBtn: PropTypes.bool,
  primaryBtnText: PropTypes.string,
  primaryBtnAction: PropTypes.func,
  secondaryBtnText: PropTypes.string,
  secondaryBtnAction: PropTypes.func,
  hideIcon: PropTypes.bool
};

export default Banner;
