import React, { Fragment } from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import HeaderContent from 'components/SyncWizard/shared/HeaderContent';

const PopOver = ({ headerContent, isSticky }) => {
  return (
    <Fragment>
      <svg
        fill="none"
        viewBox="0 0 24 24"
        stroke="#2196F3"
        className={`tw-h-4 tw-ml-4 tw-cursor-pointer question-mark-circle w-6 h-6 ${
          headerContent
            ? ''
            : 'tw-mt-16 tw-ml-16'
        }`}
        data-tip
        data-for="registerTip"
        data-background-color="white"
        data-text-color="black"
        data-border="true"
        data-border-color="black"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
      <ReactTooltip
        className="tw-p-0 tw-opacity-100"
        id="registerTip"
        place={isSticky ? 'bottom' : 'top'}
        effect="solid"
      >
        {headerContent ? <HeaderContent /> : 'PopOver Component'}
      </ReactTooltip>
    </Fragment>
  );
};

PopOver.defaultProps = {
  headerContent: false
};

PopOver.propTypes = {
  headerContent: PropTypes.bool,
  isSticky: PropTypes.bool.isRequired
};

export default PopOver;
