export const CREATE_JOURNEY = 'journey/CREATE_JOURNEY';
export const CREATE_JOURNEY_REQUEST = `${CREATE_JOURNEY}_REQUEST`;
export const CREATE_JOURNEY_SUCCESS = `${CREATE_JOURNEY}_SUCCESS`;
export const CREATE_JOURNEY_FAILURE = `${CREATE_JOURNEY}_FAILURE`;
export const UPDATE_JOURNEY = 'journey/UPDATE_JOURNEY';
export const UPDATE_JOURNEY_REQUEST = `${UPDATE_JOURNEY}_REQUEST`;
export const UPDATE_JOURNEY_SUCCESS = `${UPDATE_JOURNEY}_SUCCESS`;
export const UPDATE_JOURNEY_FAILURE = `${UPDATE_JOURNEY}_FAILURE`;
export const FETCH_SINGLE_JOURNEY = 'journey/FETCH_SINGLE_JOURNEY';
export const FETCH_SINGLE_JOURNEY_REQUEST = `${FETCH_SINGLE_JOURNEY}_REQUEST`;
export const FETCH_SINGLE_JOURNEY_SUCCESS = `${FETCH_SINGLE_JOURNEY}_SUCCESS`;
export const FETCH_SINGLE_JOURNEY_FAILURE = `${FETCH_SINGLE_JOURNEY}_FAILURE`;
