import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Badge from 'components/Theme/Badge';

const ProductInfo = ({ product }) => {
  return (
    <Fragment key={product.id}>
      <div className="tw-mb-5">
        {product.product_lifecycles ? (
          <div className="tw-font-medium tw-text-base">
            <Badge
              shape="rectangle"
              size="small"
              value={product.product_lifecycles}
              containerStyle={{
                paddingBottom: '3px',
                textTransform: 'uppercase'
              }}
            />
          </div>
        ) : null}
        <div className="tw-font-bold tw-mt-2">{product.name}</div>
        <div className="tw-font-normal">{product.category}</div>
      </div>
    </Fragment>
  );
};

ProductInfo.defaultProps = {
  product: null
};

ProductInfo.propTypes = {
  product: PropTypes.oneOfType([PropTypes.object])
};

export default ProductInfo;
