import React from 'react';
import PropTypes from 'prop-types';
import ulRocket from 'images/rocket.svg';
import zpLogo from 'images/zen-planner-logo.svg';

const LogoContent = ({ contactsStats, containerClass }) => {
  return (
    <div className={containerClass}>
      <div className="tw-table-cell tw-text-center tw-w-1/3">
        <img className="tw-h-24" src={ulRocket} alt="" />
        <div className="tw-mt-2">
          <strong className="tw-text-lg">{contactsStats.upLaunch}</strong>
          <br />
          Contacts in UpLaunch
        </div>
      </div>
      <div className="tw-table-cell tw-text-center tw-w-1/3">
        <div className="tw-w-1/4 tw-mx-auto tw-my-1">
          <div className="tw-h-0 tw-w-0 tw-table-cell right-triangle-border" />
          <div className="tw-table-cell tw-align-middle">
            <div className="tw-h-2 tw-w-4 tw-bg-gray-900" />
          </div>
        </div>
        <div className="tw-w-1/6 tw-mx-auto tw-my-1">
          <div className="tw-table-cell tw-align-middle">
            <div className="tw-h-2 tw-w-4 tw-bg-gray-900" />
          </div>
          <div className="tw-h-0 tw-w-0 tw-table-cell left-triangle-border" />
        </div>
      </div>
      <div className="tw-table-cell tw-text-center tw-w-1/3">
        <img className="tw-h-24" src={zpLogo} alt="" />
        <div className="tw-mt-2">
          <strong className="tw-text-lg">{contactsStats.zenPlanner}</strong>
          <br />
          Retrieved from Zen Planner
        </div>
      </div>
    </div>
  );
};

LogoContent.defaultProps = {
  containerClass:
    'tw-container tw-mx-auto tw-w-3/3 md:tw-w-2/3 lg:tw-w-1/3 tw-text-md tw-table tw-mt-12'
};

LogoContent.propTypes = {
  containerClass: PropTypes.string,
  contactsStats: PropTypes.shape({
    zenPlanner: PropTypes.number.isRequired,
    upLaunch: PropTypes.number.isRequired
  }).isRequired
};

export default LogoContent;
