import React, { useContext, useEffect, useState } from 'react';
import UtilityCard from 'components/OverlordUtilities/shared/UtilityCard';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import get from 'lodash.get';
import isEmpty from 'lodash.isempty';
import {
  createErrorMessageSelector,
  createLoadedSelector
} from 'appState/selectors';
import {
  reassignUserObjects,
  resetIsLoaded
} from 'appState/actions/ActionCreators';
import { CurrentAccountContext } from 'components/shared/context/CurrentAccountContext';
import { UsersContext } from 'components/shared/context/UsersContext';
import useProcessingActivity from 'components/shared/hooks/useProcessingActivity';
import useAccountDisabled from 'components/shared/hooks/useAccountDisabled';

const UTILITY_NAMES = ['user_object_reassigner'];

const SELECTED_LIFECYCLE_OPTIONS = ['lead', 'client', 'former_client', 'none'];

const UserObjectReassignment = () => {
  const currentAccount = useContext(CurrentAccountContext);
  const accountId = currentAccount.id;
  const { users, isUsersLoaded, usersError } = useContext(UsersContext);
  const processingActivity = useProcessingActivity(UTILITY_NAMES);
  const accountDisabled = useAccountDisabled();

  const errorSelector = createErrorMessageSelector([
    'overlord/REASSIGN_USER_OBJECTS'
  ]);
  const loadedSelector = createLoadedSelector([
    'overlord/REASSIGN_USER_OBJECTS'
  ]);
  const structuredSelector = createStructuredSelector({
    error: state => errorSelector(state),
    isLoaded: state => loadedSelector(state)
  });

  const { error, isLoaded } = useSelector(structuredSelector);

  const userSelectOptions = users.map(user => {
    return (
      <option key={user.id} value={user.id}>
        {user.id} - {user.full_name}
      </option>
    );
  });

  const [isContactSelected, setIsContactSelected] = useState(false);
  const [toUserIds, setToUserIds] = useState();
  const [fromUserIds, setFromUserIds] = useState();
  const [objects, setObjects] = useState([]);
  const [selectedLifecycles, setSelectedLifecycles] = useState(
    isContactSelected ? SELECTED_LIFECYCLE_OPTIONS : []
  );

  const dispatch = useDispatch();

  const reassignUserObject = () => {
    if (
      get(objects, 'length') &&
      get(toUserIds, 'length') &&
      get(fromUserIds, 'length')
    ) {
      dispatch(
        reassignUserObjects({
          accountId,
          toUserIds,
          fromUserIds,
          objects,
          selectedLifecycles
        })
      );
    }
  };

  useEffect(() => {
    if (isLoaded && isEmpty(error)) {
      app.toast(`Successfully reassigned User objects.`, {
        type: 'info',
        icon: 'fa-floppy-o'
      });
    }

    return () => {
      dispatch(resetIsLoaded('overlord/REASSIGN_USER_OBJECTS'));
    };
  }, [users, toUserIds, isLoaded, error, dispatch]);

  const onObjectsChange = e => {
    if (e.target.value === 'contacts' && e.target.checked) {
      setSelectedLifecycles(SELECTED_LIFECYCLE_OPTIONS);
    } else if (e.target.value === 'contacts' && !e.target.checked) {
      setSelectedLifecycles([]);
    }

    if (e.target.checked) {
      setObjects([...objects, e.target.value]);
    } else {
      const index = objects.indexOf(e.target.value);
      setObjects(objects.filter((o, i) => i !== index));
    }
  };

  const onSelectedLifecyclesChange = e => {
    if (e.target.checked) {
      setSelectedLifecycles([...selectedLifecycles, e.target.value]);
    } else {
      const index = selectedLifecycles.indexOf(e.target.value);
      setSelectedLifecycles(
        selectedLifecycles.filter((item, i) => i !== index)
      );
    }
  };

  const handleFromUserIdsChange = e => {
    setFromUserIds(
      [...e.target.options].filter(o => o.selected).map(o => o.value)
    );
  };

  const handleToUsersIdChange = e => {
    setToUserIds(
      [...e.target.options].filter(o => o.selected).map(o => o.value)
    );
  };

  const getCardBody = () => {
    return (
      <div>
        {/* <%= form_tag overlord_utility_process_reassign_user_responsibilities_path(overlord_utility_id: @account.id) , method: :post do %> */}
        <div>
          <p>Pick what objects you would like to reassign</p>

          <label className="custom-control custom-checkbox">
            <input
              onChange={e => onObjectsChange(e)}
              name="objects[tasks]"
              value="tasks"
              type="checkbox"
              className="custom-control-input"
            />
            <span className="custom-control-indicator" />
            <span className="ml-10">Tasks</span>
          </label>
          <label className="custom-control custom-checkbox">
            <input
              onChange={e => onObjectsChange(e)}
              name="objects[contacts]"
              value="contacts"
              type="checkbox"
              className="custom-control-input"
              onClick={() => setIsContactSelected(!isContactSelected)}
            />
            <span className="custom-control-indicator" />
            <span className="ml-10">Contacts</span>
          </label>
          <label className="custom-control custom-checkbox">
            <input
              onChange={e => onObjectsChange(e)}
              name="objects[appointments]"
              value="appointments"
              type="checkbox"
              className="custom-control-input"
            />
            <span className="custom-control-indicator" />
            <span className="ml-10">Appointments</span>
          </label>
        </div>
        {isContactSelected && (
          <div id="user-reassignment-selected-lifecycles-wrapper">
            <p className="mt-20">
              Pick what lifecycles to target for contact reassignment.
            </p>
            <label className="custom-control custom-checkbox">
              <input
                onChange={e => onSelectedLifecyclesChange(e)}
                type="checkbox"
                defaultChecked
                name="selectedLifecycles[lead]"
                value="lead"
                className="custom-control-input"
              />
              <span className="custom-control-indicator" />
              <span className="ml-10">Leads</span>
            </label>
            <label className="custom-control custom-checkbox">
              <input
                onChange={e => onSelectedLifecyclesChange(e)}
                type="checkbox"
                defaultChecked
                name="selectedLifecycles[client]"
                value="client"
                className="custom-control-input"
              />
              <span className="custom-control-indicator" />
              <span className="ml-10">Clients</span>
            </label>
            <label className="custom-control custom-checkbox">
              <input
                onChange={e => onSelectedLifecyclesChange(e)}
                type="checkbox"
                defaultChecked
                name="selectedLifecycles[former_client]"
                value="former_client"
                className="custom-control-input"
              />
              <span className="custom-control-indicator" />
              <span className="ml-10">Former Clients</span>
            </label>
            <label className="custom-control custom-checkbox">
              <input
                onChange={e => onSelectedLifecyclesChange(e)}
                type="checkbox"
                defaultChecked
                name="selectedLifecycles[none]"
                value="none"
                className="custom-control-input"
              />
              <span className="custom-control-indicator" />
              <span className="ml-10">No Lifecycle</span>
            </label>
          </div>
        )}
        <hr />
        <div className="form-row">
          <div className="col-lg-12">
            <p>
              Select the user(s) you want to reassign the objects from and the
              user(s) you want to reassign the objects to. If more than one 'to
              user' is selected it will evenly distribute via round robin to
              each of them.
            </p>
          </div>
          <div className="col-lg-6 form-group">
            {/* <%= label_tag :from_user, 'From User' %> */}
            <label htmlFor="fromUsers">From Users</label>

            {/* <%= select_tag :from_user, options_for_select(@account.users.active.order(:id).map { |u| ["#{u.id} - #{u.full_name}", "#{u.id}"] }), class: 'form-control', 'data-size': '20', 'data-provide': 'selectpicker' %> */}
            {users && users.length ? (
              <select
                onChange={e => handleFromUserIdsChange(e)}
                id="fromUsers"
                className="form-control"
                data-size="20"
                data-provide="selectpicker"
                multiple
              >
                <option value="" disabled hidden>
                  Please Choose Users...
                </option>
                {userSelectOptions}
              </select>
            ) : null}
          </div>
          <div className="col-lg-6 form-group">
            {/* <%= label_tag :to_user, 'To User' %>
                <%= select_tag :to_user, options_for_select(@account.users.active.order(:id).map { |u| ["#{u.id} - #{u.full_name}", "#{u.id}"] }), class: 'form-control', 'data-size': '20', 'data-provide': 'selectpicker' %> */}

            <label htmlFor="toUsers">To Users</label>
            {users && users.length ? (
              <select
                onChange={e => handleToUsersIdChange(e)}
                id="toUsers"
                className="form-control"
                data-size="20"
                data-provide="selectpicker"
                multiple
              >
                <option value="" disabled hidden>
                  Nothing selected
                </option>
                {userSelectOptions}
              </select>
            ) : null}
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="col-lg-6 pr-10">
      <UtilityCard
        title="User Object Reassignment"
        subtitle="Use this tool to reassign tasks, contacts and appointments from
              one user to another."
        submitBtnText="Reassign Responsibilities"
        submitBtnAction={reassignUserObject}
        bodyComponent={getCardBody}
        isLoaded={isUsersLoaded}
        processingActivity={processingActivity}
        accountDisabled={accountDisabled}
        error={error || usersError}
      />
    </div>
  );
};

export default UserObjectReassignment;
