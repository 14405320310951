import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ProductList from './ProductList';
import Product from './Product';

const MarketPlace = () => {
  const { path } = useRouteMatch();

  return (
    <div>
      <Switch>
        <Route exact path={path} component={ProductList} />
        <Route path={`${path}/product/:id`} render={(props)=><Product {...props}/>} />
      </Switch>
    </div>
  );
};

export default MarketPlace;
