const theme = {
  container: {},
  input: {
    width: '100%',
    height: 38,
    padding: '10px 12px',
    border: '1px solid #d2d6dc',
    borderTopLeftRadius: '0.375rem',
    borderTopRightRadius: '0.375rem',
    borderBottomRightRadius: '0.375rem',
    borderBottomLeftRadius: '0.375rem',
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
    fontWeight: '400 !important',
    color: 'black',
    fontFamily: 'Inter, sans-serif !important',
    appearance: 'none',
    colorAdjust: 'exact',
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#ffffff',
    borderColor: '#d2d6dc',
    borderWidth: '1px',
    paddingTop: '0.5rem',
    paddingRight: '2.5rem',
    paddingBottom: '0.5rem',
    paddingLeft: '0.75rem',
    fontSize: '0.875rem',
    lineHeight: '1.5',
    backgroundPosition: 'right 0.5rem center',
    backgroundSize: '1.5em 1.5em',
    backgroundImage:
      "url(\"data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='none'%3e%3cpath d='M7 7l3-3 3 3m0 6l-3 3-3-3' stroke='%239fa6b2' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e\")"
  },
  inputFocused: {
    outlineColor: null,
    outlineWidth: 0,
    outline: 0,
    boxShadow: '0 0 0 3px rgba(164, 202, 254, 0.45)',
    border: '1px solid  #a4cafe'
  },
  suggestionsContainer: {
    display: 'none'
  },
  suggestionsContainerOpen: {
    display: 'block',
    position: 'absolute',
    width: '100%',
    backgroundColor: '#fff',
    fontFamily: 'Helvetica, sans-serif',
    fontWeight: '400 !important',
    fontSize: 14,
    borderRadius: '0.375rem',
    zIndex: 10,
    height: 'auto',
    maxHeight: '150px',
    overflow: 'auto',
    top: '75px',
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)'
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
    textAlign: 'left',
    lineHeight: '0.5em'
  },
  suggestion: {
    cursor: 'pointer',
    padding: '10px',
    paddingLeft: '0 px'
  },
  suggestionHighlighted: {
    backgroundColor: '#0179ff',
    color: '#fff'
  }
};
module.exports = {
  theme
};
