import React, { Fragment } from 'react';
import { withA11y } from '@storybook/addon-a11y';
import { withKnobs, select, boolean, object } from '@storybook/addon-knobs';
import ButtonGroup from './index';

export default {
  component: ButtonGroup,
  title: 'ButtonGroup',
  decorators: [withA11y, withKnobs]
};

let sizeSelect;
let typeSelect;
let btnGroup;

const btnObject = [
  { color: 'alpha', text: 'days', action: null },
  { color: 'alpha', text: 'months', action: null },
  { color: 'alpha', text: 'years', action: null }
];

const containerStyle = { marginRight: '5px', marginBottom: '5px' };

function includeSizeSelect(defaultValue) {
  const label = 'Size';
  const options = ['xs', 'sm', 'md', 'lg', 'xl'];
  sizeSelect = select(label, options, defaultValue);
}

function includeTypeSelect(defaultValue) {
  const label = 'Type';
  const options = ['basic'];
  typeSelect = select(label, options, defaultValue);
}

function includeBtnGroup(defaultValue) {
  const label = 'Button Group';
  btnGroup = object(label, defaultValue);
}

export const basic = () => {
  includeSizeSelect('lg');
  includeTypeSelect('basic');
  includeBtnGroup(btnObject);

  return (
    <Fragment>
      <div>Alpha:</div>
      <ButtonGroup
        btnProps={btnGroup}
        size={sizeSelect}
        type={typeSelect}
        containerStyle={containerStyle}
        disabled={boolean('Disabled', false)}
      />
      <div>Bravo:</div>
      <ButtonGroup
        btnProps={[
          { color: 'bravo', text: 'days', action: null },
          { color: 'bravo', text: 'months', action: null },
          { color: 'bravo', text: 'years', action: null }
        ]}
        size={sizeSelect}
        type={typeSelect}
        containerStyle={containerStyle}
        disabled={boolean('Disabled', false)}
      />
      <br />
      <div>Charlie:</div>
      <ButtonGroup
        btnProps={[
          { color: 'charlie', text: 'days', action: null },
          { color: 'charlie', text: 'months', action: null },
          { color: 'charlie', text: 'years', action: null }
        ]}
        size={sizeSelect}
        type={typeSelect}
        containerStyle={containerStyle}
        disabled={boolean('Disabled', false)}
      />
      <div>Success:</div>
      <ButtonGroup
        btnProps={[
          { color: 'success', text: 'days', action: null },
          { color: 'success', text: 'months', action: null },
          { color: 'success', text: 'years', action: null }
        ]}
        size={sizeSelect}
        type={typeSelect}
        containerStyle={containerStyle}
        disabled={boolean('Disabled', false)}
      />
      <br />
      <div>Warning:</div>
      <ButtonGroup
        btnProps={[
          { color: 'warning', text: 'days', action: null },
          { color: 'warning', text: 'months', action: null },
          { color: 'warning', text: 'years', action: null }
        ]}
        size={sizeSelect}
        type={typeSelect}
        containerStyle={containerStyle}
        disabled={boolean('Disabled', false)}
      />
      <div>Error:</div>
      <ButtonGroup
        btnProps={[
          { color: 'error', text: 'days', action: null },
          { color: 'error', text: 'months', action: null },
          { color: 'error', text: 'years', action: null }
        ]}
        size={sizeSelect}
        type={typeSelect}
        containerStyle={containerStyle}
        disabled={boolean('Disabled', false)}
      />
    </Fragment>
  );
};
