import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  createErrorMessageSelector,
  createLoadedSelector
} from 'appState/selectors';
import { fetchUsers } from 'appState/actions/ActionCreators';
import { CurrentAccountContext } from 'components/shared/context/CurrentAccountContext';

const UsersContext = React.createContext();

const UsersProvider = props => {
  const { children } = props;

  const currentAccount = useContext(CurrentAccountContext);
  const accountId = currentAccount?.id;

  const errorSelector = createErrorMessageSelector(['overlord/USERS_FETCH']);
  const loadedSelector = createLoadedSelector(['overlord/USERS_FETCH']);
  const structuredSelector = createStructuredSelector({
    users: state => state.overlordUtilities.users,
    error: state => errorSelector(state),
    isLoaded: state => loadedSelector(state)
  });

  const { error, isLoaded, users } = useSelector(structuredSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUsers({ accountId }));
  }, [dispatch, accountId]);

  return (
    <UsersContext.Provider
      value={{ users, isUsersLoaded: isLoaded, usersError: error }}
    >
      {children}
    </UsersContext.Provider>
  );
};

UsersProvider.defaultProps = {
  children: null
};

UsersProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.node)
  ])
};

export { UsersContext, UsersProvider };
