import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import HeaderComponent from 'components/SyncWizard/shared/HeaderComponent';
import MatchContacts from './MatchContacts';

const PossibleMatch = ({
  syncData,
  updateEvent,
  updateEmailEvent,
  toggleMatchTable,
  currentDisplayTable,
  isSticky
}) => {
  const emailMatchSyncData = syncData.contacts.possibleMatchWithSameEmail;
  const nameMatchSyncData = syncData.contacts.possibleMatchWithSameName;
  return (
    <Fragment>
      <HeaderComponent
        title={
          !currentDisplayTable
            ? 'Step 2a: Match Your Contacts by Email'
            : 'Step 2b: Match Your Contacts by Name'
        }
      />
      <div className="tw-mx-10 tw-mt-10 tw-mb-20">
        {!currentDisplayTable ? (
          <div className="tw-rounded-md tw-bg-alpha-100 tw-p-4">
            <div className="tw-flex">
              <div className="tw-flex-shrink-0">
                <svg
                  className="tw-h-5 tw-w-5 tw-text-alpha-400"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <div className="tw-ml-3">
                <h3 className="tw-text-sm tw-leading-5 tw-font-medium tw-text-alpha-800">
                  Who's here?
                </h3>
                <div className="tw-mt-2 tw-text-sm tw-leading-5 tw-text-alpha-700">
                  <ul className="tw-list-disc tw-pl-5">
                    <li>
                      We found more than one exact email match to contacts in
                      Zen Planner.
                    </li>
                    <li className="tw-mt-1">Names may not match.</li>
                    <li className="tw-mt-1">
                      We've made a best match for you. If it is incorrect,
                      toggle on the right one.
                    </li>
                    <li className="tw-mt-1">
                      You should toggle off any contacts you do not want to be
                      connected.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="tw-rounded-md tw-bg-alpha-100 tw-p-4">
            <div className="tw-flex">
              <div className="tw-flex-shrink-0">
                <svg
                  className="tw-h-5 tw-w-5 tw-text-alpha-400"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <div className="tw-ml-3">
                <h3 className="tw-text-sm tw-leading-5 tw-font-medium tw-text-alpha-800">
                  Who's here?
                </h3>
                <div className="tw-mt-2 tw-text-sm tw-leading-5 tw-text-alpha-700">
                  <ul className="tw-list-disc tw-pl-5">
                    <li>
                      We found UpLaunch contacts with an exact name and
                      lifecycle match to a contact in Zen Planner.
                    </li>
                    <li className="tw-mt-1">Emails do not match.</li>
                    <li className="tw-mt-1">
                      You should toggle off any contacts you do not want to
                      connected.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}
        <MatchContacts
          syncData={
            !currentDisplayTable ? emailMatchSyncData : nameMatchSyncData
          }
          updateEvent={updateEvent}
          display={currentDisplayTable}
          isSticky={isSticky}
        />
      </div>
    </Fragment>
  );
};

PossibleMatch.propTypes = {
  syncData: PropTypes.shape({
    contacts: PropTypes.shape({
      possibleMatchWithSameEmail: PropTypes.array.isRequired,
      possibleMatchWithSameName: PropTypes.array.isRequired
    }).isRequired
  }).isRequired,
  updateEvent: PropTypes.func.isRequired,
  updateEmailEvent: PropTypes.func.isRequired,
  currentDisplayTable: PropTypes.bool.isRequired,
  isSticky: PropTypes.bool.isRequired
};

export default PossibleMatch;
