import React, { Fragment, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import isEmpty from 'lodash.isempty';
import loadingGif from 'images/broadcast/loading.gif';
import { textTruncate } from 'lib/utils/string';
import { readURLOriginalRatio } from 'lib/utils/image';
import {
  createErrorMessageSelector,
  createLoadedSelector
} from 'appState/selectors';
import Styled from './BroadcastPhonePreview.styles';

const BroadcastPhonePreview = props => {
  const { emailBody, smsBody, smsMedia, phoneScale, isEmail } = props;
  const imagePreviewEl = useRef(null);

  const errorSelector = createErrorMessageSelector([
    'broadcastCenter/BROADCAST_FETCH_PREVIEW'
  ]);
  const loadedSelector = createLoadedSelector([
    'broadcastCenter/BROADCAST_FETCH_PREVIEW'
  ]);
  const structuredSelector = createStructuredSelector({
    error: state => errorSelector(state),
    isLoaded: state => loadedSelector(state)
  });
  const { error, isLoaded } = useSelector(structuredSelector);

  function loadMediaPreview(url) {
    fetch(url)
      .then(res => {
        return res.blob();
      })
      .then(res => {
        if (res) {
          readURLOriginalRatio(res, imagePreviewEl);
        }
      });
  }

  useEffect(() => {
    loadMediaPreview(smsMedia);
  }, [smsMedia]);

  const randomLetter = () =>
    String.fromCharCode(65 + Math.floor(Math.random() * 23));

  function randomInitials() {
    return `${randomLetter()}${randomLetter()}`;
  }

  return (
    <Fragment>
      <Styled.Phone phoneScale={phoneScale}>
        <Styled.Screen phoneScale={phoneScale}>
          {!isLoaded && isEmpty(error) ? (
            <div className="h-400px center-vh">
              <svg className="spinner-circle-material-svg" viewBox="0 0 50 50">
                <circle className="circle" cx="25" cy="25" r="20" />
              </svg>
            </div>
          ) : (
            <div>
              {isEmail || emailBody ? (
                <div dangerouslySetInnerHTML={{ __html: emailBody }} />
              ) : (
                <div className="d-flex flex-column" style={{ height: '100%' }}>
                  <div className="full-width flex-grow">
                    <div id="chat-content">
                      <div className="media media-chat media-chat-reverse pr-0 pl-20">
                        <div className="media-body">
                          <p>
                            {isEmpty(smsBody) && isEmpty(smsMedia)
                              ? 'Template has no saved sms body. Update template to add an sms body...'
                              : textTruncate(smsBody)}
                            {/* MMS Media Preview */}
                            <span
                              className={`${smsMedia ? 'd-block' : 'd-none'}`}
                              style={{
                                maxWidth: '240px',
                                maxHeight: '240px',
                                position: 'relative',
                                overflow: 'hidden'
                              }}
                            >
                              <img
                                alt="Preview MMS"
                                ref={imagePreviewEl}
                                src={loadingGif}
                              />
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="media media-chat pl-0">
                        <a
                          id="full-chat-window-contact-info"
                          className="avatar"
                        >
                          {randomInitials()}
                        </a>
                        <div className="media-body">
                          <p>...</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </Styled.Screen>
        <Styled.Speaker phoneScale={phoneScale} />
        <Styled.Home phoneScale={phoneScale} />
      </Styled.Phone>
    </Fragment>
  );
};

BroadcastPhonePreview.defaultProps = {
  emailBody: '',
  smsBody: '',
  smsMedia: '',
  phoneScale: 1,
  isEmail: false
};

// const styleTypes = PropTypes.oneOfType([PropTypes.string, PropTypes.number]);

BroadcastPhonePreview.propTypes = {
  emailBody: PropTypes.string,
  smsBody: PropTypes.string,
  smsMedia: PropTypes.string,
  phoneScale: PropTypes.number,
  isEmail: PropTypes.bool
};

export default BroadcastPhonePreview;
