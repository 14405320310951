import React from 'react';
import { withA11y } from '@storybook/addon-a11y';
import { withKnobs, object } from '@storybook/addon-knobs';
import Breadcrumbs from './index';

export default {
  component: Breadcrumbs,
  title: 'Breadcrumbs',
  decorators: [withA11y, withKnobs]
};

export const lightBackground = () => {
  return (
    <Breadcrumbs
      crumbs={object('Breadcrumbs', [
        {
          name: 'Overlord',
          action: () => null
        },
        {
          name: 'Partner',
          action: () => null
        },
        {
          name: 'Product',
          action: () => null
        },
        {
          name: 'Included Form',
          action: () => null
        },
        {
          name: 'Settings',
          action: () => null
        }
      ])}
    />
  );
};

export const darkBackground = () => {
  return (
    <div className="tw-bg-gray-500 tw-h-10 tw-w-100 tw-px-5 tw-pt-3">
      <Breadcrumbs
        crumbs={object('Breadcrumbs', [
          {
            name: 'Overlord',
            action: () => null
          },
          {
            name: 'Partner',
            action: () => null
          },
          {
            name: 'Product',
            action: () => null
          },
          {
            name: 'Included Form',
            action: () => null
          },
          {
            name: 'Settings',
            action: () => null
          }
        ])}
        isBackgroundWhite={false}
      />
    </div>
  );
};
