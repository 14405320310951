import React from 'react';
import PropTypes from 'prop-types';

const CurrentUserContext = React.createContext();

const CurrentUserProvider = props => {
  const { children, value } = props;
  return (
    <CurrentUserContext.Provider value={value}>
      {children}
    </CurrentUserContext.Provider>
  );
};

CurrentUserProvider.defaultProps = {
  children: null,
  value: null
};

CurrentUserProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

export { CurrentUserContext, CurrentUserProvider };
