import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { createLoadedSelector } from 'app-state/selectors';
import { fetchOptInForm } from 'app-state/actions/ActionCreators';
import useWindowDimensions from 'components/shared/hooks/useWindowDimensions';
import { FETCH_OPT_IN_FORM } from 'app-state/actions/constants/optinform.actions';
import { GoogleMap } from './GoogleMap';
import { OptInLandingForm } from './OptInForm';

const MOBILE_BREAKPOINT = 769;
const OptInPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { windowWidth } = useWindowDimensions();
  const isMobile = windowWidth < MOBILE_BREAKPOINT;

  const optInFormLoadedSelector = createLoadedSelector([FETCH_OPT_IN_FORM]);

  const structuredSelector = createStructuredSelector({
    accountFullAddress: state => state.optInForm.accountFullAddress,
    accountLogo: state => state.optInForm.accountLogo,
    googleApiPublicKey: state => state.optInForm.googleApiPublicKey,
    landingPageButtonText: state => state.optInForm.landingPageButtonText,
    latitude: state => state.optInForm.latitude,
    longitude: state => state.optInForm.longitude,
    newLeadLandingPageTitle: state => state.optInForm.newLeadLandingPageTitle,
    privacyPolicyUrl: state => state.optInForm.privacyPolicyUrl,
    recaptchaKey: state => state.optInForm.recaptchaKey,
    repactchaEnabled: state => state.optInForm.repactchaEnabled,
    isOptFormLoaded: state => optInFormLoadedSelector(state)
  });

  const {
    accountFullAddress,
    accountLogo,
    googleApiPublicKey,
    landingPageButtonText,
    latitude,
    longitude,
    newLeadLandingPageTitle,
    privacyPolicyUrl,
    recaptchaKey,
    repactchaEnabled,
    isOptFormLoaded
  } = useSelector(structuredSelector);

  useEffect(() => {
    dispatch(
      fetchOptInForm({
        journeyId: id
      })
    );
  }, [dispatch, id]);

  function renderComponents() {
    if (!isOptFormLoaded) {
      return (
        <div className="tw-flex tw-w-full tw-items-center tw-justify-center tw-rounded-lg tw-bg-white tw-m-8 tw-mb-20 tw-min-h-screen">
          <div className="center-vh">
            <div className="spinner-dots">
              <span className="dot1" />
              <span className="dot2" />
              <span className="dot3" />
            </div>
          </div>
        </div>
      );
    }

    return (
      <>
        <div className={`tw-w-1/2 tw-bg-white ${isMobile && 'tw-w-full'}`}>
          <OptInLandingForm
            buttonText={landingPageButtonText}
            accountLogo={accountLogo}
            title={newLeadLandingPageTitle}
            privacyPolicyUrl={privacyPolicyUrl}
            recaptchaKey={recaptchaKey}
            repactchaEnabled={repactchaEnabled}
            journeyId={id}
          />
        </div>
        {isMobile || (
          <div className="tw-w-1/2 tw-bg-white">
            <GoogleMap
              apiKey={googleApiPublicKey}
              longitude={longitude}
              latitude={latitude}
              address={accountFullAddress}
            />
          </div>
        )}
      </>
    );
  }

  return (
    <div className="tw-flex" style={{ minHeight: '100vh' }}>
      {renderComponents()}
    </div>
  );
};

export default OptInPage;
