import React from 'react';
import { withA11y } from '@storybook/addon-a11y';
import { withKnobs, text, select, boolean } from '@storybook/addon-knobs';
import Notification from './index';

export default {
  component: Notification,
  title: 'Notification',
  decorators: [withA11y, withKnobs]
};

let typeSelect;
let colorSelect;

function includeTypeSelect(defaultValue) {
  const label = 'Type';
  const options = ['standard', 'colored'];
  typeSelect = select(label, options, defaultValue);
}

function includeColorSelect(defaultValue) {
  const label = 'Color';
  const options = [
    'alpha',
    'bravo',
    'charlie',
    'success',
    'warning',
    'error',
    'gray'
  ];
  colorSelect = select(label, options, defaultValue);
}

export const standard = () => {
  includeTypeSelect('standard');
  includeColorSelect('success');

  return (
    <Notification
      message={text('Message', 'Anyone with a link can now view this file.')}
      headerText={text('Header Text', 'Successfully saved!')}
      color={colorSelect}
      type={typeSelect}
      show={boolean('Show', true)}
      timeout={0}
      HeaderIcon={color => {
        return (
          <svg
            className={`tw-h-6 tw-w-6 tw-text-${color}-400`}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        );
      }}
    />
  );
};

export const error = () => {
  includeTypeSelect('colored');
  includeColorSelect('error');
  return (
    <Notification
      message={text(
        'Message',
        'This is an error message. You should do something about it!'
      )}
      headerText={text('Header Text', 'Error!')}
      color={colorSelect}
      type={typeSelect}
      show={boolean('Show', true)}
      timeout={0}
      HeaderIcon={color => {
        return (
          <svg
            className={`tw-h-5 tw-w-5 tw-text-${color}-400`}
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
              clipRule="evenodd"
            />
          </svg>
        );
      }}
    />
  );
};

export const success = () => {
  includeTypeSelect('colored');
  includeColorSelect('success');
  return (
    <Notification
      message={text(
        'Message',
        'This is a success message. Congrats, you did it!'
      )}
      headerText={text('Header Text', 'Success!')}
      color={colorSelect}
      type={typeSelect}
      show={boolean('Show', true)}
      timeout={0}
      HeaderIcon={color => {
        return (
          <svg
            className={`tw-h-5 tw-w-5 tw-text-${color}-400`}
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clipRule="evenodd"
            />
          </svg>
        );
      }}
    />
  );
};
