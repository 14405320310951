import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { toCamel } from 'lib/utils/string';

const TextArea = ({
  id,
  value,
  defaultValue,
  labelText,
  rows,
  placeholder,
  required,
  textAreaStyle,
  containerStyle,
  labelStyle,
  onChange,
  onBlur,
  showError,
  error,
  helpText,
  hideLabel,
  widthClass
}) => {
  function getName() {
    return toCamel(id);
  }

  return (
    <div className={widthClass} style={containerStyle}>
      <label
        htmlFor={id}
        className={
          hideLabel
            ? 'tw-sr-only'
            : `tw-font-body tw-block tw-text-sm tw-leading-5 tw-font-medium tw-text-gray-700`
        }
        style={labelStyle}
      >
        {labelText}
        <span className="tw-text-error-700">{required ? '*' : null}</span>
      </label>
      <div className="tw-rounded-md tw-shadow-sm">
        <div className="tw-mt-1 tw-relative">
          <textarea
            id={id}
            data-testid={id}
            rows={rows}
            className={`tw-form-textarea tw-font-body tw-mt-1 tw-block tw-w-full tw-transition tw-duration-150 tw-ease-in-out sm:tw-text-sm sm:tw-leading-5 tw-border tw-border-solid tw-border-${
              showError ? 'red' : 'gray'
            }-300 focus:tw-outline-none focus:tw-shadow-outline-${
              showError ? 'red' : 'blue'
            } focus:tw-border-${showError ? 'red' : 'blue'}-300`}
            style={textAreaStyle}
            placeholder={placeholder}
            name={getName()}
            value={value}
            defaultValue={defaultValue}
            onChange={onChange}
            onBlur={onBlur}
          />
          {showError ? (
            <Fragment>
              <div className="tw-absolute tw-inset-y-2 tw-right-0 tw-pr-3 tw-flex tw-items-start tw-pointer-events-none">
                <svg
                  className="tw-h-5 tw-w-5 tw-text-red-500"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </Fragment>
          ) : null}
        </div>
      </div>
      {helpText ? (
        <p className="tw-font-body tw-my-2 tw-text-sm tw-text-gray-500">
          {helpText}
        </p>
      ) : null}
      {showError ? (
        <p className="tw-font-body tw-my-2 tw-text-sm tw-text-red-600">
          {error}
        </p>
      ) : null}
    </div>
  );
};

TextArea.defaultProps = {
  value: undefined,
  defaultValue: undefined,
  placeholder: '',
  rows: 3,
  required: false,
  textAreaStyle: {},
  containerStyle: {},
  labelStyle: {},
  onChange: null,
  onBlur: null,
  showError: false,
  error: '',
  helpText: '',
  hideLabel: false,
  widthClass: 'tw-col-span-10'
};

const styleProps = PropTypes.objectOf(
  PropTypes.oneOfType([PropTypes.string, PropTypes.number])
);

TextArea.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  labelText: PropTypes.string.isRequired,
  rows: PropTypes.number,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  textAreaStyle: styleProps,
  containerStyle: styleProps,
  labelStyle: styleProps,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  showError: PropTypes.bool,
  error: PropTypes.string,
  helpText: PropTypes.string,
  hideLabel: PropTypes.bool,
  widthClass: PropTypes.string
};

export default TextArea;
