import styled from 'styled-components';

const Phone = styled.div`
  width: ${props => props.phoneScale * 220}px;
  height: ${props => props.phoneScale * 454.19}px;
  border: 2px solid #ccc;
  border-radius: 30px;
  position: relative;
  background: #fff;
`;

const Screen = styled.div`
  width: ${props => props.phoneScale * 200}px;
  height: ${props => props.phoneScale * 350}px;
  border: 1px solid #ccc;
  position: absolute;
  left: ${props => props.phoneScale * 8}px;
  top: ${props => props.phoneScale * 45}px;
  box-sizing: border-box;
  overflow: hidden;
`;

const Speaker = styled.div`
  width: ${props => props.phoneScale * 50}px;
  height: ${props => props.phoneScale * 6}px;
  border: 1px solid #ccc;
  border-radius: 6px;
  position: absolute;
  left: 50%;
  top: ${props => props.phoneScale * 25}px;
  margin: 0 -${props => props.phoneScale * 25}px;
`;

const Home = styled.div`
  width: ${props => props.phoneScale * 36}px;
  height: ${props => props.phoneScale * 36}px;
  border: 1px solid #ccc;
  position: absolute;
  bottom: ${props => props.phoneScale * 10}px;
  left: 50%;
  margin: 0 -${props => props.phoneScale * 18}px;
  border-radius: 50%;
`;

const Styled = {
  Phone,
  Screen,
  Speaker,
  Home
};

export default Styled;
