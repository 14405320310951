import React, { useState, useEffect, useCallback } from 'react';
import Table from 'components/Theme/Table';
import Pagination from 'components/Theme/Pagination';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { MATCH_LIMIT } from 'config/constants';

const includedColumns = {
  source: 'source',
  firstName: 'first name',
  lastName: 'last name',
  email: 'email',
  status: 'lifecycle/status'
};

const MatchContacts = ({ syncData, updateEvent, display, isSticky }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState(
    syncData.slice(0, MATCH_LIMIT)
  );
  const totalItems = syncData.length;
  const totalPages = Math.ceil(totalItems / MATCH_LIMIT);
  const updatePage = useCallback(
    (data = null) => {
      const pageData = get(data, 'page');
      const pageNum = pageData || currentPage;
      const pageCal = (pageNum - 1) * MATCH_LIMIT;
      const multipleRows = syncData.slice(pageCal, pageCal + MATCH_LIMIT);
      if (pageData) {
        setCurrentPage(pageNum);
      }
      setCurrentData(multipleRows);
    },
    [syncData, currentPage]
  );
  const rowIndexOffset = (currentPage - 1) * MATCH_LIMIT;
  useEffect(() => {
    updatePage();
  }, [updatePage, syncData, currentPage]);
  useEffect(() => {
    setCurrentPage(1);
  }, [display]);
  return (
    <div>
      <div>
        <div className="tw-max-w-10xl tw-mt-12">
          <Table
            title=""
            subtitle=""
            rows={currentData}
            includedColumns={includedColumns}
            hasToggleActionPerRecord
            disabledTogglePositions={[0]}
            onClickToggleBtn={updateEvent}
            hasStatus
            hasFooter
            showStatusColor
            isSticky={isSticky}
            rowIndexOffset={rowIndexOffset}
            FooterComponent={() =>
              totalPages && totalPages > 1 ? (
                <Pagination
                  total={totalItems}
                  totalPages={totalPages}
                  limit={MATCH_LIMIT}
                  action={updatePage}
                  tableIdentifier={display ? 'matchContact' : 'matchEmail'}
                />
              ) : null
            }
            emptyMessage="No action required"
            popOverInfo="lifecycle/status"
            customActionHeader="Connect"
          />
        </div>
      </div>
    </div>
  );
};
MatchContacts.propTypes = {
  syncData: PropTypes.instanceOf(Array).isRequired,
  updateEvent: PropTypes.func.isRequired,
  display: PropTypes.bool.isRequired,
  isSticky: PropTypes.bool.isRequired
};

export default MatchContacts;
