import React, { useState } from 'react';
import PropTypes from 'prop-types';
import HeroIcon from 'components/Theme/HeroIcon';

const Accordion = ({
  label,
  children,
  color,
  onLabelClick,
  isExpanded,
  coloredBg,
  contentCentered
}) => {
  const [expandAccordion, setExpandAccordion] = useState(isExpanded || false);
  const handleLabelClick = () => {
    setExpandAccordion(!expandAccordion);
    onLabelClick();
  };

  const tabContentClasses = expandAccordion
    ? 'tw-max-h-screen tw-flex'
    : 'tw-hidden';
  const bgColor = coloredBg ? color : 'gray';

  const getIcon = () => {
    return (
      <HeroIcon color={color} icon={expandAccordion ? 'x-circle' : 'plus'} />
    );
  };

  return (
    <div className="tw-mx-auto tw-my-4 tw-min-w-300 tw-rounded-lg tw-shadow-md tw-bg-white tw-flex-col">
      <div
        className={`tw-p-5 tw-cursor-pointer tw-text-${color}-500 tw-capitalize`}
        onClick={handleLabelClick}
        onKeyDown={null}
        role="button"
        tabIndex={-1}
      >
        <span>
          <label className="tw-text-lg tw-cursor-pointer">{label}</label>
        </span>
        <span className="tw-float-right tw-h-6 tw-w-6 tw-inline-block">
          {getIcon()}
        </span>
      </div>
      <div
        className={`${tabContentClasses} tw-rounded-b-lg tw-p-5 tw-bg-${bgColor}-200 tw-overflow-auto`}
      >
        <div className={`${contentCentered ? 'tw-mx-auto' : ''}`}>
          {children}
        </div>
      </div>
    </div>
  );
};

Accordion.defaultProps = {
  label: '',
  color: 'alpha',
  children: null,
  isExpanded: false,
  coloredBg: false,
  onLabelClick: () => {},
  contentCentered: false
};

Accordion.propTypes = {
  label: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  isExpanded: PropTypes.bool,
  coloredBg: PropTypes.bool,
  onLabelClick: PropTypes.func,
  contentCentered: PropTypes.bool
};

export default Accordion;
