import React from 'react';
import { action } from '@storybook/addon-actions';
import { withA11y } from '@storybook/addon-a11y';
import { withKnobs, text, select, boolean } from '@storybook/addon-knobs';
import Modal from './index';

export default {
  component: Modal,
  title: 'Modal',
  decorators: [withA11y, withKnobs]
};

let colorSelect;

function includeColorSelect(defaultValue) {
  const label = 'Color';
  const options = [
    'alpha',
    'bravo',
    'charlie',
    'success',
    'warning',
    'error',
    'gray'
  ];
  colorSelect = select(label, options, defaultValue);
}

const steps = [
  {
    id: 1,
    text: 'step one',
    buttonText: 'Go to step two',
    content: (
      <div className="tw-font-body tw-text-sm tw-leading-5 tw-text-gray-500">
        Step 1
      </div>
    ),
    action: () => console.log('Step 1 Button clicked')
  },
  {
    id: 2,
    text: 'step two',
    buttonText: 'Go to step three',
    content: (
      <div className="tw-font-body tw-text-sm tw-leading-5 tw-text-gray-500">
        Step 2
      </div>
    ),
    action: () => console.log('Step 2 Button clicked')
  },
  {
    id: 3,
    text: 'step three',
    buttonText: 'Go to step four',
    content: (
      <div className="tw-font-body tw-text-sm tw-leading-5 tw-text-gray-500">
        Step 3
      </div>
    ),
    action: () => console.log('Step 3 Button clicked')
  },
  {
    id: 4,
    text: 'step four',
    buttonText: 'Submit',
    content: (
      <div className="tw-font-body tw-text-sm tw-leading-5 tw-text-gray-500">
        Step 4
      </div>
    ),
    action: () => console.log('Step 4 Button clicked')
  }
];

export const standard = () => {
  includeColorSelect('error');

  return (
    <Modal
      headerText={text('Header Text', 'Delete Plan?')}
      bodyText={text('bodyText', 'Are you sure you want to delete this plan?')}
      primaryAction={action('primary-action-click')}
      primaryActionText={text('Primary Action Text', 'Delete')}
      showSecondaryAction={boolean('Show Secondary Action', true)}
      secondaryAction={action('secondary-action-click')}
      show={boolean('Show Modal', true)}
      color={colorSelect}
      showHeaderIcon={boolean('Show Header Icon', true)}
      HeaderIcon={color => {
        return (
          <svg
            className={`tw-h-6 tw-w-6 tw-text-${color}-600`}
            stroke="currentColor"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
            />
          </svg>
        );
      }}
    />
  );
};

export const withoutHeaderIcon = () => {
  includeColorSelect('error');

  return (
    <Modal
      headerText={text('Header Text', 'Delete Plan?')}
      bodyText={text('bodyText', 'Are you sure you want to delete this plan?')}
      primaryAction={action('primary-action-click')}
      primaryActionText={text('Primary Action Text', 'Delete')}
      showSecondaryAction={boolean('Show Secondary Action', true)}
      secondaryAction={action('secondary-action-click')}
      show={boolean('Show Modal', true)}
      color={colorSelect}
      showHeaderIcon={boolean('Show Header Icon', false)}
    />
  );
};

export const withoutSecondaryAction = () => {
  includeColorSelect('error');

  return (
    <Modal
      headerText={text('Header Text', 'Delete Plan?')}
      bodyText={text('bodyText', 'Are you sure you want to delete this plan?')}
      primaryAction={action('primary-action-click')}
      primaryActionText={text('Primary Action Text', 'Delete')}
      showSecondaryAction={boolean('Show Secondary Action', false)}
      secondaryAction={action('secondary-action-click')}
      show={boolean('Show Modal', true)}
      color={colorSelect}
      showHeaderIcon={boolean('Show Header Icon', true)}
      HeaderIcon={color => {
        return (
          <svg
            className={`tw-h-6 tw-w-6 tw-text-${color}-600`}
            stroke="currentColor"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
            />
          </svg>
        );
      }}
    />
  );
};

export const withSteps = () => {
  includeColorSelect('alpha');

  return (
    <Modal
      headerText={text('Header Text', 'Delete Plan?')}
      steps={steps}
      selectedStep={1}
      primaryAction={action('primary-action-click')}
      primaryActionText={text('Primary Action Text', 'Delete')}
      showSecondaryAction={boolean('Show Secondary Action', true)}
      secondaryAction={action('secondary-action-click')}
      show={boolean('Show Modal', true)}
      color={colorSelect}
      showHeaderIcon={boolean('Show Header Icon', false)}
    />
  );
};
