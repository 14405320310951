import styled from 'styled-components';

const MainContent = styled.div`
  height: calc(100vh - 170px);
  max-width: ${props => (props.largerContent ? '1600px' : '1400px')};
  margin: 0 auto;
  margin-top: ${props => (props.isTemplate ? '72px' : '60px')};
`;

const TemplateBorderBadgeContainer = styled.div`
  position: fixed;
  top: 91px;
  left: 50%;
  z-index: 10;

  @media (max-width: 991.98px) {
    left: calc(50% - 50px);
  }

  @media (max-width: 767.98px) {
    top: 82px;
  }
`;

const Row = styled.div`
  padding-bottom: 80px;
`;

const Styled = {
  MainContent,
  TemplateBorderBadgeContainer,
  Row
};

export default Styled;
