import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import BeePlugin from '@mailupinc/bee-plugin';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import isEmpty from 'lodash.isempty';
import {
  broadcastFetchEmailMergeTags,
  broadcastSetPreviewEmailFired,
  resetIsLoaded
} from 'appState/actions/ActionCreators';
import { EnvVariablesContext } from 'components/shared/context/EnvVariablesContext';
import { CurrentUserContext } from 'components/shared/context/CurrentUserContext';
import { CurrentAccountContext } from 'components/shared/context/CurrentAccountContext';
import { parameterize } from 'lib/utils/string';

let beeEditor;

const mergeContents = [
  {
    name: 'Account Logo',
    value: '{{ account_logo.block }}'
  },
  {
    name: 'Social Media Footer',
    value: '{{ social_media.block }}'
  },
  {
    name: 'Compliance Footer',
    value: '{{ compliance_footer.block }}'
  },
  {
    name: 'Journey Call to Action',
    value: '{{ journey_cta.block }}'
  }
];

const Bee = props => {
  const {
    onSave,
    onAutoSave,
    onSend,
    onLoad,
    onSaveAsTemplate,
    onChange,
    beeConfig,
    style,
    className
  } = props;

  const [template, setTemplate] = useState();
  const [beeEditorStarted, setBeeEditorStarted] = useState(false);
  const env = useContext(EnvVariablesContext);
  const currentUser = useContext(CurrentUserContext);
  const currentAccount = useContext(CurrentAccountContext);
  const dispatch = useDispatch();

  const CLIENT_ID = env.BEE_CLIENT_ID;
  const CLIENT_SECRET = env.BEE_CLIENT_SECRET;

  const structuredSelector = createStructuredSelector({
    currentBroadcast: state => state.broadcastCenter.currentBroadcast,
    mergeFields: state => state.broadcastCenter.mergeFields,
    specialLinks: state => state.broadcastCenter.specialLinks,
    isCallingBroadcastSave: state =>
      state.broadcastCenter.isCallingBroadcastSave,
    testEmailAddress: state => state.broadcastCenter.testEmailAddress,
    previewEmailFired: state => state.broadcastCenter.previewEmailFired
  });

  const {
    currentBroadcast,
    mergeFields,
    specialLinks,
    isCallingBroadcastSave,
    testEmailAddress,
    previewEmailFired
  } = useSelector(structuredSelector);

  const DEFAULT_CONFIGURATION = {
    container: 'email-bee-container', // Identifies the id of div element that contains BEE Plugin.
    autosave: 60,
    language: 'en-US',
    preventClose: false,
    mergeContents,
    trackChanges: true,
    onSave,
    onAutoSave,
    onChange,
    onSaveAsTemplate,
    onSend,
    onLoad,
    uid: `uplaunch-${env.NODE_ENV}-account-id-${
      currentAccount.id
    }-${parameterize(currentAccount.name)}`,
    roleHash: `${
      // eslint-disable-next-line no-nested-ternary
      currentUser.overlord
        ? 'uplaunch-staff'
        : currentUser.partner
        ? 'uplaunch-partner'
        : 'uplaunch-user'
    }`
  };

  useEffect(() => {
    if (currentBroadcast.email_json_draft || currentBroadcast.email_json) {
      const emailJson =
        currentBroadcast.email_json_draft || currentBroadcast.email_json;
      let emailTemplate;
      try {
        emailTemplate = JSON.parse(emailJson);
      } catch (e) {
        emailTemplate = emailJson;
      }
      setTemplate(emailTemplate);
    }
  }, [currentBroadcast]);

  useEffect(() => {
    if (currentBroadcast.id) {
      dispatch(
        broadcastFetchEmailMergeTags({ broadcastId: currentBroadcast.id })
      );
    }
  }, [currentBroadcast.id, dispatch]);

  useEffect(() => {
    if (isCallingBroadcastSave && beeEditor) beeEditor.save();
  }, [isCallingBroadcastSave]);

  useEffect(() => {
    if (!isEmpty(testEmailAddress) && beeEditor) beeEditor.send();
  }, [testEmailAddress]);

  useEffect(() => {
    if (previewEmailFired && beeEditor) {
      beeEditor.send();
      dispatch(broadcastSetPreviewEmailFired(false));
      dispatch(resetIsLoaded('broadcastCenter/BROADCAST_FETCH_PREVIEW'));
    }
  }, [previewEmailFired]);

  function parseTemplate(_template) {
    const stringifiedTemplate = JSON.stringify(_template);
    const parsedTemplate = JSON.parse(
      stringifiedTemplate
        .replace(/{{ broadcast_sender.first_name }}/g, currentUser.first_name)
        .replace(/{{ broadcast_sender.full_name }}/g, currentUser.full_name)
    );
    return parsedTemplate;
  }

  function onFetchBeeToken(clientId, clientSecret, _beeEditor) {
    return _beeEditor.getToken(clientId, clientSecret);
  }

  useEffect(() => {
    if (template && !isEmpty(mergeFields) && !isEmpty(specialLinks)) {
      const beeFinalConfig = {
        ...DEFAULT_CONFIGURATION,
        ...beeConfig,
        mergeTags: mergeFields,
        specialLinks
      };
      const parsedTemplate = parseTemplate(template);
      if (!beeEditor) {
        beeEditor = new BeePlugin();
      }
      if (beeEditor) {
        // ensure fresh token is requested on each load of the Bee plugin
        delete beeEditor.token;
      }
      onFetchBeeToken(CLIENT_ID, CLIENT_SECRET, beeEditor).then(() => {
        if (!beeEditorStarted) {
          beeEditor.start(beeFinalConfig, parsedTemplate);
          setBeeEditorStarted(true);
        }
      });
    }
  }, [template, mergeFields, specialLinks]);

  return <div id="email-bee-container" style={style} className={className} />;
};

Bee.defaultProps = {
  beeConfig: {},
  style: {}
};

Bee.propTypes = {
  onSave: PropTypes.func.isRequired,
  onAutoSave: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
  onLoad: PropTypes.func.isRequired,
  onSaveAsTemplate: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  beeConfig: PropTypes.object,
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  className: PropTypes.string.isRequired
};

export default Bee;
