// linting rules disabled for immer js
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  FETCH_PLANS_SUCCESS,
  FETCH_PLAN_PAGE_SUCCESS,
  CREATE_PLAN_SUCCESS,
  UPDATE_PLAN_SUCCESS,
  DELETE_PLAN_SUCCESS,
  AVAILABLE_PLAN_SUCCESS,
  RESET_CURRENT_PLAN,
  FETCH_SMS_CREDIT_PACKS_SUCCESS,
  FETCH_SMS_CREDIT_PACK_SUCCESS,
  CREATE_SMS_CREDIT_PACK_SUCCESS,
  UPDATE_SMS_CREDIT_PACK_SUCCESS,
  DELETE_SMS_CREDIT_PACK_SUCCESS,
  FETCH_ONBOARDING_FEES_SUCCESS,
  FETCH_ONBOARDING_FEE_SUCCESS,
  CREATE_ONBOARDING_FEE_SUCCESS,
  UPDATE_ONBOARDING_FEE_SUCCESS,
  DELETE_ONBOARDING_FEE_SUCCESS,
  FETCH_PARTNER_SUCCESS
} from 'appState/actions/constants/plan.actions';

const initialState = {
  plans: [],
  plansAvailableForUser: [],
  currentPlan: {},
  currentPricingInformation: {},
  smsEnabledCountries: [],
  currentSmsEnabledCountry: {},
  smsCreditPacks: [],
  currentSmsCreditPack: {},
  onboardingFees: [],
  currentOnboardingFee: {},
  partners: []
};

export default function PlanReducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case FETCH_PLANS_SUCCESS: {
        const { plans } = action.payload;
        draft.plans = plans;
        return;
      }
      case FETCH_PLAN_PAGE_SUCCESS: {
        const { plan } = action.payload;
        draft.currentPlan = plan;
        return;
      }
      case CREATE_PLAN_SUCCESS:
      case UPDATE_PLAN_SUCCESS: {
        const { plan } = action.payload;
        draft.currentPlan = plan;
        return;
      }
      case DELETE_PLAN_SUCCESS: {
        const { planId } = action.payload;
        draft.currentPlan = {};
        draft.plans = draft.plans.filter(plan => plan.id !== planId);
        return;
      }
      case RESET_CURRENT_PLAN: {
        draft.currentPlan = {};
        draft.smsEnabledCountries = [];
        return;
      }
      case AVAILABLE_PLAN_SUCCESS: {
        const { plansAvailableForUser } = action.payload;
        draft.plansAvailableForUser = plansAvailableForUser;
        return;
      }
      case FETCH_SMS_CREDIT_PACKS_SUCCESS: {
        const { smsCreditPacks } = action.payload;
        draft.smsCreditPacks = smsCreditPacks;
        return;
      }
      case FETCH_SMS_CREDIT_PACK_SUCCESS:
      case CREATE_SMS_CREDIT_PACK_SUCCESS:
      case UPDATE_SMS_CREDIT_PACK_SUCCESS: {
        const { smsCreditPack } = action.payload;
        draft.currentSmsCreditPack = smsCreditPack;
        return;
      }
      case DELETE_SMS_CREDIT_PACK_SUCCESS: {
        draft.currentSmsCreditPack = {};
        return;
      }
      case FETCH_ONBOARDING_FEES_SUCCESS: {
        const { onboardingFees } = action.payload;
        draft.onboardingFees = onboardingFees;
        return;
      }
      case FETCH_ONBOARDING_FEE_SUCCESS:
      case CREATE_ONBOARDING_FEE_SUCCESS:
      case UPDATE_ONBOARDING_FEE_SUCCESS: {
        const { onboardingFee } = action.payload;
        draft.currentOnboardingFee = onboardingFee;
        return;
      }
      case DELETE_ONBOARDING_FEE_SUCCESS: {
        draft.currentOnboardingFee = {};
        return;
      }
      case FETCH_PARTNER_SUCCESS: {
        const { partners } = action.payload;
        draft.partners = partners;
        return;
      }
      default:
        return draft;
    }
  });
}
