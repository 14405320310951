import moment from 'moment';
import uniqueArray from 'lib/utils/array';

const timeStringsByIncrement = (desiredStartTime, interval, period) => {
  const periodsInADay = moment.duration(1, 'day').as(period);

  const timeStrings = [];
  const startTimeMoment = moment(desiredStartTime, 'hh:mm');
  for (let i = 0; i <= periodsInADay; i += interval) {
    startTimeMoment.add(i === 0 ? 0 : interval, period);
    timeStrings.push(startTimeMoment.format('hh:mm A'));
  }

  return uniqueArray(timeStrings);
};

export default timeStringsByIncrement;
