import React, { useContext } from 'react';
import { CurrentAccountContext } from 'components/shared/context/CurrentAccountContext';
import CustomGroupAssigner from './CustomGroupAssigner';
import BulkCampaignChanger from './BulkCampaignChanger';
import BulkJourneyChanger from './BulkJourneyChanger';
import BulkContactUnsubscriber from './BulkContactUnsubscriber';
import ContactMerger from './ContactMerger';

const ContactUtilities = () => {
  const currentAccount = useContext(CurrentAccountContext);

  return (
    <div className="tab-pane active" id="contact" role="tabpanel">
      <div className="row">
        <div className="col-lg-6 pl-10">
          <CustomGroupAssigner />
        </div>
        <div className="col-lg-6 pl-10">
          <BulkContactUnsubscriber />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 pl-10">
          <BulkCampaignChanger />
        </div>
        <div className="col-lg-6 pl-10">
          <ContactMerger />
        </div>
      </div>
      {!currentAccount.mod_campaigns_enabled && (
        <div className="row">
          <div className="col-lg-6 pl-10">
            <BulkJourneyChanger />
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactUtilities;
