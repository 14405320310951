import React from 'react';
import PropTypes from 'prop-types';
import { formikShape } from 'lib/utils/prop-types-extensions';
import Select from 'components/Theme/Select';
import HeroIcon from 'components/Theme/HeroIcon';
import isEmpty from 'lodash.isempty';

const ZenPlannerDetail = ({
  programName,
  id,
  formik,
  campaignSelectOptions
}) => {
  return (
    <div key={id}>
      <div
        key={programName}
        className="tw-bg-gray-100 tw-px-4 tw-py-3 tw-font-medium sm:tw-px-6"
      >
        {programName}
      </div>
      <ZenPlannerItem
        key={`${id}_lead`}
        programName={`${programName} Prospect`}
        lifecyle="lead"
        id={id}
        formik={formik}
        campaignSelectOptions={campaignSelectOptions}
      />
      <ZenPlannerItem
        key={`${id}_client`}
        programName={`${programName} Member`}
        lifecyle="client"
        id={id}
        formik={formik}
        campaignSelectOptions={campaignSelectOptions}
      />
      <ZenPlannerItem
        key={`${id}_former_client`}
        programName={`${programName} Alumni`}
        lifecyle="former_client"
        id={id}
        formik={formik}
        campaignSelectOptions={campaignSelectOptions}
      />
    </div>
  );
};

ZenPlannerDetail.propTypes = {
  formik: PropTypes.shape(formikShape).isRequired,
  id: PropTypes.string.isRequired,
  programName: PropTypes.string,
  campaignSelectOptions: PropTypes.arrayOf.isRequired
};

ZenPlannerDetail.defaultProps = {
  programName: ''
};

export const ZenPlannerItem = ({
  programName,
  lifecyle,
  id,
  formik,
  campaignSelectOptions
}) => {
  const getProductIdCampaignId = (programId, lifeCycleName) => {
    const { uplaunchProducts } = formik.values;

    const product = uplaunchProducts.find(
      p => p?.program_id === programId && p?.lifecycle === lifeCycleName
    );
    if (isEmpty(product)) return {};
    return product;
  };

  const { product_id, campaign_id } = getProductIdCampaignId(id, lifecyle);
  const productOption = [];
  const campaignFilterOptions = [];
  campaignSelectOptions.forEach(campaign => {
    if (
      campaign.lifeCycle === lifecyle &&
      productOption.findIndex(p => p?.value === campaign.productId) < 1
    ) {
      productOption.push({
        displayName: campaign.productName,
        value: campaign.productId
      });
    }
    if (campaign.productId === product_id && campaign.lifeCycle === lifecyle) {
      campaignFilterOptions.push(campaign);
    }
  });

  const handleProductChange = (value, programId, lifeCycleName) => {
    const { uplaunchProducts } = formik.values;
    const index = uplaunchProducts.findIndex(
      p => p?.program_id === programId && p?.lifecycle === lifeCycleName
    );
    const productValue = parseInt(value);
    if (index < 0) {
      const updateObject = {
        id: null,
        program_id: programId,
        product_id: productValue,
        campaign_id: null,
        lifecycle: lifeCycleName,
        isValid: false
      };
      const updateProducts = [...uplaunchProducts, updateObject];
      formik.setFieldValue('uplaunchProducts', updateProducts);
      return;
    }
    const cloneProductsArray = [...uplaunchProducts];
    if (!value && cloneProductsArray[index].id === null) {
      cloneProductsArray.splice(index, 1);
    } else {
      cloneProductsArray[index] = {
        ...cloneProductsArray[index],
        product_id: productValue || null,
        campaign_id: null,
        isValid: !productValue
      };
    }
    formik.setFieldValue('uplaunchProducts', [...cloneProductsArray]);
  };

  const handleCampaignsChange = (value, programId, lifeCycleName) => {
    const { uplaunchProducts } = formik.values;
    const index = uplaunchProducts.findIndex(
      p => p?.program_id === programId && p?.lifecycle === lifeCycleName
    );
    const cloneProductsArray = [...uplaunchProducts];
    cloneProductsArray[index] = {
      ...cloneProductsArray[index],
      campaign_id: parseInt(value),
      isValid: parseInt(value) > 0
    };
    formik.setFieldValue('uplaunchProducts', [...cloneProductsArray]);
  };

  return (
    <div
      key={programName}
      className="tw-grid tw-grid-cols-3 tw-grid-flow-col tw-border-b tw-px-1 tw-pb-2 tw-border-t-0 tw-border-l-0 tw-border-r-0 tw-border-solid tw-border-gray-200"
    >
      <div className="tw-pl-12 tw-pt-4 tw-w-full">{programName}</div>
      <div className="tw-flex">
        <span className="tw-pt-4">
          <HeroIcon id={programName} icon="arrow-right" color="gray" />
        </span>
        <Select
          value={product_id}
          id={`${id}_${lifecyle}_product`}
          onChange={e => {
            handleProductChange(e.target.value, id, lifecyle);
          }}
          options={productOption}
          widthClass="tw-w-full tw-pl-4"
        />
      </div>
      <Select
        value={campaign_id}
        id={`${id}_${lifecyle}_campaign`}
        onChange={e => {
          handleCampaignsChange(e.target.value, id, lifecyle);
        }}
        options={campaignFilterOptions}
        widthClass="tw-pl-4"
        showError={product_id && !campaign_id}
      />
    </div>
  );
};

ZenPlannerItem.propTypes = {
  formik: PropTypes.shape(formikShape).isRequired,
  id: PropTypes.string.isRequired,
  programName: PropTypes.string,
  lifecyle: PropTypes.string,
  campaignSelectOptions: PropTypes.arrayOf.isRequired
};

ZenPlannerItem.defaultProps = {
  lifecyle: '',
  programName: ''
};

export default ZenPlannerDetail;
