import React, { useContext, useEffect, useState, useCallback } from 'react';
import UtilityCard from 'components/OverlordUtilities/shared/UtilityCard';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import isEmpty from 'lodash.isempty';
import {
  createErrorMessageSelector,
  createLoadedSelector
} from 'appState/selectors';
import {
  mergeContacts,
  resetIsLoaded,
  fetchAutoCompleteSuggestions
} from 'appState/actions/ActionCreators';
import { titleize } from 'lib/utils/string';
import get from 'lodash.get';
import { CurrentAccountContext } from 'components/shared/context/CurrentAccountContext';
import useProcessingActivity from 'components/shared/hooks/useProcessingActivity';
import useAccountDisabled from 'components/shared/hooks/useAccountDisabled';
import AutoCompleteDropdown from 'components/Theme/AutoCompleteDropDown';

const UTILITY_NAMES = ['contact_merger'];

const DEFAULT_OBJECTS_TO_MERGE = [
  'tasks',
  'appointments',
  'emails',
  'sms',
  'forms',
  'activities',
  'communication_records',
  'customized_steps',
  'custom_groups',
  'campaign_overlays'
];

const ContactMerger = () => {
  const [objectsToMerge, setObjectsToMerge] = useState(
    DEFAULT_OBJECTS_TO_MERGE
  );
  const [baseContactId, setBaseContactId] = useState();
  const [duplicateContactId, setDuplicateContactId] = useState();
  const currentAccount = useContext(CurrentAccountContext);
  const processingActivity = useProcessingActivity(UTILITY_NAMES);
  const accountDisabled = useAccountDisabled();
  const [baseContact, setBaseContact] = useState();
  const [duplicateContact, setDuplicateContact] = useState();

  const errorSelector = createErrorMessageSelector(['overlord/MERGE_CONTACTS']);
  const loadedSelectorContactsMerge = createLoadedSelector([
    'overlord/MERGE_CONTACTS'
  ]);
  const structuredSelector = createStructuredSelector({
    contacts: state => state.overlordUtilities.contacts,
    error: state => errorSelector(state),
    isContactsMergedLoaded: state => loadedSelectorContactsMerge(state),
    contactSuggestions: state => state.autoComplete.suggestions
  });
  const { error, isContactsMergedLoaded, contactSuggestions } = useSelector(
    structuredSelector
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (isContactsMergedLoaded && isEmpty(error)) {
      app.toast(
        `Successfully merged contacts for ${titleize(
          currentAccount.name
        )} account`,
        {
          type: 'primary',
          icon: 'fa-floppy-o'
        }
      );
    }

    return () => {
      dispatch(resetIsLoaded('overlord/MERGE_CONTACTS'));
    };
  }, [error, isContactsMergedLoaded, currentAccount.name, dispatch]);

  const submitMergeContacts = () => {
    if (get(objectsToMerge, 'length') && baseContactId && duplicateContactId) {
      dispatch(
        mergeContacts({
          baseContactId,
          duplicateContactId,
          objectsToMerge
        })
      );
    }
  };

  const handleObjectsToMergeChange = e => {
    setObjectsToMerge(
      [...e.target.options].filter(o => o.selected).map(o => o.value)
    );
  };

  const handleContactSelection = useCallback(
    (value, type) => {
      const contactSelected = contactSuggestions.find(
        suggestion => suggestion.value === value
      );
      if (type === 'base') {
        setBaseContactId(value);
        setBaseContact(contactSelected);
      } else {
        setDuplicateContactId(value);
        setDuplicateContact(contactSelected);
      }
    },
    [contactSuggestions]
  );

  const handleContactSuggestionsChange = query => {
    dispatch(
      fetchAutoCompleteSuggestions({
        url: 'contacts',
        query,
        suggestionDataMap: {
          displayName: ['id', 'full_name'],
          value: 'id'
        }
      })
    );
  };

  const getCardBody = () => {
    return (
      <div>
        {/* <%= form_tag overlord_utility_merge_contacts_path(overlord_utility_id: @account.id) , method: :post do %> */}
        <p className="mb-20">
          The <strong>Base Contact</strong> will be kept and the{' '}
          <strong>Duplicate Contact</strong> will merge objects over to the{' '}
          <strong>Base Contact</strong> and then will be soft deleted.
        </p>
        <p className="mb-20">
          <strong>Note: </strong>If both contacts are synced, the base
          contact&apos;s sync information will be retained.
        </p>
        <div className="form-row mt-10">
          <div className="col-lg-6 form-group">
            <AutoCompleteDropdown
              suggestions={contactSuggestions}
              labelText="Base Contact"
              id="baseContactId"
              handleChange={handleContactSuggestionsChange}
              handleSelection={val => handleContactSelection(val, 'base')}
              displayValue={baseContact?.displayName}
              widthClass="tw-col-span-12 sm:tw-col-span-6 xxs:tw-my-4 xs:tw-my-0"
              prependId
            />
          </div>
          <div className="col-lg-6 form-group">
            <AutoCompleteDropdown
              suggestions={contactSuggestions}
              labelText="Duplicate Contact"
              id="duplicateContactId"
              handleChange={handleContactSuggestionsChange}
              handleSelection={val => handleContactSelection(val, 'duplicate')}
              displayValue={duplicateContact?.displayName}
              widthClass="tw-col-span-12 sm:tw-col-span-6 xxs:tw-my-4 xs:tw-my-0"
              prependId
            />
          </div>
          <div className="col-lg-12 form-group">
            {/* <%= label_tag :objects_to_move, 'Object To Merge' %>
              <% select_options = [['Tasks', 'tasks'],['Appointments', 'appointments'],['Emails', 'emails'],['SMS', 'sms'],['Contact Forms', 'forms'],['Activities','activities'],['Communication Records', 'communication_records'],['Customized Steps', 'customized_steps'], ['Custom Groups', 'custom_groups'], ['Campaign Overlays', 'campaign_overlays']] %>
              <%= select_tag :objects_to_move, options_for_select(select_options, select_options), class: 'form-control', 'data-size': '20', 'data-provide': 'selectpicker', multiple: true %> */}
            <label htmlFor="objectsToMove">Object To Merge</label>
            <select
              id="objectsToMove"
              className="form-control"
              data-size="20"
              data-provide="selectpicker"
              multiple
              onChange={e => handleObjectsToMergeChange(e)}
              defaultValue={DEFAULT_OBJECTS_TO_MERGE}
            >
              <option value="tasks">Tasks</option>
              <option value="appointments">Appointments</option>
              <option value="emails">Emails</option>
              <option value="sms">SMS</option>
              <option value="forms">Contact Forms</option>
              <option value="activities">Activities</option>
              <option value="communication_records">
                Communication Records
              </option>
              <option value="customized_steps">Customized Steps</option>
              <option value="custom_groups">Custom Groups</option>
              <option value="campaign_overlays">Campaign Overlays</option>
            </select>
          </div>
        </div>
      </div>
    );
  };
  return (
    <UtilityCard
      title="Contact Merger"
      subtitle="Use this tool to merge two contacts together."
      submitBtnText="Merge Contacts"
      submitBtnAction={submitMergeContacts}
      bodyComponent={getCardBody}
      processingActivity={processingActivity}
      accountDisabled={accountDisabled}
    />
  );
};

export default ContactMerger;
