import React from 'react';
import PropTypes from 'prop-types';
import { formikShape } from 'lib/utils/prop-types-extensions';
import TextInput from 'components/Theme/TextInput';
import TextArea from 'components/Theme/TextArea';
import Select from 'components/Theme/Select';
import DatePicker from 'components/Theme/DatePicker';

const FormField = ({ field, formik, widthClass }) => {
  const fieldIdString = field.id.toString();

  function handleDatePickerChange(updatedValue) {
    formik.setFieldValue(fieldIdString, updatedValue);
  }

  function getTextInput() {
    return (
      <TextInput
        id={fieldIdString}
        value={formik.values[fieldIdString]}
        labelText={field.question}
        required={field.required}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        showError={
          formik.touched[fieldIdString] && !!formik.errors[fieldIdString]
        }
        error={formik.errors[fieldIdString]}
        widthClass={widthClass}
      />
    );
  }

  function getInputType() {
    switch (field.field_type) {
      case 'text': {
        return getTextInput();
      }
      case 'text_area': {
        return (
          <TextArea
            id={fieldIdString}
            value={formik.values[fieldIdString]}
            labelText={field.question}
            required={field.required}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            showError={
              formik.touched[fieldIdString] && !!formik.errors[fieldIdString]
            }
            error={formik.errors[fieldIdString]}
            widthClass={widthClass}
          />
        );
      }
      case 'yes_or_no': {
        return (
          <Select
            id={fieldIdString}
            value={formik.values[fieldIdString]}
            options={[
              { displayName: 'Yes', value: true },
              { displayName: 'No', value: false }
            ]}
            labelText={field.question}
            required={field.required}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            showError={
              formik.touched[fieldIdString] && !!formik.errors[fieldIdString]
            }
            error={formik.errors[fieldIdString]}
            widthClass={widthClass}
          />
        );
      }
      case 'date_picker': {
        return (
          <DatePicker
            id={fieldIdString}
            value={formik.values[fieldIdString]}
            labelText={field.question}
            required={field.required}
            onChange={handleDatePickerChange}
            onBlur={formik.handleBlur}
            showError={
              formik.touched[fieldIdString] && !!formik.errors[fieldIdString]
            }
            error={formik.errors[fieldIdString]}
            widthClass={widthClass}
          />
        );
      }
      default: {
        return getTextInput();
      }
    }
  }

  return getInputType();
};

FormField.defaultProps = {
  field: null,
  formik: null,
  widthClass: 'tw-col-span-12'
};

FormField.propTypes = {
  field: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool])
  ),
  formik: PropTypes.shape(formikShape),
  widthClass: PropTypes.string
};

export default FormField;
