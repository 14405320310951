import React from 'react';
import { withA11y } from '@storybook/addon-a11y';
import {
  withKnobs,
  text,
  select,
  boolean,
  object
} from '@storybook/addon-knobs';
import InfoPanel from './index';

export default {
  component: InfoPanel,
  title: 'Info Panel',
  decorators: [withA11y, withKnobs]
};

let colorSelect;

function includeColorSelect(defaultValue) {
  const label = 'Color';
  const options = [
    'alpha',
    'bravo',
    'charlie',
    'success',
    'warning',
    'error',
    'gray'
  ];
  colorSelect = select(label, options, defaultValue);
}

const infoItems = [
  'Info Panel is a good way to inform the customer about the important information about the page.',
  'This panel supports all the colours that are used in the system.',
  'Also, it can show info SVG icon whenever needed, which by default is set to true.',
  'It can also use HTML Info items to get the HTML elements working within the info panel. Look for withHtmlInfoItems props which by default is set to false.'
];

export const standard = () => {
  includeColorSelect('alpha');
  return (
    <InfoPanel
      title={text('Title', 'Things to Know')}
      color={colorSelect}
      showSvg={boolean('Show Icon', true)}
      infoItems={object('Info Items', infoItems)}
      orderedList={boolean('Ordered List', false)}
    />
  );
};
