import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Route, Switch, useRouteMatch, useLocation } from 'react-router-dom';
import isEmpty from 'lodash.isempty';
import {
  createErrorMessageSelector,
  createLoadedSelector
} from 'appState/selectors';
import {
  broadcastSetIsTemplate,
  resetIsLoaded,
  broadcastSetBeeHasLoaded,
  broadcastResetToInitialState
} from 'appState/actions/ActionCreators';
import usePrevious from 'components/shared/hooks/usePrevious';
import getPathName from 'lib/utils/router';
import BroadcastIndex from './Broadcast/Index';
import SelectTemplate from './Broadcast/SelectTemplate';
import SelectAudience from './Broadcast/SelectAudience';
import Sms from './Broadcast/Sms';
import Email from './Broadcast/Email';
import Schedule from './Broadcast/Schedule';
import FourOFour from '../FourOFour';

const BroadcastCenter = () => {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const location = useLocation();
  const previousLocation = usePrevious(location);

  const errorSelectorDelete = createErrorMessageSelector([
    'broadcastCenter/BROADCAST_DELETE'
  ]);
  const loadedSelectorDelete = createLoadedSelector([
    'broadcastCenter/BROADCAST_DELETE'
  ]);

  const structuredSelector = createStructuredSelector({
    isTemplate: state => state.broadcastCenter.isTemplate,
    deleteError: state => errorSelectorDelete(state),
    deleteCompleted: state => loadedSelectorDelete(state)
  });

  const { isTemplate, deleteError, deleteCompleted } = useSelector(
    structuredSelector
  );

  useEffect(() => {
    if (deleteCompleted && isEmpty(deleteError)) {
      app.toast(
        `Your ${
          isTemplate ? 'broadcast template' : 'broadcast'
        } has been deleted.`,
        {
          type: 'success',
          icon: 'fa-check'
        }
      );
    }

    if (deleteCompleted && !isEmpty(deleteError)) {
      app.toast(
        `There was an error deleting your ${
          isTemplate ? 'broadcast template' : 'broadcast'
        }.`,
        {
          type: 'danger',
          icon: 'fa-close'
        }
      );
    }

    if (deleteCompleted) {
      dispatch(broadcastResetToInitialState());
      dispatch(resetIsLoaded('broadcastCenter/BROADCAST_DELETE'));
    }
  }, [deleteError, deleteCompleted, dispatch]);

  useEffect(() => {
    if (path)
      dispatch(broadcastSetIsTemplate(path.includes('broadcast-templates')));
  }, [path, dispatch]);

  useEffect(() => {
    if (previousLocation) {
      const prevPathName = getPathName(previousLocation.pathname);
      if (prevPathName === 'email') dispatch(broadcastSetBeeHasLoaded(false));
    }
  }, [previousLocation, dispatch]);

  return (
    <div>
      <Switch>
        <Route exact path={path} component={BroadcastIndex} />
        <Route
          path={`${path}/:selectedTemplateType/select-template`}
          component={SelectTemplate}
        />
        <Route
          path={`${path}/:id/select-audience`}
          component={SelectAudience}
        />
        <Route path={`${path}/:id/sms`} component={Sms} />
        <Route path={`${path}/:id/email`} component={Email} />
        <Route
          path={[`${path}/:id/schedule`, `${path}/:id/save-details`]}
          component={Schedule}
        />
        <Route component={FourOFour} />
      </Switch>
    </div>
  );
};

export default BroadcastCenter;
