import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useRouteMatch, useHistory } from 'react-router-dom';
import isEmpty from 'lodash.isempty';
import {
  createErrorMessageSelector,
  createLoadedSelector
} from 'appState/selectors';
import {
  broadcastTemplateFetchSmsMergeTags,
  broadcastSaveSms
} from 'appState/actions/ActionCreators';
import useToggleHeaderFooter from 'components/shared/hooks/useToggleHeaderFooter';
import BroadcastTemplateStep from '../BroadcastTemplateStep';
import Card from '../Card';

const Sms = () => {
  const { path } = useRouteMatch();
  useToggleHeaderFooter(path);
  const textAreaEl = useRef(null);
  const selectPickerEl = useRef(null);

  const errorSelector = createErrorMessageSelector([
    'broadcastCenter/BROADCAST_TEMPLATES_FETCH'
  ]);
  const loadedSelector = createLoadedSelector([
    'broadcastCenter/BROADCAST_TEMPLATES_FETCH'
  ]);

  const dispatch = useDispatch();
  const history = useHistory();

  const structuredSelector = createStructuredSelector({
    currentBroadcastTemplate: state =>
      state.broadcastCenter.currentBroadcastTemplate,
    smsMergeFields: state => state.broadcastCenter.smsMergeFields,
    error: state => errorSelector(state),
    isLoaded: state => loadedSelector(state)
  });

  const {
    currentBroadcastTemplate,
    smsMergeFields,
    isLoaded,
    error
  } = useSelector(structuredSelector);

  const [smsBody, setSmsBody] = useState();

  useEffect(() => {
    setSmsBody(currentBroadcastTemplate.sms_body);
  }, [dispatch, currentBroadcastTemplate.sms_body]);

  const smsMergeFieldSelectOptions = smsMergeFields.map(mergeField => {
    return (
      <option key={mergeField[0]} value={mergeField[1]}>
        {mergeField[0]}
      </option>
    );
  });

  function saveBroadcastTemplate() {
    dispatch(
      broadcastSaveSms({
        broadcastTemplateId: currentBroadcastTemplate.id,
        smsBody,
        history
      })
    );
  }

  const handleSmsMergeFieldDropdownChange = mergeField => {
    const caretPos = document.getElementById('broadcast-sms-editor')
      .selectionStart;
    const caretEnd = document.getElementById('broadcast-sms-editor')
      .selectionEnd;
    const textAreaTxt = document.getElementById('broadcast-sms-editor').value;
    const txtToAdd = mergeField;
    document.getElementById('broadcast-sms-editor').value =
      textAreaTxt.substring(0, caretPos) +
      txtToAdd +
      textAreaTxt.substring(caretEnd);

    textAreaEl.current.focus();
    document.getElementById('broadcast-sms-editor').selectionStart =
      caretPos + txtToAdd.length;
    document.getElementById('broadcast-sms-editor').selectionEnd =
      caretPos + txtToAdd.length;
    selectPickerEl.current.value = '';
    // $('#broadcast-sms-editor').data('content-changed', true);
    setSmsBody(document.getElementById('broadcast-sms-editor').value);
  };

  useEffect(() => {
    if (!isEmpty(currentBroadcastTemplate)) {
      dispatch(
        broadcastTemplateFetchSmsMergeTags({
          broadcastTemplateId: currentBroadcastTemplate.id
        })
      );
    }
  }, [dispatch, currentBroadcastTemplate]);

  return (
    <div>
      <BroadcastTemplateStep saveAction={saveBroadcastTemplate}>
        <Card title="SMS Message" subtitle="What would you like to say?">
          <div className="form-row">
            <div className="form-group col-md-12">
              <label>Message</label>
              <div className="float-right mb-10">
                {smsMergeFields && smsMergeFields.length ? (
                  <select
                    ref={selectPickerEl}
                    onChange={e =>
                      handleSmsMergeFieldDropdownChange(e.target.value)
                    }
                    id="userId"
                    className="form-control"
                    defaultValue=""
                  >
                    <option value="" disabled>
                      Insert Merge Field
                    </option>
                    {smsMergeFieldSelectOptions}
                  </select>
                ) : null}
              </div>
              <textarea
                ref={textAreaEl}
                className="form-control"
                placeholder="Write your message here..."
                id="broadcast-sms-editor"
                data-provide="maxlength"
                data-warning-class="badge badge-info"
                data-always-show="true"
                maxLength="320"
                onChange={e => setSmsBody(e.target.value)}
                value={smsBody}
              />
            </div>
          </div>
        </Card>
      </BroadcastTemplateStep>
    </div>
  );
};

export default Sms;
