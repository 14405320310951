import React from 'react';
import Icon from '../index';
import iconPropTypes from '../iconPropTypes';
import iconDefaultProps from '../iconDefaultProps';

const UserIcon = ({ color, tint, filled, onClick, width, height }) => {
  return (
    <Icon
      color={color}
      tint={tint}
      onClick={onClick}
      filled={filled}
      width={width}
      height={height}
      pathDefinition={
        filled
          ? 'M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z'
          : 'M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z'
      }
    />
  );
};

UserIcon.defaultProps = iconDefaultProps;
UserIcon.propTypes = iconPropTypes;

export default UserIcon;
