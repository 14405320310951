$('document').ready(() => {
  let page = $('meta[name=psj]');
  if (page.attr('controller') === 'contacts' && page.attr('action') === 'show') {

    $('body').on('change', '#contact-change-campaign-select', function () {
      let conversion_select_wrapper = $('#contact-change-campaign-conversion-select-wrapper')
      let conversion_select = $('#contact-change-campaign-conversion-select')
      conversion_select.val("");
      conversion_select.selectpicker("refresh");
      conversion_select.attr('required', false);
      if ($(this).find(":selected").attr('data-lifecycle') === 'client' && $(this).find(":selected").attr('data-current-lifecycle') !== 'client') {
        conversion_select.attr('required', true);
        conversion_select_wrapper.show();
      } else {
        conversion_select_wrapper.hide();
      }
    })

  }
});
