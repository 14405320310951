import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useRouteMatch, useParams } from 'react-router-dom';
import isEmpty from 'lodash.isempty';
import {
  createErrorMessageSelector,
  createLoadedSelector
} from 'appState/selectors';
import {
  broadcastTemplatesFetch,
  broadcastTemplatesPageChange
} from 'appState/actions/ActionCreators';
import useToggleHeaderFooter from 'components/shared/hooks/useToggleHeaderFooter';
import Pagination from 'components/BroadcastCenter/shared/Pagination';
import ReassignTemplateModal from 'components/BroadcastCenter/Broadcast/SelectTemplate/ReassignTemplateModal';
import TemplateCard from './TemplateCard';
import DefaultTemplateCard from './DefaultTemplateCard';
import AudienceSummaryModal from './AudienceSummaryModal';
import DeleteModal from './DeleteModal';
import BroadcastStep from '../BroadcastStep';
import SkeletonTemplateCards from './SkeletonTemplateCards';

const SelectTemplate = () => {
  const { selectedTemplateType } = useParams();
  const { path } = useRouteMatch();
  useToggleHeaderFooter(path);

  const errorSelector = createErrorMessageSelector([
    'broadcastCenter/BROADCAST_TEMPLATES_FETCH'
  ]);
  const loadedSelector = createLoadedSelector([
    'broadcastCenter/BROADCAST_TEMPLATES_FETCH'
  ]);
  const structuredSelector = createStructuredSelector({
    broadcastTemplates: state => state.broadcastCenter.broadcastTemplates,
    totalPages: state => state.broadcastCenter.totalPages,
    error: state => errorSelector(state),
    isLoaded: state => loadedSelector(state)
  });

  const { broadcastTemplates, totalPages, error, isLoaded } = useSelector(
    structuredSelector
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(broadcastTemplatesFetch({ type: selectedTemplateType }));
  }, [dispatch, selectedTemplateType]);

  const pageChange = () => args => {
    dispatch(
      broadcastTemplatesPageChange({ ...args, type: selectedTemplateType })
    );
  };

  return (
    <div>
      <BroadcastStep>
        {isLoaded ? (
          <DefaultTemplateCard
            title="Start From Stratch"
            description="Need a blank template to start your ideas from? Look no further."
            templateType={selectedTemplateType}
          />
        ) : null}
        {isLoaded ? (
          <Fragment>
            {!isEmpty(broadcastTemplates) &&
              broadcastTemplates.map(template => (
                <TemplateCard key={template.id} template={template} />
              ))}
            <AudienceSummaryModal templates={broadcastTemplates} />
          </Fragment>
        ) : (
          <SkeletonTemplateCards count={10} />
        )}
        {totalPages && totalPages > 1 ? (
          <div className="w-100 d-flex justify-content-center">
            <Pagination totalPages={totalPages} action={pageChange()} />
          </div>
        ) : null}
      </BroadcastStep>
      <DeleteModal />
      <ReassignTemplateModal />
    </div>
  );
};

export default SelectTemplate;
