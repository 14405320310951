import { combineReducers } from 'redux';
import smsInbox from './SmsInboxReducer';
import overlordUtilities from './OverlordUtilities';
import overlordLayout from './OverlordLayout';
import broadcastCenter from './BroadcastCenter';
import purchase from './PurchaseReducer';
import subscription from './SubscriptionReducer';
import appointmentBooker from './AppointmentBookerReducer';
import plan from './PlanReducer';
import error from './ErrorReducer';
import loaded from './LoadedReducer';
import loading from './LoadingReducer';
import contact from './ContactReducer';
import task from './TaskReducer';
import contactFormType from './ContactFormTypeReducer';
import contactForm from './ContactFormReducer';
import activity from './ActivityReducer';
import syncWizard from './SyncWizardReducer';
import marketPlace from './MarketPlaceReducer';
import product from './ProductReducer';
import productSubscription from './ProductSubscriptionReducer';
import partner from './PartnerReducer';
import autoComplete from './AutoCompleteReducer';
import blog from './BlogReducer';
import flashMessage from './FlashMessageReducer';
import optInForm from './OptInFormReducer';
import backGroundJob from './BackGroundJobReducer';
import appointmentType from './AppointmentTypeReducer';
import zenPlannerSync from './ZenPlannerSyncReducer';
import leadSource from './LeadSourceReducer';

const appReducer = combineReducers({
  smsInbox,
  overlordUtilities,
  broadcastCenter,
  purchase,
  subscription,
  plan,
  appointmentBooker,
  contact,
  task,
  activity,
  contactFormType,
  contactForm,
  error,
  loaded,
  loading,
  syncWizard,
  marketPlace,
  product,
  productSubscription,
  autoComplete,
  blog,
  flashMessage,
  optInForm,
  overlordLayout,
  partner,
  backGroundJob,
  appointmentType,
  zenPlannerSync,
  leadSource
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
