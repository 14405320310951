import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import isEmpty from 'lodash.isempty';
import { broadcastsFetchCurrentBroadcastTemplate } from 'appState/actions/ActionCreators';
import Styled from './BroadcastTemplateStep.styles';
import Header from '../Header';
import Footer from '../Footer';

const BroadcastStep = props => {
  const { children, saveAction } = props;
  const { id } = useParams();
  const dispatch = useDispatch();

  const structuredSelector = createStructuredSelector({
    currentBroadcastTemplate: state =>
      state.broadcastCenter.currentBroadcastTemplate,
    step: state => state.broadcastCenter.step
  });

  const { currentBroadcastTemplate, step } = useSelector(structuredSelector);

  useEffect(() => {
    if (isEmpty(currentBroadcastTemplate) && step !== 'select-template')
      dispatch(
        broadcastsFetchCurrentBroadcastTemplate({ broadcastTemplateId: id })
      );
  }, [currentBroadcastTemplate, id, step, dispatch]);

  return (
    <div>
      <Header />
      <Styled.MainContent
        id="content"
        className="pt-0 main-content container-fluid"
      >
        <Styled.Row className="row">{children}</Styled.Row>
      </Styled.MainContent>
      <Footer saveAction={saveAction} />
    </div>
  );
};

BroadcastStep.defaultProps = {
  saveAction: null
};

BroadcastStep.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  saveAction: PropTypes.func
};

export default BroadcastStep;
