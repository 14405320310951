import React, { useContext, useEffect, useState } from 'react';
import UtilityCard from 'components/OverlordUtilities/shared/UtilityCard';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  createErrorMessageSelector,
  createLoadedSelector
} from 'appState/selectors';
import {
  bulkChangeCampaign,
  fetchCampaigns
} from 'appState/actions/ActionCreators';
import { CurrentAccountContext } from 'components/shared/context/CurrentAccountContext';
import useProcessingActivity from 'components/shared/hooks/useProcessingActivity';
import useAccountDisabled from 'components/shared/hooks/useAccountDisabled';
import isEmpty from 'lodash.isempty';

const UTILITY_NAMES = ['bulk_campaign_changer'];

const BulkCampaignChanger = () => {
  let fileField = null;
  const [campaignId, setCampaignId] = useState();
  const [startingPosition, setStartingPosition] = useState(1);
  const [createActivity, setCreateActivity] = useState(true);
  const [shouldCampaignStart, setShouldCampaignStart] = useState(true);

  const currentAccount = useContext(CurrentAccountContext);
  const accountId = currentAccount.id;
  const processingActivity = useProcessingActivity(UTILITY_NAMES);
  const accountDisabled = useAccountDisabled();

  const errorSelector = createErrorMessageSelector([
    'overlord/BULK_CAMPAIGN_CHANGER',
    'overlord/CAMPAIGNS_FETCH'
  ]);
  const loadedSelector = createLoadedSelector(['overlord/CAMPAIGNS_FETCH']);
  const structuredSelector = createStructuredSelector({
    campaigns: state => state.overlordUtilities.campaigns,
    error: state => errorSelector(state),
    isLoaded: state => loadedSelector(state)
  });
  const { campaigns, error, isLoaded } = useSelector(structuredSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isEmpty(campaigns)) dispatch(fetchCampaigns());
  }, [dispatch, accountId]);

  const changeCampaign = () => {
    if (fileField && campaignId) {
      dispatch(
        bulkChangeCampaign({
          campaignId,
          createActivity,
          startingPosition,
          shouldCampaignStart,
          file: fileField.files[0]
        })
      );
    }
  };

  const campaignSelectOptions = campaigns.map(campaign => {
    return (
      <option key={campaign.id} value={campaign.id}>
        {campaign.name}
      </option>
    );
  });

  const getCardBody = () => {
    return (
      <div className="form-row">
        <div className="form-group col-lg-6">
          <label htmlFor="file">Select CSV File for Upload</label>
          <div className="input-group file-group">
            <input
              type="text"
              className="form-control file-value"
              placeholder="Choose file..."
              readOnly=""
            />
            <input
              ref={field => {
                fileField = field;
              }}
              id="upload-csv"
              name="file2"
              type="file"
              className="form-control"
              placeholder="Upload CSV"
            />
            <span className="input-group-append">
              <button className="btn btn-light file-browser" type="button">
                <i className="fa fa-upload" />
              </button>
            </span>
          </div>
        </div>
        <div className="form-group col-lg-6">
          <label htmlFor="campaignId">Select Campaign</label>
          {campaigns && campaigns.length ? (
            <select
              onChange={e => setCampaignId(e.target.value)}
              id="campaignId"
              className="form-control"
              data-size="20"
              data-provide="selectpicker"
              defaultValue=""
            >
              <option value="" disabled hidden>
                Nothing selected
              </option>
              {campaignSelectOptions}
              <option value="none">None</option>
            </select>
          ) : null}
        </div>
        <div className="form-group col-lg-6">
          <label htmlFor="startingPosition">Starting Position</label>
          <input
            id="startingPosition"
            className="form-control"
            type="text"
            onChange={e => setStartingPosition(e.target.value)}
            value={startingPosition}
          />
        </div>
        <div className="form-group col-lg-6">
          <label htmlFor="createActivity">
            Create an activity for this change?
          </label>
          <select
            onChange={e => setCreateActivity(e.target.value)}
            id="createActivity"
            className="form-control"
            data-size="20"
            data-provide="selectpicker"
            value={createActivity}
          >
            <option value="" disabled hidden>
              Nothing selected
            </option>
            <option value>Yes</option>
            <option value={false}>No</option>
          </select>
        </div>
        <div className="form-group col-lg-12">
          <label htmlFor="shouldCampaignStart">
            Should campaign start immediately?
          </label>
          <select
            onChange={e => setShouldCampaignStart(e.target.value)}
            id="shouldCampaignStart"
            className="form-control"
            data-size="20"
            data-provide="selectpicker"
            value={shouldCampaignStart}
          >
            <option value="" disabled hidden>
              Nothing selected
            </option>
            <option value>Yes, start immediately</option>
            <option value={false}>No, pause for now</option>
          </select>
        </div>
      </div>
    );
  };

  return (
    <UtilityCard
      title="Bulk Campaign Changer"
      subtitle="Use this tool to change contacts campaign."
      submitBtnText="Change Contacts Campaign"
      submitBtnAction={changeCampaign}
      bodyComponent={getCardBody}
      isLoaded={isLoaded}
      processingActivity={processingActivity}
      accountDisabled={accountDisabled}
      error={error}
    />
  );
};

export default BulkCampaignChanger;
