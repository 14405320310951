import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  broadcastsFetchEmails,
  broadcastResetEmails,
  broadcastsFetchContacts
} from 'appState/actions/ActionCreators';
import BroadcastListItem from '../BroadcastListItem';

const SendingListItem = props => {
  const { broadcast } = props;
  const dispatch = useDispatch();

  const getBroadcastEmails = metric => {
    if (metric === 'erred') {
      dispatch(broadcastResetEmails());
      dispatch(
        broadcastsFetchEmails({
          broadcastId: broadcast.id,
          broadcastMetric: metric,
          broadcastType: 'email'
        })
      );
    } else {
      dispatch(
        broadcastsFetchContacts({
          broadcastId: broadcast.id,
          broadcastMetric: metric,
          broadcastType: broadcast.type === 'EmailBroadcast' ? 'email' : 'sms'
        })
      );
    }
  };

  return (
    <BroadcastListItem
      broadcast={broadcast}
      badgeType="info"
      timestampAttr={broadcast.updated_at}
    >
      <div className="col-sm-6">
        <hr className="hr-sm d-block d-sm-none" />
        {/* <!-- Right Side Content --> */}
        <div className="media p-0 align-items-center">
          <div className="media-body">
            <div className="d-flex justify-content-sm-end justify-content-center">
              <div className="w-80px d-flex flex-column align-items-center">
                {/* <%= link_to '#', class: "metrics-modal-link text-center", "data-id": broadcast.id, "data-status": 'sending', "data-type": broadcast.template_type.downcase, "data-metric": "count", "data-is-percentage": false, style: "border-bottom: 1px dotted;" do %> */}
                <a
                  onClick={() => getBroadcastEmails('scheduled')}
                  href="#"
                  className="metrics-modal-link text-center"
                  data-target="#broadcast-center-metrics-modal"
                  data-toggle="modal"
                  style={{ borderBottom: '1px dotted' }}
                >
                  <small>Scheduled</small>
                  <h3>{broadcast.scheduled_count}</h3>
                </a>
                {/* <% end %> */}
              </div>
              <div className="w-80px d-flex flex-column align-items-center">
                {/* <%= link_to '#', class: "metrics-modal-link text-center", "data-id": broadcast.id, "data-status": 'sending', "data-type": broadcast.template_type.downcase, "data-metric": "sent", "data-is-percentage": true, style: "border-bottom: 1px dotted;" do %> */}
                <a
                  onClick={() => getBroadcastEmails('sent')}
                  href="#"
                  className="metrics-modal-link text-center"
                  data-target="#broadcast-center-metrics-modal"
                  data-toggle="modal"
                  style={{ borderBottom: '1px dotted' }}
                >
                  <small>Sent</small>
                  <h3>
                    {broadcast.sent_count === 0 ? 0 : broadcast.sent_percentage}
                    %
                  </h3>
                </a>
                {/* <% end %> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </BroadcastListItem>
  );
};

const broadcastShape = {
  updated_at: PropTypes.string,
  scheduled_count: PropTypes.number,
  sent_count: PropTypes.number,
  sent_percentage: PropTypes.number
};

SendingListItem.propTypes = {
  broadcast: PropTypes.shape(broadcastShape).isRequired
};

export default SendingListItem;
