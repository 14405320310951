import React, { Fragment, useState, useEffect } from 'react';
import Modal from 'components/Theme/Modal';
import PropTypes from 'prop-types';
import Button from 'components/Theme/Button';
import {
  planAvailableForUser,
  upgradeSubscription,
  resetIsLoaded,
  flashErrorMessage,
  flashSuccessMessage
} from 'appState/actions/ActionCreators';
import {
  createErrorMessageSelector,
  createLoadedSelector,
  createLoadingSelector
} from 'appState/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Select from 'components/Theme/Select';
import { formatTimestamp } from 'lib/utils/dateTime';
import isEmpty from 'lodash.isempty';

const TwoStepUpgrade = ({
  mySubscription,
  myPlan,
  buttonText,
  isFullWidth,
  disabled,
  buttonSize
}) => {
  const errorSelector = createErrorMessageSelector([
    'subscription/UPGRADE_SUBSCRIPTION'
  ]);
  const loadedSelector = createLoadedSelector([
    'subscription/UPGRADE_SUBSCRIPTION'
  ]);
  const loadingSelector = createLoadingSelector([
    'subscription/UPGRADE_SUBSCRIPTION'
  ]);

  const structuredSelector = createStructuredSelector({
    plans: state => state.plan.plansAvailableForUser,
    error: state => errorSelector(state),
    isLoaded: state => loadedSelector(state),
    isLoading: state => loadingSelector(state)
  });

  const { plans, error, isLoaded, isLoading } = useSelector(structuredSelector);
  const [showStripeModal, setStripeShowModal] = useState(false);
  const [newPlan, setNewPlan] = useState({});
  const [currentStripeStep, setCurrentStripeStep] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoaded) {
      if (isEmpty(error)) {
        dispatch(flashSuccessMessage('Plan is upgraded Successfully.'));
        setStripeShowModal(false);
      } else {
        dispatch(flashErrorMessage(error));
      }

      dispatch(resetIsLoaded('subscription/UPGRADE_SUBSCRIPTION'));
    }
    return () => {
      dispatch(resetIsLoaded('subscription/UPGRADE_SUBSCRIPTION'));
    };
  }, [error, isLoaded, dispatch]);

  useEffect(() => {
    dispatch(planAvailableForUser());
  }, [dispatch]);

  const planOptions = () => {
    const selectOptions = [];
    plans.map(plan => {
      return (
        plan.priority < myPlan.priority &&
        selectOptions.push({ displayName: plan.name, value: plan.id })
      );
    });
    return selectOptions;
  };

  const handleUpgradePlan = () => {
    setStripeShowModal(true);
  };

  const submitModal = () => {
    dispatch(upgradeSubscription({ newPlanId: newPlan.id }));
  };

  const setSelectedPlan = value => {
    setNewPlan(plans.find(plan => plan.id === Number(value)));
  };

  function stepOne() {
    return (
      <div className="tw-ml-10">
        <div>
          <div>Current:</div>
          {myPlan.name ? (
            <div className="tw-font-bold">{myPlan.name} Plan</div>
          ) : null}
          {myPlan.max_products ? (
            <div>{myPlan.max_products} products</div>
          ) : null}
          {myPlan.pricing_information ? (
            <div className="tw-my-3">
              {myPlan.pricing_information.price_with_currency}
              /month started{' '}
              {formatTimestamp(
                mySubscription.created_at,
                null,
                false,
                'MM/DD/YYYY',
                true
              )}
            </div>
          ) : null}
        </div>
        <div className="tw-mt-5">
          <div>New:</div>
          <Select
            id="plan"
            value={newPlan.id || ''}
            options={planOptions()}
            placeholder="Select Plan"
            labelText="Plans"
            type="text"
            hideLabel
            widthClass="tw-max-w-xs"
            onChange={e => setSelectedPlan(e.target.value)}
          />
          {newPlan.max_products ? (
            <div className="tw-pl-1">{newPlan.max_products} products</div>
          ) : null}
          {newPlan.pricing_information ? (
            <div className="tw-pl-1 tw-my-3">
              {newPlan.pricing_information.price_with_currency}
              /month starting today
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  function stepTwo() {
    return (
      <div className="tw-ml-10">
        {newPlan.name ? (
          <div className="tw-font-bold">{newPlan.name} Plan</div>
        ) : null}
        {newPlan.max_products ? (
          <div>{newPlan.max_products} products </div>
        ) : null}
        {newPlan.pricing_information ? (
          <div className="tw-my-3">
            {newPlan.pricing_information.price_with_currency}
            /month
          </div>
        ) : null}
        {mySubscription.card_last4 ? (
          <div className="tw-my-3">
            <strong>{mySubscription.card_brand} </strong> Card ending with{' '}
            <strong>{mySubscription.card_last4}</strong> will be billed.
          </div>
        ) : null}
        <ul className="tw-pl-4 tw-my-3">
          <li className="tw-font-bold">Cancel anytime in Plans & Billing</li>
          <li className="tw-font-bold">
            By clicking submit, you agree to the UpLaunch Terms of Service.
          </li>
        </ul>
      </div>
    );
  }

  const steps = [
    {
      id: 1,
      text: 'step one',
      buttonText: 'Next',
      content: stepOne(),
      action: () => setCurrentStripeStep(currentStripeStep + 1)
    },
    {
      id: 2,
      text: 'step two',
      buttonText: 'Submit',
      content: stepTwo(),
      action: () => submitModal()
    }
  ];

  return (
    <Fragment>
      <Button
        color="alpha"
        text={buttonText}
        onClick={handleUpgradePlan}
        isLoaded={!isLoading}
        disabled={
          isLoading ||
          disabled ||
          !isEmpty(mySubscription.cancellation_submitted_at)
        }
        isFullWidth={isFullWidth}
        size={buttonSize || 'md'}
      />
      <Modal
        headerText="Change your Uplaunch Plan"
        helpText="Note: Current ZP users can reach out to sales@uplaunch.com for bundled pricing discounts."
        steps={steps}
        selectedStep={currentStripeStep}
        showPrimaryAction
        primaryActionText="Next"
        showSecondaryAction
        setShow={setStripeShowModal}
        secondaryActionText="Cancel"
        show={showStripeModal}
        showHeaderIcon={false}
        hideBullets
      />
    </Fragment>
  );
};

TwoStepUpgrade.defaultProps = {
  myPlan: {},
  mySubscription: {},
  buttonText: 'Upgrade',
  isFullWidth: false,
  disabled: false,
  buttonSize: ''
};

TwoStepUpgrade.propTypes = {
  myPlan: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  ),
  mySubscription: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  ),
  buttonText: PropTypes.string,
  isFullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  buttonSize: PropTypes.string
};

export default TwoStepUpgrade;
