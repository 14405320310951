// TODO: Update all actions constants
export const SMS_INBOX_FETCH_CONVERSATION_REQUEST =
  'smsInbox/SMS_INBOX_FETCH_CONVERSATION_REQUEST';
export const SMS_INBOX_FETCH_CONVERSATION_SUCCESS =
  'smsInbox/SMS_INBOX_FETCH_CONVERSATION_SUCCESS';
export const SMS_INBOX_FETCH_CONVERSATION_FAILURE =
  'smsInbox/SMS_INBOX_FETCH_CONVERSATION_FAILURE';
export const SMS_INBOX_CONVERSATION_PAGE_CHANGE_REQUEST =
  'smsInbox/SMS_INBOX_CONVERSATION_PAGE_CHANGE_REQUEST';
export const SMS_INBOX_CONVERSATION_PAGE_CHANGE_SUCCESS =
  'smsInbox/SMS_INBOX_CONVERSATION_PAGE_CHANGE_SUCCESS';
export const SMS_INBOX_FETCH_REQUEST = 'smsInbox/SMS_INBOX_FETCH_REQUEST';
export const SMS_INBOX_FETCH_SUCCESS = 'smsInbox/SMS_INBOX_FETCH_SUCCESS';
export const SMS_INBOX_FETCH_FAILURE = 'smsInbox/SMS_INBOX_FETCH_FAILURE';
export const SMS_INBOX_PAGE_CHANGE_REQUEST =
  'smsInbox/SMS_INBOX_PAGE_CHANGE_REQUEST';
export const SMS_INBOX_PAGE_CHANGE_SUCCESS =
  'smsInbox/SMS_INBOX_PAGE_CHANGE_SUCCESS';
export const SMS_INBOX_ADD_MESSAGE = 'smsInbox/SMS_INBOX_ADD_MESSAGE';
export const SMS_INBOX_MARK_AS_READ_REQUEST =
  'smsInbox/SMS_INBOX_MARK_AS_READ_REQUEST';
export const SMS_INBOX_MARK_AS_READ_SUCCESS =
  'smsInbox/SMS_INBOX_MARK_AS_READ_SUCCESS';
export const SMS_INBOX_MARK_AS_READ_FAILURE =
  'smsInbox/SMS_INBOX_MARK_AS_READ_FAILURE';

export const SMS_INBOX_SEND_MESSAGE_ACTION = 'smsInbox/SMS_INBOX_SEND_MESSAGE';
export const SMS_INBOX_SEND_MESSAGE_REQUEST = `${SMS_INBOX_SEND_MESSAGE_ACTION}_REQUEST`;
export const SMS_INBOX_SEND_MESSAGE_SUCCESS = `${SMS_INBOX_SEND_MESSAGE_ACTION}_SUCCESS`;
export const SMS_INBOX_SEND_MESSAGE_FAILURE = `${SMS_INBOX_SEND_MESSAGE_ACTION}_FAILURE`;

export const SMS_INBOX_CURRENT_CONVERSATION_ADD_MESSAGE =
  'smsInbox/SMS_INBOX_CURRENT_CONVERSATION_ADD_MESSAGE';
export const SMS_INBOX_MESSAGE_LIST_ITEM_LAST_MESSAGE_UPDATE =
  'smsInbox/SMS_INBOX_MESSAGE_LIST_ITEM_LAST_MESSAGE_UPDATE';
