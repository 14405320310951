// linting rules disabled for immer js
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import isEmpty from 'lodash.isempty';
import {
  SET_CURRENT_ACCOUNT,
  BROADCAST_SET_STEP,
  BROADCAST_SEND_TEST_EMAIL_SUCCESS,
  RESET_TEST_EMAIL_SENT,
  BROADCASTS_FETCH_REQUEST,
  BROADCASTS_FETCH_SUCCESS,
  BROADCASTS_PAGE_CHANGE_REQUEST,
  BROADCASTS_PAGE_CHANGE_SUCCESS,
  BROADCASTS_RESET_PAGE_CHANGE,
  BROADCASTS_FETCH_CURRENT_BROADCAST_SUCCESS,
  BROADCASTS_FETCH_CURRENT_BROADCAST_TEMPLATE_SUCCESS,
  BROADCASTS_FETCH_CONTACTS_REQUEST,
  BROADCASTS_FETCH_CONTACTS_SUCCESS,
  BROADCAST_RESET_CONTACTS,
  BROADCASTS_FETCH_EMAILS_REQUEST,
  BROADCASTS_FETCH_EMAILS_SUCCESS,
  BROADCAST_RESET_EMAILS,
  BROADCASTS_FETCH_SMS_REQUEST,
  BROADCASTS_FETCH_SMS_SUCCESS,
  BROADCAST_RESET_SMS,
  BROADCAST_FETCH_PREVIEW_SUCCESS,
  BROADCAST_RESET_PREVIEW_DATA,
  BROADCAST_DELETE_SUCCESS,
  BROADCAST_TEMPLATE_DELETE_SUCCESS,
  BROADCAST_SET_SELECTED_BROADCAST,
  BROADCAST_TEMPLATES_FETCH_REQUEST,
  BROADCAST_TEMPLATES_FETCH_SUCCESS,
  BROADCAST_TEMPLATES_PAGE_CHANGE_REQUEST,
  BROADCAST_TEMPLATES_PAGE_CHANGE_SUCCESS,
  BROADCAST_TEMPLATES_CREATE_BROADCAST_SUCCESS,
  BROADCASTS_FETCH_EMAIL_MERGE_TAGS_SUCCESS,
  BROADCAST_SET_TEST_EMAIL_ADDRESS,
  BROADCAST_SET_PREVIEW_EMAIL_FIRED,
  BROADCASTS_FETCH_SMS_MERGE_TAGS_SUCCESS,
  BROADCASTS_TEMPLATE_FETCH_SMS_MERGE_TAGS_SUCCESS,
  BROADCAST_SAVE_SMS_REQUEST,
  BROADCAST_SAVE_SMS_SUCCESS,
  BROADCAST_SAVE_SMS_FAILURE,
  BROADCAST_SAVE_AS_TEMPLATE_SUCCESS,
  BROADCAST_SEND_AGAIN_SUCCESS,
  BROADCAST_EDIT_SCHEDULED_BROADCAST_SUCCESS,
  BROADCAST_UPDATE_BROADCAST_SUCCESS,
  BROADCAST_SET_SELECTED_BROADCAST_TEMPLATE,
  BROADCAST_SAVE_EMAIL_REQUEST,
  BROADCAST_SAVE_EMAIL_SUCCESS,
  BROADCAST_SAVE_EMAIL_FAILURE,
  BROADCAST_CALL_EMAIL_SAVE_ACTION,
  BROADCAST_RESET_EMAIL_SAVE_ACTION,
  BROADCAST_SET_EMAIL_SUBJECT,
  BROADCAST_RESET_IS_AUTOSAVING,
  BROADCAST_SCHEDULE_SUCCESS,
  BROADCAST_FETCH_AUDIENCE_FILTERS_SUCCESS,
  BROADCAST_UPDATE_AUDIENCE_SUCCESS,
  BROADCAST_FETCH_AUDIENCE_COUNT_SUCCESS,
  BROADCAST_FETCH_FILTER_CONTACTS_COUNT_SUCCESS,
  BROADCAST_SET_HAS_CONTENT_CHANGED,
  BROADCAST_SET_BEE_EDITOR_HAS_LOADED,
  BROADCAST_SET_IS_TEMPLATE,
  BROADCAST_TEMPLATE_SAVE_DETAILS_SUCCESS,
  BROADCAST_TEMPLATE_CREATE_SUCCESS,
  BROADCAST_SET_SELECTED_FILTERS,
  BROADCAST_SET_SELECTED_SUGGESTIONS,
  BROADCAST_RESET_TO_INITIAL_STATE,
  BROADCAST_SEARCH_CONTACTS_BY_FILE_SUCCESS,
  BROADCAST_PRODUCTS_FETCH_SUCCESS,
  BROADCAST_TEMPLATE_REASSIGN_SUCCESS
} from 'appState/actions/constants/broadcastCenter.actions';

const LIMIT = 20;

const initialState = {
  currentAccount: {},
  step: 'select-template',
  broadcasts: [],
  broadcastCounts: {
    sentAndSending: 0,
    draft: 0,
    scheduled: 0,
    error: 0
  },
  currentBroadcast: {},
  isTemplate: false,
  hasContentChanged: false,
  currentBroadcastTemplate: {},
  contacts: [],
  contactsFromFile: [],
  byContactIds: false,
  emails: [],
  sms: [],
  broadcastMetric: '',
  broadcastType: '',
  emailSubject: '',
  emailBody: '',
  smsBody: '',
  smsMedia: '',
  previewEmailSubject: '',
  previewEmailBody: '',
  previewSmsBody: '',
  previewSmsMedia: '',
  previewContact: {},
  products: [],
  templateType: null,
  testEmailSent: false,
  testEmailAddress: '',
  previewEmailFired: false,
  mergeFields: [],
  smsMergeFields: [],
  specialLinks: [],
  contact: {},
  selectedBroadcastId: -1,
  emailSaveAction: null,
  hasBeeLoaded: false,
  isCallingBroadcastSave: false,
  isEmailSaving: false,
  isSmsSaving: false,
  overrideNavigation: false,
  isAutoSaving: false,
  page: 1,
  total: 0,
  isChangingPage: false,
  totalPages: 0,
  broadcastsPage: 1,
  broadcastsTotal: 0,
  broadcastsTotalPages: 0,
  broadcastTemplates: [],
  audienceFilters: [],
  audienceCount: 0,
  contactsCounts: {},
  selectedFilters: null,
  selectedSuggestions: null
};

const getCounts = broadcasts => {
  return broadcasts.reduce(
    (acc, b) => {
      if (b.status === 'sent' || b.status === 'sending')
        acc.sentAndSending += 1;
      if (b.status === 'draft') acc.draft += 1;
      if (b.status === 'scheduled') acc.scheduled += 1;
      if (b.status === 'error') acc.error += 1;
      return acc;
    },
    {
      sentAndSending: 0,
      draft: 0,
      scheduled: 0,
      error: 0
    }
  );
};

export default function BroadcastCenterReducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case SET_CURRENT_ACCOUNT: {
        draft.currentAccount = action.payload;
        return;
      }
      case BROADCAST_SET_STEP: {
        const { step } = action.payload;
        draft.step = step;
        return;
      }
      case BROADCAST_SEND_TEST_EMAIL_SUCCESS: {
        const { testEmailSent } = action.payload;
        draft.testEmailSent = testEmailSent;
        return;
      }
      case RESET_TEST_EMAIL_SENT: {
        draft.testEmailSent = false;
        return;
      }
      case BROADCASTS_FETCH_REQUEST: {
        const page =
          (action.payload && action.payload.page) || initialState.page;
        draft.page = page;
        return;
      }
      case BROADCASTS_FETCH_SUCCESS: {
        const { broadcasts, page, total } = action.payload;
        const totalPages = Math.ceil(total / LIMIT);
        const broadcastCounts = getCounts(broadcasts);

        draft.broadcasts = broadcasts;
        draft.broadcastCounts = broadcastCounts;
        draft.broadcastsPage = page;
        draft.broadcastsTotal = total;
        draft.broadcastsTotalPages = totalPages;
        return;
      }
      case BROADCASTS_PAGE_CHANGE_REQUEST: {
        const { page } = action.payload;
        draft.page = page;
        draft.isChangingPage = true;
        return;
      }
      case BROADCASTS_PAGE_CHANGE_SUCCESS: {
        const { broadcasts, total } = action.payload;
        const broadcastCounts = getCounts(broadcasts);

        draft.broadcasts = broadcasts;
        draft.broadcastCounts = broadcastCounts;
        draft.total = total;
        draft.isChangingPage = false;
        return;
      }
      case BROADCASTS_RESET_PAGE_CHANGE: {
        draft.page = 1;
        draft.total = 0;
        draft.isChangingPage = false;
        draft.totalPages = 0;
        draft.broadcastsPage = 1;
        draft.broadcastsTotal = 0;
        draft.broadcastsTotalPages = 0;
        return;
      }
      case BROADCASTS_FETCH_CURRENT_BROADCAST_SUCCESS: {
        const { currentBroadcast } = action.payload;
        draft.currentBroadcast = currentBroadcast;
        return;
      }
      case BROADCASTS_FETCH_CURRENT_BROADCAST_TEMPLATE_SUCCESS: {
        const { currentBroadcastTemplate } = action.payload;
        draft.currentBroadcastTemplate = currentBroadcastTemplate;
        return;
      }
      case BROADCASTS_FETCH_CONTACTS_REQUEST: {
        if (action.payload) {
          const { broadcastMetric, broadcastType } = action.payload;
          draft.broadcastMetric = broadcastMetric;
          draft.broadcastType = broadcastType;
        }
        return;
      }
      case BROADCASTS_FETCH_CONTACTS_SUCCESS: {
        const { contacts, byContactIds } = action.payload;
        draft.contacts = contacts;
        draft.byContactIds = byContactIds;
        return;
      }
      case BROADCAST_RESET_CONTACTS: {
        draft.contacts = [];
        draft.byContactIds = false;
        return;
      }
      case BROADCASTS_FETCH_EMAILS_REQUEST: {
        const { broadcastMetric, broadcastType } = action.payload;
        draft.broadcastMetric = broadcastMetric;
        draft.broadcastType = broadcastType;
        return;
      }
      case BROADCASTS_FETCH_EMAILS_SUCCESS: {
        const { emails } = action.payload;
        draft.emails = emails;
        return;
      }
      case BROADCAST_RESET_EMAILS: {
        draft.emails = [];
        return;
      }
      case BROADCASTS_FETCH_SMS_REQUEST: {
        const { broadcastMetric, broadcastType } = action.payload;
        draft.broadcastMetric = broadcastMetric;
        draft.broadcastType = broadcastType;
        return;
      }
      case BROADCASTS_FETCH_SMS_SUCCESS: {
        const { sms } = action.payload;
        draft.sms = sms;
        return;
      }
      case BROADCAST_RESET_SMS: {
        draft.sms = [];
        return;
      }
      case BROADCAST_FETCH_PREVIEW_SUCCESS: {
        const {
          emailSubject,
          emailBody,
          smsBody,
          smsMedia,
          contact
        } = action.payload;
        draft.previewEmailSubject = emailSubject;
        draft.previewEmailBody = emailBody;
        draft.previewSmsBody = smsBody;
        draft.previewSmsMedia = smsMedia;
        draft.previewContact = contact;
        return;
      }
      case BROADCAST_RESET_PREVIEW_DATA: {
        draft.previewEmailSubject = '';
        draft.previewEmailBody = '';
        draft.previewSmsBody = '';
        draft.previewSmsMedia = '';
        draft.previewContact = {};
        return;
      }
      case BROADCAST_DELETE_SUCCESS: {
        const { broadcastId } = action.payload;
        draft.broadcasts = draft.broadcasts.filter(b => b.id !== broadcastId);
        draft.currentBroadcast = {};
        draft.hasContentChanged = false;
        return;
      }
      case BROADCAST_TEMPLATE_DELETE_SUCCESS: {
        const { broadcastTemplateId } = action.payload;
        draft.broadcastTemplates = draft.broadcastTemplates.filter(
          b => b.id !== broadcastTemplateId
        );
        return;
      }
      case BROADCAST_SET_SELECTED_BROADCAST: {
        const selectedBroadcastId = action.payload;
        draft.selectedBroadcastId = selectedBroadcastId;
        draft.currentBroadcast = draft.broadcasts.find(
          b => b.id === selectedBroadcastId
        );
        return;
      }
      case BROADCAST_SET_SELECTED_BROADCAST_TEMPLATE: {
        const selectedBroadcastTemplate = action.payload;
        draft.selectedBroadcastTemplateId = selectedBroadcastTemplate.id;
        draft.currentBroadcast = selectedBroadcastTemplate;
        return;
      }
      case BROADCAST_TEMPLATES_FETCH_REQUEST: {
        const page =
          (action.payload && action.payload.page) || initialState.page;
        draft.page = page;
        return;
      }
      case BROADCAST_TEMPLATES_FETCH_SUCCESS: {
        const { broadcastTemplates, page, total } = action.payload;
        const totalPages = Math.ceil(total / LIMIT);

        draft.broadcastTemplates = broadcastTemplates;
        draft.page = page;
        draft.total = total;
        draft.totalPages = totalPages;
        return;
      }
      case BROADCAST_TEMPLATES_PAGE_CHANGE_REQUEST: {
        const { page } = action.payload;
        draft.page = page;
        draft.isChangingPage = true;
        return;
      }
      case BROADCAST_TEMPLATES_PAGE_CHANGE_SUCCESS: {
        const { broadcastTemplates, total } = action.payload;

        draft.broadcastTemplates = broadcastTemplates;
        draft.total = total;
        draft.isChangingPage = false;
        return;
      }
      case BROADCAST_TEMPLATES_CREATE_BROADCAST_SUCCESS: {
        const { currentBroadcast } = action.payload;
        draft.currentBroadcast = currentBroadcast;
        return;
      }
      case BROADCASTS_FETCH_EMAIL_MERGE_TAGS_SUCCESS: {
        const { mergeFields, specialLinks } = action.payload;
        draft.mergeFields = mergeFields;
        draft.specialLinks = specialLinks;
        return;
      }
      case BROADCASTS_FETCH_SMS_MERGE_TAGS_SUCCESS: {
        const { mergeFields } = action.payload;
        draft.smsMergeFields = mergeFields;
        return;
      }
      case BROADCASTS_TEMPLATE_FETCH_SMS_MERGE_TAGS_SUCCESS: {
        const { mergeFields } = action.payload;
        draft.smsMergeFields = mergeFields;
        return;
      }
      case BROADCAST_SAVE_SMS_REQUEST: {
        draft.isSmsSaving = true;
        return;
      }
      case BROADCAST_SAVE_SMS_SUCCESS: {
        const { currentBroadcast } = action.payload;
        draft.currentBroadcast = currentBroadcast;
        draft.hasContentChanged = false;
        draft.isSmsSaving = false;
        return;
      }
      case BROADCAST_SAVE_SMS_FAILURE: {
        draft.isSmsSaving = false;
        return;
      }
      case BROADCAST_SAVE_EMAIL_REQUEST: {
        const { isAutoSaving } = action.payload;
        draft.isAutoSaving = isAutoSaving;
        draft.isEmailSaving = true;
        return;
      }
      case BROADCAST_SAVE_EMAIL_SUCCESS: {
        const { currentBroadcast } = action.payload;
        draft.currentBroadcast = currentBroadcast;
        draft.hasContentChanged = false;
        draft.isEmailSaving = false;
        return;
      }
      case BROADCAST_SAVE_EMAIL_FAILURE: {
        draft.isEmailSaving = false;
        return;
      }
      case BROADCAST_RESET_IS_AUTOSAVING: {
        draft.isAutoSaving = false;
        return;
      }
      case BROADCAST_SAVE_AS_TEMPLATE_SUCCESS: {
        if (!isEmpty(action.payload)) {
          const { currentBroadcast } = action.payload;
          draft.currentBroadcast = currentBroadcast;
        }
        return;
      }
      case BROADCAST_SEND_AGAIN_SUCCESS: {
        const { currentBroadcast } = action.payload;
        draft.currentBroadcast = currentBroadcast;
        return;
      }
      case BROADCAST_EDIT_SCHEDULED_BROADCAST_SUCCESS: {
        const { currentBroadcast } = action.payload;
        draft.currentBroadcast = currentBroadcast;
        draft.broadcasts = draft.broadcasts.map(b => {
          if (b.id === currentBroadcast.id) {
            b.status = 'draft';
          }
          return b;
        });
        return;
      }
      case BROADCAST_UPDATE_BROADCAST_SUCCESS: {
        const { currentBroadcast } = action.payload;
        draft.currentBroadcast = currentBroadcast;
        return;
      }
      case BROADCAST_CALL_EMAIL_SAVE_ACTION: {
        draft.isCallingBroadcastSave = true;
        draft.overrideNavigation = action.payload;
        return;
      }
      case BROADCAST_RESET_EMAIL_SAVE_ACTION: {
        draft.isCallingBroadcastSave = false;
        draft.overrideNavigation = false;
        return;
      }
      case BROADCAST_SET_EMAIL_SUBJECT: {
        draft.emailSubject = action.payload;
        return;
      }
      case BROADCAST_SET_TEST_EMAIL_ADDRESS: {
        draft.testEmailAddress = action.payload;
        return;
      }
      case BROADCAST_SET_PREVIEW_EMAIL_FIRED: {
        draft.previewEmailFired = action.payload;
        if (action.payload) {
          draft.previewEmailBody = '';
        }
        return;
      }
      case BROADCAST_SCHEDULE_SUCCESS: {
        draft.currentBroadcast = {};
        draft.hasContentChanged = false;
        return;
      }
      case BROADCAST_FETCH_AUDIENCE_FILTERS_SUCCESS: {
        const { audienceFilters } = action.payload;
        draft.audienceFilters = audienceFilters;
        return;
      }
      case BROADCAST_UPDATE_AUDIENCE_SUCCESS: {
        const { audience } = action.payload;
        draft.currentBroadcast.audience = audience;
        draft.hasContentChanged = false;
        return;
      }
      case BROADCAST_FETCH_AUDIENCE_COUNT_SUCCESS: {
        const { audienceCount } = action.payload;
        draft.audienceCount = audienceCount;
        return;
      }
      case BROADCAST_FETCH_FILTER_CONTACTS_COUNT_SUCCESS: {
        const { contactsCounts } = action.payload;
        draft.contactsCounts = contactsCounts;
        return;
      }
      case BROADCAST_SET_IS_TEMPLATE: {
        const isTemplate = action.payload;
        draft.isTemplate = isTemplate;
        return;
      }
      case BROADCAST_SET_HAS_CONTENT_CHANGED: {
        const hasContentChanged = action.payload;
        draft.hasContentChanged = hasContentChanged;
        return;
      }
      case BROADCAST_SET_BEE_EDITOR_HAS_LOADED: {
        const hasBeeLoaded = action.payload;
        draft.hasBeeLoaded = hasBeeLoaded;
        return;
      }
      case BROADCAST_TEMPLATE_SAVE_DETAILS_SUCCESS: {
        const { currentBroadcast } = action.payload;
        draft.currentBroadcast = currentBroadcast;
        draft.hasContentChanged = false;
        return;
      }
      case BROADCAST_TEMPLATE_CREATE_SUCCESS: {
        const { currentBroadcast } = action.payload;
        draft.currentBroadcast = currentBroadcast;
        return;
      }
      case BROADCAST_SET_SELECTED_FILTERS: {
        const { selectedFilters } = action.payload;
        draft.selectedFilters = selectedFilters;
        return;
      }
      case BROADCAST_SET_SELECTED_SUGGESTIONS: {
        const { selectedSuggestions } = action.payload;
        draft.selectedSuggestions = selectedSuggestions;
        return;
      }
      case BROADCAST_RESET_TO_INITIAL_STATE: {
        const initialStateClone = { ...initialState };
        delete initialStateClone.currentAccount;
        delete initialStateClone.broadcasts;
        delete initialStateClone.broadcastCounts;
        delete initialStateClone.broadcastsTotalPages;

        return {
          ...initialStateClone,
          currentAccount: state.currentAccount,
          broadcasts: state.broadcasts,
          broadcastCounts: state.broadcastCounts,
          broadcastsTotalPages: state.broadcastsTotalPages
        };
      }
      case BROADCAST_SEARCH_CONTACTS_BY_FILE_SUCCESS: {
        const { contactsFromFile } = action.payload;
        draft.contactsFromFile = contactsFromFile;
        return;
      }
      case BROADCAST_PRODUCTS_FETCH_SUCCESS: {
        const { products } = action.payload;
        draft.products = products;
        return;
      }
      case BROADCAST_TEMPLATE_REASSIGN_SUCCESS: {
        const { broadcastTemplate } = action.payload;
        draft.broadcastTemplates = draft.broadcastTemplates.map(template =>
          template.id === broadcastTemplate.id ? broadcastTemplate : template
        );
        return;
      }
      default:
        return draft;
    }
  });
}
