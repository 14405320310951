import React, { useContext, useEffect, Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';
import {
  createErrorMessageSelector,
  createLoadedSelector,
  createLoadingSelector
} from 'appState/selectors';
import {
  fetchPinnedTasksActivities,
  fetchTasksActivities,
  tasksActivitiesPageChange,
  resetIsLoaded
} from 'appState/actions/ActionCreators';
import { FETCH_TASKS_ACTIVITIES } from 'appState/actions/constants/activity.actions';
import TaskBuilder from 'components/Task/Builder';
import Notification from 'components/Theme/Notification';
import NotificationIcon from 'components/Theme/Icon/NotificationIcon';
import PinnedActivities from 'components/Contact/Profile/PinnedActivities';
import RecentActivities from 'components/Contact/Profile/RecentActivities';
import { CurrentAccountContext } from 'components/shared/context/CurrentAccountContext';
import {
  CREATE_TASK,
  UPDATE_TASK
} from 'app-state/actions/constants/task.actions';

const TasksTab = ({ contactId }) => {
  const dispatch = useDispatch();
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showNotificationMessage, setShowNotificationMessage] = useState('');
  const errorSelectorCreate = createErrorMessageSelector([CREATE_TASK]);
  const loadingSelectorCreate = createLoadingSelector([CREATE_TASK]);
  const loadedSelectorCreate = createLoadedSelector([CREATE_TASK]);
  const errorSelectorUpdate = createErrorMessageSelector([UPDATE_TASK]);
  const loadedSelectorUpdate = createLoadedSelector([UPDATE_TASK]);
  const taskActivitiesLoadedSelector = createLoadedSelector([
    FETCH_TASKS_ACTIVITIES
  ]);

  const structuredSelector = createStructuredSelector({
    currentContact: state => state.contact.currentContact,
    pinnedTasksActivities: state => state.activity.pinnedTasksActivities,
    tasksActivities: state => state.activity.tasksActivities,
    tasksTotal: state => state.activity.tasksTotal,
    tasksTotalPages: state => state.activity.tasksTotalPages,
    limit: state => state.activity.limit,
    errorCreate: state => errorSelectorCreate(state),
    isLoadingCreate: state => loadingSelectorCreate(state),
    isLoadedCreate: state => loadedSelectorCreate(state),
    errorUpdate: state => errorSelectorUpdate(state),
    isLoadedUpdate: state => loadedSelectorUpdate(state),
    isLoadedTaskActivities: state => taskActivitiesLoadedSelector(state)
  });

  const {
    currentContact,
    pinnedTasksActivities,
    tasksActivities,
    tasksTotal,
    tasksTotalPages,
    limit,
    errorCreate,
    isLoadingCreate,
    isLoadedCreate,
    errorUpdate,
    isLoadedUpdate,
    isLoadedTaskActivities
  } = useSelector(structuredSelector);

  const currentAccount = useContext(CurrentAccountContext);

  useEffect(() => {
    if (isEmpty(pinnedTasksActivities) && !isEmpty(currentContact))
      dispatch(
        fetchPinnedTasksActivities({
          contactId: currentContact.id
        })
      );
  }, [dispatch, currentContact]);

  useEffect(() => {
    if (isEmpty(tasksActivities) && !isEmpty(currentContact))
      dispatch(
        fetchTasksActivities({
          contactId: currentContact.id
        })
      );
  }, [dispatch, currentContact]);

  useEffect(() => {
    if (isLoadedCreate && isEmpty(errorCreate)) {
      setShowSuccess(true);
      setShowNotificationMessage('Task created');
    } else if (isLoadedUpdate && isEmpty(errorUpdate)) {
      setShowSuccess(true);
      setShowNotificationMessage('Task updated');
    } else if (isLoadedCreate && !isEmpty(errorCreate)) {
      setShowError(true);
      setShowNotificationMessage('Task not created');
    } else if (isLoadedUpdate && !isEmpty(errorUpdate)) {
      setShowError(true);
      setShowNotificationMessage('Task not updated');
    }

    dispatch(resetIsLoaded(UPDATE_TASK));
    dispatch(resetIsLoaded(CREATE_TASK));

    return () => {
      dispatch(resetIsLoaded(UPDATE_TASK));
      dispatch(resetIsLoaded(CREATE_TASK));
    };
  }, [errorCreate, isLoadedCreate, dispatch, isLoadedUpdate, errorUpdate]);

  return (
    <Fragment>
      <div className="tw-py-5">
        <div className="tw-grid tw-grid-cols-12 tw-gap-6">
          <div className="tw-col-span-12">
            <TaskBuilder
              contactId={contactId}
              isLoadedCreate={isLoadedCreate}
              isLoadingCreate={isLoadingCreate}
              errorCreate={errorCreate}
              isLoadedUpdate={isLoadedUpdate}
              errorUpdate={errorUpdate}
            />
            <div className="tw-border-t tw-border-b-0 tw-border-l-0 tw-border-r-0 tw-border-solid tw-border-gray-200 tw-col-span-12" />
          </div>
          <div className="tw-col-span-12 tw-px-4">
            <PinnedActivities
              tabName="tasks"
              activities={pinnedTasksActivities}
            />
            <RecentActivities
              activities={tasksActivities}
              total={tasksTotal}
              totalPages={tasksTotalPages}
              limit={limit}
              currentContact={currentContact}
              currentAccount={currentAccount}
              isLoaded={isLoadedTaskActivities}
              resetAction={FETCH_TASKS_ACTIVITIES}
              pageChangeAction={tasksActivitiesPageChange}
            />
          </div>
        </div>
      </div>

      <Notification
        message={showNotificationMessage}
        show={showSuccess}
        setShowAction={setShowSuccess}
        type="colored"
        headerText="Success!"
        color="success"
        HeaderIcon={color => {
          return (
            <NotificationIcon color={color} tint="400" width="5" height="5" />
          );
        }}
      />

      <Notification
        message={showNotificationMessage}
        show={showError}
        setShowAction={setShowError}
        type="colored"
        headerText="Error!"
        color="error"
        HeaderIcon={color => {
          return (
            <NotificationIcon
              color={color}
              tint="400"
              width="5"
              height="5"
              filled
            />
          );
        }}
      />
    </Fragment>
  );
};

TasksTab.propTypes = {
  contactId: PropTypes.string.isRequired
};

export default TasksTab;
