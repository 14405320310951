import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const ShowMore = props => {
  const {
    show,
    setShow,
    maxHeight,
    color,
    children,
    buttonClasses,
    showText,
    hideText
  } = props;
  const [showMore, setShowMore] = useState(false);

  const handleSetShowMore = () => {
    if (setShow) {
      // handle show/hide from parent
      setShow(!show);
    } else {
      // handle show/hide state internally
      setShowMore(prevShowMore => !prevShowMore);
    }
  };

  useEffect(() => {
    setShowMore(show);
  }, [show]);

  return (
    <div>
      <div
        style={{
          maxHeight: showMore ? maxHeight : 0,
          opacity: showMore ? 100 : 0,
          transition: showMore ? 'all 0.4s ease-in' : 'all 0.3s ease-out',
          overflow: 'hidden'
        }}
      >
        {children}
      </div>
      <div
        role="button"
        data-testid="show-more"
        tabIndex="-1"
        onClick={handleSetShowMore}
        onKeyDown={e => {
          if (e.code === 'Enter') handleSetShowMore();
        }}
        className={`tw-cursor-pointer tw-text-${color}-600 ${buttonClasses}`}
      >
        {showMore ? hideText : showText}
      </div>
    </div>
  );
};

ShowMore.defaultProps = {
  show: false,
  setShow: null,
  maxHeight: '500px',
  color: 'alpha',
  children: null,
  buttonClasses: '',
  showText: '+ Show more',
  hideText: '- Show less'
};

ShowMore.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  maxHeight: PropTypes.string,
  color: PropTypes.oneOf([
    'alpha',
    'bravo',
    'charlie',
    'success',
    'warning',
    'error',
    'gray'
  ]),
  buttonClasses: PropTypes.string,
  showText: PropTypes.string,
  hideText: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func])
};

export default ShowMore;
