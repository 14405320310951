export function scrollToTop(id = null, scrollBehavior = 'auto') {
  const element = id ? document.getElementById(id) : window;
  element.scrollTo({ top: 0, behavior: scrollBehavior });
}

export function scrollToBottom(id = null, scrollBehavior = 'auto') {
  const element = id ? document.getElementById(id) : window;
  element.scrollTo({ top: element.scrollHeight, behavior: scrollBehavior });
}

export function scrollTo(
  id = null,
  scrollBehavior = 'auto',
  scrollToYPosition = 0,
  scrollToXPosition = 0
) {
  const element = id ? document.getElementById(id) : window;
  element.scrollTo({
    top: scrollToYPosition,
    left: scrollToXPosition,
    behavior: scrollBehavior
  });
}
