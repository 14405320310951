import React from 'react';
import PropTypes from 'prop-types';
import HeroIcon from 'components/Theme/HeroIcon';

const Alert = ({ headerText, bodyText, color, icon, containerClasses }) => {
  return (
    <div
      className={`tw-rounded-md tw-bg-${color}-100 tw-p-4 ${containerClasses}`}
    >
      <div className="tw-flex">
        {icon && (
          <div className="tw-flex-shrink-0">
            <HeroIcon icon={icon} color={color} />
          </div>
        )}
        <div className="tw-ml-3">
          <h3 className={`tw-text-sm tw-font-medium tw-text-${color}-800`}>
            {headerText}
          </h3>
          <div className={`tw-mt-2 tw-text-sm tw-text-${color}-700`}>
            {Array.isArray(bodyText) ? (
              <ul className="tw-list-disc tw-pl-5 tw-space-y-1">
                {bodyText.map(item => (
                  <li key={item}>{item}</li>
                ))}
              </ul>
            ) : (
              <div>{bodyText}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

Alert.defaultProps = {
  color: 'alpha',
  icon: '',
  containerClasses: 'tw-my-3'
};

Alert.propTypes = {
  headerText: PropTypes.string.isRequired,
  icon: PropTypes.string,
  bodyText: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  color: PropTypes.oneOf([
    'alpha',
    'bravo',
    'charlie',
    'success',
    'warning',
    'error',
    'gray'
  ]),
  containerClasses: PropTypes.string
};

export default Alert;
