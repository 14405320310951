import { v4 as uuidv4 } from 'uuid';
import { FLASH_MESSAGE_ACTION_TYPE } from 'app-state/actions/constants/flashMessage.actions';
import {
  clearFlashMessages,
  addFlashMessage,
  removeFlashMessage
} from 'app-state/actions/ActionCreators';

const DEFAULT_TIMEOUT = 5000;
const DEFAULT_PROPS = {};

function flashMessageMiddleware(options = {}) {
  const {
    getState,
    timeout: globalTimeout = DEFAULT_TIMEOUT,
    props = DEFAULT_PROPS
  } = options;
  if (getState)
    throw new Error('middleware must be initialized as a function.');
  // Handle actions
  return () => next => action => {
    // Only handle actions of special type
    if (action.type !== FLASH_MESSAGE_ACTION_TYPE) return next(action);
    const {
      payload: {
        message,
        options: {
          push = false,
          isError = false,
          timeout = globalTimeout,
          actionProps = {}
        }
      }
    } = action;
    const id = uuidv4();
    // Dispatch appropriate internal actions
    if (push) next(clearFlashMessages());
    next(
      addFlashMessage({
        id,
        message,
        isError,
        timeout,
        props: { ...props, ...actionProps }
      })
    );
    if (timeout) setTimeout(() => next(removeFlashMessage({ id })), timeout);
  };
}

export default flashMessageMiddleware;
