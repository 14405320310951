import React, {
  useContext,
  useState,
  useEffect,
  useMemo,
  useCallback
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useParams, useHistory } from 'react-router-dom';
import groupBy from 'lodash.groupby';
import isEmpty from 'lodash.isempty';
import { CurrentAccountContext } from 'components/shared/context/CurrentAccountContext';
import {
  fetchAddContactsFilters,
  addContactsToProduct,
  fetchProductSubscription,
  flashSuccessMessage,
  flashErrorMessage,
  resetIsLoaded
} from 'app-state/actions/ActionCreators';
import {
  createErrorMessageSelector,
  createLoadedSelector,
  createLoadingSelector
} from 'app-state/selectors';
import {
  ADD_CONTACTS_TO_PRODUCT,
  FETCH_PRODUCT_SUBSCRIPTION
} from 'app-state/actions/constants/productSubscription.actions';
import MultiSelect from 'components/Theme/MultiSelect';
import Alert from 'components/Theme/Alert';
import PageHeader from 'components/Theme/PageHeader';
import Card from 'components/Theme/Card';
import Footer from 'components/SyncWizard/shared/Footer';

const ProductAddContacts = () => {
  const currentAccount = useContext(CurrentAccountContext);
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const errorSelector = createErrorMessageSelector([ADD_CONTACTS_TO_PRODUCT]);
  const addContactLoadedSelector = createLoadedSelector([
    ADD_CONTACTS_TO_PRODUCT
  ]);

  const productSubscriptionLoadedSelector = createLoadedSelector([
    FETCH_PRODUCT_SUBSCRIPTION
  ]);

  const addContactLoadingSelector = createLoadingSelector([
    ADD_CONTACTS_TO_PRODUCT
  ]);

  const [contactSegmentsToCampaigns, setContactSegmentsToCampaigns] = useState(
    {}
  );

  const structuredSelector = createStructuredSelector({
    addContactsFilters: state => state.productSubscription.addContactsFilters,
    currentProductSubscription: state =>
      state.productSubscription.currentProductSubscription,
    isAddContactLoaded: state => addContactLoadedSelector(state),
    isAddContactLoading: state => addContactLoadingSelector(state),
    isProductSubscriptionLoaded: state =>
      productSubscriptionLoadedSelector(state),
    addContactError: state => errorSelector(state)
  });

  const {
    addContactsFilters,
    currentProductSubscription,
    isAddContactLoaded,
    isAddContactLoading,
    isProductSubscriptionLoaded,
    addContactError
  } = useSelector(structuredSelector);

  function filterGroupsOptionName(category, option) {
    if (category === 'groups' && option.contacts_count >= 0) {
      const countStr = option.contacts_count > 1 ? 'contacts' : 'contact';
      return `${option.name} (${option.contacts_count} ${countStr})`;
    }

    return option.name;
  }

  const addContactsFiltersGroupedOptions = useMemo(() => {
    const options = groupBy(addContactsFilters, 'category');

    const result = Object.keys(options).map(cat => {
      return {
        label: cat,
        options: options[cat].map(opt => ({
          displayName: filterGroupsOptionName(cat, opt),
          value: opt.id
        }))
      };
    });

    return result;
  }, [addContactsFilters]);

  const onMultiSelectChange = useCallback(
    (campaign, value) => {
      const newContactSegmentsToCampaigns = { ...contactSegmentsToCampaigns };
      newContactSegmentsToCampaigns[campaign.id] = value;
      setContactSegmentsToCampaigns(newContactSegmentsToCampaigns);
    },
    [contactSegmentsToCampaigns]
  );

  useEffect(() => {
    if (
      isEmpty(currentProductSubscription) ||
      currentProductSubscription.id !== parseInt(id)
    ) {
      dispatch(
        fetchProductSubscription({
          productSubscriptionId: id,
          currentAccountId: currentAccount.id
        })
      );
    }
  }, [dispatch, currentAccount, currentProductSubscription, id]);

  useEffect(() => {
    if (!addContactsFilters?.length)
      dispatch(
        fetchAddContactsFilters({ currentAccountId: currentAccount.id })
      );
  }, [dispatch, addContactsFilters]);

  useEffect(() => {
    if (isAddContactLoaded) {
      if (isEmpty(addContactError)) {
        dispatch(flashSuccessMessage('Contacts saved successfully.'));
        dispatch(resetIsLoaded(ADD_CONTACTS_TO_PRODUCT));
        history.push(`/ui/my-products`);
      } else dispatch(flashErrorMessage(addContactError));
    }
  }, [dispatch, isAddContactLoaded, addContactError]);

  function handleAddContacts() {
    if (isEmpty(contactSegmentsToCampaigns)) return;

    dispatch(
      addContactsToProduct({
        productSubscriptionId: currentProductSubscription.id,
        contactSegmentsToCampaigns,
        currentAccountId: currentAccount.id
      })
    );
  }

  function getAlert() {
    return (
      <div className="sm:tw-px-6 lg:tw-px-8 md:tw-px-4 tw-mt-6">
        <Alert
          headerText="Things to know"
          bodyText={[
            'Campaigns will start immediately.',
            'Contacts added are only those who are not currently or have been previously enrolled in that campaign.'
          ]}
          icon="information-circle"
        />
      </div>
    );
  }

  function getPageHeader() {
    const prdouctName = currentProductSubscription?.product?.name;
    const title = prdouctName ? `Add Contacts: ${prdouctName}` : '';
    const breadcrumbs = [
      {
        name: 'My Products',
        action: () => {
          history.push(`/ui/my-products`);
        }
      },
      {
        name: prdouctName
      }
    ];

    return (
      <PageHeader
        title={title}
        breadcrumbs={breadcrumbs}
        backgroundColor="white"
        isLoaded={isProductSubscriptionLoaded}
      />
    );
  }

  function getPageBody() {
    if (!isProductSubscriptionLoaded) {
      return (
        <div className="tw-flex tw-items-center tw-justify-center tw-rounded-lg tw-bg-white tw-m-8 tw-mb-20 tw-min-h-screen">
          <div className="center-vh">
            <div className="spinner-dots">
              <span className="dot1" />
              <span className="dot2" />
              <span className="dot3" />
            </div>
          </div>
        </div>
      );
    }

    return (
      <Card
        containerClass="tw-rounded-lg tw-bg-white tw-m-8 tw-mb-20 tw-min-h-screen"
        customMarginContainer
        header={getAlert()}
      >
        <div className="tw-grid tw-grid-cols-12 sm:tw-px-6 lg:tw-px-8 tw-mt-6">
          {currentProductSubscription?.product?.product_campaigns.map(
            campaign => {
              return (
                campaign.published_at && (
                  <MultiSelect
                    key={campaign.id}
                    id={`add-contacts-to-campaign-${campaign.id}`}
                    value={contactSegmentsToCampaigns[campaign.id]}
                    options={addContactsFiltersGroupedOptions}
                    labelText={campaign.name}
                    onChange={value => onMultiSelectChange(campaign, value)}
                    widthClass="tw-flex tw-flex-col tw-items-center tw-col-span-12 sm:tw-col-span-6 sm:tw-px-1 lg:tw-px-2 tw-mb-8"
                  />
                )
              );
            }
          )}
        </div>
        <Footer
          primaryActionText="Add Contacts"
          primaryAction={handleAddContacts}
          isLoaded={!isAddContactLoading}
        />
      </Card>
    );
  }

  return (
    <div>
      {getPageHeader()}
      {getPageBody()}
    </div>
  );
};

export default ProductAddContacts;
