import React, { Fragment } from 'react';
import get from 'lodash.get';
import groupBy from 'lodash.groupby';
import Chip from 'components/Chip';
import { toSentence } from 'lib/utils/string';

const FILTER_CATEGORY_ORDER = [
  'lifecycle',
  'groups',
  'campaign',
  'campaign_overlay',
  'assigned_staff',
  'journey',
  'appointment_booked',
  'appointment_not_booked',
  'appointment_attended',
  'appointment_not_attended',
  'age',
  'gender'
];

const connectorChip = (connector, key) => {
  return (
    <Chip
      key={key || connector}
      text={connector}
      color={connector.toUpperCase() === 'AND' ? 'danger' : 'success'}
      hasLeftMargin
    />
  );
};

const toSentenceChips = (arr, connector = 'AND', onClickAction = null) => {
  const firstItem = arr[0];
  if (arr.length <= 1)
    return (
      <Fragment>
        {arr.length === 1 ? (
          <Chip
            key={firstItem.id}
            text={firstItem.name}
            deletable={!!onClickAction}
            onDelete={onClickAction ? e => onClickAction(e, firstItem) : null}
            hasLeftMargin
          />
        ) : null}
      </Fragment>
    );
  const lastItem = arr[arr.length - 1];
  return (
    <Fragment>
      {arr
        .slice(0, arr.length - 1)
        .map(f => {
          return (
            <Chip
              key={f.id}
              text={f.name}
              deletable={!!onClickAction}
              onDelete={onClickAction ? e => onClickAction(e, f) : null}
              hasLeftMargin
            />
          );
        })
        .reduce((prev, curr) => [
          prev,
          connectorChip(connector, `${prev.id}-${curr.id}`),
          curr
        ])}
      {connectorChip(connector)}
      <Chip
        key={lastItem.id}
        text={lastItem.name}
        deletable={!!onClickAction}
        onDelete={onClickAction ? e => onClickAction(e, lastItem) : null}
        hasLeftMargin
      />
    </Fragment>
  );
};

const groupedFiltersByCategory = filters => {
  return groupBy(filters, 'category');
};

const buildSentenceFromSubfilters = subfilters => {
  const subfiltersArray = subfilters.map(f => f.name);
  return toSentence(subfiltersArray);
};

const buildAudienceShortSummary = template => {
  const mainFilter = get(template, 'select_audience_by');
  let subfilters;
  const audienceSummary = [`${template.template_type} will be sent to`];

  if (mainFilter.toLowerCase() === 'lifecycle') {
    subfilters = get(template, 'audience.filters').filter(
      f => f.category.toLowerCase() === 'lifecycle'
    );
    audienceSummary.push(
      `contacts that are in the ${buildSentenceFromSubfilters(
        subfilters
      )} lifecycle${subfilters.length > 1 ? 's' : ''}.`
    );
  } else if (mainFilter.toLowerCase() === 'everyone') {
    audienceSummary.push('everyone.');
  } else if (mainFilter.toLowerCase() === 'groups') {
    subfilters = get(template, 'audience.filters').filter(
      f => f.category.toLowerCase() === 'groups'
    );
    audienceSummary.push(
      `contacts that are in the ${buildSentenceFromSubfilters(
        subfilters
      )} group${subfilters.length > 1 ? 's' : ''}.`
    );
  } else if (mainFilter.toLowerCase() === 'searching') {
    audienceSummary.push('manually selected contacts.');
  }

  return audienceSummary.join(' ');
};

const buildAudienceFullSummary = (broadcast, onClickAction) => {
  const filters = get(broadcast, 'audience.filters');
  const groupedFilters = groupedFiltersByCategory(filters);
  const audienceSummary = [];

  FILTER_CATEGORY_ORDER.forEach((category, index) => {
    if (!Object.keys(groupedFilters).includes(category)) return;

    const categoryFilters = groupedFilters[category];
    const filterPairs = categoryFilters.map(f => {
      if (f.category === 'campaign') {
        return {
          id: f.id,
          name: `${f.owner_name}: ${f.name}`
        };
      } else {
        return {
          id: f.id,
          name: f.name
        };
      }
    });

    audienceSummary.push(
      <div
        key={category}
        className="d-flex flex-wrap"
        style={{ lineHeight: '28px' }}
      >
        {index > 1 && <Chip text="AND" color="danger" />}
        {categoryFilters[0].pre_text}{' '}
        {toSentenceChips(filterPairs, 'OR', onClickAction)}{' '}
        {categoryFilters[0].post_text}
      </div>
    );
  });

  return audienceSummary;
};

export {
  buildSentenceFromSubfilters,
  buildAudienceShortSummary,
  buildAudienceFullSummary
};
