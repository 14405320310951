import React, { useState, useRef } from 'react';
import htmlToDraft from 'html-to-draftjs';
import { ContentState, EditorState, Modifier } from 'draft-js';
import PropTypes from 'prop-types';
import useLockBodyScroll from 'components/shared/hooks/useLockBodyScroll';
import useOutsideClick from 'components/shared/outsideClickHandler';

const CustomDropDown = ({
  optionData,
  editorState,
  onChange,
  dropDownName
}) => {
  const ref = useRef();
  const [open, setOpen] = useState(false);
  let listItem = [];

  useLockBodyScroll(open, ref.current);

  useOutsideClick(ref, () => {
    setOpen(false);
  });

  const openPlaceholderDropdown = () => {
    setOpen(!open);
  };
  const addPlaceholder = (text, url) => {
    const anchorTag = url
      ? `<a class="anchorrr" href=${url}> ${text} </a>`
      : `<span>${text}</span>`;
    const { contentBlocks, entityMap } = htmlToDraft(anchorTag);
    const contentState = Modifier.replaceWithFragment(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      ContentState.createFromBlockArray(contentBlocks, entityMap).getBlockMap()
    );
    onChange(EditorState.push(editorState, contentState, 'insert-fragment'));
  };
  if (optionData && optionData.length) {
    listItem = optionData.map(item => (
      <a
        onKeyDown={addPlaceholder}
        key={item.key}
        data-testid={item.key}
        onClick={addPlaceholder.bind(this, item.value, item.url)}
        role="button"
        tabIndex={0}
        className="custom-dropdown-links"
      >
        <li
          key={item.key}
          className="rdw-dropdownoption-default placeholder-li custom-dropdown-option"
        >
          {item.text}
        </li>
      </a>
    ));
  }

  return (
    <div
      onClick={openPlaceholderDropdown}
      className="rdw-block-wrapper"
      aria-label="rdw-block-control"
      onKeyDown={addPlaceholder}
      role="link"
      data-testid="link"
      tabIndex={-1}
      ref={ref}
    >
      <div
        className="rdw-dropdown-wrapper rdw-block-dropdown"
        aria-label="rdw-dropdown"
        style={{ width: '200px' }}
      >
        <div className="rdw-dropdown-selectedtext" title="Placeholders">
          <span>{dropDownName}</span>
          <div className="tw-absolute tw-w-4 tw-right-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="tw-relative tw-top-2.5 tw-text-gray-400"
            >
              <path
                fillRule="evenodd"
                d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                clipRule="evenodd"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="tw-relative tw-bottom-1 tw-text-gray-400"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        </div>
        <ul
          style={{ display: open ? 'block' : 'none' }}
          className={open ? `rdw-dropdown-optionwrapper` : ''}
        >
          {listItem}
        </ul>
      </div>
    </div>
  );
};

const optionShape = {
  key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  text: PropTypes.string,
  url: PropTypes.string,
  value: PropTypes.string
};

CustomDropDown.defaultProps = {
  optionData: [],
  editorState: {},
  onChange: null,
  dropDownName: ''
};
CustomDropDown.propTypes = {
  optionData: PropTypes.arrayOf(PropTypes.shape(optionShape)),
  editorState: PropTypes.oneOfType([PropTypes.object]),
  onChange: PropTypes.func,
  dropDownName: PropTypes.string
};
export default React.memo(CustomDropDown);
