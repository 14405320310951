/* eslint-disable react/button-has-type */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Spinner from 'components/Theme/Spinner';

const Button = ({
  testId,
  text,
  buttonRef,
  disabled,
  isLoaded,
  isFullWidth,
  size,
  layout,
  type,
  color,
  onClick,
  buttonStyle,
  containerStyle,
  containerClass,
  disabledStyle,
  disabledTitleStyle,
  titleStyle,
  showSvg,
  isButtonWithDropdown,
  buttonClass,
  actionType
}) => {
  const [spinnerSize, setSpinnerSize] = useState('sm');

  function getButtonSizeAttributes() {
    switch (size) {
      case 'xs': {
        return 'tw-rounded tw-px-2.5 tw-py-1.5 tw-text-xs tw-leading-4';
      }
      case 'sm': {
        return 'tw-rounded-md tw-px-3 tw-py-2 tw-text-sm tw-leading-4';
      }
      case 'md': {
        return 'tw-rounded-md tw-px-4 tw-py-2 tw-text-sm tw-leading-5';
      }
      case 'lg': {
        return 'tw-rounded-md tw-px-4 tw-py-2 tw-text-base tw-leading-6';
      }
      case 'xl': {
        return 'tw-rounded-md tw-px-6 tw-py-3 tw-text-base tw-leading-6';
      }
      default: {
        return 'tw-rounded-md tw-px-4 tw-py-2 tw-text-sm tw-leading-5';
      }
    }
  }

  function getLayoutTypeAttributes() {
    switch (layout) {
      case 'left-button': {
        return 'tw-rounded-l-md tw-px-4 tw-py-2 tw-text-sm tw-leading-5';
      }
      case 'right-button': {
        return 'tw-rounded-r-md tw-py-2 tw-text-sm tw-leading-5';
      }
      default: {
        return 'tw-rounded-md tw-py-2 tw-text-sm tw-leading-5';
      }
    }
  }

  function getButtonTypeAttributes() {
    switch (type) {
      case 'primary': {
        return `tw-border-transparent tw-text-white tw-bg-${color}-600 focus:tw-border-${color}-500 active:tw-bg-${color}-500 focus:tw-shadow-outline-${color} active:tw-bg-${color}-500 ${
          disabled ? '' : `hover:tw-bg-${color}-500`
        }`;
      }
      case 'secondary': {
        return `tw-border-transparent tw-text-${color}-700 tw-bg-${color}-100 focus:tw-border-${color}-300 focus:tw-shadow-outline-${color} active:tw-bg-${color}-200 ${
          disabled ? '' : `hover:tw-bg-${color}-50`
        }`;
      }
      case 'secondaryWithBorder': {
        return `tw-border-solid tw-text-${color}-600 tw-bg-${color}-100 tw-border-${color}-600 focus:tw-shadow-outline-${color} active:tw-bg-${color}-200 ${
          disabled ? '' : `hover:tw-bg-${color}-50`
        }`;
      }
      case 'white': {
        return `tw-border-gray-300 tw-border-solid tw-text-gray-700 tw-bg-white focus:tw-border-blue-300 focus:tw-shadow-outline-blue active:tw-text-gray-800 active:tw-bg-gray-50 ${
          disabled ? '' : 'hover:tw-text-gray-500'
        }`;
      }
      case 'gamma': {
        return 'tw-bg-white tw-text-alpha-500 tw-border-gray-300 tw-border-solid tw-text-gray-700 tw-bg-white focus:tw-border-blue-300 focus:tw-shadow-outline-blue active:tw-text-gray-800 active:tw-bg-gray-50';
      }
      default: {
        return `tw-border-transparent tw-text-white tw-bg-${color}-600 focus:tw-border-${color}-500 active:tw-bg-${color}-500 focus:tw-shadow-outline-${color} active:tw-bg-${color}-500 ${
          disabled ? '' : `hover:tw-bg-${color}-500`
        }`;
      }
    }
  }

  const getSpinnerSize = useCallback(() => {
    if (size === 'xs') {
      setSpinnerSize('xs');
    } else if (size === 'xl') {
      setSpinnerSize('md');
    } else {
      setSpinnerSize('sm');
    }
  }, [size]);

  useEffect(() => {
    getSpinnerSize();
  }, [getSpinnerSize]);

  return (
    <span
      className={`tw-inline-flex tw-rounded-md tw-shadow-sm ${
        isFullWidth ? 'tw-w-full' : ''
      } ${containerClass}`}
      style={containerStyle}
    >
      <button
        ref={buttonRef}
        data-testid={testId}
        type={actionType}
        className={`tw-font-body tw-inline-flex tw-items-center tw-justify-center tw-border tw-font-medium focus:tw-outline-none tw-transition tw-ease-in-out tw-duration-150 ${
          disabled ? 'tw-opacity-50' : `tw-cursor-pointer`
        } ${
          isButtonWithDropdown
            ? getLayoutTypeAttributes()
            : getButtonSizeAttributes()
        }
          ${isFullWidth ? 'tw-w-full' : ''}
          ${getButtonTypeAttributes()} ${buttonClass && buttonClass}`}
        disabled={disabled}
        onClick={onClick}
        style={disabled ? { ...disabledStyle, ...buttonStyle } : buttonStyle}
      >
        <span
          style={
            disabled ? { ...disabledTitleStyle, ...titleStyle } : titleStyle
          }
        >
          {isLoaded ? (
            text
          ) : (
            <div className="tw-px-3">
              <Spinner
                size={spinnerSize}
                color={type === 'white' ? 'gray' : 'white'}
                tint="400"
              />
            </div>
          )}
        </span>
        {showSvg ? (
          <svg
            className="tw-h-5 tw-w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
          </svg>
        ) : null}
      </button>
    </span>
  );
};

Button.defaultProps = {
  testId: 'button',
  buttonRef: null,
  buttonClass: '',
  disabled: false,
  isLoaded: true,
  isFullWidth: false,
  size: 'md',
  layout: 'left-button',
  type: 'primary',
  color: 'alpha',
  onClick: () => {},
  buttonStyle: {},
  containerStyle: {},
  containerClass: '',
  disabledStyle: {},
  disabledTitleStyle: {},
  titleStyle: {},
  showSvg: false,
  isButtonWithDropdown: false,
  actionType: 'button'
};

const styleProps = PropTypes.objectOf(
  PropTypes.oneOfType([PropTypes.string, PropTypes.number])
);

Button.propTypes = {
  testId: PropTypes.string,
  text: PropTypes.string.isRequired,
  buttonRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]),
  disabled: PropTypes.bool,
  isLoaded: PropTypes.bool,
  buttonStyle: styleProps,
  containerStyle: styleProps,
  containerClass: PropTypes.string,
  disabledStyle: styleProps,
  disabledTitleStyle: styleProps,
  titleStyle: styleProps,
  showSvg: PropTypes.bool,
  isButtonWithDropdown: PropTypes.bool,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'w-100']),
  layout: PropTypes.oneOf(['left-button', 'right-button']),
  type: PropTypes.oneOf([
    'primary',
    'secondary',
    'secondaryWithBorder',
    'white',
    'gamma'
  ]),
  isFullWidth: PropTypes.bool,
  color: PropTypes.oneOf([
    'alpha',
    'bravo',
    'charlie',
    'success',
    'warning',
    'error',
    'gray'
  ]),
  onClick: PropTypes.func,
  buttonClass: PropTypes.string,
  actionType: PropTypes.string
};

export default Button;
