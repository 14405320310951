import React from 'react';
import UserObjectReassignment from './UserObjectReassignment';
import AvailabilityCloner from './AvailabilityCloner';

const StaffMemberUtilities = () => {
  return (
    <div className="tab-pane active" id="staff-member" role="tabpanel">
      <div className="row">
        <UserObjectReassignment />
        <AvailabilityCloner />
      </div>
    </div>
  );
};

export default StaffMemberUtilities;
