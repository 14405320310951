/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toCamel } from 'lib/utils/string';
import HeroIcon from 'components/Theme/HeroIcon';

const FileInput = ({
  id,
  value,
  labelText,
  placeholder,
  required,
  inputStyle,
  containerStyle,
  labelStyle,
  onChange,
  showError,
  error,
  helpText,
  hideLabel,
  widthClass,
  disabled
}) => {
  function getName() {
    return toCamel(id);
  }

  const fileRef = React.createRef();
  const [isFocused, setIsFocused] = useState(false);

  function handleOnFocus() {
    setIsFocused(true);
  }

  function handleOnBlur() {
    setIsFocused(false);
  }

  function buttonBorderClass() {
    if (showError) {
      return 'tw-border-red-300';
    }
    if (isFocused) {
      return 'tw-border-blue-300';
    }
    return 'tw-border-gray-300';
  }

  return (
    <div className={widthClass} style={containerStyle}>
      <label
        htmlFor={id}
        className={`${
          hideLabel
            ? 'tw-sr-only'
            : 'tw-font-body tw-block tw-text-sm tw-font-medium tw-leading-5 tw-text-gray-700'
        }`}
        style={labelStyle}
      >
        {labelText}
        <span className="tw-text-error-700">{required ? ' *' : null}</span>
      </label>
      <div className="tw-mt-1 tw-relative">
        {value && (
          <div
            data-testid="file-input-value"
            className="tw-absolute tw-inset-y-0 tw-left-0 tw-pl-3 tw-flex tw-items-center tw-cursor-pointer"
            onClick={() => onChange(null)}
          >
            <HeroIcon icon="x" color="error" />
          </div>
        )}

        <input
          data-testid={id}
          id={id}
          className={`tw-font-body tw-form-input tw-block tw-w-full tw-py-2 tw-px-3 tw-border tw-border-solid tw-border-${
            showError ? 'red' : 'gray'
          }-300 tw-shadow-sm focus:tw-outline-none focus:tw-shadow-outline-${
            showError ? 'red' : 'blue'
          } focus:tw-border-${
            showError ? 'red' : 'blue'
          }-300 tw-transition tw-duration-150 tw-ease-in-out tw-text-sm tw-leading-5 tw-px-10 tw-rounded-md tw-cursor-pointer`}
          style={inputStyle}
          placeholder={placeholder}
          type="text"
          name={getName()}
          value={value}
          onBlur={handleOnBlur}
          onFocus={handleOnFocus}
          readOnly
          onClick={() => fileRef.current.click()}
        />
        <input
          type="file"
          onChange={e => onChange(e.currentTarget.files[0])}
          ref={fileRef}
          hidden
          disabled={disabled}
        />
        <div
          className={`tw-bg-gray-200 tw-absolute tw-inset-y-0 tw-right-0 tw-px-3 tw-flex tw-items-center tw-pointer-events-none tw-border tw-border-l-0 tw-border-solid ${buttonBorderClass()} tw-shadow-sm tw-rounded-r-md tw-cursor-pointer`}
        >
          <svg
            className="tw-w-4 tw-h-4"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
            />
          </svg>
        </div>
      </div>
      {helpText ? (
        <p className="tw-font-body tw-my-2 tw-text-sm tw-text-gray-500">
          {helpText}
        </p>
      ) : null}
      {showError ? (
        <p className="tw-font-body tw-my-2 tw-text-sm tw-text-red-600">
          {error}
        </p>
      ) : null}
    </div>
  );
};

FileInput.defaultProps = {
  value: undefined,
  placeholder: '',
  required: false,
  inputStyle: {},
  containerStyle: {},
  labelStyle: {},
  onChange: null,
  showError: false,
  error: '',
  helpText: '',
  hideLabel: false,
  widthClass: 'tw-col-span-10',
  disabled: false
};

const styleProps = PropTypes.objectOf(
  PropTypes.oneOfType([PropTypes.string, PropTypes.number])
);

FileInput.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  labelText: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  inputStyle: styleProps,
  containerStyle: styleProps,
  labelStyle: styleProps,
  onChange: PropTypes.func,
  showError: PropTypes.bool,
  error: PropTypes.string,
  helpText: PropTypes.string,
  hideLabel: PropTypes.bool,
  widthClass: PropTypes.string,
  disabled: PropTypes.bool
};

export default FileInput;
