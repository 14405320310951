import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { formikShape } from 'lib/utils/prop-types-extensions';
import InfoPanel from 'components/Theme/InfoPanel';
import Card from 'components/Theme/Card';
import Table from 'components/Theme/Table';
import Select from 'components/Theme/Select';
import { titleize } from 'lib/utils/string';
import isEmpty from 'lodash.isempty';
import {
  REVIEW_HEADERS_INFO_PANEL,
  MANDATORY_HEADERS
} from '../common/constant';

const setInitialMapping = headers => {
  const mapHeaders = {};
  headers.map(header => {
    switch (true) {
      case /^(first|First)/gi.test(header): {
        mapHeaders[header] = 'first_name';
        return 'first_name';
      }
      case /^(last|Last)/gi.test(header): {
        mapHeaders[header] = 'last_name';
        return 'last_name';
      }
      case /^(email|Email)/gi.test(header): {
        mapHeaders[header] = 'email';
        return 'email';
      }
      case /^(phone|Phone)/gi.test(header): {
        mapHeaders[header] = 'phone';
        return 'phone';
      }
      case /^(birthday|Birthday|dob|DOB|birth|Birth|date of birth)/gi.test(
        header
      ): {
        mapHeaders[header] = 'birthday';
        return 'birthday';
      }
      case /\b(?:gender|sex)\b/gi.test(header): {
        mapHeaders[header] = 'gender';
        return 'gender';
      }
      case /\b(?:address_1|address1|addressLine1|address_line_1)\b/gi.test(
        header
      ): {
        mapHeaders[header] = 'address_1';
        return 'address_1';
      }
      case /\b(?:address_2|address2|addressLine2|address_line_2)\b/gi.test(
        header
      ): {
        mapHeaders[header] = 'address_2';
        return 'address_2';
      }
      case /\b(?:city)\b/gi.test(header): {
        mapHeaders[header] = 'city';
        return 'city';
      }
      case /\b(?:state)\b/gi.test(header): {
        mapHeaders[header] = 'state';
        return 'state';
      }
      case /\b(?:postal_code|postalCode|code|pin_code|pinCode)\b/gi.test(
        header
      ): {
        mapHeaders[header] = 'postal_code';
        return 'postal_code';
      }
      default:
        mapHeaders[header] = 'skip';
        return 'skip';
    }
  });
  return mapHeaders;
};

const ReviewHeaders = ({ formik }) => {
  const { mappedHeaders, headers, fileData } = formik.values;
  const [options, setOptions] = useState([]);

  const setInitialHeaders = () => {
    const newHeaders = [...MANDATORY_HEADERS];
    // eslint-disable-next-line array-callback-return
    headers.map(header => {
      const objIndex = newHeaders.findIndex(
        obj => obj.value !== 'skip' && obj.value === mappedHeaders[header]
      );
      if (objIndex >= 0) {
        newHeaders[objIndex] = { ...newHeaders[objIndex], disabled: true };
      }
    });
    return newHeaders;
  };

  useEffect(() => {
    if (isEmpty(mappedHeaders) && headers.length > 0) {
      const updatedMappedHeaders = setInitialMapping(headers);
      formik.setFieldValue('mappedHeaders', updatedMappedHeaders);
    }
    setOptions(setInitialHeaders);
  }, [mappedHeaders]);

  const setSelectedHeader = (header, value) => {
    const updatedValue = {};
    updatedValue[header] = value;
    const oldHeaderWithValue = Object.keys(mappedHeaders).find(
      key => mappedHeaders[key] === value
    );
    if (oldHeaderWithValue && value !== 'skip') {
      updatedValue[oldHeaderWithValue] = '';
    }
    formik.setFieldValue('mappedHeaders', {
      ...mappedHeaders,
      ...updatedValue
    });
  };

  return (
    <Fragment>
      <InfoPanel title="Things to Know" infoItems={REVIEW_HEADERS_INFO_PANEL} />
      <div className="tw-flex tw-mt-2">
        <h4 className="tw-flex-1 tw-mx-5">
          <strong>Column Name in .CSV</strong>
        </h4>
        <h4 className="tw-flex-1 tw-mx-5">
          <strong>Field Name in UpLaunch</strong>
        </h4>
      </div>
      {headers.map(header => {
        return (
          <div key={header}>
            <Card
              title={titleize(header, '_')}
              headerClass="tw-capitalize"
              containerClass="tw-rounded-md tw-mt-5 tw-border-2"
              noTitleBorder
            >
              <div className="tw-flex">
                <div className="tw-flex-1 tw-mx-5 tw-mb-5">
                  <Table
                    rows={fileData}
                    includedColumns={[header]}
                    emptyMessage="No values present."
                  />
                </div>
                <div className="tw-flex-1 tw-mx-5 tw-mb-5">
                  <Select
                    id={`select-${header}`}
                    value={mappedHeaders[header]}
                    options={options}
                    placeholder="Select Header"
                    labelText="Map to Uplaunch Field"
                    onChange={e => setSelectedHeader(header, e.target.value)}
                    showError={
                      !Object.prototype.hasOwnProperty.call(
                        mappedHeaders,
                        header
                      ) || mappedHeaders[header] === ''
                    }
                    error="Please Match"
                  />
                </div>
              </div>
            </Card>
          </div>
        );
      })}
    </Fragment>
  );
};

ReviewHeaders.propTypes = {
  formik: PropTypes.shape(formikShape).isRequired
};

export default ReviewHeaders;
