import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Status } from '@googlemaps/react-wrapper';

const render = status => {
  if (status === Status.LOADING) return <h3>{status} ..</h3>;
  if (status === Status.FAILURE) return <h3>{status} ...</h3>;
  return null;
};

function MapComponent({ latitude, longitude, zoom, address }) {
  const ref = useRef();
  const center = { lat: latitude, lng: longitude };

  useEffect(() => {
    const map = new window.google.maps.Map(ref.current, {
      center,
      zoom
    });
    const marker = new window.google.maps.Marker({
      position: center,
      map
    });

    const infowindow = new window.google.maps.InfoWindow({
      content: `<div><strong>Our Address:</strong><br>${address}</div>`
    });

    marker.addListener('click', () => {
      infowindow.open({
        anchor: marker,
        map,
        shouldFocus: false
      });
    });
  }, []);

  return <div ref={ref} id="map" style={{ minHeight: '100vh' }} />;
}

MapComponent.defaultProps = {
  zoom: 15,
  latitude: 0,
  longitude: 0,
  address: ''
};

MapComponent.propTypes = {
  zoom: PropTypes.number,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  address: PropTypes.string
};

function Map({ apiKey, latitude, longitude, zoom, address }) {
  return (
    <Wrapper apiKey={apiKey} render={render}>
      <MapComponent
        latitude={latitude}
        longitude={longitude}
        zoom={zoom}
        address={address}
      />
    </Wrapper>
  );
}

Map.defaultProps = {
  zoom: 15,
  latitude: 0,
  longitude: 0,
  address: ''
};

Map.propTypes = {
  apiKey: PropTypes.string.isRequired,
  zoom: PropTypes.number,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  address: PropTypes.string
};
// eslint-disable-next-line
export const GoogleMap = React.memo(Map);
