import styled from 'styled-components';

const ChipContainer = styled.div`
  display: inline-block;
  margin-right: 6px;
  margin-left: ${props => (props.hasLeftMargin ? '6px' : 0)};
  margin-bottom: 8px;
`;

const ChipContent = styled.div`
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Chip = styled.div`
  font-weight: 400;
  padding: 0 12px;
`;

const Styled = {
  ChipContainer,
  ChipContent,
  Chip
};

export default Styled;
