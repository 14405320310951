import Badge from 'components/Theme/Badge';
import statusImage from 'assets/images/uplaunch-email-status-mascot.svg';
import Tooltip from 'components/Theme/Tooltip';
import React from 'react';

export default function SmsSubscribedTooltip() {
  return (
    <Tooltip
      id="sms-subscribed-tooltip"
      place="right"
      classNames="tw-bg-white"
      TooltipElement={(id, _color) => (
        <span
          data-tip
          data-for={id}
          data-event="click focus"
          className="tw-cursor-pointer"
        >
          <Badge
            badgeStyle={{ marginTop: '0' }}
            color="success"
            badgeClass="tw-ml-1"
            hasDot={false}
            onClick={null}
            shape="round"
            size="small"
            textStyle={{}}
            value="Subscribed"
            icon="check-circle"
          />
        </span>
      )}
    >
      <div className="tw-grid tw-grid-cols-12">
        <div className="tw-col-span-6">
          <div className="tw-font-body tw-text-lg tw-leading-7 tw-font-medium tw-text-gray-900">
            We have touchdown!
          </div>
          <p className="tw-font-body tw-mb-0 tw-mt-2 tw-text-sm tw-leading-5 tw-text-gray-500">
            This is a good phone number and they are subscribed to receiving
            campaign SMS.
          </p>
        </div>
        <img
          src={statusImage}
          className="tw-col-span-6"
          alt="UpLaunch Communication Status"
        />
      </div>
    </Tooltip>
  );
}
