import React from 'react';
import Icon from '../index';
import iconPropTypes from '../iconPropTypes';
import iconDefaultProps from '../iconDefaultProps';

const BroadcastIcon = ({ color, tint, filled, onClick, width, height }) => {
  return (
    <Icon
      color={color}
      tint={tint}
      onClick={onClick}
      filled={filled}
      width={width}
      height={height}
      pathDefinition="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"
    />
  );
};

BroadcastIcon.defaultProps = iconDefaultProps;
BroadcastIcon.propTypes = iconPropTypes;

export default BroadcastIcon;
