/* eslint-disable react/display-name */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';
import ShowMore from 'components/Theme/ShowMore';
import { axiosDefault } from 'lib/utils/axios-setup';
import { titleize, resolveApostrophe } from 'lib/utils/string';
import { formatTimestamp } from 'lib/utils/dateTime';
import {
  updateActivity,
  updateEmailActivity,
  updateSmsActivity,
  updateAppointmentsActivity,
  updateNotesActivity,
  updateTasksActivity,
  updateFormsActivity
} from 'appState/actions/ActionCreators';
import Notification from 'components/Theme/Notification';
import { DATE_TIME_FORMAT, DATE_TIME_FORMAT_TASK } from 'config/constants';
import HeroIcon from 'components/Theme/HeroIcon';

let store;
let TAB_ACTIONS;

export function setStore(_store) {
  store = _store;
}

async function fetchTabActions() {
  const response = await axiosDefault.get('/activities/tab_actions');
  return response.data;
}

export async function fetchAndStoreTabActions() {
  TAB_ACTIONS = await fetchTabActions();
}

function getActivityType(a) {
  let type = '';
  Object.keys(TAB_ACTIONS).map(action => {
    const lowerCaseActions = TAB_ACTIONS[action].map(actionText =>
      actionText.toLowerCase()
    );
    if (lowerCaseActions.includes(a.action.toLowerCase())) {
      type = action;
    }
    return action;
  });
  return type;
}

function getBodyText(a) {
  switch (a.action.toLowerCase()) {
    case 'campaign email sent':
    case 'email sent':
    case 'bulk email sent':
    case 'email opened':
    case 'manual email sent':
    case 'transactional email': {
      const emailSentBody = () => (
        <p className="tw-mb-0">
          <span className="tw-font-medium">Subject:</span> {a.email_subject}
        </p>
      );
      return emailSentBody;
    }
    case 'bad email error': {
      const badEmailBody = () => (
        <div>
          <p className="tw-mb-0">
            <span className="tw-font-medium">Subject:</span> {a.email_subject}
          </p>
          <Notification
            message="This is not a system error - typically, emails get rejected by a service provider if they have marked something as spam in the past or if their email provider has overly restrictive spam filters."
            show
            showAlways
            type="colored"
            headerText="UpLaunch Note"
            color="gray"
          />
        </div>
      );
      return badEmailBody;
    }
    case 'email link clicked': {
      const emailLinkCreatedBody = () => (
        <div>
          {a.clicked_email_link ? (
            <p className="tw-mb-0">
              <span className="tw-font-medium">Link:</span>{' '}
              {a.clicked_email_link}
            </p>
          ) : null}
        </div>
      );
      return emailLinkCreatedBody;
    }
    case 'contact updated': {
      const moreKeys = a.data && Object.keys(a.data.contact_update_changes);
      const keys = moreKeys.splice(0, 3);
      const renderText = (change, key) => {
        return `${change[key][0] ? change[key][0] : 'NA'} -> ${
          change[key][1] ? change[key][1] : 'NA'
        }`;
      };

      const contactUpdatedBody = () => (
        <div>
          {keys
            ? keys.map(key => {
                return (
                  <div className="tw-mb-1" key={key}>
                    <strong>{titleize(key, '_')}: </strong>
                    {renderText(a.data.contact_update_changes, key)}
                  </div>
                );
              })
            : null}
          {!isEmpty(moreKeys) ? (
            <Fragment>
              <ShowMore>
                {moreKeys.map(key => {
                  return (
                    <div className="tw-mb-1" key={key}>
                      <strong>{titleize(key, '_')}: </strong>
                      {renderText(a.data.contact_update_changes, key)}
                    </div>
                  );
                })}
              </ShowMore>
            </Fragment>
          ) : null}
        </div>
      );
      return contactUpdatedBody;
    }
    case 'sms sent':
    case 'bulk sms sent':
    case 'campaign sms sent':
    case 'manual sms sent':
    case 'sms reply received':
    case 'transactional sms': {
      const smsSentBody = () => (
        <Fragment>
          {!isEmpty(a.sms_body) && (
            <p className="tw-mb-0">
              <span className="tw-font-medium">Message:</span> {a.sms_body}
            </p>
          )}
          {a &&
            a.sourceable &&
            a.sourceable.type === 'sms' &&
            a.sourceable.sms &&
            a.sourceable.sms.media_urls &&
            a.sourceable.sms.media_urls.length > 0 && (
              <p>
                <span className="tw-break-all">
                  <span className="tw-font-medium">Media Attachments:</span>{' '}
                  {a.sourceable.sms.media_urls.length}
                </span>
              </p>
            )}
        </Fragment>
      );
      return smsSentBody;
    }
    case 'appointment created':
    case 'appointment canceled':
    case 'appointment completed':
    case 'appointment no showed':
    case 'appointment rescheduled': {
      const appointmentBody = ({ timezone }) => {
        const localTimeStamp = formatTimestamp(
          a.appointment_starts_at,
          timezone,
          false,
          DATE_TIME_FORMAT,
          true
        );
        return (
          <p className="tw-mb-0">
            <span className="tw-font-medium">Appointment Type:</span>{' '}
            {a.appointment_type}
            <br />
            <span className="tw-font-medium">Scheduled For:</span>{' '}
            {localTimeStamp} with {a.user.first_name} {a.user.last_name}
          </p>
        );
      };
      appointmentBody.propTypes = {
        timezone: PropTypes.string.isRequired
      };
      return appointmentBody;
    }
    case 'task created':
    case 'task cancelation':
    case 'task changed':
    case 'task completed':
    case 'task reassigned':
    case 'task rescheduled': {
      const taskBody = ({ timezone }) => {
        const localTimeStamp = formatTimestamp(
          a.task_due_date,
          timezone,
          false,
          DATE_TIME_FORMAT_TASK,
          true
        );
        return (
          <p className="tw-mb-0">
            <span className="tw-font-medium">Title:</span> {a.task_title}
            <br />
            <span className="tw-font-medium">Due Date:</span> {localTimeStamp}
          </p>
        );
      };
      taskBody.propTypes = {
        timezone: PropTypes.string.isRequired
      };
      return taskBody;
    }
    case 'form submitted': {
      const formSubmittedBody = () =>
        a.form_title && (
          <p className="tw-mb-0">
            <span className="tw-font-medium">Form:</span> {a.form_title}
          </p>
        );
      return formSubmittedBody;
    }
    case 'contact form deleted': {
      const formDeletedBody = () =>
        a.form_title && (
          <p className="tw-mb-0">
            <span className="tw-font-medium">Form:</span> {a.form_title}
          </p>
        );
      return formDeletedBody;
    }
    case 'document uploaded': {
      const documentUploadedBody = () => (
        <p className="tw-mb-0">
          <span className="tw-font-medium">File Name:</span>{' '}
          {a.document_name ? a.document_name : 'Dummy File'}
        </p>
      );
      return documentUploadedBody;
    }
    case 'order sent to mdi apparel': {
      const orderSentToMdiApparelBody = () => (
        <p className="tw-mb-0">{a.note}</p>
      );
      return orderSentToMdiApparelBody;
    }
    case 'new conversion': {
      const newConversionBody = () => (
        <p className="tw-mb-0">
          <span className="tw-font-medium">Message:</span> {a.sms_body}
        </p>
      );
      return newConversionBody;
    }
    case 'referral submitted': {
      const referralSubmittedBody = () => (
        <p className="tw-mb-0">
          <span className="tw-font-medium">Opt-In Emails Sent To:</span>{' '}
          {a.referrals}.
        </p>
      );
      return referralSubmittedBody;
    }
    default: {
      return () => null;
    }
  }
}

function getHeaderText(a) {
  switch (a.action.toLowerCase()) {
    case 'email sent':
    case 'manual email sent': {
      const emailSentHeader = () => (
        <Fragment>
          {a.user.first_name} {a.user.last_name} sent an email to{' '}
          {a.contact.first_name} {a.contact.last_name}.
        </Fragment>
      );
      return emailSentHeader;
    }
    case 'campaign email sent': {
      const campaignEmailSentHeader = () => (
        <Fragment>
          {a.user.first_name} {a.user.last_name} sent a campaign email to{' '}
          {a.contact.first_name} {a.contact.last_name}.
        </Fragment>
      );
      return campaignEmailSentHeader;
    }
    case 'bad email error': {
      const badEmailHeader = () => (
        <Fragment>
          {a.contact.first_name} {resolveApostrophe(a.contact.last_name)} email
          from {a.user.first_name} {a.user.last_name} was rejected by their
          service provider.
        </Fragment>
      );
      return badEmailHeader;
    }
    case 'contact unsubscribed': {
      const contactUnsubscribedHeader = () => (
        <Fragment>
          {a.contact.first_name} {a.contact.last_name} has unsubscribed from{' '}
          {a.contact.account_name}.
        </Fragment>
      );
      return contactUnsubscribedHeader;
    }
    case 'email link clicked': {
      const emailLinkClickedHeader = () => (
        <Fragment>
          {a.contact.first_name} {a.contact.last_name} clicked a link in the{' '}
          &quot;{a.email_subject}&quot; email.
        </Fragment>
      );
      return emailLinkClickedHeader;
    }
    case 'bulk email sent': {
      const bulkEmailSentHeader = () => (
        <Fragment>
          {a.user.first_name} {a.user.last_name} sent a bulk email to{' '}
          {a.bulk_recipients_count} contacts.
        </Fragment>
      );
      return bulkEmailSentHeader;
    }
    case 'email opened': {
      const emailOpenedHeader = () => (
        <Fragment>
          {a.contact.first_name} {a.contact.last_name} opened an email from{' '}
          {a.user.first_name} {a.user.last_name}.
        </Fragment>
      );
      return emailOpenedHeader;
    }
    case 'spam complaint': {
      const spamComplaintHeader = () => (
        <Fragment>
          {a.contact.first_name} {a.contact.last_name} submitted a spam
          complaint and has been unsubscribed permanently.
        </Fragment>
      );
      return spamComplaintHeader;
    }
    case 'transactional email': {
      const transactionalEmailHeader = () => (
        <Fragment>
          {a.contact.first_name} {a.contact.last_name} was sent a transactional
          email.
        </Fragment>
      );
      return transactionalEmailHeader;
    }
    case 'sms sent':
    case 'manual sms sent': {
      const smsSentHeader = () => (
        <Fragment>
          {a.user.first_name} {a.user.last_name} sent an SMS message to{' '}
          {a.contact.first_name} {a.contact.last_name}.
        </Fragment>
      );
      return smsSentHeader;
    }
    case 'bulk sms sent': {
      const bulkSmsSentHeader = () => (
        <Fragment>
          {a.user.first_name} {a.user.last_name} sent a bulk SMS to{' '}
          {a.bulk_recipients_count} contacts.
        </Fragment>
      );
      return bulkSmsSentHeader;
    }
    case 'campaign sms sent': {
      const campaignSmsSentHeader = () => (
        <Fragment>
          {a.user.first_name} {a.user.last_name} sent a Campaign SMS message to{' '}
          {a.contact.first_name} {a.contact.last_name}.
        </Fragment>
      );
      return campaignSmsSentHeader;
    }
    case 'contact opted in': {
      const contactOptedInHeader = () => (
        <Fragment>
          {a.contact.first_name} {a.contact.last_name} has opted-in to SMS
          communications.
        </Fragment>
      );
      return contactOptedInHeader;
    }
    case 'contact opted out': {
      const contactOptedOutHeader = () => (
        <Fragment>
          {a.contact.first_name} {a.contact.last_name} has opted-out of SMS
          communications.
        </Fragment>
      );
      return contactOptedOutHeader;
    }
    case 'contact updated': {
      const contactUpdatedBody = () => (
        <Fragment>
          {a.contact.first_name} was updated by{' '}
          {a.by_user ? a.by_user : titleize(a.source, '_')}.{' '}
        </Fragment>
      );
      return contactUpdatedBody;
    }
    case 'sms reply received': {
      const smsReplyReceivedHeader = () => (
        <Fragment>
          {a.contact.first_name} {a.contact.last_name} sent an SMS reply to{' '}
          {a.contact && a.contact.account && a.contact.account.name}.
        </Fragment>
      );
      return smsReplyReceivedHeader;
    }
    case 'transactional sms': {
      const transactionalSmsHeader = () => (
        <Fragment>
          {a.contact.first_name} {a.contact.last_name} was sent a transactional
          SMS.
        </Fragment>
      );
      return transactionalSmsHeader;
    }
    case 'appointment created': {
      const appointmentCreatedHeader = () => (
        <Fragment>
          {a.contact.first_name} {a.contact.last_name} has booked a new
          appointment with {a.user.first_name} {a.user.last_name}.
        </Fragment>
      );
      return appointmentCreatedHeader;
    }
    case 'appointment canceled': {
      const appointmentCanceledHeader = () => (
        <Fragment>
          {a.contact.first_name} {resolveApostrophe(a.contact.last_name)}{' '}
          appointment with {a.user.first_name} {a.user.last_name} was cancelled.
        </Fragment>
      );
      return appointmentCanceledHeader;
    }
    case 'appointment completed': {
      const appointmentCompletedHeader = () => (
        <Fragment>
          {a.contact.first_name} {resolveApostrophe(a.contact.last_name)}{' '}
          appointment with {a.user.first_name} {a.user.last_name} was completed.
        </Fragment>
      );
      return appointmentCompletedHeader;
    }
    case 'appointment no showed': {
      const appointmentNoShowedHeader = () => (
        <Fragment>
          {a.contact.first_name} {a.contact.last_name} was marked as a no-show
          for their appointment with {a.user.first_name} {a.user.last_name}.
        </Fragment>
      );
      return appointmentNoShowedHeader;
    }
    case 'appointment rescheduled': {
      const appointmentRescheduledHeader = () => (
        <Fragment>
          {a.contact.first_name} {resolveApostrophe(a.contact.last_name)}{' '}
          appointment with {a.user.first_name} {a.user.last_name} was
          rescheduled.
        </Fragment>
      );
      return appointmentRescheduledHeader;
    }
    case 'task created': {
      const taskCreatedHeader = () => (
        <Fragment>
          {a.user.first_name} {a.user.last_name} was assigned a task for{' '}
          {a.contact.first_name} {a.contact.last_name}.
        </Fragment>
      );
      return taskCreatedHeader;
    }
    case 'task canceled': {
      const taskCancelledHeader = () => (
        <Fragment>
          {a.user.first_name} {resolveApostrophe(a.user.last_name)} task for{' '}
          {a.contact.first_name} {a.contact.last_name} has been cancelled.
        </Fragment>
      );
      return taskCancelledHeader;
    }
    case 'task changed': {
      const taskChangedHeader = () => (
        <Fragment>
          {a.user.first_name} {resolveApostrophe(a.user.last_name)} task for{' '}
          {a.contact.first_name} {a.contact.last_name} has been updated.
        </Fragment>
      );
      return taskChangedHeader;
    }
    case 'task completed': {
      const taskCompletedHeader = () => (
        <Fragment>
          {a.user.first_name} {resolveApostrophe(a.user.last_name)} task for{' '}
          {a.contact.first_name} {a.contact.last_name} has been successfully
          completed!
        </Fragment>
      );
      return taskCompletedHeader;
    }
    case 'task reassigned': {
      const taskReassignedHeader = () => (
        <Fragment>
          A task for {a.contact.first_name} {a.contact.last_name} has been
          reassigned to {a.user.first_name} {a.user.last_name}.
        </Fragment>
      );
      return taskReassignedHeader;
    }
    case 'task rescheduled': {
      const taskRescheduledHeader = () => (
        <Fragment>
          {a.user.first_name} {resolveApostrophe(a.user.last_name)} task for{' '}
          {a.contact.first_name} {a.contact.last_name} has been rescheduled.
        </Fragment>
      );
      return taskRescheduledHeader;
    }
    case 'note created': {
      const noteCreatedHeader = () => (
        <Fragment>
          {a.user.first_name} {a.user.last_name} left a note on{' '}
          {a.contact.first_name} {resolveApostrophe(a.contact.last_name)}{' '}
          contact record.
        </Fragment>
      );
      return noteCreatedHeader;
    }
    case 'document uploaded': {
      return (
        <Fragment>
          {a.user.first_name} {a.user.last_name} has uploaded a document on{' '}
          {a.contact.first_name} {resolveApostrophe(a.contact.last_name)}{' '}
          contact record.
        </Fragment>
      );
    }
    case 'phone call note created': {
      const phoneCallNoteCreatedHeader = () => (
        <Fragment>
          {a.user.first_name} {a.user.last_name} contacted{' '}
          {a.contact.first_name} {a.contact.last_name} via telephone.
        </Fragment>
      );
      return phoneCallNoteCreatedHeader;
    }
    case 'form submitted': {
      const formSubmittedHeader = () => (
        <Fragment>
          {a.contact.first_name} {a.contact.last_name} has a new completed form
          on their contact record.
        </Fragment>
      );
      return formSubmittedHeader;
    }
    case 'contact form deleted': {
      const formDeletedHeader = () => (
        <Fragment>
          {a.user.first_name} {a.user.last_name} has deleted a form from{' '}
          {a.contact.first_name} {a.contact.last_name}
        </Fragment>
      );
      return formDeletedHeader;
    }
    case 'campaign started': {
      const campaignStartedHeader = () => (
        <Fragment>
          {a.contact.first_name} {a.contact.last_name} was added to{' '}
          {a.campaign_name}.
        </Fragment>
      );
      return campaignStartedHeader;
    }
    case 'campaign paused': {
      const campaignPausedHeader = () => (
        <Fragment>
          {a.user.first_name} {a.user.last_name} has paused{' '}
          {a.contact.first_name} {a.contact.last_name}&apos;s {a.campaign_name}.
        </Fragment>
      );
      return campaignPausedHeader;
    }
    case 'campaign resumed': {
      const campaignResumedHeader = () => (
        <Fragment>
          {a.user.first_name} {a.user.last_name} has resumed{' '}
          {a.contact.first_name} {a.contact.last_name}&apos;s {a.campaign_name}.
        </Fragment>
      );
      return campaignResumedHeader;
    }
    case 'campaign completed': {
      const campaignCompletedHeader = () => (
        <Fragment>
          {a.contact.first_name} {a.contact.last_name} has completed their{' '}
          {a.campaign_name}.
        </Fragment>
      );
      return campaignCompletedHeader;
    }
    case 'hold requested': {
      const holdRequestedHeader = () => (
        <Fragment>
          {a.contact.first_name} {a.contact.last_name} has just submitted a hold
          request!
        </Fragment>
      );
      return holdRequestedHeader;
    }
    case 'order sent to mdi apparel': {
      const orderSentToMdiApparelHeader = () => (
        <Fragment>
          Order sent to MDI Apparel for {a.contact.first_name}{' '}
          {a.contact.last_name}.
        </Fragment>
      );
      return orderSentToMdiApparelHeader;
    }
    case 'contact created': {
      const contactCreatedHeader = () => (
        <Fragment>
          {a.contact.first_name} {a.contact.last_name} was created as a contact
          in the platform.
        </Fragment>
      );
      return contactCreatedHeader;
    }
    case 'contact deleted': {
      const contactDeletedHeader = () => (
        <Fragment>
          {a.contact.first_name} {a.contact.last_name} has been deleted from
          your{' '}
          {a.source && a.source === 'zen_planner'
            ? titleize(a.source, '_')
            : ''}{' '}
          account.
        </Fragment>
      );
      return contactDeletedHeader;
    }
    case 'contact reassigned': {
      const contactReassignedHeader = () => (
        <Fragment>
          {a.contact.first_name} {a.contact.last_name} was manually reassigned
          to
          {a.to_user} by {a.by_user}.
        </Fragment>
      );
      return contactReassignedHeader;
    }
    case 'contact undeleted': {
      const contactDeletedHeader = () => (
        <Fragment>
          {a.contact.first_name} {a.contact.last_name} has been re-enabled in
          your{' '}
          {a.source && a.source === 'zen_planner'
            ? titleize(a.source, '_')
            : ''}{' '}
          account.
        </Fragment>
      );
      return contactDeletedHeader;
    }
    case 'contact resubscribed': {
      const contactResubscribedHeader = () => (
        <Fragment>
          {a.contact.first_name} {a.contact.last_name} has been manually
          re-subscribed to communications from {a.contact.account_name}
        </Fragment>
      );
      return contactResubscribedHeader;
    }
    case 'new conversion': {
      const newConversionHeader = () => (
        <Fragment>
          {a.contact.first_name} {a.contact.last_name} just became a client!
          Congratulations!
        </Fragment>
      );
      return newConversionHeader;
    }
    case 'new sale': {
      const newSaleHeader = () => (
        <Fragment>
          A new sale was just made to {a.contact.first_name}{' '}
          {a.contact.last_name}!
        </Fragment>
      );
      return newSaleHeader;
    }
    case 'referral submitted': {
      const referralSubmittedHeader = () => (
        <Fragment>
          {a.contact.first_name} {a.contact.last_name} has referred{' '}
          {a.referrals_length} people to {a.contact.account_name}!
        </Fragment>
      );
      return referralSubmittedHeader;
    }
    default: {
      return () => null;
    }
  }
}

function getIcon(color, type) {
  switch (type) {
    case 'email': {
      return (
        <svg
          className={`tw-h-5 tw-w-5 tw-align-middle tw-mx-auto tw-text-${color}-600`}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
        </svg>
      );
    }
    case 'sms': {
      return (
        <svg
          className={`tw-w-5 tw-h-5 tw-align-middle tw-mx-auto tw-text-${color}-600`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
          />
        </svg>
      );
    }
    case 'appointment': {
      return (
        <svg
          className={`tw-w-5 tw-h-5 tw-align-middle tw-mx-auto tw-text-${color}-600`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
          />
        </svg>
      );
    }
    case 'task': {
      return (
        <svg
          className={`tw-w-5 tw-h-5 tw-align-middle tw-mx-auto tw-text-${color}-600`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
          />
        </svg>
      );
    }
    case 'form': {
      return (
        <svg
          className={`tw-w-5 tw-h-5 tw-align-middle tw-mx-auto tw-text-${color}-600`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
          />
        </svg>
      );
    }
    case 'note': {
      return (
        <svg
          className={`tw-w-5 tw-h-5 tw-align-middle tw-mx-auto tw-text-${color}-600`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
          />
        </svg>
      );
    }
    case 'document': {
      return (
        <svg
          className={`tw-w-5 tw-h-5 tw-align-middle tw-mx-auto tw-text-${color}-600`}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
          />
        </svg>
      );
    }
    case 'campaign': {
      return (
        <svg
          className={`tw-w-5 tw-h-5 tw-align-middle tw-mx-auto tw-text-${color}-600`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7"
          />
        </svg>
      );
    }
    case 'misc': {
      return (
        <svg
          className={`tw-w-5 tw-h-5 tw-align-middle tw-mx-auto tw-text-${color}-600`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z"
          />
        </svg>
      );
    }
    default: {
      return null;
    }
  }
}

function getInfoAlertMessage(a) {
  if (a.source) {
    if (a.source === 'manual') {
      const recipientFullName =
        a.recipient?.first_name && a.recipient?.last_name
          ? `${a.recipient?.first_name} ${a.recipient?.last_name}`
          : 'staff';
      return `Action manually triggered by ${recipientFullName}`;
    }

    if (a.source === 'automatic') {
      return 'Action automatically triggered by UpLaunch Platform';
    }

    return `Action triggered by ${titleize(a.source, '_')} integration.`;
  }
  return null;
}

export function decorateActivity(a, index) {
  const type = getActivityType(a);
  const decoratedActivity = {
    bodyText: getBodyText(a),
    headerText: getHeaderText(a),
    icon: color => getIcon(color, type),
    index,
    isPinned: a.pinned,
    pinnedAction: tabName => {
      switch (tabName) {
        case 'email': {
          return store.dispatch(updateEmailActivity({ activity: a }));
        }
        case 'sms': {
          return store.dispatch(updateSmsActivity({ activity: a }));
        }
        case 'appointments': {
          return store.dispatch(updateAppointmentsActivity({ activity: a }));
        }
        case 'notes': {
          return store.dispatch(updateNotesActivity({ activity: a }));
        }
        case 'tasks': {
          return store.dispatch(updateTasksActivity({ activity: a }));
        }
        case 'forms': {
          return store.dispatch(updateFormsActivity({ activity: a }));
        }
        default: {
          return store.dispatch(updateActivity({ activity: a }));
        }
      }
    },
    timestamp: a.created_at,
    ...a
  };

  if (a.source && a.action !== 'Note Created') {
    decoratedActivity.infoAlert = {
      message: getInfoAlertMessage(a),
      color: 'gray',
      type: 'colored',
      // eslint-disable-next-line react/display-name
      HeaderIcon: color => {
        return (
          <svg
            className={`tw-h-5 tw-w-5 tw-text-${color}-400`}
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
              clipRule="evenodd"
            />
          </svg>
        );
      }
    };
  }

  if (a.note) {
    decoratedActivity.staffNote = {
      headerText: 'Staff Note',
      message: a.note,
      color: 'gray',
      type: 'colored',
      // eslint-disable-next-line react/display-name
      HeaderIcon: color => {
        return (
          <svg
            className={`identification tw-w-6 tw-h-6 tw-text-${color}-400`}
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M10 2a1 1 0 00-1 1v1a1 1 0 002 0V3a1 1 0 00-1-1zM4 4h3a3 3 0 006 0h3a2 2 0 012 2v9a2 2 0 01-2 2H4a2 2 0 01-2-2V6a2 2 0 012-2zm2.5 7a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm2.45 4a2.5 2.5 0 10-4.9 0h4.9zM12 9a1 1 0 100 2h3a1 1 0 100-2h-3zm-1 4a1 1 0 011-1h2a1 1 0 110 2h-2a1 1 0 01-1-1z"
              clipRule="evenodd"
            />
          </svg>
        );
      }
    };
  }

  if (
    a.sourceable &&
    a.sourceable.email &&
    a.sourceable.email.status_class_status
  ) {
    decoratedActivity.statusObject = {
      status: a.sourceable.email.status_class_status,
      title: a.sourceable.email.status_class_title,
      friendly_message: a.sourceable.email.status_message,
      bounce_code: a.sourceable.email.status_bounce_code,
      bounce_class: a.sourceable.email.status_bounce_class,
      bounce_description: a.sourceable.email.status_bounce_description,
      reason: a.sourceable.email.status_reason
    };
  }

  if (
    a.sourceable &&
    a.sourceable.sms &&
    a.sourceable.sms.status_class_status
  ) {
    decoratedActivity.statusObject = {
      status: a.sourceable.sms.status_class_status,
      title: a.sourceable.sms.status_class_title,
      friendly_message: a.sourceable.sms.status_message,
      failed_code: a.sourceable.sms.status_failed_code,
      failed_link: a.sourceable.sms.status_failed_link,
      failed_message: a.sourceable.sms.status_failed_message
    };
  }

  if (
    a.action.toLowerCase() === 'campaign email sent' ||
    a.action.toLowerCase() === 'email sent' ||
    a.action.toLowerCase() === 'bulk email sent' ||
    a.action.toLowerCase() === 'email opened' ||
    a.action.toLowerCase() === 'manual email sent' ||
    a.action.toLowerCase() === 'transactional email' ||
    a.action.toLowerCase() === 'campaing email sent'
  ) {
    decoratedActivity.actionText = 'Read Email';
    decoratedActivity.action = () => null;
  }

  if (type === 'form') {
    if (a.deleted !== true) {
      decoratedActivity.actionText = 'View Form';
      decoratedActivity.action = () => null;
      decoratedActivity.secondaryActionText = 'Delete Form';
      decoratedActivity.secondaryAction = () => null;
    }
    if (a.deleted && a.action.toLowerCase() === 'contact form deleted') {
      decoratedActivity.icon = color => (
        <HeroIcon icon="x" color={color} tint="600" />
      );
    }
  }

  if (type === 'task') {
    decoratedActivity.actions = [
      { displayName: 'Complete Task', value: 'completeTask' },
      { displayName: 'Update Task', value: 'editTask' },
      { displayName: 'Cancel Task', value: 'cancelTask' }
    ];
  }

  if (type === 'appointment') {
    decoratedActivity.actions = [
      { displayName: 'Reschedule', value: 'reschedule' },
      { displayName: 'No-show', value: 'noShow' },
      { displayName: 'Cancel', value: 'cancel' }
    ];
  }

  return decoratedActivity;
}

export function decoratedActivities(activities) {
  return activities
    .map((a, index) => {
      const decoratedActivity = decorateActivity(a, index);

      return decoratedActivity;
    })
    .sort(function sortByDate(a, b) {
      return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();
    });
}
