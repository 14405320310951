import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import LeadSourcesList from './List';
import UpdateContacts from './UpdateContacts';

const LeadSources = () => {
  const { path } = useRouteMatch();

  return (
    <div>
      <Switch>
        <Route exact path={path} component={LeadSourcesList} />
        <Route path={`${path}/bulk-update`} component={UpdateContacts} />
      </Switch>
    </div>
  );
};

export default LeadSources;
