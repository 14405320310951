/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';

const EmailPreview = ({ emailBody, emailSubject, isMobile }) => {
  return (
    <div>
      <div>
        {isMobile ? (
          <div>
            <div dangerouslySetInnerHTML={{ __html: emailBody }} />
          </div>
        ) : (
          <div>
            <div>
              {/* <!-- Email Preview --> */}
              <div
                style={{
                  width: '500px',
                  margin: '0 auto',
                  padding: '5px 25px'
                }}
              >
                <h5>
                  <span className="tw-font-bold">Subject:&nbsp;&nbsp;</span>
                  {emailSubject}
                </h5>
              </div>
              <div>
                <div
                  className="tw-mx-auto mail-preview"
                  style={{ maxWidth: '500px' }}
                  dangerouslySetInnerHTML={{ __html: emailBody }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

EmailPreview.defaultProps = {
  isMobile: false
};

EmailPreview.propTypes = {
  emailSubject: PropTypes.string.isRequired,
  emailBody: PropTypes.string.isRequired,
  isMobile: PropTypes.bool
};

export default EmailPreview;
