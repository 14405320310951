import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Theme/Button';
import StyledFooter from 'components/SyncWizard/Styles/Footer.styles';

const Footer = ({
  primaryActionText,
  primaryAction,
  showSecondaryAction,
  secondaryActionText,
  secondaryAction,
  disableButton,
  isLoaded
}) => {
  const footer = document.getElementById('main-footer');
  footer.setAttribute('style', 'display: none');

  return (
    <StyledFooter.Footer>
      <div className="tw-bg-white tw-px-4 tw-py-3 sm:tw-px-6 tw-shadow">
        <div className="tw-inline-block tw-w-1/2">
          {showSecondaryAction ? (
            <span className="tw-mt-3 tw-w-full sm:tw-mt-0 sm:tw-w-auto">
              <Button
                text={secondaryActionText}
                type="white"
                size="lg"
                onClick={secondaryAction}
                disabled={disableButton}
              />
            </span>
          ) : null}
        </div>
        <div className="tw-inline-block tw-w-1/2 tw-text-right">
          <span className="tw-w-full sm:tw-ml-3 sm:tw-w-auto">
            <Button
              text={primaryActionText}
              size="lg"
              onClick={primaryAction}
              disabled={disableButton}
              isLoaded={isLoaded}
            />
          </span>
        </div>
      </div>
    </StyledFooter.Footer>
  );
};

Footer.defaultProps = {
  primaryActionText: '',
  primaryAction: () => null,
  showSecondaryAction: false,
  secondaryActionText: '',
  secondaryAction: () => null,
  disableButton: false,
  isLoaded: true
};

Footer.propTypes = {
  primaryActionText: PropTypes.string,
  primaryAction: PropTypes.func,
  showSecondaryAction: PropTypes.bool,
  secondaryActionText: PropTypes.string,
  secondaryAction: PropTypes.func,
  disableButton: PropTypes.bool,
  isLoaded: PropTypes.bool
};

export default Footer;
