/* eslint-disable eqeqeq */
import React, { Fragment, useContext, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { formikShape } from 'lib/utils/prop-types-extensions';
import { PropTypes } from 'prop-types';
import Card from 'components/Theme/Card';
import { createStructuredSelector } from 'reselect';
import HeroIcon from 'components/Theme/HeroIcon';
import Table from 'components/Theme/Table';
import { UsersContext } from 'components/shared/context/UsersContext';
import { titleize } from 'lib/utils/string';
import Styled from 'components/BroadcastCenter/Broadcast/Schedule/Schedule.styles';
import DatePicker from 'react-datepicker';
import ActionCable from 'components/ActionCable';

const ImportItem = ({ item }) => {
  return (
    <div className="tw-flex-1 tw-text-sm tw-font-normal tw-leading-5">
      {item}
    </div>
  );
};

ImportItem.defaultProps = {
  item: ''
};

ImportItem.propTypes = {
  item: PropTypes.string
};
const ImportOption = ({
  importType,
  setScheduleForDateTime,
  handleImportType,
  scheduleForDateTime,
  importProgress
}) => {
  return (
    <>
      {!importProgress && (
        <>
          <div className="form-check">
            <input
              type="radio"
              name="import-type"
              id="import-now"
              data-testid="import-now"
              value="import_now"
              onChange={e => handleImportType(e.target.value)}
              checked={importType === 'import_now'}
            />
            <Styled.Label
              className="form-check-label cursor-pointer"
              htmlFor="import-now"
            >
              Import Now
            </Styled.Label>
          </div>
          <div className="form-check">
            <input
              type="radio"
              name="import-type"
              data-testid="schedule"
              id="schedule"
              value="schedule"
              onChange={e => handleImportType(e.target.value)}
              checked={importType === 'schedule'}
            />
            <Styled.Label
              className="form-check-label cursor-pointer"
              htmlFor="schedule"
            >
              Schedule Import{' '}
            </Styled.Label>
          </div>
          <div>
            {importType === 'schedule' ? (
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <div
                      className="mb-20"
                      id="schedule-broadcast-datetime-wrapper"
                    >
                      <DatePicker
                        selected={scheduleForDateTime}
                        onChange={date => setScheduleForDateTime(date)}
                        className="form-control"
                        minDate={new Date()}
                        showTimeSelect
                        dateFormat="MMMM d, yyyy h:mm aa"
                        shouldCloseOnSelect={false}
                        timeIntervals={15}
                        inline
                        tabIndex={-1}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </>
      )}
    </>
  );
};

ImportOption.propTypes = {
  scheduleForDateTime: PropTypes.string,
  setScheduleForDateTime: PropTypes.func,
  importType: PropTypes.string,
  handleImportType: PropTypes.func,
  importProgress: PropTypes.bool
};

ImportOption.defaultProps = {
  scheduleForDateTime: '',
  importType: 'import_now',
  setScheduleForDateTime: () => {},
  handleImportType: () => {},
  importProgress: false
};

const ImportReviewProceesed = () => {
  const structuredSelector = createStructuredSelector({
    importedContact: state => state.contact.importedContact
  });
  const { importedContact } = useSelector(structuredSelector);

  const totalContact = importedContact.length;
  const invalidContact = importedContact.filter(
    i => i.validation_errors !== ''
  );

  return (
    <>
      <ImportItem
        item={`Valid Contacts: ${totalContact - invalidContact.length}`}
      />
      <ImportItem item={`Invalid Contacts: ${invalidContact.length}`} />
      <ImportItem item={`Total Contacts: ${totalContact}`} />
    </>
  );
};

const ImportDetail = ({ values }) => {
  const { users } = useContext(UsersContext);
  const {
    assignedStaffId,
    lifecycle,
    product,
    campaignName,
    customGroups
  } = values;

  const staffItem = users.find(u => u.id == assignedStaffId);

  return (
    <>
      <ImportItem item={`Lifecycle: ${lifecycle}`} />
      <ImportItem item={`Campaign: ${campaignName || ''}`} />
      <ImportItem item={`Product: ${product || ''}`} />
      <ImportItem item={`Assigned Staff: ${staffItem?.full_name}`} />
      <ImportItem item={`Custom Groups: ${customGroups}`} />
    </>
  );
};

ImportDetail.propTypes = {
  values: PropTypes.oneOfType([PropTypes.object]).isRequired
};

const ImportProgress = ({
  validContactCount,
  importProgressText,
  isImported,
  invalidContacts,
  totalContact
}) => {
  const [importedCount, setImportedCount] = useState(
    isImported ? validContactCount : 0
  );

  function handleRowImport(response) {
    setImportedCount(response.count);
  }

  function getColumnName() {
    if (invalidContacts?.length) {
      return Object.keys(invalidContacts[0]);
    }
  }

  const columnName = useMemo(() => getColumnName(), [invalidContacts]);

  return (
    <>
      <Card
        key="importing-now"
        containerClass="tw-rounded-md tw-mt-5 tw-border-2"
        noTitleBorder
      >
        <div className="tw-align-middle tw-min-w-full tw-rounded-lg tw-border-b tw-border-sucess-200 tw-pt-2">
          <div className="tw-px-4 tw-py-3 tw-border-b tw-border-t-0 tw-border-l-0 tw-border-r-0 tw-border-solid tw-border-gray-200 sm:tw-px-6">
            <div className="tw--ml-4 tw-flex tw-justify-between tw-items-center tw-flex-wrap sm:tw-flex-no-wrap">
              <div className="tw-ml-4 tw-mt-2 tw-flex tw-items-center tw-justify-center">
                <div className="tw-bg-gray-100 tw-rounded-full tw-min-h-10 tw-h-10 tw-w-10 tw-flex tw-items-center tw-justify-center">
                  <HeroIcon icon="information-circle" color="gray" />
                </div>
                <div className="tw-flex tw-items-center tw-justify-center">
                  <h3 className="tw-font-body tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900 tw-pl-4 tw-pt-1">
                    {importProgressText}
                  </h3>
                </div>
              </div>
            </div>
          </div>
          {!isImported && (
            <div className="spinner-linear">
              <div className="line" />
            </div>
          )}
          <div className="tw-p-6 tw-text-gray-900 tw-pl-20">
            <ActionCable
              channel={{ channel: 'CsvImportStatusChannel' }}
              onReceived={handleRowImport}
            >
              <span className="md:tw-flex tw-hidden">
                {importedCount} out of {totalContact} contacts imported.
              </span>
            </ActionCable>
            {isImported && (
              <div className="tw-p-6 tw-text-gray-900">
                <Table
                  rows={invalidContacts}
                  customTableClass="fixedTable tw-overflow-auto"
                  includedColumns={columnName}
                  fixedColumnWidth={500}
                  emptyMessage="No values present."
                  showColumnValueTitle
                  validationKey="validation_errors"
                />
              </div>
            )}
          </div>
        </div>
      </Card>
    </>
  );
};

ImportProgress.propTypes = {
  validContactCount: PropTypes.number,
  importProgressText: PropTypes.string,
  isImported: PropTypes.bool,
  invalidContacts: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  totalContact: PropTypes.number
};

ImportProgress.defaultProps = {
  validContactCount: 0,
  importProgressText: '',
  isImported: false,
  invalidContacts: {},
  totalContact: 0
};

const ImportMapToHeaders = ({ mappedHeaders }) => {
  return (
    <>
      {Object.keys(mappedHeaders).map(key => {
        return (
          <ImportItem
            key={key}
            item={`${key}: ${titleize(mappedHeaders[key], '_')}`}
          />
        );
      })}
    </>
  );
};

ImportMapToHeaders.propTypes = {
  mappedHeaders: PropTypes.oneOfType([PropTypes.object]).isRequired
};

const ImportStatus = ({
  formik,
  setImportStep,
  importType,
  setScheduleForDateTime,
  handleImportType,
  scheduleForDateTime,
  importProgress,
  importedContact,
  importStatus,
  setPrimaryActionText
}) => {
  const itemList = [
    'Import Details',
    'Map Headers to Columns',
    'Review Processed File',
    'Import Options'
  ];

  const { mappedHeaders } = formik.values;

  const totalContact = importedContact.length;
  const invalidContact = importedContact.filter(
    i => i.validation_errors !== ''
  );
  const isImporting = importStatus === 'importing' || importProgress;
  const isImported = importStatus === 'imported';
  const isScheduled = importStatus === 'scheduled';

  function getHeaderContain(item, idx) {
    const isImportOptionHeader = idx === 3;

    return (
      <div className="tw-px-4 tw-py-3 tw-border-b tw-border-t-0 tw-border-l-0 tw-border-r-0 tw-border-solid tw-border-gray-200 sm:tw-px-6">
        <div className="tw--ml-4 tw-flex tw-justify-between tw-items-center tw-flex-wrap sm:tw-flex-no-wrap">
          <div className="tw-ml-4 tw-mt-2 tw-flex tw-items-center tw-justify-center">
            <div
              className={`tw-bg-${
                isImportOptionHeader ? 'gray' : 'success'
              }-100 tw-rounded-full tw-min-h-10 tw-h-10 tw-w-10 tw-flex tw-items-center tw-justify-center`}
            >
              <HeroIcon
                icon={
                  isImportOptionHeader ? 'information-circle' : 'check-circle'
                }
                color={isImportOptionHeader ? 'gray' : 'success'}
              />
            </div>
            <div className="tw-flex tw-items-center tw-justify-center">
              <h3 className="tw-font-body tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900 tw-pl-4 tw-pt-1">
                {item}
              </h3>
              {isImportOptionHeader && (
                <div className="tw-pl-2 tw-text-sm tw-font-normal tw-leading-5 tw-italic">
                  - When should we import your contacts
                </div>
              )}
            </div>
          </div>
          <div className="tw-flex-shrink-0 tw-cursor-pointer">
            {!isImportOptionHeader &&
              !isImporting &&
              !isImported &&
              !isScheduled && (
                <HeroIcon
                  icon="pencil-alt"
                  color="success"
                  onClick={() => {
                    setImportStep(idx);
                    setPrimaryActionText('Next');
                  }}
                />
              )}
          </div>
        </div>
      </div>
    );
  }

  function getBodyContain(idx) {
    switch (idx) {
      case 1: {
        return <ImportMapToHeaders mappedHeaders={mappedHeaders} />;
      }
      case 2: {
        return <ImportReviewProceesed />;
      }
      case 3: {
        return (
          <ImportOption
            importType={importType}
            setScheduleForDateTime={setScheduleForDateTime}
            handleImportType={handleImportType}
            scheduleForDateTime={scheduleForDateTime}
            importProgress={importProgress}
            importedContact={importedContact}
          />
        );
      }
      default:
        return <ImportDetail values={formik.values} />;
    }
  }

  function renderItems() {
    return itemList.map((item, idx) => {
      if (idx === 3 && (isImporting || isImported || isScheduled)) {
        return true;
      }
      return (
        <Card
          key={item}
          containerClass="tw-rounded-md tw-mt-5 tw-border-2"
          noTitleBorder
        >
          <div className="tw-align-middle tw-min-w-full tw-rounded-lg tw-border-b tw-border-sucess-200 tw-pt-2">
            {getHeaderContain(item, idx)}
            <div className="tw-p-6 tw-text-gray-900 tw-pl-20">
              {getBodyContain(idx)}
            </div>
          </div>
        </Card>
      );
    });
  }

  return (
    <Fragment>
      <div key="header">
        {renderItems()}
        {(isImporting || isImported) && (
          <ImportProgress
            invalidContacts={invalidContact}
            validContactCount={totalContact - invalidContact.length}
            totalContact={totalContact}
            importProgressText={
              isImporting
                ? 'Importing Contacts...Please wait'
                : 'Contacts Imported'
            }
            isImported={isImported}
          />
        )}
      </div>
    </Fragment>
  );
};

ImportStatus.propTypes = {
  formik: PropTypes.shape(formikShape).isRequired,
  setImportStep: PropTypes.func.isRequired,
  handleImportType: PropTypes.func,
  importType: PropTypes.string,
  setScheduleForDateTime: PropTypes.func,
  scheduleForDateTime: PropTypes.string,
  importProgress: PropTypes.bool,
  importedContact: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  importStatus: PropTypes.string,
  setPrimaryActionText: PropTypes.func
};

ImportStatus.defaultProps = {
  handleImportType: () => {},
  importType: '',
  setScheduleForDateTime: () => {},
  scheduleForDateTime: '',
  importProgress: false,
  importedContact: {},
  importStatus: '',
  setPrimaryActionText: () => {}
};

export default ImportStatus;
