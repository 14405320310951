import React, { useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { createLoadedSelector } from 'app-state/selectors';
import {
  fetchBackGroundJobData,
  setOverlordLayoutHeaderText
} from 'app-state/actions/ActionCreators';
import { FETCH_BACKGROUNDJOB_DATA } from 'app-state/actions/constants/backGroundJob.actions';
import { CurrentAccountContext } from 'components/shared/context/CurrentAccountContext';
import Skeleton from 'react-loading-skeleton';

const interval = 10000;

const BackGroundItem = ({ textName, loaded, textValue }) => {
  return (
    <div className="tw-bg-white tw-overflow-hidden tw-shadow-lg tw-rounded-lg">
      <div className="tw-px-4 tw-py-5 sm:tw-p-6">
        <dl>
          <dt className="tw-text-sm tw-leading-5 tw-font-medium tw-text-gray-500 tw-truncate">
            {textName}
          </dt>
          <dd className="tw-mt-1 tw-text-3xl tw-leading-9 tw-font-semibold tw-text-gray-900">
            {loaded ? textValue : <Skeleton width={100} height={20} />}
          </dd>
        </dl>
      </div>
    </div>
  );
};

BackGroundItem.defaultProps = {
  textName: '',
  loaded: false,
  textValue: 0
};

BackGroundItem.propTypes = {
  textName: PropTypes.string,
  loaded: PropTypes.bool,
  textValue: PropTypes.number
};
const BackGroundJobDashboard = () => {
  const dispatch = useDispatch();
  const backGroundJobLoadedSelector = createLoadedSelector([
    FETCH_BACKGROUNDJOB_DATA
  ]);
  const currentAccount = useContext(CurrentAccountContext);
  const structuredSelector = createStructuredSelector({
    busy: state => state.backGroundJob.busy,
    enqueued: state => state.backGroundJob.enqueued,
    failed: state => state.backGroundJob.failed,
    processed: state => state.backGroundJob.processed,
    retries: state => state.backGroundJob.retries,
    scheduled: state => state.backGroundJob.scheduled,
    isBackGroundJobLoaded: state => backGroundJobLoadedSelector(state)
  });

  const {
    busy,
    enqueued,
    failed,
    processed,
    retries,
    scheduled,
    isBackGroundJobLoaded
  } = useSelector(structuredSelector);

  function fetchDataFromService() {
    dispatch(
      fetchBackGroundJobData({
        backGroundJobUrl: currentAccount?.account_url
      })
    );
  }
  useEffect(() => {
    dispatch(setOverlordLayoutHeaderText('Background Job Dashboard'));
    fetchDataFromService();
    const handler = setInterval(() => {
      fetchDataFromService();
    }, interval);
    return () => {
      clearTimeout(handler);
    };
  }, [dispatch]);

  return (
    <div className="tw-mb-10">
      <div className="tw-mt-5 tw-grid tw-grid-cols-1 tw-gap-5 sm:tw-grid-cols-3">
        <BackGroundItem
          textName="Processed"
          loaded={isBackGroundJobLoaded}
          textValue={processed}
        />
        <BackGroundItem
          textName="Failed"
          loaded={isBackGroundJobLoaded}
          textValue={failed}
        />
        <BackGroundItem
          textName="Busy"
          loaded={isBackGroundJobLoaded}
          textValue={busy}
        />
        <BackGroundItem
          textName="Enqueued"
          loaded={isBackGroundJobLoaded}
          textValue={enqueued}
        />
        <BackGroundItem
          textName="Retries"
          loaded={isBackGroundJobLoaded}
          textValue={retries}
        />
        <BackGroundItem
          textName="Scheduled"
          loaded={isBackGroundJobLoaded}
          textValue={scheduled}
        />
      </div>
    </div>
  );
};

export default BackGroundJobDashboard;
