import styled from 'styled-components';

const SubjectInputWrapper = styled.div`
  max-height: 36px;
  z-index: 101;

  & .invalid-feedback {
    position: relative;
    width: 260px;
    padding: 10px;
    margin-top: 8px;
    background: #ffffff;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    -webkit-box-shadow: 0px 1px 4px 0px rgba(50, 50, 50, 0.27);
    -moz-box-shadow: 0px 1px 4px 0px rgba(50, 50, 50, 0.27);
    box-shadow: 0px 1px 4px 0px rgba(50, 50, 50, 0.27);
  }

  & .invalid-feedback:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    margin-left: -0.5em;
    top: 0em;
    left: 25%;
    box-sizing: border-box;
    z-index: 100;
    border: 0.75em solid black;
    border-color: transparent transparent white white;
    transform-origin: 0 0;
    transform: rotate(135deg);
    box-shadow: -2px 2px 2px -2px rgba(0, 0, 0, 0.2);
  }
`;

const SubjectInputForm = styled.form`
  & .form-type-material .form-control {
    background-image: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(#48b0f7),
        to(#48b0f7)
      ),
      -webkit-gradient(linear, left top, left bottom, from(#ebebeb), to(#ebebeb));
    background-image: -webkit-linear-gradient(#48b0f7, #48b0f7),
      -webkit-linear-gradient(#ebebeb, #ebebeb);
    background-image: linear-gradient(#48b0f7, #48b0f7),
      linear-gradient(#ebebeb, #ebebeb);
  }

  & .form-type-material .form-control.is-invalid {
    background-image: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(#f96868),
        to(#f96868)
      ),
      -webkit-gradient(linear, left top, left bottom, from(#ebebeb), to(#ebebeb)) !important;
    background-image: -webkit-linear-gradient(#f96868, #f96868),
      -webkit-linear-gradient(#ebebeb, #ebebeb) !important;
    background-image: linear-gradient(#f96868, #f96868),
      linear-gradient(#ebebeb, #ebebeb) !important;
  }

  & .form-type-material .form-control::selection {
    background-color: #48b0f7;
  }
`;

const BeeWrapper = styled.div`
  overflow: hidden;
  height: calc(100vh - 290px);
  position: relative;

  &.full-screen {
    overflow: hidden;
    height: 95vh;
    position: relative;
  }

  & iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    min-width: 640px !important;
    background-color: #fff !important;
    border-radius: ${props =>
      props.hasRoundedCorners ? '0 0 10px 10px' : '0'};
  }
`;

const Styled = {
  SubjectInputWrapper,
  SubjectInputForm,
  BeeWrapper
};

export default Styled;
