import React from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useHistory } from 'react-router-dom';
import { broadcastEmailSchema } from 'lib/validation/schema';
import {
  broadcastSaveEmail,
  broadcastSetEmailSubject
} from 'appState/actions/ActionCreators';
import Styled from './EditEmailSubjectModal.styles';

const EditEmailSubjectModal = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const structuredSelector = createStructuredSelector({
    currentBroadcast: state => state.broadcastCenter.currentBroadcast,
    emailSubject: state => state.broadcastCenter.emailSubject
  });

  const { currentBroadcast, emailSubject } = useSelector(structuredSelector);

  function saveEmailSubject() {
    dispatch(
      broadcastSaveEmail({
        broadcastId: currentBroadcast.id,
        history
      })
    );
  }

  const formik = useFormik({
    initialValues: {
      emailSubject
    },
    enableReinitialize: true,
    validationSchema: broadcastEmailSchema,
    onSubmit: () => saveEmailSubject()
  });

  const onEmailSubjectChange = e => {
    const subject = e.target.value;
    formik.handleChange(e);
    dispatch(broadcastSetEmailSubject(subject));
  };

  return (
    <div
      className="modal fade show"
      id="edit-email-subject-modal"
      tabIndex="-1"
      aria-modal="true"
      aria-hidden="true"
      style={{ display: 'none' }}
    >
      <div className="modal-dialog modal-md">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Edit Email Subject</h4>
            <button type="button" className="close" data-dismiss="modal">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <Styled.SubjectInputForm
              id="email-subject-form"
              data-toggle="validator"
              data-focus="false"
              className="broadcast-center-form"
              style={{ width: '99%' }}
            >
              <Styled.SubjectInputWrapper
                id="subject-field-wrapper"
                className="form-group no-margin no-padding form-type-material"
              >
                <input
                  onKeyDown={e =>
                    e.code === 'Enter' ? e.preventDefault() : null
                  }
                  type="text"
                  id="emailSubject"
                  name="emailSubject"
                  className={`form-control required ${
                    formik.touched.emailSubject && formik.errors.emailSubject
                      ? 'is-invalid'
                      : ''
                  }`}
                  data-error="Please add a subject line to this email."
                  required
                  placeholder="Enter Your Subject Here"
                  onChange={e => onEmailSubjectChange(e)}
                  onBlur={formik.handleBlur}
                  value={formik.values.emailSubject}
                  autoComplete="on"
                />
                {formik.touched.emailSubject && formik.errors.emailSubject ? (
                  <span className="invalid-feedback">
                    {formik.errors.emailSubject}
                  </span>
                ) : null}
              </Styled.SubjectInputWrapper>
            </Styled.SubjectInputForm>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-danger"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-info"
              data-dismiss={
                formik.touched.emailSubject && formik.errors.emailSubject
                  ? ''
                  : 'modal'
              }
              onClick={formik.handleSubmit}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditEmailSubjectModal;
