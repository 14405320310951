import { useEffect, useState } from 'react';
import debounce from 'lodash.debounce';

export default function useInfiniteScroll(
  elementSelector,
  loadMore,
  isInverse = false,
  isMobile = false
) {
  const [isFetching, setIsFetching] = useState(false);
  const [lastScrollHeight, setLastScrollHeight] = useState(0);

  function handleScroll(element) {
    if (isFetching) return;

    const padding = 250;
    let conditionalFetchingCriteria;

    if (isInverse) {
      // Top of the scrollable element + some padding
      conditionalFetchingCriteria = element.scrollTop - padding <= 0;
    } else {
      // Bottom of the scrollable element + some padding
      conditionalFetchingCriteria =
        element.scrollTop + padding >=
        element.scrollHeight - element.offsetHeight;
    }

    if (conditionalFetchingCriteria) {
      setLastScrollHeight(element.scrollHeight);
      setIsFetching(true);
    }
  }

  const debouncedHandleScroll = debounce(handleScroll, 50);

  useEffect(() => {
    const element = isMobile
      ? document.body
      : document.getElementById(elementSelector);
    element.addEventListener('scroll', () => debouncedHandleScroll(element));
    document.body.addEventListener('touchmove', () =>
      debouncedHandleScroll(element)
    );
    return () => {
      element.removeEventListener('scroll', () =>
        debouncedHandleScroll(element)
      );
      document.body.removeEventListener('touchmove', () =>
        debouncedHandleScroll(element)
      );
    };
  }, []);

  useEffect(() => {
    if (!isFetching) return;
    loadMore();
  }, [isFetching]);

  return isInverse
    ? [isFetching, setIsFetching, lastScrollHeight]
    : [isFetching, setIsFetching];
}
