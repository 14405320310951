// linting rules disabled for immer js
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import { CREATE_CONTACT_FORM_SUCCESS, UPDATE_CONTACT_FORM_SUCCESS } from 'appState/actions/constants/contactForm.actions';

const initialState = {};

export default function ContactFormTypeReducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case CREATE_CONTACT_FORM_SUCCESS: {
        return draft;
      }
      case UPDATE_CONTACT_FORM_SUCCESS: {
        return draft;
      }
      default:
        return draft;
    }
  });
}
