/* eslint-disable react/no-this-in-sfc */
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import isEmpty from 'lodash.isempty';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { readURLCrop } from 'lib/utils/image';
import loadingGif from 'images/broadcast/loading.gif';
import { CurrentUserContext } from 'components/shared/context/CurrentUserContext';
import { CurrentAccountContext } from 'components/shared/context/CurrentAccountContext';
import TextareaAutosize from 'react-autosize-textarea';
import {
  smsInboxSendMessage,
  resetIsLoaded
} from 'appState/actions/ActionCreators';
import {
  createErrorMessageSelector,
  createLoadedSelector
} from 'appState/selectors';
import { ENTER_KEY_CODE } from 'config/constants';
import { SMS_INBOX_SEND_MESSAGE_ACTION } from 'appState/actions/constants/smsInbox.actions';
import { SegmentedMessage } from 'sms-segments-calculator';

const Footer = props => {
  const { contact, setAddedImage } = props;
  const currentAccount = useContext(CurrentAccountContext);
  const currentUser = useContext(CurrentUserContext);
  const dispatch = useDispatch();
  const unsubscribedConversation = !contact.subscribedToSms;
  const canSendSms =
    contact.subscribedToSms && contact.phoneNumberValidForSms && contact.phone;
  const [message, setMessage] = useState('');
  const [fileField, setFileField] = useState({});
  const [removeTempMedia, setRemoveTempMedia] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const imagePreviewEl = useRef(null);
  const inputFileEl = useRef(null);

  function sendMessage() {
    setIsSending(true);

    const smsSaveParams = {
      contactId: contact.id,
      currentUserId: currentUser.id,
      removeTempMedia
    };

    if (currentAccount.mms_available) {
      const selectedFiles = get(inputFileEl, 'current.files');
      if (selectedFiles.length > 0) smsSaveParams.file = fileField;
    }
    if (message) smsSaveParams.message = message;

    dispatch(smsInboxSendMessage(smsSaveParams));
  }

  const errorSelector = createErrorMessageSelector([
    SMS_INBOX_SEND_MESSAGE_ACTION
  ]);
  const loadedSelector = createLoadedSelector([SMS_INBOX_SEND_MESSAGE_ACTION]);

  const structuredSelector = createStructuredSelector({
    error: state => errorSelector(state),
    isMessageSentLoaded: state => loadedSelector(state)
  });
  const { error, isMessageSentLoaded } = useSelector(structuredSelector);

  function handleRemoveMMSMedia() {
    imagePreviewEl.current.setAttribute('src', loadingGif);
    if (inputFileEl.current) inputFileEl.current.value = null;
    setFileField({});
    setRemoveTempMedia(true);
    setAddedImage(false);
  }

  useEffect(() => {
    if (isMessageSentLoaded) {
      if (isEmpty(error)) setMessage('');
      setIsSending(false);
      handleRemoveMMSMedia();
    }

    dispatch(resetIsLoaded(SMS_INBOX_SEND_MESSAGE_ACTION));

    return () => {
      dispatch(resetIsLoaded(SMS_INBOX_SEND_MESSAGE_ACTION));
    };
  }, [isMessageSentLoaded, error]);

  function handleFileSelect(e) {
    if (e.target.files) {
      setFileField(e.target.files[0]);
      const croppedImagePreviewEl = readURLCrop(
        e.target.files[0],
        imagePreviewEl
      );
      imagePreviewEl.current.setAttribute(
        'src',
        croppedImagePreviewEl.current.src
      );
      if (e.target.files[0]) {
        setRemoveTempMedia(false);
        setAddedImage(true);
      }
    }
  }

  function handleEnterKeyPress(e) {
    if (message.trim() && e.keyCode === ENTER_KEY_CODE && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  }

  function getSegmentCount() {
    const segmentedMessage = new SegmentedMessage(message);
    return segmentedMessage.segmentsCount;
  }

  const segementCount = getSegmentCount();
  return (
    <Fragment>
      {isSending && (
        <div className="spinner-linear spinner-info" id="sending-loader">
          <div className="line" />
        </div>
      )}
      <footer
        id="sms-chat-input"
        className={`publisher ${
          isEmpty(inputFileEl?.current?.value)
            ? 'align-items-center'
            : 'align-items-start'
        }`}
        style={{
          borderBottomLeftRadius: '10px',
          borderBottomRightRadius: '10px'
        }}
      >
        {contact && !canSendSms ? (
          <Fragment>
            <textarea
              data-autoresize
              rows="1"
              placeholder={
                unsubscribedConversation
                  ? 'Contact is unsubscribed to SMS.'
                  : 'Invalid Phone Number For SMS'
              }
              className="publisher-input text-secondary"
              style={{ maxHeight: '350px' }}
              data-provide="maxlength"
              maxLength="320"
              readOnly
            />
            <div>
              <a className="publisher-btn" href="#">
                <i className="fa fa-paper-plane text-secondary" />
              </a>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div
              className={`tw-rounded-lg ${
                !removeTempMedia && (fileField && !isEmpty(fileField.name))
                  ? 'd-block'
                  : 'd-none'
              }`}
              style={{
                width: '100px',
                height: '100px',
                position: 'relative',
                display: 'none'
              }}
              id="image_upload_preview_wrapper"
            >
              <span
                className="fa-stack cursor-pointer"
                style={{
                  verticalAlign: 'top',
                  position: 'absolute',
                  right: '-10px',
                  top: '-10px'
                }}
                id="remove-mms-media"
                onClick={handleRemoveMMSMedia}
                onKeyDown={null}
                role="button"
                tabIndex={-1}
              >
                <i
                  className="fa fa-times-circle fa-stack-2x text-danger"
                  style={{ zIndex: '101' }}
                />
                <i
                  className="fa fa-circle fa-stack-2x text-white"
                  style={{ zIndex: '100' }}
                />
              </span>
              <div
                className="big-rounded"
                style={{ width: '100px', height: '100px', overflow: 'hidden' }}
              >
                <img
                  src={loadingGif}
                  ref={imagePreviewEl}
                  id="image_upload_preview"
                  alt="MMS Preview"
                />
              </div>
            </div>
            <TextareaAutosize
              rows={1}
              placeholder="Write your message here..."
              className="publisher-input"
              style={{ maxHeight: '60px' }}
              maxLength="320"
              onKeyDown={handleEnterKeyPress}
              onChange={e => setMessage(e.target.value)}
              value={message}
            />
            <span
              id="sending-placeholder"
              className="publisher-input"
              style={{ display: 'none' }}
            >
              <em>Sending Message...</em>
            </span>
            <div>
              <a
                id="sms-full-chat-create-button"
                className="publisher-btn"
                href="#"
                onClick={sendMessage}
              >
                <svg
                  className="tw-w-6 tw-h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
                  />
                </svg>
              </a>
            </div>
            {currentAccount.mms_available ? (
              <div className="file-group file-group-inline">
                <a
                  className="publisher-btn file-browser"
                  href="#"
                  data-provide="tooltip"
                  title="Image size max: 25MB. Video size max 50MB."
                  data-placement="bottom"
                  data-tooltip-color="secondary"
                >
                  <svg
                    className="tw-w-6 tw-h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                  </svg>
                </a>
                <input
                  ref={inputFileEl}
                  type="file"
                  id="inputFile"
                  accept="image/*, video/*, video/mp4, video/x-m4v"
                  onChange={handleFileSelect}
                />
              </div>
            ) : null}
          </Fragment>
        )}
      </footer>
      <div className="tw-flex tw-flex-col tw-bg-gray-200">
        <div className="tw-pl-3 tw-pb-1 tw-pr-1 tw-pt-1 sm:tw-pb-2 sm:tw-pt-2 tw-text-sm">
          <p className="tw-italic tw-mb-0 tw-leading-tight">
            Aim to be short and sweet. One standard SMS segment is 160
            characters. If using an emoji, the segment decreases to 70
            characters
          </p>
          {/* <div className="tw-text-xs sm:tw-text-sm"> */}
          <span className="tw-font-medium">Characters Limts:</span>
          <span> {message.length}/320</span>
          <span className="tw-font-medium"> Segment:</span>
          <span> {segementCount}</span>
          {/* </div> */}
        </div>
      </div>
    </Fragment>
  );
};

Footer.defaultProps = {
  contact: {}
};

const contactShape = {
  name: PropTypes.string
};

Footer.propTypes = {
  contact: PropTypes.shape(contactShape),
  setAddedImage: PropTypes.func.isRequired
};

export default Footer;
