import React, { Fragment, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
  createErrorMessageSelector,
  createLoadedSelector
} from 'app-state/selectors';
import {
  fetchLeadSources,
  bulkUpdateContactsLeadSource,
  flashSuccessMessage,
  flashErrorMessage
} from 'app-state/actions/ActionCreators';
import { bulkUpdateContactsSchema } from 'lib/validation/schema';
import { createStructuredSelector } from 'reselect';
import isEmpty from 'lodash.isempty';
import { BULK_UPDATE_CONTACTS } from 'app-state/actions/constants/leadSources.actions';
import extractFileName from 'lib/utils/file';
import Card from 'components/Theme/Card';
import InfoPanel from 'components/Theme/InfoPanel';
import PageHeader from 'components/Theme/PageHeader';
import FileInput from 'components/Theme/FileInput';
import Modal from 'components/Theme/Modal';
import Notification from 'components/Theme/Notification';
import Select from 'components/Theme/Select';

const UpdateContacts = () => {
  const dispatch = useDispatch();
  const [leadSourceOptions, setLeadSourceOptions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const bulkUpdateLoadedSelector = createLoadedSelector([BULK_UPDATE_CONTACTS]);
  const bulkUpdateErrorSelector = createErrorMessageSelector([
    BULK_UPDATE_CONTACTS
  ]);

  const infoItems = [
    'This importer will only adjust the lead source field for existing contacts included in your CSV.  To add new contacts, please use <i><a href="/ui/contacts/csv_import/new" target="_importer" style="color: #245CCE; text-decoration: underline">Contact Importer</a></i>.',
    'To update an entire group of contacts who entered through a specific journey, navigate to Account Settings> Archived Journeys> Actions (Drop down)> Export contacts. Then re-import that list here.',
    'If importing a custom list, the CSV must contain columns entitled, <b>id, first_name, last_name, email</b> in this exact order.'
  ];

  const structuredSelector = createStructuredSelector({
    defaultLeadSources: state => state.leadSource.defaultLeadSources,
    customLeadSources: state => state.leadSource.customLeadSources,
    bulkUpdateLoaded: state => bulkUpdateLoadedSelector(state),
    bulkUpdateError: state => bulkUpdateErrorSelector(state),
    rejectedBulkUpdateContacts: state =>
      state.leadSource.rejectedBulkUpdateContacts
  });

  const {
    defaultLeadSources,
    customLeadSources,
    bulkUpdateLoaded,
    bulkUpdateError,
    rejectedBulkUpdateContacts
  } = useSelector(structuredSelector);

  useEffect(() => {
    dispatch(fetchLeadSources());
  }, [dispatch]);

  useEffect(() => {
    const defaultOptions = defaultLeadSources.map(leadSource => {
      return { displayName: leadSource.name, value: leadSource.key };
    });

    let customOptions = [];
    if (!isEmpty(customLeadSources)) {
      customOptions = customLeadSources.map(leadSource => {
        return { displayName: leadSource.name, value: leadSource.key };
      });
    }

    setLeadSourceOptions([...defaultOptions, ...customOptions]);
  }, [defaultLeadSources, customLeadSources]);

  const formik = useFormik({
    initialValues: {
      file: null,
      leadSource: ''
    },
    enableReinitialize: true,
    validationSchema: bulkUpdateContactsSchema,
    onSubmit: values => {
      setBtnDisabled(true);
      setShowWarning(true);
      dispatch(bulkUpdateContactsLeadSource(values));
    }
  });

  useEffect(() => {
    if (bulkUpdateLoaded) {
      if (isEmpty(bulkUpdateError)) {
        if (isEmpty(rejectedBulkUpdateContacts))
          dispatch(flashSuccessMessage('Bulk update contacts successfull'));
        setBtnDisabled(false);
        setShowWarning(false);
        formik.resetForm();
      } else {
        dispatch(flashErrorMessage(bulkUpdateError));
      }
      if (!isEmpty(rejectedBulkUpdateContacts)) setShowModal(true);
    }
  }, [bulkUpdateLoaded, bulkUpdateError, rejectedBulkUpdateContacts]);

  const onImportFileChange = file => {
    formik.setFieldValue('file', file);
  };

  return (
    <Fragment>
      <PageHeader
        title="Bulk Update"
        breadcrumbs={[
          {
            name: 'Settings',
            action: () => {
              window.location = '/account/account_settings';
            }
          },
          {
            name: 'Lead Sources',
            action: () => {
              window.location = '/ui/lead-sources';
            }
          },
          {
            name: 'Bulk Update',
            action: () => {}
          }
        ]}
      />
      <Card
        containerClass="tw-rounded-lg tw-bg-white tw-m-8 tw-mb-20"
        customMarginContainer
        showPrimaryAction
        primaryActionText="Process CSV"
        primaryAction={formik.handleSubmit}
        disablePrimaryAction={btnDisabled}
      >
        <div className="tw-mx-10 tw-mt-10 tw-mb-20">
          <InfoPanel
            title="Things to know"
            infoItems={infoItems}
            withHtmlInfoItems
          />
          <div className="tw-w-full tw-my-8">
            <div className="tw-my-8 tw-w-full sm:tw-w-1/2 md:tw-w-2/5">
              <FileInput
                id="file"
                value={extractFileName(formik.values.file)}
                labelText="Upload CSV"
                placeholder="Select a CSV file"
                onChange={onImportFileChange}
                onBlur={formik.handleBlur}
                showError={formik.touched.file && !!formik.errors.file}
                error={formik.errors.file}
                required
              />
            </div>
            <div className="tw-my-8 tw-w-full sm:tw-w-1/2 md:tw-w-2/5">
              <Select
                id="leadSource"
                labelText="Lead Source"
                options={leadSourceOptions}
                value={formik.values.leadSource}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                showError={
                  formik.touched.leadSource && !!formik.errors.leadSource
                }
                error={formik.errors.leadSource}
                required
              />
            </div>
          </div>
        </div>
        <Notification
          message="Updation in progress, do not move away from this page."
          color="warning"
          type="colored"
          show={showWarning}
          setShow={setShowWarning}
        />
        <Modal
          headerText="Bulk Update Info"
          primaryActionText="OK"
          primaryAction={() => setShowModal(false)}
          show={showModal}
          setShow={setShowModal}
          showHeaderIcon={false}
          canHandleClickOutside={false}
        >
          <p className="tw-text-error-800 tw-text-xs">
            Error: the following contacts in your CSV were not updated. The
            remaining contacts were updated. The contacts below were not updated
            as they do not match existing contacts in UpLaunch. Please make sure
            that all contacts in your CSV are already in your UpLaunch system
            before attempting to bulk update their lead source.
          </p>
          {rejectedBulkUpdateContacts.map(conRow => {
            return (
              <p className="tw-flex" key={conRow[0]}>
                <span className="tw-flex-initial tw-font-bold tw-w-20">
                  {conRow[0]}
                </span>
                {conRow[1] && (
                  <span className="tw-flex-initial tw-font-bold tw-w-20 tw-pl-4">
                    {conRow[1]}
                  </span>
                )}
                {conRow[2] && (
                  <span className="tw-flex-initial tw-font-bold tw-pl-4">
                    {conRow[2]}
                  </span>
                )}
              </p>
            );
          })}
        </Modal>
      </Card>
    </Fragment>
  );
};

export default UpdateContacts;
