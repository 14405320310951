import { call, put, takeLatest } from 'redux-saga/effects';
import { axiosDefault } from 'lib/utils/axios-setup';
import {
  CREATE_JOURNEY_REQUEST,
  CREATE_JOURNEY_SUCCESS,
  CREATE_JOURNEY_FAILURE,
  UPDATE_JOURNEY_REQUEST,
  UPDATE_JOURNEY_SUCCESS,
  UPDATE_JOURNEY_FAILURE
  } from 'app-state/actions/constants/journey.actions';

function camelToSnake(str) {
  return str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
}

function journeyParams(journey) {
  const newJourney = {};
  Object.keys(journey).forEach(key => {
    const newKey = camelToSnake(key);
    newJourney[newKey] = journey[key];
  });

  return newJourney;
}

function* createJourney({ payload: { data } }) {
  const params = {
    journey: journeyParams(data)
  };
  try {
    const response = yield call(axiosDefault.post, '/journeys', params);

    yield put({
      type: CREATE_JOURNEY_SUCCESS,
      payload: {
        journey: response.data
      }
    });
  } catch (e) {
    yield put({
      type: CREATE_JOURNEY_FAILURE,
      payload: {
        error: e,
        fallbackError: 'Error while setting up your product.'
      },
      error: true
    });
  }
}

function* updateJourney({ payload: { journeyId, data } }) {
  const params = {
    journey: journeyParams(data)
  };
  try {
    const response = yield call(
      axiosDefault.put,
      `/journeys/${journeyId}`,
      params
    );

    yield put({
      type: UPDATE_JOURNEY_SUCCESS,
      payload: {
        journey: response.data
      }
    });
  } catch (e) {
    yield put({
      type: UPDATE_JOURNEY_FAILURE,
      payload: {
        error: e,
        fallbackError: 'Error while updating up your product.'
      },
      error: true
    });
  }
}

export function* journeyCreate() {
  yield takeLatest(CREATE_JOURNEY_REQUEST, createJourney);
}

export function* journeyUpdate() {
  yield takeLatest(UPDATE_JOURNEY_REQUEST, updateJourney);
}
