import React, { useContext, useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  createErrorMessageSelector,
  createLoadedSelector
} from 'appState/selectors';
import {
  fetchPinnedAppointmentsActivities,
  fetchAppointmentsActivities,
  appointmentsActivitiesPageChange,
  resetIsLoaded
} from 'appState/actions/ActionCreators';
import { FETCH_APPOINTMENTS_ACTIVITIES } from 'appState/actions/constants/activity.actions';
import {
  UPDATE_APPOINTMENT,
  CREATE_APPOINTMENT_FROM_BOOKER
} from 'appState/actions/constants/appointmentBooker.actions';
import isEmpty from 'lodash.isempty';
import { formatTimestamp } from 'lib/utils/dateTime';
import Tabs from 'components/Theme/Tabs';
import InfoPanel from 'components/Theme/InfoPanel';
import Notification from 'components/Theme/Notification';
import NotificationIcon from 'components/Theme/Icon/NotificationIcon';
import PinnedActivities from 'components/Contact/Profile/PinnedActivities';
import RecentActivities from 'components/Contact/Profile/RecentActivities';
import { CurrentAccountContext } from 'components/shared/context/CurrentAccountContext';
import AppointmentBookerTab from './AppointmentBookerTab';
import ManualOverrideTab from './ManualOverrideTab';

const AppointmentsTab = () => {
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showUpdateSuccess, setShowUpdateSuccess] = useState(false);
  const dispatch = useDispatch();
  const loadedSelector = createLoadedSelector([FETCH_APPOINTMENTS_ACTIVITIES]);

  const updateAppointmentLoadedSelector = createLoadedSelector([
    UPDATE_APPOINTMENT
  ]);
  const updateAppointmentErrorSelector = createErrorMessageSelector([
    UPDATE_APPOINTMENT
  ]);
  const createAppointmentLoadedSelector = createLoadedSelector([
    CREATE_APPOINTMENT_FROM_BOOKER
  ]);
  const createAppointmentErrorSelector = createErrorMessageSelector([
    CREATE_APPOINTMENT_FROM_BOOKER
  ]);

  const structuredSelector = createStructuredSelector({
    currentContact: state => state.contact.currentContact,
    currentAppointment: state => state.appointmentBooker.currentAppointment,
    pinnedAppointmentsActivities: state =>
      state.activity.pinnedAppointmentsActivities,
    appointmentsActivities: state => state.activity.appointmentsActivities,
    appointmentsTotal: state => state.activity.appointmentsTotal,
    appointmentsTotalPages: state => state.activity.appointmentsTotalPages,
    limit: state => state.activity.limit,
    isLoaded: state => loadedSelector(state),
    updateAppointmentError: state => updateAppointmentErrorSelector(state),
    createAppointmentError: state => createAppointmentErrorSelector(state),
    isUpdateAppointmentLoaded: state => updateAppointmentLoadedSelector(state),
    isCreateAppointmentLoaded: state => createAppointmentLoadedSelector(state)
  });

  const {
    currentContact,
    pinnedAppointmentsActivities,
    appointmentsActivities,
    appointmentsTotal,
    appointmentsTotalPages,
    limit,
    isLoaded,
    isUpdateAppointmentLoaded,
    isCreateAppointmentLoaded,
    updateAppointmentError,
    createAppointmentError,
    currentAppointment
  } = useSelector(structuredSelector);

  const currentAccount = useContext(CurrentAccountContext);

  useEffect(() => {
    if (isEmpty(pinnedAppointmentsActivities) && !isEmpty(currentContact))
      dispatch(
        fetchPinnedAppointmentsActivities({
          contactId: currentContact.id
        })
      );
  }, [dispatch, currentContact]);

  useEffect(() => {
    if (isEmpty(appointmentsActivities) && !isEmpty(currentContact))
      dispatch(
        fetchAppointmentsActivities({
          contactId: currentContact.id
        })
      );
  }, [dispatch, currentContact]);

  useEffect(() => {
    if (isCreateAppointmentLoaded && isEmpty(createAppointmentError)) {
      dispatch(
        fetchAppointmentsActivities({
          contactId: currentContact.id
        })
      );
      setShowSuccess(true);
    }

    if (isCreateAppointmentLoaded && !isEmpty(createAppointmentError)) {
      setShowError(true);
    }

    dispatch(resetIsLoaded(CREATE_APPOINTMENT_FROM_BOOKER));

    return () => {
      dispatch(resetIsLoaded(CREATE_APPOINTMENT_FROM_BOOKER));
    };
  }, [
    createAppointmentError,
    isCreateAppointmentLoaded,
    currentContact,
    dispatch
  ]);

  useEffect(() => {
    if (isUpdateAppointmentLoaded && isEmpty(updateAppointmentError)) {
      setShowUpdateSuccess(true);
    }

    if (isUpdateAppointmentLoaded && !isEmpty(updateAppointmentError)) {
      setShowError(true);
    }

    if (isUpdateAppointmentLoaded) {
      dispatch(resetIsLoaded(UPDATE_APPOINTMENT));
    }

    return () => {
      if (isUpdateAppointmentLoaded) {
        dispatch(resetIsLoaded(UPDATE_APPOINTMENT));
      }
    };
  }, [updateAppointmentError, isUpdateAppointmentLoaded, dispatch]);

  const getErrorNotificationMessage = (createError, updateError) => {
    if (createError) {
      return 'Appointment not created';
    }
    if (updateError) {
      return 'Appointment not updated';
    }
    return null;
  };

  return (
    <Fragment>
      {!isEmpty(currentAppointment) ? (
        <div className="tw-mt-5 tw-mb-2">
          <InfoPanel
            color="alpha"
            infoItems={[
              `Appointment Type: ${currentAppointment.appointment_type}`,
              `Staff Member: ${currentAppointment.user}`,
              `Date & Time: ${formatTimestamp(
                currentAppointment.starts_at,
                currentAccount.time_zone
              )}`
            ]}
            orderedList={false}
            showSvg
            title="Reschedule Appointment"
          />
        </div>
      ) : null}

      <div className="tw-mt-5">
        <div>
          <Tabs
            color="alpha"
            tabs={[
              {
                text: 'Booking Wizard',
                value: 'booking_wizard',
                // eslint-disable-next-line react/display-name
                content: () => <AppointmentBookerTab />
              },
              {
                text: 'Manual Override',
                value: 'manual_override',
                // eslint-disable-next-line react/display-name
                content: () => <ManualOverrideTab />
              }
            ]}
            type="secondary"
          />
          <div className="tw-hidden sm:tw-block tw-mt-5">
            <div className="tw-py-5">
              <div className="tw-border-t tw-border-b-0 tw-border-l-0 tw-border-r-0 tw-border-solid tw-border-gray-200" />
            </div>
          </div>
        </div>

        <div className="tw-col-span-12 tw-px-4">
          <PinnedActivities
            tabName="appointments"
            activities={pinnedAppointmentsActivities}
          />
          <RecentActivities
            activities={appointmentsActivities}
            total={appointmentsTotal}
            totalPages={appointmentsTotalPages}
            limit={limit}
            currentContact={currentContact}
            currentAccount={currentAccount}
            isLoaded={isLoaded}
            resetAction={FETCH_APPOINTMENTS_ACTIVITIES}
            pageChangeAction={appointmentsActivitiesPageChange}
          />
        </div>
      </div>
      <Notification
        message="Appointment updated"
        show={showUpdateSuccess}
        setShowAction={setShowUpdateSuccess}
        type="colored"
        headerText="Success!"
        color="success"
        HeaderIcon={color => {
          return (
            <NotificationIcon color={color} tint="400" width="5" height="5" />
          );
        }}
      />
      <Notification
        message="Appointment created"
        show={showSuccess}
        setShowAction={setShowSuccess}
        type="colored"
        headerText="Success!"
        color="success"
        HeaderIcon={color => {
          return (
            <NotificationIcon color={color} tint="400" width="5" height="5" />
          );
        }}
      />
      <Notification
        HeaderIcon={color => {
          return (
            <NotificationIcon
              color={color}
              tint="400"
              width="5"
              height="5"
              filled
            />
          );
        }}
        color="error"
        headerText="Error!"
        message={getErrorNotificationMessage(
          createAppointmentError,
          updateAppointmentError
        )}
        setShowAction={setShowError}
        show={showError}
        type="colored"
      />
    </Fragment>
  );
};

export default AppointmentsTab;
