// linting rules disabled for immer js
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  FETCH_CONTACT_FORM_TYPES_SUCCESS,
  FETCH_CONTACT_FORM_TYPE_SUCCESS
} from 'appState/actions/constants/contactFormType.actions';

const initialState = {
  contactFormTypes: [],
  currentContactFormType: {}
};

export default function ContactFormTypeReducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case FETCH_CONTACT_FORM_TYPES_SUCCESS: {
        const { contactFormTypes } = action.payload;
        draft.contactFormTypes = contactFormTypes;
        return;
      }
      case FETCH_CONTACT_FORM_TYPE_SUCCESS: {
        const { currentContactFormTypes } = action.payload;
        draft.currentContactFormTypes = currentContactFormTypes;
        return;
      }
      default:
        return draft;
    }
  });
}
