import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import isEmpty from 'lodash.isempty';
import { CurrentUserContext } from 'components/shared/context/CurrentUserContext';
import { formatTimestamp } from 'lib/utils/dateTime';
import Styled from './UtilityCard.styles';

const UtilityCard = props => {
  const {
    title,
    subtitle,
    submitBtnText,
    submitBtnAction,
    bodyComponent,
    isLoaded,
    processingActivity,
    accountDisabled,
    error
  } = props;

  const currentUser = useContext(CurrentUserContext);
  const userTimeZone = currentUser.account_time_zone;

  const submitAction = () => {
    if (submitAction) submitBtnAction();
  };

  const debounceSubmitAction = debounce(submitAction, 400);

  const onClickHandler = e => {
    e.preventDefault();
    debounceSubmitAction(e);
  };

  return (
    <div className="card" key={processingActivity.id}>
      <header className="card-header">
        <h4 className="card-title mb-0">
          {title}
          <small className="sidetitle">{subtitle}</small>
        </h4>
        <ul className="card-controls">
          <li>
            <a className="card-btn-slide rotate-180" href="#" />
          </li>
        </ul>
      </header>
      <div className="card-content">
        {!isEmpty(error) ? (
          <div
            className="alert alert-danger alert-dismissible fade show d-flex"
            role="alert"
          >
            <button type="button" className="close" data-dismiss="alert">
              <span>×</span>
            </button>
            {error}
          </div>
        ) : null}

        {!isEmpty(processingActivity) ? (
          <div
            className="alert alert-warning alert-dismissible fade show d-flex"
            role="alert"
          >
            <Styled.Spinner className="spinner-circle-shadow spinner-warning" />
            Currenty processing utility by{' '}
            {processingActivity.metadata.current_user_full_name} started{' '}
            {formatTimestamp(processingActivity.updated_at, userTimeZone)}.
          </div>
        ) : null}

        <form>
          <div className="card-body">
            {typeof bodyComponent === 'function' ? (
              bodyComponent()
            ) : (
              <bodyComponent />
            )}
          </div>
          {submitBtnText.length && (
            <footer className="card-footer text-right">
              <div>
                <button
                  type="submit"
                  className="btn btn-info"
                  onClick={e => onClickHandler(e)}
                  disabled={!isEmpty(processingActivity)}
                >
                  {submitBtnText}
                </button>
              </div>
            </footer>
          )}
        </form>
      </div>
      {accountDisabled && <div className="card-loading reveal" />}
      {!isLoaded && (
        <div className="card-loading reveal">
          <div className="spinner-linear spinner-info align-self-start">
            <div className="line" />
          </div>
        </div>
      )}
    </div>
  );
};

UtilityCard.defaultProps = {
  title: '',
  subtitle: '',
  submitBtnText: '',
  submitBtnAction: null,
  bodyComponent: null,
  isLoaded: true,
  processingActivity: {},
  accountDisabled: false,
  error: ''
};

const metadataShape = {
  current_user_id: PropTypes.number,
  current_user_full_name: PropTypes.string
};

const processingActivityShape = {
  metadata: PropTypes.shape(metadataShape),
  status: PropTypes.string,
  utility: PropTypes.string,
  updated_at: PropTypes.string
};

UtilityCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  submitBtnText: PropTypes.string,
  submitBtnAction: PropTypes.func,
  bodyComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  isLoaded: PropTypes.bool,
  processingActivity: PropTypes.shape(processingActivityShape),
  accountDisabled: PropTypes.bool,
  error: PropTypes.string
};

export default UtilityCard;
