import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { broadcastSetStep } from 'appState/actions/ActionCreators';
import getPathName from 'lib/utils/router';

const useToggleHeaderFooter = (path, hideSidebar = false) => {
  const pathName = getPathName(path);
  const routesWhitelist = [
    'select-template',
    'select-audience',
    'sms',
    'email',
    'schedule',
    'save-details'
  ];
  const header = document.getElementById('top-menu-bar');
  const footer = document.getElementById('main-footer');
  const sidebar = document.getElementsByClassName('sidebar')[0];

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(broadcastSetStep({ step: pathName }));
  }, []);

  if (routesWhitelist.includes(pathName)) {
    header.setAttribute('style', 'display: none');
    footer.setAttribute('style', 'display: none');
  } else {
    header.setAttribute('style', 'display: flex');
    footer.setAttribute('style', 'display: block');
  }

  if (routesWhitelist.includes(pathName) && hideSidebar) {
    sidebar.setAttribute('style', 'display: none');
  } else {
    sidebar.setAttribute('style', 'display: flex');
  }
};

export default useToggleHeaderFooter;
