import { call, put, takeLatest } from 'redux-saga/effects';
import { axiosDefault, axiosMultipartForm } from 'lib/utils/axios-setup';
import { toSnake } from 'lib/utils/string';
import isEmpty from 'lodash.isempty';
import {
  FETCH_CONTACT_REQUEST,
  FETCH_CONTACT_SUCCESS,
  FETCH_CONTACT_FAILURE,
  FETCH_NEXT_CAMPAIGN_TOUCHPOINT_REQUEST,
  FETCH_NEXT_CAMPAIGN_TOUCHPOINT_SUCCESS,
  FETCH_NEXT_CAMPAIGN_TOUCHPOINT_FAILURE,
  FETCH_CONTACT_AUTOMATIONS_REQUEST,
  FETCH_CONTACT_AUTOMATIONS_SUCCESS,
  FETCH_CONTACT_AUTOMATIONS_FAILURE,
  FETCH_CONTACT_CUSTOM_GROUPS_REQUEST,
  FETCH_CONTACT_CUSTOM_GROUPS_SUCCESS,
  FETCH_CONTACT_CUSTOM_GROUPS_FAILURE,
  UPDATE_CONTACT_CUSTOM_GROUPS_REQUEST,
  UPDATE_CONTACT_CUSTOM_GROUPS_SUCCESS,
  UPDATE_CONTACT_CUSTOM_GROUPS_FAILURE,
  FETCH_CURRENT_CONTACT_CTA_REQUEST,
  FETCH_CURRENT_CONTACT_CTA_SUCCESS,
  FETCH_CURRENT_CONTACT_CTA_FAILURE,
  FETCH_CURRENT_AUTOMATION_DETAILS_REQUEST,
  FETCH_CURRENT_AUTOMATION_DETAILS_SUCCESS,
  FETCH_CURRENT_AUTOMATION_DETAILS_FAILURE,
  CREATE_CONTACT_REQUEST,
  CREATE_CONTACT_SUCCESS,
  CREATE_CONTACT_FAILURE,
  VALIDATE_CONTACTS_CSV_REQUEST,
  VALIDATE_CONTACTS_CSV_SUCCESS,
  VALIDATE_CONTACTS_CSV_FAILURE,
  SAVE_CONTACTS_CSV_REQUEST,
  SAVE_CONTACTS_CSV_SUCCESS,
  SAVE_CONTACTS_CSV_FAILURE,
  CREATE_CSV_IMPORT_REQUEST,
  CREATE_CSV_IMPORT_SUCCESS,
  CREATE_CSV_IMPORT_FAILURE,
  UPDATE_CSV_IMPORT_REQUEST,
  UPDATE_CSV_IMPORT_SUCCESS,
  UPDATE_CSV_IMPORT_FAILURE,
  DELETE_CSV_IMPORT_REQUEST,
  DELETE_CSV_IMPORT_SUCCESS,
  DELETE_CSV_IMPORT_FAILURE,
  FETCH_CSV_IMPORT_REQUEST,
  FETCH_CSV_IMPORT_SUCCESS,
  FETCH_CSV_IMPORT_FAILURE,
  VALIDATE_SINGLE_CONTACT_CSV_REQUEST,
  VALIDATE_SINGLE_CONTACT_CSV_SUCCESS,
  VALIDATE_SINGLE_CONTACT_CSV_FAILURE,
  SCHEDULE_CSV_IMPORT_REQUEST,
  SCHEDULE_CSV_IMPORT_SUCCESS,
  SCHEDULE_CSV_IMPORT_FAILURE,
  PAUSE_AUTOMATION_REQUEST,
  PAUSE_AUTOMATION_SUCCESS,
  PAUSE_AUTOMATION_FAILURE,
  UNPAUSE_AUTOMATION_REQUEST,
  UNPAUSE_AUTOMATION_SUCCESS,
  UNPAUSE_AUTOMATION_FAILURE,
  UPDATE_CURRENT_AUTOMATION_COMPLETE_REQUEST,
  UPDATE_CURRENT_AUTOMATION_COMPLETE_SUCCESS,
  UPDATE_CURRENT_AUTOMATION_COMPLETE_FAILURE,
  NEW_AUTOMATION_LIFECYCLE_CHECK_REQUEST,
  NEW_AUTOMATION_LIFECYCLE_CHECK_SUCCESS,
  NEW_AUTOMATION_LIFECYCLE_CHECK_FAILURE,
  FETCH_ACCOUNT_CAMPAIGNS_REQUEST,
  FETCH_ACCOUNT_CAMPAIGNS_SUCCESS,
  FETCH_ACCOUNT_CAMPAIGNS_FAILURE,
  FETCH_CAMPAIGN_STEP_LIST_REQUEST,
  FETCH_CAMPAIGN_STEP_LIST_SUCCESS,
  FETCH_CAMPAIGN_STEP_LIST_FAILURE,
  CREATE_AUTOMATION_REQUEST,
  CREATE_AUTOMATION_SUCCESS,
  CREATE_AUTOMATION_FAILURE,
  FETCH_CAMPAIGNS_LIST_REQUEST,
  FETCH_CAMPAIGNS_LIST_SUCCESS,
  FETCH_CAMPAIGNS_LIST_FAILURE,
  NEW_LIFECYCLE_CONFIRMATION_REQUEST,
  NEW_LIFECYCLE_CONFIRMATION_SUCCESS,
  NEW_LIFECYCLE_CONFIRMATION_FAILURE,
  UPDATE_CONTACT_LIFECYCLE_REQUEST,
  UPDATE_CONTACT_LIFECYCLE_SUCCESS,
  UPDATE_CONTACT_LIFECYCLE_FAILURE,
  CREATE_CONTACT_NOTE_REQUEST,
  CREATE_CONTACT_NOTE_SUCCESS,
  CREATE_CONTACT_NOTE_FAILURE,
  UPDATE_CONTACT_REQUEST,
  UPDATE_CONTACT_SUCCESS,
  UPDATE_CONTACT_FAILURE,
  CREATE_FILE_UPLOAD_REQUEST,
  CREATE_FILE_UPLOAD_SUCCESS,
  CREATE_FILE_UPLOAD_FAILURE,
  FETCH_APPOINTMENT_TYPES_REQUEST,
  FETCH_APPOINTMENT_TYPES_SUCCESS,
  FETCH_APPOINTMENT_TYPES_FAILURE,
  FETCH_UPLOADED_FILES_LIST_REQUEST,
  FETCH_UPLOADED_FILES_LIST_SUCCESS,
  FETCH_UPLOADED_FILES_LIST_FAILURE,
  ENABLE_NEW_CONTACT_PROFILE_REQUEST,
  ENABLE_NEW_CONTACT_PROFILE_SUCCESS,
  ENABLE_NEW_CONTACT_PROFILE_FAILURE,
  CREATE_EMAIL_REQUEST,
  CREATE_EMAIL_SUCCESS,
  CREATE_EMAIL_FAILURE,
  DELETE_CONTACT_REQUEST,
  DELETE_CONTACT_SUCCESS,
  DELETE_CONTACT_FAILURE,
  UNSUBSCRIBE_CONTACT_REQUEST,
  UNSUBSCRIBE_CONTACT_SUCCESS,
  UNSUBSCRIBE_CONTACT_FAILURE,
  RESUBSCRIBE_CONTACT_REQUEST,
  RESUBSCRIBE_CONTACT_SUCCESS,
  RESUBSCRIBE_CONTACT_FAILURE,
  REMOVE_SPAM_COMPLAINT_REQUEST,
  REMOVE_SPAM_COMPLAINT_SUCCESS,
  REMOVE_SPAM_COMPLAINT_FAILURE,
  FETCH_IMPORT_HISTORY_REQUEST,
  FETCH_IMPORT_HISTORY_SUCCESS,
  FETCH_IMPORT_HISTORY_FAILURE,
  FETCH_NEXT_APPOINTMENT_REQUEST,
  FETCH_NEXT_APPOINTMENT_SUCCESS,
  FETCH_NEXT_APPOINTMENT_FAILURE,
  FETCH_NEXT_TASK_REQUEST,
  FETCH_NEXT_TASK_SUCCESS,
  FETCH_NEXT_TASK_FAILURE
} from 'appState/actions/constants/contact.actions';
import {
  isDummyContact,
  createDummyNote,
  updateDummyContact,
  uploadDummyDocument,
  createDummyAutomation,
  createContactEmail,
  pauseUnpauseDummyAutomation,
  updateDummyCustomGroups
} from 'lib/services/dummyContactResponses';

function* enableNewProfile() {
  try {
    const response = yield call(
      axiosDefault.put,
      '/account_enable_new_contact_profile/convert_contact_profiles'
    );

    yield put({
      type: ENABLE_NEW_CONTACT_PROFILE_SUCCESS,
      payload: { data: response.data }
    });
  } catch (e) {
    yield put({
      type: ENABLE_NEW_CONTACT_PROFILE_FAILURE,
      payload: {
        error: e,
        fallbackError: 'Error enabling new contact profile. Please try agian.'
      }
    });
  }
}

function* updateContactCustomGroups({ payload: { contactId, customGroups } }) {
  try {
    const params = { custom_group_ids: customGroups };

    if (yield call(isDummyContact))
      return yield call(updateDummyCustomGroups, params);

    const response = yield call(
      axiosDefault.put,
      `/contacts/${contactId}/custom_groups`,
      params
    );

    yield put({
      type: UPDATE_CONTACT_CUSTOM_GROUPS_SUCCESS,
      payload: {
        contactCustomGroups: response.data
      }
    });
  } catch (e) {
    yield put({
      type: UPDATE_CONTACT_CUSTOM_GROUPS_FAILURE,
      payload: {
        error: e
      },
      error: true
    });
  }
}

function* fetchContact({ payload: { contactId, dummy } }) {
  try {
    let params = '';
    if (dummy) params += `?dummy=${dummy}`;

    const response = yield call(
      axiosDefault.get,
      `/contacts/${contactId}${params}`
    );

    yield put({
      type: FETCH_CONTACT_SUCCESS,
      payload: {
        currentContact: response.data
      }
    });
  } catch (e) {
    yield put({
      type: FETCH_CONTACT_FAILURE,
      payload: { error: e, fallbackError: 'Error fetching the contact.' },
      error: true
    });
  }
}

function* createContact({
  payload: {
    firstName,
    lastName,
    email,
    assignedStaffId,
    journeyId,
    phone = '',
    gender = '',
    tshirtSize = '',
    birthday = '',
    address1 = '',
    address2 = '',
    city = '',
    state = '',
    postalCode = '',
    referrerId = '',
    initialCampaignId = '',
    gdpr = false,
    leadSource
  }
}) {
  try {
    const params = {
      contact: {
        first_name: firstName,
        last_name: lastName,
        email,
        phone,
        gender,
        tshirt_size: tshirtSize,
        assigned_staff_id: assignedStaffId,
        birthday,
        client_journey_id: journeyId,
        address_1: address1,
        address_2: address2,
        city,
        state,
        postal_code: postalCode,
        internal_request: true,
        referrer_id: referrerId,
        current_product_campaign_id: initialCampaignId,
        gdpr_consent: gdpr,
        lead_source: leadSource
      }
    };

    const response = yield call(axiosDefault.post, '/contacts', params);
    window.location = `/ui/contacts/${response.data.id}`;
    yield put({
      type: CREATE_CONTACT_SUCCESS,
      payload: {
        contact: response.data
      }
    });
  } catch (e) {
    yield put({
      type: CREATE_CONTACT_FAILURE,
      payload: { error: e, fallbackError: 'Error while creating new contact.' },
      error: true
    });
  }
}

function* updateContact({
  payload: {
    id,
    firstName,
    lastName,
    email,
    assignedStaffId,
    journeyId,
    phone = '',
    gender = '',
    tshirtSize = '',
    birthday = '',
    address1 = '',
    address2 = '',
    city = '',
    state = '',
    postalCode = '',
    membershipStartedAt = '',
    referrerId = '',
    leadSource
  }
}) {
  try {
    const params = {
      contact: {
        id,
        first_name: firstName,
        last_name: lastName,
        email,
        phone,
        gender,
        tshirt_size: tshirtSize,
        assigned_staff_id: assignedStaffId,
        birthday,
        client_journey_id: journeyId,
        address_1: address1,
        address_2: address2,
        city,
        state,
        postal_code: postalCode,
        membership_started_at: membershipStartedAt,
        referrer_id: referrerId,
        lead_source: leadSource
      }
    };

    if (yield call(isDummyContact))
      return yield call(updateDummyContact, params);

    const response = yield call(axiosDefault.patch, `/contacts/${id}`, params);

    yield put({
      type: UPDATE_CONTACT_SUCCESS,
      payload: {
        contact: response.data
      }
    });
  } catch (e) {
    yield put({
      type: UPDATE_CONTACT_FAILURE,
      payload: { error: e, fallbackError: 'Error while updating contact.' },
      error: true
    });
  }
}

function* deleteContact({ payload: { contactId } }) {
  try {
    yield call(axiosDefault.delete, `/contacts/${contactId}`);

    yield put({
      type: DELETE_CONTACT_SUCCESS,
      payload: { data: true }
    });
  } catch (e) {
    yield put({
      type: DELETE_CONTACT_FAILURE,
      payload: {
        error: e,
        fallbackError: 'Contact cannot be deleted.'
      },
      error: true
    });
  }
}

function* unsubscribeContact({ payload: { contactId } }) {
  try {
    const response = yield call(
      axiosDefault.put,
      `/contacts/${contactId}/unsubscribe`
    );

    yield put({
      type: UNSUBSCRIBE_CONTACT_SUCCESS,
      payload: { contact: response.data }
    });
  } catch (e) {
    yield put({
      type: UNSUBSCRIBE_CONTACT_FAILURE,
      payload: {
        error: e,
        fallbackError: 'Contact cannot be unsubscribed.'
      },
      error: true
    });
  }
}

function* resubscribeContact({ payload: { contactId } }) {
  try {
    const response = yield call(
      axiosDefault.put,
      `/contacts/${contactId}/resubscribe`
    );

    yield put({
      type: RESUBSCRIBE_CONTACT_SUCCESS,
      payload: { contact: response.data }
    });
  } catch (e) {
    yield put({
      type: RESUBSCRIBE_CONTACT_FAILURE,
      payload: {
        error: e,
        fallbackError: 'Contact cannot be resubscribed.'
      },
      error: true
    });
  }
}

function* fetchContactAutomations({
  payload: { contactId, includeCompleted = true }
}) {
  try {
    const response = yield call(
      axiosDefault.get,
      `/automations?contact_id=${contactId}&include_completed=${includeCompleted}`
    );

    yield put({
      type: FETCH_CONTACT_AUTOMATIONS_SUCCESS,
      payload: {
        automations: response.data
      }
    });
  } catch (e) {
    yield put({
      type: FETCH_CONTACT_AUTOMATIONS_FAILURE,
      payload: {
        error: e
      },
      error: true
    });
  }
}

function* fetchContactCustomGroups({ payload: { contactId } }) {
  try {
    const response = yield call(
      axiosDefault.get,
      `/custom_groups?contact_id=${contactId}`
    );

    yield put({
      type: FETCH_CONTACT_CUSTOM_GROUPS_SUCCESS,
      payload: {
        customGroups: response.data
      }
    });
  } catch (e) {
    yield put({
      type: FETCH_CONTACT_CUSTOM_GROUPS_FAILURE,
      payload: {
        error: e
      },
      error: true
    });
  }
}

function* fetchCurrentContactCta({ payload: { contactId } }) {
  try {
    const response = yield call(
      axiosDefault.get,
      `/contacts/${contactId}/call_to_action`
    );

    yield put({
      type: FETCH_CURRENT_CONTACT_CTA_SUCCESS,
      payload: {
        currentContactCta: response.data
      }
    });
  } catch (e) {
    yield put({
      type: FETCH_CURRENT_CONTACT_CTA_FAILURE,
      payload: {
        error: e
      },
      error: true
    });
  }
}

function* validateContactImportCsv({
  payload: { mappedData, fileData, assignedStaffId, id }
}) {
  const params = {
    mapped_data: mappedData,
    file_data: fileData,
    assigned_staff_id: assignedStaffId,
    csv_import_id: id
  };
  try {
    const response = yield call(
      axiosDefault.post,
      '/csv_imports/validate_all',
      params
    );

    yield put({
      type: VALIDATE_CONTACTS_CSV_SUCCESS,
      payload: {
        importedContact: response?.data
      }
    });
  } catch (e) {
    yield put({
      type: VALIDATE_CONTACTS_CSV_FAILURE,
      payload: {
        error: e,
        fallbackError: 'Error validating the contacts csv.'
      },
      error: true
    });
  }
}

function* saveContactImportCsv({
  payload: { mappedData, fileData, csvImportId, status, importedContact }
}) {
  const params = {
    mapped_data: mappedData,
    file_data: fileData,
    status,
    csv_import_id: csvImportId,
    mapped_csv_rows: importedContact
  };
  try {
    const response = yield call(
      axiosDefault.put,
      '/csv_imports/handle_update',
      params
    );

    yield put({
      type: SAVE_CONTACTS_CSV_SUCCESS,
      payload: {
        csvImportData: response?.data
      }
    });
  } catch (e) {
    yield put({
      type: SAVE_CONTACTS_CSV_FAILURE,
      payload: {
        error: e,
        fallbackError: 'Error saving the contacts csv.'
      },
      error: true
    });
  }
}

function* createImport({
  payload: { file, assignedStaffId, lifecycle, campaign_id, gdpr }
}) {
  const formData = new FormData();
  formData.append(
    'csv_import[file]',
    new Blob([file], { type: file.type }),
    file.name
  );
  formData.append('csv_import[assigned_staff_ids][]', assignedStaffId);
  formData.append('csv_import[lifecycle]', lifecycle);
  formData.append('csv_import[product_campaign_id]', campaign_id);
  formData.append('csv_import[gdpr_compliant]', gdpr);
  try {
    const response = yield call(
      axiosMultipartForm.post,
      '/csv_imports',
      formData
    );

    yield put({
      type: CREATE_CSV_IMPORT_SUCCESS,
      payload: {
        csvImportData: response?.data
      }
    });
  } catch (e) {
    yield put({
      type: CREATE_CSV_IMPORT_FAILURE,
      payload: {
        error: e,
        fallbackError: 'Error creating the contacts csv.'
      },
      error: true
    });
  }
}

function* updateImport({
  payload: { assignedStaffId, lifecycle, campaign_id, csvImportId, gdpr }
}) {
  const formData = new FormData();
  formData.append('csv_import[assigned_staff_ids][]', assignedStaffId);
  formData.append('csv_import[lifecycle]', lifecycle);
  formData.append('csv_import[product_campaign_id]', campaign_id);
  formData.append('csv_import[gdpr_compliant]', gdpr);
  try {
    const response = yield call(
      axiosMultipartForm.put,
      `/csv_imports/${csvImportId}`,
      formData
    );

    yield put({
      type: UPDATE_CSV_IMPORT_SUCCESS,
      payload: {
        csvImportData: response?.data
      }
    });
  } catch (e) {
    yield put({
      type: UPDATE_CSV_IMPORT_FAILURE,
      payload: {
        error: e,
        fallbackError: 'Error updating the contacts csv.'
      },
      error: true
    });
  }
}

function* fetchImport({ payload: { id } }) {
  try {
    const response = yield call(axiosDefault.get, `/csv_imports/${id}`);

    yield put({
      type: FETCH_CSV_IMPORT_SUCCESS,
      payload: {
        csvImportData: response?.data
      }
    });
  } catch (e) {
    yield put({
      type: FETCH_CSV_IMPORT_FAILURE,
      payload: {
        error: e,
        fallbackError: 'Error creating the contacts csv.'
      },
      error: true
    });
  }
}

function* deleteImport({ payload: { currentImportId } }) {
  try {
    const response = yield call(
      axiosDefault.delete,
      `/csv_imports/${currentImportId}`
    );

    yield put({
      type: DELETE_CSV_IMPORT_SUCCESS,
      payload: {
        csvImportData: response?.data
      }
    });
  } catch (e) {
    yield put({
      type: DELETE_CSV_IMPORT_FAILURE,
      payload: {
        error: e,
        fallbackError: 'Error deleting the csv import.'
      },
      error: true
    });
  }
}

function* validateSingleContactImportCsv({
  payload: { data, assignedStaffId, activeIndex }
}) {
  const params = {
    contact: data,
    assigned_staff_id: assignedStaffId
  };
  try {
    const response = yield call(
      axiosDefault.post,
      '/csv_imports/validate_row',
      params
    );

    yield put({
      type: VALIDATE_SINGLE_CONTACT_CSV_SUCCESS,
      payload: {
        contact: response?.data,
        activeIndex
      }
    });
  } catch (e) {
    yield put({
      type: VALIDATE_SINGLE_CONTACT_CSV_FAILURE,
      payload: {
        error: e,
        fallbackError: 'Error validating the contacts csv.'
      },
      error: true
    });
  }
}

function* csvImportSchedule({
  payload: {
    mappedData,
    fileData,
    csvImportId,
    importedContact,
    scheduleForDateTime,
    importType
  }
}) {
  const params = {
    mapped_data: mappedData,
    file_data: fileData,
    csv_import_id: csvImportId,
    mapped_csv_rows: importedContact,
    schedule_for_date_time: scheduleForDateTime,
    import_type: importType
  };
  try {
    const response = yield call(
      axiosDefault.post,
      '/csv_imports/schedule',
      params
    );

    yield put({
      type: SCHEDULE_CSV_IMPORT_SUCCESS,
      payload: {
        contact: response?.data
      }
    });
  } catch (e) {
    yield put({
      type: SCHEDULE_CSV_IMPORT_FAILURE,
      payload: {
        error: e,
        fallbackError: 'Error scheduling the contacts csv import.'
      },
      error: true
    });
  }
}

function* fetchNextCampaignTouchpoint({ payload: { contactId } }) {
  try {
    const response = yield call(
      axiosDefault.get,
      `/contacts/${contactId}/next_campaign_touch_point`
    );

    yield put({
      type: FETCH_NEXT_CAMPAIGN_TOUCHPOINT_SUCCESS,
      payload: {
        nextCampaignTouchpoint: response.data
      }
    });
  } catch (e) {
    yield put({
      type: FETCH_NEXT_CAMPAIGN_TOUCHPOINT_FAILURE,
      payload: {
        error: e
      },
      error: true
    });
  }
}

function* fetchCurrentAutomationDetails({ payload: { automationId } }) {
  try {
    const response = yield call(
      axiosDefault.get,
      `automations/${automationId}/details`
    );

    yield put({
      type: FETCH_CURRENT_AUTOMATION_DETAILS_SUCCESS,
      payload: {
        currentAutomationDetails: response.data
      }
    });
  } catch (e) {
    yield put({
      type: FETCH_CURRENT_AUTOMATION_DETAILS_FAILURE,
      payload: {
        error: e
      },
      error: true
    });
  }
}

function* updateCurrentAutomationPause({
  payload: { automationId, pauseOption, xDays, untilXDate }
}) {
  let pauseOptionValue;
  if (pauseOption === 'x-days') {
    pauseOptionValue = xDays;
  } else if (pauseOption === 'until-x-date') {
    pauseOptionValue = untilXDate;
  }

  if (yield call(isDummyContact))
    return yield call(pauseUnpauseDummyAutomation, {
      automationId,
      action: 'pause'
    });

  try {
    const response = yield call(
      axiosDefault.put,
      `automations/${automationId}/pause?pause_option=${pauseOption}&pause_option_value=${pauseOptionValue}`
    );

    return yield put({
      type: PAUSE_AUTOMATION_SUCCESS,
      payload: {
        automation: response.data
      }
    });
  } catch (e) {
    return yield put({
      type: PAUSE_AUTOMATION_FAILURE,
      payload: {
        error: e
      },
      error: true
    });
  }
}

function* updateCurrentAutomationComplete({ payload: { automationId } }) {
  try {
    yield call(axiosDefault.put, `automations/${automationId}/complete`);

    yield put({
      type: UPDATE_CURRENT_AUTOMATION_COMPLETE_SUCCESS,
      payload: {
        automationId
      }
    });
  } catch (e) {
    yield put({
      type: UPDATE_CURRENT_AUTOMATION_COMPLETE_FAILURE,
      payload: {
        error: e
      },
      error: true
    });
  }
}

function* updateCurrentAutomationUnpause({ payload: { automationId } }) {
  try {
    if (yield call(isDummyContact))
      return yield call(pauseUnpauseDummyAutomation, {
        automationId,
        action: 'unpause'
      });

    const response = yield call(
      axiosDefault.put,
      `automations/${automationId}/unpause`
    );

    return yield put({
      type: UNPAUSE_AUTOMATION_SUCCESS,
      payload: {
        automation: response.data
      }
    });
  } catch (e) {
    return yield put({
      type: UNPAUSE_AUTOMATION_FAILURE,
      payload: {
        error: e
      },
      error: true
    });
  }
}

function* newAutomationLifecycleCheck({
  payload: { contactId, journeyId, newCampaign }
}) {
  try {
    const response = yield call(
      axiosDefault.get,
      `automations/create_confirmation?contact_id=${contactId}&journey_id=${journeyId}&campaign_id=${newCampaign}`
    );

    yield put({
      type: NEW_AUTOMATION_LIFECYCLE_CHECK_SUCCESS,
      payload: {
        addAutomationConfirmationMessage: response.data.message,
        newLifecycle: response.data.new_lifecycle,
        willChangeLifecycle: response.data.will_change_lifecycle,
        alreadyBeenInCampaign: response.data.already_been_in_campaign,
        automationsToBeCompletedIds:
          response.data.automations_to_be_completed_ids
      }
    });
  } catch (e) {
    yield put({
      type: NEW_AUTOMATION_LIFECYCLE_CHECK_FAILURE,
      payload: {
        error: e
      },
      error: true
    });
  }
}

function* fetchChangedCampaignList({ payload }) {
  let parameterizedString = '';
  Object.keys(payload).forEach(k => {
    parameterizedString += `${parameterizedString.length ? '&' : ''}${toSnake(
      k
    )}=${payload[k]}`;
  });
  try {
    const response = yield call(
      axiosDefault.get,
      `/product_campaigns?${parameterizedString}`
    );

    yield put({
      type: FETCH_CAMPAIGNS_LIST_SUCCESS,
      payload: {
        campaigns: response.data
      }
    });
  } catch (e) {
    yield put({
      type: FETCH_CAMPAIGNS_LIST_FAILURE,
      payload: {
        error: e
      },
      error: true
    });
  }
}

function* fetchAccountCampaigns() {
  try {
    const response = yield call(axiosDefault.get, `/campaigns`);

    yield put({
      type: FETCH_ACCOUNT_CAMPAIGNS_SUCCESS,
      payload: response.data
    });
  } catch (e) {
    yield put({
      type: FETCH_ACCOUNT_CAMPAIGNS_FAILURE,
      payload: { error: e, fallbackError: 'Error loading campaigns.' },
      error: true
    });
  }
}

function* fetchStepList({ payload: { productCampaignId } }) {
  try {
    const response = yield call(
      axiosDefault.get,
      `/product_campaign_steps?product_campaign_id=${productCampaignId}`
    );

    yield put({
      type: FETCH_CAMPAIGN_STEP_LIST_SUCCESS,
      payload: response.data
    });
  } catch (e) {
    yield put({
      type: FETCH_CAMPAIGN_STEP_LIST_FAILURE,
      payload: { error: e, fallbackError: 'Error loading campaign steps.' },
      error: true
    });
  }
}

function* newLifecycleSummaryConfirmation({
  payload: { contactId, journeyId, newLifecycle, selectedCampaign }
}) {
  try {
    const response = yield call(
      axiosDefault.get,
      `contacts/create_confirmation?contact_id=${contactId}&journey_id=${journeyId}&new_lifecycle=${newLifecycle}&campaign_to_add=${selectedCampaign}`
    );

    yield put({
      type: NEW_LIFECYCLE_CONFIRMATION_SUCCESS,
      payload: {
        newLifecycleConfirmationMessage: response.data.message,
        newLifecycle: response.data.new_lifecycle,
        willBeClient: response.data.will_be_client,
        automationsToBeCompletedIds: response.data.automationsToBeCompletedIds,
        campaignToAdd: response.data.campaign_to_add,
        alreadyBeenInCampaign: response.data.already_been_in_campaign
      }
    });
  } catch (e) {
    yield put({
      type: NEW_LIFECYCLE_CONFIRMATION_FAILURE,
      payload: {
        error: e
      },
      error: true
    });
  }
}

function* createNewAutomation({
  payload: {
    campaignStepId,
    campaignId,
    newLifecycle,
    markAsSale,
    automationsToBeCompletedIds,
    contactId,
    journeyId,
    restartCampaignOption
  } = {}
}) {
  const automation = {
    automation: {
      product_campaign_step_id: campaignStepId,
      restart_campaign_option: restartCampaignOption,
      product_campaign_id: campaignId,
      new_lifecycle: newLifecycle,
      mark_as_sale: markAsSale,
      automations_to_be_completed_ids: automationsToBeCompletedIds,
      contact_id: contactId,
      journey_id: journeyId
    }
  };

  if (yield call(isDummyContact))
    return yield call(createDummyAutomation, automation);

  try {
    const response = yield call(axiosDefault.post, '/automations', automation);
    yield put({
      type: CREATE_AUTOMATION_SUCCESS,
      payload: {
        newAutomation: response.data,
        automationsToBeCompletedIds,
        newLifecycle
      }
    });
  } catch (e) {
    yield put({
      type: CREATE_AUTOMATION_FAILURE,
      payload: { error: e, fallbackError: 'Error loading automation.' },
      error: true
    });
  }
}

function* updateContactLifecycle({
  payload: {
    contactId,
    journeyId,
    newLifecycle,
    markAsSale,
    selectedCampaign,
    automationsToBeCompletedIds,
    restartCampaignOption,
    campaignToAdd,
    campaignStepId,
    alreadyBeenInCampaign
  } = {}
}) {
  const params = {
    automation: {
      restart_campaign_option: restartCampaignOption,
      product_campaign_id:
        selectedCampaign !== 'no_campaign' ? selectedCampaign : null,
      new_lifecycle: newLifecycle,
      mark_as_sale: markAsSale,
      automations_to_be_completed_ids: automationsToBeCompletedIds,
      contact_id: contactId,
      journey_id: journeyId,
      already_been_in_campaign: alreadyBeenInCampaign,
      product_campaign_step_id: campaignStepId
    }
  };
  if (!isEmpty(campaignToAdd)) {
    params.automation.campaign_to_add = campaignToAdd.id;
  }
  try {
    const response = yield call(
      axiosDefault.put,
      `contacts/update_lifecycle?contact_id=${contactId}`,
      params
    );
    yield put({
      type: UPDATE_CONTACT_LIFECYCLE_SUCCESS,
      payload: {
        newAutomation: response.data,
        automationsToBeCompletedIds,
        newLifecycle
      }
    });
  } catch (e) {
    yield put({
      type: UPDATE_CONTACT_LIFECYCLE_FAILURE,
      payload: { error: e },
      error: true
    });
  }
}

function* createContactNote({ payload: { note, contactId } }) {
  if (yield call(isDummyContact))
    return yield call(createDummyNote, { note, contactId });

  try {
    const params = {
      note: { note }
    };

    const response = yield call(
      axiosDefault.post,
      `/contacts/${contactId}/create_note`,
      params
    );

    yield put({
      type: CREATE_CONTACT_NOTE_SUCCESS,
      payload: {
        note: response.data
      }
    });
  } catch (e) {
    yield put({
      type: CREATE_CONTACT_NOTE_FAILURE,
      payload: { error: e },
      error: true
    });
  }
}

function* fetchAppointmentTypes() {
  try {
    const response = yield call(axiosDefault.get, `/appointment_types`);

    yield put({
      type: FETCH_APPOINTMENT_TYPES_SUCCESS,
      payload: response.data
    });
  } catch (e) {
    yield put({
      type: FETCH_APPOINTMENT_TYPES_FAILURE,
      payload: { error: e, fallbackError: 'Error loading appointment types.' },
      error: true
    });
  }
}

function* fetchUploadedFiles({ payload: { contactId } = {} }) {
  try {
    const response = yield call(
      axiosDefault.get,
      `/attachments?contact_id=${contactId}`
    );
    yield put({
      type: FETCH_UPLOADED_FILES_LIST_SUCCESS,
      payload: response.data
    });
  } catch (e) {
    yield put({
      type: FETCH_UPLOADED_FILES_LIST_FAILURE,
      payload: { error: e, fallbackError: 'Error loading uploaded file list.' },
      error: true
    });
  }
}

function* createEmailRequest({
  payload: { contactId, emailSubject, emailBody }
}) {
  const isDummy = yield call(isDummyContact);

  const dummyComplianceFooter =
    '<br><br><br>\n<small>\nThis email was sent to you because you have opted in for communications with {Account Name}.\n<br>If you would like to unsubscribe, you can do so by clicking here at any time.<br>{Account Address}.\n</small>\n';
  const realComplianceFooter = '{{ unbranded_compliance_footer.block }}';
  const styledEmailBody = `<div style='vertical-align:top;min-width:320px;max-width:500px;margin:0 auto;background-color:#FFFFFF;width:100%;padding:5px 25px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;'>${emailBody} ${
    isDummy ? dummyComplianceFooter : realComplianceFooter
  }</div>`;

  if (isDummy)
    return yield call(createContactEmail, {
      contactId,
      subject: emailSubject,
      body: styledEmailBody
    });

  const params = {
    contact_id: contactId,
    subject: emailSubject,
    body: styledEmailBody
  };
  try {
    const response = yield call(axiosDefault.post, `/emails`, params);

    yield put({
      type: CREATE_EMAIL_SUCCESS,
      payload: {
        data: response.data
      }
    });
  } catch (e) {
    yield put({
      type: CREATE_EMAIL_FAILURE,
      payload: { error: e, fallbackError: 'Error sending email.' },
      error: true
    });
  }
}

export function* createNote() {
  yield takeLatest(CREATE_CONTACT_NOTE_REQUEST, createContactNote);
}

function* createDocumentFileUpload({ payload: { fileParams, contactId } }) {
  try {
    const formData = new FormData();
    formData.append('contact_id', contactId);
    formData.append('attachment[file]', fileParams.file[0]);
    formData.append('attachment[owner_type]', 'Contact');
    formData.append('attachment[owner_id]', contactId);

    if (yield call(isDummyContact))
      return yield call(uploadDummyDocument, { fileParams, contactId });

    const response = yield call(
      axiosMultipartForm.post,
      `/attachments`,
      formData
    );
    yield put({
      type: CREATE_FILE_UPLOAD_SUCCESS,
      payload: {
        file: response.data
      }
    });
  } catch (e) {
    yield put({
      type: CREATE_FILE_UPLOAD_FAILURE,
      payload: { error: e, fallbackError: 'Error Uploading File.' },
      error: true
    });
  }
}

function* removeSpamComplaint({ payload: { currentContactId } }) {
  try {
    const response = yield call(
      axiosDefault.put,
      `/contacts/${currentContactId}/remove_spam_complaint`
    );

    yield put({
      type: REMOVE_SPAM_COMPLAINT_SUCCESS,
      payload: { contact: response.data }
    });
  } catch (e) {
    yield put({
      type: REMOVE_SPAM_COMPLAINT_FAILURE,
      payload: { error: e, fallbackError: 'Error removing spam complaint' },
      error: true
    });
  }
}

function* fetchImportedHistory() {
  try {
    const response = yield call(axiosDefault.get, `/csv_imports/history`);

    yield put({
      type: FETCH_IMPORT_HISTORY_SUCCESS,
      payload: { importHistory: response.data }
    });
  } catch (e) {
    yield put({
      type: FETCH_IMPORT_HISTORY_FAILURE,
      payload: { error: e, fallbackError: 'Error fetching import history' },
      error: true
    });
  }
}

function* fetchNextAppointment({ payload: { contactId } }) {
  try {
    const response = yield call(
      axiosDefault.get,
      `/contacts/${contactId}/next_appointment`
    );

    yield put({
      type: FETCH_NEXT_APPOINTMENT_SUCCESS,
      payload: { nextAppointment: response.data }
    });
  } catch (e) {
    yield put({
      type: FETCH_NEXT_APPOINTMENT_FAILURE,
      payload: { error: e, fallbackError: 'Error fetching next appointment' },
      error: true
    });
  }
}

function* fetchNextTask({ payload: { contactId } }) {
  try {
    const response = yield call(
      axiosDefault.get,
      `/contacts/${contactId}/next_task`
    );

    yield put({
      type: FETCH_NEXT_TASK_SUCCESS,
      payload: { nextTask: response.data }
    });
  } catch (e) {
    yield put({
      type: FETCH_NEXT_TASK_FAILURE,
      payload: { error: e, fallbackError: 'Error fetch next task' },
      error: true
    });
  }
}

export function* createFileUpload() {
  yield takeLatest(CREATE_FILE_UPLOAD_REQUEST, createDocumentFileUpload);
}

export function* contactAutomationsFetch() {
  yield takeLatest(FETCH_CONTACT_AUTOMATIONS_REQUEST, fetchContactAutomations);
}

export function* contactFetch() {
  yield takeLatest(FETCH_CONTACT_REQUEST, fetchContact);
}

export function* contactCustomGroupsFetch() {
  yield takeLatest(
    FETCH_CONTACT_CUSTOM_GROUPS_REQUEST,
    fetchContactCustomGroups
  );
}

export function* contactCustomGroupsUpdate() {
  yield takeLatest(
    UPDATE_CONTACT_CUSTOM_GROUPS_REQUEST,
    updateContactCustomGroups
  );
}

export function* currentContactCtaFetch() {
  yield takeLatest(FETCH_CURRENT_CONTACT_CTA_REQUEST, fetchCurrentContactCta);
}

export function* validateContactsCsv() {
  yield takeLatest(VALIDATE_CONTACTS_CSV_REQUEST, validateContactImportCsv);
}

export function* saveContactsCsv() {
  yield takeLatest(SAVE_CONTACTS_CSV_REQUEST, saveContactImportCsv);
}

export function* createCsvImport() {
  yield takeLatest(CREATE_CSV_IMPORT_REQUEST, createImport);
}

export function* updateCsvImport() {
  yield takeLatest(UPDATE_CSV_IMPORT_REQUEST, updateImport);
}

export function* deleteCsvImport() {
  yield takeLatest(DELETE_CSV_IMPORT_REQUEST, deleteImport);
}

export function* fetchCsvImport() {
  yield takeLatest(FETCH_CSV_IMPORT_REQUEST, fetchImport);
}

export function* validateSingleContactCsv() {
  yield takeLatest(
    VALIDATE_SINGLE_CONTACT_CSV_REQUEST,
    validateSingleContactImportCsv
  );
}

export function* scheduleCsvImport() {
  yield takeLatest(SCHEDULE_CSV_IMPORT_REQUEST, csvImportSchedule);
}

export function* nextCampaignTouchpointFetch() {
  yield takeLatest(
    FETCH_NEXT_CAMPAIGN_TOUCHPOINT_REQUEST,
    fetchNextCampaignTouchpoint
  );
}

export function* currentAutomationDetailsFetch() {
  yield takeLatest(
    FETCH_CURRENT_AUTOMATION_DETAILS_REQUEST,
    fetchCurrentAutomationDetails
  );
}

export function* currentAutomationPauseUpdate() {
  yield takeLatest(PAUSE_AUTOMATION_REQUEST, updateCurrentAutomationPause);
}

export function* currentAutomationUnpauseUpdate() {
  yield takeLatest(UNPAUSE_AUTOMATION_REQUEST, updateCurrentAutomationUnpause);
}

export function* currentAutomationCompleteUpdate() {
  yield takeLatest(
    UPDATE_CURRENT_AUTOMATION_COMPLETE_REQUEST,
    updateCurrentAutomationComplete
  );
}

export function* lifecycleCheckNewAutomation() {
  yield takeLatest(
    NEW_AUTOMATION_LIFECYCLE_CHECK_REQUEST,
    newAutomationLifecycleCheck
  );
}

export function* getCampaigns() {
  yield takeLatest(FETCH_ACCOUNT_CAMPAIGNS_REQUEST, fetchAccountCampaigns);
}

export function* getSteps() {
  yield takeLatest(FETCH_CAMPAIGN_STEP_LIST_REQUEST, fetchStepList);
}

export function* createAutomation() {
  yield takeLatest(CREATE_AUTOMATION_REQUEST, createNewAutomation);
}

export function* campaignsToBeCompletedFetch() {
  yield takeLatest(FETCH_CAMPAIGNS_LIST_REQUEST, fetchChangedCampaignList);
}

export function* confirmLifecycleMessage() {
  yield takeLatest(
    NEW_LIFECYCLE_CONFIRMATION_REQUEST,
    newLifecycleSummaryConfirmation
  );
}

export function* contactLifecycleUpdate() {
  yield takeLatest(UPDATE_CONTACT_LIFECYCLE_REQUEST, updateContactLifecycle);
}

export function* contactCreate() {
  yield takeLatest(CREATE_CONTACT_REQUEST, createContact);
}

export function* contactUpdate() {
  yield takeLatest(UPDATE_CONTACT_REQUEST, updateContact);
}

export function* appointmentTypesFetch() {
  yield takeLatest(FETCH_APPOINTMENT_TYPES_REQUEST, fetchAppointmentTypes);
}

export function* uploadedFileListFetch() {
  yield takeLatest(FETCH_UPLOADED_FILES_LIST_REQUEST, fetchUploadedFiles);
}

export function* newContactProfileEnable() {
  yield takeLatest(ENABLE_NEW_CONTACT_PROFILE_REQUEST, enableNewProfile);
}

export function* createEmailCommunication() {
  yield takeLatest(CREATE_EMAIL_REQUEST, createEmailRequest);
}

export function* contactDelete() {
  yield takeLatest(DELETE_CONTACT_REQUEST, deleteContact);
}

export function* contactUnsubscribe() {
  yield takeLatest(UNSUBSCRIBE_CONTACT_REQUEST, unsubscribeContact);
}

export function* contactResubscribe() {
  yield takeLatest(RESUBSCRIBE_CONTACT_REQUEST, resubscribeContact);
}

export function* removeSpamComplaintFromContact() {
  yield takeLatest(REMOVE_SPAM_COMPLAINT_REQUEST, removeSpamComplaint);
}

export function* fetchImportHistory() {
  yield takeLatest(FETCH_IMPORT_HISTORY_REQUEST, fetchImportedHistory);
}

export function* fetchContactsNextAppointment() {
  yield takeLatest(FETCH_NEXT_APPOINTMENT_REQUEST, fetchNextAppointment);
}

export function* fetchContactsNextTask() {
  yield takeLatest(FETCH_NEXT_TASK_REQUEST, fetchNextTask);
}
