import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from 'components/Theme/Modal';
import {
  markAppointmentNoShow,
  resetIsLoaded,
  fetchAppointmentsActivities,
  fetchActivities,
  fetchNextAppointment
} from 'appState/actions/ActionCreators';
import TextArea from 'components/Theme/TextArea';
import { formatTimestamp } from 'lib/utils/dateTime';
import getUserName from 'lib/utils/getUserName';
import { CurrentAccountContext } from 'components/shared/context/CurrentAccountContext';
import {
  createErrorMessageSelector,
  createLoadedSelector
} from 'appState/selectors';
import { MARK_APPOINTMENT_NO_SHOW } from 'app-state/actions/constants/activity.actions';
import { createStructuredSelector } from 'reselect';
import isEmpty from 'lodash.isempty';

const AppointmentNoShowModal = ({ appointment, show, setShow }) => {
  const dispatch = useDispatch();
  const currentAccount = useContext(CurrentAccountContext);
  const [notes, setNotes] = useState('');

  const errorSelector = createErrorMessageSelector([MARK_APPOINTMENT_NO_SHOW]);

  const updateAppointmentLoadingSelector = createLoadedSelector([
    MARK_APPOINTMENT_NO_SHOW
  ]);

  const structuredSelector = createStructuredSelector({
    selectedTab: state => state.contact.currentContactTab,
    currentContact: state => state.contact.currentContact,
    isNoShowAppointmentLoaded: state => updateAppointmentLoadingSelector(state),
    error: state => errorSelector(state)
  });

  const {
    selectedTab,
    currentContact,
    isNoShowAppointmentLoaded,
    error
  } = useSelector(structuredSelector);

  const activityParams = {
    accountId: currentAccount.id,
    contactId: currentContact?.id
  };

  useEffect(() => {
    if (isNoShowAppointmentLoaded && isEmpty(error)) {
      if (selectedTab === 'feed') {
        dispatch(fetchActivities(activityParams));
      } else {
        dispatch(fetchAppointmentsActivities(activityParams));
      }
      dispatch(fetchNextAppointment({ contactId: currentContact.id }));
    }

    if (isNoShowAppointmentLoaded) {
      dispatch(resetIsLoaded(MARK_APPOINTMENT_NO_SHOW));
    }

    return () => {
      if (isNoShowAppointmentLoaded) {
        dispatch(resetIsLoaded(MARK_APPOINTMENT_NO_SHOW));
      }
    };
  }, [error, isNoShowAppointmentLoaded, dispatch]);

  function handleNoShowAppointmentAction() {
    dispatch(
      markAppointmentNoShow({ appointmentId: appointment.sourceable_id, notes })
    );
  }

  const changeNotesValue = e => {
    setNotes(e.target.value);
  };

  return (
    <Modal
      headerText="Mark this as No-show?"
      primaryAction={handleNoShowAppointmentAction}
      primaryActionText="Yes"
      secondaryAction={() => {
        setShow(false);
      }}
      secondaryActionText="No"
      showSecondaryAction
      show={show}
      setShow={setShow}
      color="alpha"
      HeaderIcon={color => {
        return (
          <svg
            className={`tw-h-6 tw-w-6 tw-text-${color}-600`}
            stroke="currentColor"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
            />
          </svg>
        );
      }}
    >
      <p className="tw-mb-5">
        <span className="tw-font-medium">Appointment Type:</span>{' '}
        {appointment.appointment_type}
        <br />
        <span className="tw-font-medium">Scheduled For:</span>{' '}
        {formatTimestamp(
          appointment.appointment_starts_at,
          currentAccount.time_zone,
          false,
          'MMMM Do [at] h:mm A',
          true
        )}{' '}
        with {getUserName(appointment)}
        <br />
        <span className="tw-font-medium">Created At:</span>{' '}
        {formatTimestamp(
          appointment.created_at,
          currentAccount.time_zone,
          false,
          'MMMM Do [at] h:mm A',
          true
        )}
      </p>
      <div>
        <TextArea
          id="notes"
          rows={5}
          value={notes}
          labelText="Notes"
          hideLabel
          placeholder="Add Notes"
          onChange={changeNotesValue}
          onBlur={changeNotesValue}
        />
      </div>
    </Modal>
  );
};

const userObjectShape = {
  first_name: PropTypes.string,
  last_name: PropTypes.string
};

const appointmentObjectShape = {
  sourceable_id: PropTypes.number,
  appointment_type: PropTypes.string,
  appointment_starts_at: PropTypes.string,
  created_at: PropTypes.string,
  user: PropTypes.shape(userObjectShape)
};

AppointmentNoShowModal.defaultProps = {
  appointment: {},
  show: false,
  setShow: () => null
};

AppointmentNoShowModal.propTypes = {
  appointment: PropTypes.shape(appointmentObjectShape),
  show: PropTypes.bool,
  setShow: PropTypes.func
};

export default AppointmentNoShowModal;
