/* global App */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const ActionCable = props => {
  const [cableSubscription, setCableSubscription] = useState(null);
  const {
    channel,
    children,
    onReceived,
    onInitialized,
    onConnected,
    onDisconnected,
    onRejected
  } = props;

  useEffect(() => {
    // Connect/subscribe to existing App.cable
    const cableSub = App.cable.subscriptions.create(channel, {
      received: data => {
        return onReceived && onReceived(data);
      },
      initialized: () => {
        return onInitialized && onInitialized();
      },
      connected: () => {
        // eslint-disable-next-line no-console
        console.log('sms inbox api connected');
        return onConnected && onConnected();
      },
      disconnected: () => {
        return onDisconnected && onDisconnected();
      },
      rejected: () => {
        return onRejected && onRejected();
      }
    });
    setCableSubscription(cableSub);

    return () => {
      if (cableSubscription) {
        setCableSubscription(null);
      }
    };
  }, []);

  // eslint-disable-next-line no-unused-vars
  function send(data) {
    if (!cableSubscription) {
      throw new Error('ActionCable component unloaded');
    }

    cableSubscription.send(data);
  }

  // eslint-disable-next-line no-unused-vars
  function perform(action, data) {
    if (!cableSubscription) {
      throw new Error('ActionCable component unloaded');
    }

    cableSubscription.perform(action, data);
  }

  return children || null;
};

ActionCable.defaultProps = {
  onReceived: null,
  onInitialized: null,
  onConnected: null,
  onDisconnected: null,
  onRejected: null,
  children: null
};

ActionCable.propTypes = {
  channel: PropTypes.objectOf(PropTypes.any).isRequired,
  onReceived: PropTypes.func,
  onInitialized: PropTypes.func,
  onConnected: PropTypes.func,
  onDisconnected: PropTypes.func,
  onRejected: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.node)
  ])
};

export default ActionCable;
