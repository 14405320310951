import React, { useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useFormik } from 'formik';
import {
  createErrorMessageSelector,
  createLoadingSelector,
  createLoadedSelector
} from 'appState/selectors';
import {
  fetchAppointmentsActivities,
  createAppointmentFromBooker,
  updateAppointment
} from 'appState/actions/ActionCreators';
import isEmpty from 'lodash.isempty';
import Button from 'components/Theme/Button';
import timeStringsByIncrement from 'lib/utils/timeStringsByIncrement';
import DatePicker from 'components/Theme/DatePicker';
import {
  appointmentCreateSchema,
  appointmentUpdateSchema
} from 'lib/validation/schema';
import Select from 'components/Theme/Select';
import { UsersContext } from 'components/shared/context/UsersContext';
import { CurrentAccountContext } from 'components/shared/context/CurrentAccountContext';
import { buildDateTimeWithTimezone, getPreviousDay } from 'lib/utils/dateTime';
import { toSnake } from 'lib/utils/string';

const ManualOverrideTab = () => {
  const currentAccount = useContext(CurrentAccountContext);
  const dispatch = useDispatch();

  const errorSelector = createErrorMessageSelector([
    'appointment_booker/CREATE_APPOINTMENT_FROM_BOOKER'
  ]);
  const loadingSelector = createLoadingSelector([
    'appointment_booker/CREATE_APPOINTMENT_FROM_BOOKER',
    'appointment_booker/UPDATE_APPOINTMENT'
  ]);
  const loadedSelector = createLoadedSelector([
    'appointment_booker/CREATE_APPOINTMENT_FROM_BOOKER',
    'appointment_booker/UPDATE_APPOINTMENT'
  ]);
  const structuredSelector = createStructuredSelector({
    appointmentTypes: state => state.contact.appointmentTypes,
    currentAppointmentTypeId: state => state.contact.currentAppointmentTypeId,
    currentAppointment: state => state.appointmentBooker.currentAppointment,
    currentContact: state => state.contact.currentContact,
    error: state => errorSelector(state),
    isLoading: state => loadingSelector(state),
    isLoaded: state => loadedSelector(state)
  });

  const {
    appointmentTypes,
    currentAppointmentTypeId,
    currentAppointment,
    currentContact,
    error,
    isLoading,
    isLoaded
  } = useSelector(structuredSelector);

  const appointmentTypeOptions = appointmentTypes.map(apptType => {
    return { displayName: apptType.name, value: apptType.id };
  });

  const { users, isUsersLoaded, usersError } = useContext(UsersContext);

  const userSelectOptions = users.map(user => {
    return { displayName: user.full_name, value: user.id };
  });

  const appointmentTimeOptions = timeStringsByIncrement('05:00', 15, 'm').map(
    time => {
      return { displayName: time, value: time };
    }
  );

  function handleSubmit(values) {
    const appointmentParams = {
      appointment: {
        contact_id: currentContact.id
      },
      account_id: currentAccount.id,
      override: true,
      client_facing: false
    };
    if (Object.keys(values).length)
      Object.keys(values).forEach(function assignParams(key) {
        appointmentParams.appointment[toSnake(key)] = values[key];
      });
    appointmentParams.appointment.starts_at = buildDateTimeWithTimezone(
      appointmentParams.appointment.appointment_date,
      appointmentParams.appointment.appointment_time,
      currentAccount.time_zone
    );
    if (isEmpty(currentAppointment)) {
      dispatch(createAppointmentFromBooker({ appointmentParams }));
    } else {
      appointmentParams.appointment_id = currentAppointment.id;
      delete appointmentParams.appointment.appointment_type_id;
      dispatch(updateAppointment({ appointmentParams }));
    }
  }

  const formik = useFormik({
    initialValues: {
      appointmentTypeId: currentAppointmentTypeId,
      userId: '',
      appointmentDate: '',
      appointmentTime: appointmentTimeOptions[0].value
    },
    enableReinitialize: true,
    validationSchema: isEmpty(currentAppointment)
      ? appointmentCreateSchema
      : appointmentUpdateSchema,
    onSubmit: values => {
      handleSubmit(values);
    }
  });

  function handleAppointmentDateChange(updatedValue) {
    formik.setFieldValue('appointmentDate', updatedValue);
  }

  useEffect(() => {
    if (isLoaded && isEmpty(error)) {
      dispatch(
        fetchAppointmentsActivities({
          contactId: currentContact.id
        })
      );
      formik.setFieldValue('appointmentTypeId', '');
      formik.setFieldValue('userId', '');
      formik.setFieldValue('appointmentDate', '');
      formik.setFieldValue('appointmentTime', '');
      formik.setTouched({});
    }
    if (isLoaded && !isEmpty(error)) {
      formik.resetForm({
        values: {
          appointmentTypeId: '',
          userId: '',
          appointmentDate: '',
          appointmentTime: ''
        }
      });
    }
  }, [error, isLoaded, dispatch]);

  return (
    <div className="">
      <div className="tw-hidden sm:tw-block">
        <div className="tw-py-5">
          <div className="tw-border-t tw-border-b-0 tw-border-l-0 tw-border-r-0 tw-border-solid tw-border-gray-200" />
        </div>
      </div>
      <div className="tw-grid tw-grid-cols-12 tw-gap-6">
        {isEmpty(currentAppointment) ? (
          <Select
            id="appointment_type_id"
            value={formik.values.appointmentTypeId}
            options={appointmentTypeOptions}
            labelText="Appointment Type"
            placeholder="Select Appointment Type"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            showError={
              formik.touched.appointmentTypeId &&
              !!formik.errors.appointmentTypeId
            }
            error={formik.errors.appointmentTypeId}
            widthClass="tw-col-span-12 xs:tw-col-span-6"
          />
        ) : null}
        <Select
          id="user_id"
          value={formik.values.userId}
          options={isUsersLoaded && !usersError ? userSelectOptions : []}
          labelText="Staff Member"
          placeholder="Select Staff Member"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          showError={formik.touched.userId && !!formik.errors.userId}
          error={formik.errors.userId}
          widthClass="tw-col-span-12 sm:tw-col-span-6"
        />
        <DatePicker
          id="appointment_date"
          value={formik.values.appointmentDate}
          labelText="Appointment Date"
          onChange={handleAppointmentDateChange}
          onBlur={formik.handleBlur}
          showError={
            formik.touched.appointmentDate && !!formik.errors.appointmentDate
          }
          error={formik.errors.appointmentDate}
          widthClass="tw-col-span-12 xs:tw-col-span-6"
          minDate={getPreviousDay()}
        />
        <Select
          id="appointment_time"
          value={formik.values.appointmentTime}
          options={appointmentTimeOptions}
          labelText="Appointment Time"
          placeholder="Select Appointment Time"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          showError={
            formik.touched.appointmentTime && !!formik.errors.appointmentTime
          }
          error={formik.errors.appointmentTime}
          widthClass="tw-col-span-12 sm:tw-col-span-6"
        />
        <div className="tw-col-span-12">
          <Button
            color="alpha"
            containerStyle={{
              marginBottom: '5px',
              float: 'right'
            }}
            isLoaded
            isLoading={isLoading}
            onClick={formik.handleSubmit}
            text="Book It!"
            type="primary"
          />
        </div>
      </div>
    </div>
  );
};

export default ManualOverrideTab;
