export const FETCH_CONTACT_FORM_TYPES_REQUEST =
  'contactFormTypes/FETCH_CONTACT_FORM_TYPES_REQUEST';
export const FETCH_CONTACT_FORM_TYPES_SUCCESS =
  'contactFormTypes/FETCH_CONTACT_FORM_TYPES_SUCCESS';
export const FETCH_CONTACT_FORM_TYPES_FAILURE =
  'contactFormTypes/FETCH_CONTACT_FORM_TYPES_FAILURE';

export const FETCH_CONTACT_FORM_TYPE_REQUEST =
  'contactFormTypes/FETCH_CONTACT_FORM_TYPE_REQUEST';
export const FETCH_CONTACT_FORM_TYPE_SUCCESS =
  'contactFormTypes/FETCH_CONTACT_FORM_TYPE_SUCCESS';
export const FETCH_CONTACT_FORM_TYPE_FAILURE =
  'contactFormTypes/FETCH_CONTACT_FORM_TYPE_FAILURE';
