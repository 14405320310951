import React from 'react';
import PropTypes from 'prop-types';

const SummarizedView = ({ title, steps }) => {
  return (
    <div className="tw-flex tw-flex-col tw-mx-auto tw-mb-10 tw-w-11/12">
      <table className="tw-min-w-full tw-min-h-100 table-fixed">
        <tbody>
          <tr
            key={title}
            className="tw-font-body tw-font-medium tw-text-lg tw-border-b tw-border-t-0 tw-border-l-0 tw-border-r-0 tw-border-solid tw-border-gray-200 tw-text-black"
          >
            <td className="tw-py-2">{title}</td>
          </tr>
          {steps.map(step => {
            return (
              <tr
                key={step.title}
                className="tw-font-body tw-font-medium tw-text-sm tw-border-b tw-border-t-0 tw-border-l-0 tw-border-r-0 tw-border-solid tw-border-gray-200 tw-text-gray-500 tw-align-top"
              >
                <td className="tw-w-1/4 tw-py-5">{step.title}</td>
                <td className="tw-w-1/2 tw-text-gray-800 tw-py-5">
                  {step.value}
                </td>
                <td />
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

SummarizedView.defaultProps = {
  title: '',
  steps: []
};

SummarizedView.propTypes = {
  title: PropTypes.string,
  steps: PropTypes.array
};

export default SummarizedView;
