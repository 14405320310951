import { all } from 'redux-saga/effects';
import * as smsSagas from './SmsInbox';
import * as overlordUtilitySagas from './OverlordUtilities';
import * as broadcastCenterSagas from './BroadcastCenter';
import * as purchaseSagas from './Purchase';
import * as planSagas from './Plan';
import * as syncWizardSagas from './SyncWizard';
import * as subscriptionSagas from './Subscription';
import * as appointmentBookerSagas from './AppointmentBooker';
import * as contactSagas from './Contact';
import * as taskSagas from './Task';
import * as contactFormTypeSagas from './ContactFormType';
import * as contactFormSagas from './ContactForm';
import * as activitySagas from './Activity';
import * as marketPlaceSagas from './MarketPlace';
import * as productSagas from './Product';
import * as productSubscriptionSagas from './ProductSubscription';
import * as journeySagas from './Journey';
import * as autoCompleteSagas from './AutoComplete';
import * as blogSagas from './Blog';
import * as optInFormSagas from './OptInForm';
import * as partnerSagas from './Partner';
import * as backGroundJob from './BackGroundJob';
import * as appointmentTypeSagas from './AppointmentType';
import * as zenPlannerSyncSagas from './ZenPlannerSync';
import * as leadSourceSagas from './LeadSource';

export default function* rootSaga() {
  yield all([
    ...Object.keys(smsSagas).map(func => smsSagas[func]()),
    ...Object.keys(overlordUtilitySagas).map(func =>
      overlordUtilitySagas[func]()
    ),
    ...Object.keys(broadcastCenterSagas).map(func =>
      broadcastCenterSagas[func]()
    ),
    ...Object.keys(purchaseSagas).map(func => purchaseSagas[func]()),
    ...Object.keys(planSagas).map(func => planSagas[func]()),

    ...Object.keys(appointmentBookerSagas).map(func =>
      appointmentBookerSagas[func]()
    ),
    ...Object.keys(contactSagas).map(func => contactSagas[func]()),
    ...Object.keys(taskSagas).map(func => taskSagas[func]()),
    ...Object.keys(contactFormTypeSagas).map(func =>
      contactFormTypeSagas[func]()
    ),
    ...Object.keys(activitySagas).map(func => activitySagas[func]()),
    ...Object.keys(contactFormSagas).map(func => contactFormSagas[func]()),
    ...Object.keys(syncWizardSagas).map(func => syncWizardSagas[func]()),
    ...Object.keys(subscriptionSagas).map(func => subscriptionSagas[func]()),
    ...Object.keys(marketPlaceSagas).map(func => marketPlaceSagas[func]()),
    ...Object.keys(productSagas).map(func => productSagas[func]()),
    ...Object.keys(productSubscriptionSagas).map(func =>
      productSubscriptionSagas[func]()
    ),
    ...Object.keys(journeySagas).map(func => journeySagas[func]()),
    ...Object.keys(autoCompleteSagas).map(func => autoCompleteSagas[func]()),
    ...Object.keys(blogSagas).map(func => blogSagas[func]()),
    ...Object.keys(optInFormSagas).map(func => optInFormSagas[func]()),
    ...Object.keys(partnerSagas).map(func => partnerSagas[func]()),
    ...Object.keys(backGroundJob).map(func => backGroundJob[func]()),
    ...Object.keys(appointmentTypeSagas).map(func =>
      appointmentTypeSagas[func]()
    ),
    ...Object.keys(zenPlannerSyncSagas).map(func =>
      zenPlannerSyncSagas[func]()
    ),
    ...Object.keys(leadSourceSagas).map(func => leadSourceSagas[func]())
  ]);
}
