import { call, put, takeLatest } from 'redux-saga/effects';
import isEmpty from 'lodash.isempty';
import { axiosDefault } from 'lib/utils/axios-setup';
import {
  FETCH_PLANS_REQUEST,
  FETCH_PLANS_SUCCESS,
  FETCH_PLANS_FAILURE,
  FETCH_PLAN_PAGE_REQUEST,
  FETCH_PLAN_PAGE_SUCCESS,
  FETCH_PLAN_PAGE_FAILURE,
  CREATE_PLAN_REQUEST,
  CREATE_PLAN_SUCCESS,
  CREATE_PLAN_FAILURE,
  UPDATE_PLAN_REQUEST,
  UPDATE_PLAN_SUCCESS,
  UPDATE_PLAN_FAILURE,
  DELETE_PLAN_REQUEST,
  DELETE_PLAN_SUCCESS,
  DELETE_PLAN_FAILURE,
  AVAILABLE_PLAN_REQUEST,
  AVAILABLE_PLAN_SUCCESS,
  AVAILABLE_PLAN_FAILURE,
  FETCH_SMS_CREDIT_PACKS_REQUEST,
  FETCH_SMS_CREDIT_PACKS_SUCCESS,
  FETCH_SMS_CREDIT_PACKS_FAILURE,
  FETCH_SMS_CREDIT_PACK_REQUEST,
  FETCH_SMS_CREDIT_PACK_SUCCESS,
  FETCH_SMS_CREDIT_PACK_FAILURE,
  CREATE_SMS_CREDIT_PACK_REQUEST,
  CREATE_SMS_CREDIT_PACK_SUCCESS,
  CREATE_SMS_CREDIT_PACK_FAILURE,
  UPDATE_SMS_CREDIT_PACK_REQUEST,
  UPDATE_SMS_CREDIT_PACK_SUCCESS,
  UPDATE_SMS_CREDIT_PACK_FAILURE,
  DELETE_SMS_CREDIT_PACK_REQUEST,
  DELETE_SMS_CREDIT_PACK_SUCCESS,
  DELETE_SMS_CREDIT_PACK_FAILURE,
  FETCH_ONBOARDING_FEES_REQUEST,
  FETCH_ONBOARDING_FEES_SUCCESS,
  FETCH_ONBOARDING_FEES_FAILURE,
  FETCH_ONBOARDING_FEE_REQUEST,
  FETCH_ONBOARDING_FEE_SUCCESS,
  FETCH_ONBOARDING_FEE_FAILURE,
  CREATE_ONBOARDING_FEE_REQUEST,
  CREATE_ONBOARDING_FEE_SUCCESS,
  CREATE_ONBOARDING_FEE_FAILURE,
  UPDATE_ONBOARDING_FEE_REQUEST,
  UPDATE_ONBOARDING_FEE_SUCCESS,
  UPDATE_ONBOARDING_FEE_FAILURE,
  DELETE_ONBOARDING_FEE_REQUEST,
  DELETE_ONBOARDING_FEE_SUCCESS,
  DELETE_ONBOARDING_FEE_FAILURE
} from 'appState/actions/constants/plan.actions';

function* fetchPlans({ payload: { partnerId = '' } }) {
  try {
    const response = yield call(
      axiosDefault.get,
      `/plans?partner_id=${partnerId}`
    );

    yield put({
      type: FETCH_PLANS_SUCCESS,
      payload: {
        plans: response.data
      }
    });
  } catch (e) {
    yield put({
      type: FETCH_PLANS_FAILURE,
      payload: {
        error: e
      },
      error: true
    });
  }
}

function* fetchPlanPage({ payload: { planId = null } }) {
  try {
    const response = yield call(
      axiosDefault.get,
      `/plan_pages${planId ? `?plan_id=${planId}` : ''}`
    );

    yield put({
      type: FETCH_PLAN_PAGE_SUCCESS,
      payload: {
        plan: response.data.plan,
        onboardingFees: response.data.onboarding_fees
      }
    });
  } catch (e) {
    yield put({
      type: FETCH_PLAN_PAGE_FAILURE,
      payload: {
        error: e
      },
      error: true
    });
  }
}

function* createPlan({
  payload: {
    tier,
    name,
    type,
    maxUsers,
    maxProducts,
    partnerId,
    maxJourneys,
    maxAppointmentTypes,
    chargeCampaignSms,
    typeformId,
    contactImportTypeformId,
    description,
    features,
    price,
    stripePlan,
    onboardingFeeId,
    smsEnabledCountries = null,
    history
  }
}) {
  try {
    const params = {
      plan: {
        tier,
        name,
        type,
        max_users: maxUsers,
        max_products: maxProducts,
        partner_id: partnerId,
        max_journeys: maxJourneys,
        max_appointment_types: maxAppointmentTypes,
        charge_campaign_sms: chargeCampaignSms,
        typeform_id: typeformId,
        contact_import_typeform_id: contactImportTypeformId,
        description,
        features: features.map(feature => feature.name),
        pricing_information_attributes: {
          price,
          stripe_plan: stripePlan,
          onboarding_fee_id: onboardingFeeId
        },
        sms_enabled_countries_attributes: []
      }
    };
    if (!isEmpty(smsEnabledCountries))
      params.plan.sms_enabled_countries_attributes = smsEnabledCountries;

    const response = yield call(axiosDefault.post, '/plans', params);

    yield put({
      type: CREATE_PLAN_SUCCESS,
      payload: {
        plan: response.data
      }
    });
    history.push(`/overlord/plans/${response.data.id}/edit`);
  } catch (e) {
    yield put({
      type: CREATE_PLAN_FAILURE,
      payload: {
        error: e
      },
      error: true
    });
  }
}

function* updatePlan({
  payload: {
    tier,
    planId,
    visible,
    name = null,
    type = null,
    maxUsers = null,
    maxProducts = null,
    partnerId = null,
    maxJourneys = null,
    maxAppointmentTypes = null,
    chargeCampaignSms = null,
    typeformId = null,
    contactImportTypeformId = null,
    description = null,
    features = null,
    price = null,
    stripePlan = null,
    onboardingFeeId = null,
    smsEnabledCountries = null
  }
}) {
  try {
    const params = {
      plan: {
        pricing_information_attributes: {},
        sms_enabled_countries_attributes: []
      }
    };
    if (tier) params.plan.tier = tier;
    if (name) params.plan.name = name;
    if (type) params.plan.type = type;
    if (maxUsers) params.plan.max_users = maxUsers;
    if (maxProducts >= 0) params.plan.max_products = maxProducts;
    if (typeformId) params.plan.typeform_id = typeformId;
    if (contactImportTypeformId)
      params.plan.contact_import_typeform_id = contactImportTypeformId;
    if (maxJourneys >= 0) params.plan.max_journeys = maxJourneys;
    if (chargeCampaignSms) params.plan.charge_campaign_sms = chargeCampaignSms;
    if (maxAppointmentTypes)
      params.plan.max_appointment_types = maxAppointmentTypes;
    if (description) params.plan.description = description;
    if (features) params.plan.features = features.map(feature => feature.name);
    if (price) params.plan.pricing_information_attributes.price = price;
    if (stripePlan)
      params.plan.pricing_information_attributes.stripe_plan = stripePlan;
    if (onboardingFeeId)
      params.plan.pricing_information_attributes.onboarding_fee_id = onboardingFeeId;
    if (!isEmpty(smsEnabledCountries))
      params.plan.sms_enabled_countries_attributes = smsEnabledCountries;
    if (partnerId) params.plan.partner_id = partnerId;
    params.plan.visible = visible || false;

    const response = yield call(axiosDefault.patch, `/plans/${planId}`, params);

    yield put({
      type: UPDATE_PLAN_SUCCESS,
      payload: {
        plan: response.data
      }
    });
  } catch (e) {
    yield put({
      type: UPDATE_PLAN_FAILURE,
      payload: {
        error: e
      },
      error: true
    });
  }
}

function* deletePlan({ payload: { planId } }) {
  try {
    yield call(axiosDefault.delete, `/plans/${planId}`);

    yield put({
      type: DELETE_PLAN_SUCCESS,
      payload: {
        planId
      }
    });
  } catch (e) {
    yield put({
      type: DELETE_PLAN_FAILURE,
      payload: {
        error: e
      },
      error: true
    });
  }
}

function* fetchPlanAvailableForUser() {
  try {
    const response = yield call(
      axiosDefault.get,
      `/plans?available_to_users=${true}`
    );

    yield put({
      type: AVAILABLE_PLAN_SUCCESS,
      payload: {
        plansAvailableForUser: response.data
      }
    });
  } catch (e) {
    yield put({
      type: AVAILABLE_PLAN_FAILURE,
      payload: {
        error: e
      },
      error: true
    });
  }
}

function* fetchSmsCreditPacks() {
  try {
    const response = yield call(axiosDefault.get, '/sms_credit_packs');

    yield put({
      type: FETCH_SMS_CREDIT_PACKS_SUCCESS,
      payload: {
        smsCreditPacks: response.data
      }
    });
  } catch (e) {
    yield put({
      type: FETCH_SMS_CREDIT_PACKS_FAILURE,
      payload: {
        error: e
      },
      error: true
    });
  }
}

function* fetchSmsCreditPack({ payload: { smsCreditPackId } }) {
  try {
    const response = yield call(
      axiosDefault.get,
      `/sms_credit_packs/${smsCreditPackId}`
    );

    yield put({
      type: FETCH_SMS_CREDIT_PACK_SUCCESS,
      payload: {
        smsCreditPack: response.data
      }
    });
  } catch (e) {
    yield put({
      type: FETCH_SMS_CREDIT_PACK_FAILURE,
      payload: {
        error: e
      },
      error: true
    });
  }
}

function* createSmsCreditPack({ payload: { credits, price } }) {
  try {
    const params = {
      sms_credit_pack: {
        credits,
        price
      }
    };
    const response = yield call(axiosDefault.post, '/sms_credit_packs', params);

    yield put({
      type: CREATE_SMS_CREDIT_PACK_SUCCESS,
      payload: {
        smsCreditPack: response.data
      }
    });
  } catch (e) {
    yield put({
      type: CREATE_SMS_CREDIT_PACK_FAILURE,
      payload: {
        error: e
      },
      error: true
    });
  }
}

function* updateSmsCreditPack({
  payload: { smsCreditPackId, credits = null, price = null }
}) {
  try {
    const params = {
      sms_credit_pack: {}
    };
    if (credits) params.sms_credit_pack.credits = credits;
    if (price) params.sms_credit_pack.price = price;

    const response = yield call(
      axiosDefault.patch,
      `/sms_credit_packs/${smsCreditPackId}`,
      params
    );

    yield put({
      type: UPDATE_SMS_CREDIT_PACK_SUCCESS,
      payload: {
        smsCreditPack: response.data
      }
    });
  } catch (e) {
    yield put({
      type: UPDATE_SMS_CREDIT_PACK_FAILURE,
      payload: {
        error: e
      },
      error: true
    });
  }
}

function* deleteSmsCreditPack({ payload: { smsCreditPackId } }) {
  try {
    yield call(axiosDefault.delete, `/sms_credit_packs/${smsCreditPackId}`);

    yield put({
      type: DELETE_SMS_CREDIT_PACK_SUCCESS
    });
  } catch (e) {
    yield put({
      type: DELETE_SMS_CREDIT_PACK_FAILURE,
      payload: {
        error: e
      },
      error: true
    });
  }
}

function* fetchOnboardingFees() {
  try {
    const response = yield call(axiosDefault.get, '/onboarding_fees');

    yield put({
      type: FETCH_ONBOARDING_FEES_SUCCESS,
      payload: {
        onboardingFees: response.data
      }
    });
  } catch (e) {
    yield put({
      type: FETCH_ONBOARDING_FEES_FAILURE,
      payload: {
        error: e
      },
      error: true
    });
  }
}

function* fetchOnboardingFee({ payload: { onboardingFeeId } }) {
  try {
    const response = yield call(
      axiosDefault.get,
      `/onboarding_fees/${onboardingFeeId}`
    );

    yield put({
      type: FETCH_ONBOARDING_FEE_SUCCESS,
      payload: {
        onboardingFee: response.data
      }
    });
  } catch (e) {
    yield put({
      type: FETCH_ONBOARDING_FEE_FAILURE,
      payload: {
        error: e
      },
      error: true
    });
  }
}

function* createOnboardingFee({ payload: { fee } }) {
  try {
    const params = {
      onboarding_fee: {
        fee
      }
    };
    const response = yield call(axiosDefault.post, '/onboarding_fees', params);

    yield put({
      type: CREATE_ONBOARDING_FEE_SUCCESS,
      payload: {
        onboardingFee: response.data
      }
    });
  } catch (e) {
    yield put({
      type: CREATE_ONBOARDING_FEE_FAILURE,
      payload: {
        error: e
      },
      error: true
    });
  }
}

function* updateOnboardingFee({ payload: { onboardingFeeId, fee } }) {
  try {
    const params = {
      onboarding_fee: {
        fee
      }
    };

    const response = yield call(
      axiosDefault.patch,
      `/onboarding_fees/${onboardingFeeId}`,
      params
    );

    yield put({
      type: UPDATE_ONBOARDING_FEE_SUCCESS,
      payload: {
        onboardingFee: response.data
      }
    });
  } catch (e) {
    yield put({
      type: UPDATE_ONBOARDING_FEE_FAILURE,
      payload: {
        error: e
      },
      error: true
    });
  }
}

function* deleteOnboardingFee({ payload: { onboardingFeeId } }) {
  try {
    yield call(axiosDefault.delete, `/onboarding_fees/${onboardingFeeId}`);

    yield put({
      type: DELETE_ONBOARDING_FEE_SUCCESS
    });
  } catch (e) {
    yield put({
      type: DELETE_ONBOARDING_FEE_FAILURE,
      payload: {
        error: e
      },
      error: true
    });
  }
}

export function* plansFetch() {
  yield takeLatest(FETCH_PLANS_REQUEST, fetchPlans);
}

export function* planPageFetch() {
  yield takeLatest(FETCH_PLAN_PAGE_REQUEST, fetchPlanPage);
}

export function* planCreate() {
  yield takeLatest(CREATE_PLAN_REQUEST, createPlan);
}

export function* planUpdate() {
  yield takeLatest(UPDATE_PLAN_REQUEST, updatePlan);
}

export function* planDelete() {
  yield takeLatest(DELETE_PLAN_REQUEST, deletePlan);
}

export function* planAvailableForUser() {
  yield takeLatest(AVAILABLE_PLAN_REQUEST, fetchPlanAvailableForUser);
}

export function* smsCreditPacksFetch() {
  yield takeLatest(FETCH_SMS_CREDIT_PACKS_REQUEST, fetchSmsCreditPacks);
}

export function* smsCreditPackFetch() {
  yield takeLatest(FETCH_SMS_CREDIT_PACK_REQUEST, fetchSmsCreditPack);
}

export function* smsCreditPackCreate() {
  yield takeLatest(CREATE_SMS_CREDIT_PACK_REQUEST, createSmsCreditPack);
}

export function* smsCreditPackUpdate() {
  yield takeLatest(UPDATE_SMS_CREDIT_PACK_REQUEST, updateSmsCreditPack);
}

export function* smsCreditPackDelete() {
  yield takeLatest(DELETE_SMS_CREDIT_PACK_REQUEST, deleteSmsCreditPack);
}

export function* onboardingFeesFetch() {
  yield takeLatest(FETCH_ONBOARDING_FEES_REQUEST, fetchOnboardingFees);
}

export function* onboardingFeeFetch() {
  yield takeLatest(FETCH_ONBOARDING_FEE_REQUEST, fetchOnboardingFee);
}

export function* onboardingFeeCreate() {
  yield takeLatest(CREATE_ONBOARDING_FEE_REQUEST, createOnboardingFee);
}

export function* onboardingFeeUpdate() {
  yield takeLatest(UPDATE_ONBOARDING_FEE_REQUEST, updateOnboardingFee);
}

export function* onboardingFeeDelete() {
  yield takeLatest(DELETE_ONBOARDING_FEE_REQUEST, deleteOnboardingFee);
}
