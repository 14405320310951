import moment from 'moment-timezone';

export default function getBadgeContent(eleDueDate, timezone) {
  const dueDate = new Date(
    moment.tz(eleDueDate, timezone).startOf('day')
  ).valueOf();

  const today = new Date(moment.tz(Date(), timezone).startOf('day')).valueOf();

  if (dueDate === today) return { value: 'due today', color: 'warning' };
  if (dueDate < today) return { value: 'overdue', color: 'error' };
  return { value: 'upcoming', color: 'success' };
}
