import React, { useEffect } from 'react';
import isEmpty from 'lodash.isempty';
import { createStructuredSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchIncludedForm,
  setOverlordLayoutHeaderText,
  updateIncludedForm,
  flashErrorMessage,
  flashSuccessMessage,
  fetchProduct
} from 'appState/actions/ActionCreators';
import {
  createErrorMessageSelector,
  createLoadedSelector
} from 'appState/selectors';
import { UPDATE_INCLUDED_FORM } from 'app-state/actions/constants/product.actions';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { addToCampaignSchema } from 'lib/validation/schema';
import Breadcrumbs from 'components/Theme/Breadcrumbs';
import Button from 'components/Theme/Button';
import Toggle from 'components/Theme/Toggle';
import Select from 'components/Theme/Select';
import HeroIcon from 'components/Theme/HeroIcon';

const AddToCampaign = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { id, partnerId, productId } = params;

  const updateErrorSelector = createErrorMessageSelector([
    UPDATE_INCLUDED_FORM
  ]);

  const loadedSelectorUpdate = createLoadedSelector([UPDATE_INCLUDED_FORM]);

  const structuredSelector = createStructuredSelector({
    currentIncludedForm: state => state.product.currentIncludedForm,
    campaigns: state => state.product.currentProduct.product_campaigns,
    updateError: state => updateErrorSelector(state),
    isUpdateLoaded: state => loadedSelectorUpdate(state)
  });

  const {
    currentIncludedForm,
    campaigns,
    updateError,
    isUpdateLoaded
  } = useSelector(structuredSelector);

  const breadcrumbs = [
    {
      name: 'Overlord',
      action: () => {
        window.location = '/overlord/';
      }
    },
    {
      name: 'Partner',
      action: () => {
        window.location = `/overlord/partners/${partnerId}`;
      }
    },
    {
      name: 'Product',
      action: () => {
        window.location = `/overlord/partners/${partnerId}/products/${productId}`;
      }
    },
    {
      name: 'Included Form',
      action: () => {
        window.location = `/overlord/partners/${partnerId}/products/${productId}/included_forms/${id}`;
      }
    },
    {
      name: 'Settings',
      action: () => {
        window.location = `/overlord/partners/${partnerId}/products/${productId}/included_forms/${id}/settings`;
      }
    },
    {
      name: 'Add To Campaign',
      action: () => console.log('Add To Campaign clicked')
    }
  ];

  useEffect(() => {
    if (isUpdateLoaded) {
      if (isEmpty(updateError)) {
        dispatch(
          flashSuccessMessage('Successfully updated add to campaign settings')
        );
      } else if (!isEmpty(updateError)) {
        dispatch(flashErrorMessage(updateError));
      }
    }
  }, [isUpdateLoaded, updateError]);

  useEffect(() => {
    dispatch(fetchProduct({ productId }));
  }, [productId]);

  useEffect(() => {
    dispatch(fetchIncludedForm({ includedFormId: id }));
  }, [id, dispatch]);

  useEffect(() => {
    if (!isEmpty(currentIncludedForm)) {
      dispatch(
        setOverlordLayoutHeaderText(`${currentIncludedForm.name} Settings`)
      );
    } else {
      dispatch(setOverlordLayoutHeaderText('Settings'));
    }
  }, [currentIncludedForm]);

  function handleSubmit(values) {
    const addToCampaignParams = {};
    if (Object.keys(values).length)
      Object.keys(values).forEach(function assignParams(key) {
        addToCampaignParams[key] = values[key];
      });
    if (currentIncludedForm?.uplaunch_action) {
      addToCampaignParams.id = currentIncludedForm.uplaunch_action.id;
    }
    dispatch(
      updateIncludedForm({
        includedFormId: id,
        addToCampaignParams
      })
    );
  }

  const formik = useFormik({
    initialValues: {
      productCampaignId:
        currentIncludedForm?.uplaunch_action?.product_campaign_id || '',
      hasChangeCampaignModule:
        currentIncludedForm?.uplaunch_action?.has_staff_notification_sms
    },
    enableReinitialize: true,
    validationSchema: addToCampaignSchema,
    onSubmit: values => {
      handleSubmit(values);
    }
  });

  const campaignOverlays = campaigns.filter(c => {
    return c.campaign_type === 'overlay';
  });

  const campaignOptions = campaignOverlays.map(campaign => {
    return { displayName: campaign.name, value: campaign.id };
  });

  function handleHasChangeCampaignToggle() {
    formik.setFieldValue(
      'hasChangeCampaignModule',
      !formik.values.hasChangeCampaignModule
    );
  }
  return (
    <div>
      <div className="tw-px-4 tw-py-4 sm:tw-px-6">
        <div className="tw-mb-5">
          <div className="tw-mb-5">
            {breadcrumbs?.length && (
              <div>
                <Breadcrumbs crumbs={breadcrumbs} isBackgroundWhite />
              </div>
            )}
          </div>
          <div className="tw-mt-2 md:tw-flex md:tw-items-center md:tw-justify-between">
            <div className="tw-flex-1 tw-min-w-0">
              <Toggle
                color="alpha"
                onClick={handleHasChangeCampaignToggle}
                isToggled={formik.values.hasChangeCampaignModule}
                size="large"
                withIcons
                withLabel
                label="Add contact to campaign?"
              />
            </div>
            <div className="tw-mt-4 tw-flex tw-flex-shrink-0 md:tw-mt-0 md:tw-ml-4">
              <Button
                text="Update"
                color="alpha"
                onClick={formik.handleSubmit}
                containerStyle={{ marginLeft: '0.75rem' }}
                disabled={
                  isEmpty(currentIncludedForm) || isEmpty(campaignOverlays)
                }
                isLoaded={!isEmpty(currentIncludedForm)}
              />
            </div>
          </div>
        </div>
        <div className="tw-border-b tw-border-t-0 tw-border-l-0 tw-border-r-0 tw-border-solid tw-border-gray-200" />
        <div className="tw-mt-10">
          {isEmpty(campaignOverlays) ? (
            <div className="tw-text-center tw-relative tw-block tw-w-full tw-border-2 tw-border-gray-300 tw-border-dashed tw-rounded-lg tw-p-12 tw-text-center hover:tw-border-gray-400 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-indigo-500">
              <HeroIcon icon="server" width={12} height={12} color="gray" />
              <h3 className="tw-mt-2 tw-text-sm tw-font-medium tw-text-gray-900">
                No Campaign Overlays
              </h3>
              <p className="tw-mt-1 tw-text-sm tw-text-gray-500">
                The product must have one campaign overlay to use this action.
              </p>
              <div className="mt-6">
                <Button
                  text="Add Campaign Overlay"
                  color="alpha"
                  onClick={() => {
                    window.location = `/partners/${partnerId}/products/${productId}/product_campaigns/new?overlay=true`;
                  }}
                />
              </div>
            </div>
          ) : (
            <Select
              id="product_campaign_id"
              labelText="Select the campaign overlay you want to add the contact to."
              options={campaignOptions}
              value={formik.values.productCampaignId}
              onChange={e => {
                formik.setFieldValue('productCampaignId', e.target.value);
              }}
              onBlur={formik.handleBlur}
              showError={
                formik.touched.productCampaignId &&
                !!formik.errors.productCampaignId
              }
              error={formik.errors.productCampaignId}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AddToCampaign;
