import React, { useState, useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { useParams, useHistory } from 'react-router-dom';
import {
  createErrorMessageSelector,
  createLoadedSelector
} from 'appState/selectors';
import { useSelector, useDispatch } from 'react-redux';
import PageHeader from 'components/Theme/PageHeader';
import Card from 'components/Theme/Card';
import Button from 'components/Theme/Button';
import Badge from 'components/Theme/Badge';
import Modal from 'components/Theme/Modal';
import {
  createProductSubscription,
  mySubscriptionFetch,
  resetIsLoaded,
  productFetch,
  clearCurrentProductFromState
} from 'appState/actions/ActionCreators';
import Skeleton from 'react-loading-skeleton';
import TwoStepUpgrade from 'components/Stripe/TwoStepUpgrade';
import Notification from 'components/Theme/Notification';
import isEmpty from 'lodash.isempty';
import sampleSize from 'lodash.samplesize';
import HeroIcon from 'components/Theme/HeroIcon';
import RollbackModal from 'components/Subscriptions/DowngradePlan/RollbackModal';
import MyPlanAndProduct from '../MyPlanAndProduct';

const Product = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [
    shouldRedirectToProductSetup,
    setShouldRedirectToProductSetup
  ] = useState(false);
  const [
    shouldRedirectToMarketplace,
    setShouldRedirectToMarketplace
  ] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setShowNotificationMessage] = useState('');
  const [notificationColor, setShowNotificationColor] = useState('');
  const [notificationHeaderText, setNotificationHeaderText] = useState('');
  const errorSelector = createErrorMessageSelector([
    'productSubscription/CREATE_PRODUCT_SUBSCRIPTION'
  ]);
  const isCreateLoadedSelector = createLoadedSelector([
    'productSubscription/CREATE_PRODUCT_SUBSCRIPTION'
  ]);

  const isMySubscriptionLoadedSelector = createLoadedSelector([
    'subscription/FETCH_MY_SUBSCRIPTION'
  ]);

  const isLoadedSelector = createLoadedSelector(['marketplace/PRODUCT_FETCH']);
  const history = useHistory();
  const structuredSelector = createStructuredSelector({
    currentProduct: state => state.marketPlace.currentProduct,
    currentProductSubscription: state =>
      state.productSubscription.currentProductSubscription,
    categoryProductList: state => state.marketPlace.categoryProductList,
    mySubscription: state => state.subscription.mySubscription,
    myProducts: state => state.subscription.myProducts,
    error: state => errorSelector(state),
    isCreateLoaded: state => isCreateLoadedSelector(state),
    isLoaded: state => isLoadedSelector(state),
    isMySubscriptionLoaded: state => isMySubscriptionLoadedSelector(state)
  });
  const {
    currentProduct,
    currentProductSubscription,
    categoryProductList,
    mySubscription,
    myProducts,
    error,
    isCreateLoaded,
    isLoaded,
    isMySubscriptionLoaded
  } = useSelector(structuredSelector);
  useEffect(() => {
    dispatch(productFetch({ productId: id }));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(mySubscriptionFetch());

    return () => {
      dispatch(clearCurrentProductFromState());
    };
  }, []);

  useEffect(() => {
    if (shouldRedirectToMarketplace) window.location = '/ui/marketplace';
  }, [shouldRedirectToMarketplace]);

  useEffect(() => {
    if (shouldRedirectToProductSetup && currentProductSubscription?.id)
      window.location = `/ui/my-products/${currentProductSubscription.id}/setup`;
  }, [shouldRedirectToProductSetup, currentProductSubscription]);

  const [showModal, setShowModal] = useState(false);
  const [
    showModalForCancelDowngrade,
    setshowModalForCancelDowngrade
  ] = useState(false);

  const imageUrl = currentProduct.hero_image_url;

  const purchasePageImageOneUrl = currentProduct.purchase_page_image_one_url;

  const purchasePageImageTwoUrl = currentProduct.purchase_page_image_two_url;

  const handleSetupLater = () => {
    setShowModal(false);
    setShouldRedirectToMarketplace(true);
  };

  const handleSetupNow = () => {
    setShowModal(false);
    setShouldRedirectToProductSetup(true);
  };

  const handleAddProduct = () => {
    dispatch(createProductSubscription({ productId: currentProduct.id }));
  };

  const handleAddProductClick = () => {
    if (mySubscription.downgrade_due_date) {
      setshowModalForCancelDowngrade(true);
    } else {
      handleAddProduct();
    }
  };

  const handleNotification = (isShow, headerStr, color, message) => {
    setShowNotification(isShow);
    setNotificationHeaderText(headerStr);
    setShowNotificationColor(color);
    setShowNotificationMessage(message);
  };

  useEffect(() => {
    if (isCreateLoaded) {
      if (!isEmpty(error)) {
        handleNotification(true, 'Error!', 'error', error);
      } else {
        setShowModal(true);
      }
    }
    dispatch(resetIsLoaded('subscription/CREATE_PRODUCT_SUBSCRIPTION'));
    return () => {
      dispatch(resetIsLoaded('subscription/CREATE_PRODUCT_SUBSCRIPTION'));
    };
  }, [error, isCreateLoaded, dispatch]);

  const alreadyMyProduct = () => {
    return (
      myProducts.findIndex(myProduct => myProduct.id === currentProduct.id) > -1
    );
  };

  const disableAddProduct =
    myProducts && !isEmpty(myProducts) ? alreadyMyProduct() : false;

  function createCard(prod) {
    const image = prod.hero_image_url;
    return (
      <Card
        color="alpha"
        containerClass="tw-bg-white tw-rounded-lg tw-m-5 tw-col-span-12 md:tw-col-span-4"
        customMarginContainer
        imageUrl={image}
        imageClass="tw-rounded-t-lg"
        headerClass="tw-mb-10"
      >
        <div className="tw-m-5">
          {prod.product_lifecycle ? (
            <div className="tw-my-2">
              <Badge
                shape="rectangle"
                size="small"
                value={prod.product_lifecycle}
                containerStyle={{
                  textTransform: 'uppercase'
                }}
              />
            </div>
          ) : null}
          <h3 className="tw-font-body tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
            {prod.name}
          </h3>
          <p className="tw-font-body tw-mt-1 tw-mb-0 tw-text-sm tw-leading-5 tw-text-gray-500">
            {prod.campaign_count}
          </p>
        </div>
      </Card>
    );
  }

  function getPageHeader() {
    const breadcrumbs = [
      {
        name: 'Marketplace',
        action: () => {
          history.push(`/ui/marketplace`);
        }
      },
      {
        name: currentProduct?.name || 'Product'
      }
    ];

    return (
      <PageHeader
        title={`${currentProduct?.name || 'Product'}`}
        backgroundColor="white"
        breadcrumbs={breadcrumbs}
        rightBar={
          <MyPlanAndProduct
            myProducts={myProducts}
            mySubscription={mySubscription}
          />
        }
      />
    );
  }

  const getTotalProductCount = products => {
    return products.filter(
      f => f.lifecycles && f.lifecycles.join('') !== ['lead'].join('')
    ).length;
  };

  const getProductAddButton = () => {
    if (!isLoaded || !isMySubscriptionLoaded || isEmpty(currentProduct)) {
      return <Skeleton width={250} height={30} count={1} />;
    }

    const { plan = {}, comped, products } = mySubscription;

    if (
      disableAddProduct ||
      plan?.product_limit === 'unlimited' ||
      currentProduct?.lifecycles.join('') === ['lead'].join('') ||
      (plan?.tier === 'standard' &&
        plan?.product_limit > getTotalProductCount(products))
    ) {
      return (
        <Button
          color="alpha"
          text={disableAddProduct ? 'Already Added' : 'Add Product'}
          onClick={handleAddProductClick}
          disabled={disableAddProduct}
        />
      );
    }

    return (
      <>
        {!comped ? (
          <TwoStepUpgrade
            myPlan={plan}
            mySubscription={mySubscription}
            buttonText="Upgrade Plan"
          />
        ) : null}
      </>
    );
  };

  return (
    <div className="tw-text-sm tw-overflow-hidden">
      {getPageHeader()}
      <div className="xxs:tw-col-span-12 md:tw-col-span-6 lg:tw-col-span-8">
        <Card
          containerClass="tw-bg-white tw-my-4 tw-mx-4 tw-min-h-screen tw-rounded-lg tw-overflow-hidden"
          customMarginContainer
        >
          <div className="tw-grid tw-grid-cols-12 tw-gap-2 tw-min-h-300">
            <div
              className="tw-col-span-12 tw-block lg:tw-hidden tw-bg-cover tw-bg-center tw-h-64"
              style={{ backgroundImage: `url(${imageUrl})` }}
            />
            <div className="tw-col-span-12 lg:tw-col-span-6">
              <div className="tw-p-12">
                {currentProduct.product_lifecycle ? (
                  <div className="tw-my-2">
                    <Badge
                      shape="round"
                      size="small"
                      value={currentProduct.product_lifecycle}
                      containerStyle={{
                        textTransform: 'uppercase'
                      }}
                    />
                  </div>
                ) : null}
                <h2 className="tw-font-body tw-text-2xl tw-font-bold tw-leading-7 tw-tracking-normal tw-mt-4">
                  {currentProduct?.name ? (
                    currentProduct.name
                  ) : (
                    <Skeleton width={150} height={50} />
                  )}
                </h2>
                <h4 className="tw-font-body tw-text-2xl tw-font-light tw-leading-9 tw-tracking-normal">
                  {currentProduct?.category ? (
                    currentProduct.category
                  ) : (
                    <Skeleton width={250} height={30} />
                  )}
                </h4>
                {getProductAddButton()}
                <h4 className="tw-text-base tw-font-light tw-leading-7 tw-tracking-normal tw-mt-4">
                  {isLoaded ? (
                    currentProduct.description
                  ) : (
                    <Skeleton width={450} height={20} />
                  )}
                </h4>
              </div>
            </div>
            <div
              className="tw-col-span-6 tw-hidden lg:tw-block tw-bg-cover tw-bg-center tw-h-full tw-rounded-t-lg tw-rounded-bl-lg"
              style={{ backgroundImage: `url(${imageUrl})` }}
            />
          </div>
          <div className="tw-bg-gray-100">
            <div className="tw-px-12 tw-py-16">
              <div className="tw-grid tw-grid-cols-12 tw-gap-6">
                <div className="tw-col-span-12 lg:tw-col-span-4">
                  <div className="tw-flex lg:tw-justify-center">
                    <div className="tw-bg-alpha-base tw-text-white tw-rounded-full tw-w-10 tw-h-10 tw-flex-shrink-0 tw-flex tw-justify-center tw-items-center">
                      <HeroIcon
                        icon={currentProduct.content_feature_one_icon}
                        color="white"
                      />
                    </div>
                    <div className="tw-ml-4">
                      <div className="tw-text-gray-800 tw-text-lg tw-font-medium tw-leading-6 tw-tracking-normal">
                        {currentProduct.content_feature_one_title}
                      </div>
                      <div className="tw-text-gray-600 tw-font-body tw-text-base tw-font-light tw-leading-6 tw-tracking-normal">
                        {currentProduct.content_feature_one_subtitle}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tw-col-span-12 lg:tw-col-span-4">
                  <div className="tw-flex lg:tw-justify-center">
                    <div className="tw-bg-alpha-base tw-text-white tw-rounded-full tw-w-10 tw-h-10 tw-flex-shrink-0 tw-flex tw-justify-center tw-items-center">
                      <HeroIcon
                        icon={currentProduct.content_feature_two_icon}
                        color="white"
                      />
                    </div>
                    <div className="tw-ml-4">
                      <div className="tw-text-gray-800 tw-text-lg tw-font-medium tw-leading-6 tw-tracking-normal">
                        {currentProduct.content_feature_two_title}
                      </div>
                      <div className="tw-text-gray-600 tw-font-body tw-text-base tw-font-light tw-leading-6 tw-tracking-normal">
                        {currentProduct.content_feature_two_subtitle}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tw-col-span-12 lg:tw-col-span-4">
                  <div className="tw-flex lg:tw-justify-center">
                    <div className="tw-bg-alpha-base tw-text-white tw-rounded-full tw-w-10 tw-h-10 tw-flex-shrink-0 tw-flex tw-justify-center tw-items-center">
                      <HeroIcon
                        icon={currentProduct.content_feature_three_icon}
                        color="white"
                      />
                    </div>
                    <div className="tw-ml-4 tw-text-left">
                      <div className="tw-text-gray-800 tw-text-lg tw-font-medium tw-leading-6 tw-tracking-normal">
                        {currentProduct.content_feature_three_title}
                      </div>
                      <div className="tw-text-gray-600 tw-font-body tw-text-base tw-font-light tw-leading-6 tw-tracking-normal">
                        {currentProduct.content_feature_three_subtitle}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tw-bg-white">
            <div className="tw-max-w-full tw-mx-auto tw-py-16 tw-px-12 lg:tw-py-24 tw-grid tw-grid-cols-12 lg:tw-gap-x-8">
              <div className="tw-col-span-12 lg:tw-col-span-4">
                <img
                  src={purchasePageImageOneUrl}
                  alt="UpLaunch Products"
                  className="tw-rounded-lg"
                />
              </div>
              <div className="tw-col-span-12 lg:tw-col-span-8 lg:tw-px-12 lg:tw-flex lg:tw-items-center lg:tw-justify-center tw-h-full">
                <dl className="">
                  <p className="tw-text-3xl tw-font-extrabold tw-text-gray-900 tw-my-10">
                    Campaigns Included
                  </p>
                  {currentProduct &&
                    currentProduct.product_campaigns &&
                    currentProduct.product_campaigns.map(campaign => (
                      <div key={campaign.name} className="tw-flex tw-mb-5">
                        <HeroIcon icon="check" color="success" />
                        <div className="tw-ml-3">
                          <dt className="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
                            {campaign.name}
                          </dt>
                          <dd className="tw-mt-2 tw-text-base tw-text-gray-500">
                            {campaign.description}
                          </dd>
                        </div>
                      </div>
                    ))}
                </dl>
              </div>
            </div>
          </div>
          <h1 className="tw-text-3xl tw-font-bold tw-tracking-wide tw-text-center tw-leading-9 tw-ml-12">
            Sample Content
          </h1>
          <div className="tw-my-12 tw-mx-auto tw-max-w-5xl tw-px-12">
            <img
              src={purchasePageImageTwoUrl}
              alt="UpLaunch Products"
              className="tw-rounded-lg"
            />
          </div>
          <div className="tw-grid tw-grid-cols-12 tw-px-6 tw-mb-15">
            {sampleSize(
              categoryProductList.filter(elem => currentProduct.id !== elem.id),
              3
            ).map(prod => createCard(prod))}
          </div>
        </Card>
      </div>
      <RollbackModal
        handlePrimaryActionOnSuccess={handleAddProduct}
        showModalForCancelDowngrade={showModalForCancelDowngrade}
        setshowModalForCancelDowngrade={setshowModalForCancelDowngrade}
        primaryActionText="Yes, cancel and add product now."
      />
      <Modal
        color="alpha"
        headerText={`Setup ${currentProduct?.name} Product`}
        secondaryAction={handleSetupLater}
        primaryAction={handleSetupNow}
        showHeaderIcon={false}
        primaryActionText="Setup Now"
        secondaryActionText="Setup Later"
        setShow={setShowModal}
        show={showModal}
        showSecondaryAction
        showPrimaryAction
        overflowHidden={false}
        canHandleClickOutside
      >
        <p style={{ fontSize: '14px', fontWeight: 400 }}>
          This product has been added. You can remove or edit it in
          <a href="/ui/my-products" className="text-info tw-pl-1 tw-underline">
            My Products.
          </a>
        </p>
      </Modal>
      <Notification
        message={notificationMessage}
        show={showNotification}
        setShowAction={setShowNotification}
        type="colored"
        headerText={notificationHeaderText}
        color={notificationColor}
        HeaderIcon={color => {
          return (
            <svg
              className={`tw-h-5 tw-w-5 tw-text-${color}-400`}
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                clipRule="evenodd"
              />
            </svg>
          );
        }}
      />
    </div>
  );
};

export default Product;
