import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { createStructuredSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import {
  createErrorMessageSelector,
  createLoadedSelector
} from 'appState/selectors';
import Modal from 'components/Theme/Modal';
import TextInput from 'components/Theme/TextInput';
import TextArea from 'components/Theme/TextArea';
import Select from 'components/Theme/Select';
import DatePicker from 'components/Theme/DatePicker';
import DraftEditor from 'components/Theme/DraftEditor';
import { taskSchema } from 'lib/validation/schema';
import { updateTask, fetchNextTask } from 'appState/actions/ActionCreators';
import { CurrentAccountContext } from 'components/shared/context/CurrentAccountContext';
import { UsersContext } from 'components/shared/context/UsersContext';
import { UPDATE_TASK } from 'app-state/actions/constants/task.actions';
import isEmpty from 'lodash.isempty';
import { getPreviousDay, formatTimestamp } from 'lib/utils/dateTime';

const TaskEditModal = ({ activeItem, show, setShow }) => {
  const dispatch = useDispatch();
  const currentAccount = useContext(CurrentAccountContext);
  const errorSelector = createErrorMessageSelector([UPDATE_TASK]);
  const loadedSelector = createLoadedSelector([UPDATE_TASK]);

  const structuredSelector = createStructuredSelector({
    currentContact: state => state.contact.currentContact,
    error: state => errorSelector(state),
    isLoaded: state => loadedSelector(state)
  });

  const { currentContact, isLoaded, error } = useSelector(structuredSelector);

  useEffect(() => {
    setShow(show);
  }, [show, setShow]);

  useEffect(() => {
    if (isLoaded) {
      if (isEmpty(error)) {
        dispatch(fetchNextTask({ contactId: currentContact.id }));
      }
    }
  }, [dispatch, isLoaded, error]);

  function handleSubmit(values) {
    const taskParams = { accountId: currentAccount.id };
    taskParams.taskId = activeItem.sourceable_id;
    if (currentContact) taskParams.contactId = currentContact.id;
    if (Object.keys(values).length)
      Object.keys(values).forEach(function assignParams(key) {
        taskParams[key] = values[key];
      });
    dispatch(updateTask(taskParams));
  }

  const formik = useFormik({
    initialValues: {
      title: activeItem.task_title,
      description: activeItem.task_description,
      staffId: activeItem.user_id,
      dueDate: formatTimestamp(
        activeItem.task_due_date,
        currentAccount.time_zone,
        false,
        'LL',
        true
      ),
      note: ''
    },
    validationSchema: taskSchema,
    onSubmit: values => {
      handleSubmit(values);
    }
  });

  function handleDueDateChange(updatedValue) {
    formik.setFieldValue('dueDate', updatedValue);
  }

  function handleDescriptionChange(updatedDescription) {
    formik.setFieldValue('description', updatedDescription);
  }

  function handleDescriptionBlur() {
    formik.setFieldTouched('description', true);
  }

  const { users } = useContext(UsersContext);

  const userSelectOptions = users.map(user => {
    return { displayName: user.full_name, value: user.id };
  });

  const customMergeFieldsData = {
    mergeFieldOptions: [
      { key: 1, text: 'Business Name', value: '{{ account.name }}' },
      { key: 2, text: 'Contact First Name', value: '{{ contact.first_name }}' },
      { key: 3, text: 'Contact Last Name', value: '{{ contact.last_name }}' }
    ]
  };

  return (
    <Modal
      color="alpha"
      headerText="Update Task"
      primaryAction={formik.handleSubmit}
      primaryActionText="Submit"
      showSecondaryAction
      showHeaderIcon={false}
      show={show}
      setShow={setShow}
      canHandleClickOutside
    >
      <div className="tw-grid tw-grid-cols-12 tw-gap-6 tw-py-4">
        <TextInput
          id="title"
          value={formik.values.title}
          labelText="Title"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          showError={formik.touched.title && !!formik.errors.title}
          error={formik.errors.title}
          widthClass="tw-col-span-12 sm:tw-col-span-6"
        />

        <div className="tw-col-span-12">
          <DraftEditor
            editorStateString={formik.values.description}
            handleEditorTextVal={handleDescriptionChange}
            customDropDownData={customMergeFieldsData}
            customDropDownName={['Merge Fields']}
            editorOptions={['inline', 'list', 'link', 'remove', 'history']}
            labelText="Description"
            onBlur={handleDescriptionBlur}
            showError={
              formik.touched.description && !!formik.errors.description
            }
            error={formik.errors.description}
          />
        </div>

        <Select
          id="staff_id"
          value={formik.values.staffId}
          options={userSelectOptions}
          labelText="Assign Task To"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          showError={formik.touched.staffId && !!formik.errors.staffId}
          error={formik.errors.staffId}
          widthClass="tw-col-span-12 sm:tw-col-span-6"
        />

        <DatePicker
          id="due_date"
          value={formik.values.dueDate}
          labelText="Due Date"
          onChange={handleDueDateChange}
          onBlur={formik.handleBlur}
          showError={formik.touched.dueDate && !!formik.errors.dueDate}
          error={formik.errors.dueDate}
          widthClass="tw-col-span-12 sm:tw-col-span-6"
          minDate={getPreviousDay()}
        />
        <div className="tw-col-span-12">
          <TextArea
            id="note"
            value={formik.values.note}
            labelText="Notes"
            placeholder="Add Notes"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            showError={formik.touched.note && !!formik.errors.note}
            error={formik.errors.note}
            widthClass="tw-col-span-12 sm:tw-col-span-6"
          />
        </div>
      </div>
    </Modal>
  );
};

TaskEditModal.defaultProps = {
  show: false,
  setShow: () => null
};

const itemShape = {
  task_title: PropTypes.string,
  task_description: PropTypes.string,
  task_due_date: PropTypes.string,
  user_id: PropTypes.number,
  sourceable_id: PropTypes.number
};

TaskEditModal.propTypes = {
  activeItem: PropTypes.shape(itemShape).isRequired,
  show: PropTypes.bool,
  setShow: PropTypes.func
};

export default TaskEditModal;
