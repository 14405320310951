import * as Yup from 'yup';

function createYupSchema(schema, config) {
  const schemaClone = { ...schema };
  const { id, validationType, validations = [] } = config;
  if (!Yup[validationType]) {
    return schemaClone;
  }
  let validator = Yup[validationType]();
  validations.forEach(validation => {
    const { params, type } = validation;
    if (!validator[type]) {
      return;
    }
    validator = validator[type](...params);
  });
  schemaClone[id] = validator;
  return schemaClone;
}

export default createYupSchema;
