import React from 'react';
import PropTypes from 'prop-types';
import ProductInfo from './ProductInfo';

const MyProduct = ({ products }) => {
  return (
    <div className="tw-flex tw-flex-col tw-mx-auto tw-rounded-lg tw-bg-white">
      <div className="tw--my-2 tw-py-2 sm:tw--mx-6 sm:tw-px-6 lg:tw--mx-6 lg:tw-px-6">
        <div className="tw-align-middle tw-inline-block tw-min-w-full tw-rounded-lg tw-border-b tw-border-gray-200">
          <div className="tw-px-4 tw-py-3 sm:tw-px-6 tw-h-auto tw-max-h-60 tw-overflow-auto">
            <div>
              <div>
                <h4 className="tw-font-body tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
                  MY PRODUCTS
                </h4>
              </div>
              {products.length
                ? products.map(product => {
                    return <ProductInfo key={product.id} product={product} />;
                  })
                : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

MyProduct.defaultProps = {
  products: []
};

MyProduct.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object)
};

export default MyProduct;
