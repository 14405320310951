import { useLayoutEffect } from 'react';
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks
} from 'body-scroll-lock';

export default function useLockBodyScroll(disableScroll, targetElement) {
  useLayoutEffect(() => {
    if (targetElement) {
      if (disableScroll) disableBodyScroll(targetElement);
      if (!disableScroll) enableBodyScroll(targetElement);
    }

    // Clear all scroll locks when component unmounts
    return () => {
      clearAllBodyScrollLocks();
    };
  }, [disableScroll, targetElement]);
}
