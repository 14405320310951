// linting rules disabled for immer js
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  FETCH_MY_PRODUCT_SUBSCRIPTIONS_SUCCESS,
  FETCH_PRODUCT_SUBSCRIPTION_SUCCESS,
  PAUSE_PRODUCT_SUBSCRIPTION_SUCCESS,
  UNPAUSE_PRODUCT_SUBSCRIPTION_SUCCESS,
  REMOVE_PRODUCT_SUBSCRIPTION_SUCCESS,
  CREATE_PRODUCT_SUBSCRIPTION_SUCCESS,
  FETCH_ADD_CONTACTS_FILTERS_SUCCESS,
  VIEW_CONTACTS_IN_PRODUCT_CAMPAIGN_SUCCESS,
  FETCH_CONTACTS_COUNT_SUCCESS,
  ADD_CONTACTS_TO_PRODUCT_SUCCESS,
  UPDATE_PROCESS_ADD_CONTACTS_AT,
  CLEAR_VIEW_CONTACTS_MODAL_CONTACTS
} from 'appState/actions/constants/productSubscription.actions';

const initialState = {
  currentProductSubscription: {},
  myProductSubscriptions: [],
  addContactsFilters: [],
  contacts: [],
  viewContactsModalPage: 1,
  viewContactsModalTotal: 0,
  isViewContactsModalChangingPage: false,
  viewContactsModalTotalPages: 0,
  contactsCount: ''
};

const LIMIT = 20;

export default function ProductSubscriptionReducer(
  state = initialState,
  action
) {
  return produce(state, draft => {
    switch (action.type) {
      case FETCH_PRODUCT_SUBSCRIPTION_SUCCESS: {
        const { currentProductSubscription } = action.payload;
        draft.currentProductSubscription = currentProductSubscription;
        return;
      }
      case FETCH_MY_PRODUCT_SUBSCRIPTIONS_SUCCESS: {
        const { myProductSubscriptions } = action.payload;
        draft.myProductSubscriptions = myProductSubscriptions;
        return;
      }
      case PAUSE_PRODUCT_SUBSCRIPTION_SUCCESS: {
        const { productSubscription } = action.payload;
        draft.myProductSubscriptions = draft.myProductSubscriptions.map(ps => {
          if (ps.id === productSubscription.id) {
            return productSubscription;
          }
          return ps;
        });
        return;
      }
      case UNPAUSE_PRODUCT_SUBSCRIPTION_SUCCESS: {
        const { productSubscription } = action.payload;
        draft.myProductSubscriptions = draft.myProductSubscriptions.map(ps => {
          if (ps.id === productSubscription.id) {
            return productSubscription;
          }
          return ps;
        });
        return;
      }
      case REMOVE_PRODUCT_SUBSCRIPTION_SUCCESS: {
        const { productSubscription } = action.payload;
        const removedSubIndex = draft.myProductSubscriptions.findIndex(
          ps => ps.id === productSubscription.id
        );
        draft.myProductSubscriptions.splice(removedSubIndex, 1);
        return;
      }
      case CREATE_PRODUCT_SUBSCRIPTION_SUCCESS: {
        const { productSubscription } = action.payload;
        draft.currentProductSubscription = productSubscription;
        return;
      }
      case FETCH_ADD_CONTACTS_FILTERS_SUCCESS: {
        const { addContactsFilters } = action.payload;
        draft.addContactsFilters = addContactsFilters;
        return;
      }
      case ADD_CONTACTS_TO_PRODUCT_SUCCESS: {
        const { productSubscription } = action.payload;
        draft.myProductSubscriptions = draft.myProductSubscriptions.map(ps => {
          if (ps.id === productSubscription.id) {
            return productSubscription;
          }
          return ps;
        });
        return;
      }
      case VIEW_CONTACTS_IN_PRODUCT_CAMPAIGN_SUCCESS: {
        const { contacts, page, total } = action.payload;
        draft.viewContactsModalTotalPages = Math.ceil(total / LIMIT);
        draft.viewContactsModalPage = page;
        draft.viewContactsModalTotal = total;
        draft.contacts = contacts;
        return;
      }
      case CLEAR_VIEW_CONTACTS_MODAL_CONTACTS: {
        draft.contacts = [];
        return;
      }
      case FETCH_CONTACTS_COUNT_SUCCESS: {
        const { contactsCount } = action.payload;
        draft.contactsCount = contactsCount;
        return;
      }
      case UPDATE_PROCESS_ADD_CONTACTS_AT: {
        const { productSubscriptionId } = action.payload;
        const { myProductSubscriptions } = draft;
        const productSubscriptionIndex = myProductSubscriptions.findIndex(
          ps => ps.id === productSubscriptionId
        );
        if (
          productSubscriptionIndex === -1 ||
          myProductSubscriptions[productSubscriptionIndex]
            .processing_add_contacts_at === null
        )
          return;
        draft.myProductSubscriptions[
          productSubscriptionIndex
        ].processing_add_contacts_at = null;
        return;
      }
      default:
        return draft;
    }
  });
}
