import { call, put, takeLatest } from 'redux-saga/effects';
import { axiosDefault } from 'lib/utils/axios-setup';
import {
  FETCH_APPOINTMENT_TYPE_REQUEST,
  FETCH_APPOINTMENT_TYPE_SUCCESS,
  FETCH_APPOINTMENT_TYPE_FAILURE,
  CREATE_APPOINTMENT_TYPE_REQUEST,
  CREATE_APPOINTMENT_TYPE_SUCCESS,
  CREATE_APPOINTMENT_TYPE_FAILURE,
  UPDATE_APPOINTMENT_TYPE_REQUEST,
  UPDATE_APPOINTMENT_TYPE_SUCCESS,
  UPDATE_APPOINTMENT_TYPE_FAILURE,
  DELETE_APPOINTMENT_TYPE_REQUEST,
  DELETE_APPOINTMENT_TYPE_SUCCESS,
  DELETE_APPOINTMENT_TYPE_FAILURE,
  FETCH_ALL_APPOINTMENT_TYPE_REQUEST,
  FETCH_ALL_APPOINTMENT_TYPE_SUCCESS,
  FETCH_ALL_APPOINTMENT_TYPE_FAILURE
} from 'app-state/actions/constants/appointmentType.actions';

function* fetchAppointmentType({ payload: { appointmentTypeId } }) {
  try {
    const response = yield call(
      axiosDefault.get,
      `/appointment_types/${appointmentTypeId}`
    );

    yield put({
      type: FETCH_APPOINTMENT_TYPE_SUCCESS,
      payload: { currentAppointmentType: response.data }
    });
  } catch (e) {
    yield put({
      type: FETCH_APPOINTMENT_TYPE_FAILURE,
      payload: { error: e },
      error: true
    });
  }
}

function* createAppointmentType({ payload: { params } }) {
  const appointmentParams = {
    appointment_type: {
      name: params.name,
      permitted_staff_ids: params.permittedStaffIds,
      allow_self_scheduling: params.allowSchedulling,
      booking_form_id: params.contactFormType,
      booking_redirect_url: params.url,
      future_booking_restriction_days: params.futureBookingRestrictionDays,
      advanced_notice: params.notice,
      padding: params.padding,
      duration: params.duration,
      booking_redirect: params.hasBookingRedirect,
      has_booking_form: params.hasBookingForm,
      future_booking_restricted: params.hasFutureBookingRestricted,
      max_attendees: params.maxAttendees
    }
  };
  try {
    const response = yield call(
      axiosDefault.post,
      `/appointment_types`,
      appointmentParams
    );

    yield put({
      type: CREATE_APPOINTMENT_TYPE_SUCCESS,
      payload: {
        currentAppointmentType: response.data
      }
    });
  } catch (e) {
    yield put({
      type: CREATE_APPOINTMENT_TYPE_FAILURE,
      payload: { error: e },
      error: true
    });
  }
}

function* updateAppointmentType({ payload: { params } }) {
  const appointmentParams = {
    appointment_type: {
      id: params.id,
      name: params.name,
      permitted_staff_ids: params.permittedStaffIds,
      allow_self_scheduling: params.allowSchedulling,
      booking_form_id: params.hasBookingForm ? params.contactFormType : null,
      booking_redirect_url: params.hasBookingRedirect ? params.url : null,
      future_booking_restriction_days: params.hasFutureBookingRestricted
        ? params.futureBookingRestrictionDays
        : null,
      advanced_notice: params.notice,
      padding: params.padding,
      duration: params.duration,
      booking_redirect: params.hasBookingRedirect,
      has_booking_form: params.hasBookingForm,
      future_booking_restricted: params.hasFutureBookingRestricted,
      max_attendees: params.maxAttendees
    }
  };
  try {
    const response = yield call(
      axiosDefault.put,
      `/appointment_types/${params.id}`,
      appointmentParams
    );

    yield put({
      type: UPDATE_APPOINTMENT_TYPE_SUCCESS,
      payload: {
        currentAppointmentType: response.data
      }
    });
  } catch (e) {
    yield put({
      type: UPDATE_APPOINTMENT_TYPE_FAILURE,
      payload: { error: e },
      error: true
    });
  }
}

function* deleteAppointmentType({ payload: { appointmentTypeId } }) {
  try {
    yield call(axiosDefault.delete, `/appointment_types/${appointmentTypeId}`);

    yield put({
      type: DELETE_APPOINTMENT_TYPE_SUCCESS,
      payload: {
        appointmentTypeId
      }
    });
  } catch (e) {
    yield put({
      type: DELETE_APPOINTMENT_TYPE_FAILURE,
      payload: { error: e },
      error: true
    });
  }
}

function* fetchAllAppointmentTypes() {
  try {
    const response = yield call(axiosDefault.get, `/appointment_types`);

    yield put({
      type: FETCH_ALL_APPOINTMENT_TYPE_SUCCESS,
      payload: response.data
    });
  } catch (e) {
    yield put({
      type: FETCH_ALL_APPOINTMENT_TYPE_FAILURE,
      payload: { error: e, fallbackError: 'Error loading appointment types.' },
      error: true
    });
  }
}

export function* appointmentTypeFetch() {
  yield takeLatest(FETCH_APPOINTMENT_TYPE_REQUEST, fetchAppointmentType);
}

export function* appointmentTypeCreate() {
  yield takeLatest(CREATE_APPOINTMENT_TYPE_REQUEST, createAppointmentType);
}

export function* appointmentTypeUpdate() {
  yield takeLatest(UPDATE_APPOINTMENT_TYPE_REQUEST, updateAppointmentType);
}

export function* appointmentTypeDelete() {
  yield takeLatest(DELETE_APPOINTMENT_TYPE_REQUEST, deleteAppointmentType);
}

export function* fetchAllAppointmentType() {
  yield takeLatest(
    FETCH_ALL_APPOINTMENT_TYPE_REQUEST,
    fetchAllAppointmentTypes
  );
}
