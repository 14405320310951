import React, { useState } from 'react';
import UtilityCard from 'components/OverlordUtilities/shared/UtilityCard';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  createErrorMessageSelector,
  createLoadingSelector
} from 'appState/selectors';
import { bulkContactUnsubscriber } from 'appState/actions/ActionCreators';
import useProcessingActivity from 'components/shared/hooks/useProcessingActivity';
import useAccountDisabled from 'components/shared/hooks/useAccountDisabled';

const UTILITY_NAMES = ['bulk_contact_unsubscriber'];

const BulkContactUnsubscriber = () => {
  let fileField = null;
  const [unsubTypes, setUnsubTypes] = useState(null);

  const processingActivity = useProcessingActivity(UTILITY_NAMES);
  const accountDisabled = useAccountDisabled();

  const errorSelector = createErrorMessageSelector([
    'overlord/BULK_CONTACT_UNSUBSCRIBER'
  ]);
  const loadingSelector = createLoadingSelector([
    'overlord/BULK_CONTACT_UNSUBSCRIBER'
  ]);
  const structuredSelector = createStructuredSelector({
    error: state => errorSelector(state),
    isLoading: state => loadingSelector(state)
  });
  const { error, isLoading } = useSelector(structuredSelector);
  const dispatch = useDispatch();

  const unsubscribeContacts = () => {
    if (fileField && unsubTypes) {
      dispatch(
        bulkContactUnsubscriber({
          unsubTypes,
          file: fileField.files[0]
        })
      );
    }
  };

  const getCardBody = () => {
    return (
      <div className="form-row">
        <div className="form-group col-lg-6">
          <label htmlFor="file">Select CSV File for Upload</label>
          <div className="input-group file-group">
            <input
              type="text"
              className="form-control file-value"
              placeholder="Choose file..."
              readOnly=""
            />
            <input
              ref={field => {
                fileField = field;
              }}
              id="upload-csv"
              name="file"
              type="file"
              className="form-control"
              placeholder="Upload CSV"
            />
            <span className="input-group-append">
              <button className="btn btn-light file-browser" type="button">
                <i className="fa fa-upload" />
              </button>
            </span>
          </div>
        </div>
        <div className="form-group col-lg-6">
          <label htmlFor="unsubType">Select Unsubscribe Type(s)</label>
          <select
            onChange={e =>
              setUnsubTypes(
                [...e.target.options].filter(o => o.selected).map(o => o.value)
              )
            }
            id="unsubType"
            className="form-control"
            data-size="20"
            data-provide="selectpicker"
            multiple
            defaultValue=""
          >
            <option value="" disabled hidden>
              Nothing selected
            </option>
            <option value="email">Email - All communications</option>
            <option value="sms">SMS - Just SMS</option>
          </select>
        </div>
      </div>
    );
  };
  return (
    <UtilityCard
      title="Bulk Contact Unsubscriber"
      subtitle="Use this tool to unsubscribe contacts from email and/or SMS."
      submitBtnText="Unsubscribe Contacts"
      submitBtnAction={unsubscribeContacts}
      bodyComponent={getCardBody}
      isLoaded={!isLoading}
      processingActivity={processingActivity}
      accountDisabled={accountDisabled}
      error={error}
    />
  );
};

export default BulkContactUnsubscriber;
