/* eslint-disable import/no-unresolved */
/* eslint-disable import/prefer-default-export */
import { call, put, takeLatest } from 'redux-saga/effects';
import { axiosDefault } from 'lib/utils/axios-setup';
import {
  FETCH_ZEN_PLANNER_PROGRAMS_REQUEST,
  FETCH_ZEN_PLANNER_PROGRAMS_SUCCESS,
  FETCH_ZEN_PLANNER_PROGRAMS_FAILURE,
  FETCH_SELECTED_LINKED_ZEN_PLANNER_REQUEST,
  FETCH_SELECTED_LINKED_ZEN_PLANNER_SUCCESS,
  FETCH_SELECTED_LINKED_ZEN_PLANNER_FAILURE,
  CREATE_SYNC_ZP_PLANNER_WITH_PRODUCT_REQUEST,
  CREATE_SYNC_ZP_PLANNER_WITH_PRODUCT_SUCCESS,
  CREATE_SYNC_ZP_PLANNER_WITH_PRODUCT_FAILURE
} from 'app-state/actions/constants/zenplannersync.actions';

function* fetchZenPlannerPrograms() {
  try {
    const response = yield call(
      axiosDefault.get,
      `/zen_planner/sync_zp/membership_programs`
    );
    yield put({
      type: FETCH_ZEN_PLANNER_PROGRAMS_SUCCESS,
      payload: {
        zenPlannerPrograms: response.data
      }
    });
  } catch (e) {
    yield put({
      type: FETCH_ZEN_PLANNER_PROGRAMS_FAILURE,
      payload: {
        error: e,
        fallbackError: 'Error loading zen planner programs'
      },
      error: true
    });
  }
}

function* fetchSelectedLinkedZenPlannerPrograms() {
  try {
    const response = yield call(
      axiosDefault.get,
      `zen_planner/sync_zp/selected_programs`
    );
    yield put({
      type: FETCH_SELECTED_LINKED_ZEN_PLANNER_SUCCESS,
      payload: {
        selectedPrograms: response.data?.selected_programs,
        linkedZpPrograms: response.data?.linked_zp_programs
      }
    });
  } catch (e) {
    yield put({
      type: FETCH_SELECTED_LINKED_ZEN_PLANNER_FAILURE,
      payload: {
        error: e,
        fallbackError: 'Error loading seleted zen planner program'
      },
      error: true
    });
  }
}

function* createSyncZpPlannerWithProducts({
  payload: { selectedPrograms, linkedZpPrograms }
}) {
  const params = {
    selected_programs: selectedPrograms,
    linked_zp_programs: linkedZpPrograms
  };
  try {
    const response = yield call(
      axiosDefault.put,
      `zen_planner/sync_zp/update_programs`,
      params
    );

    yield put({
      type: CREATE_SYNC_ZP_PLANNER_WITH_PRODUCT_SUCCESS,
      payload: {
        data: response?.data
      }
    });
  } catch (e) {
    yield put({
      type: CREATE_SYNC_ZP_PLANNER_WITH_PRODUCT_FAILURE,
      payload: {
        error: e,
        fallbackError: 'Error sumbitming zp planner sync.'
      },
      error: true
    });
  }
}

export function* fetchZenPlannerProgram() {
  yield takeLatest(FETCH_ZEN_PLANNER_PROGRAMS_REQUEST, fetchZenPlannerPrograms);
}

export function* fetchSelectedLinkedZpPrograms() {
  yield takeLatest(
    FETCH_SELECTED_LINKED_ZEN_PLANNER_REQUEST,
    fetchSelectedLinkedZenPlannerPrograms
  );
}

export function* createSyncZpPlannerWithProduct() {
  yield takeLatest(
    CREATE_SYNC_ZP_PLANNER_WITH_PRODUCT_REQUEST,
    createSyncZpPlannerWithProducts
  );
}
