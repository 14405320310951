export const MATCH_LIMIT = 100;
export const ENTER_KEY_CODE = 13;
export const TABLE_ROW_LIMIT = 10;
export const ZP_STR = 'Zen Planner';
export const UP_STR = 'UpLaunch';

export const TIERS = ['basic', 'standard', 'premium'];

export const ZP_STATUS_SORT = {
  Member: 0,
  Alumni: 1,
  Prospect: 2,
  None: 3
};

export const DATE_TIME_FORMAT = 'MMMM Do [at] h:mm A';
export const DATE_TIME_FORMAT_TASK = 'MMMM Do';
export const ANSWER_TYPE_OPTIONS = [
  { displayName: 'Text', value: 'text' },
  { displayName: 'Text Area', value: 'text_area' },
  { displayName: 'Yes or No', value: 'yes_or_no' },
  { displayName: 'Date Picker', value: 'date_picker' },
  { displayName: 'Membership Hold Policy', value: 'membership_hold_policy' },
  { displayName: 'Cancellation Policy', value: 'cancellation_policy' },
  { displayName: 'Guest Waiver', value: 'guest_waiver' }
];
export const STAFF_NOTIFICATION_RECIPIENT_OPTIONS = [
  { displayName: 'Admin Only', value: 'admin' },
  {
    displayName: 'Admin & Managers',
    value: 'admin_managers'
  },
  {
    displayName: 'Assigned Staff Member Only',
    value: 'assigned_staff_member'
  },
  {
    displayName: 'Assigned Staff Member & Admin',
    value: 'assigned_staff_member_admin'
  },
  {
    displayName: 'All Staff Members',
    value: 'all_staff'
  }
];

export const PRODUCT_LIST_SORT = {
  'General Membership': 0,
  'Social Proof': 1,
  Reactivation: 2,
  Referral: 3,
  'Goal Setting': 4,
  Seasonal: 5,
  Youth: 6,
  'Martial Arts': 7,
  Yoga: 8,
  CrossFit: 9,
  'Drop-in': 10,
  'Punch Card': 11,
  'Personal Training': 12,
  Nutrition: 13,
  'Online Training': 14,
  Seniors: 15,
  Women: 16,
  Teen: 17,
  Corporate: 18,
  Custom: 19,
  Newsletter: 20,
  'Upsell/Cross-sell': 21,
  'Primary CTA': 22,
  'New Client': 23,
  'Enhanced Nurture': 24,
  NPS: 25,
  'Group - General': 26,
  'Group - Punch Card': 27,
  'Group - Women': 28,
  'Group - Men': 29,
  'Group - Seniors': 30,
  'Club Membership': 31,
  Weightlifting: 32,
  'Sports Performance': 33,
  'Teen Fitness': 34,
  'Child Fitness': 35
};
