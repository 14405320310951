import React, { useState } from 'react';
import PropTypes from 'prop-types';

const Pagination = props => {
  const { totalPages, action } = props;
  const [currentPage, setCurrentPage] = useState(1);

  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  const onPreviousPageClick = () => {
    const prevPage = currentPage - 1;
    if (currentPage !== 1) {
      scrollToTop();
      setCurrentPage(prevPage);
      if (action) action({ page: prevPage, totalPages });
    }
  };

  const onNextPageClick = () => {
    const nextPage = currentPage + 1;
    if (currentPage !== totalPages) {
      scrollToTop();
      setCurrentPage(nextPage);
      if (action) action({ page: nextPage, totalPages });
    }
  };

  const onDirectPageClick = pageNum => {
    scrollToTop();
    setCurrentPage(pageNum);
    if (action) action({ page: pageNum, totalPages });
  };

  const createPages = () => {
    const pagesArray = [];
    for (let i = 1; i <= totalPages; i += 1) {
      pagesArray.push(
        <li
          className={`page-item ${currentPage === i ? 'active' : ''}`}
          key={i}
        >
          <a
            className="page-link"
            href="#"
            onClick={() => onDirectPageClick(i)}
          >
            {i}
          </a>
        </li>
      );
    }
    return pagesArray;
  };

  return (
    <nav>
      <ul className="pagination">
        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
          <a
            className="page-link"
            href="#"
            onClick={() => onPreviousPageClick()}
          >
            <span className="ti-arrow-left" />
          </a>
        </li>
        {createPages()}
        <li
          className={`page-item ${
            currentPage === totalPages ? 'disabled' : ''
          }`}
        >
          <a className="page-link" href="#" onClick={() => onNextPageClick()}>
            <span className="ti-arrow-right" />
          </a>
        </li>
      </ul>
    </nav>
  );
};

Pagination.defaultProps = {
  action: null
};

Pagination.propTypes = {
  totalPages: PropTypes.number.isRequired,
  action: PropTypes.func
};

export default Pagination;
