import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Styled from './Chip.styles';

const sizes = {
  small: {
    textSize: 12,
    iconSize: 18,
    height: 26
  },
  medium: {
    textSize: 14,
    iconSize: 20,
    height: 28
  },
  large: {
    textSize: 16,
    iconSize: 22,
    height: 30
  }
};

const Chip = props => {
  const {
    text,
    color,
    size,
    clickable,
    onClick,
    deletable,
    onDelete,
    hasLeftMargin
  } = props;
  const [show, setShow] = useState(true);

  function handleDelete() {
    setShow(false);
    if (deletable && onDelete) onDelete();
  }

  function handleClick() {
    if (clickable && onClick) onClick();
  }

  return (
    <div onClick={handleClick} onKeyDown={null} role="button" tabIndex={-1}>
      {show && (
        <Styled.ChipContainer hasLeftMargin={hasLeftMargin}>
          <Styled.ChipContent
            style={{ height: `${sizes[size].height}px` }}
            className={`bg-pale-${color} text-${color}`}
          >
            <Styled.Chip className={`fs-${sizes[size].textSize}`}>
              {text}
            </Styled.Chip>
            {deletable && (
              <i
                className={`fa fa-times-circle fs-${sizes[size].iconSize} pr-2 cursor-pointer`}
                onClick={handleDelete}
                onKeyDown={null}
                role="button"
                tabIndex={-1}
              />
            )}
          </Styled.ChipContent>
        </Styled.ChipContainer>
      )}
    </div>
  );
};

Chip.defaultProps = {
  text: '',
  color: 'info',
  size: 'medium',
  clickable: false,
  onClick: null,
  deletable: false,
  onDelete: null,
  hasLeftMargin: false
};

Chip.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  clickable: PropTypes.bool,
  onClick: PropTypes.func,
  deletable: PropTypes.bool,
  onDelete: PropTypes.func,
  hasLeftMargin: PropTypes.bool
};

export default Chip;
