import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

const SendScheduleConfirmationModal = props => {
  const {
    saveAction,
    sendingType,
    scheduleForDateTime,
    audienceCount,
    templateType
  } = props;
  return (
    <div
      className="modal fade show"
      id="send-schedule-confirmation-modal"
      tabIndex="-1"
      aria-modal="true"
      aria-hidden="true"
      style={{ display: 'none' }}
    >
      <div className="modal-dialog modal-md">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Are you sure?</h4>
            <button type="button" className="close" data-dismiss="modal">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <p>
              You&apos;re about to{' '}
              {sendingType === 'send_now' ? 'send' : 'schedule'} a Broadcast{' '}
              {templateType} to <strong>{audienceCount} contacts</strong>.{' '}
              {sendingType === 'send_now'
                ? 'It will send immediately.'
                : `It is scheduled to send ${moment(scheduleForDateTime).format(
                    'MMMM Do [at] h:mm A'
                  )}.`}
            </p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-danger"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-info"
              data-dismiss="modal"
              onClick={saveAction}
            >
              Let&apos;s go!
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

SendScheduleConfirmationModal.propTypes = {
  saveAction: PropTypes.func.isRequired,
  sendingType: PropTypes.oneOf(['send_now', 'schedule', 'save_as_template'])
    .isRequired,
  scheduleForDateTime: PropTypes.instanceOf(Date).isRequired,
  audienceCount: PropTypes.number.isRequired,
  templateType: PropTypes.oneOf(['Email', 'SMS']).isRequired
};

export default SendScheduleConfirmationModal;
