import React from 'react';
import PropTypes from 'prop-types';
import EditScheduledBroadcastModal from 'components/BroadcastCenter/Broadcast/Index/EditScheduledBroadcastModal';
import { useDispatch } from 'react-redux';
import { broadcastsFetchContacts } from 'appState/actions/ActionCreators';
import BroadcastListItem from '../BroadcastListItem';
import BroadcastListItemActions from '../BroadcastListItemActions';

const ScheduledListItem = props => {
  const { broadcast } = props;
  const dispatch = useDispatch();

  const getBroadcastEmails = metric => {
    dispatch(
      broadcastsFetchContacts({
        broadcastId: broadcast.id,
        broadcastMetric: metric,
        broadcastType: broadcast.type === 'EmailBroadcast' ? 'email' : 'sms'
      })
    );
  };

  return (
    <div>
      <BroadcastListItem
        broadcast={broadcast}
        badgeType="purple"
        timestampAttr={broadcast.scheduled_for}
        timestampPretext="to send"
      >
        <div className="col-sm-6 d-flex justify-content-end">
          <div className="p-0">
            <div className="w-80px d-flex flex-column align-items-center">
              <a
                onClick={() => getBroadcastEmails('scheduled')}
                href="#"
                className="metrics-modal-link text-center"
                data-target="#broadcast-center-metrics-modal"
                data-toggle="modal"
                style={{ borderBottom: '1px dotted' }}
              >
                <small>Scheduled</small>
                <h3>{broadcast.scheduled_count}</h3>
              </a>
            </div>
          </div>
          <BroadcastListItemActions broadcast={broadcast} />
        </div>
      </BroadcastListItem>
      <EditScheduledBroadcastModal />
    </div>
  );
};

const broadcastShape = {
  scheduled_for: PropTypes.string,
  scheduled_count: PropTypes.number
};

ScheduledListItem.propTypes = {
  broadcast: PropTypes.shape(broadcastShape).isRequired
};

export default ScheduledListItem;
