import React from 'react';
import { action } from '@storybook/addon-actions';
import { withA11y } from '@storybook/addon-a11y';
import { withKnobs, object } from '@storybook/addon-knobs';
import NavBar from './index';

export default {
  component: NavBar,
  title: 'Nav Bar',
  decorators: [withA11y, withKnobs]
};

export const standard = () => {
  return <NavBar navLinks={object('Breadcrumbs', [])} />;
};

export const withNavLinks = () => {
  return (
    <NavBar
      navLinks={object('Breadcrumbs', [
        {
          title: 'Home',
          onClick: action('nav-bar-home-click')
        },
        {
          title: 'Metrics',
          onClick: action('nav-bar-metrics-click')
        },
        {
          title: 'Settings',
          onClick: action('nav-bar-settings-click')
        }
      ])}
    />
  );
};
