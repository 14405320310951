import React, { useEffect, useReducer, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import {
  createErrorMessageSelector,
  createLoadedSelector
} from 'appState/selectors';
import isEmpty from 'lodash.isempty';
import {
  plansFetch,
  resetIsLoaded,
  fetchPartners,
  setOverlordLayoutHeaderText
} from 'appState/actions/ActionCreators';
import { TIERS } from 'config/constants';
import Accordion from 'components/Theme/Accordion';
import HeroIcon from 'components/Theme/HeroIcon';
import Select from 'components/Theme/Select';
import Notification from 'components/Theme/Notification';
import PageHeader from 'components/Theme/PageHeader';
import { DELETE_PLAN } from 'appState/actions/constants/plan.actions';
import AccordionContent from '../AccordionContent';

const SUCCESS = 'SUCCESS';
const ERROR = 'ERROR';
const HIDE = 'HIDE';

const notificationIntialState = {
  notificationHeaderText: '',
  notificationMessage: '',
  notificationColor: '',
  show: false
};

const notificationReducer = (state, action) => {
  switch (action.type) {
    case SUCCESS: {
      return {
        ...state,
        notificationHeaderText: 'Success!',
        notificationColor: 'success',
        notificationMessage: action.message,
        show: true
      };
    }
    case ERROR: {
      return {
        ...state,
        notificationHeaderText: 'Error!',
        notificationColor: 'error',
        notificationMessage: action.message,
        show: true
      };
    }
    case HIDE: {
      return {
        ...state,
        show: false
      };
    }
    default:
      return state;
  }
};

const PlanIndex = () => {
  const expandedAccordions = useRef([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const [partnersList, setPartnersList] = useState([]);
  const [partnerSelectedValue, setPartnerSelectedValue] = useState('');
  const [notification, dispatchNotification] = useReducer(
    notificationReducer,
    notificationIntialState
  );

  const partnersLoadedSelector = createLoadedSelector([
    'partner/FETCH_PARTNERS'
  ]);
  const loadedSelectorDelete = createLoadedSelector([DELETE_PLAN]);
  const errorSelectorDelete = createErrorMessageSelector([DELETE_PLAN]);

  const structuredSelector = createStructuredSelector({
    plans: state => state.plan.plans,
    partners: state => state.partner.partners,
    isDeletePlanLoaded: state => loadedSelectorDelete(state),
    deletePlanError: state => errorSelectorDelete(state),
    isPartnersLoaded: state => partnersLoadedSelector(state)
  });

  const {
    plans,
    partners,
    isDeletePlanLoaded,
    deletePlanError,
    isPartnersLoaded
  } = useSelector(structuredSelector);

  useEffect(() => {
    dispatch(setOverlordLayoutHeaderText('Plans'));
    dispatch(fetchPartners());
  }, []);

  useEffect(() => {
    if (isPartnersLoaded) {
      const p = partners.map(partner => {
        return { displayName: partner.name, value: partner.id };
      });
      setPartnersList(p);
      dispatch(plansFetch({}));
    }
  }, [isPartnersLoaded, dispatch]);

  useEffect(() => {
    if (isDeletePlanLoaded) {
      if (isEmpty(deletePlanError)) {
        dispatchNotification({
          type: SUCCESS,
          message: 'Plan delete successful.'
        });
      } else {
        dispatchNotification({ type: ERROR, message: deletePlanError });
      }
    }
    dispatch(resetIsLoaded(DELETE_PLAN));
  }, [isDeletePlanLoaded, deletePlanError, dispatch]);

  function handlePartnerChange(partnerId) {
    setPartnerSelectedValue(partnerId);
    dispatch(plansFetch({ partnerId }));
  }

  function handleNotificationSetShow(show) {
    if (!show) dispatchNotification({ type: HIDE });
  }

  const getTierPlans = tier => {
    const tierPlans = plans.filter(plan => plan.tier === tier);
    return tierPlans.map(plan => {
      return { ...plan, connect: plan.visible };
    });
  };

  const handleLabelClick = tier => {
    if (expandedAccordions.current.includes(tier)) {
      expandedAccordions.current = [
        ...expandedAccordions.current.filter(val => val !== tier)
      ];
    } else {
      expandedAccordions.current = [...expandedAccordions.current, tier];
    }
  };

  const isExpanded = tier => {
    return expandedAccordions.current.includes(tier);
  };

  const HandlePlanView = () => {
    return TIERS.map(tier => {
      return (
        <Accordion
          key={tier}
          label={tier}
          onLabelClick={() => handleLabelClick(tier)}
          isExpanded={isExpanded(tier)}
          contentCentered
        >
          <AccordionContent tierPlans={getTierPlans(tier)} tier={tier} />
        </Accordion>
      );
    });
  };

  return (
    <div className="tw-min-h-screen">
      <div className="xxs:tw-w-11/12 tw-mx-auto tw-my-4 tw-min-w-300 tw-rounded-lg tw-shadow-md tw-bg-white tw-flex-col">
        <div className="tw-max-h-screen tw-flex tw-rounded-b-lg tw-p-5 tw-bg-gray-200 tw-overflow-auto">
          <div className="tw-mx-auto">
            <Select
              value={partnerSelectedValue}
              id="appointmentTypes"
              labelText="Choose Partner for which you want to manage plans"
              options={partnersList}
              onChange={e => handlePartnerChange(e.target.value)}
            />
          </div>
        </div>
      </div>
      <HandlePlanView />
      {notification.show && (
        <Notification
          HeaderIcon={color => {
            return (
              <HeroIcon
                icon={
                  notification.notificationColor === 'success'
                    ? 'check-circle'
                    : 'x-circle'
                }
                widthClasses="tw-w-5"
                heightClasses="tw-h-5"
                color={color}
              />
            );
          }}
          color={notification.notificationColor}
          headerText={notification.notificationHeaderText}
          message={notification.notificationMessage}
          setShowAction={handleNotificationSetShow}
          show={notification.show}
          type="colored"
        />
      )}
    </div>
  );
};

export default PlanIndex;
