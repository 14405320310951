import { createAction } from 'redux-actions';
import {
  FETCH_AVAILABLE_PHONE_NUMBERS_REQUEST,
  PURCHASE_NUMBER_FOR_ACCOUNT_REQUEST,
  ASSIGN_NUMBER_FOR_ACCOUNT_REQUEST,
  CAMPAIGN_PAUSER_PAUSE_REQUEST,
  CAMPAIGN_PAUSER_UNPAUSE_REQUEST,
  ACCOUNT_DISABLER_DISABLE_REQUEST,
  ACCOUNT_ENABLER_ENABLE_REQUEST,
  RECAPTCHA_DISABLER_DISABLE_REQUEST,
  RECAPTCHA_ENABLER_ENABLE_REQUEST,
  BULK_JOURNEY_CHANGER_REQUEST,
  JOURNEYS_FETCH_REQUEST,
  CUSTOM_GROUP_ASSIGNER_REQUEST,
  CUSTOM_GROUPS_FETCH_REQUEST,
  USERS_FETCH_REQUEST,
  ACCOUNT_ADMIN_CHANGER_REQUEST,
  SUBDOMAIN_CHANGER_REQUEST,
  FETCH_SUBDOMAINS_REQUEST,
  CONTACTS_FETCH_REQUEST,
  MERGE_CONTACTS_REQUEST,
  BULK_CAMPAIGN_CHANGER_REQUEST,
  CAMPAIGNS_FETCH_REQUEST,
  REASSIGN_USER_OBJECTS_REQUEST,
  CLONE_USER_AVAILABILITY_REQUEST,
  FETCH_RECENT_ACTIVITY_REQUEST,
  FETCH_PROCESSING_ACTIVITIES_REQUEST,
  START_FETCH_PROCESSING_WATCHER,
  STOP_FETCH_PROCESSING_WATCHER,
  RESET_IS_LOADED_REQUEST,
  ONBOARDING_DISABLER_DISABLE_REQUEST,
  ONBOARDING_ENABLER_ENABLE_REQUEST,
  BULK_CONTACT_UNSUBSCRIBER_REQUEST,
  CUSTOM_GROUPS_CREATE_REQUEST,
  SET_RECAPTCHA_DISABLED,
  SET_OVERLORD_LAYOUT_HEADER_TEXT
} from './constants/overlord.actions';

import {
  SET_CURRENT_ACCOUNT,
  BROADCAST_SET_STEP,
  BROADCAST_SEND_TEST_EMAIL_REQUEST,
  RESET_TEST_EMAIL_SENT,
  BROADCASTS_FETCH_REQUEST,
  BROADCASTS_PAGE_CHANGE_REQUEST,
  BROADCASTS_RESET_PAGE_CHANGE,
  BROADCASTS_FETCH_CURRENT_BROADCAST_REQUEST,
  BROADCASTS_FETCH_CURRENT_BROADCAST_TEMPLATE_REQUEST,
  BROADCASTS_FETCH_CONTACTS_REQUEST,
  BROADCAST_RESET_CONTACTS,
  BROADCASTS_FETCH_EMAILS_REQUEST,
  BROADCAST_RESET_EMAILS,
  BROADCASTS_FETCH_SMS_REQUEST,
  BROADCAST_RESET_SMS,
  BROADCAST_FETCH_PREVIEW_REQUEST,
  BROADCAST_RESET_PREVIEW_DATA,
  BROADCAST_DELETE_REQUEST,
  BROADCAST_SET_SELECTED_BROADCAST,
  BROADCAST_TEMPLATES_FETCH_REQUEST,
  BROADCAST_TEMPLATES_PAGE_CHANGE_REQUEST,
  BROADCAST_TEMPLATES_CREATE_BROADCAST_REQUEST,
  BROADCASTS_FETCH_EMAIL_MERGE_TAGS_REQUEST,
  BROADCAST_SET_TEST_EMAIL_ADDRESS,
  BROADCAST_SET_PREVIEW_EMAIL_FIRED,
  BROADCASTS_FETCH_SMS_MERGE_TAGS_REQUEST,
  BROADCASTS_TEMPLATE_FETCH_SMS_MERGE_TAGS_REQUEST,
  BROADCAST_SAVE_SMS_REQUEST,
  BROADCAST_SAVE_AS_TEMPLATE_REQUEST,
  BROADCAST_SEND_AGAIN_REQUEST,
  BROADCAST_EDIT_SCHEDULED_BROADCAST_REQUEST,
  BROADCAST_UPDATE_BROADCAST_REQUEST,
  BROADCAST_TEMPLATE_DELETE_REQUEST,
  BROADCAST_SET_SELECTED_BROADCAST_TEMPLATE,
  BROADCAST_SAVE_EMAIL_REQUEST,
  BROADCAST_CALL_EMAIL_SAVE_ACTION,
  BROADCAST_RESET_EMAIL_SAVE_ACTION,
  BROADCAST_SET_EMAIL_SUBJECT,
  BROADCAST_SCHEDULE_REQUEST,
  BROADCAST_FETCH_AUDIENCE_FILTERS_REQUEST,
  BROADCAST_UPDATE_AUDIENCE_REQUEST,
  BROADCAST_FETCH_AUDIENCE_COUNT_REQUEST,
  BROADCAST_FETCH_FILTER_CONTACTS_COUNT_REQUEST,
  BROADCAST_SET_HAS_CONTENT_CHANGED,
  BROADCAST_SET_BEE_EDITOR_HAS_LOADED,
  BROADCAST_SET_IS_TEMPLATE,
  BROADCAST_TEMPLATE_SAVE_DETAILS_REQUEST,
  BROADCAST_TEMPLATE_CREATE_REQUEST,
  BROADCAST_UPDATE_TEMPLATE_REQUEST,
  BROADCAST_SET_SELECTED_SUGGESTIONS,
  BROADCAST_SET_SELECTED_FILTERS,
  BROADCAST_RESET_TO_INITIAL_STATE,
  ENABLE_OLD_BROADCAST_CENTER_REQUEST,
  BROADCAST_SEARCH_CONTACTS_BY_FILE_REQUEST,
  BROADCAST_CREATE_CUSTOM_GROUP_REQUEST,
  BROADCAST_PRODUCTS_FETCH_REQUEST,
  BROADCAST_TEMPLATE_REASSIGN_REQUEST
} from './constants/broadcastCenter.actions';

import {
  SMS_INBOX_FETCH_CONVERSATION_REQUEST,
  SMS_INBOX_CONVERSATION_PAGE_CHANGE_REQUEST,
  SMS_INBOX_FETCH_REQUEST,
  SMS_INBOX_PAGE_CHANGE_REQUEST,
  SMS_INBOX_MARK_AS_READ_REQUEST,
  SMS_INBOX_SEND_MESSAGE_REQUEST,
  SMS_INBOX_MESSAGE_LIST_ITEM_LAST_MESSAGE_UPDATE,
  SMS_INBOX_CURRENT_CONVERSATION_ADD_MESSAGE
} from './constants/smsInbox.actions';

import {
  PURCHASE_PAGE_CREATE_SUBSCRIPTION_REQUEST,
  PURCHASE_PAGE_FETCH_PRODUCT_PLAN_INFO_REQUEST
} from './constants/purchase.actions';

import {
  FETCH_PLANS_REQUEST,
  FETCH_PLAN_PAGE_REQUEST,
  CREATE_PLAN_REQUEST,
  UPDATE_PLAN_REQUEST,
  DELETE_PLAN_REQUEST,
  AVAILABLE_PLAN_REQUEST,
  RESET_CURRENT_PLAN,
  FETCH_SMS_CREDIT_PACKS_REQUEST,
  FETCH_SMS_CREDIT_PACK_REQUEST,
  CREATE_SMS_CREDIT_PACK_REQUEST,
  UPDATE_SMS_CREDIT_PACK_REQUEST,
  DELETE_SMS_CREDIT_PACK_REQUEST,
  FETCH_ONBOARDING_FEES_REQUEST,
  FETCH_ONBOARDING_FEE_REQUEST,
  CREATE_ONBOARDING_FEE_REQUEST,
  UPDATE_ONBOARDING_FEE_REQUEST,
  DELETE_ONBOARDING_FEE_REQUEST
} from './constants/plan.actions';

import {
  FETCH_PRODUCT_REQUEST,
  CREATE_PRODUCT_REQUEST,
  UPDATE_PRODUCT_REQUEST,
  FETCH_INCLUDED_FORM_REQUEST,
  FETCH_STAFF_SMS_MERGE_FIELDS_REQUEST,
  FETCH_STAFF_EMAIL_MERGE_FIELDS_REQUEST,
  CREATE_INCLUDED_FORM_REQUEST,
  UPDATE_INCLUDED_FORM_REQUEST,
  DELETE_PRODUCT_REQUEST,
  TOGGLE_PUBLISH_PRODUCT_REQUEST,
  FETCH_PRODUCTS_CATEGORIES_REQUEST
} from './constants/product.actions';

import {
  FETCH_MY_PRODUCT_SUBSCRIPTIONS_REQUEST,
  FETCH_PRODUCT_SUBSCRIPTION_REQUEST,
  UPDATE_PRODUCT_SUBSCRIPTION_REQUEST,
  UNPAUSE_PRODUCT_SUBSCRIPTION_REQUEST,
  PAUSE_PRODUCT_SUBSCRIPTION_REQUEST,
  REMOVE_PRODUCT_SUBSCRIPTION_REQUEST,
  CREATE_PRODUCT_SUBSCRIPTION_REQUEST,
  FETCH_ADD_CONTACTS_FILTERS_REQUEST,
  ADD_CONTACTS_TO_PRODUCT_REQUEST,
  FETCH_CONTACTS_COUNT_REQUEST,
  VIEW_CONTACTS_IN_PRODUCT_CAMPAIGN_REQUEST,
  UPDATE_PROCESS_ADD_CONTACTS_AT,
  CLEAR_VIEW_CONTACTS_MODAL_CONTACTS
} from './constants/productSubscription.actions';

import {
  CREATE_CONTACT_REQUEST,
  FETCH_CONTACT_REQUEST,
  FETCH_NEXT_CAMPAIGN_TOUCHPOINT_REQUEST,
  FETCH_CONTACT_AUTOMATIONS_REQUEST,
  FETCH_CONTACT_CUSTOM_GROUPS_REQUEST,
  UPDATE_CONTACT_CUSTOM_GROUPS_REQUEST,
  FETCH_CURRENT_CONTACT_CTA_REQUEST,
  SET_CURRENT_CONTACT_TAB,
  VALIDATE_CONTACTS_CSV_REQUEST,
  VALIDATE_SINGLE_CONTACT_CSV_REQUEST,
  SAVE_CONTACTS_CSV_REQUEST,
  CREATE_CSV_IMPORT_REQUEST,
  UPDATE_CSV_IMPORT_REQUEST,
  FETCH_CSV_IMPORT_REQUEST,
  DELETE_CSV_IMPORT_REQUEST,
  SET_CSV_VALIDATION_ERROR,
  SCHEDULE_CSV_IMPORT_REQUEST,
  FETCH_CURRENT_AUTOMATION_DETAILS_REQUEST,
  PAUSE_AUTOMATION_REQUEST,
  UNPAUSE_AUTOMATION_REQUEST,
  UPDATE_CURRENT_AUTOMATION_COMPLETE_REQUEST,
  NEW_AUTOMATION_LIFECYCLE_CHECK_REQUEST,
  FETCH_ACCOUNT_CAMPAIGNS_REQUEST,
  FETCH_CAMPAIGN_STEP_LIST_REQUEST,
  CREATE_AUTOMATION_REQUEST,
  UPDATE_CONTACT_REQUEST,
  FETCH_CAMPAIGNS_LIST_REQUEST,
  NEW_LIFECYCLE_CONFIRMATION_REQUEST,
  UPDATE_CONTACT_LIFECYCLE_REQUEST,
  CREATE_CONTACT_NOTE_REQUEST,
  CREATE_FILE_UPLOAD_REQUEST,
  FETCH_APPOINTMENT_TYPES_REQUEST,
  SET_CURRENT_APPOINTMENT_TYPE_ID,
  FETCH_UPLOADED_FILES_LIST_REQUEST,
  ENABLE_NEW_CONTACT_PROFILE_REQUEST,
  CREATE_EMAIL_REQUEST,
  DELETE_CONTACT_REQUEST,
  UNSUBSCRIBE_CONTACT_REQUEST,
  RESUBSCRIBE_CONTACT_REQUEST,
  REMOVE_SPAM_COMPLAINT_REQUEST,
  FETCH_IMPORT_HISTORY_REQUEST,
  FETCH_NEXT_APPOINTMENT_REQUEST,
  FETCH_NEXT_TASK_REQUEST
} from './constants/contact.actions';

import {
  MATCHED_CONTACTS_FETCH_REQUEST,
  MEMBERS_SYNC_REQUEST,
  SYNC_WIZARD_UPDATE_EXACT_MATCH_DATA,
  SYNC_WIZARD_UPDATE_NAME_MATCH_DATA,
  SYNC_WIZARD_UPDATE_EMAIL_MATCH_DATA,
  SYNC_WIZARD_UPDATE_CAMPAIGN_MATCH_DATA,
  SYNC_WIZARD_UPDATE_NEW_UPLAUNCH_DATA,
  SYNC_WIZARD_UPDATE_NEW_ZENPLANNER_DATA,
  SYNC_WIZARD_INITIALIZE_CAMPAIGN_DATA,
  SYNC_WIZARD_INITIALIZE_NEW_CONTACTS_DATA,
  SET_SYNC_TO_REQUIRED_REQUEST,
  SYNC_WIZARD_FETCH_TEST_LOG_REQUEST,
  FETCH_SYNC_MODE_REQUEST,
  FETCH_SYNC_STATUS_REQUEST,
  SET_SYNC_WIZARD_ACCOUNT,
  STATS_FETCH_REQUEST,
  LAST_SYNC_OUTPUT_LOG_REQUEST,
  START_LAST_SYNC_OUTPUT_LOG_WATCHER,
  STOP_LAST_SYNC_OUTPUT_LOG_WATCHER,
  START_CHECK_SYNC_STATUS_WATCHER,
  STOP_CHECK_SYNC_STATUS_WATCHER,
  INITIALIZE_SYNC_WIZARD_REQUEST,
  SWITCH_OFF_TEST_MODE_REQUEST
} from './constants/syncWizard.actions';

import {
  FETCH_SUBSCRIPTIONS_REQUEST,
  FETCH_SUBSCRIPTION_REQUEST,
  FETCH_PRODUCTS_REQUEST,
  UPDATE_SUBSCRIPTION_REQUEST,
  PAGE_CHANGE_SUBSCRIPTIONS_REQUEST,
  DOWNGRADE_SUBSCRIPTION_REQUEST,
  CANCEL_DOWNGRADE_SUBSCRIPTION_REQUEST,
  FETCH_MY_SUBSCRIPTION_REQUEST,
  UPGRADE_SUBSCRIPTION_REQUEST,
  UPDATE_CARD_SUBSCRIPTION_REQUEST,
  UPDATE_DEFAULT_PRODUCT_REQUEST,
  CURRENT_SUBSCRIPTIONS_RESET,
  UNDO_CANCELLATION_SUBSCRIPTION_REQUEST
} from './constants/subscription.actions';
import {
  FETCH_APPOINTMENT_BOOKER_REQUEST,
  CREATE_APPOINTMENT_FROM_BOOKER_REQUEST,
  FETCH_APPOINTMENT_REQUEST,
  UPDATE_APPOINTMENT_REQUEST,
  RESET_APPOINTMENT_BOOKER
} from './constants/appointmentBooker.actions';

import {
  CREATE_TASK_REQUEST,
  UPDATE_TASK_REQUEST,
  FETCH_TASK_REQUEST
} from './constants/task.actions';

import {
  FETCH_CONTACT_FORM_TYPES_REQUEST,
  FETCH_CONTACT_FORM_TYPE_REQUEST
} from './constants/contactFormType.actions';

import {
  CREATE_CONTACT_FORM_REQUEST,
  UPDATE_CONTACT_FORM_REQUEST,
  DELETE_CONTACT_FORM_REQUEST
} from './constants/contactForm.actions';

import {
  FETCH_PINNED_ACTIVITIES_REQUEST,
  FETCH_ACTIVITIES_REQUEST,
  ACTIVITIES_PAGE_CHANGE_REQUEST,
  UPDATE_ACTIVITY_REQUEST,
  UPDATE_ACTIVITIES_FEED,
  FETCH_PINNED_SMS_ACTIVITIES_REQUEST,
  FETCH_SMS_ACTIVITIES_REQUEST,
  SMS_ACTIVITIES_PAGE_CHANGE_REQUEST,
  UPDATE_SMS_ACTIVITY_REQUEST,
  FETCH_PINNED_EMAIL_ACTIVITIES_REQUEST,
  FETCH_EMAIL_ACTIVITIES_REQUEST,
  EMAIL_ACTIVITIES_PAGE_CHANGE_REQUEST,
  UPDATE_EMAIL_ACTIVITY_REQUEST,
  FETCH_PINNED_APPOINTMENTS_ACTIVITIES_REQUEST,
  FETCH_APPOINTMENTS_ACTIVITIES_REQUEST,
  APPOINTMENTS_ACTIVITIES_PAGE_CHANGE_REQUEST,
  UPDATE_APPOINTMENTS_ACTIVITY_REQUEST,
  FETCH_PINNED_TASKS_ACTIVITIES_REQUEST,
  FETCH_TASKS_ACTIVITIES_REQUEST,
  TASKS_ACTIVITIES_PAGE_CHANGE_REQUEST,
  UPDATE_TASKS_ACTIVITY_REQUEST,
  FETCH_PINNED_NOTES_ACTIVITIES_REQUEST,
  FETCH_NOTES_ACTIVITIES_REQUEST,
  NOTES_ACTIVITIES_PAGE_CHANGE_REQUEST,
  UPDATE_NOTES_ACTIVITY_REQUEST,
  FETCH_PINNED_FORMS_ACTIVITIES_REQUEST,
  FETCH_FORMS_ACTIVITIES_REQUEST,
  FORMS_ACTIVITIES_PAGE_CHANGE_REQUEST,
  UPDATE_FORMS_ACTIVITY_REQUEST,
  FETCH_CONTACT_FORM_REQUEST,
  COMPLETE_TASK_REQUEST,
  EDIT_TASK_REQUEST,
  REASSIGN_TASK_REQUEST,
  CANCEL_TASK_REQUEST,
  RESCHEDULE_APPOINTMENT_REQUEST,
  MARK_APPOINTMENT_NO_SHOW_REQUEST,
  CANCEL_APPOINTMENT_REQUEST
} from './constants/activity.actions';

import {
  MARKET_PLACE_LIST_FETCH_REQUEST,
  PRODUCT_FETCH_REQUEST,
  CLEAR_CURRENT_PRODUCT_FROM_STATE
} from './constants/marketPlace.actions';

import {
  FETCH_APPOINTMENT_TYPE_REQUEST,
  CREATE_APPOINTMENT_TYPE_REQUEST,
  UPDATE_APPOINTMENT_TYPE_REQUEST,
  DELETE_APPOINTMENT_TYPE_REQUEST,
  FETCH_ALL_APPOINTMENT_TYPE_REQUEST
} from './constants/appointmentType.actions';

import {
  CREATE_JOURNEY_REQUEST,
  UPDATE_JOURNEY_REQUEST
} from './constants/journey.actions';

import { FETCH_OPT_IN_FORM_REQUEST } from './constants/optinform.actions';

import {
  FETCH_AUTO_COMPLETE_SUGGESTIONS_REQUEST,
  RESET_AUTO_COMPLETE_SUGGESTIONS
} from './constants/autoComplete.actions';

import { FETCH_BLOGS_REQUEST } from './constants/blog.actions';
import {
  FETCH_PARTNERS_REQUEST,
  FETCH_PARTNER_REQUEST
} from './constants/partner.actions';
import {
  ADD_FLASH_MESSAGE_ACTION_TYPE,
  CLEAR_FLASH_MESSAGES_ACTION_TYPE,
  REMOVE_FLASH_MESSAGE_ACTION_TYPE,
  FLASH_MESSAGE_ACTION_TYPE
} from './constants/flashMessage.actions';
import { FETCH_BACKGROUNDJOB_DATA_REQUEST } from './constants/backGroundJob.actions';
import {
  FETCH_ZEN_PLANNER_PROGRAMS_REQUEST,
  FETCH_SELECTED_LINKED_ZEN_PLANNER_REQUEST,
  CREATE_SYNC_ZP_PLANNER_WITH_PRODUCT_REQUEST
} from './constants/zenplannersync.actions';
import {
  CREATE_LEAD_SOURCE_REQUEST,
  FETCH_LEAD_SOURCES_REQUEST,
  BULK_UPDATE_CONTACTS_REQUEST
} from './constants/leadSources.actions';

const generateActionCreator = type => (payload, error = false) => ({
  type,
  payload,
  error
});

// SMS INBOX
export const smsInboxFetch = generateActionCreator(SMS_INBOX_FETCH_REQUEST);
export const smsInboxPageChange = generateActionCreator(
  SMS_INBOX_PAGE_CHANGE_REQUEST
);
export const smsInboxFetchConversation = generateActionCreator(
  SMS_INBOX_FETCH_CONVERSATION_REQUEST
);
export const smsInboxConversationPageChange = generateActionCreator(
  SMS_INBOX_CONVERSATION_PAGE_CHANGE_REQUEST
);
export const smsInboxMarkAsRead = generateActionCreator(
  SMS_INBOX_MARK_AS_READ_REQUEST
);
export const smsInboxSendMessage = generateActionCreator(
  SMS_INBOX_SEND_MESSAGE_REQUEST
);
export const smsInboxUpdateCurrentConversation = generateActionCreator(
  SMS_INBOX_CURRENT_CONVERSATION_ADD_MESSAGE
);
export const smsInboxUpdateMessageListItem = generateActionCreator(
  SMS_INBOX_MESSAGE_LIST_ITEM_LAST_MESSAGE_UPDATE
);

/**
 * OVERLORD UTILITIES
 */

/* General */
export const fetchUsers = generateActionCreator(USERS_FETCH_REQUEST);
export const resetIsLoaded = generateActionCreator(RESET_IS_LOADED_REQUEST);

/* Account Utilities */
// PHONE NUMBER CHANGER
export const fetchAvailablePhoneNumbers = generateActionCreator(
  FETCH_AVAILABLE_PHONE_NUMBERS_REQUEST
);
export const purchaseNumberForAccount = generateActionCreator(
  PURCHASE_NUMBER_FOR_ACCOUNT_REQUEST
);
// PHONE NUMBER ASSIGN
export const assignNumberForAccount = generateActionCreator(
  ASSIGN_NUMBER_FOR_ACCOUNT_REQUEST
);
// CAMPAIGN PAUSER
export const pauseCampaign = generateActionCreator(
  CAMPAIGN_PAUSER_PAUSE_REQUEST
);
export const unpauseCampaign = generateActionCreator(
  CAMPAIGN_PAUSER_UNPAUSE_REQUEST
);
// ACCOUNT ADMIN CHANGER
export const changeAccountAdmin = generateActionCreator(
  ACCOUNT_ADMIN_CHANGER_REQUEST
);
// ACCOUNT DISABLER
export const disableAccount = generateActionCreator(
  ACCOUNT_DISABLER_DISABLE_REQUEST
);
export const enableAccount = generateActionCreator(
  ACCOUNT_ENABLER_ENABLE_REQUEST
);
// RECAPTCHA DISABLER
export const disableRecaptcha = generateActionCreator(
  RECAPTCHA_DISABLER_DISABLE_REQUEST
);
export const enableRecaptcha = generateActionCreator(
  RECAPTCHA_ENABLER_ENABLE_REQUEST
);
export const setRecaptchaDisabled = generateActionCreator(
  SET_RECAPTCHA_DISABLED
);
// ACCOUNT SUBDOMAIN CHANGER
export const changeSubdomain = generateActionCreator(SUBDOMAIN_CHANGER_REQUEST);
export const fetchSubdomains = generateActionCreator(FETCH_SUBDOMAINS_REQUEST);

/* Staff Member Utilities */
// USER OBJECT REASSIGNER
export const reassignUserObjects = generateActionCreator(
  REASSIGN_USER_OBJECTS_REQUEST
);
// CLONE USER AVAILABILITY SLOTS
export const cloneUserAvailability = generateActionCreator(
  CLONE_USER_AVAILABILITY_REQUEST
);

/* Contact Utilities */
// CUSTOM GROUP ASSIGNER
export const assignCustomGroup = generateActionCreator(
  CUSTOM_GROUP_ASSIGNER_REQUEST
);
export const fetchCustomGroups = generateActionCreator(
  CUSTOM_GROUPS_FETCH_REQUEST
);
export const createCustomGroups = generateActionCreator(
  CUSTOM_GROUPS_CREATE_REQUEST
);
// BULK CAMPAIGN CHANGER
export const bulkChangeCampaign = generateActionCreator(
  BULK_CAMPAIGN_CHANGER_REQUEST
);

// BULK CONTACT UNSUBSCRIBER
export const bulkContactUnsubscriber = generateActionCreator(
  BULK_CONTACT_UNSUBSCRIBER_REQUEST
);
export const fetchCampaigns = generateActionCreator(CAMPAIGNS_FETCH_REQUEST);
// BULK JOURNEY CHANGER
export const bulkChangeJourney = generateActionCreator(
  BULK_JOURNEY_CHANGER_REQUEST
);
export const fetchJourneys = generateActionCreator(JOURNEYS_FETCH_REQUEST);
// CONTACT MERGER
export const fetchContacts = generateActionCreator(CONTACTS_FETCH_REQUEST);
export const mergeContacts = generateActionCreator(MERGE_CONTACTS_REQUEST);

/* Overlord Recent Activities */
export const fetchRecentActivity = generateActionCreator(
  FETCH_RECENT_ACTIVITY_REQUEST
);
export const fetchProcessingActivities = generateActionCreator(
  FETCH_PROCESSING_ACTIVITIES_REQUEST
);
export const startFetchProcessingWatcher = generateActionCreator(
  START_FETCH_PROCESSING_WATCHER
);
export const stopFetchProcessingWorker = generateActionCreator(
  STOP_FETCH_PROCESSING_WATCHER
);

// ONBOARDING DISABLER
export const deactivateOnboarding = generateActionCreator(
  ONBOARDING_DISABLER_DISABLE_REQUEST
);
export const activateOnboarding = generateActionCreator(
  ONBOARDING_ENABLER_ENABLE_REQUEST
);

// ENABLE_NEW_CONTACT_PROFILE
export const enableNewContactProfile = generateActionCreator(
  ENABLE_NEW_CONTACT_PROFILE_REQUEST
);

export const setOverlordLayoutHeaderText = generateActionCreator(
  SET_OVERLORD_LAYOUT_HEADER_TEXT
);

/**
 * BROADCAST CENTER
 */

export const setCurrentAccount = generateActionCreator(SET_CURRENT_ACCOUNT);
export const broadcastSetStep = generateActionCreator(BROADCAST_SET_STEP);
export const broadcastSendTestEmail = generateActionCreator(
  BROADCAST_SEND_TEST_EMAIL_REQUEST
);
export const resetTestEmailSent = generateActionCreator(RESET_TEST_EMAIL_SENT);
export const broadcastSetHasContentChanged = generateActionCreator(
  BROADCAST_SET_HAS_CONTENT_CHANGED
);
export const broadcastResetToInitialState = generateActionCreator(
  BROADCAST_RESET_TO_INITIAL_STATE
);

/* Index */
export const broadcastsFetch = generateActionCreator(BROADCASTS_FETCH_REQUEST);
export const broadcastsPageChange = generateActionCreator(
  BROADCASTS_PAGE_CHANGE_REQUEST
);
export const broadcastsResetPageChange = generateActionCreator(
  BROADCASTS_RESET_PAGE_CHANGE
);
export const broadcastsFetchCurrentBroadcast = generateActionCreator(
  BROADCASTS_FETCH_CURRENT_BROADCAST_REQUEST
);
export const broadcastsFetchContacts = generateActionCreator(
  BROADCASTS_FETCH_CONTACTS_REQUEST
);
export const broadcastResetContacts = generateActionCreator(
  BROADCAST_RESET_CONTACTS
);
export const broadcastsFetchEmails = generateActionCreator(
  BROADCASTS_FETCH_EMAILS_REQUEST
);
export const broadcastResetEmails = generateActionCreator(
  BROADCAST_RESET_EMAILS
);
export const broadcastsFetchSms = generateActionCreator(
  BROADCASTS_FETCH_SMS_REQUEST
);
export const broadcastResetSms = generateActionCreator(BROADCAST_RESET_SMS);
export const broadcastSaveAsTemplate = generateActionCreator(
  BROADCAST_SAVE_AS_TEMPLATE_REQUEST
);
export const broadcastSendAgain = generateActionCreator(
  BROADCAST_SEND_AGAIN_REQUEST
);
export const broadcastEditScheduledBroadcast = generateActionCreator(
  BROADCAST_EDIT_SCHEDULED_BROADCAST_REQUEST
);
export const broadcastUpdate = generateActionCreator(
  BROADCAST_UPDATE_BROADCAST_REQUEST
);
export const broadcastSetTestEmailAddress = generateActionCreator(
  BROADCAST_SET_TEST_EMAIL_ADDRESS
);
export const broadcastSetPreviewEmailFired = generateActionCreator(
  BROADCAST_SET_PREVIEW_EMAIL_FIRED
);
export const enableOldBroadcastCenter = generateActionCreator(
  ENABLE_OLD_BROADCAST_CENTER_REQUEST
);

/* Email/SMS */
export const broadcastFetchPreview = generateActionCreator(
  BROADCAST_FETCH_PREVIEW_REQUEST
);
export const broadcastResetPreviewData = generateActionCreator(
  BROADCAST_RESET_PREVIEW_DATA
);
export const broadcastDelete = generateActionCreator(BROADCAST_DELETE_REQUEST);
export const broadcastSetSelected = generateActionCreator(
  BROADCAST_SET_SELECTED_BROADCAST
);
export const broadcastFetchEmailMergeTags = generateActionCreator(
  BROADCASTS_FETCH_EMAIL_MERGE_TAGS_REQUEST
);
export const broadcastFetchSmsMergeTags = generateActionCreator(
  BROADCASTS_FETCH_SMS_MERGE_TAGS_REQUEST
);
export const broadcastSaveSms = generateActionCreator(
  BROADCAST_SAVE_SMS_REQUEST
);
export const broadcastSaveEmail = generateActionCreator(
  BROADCAST_SAVE_EMAIL_REQUEST
);
export const broadcastCallSaveEmailAction = generateActionCreator(
  BROADCAST_CALL_EMAIL_SAVE_ACTION
);
export const broadcastResetEmailSaveAction = generateActionCreator(
  BROADCAST_RESET_EMAIL_SAVE_ACTION
);
export const broadcastSetEmailSubject = generateActionCreator(
  BROADCAST_SET_EMAIL_SUBJECT
);
export const broadcastSetBeeHasLoaded = generateActionCreator(
  BROADCAST_SET_BEE_EDITOR_HAS_LOADED
);

/* Select Template */
export const broadcastTemplatesFetch = generateActionCreator(
  BROADCAST_TEMPLATES_FETCH_REQUEST
);
export const broadcastTemplatesPageChange = generateActionCreator(
  BROADCAST_TEMPLATES_PAGE_CHANGE_REQUEST
);
export const broadcastTemplatesCreateBroadcast = generateActionCreator(
  BROADCAST_TEMPLATES_CREATE_BROADCAST_REQUEST
);
export const broadcastTemplateDelete = generateActionCreator(
  BROADCAST_TEMPLATE_DELETE_REQUEST
);
export const broadcastTemplateSetSelected = generateActionCreator(
  BROADCAST_SET_SELECTED_BROADCAST_TEMPLATE
);
export const broadcastFetchProducts = generateActionCreator(
  BROADCAST_PRODUCTS_FETCH_REQUEST
);
export const broadcastTemplateReassign = generateActionCreator(
  BROADCAST_TEMPLATE_REASSIGN_REQUEST
);

/* Select Audience */
export const broadcastFetchAudienceFilters = generateActionCreator(
  BROADCAST_FETCH_AUDIENCE_FILTERS_REQUEST
);
export const broadcastUpdateAudience = generateActionCreator(
  BROADCAST_UPDATE_AUDIENCE_REQUEST
);
export const broadcastFetchAudienceCount = generateActionCreator(
  BROADCAST_FETCH_AUDIENCE_COUNT_REQUEST
);
export const broadcastFetchContactsCounts = generateActionCreator(
  BROADCAST_FETCH_FILTER_CONTACTS_COUNT_REQUEST
);
export const broadcastSetSelectedFilters = generateActionCreator(
  BROADCAST_SET_SELECTED_FILTERS
);
export const broadcastSetSelectedSuggestions = generateActionCreator(
  BROADCAST_SET_SELECTED_SUGGESTIONS
);
export const broadcastSearchContactsByFile = generateActionCreator(
  BROADCAST_SEARCH_CONTACTS_BY_FILE_REQUEST
);

export const broadcastCreateCustomGroup = generateActionCreator(
  BROADCAST_CREATE_CUSTOM_GROUP_REQUEST
);

/* Schedule */
export const broadcastSchedule = generateActionCreator(
  BROADCAST_SCHEDULE_REQUEST
);

/* Broadcast Templates */
export const broadcastSetIsTemplate = generateActionCreator(
  BROADCAST_SET_IS_TEMPLATE
);
export const broadcastsFetchCurrentBroadcastTemplate = generateActionCreator(
  BROADCASTS_FETCH_CURRENT_BROADCAST_TEMPLATE_REQUEST
);
export const broadcastTemplateFetchSmsMergeTags = generateActionCreator(
  BROADCASTS_TEMPLATE_FETCH_SMS_MERGE_TAGS_REQUEST
);
export const broadcastTemplateSaveDetails = generateActionCreator(
  BROADCAST_TEMPLATE_SAVE_DETAILS_REQUEST
);
export const broadcastTemplateCreate = generateActionCreator(
  BROADCAST_TEMPLATE_CREATE_REQUEST
);
export const broadcastUpdateTemplate = generateActionCreator(
  BROADCAST_UPDATE_TEMPLATE_REQUEST
);

/**
 * PURCHASE PAGE
 */
export const purchasePageCreateSubscription = generateActionCreator(
  PURCHASE_PAGE_CREATE_SUBSCRIPTION_REQUEST
);

export const purchasePageFetchProductPlanInfo = generateActionCreator(
  PURCHASE_PAGE_FETCH_PRODUCT_PLAN_INFO_REQUEST
);

/**
 * OVERLORD PLAN BUILDER
 */
export const plansFetch = generateActionCreator(FETCH_PLANS_REQUEST);
export const planPageFetch = generateActionCreator(FETCH_PLAN_PAGE_REQUEST);
export const planCreate = generateActionCreator(CREATE_PLAN_REQUEST);
export const planUpdate = generateActionCreator(UPDATE_PLAN_REQUEST);
export const planDelete = generateActionCreator(DELETE_PLAN_REQUEST);
export const planAvailableForUser = generateActionCreator(
  AVAILABLE_PLAN_REQUEST
);
export const currentPlanReset = generateActionCreator(RESET_CURRENT_PLAN);

export const smsCreditPacksFetch = generateActionCreator(
  FETCH_SMS_CREDIT_PACKS_REQUEST
);
export const smsCreditPackFetch = generateActionCreator(
  FETCH_SMS_CREDIT_PACK_REQUEST
);
export const smsCreditPackCreate = generateActionCreator(
  CREATE_SMS_CREDIT_PACK_REQUEST
);
export const smsCreditPackUpdate = generateActionCreator(
  UPDATE_SMS_CREDIT_PACK_REQUEST
);
export const smsCreditPackDelete = generateActionCreator(
  DELETE_SMS_CREDIT_PACK_REQUEST
);

export const onboardingFeesFetch = generateActionCreator(
  FETCH_ONBOARDING_FEES_REQUEST
);
export const onboardingFeeFetch = generateActionCreator(
  FETCH_ONBOARDING_FEE_REQUEST
);
export const onboardingFeeCreate = generateActionCreator(
  CREATE_ONBOARDING_FEE_REQUEST
);
export const onboardingFeeUpdate = generateActionCreator(
  UPDATE_ONBOARDING_FEE_REQUEST
);
export const onboardingFeeDelete = generateActionCreator(
  DELETE_ONBOARDING_FEE_REQUEST
);

/** Contact Actions */
export const createContact = generateActionCreator(CREATE_CONTACT_REQUEST);

export const updateContact = generateActionCreator(UPDATE_CONTACT_REQUEST);

export const deleteContact = generateActionCreator(DELETE_CONTACT_REQUEST);

export const unsubscribeContact = generateActionCreator(
  UNSUBSCRIBE_CONTACT_REQUEST
);

export const resubscribeContact = generateActionCreator(
  RESUBSCRIBE_CONTACT_REQUEST
);
/**
 * Contact Profile
 */

export const fetchContact = generateActionCreator(FETCH_CONTACT_REQUEST);
export const fetchNextCampaignTouchpoint = generateActionCreator(
  FETCH_NEXT_CAMPAIGN_TOUCHPOINT_REQUEST
);
export const fetchContactAutomations = generateActionCreator(
  FETCH_CONTACT_AUTOMATIONS_REQUEST
);
export const fetchContactCustomGroups = generateActionCreator(
  FETCH_CONTACT_CUSTOM_GROUPS_REQUEST
);

export const updateContactCustomGroups = generateActionCreator(
  UPDATE_CONTACT_CUSTOM_GROUPS_REQUEST
);

export const fetchCurrentContactCta = generateActionCreator(
  FETCH_CURRENT_CONTACT_CTA_REQUEST
);

export const setCurrentContactTab = generateActionCreator(
  SET_CURRENT_CONTACT_TAB
);

export const validateContactsCsv = generateActionCreator(
  VALIDATE_CONTACTS_CSV_REQUEST
);

export const saveContactsCsv = generateActionCreator(SAVE_CONTACTS_CSV_REQUEST);

export const createCsvImport = generateActionCreator(CREATE_CSV_IMPORT_REQUEST);

export const updateCsvImport = generateActionCreator(UPDATE_CSV_IMPORT_REQUEST);

export const fetchCsvImport = generateActionCreator(FETCH_CSV_IMPORT_REQUEST);

export const validateSingleContactCsv = generateActionCreator(
  VALIDATE_SINGLE_CONTACT_CSV_REQUEST
);

export const deleteCsvImport = generateActionCreator(DELETE_CSV_IMPORT_REQUEST);

export const setCsvValidationError = generateActionCreator(
  SET_CSV_VALIDATION_ERROR
);

export const scheduleCsvImport = generateActionCreator(
  SCHEDULE_CSV_IMPORT_REQUEST
);

export const fetchCurrentAutomationDetails = generateActionCreator(
  FETCH_CURRENT_AUTOMATION_DETAILS_REQUEST
);

export const updateCurrentAutomationPause = generateActionCreator(
  PAUSE_AUTOMATION_REQUEST
);

export const updateCurrentAutomationUnpause = generateActionCreator(
  UNPAUSE_AUTOMATION_REQUEST
);
export const updateCurrentAutomationComplete = generateActionCreator(
  UPDATE_CURRENT_AUTOMATION_COMPLETE_REQUEST
);
export const newAutomationLifecycleCheck = generateActionCreator(
  NEW_AUTOMATION_LIFECYCLE_CHECK_REQUEST
);

export const fetchAccountCampaigns = generateActionCreator(
  FETCH_ACCOUNT_CAMPAIGNS_REQUEST
);

export const fetchStepList = generateActionCreator(
  FETCH_CAMPAIGN_STEP_LIST_REQUEST
);

export const createNewAutomation = generateActionCreator(
  CREATE_AUTOMATION_REQUEST
);

export const fetchChangedCampaignList = generateActionCreator(
  FETCH_CAMPAIGNS_LIST_REQUEST
);

export const newLifecycleSummaryConfirmation = generateActionCreator(
  NEW_LIFECYCLE_CONFIRMATION_REQUEST
);

export const updateContactLifecycle = generateActionCreator(
  UPDATE_CONTACT_LIFECYCLE_REQUEST
);

export const updateAppointment = generateActionCreator(
  UPDATE_APPOINTMENT_REQUEST
);

export const removeSpamComplaint = generateActionCreator(
  REMOVE_SPAM_COMPLAINT_REQUEST
);

export const fetchImportHistory = generateActionCreator(
  FETCH_IMPORT_HISTORY_REQUEST
);

export const fetchNextAppointment = generateActionCreator(
  FETCH_NEXT_APPOINTMENT_REQUEST
);

export const fetchNextTask = generateActionCreator(FETCH_NEXT_TASK_REQUEST);

/**
 * SYNC WIZARD
 */
export const matchedContactsFetch = generateActionCreator(
  MATCHED_CONTACTS_FETCH_REQUEST
);

export const initializeSyncWizard = generateActionCreator(
  INITIALIZE_SYNC_WIZARD_REQUEST
);

export const setSyncToRequired = generateActionCreator(
  SET_SYNC_TO_REQUIRED_REQUEST
);

export const fetchLogger = generateActionCreator(
  SYNC_WIZARD_FETCH_TEST_LOG_REQUEST
);

export const fetchLastSyncOutputLog = generateActionCreator(
  LAST_SYNC_OUTPUT_LOG_REQUEST
);

export const membersSync = generateActionCreator(MEMBERS_SYNC_REQUEST);

export const fetchMode = generateActionCreator(FETCH_SYNC_MODE_REQUEST);

export const fetchSyncStatus = generateActionCreator(FETCH_SYNC_STATUS_REQUEST);

export const updateExactMatchData = generateActionCreator(
  SYNC_WIZARD_UPDATE_EXACT_MATCH_DATA
);

export const updateNameMatchData = generateActionCreator(
  SYNC_WIZARD_UPDATE_NAME_MATCH_DATA
);

export const updateEmailMatchData = generateActionCreator(
  SYNC_WIZARD_UPDATE_EMAIL_MATCH_DATA
);

export const updateCampaignMatchData = generateActionCreator(
  SYNC_WIZARD_UPDATE_CAMPAIGN_MATCH_DATA
);

export const updateNewUplaunchData = generateActionCreator(
  SYNC_WIZARD_UPDATE_NEW_UPLAUNCH_DATA
);

export const updateNewZenPlannerData = generateActionCreator(
  SYNC_WIZARD_UPDATE_NEW_ZENPLANNER_DATA
);

export const initializeCampaignData = generateActionCreator(
  SYNC_WIZARD_INITIALIZE_CAMPAIGN_DATA
);

export const initilaizeNewContactsData = generateActionCreator(
  SYNC_WIZARD_INITIALIZE_NEW_CONTACTS_DATA
);

export const setSyncWizardAccount = generateActionCreator(
  SET_SYNC_WIZARD_ACCOUNT
);
export const statsFetch = generateActionCreator(STATS_FETCH_REQUEST);
export const startLastSyncOutputLogWatcher = generateActionCreator(
  START_LAST_SYNC_OUTPUT_LOG_WATCHER
);
export const stopLastSyncOutputLogWorker = generateActionCreator(
  STOP_LAST_SYNC_OUTPUT_LOG_WATCHER
);
export const startCheckSyncStatusWatcher = generateActionCreator(
  START_CHECK_SYNC_STATUS_WATCHER
);
export const stopCheckSyncStatusWatcher = generateActionCreator(
  STOP_CHECK_SYNC_STATUS_WATCHER
);

export const switchOffTestMode = generateActionCreator(
  SWITCH_OFF_TEST_MODE_REQUEST
);

/**
 * Subscriptions
 */

export const subscriptionsFetch = generateActionCreator(
  FETCH_SUBSCRIPTIONS_REQUEST
);

export const subscriptionFetch = generateActionCreator(
  FETCH_SUBSCRIPTION_REQUEST
);

export const productsFetch = generateActionCreator(FETCH_PRODUCTS_REQUEST);
export const subscriptionUpdate = generateActionCreator(
  UPDATE_SUBSCRIPTION_REQUEST
);
export const subscriptionCardUpdate = generateActionCreator(
  UPDATE_CARD_SUBSCRIPTION_REQUEST
);
export const subscriptionsPageChange = generateActionCreator(
  PAGE_CHANGE_SUBSCRIPTIONS_REQUEST
);
export const downgradeSubscription = generateActionCreator(
  DOWNGRADE_SUBSCRIPTION_REQUEST
);

export const cancelDowngradeSubscription = generateActionCreator(
  CANCEL_DOWNGRADE_SUBSCRIPTION_REQUEST
);

export const undoCancellationSubscription = generateActionCreator(
  UNDO_CANCELLATION_SUBSCRIPTION_REQUEST
);

export const mySubscriptionFetch = generateActionCreator(
  FETCH_MY_SUBSCRIPTION_REQUEST
);

export const createProductSubscription = generateActionCreator(
  CREATE_PRODUCT_SUBSCRIPTION_REQUEST
);

export const upgradeSubscription = generateActionCreator(
  UPGRADE_SUBSCRIPTION_REQUEST
);

export const updateDefaultProduct = generateActionCreator(
  UPDATE_DEFAULT_PRODUCT_REQUEST
);

export const currentSubscriptionsReset = generateActionCreator(
  CURRENT_SUBSCRIPTIONS_RESET
);

/**
 * Appointment Booker
 */

export const fetchAppointmentBooker = generateActionCreator(
  FETCH_APPOINTMENT_BOOKER_REQUEST
);
export const createAppointmentFromBooker = generateActionCreator(
  CREATE_APPOINTMENT_FROM_BOOKER_REQUEST
);
export const fetchAppointment = generateActionCreator(
  FETCH_APPOINTMENT_REQUEST
);

export const resetAppointmentBooker = generateActionCreator(
  RESET_APPOINTMENT_BOOKER
);
/**
 * Tasks
 */

export const createTask = generateActionCreator(CREATE_TASK_REQUEST);
export const updateTask = generateActionCreator(UPDATE_TASK_REQUEST);
export const fetchTask = generateActionCreator(FETCH_TASK_REQUEST);

/**
 * Contact Form Types
 */

export const fetchContactFormTypes = generateActionCreator(
  FETCH_CONTACT_FORM_TYPES_REQUEST
);

export const fetchContactFormType = generateActionCreator(
  FETCH_CONTACT_FORM_TYPE_REQUEST
);

/**
 * Contact Form
 */

export const createContactForm = generateActionCreator(
  CREATE_CONTACT_FORM_REQUEST
);

export const contactFormUpdate = generateActionCreator(
  UPDATE_CONTACT_FORM_REQUEST
);

export const contactFormDelete = generateActionCreator(
  DELETE_CONTACT_FORM_REQUEST
);

export const updateContactForm = generateActionCreator(UPDATE_CONTACT_REQUEST);

/**
 * Contact Notes
 */

export const createContactNote = generateActionCreator(
  CREATE_CONTACT_NOTE_REQUEST
);

export const createFileUpload = generateActionCreator(
  CREATE_FILE_UPLOAD_REQUEST
);

/**
 * Activities
 */

export const fetchPinnedActivities = generateActionCreator(
  FETCH_PINNED_ACTIVITIES_REQUEST
);
export const fetchActivities = generateActionCreator(FETCH_ACTIVITIES_REQUEST);
export const activitiesPageChange = generateActionCreator(
  ACTIVITIES_PAGE_CHANGE_REQUEST
);
export const updateActivity = generateActionCreator(UPDATE_ACTIVITY_REQUEST);

export const updateActivitiesFeed = generateActionCreator(
  UPDATE_ACTIVITIES_FEED
);

export const fetchPinnedSmsActivities = generateActionCreator(
  FETCH_PINNED_SMS_ACTIVITIES_REQUEST
);
export const fetchSmsActivities = generateActionCreator(
  FETCH_SMS_ACTIVITIES_REQUEST
);
export const smsActivitiesPageChange = generateActionCreator(
  SMS_ACTIVITIES_PAGE_CHANGE_REQUEST
);
export const updateSmsActivity = generateActionCreator(
  UPDATE_SMS_ACTIVITY_REQUEST
);

export const fetchPinnedEmailActivities = generateActionCreator(
  FETCH_PINNED_EMAIL_ACTIVITIES_REQUEST
);
export const fetchEmailActivities = generateActionCreator(
  FETCH_EMAIL_ACTIVITIES_REQUEST
);
export const emailActivitiesPageChange = generateActionCreator(
  EMAIL_ACTIVITIES_PAGE_CHANGE_REQUEST
);
export const updateEmailActivity = generateActionCreator(
  UPDATE_EMAIL_ACTIVITY_REQUEST
);

export const fetchPinnedAppointmentsActivities = generateActionCreator(
  FETCH_PINNED_APPOINTMENTS_ACTIVITIES_REQUEST
);
export const fetchAppointmentsActivities = generateActionCreator(
  FETCH_APPOINTMENTS_ACTIVITIES_REQUEST
);
export const appointmentsActivitiesPageChange = generateActionCreator(
  APPOINTMENTS_ACTIVITIES_PAGE_CHANGE_REQUEST
);
export const updateAppointmentsActivity = generateActionCreator(
  UPDATE_APPOINTMENTS_ACTIVITY_REQUEST
);

export const fetchPinnedTasksActivities = generateActionCreator(
  FETCH_PINNED_TASKS_ACTIVITIES_REQUEST
);
export const fetchTasksActivities = generateActionCreator(
  FETCH_TASKS_ACTIVITIES_REQUEST
);
export const tasksActivitiesPageChange = generateActionCreator(
  TASKS_ACTIVITIES_PAGE_CHANGE_REQUEST
);
export const updateTasksActivity = generateActionCreator(
  UPDATE_TASKS_ACTIVITY_REQUEST
);

export const fetchPinnedNotesActivities = generateActionCreator(
  FETCH_PINNED_NOTES_ACTIVITIES_REQUEST
);
export const fetchNotesActivities = generateActionCreator(
  FETCH_NOTES_ACTIVITIES_REQUEST
);
export const notesActivitiesPageChange = generateActionCreator(
  NOTES_ACTIVITIES_PAGE_CHANGE_REQUEST
);
export const updateNotesActivity = generateActionCreator(
  UPDATE_NOTES_ACTIVITY_REQUEST
);

export const fetchPinnedFormsActivities = generateActionCreator(
  FETCH_PINNED_FORMS_ACTIVITIES_REQUEST
);
export const fetchFormsActivities = generateActionCreator(
  FETCH_FORMS_ACTIVITIES_REQUEST
);
export const formsActivitiesPageChange = generateActionCreator(
  FORMS_ACTIVITIES_PAGE_CHANGE_REQUEST
);
export const updateFormsActivity = generateActionCreator(
  UPDATE_FORMS_ACTIVITY_REQUEST
);

export const fetchContactForm = generateActionCreator(
  FETCH_CONTACT_FORM_REQUEST
);

export const completeTask = generateActionCreator(COMPLETE_TASK_REQUEST);
export const editTask = generateActionCreator(EDIT_TASK_REQUEST);
export const reassignTask = generateActionCreator(REASSIGN_TASK_REQUEST);
export const cancelTask = generateActionCreator(CANCEL_TASK_REQUEST);

export const rescheduleAppointment = generateActionCreator(
  RESCHEDULE_APPOINTMENT_REQUEST
);
export const markAppointmentNoShow = generateActionCreator(
  MARK_APPOINTMENT_NO_SHOW_REQUEST
);
export const cancelAppointment = generateActionCreator(
  CANCEL_APPOINTMENT_REQUEST
);

/**
 * Appointments
 */

export const fetchAppointmentTypes = generateActionCreator(
  FETCH_APPOINTMENT_TYPES_REQUEST
);
export const setCurrentAppointmentTypeId = generateActionCreator(
  SET_CURRENT_APPOINTMENT_TYPE_ID
);
export const appointmentTypeCreate = generateActionCreator(
  CREATE_APPOINTMENT_TYPE_REQUEST
);
export const appointmentTypeUpdate = generateActionCreator(
  UPDATE_APPOINTMENT_TYPE_REQUEST
);
export const appointmentTypeFetch = generateActionCreator(
  FETCH_APPOINTMENT_TYPE_REQUEST
);
export const appointmentTypeDelete = generateActionCreator(
  DELETE_APPOINTMENT_TYPE_REQUEST
);
export const fetchAllAppointmentType = generateActionCreator(
  FETCH_ALL_APPOINTMENT_TYPE_REQUEST
);
/**
 * Market Place
 */
export const fetchMarketPlace = generateActionCreator(
  MARKET_PLACE_LIST_FETCH_REQUEST
);

export const clearCurrentProductFromState = generateActionCreator(
  CLEAR_CURRENT_PRODUCT_FROM_STATE
);

/**
 * Products
 */
export const fetchProduct = generateActionCreator(FETCH_PRODUCT_REQUEST);
export const createProduct = generateActionCreator(CREATE_PRODUCT_REQUEST);
export const updateProduct = generateActionCreator(UPDATE_PRODUCT_REQUEST);
export const productFetch = generateActionCreator(PRODUCT_FETCH_REQUEST);
export const fetchProductsCategories = generateActionCreator(
  FETCH_PRODUCTS_CATEGORIES_REQUEST
);

export const fetchContactsCount = generateActionCreator(
  FETCH_CONTACTS_COUNT_REQUEST
);

export const viewContactsInProductCampaign = generateActionCreator(
  VIEW_CONTACTS_IN_PRODUCT_CAMPAIGN_REQUEST
);

export const clearViewContactsModalContacts = generateActionCreator(
  CLEAR_VIEW_CONTACTS_MODAL_CONTACTS
);

export const journeyCreate = generateActionCreator(CREATE_JOURNEY_REQUEST);
export const journeyUpdate = generateActionCreator(UPDATE_JOURNEY_REQUEST);

export const fetchUploadedFilesList = generateActionCreator(
  FETCH_UPLOADED_FILES_LIST_REQUEST
);

export const fetchIncludedForm = generateActionCreator(
  FETCH_INCLUDED_FORM_REQUEST
);

export const fetchStaffSmsMergeFields = generateActionCreator(
  FETCH_STAFF_SMS_MERGE_FIELDS_REQUEST
);

export const fetchStaffEmailMergeFields = generateActionCreator(
  FETCH_STAFF_EMAIL_MERGE_FIELDS_REQUEST
);

export const createIncludedForm = generateActionCreator(
  CREATE_INCLUDED_FORM_REQUEST
);
export const updateIncludedForm = generateActionCreator(
  UPDATE_INCLUDED_FORM_REQUEST
);

export const deleteProduct = generateActionCreator(DELETE_PRODUCT_REQUEST);

export const togglePublishProduct = generateActionCreator(
  TOGGLE_PUBLISH_PRODUCT_REQUEST
);

/**
 * OptInForm
 */

export const fetchOptInForm = generateActionCreator(FETCH_OPT_IN_FORM_REQUEST);

/**
 * ProductSubscriptions
 */

export const fetchMyProductSubscriptions = generateActionCreator(
  FETCH_MY_PRODUCT_SUBSCRIPTIONS_REQUEST
);
export const fetchProductSubscription = generateActionCreator(
  FETCH_PRODUCT_SUBSCRIPTION_REQUEST
);
export const updateProductSubscription = generateActionCreator(
  UPDATE_PRODUCT_SUBSCRIPTION_REQUEST
);
export const pauseProductSubscription = generateActionCreator(
  PAUSE_PRODUCT_SUBSCRIPTION_REQUEST
);
export const unpauseProductSubscription = generateActionCreator(
  UNPAUSE_PRODUCT_SUBSCRIPTION_REQUEST
);
export const removeProductSubscription = generateActionCreator(
  REMOVE_PRODUCT_SUBSCRIPTION_REQUEST
);
export const updateProcessAddContactsAt = generateActionCreator(
  UPDATE_PROCESS_ADD_CONTACTS_AT
);

/**
 * AutoComplete
 */

export const fetchAddContactsFilters = generateActionCreator(
  FETCH_ADD_CONTACTS_FILTERS_REQUEST
);
export const addContactsToProduct = generateActionCreator(
  ADD_CONTACTS_TO_PRODUCT_REQUEST
);

export const fetchAutoCompleteSuggestions = generateActionCreator(
  FETCH_AUTO_COMPLETE_SUGGESTIONS_REQUEST
);

export const resetAutoCompleteSuggestions = generateActionCreator(
  RESET_AUTO_COMPLETE_SUGGESTIONS
);

export const fetchBlogTypes = generateActionCreator(FETCH_BLOGS_REQUEST);

export const createEmailCommunication = generateActionCreator(
  CREATE_EMAIL_REQUEST
);

/**
 * Lead Sources
 */

export const fetchLeadSources = generateActionCreator(
  FETCH_LEAD_SOURCES_REQUEST
);

export const createLeadSource = generateActionCreator(
  CREATE_LEAD_SOURCE_REQUEST
);

export const bulkUpdateContactsLeadSource = generateActionCreator(
  BULK_UPDATE_CONTACTS_REQUEST
);

/**
 * Flash Messages
 */

export const flashMessage = (message, options = {}) => {
  return {
    type: FLASH_MESSAGE_ACTION_TYPE,
    payload: {
      message,
      options
    }
  };
};

export const clearFlashMessages = createAction(
  CLEAR_FLASH_MESSAGES_ACTION_TYPE
);
export const removeFlashMessage = createAction(
  REMOVE_FLASH_MESSAGE_ACTION_TYPE
);

export const addFlashMessage = createAction(ADD_FLASH_MESSAGE_ACTION_TYPE);

export const flashSuccessMessage = (message, options) => {
  return flashMessage(message, { isError: false, ...options });
};

export const flashErrorMessage = (message, options) => {
  return flashMessage(message, { isError: true, ...options });
};

/**
 * Partners
 */

export const fetchPartners = generateActionCreator(FETCH_PARTNERS_REQUEST);
export const fetchPartner = generateActionCreator(FETCH_PARTNER_REQUEST);

/**
 * BackGroundJob
 */
export const fetchBackGroundJobData = generateActionCreator(
  FETCH_BACKGROUNDJOB_DATA_REQUEST
);

/**
 * ZenPlannerProgrmsSync
 */
export const fetchZenPlannerProgram = generateActionCreator(
  FETCH_ZEN_PLANNER_PROGRAMS_REQUEST
);

export const fetchSelectedLinkedZpPrograms = generateActionCreator(
  FETCH_SELECTED_LINKED_ZEN_PLANNER_REQUEST
);

export const createSyncZpPlannerWithProduct = generateActionCreator(
  CREATE_SYNC_ZP_PLANNER_WITH_PRODUCT_REQUEST
);
