/**
 * Broadcast Center
 */

/* General */
export const SET_CURRENT_ACCOUNT = 'broadcastCenter/SET_CURRENT_ACCOUNT';
export const BROADCAST_SET_STEP = 'broadcastCenter/BROADCAST_SET_STEP';
export const BROADCAST_SEND_TEST_EMAIL_REQUEST =
  'broadcastCenter/BROADCAST_SEND_TEST_EMAIL_REQUEST';
export const BROADCAST_SEND_TEST_EMAIL_SUCCESS =
  'broadcastCenter/BROADCAST_SEND_TEST_EMAIL_SUCCESS';
export const BROADCAST_SEND_TEST_EMAIL_FAILURE =
  'broadcastCenter/BROADCAST_SEND_TEST_EMAIL_FAILURE';
export const RESET_TEST_EMAIL_SENT = 'broadcastCenter/RESET_TEST_EMAIL_SENT';
export const ENABLE_OLD_BROADCAST_CENTER_REQUEST =
  'broadcastCenter/ENABLE_OLD_BROADCAST_CENTER_REQUEST';
export const ENABLE_OLD_BROADCAST_CENTER_SUCCESS =
  'broadcastCenter/ENABLE_OLD_BROADCAST_CENTER_SUCCESS';
export const ENABLE_OLD_BROADCAST_CENTER_FAILURE =
  'broadcastCenter/ENABLE_OLD_BROADCAST_CENTER_FAILURE';
/* Broadcasts */
export const BROADCASTS_FETCH_REQUEST =
  'broadcastCenter/BROADCASTS_FETCH_REQUEST';
export const BROADCASTS_FETCH_SUCCESS =
  'broadcastCenter/BROADCASTS_FETCH_SUCCESS';
export const BROADCASTS_FETCH_FAILURE =
  'broadcastCenter/BROADCASTS_FETCH_FAILURE';
export const BROADCASTS_PAGE_CHANGE_REQUEST =
  'broadcastCenter/BROADCASTS_PAGE_CHANGE_REQUEST';
export const BROADCASTS_PAGE_CHANGE_SUCCESS =
  'broadcastCenter/BROADCASTS_PAGE_CHANGE_SUCCESS';
export const BROADCASTS_PAGE_CHANGE_FAILURE =
  'broadcastCenter/BROADCASTS_PAGE_CHANGE_FAILURE';
export const BROADCASTS_RESET_PAGE_CHANGE =
  'broadcastCenter/BROADCASTS_RESET_PAGE_CHANGE';
export const BROADCASTS_FETCH_CURRENT_BROADCAST_REQUEST =
  'broadcastCenter/BROADCASTS_FETCH_CURRENT_BROADCAST_REQUEST';
export const BROADCASTS_FETCH_CURRENT_BROADCAST_SUCCESS =
  'broadcastCenter/BROADCASTS_FETCH_CURRENT_BROADCAST_SUCCESS';
export const BROADCASTS_FETCH_CURRENT_BROADCAST_FAILURE =
  'broadcastCenter/BROADCASTS_FETCH_CURRENT_BROADCAST_FAILURE';
export const BROADCASTS_FETCH_CONTACTS_REQUEST =
  'broadcastCenter/BROADCASTS_FETCH_CONTACTS_REQUEST';
export const BROADCASTS_FETCH_CONTACTS_SUCCESS =
  'broadcastCenter/BROADCASTS_FETCH_CONTACTS_SUCCESS';
export const BROADCASTS_FETCH_CONTACTS_FAILURE =
  'broadcastCenter/BROADCASTS_FETCH_CONTACTS_FAILURE';
export const BROADCAST_RESET_CONTACTS =
  'broadcastCenter/BROADCAST_RESET_CONTACTS';
export const BROADCASTS_FETCH_EMAILS_REQUEST =
  'broadcastCenter/BROADCASTS_FETCH_EMAILS_REQUEST';
export const BROADCASTS_FETCH_EMAILS_SUCCESS =
  'broadcastCenter/BROADCASTS_FETCH_EMAILS_SUCCESS';
export const BROADCASTS_FETCH_EMAILS_FAILURE =
  'broadcastCenter/BROADCASTS_FETCH_EMAILS_FAILURE';
export const BROADCAST_RESET_EMAILS = 'broadcastCenter/BROADCAST_RESET_EMAILS';
export const BROADCASTS_FETCH_SMS_REQUEST =
  'broadcastCenter/BROADCASTS_FETCH_SMS_REQUEST';
export const BROADCASTS_FETCH_SMS_SUCCESS =
  'broadcastCenter/BROADCASTS_FETCH_SMS_SUCCESS';
export const BROADCASTS_FETCH_SMS_FAILURE =
  'broadcastCenter/BROADCASTS_FETCH_SMS_FAILURE';
export const BROADCAST_RESET_SMS = 'broadcastCenter/BROADCAST_RESET_SMS';
export const BROADCAST_FETCH_PREVIEW_REQUEST =
  'broadcastCenter/BROADCAST_FETCH_PREVIEW_REQUEST';
export const BROADCAST_FETCH_PREVIEW_SUCCESS =
  'broadcastCenter/BROADCAST_FETCH_PREVIEW_SUCCESS';
export const BROADCAST_FETCH_PREVIEW_FAILURE =
  'broadcastCenter/BROADCAST_FETCH_PREVIEW_FAILURE';
export const BROADCAST_RESET_PREVIEW_DATA =
  'broadcastCenter/BROADCAST_RESET_PREVIEW_DATA';
export const BROADCAST_DELETE_REQUEST =
  'broadcastCenter/BROADCAST_DELETE_REQUEST';
export const BROADCAST_DELETE_SUCCESS =
  'broadcastCenter/BROADCAST_DELETE_SUCCESS';
export const BROADCAST_DELETE_FAILURE =
  'broadcastCenter/BROADCAST_DELETE_FAILURE';
export const BROADCAST_TEMPLATE_DELETE_REQUEST =
  'broadcastCenter/BROADCAST_TEMPLATE_DELETE_REQUEST';
export const BROADCAST_TEMPLATE_DELETE_SUCCESS =
  'broadcastCenter/BROADCAST_TEMPLATE_DELETE_SUCCESS';
export const BROADCAST_TEMPLATE_DELETE_FAILURE =
  'broadcastCenter/BROADCAST_TEMPLATE_DELETE_FAILURE';
export const BROADCAST_SET_SELECTED_BROADCAST =
  'broadcastCenter/BROADCAST_SET_SELECTED_BROADCAST';
export const BROADCAST_SET_SELECTED_FILTERS =
  'broadcastCenter/BROADCAST_SET_SELECTED_FILTERS';
export const BROADCAST_SET_SELECTED_SUGGESTIONS =
  'broadcastCenter/BROADCAST_SET_SELECTED_SUGGESTIONS';
export const BROADCAST_SET_SELECTED_BROADCAST_TEMPLATE =
  'broadcastCenter/BROADCAST_SET_SELECTED_BROADCAST_TEMPLATE';
export const BROADCASTS_FETCH_EMAIL_MERGE_TAGS_REQUEST =
  'broadcastCenter/BROADCASTS_FETCH_EMAIL_MERGE_TAGS_REQUEST';
export const BROADCASTS_FETCH_EMAIL_MERGE_TAGS_SUCCESS =
  'broadcastCenter/BROADCASTS_FETCH_EMAIL_MERGE_TAGS_SUCCESS';
export const BROADCASTS_FETCH_EMAIL_MERGE_TAGS_FAILURE =
  'broadcastCenter/BROADCASTS_FETCH_EMAIL_MERGE_TAGS_FAILURE';
export const BROADCASTS_FETCH_SMS_MERGE_TAGS_REQUEST =
  'broadcastCenter/BROADCASTS_FETCH_SMS_MERGE_TAGS_REQUEST';
export const BROADCASTS_FETCH_SMS_MERGE_TAGS_SUCCESS =
  'broadcastCenter/BROADCASTS_FETCH_SMS_MERGE_TAGS_SUCCESS';
export const BROADCASTS_FETCH_SMS_MERGE_TAGS_FAILURE =
  'broadcastCenter/BROADCASTS_FETCH_SMS_MERGE_TAGS_FAILURE';
export const BROADCAST_SET_TEST_EMAIL_ADDRESS =
  'broadcastCenter/BROADCAST_SET_TEST_EMAIL_ADDRESS';
export const BROADCAST_SET_PREVIEW_EMAIL_FIRED =
  'broadcastCenter/BROADCAST_SET_PREVIEW_EMAIL_FIRED';
export const BROADCAST_SAVE_SMS_REQUEST =
  'broadcastCenter/BROADCAST_SAVE_SMS_REQUEST';
export const BROADCAST_SAVE_SMS_SUCCESS =
  'broadcastCenter/BROADCAST_SAVE_SMS_SUCCESS';
export const BROADCAST_SAVE_SMS_FAILURE =
  'broadcastCenter/BROADCAST_SAVE_SMS_FAILURE';
export const BROADCAST_SAVE_AS_TEMPLATE_REQUEST =
  'broadcastCenter/BROADCAST_SAVE_AS_TEMPLATE_REQUEST';
export const BROADCAST_SAVE_AS_TEMPLATE_SUCCESS =
  'broadcastCenter/BROADCAST_SAVE_AS_TEMPLATE_SUCCESS';
export const BROADCAST_SAVE_AS_TEMPLATE_FAILURE =
  'broadcastCenter/BROADCAST_SAVE_AS_TEMPLATE_FAILURE';
export const BROADCAST_SEND_AGAIN_REQUEST =
  'broadcastCenter/BROADCAST_SEND_AGAIN_REQUEST';
export const BROADCAST_SEND_AGAIN_SUCCESS =
  'broadcastCenter/BROADCAST_SEND_AGAIN_SUCCESS';
export const BROADCAST_SEND_AGAIN_FAILURE =
  'broadcastCenter/BROADCAST_SEND_AGAIN_FAILURE';
export const BROADCAST_EDIT_SCHEDULED_BROADCAST_REQUEST =
  'broadcastCenter/BROADCAST_EDIT_SCHEDULED_BROADCAST_REQUEST';
export const BROADCAST_EDIT_SCHEDULED_BROADCAST_SUCCESS =
  'broadcastCenter/BROADCAST_EDIT_SCHEDULED_BROADCAST_SUCCESS';
export const BROADCAST_EDIT_SCHEDULED_BROADCAST_FAILURE =
  'broadcastCenter/BROADCAST_EDIT_SCHEDULED_BROADCAST_FAILURE';
export const BROADCAST_UPDATE_BROADCAST_REQUEST =
  'broadcastCenter/BROADCAST_UPDATE_BROADCAST_REQUEST';
export const BROADCAST_UPDATE_BROADCAST_SUCCESS =
  'broadcastCenter/BROADCAST_UPDATE_BROADCAST_SUCCESS';
export const BROADCAST_UPDATE_BROADCAST_FAILURE =
  'broadcastCenter/BROADCAST_UPDATE_BROADCAST_FAILURE';
export const BROADCAST_SAVE_EMAIL_REQUEST =
  'broadcastCenter/BROADCAST_SAVE_EMAIL_REQUEST';
export const BROADCAST_SAVE_EMAIL_SUCCESS =
  'broadcastCenter/BROADCAST_SAVE_EMAIL_SUCCESS';
export const BROADCAST_SAVE_EMAIL_FAILURE =
  'broadcastCenter/BROADCAST_SAVE_EMAIL_FAILURE';
export const BROADCAST_CALL_EMAIL_SAVE_ACTION =
  'broadcastCenter/BROADCAST_CALL_EMAIL_SAVE_ACTION';
export const BROADCAST_RESET_EMAIL_SAVE_ACTION =
  'broadcastCenter/BROADCAST_RESET_EMAIL_SAVE_ACTION';
export const BROADCAST_SET_EMAIL_SUBJECT =
  'broadcastCenter/BROADCAST_SET_EMAIL_SUBJECT';
export const BROADCAST_RESET_IS_AUTOSAVING =
  'broadcastCenter/BROADCAST_RESET_IS_AUTOSAVING';
export const BROADCAST_SCHEDULE_REQUEST =
  'broadcastCenter/BROADCAST_SCHEDULE_REQUEST';
export const BROADCAST_SCHEDULE_SUCCESS =
  'broadcastCenter/BROADCAST_SCHEDULE_SUCCESS';
export const BROADCAST_SCHEDULE_FAILURE =
  'broadcastCenter/BROADCAST_SCHEDULE_FAILURE';
export const BROADCAST_FETCH_AUDIENCE_FILTERS_REQUEST =
  'broadcastCenter/BROADCAST_FETCH_AUDIENCE_FILTERS_REQUEST';
export const BROADCAST_FETCH_AUDIENCE_FILTERS_SUCCESS =
  'broadcastCenter/BROADCAST_FETCH_AUDIENCE_FILTERS_SUCCESS';
export const BROADCAST_FETCH_AUDIENCE_FILTERS_FAILURE =
  'broadcastCenter/BROADCAST_FETCH_AUDIENCE_FILTERS_FAILURE';
export const BROADCAST_UPDATE_AUDIENCE_REQUEST =
  'broadcastCenter/BROADCAST_UPDATE_AUDIENCE_REQUEST';
export const BROADCAST_UPDATE_AUDIENCE_SUCCESS =
  'broadcastCenter/BROADCAST_UPDATE_AUDIENCE_SUCCESS';
export const BROADCAST_UPDATE_AUDIENCE_FAILURE =
  'broadcastCenter/BROADCAST_UPDATE_AUDIENCE_FAILURE';
export const BROADCAST_FETCH_AUDIENCE_COUNT_REQUEST =
  'broadcastCenter/BROADCAST_FETCH_AUDIENCE_COUNT_REQUEST';
export const BROADCAST_FETCH_AUDIENCE_COUNT_SUCCESS =
  'broadcastCenter/BROADCAST_FETCH_AUDIENCE_COUNT_SUCCESS';
export const BROADCAST_FETCH_AUDIENCE_COUNT_FAILURE =
  'broadcastCenter/BROADCAST_FETCH_AUDIENCE_COUNT_FAILURE';
export const BROADCAST_FETCH_FILTER_CONTACTS_COUNT_REQUEST =
  'broadcastCenter/BROADCAST_FETCH_FILTER_CONTACTS_COUNT_REQUEST';
export const BROADCAST_FETCH_FILTER_CONTACTS_COUNT_SUCCESS =
  'broadcastCenter/BROADCAST_FETCH_FILTER_CONTACTS_COUNT_SUCCESS';
export const BROADCAST_FETCH_FILTER_CONTACTS_COUNT_FAILURE =
  'broadcastCenter/BROADCAST_FETCH_FILTER_CONTACTS_COUNT_FAILURE';
export const BROADCAST_SET_HAS_CONTENT_CHANGED =
  'broadcastCenter/BROADCAST_SET_HAS_CONTENT_CHANGED';
export const BROADCAST_SET_BEE_EDITOR_HAS_LOADED =
  'broadcastCenter/BROADCAST_SET_BEE_EDITOR_HAS_LOADED';
export const BROADCAST_RESET_TO_INITIAL_STATE =
  'broadcastCenter/BROADCAST_RESET_TO_INITIAL_STATE';

/* Broadcast Templates */
export const BROADCAST_SET_IS_TEMPLATE =
  'broadcastCenter/BROADCAST_SET_IS_TEMPLATE';
export const BROADCAST_TEMPLATES_FETCH_REQUEST =
  'broadcastCenter/BROADCAST_TEMPLATES_FETCH_REQUEST';
export const BROADCAST_TEMPLATES_FETCH_SUCCESS =
  'broadcastCenter/BROADCAST_TEMPLATES_FETCH_SUCCESS';
export const BROADCAST_TEMPLATES_FETCH_FAILURE =
  'broadcastCenter/BROADCAST_TEMPLATES_FETCH_FAILURE';
export const BROADCAST_TEMPLATES_PAGE_CHANGE_REQUEST =
  'broadcastCenter/BROADCAST_TEMPLATES_PAGE_CHANGE_REQUEST';
export const BROADCAST_TEMPLATES_PAGE_CHANGE_SUCCESS =
  'broadcastCenter/BROADCAST_TEMPLATES_PAGE_CHANGE_SUCCESS';
export const BROADCAST_TEMPLATES_PAGE_CHANGE_FAILURE =
  'broadcastCenter/BROADCAST_TEMPLATES_PAGE_CHANGE_FAILURE';
export const BROADCAST_TEMPLATES_CREATE_BROADCAST_REQUEST =
  'broadcastCenter/BROADCAST_TEMPLATES_CREATE_BROADCAST_REQUEST';
export const BROADCAST_TEMPLATES_CREATE_BROADCAST_SUCCESS =
  'broadcastCenter/BROADCAST_TEMPLATES_CREATE_BROADCAST_SUCCESS';
export const BROADCAST_TEMPLATES_CREATE_BROADCAST_FAILURE =
  'broadcastCenter/BROADCAST_TEMPLATES_CREATE_BROADCAST_FAILURE';
export const BROADCASTS_FETCH_CURRENT_BROADCAST_TEMPLATE_REQUEST =
  'broadcastCenter/BROADCASTS_FETCH_CURRENT_BROADCAST_TEMPLATE_REQUEST';
export const BROADCASTS_FETCH_CURRENT_BROADCAST_TEMPLATE_SUCCESS =
  'broadcastCenter/BROADCASTS_FETCH_CURRENT_BROADCAST_TEMPLATE_SUCCESS';
export const BROADCASTS_FETCH_CURRENT_BROADCAST_TEMPLATE_FAILURE =
  'broadcastCenter/BROADCASTS_FETCH_CURRENT_BROADCAST_TEMPLATE_FAILURE';
export const BROADCASTS_TEMPLATE_FETCH_SMS_MERGE_TAGS_REQUEST =
  'broadcastCenter/BROADCASTS_TEMPLATE_FETCH_SMS_MERGE_TAGS_REQUEST';
export const BROADCASTS_TEMPLATE_FETCH_SMS_MERGE_TAGS_SUCCESS =
  'broadcastCenter/BROADCASTS_TEMPLATE_FETCH_SMS_MERGE_TAGS_SUCCESS';
export const BROADCASTS_TEMPLATE_FETCH_SMS_MERGE_TAGS_FAILURE =
  'broadcastCenter/BROADCASTS_TEMPLATE_FETCH_SMS_MERGE_TAGS_FAILURE';
export const BROADCAST_TEMPLATE_SAVE_DETAILS_REQUEST =
  'broadcastCenter/BROADCAST_FETCH_CURRENT_BROADCAST_TEMPLATE_REQUEST';
export const BROADCAST_TEMPLATE_SAVE_DETAILS_SUCCESS =
  'broadcastCenter/BROADCAST_FETCH_CURRENT_BROADCAST_TEMPLATE_SUCCESS';
export const BROADCAST_TEMPLATE_SAVE_DETAILS_FAILURE =
  'broadcastCenter/BROADCAST_FETCH_CURRENT_BROADCAST_TEMPLATE_FAILURE';
export const BROADCAST_TEMPLATE_CREATE_REQUEST =
  'broadcastCenter/BROADCAST_TEMPLATE_CREATE_REQUEST';
export const BROADCAST_TEMPLATE_CREATE_SUCCESS =
  'broadcastCenter/BROADCAST_TEMPLATE_CREATE_SUCCESS';
export const BROADCAST_TEMPLATE_CREATE_FAILURE =
  'broadcastCenter/BROADCAST_TEMPLATE_CREATE_FAILURE';
export const BROADCAST_UPDATE_TEMPLATE_REQUEST =
  'broadcastCenter/BROADCAST_UPDATE_TEMPLATE_REQUEST';
export const BROADCAST_UPDATE_TEMPLATE_SUCCESS =
  'broadcastCenter/BROADCAST_UPDATE_TEMPLATE_SUCCESS';
export const BROADCAST_UPDATE_TEMPLATE_FAILURE =
  'broadcastCenter/BROADCAST_UPDATE_TEMPLATE_FAILURE';
export const BROADCAST_SEARCH_CONTACTS_BY_FILE_REQUEST =
  'broadcastCenter/BROADCAST_SEARCH_CONTACTS_BY_FILE_REQUEST';
export const BROADCAST_SEARCH_CONTACTS_BY_FILE_SUCCESS =
  'broadcastCenter/BROADCAST_SEARCH_CONTACTS_BY_FILE_SUCCESS';
export const BROADCAST_SEARCH_CONTACTS_BY_FILE_FAILURE =
  'broadcastCenter/BROADCAST_SEARCH_CONTACTS_BY_FILE_FAILURE';
export const BROADCAST_CREATE_CUSTOM_GROUP_REQUEST =
  'broadcastCenter/BROADCAST_CREATE_CUSTOM_GROUP_REQUEST';
export const BROADCAST_CREATE_CUSTOM_GROUP_SUCCESS =
  'broadcastCenter/BROADCAST_CREATE_CUSTOM_GROUP_SUCCESS';
export const BROADCAST_CREATE_CUSTOM_GROUP_FAILURE =
  'broadcastCenter/BROADCAST_CREATE_CUSTOM_GROUP_FAILURE';
export const BROADCAST_PRODUCTS_FETCH_REQUEST =
  'broadcastCenter/BROADCAST_PRODUCTS_FETCH_REQUEST';
export const BROADCAST_PRODUCTS_FETCH_SUCCESS =
  'broadcastCenter/BROADCAST_PRODUCTS_FETCH_SUCCESS';
export const BROADCAST_PRODUCTS_FETCH_FAILURE =
  'broadcastCenter/BROADCAST_PRODUCTS_FETCH_FAILURE';
export const BROADCAST_TEMPLATE_REASSIGN_REQUEST =
  'broadcastCenter/BROADCAST_TEMPLATE_REASSIGN_REQUEST';
export const BROADCAST_TEMPLATE_REASSIGN_SUCCESS =
  'broadcastCenter/BROADCAST_TEMPLATE_REASSIGN_SUCCESS';
export const BROADCAST_TEMPLATE_REASSIGN_FAILURE =
  'broadcastCenter/BROADCAST_TEMPLATE_REASSIGN_FAILURE';
