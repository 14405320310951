import React, { Fragment } from 'react';
import Notification from 'components/Theme/Notification';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { removeFlashMessage } from 'app-state/actions/ActionCreators';

const FlashMessages = () => {
  const structuredSelector = createStructuredSelector({
    messages: state => state.flashMessage.messages
  });

  const { messages } = useSelector(structuredSelector);
  const dispatch = useDispatch();

  const successIcon = (
    <svg
      className={`tw-h-5 tw-w-5 tw-text-success-400`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  );

  const errorIcon = (
    <svg
      className={`tw-h-5 tw-w-5 tw-text-error-400`}
      fill="currentColor"
      viewBox="0 0 20 20"
    >
      <path
        fillRule="evenodd"
        d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
        clipRule="evenodd"
      />
    </svg>
  );

  return (
    <Fragment>
      {messages.map(message => {
        return (
          <Notification
            key={message.id}
            message={message.message}
            headerText={message.isError ? 'Error!' : 'Success!'}
            color={message.isError ? 'error' : 'success'}
            timeout={
              !isNaN(message.timeout) ? message.timeout - 100 : message.timeout
            }
            type="colored"
            show
            setShowAction={show => {
              if (!show) dispatch(removeFlashMessage({ id: message.id }));
            }}
            showAlways={message.timeout === false}
            HeaderIcon={() => {
              return message.isError ? errorIcon : successIcon;
            }}
          />
        );
      })}
    </Fragment>
  );
};

export default FlashMessages;
