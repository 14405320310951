import React, { Fragment, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import isEmpty from 'lodash.isempty';
import {
  createErrorMessageSelector,
  createLoadedSelector
} from 'appState/selectors';
import {
  fetchMyProductSubscriptions,
  fetchCustomGroups,
  resetIsLoaded,
  fetchChangedCampaignList
} from 'appState/actions/ActionCreators';
import { UsersContext } from 'components/shared/context/UsersContext';
import { CurrentAccountContext } from 'components/shared/context/CurrentAccountContext';
import { formikShape } from 'lib/utils/prop-types-extensions';
import { FETCH_MY_PRODUCT_SUBSCRIPTIONS } from 'appState/actions/constants/productSubscription.actions';
import { CUSTOM_GROUPS_CREATE } from 'appState/actions/constants/overlord.actions';
import { FETCH_CAMPAIGNS_LIST } from 'appState/actions/constants/contact.actions';
import FileInput from 'components/Theme/FileInput';
import InfoPanel from 'components/Theme/InfoPanel';
import MultiSelect from 'components/Theme/MultiSelect';
import Select from 'components/Theme/Select';
import {
  RadioInput,
  CheckInput
} from 'components/ProductSubscriptionSetup/shared/FormInputs';
import { readRemoteFile } from 'react-papaparse';
import AddNewCustomGroupModal from './AddNewCustomGroupModal';

const infoItems = [
  'You can upload a .csv file with any set of columns as long as it has one record per row and the csv header is the first row of the file.',
  'The next step in upload will allow you to match your csv headers to the right data columns.',
  "You'll be able to clean any bad data before finalizing your import."
];

const Setup = ({ formik, csvImportId }) => {
  const [productCampaignOptions, setProductCampaignOptions] = useState([]);
  const [showCustomGroupsModal, setShowCustomGroupsModal] = useState(false);
  const [campaignHasImmediateStep, setCampaignHasImmediateStep] = useState(
    false
  );
  const [customGroupOptions, setCustomGroupOptions] = useState([]);
  const currentAccount = useContext(CurrentAccountContext);
  const { users, isUsersLoaded, usersError } = useContext(UsersContext);
  const userSelectOptions = users.map(user => {
    return { displayName: user.full_name, value: user.id };
  });

  const myProductsLoadedSelector = createLoadedSelector([
    FETCH_MY_PRODUCT_SUBSCRIPTIONS
  ]);
  const myProductsErrorSelector = createErrorMessageSelector([
    FETCH_MY_PRODUCT_SUBSCRIPTIONS
  ]);
  const createCustomGroupsLoadedSelector = createLoadedSelector([
    CUSTOM_GROUPS_CREATE
  ]);
  const createCustomGroupsErrorSelector = createErrorMessageSelector([
    CUSTOM_GROUPS_CREATE
  ]);
  const createFetchCampaignsLodedSelector = createLoadedSelector([
    FETCH_CAMPAIGNS_LIST
  ]);
  const createFetchCampaignsErrorSelector = createErrorMessageSelector([
    FETCH_CAMPAIGNS_LIST
  ]);

  const dispatch = useDispatch();

  const structuredSelector = createStructuredSelector({
    myProductSubscriptions: state =>
      state.productSubscription.myProductSubscriptions,
    campaigns: state => state.contact.campaigns,
    campaignsLoaded: state => createFetchCampaignsLodedSelector(state),
    campaignsError: state => createFetchCampaignsErrorSelector(state),
    myProductsLoaded: state => myProductsLoadedSelector(state),
    errorMyProducts: state => myProductsErrorSelector(state),
    customGroups: state => state.overlordUtilities.customGroups,
    newCustomGroups: state => state.overlordUtilities.newCustomGroups,
    createCustomGroupsLoaded: state => createCustomGroupsLoadedSelector(state),
    createCustomGroupsError: state => createCustomGroupsErrorSelector(state)
  });

  const {
    myProductSubscriptions,
    campaigns,
    campaignsLoaded,
    campaignsError,
    myProductsLoaded,
    errorMyProducts,
    customGroups,
    newCustomGroups,
    createCustomGroupsLoaded,
    createCustomGroupsError
  } = useSelector(structuredSelector);

  useEffect(() => {
    if (myProductsLoaded) {
      if (!isEmpty(errorMyProducts)) {
        // eslint-disable-next-line no-console
        console.error(errorMyProducts);
      }
    } else {
      dispatch(fetchMyProductSubscriptions({ accountId: currentAccount.id }));
    }
  }, [myProductsLoaded, errorMyProducts, currentAccount, dispatch]);

  useEffect(() => {
    if (!customGroups?.length)
      dispatch(fetchCustomGroups({ accountId: currentAccount.id }));
  }, [dispatch, currentAccount]);

  useEffect(() => {
    if (customGroups?.length) {
      setCustomGroupOptions(
        customGroups.map(cg => {
          return {
            displayName: cg.name,
            value: cg.id,
            id: cg.id,
            name: cg.name
          };
        })
      );
    }
  }, [customGroups]);

  useEffect(() => {
    if (createCustomGroupsLoaded) {
      if (isEmpty(createCustomGroupsError)) {
        const newCustomGroupIds = newCustomGroups.map(cg => cg.id);
        const selectedCustomGroupIds = [
          ...formik.values.customGroups,
          ...newCustomGroupIds
        ];
        formik.setFieldValue('customGroups', selectedCustomGroupIds);
      }
      dispatch(resetIsLoaded(CUSTOM_GROUPS_CREATE));
    }
  }, [
    formik,
    dispatch,
    newCustomGroups,
    createCustomGroupsLoaded,
    createCustomGroupsError
  ]);

  useEffect(() => {
    if (campaignsLoaded) {
      if (isEmpty(campaignsError)) {
        setProductCampaignOptions(
          campaigns.map(campaign => {
            return {
              displayName: campaign.name,
              value: campaign.id,
              hasImmediateStep: campaign.has_immediate_step
            };
          })
        );
      }
      dispatch(resetIsLoaded(FETCH_CAMPAIGNS_LIST));
    }
  }, [campaigns, campaignsLoaded, campaignsError, dispatch]);

  useEffect(() => {
    if (isEmpty(productCampaignOptions)) {
      if (!isEmpty(campaigns)) {
        setProductCampaignOptions(
          campaigns.map(campaign => {
            return {
              displayName: campaign.name,
              value: campaign.id,
              hasImmediateStep: campaign.has_immediate_step
            };
          })
        );
      }
    }
  }, [productCampaignOptions, campaigns]);

  useEffect(() => {
    if (isEmpty(productCampaignOptions)) {
      if (csvImportId && formik.values.product_id) {
        dispatch(
          fetchChangedCampaignList({
            accountId: currentAccount.id,
            productId: formik.values.product_id
          })
        );
      }
    }
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(productCampaignOptions))
      setCampaignHasImmediateStep(
        productCampaignOptions.find(
          pc => pc.value.toString() === formik.values.campaign
        )?.hasImmediateStep || false
      );
  }, [productCampaignOptions, formik.values.campaign]);

  function extractFileName(file) {
    if (file instanceof File) {
      return file.name;
    }
    if (file && file.url) {
      // eslint-disable-next-line no-useless-escape
      const match = /[^/\\&\?]+\.\w{3,4}(?=([\?&].*$|$))/.exec(file.url);
      const filename = match !== null ? match[0] : '';
      return filename;
    }
    return '';
  }

  function selectProductOptions() {
    const productOptions = myProductSubscriptions.map(product => {
      return {
        displayName: product.name,
        value: product.product_id
      };
    });
    return productOptions;
  }

  const onImportFileChange = file => {
    formik.setFieldValue('file', file);
  };

  const setHeaders = file => {
    return readRemoteFile(file, {
      header: true,
      skipEmptyLines: true,
      complete: results => {
        formik.setFieldValue('headers', results.meta.fields);
        formik.setFieldValue('fileData', results.data);
      },
      transformHeader(h) {
        return h.trim();
      }
    });
  };

  useEffect(() => {
    if (formik.values.file) {
      setHeaders(formik.values.file);
    }
  }, [formik.values.file]);

  const handleCampaignChange = e => {
    formik.setFieldValue('campaignName', e.target.selectedOptions[0].text);
    formik.setFieldValue('campaign_id', e.target.value);
    setCampaignHasImmediateStep(
      productCampaignOptions.find(pc => pc.value.toString() === e.target.value)
        .hasImmediateStep
    );
  };

  const handleCustomGroupsChange = value => {
    formik.setFieldValue('customGroups', value);
  };

  const handleProductChange = e => {
    formik.setFieldValue('product_id', e.target.value);
    formik.setFieldValue('product', e.target.selectedOptions[0].text);
    formik.setFieldValue('campaign', '');
    dispatch(
      fetchChangedCampaignList({
        accountId: currentAccount.id,
        productId: e.target.value
      })
    );
    setCampaignHasImmediateStep(false);
  };

  return (
    <Fragment>
      <InfoPanel title="Things to Know" infoItems={infoItems} />
      <div className="tw-w-full tw-my-8">
        <div className="tw-my-8 tw-w-full sm:tw-w-1/2 md:tw-w-2/5">
          {formik.values.filename ? (
            <FileInput
              id="file"
              labelText="CSV File to Import"
              value={formik.values.filename}
              required
              disabled
            />
          ) : (
            <FileInput
              id="file"
              labelText="CSV File to Import"
              placeholder="Select file"
              value={extractFileName(formik.values.file)}
              onChange={onImportFileChange}
              onBlur={formik.handleBlur}
              showError={formik.touched.file && !!formik.errors.file}
              error={formik.errors.file}
              required
            />
          )}
        </div>
        <div className="tw-my-8 tw-w-full sm:tw-w-1/2 md:tw-w-2/5">
          <Select
            id="lifecycle"
            labelText="Lifecycle"
            placeholder="Select Lifecycle"
            options={[
              { displayName: 'None', value: 'none' },
              { displayName: 'Lead', value: 'lead' },
              { displayName: 'Client', value: 'client' },
              { displayName: 'Former Client', value: 'former_client' }
            ]}
            value={formik.values.lifecycle}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            showError={formik.touched.lifecycle && !!formik.errors.lifecycle}
            error={formik.errors.lifecycle}
            required
          />
        </div>
        <div className="tw-my-8 tw-w-full sm:tw-w-1/2 md:tw-w-2/5">
          <Select
            id="product"
            labelText="Product"
            options={selectProductOptions()}
            value={
              formik.values.product_id
                ? formik.values.product_id
                : formik.values.product
            }
            onChange={e => handleProductChange(e)}
            onBlur={formik.handleBlur}
          />
        </div>
        <div className="tw-my-8 tw-w-full sm:tw-w-1/2 md:tw-w-2/5">
          <Select
            id="campaign"
            labelText="Campaign"
            options={productCampaignOptions}
            value={
              formik.values.campaign_id
                ? formik.values.campaign_id
                : formik.values.campaign
            }
            onChange={e => handleCampaignChange(e)}
            onBlur={formik.handleBlur}
          />
        </div>
        {campaignHasImmediateStep && (
          <div className="tw-my-8 tw-w-full md:tw-w-11/12">
            <p className="tw-mb-0 tw-font-semibold">
              The campaign you selected has automations that will run
              immediately.
            </p>
            <p className="tw-font-semibold">
              Would you like Step 1 to run immediately after import or do you
              prefer your contacts to skip to Step 2?
            </p>
            <RadioInput
              name="sendCampaignStepOne"
              id="sendStepOne"
              value
              labelText="Send Step 1 immediately after import"
              checked={formik.values.sendCampaignStepOne === 'true'}
              onChange={formik.handleChange}
              onBlur={formik.handleblur}
            />
            <RadioInput
              name="sendCampaignStepOne"
              id="skipStepOne"
              value={false}
              labelText="Skip to Step 2"
              checked={formik.values.sendCampaignStepOne === 'false'}
              onChange={formik.handleChange}
              onBlur={formik.handleblur}
            />
          </div>
        )}
        <div className="tw-my-8 tw-w-full sm:tw-w-1/2 md:tw-w-2/5">
          <Select
            id="assignedStaffId"
            labelText="Assigned Staff"
            placeholder="Select Assigned Staff"
            options={isUsersLoaded && !usersError ? userSelectOptions : []}
            value={formik.values.assignedStaffId}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            showError={
              formik.touched.assignedStaffId && !!formik.errors.assignedStaffId
            }
            error={formik.errors.assignedStaffId}
            required
          />
        </div>
        <div className="tw-my-8 tw-w-full sm:tw-w-1/2 md:tw-w-2/5">
          <MultiSelect
            id="customGroups"
            value={formik.values.customGroups}
            options={customGroupOptions}
            labelText="Custom Groups"
            onChange={handleCustomGroupsChange}
            onBlur={formik.handleBlur}
            showAddNewOption
            addNewOptionText="+ Create New Custom Group from Search Input"
            onAddNewOptionClick={() => setShowCustomGroupsModal(true)}
          />
        </div>
        <div className="tw-my-8 tw-w-full md:tw-w-11/12">
          <CheckInput
            name="gdpr"
            id="gdpr"
            value={formik.values.gdpr}
            checked={formik.values.gdpr}
            labelText="I certify that I have received consent has previously been granted to communicate with these contacts."
            onChange={formik.handleChange}
            onBlur={formik.onBlur}
            showError={formik.touched.gdpr && !!formik.errors.gdpr}
            error={formik.errors.gdpr}
          />
        </div>
        <AddNewCustomGroupModal
          show={showCustomGroupsModal}
          setShow={setShowCustomGroupsModal}
          customGroups={customGroupOptions}
        />
      </div>
    </Fragment>
  );
};

Setup.propTypes = {
  formik: PropTypes.shape(formikShape).isRequired,
  csvImportId: PropTypes.number
};

Setup.defaultProps = {
  csvImportId: ''
};

export default Setup;
