import React from 'react';
import { action } from '@storybook/addon-actions';
import { withA11y } from '@storybook/addon-a11y';
import { withKnobs, select, boolean } from '@storybook/addon-knobs';
import Toggle from './index';

export default {
  component: Toggle,
  title: 'Toggle',
  decorators: [withA11y, withKnobs]
};

let sizeSelect;
let colorSelect;

function includeSizeSelect(defaultValue) {
  const label = 'Size';
  const options = ['small', 'large'];
  sizeSelect = select(label, options, defaultValue);
}

function includeColorSelect(defaultValue) {
  const label = 'Color';
  const options = [
    'alpha',
    'bravo',
    'charlie',
    'success',
    'warning',
    'error',
    'gray'
  ];
  colorSelect = select(label, options, defaultValue);
}

export const primary = () => {
  includeSizeSelect('large');
  includeColorSelect('alpha');
  return (
    <Toggle
      onClick={action('toggle-primary-click')}
      size={sizeSelect}
      color={colorSelect}
      withIcons={boolean('With Icons', false)}
      isToggled={boolean('Is Toggle On?', true)}
    />
  );
};

export const smallVariant = () => {
  includeSizeSelect('small');
  includeColorSelect('alpha');
  return (
    <Toggle
      onClick={action('toggle-small-click')}
      size={sizeSelect}
      color={colorSelect}
      withIcons={boolean('With Icons', false)}
      isToggled={boolean('Is Toggle On?', true)}
    />
  );
};

export const withIcons = () => {
  includeSizeSelect('large');
  includeColorSelect('alpha');
  return (
    <Toggle
      onClick={action('toggle-with-icons-click')}
      size={sizeSelect}
      color={colorSelect}
      withIcons={boolean('With Icons', true)}
      isToggled={boolean('Is Toggle On?', true)}
    />
  );
};
