import {
  take,
  call,
  race,
  delay,
  put,
  takeLatest,
  select
} from 'redux-saga/effects';
import { axiosDefault, axiosMultipartForm } from 'lib/utils/axios-setup';
import { toSnake } from 'lib/utils/string';
import {
  FETCH_AVAILABLE_PHONE_NUMBERS_REQUEST,
  FETCH_AVAILABLE_PHONE_NUMBERS_SUCCESS,
  FETCH_AVAILABLE_PHONE_NUMBERS_FAILURE,
  PURCHASE_NUMBER_FOR_ACCOUNT_REQUEST,
  PURCHASE_NUMBER_FOR_ACCOUNT_SUCCESS,
  PURCHASE_NUMBER_FOR_ACCOUNT_FAILURE,
  ASSIGN_NUMBER_FOR_ACCOUNT_REQUEST,
  ASSIGN_NUMBER_FOR_ACCOUNT_SUCCESS,
  ASSIGN_NUMBER_FOR_ACCOUNT_FAILURE,
  CAMPAIGN_PAUSER_PAUSE_REQUEST,
  CAMPAIGN_PAUSER_PAUSE_SUCCESS,
  CAMPAIGN_PAUSER_PAUSE_FAILURE,
  CAMPAIGN_PAUSER_UNPAUSE_REQUEST,
  CAMPAIGN_PAUSER_UNPAUSE_SUCCESS,
  CAMPAIGN_PAUSER_UNPAUSE_FAILURE,
  BULK_JOURNEY_CHANGER_REQUEST,
  BULK_JOURNEY_CHANGER_SUCCESS,
  BULK_JOURNEY_CHANGER_FAILURE,
  JOURNEYS_FETCH_REQUEST,
  JOURNEYS_FETCH_SUCCESS,
  JOURNEYS_FETCH_FAILURE,
  CUSTOM_GROUP_ASSIGNER_REQUEST,
  CUSTOM_GROUP_ASSIGNER_SUCCESS,
  CUSTOM_GROUP_ASSIGNER_FAILURE,
  CUSTOM_GROUPS_FETCH_REQUEST,
  CUSTOM_GROUPS_FETCH_SUCCESS,
  CUSTOM_GROUPS_FETCH_FAILURE,
  ACCOUNT_DISABLER_DISABLE_REQUEST,
  ACCOUNT_ENABLER_ENABLE_REQUEST,
  ACCOUNT_DISABLER_DISABLE_SUCCESS,
  ACCOUNT_ENABLER_ENABLE_SUCCESS,
  ACCOUNT_DISABLER_DISABLE_FAILURE,
  ACCOUNT_ENABLER_ENABLE_FAILURE,
  RECAPTCHA_DISABLER_DISABLE_REQUEST,
  RECAPTCHA_ENABLER_ENABLE_REQUEST,
  RECAPTCHA_DISABLER_DISABLE_SUCCESS,
  RECAPTCHA_ENABLER_ENABLE_SUCCESS,
  RECAPTCHA_DISABLER_DISABLE_FAILURE,
  RECAPTCHA_ENABLER_ENABLE_FAILURE,
  USERS_FETCH_REQUEST,
  USERS_FETCH_SUCCESS,
  USERS_FETCH_FAILURE,
  ACCOUNT_ADMIN_CHANGER_REQUEST,
  ACCOUNT_ADMIN_CHANGER_SUCCESS,
  ACCOUNT_ADMIN_CHANGER_FAILURE,
  SUBDOMAIN_CHANGER_REQUEST,
  SUBDOMAIN_CHANGER_SUCCESS,
  SUBDOMAIN_CHANGER_FAILURE,
  FETCH_SUBDOMAINS_REQUEST,
  FETCH_SUBDOMAINS_SUCCESS,
  FETCH_SUBDOMAINS_FAILURE,
  CONTACTS_FETCH_REQUEST,
  CONTACTS_FETCH_SUCCESS,
  CONTACTS_FETCH_FAILURE,
  MERGE_CONTACTS_REQUEST,
  MERGE_CONTACTS_SUCCESS,
  MERGE_CONTACTS_FAILURE,
  BULK_CAMPAIGN_CHANGER_REQUEST,
  BULK_CAMPAIGN_CHANGER_SUCCESS,
  BULK_CAMPAIGN_CHANGER_FAILURE,
  CAMPAIGNS_FETCH_REQUEST,
  CAMPAIGNS_FETCH_SUCCESS,
  CAMPAIGNS_FETCH_FAILURE,
  REASSIGN_USER_OBJECTS_REQUEST,
  REASSIGN_USER_OBJECTS_SUCCESS,
  REASSIGN_USER_OBJECTS_FAILURE,
  CLONE_USER_AVAILABILITY_REQUEST,
  CLONE_USER_AVAILABILITY_SUCCESS,
  CLONE_USER_AVAILABILITY_FAILURE,
  FETCH_RECENT_ACTIVITY_REQUEST,
  FETCH_RECENT_ACTIVITY_SUCCESS,
  FETCH_RECENT_ACTIVITY_FAILURE,
  FETCH_PROCESSING_ACTIVITIES_SUCCESS,
  FETCH_PROCESSING_ACTIVITIES_FAILURE,
  START_FETCH_PROCESSING_WATCHER,
  STOP_FETCH_PROCESSING_WATCHER,
  ONBOARDING_DISABLER_DISABLE_REQUEST,
  ONBOARDING_ENABLER_ENABLE_REQUEST,
  ONBOARDING_DISABLER_DISABLE_SUCCESS,
  ONBOARDING_ENABLER_ENABLE_SUCCESS,
  ONBOARDING_DISABLER_DISABLE_FAILURE,
  ONBOARDING_ENABLER_ENABLE_FAILURE,
  BULK_CONTACT_UNSUBSCRIBER_REQUEST,
  BULK_CONTACT_UNSUBSCRIBER_SUCCESS,
  BULK_CONTACT_UNSUBSCRIBER_FAILURE,
  CUSTOM_GROUPS_CREATE_REQUEST,
  CUSTOM_GROUPS_CREATE_SUCCESS,
  CUSTOM_GROUPS_CREATE_FAILURE
} from 'appState/actions/constants/overlord.actions';

const RECAPTCHA_SEGMENT_STRING = 'captcha-off';

function* fetchAvailablePhoneNumbers({ payload: { areaCode } = {} }) {
  try {
    const response = yield call(
      axiosDefault.post,
      `/phone_number_changer/fetch_available_phone_numbers?area_code=${areaCode}`
    );

    yield put({
      type: FETCH_AVAILABLE_PHONE_NUMBERS_SUCCESS,
      payload: response.data.available_numbers
    });
  } catch (e) {
    yield put({
      type: FETCH_AVAILABLE_PHONE_NUMBERS_FAILURE,
      payload: { error: e, fallbackError: 'Error loading phone numbers.' },
      error: true
    });
  }
}

function* purchaseNumberForAccount({ payload: { phoneNumber } = {} }) {
  try {
    const response = yield call(
      axiosDefault.post,
      `/phone_number_changer/purchase_number_for_account?phone_number=${phoneNumber}`
    );

    yield put({
      type: PURCHASE_NUMBER_FOR_ACCOUNT_SUCCESS,
      payload: { data: response.data.phone_number }
    });
  } catch (e) {
    yield put({
      type: PURCHASE_NUMBER_FOR_ACCOUNT_FAILURE,
      payload: {
        error: e,
        fallbackError: 'Error purchasing phone number. Please try again.'
      },
      error: true
    });
  }
}

function* assignNumberForAccount({ payload: { accountPhoneNumber } = {} }) {
  try {
    const response = yield call(
      axiosDefault.post,
      `/phone_number_assigner/assign_phone_number_for_account?phone_number=${accountPhoneNumber}`
    );
    yield put({
      type: ASSIGN_NUMBER_FOR_ACCOUNT_SUCCESS,
      payload: { accountPhoneNumber: response.data.account_phone_number }
    });
  } catch (e) {
    yield put({
      type: ASSIGN_NUMBER_FOR_ACCOUNT_FAILURE,
      payload: {
        error: e,
        fallbackError: 'Error assigning phone number. Please try again.'
      },
      error: true
    });
  }
}

function* pauseCampaign() {
  try {
    const response = yield call(axiosDefault.post, '/campaign_pauser/pause');

    yield put({
      type: CAMPAIGN_PAUSER_PAUSE_SUCCESS,
      payload: { data: response.data }
    });
  } catch (e) {
    yield put({
      type: CAMPAIGN_PAUSER_PAUSE_FAILURE,
      payload: {
        error: e,
        fallbackError: 'Error pausing campaign. Please try again.'
      },
      error: true
    });
  }
}

function* unpauseCampaign() {
  try {
    const response = yield call(axiosDefault.post, `/campaign_pauser/unpause`);

    yield put({
      type: CAMPAIGN_PAUSER_UNPAUSE_SUCCESS,
      payload: { data: response.data }
    });
  } catch (e) {
    yield put({
      type: CAMPAIGN_PAUSER_UNPAUSE_FAILURE,
      payload: {
        error: e,
        fallbackError: 'Error unpausing campaign. Please try again.'
      },
      error: true
    });
  }
}

function* changeBulkJourney({ payload: { journeyId, file } = {} }) {
  try {
    const formData = new FormData();
    formData.append('file', new Blob([file], { type: 'text/csv' }), file.name);

    yield call(
      axiosMultipartForm.post,
      `/journey_change_from_csv/change?journey_id=${journeyId}`,
      formData
    );

    yield put({
      type: BULK_JOURNEY_CHANGER_SUCCESS,
      payload: {}
    });
  } catch (e) {
    yield put({
      type: BULK_JOURNEY_CHANGER_FAILURE,
      payload: {
        error: e,
        fallbackError: 'Error bulk changing journey. Please try again.'
      },
      error: true
    });
  }
}

function* bulkUnsubscribeContacts({ payload: { unsubTypes, file } = {} }) {
  try {
    const formData = new FormData();
    formData.append('file', new Blob([file], { type: 'text/csv' }), file.name);
    formData.append('types', unsubTypes);
    yield call(
      axiosMultipartForm.post,
      '/bulk_contact_unsubscribe_from_csv/unsubscribe',
      formData
    );

    yield put({
      type: BULK_CONTACT_UNSUBSCRIBER_SUCCESS,
      payload: {}
    });
  } catch (e) {
    yield put({
      type: BULK_CONTACT_UNSUBSCRIBER_FAILURE,
      payload: {
        error: e,
        fallbackError:
          'Error bulk unsubscribing your contacts. Please try again.'
      },
      error: true
    });
  }
}

function* fetchJourneys({ payload }) {
  let parameterizedString = '';
  if (payload)
    Object.keys(payload).forEach(k => {
      parameterizedString += `${parameterizedString.length ? '&' : ''}${toSnake(
        k
      )}=${payload[k]}`;
    });
  try {
    const response = yield call(
      axiosDefault.get,
      `/journeys?${parameterizedString}`
    );

    yield put({
      type: JOURNEYS_FETCH_SUCCESS,
      payload: response.data
    });
  } catch (e) {
    yield put({
      type: JOURNEYS_FETCH_FAILURE,
      payload: { error: e, fallbackError: 'Error loading journeys.' },
      error: true
    });
  }
}

function* assignCustomGroup({ payload: { customGroupIds, file } = {} }) {
  try {
    const formData = new FormData();
    formData.append('file', new Blob([file], { type: 'text/csv' }), file.name);

    let customGroupString = '';
    customGroupIds.forEach(id => {
      customGroupString += `&email_tag_ids[]=${id}`;
    });

    yield call(
      axiosMultipartForm.post,
      `/custom_group_from_csv/assign?${customGroupString}`,
      formData
    );

    yield put({
      type: CUSTOM_GROUP_ASSIGNER_SUCCESS,
      payload: {}
    });
  } catch (e) {
    yield put({
      type: CUSTOM_GROUP_ASSIGNER_FAILURE,
      payload: {
        error: e,
        fallbackError:
          'Error assigning contacts to custom group. Please try again.'
      },
      error: true
    });
  }
}

function* fetchCustomGroups() {
  try {
    const response = yield call(axiosDefault.get, '/custom_groups');

    yield put({
      type: CUSTOM_GROUPS_FETCH_SUCCESS,
      payload: response.data
    });
  } catch (e) {
    yield put({
      type: CUSTOM_GROUPS_FETCH_FAILURE,
      payload: { error: e, fallbackError: 'Error loading custom groups.' },
      error: true
    });
  }
}

function* createCustomGroups({ payload: { customGroups } }) {
  try {
    const response = yield call(axiosDefault.post, `/custom_groups/bulk`, {
      email_tags: customGroups
    });

    yield put({
      type: CUSTOM_GROUPS_CREATE_SUCCESS,
      payload: {
        newCustomGroups: response.data
      }
    });
  } catch (e) {
    yield put({
      type: CUSTOM_GROUPS_CREATE_FAILURE,
      payload: { error: e, fallbackError: 'Error creating custom groups.' },
      error: true
    });
  }
}

function* disableAccount() {
  try {
    const response = yield call(axiosDefault.put, '/account_disabler/disable');

    yield put({
      type: ACCOUNT_DISABLER_DISABLE_SUCCESS,
      payload: { data: response.data }
    });
  } catch (e) {
    yield put({
      type: ACCOUNT_DISABLER_DISABLE_FAILURE,
      payload: {
        error: e,
        fallbackError: 'Error disabling account. Please try again.'
      },
      error: true
    });
  }
}

function* enableAccount() {
  try {
    const response = yield call(axiosDefault.put, '/account_disabler/enable');

    yield put({
      type: ACCOUNT_ENABLER_ENABLE_SUCCESS,
      payload: { data: response.data }
    });
  } catch (e) {
    yield put({
      type: ACCOUNT_ENABLER_ENABLE_FAILURE,
      payload: {
        error: e,
        fallbackError: 'Error enabling account. Please try again.'
      },
      error: true
    });
  }
}

function* fetchUsers() {
  try {
    const response = yield call(axiosDefault.get, '/users');

    yield put({
      type: USERS_FETCH_SUCCESS,
      payload: response.data
    });
  } catch (e) {
    yield put({
      type: USERS_FETCH_FAILURE,
      payload: { error: e, fallbackError: 'Error loading staff members.' },
      error: true
    });
  }
}

function* changeAccountAdmin({ payload: { userId } = {} }) {
  try {
    const response = yield call(
      axiosDefault.post,
      `/account_admin_changer/change?new_admin_user_id=${userId}`
    );

    yield put({
      type: ACCOUNT_ADMIN_CHANGER_SUCCESS,
      payload: { data: response.data }
    });
  } catch (e) {
    yield put({
      type: ACCOUNT_ADMIN_CHANGER_FAILURE,
      payload: {
        error: e,
        fallbackError: 'Error changing account admin. Please try again.'
      },
      error: true
    });
  }
}

function* changeSubdomain({ payload: { newSubdomain } = {} }) {
  try {
    const response = yield call(
      axiosDefault.put,
      `/account_subdomain_changer/change?new_account_subdomain=${newSubdomain}`
    );

    yield put({
      type: SUBDOMAIN_CHANGER_SUCCESS,
      payload: { data: response.data }
    });
  } catch (e) {
    yield put({
      type: SUBDOMAIN_CHANGER_FAILURE,
      payload: {
        error: e,
        fallbackError: 'Error changing account subdomain. Please try again.'
      },
      error: true
    });
  }
}

function* fetchSubdomains() {
  try {
    const response = yield call(axiosDefault.get, '/subdomains');

    yield put({
      type: FETCH_SUBDOMAINS_SUCCESS,
      payload: response.data.map(subdomain => subdomain.name)
    });
  } catch (e) {
    yield put({
      type: FETCH_SUBDOMAINS_FAILURE,
      payload: {
        error: e,
        fallbackError: 'Error loading previous subdomains.'
      },
      error: true
    });
  }
}

function* fetchContacts() {
  try {
    const response = yield call(
      axiosDefault.get,
      `/contacts?&all_contacts=true`
    );

    yield put({
      type: CONTACTS_FETCH_SUCCESS,
      payload: response.data
    });
  } catch (e) {
    yield put({
      type: CONTACTS_FETCH_FAILURE,
      payload: { error: e, fallbackError: 'Error loading contacts.' },
      error: true
    });
  }
}

function* fetchCampaigns() {
  try {
    const response = yield call(axiosDefault.get, `/campaigns`);

    yield put({
      type: CAMPAIGNS_FETCH_SUCCESS,
      payload: response.data
    });
  } catch (e) {
    yield put({
      type: CAMPAIGNS_FETCH_FAILURE,
      payload: { error: e, fallbackError: 'Error loading campaigns.' },
      error: true
    });
  }
}

function* contactsMerge({
  payload: { baseContactId, duplicateContactId, objectsToMerge } = {}
}) {
  try {
    const response = yield call(
      axiosDefault.post,
      `/contact_merger/merge?base_contact_id=${baseContactId}&duplicate_contact_id=${duplicateContactId}&objects_to_move=${objectsToMerge}`
    );

    yield put({
      type: MERGE_CONTACTS_SUCCESS,
      payload: { data: response.data }
    });
  } catch (e) {
    yield put({
      type: MERGE_CONTACTS_FAILURE,
      payload: {
        error: e,
        fallbackError: 'Error merging contacts. Please try again.'
      },
      error: true
    });
  }
}

function* changeBulkCampaign({
  payload: {
    campaignId,
    createActivity,
    startingPosition,
    shouldCampaignStart,
    file
  } = {}
}) {
  try {
    const formData = new FormData();
    formData.append('file', new Blob([file], { type: 'text/csv' }), file.name);

    yield call(
      axiosMultipartForm.post,
      `/campaign_change_from_csv/change?campaign_id=${campaignId}&create_activity=${createActivity}&starting_position=${startingPosition}&should_campaign_start=${shouldCampaignStart}`,
      formData
    );

    yield put({
      type: BULK_CAMPAIGN_CHANGER_SUCCESS,
      payload: {}
    });
  } catch (e) {
    yield put({
      type: BULK_CAMPAIGN_CHANGER_FAILURE,
      payload: {
        error: e,
        fallbackError: 'Error bulk changing campaign. Please try again.'
      },
      error: true
    });
  }
}

function* reassignUserObject({
  payload: { toUserIds, fromUserIds, objects, selectedLifecycles } = {}
}) {
  try {
    let objectsString = '';
    objects.forEach(object => {
      objectsString += `&objects[]=${object}`;
    });

    let selectedLifecyclesString = '';
    selectedLifecycles.forEach(lifecycle => {
      selectedLifecyclesString += `&selected_lifecycles[]=${lifecycle}`;
    });

    let fromUsersIdsString = '';
    fromUserIds.forEach(id => {
      fromUsersIdsString += `&from_users_ids[]=${id}`;
    });

    let toUsersIdsString = '';
    toUserIds.forEach(id => {
      toUsersIdsString += `&to_users_ids[]=${id}`;
    });

    const response = yield call(
      axiosDefault.post,
      `/user_responsibility_reassigner/reassign?${toUsersIdsString}${fromUsersIdsString}${objectsString}${selectedLifecyclesString}`
    );

    yield put({
      type: REASSIGN_USER_OBJECTS_SUCCESS,
      payload: { data: response.data }
    });
  } catch (e) {
    yield put({
      type: REASSIGN_USER_OBJECTS_FAILURE,
      payload: {
        error: e,
        fallbackError: 'Error reassigning user object. Please try again.'
      },
      error: true
    });
  }
}

function* cloneUserAvailability({
  payload: { toUserId, fromUserId, cloneMethod } = {}
}) {
  try {
    const response = yield call(
      axiosDefault.post,
      `/availability_slots_cloner/clone?to_user_id=${toUserId}&from_user_id=${fromUserId}&clone_method=${cloneMethod}`
    );

    yield put({
      type: CLONE_USER_AVAILABILITY_SUCCESS,
      payload: { data: response.data }
    });
  } catch (e) {
    yield put({
      type: CLONE_USER_AVAILABILITY_FAILURE,
      payload: {
        error: e,
        fallbackError: 'Error cloning user availability. Please try again.'
      },
      error: true
    });
  }
}

function* fetchRecentActivities() {
  try {
    const response = yield call(axiosDefault.get, `/overlord_utility_jobs`);

    yield put({
      type: FETCH_RECENT_ACTIVITY_SUCCESS,
      payload: response.data
    });
  } catch (e) {
    yield put({
      type: FETCH_RECENT_ACTIVITY_FAILURE,
      payload: {
        error: e,
        fallbackError: 'Error loading recent overlord activities.'
      },
      error: true
    });
  }
}

function* fetchProcessingActivities() {
  while (true) {
    try {
      const response = yield call(
        axiosDefault.get,
        `/overlord_utility_jobs?status=processing`
      );
      const { processingActivities } = yield select(
        state => state.overlordUtilities
      );
      yield put({
        type: FETCH_PROCESSING_ACTIVITIES_SUCCESS,
        payload: response.data
      });
      if (processingActivities.length !== response.data.length) {
        yield put({
          type: FETCH_RECENT_ACTIVITY_REQUEST,
          payload: {}
        });
      }

      yield delay(5000);
    } catch (e) {
      yield put({
        type: FETCH_PROCESSING_ACTIVITIES_FAILURE,
        payload: {
          error: e,
          fallbackError:
            'Error loading currently processing overlord activities.'
        },
        error: true
      });
      yield put({
        type: STOP_FETCH_PROCESSING_WATCHER,
        error: true
      });
    }
  }
}

function* disableOnboarding() {
  try {
    const response = yield call(
      axiosDefault.put,
      `/account_onboarding/deactivate`
    );

    yield put({
      type: ONBOARDING_DISABLER_DISABLE_SUCCESS,
      payload: { data: response.data }
    });
  } catch (e) {
    yield put({
      type: ONBOARDING_DISABLER_DISABLE_FAILURE,
      payload: {
        error: e,
        fallbackError: 'Error disabling onboarding. Please try again.'
      },
      error: true
    });
  }
}

function* enableOnboarding() {
  try {
    const response = yield call(
      axiosDefault.put,
      `/account_onboarding/activate`
    );

    yield put({
      type: ONBOARDING_ENABLER_ENABLE_SUCCESS,
      payload: { data: response.data }
    });
  } catch (e) {
    yield put({
      type: ONBOARDING_ENABLER_ENABLE_FAILURE,
      payload: {
        error: e,
        fallbackError: 'Error onboarding account. Please try again.'
      },
      error: true
    });
  }
}

function* disableRecaptcha() {
  try {
    const params = {
      segment: RECAPTCHA_SEGMENT_STRING
    };
    const response = yield call(
      axiosDefault.put,
      '/recaptcha_disabler/disable',
      params
    );

    yield put({
      type: RECAPTCHA_DISABLER_DISABLE_SUCCESS,
      payload: { data: response.data }
    });
  } catch (e) {
    yield put({
      type: RECAPTCHA_DISABLER_DISABLE_FAILURE,
      payload: { error: e, fallbackError: 'Error Turning Off Recaptcha.' },
      error: true
    });
  }
}

function* enableRecaptcha() {
  try {
    const params = {
      segment: RECAPTCHA_SEGMENT_STRING
    };
    const response = yield call(
      axiosDefault.put,
      '/recaptcha_disabler/enable',
      params
    );

    yield put({
      type: RECAPTCHA_ENABLER_ENABLE_SUCCESS,
      payload: { data: response.data }
    });
  } catch (e) {
    yield put({
      type: RECAPTCHA_ENABLER_ENABLE_FAILURE,
      payload: { error: e, fallbackError: 'Error Turning Off Recaptcha.' },
      error: true
    });
  }
}

export function* pollFetchProcessingActivitiesWatcher() {
  while (true) {
    const action = yield take(START_FETCH_PROCESSING_WATCHER);
    yield race([
      call(fetchProcessingActivities, action),
      take(STOP_FETCH_PROCESSING_WATCHER)
    ]);
  }
}

export function* phoneNumbersFetchAvailable() {
  yield takeLatest(
    FETCH_AVAILABLE_PHONE_NUMBERS_REQUEST,
    fetchAvailablePhoneNumbers
  );
}

export function* phoneNumbersPurchaseForAccount() {
  yield takeLatest(
    PURCHASE_NUMBER_FOR_ACCOUNT_REQUEST,
    purchaseNumberForAccount
  );
}

export function* phoneNumberAssignForAccount() {
  yield takeLatest(ASSIGN_NUMBER_FOR_ACCOUNT_REQUEST, assignNumberForAccount);
}

export function* campaignPause() {
  yield takeLatest(CAMPAIGN_PAUSER_PAUSE_REQUEST, pauseCampaign);
}

export function* campaignUnpause() {
  yield takeLatest(CAMPAIGN_PAUSER_UNPAUSE_REQUEST, unpauseCampaign);
}

export function* bulkJourneyChange() {
  yield takeLatest(BULK_JOURNEY_CHANGER_REQUEST, changeBulkJourney);
}

export function* getJourneys() {
  yield takeLatest(JOURNEYS_FETCH_REQUEST, fetchJourneys);
}

export function* customGroupAssign() {
  yield takeLatest(CUSTOM_GROUP_ASSIGNER_REQUEST, assignCustomGroup);
}

export function* getCustomGroups() {
  yield takeLatest(CUSTOM_GROUPS_FETCH_REQUEST, fetchCustomGroups);
}

export function* createNewCustomGroups() {
  yield takeLatest(CUSTOM_GROUPS_CREATE_REQUEST, createCustomGroups);
}

export function* accountDisable() {
  yield takeLatest(ACCOUNT_DISABLER_DISABLE_REQUEST, disableAccount);
}

export function* accountEnable() {
  yield takeLatest(ACCOUNT_ENABLER_ENABLE_REQUEST, enableAccount);
}

export function* recaptchaDisable() {
  yield takeLatest(RECAPTCHA_DISABLER_DISABLE_REQUEST, disableRecaptcha);
}

export function* recaptchaEnable() {
  yield takeLatest(RECAPTCHA_ENABLER_ENABLE_REQUEST, enableRecaptcha);
}

export function* getUsers() {
  yield takeLatest(USERS_FETCH_REQUEST, fetchUsers);
}

export function* accountAdminChange() {
  yield takeLatest(ACCOUNT_ADMIN_CHANGER_REQUEST, changeAccountAdmin);
}

export function* subdomainChange() {
  yield takeLatest(SUBDOMAIN_CHANGER_REQUEST, changeSubdomain);
}

export function* getSubdomains() {
  yield takeLatest(
    [FETCH_SUBDOMAINS_REQUEST, SUBDOMAIN_CHANGER_SUCCESS],
    fetchSubdomains
  );
}

export function* getContacts() {
  yield takeLatest(CONTACTS_FETCH_REQUEST, fetchContacts);
}

export function* mergeContacts() {
  yield takeLatest(MERGE_CONTACTS_REQUEST, contactsMerge);
}

export function* getCampaigns() {
  yield takeLatest(CAMPAIGNS_FETCH_REQUEST, fetchCampaigns);
}

export function* bulkCampaignChange() {
  yield takeLatest(BULK_CAMPAIGN_CHANGER_REQUEST, changeBulkCampaign);
}

export function* bulkContactUnsubscribe() {
  yield takeLatest(BULK_CONTACT_UNSUBSCRIBER_REQUEST, bulkUnsubscribeContacts);
}

export function* userObjectReassign() {
  yield takeLatest(REASSIGN_USER_OBJECTS_REQUEST, reassignUserObject);
}

export function* userAvailabilityClone() {
  yield takeLatest(CLONE_USER_AVAILABILITY_REQUEST, cloneUserAvailability);
}

export function* getRecentActivity() {
  yield takeLatest(
    [
      PURCHASE_NUMBER_FOR_ACCOUNT_SUCCESS,
      PURCHASE_NUMBER_FOR_ACCOUNT_FAILURE,
      ASSIGN_NUMBER_FOR_ACCOUNT_SUCCESS,
      ASSIGN_NUMBER_FOR_ACCOUNT_FAILURE,
      CAMPAIGN_PAUSER_PAUSE_SUCCESS,
      CAMPAIGN_PAUSER_PAUSE_FAILURE,
      CAMPAIGN_PAUSER_UNPAUSE_SUCCESS,
      CAMPAIGN_PAUSER_UNPAUSE_FAILURE,
      BULK_JOURNEY_CHANGER_SUCCESS,
      BULK_JOURNEY_CHANGER_FAILURE,
      CUSTOM_GROUP_ASSIGNER_SUCCESS,
      CUSTOM_GROUP_ASSIGNER_FAILURE,
      ACCOUNT_DISABLER_DISABLE_SUCCESS,
      ACCOUNT_DISABLER_DISABLE_FAILURE,
      ACCOUNT_ENABLER_ENABLE_SUCCESS,
      ACCOUNT_ENABLER_ENABLE_FAILURE,
      ACCOUNT_ADMIN_CHANGER_SUCCESS,
      ACCOUNT_ADMIN_CHANGER_FAILURE,
      SUBDOMAIN_CHANGER_SUCCESS,
      SUBDOMAIN_CHANGER_FAILURE,
      MERGE_CONTACTS_SUCCESS,
      MERGE_CONTACTS_FAILURE,
      BULK_CAMPAIGN_CHANGER_SUCCESS,
      BULK_CAMPAIGN_CHANGER_FAILURE,
      REASSIGN_USER_OBJECTS_SUCCESS,
      REASSIGN_USER_OBJECTS_FAILURE,
      CLONE_USER_AVAILABILITY_SUCCESS,
      CLONE_USER_AVAILABILITY_FAILURE,
      FETCH_RECENT_ACTIVITY_REQUEST,
      ONBOARDING_DISABLER_DISABLE_SUCCESS,
      ONBOARDING_DISABLER_DISABLE_FAILURE,
      ONBOARDING_ENABLER_ENABLE_SUCCESS,
      ONBOARDING_ENABLER_ENABLE_FAILURE,
      BULK_CONTACT_UNSUBSCRIBER_FAILURE,
      BULK_CONTACT_UNSUBSCRIBER_SUCCESS
    ],
    fetchRecentActivities
  );
}

export function* onboardDisable() {
  yield takeLatest(ONBOARDING_DISABLER_DISABLE_REQUEST, disableOnboarding);
}

export function* onboardEnable() {
  yield takeLatest(ONBOARDING_ENABLER_ENABLE_REQUEST, enableOnboarding);
}
