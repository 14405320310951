import React, { useState, useEffect, useMemo, useContext } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Card from 'components/Theme/Card';
import PageHeader from 'components/Theme/PageHeader';
import {
  newImportStepOneSchema,
  newImportStepOneGdprSchema
} from 'lib/validation/schema';
import { titleize } from 'lib/utils/string';
import {
  validateContactsCsv,
  saveContactsCsv,
  createCsvImport,
  updateCsvImport,
  fetchCsvImport,
  scheduleCsvImport,
  flashSuccessMessage,
  flashErrorMessage
} from 'appState/actions/ActionCreators';
import { createStructuredSelector } from 'reselect';
import {
  createLoadedSelector,
  createErrorMessageSelector
} from 'appState/selectors';
import isEmpty from 'lodash.isempty';
import { CurrentAccountContext } from 'components/shared/context/CurrentAccountContext';
import ReviewHeaders from './StepTwo/ReviewHeaders';
import Setup from './StepOne/Setup';
import { SUB_TITLES, TITLES, MANDATORY_FIELDS } from './common/constant';
import ImportValidate from './StepThree/ImportValidate';
import ImportStatus from './StepFour/ImportStatus';

const ContactsImportNew = () => {
  const nextQuarterHour = new Date(
    Math.ceil(new Date().getTime() / 900000) * 900000
  );
  const { id } = useParams();
  const currentAccount = useContext(CurrentAccountContext);
  const [primaryActionText, setPrimaryActionText] = useState('Next');
  const [importType, setImportType] = useState('import_now');
  const [importStep, setImportStep] = useState(id ? '' : 0);
  const [importProgress, setImportProgress] = useState(false);
  const [scheduleForDateTime, setScheduleForDateTime] = useState(
    nextQuarterHour
  );
  const dispatch = useDispatch();
  const statuses = {
    processing: 0,
    mapHeaders: 1,
    validateRows: 2,
    review: 3,
    imported: 3,
    importing: 3,
    scheduled: 3
  };

  const scheduleCsvImportLoadedSelector = createLoadedSelector([
    'contact/SCHEDULE_CSV_IMPORT'
  ]);

  const scheduleCsvImportErrorSelector = createErrorMessageSelector([
    'contact/SCHEDULE_CSV_IMPORT'
  ]);

  const saveCsvImportLoadedSelector = createLoadedSelector([
    'contact/SAVE_CONTACTS_CSV'
  ]);

  const saveCsvImportErrorSelector = createErrorMessageSelector([
    'contact/SAVE_CONTACTS_CSV'
  ]);

  const fetchCsvImportLoadedSelector = createLoadedSelector([
    'contact/FETCH_CSV_IMPORT'
  ]);

  const createCsvImportLoadedSelector = createLoadedSelector([
    'contact/CREATE_CSV_IMPORT'
  ]);

  const createCsvImportErrorSelector = createErrorMessageSelector([
    'contact/CREATE_CSV_IMPORT'
  ]);

  const structuredSelector = createStructuredSelector({
    mappedHeaders: state => state.contact.mappedHeaders,
    fileData: state => state.contact.fileData,
    headers: state => state.contact.headers,
    csvImportId: state => state.contact.csvImportId,
    filename: state => state.contact.filename,
    importedContact: state => state.contact.importedContact,
    scheduleCsvImportLoadeded: state => scheduleCsvImportLoadedSelector(state),
    scheduleCsvImportError: state => scheduleCsvImportErrorSelector(state),
    saveCsvImportLoadeded: state => saveCsvImportLoadedSelector(state),
    saveCsvImportError: state => saveCsvImportErrorSelector(state),
    createCsvImportLoaded: state => createCsvImportLoadedSelector(state),
    createCsvImportError: state => createCsvImportErrorSelector(state),
    importStatus: state => state.contact.status,
    fetchCsvImportLoaded: state => fetchCsvImportLoadedSelector(state),
    lifecycle: state => state.contact.lifecycle,
    product: state => state.contact.product,
    product_id: state => state.contact.product_id,
    campaign: state => state.contact.campaign,
    campaign_id: state => state.contact.campaign_id,
    assignedStaffId: state => state.contact.assignedStaffId,
    gdprCompliant: state => state.contact.gdprCompliant
  });

  const {
    mappedHeaders,
    importedContact,
    fileData,
    headers,
    csvImportId,
    filename,
    scheduleCsvImportLoadeded,
    scheduleCsvImportError,
    saveCsvImportLoadeded,
    saveCsvImportError,
    createCsvImportLoaded,
    createCsvImportError,
    importStatus,
    fetchCsvImportLoaded,
    lifecycle,
    product,
    campaign,
    campaign_id,
    product_id,
    assignedStaffId,
    gdprCompliant
  } = useSelector(structuredSelector);

  function handleImportType(importTypeVal) {
    setImportType(importTypeVal);
    setPrimaryActionText(
      importTypeVal === 'schedule' ? 'Schedule Import' : 'Import Now'
    );
  }

  function handleNextClick() {
    setPrimaryActionText('Next');
    if (importStep === 0) {
      if (id) {
        setImportStep(importStep + 1);
        updateContactCsv();
      } else {
        createContactCsv();
      }
    } else if (importStep === 1) {
      handleValidation();
    } else if (importStep === 2) {
      setImportStep(importStep + 1);
      setPrimaryActionText('Import Now');
    } else if (importStep >= 3) {
      const actionText = importType === 'schedule' ? 'Scheduled' : 'Importing';
      setPrimaryActionText(actionText);
      scheduleImport();
    }
  }

  function scheduleImport() {
    const params = {
      mappedData: mappedHeaders,
      fileData,
      csvImportId: csvImportId || id,
      importedContact,
      scheduleForDateTime,
      importType
    };

    dispatch(scheduleCsvImport(params));
  }

  function handleBackClick() {
    setImportStep(importStep - 1);
    setPrimaryActionText('Next');
  }

  function customValidateSchema() {
    if (importStep === 0 && !id) {
      return currentAccount.gdpr_compliant
        ? newImportStepOneGdprSchema
        : newImportStepOneSchema;
    }

    return null;
  }
  const formik = useFormik({
    initialValues: {
      file: null,
      filename: '',
      lifecycle: 'none',
      product_id: '',
      campaign_id: '',
      sendCampaignStepOne: 'true',
      assignedStaffId: '',
      customGroups: '',
      headers: [],
      fileData: [],
      mappedHeaders: {},
      campaignName: '',
      gdpr: gdprCompliant || false
    },
    enableReinitialize: true,
    validationSchema: customValidateSchema(),
    onSubmit: () => {
      // eslint-disable-next-line no-use-before-define
      handleNextClick();
    }
  });

  useEffect(() => {
    formik.setFieldValue('headers', headers);
    formik.setFieldValue('fileData', fileData);
    formik.setFieldValue('mappedHeaders', mappedHeaders);
    formik.setFieldValue('filename', filename);
    formik.setFieldValue('lifecycle', lifecycle);
    formik.setFieldValue('product', product);
    formik.setFieldValue('product_id', product_id);
    formik.setFieldValue('campaign_id', campaign_id);
    formik.setFieldValue('campaignName', campaign);
    formik.setFieldValue('assignedStaffId', assignedStaffId);
  }, [fileData, mappedHeaders, headers]);

  const isValidData = useMemo(() => {
    if (importStep !== 1) {
      return true;
    }
    const { mappedHeaders } = formik.values;
    const valuesArray = Object.values(mappedHeaders);
    if (!valuesArray.includes('')) {
      return MANDATORY_FIELDS.every(f => valuesArray.includes(f));
    }
    return false;
  }, [formik.values.mappedHeaders]);

  function handleDisablePrimaryAction() {
    if (['importing', 'imported'].includes(importStatus) || !isValidData) {
      return true;
    }

    return false;
  }

  function handleValidation() {
    const { mappedHeaders, fileData, assignedStaffId } = formik.values;
    setImportStep(importStep + 1);
    dispatch(
      validateContactsCsv({
        mappedData: mappedHeaders,
        fileData,
        assignedStaffId,
        id
      })
    );
  }

  function getCsvImport() {
    dispatch(
      fetchCsvImport({
        id
      })
    );
  }

  function setStep() {
    let step = 0;
    if (id) {
      step = importStatus ? statuses[importStatus] : 1;
    }
    return step;
  }

  const isReadOnly = ['importing', 'imported', 'scheduled'].includes(
    importStatus
  );

  useEffect(() => {
    id && getCsvImport();
  }, [id]);

  useEffect(() => {
    if (fetchCsvImportLoaded) {
      setImportStep(setStep());

      let text = 'Next';
      if (isReadOnly) {
        text = titleize(importStatus);
      } else if (importStep >= 3 || importStep === '') {
        text = 'Import Now';
      }
      setPrimaryActionText(text);
    }
  }, [dispatch, fetchCsvImportLoaded]);

  useEffect(() => {
    if (scheduleCsvImportLoadeded) {
      if (isEmpty(scheduleCsvImportError)) {
        if (importType === 'schedule') {
          window.location = '/ui/contacts/csv_import/history';
          dispatch(flashSuccessMessage('Successfully scheduled csv import.'));
        } else {
          setImportProgress(true);
        }
      } else {
        dispatch(flashErrorMessage(scheduleCsvImportError));
      }
    }
  }, [dispatch, scheduleCsvImportLoadeded, scheduleCsvImportError]);

  useEffect(() => {
    if (saveCsvImportLoadeded) {
      if (isEmpty(saveCsvImportError)) {
        dispatch(flashSuccessMessage('Successfully saved csv import.'));
        window.location = '/ui/contacts/csv_import/history';
      } else {
        dispatch(flashErrorMessage(saveCsvImportError));
      }
    }
  }, [dispatch, saveCsvImportLoadeded, saveCsvImportError]);

  useEffect(() => {
    if (createCsvImportLoaded) {
      if (isEmpty(createCsvImportError)) {
        setImportStep(importStep + 1);
      } else {
        dispatch(flashErrorMessage(createCsvImportError));
      }
    }
  }, [dispatch, createCsvImportLoaded, createCsvImportError]);

  const contactImportSteps = [
    <Setup key={`newImport-${importStep}`} formik={formik} csvImportId={id} />,
    <ReviewHeaders key={`newImport-${importStep}`} formik={formik} />,
    <ImportValidate key={`newImport-${importStep}`} formik={formik} />,
    <ImportStatus
      key={`newImport-${importStep}`}
      formik={formik}
      setImportStep={setImportStep}
      importType={importType}
      setScheduleForDateTime={setScheduleForDateTime}
      handleImportType={handleImportType}
      scheduleForDateTime={scheduleForDateTime}
      importProgress={importProgress}
      importedContact={importedContact}
      importStatus={importStatus}
      setPrimaryActionText={setPrimaryActionText}
    />
  ];

  function saveForLater() {
    const { mappedHeaders, fileData } = formik.values;
    const status = Object.keys(statuses)[importStep];
    dispatch(
      saveContactsCsv({
        mappedData: mappedHeaders,
        fileData,
        status,
        csvImportId,
        importedContact
      })
    );
  }

  function updateContactCsv() {
    const { assignedStaffId, lifecycle, campaign_id, gdpr } = formik.values;
    dispatch(
      updateCsvImport({
        assignedStaffId,
        lifecycle,
        campaign_id,
        csvImportId,
        gdpr
      })
    );
  }

  function createContactCsv() {
    const {
      file,
      assignedStaffId,
      lifecycle,
      campaign_id,
      gdpr
    } = formik.values;
    dispatch(
      createCsvImport({
        file,
        assignedStaffId,
        lifecycle,
        campaign_id,
        gdpr
      })
    );
  }

  return (
    <div className="tw-min-h-screen">
      <PageHeader
        title={TITLES[importStep]}
        breadcrumbs={[
          {
            name: 'Contacts',
            action: () => {
              window.location = '/contacts';
            }
          },
          {
            name: 'New Import',
            action: () => {
              window.location = '/ui/contacts/import';
            }
          }
        ]}
      >
        <h5 className="tw-text-gray-500">{SUB_TITLES[importStep]}</h5>
      </PageHeader>
      <div className="tw-mx-7 tw-mt-7 tw-mb-24">
        <Card
          hasDetachedActions
          showPrimaryAction
          primaryActionText={primaryActionText}
          disablePrimaryAction={isReadOnly || !isValidData || importProgress}
          primaryAction={formik.handleSubmit}
          containerClass="tw-bg-white tw-rounded-md"
          showSecondaryAction={importStep > 0 && !isReadOnly && !importProgress}
          secondaryActionText="Back"
          secondaryAction={handleBackClick}
          showTertiaryAction={importStep > 0 && !isReadOnly && !importProgress}
          tertiaryActionText="Save for Later"
          tertiaryAction={saveForLater}
        >
          <div className="tw-mx-10 tw-mt-10 tw-mb-20">
            {contactImportSteps[importStep]}
          </div>
        </Card>
      </div>
    </div>
  );
};

export default ContactsImportNew;
