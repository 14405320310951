import isEmpty from 'lodash.isempty';

function getNormalizedSmsType(smsType) {
  const transactionalTypes = [
    'client_transactional',
    'transactional',
    'uplaunch_action'
  ];
  const outboundTypes = [
    'campaign',
    'customized_campaign',
    'outbound',
    'staff'
  ];

  if (transactionalTypes.indexOf(smsType) !== -1) {
    return 'transactional';
  }
  if (outboundTypes.indexOf(smsType) !== -1) {
    return 'outbound';
  }
  return smsType;
}

export const decorateMessage = (message, previous = null, next = null) => {
  // eslint-disable-next-line camelcase
  const { sms_type, ...messageRest } = message;
  const currentMessageSmsType = getNormalizedSmsType(sms_type);
  const nextSmsType = next && getNormalizedSmsType(next.sms_type);
  const previousSmsType = previous && getNormalizedSmsType(previous.sms_type);
  const currentSenderId = message && message.user.id;
  const previousSenderId = previous && previous.user.id;
  const nextSenderId = next && next.user.id;
  const isCurrentMessageMms = !isEmpty(message?.media_urls);
  const isPreviousMessageMms = !isEmpty(previous?.media_urls);

  const isLast =
    next === undefined ||
    isCurrentMessageMms ||
    nextSmsType !== currentMessageSmsType ||
    (nextSenderId !== currentSenderId && currentMessageSmsType !== 'inbound');

  const isFirst =
    previous === undefined ||
    isCurrentMessageMms ||
    isPreviousMessageMms ||
    previousSmsType !== currentMessageSmsType ||
    (previousSenderId !== currentSenderId &&
      currentMessageSmsType !== 'inbound');

  const decoratedMessage = {
    ...messageRest,
    sms_type: currentMessageSmsType,
    smsType: currentMessageSmsType,
    mms: isCurrentMessageMms,
    last: isLast,
    left: currentMessageSmsType === 'inbound',
    right: currentMessageSmsType !== 'inbound',
    first: isFirst
  };

  return decoratedMessage;
};

export const decorateMessages = messages => {
  return messages.map((message, index, array) => {
    // previous & next are flipped because the list is inverted
    const previous = array[index + 1];
    const next = array[index - 1];

    return decorateMessage(message, previous, next);
  });
};
