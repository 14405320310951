import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';

const SkeletonItem = ({ isLastItem }) => {
  return (
    <div className="tw-flex">
      <div className="tw-flex-initial tw-flex tw-flex-col">
        <div className="tw--mt-6">
          <Skeleton circle height={40} width={40} />
        </div>
        {!isLastItem && (
          <div className="tw-align-middle tw-min-h-full tw-flex tw-flex-col">
            <div className="tw-flex-1 tw-mx-auto tw--mt-2">
              <Skeleton height={130} width={4} />
            </div>
          </div>
        )}
      </div>
      <div className="tw-flex-1 tw-pl-5 tw-flex tw-items-start tw-justify-between">
        <div className="tw-rounded-lg tw--mt-6">
          <div className="tw-flex tw-items-start tw-mt-4">
            <div className="tw-font-body tw-text-base tw-font-medium tw-pb-2">
              <div className="tw-inline-flex">
                <p className="tw-mb-0 tw-inline tw-relative">
                  <Skeleton height={24} width={376} />
                </p>
              </div>
            </div>
          </div>
          <div className="tw-font-body tw-leading-5 tw-text-gray-500 tw-text-sm">
            <Skeleton height={20} width={340} />
          </div>
          <div className="tw-pt-3 tw-mb-4">
            <Skeleton height={34} width={99} />
          </div>
        </div>
        <div className="tw-mt-5 sm:tw-mt-0 sm:tw-ml-6 sm:tw-flex-shrink-0 sm:tw-flex sm:tw-items-center">
          <div className="tw-font-body tw-text-xs tw-leading-snug tw-mb-0 tw--mt-2">
            <Skeleton height={16} width={80} />
          </div>
        </div>
      </div>
    </div>
  );
};

SkeletonItem.defaultProps = {
  isLastItem: false
};

SkeletonItem.propTypes = {
  isLastItem: PropTypes.bool
};

export default SkeletonItem;
