import { call, put, takeLatest } from 'redux-saga/effects';
import { axiosDefault } from 'lib/utils/axios-setup';
import {
  FETCH_PARTNERS_REQUEST,
  FETCH_PARTNERS_SUCCESS,
  FETCH_PARTNERS_FAILURE,
  FETCH_PARTNER_REQUEST,
  FETCH_PARTNER_SUCCESS,
  FETCH_PARTNER_FAILURE
} from 'appState/actions/constants/partner.actions';

function* fetchPartners() {
  try {
    const response = yield call(axiosDefault.get, '/partners');

    yield put({
      type: FETCH_PARTNERS_SUCCESS,
      payload: {
        partners: response.data
      }
    });
  } catch (e) {
    yield put({
      type: FETCH_PARTNERS_FAILURE,
      payload: {
        error: e
      },
      error: true
    });
  }
}

function* fetchPartner({ payload: { partnerId } }) {
  try {
    const response = yield call(axiosDefault.get, `/partners/${partnerId}`);

    yield put({
      type: FETCH_PARTNER_SUCCESS,
      payload: {
        currentPartner: response.data,
        products: response.data.products
      }
    });
  } catch (e) {
    yield put({
      type: FETCH_PARTNER_FAILURE,
      payload: {
        error: e
      },
      error: true
    });
  }
}

export function* partnersFetch() {
  yield takeLatest(FETCH_PARTNERS_REQUEST, fetchPartners);
}

export function* partnerFetch() {
  yield takeLatest(FETCH_PARTNER_REQUEST, fetchPartner);
}
