import React, { useContext, useEffect, useState } from 'react';
import UtilityCard from 'components/OverlordUtilities/shared/UtilityCard';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import isEmpty from 'lodash.isempty';
import {
  createErrorMessageSelector,
  createLoadedSelector
} from 'appState/selectors';
import {
  cloneUserAvailability,
  resetIsLoaded
} from 'appState/actions/ActionCreators';
import { CurrentAccountContext } from 'components/shared/context/CurrentAccountContext';
import { UsersContext } from 'components/shared/context/UsersContext';
import useProcessingActivity from 'components/shared/hooks/useProcessingActivity';
import useAccountDisabled from 'components/shared/hooks/useAccountDisabled';

const UTILITY_NAMES = ['availability_cloner'];

const AvailabilityCloner = () => {
  const { users, isUsersLoaded, usersError } = useContext(UsersContext);
  const processingActivity = useProcessingActivity(UTILITY_NAMES);
  const accountDisabled = useAccountDisabled();

  const errorSelector = createErrorMessageSelector([
    'overlord/CLONE_USER_AVAILABILITY'
  ]);
  const loadedSelector = createLoadedSelector([
    'overlord/CLONE_USER_AVAILABILITY'
  ]);
  const structuredSelector = createStructuredSelector({
    error: state => errorSelector(state),
    isLoaded: state => loadedSelector(state)
  });

  const { error, isLoaded } = useSelector(structuredSelector);

  const userSelectOptions = users.map(user => {
    return (
      <option key={user.id} value={user.id}>
        {user.id} - {user.full_name}
      </option>
    );
  });

  const [toUserId, setToUserId] = useState();
  const [fromUserId, setFromUserId] = useState();
  const [cloneMethod, setCloneMethod] = useState();

  const dispatch = useDispatch();

  const cloneAvailability = () => {
    if (cloneMethod && toUserId && fromUserId) {
      dispatch(
        cloneUserAvailability({
          toUserId,
          fromUserId,
          cloneMethod
        })
      );
    }
  };

  useEffect(() => {
    if (isLoaded && isEmpty(error)) {
      const toUser = users.filter(u => u.id === parseInt(toUserId, 10))[0];
      const fromUser = users.filter(u => u.id === parseInt(fromUserId, 10))[0];
      app.toast(
        `Successfully cloned (${
          cloneMethod === 'keep' ? 'kept' : 'replaced'
        }) availability from ${fromUser.full_name} to ${toUser.full_name}`,
        {
          type: 'primary',
          icon: 'fa-floppy-o'
        }
      );
    }

    return () => {
      dispatch(resetIsLoaded('overlord/CLONE_USER_AVAILABILITY'));
    };
  }, [users, toUserId, fromUserId, cloneMethod, error, isLoaded, dispatch]);

  const getCardBody = () => {
    return (
      <div>
        {/* <%= form_tag overlord_utility_clone_availability_slots_path(overlord_utility_id: @account.id) , method: :post do %> */}
        <div className="row">
          <div className="col-lg-12">
            <p>Choose how you would like to clone the availability slots</p>
            {/* <%= label_tag :clone_method, 'Clone Method' %>
              <%= select_tag :clone_method, options_for_select([['Replace Current Slots On To User','replace'],['Keep Current Slots On To User', 'keep'] ]), class: 'form-control', 'data-size': '20', 'data-provide': 'selectpicker' %> */}
            <label htmlFor="cloneMethod">Clone Method</label>
            <select
              onChange={e => setCloneMethod(e.target.value)}
              id="cloneMethod"
              className="form-control"
              data-size="20"
              data-provide="selectpicker"
              defaultValue=""
            >
              <option value="" disabled hidden>
                Nothing selected
              </option>
              <option value="replace">Replace Current Slots On To User</option>
              <option value="keep">Keep Current Slots On To User</option>
            </select>
          </div>
        </div>
        <hr />
        <div className="form-row">
          <div className="col-lg-12">
            <p>
              Select the user you want to clone the availability slots from and
              the user you want to clone the availability slots to.
            </p>
          </div>
          <div className="col-lg-6 form-group">
            {/* <%= label_tag :from_user, 'From User' %>
              <%= select_tag :from_user, options_for_select(@account.users.active.order(:id).map { |u| ["#{u.id} - #{u.full_name}", "#{u.id}"] }), class: 'form-control', 'data-size': '20', 'data-provide': 'selectpicker' %> */}
            <label htmlFor="fromUser">From User</label>
            {users && users.length ? (
              <select
                onChange={e => setFromUserId(e.target.value)}
                id="fromUser"
                className="form-control"
                data-size="20"
                data-provide="selectpicker"
                defaultValue=""
              >
                <option value="" disabled hidden>
                  Nothing selected
                </option>
                {userSelectOptions}
              </select>
            ) : null}
          </div>
          <div className="col-lg-6 form-group">
            {/* <%= label_tag :to_user, 'To User' %>
              <%= select_tag :to_user, options_for_select(@account.users.active.order(:id).map { |u| ["#{u.id} - #{u.full_name}", "#{u.id}"] }), class: 'form-control', 'data-size': '20', 'data-provide': 'selectpicker' %> */}
            <label htmlFor="toUser">To User</label>
            {users && users.length ? (
              <select
                onChange={e => setToUserId(e.target.value)}
                id="toUser"
                className="form-control"
                data-size="20"
                data-provide="selectpicker"
                defaultValue=""
              >
                <option value="" disabled hidden>
                  Nothing selected
                </option>
                {userSelectOptions}
              </select>
            ) : null}
          </div>
        </div>
        {/* <% end %> */}
      </div>
    );
  };
  return (
    <div className="col-lg-6 pl-10">
      <UtilityCard
        title="Clone User Availability Slots"
        subtitle="Use this tool to clone availability slots from one user to
        another."
        submitBtnText="Confirm Availability Slots"
        submitBtnAction={cloneAvailability}
        bodyComponent={getCardBody}
        isLoaded={isUsersLoaded}
        processingActivity={processingActivity}
        accountDisabled={accountDisabled}
        error={error || usersError}
      />
    </div>
  );
};

export default AvailabilityCloner;
