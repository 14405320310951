import { call, put, takeLatest } from 'redux-saga/effects';
import { axiosDefault } from 'lib/utils/axios-setup';
import {
  CREATE_TASK_REQUEST,
  CREATE_TASK_SUCCESS,
  CREATE_TASK_FAILURE,
  UPDATE_TASK_REQUEST,
  UPDATE_TASK_SUCCESS,
  UPDATE_TASK_FAILURE,
  FETCH_TASK_REQUEST,
  FETCH_TASK_SUCCESS,
  FETCH_TASK_FAILURE
} from 'appState/actions/constants/task.actions';
import {
  isDummyContact,
  createDummyTask
} from 'lib/services/dummyContactResponses';

function* createTask({
  payload: { title, description, dueDate, staffId, contactId }
}) {
  try {
    const params = {
      task: {
        title,
        description,
        due_date: dueDate,
        contact_id: contactId,
        staff_id: staffId,
        type: 'ManualTask'
      }
    };

    if (yield call(isDummyContact)) return yield call(createDummyTask, params);

    const response = yield call(axiosDefault.post, '/tasks', params);

    return yield put({
      type: CREATE_TASK_SUCCESS,
      payload: {
        currentTask: response.data
      }
    });
  } catch (e) {
    return yield put({
      type: CREATE_TASK_FAILURE,
      payload: { error: e },
      error: true
    });
  }
}

function* updateTask({
  payload: {
    taskId,
    title = null,
    description = null,
    dueDate = null,
    staffId = null,
    contactId = null,
    note = null
  }
}) {
  try {
    const params = { task: {} };

    if (title) params.task.title = title;
    if (description) params.task.description = description;
    if (dueDate) params.task.due_date = dueDate;
    if (contactId) params.task.contact_id = contactId;
    if (staffId) params.task.staff_id = staffId;
    if (note) params.note = note;

    const response = yield call(axiosDefault.put, `/tasks/${taskId}`, params);

    yield put({
      type: UPDATE_TASK_SUCCESS,
      payload: {
        currentTask: response.data
      }
    });
  } catch (e) {
    yield put({
      type: UPDATE_TASK_FAILURE,
      payload: {
        error: e
      },
      error: true
    });
  }
}

function* fetchTask({ payload: { taskId } }) {
  try {
    const response = yield call(axiosDefault.get, `/tasks/${taskId}`);

    yield put({
      type: FETCH_TASK_SUCCESS,
      payload: {
        currentTask: response.data
      }
    });
  } catch (e) {
    yield put({
      type: FETCH_TASK_FAILURE,
      payload: { error: e },
      error: true
    });
  }
}

export function* taskCreate() {
  yield takeLatest(CREATE_TASK_REQUEST, createTask);
}

export function* taskUpdate() {
  yield takeLatest(UPDATE_TASK_REQUEST, updateTask);
}

export function* taskFetch() {
  yield takeLatest(FETCH_TASK_REQUEST, fetchTask);
}
