import React, { useCallback, useEffect, useState, memo } from 'react';
import PropTypes from 'prop-types';
import Spinner from 'components/Theme/Spinner';

const defaultColor = 'gray';
const defaultText = `Unknown Text`;

const ButtonGroup = ({
  btnRef,
  btnProps,
  size,
  type,
  buttonStyle,
  containerStyle,
  disabledStyle,
  btnDefaultState,
  isLoaded,
  disabled
}) => {
  const [spinnerSize, setSpinnerSize] = useState('sm');

  function getButtonSizeAttributes() {
    switch (size) {
      case 'xs': {
        return ' tw-px-2.5 tw-py-1.5 tw-text-xs tw-leading-4';
      }
      case 'sm': {
        return 'tw-px-3 tw-py-2 tw-text-sm tw-leading-4';
      }
      case 'md': {
        return 'tw-px-4 tw-py-2 tw-text-sm tw-leading-5';
      }
      case 'lg': {
        return 'tw-px-4 tw-py-2 tw-text-base tw-leading-6';
      }
      case 'xl': {
        return 'tw-px-6 tw-py-3 tw-text-base tw-leading-6';
      }
      default: {
        return 'tw-px-4 tw-py-2 tw-text-sm tw-leading-5';
      }
    }
  }

  function getButtonTypeAttributes(btnColor, index) {
    switch (type) {
      case 'basic': {
        return `
        tw-relative tw-inline-flex tw-items-center tw-px-2 tw-py-2 tw-text-gray-700 tw-border-solid tw-border ${
          btnProps.length > 0 && index !== 0 ? 'tw-border-l-0' : ''
        } tw-border-gray-300 tw-bg-white tw-text-sm tw-leading-5 tw-font-medium tw-text-gray-500 hover:tw-text-gray-400 focus:tw-z-10 focus:tw-outline-none focus:tw-border-${btnColor}-300 focus:tw-text-${btnColor}-500 focus:tw-shadow-outline-${
          btnColor === 'error' ? 'red' : 'blue'
        } tw-transition tw-ease-in-out tw-duration-150
         ${disabled ? '' : `hover:tw-bg-${btnColor}-50`}`;
      }
      default: {
        return `tw-border-transparent ${
          btnColor === 'white' ? 'tw-text-gray-700' : 'tw-text-white'
        } tw-bg-${btnColor}-600 focus:tw-border-${btnColor}-500 active:tw-bg-${btnColor}-500 focus:tw-shadow-outline-${btnColor} active:tw-bg-${btnColor}-500 ${
          disabled ? '' : `hover:tw-bg-${btnColor}-500`
        }`;
      }
    }
  }

  const getSpinnerSize = useCallback(() => {
    if (size === 'xs') {
      setSpinnerSize('xs');
    } else if (size === 'xl') {
      setSpinnerSize('md');
    } else {
      setSpinnerSize('sm');
    }
  }, [size]);

  useEffect(() => {
    getSpinnerSize();
  }, [getSpinnerSize]);

  function firstElement(index) {
    return index === 0 ? 'tw-rounded-l-md' : '';
  }

  function lastElement(index, btnData) {
    return index === btnData.length - 1 ? `tw-rounded-r-md` : ``;
  }

  function getBtnState(data) {
    const { row } = data;
    let btnState;
    if (!Array.isArray(row)) {
      btnState = row.connect;
    } else {
      // assuming both row will contain same state
      btnState = row[0].connect;
    }
    return btnState;
  }

  function setActiveBtnClass(currentBtnIndex, data, btnColor) {
    const activeStyle = `tw-bg-white tw-text-${btnColor}-500`;
    let style = '';

    if (getBtnState(data)) {
      style = btnDefaultState[currentBtnIndex] ? activeStyle : style;
    } else {
      style = !btnDefaultState[currentBtnIndex] ? activeStyle : style;
    }

    return style;
  }

  function buttonList() {
    return btnProps.map((value, index) => {
      return (
        <button
          key={value.text}
          type="button"
          className={`${
            disabled ? `tw-opacity-50` : `tw-cursor-pointer`
          }  ${lastElement(index, btnProps)} ${
            btnRef && btnRef.row
              ? setActiveBtnClass(index, btnRef, value.color || defaultColor)
              : null
          } ${firstElement(
            index
          )} ${getButtonSizeAttributes()} ${getButtonTypeAttributes(
            value.color || defaultColor,
            index
          )}`}
          disabled={disabled}
          onClick={
            value.action
              ? () => value.action(btnRef, btnDefaultState[index])
              : null
          }
          style={disabled ? { ...disabledStyle, ...buttonStyle } : buttonStyle}
        >
          {isLoaded ? (
            value.text || defaultText
          ) : (
            <div className="tw-px-3">
              <Spinner size={spinnerSize} color="gray" tint={400} />
            </div>
          )}
        </button>
      );
    });
  }

  return (
    <span
      style={containerStyle}
      className="tw-relative tw-z-0 tw-inline-flex tw-shadow-sm tw-rounded-md"
    >
      {buttonList()}
    </span>
  );
};

ButtonGroup.defaultProps = {
  btnRef: {},
  btnProps: [
    { color: 'alpha', text: 'Unknown Text1', action: () => null },
    { color: 'alpha', text: 'Unknown Text2', action: () => null },
    { color: 'alpha', text: 'Unknown Text3', action: () => null }
  ],
  size: 'lg',
  type: 'basic',
  buttonStyle: {},
  containerStyle: {},
  disabledStyle: {},
  btnDefaultState: { '0': true, '1': false },
  isLoaded: true,
  disabled: false
};

const styleProps = PropTypes.objectOf(PropTypes.string);

const btnPropObj = PropTypes.shape({
  text: PropTypes.string,
  color: PropTypes.string,
  action: PropTypes.func
});

const btnRefObj = PropTypes.shape({
  index: PropTypes.number,
  row: PropTypes.oneOfType([PropTypes.array, PropTypes.instanceOf(Object)])
});

ButtonGroup.propTypes = {
  btnRef: btnRefObj,
  btnProps: PropTypes.arrayOf(btnPropObj),
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  type: PropTypes.oneOf(['basic']),
  buttonStyle: styleProps,
  containerStyle: styleProps,
  disabledStyle: styleProps,
  btnDefaultState: PropTypes.objectOf(PropTypes.bool),
  isLoaded: PropTypes.bool,
  disabled: PropTypes.bool
};

export default memo(ButtonGroup);
