import React from 'react';
import PropTypes from 'prop-types';
import HeroIcon from 'components/Theme/HeroIcon';

const InfoPanel = ({
  title,
  color,
  showSvg,
  infoItems,
  orderedList,
  withColon,
  withHtmlInfoItems
}) => {
  return (
    <div className={`tw-rounded-md tw-bg-${color}-100 tw-p-4`}>
      <div className="tw-flex">
        <div className="tw-flex-shrink-0">
          {showSvg && <HeroIcon icon="information-circle" color={color} />}
        </div>
        <div className="tw-ml-3">
          <h3
            className={`tw-text-sm tw-leading-5 tw-font-medium tw-text-${color}-800`}
          >
            {title}
            {withColon && ':'}
          </h3>
          <div
            className={`tw-mt-2 tw-text-sm tw-leading-5 tw-text-${color}-700`}
          >
            {React.createElement(
              orderedList ? 'ol' : 'ul',
              {
                className: `${
                  orderedList ? 'tw-list-decimal' : 'tw-list-disc'
                } tw-pl-5`
              },
              infoItems.map((item, index) => {
                return withHtmlInfoItems ? (
                  <li
                    key={`li-${item}`}
                    className={`${index === 0 ? '' : 'tw-mt-1'}`}
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: item }}
                  />
                ) : (
                  <li
                    key={`li-${item}`}
                    className={`${index === 0 ? '' : 'tw-mt-1'}`}
                  >
                    {item}
                  </li>
                );
              })
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

InfoPanel.defaultProps = {
  title: 'Info Title',
  color: 'alpha',
  showSvg: true,
  infoItems: [],
  orderedList: false,
  withColon: true,
  withHtmlInfoItems: false
};

InfoPanel.propTypes = {
  title: PropTypes.string,
  color: PropTypes.oneOf([
    'alpha',
    'bravo',
    'charlie',
    'success',
    'warning',
    'error',
    'gray'
  ]),
  showSvg: PropTypes.bool,
  infoItems: PropTypes.arrayOf(PropTypes.string),
  orderedList: PropTypes.bool,
  withColon: PropTypes.bool,
  withHtmlInfoItems: PropTypes.bool
};

export default InfoPanel;
