import React from 'react';
import { withA11y } from '@storybook/addon-a11y';
import { withKnobs, select, object, boolean } from '@storybook/addon-knobs';
import ProgressBar, { BULLET_TYPES } from './index';

export default {
  component: ProgressBar,
  title: 'Progress Bar',
  decorators: [withA11y, withKnobs]
};

let stepSelect;
let fillerColorSelect;
let bulletStyleSelect;
const stepsArr = [
  {
    text: 'EXACT MATCHES'
  },
  {
    text: 'MATCH CONTACTS'
  },
  {
    text: 'MATCH CAMPAIGNS'
  },
  {
    text: 'ADD CONTACTS'
  },
  {
    text: 'REVIEW & SYNC'
  }
];

function withFillerColorSelect(defaultValue) {
  const label = 'Filler Color';
  const options = [
    'alpha',
    'bravo',
    'charlie',
    'success',
    'warning',
    'error',
    'gray',
    'black'
  ];
  fillerColorSelect = select(label, options, defaultValue);
}

function withStepCompletionSelect(defaultValue) {
  const label = 'Complete Step';
  const options = [1, 2, 3, 4, 5];
  stepSelect = select(label, options, defaultValue);
}

function withBulletStyleSelect(defaultValue) {
  const label = 'Bullet Style';
  const options = Object.values(BULLET_TYPES);
  bulletStyleSelect = select(label, options, defaultValue);
}

export const NoProgress = () => {
  return (
    <ProgressBar
      completed="0"
      steps={object('Steps', stepsArr)}
      showSteps={boolean('Show Steps', true)}
      compressed={boolean('Compressed', false)}
      showStepNumber={boolean('Show Step Number', true)}
    />
  );
};

export const SomeProgress = () => {
  withStepCompletionSelect(2);
  withFillerColorSelect('alpha');
  withBulletStyleSelect('solid');
  return (
    <ProgressBar
      completed={stepSelect}
      steps={object('Steps', stepsArr)}
      showSteps={boolean('Show Steps', true)}
      compressed={boolean('Compressed', false)}
      bulletStyle={bulletStyleSelect}
      showStepNumber={boolean('Show Step Number', true)}
      fillerColor={fillerColorSelect}
    />
  );
};

export const StyledProgressBar = () => {
  withStepCompletionSelect(2);
  withFillerColorSelect('alpha');
  return (
    <ProgressBar
      completed={stepSelect}
      showSteps={false}
      steps={object('Steps', stepsArr)}
      styled={boolean('Styled', true)}
      showSteps={boolean('Show Steps', true)}
      showStepNumber={boolean('Show Step Number', true)}
      fillerColor={fillerColorSelect}
    />
  );
};
