/* eslint-disable no-param-reassign */
import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { broadcastTemplatesCreateBroadcast } from 'appState/actions/ActionCreators';
import { CurrentUserContext } from 'components/shared/context/CurrentUserContext';
import defaultTemplatePreview from 'images/broadcast/default-template-image.png';
import Styled from './DefaultTemplateCard.styles';

const DefaultTemplateCard = props => {
  const { title, description, templateType } = props;
  const currentUser = useContext(CurrentUserContext);
  const dispatch = useDispatch();
  const history = useHistory();

  function selectTemplate() {
    dispatch(
      broadcastTemplatesCreateBroadcast({
        templateType,
        currentUserId: currentUser.id,
        history
      })
    );
  }

  return (
    <div className="col-md-12 col-lg-6 col-xxxl-4">
      {/* TO-DO: redirect to /sms if SMSBroadcast type */}
      <Styled.Card className="card">
        <header className="card-header">
          <h4 className="card-title mb-0">
            <strong>{title}</strong>
          </h4>
          {/* <small className="sidetitle ml-0">{template.description}</small> */}
        </header>
        <div className="card-content">
          <div className="card-body" style={{ overflowY: 'hidden' }}>
            <div className="d-flex">
              <div className="col-sm-7" style={{ height: '266px' }}>
                <Styled.Phone>
                  <Styled.Screen>
                    <img
                      src={defaultTemplatePreview}
                      alt="Broadcast Template Preview"
                      width="200"
                    />
                  </Styled.Screen>
                  <Styled.Speaker />
                  <Styled.Home />
                </Styled.Phone>
              </div>
              <div className="col-sm-5 d-none d-sm-block">
                {description && description.length && (
                  <div>
                    <h6>Description</h6>
                    <p>{description}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <footer
            className="card-footer text-right"
            style={{ borderRadius: '0 0 10px 10px' }}
          >
            <a
              className="btn btn-flat btn-info new-broadcast-button"
              href="#"
              onClick={selectTemplate}
            >
              Use Template
            </a>
          </footer>
        </div>
      </Styled.Card>
    </div>
  );
};

DefaultTemplateCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  templateType: PropTypes.string.isRequired
};

export default DefaultTemplateCard;
