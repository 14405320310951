import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  createErrorMessageSelector,
  createLoadedSelector
} from 'appState/selectors';
import {
  setCurrentAppointmentTypeId,
  fetchAppointmentTypes
} from 'appState/actions/ActionCreators';
import isEmpty from 'lodash.isempty';
import Skeleton from 'react-loading-skeleton';
import AppointmentBooker from 'components/AppointmentBooker';
import Select from 'components/Theme/Select';

const AppointmentBookerTab = () => {
  const dispatch = useDispatch();

  const loadedSelector = createLoadedSelector([
    'appointment_booker/CREATE_APPOINTMENT_FROM_BOOKER'
  ]);

  const errorSelector = createErrorMessageSelector([
    'appointment_booker/UPDATE_APPOINTMENT'
  ]);

  const structuredSelector = createStructuredSelector({
    appointmentTypes: state => state.contact.appointmentTypes,
    currentAppointmentTypeId: state => state.contact.currentAppointmentTypeId,
    currentContact: state => state.contact.currentContact,
    isLoaded: state => loadedSelector(state),
    error: state => errorSelector(state),
    currentAppointment: state => state.appointmentBooker.currentAppointment
  });

  const {
    appointmentTypes,
    currentAppointmentTypeId,
    currentContact,
    currentAppointment,
    error,
    isLoaded
  } = useSelector(structuredSelector);

  const appointmentTypeOptions = appointmentTypes.map(apptType => {
    return { displayName: apptType.name, value: apptType.id };
  });

  function handleChangeAppointmentTypeSelect(apptTypeId) {
    dispatch(setCurrentAppointmentTypeId({ id: apptTypeId }));
  }

  useEffect(() => {
    if (isLoaded && isEmpty(error))
      dispatch(
        setCurrentAppointmentTypeId({
          id: ''
        })
      );
  }, [dispatch, isLoaded]);

  useEffect(() => {
    if (isEmpty(appointmentTypes)) dispatch(fetchAppointmentTypes());
  }, [dispatch, appointmentTypes]);

  return (
    <div className="">
      {!isEmpty(appointmentTypes) || !isEmpty(currentAppointment) ? (
        <div className="">
          <div className="tw-hidden sm:tw-block">
            <div className="tw-py-5">
              <div className="tw-border-t tw-border-b-0 tw-border-l-0 tw-border-r-0 tw-border-solid tw-border-gray-200" />
            </div>
          </div>
          {isEmpty(currentAppointment) && !isEmpty(appointmentTypes) ? (
            <Select
              value={currentAppointmentTypeId}
              id="appointmentTypes"
              labelText="Choose Appointment Type"
              options={appointmentTypeOptions}
              onChange={e => handleChangeAppointmentTypeSelect(e.target.value)}
            />
          ) : null}
          {(!isEmpty(currentAppointment) ||
            // eslint-disable-next-line prettier/prettier
            !isEmpty(currentAppointmentTypeId)) &&
          !isEmpty(currentContact) ? (
            <div className="tw-mt-10">
              <AppointmentBooker
                contactTokenProp={currentContact.token}
                appointmentTypeIdProp={parseInt(
                  !isEmpty(currentAppointment)
                    ? currentAppointment.appointment_type_id
                    : currentAppointmentTypeId,
                  10
                )}
                clientFacing={false}
              />
            </div>
          ) : null}
        </div>
      ) : (
        <Skeleton height={30} />
      )}
    </div>
  );
};

export default AppointmentBookerTab;
