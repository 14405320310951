import React, { useEffect, useState, Fragment, useContext } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';
import statusImage from 'images/uplaunch-email-status-mascot.svg';
import rocketCrash from 'images/uplaunch-failed-status-mascot.svg';
import Badge from 'components/Theme/Badge';
import Button from 'components/Theme/Button';
import Link from 'components/Theme/Link';
import Notification from 'components/Theme/Notification';
import Tooltip from 'components/Theme/Tooltip';
import ButtonWithDropdown from 'components/Theme/ButtonWithDropdown';
import { formatTimestamp } from 'lib/utils/dateTime';
import { useHistory } from 'react-router-dom';
import { fetchAppointment } from 'appState/actions/ActionCreators';
import { CurrentAccountContext } from 'components/shared/context/CurrentAccountContext';
import EmailPreviewModal from '../EmailPreviewModal';
import FormPreviewModal from '../FormPreviewModal';
import FormDeleteModal from '../FormDeleteModal';
import TaskEditModal from '../TaskEditModal';
import TaskCompleteModal from '../TaskCompleteModal';
import TaskCancelModal from '../TaskCancelModal';
import AppointmentRescheduleModal from '../AppointmentRescheduleModal';
import AppointmentNoShowModal from '../AppointmentNoShowModal';
import AppointmentCancelModal from '../AppointmentCancelModal';

const PinIcon = ({ isPinned, actionColor, handlePinnedAction }) => {
  return (
    <Fragment>
      {isPinned ? (
        <Fragment>
          <svg
            className={`tw-h-4 tw-w-4 tw-text-${actionColor}-base tw-ml-2 tw-cursor-pointer tw-absolute tw-bottom-1`}
            onClick={handlePinnedAction}
            fill="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            stroke="currentColor"
            viewBox="0 0 24 24"
            data-testid="pinned-test"
          >
            <path d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z" />
          </svg>
        </Fragment>
      ) : (
        <Fragment>
          <svg
            className={`tw-h-4 tw-w-4 tw-text-${actionColor}-base tw-ml-2 tw-cursor-pointer tw-absolute tw-bottom-1`}
            onClick={handlePinnedAction}
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            stroke="currentColor"
            data-testid="pinned-test"
          >
            <path d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z" />
          </svg>
        </Fragment>
      )}
    </Fragment>
  );
};

const childrenDefaultProps = { children: null };
const childrenPropTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func])
};

const ShowDetailsHeader = ({ children }) => {
  return (
    <div className="tw-font-body tw-text-sm tw-leading-6 tw-font-medium tw-text-gray-900">
      {children}
    </div>
  );
};

ShowDetailsHeader.defaultProps = childrenDefaultProps;
ShowDetailsHeader.propTypes = childrenPropTypes;

const ShowDetailsBody = ({ children }) => {
  return children;
};

ShowDetailsBody.defaultProps = childrenDefaultProps;
ShowDetailsBody.propTypes = childrenPropTypes;

const ShowDetails = ({ children }) => {
  return (
    <div className="tw-px-5 tw-py-5 tw--mx-5 tw--my-5 tw-bg-gray-50 tw-space-y-6 tw-mt-2">
      {children}
    </div>
  );
};

ShowDetails.defaultProps = childrenDefaultProps;
ShowDetails.propTypes = childrenPropTypes;
const ERROR_STATUS = ['MESSAGE FAILED', 'MESSAGE UNDELIVERED'];
const Item = ({
  item,
  isLastItem,
  itemColor,
  actionColor,
  secondaryActionColor,
  secondaryActionType,
  actionType,
  actionSize,
  showIcons,
  tabName,
  showMoreIndex,
  showMoreOpened
}) => {
  const [isPinned, setIsPinned] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showEmailPreview, setShowEmailPreview] = useState(false);
  const [showFormPreview, setShowFormPreview] = useState(false);
  const [showEditTaskModal, setShowEditTaskModal] = useState(false);
  const [showCompleteTaskModal, setShowCompleteTaskModal] = useState(false);
  const [showCancelTaskModal, setshowCancelTaskModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [
    showRescheduleAppointmentModal,
    setShowRescheduleAppointmentModal
  ] = useState(false);
  const [showAppointmentNoShowModal, setShowAppointmentNoShowModal] = useState(
    false
  );
  const [showAppointmentCancelModal, setShowAppointmentCancelModal] = useState(
    false
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const currentAccount = useContext(CurrentAccountContext);

  const itemIdString =
    typeof item.id !== 'string' ? item.id.toString() : item.id;

  useEffect(() => {
    setIsPinned(item.isPinned);
  }, [item.isPinned]);

  function itemIcon(_color) {
    return typeof item.icon === 'function' ? (
      <Fragment>{item.icon(_color)}</Fragment>
    ) : (
      <Fragment>
        <item.icon color={_color} />
      </Fragment>
    );
  }

  function handlePinnedAction() {
    setIsPinned(!isPinned);
    if (typeof item.pinnedAction === 'function') item.pinnedAction(tabName);
  }

  function handleActionClick() {
    if (item.actionText) {
      switch (item.actionText.toLowerCase()) {
        case 'read email': {
          return setShowEmailPreview(true);
        }
        case 'view form': {
          return setShowFormPreview(true);
        }
        case 'delete': {
          return setShowFormPreview(true);
        }
        default: {
          return null;
        }
      }
    }
    if (item.action) item.action();
    return null;
  }

  function handleSecondaryActionClick() {
    if (item.secondaryActionText) {
      switch (item.secondaryActionText.toLowerCase()) {
        case 'delete form': {
          return setShowDeleteModal(true);
        }
        default: {
          return null;
        }
      }
    }
    if (item.secondaryAction) item.secondaryAction();
    return null;
  }

  function handleButtonGroupSelect(val) {
    switch (val) {
      case 'completeTask': {
        return setShowCompleteTaskModal(true);
      }
      case 'editTask': {
        return setShowEditTaskModal(true);
      }
      case 'cancelTask': {
        return setshowCancelTaskModal(true);
      }
      case 'reschedule': {
        history.push(`/ui/contacts/${item?.contact_id}#appointments`);
        dispatch(
          fetchAppointment({
            appointmentId: item.sourceable_id,
            accountId: item.account_id
          })
        );
        return;
      }
      case 'noShow': {
        return setShowAppointmentNoShowModal(true);
      }
      case 'cancel': {
        return setShowAppointmentCancelModal(true);
      }
      default: {
        return null;
      }
    }
  }

  function isTaskCompletedOrCanceled(_item) {
    if (!_item.sourceable) return false;
    const itemSourceable = _item.sourceable?.[_item.sourceable?.type];
    return itemSourceable?.completed_at || itemSourceable.canceled_at;
  }

  function isAppointmentCanceledOrNoShow(_item) {
    if (!_item.sourceable) return false;
    const itemSourceable = _item.sourceable?.[_item.sourceable?.type];
    return itemSourceable?.canceled_at || itemSourceable?.noshowed_at;
  }

  function displayDropdown(_item) {
    switch (_item.sourceable_type) {
      case 'Task':
        return !isTaskCompletedOrCanceled(_item);
      case 'Appointment':
        return !isAppointmentCanceledOrNoShow(_item);
      default:
        return !!_item.actions;
    }
  }

  function getBadgeProperty(status) {
    if (typeof status === 'string') {
      return ERROR_STATUS.includes(status.toUpperCase())
        ? { icon: 'exclamation-circle', color: 'error' }
        : { icon: 'check-circle', color: 'success' };
    }

    return { icon: 'check-circle', color: 'alpha' };
  }

  let showTimelineConnector = false;
  const showMoreEnabled = !!showMoreIndex;
  const isLastShowMoreItem = item.index === showMoreIndex;

  // ShowMore is enabled
  if (showMoreEnabled) {
    // ShowMore is open & is not the last activity feed item
    if (showMoreOpened && !isLastItem) showTimelineConnector = true;

    // ShowMore is closed & is not the last ShowMore activity feed item
    if (!showMoreOpened && !isLastShowMoreItem && !isLastItem)
      showTimelineConnector = true;
  }

  // ShowMore is disabled & is not the last activity feed item
  if (!showMoreEnabled && !isLastItem) showTimelineConnector = true;

  let HeaderText;
  if (typeof item.headerText === 'function') {
    HeaderText = item.headerText;
  }

  return (
    <div className="tw-flex">
      <div className="tw-flex-initial tw-flex tw-flex-col">
        {showIcons ? (
          <div
            className={`
        tw-bg-${itemColor}-200
        tw-rounded-full
        tw-min-h-10
        tw-h-10
        tw-w-10
        tw-flex
        tw-items-center
        tw-justify-center
      `}
          >
            {itemIcon(itemColor)}
          </div>
        ) : (
          <div
            className={`
        tw-bg-${itemColor}-200
        tw-rounded-full
        tw-min-h-4
        tw-h-4
        tw-w-4
        tw-px-2
      `}
          />
        )}
        {showTimelineConnector ? (
          <div className="tw-align-middle tw-min-h-full tw-flex tw-flex-col">
            <div
              className={`
          tw-flex-1
          tw-bg-${itemColor}-200
          tw-w-1
          tw-mx-auto
        `}
            />
          </div>
        ) : null}
      </div>
      <div className="tw-flex-1 tw-pl-5 tw-flex tw-items-start tw-justify-between">
        <div className="tw-flex-1">
          {!isEmpty(item.statusObject) ? (
            <Tooltip
              id={itemIdString}
              place="right"
              afterHide={() => setShowDetails(false)}
              TooltipElement={() => {
                const { icon, color } = getBadgeProperty(
                  item.statusObject.status
                );
                return (
                  <div
                    className="tw-inline tw-cursor-pointer"
                    data-tip
                    data-for={itemIdString}
                    data-border
                    data-border-color="#d2d6dc"
                    data-background-color="#fff"
                    data-event="click focus"
                  >
                    <Badge
                      value={`Status: ${item.statusObject.status}`}
                      color={color}
                      icon={icon}
                      tint={800}
                      badgeStyle={{ marginTop: '0.5rem' }}
                    />
                  </div>
                );
              }}
            >
              <div className="tw-grid tw-grid-cols-12">
                <div className="tw-col-span-6">
                  <div className="tw-font-body tw-text-lg tw-leading-7 tw-font-medium tw-text-gray-900">
                    {item.statusObject.title}
                  </div>
                  <p className="tw-font-body tw-mb-0 tw-mt-1 tw-text-sm tw-leading-5 tw-text-gray-500">
                    {item.statusObject.friendly_message}
                  </p>
                </div>
                <img
                  src={
                    !isEmpty(item.statusObject.reason) ||
                    !isEmpty(item.statusObject.failed_code)
                      ? rocketCrash
                      : statusImage
                  }
                  className="tw-col-span-6"
                  alt="UpLaunch Communication Status"
                />
              </div>
              {item.statusObject &&
                (!isEmpty(item.statusObject.reason) ||
                  !isEmpty(item.statusObject.failed_code)) && (
                  <div className="tw-mt-5">
                    <Link
                      text={
                        showDetails
                          ? 'Hide Technical Details'
                          : 'Show Technical Details'
                      }
                      onClick={() => setShowDetails(!showDetails)}
                      color="alpha"
                    />
                  </div>
                )}
              {showDetails ? (
                <Fragment>
                  {!isEmpty(item.email_subject) ? (
                    <ShowDetails>
                      <ShowDetailsHeader>
                        {item.statusObject.bounce_code} -{' '}
                        {item.statusObject.bounce_class}
                      </ShowDetailsHeader>
                      <ShowDetailsBody>
                        <p className="tw-font-body tw-mb-0 tw-mt-1 tw-text-sm tw-leading-5 tw-text-gray-500">
                          {item.statusObject.bounce_description}
                        </p>
                        <p className="tw-font-body tw-mb-0 tw-mt-1 tw-text-sm tw-leading-5 tw-text-gray-500">
                          {item.statusObject.reason}
                        </p>
                      </ShowDetailsBody>
                    </ShowDetails>
                  ) : (
                    <ShowDetails>
                      <ShowDetailsHeader>
                        {item.statusObject.failed_code} -{' '}
                        {item.statusObject.failed_message} -{' '}
                        {item.statusObject.failed_link}
                      </ShowDetailsHeader>
                    </ShowDetails>
                  )}
                </Fragment>
              ) : null}
            </Tooltip>
          ) : null}
          <div
            className="tw-flex tw-items-start"
            style={showIcons ? { marginTop: '10px' } : {}}
          >
            <div className="tw-font-body tw-text-base tw-font-medium tw-pb-2">
              <div className="tw-inline-flex">
                <p className="tw-mb-0 tw-inline tw-relative">
                  {HeaderText ? <HeaderText /> : item.headerText}
                  <PinIcon
                    isPinned={isPinned}
                    actionColor={actionColor}
                    handlePinnedAction={handlePinnedAction}
                  />
                </p>
              </div>
            </div>
          </div>
          <div className="tw-font-body tw-leading-5 tw-text-gray-500 tw-text-sm">
            {typeof item.bodyText === 'string' ? (
              item.bodyText
            ) : (
              <item.bodyText timezone={currentAccount.time_zone} />
            )}
          </div>
          {item.staffNote ? (
            <Notification
              message={item.staffNote.message}
              show
              showAlways
              type={item.staffNote.type}
              headerText={item.staffNote.headerText}
              color={item.staffNote.color}
              HeaderIcon={item.staffNote.HeaderIcon}
              containerStyle={
                item.infoAlert || item.actionText ? { paddingBottom: 0 } : {}
              }
              customizePadding="tw-p-1 tw-leading-none"
            />
          ) : null}
          {item.infoAlert ? (
            <Notification
              message={item.infoAlert.message}
              show
              showAlways
              type={item.infoAlert.type}
              headerText={item.infoAlert.headerText}
              color={item.infoAlert.color}
              HeaderIcon={item.infoAlert.HeaderIcon}
              containerStyle={item.actionText ? { paddingBottom: 0 } : {}}
              customizePadding="tw-p-1 tw-leading-none"
            />
          ) : null}
          {item.actionText ? (
            <div className="tw-pt-1 tw-mb-2">
              <Button
                testId={item.actionText}
                text={item.actionText}
                type={actionType}
                size={actionSize}
                color={actionColor}
                onClick={() => handleActionClick()}
              />
            </div>
          ) : null}
          {item.secondaryActionText && (
            <div className="tw-pt-1 tw-mb-2">
              <Button
                testId={item.secondaryActionText}
                text={item.secondaryActionText}
                type={secondaryActionType}
                size={actionSize}
                color={secondaryActionColor}
                containerClass="tw-ml-4"
                onClick={() => handleSecondaryActionClick()}
              />
            </div>
          )}
          {displayDropdown(item) ? (
            <div className="tw-pt-1 tw-mb-2">
              <ButtonWithDropdown
                value="activityActions"
                options={item.actions}
                buttonText="Available Actions"
                type="white"
                containerStyle={{ marginTop: '-0.75rem' }}
                showSvg
                onClick={handleButtonGroupSelect}
                leftBtnDropdownToggle
              />
            </div>
          ) : null}
        </div>

        <div className="tw-mt-5 sm:tw-mt-0 sm:tw-ml-6 sm:tw-flex-shrink-0 sm:tw-flex sm:tw-items-center">
          <p
            className="tw-font-body tw-text-xs tw-text-gray-400 tw-leading-snug tw-mb-0"
            style={showIcons ? { marginTop: '10px' } : {}}
          >
            {formatTimestamp(
              item.timestamp,
              currentAccount.time_zone,
              false,
              'MMMM Do YYYY [at] h:mm A',
              true
            )}
          </p>
        </div>
      </div>
      {!isEmpty(item.email_subject) && (
        <EmailPreviewModal
          activeItem={item}
          show={showEmailPreview}
          setShow={setShowEmailPreview}
        />
      )}
      <div>
        <FormPreviewModal
          formId={item.form_id}
          show={showFormPreview}
          setShow={setShowFormPreview}
        />
      </div>
      {!isEmpty(item.secondaryActionText) && (
        <FormDeleteModal
          formId={item.form_id}
          show={showDeleteModal}
          setShow={setShowDeleteModal}
        />
      )}
      {!isEmpty(item.task_title) && !isTaskCompletedOrCanceled(item) && (
        <div>
          <TaskEditModal
            activeItem={item}
            show={showEditTaskModal}
            setShow={setShowEditTaskModal}
          />
          <TaskCompleteModal
            task={item}
            show={showCompleteTaskModal}
            setShow={setShowCompleteTaskModal}
          />
          <TaskCancelModal
            task={item}
            show={showCancelTaskModal}
            setShow={setshowCancelTaskModal}
          />
        </div>
      )}
      {!isEmpty(item.appointment_type) && !isAppointmentCanceledOrNoShow(item) && (
        <div>
          <AppointmentRescheduleModal
            appointment={item}
            show={showRescheduleAppointmentModal}
            setShow={setShowRescheduleAppointmentModal}
          />
          <AppointmentNoShowModal
            appointment={item}
            show={showAppointmentNoShowModal}
            setShow={setShowAppointmentNoShowModal}
          />
          <AppointmentCancelModal
            appointment={item}
            show={showAppointmentCancelModal}
            setShow={setShowAppointmentCancelModal}
          />
        </div>
      )}
    </div>
  );
};

const colorPropType = PropTypes.oneOf([
  'alpha',
  'bravo',
  'charlie',
  'success',
  'warning',
  'error',
  'gray'
]);

const styleProps = PropTypes.objectOf(
  PropTypes.oneOfType([PropTypes.string, PropTypes.number])
);

const infoAlertShape = {
  message: PropTypes.string,
  type: PropTypes.oneOf(['standard', 'colored']),
  headerText: PropTypes.string,
  HeaderIcon: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  color: colorPropType,
  containerStyle: styleProps
};

const statusObjectShape = {
  status: PropTypes.string,
  title: PropTypes.string,
  friendly_message: PropTypes.string,
  bounce_code: PropTypes.string,
  bounce_class: PropTypes.string,
  bounce_description: PropTypes.string,
  reason: PropTypes.string,
  failed_code: PropTypes.string,
  failed_link: PropTypes.string,
  failed_message: PropTypes.string
};

const actionShape = {
  displayName: PropTypes.string,
  value: PropTypes.string
};

const itemShape = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  sourceable_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  index: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  headerText: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.string
  ]),
  bodyText: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.string
  ]),
  action: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  actionText: PropTypes.string,
  actions: PropTypes.arrayOf(PropTypes.shape(actionShape)),
  timestamp: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  isPinned: PropTypes.bool.isRequired,
  pinnedAction: PropTypes.func,
  infoAlert: PropTypes.shape(infoAlertShape),
  statusObject: PropTypes.shape(statusObjectShape),
  email_subject: PropTypes.string,
  email_body: PropTypes.string
});

PinIcon.defaultProps = {
  isPinned: false,
  actionColor: 'alpha',
  handlePinnedAction: null
};

PinIcon.propTypes = {
  isPinned: PropTypes.bool,
  actionColor: colorPropType,
  handlePinnedAction: PropTypes.func
};

Item.defaultProps = {
  itemColor: 'gray',
  actionColor: 'alpha',
  secondaryActionColor: 'error',
  secondaryActionType: 'secondary',
  actionType: 'white',
  actionSize: 'sm',
  showIcons: false,
  tabName: 'feed',
  showMoreIndex: 0,
  showMoreOpened: false
};

Item.propTypes = {
  item: itemShape.isRequired,
  isLastItem: PropTypes.bool.isRequired,
  itemColor: colorPropType,
  actionColor: colorPropType,
  secondaryActionColor: colorPropType,
  secondaryActionType: PropTypes.string,
  actionType: PropTypes.string,
  actionSize: PropTypes.string,
  showIcons: PropTypes.bool,
  tabName: PropTypes.string,
  showMoreIndex: PropTypes.number,
  showMoreOpened: PropTypes.bool
};

export default Item;
