import React, { useContext, useState } from 'react';
import Select from 'components/Theme/Select';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';
import { CurrentAccountContext } from 'components/shared/context/CurrentAccountContext';

const PLACE_HOLDER = 'None Available';

const DefaultProducts = ({
  clientList,
  newLeadList,
  handleLeadProduct,
  handleClientProduct,
  defaultClientProduct,
  defaultLeadProduct
}) => {
  const [selectedLead, setSelectedLead] = useState(defaultLeadProduct);
  const [selectedClient, setSelectedClient] = useState(defaultClientProduct);
  const currentAccount = useContext(CurrentAccountContext);

  const handleLeadSelect = e => {
    setSelectedLead(e.target.value);
    handleLeadProduct(e.target.value);
  };
  const handleClientSelect = e => {
    setSelectedClient(e.target.value);
    handleClientProduct(e.target.value);
  };

  return (
    <div className="tw-pb-2">
      <p>
        If you manually create a contact, they will not be added automatically
        to your default product. However, if a contact is added by an
        integration or external automation this will automatically be added to
        this product. <br />
        <b>
          Note: Only products that have gone through set up will display below.
        </b>
      </p>
      <div>
        <Select
          value={selectedLead}
          id="newLeads"
          labelText="New Leads"
          placeholder={isEmpty(newLeadList) ? PLACE_HOLDER : 'Select Lead'}
          options={newLeadList}
          onChange={handleLeadSelect}
        />
      </div>
      {!currentAccount.mod_campaigns_basic && (
        <div className="tw-pt-5">
          <Select
            value={selectedClient}
            id="clientFormerClient"
            placeholder={isEmpty(clientList) ? PLACE_HOLDER : 'Select Client'}
            labelText="Clients"
            options={clientList}
            onChange={handleClientSelect}
          />
        </div>
      )}
    </div>
  );
};

DefaultProducts.defaultProps = {
  clientList: [],
  newLeadList: [],
  handleLeadProduct: () => null,
  handleClientProduct: () => null,
  defaultClientProduct: null,
  defaultLeadProduct: null
};

DefaultProducts.propTypes = {
  clientList: PropTypes.arrayOf(PropTypes.object),
  newLeadList: PropTypes.arrayOf(PropTypes.object),
  handleLeadProduct: PropTypes.func,
  handleClientProduct: PropTypes.func,
  defaultClientProduct: PropTypes.number,
  defaultLeadProduct: PropTypes.number
};

export default DefaultProducts;
