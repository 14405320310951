import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from '../../Button';

const Footer = ({
  footer,
  showPrimaryAction,
  showSecondaryAction,
  color,
  handlePrimaryActionClick,
  disablePrimaryAction,
  primaryActionText,
  showTertiaryAction,
  tertiaryActionText,
  tertiaryAction,
  disableTertiaryAction,
  hasDetachedActions,
  disableSecondaryAction,
  secondaryAction,
  secondaryActionText
}) => {
  function handleAdditionalActionClick() {
    tertiaryAction();
  }

  function handleSecondaryActionClick() {
    if (secondaryAction) secondaryAction(false);
  }

  return (
    <Fragment>
      {footer}
      {showPrimaryAction || showSecondaryAction ? (
        <div className="tw-bg-gray-50 tw-px-4 tw-py-3 sm:tw-px-6 sm:tw-flex sm:tw-flex-row-reverse">
          {showPrimaryAction ? (
            <div className="tw-inline-block">
              <span className="tw-flex tw-w-full sm:tw-ml-3 sm:tw-w-auto">
                <button
                  type="button"
                  className={`tw-font-body tw-cursor-pointer tw-inline-flex tw-justify-center tw-w-auto tw-rounded-md tw-border tw-border-transparent tw-px-4 tw-py-2 tw-bg-${color}-600 tw-text-base tw-leading-6 tw-font-medium tw-text-white tw-shadow-sm hover:tw-bg-${color}-500 focus:tw-outline-none focus:tw-border-${color}-700 focus:tw-shadow-outline-${color} tw-transition tw-ease-in-out tw-duration-150 sm:tw-text-sm sm:tw-leading-5 tw-whitespace-no-wrap ${
                    disablePrimaryAction ? 'tw-opacity-50' : 'tw-cursor-pointer'
                  }`}
                  onClick={handlePrimaryActionClick}
                  disabled={disablePrimaryAction}
                >
                  {primaryActionText}
                </button>
              </span>
            </div>
          ) : null}
          {showTertiaryAction && (
            <div className="tw-inline-block">
              <span className="tw-flex tw-w-full sm:tw-ml-3 sm:tw-w-auto">
                <Button
                  text={tertiaryActionText}
                  onClick={handleAdditionalActionClick}
                  disabled={disableTertiaryAction}
                  type="secondary"
                  buttonClass="tw-w-32"
                />
              </span>
            </div>
          )}
          {showSecondaryAction ? (
            <div
              className={`tw-inline-block ${
                hasDetachedActions ? 'tw-w-11/12' : ''
              }`}
            >
              <span className="tw-mt-3 tw-flex tw-w-full sm:tw-mt-0 sm:tw-w-auto">
                <button
                  type="button"
                  className={`tw-font-body tw-cursor-pointer tw-inline-flex tw-justify-center tw-w-auto tw-rounded-md tw-border tw-border-solid tw-border-gray-300 tw-px-4 tw-py-2 tw-bg-white tw-text-base tw-leading-6 tw-font-medium tw-text-gray-700 tw-shadow-sm hover:tw-text-gray-500 focus:tw-outline-none focus:tw-border-blue-300 focus:tw-shadow-outline-blue tw-transition tw-ease-in-out tw-duration-150 sm:tw-text-sm sm:tw-leading-5 ${
                    disableSecondaryAction
                      ? 'tw-opacity-50'
                      : 'tw-cursor-pointer'
                  }`}
                  onClick={handleSecondaryActionClick}
                  disabled={disableSecondaryAction}
                  data-testid={secondaryActionText}
                >
                  {secondaryActionText}
                </button>
              </span>
            </div>
          ) : null}
        </div>
      ) : null}
    </Fragment>
  );
};

Footer.defaultProps = {
  footer: null,
  showPrimaryAction: false,
  showSecondaryAction: false,
  color: '',
  handlePrimaryActionClick: () => {},
  disablePrimaryAction: false,
  primaryActionText: '',
  showTertiaryAction: false,
  tertiaryActionText: '',
  tertiaryAction: () => {},
  disableTertiaryAction: false,
  hasDetachedActions: false,
  disableSecondaryAction: false,
  secondaryAction: () => {},
  secondaryActionText: ''
};

Footer.propTypes = {
  footer: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  showPrimaryAction: PropTypes.bool,
  showSecondaryAction: PropTypes.bool,
  color: PropTypes.oneOf([
    'alpha',
    'bravo',
    'charlie',
    'success',
    'warning',
    'error',
    'gray'
  ]),
  handlePrimaryActionClick: PropTypes.func,
  disablePrimaryAction: PropTypes.bool,
  primaryActionText: PropTypes.string,
  showTertiaryAction: PropTypes.bool,
  tertiaryActionText: PropTypes.string,
  tertiaryAction: PropTypes.func,
  disableTertiaryAction: PropTypes.bool,
  hasDetachedActions: PropTypes.bool,
  disableSecondaryAction: PropTypes.bool,
  secondaryAction: PropTypes.func,
  secondaryActionText: PropTypes.string
};

export default Footer;
