import {
  call,
  delay,
  put,
  race,
  take,
  takeLatest,
  select
} from 'redux-saga/effects';
import { axiosDefault } from 'lib/utils/axios-setup';
import {
  MATCHED_CONTACTS_FETCH_REQUEST,
  MATCHED_CONTACTS_FETCH_SUCCESS,
  MATCHED_CONTACTS_FETCH_FAILURE,
  MEMBERS_SYNC_REQUEST,
  MEMBERS_SYNC_SUCCESS,
  MEMBERS_SYNC_FAILURE,
  SET_SYNC_TO_REQUIRED_REQUEST,
  SET_SYNC_TO_REQUIRED_SUCCESS,
  SET_SYNC_TO_REQUIRED_FAILURE,
  SYNC_WIZARD_FETCH_TEST_LOG_REQUEST,
  SYNC_WIZARD_FETCH_TEST_LOG_SUCCESS,
  SYNC_WIZARD_FETCH_TEST_LOG_FAILURE,
  FETCH_SYNC_MODE_REQUEST,
  FETCH_SYNC_MODE_SUCCESS,
  FETCH_SYNC_MODE_FAILURE,
  FETCH_SYNC_STATUS_REQUEST,
  FETCH_SYNC_STATUS_SUCCESS,
  FETCH_SYNC_STATUS_FAILURE,
  STATS_FETCH_REQUEST,
  STATS_FETCH_SUCCESS,
  STATS_FETCH_FAILURE,
  INITIALIZE_SYNC_WIZARD_REQUEST,
  INITIALIZE_SYNC_WIZARD_SUCCESS,
  INITIALIZE_SYNC_WIZARD_FAILURE,
  LAST_SYNC_OUTPUT_LOG_REQUEST,
  LAST_SYNC_OUTPUT_LOG_SUCCESS,
  LAST_SYNC_OUTPUT_LOG_FAILURE,
  START_LAST_SYNC_OUTPUT_LOG_WATCHER,
  STOP_LAST_SYNC_OUTPUT_LOG_WATCHER,
  STOP_CHECK_SYNC_STATUS_WATCHER,
  START_CHECK_SYNC_STATUS_WATCHER,
  SWITCH_OFF_TEST_MODE_REQUEST,
  SWITCH_OFF_TEST_MODE_SUCCESS,
  SWITCH_OFF_TEST_MODE_FAILURE
} from 'appState/actions/constants/syncWizard.actions';
import { waitFor } from 'lib/utils/redux-saga-extensions';
import isEmpty from 'lodash.isempty';

function* fetchMembersFromDifferentPlatforms() {
  try {
    yield call(waitFor, state => !isEmpty(state.syncWizard.currentAccount));
    const accountId = yield select(state => state.syncWizard.currentAccount.id);
    const params = {
      account_id: accountId
    };
    const response = yield call(
      axiosDefault.get,
      '/zen_planner/sync_wizards/matched_contacts',
      {
        params
      }
    );
    yield put({
      type: MATCHED_CONTACTS_FETCH_SUCCESS,
      payload: {
        contacts: response.data
      }
    });
  } catch (e) {
    yield put({
      type: MATCHED_CONTACTS_FETCH_FAILURE,
      payload: {
        error: e,
        fallbackError:
          "We're having trouble retrieving your contacts from Zen Planner.  If this issue persists, please contact Zen Planner support for assistance"
      },
      error: true
    });
  }
}

function* initializeSyncWizard() {
  try {
    yield call(waitFor, state => !isEmpty(state.syncWizard.currentAccount));
    const accountId = yield select(state => state.syncWizard.currentAccount.id);
    const params = {
      account_id: accountId
    };
    const response = yield call(axiosDefault.get, '/zen_planner/sync_wizards', {
      params
    });
    yield put({
      type: INITIALIZE_SYNC_WIZARD_SUCCESS,
      payload: {
        syncStatus: response.data
      }
    });
  } catch (e) {
    yield put({
      type: INITIALIZE_SYNC_WIZARD_FAILURE,
      payload: {
        error: e,
        fallbackError:
          'There was an issue initializing your sync wizard. Please contact support if the problem persists.'
      },
      error: true
    });
  }
}

function* sendMembersToSync({ payload: { contacts, history } = {} }) {
  try {
    yield call(waitFor, state => !isEmpty(state.syncWizard.currentAccount));
    const accountId = yield select(state => state.syncWizard.currentAccount.id);
    const params = {
      contacts,
      account_id: accountId
    };
    const response = yield call(
      axiosDefault.post,
      'zen_planner/sync_wizards/sync_contacts',
      params
    );
    history.push('/ui/sync-wizard/stats');
    yield put({
      type: MEMBERS_SYNC_SUCCESS,
      payload: {
        syncingStatus: response.data
      }
    });
  } catch (e) {
    yield put({
      type: MEMBERS_SYNC_FAILURE,
      payload: {
        error: e,
        fallbackError: 'Error syncing Members. Please try again.'
      },
      error: true
    });
  }
}

function* setSyncIntegrationToRequired(history) {
  try {
    yield call(waitFor, state => !isEmpty(state.syncWizard.currentAccount));
    const accountId = yield select(state => state.syncWizard.currentAccount.id);
    const params = {
      account_id: accountId
    };
    const response = yield call(
      axiosDefault.post,
      'zen_planner/sync_wizards/set_sync_required',
      params
    );
    yield put({
      type: SET_SYNC_TO_REQUIRED_SUCCESS,
      payload: {
        syncingStatus: response.data
      }
    });
    history.push('/ui/sync-wizard');
  } catch (e) {
    yield put({
      type: SET_SYNC_TO_REQUIRED_FAILURE,
      payload: {
        error: e,
        fallbackError:
          'Error setting the Sync Integration to required. Please try again.'
      },
      error: true
    });
  }
}

function* getSyncWizardLogger() {
  try {
    yield call(waitFor, state => !isEmpty(state.syncWizard.currentAccount));
    const accountId = yield select(state => state.syncWizard.currentAccount.id);
    const params = {
      account_id: accountId
    };
    const response = yield call(
      axiosDefault.get,
      '/zen_planner/sync_wizards/test-mode-logs',
      { params }
    );

    yield put({
      type: SYNC_WIZARD_FETCH_TEST_LOG_SUCCESS,
      payload: {
        logger: response.data.logger
      }
    });
  } catch (e) {
    yield put({
      type: SYNC_WIZARD_FETCH_TEST_LOG_FAILURE,
      payload: { error: e, fallbackError: 'Error fetching logger.' },
      error: true
    });
  }
}

function* getLastSyncOutputLogs() {
  try {
    yield call(waitFor, state => !isEmpty(state.syncWizard.currentAccount));
    const accountId = yield select(state => state.syncWizard.currentAccount.id);
    const params = {
      account_id: accountId
    };
    const response = yield call(
      axiosDefault.get,
      '/zen_planner/sync_wizards/last_sync_output_logs',
      { params }
    );

    yield put({
      type: LAST_SYNC_OUTPUT_LOG_SUCCESS,
      payload: {
        lastSyncOutputLog: response.data.logs,
        syncingStatus: response.data.syncing_status
      }
    });
  } catch (e) {
    yield put({
      type: LAST_SYNC_OUTPUT_LOG_FAILURE,
      payload: {
        error: e,
        fallbackError: 'Error fetching last sync output logs.'
      },
      error: true
    });
  }
}

function* pollLastSyncOutputLogs() {
  while (true) {
    try {
      yield call(waitFor, state => !isEmpty(state.syncWizard.currentAccount));
      const accountId = yield select(
        state => state.syncWizard.currentAccount.id
      );
      const params = {
        account_id: accountId
      };
      const response = yield call(
        axiosDefault.get,
        '/zen_planner/sync_wizards/last_sync_output_logs',
        { params }
      );

      yield put({
        type: LAST_SYNC_OUTPUT_LOG_SUCCESS,
        payload: {
          lastSyncOutputLog: response.data.logs,
          syncingStatus: response.data.syncing_status
        }
      });

      yield delay(2000);
    } catch (e) {
      yield put({
        type: LAST_SYNC_OUTPUT_LOG_FAILURE,
        payload: {
          error: e,
          fallbackError: 'Error fetching last sync output logs.'
        },
        error: true
      });
      yield put({
        type: STOP_LAST_SYNC_OUTPUT_LOG_WATCHER,
        error: true
      });
    }
  }
}

function* getSyncMode() {
  try {
    yield call(waitFor, state => !isEmpty(state.syncWizard.currentAccount));
    const accountId = yield select(state => state.syncWizard.currentAccount.id);
    const params = {
      account_id: accountId
    };
    const response = yield call(
      axiosDefault.get,
      '/zen_planner/sync_wizards/check_test_mode',
      { params }
    );

    yield put({
      type: FETCH_SYNC_MODE_SUCCESS,
      payload: {
        testMode: response.data.testMode
      }
    });
  } catch (e) {
    yield put({
      type: FETCH_SYNC_MODE_FAILURE,
      payload: { error: e, fallbackError: 'Error fetching SyncMode.' },
      error: true
    });
  }
}

function* getSyncStatus() {
  try {
    yield call(waitFor, state => !isEmpty(state.syncWizard.currentAccount));
    const accountId = yield select(state => state.syncWizard.currentAccount.id);
    const params = {
      account_id: accountId
    };
    const response = yield call(
      axiosDefault.get,
      '/zen_planner/sync_wizards/status',
      { params }
    );

    yield put({
      type: FETCH_SYNC_STATUS_SUCCESS,
      payload: {
        syncStatus: response.data
      }
    });
  } catch (e) {
    yield put({
      type: FETCH_SYNC_STATUS_FAILURE,
      payload: { error: e, fallbackError: 'Error fetching sync status.' },
      error: true
    });
  }
}

function* pollGetSyncStatus() {
  while (true) {
    try {
      yield call(waitFor, state => !isEmpty(state.syncWizard.currentAccount));
      const accountId = yield select(
        state => state.syncWizard.currentAccount.id
      );
      const params = {
        account_id: accountId
      };
      const response = yield call(
        axiosDefault.get,
        '/zen_planner/sync_wizards/status',
        { params }
      );

      yield put({
        type: FETCH_SYNC_STATUS_SUCCESS,
        payload: {
          syncStatus: response.data
        }
      });
      yield delay(2000);
    } catch (e) {
      yield put({
        type: FETCH_SYNC_STATUS_FAILURE,
        payload: { error: e, fallbackError: 'Error fetching sync status.' },
        error: true
      });
      yield put({
        type: STOP_CHECK_SYNC_STATUS_WATCHER,
        error: true
      });
    }
  }
}

function* getStats() {
  try {
    yield call(waitFor, state => !isEmpty(state.syncWizard.currentAccount));
    const accountId = yield select(state => state.syncWizard.currentAccount.id);
    const params = {
      account_id: accountId
    };
    const response = yield call(
      axiosDefault.get,
      '/zen_planner/sync_wizards/stats',
      { params }
    );

    yield put({
      type: STATS_FETCH_SUCCESS,
      payload: {
        stats: response.data
      }
    });
  } catch (e) {
    yield put({
      type: STATS_FETCH_FAILURE,
      payload: { error: e, fallbackError: 'Error fetching sync stats.' },
      error: true
    });
  }
}

function* switchTestModeOff() {
  try {
    yield call(waitFor, state => !isEmpty(state.syncWizard.currentAccount));
    const accountId = yield select(state => state.syncWizard.currentAccount.id);
    const params = {
      account_id: accountId,
      segment: 'zp-test-mode-off'
    };
    const response = yield call(axiosDefault.post, '/feature_flags', params);

    yield put({
      type: SWITCH_OFF_TEST_MODE_SUCCESS,
      payload: {
        testMode: response.data
      }
    });
  } catch (e) {
    yield put({
      type: SWITCH_OFF_TEST_MODE_FAILURE,
      payload: { error: e, fallbackError: 'Error Turning Off Test Mode.' },
      error: true
    });
  }
}

// Poll to grab output logs while sync is occurring
export function* pollLastSyncOutputLogWatcher() {
  while (true) {
    const action = yield take(START_LAST_SYNC_OUTPUT_LOG_WATCHER);
    yield race([
      call(pollLastSyncOutputLogs, action),
      take(STOP_LAST_SYNC_OUTPUT_LOG_WATCHER)
    ]);
  }
}

export function* pollGetSyncStatusWatcher() {
  while (true) {
    const action = yield take(START_CHECK_SYNC_STATUS_WATCHER);
    yield race([
      call(pollGetSyncStatus, action),
      take(STOP_CHECK_SYNC_STATUS_WATCHER)
    ]);
  }
}

export function* fetchMembers() {
  yield takeLatest(
    MATCHED_CONTACTS_FETCH_REQUEST,
    fetchMembersFromDifferentPlatforms
  );
}

export function* campaignPause() {
  yield takeLatest(MEMBERS_SYNC_REQUEST, sendMembersToSync);
}

export function* setSyncRequired() {
  yield takeLatest(SET_SYNC_TO_REQUIRED_REQUEST, setSyncIntegrationToRequired);
}

export function* fetchSyncWizardLogger() {
  yield takeLatest(SYNC_WIZARD_FETCH_TEST_LOG_REQUEST, getSyncWizardLogger);
}

export function* fetchLastSyncOutputLogs() {
  yield takeLatest(LAST_SYNC_OUTPUT_LOG_REQUEST, getLastSyncOutputLogs);
}

export function* fetchSyncMode() {
  yield takeLatest(FETCH_SYNC_MODE_REQUEST, getSyncMode);
}

export function* fetchSyncStatus() {
  yield takeLatest(FETCH_SYNC_STATUS_REQUEST, getSyncStatus);
}

export function* fetchStats() {
  yield takeLatest(STATS_FETCH_REQUEST, getStats);
}

export function* syncWizardInit() {
  yield takeLatest(INITIALIZE_SYNC_WIZARD_REQUEST, initializeSyncWizard);
}

export function* turnTestModeOff() {
  yield takeLatest(SWITCH_OFF_TEST_MODE_REQUEST, switchTestModeOff);
}
