import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import isEmpty from 'lodash.isempty';
import {
  createErrorMessageSelector,
  createLoadedSelector
} from 'appState/selectors';
import {
  pauseCampaign,
  unpauseCampaign,
  resetIsLoaded
} from 'appState/actions/ActionCreators';
import { titleize } from 'lib/utils/string';
import UtilityCard from 'components/OverlordUtilities/shared/UtilityCard';
import { CurrentAccountContext } from 'components/shared/context/CurrentAccountContext';
import useProcessingActivity from 'components/shared/hooks/useProcessingActivity';
import useAccountDisabled from 'components/shared/hooks/useAccountDisabled';

const UTILITY_NAMES = [
  'all_campaigns_pauser_pause',
  'all_campaigns_pauser_unpause'
];

const PauseAllCampaigns = () => {
  const currentAccount = useContext(CurrentAccountContext);
  const accountDisabled = useAccountDisabled();
  const processingActivity = useProcessingActivity(UTILITY_NAMES);
  const [campaignsPaused, setCampaignsPaused] = useState(
    currentAccount.campaigns_paused
  );

  const errorSelector = createErrorMessageSelector([
    'overlord/CAMPAIGN_PAUSER_PAUSE',
    'overlord/CAMPAIGN_PAUSER_UNPAUSE'
  ]);
  const loadedSelector = createLoadedSelector([
    'overlord/CAMPAIGN_PAUSER_PAUSE',
    'overlord/CAMPAIGN_PAUSER_UNPAUSE'
  ]);
  const structuredSelector = createStructuredSelector({
    isCampaignPaused: state => state.overlordUtilities.isCampaignPaused,
    error: state => errorSelector(state),
    isLoaded: state => loadedSelector(state)
  });

  const { isCampaignPaused, isLoaded, error } = useSelector(structuredSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isCampaignPaused !== null && campaignsPaused !== isCampaignPaused)
      setCampaignsPaused(isCampaignPaused);
  }, [campaignsPaused, isCampaignPaused]);

  useEffect(() => {
    if (isLoaded && isEmpty(error)) {
      app.toast(
        `Successfully ${
          campaignsPaused ? 'unpaused' : 'paused'
        } campaigns for ${titleize(currentAccount.name)}`,
        {
          type: 'primary',
          icon: 'fa-floppy-o'
        }
      );
    }
    return () => {
      dispatch(
        resetIsLoaded(
          campaignsPaused
            ? 'overlord/CAMPAIGN_PAUSER_UNPAUSE'
            : 'overlord/CAMPAIGN_PAUSER_PAUSE'
        )
      );
    };
  }, [campaignsPaused, currentAccount.name, isLoaded, error, dispatch]);

  const getCardBody = () => {
    return <p>All contacts&apos; campaigns will be paused or unpaused.</p>;
  };

  const pauseUnpauseCampaigns = () => {
    if (campaignsPaused)
      return dispatch(unpauseCampaign({ accountId: currentAccount.id }));
    return dispatch(pauseCampaign({ accountId: currentAccount.id }));
  };

  return (
    <UtilityCard
      title="Campaign Pauser"
      subtitle="Use this tool to pause/unpause campaigns for all of an
              account's contacts."
      submitBtnText={campaignsPaused ? 'Unpause Campaigns' : 'Pause Campaigns'}
      submitBtnAction={pauseUnpauseCampaigns}
      bodyComponent={getCardBody}
      processingActivity={processingActivity}
      accountDisabled={accountDisabled}
      error={error}
    />
  );
};

export default PauseAllCampaigns;
