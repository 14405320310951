/* Contact Profile */

export const FETCH_PINNED_ACTIVITIES_REQUEST =
  'activity/FETCH_PINNED_ACTIVITIES_REQUEST';
export const FETCH_PINNED_ACTIVITIES_SUCCESS =
  'activity/FETCH_PINNED_ACTIVITIES_SUCCESS';
export const FETCH_PINNED_ACTIVITIES_FAILURE =
  'activity/FETCH_PINNED_ACTIVITIES_FAILURE';

export const FETCH_ACTIVITIES = 'activity/FETCH_ACTIVITIES';
export const FETCH_ACTIVITIES_REQUEST = `${FETCH_ACTIVITIES}_REQUEST`;
export const FETCH_ACTIVITIES_SUCCESS = `${FETCH_ACTIVITIES}_SUCCESS`;
export const FETCH_ACTIVITIES_FAILURE = `${FETCH_ACTIVITIES}_FAILURE`;

export const ACTIVITIES_PAGE_CHANGE_REQUEST =
  'activity/FETCH_ACTIVITIES_REQUEST';
export const ACTIVITIES_PAGE_CHANGE_SUCCESS =
  'activity/FETCH_ACTIVITIES_SUCCESS';

export const UPDATE_ACTIVITY_REQUEST = 'activity/UPDATE_ACTIVITY_REQUEST';
export const UPDATE_ACTIVITY_SUCCESS = 'activity/UPDATE_ACTIVITY_SUCCESS';
export const UPDATE_ACTIVITY_FAILURE = 'activity/UPDATE_ACTIVITY_FAILURE';

export const FETCH_PINNED_SMS_ACTIVITIES_REQUEST =
  'activity/FETCH_PINNED_SMS_ACTIVITIES_REQUEST';
export const FETCH_PINNED_SMS_ACTIVITIES_SUCCESS =
  'activity/FETCH_PINNED_SMS_ACTIVITIES_SUCCESS';
export const FETCH_PINNED_SMS_ACTIVITIES_FAILURE =
  'activity/FETCH_PINNED_SMS_ACTIVITIES_FAILURE';

export const FETCH_SMS_ACTIVITIES_REQUEST =
  'activity/FETCH_SMS_ACTIVITIES_REQUEST';
export const FETCH_SMS_ACTIVITIES_SUCCESS =
  'activity/FETCH_SMS_ACTIVITIES_SUCCESS';
export const FETCH_SMS_ACTIVITIES_FAILURE =
  'activity/FETCH_SMS_ACTIVITIES_FAILURE';

export const SMS_ACTIVITIES_PAGE_CHANGE_REQUEST =
  'activity/FETCH_SMS_ACTIVITIES_REQUEST';
export const SMS_ACTIVITIES_PAGE_CHANGE_SUCCESS =
  'activity/FETCH_SMS_ACTIVITIES_SUCCESS';

export const UPDATE_SMS_ACTIVITY_REQUEST =
  'activity/UPDATE_SMS_ACTIVITY_REQUEST';
export const UPDATE_SMS_ACTIVITY_SUCCESS =
  'activity/UPDATE_SMS_ACTIVITY_SUCCESS';
export const UPDATE_SMS_ACTIVITY_FAILURE =
  'activity/UPDATE_SMS_ACTIVITY_FAILURE';

export const FETCH_PINNED_EMAIL_ACTIVITIES_REQUEST =
  'activity/FETCH_PINNED_EMAIL_ACTIVITIES_REQUEST';
export const FETCH_PINNED_EMAIL_ACTIVITIES_SUCCESS =
  'activity/FETCH_PINNED_EMAIL_ACTIVITIES_SUCCESS';
export const FETCH_PINNED_EMAIL_ACTIVITIES_FAILURE =
  'activity/FETCH_PINNED_EMAIL_ACTIVITIES_FAILURE';

export const FETCH_EMAIL_ACTIVITIES = 'activity/FETCH_EMAIL_ACTIVITIES';
export const FETCH_EMAIL_ACTIVITIES_REQUEST = `${FETCH_EMAIL_ACTIVITIES}_REQUEST`;
export const FETCH_EMAIL_ACTIVITIES_SUCCESS = `${FETCH_EMAIL_ACTIVITIES}_SUCCESS`;
export const FETCH_EMAIL_ACTIVITIES_FAILURE = `${FETCH_EMAIL_ACTIVITIES}_FAILURE`;

export const EMAIL_ACTIVITIES_PAGE_CHANGE_REQUEST =
  'activity/FETCH_EMAIL_ACTIVITIES_REQUEST';
export const EMAIL_ACTIVITIES_PAGE_CHANGE_SUCCESS =
  'activity/FETCH_EMAIL_ACTIVITIES_SUCCESS';

export const UPDATE_EMAIL_ACTIVITY_REQUEST =
  'activity/UPDATE_EMAIL_ACTIVITY_REQUEST';
export const UPDATE_EMAIL_ACTIVITY_SUCCESS =
  'activity/UPDATE_EMAIL_ACTIVITY_SUCCESS';
export const UPDATE_EMAIL_ACTIVITY_FAILURE =
  'activity/UPDATE_EMAIL_ACTIVITY_FAILURE';

export const FETCH_PINNED_APPOINTMENTS_ACTIVITIES_REQUEST =
  'activity/FETCH_PINNED_APPOINTMENTS_ACTIVITIES_REQUEST';
export const FETCH_PINNED_APPOINTMENTS_ACTIVITIES_SUCCESS =
  'activity/FETCH_PINNED_APPOINTMENTS_ACTIVITIES_SUCCESS';
export const FETCH_PINNED_APPOINTMENTS_ACTIVITIES_FAILURE =
  'activity/FETCH_PINNED_APPOINTMENTS_ACTIVITIES_FAILURE';

export const FETCH_APPOINTMENTS_ACTIVITIES =
  'activity/FETCH_APPOINTMENTS_ACTIVITIES';
export const FETCH_APPOINTMENTS_ACTIVITIES_REQUEST = `${FETCH_APPOINTMENTS_ACTIVITIES}_REQUEST`;
export const FETCH_APPOINTMENTS_ACTIVITIES_SUCCESS = `${FETCH_APPOINTMENTS_ACTIVITIES}_SUCCESS`;
export const FETCH_APPOINTMENTS_ACTIVITIES_FAILURE = `${FETCH_APPOINTMENTS_ACTIVITIES}_FAILURE`;

export const APPOINTMENTS_ACTIVITIES_PAGE_CHANGE_REQUEST =
  'activity/FETCH_APPOINTMENTS_ACTIVITIES_REQUEST';
export const APPOINTMENTS_ACTIVITIES_PAGE_CHANGE_SUCCESS =
  'activity/FETCH_APPOINTMENTS_ACTIVITIES_SUCCESS';

export const UPDATE_APPOINTMENTS_ACTIVITY_REQUEST =
  'activity/UPDATE_APPOINTMENTS_ACTIVITY_REQUEST';
export const UPDATE_APPOINTMENTS_ACTIVITY_SUCCESS =
  'activity/UPDATE_APPOINTMENTS_ACTIVITY_SUCCESS';
export const UPDATE_APPOINTMENTS_ACTIVITY_FAILURE =
  'activity/UPDATE_APPOINTMENTS_ACTIVITY_FAILURE';

export const FETCH_PINNED_TASKS_ACTIVITIES_REQUEST =
  'activity/FETCH_PINNED_TASKS_ACTIVITIES_REQUEST';
export const FETCH_PINNED_TASKS_ACTIVITIES_SUCCESS =
  'activity/FETCH_PINNED_TASKS_ACTIVITIES_SUCCESS';
export const FETCH_PINNED_TASKS_ACTIVITIES_FAILURE =
  'activity/FETCH_PINNED_TASKS_ACTIVITIES_FAILURE';

export const FETCH_TASKS_ACTIVITIES = 'activity/FETCH_TASKS_ACTIVITIES';
export const FETCH_TASKS_ACTIVITIES_REQUEST = `${FETCH_TASKS_ACTIVITIES}_REQUEST`;
export const FETCH_TASKS_ACTIVITIES_SUCCESS = `${FETCH_TASKS_ACTIVITIES}_SUCCESS`;
export const FETCH_TASKS_ACTIVITIES_FAILURE = `${FETCH_TASKS_ACTIVITIES}_FAILURE`;

export const TASKS_ACTIVITIES_PAGE_CHANGE_REQUEST =
  'activity/FETCH_TASKS_ACTIVITIES_REQUEST';
export const TASKS_ACTIVITIES_PAGE_CHANGE_SUCCESS =
  'activity/FETCH_TASKS_ACTIVITIES_SUCCESS';

export const UPDATE_TASKS_ACTIVITY_REQUEST =
  'activity/UPDATE_TASKS_ACTIVITY_REQUEST';
export const UPDATE_TASKS_ACTIVITY_SUCCESS =
  'activity/UPDATE_TASKS_ACTIVITY_SUCCESS';
export const UPDATE_TASKS_ACTIVITY_FAILURE =
  'activity/UPDATE_TASKS_ACTIVITY_FAILURE';

export const FETCH_PINNED_NOTES_ACTIVITIES_REQUEST =
  'activity/FETCH_PINNED_NOTES_ACTIVITIES_REQUEST';
export const FETCH_PINNED_NOTES_ACTIVITIES_SUCCESS =
  'activity/FETCH_PINNED_NOTES_ACTIVITIES_SUCCESS';
export const FETCH_PINNED_NOTES_ACTIVITIES_FAILURE =
  'activity/FETCH_PINNED_NOTES_ACTIVITIES_FAILURE';

export const FETCH_NOTES_ACTIVITIES = 'activity/FETCH_NOTES_ACTIVITIES';
export const FETCH_NOTES_ACTIVITIES_REQUEST = `${FETCH_NOTES_ACTIVITIES}_REQUEST`;
export const FETCH_NOTES_ACTIVITIES_SUCCESS = `${FETCH_NOTES_ACTIVITIES}_SUCCESS`;
export const FETCH_NOTES_ACTIVITIES_FAILURE = `${FETCH_NOTES_ACTIVITIES}_FAILURE`;

export const NOTES_ACTIVITIES_PAGE_CHANGE_REQUEST =
  'activity/FETCH_NOTES_ACTIVITIES_REQUEST';
export const NOTES_ACTIVITIES_PAGE_CHANGE_SUCCESS =
  'activity/FETCH_NOTES_ACTIVITIES_SUCCESS';

export const UPDATE_NOTES_ACTIVITY_REQUEST =
  'activity/UPDATE_NOTES_ACTIVITY_REQUEST';
export const UPDATE_NOTES_ACTIVITY_SUCCESS =
  'activity/UPDATE_NOTES_ACTIVITY_SUCCESS';
export const UPDATE_NOTES_ACTIVITY_FAILURE =
  'activity/UPDATE_NOTES_ACTIVITY_FAILURE';

export const FETCH_PINNED_FORMS_ACTIVITIES_REQUEST =
  'activity/FETCH_PINNED_FORMS_ACTIVITIES_REQUEST';
export const FETCH_PINNED_FORMS_ACTIVITIES_SUCCESS =
  'activity/FETCH_PINNED_FORMS_ACTIVITIES_SUCCESS';
export const FETCH_PINNED_FORMS_ACTIVITIES_FAILURE =
  'activity/FETCH_PINNED_FORMS_ACTIVITIES_FAILURE';

export const FETCH_FORMS_ACTIVITIES = 'activity/FETCH_FORMS_ACTIVITIES';
export const FETCH_FORMS_ACTIVITIES_REQUEST = `${FETCH_FORMS_ACTIVITIES}_REQUEST`;
export const FETCH_FORMS_ACTIVITIES_SUCCESS = `${FETCH_FORMS_ACTIVITIES}_SUCCESS`;
export const FETCH_FORMS_ACTIVITIES_FAILURE = `${FETCH_FORMS_ACTIVITIES}_FAILURE`;

export const FORMS_ACTIVITIES_PAGE_CHANGE_REQUEST =
  'activity/FETCH_FORMS_ACTIVITIES_REQUEST';
export const FORMS_ACTIVITIES_PAGE_CHANGE_SUCCESS =
  'activity/FETCH_FORMS_ACTIVITIES_SUCCESS';

export const UPDATE_FORMS_ACTIVITY_REQUEST =
  'activity/UPDATE_FORMS_ACTIVITY_REQUEST';
export const UPDATE_FORMS_ACTIVITY_SUCCESS =
  'activity/UPDATE_FORMS_ACTIVITY_SUCCESS';
export const UPDATE_FORMS_ACTIVITY_FAILURE =
  'activity/UPDATE_FORMS_ACTIVITY_FAILURE';

export const FETCH_CONTACT_FORM = 'activity/FETCH_CONTACT_FORM';
export const FETCH_CONTACT_FORM_REQUEST = `${FETCH_CONTACT_FORM}_REQUEST`;
export const FETCH_CONTACT_FORM_SUCCESS = `${FETCH_CONTACT_FORM}_SUCCESS`;
export const FETCH_CONTACT_FORM_FAILURE = `${FETCH_CONTACT_FORM}_FAILURE`;

export const COMPLETE_TASK = 'activity/COMPLETE_TASK';
export const COMPLETE_TASK_REQUEST = `${COMPLETE_TASK}_REQUEST`;
export const COMPLETE_TASK_SUCCESS = `${COMPLETE_TASK}_SUCCESS`;
export const COMPLETE_TASK_FAILURE = `${COMPLETE_TASK}_FAILURE`;

export const EDIT_TASK = 'activity/EDIT_TASK';
export const EDIT_TASK_REQUEST = `${EDIT_TASK}_REQUEST`;
export const EDIT_TASK_SUCCESS = `${EDIT_TASK}_SUCCESS`;
export const EDIT_TASK_FAILURE = `${EDIT_TASK}_FAILURE`;

export const REASSIGN_TASK = 'activity/REASSIGN_TASK';
export const REASSIGN_TASK_REQUEST = `${REASSIGN_TASK}_REQUEST`;
export const REASSIGN_TASK_SUCCESS = `${REASSIGN_TASK}_SUCCESS`;
export const REASSIGN_TASK_FAILURE = `${REASSIGN_TASK}_FAILURE`;

export const CANCEL_TASK = 'activity/CANCEL_TASK';
export const CANCEL_TASK_REQUEST = `${CANCEL_TASK}_REQUEST`;
export const CANCEL_TASK_SUCCESS = `${CANCEL_TASK}_SUCCESS`;
export const CANCEL_TASK_FAILURE = `${CANCEL_TASK}_FAILURE`;

export const RESCHEDULE_APPOINTMENT_REQUEST =
  'activity/RESCHEDULE_APPOINTMENT_REQUEST';
export const RESCHEDULE_APPOINTMENT_SUCCESS =
  'activity/RESCHEDULE_APPOINTMENT_SUCCESS';
export const RESCHEDULE_APPOINTMENT_FAILURE =
  'activity/RESCHEDULE_APPOINTMENT_FAILURE';

export const MARK_APPOINTMENT_NO_SHOW = 'activity/MARK_APPOINTMENT_NO_SHOW';
export const MARK_APPOINTMENT_NO_SHOW_REQUEST = `${MARK_APPOINTMENT_NO_SHOW}_REQUEST`;
export const MARK_APPOINTMENT_NO_SHOW_SUCCESS = `${MARK_APPOINTMENT_NO_SHOW}_SUCCESS`;
export const MARK_APPOINTMENT_NO_SHOW_FAILURE = `${MARK_APPOINTMENT_NO_SHOW}_FAILURE`;

export const CANCEL_APPOINTMENT = 'activity/CANCEL_APPOINTMENT';
export const CANCEL_APPOINTMENT_REQUEST = `${CANCEL_APPOINTMENT}_REQUEST`;
export const CANCEL_APPOINTMENT_SUCCESS = `${CANCEL_APPOINTMENT}_SUCCESS`;
export const CANCEL_APPOINTMENT_FAILURE = `${CANCEL_APPOINTMENT}_FAILURE`;

export const UPDATE_ACTIVITIES_FEED = 'activity/UPDATE_ACTIVITIES_FEED';
