import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import {
  mySubscriptionFetch,
  subscriptionUpdate
} from 'appState/actions/ActionCreators';
import {
  createErrorMessageSelector,
  createLoadedSelector
} from 'appState/selectors';
import { subscriptionSchema } from 'lib/validation/schema';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import ProductSubscription from 'components/Subscriptions/Subscription/ProductSubscription';
import Card from 'components/Theme/Card';
import TextInput from 'components/Theme/TextInput';
import DatePicker from 'components/Theme/DatePicker';
import Modal from 'components/Theme/Modal';
import Select from 'components/Theme/Select';
import Table from 'components/Theme/Table';
import Notification from 'components/Theme/Notification';
import { formatTimestamp } from 'lib/utils/dateTime';
import isEmpty from 'lodash.isempty';
import {
  UPDATE_SUBSCRIPTION,
  FETCH_SUBSCRIPTION
} from 'app-state/actions/constants/subscription.actions';
import Button from 'components/Theme/Button';

const SubscriptionDetail = () => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const [localProductSubscriptions, setLocalProductSubscriptions] = useState(
    []
  );
  const [
    isEditingProductSubscription,
    setIsEditingProductSubscription
  ] = useState(false);
  const [currentProductSubscription, setCurrentProductSubscription] = useState(
    {}
  );
  const [
    isCreatingProductSubscription,
    setIsCreatingProductSubscription
  ] = useState(false);

  const subscriptionUpdatedSelector = createLoadedSelector([
    UPDATE_SUBSCRIPTION
  ]);

  const errorSelector = createErrorMessageSelector([
    FETCH_SUBSCRIPTION,
    UPDATE_SUBSCRIPTION
  ]);

  const handleNewProductSubscription = productSubscriptionId => {
    const productSubscription = localProductSubscriptions.filter(
      s => s.id === productSubscriptionId
    )[0];
    setCurrentProductSubscription(productSubscription);

    setIsEditingProductSubscription(true);
    setIsCreatingProductSubscription(true);
  };

  const structuredSelector = createStructuredSelector({
    currentSubscription: state => state.subscription.mySubscription,
    subscriptionUpdated: state => subscriptionUpdatedSelector(state),
    error: state => errorSelector(state)
  });

  const { currentSubscription, subscriptionUpdated, error } = useSelector(
    structuredSelector
  );

  function handleSubmit(values) {
    const subscriptionParams = { subscriptionId: currentSubscription.id };
    if (Object.keys(values).length)
      Object.keys(values).forEach(function assignParams(key) {
        subscriptionParams[key] = values[key];
      });
    if (localProductSubscriptions)
      subscriptionParams.productSubscriptions = localProductSubscriptions;
    return dispatch(subscriptionUpdate(subscriptionParams));
  }

  const formik = useFormik({
    initialValues: {
      stripeCustomerId: currentSubscription?.stripe_customer_id || '',
      stripeSubscriptionId: currentSubscription?.stripe_subscription_id || '',
      expiresAt: currentSubscription?.expires_at || '',
      comped: currentSubscription?.comped || false,
      paymentFailedAt: currentSubscription?.payment_failed_at || '',
      becameDelinquentAt: currentSubscription?.became_delinquent_at || ''
    },
    enableReinitialize: true,
    validationSchema: subscriptionSchema,
    onSubmit: values => {
      handleSubmit(values);
    }
  });

  useEffect(() => {
    if (isEmpty(currentSubscription)) dispatch(mySubscriptionFetch());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(currentSubscription))
      setLocalProductSubscriptions(currentSubscription.product_subscriptions);
  }, [currentSubscription]);

  function handleExpiresAtChange(updatedValue) {
    formik.setFieldValue('expiresAt', updatedValue);
  }

  function handlePaymentFailedAtChange(updatedValue) {
    formik.setFieldValue('paymentFailedAt', updatedValue);
  }

  function handleBecameDelinquentAtChange(updatedValue) {
    formik.setFieldValue('becameDelinquentAt', updatedValue);
  }

  async function handleDeleteClick(productSubscriptionId) {
    const productSubscription = localProductSubscriptions.filter(
      s => s.id === productSubscriptionId
    )[0];
    await setCurrentProductSubscription(productSubscription);
    setShowModal(true);
  }

  function handleDeleteProductSubscription() {
    const updatedLocalProductSubscriptions = localProductSubscriptions
      .filter(
        productSubscription =>
          (currentProductSubscription.id &&
            currentProductSubscription.id !== productSubscription.id) ||
          productSubscription.id
      )
      .map(productSubscription => {
        if (
          currentProductSubscription.id &&
          currentProductSubscription.id === productSubscription.id
        )
          return {
            id: currentProductSubscription.id,
            _destroy: true
          };
        return productSubscription;
      });
    setLocalProductSubscriptions(updatedLocalProductSubscriptions);
  }

  function handleModalSetShow(show) {
    setShowModal(show);
  }

  function handleSetShowSuccess(show) {
    setShowSuccess(show);
  }

  useEffect(() => {
    if (subscriptionUpdated && isEmpty(error)) {
      setShowSuccess(true);
    }
  }, [error, subscriptionUpdated, dispatch]);

  const getCardBody = () => {
    return (
      <div>
        <form action="#" method="POST">
          <div className="tw-max-w-7xl tw-mx-auto tw-py-2 sm:tw-px-6 lg:tw-px-2">
            <div>
              <div className="tw-p-5">
                <h3 className="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900">
                  Subscription Details
                </h3>
                <p className="tw-mt-1 tw-text-sm tw-leading-5 tw-text-gray-600">
                  Edit details like stripe customer id, stripe subscription id,
                  and expires at.
                </p>
              </div>
              <div className="tw-grid tw-grid-cols-12 tw-gap-6 tw-p-5">
                <TextInput
                  id="stripe_customer_id"
                  value={formik.values.stripeCustomerId}
                  labelText="Stripe Customer ID"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  showError={
                    formik.touched.stripeCustomerId &&
                    !!formik.errors.stripeCustomerId
                  }
                  error={formik.errors.stripeCustomerId}
                  widthClass="tw-col-span-12 sm:tw-col-span-6"
                />

                <TextInput
                  id="stripe_subscription_id"
                  value={formik.values.stripeSubscriptionId}
                  labelText="Stripe Subscription ID"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  showError={
                    formik.touched.stripeSubscriptionId &&
                    !!formik.errors.stripeSubscriptionId
                  }
                  error={formik.errors.stripeSubscriptionId}
                  widthClass="tw-col-span-12 sm:tw-col-span-6"
                />
                <DatePicker
                  id="expires_at"
                  value={formik.values.expiresAt}
                  labelText="Expires At"
                  onChange={handleExpiresAtChange}
                  onBlur={formik.handleBlur}
                  showError={
                    formik.touched.expiresAt && !!formik.errors.expiresAt
                  }
                  error={formik.errors.expiresAt}
                  widthClass="tw-col-span-12 sm:tw-col-span-6"
                />
                <Select
                  id="comped"
                  value={formik.values.comped}
                  options={[
                    { displayName: 'True', value: true },
                    { displayName: 'False', value: false }
                  ]}
                  labelText="Comped"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  showError={formik.touched.comped && !!formik.errors.comped}
                  error={formik.errors.comped}
                  widthClass="tw-col-span-12 sm:tw-col-span-6"
                />

                <DatePicker
                  id="payment_failed_at"
                  value={formik.values.paymentFailedAt}
                  labelText="Payment Failed At"
                  onChange={handlePaymentFailedAtChange}
                  onBlur={formik.handleBlur}
                  showError={
                    formik.touched.paymentFailedAt &&
                    !!formik.errors.paymentFailedAt
                  }
                  error={formik.errors.paymentFailedAt}
                  widthClass="tw-col-span-12 sm:tw-col-span-6"
                />

                <DatePicker
                  id="became_delinquent_at"
                  value={formik.values.becameDelinquentAt}
                  labelText="Became Delinquent At"
                  onChange={handleBecameDelinquentAtChange}
                  onBlur={formik.handleBlur}
                  showError={
                    formik.touched.becameDelinquentAt &&
                    !!formik.errors.becameDelinquentAt
                  }
                  error={formik.errors.becameDelinquentAt}
                  widthClass="tw-col-span-12 sm:tw-col-span-6"
                />
              </div>
            </div>

            <div className="tw-hidden sm:tw-block">
              <div className="tw-py-5">
                <div className="tw-border-t tw-border-b-0 tw-border-l-0 tw-border-r-0 tw-border-solid tw-border-gray-200" />
              </div>
            </div>

            <div>
              <div className="tw-p-5">
                <h3 className="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900">
                  Product Subscriptions
                </h3>
                <p className="tw-mt-1 tw-text-sm tw-leading-5 tw-text-gray-600">
                  This lists out all the products assigned to a subscription.
                </p>
              </div>
              <div className="tw-p-5">
                {isEditingProductSubscription ? (
                  <ProductSubscription
                    subscriptionId={
                      (currentSubscription && currentSubscription.id) || 0
                    }
                    productSubscriptionId={
                      (currentProductSubscription &&
                        currentProductSubscription.id) ||
                      0
                    }
                    isCreating={isCreatingProductSubscription}
                    setIsEditing={isEditing =>
                      setIsEditingProductSubscription(isEditing)
                    }
                    productSubscriptions={localProductSubscriptions}
                    setProductSubscriptions={updatedProductSubscriptions =>
                      setLocalProductSubscriptions(updatedProductSubscriptions)
                    }
                  />
                ) : (
                  <div>
                    <Table
                      rows={localProductSubscriptions}
                      rowConditionals={value => ({
                        created_at:
                          !isEmpty(value) &&
                          formatTimestamp(
                            value,
                            Intl.DateTimeFormat().resolvedOptions().timeZone,
                            false,
                            'MMMM Do YYYY',
                            true
                          )
                      })}
                      includedColumns={['name', 'created_at']}
                      deletable
                      onDelete={handleDeleteClick}
                      emptyMessage="No products tied to this subscription"
                    />

                    <div className="tw-px-4 tw-py-3 tw-bg-gray-50 tw-text-right sm:tw-px-6">
                      <span className="tw-font-body tw-cursor-pointer tw-inline-flex tw-rounded-md tw-shadow-sm">
                        <Button
                          text="Add New"
                          onClick={handleNewProductSubscription}
                        />
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>

        <Modal
          headerText="Delete Product Subscription"
          bodyText={`Are you sure you want to delete ${
            currentProductSubscription && currentProductSubscription.name
              ? ` the ${currentProductSubscription.name}`
              : ' this'
          } product subscription?`}
          primaryAction={handleDeleteProductSubscription}
          primaryActionText="Delete"
          show={showModal}
          setShow={handleModalSetShow}
          color="error"
          HeaderIcon={color => {
            return (
              <svg
                className={`tw-h-6 tw-w-6 tw-text-${color}-600`}
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                />
              </svg>
            );
          }}
        />

        <Notification
          message="Successfully updated subscription."
          show={showSuccess}
          setShowAction={handleSetShowSuccess}
          type="colored"
          headerText="Success!"
          color="success"
          HeaderIcon={color => {
            return (
              <svg
                className={`tw-h-5 tw-w-5 tw-text-${color}-400`}
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                  clipRule="evenodd"
                />
              </svg>
            );
          }}
        />
      </div>
    );
  };

  return (
    <Card
      containerClass="tw-bg-white"
      title="Account’s Expiration Date"
      subtitle="Use this tool to update the expiration date for disabled and non-delinquent accounts."
      showPrimaryAction
      primaryAction={formik.handleSubmit}
      primaryActionText="Update"
    >
      {getCardBody()}
    </Card>
  );
};

export default SubscriptionDetail;
