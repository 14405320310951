import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'components/Theme/Modal';
import {
  contactFormDelete,
  fetchActivities,
  fetchFormsActivities,
  resetIsLoaded
} from 'appState/actions/ActionCreators';
import { DELETE_CONTACT_FORM } from 'app-state/actions/constants/contactForm.actions';
import { createStructuredSelector } from 'reselect';
import { createLoadedSelector } from 'appState/selectors';
import { CurrentAccountContext } from 'components/shared/context/CurrentAccountContext';

const FormDeleteModal = ({ formId, show, setShow }) => {
  const dispatch = useDispatch();
  const currentAccount = useContext(CurrentAccountContext);
  const deleteContactFormLoadingSelector = createLoadedSelector([
    DELETE_CONTACT_FORM
  ]);

  const structuredSelector = createStructuredSelector({
    currentContact: state => state.contact.currentContact,
    isDeletedFormLoaded: state => deleteContactFormLoadingSelector(state)
  });

  const { isDeletedFormLoaded, currentContact } = useSelector(
    structuredSelector
  );

  const activityParams = {
    accountId: currentAccount.id,
    contactId: currentContact?.id
  };

  function handleClose() {
    setShow(false);
  }

  function handleDeleteForm(form_id) {
    dispatch(
      contactFormDelete({
        formId: form_id
      })
    );
  }

  useEffect(() => {
    if (isDeletedFormLoaded) {
      dispatch(fetchActivities(activityParams));
      dispatch(
        fetchFormsActivities({
          contactId: currentContact.id
        })
      );
      dispatch(resetIsLoaded(DELETE_CONTACT_FORM));
    }
    setShow(show);
  }, [isDeletedFormLoaded, show, setShow]);

  return (
    <Modal
      color="error"
      headerText="Delete Contact Form?"
      bodyText="Are you sure you want to delete this form?"
      primaryAction={() => handleDeleteForm(formId)}
      showHeaderIcon={false}
      primaryActionText="Delete Form"
      secondaryActionText="Cancel"
      setShow={setShow}
      show={show}
      showSecondaryAction
      secondaryAction={handleClose}
      canHandleClickOutside={false}
    />
  );
};

FormDeleteModal.defaultProps = {
  formId: '',
  show: true,
  setShow: () => null
};

FormDeleteModal.propTypes = {
  formId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  show: PropTypes.bool,
  setShow: PropTypes.func
};

export default FormDeleteModal;
