import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  createTask,
  fetchTask,
  fetchTasksActivities,
  resetIsLoaded
} from 'appState/actions/ActionCreators';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { UsersContext } from 'components/shared/context/UsersContext';
import { CurrentAccountContext } from 'components/shared/context/CurrentAccountContext';
import Button from 'components/Theme/Button';
import DraftEditor from 'components/Theme/DraftEditor';
import Select from 'components/Theme/Select';
import TextInput from 'components/Theme/TextInput';
import DatePicker from 'components/Theme/DatePicker';
import { getPreviousDay } from 'lib/utils/dateTime';
import { taskSchema } from 'lib/validation/schema';
import {
  CREATE_TASK,
  UPDATE_TASK
} from 'app-state/actions/constants/task.actions';

const TaskBuilder = ({ id, contactId, isLoadingCreate }) => {
  const dispatch = useDispatch();

  const structuredSelector = createStructuredSelector({
    currentTask: state => state.task.currentTask
  });

  const { currentTask } = useSelector(structuredSelector);

  const currentAccount = useContext(CurrentAccountContext);

  function handleSubmit(values) {
    const taskParams = { accountId: currentAccount.id };
    if (id) taskParams.taskId = id;
    if (contactId) taskParams.contactId = contactId;

    if (Object.keys(values).length)
      Object.keys(values).forEach(function assignParams(key) {
        taskParams[key] = values[key];
      });

    return dispatch(createTask(taskParams));
  }

  const { users } = useContext(UsersContext);

  useEffect(() => {
    if (id) dispatch(fetchTask({ taskId: id }));
  }, [dispatch, id]);

  const userSelectOptions = users.map(user => {
    return { displayName: user.full_name, value: user.id };
  });

  const customMergeFieldsData = {
    mergeFieldOptions: [
      { key: 1, text: 'Business Name', value: '{{ account.name }}' },
      { key: 2, text: 'Contact First Name', value: '{{ contact.first_name }}' },
      { key: 3, text: 'Contact Last Name', value: '{{ contact.last_name }}' }
    ]
  };

  const formik = useFormik({
    initialValues: {
      title: (currentTask && currentTask.title) || '',
      description: (currentTask && currentTask.description) || '',
      staffId: (currentTask && currentTask.staff_id) || '',
      dueDate: (currentTask && currentTask.due_date) || ''
    },
    enableReinitialize: true,
    validationSchema: taskSchema,
    onSubmit: values => {
      handleSubmit(values);
    }
  });

  useEffect(() => {
    formik.setFieldValue('title', '');
    formik.setFieldValue('description', '');
    formik.setFieldTouched('description', false);
    formik.setFieldValue('staffId', '');
    formik.setFieldValue('dueDate', '');

    dispatch(
      fetchTasksActivities({
        accountId: currentAccount.id,
        contactId
      })
    );

    return () => {
      dispatch(resetIsLoaded(UPDATE_TASK));
      dispatch(resetIsLoaded(CREATE_TASK));
    };
  }, [dispatch, currentTask]);

  function handleDueDateChange(updatedValue) {
    formik.setFieldValue('dueDate', updatedValue);
  }

  function handleDescriptionChange(updatedDescription) {
    formik.setFieldValue('description', updatedDescription);
  }

  function handleDescriptionBlur() {
    formik.setFieldTouched('description', true);
  }

  return (
    <span>
      <div className="tw-py-5">
        <div className="tw-grid tw-grid-cols-12 tw-gap-6">
          <TextInput
            id="title"
            value={formik.values.title}
            labelText="Title"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            showError={formik.touched.title && !!formik.errors.title}
            error={formik.errors.title}
            widthClass="tw-col-span-12 sm:tw-col-span-6"
          />

          <div className="tw-col-span-12">
            <DraftEditor
              editorStateString={formik.values.description}
              handleEditorTextVal={handleDescriptionChange}
              customDropDownData={customMergeFieldsData}
              customDropDownName={['Merge Fields']}
              editorOptions={['inline', 'list', 'link', 'remove', 'history']}
              labelText="Description"
              onBlur={handleDescriptionBlur}
              showError={
                formik.touched.description && !!formik.errors.description
              }
              error={formik.errors.description}
            />
          </div>

          <Select
            id="staff_id"
            value={formik.values.staffId}
            options={userSelectOptions}
            labelText="Staff Member"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            showError={formik.touched.staffId && !!formik.errors.staffId}
            error={formik.errors.staffId}
            widthClass="tw-col-span-12 sm:tw-col-span-6"
          />

          <DatePicker
            id="due_date"
            value={formik.values.dueDate}
            labelText="Due Date"
            onChange={handleDueDateChange}
            onBlur={formik.handleBlur}
            showError={formik.touched.dueDate && !!formik.errors.dueDate}
            error={formik.errors.dueDate}
            widthClass="tw-col-span-12 sm:tw-col-span-6"
            minDate={getPreviousDay()}
          />

          <div className="tw-col-span-12">
            <Button
              buttonRef={null}
              buttonStyle={{}}
              color="alpha"
              containerStyle={{
                marginBottom: '5px',
                float: 'right'
              }}
              disabled={false}
              isLoaded={!isLoadingCreate}
              disabledStyle={{}}
              disabledTitleStyle={{}}
              onClick={formik.handleSubmit}
              text="Create Task"
              titleStyle={{}}
              type="primary"
            />
          </div>
        </div>
      </div>
    </span>
  );
};

TaskBuilder.defaultProps = { id: null, contactId: null };
TaskBuilder.propTypes = {
  id: PropTypes.number,
  contactId: PropTypes.string
};

export default TaskBuilder;
