import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';
import Skeleton from 'react-loading-skeleton';
import Badge from 'components/Theme/Badge';
import HeroIcon from 'components/Theme/HeroIcon';

const CardHeader = ({
  text,
  icon,
  badgeColor,
  badgeValue,
  disableSkeleton
}) => {
  return (
    <div className="tw-p-4 tw-border-solid tw-border-b tw-border-t-0 tw-border-l-0 tw-border-r-0 tw-border-gray-200 tw-flex tw-justify-between">
      <div className="tw-flex tw-items-center">
        <HeroIcon icon={icon} color="gray" />
        <span className="tw-pl-1 tw-font-medium tw-font-body tw-text-lg tw-leading-6 tw-text-gray-900">
          {text}
        </span>
      </div>
      <div>
        {!disableSkeleton && isEmpty(badgeValue) ? (
          <Skeleton height={20} width={80} />
        ) : (
          <Badge
            color={badgeColor}
            value={badgeValue.toUpperCase()}
            size="small"
          />
        )}
      </div>
    </div>
  );
};

CardHeader.defaultProps = {
  badgeValue: '',
  badgeColor: '',
  disableSkeleton: false
};

CardHeader.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  badgeColor: PropTypes.string,
  badgeValue: PropTypes.string,
  disableSkeleton: PropTypes.bool
};

export default CardHeader;
