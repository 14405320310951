export const ASSIGNED_STAFF = 'assign_to_appointment_type_staff';
export const DIFFERENT_STAFF = 'assign_to_different_staff';
export const ANY_ASSIGNED_STAFF = 'allow_booking_with_appointment_type_staff';
export const ANY_ASSIGNED_STAFF_REASSIGN =
  'allow_booking_with_appointment_type_staff_and_reassign';
export const ONLY_ASSINGNED_STAFF = 'only_assigned_staff_booking';
export const LAUNCH_NOW = 'launch-now';
export const LAUNCH_IN_FUTURE = 'future-date';
export const SHOW_APPOINTMENT = 'show-appointment';
export const HIDE_APPOINTMENT = 'hide-appointment';
export const SHOW_DEFAULT_PRODUCT = 'show-default-product';
export const HIDE_DEFAULT_PRODUCT = 'hide-default-product';
