import React from 'react';
import { action } from '@storybook/addon-actions';
import { withA11y } from '@storybook/addon-a11y';
import {
  withKnobs,
  text,
  object,
  boolean,
  select
} from '@storybook/addon-knobs';
import Pagination from 'components/Theme/Pagination';
import Table from './index';

export default {
  component: Table,
  title: 'Table',
  decorators: [withA11y, withKnobs]
};

let sizeSelect;
let colorSelect;

const tableRows = [
  {
    id: 0,
    name: 'Bernard Lane',
    title: 'Director, Human Resources',
    email: 'bernardlane@example.com',
    role: 'Owner',
    status: 'prospect'
  },
  {
    id: 1,
    name: 'Bernard Lane',
    title: 'Director, Human Resources',
    email: 'bernardlane@example.com',
    role: 'Owner',
    status: 'prospect'
  },
  {
    id: 2,
    name: 'Bernard Lane',
    title: 'Director, Human Resources',
    email: 'bernardlane@example.com',
    role: 'Owner',
    status: 'prospect'
  },
  {
    id: 3,
    name: 'Bernard Lane',
    title: 'Director, Human Resources',
    email: 'bernardlane@example.com',
    role: 'Owner',
    status: 'prospect'
  }
];

const tableRowsValidate = [
  {
    id: 1,
    firstname: 'Bernard',
    lastname: 'Lane',
    email: 'bernardlane@example.com',
    birthday: '04/22/1980',
    validationerror: 'A contact with this email already exists ',
    status: 'prospect',
    status1: 'prospect',
    status2: 'prospect',
    status3: 'prospect'
  },
  {
    id: 2,
    firstname: 'Bernard',
    lastname: 'Lane',
    birthday: '04/22/1980',
    email: 'bernardlane@@example.com',
    validationerror: 'Invalid Email',
    status: 'prospect',
    status1: 'prospect',
    status2: 'prospect',
    status3: 'prospect'
  },
  {
    id: 3,
    firstname: 'Bernard',
    lastname: 'Lane',
    birthday: '04/22/1980',
    email: 'bernardlane@example.com',
    validationerror: 'A contact with this email already exists ',
    status: 'prospect',
    status1: 'prospect',
    status2: 'prospect',
    status3: 'prospect'
  },
  {
    id: 4,
    firstname: '',
    lastname: 'Lane',
    birthday: '04/22/1980',
    email: 'bernardlane@example.com',
    validationerror: 'Contact must have first name',
    status: 'prospect',
    status1: 'prospect',
    status2: 'prospect',
    status3: 'prospect'
  },
  {
    id: 5,
    firstname: 'Dorothy',
    lastname: 'Miles',
    birthday: '04/22/1980',
    email: 'Dorothy@example.com',
    validationerror:
      'A contact with this email already exists and will not be imported',
    status: 'prospect',
    status1: 'prospect',
    status2: 'prospect',
    status3: 'prospect'
  }
];

const tableForMultiRows = [
  [
    {
      id: 0,
      name: {
        first: 'Bernard',
        last: 'Lane'
      },
      title: 'Director, Human Resources',
      email: 'bernardlane@example.com',
      role: 'Owner',
      status: 'lead'
    },
    {
      id: 1,
      name: {
        first: 'Bernard',
        last: 'Lane'
      },
      title: 'Director, Human Resources',
      email: 'bernardlane@example.com',
      role: 'Owner',
      status: 'lead'
    }
  ],
  [
    {
      id: 2,
      name: {
        first: 'Bernard',
        last: 'Lane'
      },
      title: 'Director, Human Resources',
      email: 'bernardlane@example.com',
      role: 'Owner',
      status: 'lead'
    },
    {
      id: 3,
      name: {
        first: 'Bernard',
        last: 'Lane'
      },
      title: 'Director, Human Resources',
      email: 'bernardlane@example.com',
      role: 'Owner',
      status: 'lead'
    },
    {
      id: 4,
      name: {
        first: 'Bernard',
        last: 'Lane'
      },
      title: 'Director, Human Resources',
      email: 'bernardlane@example.com',
      role: 'Owner',
      status: 'lead'
    }
  ],
  [
    {
      id: 5,
      name: {
        first: 'Bernard',
        last: 'Lane'
      },
      title: 'Director, Human Resources',
      email: 'bernardlane@example.com',
      role: 'Owner',
      status: 'lead'
    }
  ]
];

const tableRowsWithToggleAction = [
  [
    {
      id: 0,
      name: {
        first: 'Bernard',
        last: 'Lane'
      },
      title: 'Director, Human Resources',
      email: 'bernardlane@example.com',
      role: 'Owner'
    },
    {
      id: 1,
      name: {
        first: 'Bernard',
        last: 'Lane'
      },
      title: 'Director, Human Resources',
      email: 'bernardlane@example.com',
      role: 'Owner'
    }
  ],
  [
    {
      id: 2,
      name: {
        first: 'Bernard',
        last: 'Lane'
      },
      title: 'Director, Human Resources',
      email: 'bernardlane@example.com',
      role: 'Owner'
    },
    {
      id: 3,
      name: {
        first: 'Bernard',
        last: 'Lane'
      },
      title: 'Director, Human Resources',
      email: 'bernardlane@example.com',
      role: 'Owner'
    },
    {
      id: 4,
      name: {
        first: 'Bernard',
        last: 'Lane'
      },
      title: 'Director, Human Resources',
      email: 'bernardlane@example.com',
      role: 'Owner'
    }
  ]
];

const tableRowsWithSelectOptions = [
  {
    id: 0,
    name: 'Bernard Lane',
    title: 'Director, Human Resources',
    email: 'bernardlane@example.com',
    role: 'Owner',
    selectOptions: [
      {
        displayName: 'Legacy',
        value: 'legacy'
      },
      {
        displayName: 'Bundle',
        value: 'bundle'
      },
      {
        displayName: 'Add-on',
        value: 'add_on'
      }
    ]
  },
  {
    id: 1,
    name: 'Bernard Lane',
    title: 'Director, Human Resources',
    email: 'bernardlane@example.com',
    role: 'Owner',
    selectOptions: [
      {
        displayName: 'Legacy-1',
        value: 'legacy-1'
      },
      {
        displayName: 'Bundle-1',
        value: 'bundle-1'
      },
      {
        displayName: 'Add-on-1',
        value: 'add_on-1'
      }
    ]
  },
  {
    id: 2,
    name: 'Bernard Lane',
    title: 'Director, Human Resources',
    email: 'bernardlane@example.com',
    role: 'Owner',
    selectOptions: [
      {
        displayName: 'Legacy-2',
        value: 'legacy-2'
      },
      {
        displayName: 'Bundle-2',
        value: 'bundle-2'
      },
      {
        displayName: 'Add-on-2',
        value: 'add_on-2'
      }
    ]
  },
  {
    id: 3,
    name: 'Bernard Lane',
    title: 'Director, Human Resources',
    email: 'bernardlane@example.com',
    role: 'Owner',
    selectOptions: [
      {
        displayName: 'Legacy-3',
        value: 'legacy-3'
      },
      {
        displayName: 'Bundle-3',
        value: 'bundle-3'
      },
      {
        displayName: 'Add-on-3',
        value: 'add_on-3'
      }
    ]
  }
];

const selectOptions = [
  {
    displayName: 'Legacy',
    value: 'legacy'
  },
  {
    displayName: 'Bundle',
    value: 'bundle'
  },
  {
    displayName: 'Add-on',
    value: 'add_on'
  }
];

const buttonOptions = [
  { color: 'bravo', text: 'select', action: null },
  { color: 'bravo', text: 'discard', action: null }
];

const buttonOptionsPerRecord = [
  {
    color: 'alpha',
    type: 'white',
    text: 'Ignore',
    size: 'w-100',
    action: null
  },
  {
    color: 'alpha',
    type: 'white',
    text: 'Connect',
    size: 'w-100',
    action: null
  }
];

function includeSizeSelect(defaultValue) {
  const label = 'Size';
  const options = ['small', 'large'];
  sizeSelect = select(label, options, defaultValue);
}

function includeColorSelect(defaultValue) {
  const label = 'Color';
  const options = [
    'alpha',
    'bravo',
    'charlie',
    'success',
    'warning',
    'error',
    'gray'
  ];
  colorSelect = select(label, options, defaultValue);
}

export const standard = () => {
  return (
    <div className="tw-max-w-7xl">
      <Table
        title={text('Title', '')}
        subtitle={text('Subtitle', '')}
        rows={object('Rows', tableRows)}
        includedColumns={object('Included Columns', [
          'name',
          'title',
          'email',
          'role'
        ])}
        editable={boolean('Editable', false)}
        onEdit={action('table-edit-click')}
        deletable={boolean('Deletable', false)}
        onDelete={action('table-delete-click')}
        hasFooter={boolean('Has Footer?', false)}
        footerAction={action('table-footer-action-click')}
        footerActionText={text('Footer Action Text', '')}
      />
    </div>
  );
};

export const withActions = () => {
  return (
    <div className="tw-max-w-7xl">
      <Table
        title={text('Title', '')}
        subtitle={text('Subtitle', '')}
        rows={object('Rows', tableRows)}
        includedColumns={object('Included Columns', [
          'name',
          'title',
          'email',
          'role',
          'status'
        ])}
        editable={boolean('Editable', true)}
        onEdit={action('table-edit-click')}
        deletable={boolean('Deletable', true)}
        onDelete={action('table-delete-click')}
        hasStatus={boolean('Has Status?', true)}
        hasFooter={boolean('Has Footer?', false)}
        footerAction={action('table-footer-action-click')}
        footerActionText={text('Footer Action Text', '')}
      />
    </div>
  );
};

export const withHeader = () => {
  return (
    <div className="tw-max-w-7xl">
      <Table
        title={text('Title', 'Employee Roles')}
        subtitle={text(
          'Subtitle',
          'This is an exhaustive list of the titles and roles of all employees.'
        )}
        rows={object('Rows', tableRows)}
        includedColumns={object('Included Columns', [
          'name',
          'title',
          'email',
          'role'
        ])}
        editable={boolean('Editable', true)}
        onEdit={action('table-edit-click')}
        deletable={boolean('Deletable', true)}
        onDelete={action('table-delete-click')}
        hasFooter={boolean('Has Footer?', false)}
        footerAction={action('table-footer-action-click')}
        footerActionText={text('Footer Action Text', '')}
      />
    </div>
  );
};

export const withHeaderAndFooter = () => {
  return (
    <div className="tw-max-w-7xl">
      <Table
        title={text('Title', 'Employee Roles')}
        subtitle={text(
          'Subtitle',
          'This is an exhaustive list of the titles and roles of all employees.'
        )}
        rows={object('Rows', tableRows)}
        includedColumns={object('Included Columns', [
          'name',
          'title',
          'email',
          'role'
        ])}
        editable={boolean('Editable', true)}
        onEdit={action('table-edit-click')}
        deletable={boolean('Deletable', true)}
        onDelete={action('table-delete-click')}
        hasFooter={boolean('Has Footer?', true)}
        footerAction={action('table-footer-action-click')}
        footerActionText={text('Footer Action Text', 'Save')}
      />
    </div>
  );
};

export const withPagination = () => {
  return (
    <div className="tw-max-w-7xl">
      <Table
        title={text('Title', 'Employee Roles')}
        subtitle={text(
          'Subtitle',
          'This is an exhaustive list of the titles and roles of all employees.'
        )}
        rows={object('Rows', tableRows)}
        includedColumns={object('Included Columns', [
          'name',
          'title',
          'email',
          'role'
        ])}
        editable={boolean('Editable', true)}
        onEdit={action('table-edit-click')}
        deletable={boolean('Deletable', true)}
        onDelete={action('table-delete-click')}
        hasFooter={boolean('Has Footer?', true)}
        FooterComponent={() => (
          <Pagination
            total={text('Total Items', 30)}
            totalPages={text('Total Pages', 3)}
            limit={text('Per Page Limit', 10)}
            action={action('page-change-clicked')}
          />
        )}
      />
    </div>
  );
};

export const MultipleRecordRowStandard = () => {
  return (
    <div className="tw-max-w-7xl">
      <Table
        title={text('Title', '')}
        subtitle={text('Subtitle', '')}
        rows={object('Rows', tableForMultiRows)}
        includedColumns={object('Included Columns', [
          'name',
          'title',
          'email',
          'role'
        ])}
        rowConditionals={value => ({ name: `${value.first} ${value.last}` })}
        hasFooter={boolean('Has Footer?', true)}
        FooterComponent={() => (
          <Pagination
            total={text('Total Items', 5)}
            totalPages={text('Total Pages', 3)}
            limit={text('Per Page Limit', 5)}
            action={action('page-change-clicked')}
          />
        )}
      />
    </div>
  );
};

export const WithRowActionSelect = () => {
  return (
    <div className="tw-max-w-7xl">
      <Table
        title={text('Title', '')}
        subtitle={text('Subtitle', '')}
        rows={object('Rows', tableRowsWithSelectOptions)}
        includedColumns={object('Included Columns', [
          'name',
          'title',
          'email',
          'role'
        ])}
        hasRowActionSelect={boolean('Has Select Action?', true)}
        hasFooter={boolean('Has Footer?', true)}
        FooterComponent={() => (
          <Pagination
            total={text('Total Items', 5)}
            totalPages={text('Total Pages', 3)}
            limit={text('Per Page Limit', 5)}
            action={action('page-change-clicked')}
          />
        )}
      />
    </div>
  );
};

export const MultipleRecordRowWithSelect = () => {
  return (
    <div className="tw-max-w-7xl">
      <Table
        title={text('Title', '')}
        subtitle={text('Subtitle', '')}
        rows={object('Rows', tableForMultiRows)}
        includedColumns={object('Included Columns', [
          'name',
          'title',
          'email',
          'role'
        ])}
        rowConditionals={value => ({ name: `${value.first} ${value.last}` })}
        hasActionSelect={boolean('Has Select Action?', true)}
        selectOptions={object('Select Options', selectOptions)}
        hasFooter={boolean('Has Footer?', true)}
        FooterComponent={() => (
          <Pagination
            total={text('Total Items', 5)}
            totalPages={text('Total Pages', 3)}
            limit={text('Per Page Limit', 5)}
            action={action('page-change-clicked')}
          />
        )}
      />
    </div>
  );
};

export const MultipleRecordRowWithButtonGroup = () => {
  return (
    <div className="tw-max-w-7xl">
      <Table
        title={text('Title', '')}
        subtitle={text('Subtitle', '')}
        rows={object('Rows', tableForMultiRows)}
        includedColumns={object('Included Columns', [
          'name',
          'title',
          'email',
          'role'
        ])}
        rowConditionals={value => ({ name: `${value.first} ${value.last}` })}
        hasActionButton={boolean('Has Button Action?', true)}
        btnProps={object('Button Props', buttonOptions)}
        hasFooter={boolean('Has Footer?', true)}
        FooterComponent={() => (
          <Pagination
            total={text('Total Items', 5)}
            totalPages={text('Total Pages', 3)}
            limit={text('Per Page Limit', 5)}
            action={action('page-change-clicked')}
          />
        )}
      />
    </div>
  );
};

export const MultipleRecordRowWithButtonActionPerRecord = () => {
  return (
    <div className="tw-max-w-7xl">
      <Table
        title={text('Title', '')}
        subtitle={text('Subtitle', '')}
        rows={object('Rows', tableForMultiRows)}
        includedColumns={object('Included Columns', [
          'name',
          'title',
          'email',
          'role',
          'status'
        ])}
        rowConditionals={value => ({ name: `${value.first} ${value.last}` })}
        hasActionButtonPerRecord={boolean('Has Button Action?', true)}
        buttonActionProps={object('Button Props', buttonOptionsPerRecord)}
        hasStatus={boolean('Has Status?', true)}
        hasFooter={boolean('Has Footer?', true)}
        FooterComponent={() => (
          <Pagination
            total={text('Total Items', 5)}
            totalPages={text('Total Pages', 3)}
            limit={text('Per Page Limit', 5)}
            action={action('page-change-clicked')}
          />
        )}
      />
    </div>
  );
};

export const MultipleRecordRowWithToggle = () => {
  includeSizeSelect('large');
  includeColorSelect('alpha');
  return (
    <div className="tw-max-w-7xl">
      <Table
        title={text('Title', '')}
        subtitle={text('Subtitle', '')}
        rows={object('Rows', tableRowsWithToggleAction)}
        includedColumns={object('Included Columns', [
          'name',
          'title',
          'email',
          'role'
        ])}
        rowConditionals={value => ({ name: `${value.first} ${value.last}` })}
        hasToggleAction={boolean('Has Button Action?', true)}
        size={sizeSelect}
        color={colorSelect}
        hasFooter={boolean('Has Footer?', true)}
        FooterComponent={() => (
          <Pagination
            total={text('Total Items', 5)}
            totalPages={text('Total Pages', 3)}
            limit={text('Per Page Limit', 5)}
            action={action('page-change-clicked')}
          />
        )}
      />
    </div>
  );
};

export const MultipleRecordRowWithToggleActionPerRecord = () => {
  return (
    <div className="tw-max-w-7xl">
      <Table
        title={text('Title', '')}
        subtitle={text('Subtitle', '')}
        rows={object('Rows', tableRowsWithToggleAction)}
        includedColumns={object('Included Columns', [
          'name',
          'title',
          'email',
          'role'
        ])}
        rowConditionals={value => ({ name: `${value.first} ${value.last}` })}
        hasToggleActionPerRecord={boolean('Has Button Action?', true)}
        size={sizeSelect}
        color={colorSelect}
        disabledTogglePositions={[0]}
        toggleUsingData={boolean('Enable Toggle?', false)}
        hasFooter={boolean('Has Footer?', true)}
        FooterComponent={() => (
          <Pagination
            total={text('Total Items', 5)}
            totalPages={text('Total Pages', 3)}
            limit={text('Per Page Limit', 5)}
            action={action('page-change-clicked')}
          />
        )}
      />
    </div>
  );
};

export const ValidationOnTable = () => {
  return (
    <div className="tw-max-w-7xl">
      <Table
        rows={object('Rows', tableRowsValidate)}
        validationKey={text('ValidationKey', 'validationerror')}
        includedColumns={object('Included Columns', [
          'id',
          'firstname',
          'lastname',
          'email',
          'birthday',
          'validationerror'
        ])}
      />
    </div>
  );
};

export const FixedLastColumnOfTable = () => {
  return (
    <div className="tw-max-w-7xl">
      <Table
         title={text('Title', 'Fixed Column')}
         subtitle={text(
           'Subtitle',
           'This is an exhaustive list of the contacts'
         )}
        rows={object('Rows', tableRowsValidate)}
        validationKey={text('ValidationKey', 'validationerror')}
        customTableClass={text(
          'CustomTableClass',
          'fixedTable tw-overflow-auto'
        )}
        fixedColumnKey={text('FixedColumnKey', 'validationerror')}
        fixedColumnWidth={text('FixedColumnWidth', 500)}
        includedColumns={object('Included Columns', [
          'id',
          'firstname',
          'lastname',
          'email',
          'birthday',
          'status',
          'status1',
          'status2',
          'status3',
          'validationerror'
        ])}
        hasFooter={boolean('Has Footer?', true)}
        footerAction={action('table-footer-action-click')}
        footerActionText={text('Footer Action Text', 'Save')}
      />
    </div>
  );
};
