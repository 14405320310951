import React, { useContext, useEffect, useState } from 'react';
import UtilityCard from 'components/OverlordUtilities/shared/UtilityCard';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import get from 'lodash.get';
import {
  createErrorMessageSelector,
  createLoadedSelector
} from 'appState/selectors';
import {
  assignCustomGroup,
  fetchCustomGroups
} from 'appState/actions/ActionCreators';
import { CurrentAccountContext } from 'components/shared/context/CurrentAccountContext';
import useProcessingActivity from 'components/shared/hooks/useProcessingActivity';
import useAccountDisabled from 'components/shared/hooks/useAccountDisabled';
import isEmpty from 'lodash.isempty';

const UTILITY_NAMES = ['custom_group_assigner'];

const CustomGroupAssigner = () => {
  let fileField = null;
  const [customGroupIds, setCustomGroupIds] = useState();

  const currentAccount = useContext(CurrentAccountContext);
  const accountId = currentAccount.id;
  const processingActivity = useProcessingActivity(UTILITY_NAMES);
  const accountDisabled = useAccountDisabled();

  const errorSelector = createErrorMessageSelector([
    'overlord/CUSTOM_GROUP_ASSIGNER',
    'overlord/CUSTOM_GROUPS_FETCH'
  ]);
  const loadedSelector = createLoadedSelector(['overlord/CUSTOM_GROUPS_FETCH']);
  const structuredSelector = createStructuredSelector({
    customGroups: state => state.overlordUtilities.customGroups,
    error: state => errorSelector(state),
    isLoaded: state => loadedSelector(state)
  });
  const { customGroups, error, isLoaded } = useSelector(structuredSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isEmpty(customGroups)) dispatch(fetchCustomGroups({ accountId }));
  }, [dispatch, accountId]);

  const changeCustomGroup = () => {
    if (fileField && get(customGroupIds, 'length')) {
      dispatch(
        assignCustomGroup({
          customGroupIds,
          file: fileField.files[0]
        })
      );
    }
  };

  const customGroupSelectOptions = customGroups.map(group => {
    return (
      <option key={group.id} value={group.id}>
        {group.name}
      </option>
    );
  });

  const handleCustomGroupChange = e => {
    setCustomGroupIds(
      [...e.target.options].filter(o => o.selected).map(o => o.value)
    );
  };

  const getCardBody = () => {
    return (
      <div className="form-row">
        <div className="form-group col-lg-6">
          {/* <%= label_tag :file, 'Select CSV File for Upload' %><br> */}
          <label htmlFor="file">Select CSV File for Upload</label>
          <div className="input-group file-group">
            <input
              type="text"
              className="form-control file-value"
              placeholder="Choose file..."
              readOnly=""
            />
            {/* <%= file_field_tag :file, label: 'Select CSV File', class: 'form-control', placeholder: 'Upload CSV', id: 'upload-csv' %> */}
            {/* <label htmlFor="upload-csv">Select CSV File</label> */}
            <input
              ref={field => {
                fileField = field;
              }}
              id="upload-csv"
              name="file"
              type="file"
              className="form-control"
              placeholder="Upload CSV"
            />

            <span className="input-group-append">
              <button className="btn btn-light file-browser" type="button">
                <i className="fa fa-upload" />
              </button>
            </span>
          </div>
        </div>
        <div className="form-group col-lg-6">
          {/* <%= label_tag :email_tag_id, 'Select Custom Group' %>
            <%= select_tag :email_tag_ids, options_for_select(@account.email_tags.pluck(:name,:id)), multiple: true, class: 'form-control', 'data-provide': 'selectpicker' %> */}
          <label htmlFor="emailTagId">Select Custom Group</label>
          {customGroups && customGroups.length ? (
            <select
              onChange={e => handleCustomGroupChange(e)}
              id="emailTagId"
              className="form-control"
              data-size="20"
              data-provide="selectpicker"
              multiple
            >
              {customGroupSelectOptions}
            </select>
          ) : null}
        </div>
      </div>
    );
  };
  return (
    <UtilityCard
      title="Custom Group Assigner"
      subtitle="Use this tool to assign contacts to custom groups."
      submitBtnText="Assign Contacts"
      submitBtnAction={changeCustomGroup}
      bodyComponent={getCardBody}
      isLoaded={isLoaded}
      processingActivity={processingActivity}
      accountDisabled={accountDisabled}
      error={error}
    />
  );
};

export default CustomGroupAssigner;
