import React, { useState, useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import {
  createErrorMessageSelector,
  createLoadedSelector
} from 'appState/selectors';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'components/Theme/Modal';
import Notification from 'components/Theme/Notification';
import Button from 'components/Theme/Button';
import {
  cancelDowngradeSubscription,
  resetIsLoaded
} from 'appState/actions/ActionCreators';
import isEmpty from 'lodash.isempty';
import PropTypes from 'prop-types';

const RollbackModal = ({
  handlePrimaryActionOnSuccess,
  showModalForCancelDowngrade,
  setShowModalForCancelDowngrade,
  showButtonForCancelDowngrade,
  primaryActionText
}) => {
  const dispatch = useDispatch();

  const handlePrimaryAction = () => {
    dispatch(cancelDowngradeSubscription());
  };

  const handleSecondaryAction = () => {
    setShowModalForCancelDowngrade(false);
  };

  const handleCancelDowngradeButton = () => {
    setShowModalForCancelDowngrade(true);
  };
  const cancelDowngradeErrorSelector = createErrorMessageSelector([
    'subscription/CANCEL_DOWNGRADE_SUBSCRIPTION'
  ]);
  const cancelDowngradeLoadedSelector = createLoadedSelector([
    'subscription/CANCEL_DOWNGRADE_SUBSCRIPTION'
  ]);
  const structuredSelector = createStructuredSelector({
    errorCancelDowngrade: state => cancelDowngradeErrorSelector(state),
    isLoadedCancelDowngrade: state => cancelDowngradeLoadedSelector(state)
  });
  const { errorCancelDowngrade, isLoadedCancelDowngrade } = useSelector(
    structuredSelector
  );
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setShowNotificationMessage] = useState('');
  const [notificationColor, setShowNotificationColor] = useState('');
  const [notificationHeaderText, setNotificationHeaderText] = useState('');

  const modalMessage = showButtonForCancelDowngrade
    ? 'Are you sure to Cancel Downgrade?'
    : 'You are set to downgrade your plan at the end of this billing cycle. Do you want to cancel your downgrade and add this product?';

  const handleNotification = (
    notificationShow,
    notificationHeader,
    color,
    message
  ) => {
    setShowNotification(notificationShow);
    setNotificationHeaderText(notificationHeader);
    setShowNotificationColor(color);
    setShowNotificationMessage(message);
  };

  useEffect(() => {
    if (isLoadedCancelDowngrade && isEmpty(errorCancelDowngrade)) {
      handleNotification(true, 'Success!', 'success', 'Downgradedd......');
      setShowModalForCancelDowngrade(false);
      handlePrimaryActionOnSuccess();
    } else if (isLoadedCancelDowngrade && !isEmpty(errorCancelDowngrade)) {
      handleNotification(true, 'Error!', 'error', errorCancelDowngrade);
    }
    dispatch(resetIsLoaded('subscription/CANCEL_DOWNGRADE_SUBSCRIPTION'));
    return () => {
      dispatch(resetIsLoaded('subscription/CANCEL_DOWNGRADE_SUBSCRIPTION'));
    };
  }, [errorCancelDowngrade, isLoadedCancelDowngrade, dispatch]);

  return (
    <div>
      {showButtonForCancelDowngrade ? (
        <div>
          <div className="tw-font-medium tw-mb-2">
            You are set to downgrade your plan at the end of this billing cycle.
            You can cancel it anytime by clicking below button.
          </div>
          <Button
            text="Cancel Downgrade Plan"
            type="primary"
            onClick={handleCancelDowngradeButton}
          />
        </div>
      ) : null}
      <Modal
        color="alpha"
        headerText="Cancel Downgrade?"
        secondaryAction={handleSecondaryAction}
        primaryAction={handlePrimaryAction}
        showHeaderIcon={false}
        primaryActionText={primaryActionText || 'Yes Cancel'}
        secondaryActionText="No, nevermind."
        show={showModalForCancelDowngrade}
        showSecondaryAction
        showPrimaryAction
        overflowHidden={false}
        bodyClasses=""
        canHandleClickOutside={false}
      >
        <div className="tw-font-medium">{modalMessage}</div>
      </Modal>
      <Notification
        message={notificationMessage}
        show={showNotification}
        setShowAction={setShowNotification}
        type="colored"
        headerText={notificationHeaderText}
        color={notificationColor}
        HeaderIcon={color => {
          return (
            <svg
              className={`tw-h-5 tw-w-5 tw-text-${color}-400`}
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                clipRule="evenodd"
              />
            </svg>
          );
        }}
      />
    </div>
  );
};

RollbackModal.defaultProps = {
  handlePrimaryActionOnSuccess: () => null,
  showModalForCancelDowngrade: false,
  setShowModalForCancelDowngrade: () => null,
  showButtonForCancelDowngrade: false,
  primaryActionText: null
};

RollbackModal.propTypes = {
  handlePrimaryActionOnSuccess: PropTypes.func,
  showModalForCancelDowngrade: PropTypes.bool,
  setShowModalForCancelDowngrade: PropTypes.func,
  showButtonForCancelDowngrade: PropTypes.bool,
  primaryActionText: PropTypes.string
};

export default RollbackModal;
