import React, { useState, useRef, Fragment } from 'react';
import { PropTypes } from 'prop-types';
import Card from 'components/Theme/Card';
import HeroIcon from 'components/Theme/HeroIcon';
import useOnClickOutside from 'components/shared/hooks/useOnClickOutside';
import Tooltip from 'components/Theme/Tooltip/index';

const AppointmentTypeCard = ({
  id,
  title,
  duration,
  users,
  maxAttendees,
  onDeleteClick
}) => {
  const contactOptions = [
    {
      value: 'edit',
      displayName: 'Edit',
      onClick: () => {
        window.location = `/ui/appointment-types/${id}/edit`;
      }
    },
    {
      value: 'notifications',
      displayName: 'Notifications',
      onClick: () => {
        window.location = `/account/appointment_types/${id}/notifications/edit`;
      }
    },
    {
      value: 'instructions',
      displayName: 'Instructions',
      onClick: () => {
        window.location = `/account/appointment_types/${id}`;
      }
    },
    {
      value: 'destroy',
      displayName: 'Delete',
      className: 'tw-text-red-500 hover:tw-text-red-900 hover:tw-bg-red-100',
      onClick: () => {
        onDeleteClick();
      }
    }
  ];
  const [showDropdown, setShowDropdown] = useState(false);
  const [coords, setCoords] = useState({});
  const dropdownRef = useRef();
  const dropdownButtonRef = useRef();
  const usersLength = users?.length;
  function toggleDropdown() {
    const dropdownSpanRect = dropdownButtonRef.current?.getBoundingClientRect();

    setCoords({
      left: dropdownSpanRect.x - 200,
      top: dropdownSpanRect.y + dropdownSpanRect.height + window.scrollY
    });
    setShowDropdown(!showDropdown);
  }

  useOnClickOutside(dropdownRef, () => {
    setShowDropdown(false);
  });

  function getToolTip() {
    return (
      <Tooltip
        id={id.toString()}
        place="right"
        classNames="tw-bg-white"
        TooltipElement={() => {
          return (
            <span
              className="tw-items-center tw-cursor-pointer tw-ml-1 tw-mt-px"
              data-tip
              data-for={id.toString()}
            >
              + {usersLength - 1} more
            </span>
          );
        }}
      >
        {users.slice(1, usersLength).map(user => {
          return (
            <p key={user} className="tw-text-gray-500 tw-bg-white tw-mb-0">
              {user}
            </p>
          );
        })}
      </Tooltip>
    );
  }

  function getBodyItem(icon, content) {
    return (
      <div className="tw-flex tw-justify-between">
        <div className="tw-flex tw-items-center">
          <div className="tw-flex-shrink-0 tw-flex tw-item-center tw-mr-2">
            <HeroIcon icon={icon} color="gray" />
          </div>
          <div className="tw-flex-1 tw-text-sm tw-leading-5 tw-flex tw-items-center">
            {content}
          </div>
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      <Card
        title={title}
        containerClass="tw-w-full tw-bg-white tw-rounded-lg"
        innerContainerClass=""
        showHeaderAction
        headerAction={() => {
          toggleDropdown();
        }}
        headerActionIcon={() => (
          <span ref={dropdownButtonRef}>
            <HeroIcon icon="dots-vertical" color="alpha" />
          </span>
        )}
        headerStyle={{ borderTopWidth: '10px', borderTopColor: '#ffa255' }}
      >
        <div
          ref={dropdownRef}
          className={`tw-origin-top-right tw-absolute tw-mt-2 tw--mr-1 tw-w-56 tw-rounded-md tw-shadow-lg ${
            showDropdown ? 'tw-block' : 'tw-hidden'
          }`}
          style={{ top: coords.top, left: coords.left }}
        >
          <div className="tw-rounded-md tw-bg-white tw-shadow-xs">
            <div className="tw-py-1">
              {contactOptions.map(option => (
                <a
                  href="#"
                  key={option.value}
                  data-testid={option.value}
                  onClick={option.onClick}
                  className={`tw-block tw-px-4 tw-py-2 tw-text-sm tw-leading-5 tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900 focus:tw-outline-none focus:tw-bg-gray-100 focus:tw-text-gray-900 ${option.className}`}
                >
                  {option.displayName}
                </a>
              ))}
            </div>
          </div>
        </div>
        <div className="tw-p-6 tw-text-gray-900">
          <div className="tw-flex">
            <div className="tw-flex-shrink-0 tw-flex tw-item-center tw-mr-2">
              <HeroIcon icon="user" color="gray" />
            </div>
            {usersLength > 0 && (
              <div className="tw-flex-1 tw-text-sm tw-leading-5 ">
                {users[0]}
                {usersLength > 1 && getToolTip()}
              </div>
            )}
          </div>
          {getBodyItem('clock', `${duration} minutes`)}
          {getBodyItem('user-group', `${maxAttendees} person max`)}
        </div>
      </Card>
    </Fragment>
  );
};

AppointmentTypeCard.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.number.isRequired,
  duration: PropTypes.string,
  users: PropTypes.arrayOf(PropTypes.object),
  maxAttendees: PropTypes.string,
  onDeleteClick: PropTypes.func.isRequired
};

AppointmentTypeCard.defaultProps = {
  duration: '',
  users: [],
  maxAttendees: ''
};
export default AppointmentTypeCard;
