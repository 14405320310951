import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import PlanIndex from './Plan/Index';
import PlanBuilder from './Plan/Builder';
import FourOFour from '../FourOFour';

const PlansIndex = () => {
  const { path } = useRouteMatch();

  return (
    <div>
      <Switch>
        <Route exact path={path} component={PlanIndex} />
        <Route path={`${path}/create`} component={PlanBuilder} />
        <Route path={`${path}/:id/edit`} component={PlanBuilder} />
        <Route component={FourOFour} />
      </Switch>
    </div>
  );
};

export default PlansIndex;
