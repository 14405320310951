import React from 'react';
import { action } from '@storybook/addon-actions';
import { withA11y } from '@storybook/addon-a11y';
import { withKnobs, text, select } from '@storybook/addon-knobs';
import ActionPanel from './index';

export default {
  component: ActionPanel,
  title: 'Action Panel',
  decorators: [withA11y, withKnobs]
};

let actionTypeSelect;
let buttonLocationSelect;
let colorSelect;

function includeActionTypeSelect(defaultValue) {
  const label = 'Action Type';
  const options = [
    'buttonPrimary',
    'buttonSecondary',
    'buttonWhite',
    'link',
    'toggle',
    'input'
  ];
  actionTypeSelect = select(label, options, defaultValue);
}

function includeButtonLocationSelect(defaultValue) {
  const label = 'Button Location';
  const options = ['bottom', 'right', 'topRight'];
  buttonLocationSelect = select(label, options, defaultValue);
}

function includeColorSelect(defaultValue) {
  const label = 'Color';
  const options = [
    'alpha',
    'bravo',
    'charlie',
    'success',
    'warning',
    'error',
    'gray'
  ];
  colorSelect = select(label, options, defaultValue);
}

export const withLink = () => {
  includeActionTypeSelect('link');
  includeColorSelect('alpha');
  return (
    <div className="tw-max-w-3xl">
      <ActionPanel
        onClick={action('action-panel-link-click')}
        actionType={actionTypeSelect}
        headerText={text(
          'Header Text',
          'Does this not look like what you wanted?'
        )}
        bodyText={text(
          'Body Text',
          'We offer a number of other products, add-ons and bundles that might suit you better. We strive to perfectly tailor UpLaunch to your needs.'
        )}
        actionText={text(
          'Action Text',
          'Learn more about our other product options'
        )}
        color={colorSelect}
      />
    </div>
  );
};

export const buttonPrimary = () => {
  includeActionTypeSelect('buttonPrimary');
  includeButtonLocationSelect('bottom');
  includeColorSelect('alpha');
  return (
    <div className="tw-max-w-3xl">
      <ActionPanel
        onClick={action('action-panel-button-primary-click')}
        actionType={actionTypeSelect}
        headerText={text(
          'Header Text',
          'Does this not look like what you wanted?'
        )}
        bodyText={text(
          'Body Text',
          'We offer a number of other products, add-ons and bundles that might suit you better. We strive to perfectly tailor UpLaunch to your needs.'
        )}
        actionText={text('Action Text', 'Learn More')}
        buttonLocation={buttonLocationSelect}
        color={colorSelect}
      />
    </div>
  );
};

export const buttonSecondary = () => {
  includeActionTypeSelect('buttonSecondary');
  includeButtonLocationSelect('bottom');
  includeColorSelect('alpha');
  return (
    <div className="tw-max-w-3xl">
      <ActionPanel
        onClick={action('action-panel-button-secondary-click')}
        actionType={actionTypeSelect}
        headerText={text(
          'Header Text',
          'Does this not look like what you wanted?'
        )}
        bodyText={text(
          'Body Text',
          'We offer a number of other products, add-ons and bundles that might suit you better. We strive to perfectly tailor UpLaunch to your needs.'
        )}
        actionText={text('Action Text', 'Learn More')}
        buttonLocation={buttonLocationSelect}
        color={colorSelect}
      />
    </div>
  );
};

export const buttonWhite = () => {
  includeActionTypeSelect('buttonWhite');
  includeButtonLocationSelect('bottom');
  includeColorSelect('alpha');
  return (
    <div className="tw-max-w-3xl">
      <ActionPanel
        onClick={action('action-panel-button-white-click')}
        actionType={actionTypeSelect}
        headerText={text(
          'Header Text',
          'Does this not look like what you wanted?'
        )}
        bodyText={text(
          'Body Text',
          'We offer a number of other products, add-ons and bundles that might suit you better. We strive to perfectly tailor UpLaunch to your needs.'
        )}
        actionText={text('Action Text', 'Learn More')}
        buttonLocation={buttonLocationSelect}
        color={colorSelect}
      />
    </div>
  );
};

export const withButtonOnRight = () => {
  includeActionTypeSelect('buttonPrimary');
  includeButtonLocationSelect('right');
  includeColorSelect('alpha');
  return (
    <div className="tw-max-w-3xl">
      <ActionPanel
        onClick={action('action-panel-button-right-click')}
        actionType={actionTypeSelect}
        headerText={text(
          'Header Text',
          'Does this not look like what you wanted?'
        )}
        bodyText={text(
          'Body Text',
          'We offer a number of other products, add-ons and bundles that might suit you better. We strive to perfectly tailor UpLaunch to your needs.'
        )}
        actionText={text('Action Text', 'Learn More')}
        buttonLocation={buttonLocationSelect}
        color={colorSelect}
      />
    </div>
  );
};

export const withButtonAtTopRight = () => {
  includeActionTypeSelect('buttonPrimary');
  includeButtonLocationSelect('topRight');
  includeColorSelect('alpha');
  return (
    <div className="tw-max-w-3xl">
      <ActionPanel
        onClick={action('action-panel-button-top-right-click')}
        actionType={actionTypeSelect}
        headerText={text(
          'Header Text',
          'Does this not look like what you wanted?'
        )}
        bodyText={text(
          'Body Text',
          'We offer a number of other products, add-ons and bundles that might suit you better. We strive to perfectly tailor UpLaunch to your needs.'
        )}
        actionText={text('Action Text', 'Learn More')}
        buttonLocation={buttonLocationSelect}
        color={colorSelect}
      />
    </div>
  );
};

export const withToggle = () => {
  includeActionTypeSelect('toggle');
  includeColorSelect('alpha');
  return (
    <div className="tw-max-w-3xl">
      <ActionPanel
        onClick={action('action-panel-toggle-click')}
        actionType={actionTypeSelect}
        headerText={text(
          'Header Text',
          'Does this not look like what you wanted?'
        )}
        bodyText={text(
          'Body Text',
          'We offer a number of other products, add-ons and bundles that might suit you better. We strive to perfectly tailor UpLaunch to your needs.'
        )}
        actionText={text('Action Text', 'Learn More')}
        color={colorSelect}
      />
    </div>
  );
};

export const withInput = () => {
  includeActionTypeSelect('input');
  includeColorSelect('alpha');
  return (
    <div className="tw-max-w-3xl">
      <ActionPanel
        onClick={action('action-panel-button-with-input-click')}
        actionType={actionTypeSelect}
        headerText={text(
          'Header Text',
          'Does this not look like what you wanted?'
        )}
        bodyText={text(
          'Body Text',
          'We offer a number of other products, add-ons and bundles that might suit you better. We strive to perfectly tailor UpLaunch to your needs.'
        )}
        actionText={text('Action Text', 'Learn More')}
        inputPlaceholder={text('Input Placeholder', 'you@example.com')}
        color={colorSelect}
      />
    </div>
  );
};
