import React, { Fragment } from 'react';
import { withA11y } from '@storybook/addon-a11y';
import { withKnobs, text, select, boolean } from '@storybook/addon-knobs';
import Avatar from './index';

export default {
  component: Avatar,
  title: 'Avatar',
  decorators: [withA11y, withKnobs]
};

let sizeSelect;
let colorSelect;
let statusColorSelect;

function includeSizeSelect(defaultValue) {
  const label = 'Size';
  const options = ['xs', 'sm', 'md', 'lg', 'xl', 'w-100'];
  sizeSelect = select(label, options, defaultValue);
}

function includeColorSelect(defaultValue) {
  const label = 'Color';
  const options = [
    'alpha',
    'bravo',
    'charlie',
    'success',
    'warning',
    'error',
    'gray'
  ];
  colorSelect = select(label, options, defaultValue);
}

function includeStatusColorSelect(defaultValue) {
  const label = 'Status Color';
  const options = [
    'alpha',
    'bravo',
    'charlie',
    'success',
    'warning',
    'error',
    'gray'
  ];
  statusColorSelect = select(label, options, defaultValue);
}

export const sizes = () => {
  includeSizeSelect('xs');
  includeColorSelect('gray');
  includeStatusColorSelect('gray');
  return (
    <Fragment>
      <Avatar
        text={text('Text', 'TW')}
        containerStyle={{ marginRight: '5px', marginBottom: '5px' }}
        size={sizeSelect}
        color={colorSelect}
        hasStatus={boolean('Has Status', false)}
        statusColor={statusColorSelect}
      />
      <Avatar
        text="TW"
        containerStyle={{ marginRight: '5px', marginBottom: '5px' }}
        size="sm"
      />
      <Avatar
        text="TW"
        containerStyle={{ marginRight: '5px', marginBottom: '5px' }}
      />
      <Avatar
        text="TW"
        containerStyle={{ marginRight: '5px', marginBottom: '5px' }}
        size="lg"
      />
      <Avatar
        text="TW"
        containerStyle={{ marginRight: '5px', marginBottom: '5px' }}
        size="xl"
      />
    </Fragment>
  );
};

export const withStatus = () => {
  includeSizeSelect('xs');
  includeColorSelect('gray');
  includeStatusColorSelect('gray');
  return (
    <Fragment>
      <Avatar
        text={text('Text', 'TW')}
        containerStyle={{ marginRight: '5px', marginBottom: '5px' }}
        size={sizeSelect}
        color={colorSelect}
        hasStatus={boolean('Has Status', true)}
        statusColor={statusColorSelect}
      />
      <Avatar
        text="TW"
        containerStyle={{ marginRight: '5px', marginBottom: '5px' }}
        size="sm"
        hasStatus
        statusColor="success"
      />
      <Avatar
        text="TW"
        containerStyle={{ marginRight: '5px', marginBottom: '5px' }}
        hasStatus
        statusColor="warning"
      />
      <Avatar
        text="TW"
        containerStyle={{ marginRight: '5px', marginBottom: '5px' }}
        size="lg"
        hasStatus
        statusColor="error"
      />
      <Avatar
        text="TW"
        containerStyle={{ marginRight: '5px', marginBottom: '5px' }}
        size="xl"
        hasStatus
      />
    </Fragment>
  );
};
