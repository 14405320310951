import React, { Fragment } from 'react';
import Skeleton from 'react-loading-skeleton';

const SelectAudienceBySkeletonItem = () => {
  return (
    <Fragment>
      <div className="col-sm-12 col-md-3 col-xl-2 mb-4">
        <div className="b-2 p-20 big-rounded select_audience_by_main_category cursor-pointer">
          <Skeleton width={100} height={25} />
          <div className="pt-20">
            <Skeleton count={2} />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SelectAudienceBySkeletonItem;
