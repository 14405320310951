/* eslint-disable prettier/prettier */
import React, { useContext, useEffect, Fragment, useState } from 'react';
import { useRouteMatch, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import isEmpty from 'lodash.isempty';
import { CurrentAccountContext } from 'components/shared/context/CurrentAccountContext';
import {
  fetchAppointmentBooker,
  createAppointmentFromBooker,
  resetIsLoaded,
  fetchAppointmentsActivities,
  updateAppointment
} from 'appState/actions/ActionCreators';
import Button from 'components/Theme/Button';
import moment from 'moment-timezone';
import Skeleton from 'react-loading-skeleton';
import schemaCreator from 'lib/validation/schemaCreator';
import {
  createErrorMessageSelector,
  createLoadingSelector,
  createLoadedSelector
} from 'appState/selectors';
import PropTypes from 'prop-types';
import { buildDateTimeWithTimezone } from 'lib/utils/dateTime';
import { scrollToTop } from 'lib/utils/scroll';
import { UPDATE_APPOINTMENT } from 'app-state/actions/constants/appointmentBooker.actions';
import Notification from 'components/Theme/Notification';
import FormField from '../ContactForms/Create/FormField';

const AppointmentBooker = ({
  contactTokenProp,
  appointmentTypeIdProp,
  journeyIdProp,
  clientFacing
}) => {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const currentAccount = useContext(CurrentAccountContext);
  const query = useQuery();

  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const [journeyId] = useState(query.get('jid') || journeyIdProp);
  const [appointmentTypeId, setAppointmentTypeId] = useState(
    query.get('atid') || appointmentTypeIdProp
  );

  const [contactToken] = useState(query.get('t') || contactTokenProp);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedTime, setSelectedTime] = useState();
  const [selectedTimeSlot, setSelectedTimeSlot] = useState();
  const [showAllTimeSlots, setShowAllTimeSlots] = useState(false);
  const [bookingFormSchema, setBookingFormSchema] = useState(
    Yup.object().shape({})
  );
  const [showCreateAppointmentError, setShowCreateAppointmentError] = useState(
    false
  );
  const createAppointmentLoadingSelector = createLoadingSelector([
    'appointment_booker/CREATE_APPOINTMENT_FROM_BOOKER'
  ]);
  const errorSelector = createErrorMessageSelector([
    'appointment_booker/CREATE_APPOINTMENT_FROM_BOOKER'
  ]);
  const updateAppointmentLoadingSelector = createLoadingSelector([
    UPDATE_APPOINTMENT
  ]);
  const createAppointmentLoadedSelector = createLoadedSelector([
    'appointment_booker/CREATE_APPOINTMENT_FROM_BOOKER'
  ]);

  const structuredSelector = createStructuredSelector({
    availabilities: state => state.appointmentBooker.availabilities,
    currentWeekOffset: state => state.appointmentBooker.currentWeekOffset,
    currentAppointmentType: state =>
      state.appointmentBooker.currentAppointmentType,
    isCreatingAppointment: state => createAppointmentLoadingSelector(state),
    error: state => errorSelector(state),
    currentBookingForm: state => state.appointmentBooker.currentBookingForm,
    currentContact: state => state.appointmentBooker.currentContact,
    currentAppointment: state => state.appointmentBooker.currentAppointment,
    isUpdateAppointmentLoading: state =>
      updateAppointmentLoadingSelector(state),
    isAppointmentLoaded: state => createAppointmentLoadedSelector(state)
  });

  const {
    availabilities,
    currentWeekOffset,
    currentAppointmentType,
    currentAppointment,
    currentBookingForm,
    currentContact,
    isCreatingAppointment,
    isCreateAppointmentLoaded,
    isUpdateAppointmentLoading,
    error,
    isAppointmentLoaded
  } = useSelector(structuredSelector);

  function handleSubmit(values) {
    const timeZonedDateTime = buildDateTimeWithTimezone(
      selectedDate,
      selectedTime,
      currentAccount.time_zone
    );
    // eslint-disable-next-line prefer-const
    let appointmentParams = {
      account_id: currentAccount.id,
      client_facing: clientFacing,
      appointment: {
        contact_id: currentContact.id,
        user_id: selectedTimeSlot && selectedTimeSlot[1],
        starts_at: timeZonedDateTime,
        appointment_type_id: currentAppointmentType.id
      },
      contact_id: currentContact.id,
      contact_form: {
        contact_form_type_id: currentBookingForm && currentBookingForm.id
      }
    };

    if (Object.keys(values).length)
      Object.keys(values).forEach(function assignParams(key) {
        appointmentParams.contact_form[key] = values[key];
      });

    if (isEmpty(currentAppointment))
      dispatch(createAppointmentFromBooker({ appointmentParams }));
    else {
      appointmentParams.appointment_id = currentAppointment.id;
      dispatch(updateAppointment({ appointmentParams }));
    }
  }

  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,
    validationSchema: bookingFormSchema,
    onSubmit: values => {
      scrollToTop(null, 'smooth');
      handleSubmit(values);
    }
  });

  useEffect(() => {
    if (
      currentAppointmentType &&
      currentAppointmentType.has_booking_form &&
      currentBookingForm &&
      currentBookingForm.fields
    ) {
      const yupSchema = currentBookingForm.fields.reduce(schemaCreator, {});
      setBookingFormSchema(Yup.object().shape(yupSchema));
    }
  }, [currentBookingForm, currentAppointmentType]);

  useEffect(() => {
    if (!query.get('atid')) setAppointmentTypeId(appointmentTypeIdProp);
  }, [appointmentTypeIdProp]);

  function resetAppointmentBooker() {
    setSelectedDate('');
    setSelectedDay('');
    setSelectedTime(null);
    setSelectedTimeSlot(null);
    Object.keys(formik.values).forEach(function assignParams(key) {
      formik.setFieldValue(key, '');
    });
  }

  useEffect(() => {
    if (isCreateAppointmentLoaded && isEmpty(error) && !clientFacing) {
      dispatch(
        fetchAppointmentsActivities({
          accountId: currentAccount.id,
          contactId: currentContact.id
        })
      );
      resetAppointmentBooker();
    }

    dispatch(
      resetIsLoaded('appointment_booker/CREATE_APPOINTMENT_FROM_BOOKER')
    );

    return () => {
      dispatch(
        resetIsLoaded('appointment_booker/CREATE_APPOINTMENT_FROM_BOOKER')
      );
    };
  }, [isCreateAppointmentLoaded, dispatch]);

  useEffect(() => {
    if (isUpdateAppointmentLoading && isEmpty(error) && !clientFacing) {
      resetAppointmentBooker();
    }

    if (isUpdateAppointmentLoading) {
      dispatch(resetIsLoaded(UPDATE_APPOINTMENT));
    }

    return () => {
      if (isUpdateAppointmentLoading) {
        dispatch(resetIsLoaded(UPDATE_APPOINTMENT));
      }
    };
  }, [error, isUpdateAppointmentLoading, clientFacing, dispatch]);

  useEffect(() => {
    if (clientFacing && isAppointmentLoaded && !isEmpty(error)) {
      setShowCreateAppointmentError(true);
      setSelectedTimeSlot(null);
      setSelectedTime(null);
      dispatch(
        fetchAppointmentBooker({
          contactToken,
          accountId: currentAccount.id,
          appointmentTypeId,
          journeyId,
          week: currentWeekOffset,
          appointmentId: currentAppointment && currentAppointment.id
        })
      );
    }
  }, [error, isAppointmentLoaded, dispatch]);

  function handleTimeButtonClick(timeSlot, timeAsString) {
    setSelectedTime(timeAsString);
    setSelectedTimeSlot(timeSlot);
  }

  function convertTimeSlotToTime(slot) {
    let hour = parseInt(slot[0] / 4, 10);
    const minutes = slot[0] % 4 === 0 ? '00' : (slot[0] % 4) * 15;
    const period = slot[0] < 48 ? 'AM' : 'PM';
    if (hour > 12) {
      hour -= 12;
    }
    if (hour === 0) hour = 12;

    return `${hour}:${minutes} ${period}`;
  }

  function getAvailableTimeSlots() {
    if (!isEmpty(availabilities[selectedDay])) {
      const timeSlotButtons = availabilities[selectedDay].map(timeSlot => {
        return (
          <Button
            key={timeSlot}
            buttonRef={null}
            color="alpha"
            isLoaded
            onClick={() =>
              handleTimeButtonClick(timeSlot, convertTimeSlotToTime(timeSlot))
            }
            size="md"
            isFullWidth
            text={convertTimeSlotToTime(timeSlot)}
            titleStyle={{}}
            type={
              selectedTimeSlot === timeSlot ? 'secondaryWithBorder' : 'white'
            }
          />
        );
      });
      if (showAllTimeSlots) {
        return timeSlotButtons;
      }
      return timeSlotButtons.splice(0, 15);
    }
    return null;
  }

  function handleDateButtonClick(dateDay) {
    setSelectedDate(dateDay.momentDate);
    setSelectedDay(dateDay.day.toString());
    setSelectedTime(null);
    setSelectedTimeSlot(null);
  }

  function getThisWeekDates(mobile) {
    const weekDates = [];

    for (let i = 0; i <= 6; i += 1) {
      weekDates.push({
        momentDate: moment()
          .add(currentWeekOffset, 'w')
          .day(i),
        day: i
      });
    }

    return weekDates.map(dateDay => {
      return (
        <Button
          key={dateDay.day}
          buttonClass={`${
            mobile ? '' : 'lg:tw-text-xs xl:tw-text-sm xs:tw-h-20 md:tw-h-24'
          }`}
          buttonStyle={{
            fontFamily: 'Menlo, Monaco, monospace, sans-serif'
          }}
          containerStyle={mobile ? { marginBottom: '20px' } : {}}
          color={
            isEmpty(availabilities[dateDay.day.toString()]) ? 'gray' : 'alpha'
          }
          disabled={isEmpty(availabilities[dateDay.day.toString()])}
          disabledStyle={{
            backgroundColor: '#d2d6dc',
            textDecoration: 'line-through'
          }}
          isLoaded
          onClick={() => handleDateButtonClick(dateDay)}
          size={mobile ? 'w-100' : ''}
          text={`${dateDay.momentDate
            .format('ddd')
            .toString()} \n${dateDay.momentDate.format('MMM DD').toString()}`}
          type={
            (selectedDate && selectedDate.format('MMM DD').toString()) ===
            dateDay.momentDate.format('MMM DD').toString()
              ? 'secondaryWithBorder'
              : 'white'
          }
        />
      );
    });
  }

  function getWeekDatesSkeletons(mobile) {
    const weekDatesSkeleton = [];

    for (let i = 0; i <= 6; i += 1) {
      if (mobile) {
        weekDatesSkeleton.push(<Skeleton key={`weekDate-${i}`} height={50} />);
      } else {
        weekDatesSkeleton.push(
          <Skeleton key={`weekDate-${i}`} width={80} height={80} />
        );
      }
    }
    return weekDatesSkeleton;
  }

  function getAvailableTimeSlotsSkeletons() {
    const availableTimeSlotsSkeletons = [];

    for (let i = 0; i <= 7; i += 1) {
      availableTimeSlotsSkeletons.push(
        <span key={`avlTime-${i}`} className="tw-mb-1 tw-mr-1">
          <Skeleton key={`avlTime-${i}`} height={50} />
        </span>
      );
    }
    return availableTimeSlotsSkeletons;
  }

  function showAvailableTimeSlots() {
    if (isEmpty(availabilities)) {
      return getAvailableTimeSlotsSkeletons();
    }
    if (!isEmpty(selectedDate)) {
      return getAvailableTimeSlots();
    }
    return (
      <Button
        color="gray"
        disabled
        disabledStyle={{
          backgroundColor: '#d2d6dc'
        }}
        isLoaded
        onClick={() => {
          return null;
        }}
        size="w-100"
        text="Please select a date to view available times"
        type="secondary"
      />
    );
  }

  function handleNextWeekButton() {
    setSelectedDate(null);
    setSelectedTime(null);
    setSelectedTimeSlot(null);
    dispatch(
      fetchAppointmentBooker({
        contactToken,
        accountId: currentAccount.id,
        appointmentTypeId,
        journeyId,
        week: currentWeekOffset + 1,
        appointmentId: currentAppointment && currentAppointment.id
      })
    );
  }

  function handlePreviousWeekButton() {
    setSelectedDate(null);
    setSelectedTime(null);
    setSelectedTimeSlot(null);
    if (currentWeekOffset - 1 < 0) return;
    dispatch(
      fetchAppointmentBooker({
        contactToken,
        accountId: currentAccount.id,
        appointmentTypeId,
        journeyId,
        week: currentWeekOffset - 1,
        appointmentId: currentAppointment && currentAppointment.id
      })
    );
  }

  function toggleTimeSlotsAmountShown() {
    setShowAllTimeSlots(!showAllTimeSlots);
  }

  useEffect(() => {
    setSelectedDate(null);
    setSelectedTime(null);
    dispatch(
      fetchAppointmentBooker({
        contactToken,
        accountId: currentAccount.id,
        appointmentTypeId,
        journeyId,
        currentWeekOffset,
        appointmentId: currentAppointment && currentAppointment.id
      })
    );
  }, [dispatch, appointmentTypeId]);

  return (
    <Fragment>
      <div
        className={`tw-rounded-lg tw-bg-white tw-overflow-hidden ${
          clientFacing ? 'tw-p-10' : null
        }`}
      >
        {clientFacing ? (
          <div className="tw-bg-white tw-mb-10 sm:tw-pb-8">
            <div className="tw-max-w-screen-xl tw-mx-auto tw-px-4 md:tw-px-6 lg:tw-px-8">
              <div className="tw-text-center">
                {currentAccount && currentAccount.logoUrl ? (
                  <img
                    className="tw-mx-auto tw-mb-6 tw-w-auto"
                    src={currentAccount.logoUrl}
                    alt="Logo"
                    style={{ maxHeight: '10rem' }}
                  />
                ) : null}
                <h2 className="tw-font-body tw-text-xl xs:tw-text-2xl tw-leading-9 tw-font-bold md:tw-text-2xl sm:tw-leading-10 lg:tw-text-3xl lg:tw-leading-none">
                  {!isEmpty(currentAppointmentType) ? (
                    `Book your ${currentAppointmentType.name} with ${currentAccount.name}`
                  ) : (
                    <Skeleton key="appointmentType-1" width={400} height={40} />
                  )}
                </h2>
              </div>
            </div>
          </div>
        ) : null}
        <div className="tw-flex tw-items-center">
          <h4 className="tw-font-body tw-flex-shrink-0 tw-pr-4 tw-bg-white tw-text-sm tw-leading-5 tw-tracking-wider tw-font-semibold tw-uppercase tw-text-gray-600 tw-mb-0">
            Pick A Date
          </h4>
          <div className="tw-font-body tw-flex-1 tw-border-t-2 tw-border-b-0 tw-border-r-0 tw-border-l-0 tw-border-solid tw-border-gray-200" />
          {!isEmpty(selectedDate) ? (
            <span className="tw-pl-4 tw-font-body tw-flex-shrink-0 tw-text-sm tw-leading-5 tw-tracking-wider tw-font-semibold tw-uppercase tw-text-alpha-600">
              {selectedDate.format('ddd, MMM DD').toString()}
            </span>
          ) : null}
        </div>
        <div className="tw-flex tw-justify-between tw-items-center tw-mt-10 tw-mx-auto xs:tw-hidden md:tw-flex">
          <span>
            <svg
              className="tw-text-gray-400 hover:tw-text-gray-600 tw-cursor-pointer tw-w-6 tw-h-6"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="4"
              viewBox="0 0 24 24"
              stroke="currentColor"
              onClick={handlePreviousWeekButton}
            >
              <path d="M15 19l-7-7 7-7" />
            </svg>
          </span>
          <div className="tw-grid tw-grid-cols-7 tw-gap-1">
            {!isEmpty(availabilities)
              ? getThisWeekDates(false)
              : getWeekDatesSkeletons(false)}
          </div>
          <span>
            <svg
              className="tw-text-gray-400 hover:tw-text-gray-600 tw-cursor-pointer tw-w-6 tw-h-6"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="4"
              viewBox="0 0 24 24"
              stroke="currentColor"
              onClick={handleNextWeekButton}
            >
              <path d="M9 5l7 7-7 7" />
            </svg>
          </span>
        </div>
        <div className="tw-flex tw-flex-col tw-justify-between tw-items-center tw-mt-10 tw-mx-auto xs:tw-flex md:tw-hidden">
          <span>
            <svg
              className="tw-text-gray-400 hover:tw-text-gray-600 tw-cursor-pointer tw-w-10 tw-h-10"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="4"
              viewBox="0 0 24 24"
              stroke="currentColor"
              onClick={handlePreviousWeekButton}
              transform="rotate(90)"
            >
              <path d="M15 19l-7-7 7-7" />
            </svg>
          </span>
          {!isEmpty(availabilities)
            ? getThisWeekDates(true)
            : getWeekDatesSkeletons(true)}
          <span>
            <svg
              className="tw-text-gray-400 hover:tw-text-gray-600 tw-cursor-pointer tw-w-10 tw-h-10"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="4"
              viewBox="0 0 24 24"
              stroke="currentColor"
              onClick={handleNextWeekButton}
              transform="rotate(90)"
            >
              <path d="M9 5l7 7-7 7" />
            </svg>
          </span>
        </div>
        <div className="tw-flex tw-items-center tw-pt-10">
          <h4 className="tw-font-body tw-flex-shrink-0 tw-pr-4 tw-bg-white tw-text-sm tw-leading-5 tw-tracking-wider tw-font-semibold tw-uppercase tw-text-gray-600 tw-mb-0">
            Pick A Time
          </h4>
          <div className="tw-font-body tw-flex-1 tw-border-t-2 tw-border-b-0 tw-border-r-0 tw-border-l-0 tw-border-solid tw-border-gray-200" />
          {!isEmpty(selectedTime) ? (
            <span className="tw-pl-4 tw-font-body tw-flex-shrink-0 tw-text-sm tw-leading-5 tw-tracking-wider tw-font-semibold tw-uppercase tw-text-alpha-600">
              {selectedTime}
            </span>
          ) : null}
        </div>
        <div className="tw-w-100">
          <div
            className={`${
              !isEmpty(selectedDate)
                ? 'tw-grid tw-grid-cols-1 sm:tw-grid-cols-3 md:tw-grid-cols-4 lg:tw-grid-cols-5 tw-gap-3'
                : 'tw-text-center'
            } tw-mt-10 tw-mb-5`}
          >
            {showAvailableTimeSlots()}
          </div>
          {!isEmpty(availabilities) &&
            selectedDate &&
            getAvailableTimeSlots().length > 14 && (
              <a
                href="#"
                onClick={toggleTimeSlotsAmountShown}
                className="tw-text-alpha-600 tw-cursor-pointer"
              >
                {showAllTimeSlots
                  ? '- Show less available times'
                  : '+ Show more available times'}
              </a>
            )}
        </div>
        {currentAppointmentType &&
        currentAppointmentType.has_booking_form &&
        !isEmpty(currentBookingForm) ? (
          <Fragment>
            <div className="tw-flex tw-items-center tw-pt-10">
              <h4 className="tw-font-body tw-flex-shrink-0 tw-pr-4 tw-bg-white tw-text-sm tw-leading-5 tw-tracking-wider tw-font-semibold tw-uppercase tw-text-gray-600 tw-mb-0">
                Additional Information
              </h4>
              <div className="tw-font-body tw-flex-1 tw-border-t-2 tw-border-b-0 tw-border-r-0 tw-border-l-0 tw-border-solid tw-border-gray-200" />
            </div>
            <div className="tw-mt-10">
              {currentBookingForm &&
              currentBookingForm.fields &&
              currentBookingForm.fields.length
                ? currentBookingForm.fields
                    .slice()
                    .sort((a, b) => a.position - b.position)
                    .map(field => {
                      return (
                        <div key={field.id} className="tw-mb-8">
                          {field.field_type === 'guest_waiver' && (
                            <div>{currentAccount.waiver}</div>
                          )}
                          <FormField
                            field={field}
                            formik={formik}
                            widthClass="tw-max-w-full"
                          />
                        </div>
                      );
                    })
                : null}
            </div>
          </Fragment>
        ) : null}
        <div className="tw-float-right tw-mt-10 xs:tw-hidden md:tw-block">
          <Button
            isLoaded
            onClick={formik.handleSubmit}
            disabled={isEmpty(selectedTimeSlot) || isEmpty(selectedDate)}
            size="xl"
            text="Book It!"
          />
        </div>
        <div className="tw-mt-20 xs:tw-block md:tw-hidden">
          <Button
            isLoaded={!isCreatingAppointment}
            onClick={formik.handleSubmit}
            disabled={isEmpty(selectedTimeSlot) || isEmpty(selectedDate)}
            size="w-100"
            text="Book It!"
          />
        </div>
      </div>
      <Notification
        message={error}
        show={showCreateAppointmentError}
        setShowAction={setShowCreateAppointmentError}
        type="colored"
        headerText="Error!"
        color="error"
        HeaderIcon={color => {
          return (
            <svg
              className={`tw-h-5 tw-w-5 tw-text-${color}-400`}
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                clipRule="evenodd"
              />
            </svg>
          );
        }}
      />
    </Fragment>
  );
};

AppointmentBooker.defaultProps = {
  contactTokenProp: null,
  journeyIdProp: null,
  appointmentTypeIdProp: null,
  clientFacing: true
};

AppointmentBooker.propTypes = {
  contactTokenProp: PropTypes.string,
  journeyIdProp: PropTypes.number,
  appointmentTypeIdProp: PropTypes.number,
  clientFacing: PropTypes.bool
};

export default AppointmentBooker;
