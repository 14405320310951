import React, { useEffect, useState, useCallback, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useHistory, Redirect } from 'react-router-dom';
import {
  createLoadedSelector,
  createErrorMessageSelector
} from 'appState/selectors';
import isEmpty from 'lodash.isempty';
import {
  initializeSyncWizard,
  matchedContactsFetch,
  membersSync,
  updateExactMatchData,
  updateNameMatchData,
  updateEmailMatchData,
  updateNewUplaunchData,
  updateCampaignMatchData,
  updateNewZenPlannerData,
  initializeCampaignData,
  initilaizeNewContactsData,
  fetchMode,
  fetchSyncStatus,
  stopCheckSyncStatusWatcher,
  startCheckSyncStatusWatcher,
  switchOffTestMode,
  subscriptionFetch
} from 'appState/actions/ActionCreators';
import PageHeader from 'components/Theme/PageHeader';
import Banner from 'components/Theme/Banner';
import Card from 'components/Theme/Card';
import Modal from 'components/Theme/Modal';
import Notification from 'components/Theme/Notification';
import ProgressBar from 'components/Theme/ProgressBar';
import Footer from 'components/SyncWizard/shared/Footer';
import ExactMatch from './SyncContent/ExactMatch';
import PossibleMatch from './SyncContent/PossibleMatch';
import AddContacts from './SyncContent/AddContacts';
import MatchCampaign from './SyncContent/MatchCampaign';
import ReviewPage from './SyncContent/ReviewPage';
import OverviewPage from './SyncContent/OverviewPage';

const stepsArr = [
  {
    text: 'Exact Matches'
  },
  {
    text: 'Match Contacts'
  },
  {
    text: 'Select Campaigns'
  },
  {
    text: 'Add Contacts'
  },
  {
    text: 'Review & Sync'
  }
];

const contactMatchloadedSelector = createLoadedSelector([
  'sync-wizard/MATCHED_CONTACTS_FETCH'
]);
const loadedSelector = createLoadedSelector(['sync-wizard/FETCH_SYNC_STATUS']);
const errorSelector = createErrorMessageSelector([
  'sync-wizard/FETCH_SYNC_STATUS'
]);

const switchTestModeOffLoaded = createLoadedSelector([
  'sync-wizard/SWITCH_OFF_TEST_MODE'
]);
const switchTestModeOffError = createErrorMessageSelector([
  'sync-wizard/SWITCH_OFF_TEST_MODE'
]);

const structuredSelector = createStructuredSelector({
  syncWizard: state => state.syncWizard,
  campaignMatchData: state => state.syncWizard.contacts.campaignMatch,
  testMode: state => state.syncWizard.testMode,
  isContactMatchLoaded: state => contactMatchloadedSelector(state),
  isLoaded: state => loadedSelector(state),
  error: state => errorSelector(state),
  syncingStatus: state => state.syncWizard.syncingStatus,
  isSwitchOffTestModeLoaded: state => switchTestModeOffLoaded(state),
  errorInTestMode: state => switchTestModeOffError(state),
  currentSubscription: state => state.subscription.currentSubscription,
  currentAccount: state => state.syncWizard.currentAccount
});

const Wizard = () => {
  const [showModal, setShowModal] = useState(false);
  const {
    syncWizard,
    campaignMatchData,
    testMode,
    isLoaded,
    isContactMatchLoaded,
    error,
    syncingStatus,
    isSwitchOffTestModeLoaded,
    errorInTestMode,
    currentSubscription,
    currentAccount
  } = useSelector(structuredSelector);

  const [stepCount, setStepCount] = useState(0);
  const dispatch = useDispatch();
  const history = useHistory();
  const [isSticky, setSticky] = useState(false);
  const [showError, setShowError] = useState(false);
  const [currentDisplayTable, setCurrentDisplayTable] = useState(false);
  const [displayUplaunchTable, setDisplayUplaunchTable] = useState(true);
  const [showTestModeModal, setShowTestModeModal] = useState(true);
  const [showDefaultProductBanner, setShowDefaultProductBanner] = useState(
    false
  );
  const [
    showTestModeOffConfirmationModal,
    setShowTestModeOffConfirmationModal
  ] = useState(false);

  useEffect(() => {
    dispatch(fetchMode());
  }, [dispatch]);

  useEffect(() => {
    setShowTestModeModal(testMode);
  }, [testMode]);

  useEffect(() => {
    dispatch(fetchSyncStatus());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(currentAccount)) {
      if (isEmpty(currentSubscription)) {
        dispatch(
          subscriptionFetch({ subscriptionId: currentAccount.subscription_id })
        );
      } else {
        setShowDefaultProductBanner(!currentSubscription.default_products_set);
      }
    }
  }, [dispatch, currentAccount, currentSubscription]);

  useEffect(() => {
    if (isEmpty(error)) {
      setShowError(false);
    } else {
      setShowError(true);
    }
  }, [error, dispatch]);

  useEffect(() => {
    if (syncingStatus === 'fetched_data') {
      dispatch(stopCheckSyncStatusWatcher());
      dispatch(matchedContactsFetch());
    }

    if (
      syncingStatus === 'fetching_data' ||
      syncingStatus === null ||
      syncingStatus === 'required'
    )
      dispatch(startCheckSyncStatusWatcher());

    return () => {
      dispatch(stopCheckSyncStatusWatcher());
    };
  }, [syncingStatus, dispatch]);

  useEffect(() => {
    if (syncingStatus !== 'complete') {
      dispatch(initializeSyncWizard());
    }
  }, [syncingStatus, dispatch]);

  const initializeCampaignMatch = () => {
    dispatch(initializeCampaignData());
  };

  const initializeAddContacts = () => {
    dispatch(initilaizeNewContactsData());
  };

  const handleSyncProcess = () => {
    dispatch(membersSync({ contacts: syncWizard.contacts, history }));
  };

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 372) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);

  const navigateWizard = stepNumber => {
    if (stepNumber === 3) {
      initializeCampaignMatch();
    } else if (stepNumber === 4) {
      initializeAddContacts();
    } else if (stepNumber === 6) {
      setShowModal(true);
      return;
    }
    setStepCount(stepNumber);
  };

  function handleModalSetShow(show) {
    setShowModal(show);
  }

  let nextButtonText;
  let actionBarDisabled = false;

  if (stepCount > 0) {
    if (stepCount === 5) {
      nextButtonText = 'Sync my contacts!';
    } else {
      nextButtonText = 'Continue';
    }
  } else {
    nextButtonText = 'Start';
  }

  const updateExactMatchContacts = useCallback(
    exactMatchData => {
      dispatch(updateExactMatchData({ exactMatchData }));
    },
    [dispatch]
  );

  const updateNameMatchContacts = useCallback(
    nameMatchData => {
      dispatch(updateNameMatchData({ nameMatchData }));
    },
    [dispatch]
  );

  const updateEmailMatchContacts = useCallback(
    emailMatchData => {
      dispatch(updateEmailMatchData({ emailMatchData }));
    },
    [dispatch]
  );

  const updateNewUplaunchContacts = newToUplaunchData => {
    dispatch(updateNewUplaunchData({ newToUplaunchData }));
  };

  const updateMatchCampaignContacts = matchCampaignData => {
    dispatch(updateCampaignMatchData({ matchCampaignData }));
  };

  const updateNewZenPlannerContacts = newToZenPlannerData => {
    dispatch(updateNewZenPlannerData({ newToZenPlannerData }));
  };

  const toggleMatchTable = event => {
    if (event && event.code && event.code !== 'Enter') return;
    setCurrentDisplayTable(!currentDisplayTable);
  };

  const toggleContactsTable = event => {
    if (event && event.code && event.code !== 'Enter') return;
    setDisplayUplaunchTable(!displayUplaunchTable);
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const getPrimaryAction = () => {
    scrollToTop();
    // #TODO: UnComment the lines after ZP is ready for NewContacts
    // if (stepCount === 4 && displayUplaunchTable)
    //   toggleContactsTable();
    // else
    // #TODO: ENDS
    if (stepCount === 2 && !currentDisplayTable) {
      toggleMatchTable();
    } else {
      navigateWizard(stepCount + 1);
    }
  };

  const getSecondaryAction = () => {
    scrollToTop();
    // #TODO: UnComment the lines after ZP is ready for NewContacts
    // if (stepCount === 4 && !displayUplaunchTable)
    //   toggleContactsTable();
    // else
    // #TODO: ENDS
    if (stepCount === 2 && currentDisplayTable) {
      toggleMatchTable();
    } else {
      navigateWizard(stepCount - 1);
    }
  };

  const getToggleValue = (updatedDataIndex, btnRef, row, btnState) => {
    if (updatedDataIndex === btnRef.parentIndex) {
      if (row.id === btnRef.row.id) {
        return btnState;
      }

      return false;
    }
    if (row.id === btnRef.row.id) {
      return false;
    }

    return row.connect;
  };

  const getContactDataForStep = useCallback(() => {
    if (stepCount === 1) {
      return syncWizard.contacts.exactMatch.slice(0);
    }
    if (stepCount === 2 && !currentDisplayTable) {
      return syncWizard.contacts.possibleMatchWithSameEmail.slice(0);
    }
    if (stepCount === 2 && currentDisplayTable) {
      return syncWizard.contacts.possibleMatchWithSameName.slice(0);
    }
    return null;
  }, [
    stepCount,
    currentDisplayTable,
    syncWizard.contacts.exactMatch,
    syncWizard.contacts.possibleMatchWithSameEmail,
    syncWizard.contacts.possibleMatchWithSameName
  ]);

  const callUpdateEventForContact = useCallback(
    data => {
      if (stepCount === 1) {
        updateExactMatchContacts(data);
      } else if (stepCount === 2 && !currentDisplayTable) {
        updateEmailMatchContacts(data);
      } else if (stepCount === 2 && currentDisplayTable) {
        updateNameMatchContacts(data);
      }
    },
    [
      stepCount,
      currentDisplayTable,
      updateExactMatchContacts,
      updateEmailMatchContacts,
      updateNameMatchContacts
    ]
  );

  let updatedData;
  useEffect(() => {
    callUpdateEventForContact();
  }, [updatedData]);

  const updateToggleBtnState = (btnRef, btnState) => {
    updatedData = getContactDataForStep();
    const tableRows = updatedData.filter(row =>
      row.find(ele => ele.id === btnRef.row.id)
    );
    tableRows.map(tableRow => {
      const updatedDataIndex = updatedData.indexOf(tableRow);
      updatedData[updatedDataIndex] = tableRow.map((row, index) => {
        return {
          ...tableRow[index],
          connect: getToggleValue(updatedDataIndex, btnRef, row, btnState)
        };
      });
      return null;
    });
    callUpdateEventForContact(updatedData);
  };

  if (stepCount === 6) {
    actionBarDisabled = true;
  }

  const syncWizardComponents = [
    <OverviewPage key={`sync-${stepCount}`} contactsStats={syncWizard} />,
    <ExactMatch
      key={`sync-${stepCount}`}
      syncData={syncWizard}
      onClick={updateToggleBtnState}
      isSticky={isSticky}
    />,
    <PossibleMatch
      key={`sync-${stepCount}`}
      syncData={syncWizard}
      updateEvent={updateToggleBtnState}
      updateEmailEvent={updateEmailMatchContacts}
      toggleMatchTable={toggleMatchTable}
      currentDisplayTable={currentDisplayTable}
      isSticky={isSticky}
    />,
    <MatchCampaign
      key={`sync-${stepCount}`}
      campaignMatchSyncData={campaignMatchData}
      updateCampaignEvent={updateMatchCampaignContacts}
      isSticky={isSticky}
    />,
    <AddContacts
      key={`sync-${stepCount}`}
      syncData={syncWizard}
      updateUplaunchEvent={updateNewUplaunchContacts}
      updateZenPlannerEvent={updateNewZenPlannerContacts}
      toggleContactsTable={toggleContactsTable}
      displayUplaunchTable={displayUplaunchTable}
      isSticky={isSticky}
    />,
    <ReviewPage
      key={`sync-${stepCount}`}
      contactsStats={syncWizard}
      testMode={testMode}
    />
  ];

  useEffect(() => {
    if (isEmpty(errorInTestMode)) {
      setShowError(false);
    } else {
      setShowError(true);
    }
  }, [errorInTestMode, isSwitchOffTestModeLoaded, dispatch]);

  if (
    isLoaded &&
    (syncingStatus === 'completed' || syncingStatus === 'in_progress')
  ) {
    return <Redirect to="/ui/sync-wizard/stats" />;
  }

  function turnOffTestMode() {
    dispatch(switchOffTestMode());
  }

  return (
    <div className="tw-overflow-hidden">
      {showDefaultProductBanner && (
        <Banner
          color="warning"
          announcement="Please set default products before syncing your contacts with Zen Planner in 'My Products' section."
          smallAnnouncement="Please set default products before syncing."
          showDismissBtn={false}
        />
      )}
      <PageHeader
        color="gray"
        title={`Zen Planner Sync ${testMode ? '(Test Mode)' : ''}`}
        backgroundColor={`${testMode ? 'dark' : 'white'}`}
        primaryAction={
          testMode ? () => setShowTestModeOffConfirmationModal(true) : null
        }
        primaryActionText={testMode ? 'Turn Off Test Mode' : null}
      />
      <div className="tw-mx-7 tw-mt-7 tw-mb-24">
        {stepCount === 0 && (
          <OverviewPage key={`sync-${stepCount}`} contactsStats={syncWizard} />
        )}
        {isContactMatchLoaded && isLoaded && (
          <div>
            {stepCount > 0 && stepCount <= 5 && (
              <Fragment>
                <ProgressBar
                  completed={stepCount}
                  steps={stepsArr}
                  fillerColor="black"
                  className="tw--mr-10 tw-ml-auto tw-mb-5"
                />
                <Card containerClass="tw-bg-white tw-rounded-md">
                  {syncWizardComponents[stepCount]}
                </Card>
              </Fragment>
            )}

            <Footer
              primaryActionText={nextButtonText}
              primaryAction={getPrimaryAction}
              showSecondaryAction={stepCount > 0}
              secondaryActionText="Back"
              secondaryAction={getSecondaryAction}
              disableButton={
                actionBarDisabled ||
                !isEmpty(error) ||
                !currentSubscription.default_products_set
              }
            />
          </div>
        )}
        <Modal
          headerText="Are you ready to sync your contacts?"
          bodyText="We are now ready to sync your contacts! After you click the “Sync my contacts!” button below, the process will begin. This process will take place in the background and could take up to 5 minutes."
          primaryAction={handleSyncProcess}
          primaryActionText="OK"
          showSecondaryAction
          secondaryActionText="Cancel"
          color="alpha"
          secondaryAction={handleModalSetShow}
          show={showModal}
          setShow={handleModalSetShow}
          showHeaderIcon={false}
        />
        <Notification
          HeaderIcon={color => {
            return (
              <svg
                className={`tw-h-5 tw-w-5 tw-text-${color}-400`}
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                  clipRule="evenodd"
                />
              </svg>
            );
          }}
          color="error"
          headerText="Error!"
          message={error}
          setShowAction={setShowError}
          show={showError}
          timeout={0}
          type="colored"
        />
      </div>
      <Notification
        HeaderIcon={color => {
          return (
            <svg
              className={`tw-h-5 tw-w-5 tw-text-${color}-400`}
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                clipRule="evenodd"
              />
            </svg>
          );
        }}
        color="error"
        headerText="Error!"
        message={errorInTestMode}
        setShowAction={setShowError}
        show={showError}
        timeout={0}
        type="colored"
      />
      <Modal
        headerText="Test Mode ON"
        bodyText="The Sync Wizard starts in test mode so you can review your data before making any actual changes to your contacts. Turn off test mode at any time in the top right corner."
        primaryAction={() => setShowTestModeModal(false)}
        primaryActionText="OK"
        showPrimaryAction
        showSecondaryAction={false}
        color="alpha"
        show={showTestModeModal}
        setShow={setShowTestModeModal}
        showHeaderIcon
        HeaderIcon={() => {
          return (
            <svg
              className="tw-w-6 tw-h-6 tw-text-alpha-600"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          );
        }}
      />
      <Modal
        headerText="Turn OFF Test Mode?"
        bodyText='When you click "Sync my contact!" on Step 5, the integration will go live in your account.'
        primaryAction={turnOffTestMode}
        primaryActionText="Turn Off"
        showPrimaryAction
        showSecondaryAction
        secondaryAction={() => setShowTestModeOffConfirmationModal(false)}
        color="alpha"
        show={showTestModeOffConfirmationModal}
        setShow={setShowTestModeOffConfirmationModal}
        showHeaderIcon
        HeaderIcon={() => {
          return (
            <svg
              className="tw-w-6 tw-h-6 tw-text-alpha-600"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          );
        }}
      />
    </div>
  );
};

export default Wizard;
