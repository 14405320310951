import iconNames from 'components/Theme/HeroIcon/iconNames';
import PropTypes from 'prop-types';

const propTypes = {
  onClick: PropTypes.func,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  filled: PropTypes.bool,
  pathDefinition: PropTypes.string,
  tint: PropTypes.oneOf([
    '100',
    '200',
    '300',
    '400',
    '500',
    '600',
    '700',
    '800',
    '900',
    'base'
  ]),
  color: PropTypes.oneOf([
    'alpha',
    'bravo',
    'charlie',
    'success',
    'warning',
    'error',
    'gray',
    'white'
  ]),
  IconComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  icon: PropTypes.oneOf(Object.keys(iconNames)),
  strokeWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default propTypes;
