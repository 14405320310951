// linting rules disabled for immer js
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  FETCH_SUBSCRIPTIONS_SUCCESS,
  FETCH_SUBSCRIPTION_SUCCESS,
  FETCH_PRODUCTS_SUCCESS,
  UPDATE_SUBSCRIPTION_SUCCESS,
  PAGE_CHANGE_SUBSCRIPTIONS_SUCCESS,
  PAGE_CHANGE_SUBSCRIPTIONS_REQUEST,
  FETCH_MY_SUBSCRIPTION_SUCCESS,
  DOWNGRADE_SUBSCRIPTION_SUCCESS,
  UPGRADE_SUBSCRIPTION_SUCCESS,
  UPDATE_DEFAULT_PRODUCT_SUCCESS,
  CANCEL_DOWNGRADE_SUBSCRIPTION_SUCCESS,
  CURRENT_SUBSCRIPTIONS_RESET,
  UNDO_CANCELLATION_SUBSCRIPTION_SUCCESS
} from 'appState/actions/constants/subscription.actions';
import { RESET_CURRENT_PLAN } from 'appState/actions/constants/plan.actions';

const initialState = {
  subscriptions: [],
  currentSubscription: {},
  products: [],
  isChangingPage: false,
  total: 0,
  totalPages: 1,
  mySubscription: {},
  productSubscriptions: [],
  myProducts: []
};

const LIMIT = 20;

export default function SubscriptionReducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case FETCH_SUBSCRIPTIONS_SUCCESS: {
        const { subscriptions, total } = action.payload;
        const totalPages = Math.ceil(total / LIMIT);
        draft.subscriptions = subscriptions;
        draft.totalPages = totalPages;
        draft.total = total;
        return;
      }
      case FETCH_SUBSCRIPTION_SUCCESS: {
        const { currentSubscription } = action.payload;
        draft.currentSubscription = currentSubscription;
        return;
      }
      case FETCH_PRODUCTS_SUCCESS: {
        const { products } = action.payload;
        draft.products = products;
        return;
      }
      case UPDATE_SUBSCRIPTION_SUCCESS: {
        const { currentSubscription } = action.payload;
        draft.currentSubscription = currentSubscription;
        return;
      }
      case PAGE_CHANGE_SUBSCRIPTIONS_SUCCESS: {
        const { subscriptions, total } = action.payload;
        const totalPages = Math.ceil(total / LIMIT);
        draft.subscriptions = subscriptions;
        draft.total = total;
        draft.isChangingPage = false;
        draft.totalPages = totalPages;
        return;
      }
      case PAGE_CHANGE_SUBSCRIPTIONS_REQUEST: {
        const { page } = action.payload;
        draft.page = page;
        draft.isChangingPage = true;
        return;
      }
      case RESET_CURRENT_PLAN: {
        draft.subscriptions = [];
        draft.currentSubscription = {};
        draft.products = [];
        draft.isChangingPage = false;
        draft.total = 0;
        draft.totalPages = 1;
        return;
      }
      case FETCH_MY_SUBSCRIPTION_SUCCESS: {
        const { mySubscription } = action.payload;
        draft.mySubscription = mySubscription;
        draft.productSubscriptions = mySubscription.product_subscriptions;
        draft.myProducts = mySubscription.products;
        return;
      }
      case UPDATE_DEFAULT_PRODUCT_SUCCESS: {
        const { mySubscription } = action.payload;
        draft.mySubscription = mySubscription;
        draft.productSubscriptions = mySubscription.product_subscriptions;
        draft.myProducts = mySubscription.products;
        return;
      }
      case DOWNGRADE_SUBSCRIPTION_SUCCESS: {
        const { mySubscription } = action.payload;
        draft.mySubscription = mySubscription;
        draft.productSubscriptions = mySubscription.product_subscriptions;
        draft.myProducts = mySubscription.products;
        return;
      }
      case UPGRADE_SUBSCRIPTION_SUCCESS: {
        const { mySubscription } = action.payload;
        draft.mySubscription = mySubscription;
        return;
      }
      case CANCEL_DOWNGRADE_SUBSCRIPTION_SUCCESS: {
        const { mySubscription } = action.payload;
        draft.mySubscription = mySubscription;
        return;
      }
      case CURRENT_SUBSCRIPTIONS_RESET: {
        draft.subscriptions = [];
        return;
      }
      case UNDO_CANCELLATION_SUBSCRIPTION_SUCCESS: {
        const { mySubscription } = action.payload;
        draft.mySubscription = mySubscription;
        return;
      }
      default:
        return draft;
    }
  });
}
