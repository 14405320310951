import React, { useEffect, useCallback, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Skeleton from 'react-loading-skeleton';
import isEmpty from 'lodash.isempty';
import {
  createLoadedSelector,
  createErrorMessageSelector
} from 'appState/selectors';
import {
  fetchCurrentContactCta,
  setCurrentContactTab,
  fetchNextCampaignTouchpoint,
  contactFormUpdate,
  resetIsLoaded
} from 'appState/actions/ActionCreators';
import {
  FETCH_CURRENT_CONTACT_CTA,
  CREATE_AUTOMATION,
  PAUSE_AUTOMATION,
  UNPAUSE_AUTOMATION
} from 'appState/actions/constants/contact.actions';
import { UPDATE_CONTACT_FORM } from 'appState/actions/constants/contactForm.actions';
import Button from 'components/Theme/Button';
import TaskCompleteModal from 'components/Theme/ActivityFeed/TaskCompleteModal';
import FormPreviewModal from 'components/Theme/ActivityFeed/FormPreviewModal';
import HeroIcon from 'components/Theme/HeroIcon';
import NextCampaignTouchpointModal from './NextCampaignTouchpointModal';

const CallToAction = ({ setShowAddAutomationModal }) => {
  const dispatch = useDispatch();
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [showFormPreview, setShowFormPreview] = useState(false);
  const [showNextTouchpointModal, setShowNextTouchpointModal] = useState(false);

  const ctaLoadedSelector = createLoadedSelector([FETCH_CURRENT_CONTACT_CTA]);
  const shouldCallToActionUpdateSelector = createLoadedSelector([
    UPDATE_CONTACT_FORM,
    CREATE_AUTOMATION,
    PAUSE_AUTOMATION,
    UNPAUSE_AUTOMATION
  ]);
  const shouldCallToActionUpdateErrorSelector = createErrorMessageSelector([
    UPDATE_CONTACT_FORM,
    CREATE_AUTOMATION,
    PAUSE_AUTOMATION,
    UNPAUSE_AUTOMATION
  ]);

  const taskActivitySelector = state => {
    return state.activity.activities.find(
      activity =>
        activity.sourceable_type === 'Task' &&
        activity.sourceable_id === state.contact.currentContactCta.object_id
    );
  };

  const structuredSelector = createStructuredSelector({
    currentContact: state => state.contact.currentContact,
    currentContactCta: state => state.contact.currentContactCta,
    nextCampaignTouchpoint: state => state.contact.nextCampaignTouchpoint,
    isContactCtaLoaded: state => ctaLoadedSelector(state),
    shouldCallToActionUpdate: state => shouldCallToActionUpdateSelector(state),
    shouldCallToActionErrors: state =>
      shouldCallToActionUpdateErrorSelector(state),
    taskActivity: state => taskActivitySelector(state)
  });

  const {
    currentContact,
    currentContactCta,
    nextCampaignTouchpoint,
    isContactCtaLoaded,
    shouldCallToActionUpdate,
    shouldCallToActionErrors,
    taskActivity
  } = useSelector(structuredSelector);

  const fetchContactCta = useCallback(() => {
    dispatch(
      fetchCurrentContactCta({
        contactId: currentContact.id
      })
    );
  }, [dispatch, currentContact]);

  useEffect(() => {
    if (
      currentContactCta?.cta_type === 'next.campaign.touchpoint' &&
      !isEmpty(currentContact)
    ) {
      dispatch(
        fetchNextCampaignTouchpoint({
          contactId: currentContact.id
        })
      );
    }
  }, [dispatch, currentContactCta, currentContact]);

  useEffect(() => {
    if (!isEmpty(currentContact)) fetchContactCta();
  }, [currentContact, fetchContactCta]);

  useEffect(() => {
    if (
      !isEmpty(currentContact) &&
      shouldCallToActionUpdate &&
      isEmpty(shouldCallToActionErrors)
    ) {
      fetchContactCta();
    }
    [
      UPDATE_CONTACT_FORM,
      CREATE_AUTOMATION,
      FETCH_CURRENT_CONTACT_CTA,
      PAUSE_AUTOMATION,
      UNPAUSE_AUTOMATION
    ].forEach(action => {
      dispatch(resetIsLoaded(action));
    });

    return () => {
      [
        UPDATE_CONTACT_FORM,
        CREATE_AUTOMATION,
        FETCH_CURRENT_CONTACT_CTA,
        PAUSE_AUTOMATION,
        UNPAUSE_AUTOMATION
      ].forEach(action => {
        dispatch(resetIsLoaded(action));
      });
    };
  }, [
    dispatch,
    currentContact,
    fetchContactCta,
    shouldCallToActionUpdate,
    shouldCallToActionErrors
  ]);

  const handleCtaClick = () => {
    switch (currentContactCta.cta_type) {
      case 'unread.sms': {
        // Send SMS
        return dispatch(setCurrentContactTab('sms'));
      }
      case 'completed.form': {
        // View form
        return setShowFormPreview(true);
      }
      case 'task.due': {
        // Complete task
        return setShowTaskModal(true);
      }
      case 'task.overdue': {
        // Complete task
        return setShowTaskModal(true);
      }
      case 'recent.completed.campaign': {
        // Add campaign
        return setShowAddAutomationModal(true);
      }
      case 'next.campaign.touchpoint': {
        // Read SMS or email
        return setShowNextTouchpointModal(true);
      }
      case 'sms': {
        // Send SMS
        return dispatch(setCurrentContactTab('sms'));
      }
      case 'created': {
        // Add campaign
        return setShowAddAutomationModal(true);
      }
      case 'email': {
        // Send email
        return dispatch(setCurrentContactTab('email'));
      }
      default: {
        return null;
      }
    }
  };

  function handleFormModalClose() {
    dispatch(contactFormUpdate({ formId: currentContactCta.object_id }));
  }

  return (
    <div className="tw-rounded-md tw-p-4 tw-border-solid tw-border-2 tw-border-alpha-500">
      <div className="tw-flex">
        <div className="tw-flex-shrink-0 tw-self-center">
          <span className="tw-pt-1">
            <HeroIcon
              icon="light-bulb"
              color="gray"
              tint="700"
              height={7}
              width={7}
            />
          </span>
        </div>
        <div className="tw-ml-3 tw-flex-1 lg:tw-flex lg:tw-justify-between">
          <span className="tw-font-medium tw-text-base tw-leading-7 tw-block lg:tw-inline">
            {isContactCtaLoaded ? (
              currentContactCta.message
            ) : (
              <Skeleton height={24} width={300} />
            )}
          </span>
          {currentContactCta.action_text && (
            <div>
              {isContactCtaLoaded ? (
                <Button
                  containerClass="sm:tw-mt-2 lg:tw-mt-0"
                  disabled={false}
                  onClick={handleCtaClick}
                  size="sm"
                  text={currentContactCta.action_text}
                />
              ) : (
                <Skeleton height={30} width={60} />
              )}
            </div>
          )}
        </div>
      </div>
      {isContactCtaLoaded && (
        <Fragment>
          {['task.due', 'task.overdue'].includes(
            currentContactCta.cta_type
          ) && (
            <TaskCompleteModal
              task={taskActivity}
              show={showTaskModal}
              setShow={setShowTaskModal}
            />
          )}
          {currentContactCta.cta_type === 'completed.form' && (
            <FormPreviewModal
              formId={currentContactCta.object_id}
              show={showFormPreview}
              setShow={setShowFormPreview}
              onCloseCallback={handleFormModalClose}
            />
          )}
          {currentContactCta.cta_type === 'next.campaign.touchpoint' &&
            !isEmpty(nextCampaignTouchpoint) && (
              <NextCampaignTouchpointModal
                nextTouchpointData={nextCampaignTouchpoint}
                show={showNextTouchpointModal}
                setShow={setShowNextTouchpointModal}
              />
            )}
        </Fragment>
      )}
    </div>
  );
};

CallToAction.defaultProps = {
  setShowAddAutomationModal: () => null
};

CallToAction.propTypes = {
  setShowAddAutomationModal: PropTypes.func
};

export default CallToAction;
