import React from 'react';
import { action } from '@storybook/addon-actions';
import { withA11y } from '@storybook/addon-a11y';
import { withKnobs, text } from '@storybook/addon-knobs';
import Pagination from './index';

export default {
  component: Pagination,
  title: 'Pagination',
  decorators: [withA11y, withKnobs]
};

export const standard = () => {
  return (
    <Pagination
      total={text('Total Items', 30)}
      totalPages={text('Total Pages', 3)}
      limit={text('Per Page Limit', 10)}
      action={action('page-change-clicked')}
    />
  );
};
