/**
 * Sync Wizard
 */

/* General */
export const MATCHED_CONTACTS_FETCH_REQUEST =
  'sync-wizard/MATCHED_CONTACTS_FETCH_REQUEST';
export const MATCHED_CONTACTS_FETCH_SUCCESS =
  'sync-wizard/MATCHED_CONTACTS_FETCH_SUCCESS';
export const MATCHED_CONTACTS_FETCH_FAILURE =
  'sync-wizard/MATCHED_CONTACTS_FETCH_FAILURE';
export const MEMBERS_SYNC_REQUEST = 'sync-wizard/MEMBERS_SYNC_REQUEST';
export const MEMBERS_SYNC_SUCCESS = 'sync-wizard/MEMBERS_SYNC_SUCCESS';
export const MEMBERS_SYNC_FAILURE = 'sync-wizard/MEMBERS_SYNC_FAILURE';
export const SYNC_WIZARD_UPDATE_EXACT_MATCH_DATA =
  'sync-wizard/SYNC_WIZARD_UPDATE_EXACT_MATCH_DATA';
export const SYNC_WIZARD_UPDATE_NAME_MATCH_DATA =
  'sync-wizard/SYNC_WIZARD_UPDATE_NAME_MATCH_DATA';
export const SYNC_WIZARD_UPDATE_EMAIL_MATCH_DATA =
  'sync-wizard/SYNC_WIZARD_UPDATE_EMAIL_MATCH_DATA';
export const SYNC_WIZARD_UPDATE_CAMPAIGN_MATCH_DATA =
  'sync-wizard/SYNC_WIZARD_UPDATE_CAMPAIGN_MATCH_DATA';
export const SYNC_WIZARD_UPDATE_NEW_UPLAUNCH_DATA =
  'sync-wizard/SYNC_WIZARD_UPDATE_NEW_UPLAUNCH_DATA';
export const SYNC_WIZARD_UPDATE_NEW_ZENPLANNER_DATA =
  'sync-wizard/SYNC_WIZARD_UPDATE_NEW_ZENPLANNER_DATA';
export const SYNC_WIZARD_INITIALIZE_CAMPAIGN_DATA =
  'sync-wizard/SYNC_WIZARD_INITIALIZE_CAMPAIGN_DATA';
export const SYNC_WIZARD_INITIALIZE_NEW_CONTACTS_DATA =
  'sync-wizard/SYNC_WIZARD_INITIALIZE_NEW_CONTACTS_DATA';
export const SET_SYNC_TO_REQUIRED_REQUEST =
  'sync-wizard/SET_SYNC_TO_REQUIRED_REQUEST';
export const SET_SYNC_TO_REQUIRED_SUCCESS =
  'sync-wizard/SET_SYNC_TO_REQUIRED_SUCCESS';
export const SET_SYNC_TO_REQUIRED_FAILURE =
  'sync-wizard/SET_SYNC_TO_REQUIRED_FAILURE';
export const SYNC_WIZARD_FETCH_TEST_LOG_REQUEST =
  'sync-wizard/SYNC_WIZARD_FETCH_TEST_LOG_REQUEST';
export const SYNC_WIZARD_FETCH_TEST_LOG_SUCCESS =
  'sync-wizard/SYNC_WIZARD_FETCH_TEST_LOG_SUCCESS';
export const SYNC_WIZARD_FETCH_TEST_LOG_FAILURE =
  'sync-wizard/SYNC_WIZARD_FETCH_TEST_LOG_FAILURE';
export const FETCH_SYNC_MODE_REQUEST = 'sync-wizard/FETCH_SYNC_MODE_REQUEST';
export const FETCH_SYNC_MODE_SUCCESS = 'sync-wizard/FETCH_SYNC_MODE_SUCCESS';
export const FETCH_SYNC_MODE_FAILURE = 'sync-wizard/FETCH_SYNC_MODE_FAILURE';
export const SET_SYNC_WIZARD_ACCOUNT = 'sync-wizard/SET_SYNC_WIZARD_ACCOUNT';
export const FETCH_SYNC_STATUS_REQUEST =
  'sync-wizard/FETCH_SYNC_STATUS_REQUEST';
export const FETCH_SYNC_STATUS_SUCCESS =
  'sync-wizard/FETCH_SYNC_STATUS_SUCCESS';
export const FETCH_SYNC_STATUS_FAILURE =
  'sync-wizard/FETCH_SYNC_STATUS_FAILURE';
export const STATS_FETCH_REQUEST = 'sync-wizard/STATS_FETCH_REQUEST';
export const STATS_FETCH_SUCCESS = 'sync-wizard/STATS_FETCH_SUCCESS';
export const STATS_FETCH_FAILURE = 'sync-wizard/STATS_FETCH_FAILURE';
export const INITIALIZE_SYNC_WIZARD_REQUEST =
  'sync-wizard/INITIALIZE_SYNC_WIZARD_REQUEST';
export const INITIALIZE_SYNC_WIZARD_SUCCESS =
  'sync-wizard/INITIALIZE_SYNC_WIZARD_SUCCESS';
export const INITIALIZE_SYNC_WIZARD_FAILURE =
  'sync-wizard/INITIALIZE_SYNC_WIZARD_FAILURE';
export const LAST_SYNC_OUTPUT_LOG_REQUEST =
  'sync-wizard/LAST_SYNC_OUTPUT_LOG_REQUEST';
export const LAST_SYNC_OUTPUT_LOG_SUCCESS =
  'sync-wizard/LAST_SYNC_OUTPUT_LOG_SUCCESS';
export const LAST_SYNC_OUTPUT_LOG_FAILURE =
  'sync-wizard/LAST_SYNC_OUTPUT_LOG_FAILURE';
export const START_LAST_SYNC_OUTPUT_LOG_WATCHER =
  'sync-wizard/START_LAST_SYNC_OUTPUT_LOG_WATCHER';
export const STOP_LAST_SYNC_OUTPUT_LOG_WATCHER =
  'sync-wizard/STOP_LAST_SYNC_OUTPUT_LOG_WATCHER';
export const START_CHECK_SYNC_STATUS_WATCHER =
  'sync-wizard/START_CHECK_SYNC_STATUS_WATCHER';
export const STOP_CHECK_SYNC_STATUS_WATCHER =
  'sync-wizard/STOP_CHECK_SYNC_STATUS_WATCHER';
export const SWITCH_OFF_TEST_MODE_REQUEST =
  'sync-wizard/SWITCH_OFF_TEST_MODE_REQUEST';
export const SWITCH_OFF_TEST_MODE_SUCCESS =
  'sync-wizard/SWITCH_OFF_TEST_MODE_SUCCESS';
export const SWITCH_OFF_TEST_MODE_FAILURE =
  'sync-wizard/SWITCH_OFF_TEST_MODE_FAILURE';
