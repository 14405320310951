function uniqueArray(ar) {
  const j = {};

  ar.forEach(function(v) {
    j[`${v}::${typeof v}`] = v;
  });

  return Object.keys(j).map(function(v) {
    return j[v];
  });
}

export default uniqueArray;
