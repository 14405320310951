import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';
import TextInput from 'components/Theme/TextInput';
import Link from 'components/Theme/Link';
import Button from 'components/Theme/Button';

const TextInputsArray = ({
  id,
  value,
  labelText,
  required,
  onChange,
  showError,
  error,
  widthClass
}) => {
  const [values, setValues] = useState([{ name: '' }]);

  useEffect(() => {
    setValues(value);
  }, [value]);

  const handleChange = index => e => {
    const updatedValues = values.map((v, i) => {
      if (index !== i) return v;
      return { name: e.target.value };
    });
    setValues(updatedValues);
    onChange(e, updatedValues);
  };

  function handleAddValue() {
    const updatedValues = [...values, { name: '' }];
    setValues(updatedValues);
    onChange(null, updatedValues);
  }

  const handleRemoveValue = index => () => {
    const updatedValues = values.filter((v, i) => index !== i);
    setValues(updatedValues);
    onChange(null, updatedValues);
  };

  function getErrorMessage() {
    if (error === null) return '';
    if (typeof error === 'string') return error;
    if (!isEmpty(error)) {
      const filteredOutEmptyErrors = error.filter(e => !!e);
      return (
        filteredOutEmptyErrors[0][Object.keys(filteredOutEmptyErrors[0])[0]] ||
        filteredOutEmptyErrors[0] ||
        ''
      );
    }
    return '';
  }

  return (
    <Fragment>
      <div className={widthClass}>
        {values && values.length
          ? values.map((v, i) => {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <div key={`${v}[${i}]`} className="tw-flex">
                  <TextInput
                    id={`${id}[${i}]`}
                    value={v.name}
                    labelText={labelText}
                    onChange={handleChange(i)}
                    showError={i === values.length - 1 ? showError : false}
                    error={i === values.length - 1 ? getErrorMessage() : ''}
                    required={required}
                    hideLabel={i !== 0}
                    widthClass="tw-flex-1 tw-mr-3"
                  />
                  <div
                    className={`tw-flex-initial tw-cursor-pointer tw-flex tw-items-start tw-justify-end ${
                      i === 0 ? 'tw-mt-7' : 'tw-mt-1'
                    }`}
                  >
                    <Button
                      testId={`btn-${id}-${i}`}
                      text="Remove"
                      type="white"
                      onClick={handleRemoveValue(i)}
                    />
                  </div>
                </div>
              );
            })
          : null}
      </div>
      <div className={`${widthClass} tw-flex tw-justify-start`}>
        <Link
          text="Add Feature"
          icon="&plus;"
          iconBefore
          onClick={handleAddValue}
        />
      </div>
    </Fragment>
  );
};

TextInputsArray.defaultProps = {
  value: [],
  required: false,
  onChange: null,
  showError: false,
  error: '',
  widthClass: 'tw-col-span-12'
};

TextInputsArray.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.object),
  labelText: PropTypes.string.isRequired,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  showError: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  widthClass: PropTypes.string
};

export default TextInputsArray;
