import React from 'react';
import { action } from '@storybook/addon-actions';
import { withA11y } from '@storybook/addon-a11y';
import { withKnobs, text, select, boolean } from '@storybook/addon-knobs';
import Card from './index';

export default {
  component: Card,
  title: 'Card',
  decorators: [withA11y, withKnobs]
};

let colorSelect;

function includeColorSelect(defaultValue) {
  const label = 'Color';
  const options = [
    'alpha',
    'bravo',
    'charlie',
    'success',
    'warning',
    'error',
    'gray'
  ];
  colorSelect = select(label, options, defaultValue);
}

export const standard = () => {
  includeColorSelect('alpha');

  return (
    <div className="tw-max-w-3xl">
      <Card
        title={text('Title', 'Card Header')}
        subtitle={text('Subtitle', '')}
        showPrimaryAction={boolean('Show Primary Action', false)}
        disablePrimaryAction={boolean('Disable Primary Action', false)}
        hasDetachedActions={boolean('Detached Actions', false)}
        primaryAction={action('primary-action-click')}
        primaryActionText={text('Primary Action Text', 'OK')}
        showSecondaryAction={boolean('Show Secondary Action', false)}
        secondaryAction={action('secondary-action-click')}
        secondaryActionText={text('Secondary Action Text', 'Cancel')}
        color={colorSelect}
      >
        <p className="tw-font-body tw-py-2 tw-px-6">
          <span role="img" aria-label="wave">
            👋
          </span>{' '}
          Hey, I&apos;m a card body
        </p>
      </Card>
    </div>
  );
};

export const withFooterActions = () => {
  includeColorSelect('alpha');

  return (
    <div className="tw-max-w-3xl">
      <Card
        title={text('Title', 'Card Header')}
        subtitle={text('Subtitle', '')}
        showPrimaryAction={boolean('Show Primary Action', true)}
        disablePrimaryAction={boolean('Disable Primary Action', false)}
        hasDetachedActions={boolean('Detached Actions', false)}
        primaryAction={action('primary-action-click')}
        primaryActionText={text('Primary Action Text', 'OK')}
        showSecondaryAction={boolean('Show Secondary Action', true)}
        secondaryAction={action('secondary-action-click')}
        secondaryActionText={text('Secondary Action Text', 'Cancel')}
        color={colorSelect}
      >
        <p className="tw-font-body tw-py-2 tw-px-6">
          <span role="img" aria-label="wave">
            👋
          </span>{' '}
          Hey, I&apos;m a card body
        </p>
      </Card>
    </div>
  );
};

export const withSubtitle = () => {
  includeColorSelect('alpha');

  return (
    <div className="tw-max-w-3xl">
      <Card
        title={text('Title', 'Card Header')}
        subtitle={text('Subtitle', 'Card subtitle goes here')}
        showPrimaryAction={boolean('Show Primary Action', true)}
        disablePrimaryAction={boolean('Disable Primary Action', false)}
        hasDetachedActions={boolean('Detached Actions', false)}
        primaryAction={action('primary-action-click')}
        primaryActionText={text('Primary Action Text', 'OK')}
        showSecondaryAction={boolean('Show Secondary Action', true)}
        secondaryAction={action('secondary-action-click')}
        secondaryActionText={text('Secondary Action Text', 'Cancel')}
        color={colorSelect}
      >
        <p className="tw-font-body tw-py-2 tw-px-6">
          <span role="img" aria-label="wave">
            👋
          </span>{' '}
          Hey, I&apos;m a card body
        </p>
      </Card>
    </div>
  );
};

export const withoutSecondaryAction = () => {
  includeColorSelect('alpha');

  return (
    <div className="tw-max-w-3xl">
      <Card
        title={text('Title', 'Card Header')}
        subtitle={text('Subtitle', '')}
        showPrimaryAction={boolean('Show Primary Action', true)}
        disablePrimaryAction={boolean('Disable Primary Action', false)}
        hasDetachedActions={boolean('Detached Actions', false)}
        primaryAction={action('primary-action-click')}
        primaryActionText={text('Primary Action Text', 'OK')}
        showSecondaryAction={boolean('Show Secondary Action', false)}
        secondaryAction={action('secondary-action-click')}
        secondaryActionText={text('Secondary Action Text', 'Cancel')}
        color={colorSelect}
      >
        <p className="tw-font-body tw-py-2 tw-px-6">
          <span role="img" aria-label="wave">
            👋
          </span>{' '}
          Hey, I&apos;m a card body
        </p>
      </Card>
    </div>
  );
};

export const withAdditionalButtonAction = () => {
  includeColorSelect('alpha');

  return (
    <div className="tw-max-w-3xl">
      <Card
        title={text('Title', 'Card Header')}
        subtitle={text('Subtitle', '')}
        showPrimaryAction={boolean('Show Primary Action', true)}
        disablePrimaryAction={boolean('Disable Primary Action', false)}
        hasDetachedActions={boolean('Detached Actions', true)}
        primaryAction={action('primary-action-click')}
        primaryActionText={text('Primary Action Text', 'OK')}
        showSecondaryAction={boolean('Show Secondary Action', true)}
        secondaryAction={action('secondary-action-click')}
        secondaryActionText={text('Secondary Action Text', 'Cancel')}
        color={colorSelect}
        showTertiaryAction={boolean('Show Additional Action', true)}
        tertiaryAction={action('additional-action-click')}
        tertiaryActionText={text('Additional Action Text', 'Additional')}
        disableTertiaryAction={boolean('Disable Additional Action', false)}
      >
        <p className="tw-font-body tw-py-2 tw-px-6">
          <span role="img" aria-label="wave">
            👋
          </span>{' '}
          Hey, I&apos;m a card body
        </p>
      </Card>
    </div>
  );
};
