export const FETCH_APPOINTMENT_TYPE = 'appointment_type/FETCH_APPOINTMENT_TYPE';
export const FETCH_APPOINTMENT_TYPE_REQUEST = `${FETCH_APPOINTMENT_TYPE}_REQUEST`;
export const FETCH_APPOINTMENT_TYPE_SUCCESS = `${FETCH_APPOINTMENT_TYPE}_SUCCESS`;
export const FETCH_APPOINTMENT_TYPE_FAILURE = `${FETCH_APPOINTMENT_TYPE}_FAILURE`;

export const FETCH_ALL_APPOINTMENT_TYPE =
  'appointment_type/FETCH_ALL_APPOINTMENT_TYPE';
export const FETCH_ALL_APPOINTMENT_TYPE_REQUEST = `${FETCH_ALL_APPOINTMENT_TYPE}_REQUEST`;
export const FETCH_ALL_APPOINTMENT_TYPE_SUCCESS = `${FETCH_ALL_APPOINTMENT_TYPE}_SUCCESS`;
export const FETCH_ALL_APPOINTMENT_TYPE_FAILURE = `${FETCH_ALL_APPOINTMENT_TYPE}_FAILURE`;

export const CREATE_APPOINTMENT_TYPE =
  'appointment_type/CREATE_APPOINTMENT_TYPE';
export const CREATE_APPOINTMENT_TYPE_REQUEST = `${CREATE_APPOINTMENT_TYPE}_REQUEST`;
export const CREATE_APPOINTMENT_TYPE_SUCCESS = `${CREATE_APPOINTMENT_TYPE}_SUCCESS`;
export const CREATE_APPOINTMENT_TYPE_FAILURE = `${CREATE_APPOINTMENT_TYPE}_FAILURE`;

export const UPDATE_APPOINTMENT_TYPE =
  'appointment_type/UPDATE_APPOINTMENT_TYPE';
export const UPDATE_APPOINTMENT_TYPE_REQUEST = `${UPDATE_APPOINTMENT_TYPE}_REQUEST`;
export const UPDATE_APPOINTMENT_TYPE_SUCCESS = `${UPDATE_APPOINTMENT_TYPE}_SUCCESS`;
export const UPDATE_APPOINTMENT_TYPE_FAILURE = `${UPDATE_APPOINTMENT_TYPE}_FAILURE`;

export const DELETE_APPOINTMENT_TYPE =
  'appointment_type/DELETE_APPOINTMENT_TYPE';
export const DELETE_APPOINTMENT_TYPE_REQUEST = `${DELETE_APPOINTMENT_TYPE}_REQUEST`;
export const DELETE_APPOINTMENT_TYPE_SUCCESS = `${DELETE_APPOINTMENT_TYPE}_SUCCESS`;
export const DELETE_APPOINTMENT_TYPE_FAILURE = `${DELETE_APPOINTMENT_TYPE}_FAILURE`;
