import React from 'react';
import Icon from '../index';
import iconPropTypes from '../iconPropTypes';
import iconDefaultProps from '../iconDefaultProps';

const InformationCircleIcon = ({
  color,
  tint,
  filled,
  onClick,
  width,
  height
}) => {
  return (
    <Icon
      color={color}
      tint={tint}
      onClick={onClick}
      filled={filled}
      width={width}
      height={height}
      pathDefinition={
        filled
          ? 'M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z'
          : 'M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
      }
    />
  );
};

InformationCircleIcon.defaultProps = iconDefaultProps;
InformationCircleIcon.propTypes = iconPropTypes;

export default InformationCircleIcon;
