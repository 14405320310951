import React from 'react';
import PropTypes from 'prop-types';

const MyPlan = ({ myPlan, noOfProducts, noOfNonLeadProducts }) => {
  const remainingProducts =
    myPlan.product_limit === 'unlimited'
      ? 0
      : myPlan.product_limit - noOfNonLeadProducts;
  return (
    <div className="tw-flex tw-flex-col tw-mx-auto tw-rounded-lg tw-bg-white">
      <div className="tw--my-2 tw-py-2 sm:tw--mx-6 sm:tw-px-6 lg:tw--mx-6 lg:tw-px-6">
        <div className="tw-align-middle tw-inline-block tw-min-w-full tw-rounded-lg tw-border-b tw-border-gray-200">
          <div className="tw-px-4 tw-py-3 tw-border-b tw-border-t-0 tw-border-l-0 tw-border-r-0 tw-border-solid tw-border-gray-200 sm:tw-px-6">
            <div>
              <div>
                <h4 className="tw-font-body tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
                  MY PLAN
                </h4>
              </div>
              <div className="tw-font-medium tw-text-base">{myPlan.name}</div>
              {noOfProducts ? (
                <div className="tw-font-normal">
                  {myPlan.product_limit === 'unlimited'
                    ? `${noOfProducts} Products Added`
                    : `${remainingProducts < 0 ? 0 : remainingProducts}/${
                        myPlan.product_limit
                      } Products Remaining`}
                </div>
              ) : null}
              {!myPlan.highest_plan && (
                <a href="/ui/billing" className="tw-text-alpha-600">
                  {myPlan.partner_id
                    ? 'Show Billing Information'
                    : 'Upgrade Plan to Add More Products'}
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

MyPlan.defaultProps = {
  myPlan: {},
  noOfProducts: 0,
  noOfNonLeadProducts: 0
};

MyPlan.propTypes = {
  myPlan: PropTypes.shape({
    highest_plan: PropTypes.bool,
    product_limit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    partner_id: PropTypes.number
  }),
  noOfProducts: PropTypes.number,
  noOfNonLeadProducts: PropTypes.number
};

export default MyPlan;
