import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import Button from 'components/Theme/Button';
import isEmpty from 'lodash.isempty';

const HeaderComponent = ({
  title,
  subtitle,
  note,
  withAction,
  onClick,
  actionText,
  withSecondaryAction,
  secondaryOnClick,
  secondaryActionText,
  isLoading
}) => {
  return (
    <div>
      <div className="tw-bg-white tw-pt-4 tw-pb-2 tw-py-5 tw-border-b tw-border-solid tw-border-t-0 tw-border-r-0 tw-border-l-0 tw-border-gray-200 sm:tw-px-6 tw-rounded-t-lg">
        <div className="tw--ml-4 tw--mt-4 tw-flex tw-justify-between tw-items-center tw-flex-wrap sm:tw-flex-no-wrap">
          <div className="tw-ml-4 tw-mt-4">
            <h3 className="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
              {isLoading ? <Skeleton height={24} width={250} /> : title}
            </h3>
            {!isEmpty(subtitle) ? (
              <p className="tw-mt-1 tw-text-sm tw-leading-5 tw-text-gray-500">
                {isLoading ? <Skeleton height={14} width={450} /> : subtitle}
              </p>
            ) : null}
            {note && (
              <p className="tw-mt-1 tw-text-sm tw-leading-5 tw-text-gray-500">
                {note}
              </p>
            )}
          </div>
          {withSecondaryAction && (
            <div className="tw-ml-4 tw-mt-4 tw-flex-shrink-0" />
          )}
          {(withAction || withSecondaryAction) && (
            <div className="tw-ml-4 tw-mt-4 tw-flex-shrink-0">
              {withSecondaryAction && (
                <Button
                  text={secondaryActionText}
                  size="lg"
                  type="white"
                  onClick={secondaryOnClick}
                />
              )}
              {withAction && (
                <Button
                  text={actionText}
                  size="lg"
                  onClick={onClick}
                  containerStyle={{ marginLeft: '5px' }}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

HeaderComponent.defaultProps = {
  title: '',
  subtitle: '',
  note: '',
  actionText: '',
  withAction: false,
  onClick: () => null,
  secondaryActionText: '',
  withSecondaryAction: false,
  secondaryOnClick: () => null,
  isLoading: false
};

HeaderComponent.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  note: PropTypes.string,
  actionText: PropTypes.string,
  withAction: PropTypes.bool,
  onClick: PropTypes.func,
  secondaryActionText: PropTypes.string,
  withSecondaryAction: PropTypes.bool,
  secondaryOnClick: PropTypes.func,
  isLoading: PropTypes.bool
};

export default HeaderComponent;
