import React, { useContext, useEffect, useState, useReducer } from 'react';
import { createStructuredSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash.isempty';
import { formatTimestamp } from 'lib/utils/dateTime';
import PageHeader from 'components/Theme/PageHeader';
import { CurrentAccountContext } from 'components/shared/context/CurrentAccountContext';
import { CurrentUserContext } from 'components/shared/context/CurrentUserContext';
import {
  planAvailableForUser,
  mySubscriptionFetch,
  cancelDowngradeSubscription,
  resetIsLoaded,
  undoCancellationSubscription
} from 'appState/actions/ActionCreators';
import {
  createErrorMessageSelector,
  createLoadedSelector
} from 'appState/selectors';
import PlanDetail from 'components/PlanBilling/PlanDetail';
import Banner from 'components/Theme/Banner';
import Modal from 'components/Theme/Modal';
import Notification from 'components/Theme/Notification';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CardForm from 'components/Stripe/CardForm';
import { EnvVariablesContext } from 'components/shared/context/EnvVariablesContext';
import HeroIcon from 'components/Theme/HeroIcon';
import {
  FETCH_MY_SUBSCRIPTION,
  CANCEL_DOWNGRADE_SUBSCRIPTION,
  UNDO_CANCELLATION_SUBSCRIPTION
} from 'appState/actions/constants/subscription.actions';

const initialState = {
  notificationHeaderText: '',
  notificationMessage: '',
  notificationColor: 'success'
};

const notificationReducer = (state, action) => {
  switch (action.type) {
    case 'SUCCESS': {
      return {
        ...state,
        notificationHeaderText: 'Success!',
        notificationColor: 'success',
        notificationMessage: action.message
      };
    }
    case 'ERROR': {
      return {
        ...state,
        notificationHeaderText: 'Error!',
        notificationColor: 'error',
        notificationMessage: action.message
      };
    }
    default:
      return state;
  }
};

const PlanBilling = () => {
  const env = useContext(EnvVariablesContext);
  const stripePromise = loadStripe(env.STRIPE_PUBLISHABLE_KEY);
  const currentAccount = useContext(CurrentAccountContext);
  const currentUser = useContext(CurrentUserContext);
  const [showModal, setShowModal] = useState(false);
  const [showNotification, setshowNotification] = useState(false);
  const [
    showModalForCancelDowngrade,
    setShowModalForCancelDowngrade
  ] = useState(false);
  const [
    showModalForUndoCancellation,
    setShowModalForUndoCancellation
  ] = useState(false);
  const dispatch = useDispatch();

  const [notification, dispatchNotification] = useReducer(
    notificationReducer,
    initialState
  );

  const cancelDowngradeErrorSelector = createErrorMessageSelector([
    CANCEL_DOWNGRADE_SUBSCRIPTION
  ]);
  const cancelDowngradeLoadedSelector = createLoadedSelector([
    CANCEL_DOWNGRADE_SUBSCRIPTION
  ]);

  const undoCancellationErrorSelector = createErrorMessageSelector([
    UNDO_CANCELLATION_SUBSCRIPTION
  ]);
  const undoCancellationLoadedSelector = createLoadedSelector([
    UNDO_CANCELLATION_SUBSCRIPTION
  ]);

  const mySubscriptionLoadedSelector = createLoadedSelector([
    FETCH_MY_SUBSCRIPTION
  ]);

  const structuredSelector = createStructuredSelector({
    plansAvailableForUser: state => state.plan.plansAvailableForUser,
    mySubscription: state => state.subscription.mySubscription,
    productSubscriptions: state => state.subscription.productSubscriptions,
    errorCancelDowngrade: state => cancelDowngradeErrorSelector(state),
    isLoadedCancelDowngrade: state => cancelDowngradeLoadedSelector(state),
    isLoadedMySubscription: state => mySubscriptionLoadedSelector(state),
    isUndoCancellationLoaded: state => undoCancellationLoadedSelector(state),
    undoCancellationError: state => undoCancellationErrorSelector(state)
  });

  const {
    plansAvailableForUser,
    mySubscription,
    productSubscriptions,
    errorCancelDowngrade,
    isLoadedCancelDowngrade,
    isLoadedMySubscription,
    isUndoCancellationLoaded,
    undoCancellationError
  } = useSelector(structuredSelector);

  useEffect(() => {
    dispatch(planAvailableForUser());
  }, [dispatch]);

  useEffect(() => {
    dispatch(mySubscriptionFetch());
  }, [dispatch]);

  function handleNotificationSet(visible, message) {
    if (visible === 'error') {
      dispatchNotification({ type: 'ERROR', message });
    } else {
      dispatchNotification({ type: 'SUCCESS', message });
    }
    setshowNotification(true);
  }

  function handleCancelDowngrade() {
    dispatch(cancelDowngradeSubscription());
    setShowModalForCancelDowngrade(false);
  }

  function handleUndoCancellation() {
    dispatch(
      undoCancellationSubscription({ subscriptionId: mySubscription.id })
    );
    setShowModalForUndoCancellation(false);
  }

  useEffect(() => {
    if (isLoadedCancelDowngrade) {
      if (isEmpty(errorCancelDowngrade)) {
        handleNotificationSet('success', 'Cancel Downgrade successful.');
      } else {
        handleNotificationSet('error', errorCancelDowngrade);
      }
    }

    return () => {
      dispatch(resetIsLoaded(CANCEL_DOWNGRADE_SUBSCRIPTION));
    };
  }, [errorCancelDowngrade, isLoadedCancelDowngrade, dispatch]);

  useEffect(() => {
    if (isUndoCancellationLoaded) {
      if (isEmpty(undoCancellationError)) {
        handleNotificationSet('success', 'Cancellation revoke successful.');
      } else {
        handleNotificationSet('error', undoCancellationError);
      }
    }

    return () => {
      dispatch(resetIsLoaded(UNDO_CANCELLATION_SUBSCRIPTION));
    };
  }, [undoCancellationError, isUndoCancellationLoaded, dispatch]);

  function subscriptionCancellationForm() {
    if (mySubscription.canceled_but_not_expired) {
      setShowModalForUndoCancellation(true);
    } else {
      window.location = `/subscriptions/${mySubscription.id}/cancellation_forms/new`;
    }
  }

  function handleModalSetShow() {
    setShowModal(true);
  }

  function handleModalSetHide() {
    setShowModal(false);
  }

  function getPageHeaderDetail() {
    if (isEmpty(mySubscription)) return {};

    let text = 'Next Billing Date:';
    let date = mySubscription.expires_at;
    let primaryActionText = 'Cancel';
    let groupTextClass = '';
    let containerStyle;

    if (mySubscription.canceled_but_not_expired) {
      text = 'Cancels On:';
      primaryActionText = 'Undo Cancellation';
      date = mySubscription.cancellation_submitted_at;
      groupTextClass = 'tw-text-error-700';
      containerStyle = { filter: 'brightness(0.5)' };
    }
    const groupText = `${text} ${formatTimestamp(
      date,
      currentAccount.timezone,
      false,
      'MMMM Do YYYY',
      true
    )}`;

    return { groupText, primaryActionText, groupTextClass, containerStyle };
  }

  function getPlan(plan){
    if (currentUser?.overlord || plan.priority <= mySubscription.plan?.priority) {
      return (
        <PlanDetail
          plan={plan}
          currentPlan={mySubscription.plan}
          productSubscriptions={productSubscriptions}
          currentAccount={currentAccount}
          mySubscription={mySubscription}
          key={plan.id}
        />
      );
    }
  }

  const {
    groupText,
    primaryActionText,
    groupTextClass,
    containerStyle
  } = getPageHeaderDetail();

  const getPlanDetail = () => {
    if (plansAvailableForUser.length === 0 || !isLoadedMySubscription) return;

    return (
      <div
        className="tw-grid lg:tw-grid-cols-3 tw-gap-8 sm:tw-grid-cols-1 tw-m-4"
        style={containerStyle}
      >
        {plansAvailableForUser.map(plan => getPlan(plan))}
      </div>
    );
  };

  return (
    <div className="tw-overflow-hidden">
      {mySubscription.downgrade_due_date ? (
        <Banner
          announcement="You are set to downgrade your plan at the end of this billing cycle."
          smallAnnouncement="Cancel downgrade your plan?"
          primaryBtnText="Cancel Downgrade"
          primaryBtnAction={setShowModalForCancelDowngrade}
          hideIcon
          showDismissBtn={false}
          color="gray"
        />
      ) : null}
      <PageHeader
        title="Plans & Billing"
        backgroundColor="white"
        secondaryActionText={mySubscription.comped ? 'Comped' : 'Edit Billing'}
        showSecondaryButton={!mySubscription.comped}
        secondaryAction={handleModalSetShow}
        primaryActionText={primaryActionText}
        primaryAction={subscriptionCancellationForm}
        actionGroupText={groupText}
        primaryType="white"
        secondaryType="primary"
        actionGroupTextClass={groupTextClass}
      />
      <div className="tw-p-8">
        <div className="tw-m-4">
          <h3 className="tw-font-bold tw-inline-block">Plan & Pricing</h3>
          <p className="tw-font-medium">
            To view and edit products that have been added to your account, go
            to
            <a href="/ui/my-products" className="text-info tw-pl-1">
              My Products
            </a>
            .
          </p>
        </div>
        {getPlanDetail()}
      </div>
      {mySubscription.canceled_but_not_expired ? (
        <Modal
          color="alpha"
          headerText="Undo Cancellation Request?"
          secondaryAction={() => setShowModalForUndoCancellation(false)}
          primaryAction={handleUndoCancellation}
          showHeaderIcon={false}
          primaryActionText="Undo Cancellation"
          secondaryActionText="No, nevermind"
          show={showModalForUndoCancellation}
          showSecondaryAction
          showPrimaryAction
          overflowHidden={false}
          bodyClasses=""
          canHandleClickOutside={false}
        >
          <div className="tw-font-medium">
            Are you sure want to revoke cancellation of your subscription?
          </div>
        </Modal>
      ) : (
        <Modal
          color="alpha"
          headerText="Cancel Downgrade?"
          secondaryAction={() => setShowModalForCancelDowngrade(false)}
          primaryAction={handleCancelDowngrade}
          showHeaderIcon={false}
          primaryActionText="Yes Cancel"
          secondaryActionText="No, nevermind"
          show={showModalForCancelDowngrade}
          showSecondaryAction
          showPrimaryAction
          overflowHidden={false}
          bodyClasses=""
          canHandleClickOutside={false}
        >
          <div className="tw-font-medium">
            Are you sure want to cancel downgrade?
          </div>
        </Modal>
      )}
      <Modal
        color="alpha"
        headerText="Edit Billing"
        innerContainerClasses="tw-px-4 tw-pt-5 sm:tw-pt-6 tw-pb-0"
        showHeaderIcon={false}
        showSecondaryAction={false}
        showPrimaryAction={false}
        show={showModal}
        overflowHidden={false}
        bodyClasses=""
        canHandleClickOutside={false}
      >
        <div className="tw-mt-5 md:tw-mt-0 md:tw-col-span-3">
          <div className="sm:tw-rounded-md sm:tw-overflow-hidden">
            <Elements stripe={stripePromise}>
              <CardForm
                onCloseModal={handleModalSetHide}
                onCloseNotification={handleNotificationSet}
                subscriptionId={mySubscription.id}
              />
            </Elements>
          </div>
        </div>
      </Modal>
      <Notification
        message={notification.notificationMessage}
        show={showNotification}
        setShowAction={setshowNotification}
        type="colored"
        headerText={notification.notificationHeaderText}
        color={notification.notificationColor}
        HeaderIcon={color => {
          return (
            <HeroIcon
              icon={`${color === 'success' ? 'check' : 'x'}-circle`}
              color={color}
            />
          );
        }}
      />
    </div>
  );
};

export default PlanBilling;
