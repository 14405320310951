// linting rules disabled for immer js
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  ADD_FLASH_MESSAGE_ACTION_TYPE,
  CLEAR_FLASH_MESSAGES_ACTION_TYPE,
  REMOVE_FLASH_MESSAGE_ACTION_TYPE
} from 'app-state/actions/constants/flashMessage.actions';

const initialState = {
  messages: []
};

export default function FlashMessageReducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case ADD_FLASH_MESSAGE_ACTION_TYPE: {
        const message = action.payload;
        draft.messages.push(message);
        return;
      }
      case CLEAR_FLASH_MESSAGES_ACTION_TYPE: {
        draft.messages = [];
        return;
      }
      case REMOVE_FLASH_MESSAGE_ACTION_TYPE: {
        const { id } = action.payload;
        draft.messages = draft.messages.filter(message => message.id !== id);
        return;
      }
      default:
        return draft;
    }
  });
}
