import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useParams, useRouteMatch } from 'react-router-dom';
import { useFormik } from 'formik';
import isEmpty from 'lodash.isempty';
import {
  createErrorMessageSelector,
  createLoadingSelector,
  createLoadedSelector
} from 'appState/selectors';
import {
  fetchIncludedForm,
  createIncludedForm,
  updateIncludedForm,
  setOverlordLayoutHeaderText,
  flashErrorMessage,
  flashSuccessMessage
} from 'appState/actions/ActionCreators';
import TextInput from 'components/Theme/TextInput';
import Select from 'components/Theme/Select';
import { includedFormSchema } from 'lib/validation/schema';
import Toggle from 'components/Theme/Toggle';
import Button from 'components/Theme/Button';
import HeroIcon from 'components/Theme/HeroIcon';
import ButtonGroup from 'components/Theme/ButtonGroup';
import Alert from 'components/Theme/Alert';
import Breadcrumbs from 'components/Theme/Breadcrumbs';
import { ANSWER_TYPE_OPTIONS } from 'config/constants';

const IncludedFormBuilder = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { id, partnerId, productId } = params;
  const { path } = useRouteMatch();
  const isCreating = path.includes('create');
  const [deletedFields, setDeletedFields] = useState([]);

  const createErrorSelector = createErrorMessageSelector([
    'product/CREATE_INCLUDED_FORM'
  ]);
  const updateErrorSelector = createErrorMessageSelector([
    'product/UPDATE_INCLUDED_FORM'
  ]);
  const loadingSelectorCreate = createLoadingSelector([
    'product/CREATE_INCLUDED_FORM'
  ]);
  const loadingSelectorUpdate = createLoadingSelector([
    'product/UPDATE_INCLUDED_FORM'
  ]);
  const loadedSelectorCreate = createLoadedSelector([
    'product/CREATE_INCLUDED_FORM'
  ]);
  const loadedSelectorUpdate = createLoadedSelector([
    'product/UPDATE_INCLUDED_FORM'
  ]);

  useEffect(() => {
    if (!isCreating) {
      dispatch(fetchIncludedForm({ includedFormId: id }));
    }
  }, [id, dispatch]);

  const structuredSelector = createStructuredSelector({
    currentIncludedForm: state => state.product.currentIncludedForm,
    isCreateLoading: state => loadingSelectorCreate(state),
    isCreateLoaded: state => loadedSelectorCreate(state),
    createError: state => createErrorSelector(state),
    updateError: state => updateErrorSelector(state),
    isUpdateLoading: state => loadingSelectorUpdate(state),
    isUpdateLoaded: state => loadedSelectorUpdate(state)
  });

  const {
    currentIncludedForm,
    createError,
    updateError,
    isCreateLoading,
    isCreateLoaded,
    isUpdateLoading,
    isUpdateLoaded
  } = useSelector(structuredSelector);

  useEffect(() => {
    if (isCreating && currentIncludedForm?.id) {
      window.location = `/overlord/partners/${partnerId}/products/${productId}/included_forms/${currentIncludedForm.id}`;
    }
  }, [currentIncludedForm]);

  useEffect(() => {
    if (!isEmpty(currentIncludedForm)) {
      setOverlordLayoutHeaderText(
        `Included Forms - ${currentIncludedForm.name}`
      );
    } else {
      dispatch(setOverlordLayoutHeaderText(`Included Forms`));
    }
  }, [currentIncludedForm]);

  useEffect(() => {
    if (isCreateLoaded && isEmpty(createError)) {
      dispatch(flashSuccessMessage('Successfully created included form'));
    } else if (isUpdateLoaded && isEmpty(updateError)) {
      dispatch(flashSuccessMessage('Successfully updated included form'));
    } else if (isCreateLoaded && !isEmpty(createError)) {
      dispatch(flashErrorMessage(createError));
    } else if (isUpdateLoaded && !isEmpty(updateError)) {
      dispatch(flashErrorMessage(updateError));
    }
  }, [isCreateLoaded, isUpdateLoaded, createError, updateError]);

  function handleSubmit(values) {
    const includedFormParams = {};
    if (Object.keys(values).length)
      Object.keys(values).forEach(function assignParams(key) {
        includedFormParams[key] = values[key];
      });

    if (isCreating) {
      includedFormParams.productId = productId;
      dispatch(createIncludedForm(includedFormParams));
    } else {
      dispatch(
        updateIncludedForm({
          includedFormId: id,
          ...includedFormParams,
          deletedFields
        })
      );
    }
    setDeletedFields([]);
  }

  const formik = useFormik({
    initialValues: {
      name: (currentIncludedForm && currentIncludedForm.name) || '',
      fields: (currentIncludedForm && currentIncludedForm.fields) || []
    },
    enableReinitialize: true,
    validationSchema: includedFormSchema,
    onSubmit: values => {
      handleSubmit(values);
    }
  });

  function removeFieldsErrors(i) {
    const newErrors = { ...formik.errors };
    newErrors.fields.splice(i, 1);
    formik.setErrors(newErrors);
  }

  function removeFieldsTouched(i) {
    const newTouched = { ...formik.touched };
    newTouched.fields.splice(i, 1);

    formik.setTouched(newTouched);
  }

  function addEmptyFieldsError(i) {
    const newErrorsFields = formik.errors.fields;
    if (!isEmpty(newErrorsFields)) {
      newErrorsFields.splice(i, 0, {});
      formik.setErrors({ ...formik.errors, fields: newErrorsFields });
    }
  }

  function addEmptyFieldsTouched(i) {
    const newTouchedFields = formik.touched.fields;
    if (!isEmpty(newTouchedFields)) {
      newTouchedFields.splice(i, 0, {});
      formik.setTouched({ ...formik.touched, fields: newTouchedFields });
    }
  }

  function addField(i) {
    const newFields = [...formik.values.fields];
    addEmptyFieldsTouched(i + 1);
    addEmptyFieldsError(i + 1);
    newFields.splice(i + 1, 0, {
      question: '',
      required: false,
      field_type: 'text'
    });
    formik.setValues({ ...formik.values, fields: newFields });
  }

  function removeField(i) {
    const newFields = [...formik.values.fields];
    removeFieldsErrors(i);
    removeFieldsTouched(i);
    const field = newFields[i];
    if (field.id)
      setDeletedFields([...deletedFields, { ...field, _destroy: true }]);
    newFields.splice(i, 1);
    formik.setValues({
      ...formik.values,
      fields: newFields
    });
  }

  function includedFormField(field, i) {
    return (
      <div key={i} className="tw-py-5">
        <div className="tw-border-gray-200 tw-border-solid tw-p-5 tw-border-1 tw-rounded-lg tw-relative">
          <div className="tw-grid tw-grid-cols-12 tw-gap-6">
            <TextInput
              id={`fields.${i}.question`}
              value={field.question}
              labelText="Question To Ask"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              showError={
                formik.touched?.fields?.[i]?.question &&
                !!formik.errors?.fields?.[i]?.question
              }
              error={formik.errors?.fields?.[i]?.question}
              required
              widthClass="tw-col-span-12 sm:tw-col-span-6"
            />
            <Select
              id={`fields.${i}.field_type`}
              options={ANSWER_TYPE_OPTIONS}
              value={field.field_type}
              labelText="Type of answer"
              required
              onChange={e =>
                formik.setFieldValue(`fields.${i}.field_type`, e.target.value)
              }
              showError={
                formik.touched?.fields?.[i]?.fieldType &&
                !!formik.errors?.fields?.[i]?.field_type
              }
              error={formik.errors?.fields?.[i]?.field_type}
              onBlur={formik.handleBlur}
              widthClass="tw-col-span-12 sm:tw-col-span-6"
            />
            <div className="tw-col-span-12 tw-flex tw-items-center tw-justify-between">
              <Toggle
                color="alpha"
                size="small"
                withLabel
                label="Required?"
                isToggled={field.required}
                onClick={(ref, value) =>
                  formik.setFieldValue(`fields.${i}.required`, value)
                }
              />
              <ButtonGroup
                btnProps={[
                  {
                    color: 'white',
                    text: 'Add',
                    action: () => addField(i)
                  },
                  {
                    color: 'error',
                    text: 'Remove',
                    action: () => removeField(i)
                  }
                ]}
                type="default"
                size="xs"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  const breadcrumbs = [
    {
      name: 'Overlord',
      action: () => {
        window.location = '/overlord/';
      }
    },
    {
      name: 'Partner',
      action: () => {
        window.location = `/overlord/partners/${partnerId}`;
      }
    },
    {
      name: 'Product',
      action: () => {
        window.location = `/overlord/partners/${partnerId}/products/${productId}`;
      }
    },
    {
      name: 'Included Form',
      action: () => {
        console.log('included form clicked');
      }
    }
  ];

  return (
    <div>
      <div className="tw-px-4 tw-py-5 sm:tw-p-6">
        <div className="tw-mb-5">
          {breadcrumbs && breadcrumbs.length ? (
            <div>
              <Breadcrumbs crumbs={breadcrumbs} isBackgroundWhite />
            </div>
          ) : null}
        </div>
        <div className="tw-mt-2 md:tw-flex md:tw-items-center md:tw-justify-between">
          <div className="tw-flex-1 tw-min-w-0">
            <h2 className="tw-font-body tw-text-2xl tw-font-bold tw-leading-7 sm:tw-text-3xl sm:tw-leading-9 sm:tw-truncate">
              Included Form Builder
            </h2>
          </div>
          <div className="tw-mt-4 tw-flex tw-flex-shrink-0 md:tw-mt-0 md:tw-ml-4">
            {!isCreating ? (
              <Button
                text="Settings"
                color="gray"
                onClick={() => {
                  window.location = `/overlord/partners/${partnerId}/products/${productId}/included_forms/${id}/settings`;
                }}
                isLoaded={!isUpdateLoading}
                disabled={isCreating}
              />
            ) : null}
            <Button
              text={isCreating ? 'Create' : 'Update'}
              color="alpha"
              onClick={formik.handleSubmit}
              containerClass="tw-ml-3"
              isLoaded={!isCreateLoading && !isUpdateLoading}
              disabled={isEmpty(formik.values.fields)}
            />
          </div>
        </div>
        <div className="tw-my-3 tw-border-b tw-border-t-0 tw-border-l-0 tw-border-r-0 tw-border-solid tw-border-gray-200" />
        <Alert
          color="gray"
          headerText="Things To Know"
          bodyText={[
            'Included forms will be distributed to each account that has this product.',
            'These forms will not be editable by the account.',
            'Check out the settings page for form actions and notifications.'
          ]}
          icon="information-circle"
        />
        <div className="tw-grid tw-grid-cols-12 tw-gap-6 tw-py-10">
          <TextInput
            id="name"
            value={formik.values.name}
            labelText="Name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            showError={formik.touched.name && !!formik.errors.name}
            error={formik.errors.name}
            required
            widthClass="tw-col-span-12 sm:tw-col-span-6"
          />
        </div>
        {isEmpty(formik.values.fields) ? (
          <div className="tw-text-center tw-relative tw-block tw-w-full tw-border-2 tw-border-gray-300 tw-border-dashed tw-rounded-lg tw-p-12 tw-text-center hover:tw-border-gray-400 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-indigo-500">
            <HeroIcon icon="server" width={12} height={12} color="gray" />
            <h3 className="tw-mt-2 tw-text-sm tw-font-medium tw-text-gray-900">
              No Fields
            </h3>
            <p className="tw-mt-1 tw-text-sm tw-text-gray-500">
              A form must have at least one field.
            </p>
            <div className="mt-6">
              <Button
                text="Add Field"
                color="alpha"
                onClick={() => addField(0)}
              />
            </div>
          </div>
        ) : (
          formik.values.fields
            // eslint-disable-next-line no-underscore-dangle
            .filter(f => !f._destroy)
            .map((field, i) => {
              return includedFormField(field, i);
            })
        )}
      </div>
    </div>
  );
};

export default IncludedFormBuilder;
