import React, { useState, useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Card from 'components/Theme/Card';
import Table from 'components/Theme/Table';
import PageHeader from 'components/Theme/PageHeader';
import Pagination from 'components/Theme/Pagination';
import {
  fetchImportHistory,
  deleteCsvImport,
  flashSuccessMessage,
  flashErrorMessage
} from 'appState/actions/ActionCreators';
import Skeleton from 'react-loading-skeleton';
import {
  createLoadingSelector,
  createLoadedSelector,
  createErrorMessageSelector
} from 'appState/selectors';
import Modal from 'components/Theme/Modal';
import HeroIcon from 'components/Theme/HeroIcon';
import isEmpty from 'lodash.isempty';

const MATCH_LIMIT = 10;
const ContactsImport = () => {
  const DELETE = 'DELETE';
  const CANNOT_DELETE = 'CANNOT_DELETE';
  const HIDE = 'HIDE';

  const modalInitialState = {
    bodyText: '',
    showPrimaryAction: false,
    secondaryActionText: '',
    show: false
  };

  const modalReducer = (state, action) => {
    switch (action.type) {
      case DELETE: {
        return {
          ...state,
          bodyText: action.bodyText,
          showPrimaryAction: true,
          secondaryActionText: 'Cancel',
          show: true
        };
      }
      case CANNOT_DELETE: {
        return {
          ...state,
          bodyText: action.bodyText,
          showPrimaryAction: false,
          secondaryActionText: 'OK',
          show: true
        };
      }
      case HIDE: {
        return {
          ...state,
          show: false
        };
      }
      default:
        return state;
    }
  };
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(0);
  const [currentImportId, setCurrentImportId] = useState('');
  const [modal, dispatchModal] = useReducer(modalReducer, modalInitialState);

  const importHisrotyLoadingSelector = createLoadingSelector([
    'contact/FETCH_IMPORTED_CONTACTS'
  ]);

  const deleteCsvImportLoadedSelector = createLoadedSelector([
    'contact/DELETE_CSV_IMPORT'
  ]);

  const deleteCsvImportErrorSelector = createErrorMessageSelector([
    'contact/DELETE_CSV_IMPORT'
  ]);

  const structuredSelector = createStructuredSelector({
    importHistory: state => state.contact.importHistory,
    importHisrotyLoading: state => importHisrotyLoadingSelector(state),
    deleteCsvImportLoaded: state => deleteCsvImportLoadedSelector(state),
    deleteCsvImportError: state => deleteCsvImportErrorSelector(state)
  });

  useEffect(() => {
    dispatch(fetchImportHistory());
  }, [dispatch]);

  const {
    importHistory,
    importHisrotyLoading,
    deleteCsvImportLoaded,
    deleteCsvImportError
  } = useSelector(structuredSelector);

  const totalItems = importHistory.length;
  const totalPages =
    totalItems > MATCH_LIMIT ? Math.ceil(totalItems / MATCH_LIMIT) : 0;

  const rows = importHistory.slice(currentPage, currentPage + MATCH_LIMIT);

  const pageChange = data => {
    if (data.page) {
      const pageNum = (data.page - 1) * MATCH_LIMIT;
      setCurrentPage(pageNum);
    }
  };

  const new_import = () => {
    window.location = '/ui/contacts/csv_import/new';
  };

  const handleEditCsvImport = importId => {
    window.location = `/ui/contacts/csv_import/${importId}/edit`;
  };

  const handleViewCsvImport = importId => {
    window.location = `/ui/contacts/csv_import/${importId}/status`;
  };

  function handleDeleteCsvImport(importId) {
    setCurrentImportId(importId);
    dispatchModal({
      type: DELETE,
      bodyText:
        'Are you sure you want to delete this import? All progress will be lost.'
    });
  }

  function handleModalSetShow(show) {
    if (!show) dispatchModal({ type: HIDE });
  }

  function handleDeleteCsvImportAction() {
    if (currentImportId) {
      dispatch(deleteCsvImport({ currentImportId }));
    }
  }

  useEffect(() => {
    if (deleteCsvImportLoaded) {
      if (isEmpty(deleteCsvImportError)) {
        dispatch(flashSuccessMessage('Successfully deleted csv import.'));
        dispatch(fetchImportHistory());
      } else {
        dispatch(flashErrorMessage(deleteCsvImportError));
      }
    }
  }, [dispatch, deleteCsvImportLoaded, deleteCsvImportError]);

  function getTable() {
    if (importHisrotyLoading) {
      return <Skeleton height={60} />;
    }

    return (
      <Table
        rows={rows}
        includedColumns={[
          'lifecycle',
          'campaign',
          'product',
          'assigned_staff',
          'date_created',
          'status'
        ]}
        emptyMessage="No values present."
        showColumnBadge
        badgeColumnName="status"
        customActionKeyName="custom_action_type"
        showColumnTooltip
        tooltipColumnName="scheduled_time"
        deletable
        onDelete={handleDeleteCsvImport}
        onEdit={handleEditCsvImport}
        onView={handleViewCsvImport}
        hasFooter
        FooterComponent={() =>
          totalPages && totalPages > 1 ? (
            <Pagination
              total={totalItems}
              totalPages={totalPages}
              limit={MATCH_LIMIT}
              action={pageChange}
            />
          ) : null
        }
      />
    );
  }
  return (
    <div>
      <div className="tw-min-h-screen">
        <PageHeader
          title="Import History"
          breadcrumbs={[
            {
              name: 'Contacts',
              action: () => {
                window.location = '/contacts';
              }
            },
            {
              name: 'Import History',
              action: () => {
                window.location = 'contacts/csv_import/history';
              }
            }
          ]}
        />
        <div className="tw-mx-7 tw-mt-7 tw-mb-24">
          <Card
            title="My Import List"
            containerClass="tw-bg-white tw-rounded-md"
            showHeaderAction
            headerActionText="New Import"
            headerAction={new_import}
          >
            <div className="tw-flex">
              <div className="tw-flex-1 tw-mx-5 tw-mb-5 tw-mt-5">
                {getTable()}
              </div>
            </div>
          </Card>
        </div>
      </div>
      <Modal
        headerText="Delete Import"
        bodyText={modal.bodyText}
        primaryAction={handleDeleteCsvImportAction}
        primaryActionText="Delete"
        showPrimaryAction={modal.showPrimaryAction}
        showSecondaryAction
        secondaryActionText={modal.secondaryActionText}
        show={modal.show}
        setShow={handleModalSetShow}
        color="error"
        HeaderIcon={color => {
          return (
            <HeroIcon
              icon="exclamation"
              widthClasses="tw-w-6"
              heightClasses="tw-h-6"
              color={color}
            />
          );
        }}
      />
    </div>
  );
};

export default ContactsImport;
