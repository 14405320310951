import { put, call, select } from 'redux-saga/effects';
import isEmpty from 'lodash.isempty';
import get from 'lodash.get';
import { waitFor } from 'lib/utils/redux-saga-extensions';
import { reassignParamsFor } from 'lib/utils/contact-extensions';
import {
  decorateActivity,
  decoratedActivities
} from 'lib/services/activityFeedDecorator';
import {
  decorateMessage,
  decorateMessages
} from 'lib/services/messageDecorator';
import {
  CREATE_CONTACT_NOTE_SUCCESS,
  CREATE_APPOINTMENT_SUCCESS,
  UPDATE_CONTACT_SUCCESS,
  CREATE_FILE_UPLOAD_SUCCESS,
  FETCH_UPLOADED_FILES_LIST_SUCCESS,
  CREATE_AUTOMATION_SUCCESS,
  CREATE_EMAIL_SUCCESS,
  PAUSE_AUTOMATION_SUCCESS,
  UNPAUSE_AUTOMATION_SUCCESS,
  UPDATE_CONTACT_CUSTOM_GROUPS_SUCCESS
} from 'appState/actions/constants/contact.actions';
import { CREATE_TASK_SUCCESS } from 'appState/actions/constants/task.actions';
import { CREATE_APPOINTMENT_FROM_BOOKER_SUCCESS } from 'appState/actions/constants/appointmentBooker.actions';
import { CREATE_CONTACT_FORM_SUCCESS } from 'appState/actions/constants/contactForm.actions';
import {
  UPDATE_ACTIVITY_SUCCESS,
  FETCH_NOTES_ACTIVITIES_SUCCESS,
  FETCH_FORMS_ACTIVITIES_SUCCESS,
  FETCH_EMAIL_ACTIVITIES_SUCCESS
} from 'appState/actions/constants/activity.actions';
import {
  SMS_INBOX_SEND_MESSAGE_SUCCESS,
  SMS_INBOX_CURRENT_CONVERSATION_ADD_MESSAGE,
  SMS_INBOX_MESSAGE_LIST_ITEM_LAST_MESSAGE_UPDATE,
  SMS_INBOX_FETCH_CONVERSATION_SUCCESS
} from 'appState/actions/constants/smsInbox.actions';

const HIGH_MARK = 1000000;
function aRandomNumber() {
  return Math.floor(Math.random() * HIGH_MARK + 1);
}

function* getEssentials() {
  yield call(waitFor, state => !isEmpty(state.contact));
  yield call(waitFor, state => !isEmpty(state.activity));
  yield call(waitFor, state => !isEmpty(state.broadcastCenter));
  yield call(waitFor, state => !isEmpty(state.contactFormType));
  yield call(waitFor, state => !isEmpty(state.overlordUtilities));
  yield call(waitFor, state => !isEmpty(state.smsInbox));

  const { currentAccount } = yield select(state => state.broadcastCenter);
  const {
    currentContact,
    uploadedFiles,
    campaigns,
    automations
  } = yield select(state => state.contact);
  const { contactFormTypes } = yield select(state => state.contactFormType);
  const { users, customGroups } = yield select(
    state => state.overlordUtilities
  );
  const {
    currentConversation,
    conversationPage,
    conversationTotal
  } = yield select(state => state.smsInbox);
  const {
    notesActivities,
    notesPage,
    notesTotal,
    formsActivities,
    formsPage,
    formsTotal,
    emailActivities,
    emailPage,
    emailTotal
  } = yield select(state => state.activity);

  return {
    currentAccount,
    currentContact,
    contactFormTypes,
    uploadedFiles,
    campaigns,
    users,
    customGroups,
    currentConversation,
    conversationPage,
    conversationTotal,
    notesActivities,
    notesPage,
    notesTotal,
    formsActivities,
    formsPage,
    formsTotal,
    emailActivities,
    emailPage,
    emailTotal,
    automations
  };
}

/* TODO: Remove all 'console' statements
   as the reducer/front-end is updated accordingly. */
export function* createDummyAppointment({ appointment }) {
  yield put({
    type: CREATE_APPOINTMENT_SUCCESS,
    payload: {
      currentAppointment: appointment
    }
  });
  console.log('Appointment Created -', appointment);
}

export function* createDummyAppointmentFromBooker(appointmentParams) {
  const { users, currentContact } = yield call(getEssentials);
  const appointmentName = yield select(
    state => state.appointmentBooker.currentAppointmentType.name
  );
  const currentUser = users.find(
    user =>
      user.id.toString() === appointmentParams.appointment.user_id.toString()
  );
  const newAppointment = {
    id: aRandomNumber(),
    user_id: appointmentParams.appointment.user_id,
    user: currentUser.full_name,
    contact: currentContact.fullName,
    contact_id: appointmentParams.contact_id,
    starts_at: appointmentParams.appointment.starts_at,
    appointment_type_id: appointmentParams.appointment.appointment_type_id,
    appointment_type: appointmentName,
    notifications: true,
    redirect_url: '',
    should_redirect: false
  };

  yield put({
    type: CREATE_APPOINTMENT_FROM_BOOKER_SUCCESS,
    paylaod: {
      currentAppointment: newAppointment
    }
  });
}

export function* createDummyNote(params) {
  const {
    currentAccount,
    currentContact,
    notesActivities,
    notesPage,
    notesTotal
  } = yield call(getEssentials);

  const contact = {
    first_name: currentContact.firstName,
    last_name: currentContact.lastName
  };

  const account = {
    first_name: currentAccount.admin_first_name,
    last_name: currentAccount.admin_last_name
  };

  const noteActivity = {
    account_id: currentAccount.id,
    action: 'Note Created',
    contact,
    contact_id: params.contactId,
    created_at: Date(),
    updated_at: Date(),
    note: params.note,
    pinned: false,
    id: aRandomNumber(),
    recipient: currentAccount.id,
    user_id: currentAccount.id,
    user: account,
    source: 'manual'
  };

  const activities = [...notesActivities, noteActivity];

  yield put({
    type: CREATE_CONTACT_NOTE_SUCCESS,
    payload: {
      note: params.note
    }
  });
  yield put({
    type: FETCH_NOTES_ACTIVITIES_SUCCESS,
    payload: {
      notesActivities: decoratedActivities(activities),
      notesPage,
      notesTotal
    }
  });
}

export function* createDummyTask({ task }) {
  yield put({
    type: CREATE_TASK_SUCCESS,
    payload: {
      currentTask: task
    }
  });
  console.log('Task Created -', task);
}

export function* createDummyContactForm({ accountId, contactId, formId }) {
  const {
    currentContact,
    contactFormTypes,
    formsActivities,
    formsPage,
    formsTotal
  } = yield call(getEssentials);

  const formTitle = contactFormTypes.filter(form => form.id === formId)[0].name;

  const contact = {
    first_name: currentContact.firstName,
    last_name: currentContact.lastName
  };

  const form = {
    contact_form_type_id: formId,
    contact_id: contactId,
    created_at: Date(),
    id: formId,
    name: formTitle,
    updated_at: Date()
  };

  const formActivity = {
    account_id: accountId,
    action: 'Form Submitted',
    contact,
    contact_id: contactId,
    created_at: Date(),
    updated_at: Date(),
    form_title: formTitle,
    form_id: formId,
    pinned: false,
    id: aRandomNumber()
  };

  const activities = [...formsActivities, formActivity];

  yield put({
    type: CREATE_CONTACT_FORM_SUCCESS,
    payload: {
      contactFormTypes: form
    }
  });
  yield put({
    type: FETCH_FORMS_ACTIVITIES_SUCCESS,
    payload: {
      formsActivities: decoratedActivities(activities),
      formsPage,
      formsTotal
    }
  });
}

export function* uploadDummyDocument({ fileParams, contactId }) {
  const doc = {
    id: aRandomNumber(),
    owner_type: 'Contact',
    owner_id: contactId,
    created_at: Date(),
    updated_at: Date(),
    file: {
      url: `https://randomUrl.com/randomParams/randomValues/${get(
        fileParams,
        'file[0].path',
        'Demo file'
      )}`
    }
  };

  const { uploadedFiles } = yield call(getEssentials);
  const files = [...uploadedFiles, doc];

  yield put({
    type: CREATE_FILE_UPLOAD_SUCCESS,
    payload: {
      file: doc
    }
  });

  yield put({
    type: FETCH_UPLOADED_FILES_LIST_SUCCESS,
    payload: files
  });
}

export function* updateDummyContact({ contact }) {
  const updatedContact = reassignParamsFor(contact);
  const { users, currentContact } = yield call(getEssentials);

  const assignedStaff = users.filter(
    user => user.id === updatedContact.assignedStaffId
  )[0];

  updatedContact.fullName = [
    updatedContact.firstName,
    updatedContact.lastName
  ].join(' ');
  updatedContact.fullAddress = [
    updatedContact.address1,
    updatedContact.address2,
    updatedContact.city,
    updatedContact.state,
    updatedContact.postalCode
  ].join(', ');
  updatedContact.formattedPhone = `+1 (${updatedContact.phone
    .toString()
    .substr(0, 3)}) ${updatedContact.phone
    .toString()
    .substr(3, 3)}-${updatedContact.phone.toString().substr(6, 4)}`;
  updatedContact.assignedStaff = [
    assignedStaff.first_name,
    assignedStaff.last_name
  ].join(' ');

  const contactInfo = { ...currentContact, ...updatedContact };
  yield put({
    type: UPDATE_CONTACT_SUCCESS,
    payload: {
      contact: contactInfo
    }
  });
}

export function* updateDummyActivity({
  activity,
  activitiesLength,
  pinnedActivitiesLength
}) {
  const updatedActivity = { ...activity, pinned: !activity.pinned };
  const index = !activity.pinned ? pinnedActivitiesLength : activitiesLength;

  yield put({
    type: UPDATE_ACTIVITY_SUCCESS,
    payload: {
      updatedActivity: decorateActivity(updatedActivity, index)
    }
  });
}

export function* createDummyAutomation({ automation }) {
  const { currentAccount, campaigns } = yield call(getEssentials);
  const fullName = [
    currentAccount.admin_first_name,
    currentAccount.admin_last_name
  ].join(' ');
  const campaign = get(
    campaigns.filter(c => c.id === automation.product_campaign_id),
    '[0]'
  );
  const processingDate = new Date();
  const completionDate = processingDate;

  completionDate.setDate(completionDate.getDate() + 10);

  const newAutomation = {
    id: aRandomNumber(),
    title: campaign.name,
    next_step_processing_at: processingDate,
    completed_step_count: 0,
    total_step_count: campaign.total_step_count,
    estimated_completion_date: completionDate,
    completed_at: null,
    lifecycle: automation.new_lifecycle,
    initiation_event: `Manually added by ${fullName}`,
    paused_at: null,
    last_engagement_at: null,
    product_campaign_id: automation.product_campaign_id
  };

  yield put({
    type: CREATE_AUTOMATION_SUCCESS,
    payload: {
      newAutomation,
      automationsToBeCompletedIds: automation.automations_to_be_completed_ids
    }
  });
}

export function* sendDummySms({ sms }) {
  const {
    currentContact,
    users,
    currentConversation,
    conversationPage,
    conversationTotal
  } = yield call(getEssentials);
  const currentUser = users.find(user => user.id === sms.user_id);
  const dummySms = {
    id: aRandomNumber(),
    body: sms.body,
    contact: {
      id: currentContact.id,
      first_name: currentContact.firstName,
      last_name: currentContact.lastName
    },
    user: {
      id: currentUser.id,
      first_name: currentUser.first_name,
      last_name: currentUser.last_name
    },
    read_at: null,
    created_at: Date(),
    sms_type: 'outbound',
    delivery_status: 'queued',
    media_urls: [],
    status_class_status: '',
    status_class_title: '',
    status_message: '',
    status_failed_link: '',
    status_failed_code: '',
    status_failed_message: ''
  };

  const updatedConversation = decorateMessages(
    [...currentConversation, dummySms].reverse()
  );
  const decoratedDummyMessage = decorateMessage(dummySms);

  yield put({
    type: SMS_INBOX_FETCH_CONVERSATION_SUCCESS,
    payload: {
      currentConversation: updatedConversation,
      conversationPer: 30,
      conversationPage,
      conversationTotal
    }
  });
  yield put({
    type: SMS_INBOX_SEND_MESSAGE_SUCCESS,
    payload: {}
  });
  yield put({
    type: SMS_INBOX_CURRENT_CONVERSATION_ADD_MESSAGE,
    payload: { sms: decoratedDummyMessage }
  });
  yield put({
    type: SMS_INBOX_MESSAGE_LIST_ITEM_LAST_MESSAGE_UPDATE,
    payload: { sms: decoratedDummyMessage }
  });
}

export function* createContactEmail({ contactId, subject, body }) {
  const {
    emailActivities,
    emailPage,
    emailTotal,
    currentAccount,
    currentContact
  } = yield call(getEssentials);

  const account = {
    first_name: currentAccount.admin_first_name,
    last_name: currentAccount.admin_last_name
  };

  const contact = {
    first_name: currentContact.firstName,
    last_name: currentContact.lastName
  };

  const emailActivity = {
    account_id: currentAccount.id,
    action: 'Manual Email Sent',
    contact,
    contact_id: contactId,
    created_at: Date(),
    updated_at: Date(),
    email_body: body,
    email_subject: subject,
    pinned: false,
    id: aRandomNumber(),
    recipient: currentAccount.id,
    user_id: currentAccount.id,
    user: account,
    source: null,
    sourceable_type: 'Email'
  };

  const activities = [...emailActivities, emailActivity];

  yield put({
    type: CREATE_EMAIL_SUCCESS,
    payload: {
      data: {
        message: `${contact.first_name} ${contact.last_name} was sent an email from ${currentAccount.admin_first_name} ${currentAccount.admin_last_name}`
      }
    }
  });

  yield put({
    type: FETCH_EMAIL_ACTIVITIES_SUCCESS,
    payload: {
      emailActivities: decoratedActivities(activities),
      emailPage,
      emailTotal
    }
  });
}

export function* pauseUnpauseDummyAutomation({ automationId, action }) {
  const { currentAccount, campaigns, automations } = yield call(getEssentials);

  const automation = automations.find(
    auto => auto.id.toString() === automationId.toString()
  );

  const fullName = [
    currentAccount.admin_first_name,
    currentAccount.admin_last_name
  ].join(' ');

  const campaign = campaigns.find(c => c.id === automation.product_campaign_id);
  const processingDate = new Date();
  const completionDate = processingDate;
  completionDate.setDate(completionDate.getDate() + 10);

  const dummyAutomation = {
    id: automationId,
    title: campaign.name,
    next_step_processing_at: processingDate,
    completed_step_count: 0,
    total_step_count: campaign.total_step_count,
    estimated_completion_date: completionDate,
    completed_at: null,
    lifecycle: automation.new_lifecycle,
    initiation_event: `Manually added by ${fullName}`,
    paused_at: action === 'pause' ? Date() : null,
    last_engagement_at: null,
    product_campaign_id: automation.product_campaign_id
  };

  if (action === 'pause') {
    yield put({
      type: PAUSE_AUTOMATION_SUCCESS,
      payload: {
        automation: dummyAutomation
      }
    });
  } else if (action === 'unpause') {
    yield put({
      type: UNPAUSE_AUTOMATION_SUCCESS,
      payload: {
        automation: dummyAutomation
      }
    });
  }
}

export function* updateDummyCustomGroups({ custom_group_ids }) {
  const { customGroups } = yield call(getEssentials);

  const newCustomGroups = customGroups.filter(customGroup =>
    custom_group_ids.includes(customGroup.id)
  );

  yield put({
    type: UPDATE_CONTACT_CUSTOM_GROUPS_SUCCESS,
    payload: {
      contactCustomGroups: newCustomGroups
    }
  });
}

export function* isDummyContact() {
  const { currentContact } = yield call(getEssentials);
  const dummyContact = currentContact.dummy;
  return dummyContact === 'true';
}
