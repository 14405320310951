import React, { useContext, useEffect, useState } from 'react';
import UtilityCard from 'components/OverlordUtilities/shared/UtilityCard';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  createErrorMessageSelector,
  createLoadedSelector
} from 'appState/selectors';
import {
  bulkChangeJourney,
  fetchJourneys
} from 'appState/actions/ActionCreators';
import { CurrentAccountContext } from 'components/shared/context/CurrentAccountContext';
import useProcessingActivity from 'components/shared/hooks/useProcessingActivity';
import useAccountDisabled from 'components/shared/hooks/useAccountDisabled';
import isEmpty from 'lodash.isempty';

const UTILITY_NAMES = ['bulk_journey_changer'];

const BulkJourneyChanger = () => {
  let fileField = null;
  const [journeyId, setJourneyId] = useState();

  const currentAccount = useContext(CurrentAccountContext);
  const accountId = currentAccount.id;
  const processingActivity = useProcessingActivity(UTILITY_NAMES);
  const accountDisabled = useAccountDisabled();

  const errorSelector = createErrorMessageSelector([
    'overlord/BULK_JOURNEY_CHANGER',
    'overlord/JOURNEYS_FETCH'
  ]);
  const loadedSelector = createLoadedSelector(['overlord/JOURNEYS_FETCH']);
  const structuredSelector = createStructuredSelector({
    journeys: state => state.overlordUtilities.journeys,
    error: state => errorSelector(state),
    isLoaded: state => loadedSelector(state)
  });
  const { journeys, error, isLoaded } = useSelector(structuredSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isEmpty(journeys)) dispatch(fetchJourneys({ accountId }));
  }, [dispatch, accountId]);

  const changeJourney = () => {
    if (fileField && journeyId) {
      dispatch(
        bulkChangeJourney({
          journeyId,
          file: fileField.files[0]
        })
      );
    }
  };

  const journeySelectOptions = journeys.map(journey => {
    return (
      <option key={journey.id} value={journey.id}>
        {journey.name}
      </option>
    );
  });

  const getCardBody = () => {
    return (
      <div className="form-row">
        <div className="form-group col-lg-6">
          {/* <%= label_tag :file, 'Select CSV File for Upload' %><br> */}
          <label htmlFor="file">Select CSV File for Upload</label>
          <div className="input-group file-group">
            <input
              type="text"
              className="form-control file-value"
              placeholder="Choose file..."
              readOnly=""
            />
            {/* <%= file_field_tag :file, label: 'Select CSV File', class: 'form-control', placeholder: 'Upload CSV', id: 'upload-csv' %> */}
            <input
              ref={field => {
                fileField = field;
              }}
              id="upload-csv"
              name="file"
              type="file"
              className="form-control"
              placeholder="Upload CSV"
            />
            <span className="input-group-append">
              <button className="btn btn-light file-browser" type="button">
                <i className="fa fa-upload" />
              </button>
            </span>
          </div>
        </div>
        <div className="form-group col-lg-6 custom-overload-select">
          {/* <%= label_tag :journey_id, 'Select Journey' %>
          <%= select_tag :journey_id, options_for_select(@account.journeys.active.pluck(:name, :id)), class: 'form-control', 'data-provide': 'selectpicker' %> */}
          <label htmlFor="journeyId">Select Journey</label>
          {journeys && journeys.length ? (
            <select
              onChange={e => setJourneyId(e.target.value)}
              id="journeyId"
              className="form-control"
              data-size="20"
              data-provide="selectpicker"
              defaultValue=""
            >
              <option value="" disabled hidden>
                Nothing selected
              </option>
              {journeySelectOptions}
            </select>
          ) : null}
        </div>
      </div>
    );
  };
  return (
    <UtilityCard
      title="Bulk Journey Changer"
      subtitle="Use this tool to change contacts campaign."
      submitBtnText="Change Contacts Journey"
      submitBtnAction={changeJourney}
      bodyComponent={getCardBody}
      isLoaded={isLoaded}
      processingActivity={processingActivity}
      accountDisabled={accountDisabled}
      error={error}
    />
  );
};

export default BulkJourneyChanger;
