import React from 'react';
import { withA11y } from '@storybook/addon-a11y';
import { withKnobs } from '@storybook/addon-knobs';
import AutoCompleteDropdown from './index';

export default {
  component: AutoCompleteDropdown,
  title: 'AutoCompleteDropdown',
  decorators: [withA11y, withKnobs]
};

export const standard = () => <AutoCompleteDropdown />;
