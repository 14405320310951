import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';

const DraftSavedModal = props => {
  const { saveAction, deleteAction } = props;

  const structuredSelector = createStructuredSelector({
    isTemplate: state => state.broadcastCenter.isTemplate
  });

  const { isTemplate } = useSelector(structuredSelector);

  return (
    <div
      className="modal fade show"
      id="draft-was-saved-modal"
      tabIndex="-1"
      aria-modal="true"
      aria-hidden="true"
      style={{ display: 'none' }}
    >
      <div className="modal-dialog modal-md">
        <div className="modal-content">
          <div className="modal-header vh-75">
            <h4 className="modal-title" id="myModalLabel">
              Save {isTemplate ? 'Broadcast Template' : 'Broadcast'} Changes
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              id="dismiss-draft-was-saved-modal"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <p>
              You have updated the content of this{' '}
              {isTemplate ? 'broadcast template' : 'broadcast'}. Do you want to
              save your changes?
            </p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              id="update-broadcast-cancel-button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              id="dont-update-broadcast-confirmation-button"
              data-dismiss="modal"
              onClick={deleteAction}
            >
              Don&apos;t Update Content
            </button>
            <button
              type="button"
              className="btn btn-info"
              id="update-broadcast-confirmation-button"
              data-dismiss="modal"
              onClick={saveAction}
            >
              Update Content
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

DraftSavedModal.propTypes = {
  saveAction: PropTypes.func.isRequired,
  deleteAction: PropTypes.func.isRequired
};

export default DraftSavedModal;
