import { call, put, takeLatest } from 'redux-saga/effects';
import { axiosDefault } from 'lib/utils/axios-setup';
import {
  MARKET_PLACE_LIST_FETCH_REQUEST,
  MARKET_PLACE_LIST_FETCH_SUCCESS,
  MARKET_PLACE_LIST_FETCH_FAILURE,
  PRODUCT_FETCH_REQUEST,
  PRODUCT_FETCH_SUCCESS,
  PRODUCT_FETCH_FAILURE
} from 'app-state/actions/constants/marketPlace.actions';

function* fetchMarketPlace({ payload: { query = '' } = {} }) {
  try {
    const params = {};
    if (query && query.trim() !== '') {
      params.q = query;
    }

    const response = yield call(axiosDefault.get, `/marketplace`, { params });
    yield put({
      type: MARKET_PLACE_LIST_FETCH_SUCCESS,
      payload: {
        productList: response.data.product_list,
        featuredProductList: response.data.featured_product_list
      }
    });
  } catch (e) {
    yield put({
      type: MARKET_PLACE_LIST_FETCH_FAILURE,
      payload: { error: e },
      error: true
    });
  }
}

function* fetchProduct({ payload: { productId } }) {
  try {
    const response = yield call(axiosDefault.get, `/marketplace/${productId}`);
    yield put({
      type: PRODUCT_FETCH_SUCCESS,
      payload: {
        currentProduct: response.data.product,
        categoryProductList: response.data.category_products
      }
    });
  } catch (e) {
    yield put({
      type: PRODUCT_FETCH_FAILURE,
      payload: {
        error: e
      },
      error: true
    });
  }
}

export function* marketPlaceFetch() {
  yield takeLatest(MARKET_PLACE_LIST_FETCH_REQUEST, fetchMarketPlace);
}

export function* productFetch() {
  yield takeLatest(PRODUCT_FETCH_REQUEST, fetchProduct);
}
