import React from 'react';
import { action } from '@storybook/addon-actions';
import { withA11y } from '@storybook/addon-a11y';
import { withKnobs, text, object, boolean } from '@storybook/addon-knobs';
import MultiSelectWrapper from './MultiSelectWrapper';

export default {
  component: MultiSelectWrapper,
  title: 'MultiSelect',
  decorators: [withA11y, withKnobs]
};

export const withLabel = () => (
  <MultiSelectWrapper
    id="email"
    value={object('Value', [])}
    options={object('Select Options', [
      { displayName: 'Legacy', value: 'legacy' },
      { displayName: 'Bundle', value: 'bundle' },
      { displayName: 'Add-on', value: 'add_on' }
    ])}
    placeholder={text('Placeholder', 'Please Select Your Options')}
    labelText={text('Label Text', 'Type')}
    type={text('Type', 'text')}
    showError={boolean('Show Error', false)}
    error={text('Error Text', 'Please select a type')}
    helpText={text('Help Text', '')}
    hideLabel={boolean('Hide Label', false)}
    widthClass={text('Width Class', 'tw-max-w-xs')}
    showAddNewOption={boolean('Show Add New Option', false)}
    addNewOptionText={text('Name of New Custom Option', '')}
    onAddNewOptionClick={() => alert("Render Your Component")}
  />
);

export const withLabelAndHelpText = () => (
  <MultiSelectWrapper
    id="type"
    value={text('Value', '')}
    options={object('Select Options', [
      { displayName: 'Legacy', value: 'legacy' },
      { displayName: 'Bundle', value: 'bundle' },
      { displayName: 'Add-on', value: 'add_on' }
    ])}
    placeholder={text('Placeholder', 'Please Select Your Options')}
    labelText={text('Label Text', 'Type')}
    type={text('Type', 'type')}
    showError={boolean('Show Error', false)}
    error={text('Error Text', 'Please select a type')}
    helpText={text('Help Text', 'The plan types that we offer customers.')}
    hideLabel={boolean('Hide Label', false)}
    widthClass={text('Width Class', 'tw-max-w-xs')}
    showAddNewOption={boolean('Show Add New Option', false)}
    addNewOptionText={text('Name of New Custom Option', '')}
    onAddNewOptionClick={() => alert("Render Your Component")}
  />
);

export const withError = () => (
  <MultiSelectWrapper
    id="type"
    value={object('Value', [])}
    options={object('Select Options', [
      { displayName: 'Legacy', value: 'legacy' },
      { displayName: 'Bundle', value: 'bundle' },
      { displayName: 'Add-on', value: 'add_on' }
    ])}
    placeholder={text('Placeholder', 'Please Select Your Options')}
    labelText={text('Label Text', 'Type')}
    type={text('Type', 'type')}
    showError={boolean('Show Error', true)}
    error={text('Error Text', 'Please select a type')}
    helpText={text('Help Text', '')}
    hideLabel={boolean('Hide Label', false)}
    widthClass={text('Width Class', 'tw-max-w-xs')}
    showAddNewOption={boolean('Show Add New Option', false)}
    addNewOptionText={text('Name of New Custom Option', '')}
    onAddNewOptionClick={() => alert("Render Your Component")}
  />
);

export const withHiddenLabel = () => (
  <MultiSelectWrapper
    id="email"
    value={object('Value', [])}
    options={object('Select Options', [
      { displayName: 'Legacy', value: 'legacy' },
      { displayName: 'Bundle', value: 'bundle' },
      { displayName: 'Add-on', value: 'add_on' }
    ])}
    placeholder={text('Placeholder', 'Please Select Your Options')}
    labelText={text('Label Text', 'Type')}
    type={text('Type', 'text')}
    showError={boolean('Show Error', false)}
    error={text('Error Text', 'Please select a type')}
    helpText={text('Help Text', '')}
    hideLabel={boolean('Hide Label', true)}
    widthClass={text('Width Class', 'tw-max-w-xs')}
    showAddNewOption={boolean('Show Add New Option', false)}
    addNewOptionText={text('Name of New Custom Option', '')}
    onAddNewOptionClick={() => alert("Render Your Component")}
  />
);

export const withOptionGroup = () => (
  <MultiSelectWrapper
    id="email"
    value={object('Value', [])}
    options={object('Select Options', [
      {
        label: 'option group - 1',
        options: [
          {
            displayName: 'option 1',
            value: '1'
          },
          {
            displayName: 'option 2',
            value: '2'
          }
        ]
      },
      {
        label: 'option group - 2',
        options: [
          {
            displayName: 'option 1',
            value: '3'
          },
          {
            displayName: 'option 2',
            value: '4'
          }
        ]
      },
      {
        displayName: 'extra option',
        value: '5'
      }
    ])}
    placeholder={text('Placeholder', 'Please Select Your Options')}
    labelText={text('Label Text', 'Type')}
    type={text('Type', 'text')}
    showError={boolean('Show Error', false)}
    error={text('Error Text', 'Please select a type')}
    helpText={text('Help Text', '')}
    hideLabel={boolean('Hide Label', true)}
    widthClass={text('Width Class', 'tw-max-w-xs')}
    showAddNewOption={boolean('Show Add New Option', false)}
    addNewOptionText={text('Name of New Custom Option', '')}
    onAddNewOptionClick={() => alert("Render Your Component")}
  />
);
