import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';
import Card from '../Card';
import Link from '../Link';
import TextInput from '../TextInput';
import Toggle from '../Toggle';

const ActionPanel = ({
  headerText,
  bodyText,
  actionType,
  actionText,
  onClick,
  color,
  inputId,
  inputValue,
  inputPlaceholder,
  inputLabel,
  buttonLocation
}) => {
  const [value, setValue] = useState(inputValue || '');

  function actionPanelAction() {
    switch (actionType) {
      case 'buttonPrimary': {
        return <Button text={actionText} color={color} onClick={onClick} />;
      }
      case 'buttonSecondary': {
        return (
          <Button
            text={actionText}
            color={color}
            onClick={onClick}
            type="secondary"
          />
        );
      }
      case 'buttonWhite': {
        return <Button text={actionText} onClick={onClick} type="white" />;
      }
      case 'link': {
        return <Link text={actionText} onClick={onClick} color={color} />;
      }
      case 'toggle': {
        return <Toggle color={color} onClick={onClick} />;
      }
      case 'input': {
        return (
          <div className="tw-flex tw-items-center">
            <TextInput
              id={inputId}
              widthClass="tw-max-w-xs tw-w-full"
              value={value}
              onChange={e => setValue(e.target.value)}
              hideLabel
              labelText={inputLabel}
              placeholder={inputPlaceholder}
            />
            <Button
              text={actionText}
              color={color}
              onClick={() => onClick(value)}
              containerStyle={{ marginTop: '3px', marginLeft: '10px' }}
            />
          </div>
        );
      }
      default: {
        return <Button text={actionText} color={color} onClick={onClick} />;
      }
    }
  }

  function actionPanelHeader() {
    return (
      <h3 className="tw-font-body tw-my-0 tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
        {headerText}
      </h3>
    );
  }

  function actionPanelBody() {
    return (
      <div className="tw-mt-2 tw-text-sm tw-leading-5 tw-text-gray-500">
        <p className="tw-font-body tw-my-0">{bodyText}</p>
      </div>
    );
  }

  return (
    <Card containerClass="tw-max-w-xl tw-bg-white tw-shadow tw-overflow-hidden tw-rounded-lg">
      <div className="tw-px-4 tw-py-5 sm:tw-p-6">
        {/* Layout with button action at top right */}
        {buttonLocation === 'topRight' ? (
          <div className="sm:tw-flex sm:tw-items-start sm:tw-justify-between">
            <div>
              {actionPanelHeader()}
              {actionPanelBody()}
            </div>
            <div className="tw-mt-5 sm:tw-mt-0 sm:tw-ml-6 sm:tw-flex-shrink-0 sm:tw-flex sm:tw-items-center">
              {actionPanelAction()}
            </div>
          </div>
        ) : null}

        {/* Layout with either button or toggle action to the right */}
        {buttonLocation === 'right' || actionType === 'toggle' ? (
          <Fragment>
            {actionPanelHeader()}
            <div className="sm:tw-flex sm:tw-items-start sm:tw-justify-between">
              {actionPanelBody()}

              {buttonLocation === 'right' ? (
                <div className="tw-mt-5 sm:tw-mt-0 sm:tw-ml-6 sm:tw-flex-shrink-0 sm:tw-flex sm:tw-items-center">
                  {actionPanelAction()}
                </div>
              ) : (
                <div className="tw-mt-3 tw-text-sm tw-leading-5">
                  {actionPanelAction()}
                </div>
              )}
            </div>
          </Fragment>
        ) : null}

        {/* Layout with action (button, input, etc.) on bottom */}
        {buttonLocation === 'bottom' && actionType !== 'toggle' ? (
          <Fragment>
            {actionPanelHeader()}
            {actionPanelBody()}
            <div className="tw-mt-3 tw-text-sm tw-leading-5">
              {actionPanelAction()}
            </div>
          </Fragment>
        ) : null}
      </div>
    </Card>
  );
};

ActionPanel.defaultProps = {
  actionText: '',
  color: 'alpha',
  actionType: 'link',
  inputId: 'input_id',
  inputValue: '',
  inputPlaceholder: '',
  inputLabel: '',
  buttonLocation: 'bottom'
};

ActionPanel.propTypes = {
  headerText: PropTypes.string.isRequired,
  bodyText: PropTypes.string.isRequired,
  actionText: PropTypes.string,
  actionType: PropTypes.oneOf([
    'buttonPrimary',
    'buttonSecondary',
    'buttonWhite',
    'link',
    'toggle',
    'input'
  ]),
  onClick: PropTypes.func.isRequired,
  color: PropTypes.oneOf([
    'alpha',
    'bravo',
    'charlie',
    'success',
    'warning',
    'error',
    'gray'
  ]),
  inputId: PropTypes.string,
  inputValue: PropTypes.string,
  inputPlaceholder: PropTypes.string,
  inputLabel: PropTypes.string,
  buttonLocation: PropTypes.oneOf(['bottom', 'right', 'topRight'])
};

export default ActionPanel;
