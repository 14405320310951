import React from 'react';
import PropTypes from 'prop-types';

const EnvVariablesContext = React.createContext();

const EnvVariablesProvider = props => {
  const { children, value } = props;
  return (
    <EnvVariablesContext.Provider value={value}>
      {children}
    </EnvVariablesContext.Provider>
  );
};

EnvVariablesProvider.defaultProps = {
  children: null,
  value: null
};

EnvVariablesProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  value: PropTypes.objectOf(PropTypes.string)
};

export { EnvVariablesContext, EnvVariablesProvider };
