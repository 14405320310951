import React, { Fragment } from 'react';
import { withA11y } from '@storybook/addon-a11y';
import { withKnobs, text, select, boolean } from '@storybook/addon-knobs';
import Badge from './index';

export default {
  component: Badge,
  title: 'Badge',
  decorators: [withA11y, withKnobs]
};

let shapeSelect;
let sizeSelect;
let colorSelect;

function includeShapeSelect(defaultValue) {
  const label = 'Shape';
  const options = ['round', 'rectangle'];
  shapeSelect = select(label, options, defaultValue);
}

function includeSizeSelect(defaultValue) {
  const label = 'Size';
  const options = ['small', 'large'];
  sizeSelect = select(label, options, defaultValue);
}

function includeColorSelect(defaultValue) {
  const label = 'Color';
  const options = [
    'alpha',
    'bravo',
    'charlie',
    'success',
    'warning',
    'error',
    'gray'
  ];
  colorSelect = select(label, options, defaultValue);
}

export const standard = () => {
  includeShapeSelect('round');
  includeSizeSelect('large');
  includeColorSelect('alpha');
  return (
    <Badge
      value={text('Value', 'FitPro Edition')}
      color={colorSelect}
      shape={shapeSelect}
      size={sizeSelect}
      hasDot={boolean('hasDot', false)}
      deletable={boolean('deletable', false)}
    />
  );
};

export const withDot = () => {
  includeShapeSelect('round');
  includeSizeSelect('large');
  includeColorSelect('alpha');
  return (
    <Badge
      value={text('Value', 'FitPro Edition')}
      color={colorSelect}
      shape={shapeSelect}
      size={sizeSelect}
      hasDot={boolean('hasDot', true)}
      deletable={boolean('deletable', false)}
    />
  );
};

export const withIcon = () => {
  includeShapeSelect('round');
  includeSizeSelect('large');
  includeColorSelect('alpha');
  return (
    <Badge
      value={text('Value', 'FitPro Edition')}
      color={colorSelect}
      shape={shapeSelect}
      size={sizeSelect}
      hasDot={boolean('hasDot', false)}
      deletable={boolean('deletable', false)}
      icon="check-circle"
    />
  );
};

export const deletable = () => {
  includeShapeSelect('round');
  includeSizeSelect('large');
  includeColorSelect('alpha');
  return (
    <Badge
      value={text('Value', 'FitPro Edition')}
      color={colorSelect}
      shape={shapeSelect}
      size={sizeSelect}
      hasDot={boolean('hasDot', false)}
      deletable={boolean('deletable', true)}
    />
  );
};

export const alternateShape = () => {
  includeShapeSelect('rectangle');
  includeSizeSelect('large');
  includeColorSelect('alpha');
  return (
    <Badge
      value={text('Value', 'FitPro Edition')}
      color={colorSelect}
      shape={shapeSelect}
      size={sizeSelect}
      hasDot={boolean('hasDot', false)}
      deletable={boolean('deletable', false)}
    />
  );
};

export const sizes = () => {
  includeShapeSelect('round');
  includeSizeSelect('small');
  includeColorSelect('alpha');
  return (
    <Fragment>
      <Badge
        containerStyle={{ marginRight: '5px' }}
        value={text('Value', 'FitPro Edition')}
        color={colorSelect}
        shape={shapeSelect}
        size={sizeSelect}
        hasDot={boolean('hasDot', false)}
        deletable={boolean('deletable', false)}
      />
      <Badge
        containerStyle={{ marginRight: '5px' }}
        value="FitPro Edition"
        size="large"
      />
      <Badge
        containerStyle={{ marginRight: '5px' }}
        value="FitPro Edition"
        shape="rectangle"
        size="small"
      />
      <Badge
        containerStyle={{ marginRight: '5px' }}
        value="FitPro Edition"
        shape="rectangle"
        size="large"
      />
    </Fragment>
  );
};

export const colors = () => {
  includeShapeSelect('round');
  includeSizeSelect('large');
  includeColorSelect('alpha');
  return (
    <Fragment>
      <Badge
        containerStyle={{ marginRight: '5px' }}
        value={text('Value', 'FitPro Edition')}
        color={colorSelect}
        shape={shapeSelect}
        size={sizeSelect}
        hasDot={boolean('hasDot', false)}
        deletable={boolean('deletable', false)}
      />
      <Badge
        containerStyle={{ marginRight: '5px' }}
        value="FitPro Edition"
        color="bravo"
      />
      <Badge
        containerStyle={{ marginRight: '5px' }}
        value="FitPro Edition"
        color="charlie"
      />
      <Badge
        containerStyle={{ marginRight: '5px' }}
        value="FitPro Edition"
        color="success"
      />
      <Badge
        containerStyle={{ marginRight: '5px' }}
        value="FitPro Edition"
        color="warning"
      />
      <Badge
        containerStyle={{ marginRight: '5px' }}
        value="FitPro Edition"
        color="error"
      />
      <Badge
        containerStyle={{ marginRight: '5px' }}
        value="FitPro Edition"
        color="gray"
      />
    </Fragment>
  );
};
