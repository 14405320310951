import React, {
  useContext,
  useState,
  useEffect,
  Fragment,
  useMemo,
  useReducer
} from 'react';
import { createStructuredSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Modal from 'components/Theme/Modal';
import { useFormik } from 'formik';
import { pauseOptionSchema } from 'lib/validation/schema';
import {
  fetchMyProductSubscriptions,
  pauseProductSubscription,
  unpauseProductSubscription,
  fetchContactsCount,
  removeProductSubscription,
  viewContactsInProductCampaign,
  flashSuccessMessage,
  flashErrorMessage,
  updateProcessAddContactsAt,
  clearViewContactsModalContacts,
  fetchLeadSources
} from 'appState/actions/ActionCreators';
import TextInput from 'components/Theme/TextInput';
import Button from 'components/Theme/Button';
import DatePicker from 'components/Theme/DatePicker';
import copyToClipboardFromId from 'lib/utils/copyToClipboard';
import { CurrentAccountContext } from 'components/shared/context/CurrentAccountContext';
import Table from 'components/Theme/Table';
import Select from 'components/Theme/Select';
import Pagination from 'components/Theme/Pagination';
import {
  createErrorMessageSelector,
  createLoadedSelector
} from 'appState/selectors';
import ProductSubscriptionCard from 'components/MyProducts/ProductSubscriptionsCards/ProductSubscriptionCard';
import isEmpty from 'lodash.isempty';
import {
  PAUSE_PRODUCT_SUBSCRIPTION,
  UNPAUSE_PRODUCT_SUBSCRIPTION,
  REMOVE_PRODUCT_SUBSCRIPTION
} from 'appState/actions/constants/productSubscription.actions';
import ActionCable from 'components/ActionCable';
import groupBy from 'lodash.groupby';
import sortedProducts from 'lib/utils/sortedProducts';

const ProductSubscriptionsCards = () => {
  const NOTHING_SELECTED = 'nothing-selected';
  const EVERYTHING_ELSE = 'everything-else';
  const currentAccount = useContext(CurrentAccountContext);
  const ACCOUNT_DOMAIN = `https://${currentAccount.full_domain}`;
  const [currentProductSubscription, setCurrentProductSubscription] = useState(
    {}
  );

  const leadCaptureInitialState = {
    leadSource: '',
    directURL: `${ACCOUNT_DOMAIN}/join/`,
    embeddedURL: `${ACCOUNT_DOMAIN}/new_lead_form_embed/`
  };

  const leadCaptureReducer = (state, action) => {
    switch (action.type) {
      case NOTHING_SELECTED: {
        return {
          leadSource: '',
          directURL: `${ACCOUNT_DOMAIN}/join/${currentProductSubscription.journey_id}`,
          embeddedURL: `${ACCOUNT_DOMAIN}/new_lead_form_embed/${currentProductSubscription.journey_id}`
        };
      }
      case EVERYTHING_ELSE: {
        return {
          leadSource: action.leadSource,
          directURL: `${ACCOUNT_DOMAIN}/join/${currentProductSubscription.journey_id}/${action.leadSource}`,
          embeddedURL: `${ACCOUNT_DOMAIN}/new_lead_form_embed/${currentProductSubscription.journey_id}/${action.leadSource}`
        };
      }
      default:
        return state;
    }
  };

  const dispatch = useDispatch();
  const history = useHistory();
  const [leadCapture, dispatchLeadCapture] = useReducer(
    leadCaptureReducer,
    leadCaptureInitialState
  );
  const pauseProductSubscriptionErrorSelector = createErrorMessageSelector([
    PAUSE_PRODUCT_SUBSCRIPTION
  ]);
  const unpauseProductSubscriptionErrorSelector = createErrorMessageSelector([
    UNPAUSE_PRODUCT_SUBSCRIPTION
  ]);
  const pauseProductSubscriptionLoadedSelector = createLoadedSelector([
    PAUSE_PRODUCT_SUBSCRIPTION
  ]);
  const unpauseProductSubscriptionLoadedSelector = createLoadedSelector([
    UNPAUSE_PRODUCT_SUBSCRIPTION
  ]);

  const removeProductSubscriptionLoadedSelector = createLoadedSelector([
    REMOVE_PRODUCT_SUBSCRIPTION
  ]);

  const [showInstructionsModal, setShowInstructionsModal] = useState(false);
  const [
    viewingPauseCampaignsConfirmation,
    setViewingPauseCampaignsConfirmation
  ] = useState(false);
  const [
    viewingUnpauseCampaignsConfirmation,
    setViewingUnpauseCampaignsConfirmation
  ] = useState(false);
  const [removingProduct, setRemovingProduct] = useState(false);
  const removeProductSubscriptionErrorSelector = createErrorMessageSelector([
    REMOVE_PRODUCT_SUBSCRIPTION
  ]);

  const [showViewContactsModal, setShowViewContactsModal] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState('');
  const [leadSourceOptions, setLeadSourceOptions] = useState([]);

  const structuredSelector = createStructuredSelector({
    mySubscription: state => state.subscription.mySubscription,
    contacts: state => state.productSubscription.contacts,
    viewContactsModalTotalPages: state =>
      state.productSubscription.viewContactsModalTotalPages,
    viewContactsModalTotal: state =>
      state.productSubscription.viewContactsModalTotal,
    myProductSubscriptions: state =>
      state.productSubscription.myProductSubscriptions,
    pauseProductSubscriptionError: state =>
      pauseProductSubscriptionErrorSelector(state),
    unpauseProductSubscriptionError: state =>
      unpauseProductSubscriptionErrorSelector(state),
    isPauseProductSubscriptionLoaded: state =>
      pauseProductSubscriptionLoadedSelector(state),
    isUnpauseProductSubscriptionLoaded: state =>
      unpauseProductSubscriptionLoadedSelector(state),
    removeProductSubscriptionError: state =>
      removeProductSubscriptionErrorSelector(state),
    contactsCount: state => state.productSubscription.contactsCount,
    isRemoveProductSubscriptionLoaded: state =>
      removeProductSubscriptionLoadedSelector(state),
    defaultLeadSources: state => state.leadSource.defaultLeadSources,
    customLeadSources: state => state.leadSource.customLeadSources
  });

  const {
    mySubscription,
    myProductSubscriptions,
    pauseProductSubscriptionError,
    unpauseProductSubscriptionError,
    removeProductSubscriptionError,
    isPauseProductSubscriptionLoaded,
    isUnpauseProductSubscriptionLoaded,
    contactsCount,
    isRemoveProductSubscriptionLoaded,
    contacts,
    viewContactsModalTotalPages,
    viewContactsModalTotal,
    defaultLeadSources,
    customLeadSources
  } = useSelector(structuredSelector);

  useEffect(() => {
    if (isEmpty(myProductSubscriptions)) {
      dispatch(fetchMyProductSubscriptions({ accountId: currentAccount.id }));
    }
  }, [dispatch, currentAccount, myProductSubscriptions]);

  useEffect(() => {
    dispatch(fetchLeadSources());
  }, [dispatch]);

  useEffect(() => {
    const defaultOptions = defaultLeadSources.map(leadSource => {
      return { displayName: leadSource.name, value: leadSource.key };
    });

    let customOptions = [];
    if (!isEmpty(customLeadSources)) {
      customOptions = customLeadSources.map(leadSource => {
        return { displayName: leadSource.name, value: leadSource.key };
      });
    }

    setLeadSourceOptions([...defaultOptions, ...customOptions]);
  }, [defaultLeadSources, customLeadSources]);

  const handleLeadSourceChange = value => {
    if (value === '') {
      dispatchLeadCapture({
        type: NOTHING_SELECTED
      });
    } else {
      dispatchLeadCapture({ type: EVERYTHING_ELSE, leadSource: value });
    }
  };

  function handleOnActionClick(productSubscription, value) {
    setCurrentProductSubscription(productSubscription);
    if (value === 'customize-campaigns') {
      window.location = productSubscription.customize_campaign_url;
    } else if (value === 'setup') {
      history.push(`my-products/${productSubscription.id}/setup`);
    } else if (value === 'instructions') {
      dispatchLeadCapture({ type: NOTHING_SELECTED });
      setShowInstructionsModal(true);
    } else if (value === 'add-contacts') {
      history.push(`my-products/${productSubscription.id}/add-contacts`);
    } else if (value === 'view-contacts') {
      setShowViewContactsModal(true);
    } else if (value === 'pause campaigns') {
      setViewingPauseCampaignsConfirmation(true);
    } else if (value === 'unpause campaigns') {
      setViewingUnpauseCampaignsConfirmation(true);
    } else if (value === 'remove') {
      setRemovingProduct(true);
      dispatch(
        fetchContactsCount({
          accountId: currentAccount.id,
          productSubscriptionId: productSubscription.id
        })
      );
    }
    return [];
  }

  useEffect(() => {
    if (isPauseProductSubscriptionLoaded) {
      if (isEmpty(pauseProductSubscriptionError)) {
        dispatch(flashSuccessMessage('Successfully paused product.'));
      } else {
        dispatch(flashErrorMessage(pauseProductSubscriptionError));
      }
    }
  }, [isPauseProductSubscriptionLoaded, pauseProductSubscriptionError]);

  useEffect(() => {
    if (isUnpauseProductSubscriptionLoaded) {
      if (isEmpty(unpauseProductSubscriptionError)) {
        dispatch(flashSuccessMessage('Successfully unpaused product.'));
      } else {
        dispatch(flashErrorMessage(unpauseProductSubscriptionError));
      }
    }
  }, [isUnpauseProductSubscriptionLoaded, unpauseProductSubscriptionError]);

  useEffect(() => {
    if (isRemoveProductSubscriptionLoaded) {
      if (isEmpty(removeProductSubscriptionError)) {
        dispatch(flashSuccessMessage('Successfully removed product.'));
      } else {
        dispatch(flashErrorMessage(removeProductSubscriptionError));
      }
    }
  }, [isRemoveProductSubscriptionLoaded, removeProductSubscriptionError]);

  useEffect(() => {
    if (!isEmpty(selectedCampaign)) {
      dispatch(
        viewContactsInProductCampaign({
          accountId: currentAccount.id,
          productCampaignId: selectedCampaign
        })
      );
    }
  }, [dispatch, selectedCampaign, currentAccount.id]);

  useEffect(() => {
    if (!showViewContactsModal) {
      dispatch(clearViewContactsModalContacts());
    }
  }, [showViewContactsModal]);

  useEffect(() => {
    // eslint-disable-next-line camelcase
    if (currentProductSubscription?.product?.product_campaigns) {
      setSelectedCampaign(
        currentProductSubscription.product.product_campaigns.filter(
          campaign => !isEmpty(campaign.published_at)
        )[0]?.id
      );
    }
  }, [currentProductSubscription]);

  function handlePauseSubmit(values) {
    const productSubscriptionParams = {
      productSubscriptionId: currentProductSubscription.id,
      accountId: currentAccount.id
    };
    if (Object.keys(values).length)
      Object.keys(values).forEach(function assignParams(key) {
        productSubscriptionParams[key] = values[key];
      });
    return dispatch(pauseProductSubscription(productSubscriptionParams));
  }

  function handleUnpauseSubmit() {
    const productSubscriptionParams = {
      productSubscriptionId: currentProductSubscription.id,
      accountId: currentAccount.id
    };
    return dispatch(unpauseProductSubscription(productSubscriptionParams));
  }

  function handleRemoveSubmit() {
    dispatch(
      removeProductSubscription({
        accountId: currentAccount.id,
        productSubscriptionId: currentProductSubscription.id
      })
    );
  }

  const formik = useFormik({
    initialValues: {
      pauseOption: '',
      xDays: '',
      untilXDate: ''
    },
    enableReinitialize: true,
    validationSchema: pauseOptionSchema,
    onSubmit: values => {
      handlePauseSubmit(values);
    }
  });

  function handlePauseDate(updatedValue) {
    formik.setFieldValue('untilXDate', updatedValue);
  }

  function showDefaultProductBadge(productSubscription) {
    return (
      mySubscription?.default_lead_product_id ===
        productSubscription?.product?.id ||
      mySubscription?.default_client_product_id ===
        productSubscription?.product?.id
    );
  }

  function defaultProductBadgeText(productSubscription) {
    if (
      mySubscription?.default_lead_product_id ===
      productSubscription?.product?.id
    ) {
      return 'Default Lead Product';
    }
    if (
      mySubscription?.default_client_product_id ===
      productSubscription?.product?.id
    ) {
      return 'Default Client Product';
    }
    return '';
  }

  function handleViewContactsSelect(event) {
    setSelectedCampaign(event.target.value);
  }

  function viewContactsModalOptions() {
    if (currentProductSubscription && currentProductSubscription.product) {
      const campaigns = currentProductSubscription.product.product_campaigns.filter(
        campaign => !isEmpty(campaign.published_at)
      );
      const opts = campaigns.map(campaign => ({
        displayName: campaign.name,
        value: campaign.id
      }));
      return opts;
    }
    return [];
  }

  function pageChange(pageParams) {
    dispatch(
      viewContactsInProductCampaign({
        productCampaignId: selectedCampaign,
        page: pageParams.page,
        accountId: currentAccount.id
      })
    );
  }

  function handleProductSubscriptions({ product_subscription_id = null }) {
    dispatch(
      updateProcessAddContactsAt({
        productSubscriptionId: product_subscription_id
      })
    );
  }

  const productGroupByCategory = useMemo(() => {
    if (myProductSubscriptions.length === 0) return;
    const options = myProductSubscriptions.map(formType => {
      return {
        ...formType,
        category: formType?.product?.category || 'Without Category'
      };
    });
    const catergory = groupBy(options, 'category');
    return sortedProducts(catergory, mySubscription);
  }, [myProductSubscriptions]);

  function getProductSubscriptionCard() {
    if (!productGroupByCategory) return;
    return Object.entries(productGroupByCategory).map(category => {
      return (
        <div className="tw-p-4" key={category[0]}>
          <h3 className="tw-font-bold tw-inline-block tw-pb-2">
            {category[0]}
            <span className="tw-text-alpha-500 tw-text-base">
              {` (${category[1].length})`}
            </span>
          </h3>
          <div className="tw-grid xxs:tw-grid-cols-12 sm:tw-grid-cols-10 xl:tw-grid-cols-12 xxl:tw-grid-cols-10 tw-gap-2">
            {category[1].map(productSubscription => {
              return (
                <ProductSubscriptionCard
                  key={productSubscription.id}
                  productSubscription={productSubscription}
                  showAction
                  onActionClick={handleOnActionClick}
                  showBadge={showDefaultProductBadge(productSubscription)}
                  badgeText={defaultProductBadgeText(productSubscription)}
                />
              );
            })}
          </div>
        </div>
      );
    });
  }

  const isCurrentProductIsDefault = showDefaultProductBadge(
    currentProductSubscription
  );

  function getRemoveModalContent() {
    if (isCurrentProductIsDefault) {
      const productText = defaultProductBadgeText(
        currentProductSubscription
      ).toLocaleLowerCase();
      return (
        <p>
          This Product is used as a {productText}. You need to set up new{' '}
          {productText} before deleting it.
        </p>
      );
    }
    return (
      <>
        <p>Are you sure you want to remove this product?</p>
        {contactsCount > 0 && (
          <p>
            There are {contactsCount} active contacts assigned to this product.
          </p>
        )}
      </>
    );
  }
  return (
    <Fragment>
      <ActionCable
        channel={{ channel: 'ProductSubscriptionApiChannel' }}
        onReceived={handleProductSubscriptions}
      >
        {getProductSubscriptionCard()}
      </ActionCable>
      <Modal
        headerText="Lead Capture Instructions"
        showHeaderIcon={false}
        showPrimaryAction
        primaryAction={() => null}
        primaryActionText="OK"
        show={showInstructionsModal}
        setShow={setShowInstructionsModal}
        color="alpha"
      >
        {currentAccount.lead_source_enabled && (
          <div className="tw-col-span-10">
            <Select
              id="lead-source"
              labelText="Lead Source"
              options={leadSourceOptions}
              value={leadCapture.leadSource}
              onChange={e => handleLeadSourceChange(e.target.value)}
            />
          </div>
        )}
        <div className="tw-mb-4 tw-mt-1 tw-text-sm tw-leading-5 tw-text-gray-500">
          Gather new leads and get them enrolled in your lead campaigns for this
          product.
        </div>
        <TextInput
          id="direct-link-to-lead-capture-page"
          labelText="Direct Link To Lead Capture Page"
          value={leadCapture.directURL}
          readonly
        />
        <div className="tw-mt-2 tw-flex tw-justify-end">
          <Button
            text="Visit Page"
            color="alpha"
            size="xs"
            type="white"
            // eslint-disable-next-line no-return-assign
            onClick={() => window.open(leadCapture.directURL)}
          />
          <Button
            text="Copy To Clipboard"
            color="alpha"
            size="xs"
            containerStyle={{ marginLeft: '10px' }}
            type="secondary"
            onClick={() =>
              copyToClipboardFromId('direct-link-to-lead-capture-page')
            }
          />
        </div>
        <div className="tw-mt-2">
          <TextInput
            id="embed-code-lead-capture-input"
            labelText="Embed Code For Lead Capture Form"
            value={`<script src='https://www.uplaunch.com/api/v1/scripts/fetch_new_lead_form.js' data-form-url='${leadCapture.embeddedURL}'></script>`}
            readonly
          />
        </div>
        <div className="tw-mt-2 tw-flex tw-justify-end">
          <Button
            text="Copy To Clipboard"
            color="alpha"
            size="xs"
            type="secondary"
            onClick={() =>
              copyToClipboardFromId('embed-code-lead-capture-input')
            }
          />
        </div>
      </Modal>
      <Modal
        color="alpha"
        headerText={`Pause Your ${currentProductSubscription.name}`}
        primaryAction={formik.handleSubmit}
        showHeaderIcon={false}
        isOverflowHidden={false}
        primaryActionText="Pause"
        secondaryActionText="Cancel"
        setShow={setViewingPauseCampaignsConfirmation}
        show={viewingPauseCampaignsConfirmation}
        showPrimaryAction
        showSecondaryAction
        bodyClasses=""
        canHandleClickOutside={false}
      >
        <p className="tw-font-body tw-text-sm tw-leading-5 tw-text-gray-500">
          {`Are you sure you want to pause ${currentProductSubscription.name}?`}
        </p>
        <div className="tw-mb-4">
          <Select
            labelText="Pause Options"
            value={formik.values.pauseOption}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            showError={
              formik.touched.pauseOption && !!formik.errors.pauseOption
            }
            id="pause_option"
            options={[
              {
                displayName: 'Indefinitely',
                value: 'indefinite'
              },
              {
                displayName: 'For Set Number of Days',
                value: 'x-days'
              },
              {
                displayName: 'Until Specific Date',
                value: 'until-x-date'
              }
            ]}
          />
        </div>
        {formik.values.pauseOption === 'x-days' ? (
          <TextInput
            id="x_days"
            value={formik.values.xDays}
            labelText="Number of Days"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            showError={formik.touched.xDays && !!formik.errors.xDays}
            error={formik.errors.xDays}
            widthClass="tw-col-span-12 sm:tw-col-span-6"
          />
        ) : null}
        {formik.values.pauseOption === 'until-x-date' ? (
          <DatePicker
            id="until_x_date"
            value={formik.values.untilXDate}
            labelText="Until Specific Date"
            onChange={handlePauseDate}
            onBlur={formik.handleBlur}
            showError={formik.touched.untilXDate && !!formik.errors.untilXDate}
            widthClass="tw-col-span-12 sm:tw-col-span-6"
          />
        ) : null}
      </Modal>
      <Modal
        color="alpha"
        headerText={`Unpause Your ${currentProductSubscription.name}?`}
        primaryAction={() => handleUnpauseSubmit()}
        showHeaderIcon={false}
        isOverflowHidden={false}
        primaryActionText="Resume"
        secondaryActionText="Cancel"
        setShow={setViewingUnpauseCampaignsConfirmation}
        show={viewingUnpauseCampaignsConfirmation}
        showPrimaryAction
        showSecondaryAction
        bodyClasses=""
        canHandleClickOutside={false}
      >
        <p className="tw-font-body tw-text-sm tw-leading-5 tw-text-gray-500">
          Are you ready to resume this product&apos;s campaigns?
        </p>
        <p className="tw-font-body tw-text-sm tw-leading-5 tw-text-gray-500">
          Please note that their next campaign step will run immediately.
        </p>
      </Modal>
      <Modal
        headerIcon={{}}
        canHandleClickOutside
        color="alpha"
        headerClasses=""
        headerText={`Remove ${currentProductSubscription.name}?`}
        innerContainerClasses="tw-px-4 tw-pt-5 tw-pb-4 sm:tw-p-6 sm:tw-pb-4"
        isOverflowHidden
        primaryAction={() => handleRemoveSubmit(currentProductSubscription)}
        primaryActionText="Remove Product"
        secondaryActionText="Cancel"
        setShow={setRemovingProduct}
        show={removingProduct}
        showHeaderIcon={false}
        showPrimaryAction
        showSecondaryAction
        isPrimaryActionDisabled={isCurrentProductIsDefault}
      >
        {getRemoveModalContent()}
      </Modal>
      <Modal
        headerText=""
        showHeaderIcon={false}
        showPrimaryAction
        primaryAction={() => null}
        primaryActionText="OK"
        show={showViewContactsModal}
        innerContainerClasses="tw-px-4 tw-pt-5 tw-pb-4 sm:tw-p-8 sm:tw-pb-4"
        setShow={setShowViewContactsModal}
        color="alpha"
      >
        <div className="tw-mb-5">
          <Select
            value={selectedCampaign}
            id="selectedCampaign"
            labelText="Choose Campaign"
            onChange={handleViewContactsSelect}
            options={viewContactsModalOptions()}
          />
        </div>
        <Table
          title={`Audience Details for ${currentProductSubscription &&
            currentProductSubscription.name}`}
          rows={contacts}
          includedColumns={['first_name', 'last_name', 'email']}
          emptyMessage="No contacts tied to this campaign"
          hasFooter
          customTableClass="tw-max-h-96 tw-max-w-lg tw-overflow-auto"
          FooterComponent={() =>
            viewContactsModalTotalPages && viewContactsModalTotalPages > 1 ? (
              <Pagination
                totalPages={viewContactsModalTotalPages}
                action={pageChange}
                total={parseInt(viewContactsModalTotal, 10)}
                limit={20}
              />
            ) : null
          }
        />
      </Modal>
    </Fragment>
  );
};

export default ProductSubscriptionsCards;
