import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { createHtmlPortalNode, OutPortal } from 'react-reverse-portal';
import { Provider } from 'react-redux';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import configureStore from 'appState/stores/configureStore';
import { EnvVariablesProvider } from 'components/shared/context/EnvVariablesContext';
import { axiosSetup } from 'lib/utils/axios-setup';
import { CurrentAccountProvider } from 'components/shared/context/CurrentAccountContext';
import Card from 'components/Theme/Card';
import ConfirmationPage from 'components/AppointmentBooker/ConfirmationPage';
import { PortalProvider } from 'components/shared/context/PortalContext';
import Purchase from './Purchase';
import AppointmentBooker from './AppointmentBooker';
import OptInPage from './OptInLandingPage';

const DEFAULT_PATH = '/public';

const store = configureStore();

const AppPublic = props => {
  const { env } = props;
  const stripePromise = loadStripe(env.STRIPE_PUBLISHABLE_KEY);
  const baseUrl = env.API_BASE;
  const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
  const { currentAccount } = props;
  const [portalNodes, setPortalNodes] = useState({
    notificationNode: null
  });
  const notificationPortalNode = useMemo(() => createHtmlPortalNode(), []);

  useEffect(() => {
    if (notificationPortalNode) {
      setPortalNodes({
        notificationNode: notificationPortalNode
      });
    }
  }, [notificationPortalNode]);

  axiosSetup({ baseUrl, csrfToken, authenticationToken: null });

  return (
    <Provider store={store}>
      <EnvVariablesProvider value={env}>
        <PortalProvider value={portalNodes}>
          <BrowserRouter>
            <Switch>
              <Redirect
                exact
                from={DEFAULT_PATH}
                to={`${DEFAULT_PATH}/purchase`}
              />
              <div id="tailwind-selector-id" className="theme-app-default">
                <Route
                  path={`${DEFAULT_PATH}/purchase/:planUuid/:accountType?`}
                  render={() => (
                    <Elements stripe={stripePromise}>
                      <Purchase />
                    </Elements>
                  )}
                />
                {notificationPortalNode && (
                  <OutPortal node={notificationPortalNode} />
                )}
                <Route
                  path={[
                    `${DEFAULT_PATH}/book`,
                    `${DEFAULT_PATH}/bookings/:appointmentId/confirmation`
                  ]}
                  render={() => (
                    <div className="tw-font-body tw-text-gray-700 tw-bg-gray-base">
                      <Card
                        bodyStyle={{}}
                        color="alpha"
                        containerClass="tw-relative tw-mx-auto tw-my-15 tw-max-w-screen-lg tw-rounded-lg"
                        containerStyle={{}}
                      >
                        <Route
                          path={`${DEFAULT_PATH}/book`}
                          render={() => (
                            <CurrentAccountProvider value={currentAccount}>
                              <AppointmentBooker />
                            </CurrentAccountProvider>
                          )}
                        />
                        <Route
                          path={`${DEFAULT_PATH}/bookings/:appointmentId/confirmation`}
                          render={() => (
                            <CurrentAccountProvider value={currentAccount}>
                              <ConfirmationPage />
                            </CurrentAccountProvider>
                          )}
                        />
                      </Card>
                    </div>
                  )}
                />
                <Route
                  path={`${DEFAULT_PATH}/join/:id`}
                  render={() => (
                    <CurrentAccountProvider value={currentAccount}>
                      <OptInPage />
                    </CurrentAccountProvider>
                  )}
                />
              </div>
              <Route component={Purchase} />
            </Switch>
          </BrowserRouter>
        </PortalProvider>
      </EnvVariablesProvider>
    </Provider>
  );
};

const currentAccountShape = {
  name: PropTypes.string
};

AppPublic.defaultProps = {
  currentAccount: {}
};

AppPublic.propTypes = {
  currentAccount: PropTypes.shape(currentAccountShape),
  env: PropTypes.objectOf(PropTypes.string).isRequired
};

export default AppPublic;
