import styled from 'styled-components';

const Header = styled.header`
  margin-top: -64px;
  margin-bottom: 30px;
`;

const Styled = {
  Header
};

export default Styled;
