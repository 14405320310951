import moment from 'moment-timezone';

const TWO_HOURS = 60 * 2;
const ONE_WEEK = 60 * 24 * 7;

const formatTimestamp = (
  timestamp,
  timeZone,
  fromNow = false,
  format = 'MMMM Do [at] h:mm A',
  useFormat = false,
  ignoreFormatForToday = false
) => {
  const localTimeStamp = moment.tz(timestamp, timeZone);
  const now = moment.tz(timeZone);
  const minutesAgo = Math.abs(now.diff(localTimeStamp, 'minutes'));
  const today = localTimeStamp.isSame(now, 'day');

  if ((minutesAgo < TWO_HOURS || fromNow) && !useFormat) {
    return localTimeStamp.fromNow();
  }
  if (minutesAgo >= TWO_HOURS && minutesAgo <= ONE_WEEK && !useFormat) {
    return localTimeStamp.calendar();
  }
  if (today && useFormat && ignoreFormatForToday) {
    return localTimeStamp.format('h:mm A');
  }
  return localTimeStamp.format(format);
};

const buildDateTimeWithTimezone = (date, time, timeZone) => {
  const timeZonedDate = moment(date);
  const timeSplit = time.split(':');
  const minuteSplit = timeSplit[1].split(' ');
  const hour = timeSplit[0];
  const minute = minuteSplit[0];
  const meridiemDistinction = minuteSplit[1];

  let meridiemHour = hour;
  if (meridiemDistinction === 'PM') {
    meridiemHour = hour !== '12' ? parseInt(hour, 10) + 12 : meridiemHour;
  } else {
    meridiemHour = hour !== '12' ? meridiemHour : '00';
  }

  return timeZonedDate
    .set({
      hour: meridiemHour,
      minute,
      second: 0,
      millisecond: 0
    })
    .tz(timeZone, true)
    .format();
};

const getPreviousDay = () => {
  const date = new Date();
  date.setDate(date.getDate() - 1);
  return date;
};

export { formatTimestamp, buildDateTimeWithTimezone, getPreviousDay };
