import React from 'react';
import { object, select } from '@storybook/addon-knobs';
import TextLoader from './index';

export default {
  component: TextLoader,
  title: 'Text Loader'
};

let timeSelect;
const loaderText = [
  'Step 1',
  'Step 2',
  'Step 3',
  'Step 4',
  'Step 5',
  'Step 6',
  'Step 7',
  'Step 8'
];

function withTimeIntervalSelect(defaultValue) {
  const label = 'Time Interval';
  const options = [1, 2, 3, 4, 5];
  timeSelect = select(label, options, defaultValue);
}

export const Standard = () => {
  withTimeIntervalSelect(1);
  return (
    <TextLoader
      loaderText={object('Loading Steps', loaderText)}
      intervalSeconds={timeSelect}
    />
  );
};
