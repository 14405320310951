/* eslint-disable no-underscore-dangle */
import React, {
  Fragment,
  useEffect,
  useState,
  useRef,
  useLayoutEffect
} from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';
import { titleize } from 'lib/utils/string';
import Toggle from 'components/Theme/Toggle';
import Badge from 'components/Theme/Badge';
import Button from '../Button';
import Select from '../Select';
import ButtonGroup from '../ButtonGroup';
import PopOver from '../PopOver';
import Tooltip from '../Tooltip';

const Table = ({
  title,
  subtitle,
  rows,
  rowConditionals,
  includedColumns,
  editable,
  onEdit,
  onView,
  deletable,
  viewable,
  onDelete,
  emptyMessage,
  hasFooter,
  footerAction,
  footerActionText,
  FooterComponent,
  hasActionSelect,
  hasRowActionSelect,
  onSelectActionChange,
  selectOptions,
  hasStatus,
  handleDocumentClick,
  hasActionButton,
  btnProps,
  hasActionButtonPerRecord,
  buttonActionProps,
  hasToggleAction,
  onClickToggleBtn,
  toggleUsingData,
  size,
  color,
  hasToggleActionPerRecord,
  disabledTogglePositions,
  customActionHeader,
  popOverInfo,
  showStatusColor,
  isSticky,
  rowIndexOffset,
  customTableClass,
  validationKey,
  fixedColumnKey,
  fixedColumnWidth,
  onRowClick,
  showColumnBadge,
  badgeColumnName,
  customActionKeyName,
  disabledTooltipText,
  showColumnValueTitle,
  showColumnTooltip,
  tooltipColumnName,
  customToggleActionKeyName
}) => {
  const [filteredRows, setFilteredRows] = useState([]);
  const [clientWidth, setClientWidth] = useState([]);

  useEffect(() => {
    // eslint-disable-next-line no-underscore-dangle
    const filterDestroyedRows = rows.filter(r => !r._destroy);
    setFilteredRows(filterDestroyedRows);
  }, [rows]);

  const containerRef = useRef();
  const styleObject = {
    right: 0,
    position: 'absolute',
    width: fixedColumnWidth,
    marginTop: 0.5,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  };

  function updateSize() {
    setClientWidth(containerRef.current.clientWidth - fixedColumnWidth);
  }

  useLayoutEffect(() => {
    if (fixedColumnKey) {
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }
  }, []);

  const headerColumns = Array.isArray(includedColumns)
    ? includedColumns
    : Object.values(includedColumns);

  const tableRowColumns = Array.isArray(includedColumns)
    ? includedColumns
    : Object.keys(includedColumns);

  function getToggleBtnState(data) {
    const btnState = Array.isArray(data)
      ? data[1][customToggleActionKeyName]
      : data[customToggleActionKeyName];
    return btnState !== undefined ? btnState : true;
  }

  function getTableHeader(value) {
    const newValue = (
      <div className="tw-table-row">
        <div className="tw-float-left">{value}</div>
        <div className="tw-float-left">
          <PopOver headerContent isSticky={isSticky} />
        </div>
      </div>
    );

    const thStyle =
      value === fixedColumnKey ? { ...styleObject, padding: 20 } : null;

    return (
      <th
        key={value}
        className={`tw-font-body tw-px-6 tw-py-3 tw-border-b tw-border-t-0 tw-border-l-0 tw-border-r-0 tw-border-solid tw-border-gray-200 tw-bg-gray-50 tw-text-left tw-text-xs tw-leading-4 tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider ${
          isSticky ? 'sticky-header' : ''
        }`}
        style={thStyle}
      >
        {titleize(value === popOverInfo ? newValue : value, '_')}
      </th>
    );
  }

  function getRowSelectOptions(row) {
    return row === undefined || !row.selectOptions ? [] : row.selectOptions;
  }

  function getSelectedOption(row) {
    return row === undefined ? '' : row.selected;
  }

  function getRowWithSelectOption(row) {
    return Array.isArray(row)
      ? row.find(element => element.selectOptions)
      : row;
  }

  function getSelectBoxForRow(row) {
    const rowWithSelect = getRowWithSelectOption(row);
    const selectId = rowWithSelect.id;
    const rowSelectOptions = hasRowActionSelect
      ? getRowSelectOptions(rowWithSelect)
      : selectOptions;
    return (
      <td
        data-testid={`table-select-box-${row?.id}`}
        className="tw-font-body tw-whitespace-no-wrap tw-border-b tw-border-t-0 tw-border-l-0 tw-border-r-0 tw-border-solid tw-border-gray-200 tw-text-sm tw-text-gray-500 tw-px-6 tw-py-2"
      >
        <Select
          id={`select_${selectId}`}
          options={rowSelectOptions}
          placeholder={rowWithSelect.selectPlaceHolder}
          onChange={onSelectActionChange}
          labelText="Type"
          type="text"
          hideLabel
          widthClass="tw-max-w-xs"
          value={getSelectedOption(rowWithSelect)}
        />
      </td>
    );
  }

  function getButtonGroupForRow(index, row) {
    return (
      <td
        data-testid={`table-button-group-${row?.id}`}
        className="tw-font-body tw-whitespace-no-wrap tw-border-b tw-border-t-0 tw-border-l-0 tw-border-r-0 tw-border-solid tw-border-gray-200 tw-text-sm tw-text-gray-500 tw-px-6 tw-py-2 tw-w-24"
      >
        <ButtonGroup btnProps={btnProps} btnRef={{ index, row }} />
      </td>
    );
  }

  function getToggleActionForRow(row, btnIndex) {
    return (
      <td
        data-testid={`table-toggle-action-${row?.id}`}
        className="tw-font-body tw-whitespace-no-wrap tw-border-b tw-border-t-0 tw-border-l-0 tw-border-r-0 tw-border-solid tw-border-gray-200 tw-text-sm tw-text-gray-500 tw-px-6 tw-py-2 tw-w-24"
      >
        <Toggle
          size={size}
          color={color}
          withIcons
          onClick={onClickToggleBtn}
          btnRef={{ btnIndex, row }}
          isToggled={getToggleBtnState(row)}
          toggleUsingData={toggleUsingData}
        />
      </td>
    );
  }

  function getButtonActionPerRecord(row, rowIndex) {
    return (
      <td
        key={`${row && row.id ? row.id : row._id}-action-buttons`}
        className="tw-font-body tw-whitespace-no-wrap tw-border-b tw-border-t-0 tw-border-l-0 tw-border-r-0 tw-border-solid tw-border-gray-200 tw-text-sm tw-text-gray-500 tw-px-6 tw-py-2 tw-w-24"
      >
        {row.map((item, index) => {
          const btnProp =
            buttonActionProps[index] != null
              ? buttonActionProps[index]
              : buttonActionProps.slice(-1)[0];
          return (
            <div
              key={`${item && item.id ? item.id : item._id}-action-div`}
              style={{ padding: '3px 0px' }}
            >
              <Button
                text={btnProp.text}
                type={item.connect ? 'gamma' : btnProp.type}
                color={btnProp.color}
                size={btnProp.size}
                className="tw-bg-white tw-text-alpha-500`"
                isFullWidth
                onClick={
                  btnProp.action
                    ? () => btnProp.action(row, item, rowIndex)
                    : null
                }
              />
            </div>
          );
        })}
      </td>
    );
  }

  function toggleBtn(row, childIndex, btnIndex) {
    const parentIndex = btnIndex + rowIndexOffset;
    return !disabledTogglePositions.includes(childIndex) ? (
      <Toggle
        size={size}
        color={color}
        onClick={onClickToggleBtn}
        isToggled={getToggleBtnState(row)}
        btnRef={{ parentIndex, row }}
        toggleUsingData={toggleUsingData}
        withIcons
      />
    ) : (
      <div style={{ padding: '10px 0px' }} />
    );
  }

  function hyphenize(str) {
    return str.replace(/ +/g, '-');
  }

  function getStatusColorElement(status) {
    const cls =
      status && status !== 'None' ? hyphenize(status).toLowerCase() : 'default';
    return (
      <div
        className={`tw-rounded-full tw-w-2 tw-h-2 tw-inline-block tw-mr-1 bg-for-${cls}-status`}
      />
    );
  }

  function showBadge(val, row) {
    return showColumnBadge && badgeColumnName === val ? (
      <div data-for={`tool-badge-${row.id?.toString()}`} data-tip>
        <Badge
          badgeStyle={{ marginTop: '0' }}
          color={row.status_color}
          badgeClass="tw-justify-between tw-mb-1"
          hasDot={false}
          onClick={null}
          shape="round"
          size="small"
          textStyle={{}}
          value={row[val]}
        />
      </div>
    ) : (
      row[val]
    );
  }

  function showTooltip(val, row) {
    return showColumnTooltip && row[tooltipColumnName] ? (
      <Tooltip
        id={`tool-badge-${row.id}`}
        place="top"
        classNames="tw-bg-white"
        paddingClass="tw-p-2"
        widthClass="tw-max-w-lg"
        TooltipElement={() => {
          return showBadge(val, row);
        }}
      >
        <p className="tw-text-gray-500 tw-text-xs tw-font-normal tw-bg-white tw-mb-0">
          {row[tooltipColumnName]}
        </p>
      </Tooltip>
    ) : (
      showBadge(val, row)
    );
  }

  function getToggleActionPerRecord(row, rowIndex) {
    return (
      <td
        key={`${row && row.id ? row.id : row._id}-action-buttons`}
        className="tw-font-body tw-whitespace-no-wrap tw-border-b tw-border-t-0 tw-border-l-0 tw-border-r-0 tw-border-solid tw-border-gray-200 tw-text-sm tw-text-gray-500 tw-px-6 tw-py-2 tw-w-24"
      >
        <div className="tw-py-3">
          {row.map((item, index) => {
            return (
              <div
                key={`${item && item.id ? item.id : item._id}-action-div`}
                style={{ padding: '5px 0px' }}
              >
                {toggleBtn(item, index, rowIndex)}
              </div>
            );
          })}
        </div>
      </td>
    );
  }

  function getTableRow(row, rowIndex) {
    const rowColor = row[validationKey]?.length ? 'red' : 'gray';
    const isRowEditable = row[customActionKeyName] === 'editable';
    const isRowViewable = row[customActionKeyName] === 'viewable';
    const isRowDeletable = row[customActionKeyName] === 'deletable';
    return (
      <tr
        key={`${row && row.id ? row.id : row._id}-action-button`}
        onClick={() => {
          onRowClick(row, rowIndex);
        }}
      >
        {tableRowColumns.map((val, index) => {
          if (customActionKeyName && val === customActionKeyName) {
            return false;
          }

          const rowConditional = rowConditionals(row[val], row.id);
          const tdStyle = val === fixedColumnKey ? styleObject : null;

          return (
            <td
              key={`${row && row.id ? row.id : row && row._id}-${val}`}
              className={`tw-font-body tw-px-6 tw-py-4 tw-whitespace-no-wrap tw-border-b tw-border-t-0 tw-border-l-0 tw-border-r-0 tw-border-solid tw-border-gray-200 tw-text-sm tw-leading-5 ${
                index === 0
                  ? `tw-font-medium tw-text-${rowColor}-900`
                  : `tw-text-${rowColor}-500`
              }`}
              style={tdStyle}
              title={showColumnValueTitle ? row[val] : ''}
            >
              {showStatusColor && hasStatus && val === 'status'
                ? getStatusColorElement(row[val])
                : null}
              {!isEmpty(rowConditional) &&
              Object.keys(rowConditional).includes(val)
                ? rowConditional[val]
                : showTooltip(val, row)}
            </td>
          );
        })}
        {editable ||
        deletable ||
        viewable ||
        isRowEditable ||
        isRowViewable ||
        isRowDeletable ? (
          <td
            key={`${row && row.id ? row.id : row._id}-action-buttons`}
            className="tw-px-6 tw-py-4 tw-whitespace-no-wrap tw-text-right tw-border-b tw-border-t-0 tw-border-l-0 tw-border-r-0 tw-border-solid tw-border-gray-200 tw-text-sm tw-leading-5 tw-font-medium"
          >
            {(editable || isRowEditable) && (
              <a
                href="#"
                className="tw-font-body tw-cursor-pointer tw-mx-2 tw-text-alpha-600 hover:tw-text-alpha-900"
                onClick={() => onEdit(row.id ? row.id : row._id)}
              >
                Edit
              </a>
            )}
            {deletable &&
              (!(isRowDeletable && disabledTooltipText) ? (
                <a
                  className="tw-font-body tw-mx-2"
                  data-provide="tooltip"
                  title={disabledTooltipText}
                  disabled
                >
                  Delete
                </a>
              ) : (
                <a
                  href="#"
                  className="tw-font-body tw-cursor-pointer tw-mx-2 tw-text-alpha-600 hover:tw-text-alpha-900"
                  onClick={() => onDelete(row.id ? row.id : row._id)}
                >
                  Delete
                </a>
              ))}
            {(viewable || isRowViewable) && (
              <a
                href="#"
                className="tw-font-body tw-cursor-pointer tw-mx-2 tw-text-alpha-600 hover:tw-text-alpha-900"
                onClick={() =>
                  onView
                    ? onView(row.id ? row.id : row._id)
                    : handleDocumentClick(row.fileUrl ? row.fileUrl : null)
                }
              >
                View
              </a>
            )}
          </td>
        ) : null}
        {hasActionSelect || hasRowActionSelect ? getSelectBoxForRow(row) : null}
        {hasActionButton ? getButtonGroupForRow(rowIndex, row) : null}
        {hasToggleAction ? getToggleActionForRow(row, rowIndex) : null}
      </tr>
    );
  }

  function getTableRowForMultiRecords(row, rowIndex) {
    return (
      <tr key={row && row[0] && row[0].id ? row[0].id : row[0]._id}>
        {tableRowColumns.map((val, index) => {
          const getRowValue = row.map(item => {
            const rowConditional = rowConditionals(item[val]);
            return (
              <div
                key={`${item && item.id ? item.id : item && item._id}-${
                  item[val]
                }`}
                className="tw-px-6 tw-py-2"
              >
                {showStatusColor && hasStatus && val === 'status'
                  ? getStatusColorElement(item[val])
                  : null}
                {!isEmpty(rowConditional) &&
                Object.keys(rowConditional).includes(val)
                  ? rowConditional[val]
                  : item[val]}
              </div>
            );
          });
          return (
            <td
              key={`${
                row && row[index] && row[index].id
                  ? row[index].id
                  : row && row[index] && row[index]._id
              }-${val}`}
              className={`tw-font-body tw-whitespace-no-wrap tw-border-b tw-border-t-0 tw-border-l-0 tw-border-r-0 tw-border-solid tw-border-gray-200 tw-text-sm ${
                index === 0
                  ? 'tw-font-medium tw-text-gray-900'
                  : 'tw-text-gray-500'
              }`}
            >
              <div className="tw-py-3">{getRowValue}</div>
            </td>
          );
        })}
        {hasActionButtonPerRecord
          ? getButtonActionPerRecord(row, rowIndex)
          : null}
        {hasActionSelect || hasRowActionSelect ? getSelectBoxForRow(row) : null}
        {hasActionButton ? getButtonGroupForRow(rowIndex, row) : null}
        {hasToggleAction ? getToggleActionForRow(row, rowIndex) : null}
        {hasToggleActionPerRecord
          ? getToggleActionPerRecord(row, rowIndex)
          : null}
      </tr>
    );
  }

  function getTableContent(style) {
    return (
      <div className={customTableClass} style={style}>
        <table className="tw-min-w-full tw-min-h-100">
          {!isEmpty(headerColumns) && !isEmpty(filteredRows) ? (
            <thead>
              <tr>
                {headerColumns.map(val => getTableHeader(val))}
                {editable || deletable || viewable ? (
                  <th
                    key="action-buttons-header"
                    className="tw-px-6 tw-py-3 tw-border-b tw-border-t-0 tw-border-l-0 tw-border-r-0 tw-border-solid tw-border-gray-200 tw-bg-gray-50"
                  />
                ) : null}
                {hasActionSelect ||
                hasRowActionSelect ||
                hasActionButton ||
                hasActionButtonPerRecord ||
                hasToggleAction ||
                hasToggleActionPerRecord
                  ? getTableHeader(customActionHeader || 'Action')
                  : null}
              </tr>
            </thead>
          ) : null}
          <tbody
            className={`tw-bg-white ${
              isEmpty(filteredRows)
                ? 'tw-flex tw-justify-center tw-items-center tw-min-h-100'
                : ''
            }`}
          >
            {!isEmpty(filteredRows) ? (
              filteredRows.map((row, rowIndex) =>
                !Array.isArray(row)
                  ? getTableRow(row, rowIndex)
                  : getTableRowForMultiRecords(row, rowIndex)
              )
            ) : (
              <tr>
                <td className="tw-font-body tw-font-medium tw-text-sm tw-text-gray-700">
                  {emptyMessage}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  }

  function getTable() {
    if (fixedColumnKey) {
      return (
        <div
          data-testid="table-fixed-column"
          ref={containerRef}
          className="tw-relative"
        >
          {getTableContent({ maxWidth: clientWidth })}
        </div>
      );
    }
    return getTableContent();
  }

  return (
    <div className="tw-flex tw-flex-col tw-mx-auto">
      <div
        className={`tw--my-2 tw-py-2 sm:tw--mx-6 tw-px-6 lg:tw--mx-8 lg:tw-px-8 ${
          isSticky ? '' : 'tw-overflow-x-auto'
        }`}
      >
        <div
          className={`tw-align-middle tw-inline-block tw-min-w-full tw-shadow tw-rounded-lg tw-border-b tw-border-gray-200 ${
            isSticky ? '' : 'tw-overflow-hidden'
          }`}
        >
          {title || subtitle ? (
            <div className="tw-bg-white tw-px-4 tw-py-5 tw-border-b tw-border-t-0 tw-border-l-0 tw-border-r-0 tw-border-solid tw-border-gray-200 sm:tw-px-6">
              {title && (
                <h3 className="tw-font-body tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
                  {title}
                </h3>
              )}
              {subtitle && (
                <p className="tw-font-body tw-mt-1 tw-mb-0 tw-text-sm tw-leading-5 tw-text-gray-500">
                  {subtitle}
                </p>
              )}
            </div>
          ) : null}
          {getTable()}
          {hasFooter ? (
            <Fragment>
              {footerAction && footerActionText ? (
                <div className="tw-px-4 tw-py-3 tw-bg-gray-50 tw-text-right sm:tw-px-6">
                  <span className="tw-font-body tw-cursor-pointer tw-inline-flex tw-rounded-md tw-shadow-sm">
                    {footerActionText && footerAction ? (
                      <Button text={footerActionText} onClick={footerAction} />
                    ) : null}
                  </span>
                </div>
              ) : null}
              {FooterComponent ? (
                <Fragment>
                  {typeof FooterComponent === 'function' ? (
                    FooterComponent()
                  ) : (
                    <FooterComponent />
                  )}
                </Fragment>
              ) : null}
            </Fragment>
          ) : null}
        </div>
      </div>
    </div>
  );
};

const optionShape = PropTypes.shape({
  displayName: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
});

const buttonShape = PropTypes.shape({
  color: PropTypes.string,
  text: PropTypes.string,
  action: PropTypes.func
});

Table.defaultProps = {
  title: '',
  subtitle: '',
  rows: [],
  rowConditionals: () => null,
  includedColumns: [],
  editable: false,
  viewable: false,
  onEdit: null,
  onView: null,
  deletable: false,
  onDelete: null,
  hasFooter: false,
  footerAction: null,
  footerActionText: '',
  FooterComponent: null,
  hasActionSelect: false,
  hasRowActionSelect: false,
  onSelectActionChange: () => null,
  handleDocumentClick: () => null,
  selectOptions: [],
  hasStatus: false,
  hasActionButton: false,
  btnProps: [],
  hasActionButtonPerRecord: false,
  buttonActionProps: [],
  hasToggleAction: false,
  size: 'large',
  color: 'alpha',
  hasToggleActionPerRecord: false,
  customActionHeader: null,
  popOverInfo: null,
  onClickToggleBtn: () => null,
  toggleUsingData: true,
  disabledTogglePositions: [],
  showStatusColor: false,
  isSticky: false,
  rowIndexOffset: 0,
  customTableClass: '',
  validationKey: null,
  fixedColumnKey: null,
  fixedColumnWidth: 500,
  onRowClick: () => null,
  showColumnBadge: false,
  badgeColumnName: '',
  customActionKeyName: '',
  disabledTooltipText: '',
  showColumnValueTitle: false,
  showColumnTooltip: false,
  tooltipColumnName: '',
  customToggleActionKeyName: 'connect'
};

Table.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  rows: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.array])
  ),
  rowConditionals: PropTypes.func,
  includedColumns: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  editable: PropTypes.bool,
  handleDocumentClick: PropTypes.func,
  viewable: PropTypes.bool,
  onEdit: PropTypes.func,
  onView: PropTypes.func,
  deletable: PropTypes.bool,
  onDelete: PropTypes.func,
  emptyMessage: PropTypes.string.isRequired,
  hasFooter: PropTypes.bool,
  footerAction: PropTypes.func,
  footerActionText: PropTypes.string,
  FooterComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  hasActionSelect: PropTypes.bool,
  hasRowActionSelect: PropTypes.bool,
  onSelectActionChange: PropTypes.func,
  selectOptions: PropTypes.arrayOf(optionShape),
  hasStatus: PropTypes.bool,
  hasActionButton: PropTypes.bool,
  btnProps: PropTypes.arrayOf(buttonShape),
  hasActionButtonPerRecord: PropTypes.bool,
  buttonActionProps: PropTypes.arrayOf(buttonShape),
  hasToggleAction: PropTypes.bool,
  size: PropTypes.string,
  color: PropTypes.string,
  hasToggleActionPerRecord: PropTypes.bool,
  customActionHeader: PropTypes.string,
  popOverInfo: PropTypes.string,
  onClickToggleBtn: PropTypes.func,
  toggleUsingData: PropTypes.bool,
  disabledTogglePositions: PropTypes.arrayOf(PropTypes.number),
  showStatusColor: PropTypes.bool,
  isSticky: PropTypes.bool,
  rowIndexOffset: PropTypes.number,
  customTableClass: PropTypes.string,
  validationKey: PropTypes.string,
  fixedColumnKey: PropTypes.string,
  fixedColumnWidth: PropTypes.number,
  onRowClick: PropTypes.func,
  showColumnBadge: PropTypes.bool,
  badgeColumnName: PropTypes.string,
  customActionKeyName: PropTypes.string,
  disabledTooltipText: PropTypes.string,
  showColumnValueTitle: PropTypes.bool,
  showColumnTooltip: PropTypes.bool,
  tooltipColumnName: PropTypes.string,
  customToggleActionKeyName: PropTypes.string
};

export default Table;
