import React from 'react';
import Icon from '../index';
import iconPropTypes from '../iconPropTypes';
import iconDefaultProps from '../iconDefaultProps';

const NotificationIcon = ({ color, tint, filled, onClick, width, height }) => {
  return (
    <Icon
      color={color}
      tint={tint}
      onClick={onClick}
      filled={filled}
      width={width}
      height={height}
      pathDefinition={
        filled
          ? 'M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z'
          : 'M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z'
      }
    />
  );
};

NotificationIcon.defaultProps = iconDefaultProps;
NotificationIcon.propTypes = iconPropTypes;

export default NotificationIcon;
