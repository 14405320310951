/* Fetch Lead Sources */

export const FETCH_LEAD_SOURCES = 'lead_source/FETCH_LEAD_SOURCES';
export const FETCH_LEAD_SOURCES_REQUEST = `${FETCH_LEAD_SOURCES}_REQUEST`;
export const FETCH_LEAD_SOURCES_SUCCESS = `${FETCH_LEAD_SOURCES}_SUCCESS`;
export const FETCH_LEAD_SOURCES_FAILURE = `${FETCH_LEAD_SOURCES}_FAILURE`;

export const CREATE_LEAD_SOURCE = 'lead_source/CREATE_LEAD_SOURCE';
export const CREATE_LEAD_SOURCE_REQUEST = `${CREATE_LEAD_SOURCE}_REQUEST`;
export const CREATE_LEAD_SOURCE_SUCCESS = `${CREATE_LEAD_SOURCE}_SUCCESS`;
export const CREATE_LEAD_SOURCE_FAILURE = `${CREATE_LEAD_SOURCE}_FAILURE`;

export const BULK_UPDATE_CONTACTS = 'lead_source/BULK_UPDATE_CONTACTS';
export const BULK_UPDATE_CONTACTS_REQUEST = `${BULK_UPDATE_CONTACTS}_REQUEST`;
export const BULK_UPDATE_CONTACTS_SUCCESS = `${BULK_UPDATE_CONTACTS}_SUCCESS`;
export const BULK_UPDATE_CONTACTS_FAILURE = `${BULK_UPDATE_CONTACTS}_FAILURE`;
