export default function extractFileName(file) {
  if (file instanceof File) {
    return file.name;
  }
  if (file && file.url) {
    // eslint-disable-next-line no-useless-escape
    const match = /[^/\\&\?]+\.\w{3,4}(?=([\?&].*$|$))/.exec(file.url);
    const filename = match !== null ? match[0] : '';
    return filename;
  }
  return '';
}
