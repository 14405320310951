import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Button from 'components/Theme/Button';
import Modal from 'components/Theme/Modal';
import { formatTimestamp } from 'lib/utils/dateTime';
import {
  downgradeSubscription,
  resetIsLoaded
} from 'appState/actions/ActionCreators';
import {
  createErrorMessageSelector,
  createLoadedSelector,
  createLoadingSelector
} from 'appState/selectors';
import { createStructuredSelector } from 'reselect';
import StepOne from 'components/Subscriptions/DowngradePlan/StepOne';
import StepTwo from 'components/Subscriptions/DowngradePlan/StepTwo';
import StepThree from 'components/Subscriptions/DowngradePlan/StepThree';
import isEmpty from 'lodash.isempty';
import Notification from 'components/Theme/Notification';

const DowngradePlan = ({
  plan,
  currentPlan,
  mySubscription,
  productSubscriptions,
  currentTimezone
}) => {
  const errorSelector = createErrorMessageSelector([
    'subscription/DOWNGRADE_SUBSCRIPTION'
  ]);
  const loadedSelector = createLoadedSelector([
    'subscription/DOWNGRADE_SUBSCRIPTION'
  ]);
  const loadingSelector = createLoadingSelector([
    'subscription/DOWNGRADE_SUBSCRIPTION'
  ]);

  const structuredSelector = createStructuredSelector({
    error: state => errorSelector(state),
    isLoaded: state => loadedSelector(state),
    isLoading: state => loadingSelector(state)
  });

  const { error, isLoaded, isLoading } = useSelector(structuredSelector);
  const [downgradeWarning, setDowngradeWarning] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleDowngradeWarning = () => {
    setDowngradeWarning(true);
  };
  const [selectedProducts, setSelectedProducts] = useState([]);
  const nonLeadProduct = productSubscriptions.filter(
    f => f.product.lifecycles.join('') !== ['lead'].join('')
  );

  const getProductsRequiredToRemoveCount = () => {
    if (plan?.tier === 'basic') return nonLeadProduct.length;

    return nonLeadProduct.length > plan.max_products
      ? nonLeadProduct.length - plan.max_products
      : 0;
  };

  const productsRequiredToRemoveCount = getProductsRequiredToRemoveCount();

  function nextBillingDate() {
    return formatTimestamp(
      mySubscription.expires_at,
      currentTimezone,
      false,
      'MM/DD/YYYY',
      true
    );
  }
  const dispatch = useDispatch();
  const handleDowngradePlan = () => {
    const subscriptionParams = {};
    subscriptionParams.deletedProductIds = selectedProducts;
    subscriptionParams.newPlanId = plan.id;
    dispatch(downgradeSubscription(subscriptionParams));
  };
  const handleBtnDisableVal = () => {
    return selectedProducts.length < productsRequiredToRemoveCount;
  };

  function handleSetShowError(show) {
    setShowError(show);
  }

  useEffect(() => {
    if (isLoaded && isEmpty(error)) {
      setDowngradeWarning(false);
      setShowModal(false);
      setShowSuccess(true);
    }
    if (isLoaded && !isEmpty(error)) {
      setShowError(true);
    }
    dispatch(resetIsLoaded('subscription/DOWNGRADE_SUBSCRIPTION'));
    return () => {
      dispatch(resetIsLoaded('subscription/DOWNGRADE_SUBSCRIPTION'));
    };
  }, [error, isLoaded, dispatch]);

  function getStepsForDowngrade() {
    const stepsArray = [
      {
        buttonText: 'Next',
        content: <StepOne plan={plan} currentPlan={currentPlan} />,
        id: 2,
        text: 'step one'
      },
      {
        buttonText: 'Submit',
        content: (
          <StepThree
            plan={plan}
            productSubscriptions={nonLeadProduct}
            currentTimezone={currentTimezone}
            mySubscription={mySubscription}
            nextBillingDate={nextBillingDate}
            selectedProducts={selectedProducts}
          />
        ),
        id: 3,
        text: 'step Three',
        action: handleDowngradePlan
      }
    ];
    if (productsRequiredToRemoveCount < 1) return stepsArray;

    return [
      {
        buttonText: 'I still want to downgrade',
        content: (
          <StepTwo
            currentPlan={currentPlan}
            productSubscriptions={nonLeadProduct}
            currentTimezone={currentTimezone}
            onChange={setSelectedProducts}
            productsRequiredToRemoveCount={productsRequiredToRemoveCount}
            selectedRequiredAmount={
              productsRequiredToRemoveCount === selectedProducts.length
            }
            selectedProducts={selectedProducts}
          />
        ),
        id: 1,
        isBtnDisable: handleBtnDisableVal(),
        text: 'step two'
      }
    ].concat(stepsArray);
  }
  return (
    <Fragment>
      <Button
        text="Get Now"
        type="primary"
        isFullWidth
        disabled={!isEmpty(mySubscription.cancellation_submitted_at)}
        onClick={
          mySubscription.comped ? handleDowngradeWarning : handleShowModal
        }
        isLoaded={!isLoading}
      />
      <Modal
        color="alpha"
        headerText="Downgrade Plan"
        primaryAction={handleShowModal}
        showHeaderIcon={false}
        primaryActionText="Downgrade"
        secondaryActionText="Cancel"
        showSecondaryAction
        setShow={setDowngradeWarning}
        show={downgradeWarning}
        showPrimaryAction
        canHandleClickOutside={false}
        bodyClasses=""
      >
        <div className="tw-font-medium">
          You have a comped account. If you downgrade now, you will be unable to
          upgrade later. Are you sure you want to downgrade?
        </div>
      </Modal>
      <Modal
        bodyClasses=""
        canHandleClickOutside={false}
        color="alpha"
        headerClasses=""
        headerText="Change your UpLaunch Plan"
        innerContainerClasses="tw-px-4 tw-pt-5 tw-pb-4 sm:tw-p-6 sm:tw-pb-4"
        isOverflowHidden
        primaryActionText="Next"
        secondaryActionText="Cancel"
        selectedStep={0}
        setShow={setShowModal}
        show={showModal}
        showHeaderIcon={false}
        showPrimaryAction
        showSecondaryAction
        hideBullets
        steps={getStepsForDowngrade()}
      />
      <Notification
        message={error}
        show={showError}
        setShowAction={handleSetShowError}
        type="colored"
        headerText="Error!"
        color="error"
        HeaderIcon={color => {
          return (
            <svg
              className={`tw-h-5 tw-w-5 tw-text-${color}-400`}
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                clipRule="evenodd"
              />
            </svg>
          );
        }}
      />
      <Notification
        message="Plan is downgraded Successfully."
        show={showSuccess}
        setShowAction={setShowSuccess}
        type="colored"
        headerText="Success!"
        color="success"
        HeaderIcon={color => {
          return (
            <svg
              className={`tw-h-5 tw-w-5 tw-text-${color}-400`}
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                clipRule="evenodd"
              />
            </svg>
          );
        }}
      />
    </Fragment>
  );
};

DowngradePlan.defaultProps = {
  plan: () => null,
  currentPlan: () => null,
  productSubscriptions: () => null,
  currentTimezone: '',
  mySubscription: () => null
};

DowngradePlan.propTypes = {
  plan: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  ),
  currentPlan: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  ),
  mySubscription: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  ),
  productSubscriptions: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  ),
  currentTimezone: PropTypes.string
};

export default DowngradePlan;
