import React, { useContext, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { InPortal } from 'react-reverse-portal';
import { capitalize } from 'lib/utils/string';
import Transition from 'components/Transition';
import { PortalContext } from 'components/shared/context/PortalContext';

const Notification = ({
  message,
  type,
  headerText,
  color,
  HeaderIcon,
  show,
  setShowAction,
  showAlways,
  timeout,
  containerStyle,
  customizePadding
}) => {
  const portal = useContext(PortalContext);

  useEffect(() => {
    setShowAction(show);

    let notificationTimeout;
    if (timeout && show) {
      notificationTimeout = setTimeout(() => setShowAction(false), timeout);
    }
    return () => {
      if (timeout) clearTimeout(notificationTimeout);
    };
  }, [show, setShowAction, timeout]);

  const notificationComponent = () => {
    return (
      <div
        className={`tw-flex tw-pointer-events-none ${
          showAlways
            ? 'tw-items-start tw-py-3'
            : 'tw-fixed tw-inset-0 tw-items-end tw-justify-center sm:tw-items-start sm:tw-justify-end tw-z-1000 tw-px-4 tw-py-6 sm:tw-p-6'
        } `}
        style={containerStyle}
      >
        <div
          className={`tw-max-w-sm tw-w-full tw-pointer-events-auto ${
            showAlways ? 'tw-rounded-md' : 'tw-shadow-lg tw-rounded-lg'
          } ${type === 'standard' ? 'tw-bg-white' : `tw-bg-${color}-100`}`}
        >
          <div className="tw-rounded-lg tw-shadow-xs tw-overflow-hidden">
            <div className={`${customizePadding || 'tw-p-4'}`}>
              <div className="tw-flex tw-items-start">
                <div className="tw-flex-shrink-0">
                  {typeof HeaderIcon === 'function' ? (
                    HeaderIcon(color)
                  ) : (
                    <HeaderIcon color={color} />
                  )}
                </div>
                <div
                  className={`tw-ml-3 tw-w-0 tw-flex-1 ${
                    type === 'standard' ? 'tw-pt-0.5' : ''
                  }`}
                >
                  <p
                    className={`tw-font-body tw-my-0 tw-text-sm tw-leading-5 tw-font-medium ${
                      type === 'standard'
                        ? 'tw-text-gray-900'
                        : `tw-text-${color}-800`
                    }`}
                  >
                    {headerText ? capitalize(headerText) : null}
                  </p>
                  <p
                    className={`tw-font-body tw-mb-0 tw-text-sm tw-leading-5 ${
                      showAlways && !headerText ? 'tw-mt-0' : 'tw-mt-1'
                    } ${
                      type === 'standard'
                        ? 'tw-text-gray-500'
                        : `tw-text-${color}-700`
                    }`}
                  >
                    {message ? capitalize(message) : null}
                  </p>
                </div>
                {showAlways ? null : (
                  <div className="tw-ml-4 tw-flex-shrink-0 tw-flex">
                    <div
                      role="button"
                      tabIndex="-1"
                      onClick={() => setShowAction(false)}
                      onKeyDown={null}
                      className={`tw-cursor-pointer tw-inline-flex focus:tw-outline-none tw-transition tw-ease-in-out tw-duration-150 ${
                        type === 'standard'
                          ? 'tw-text-gray-400 focus:tw-outline-none focus:tw-text-gray-500'
                          : `tw-text-${color}-500 hover:tw-bg-${color}-100 focus:tw-bg-${color}-100`
                      }`}
                    >
                      <svg
                        className="tw-h-5 tw-w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      {showAlways
        ? notificationComponent()
        : portal?.notificationNode && (
            <Transition
              show={show}
              enter="tw-transform tw-ease-out tw-duration-300 tw-transition"
              enterFrom="tw-translate-y-2 tw-opacity-0 sm:tw-translate-y-0 sm:tw-translate-x-2"
              enterTo="tw-translate-y-0 tw-opacity-100 sm:tw-translate-x-0"
              leave="tw-transition tw-ease-in tw-duration-100"
              leaveFrom="tw-opacity-100"
              leaveTo="tw-opacity-0"
            >
              <InPortal node={portal.notificationNode}>
                {notificationComponent()}
              </InPortal>
            </Transition>
          )}
    </Fragment>
  );
};

Notification.defaultProps = {
  message: '',
  headerText: '',
  type: 'standard',
  color: 'gray',
  HeaderIcon: () => null,
  show: false,
  setShowAction: () => null,
  showAlways: false,
  timeout: 3000,
  containerStyle: {},
  customizePadding: ''
};

const styleProps = PropTypes.objectOf(
  PropTypes.oneOfType([PropTypes.string, PropTypes.number])
);

Notification.propTypes = {
  message: PropTypes.string,
  type: PropTypes.oneOf(['standard', 'colored']),
  headerText: PropTypes.string,
  HeaderIcon: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  color: PropTypes.oneOf([
    'alpha',
    'bravo',
    'charlie',
    'success',
    'warning',
    'error',
    'gray'
  ]),
  show: PropTypes.bool,
  setShowAction: PropTypes.func,
  showAlways: PropTypes.bool,
  timeout: PropTypes.number,
  containerStyle: styleProps,
  customizePadding: PropTypes.string
};

export default Notification;
