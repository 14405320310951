import { call, put, takeLatest, select } from 'redux-saga/effects';
import { axiosDefault } from 'lib/utils/axios-setup';
import get from 'lodash.get';
import isEmpty from 'lodash.isempty';
import { takeEveryDeduplicate } from 'lib/utils/redux-saga-extensions';
import {
  FETCH_SUBSCRIPTIONS_REQUEST,
  FETCH_SUBSCRIPTIONS_SUCCESS,
  FETCH_SUBSCRIPTIONS_FAILURE,
  FETCH_SUBSCRIPTION_REQUEST,
  FETCH_SUBSCRIPTION_SUCCESS,
  FETCH_SUBSCRIPTION_FAILURE,
  UPDATE_SUBSCRIPTION_REQUEST,
  UPDATE_SUBSCRIPTION_SUCCESS,
  UPDATE_SUBSCRIPTION_FAILURE,
  FETCH_PRODUCTS_REQUEST,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_FAILURE,
  PAGE_CHANGE_SUBSCRIPTIONS_REQUEST,
  PAGE_CHANGE_SUBSCRIPTIONS_SUCCESS,
  PAGE_CHANGE_SUBSCRIPTIONS_FAILURE,
  DOWNGRADE_SUBSCRIPTION_REQUEST,
  DOWNGRADE_SUBSCRIPTION_SUCCESS,
  DOWNGRADE_SUBSCRIPTION_FAILURE,
  FETCH_MY_SUBSCRIPTION_REQUEST,
  FETCH_MY_SUBSCRIPTION_SUCCESS,
  FETCH_MY_SUBSCRIPTION_FAILURE,
  UPGRADE_SUBSCRIPTION_REQUEST,
  UPGRADE_SUBSCRIPTION_SUCCESS,
  UPGRADE_SUBSCRIPTION_FAILURE,
  UPDATE_CARD_SUBSCRIPTION_REQUEST,
  UPDATE_CARD_SUBSCRIPTION_SUCCESS,
  UPDATE_CARD_SUBSCRIPTION_FAILURE,
  UPDATE_DEFAULT_PRODUCT_REQUEST,
  UPDATE_DEFAULT_PRODUCT_SUCCESS,
  UPDATE_DEFAULT_PRODUCT_FAILURE,
  CANCEL_DOWNGRADE_SUBSCRIPTION_REQUEST,
  CANCEL_DOWNGRADE_SUBSCRIPTION_SUCCESS,
  CANCEL_DOWNGRADE_SUBSCRIPTION_FAILURE,
  UNDO_CANCELLATION_SUBSCRIPTION_REQUEST,
  UNDO_CANCELLATION_SUBSCRIPTION_SUCCESS,
  UNDO_CANCELLATION_SUBSCRIPTION_FAILURE
} from 'appState/actions/constants/subscription.actions';

function* fetchSubscriptions({ payload: { planId = null, page = 1 } }) {
  try {
    const params = { page };
    if (planId) params.plan_id = planId;
    const response = yield call(axiosDefault.get, '/subscriptions', {
      params
    });
    const { isChangingPage } = yield select(state => state.subscription);
    const total = get(response, 'headers["total-count"]');

    yield put({
      type: isChangingPage
        ? PAGE_CHANGE_SUBSCRIPTIONS_SUCCESS
        : FETCH_SUBSCRIPTIONS_SUCCESS,
      payload: {
        subscriptions: response.data,
        page,
        total
      }
    });
  } catch (e) {
    const { isChangingPage } = yield select(state => state.subscription);
    yield put({
      type: isChangingPage
        ? PAGE_CHANGE_SUBSCRIPTIONS_FAILURE
        : FETCH_SUBSCRIPTIONS_FAILURE,
      payload: { error: e },
      error: true
    });
  }
}

function* fetchMySubscription() {
  try {
    const response = yield call(
      axiosDefault.get,
      '/subscriptions/my_subscription'
    );
    yield put({
      type: FETCH_MY_SUBSCRIPTION_SUCCESS,
      payload: {
        mySubscription: response.data
      }
    });
  } catch (e) {
    yield put({
      type: FETCH_MY_SUBSCRIPTION_FAILURE,
      payload: {
        error: e
      },
      error: true
    });
  }
}

function* downgradeMySubscription({
  payload: { deletedProductIds, newPlanId }
}) {
  try {
    const params = {
      deleted_product_ids: deletedProductIds,
      new_plan_id: newPlanId
    };
    const response = yield call(
      axiosDefault.post,
      '/subscriptions/downgrade_plan',
      params
    );
    yield put({
      type: DOWNGRADE_SUBSCRIPTION_SUCCESS,
      payload: {
        mySubscription: response.data.my_subscription
      }
    });
  } catch (e) {
    yield put({
      type: DOWNGRADE_SUBSCRIPTION_FAILURE,
      payload: {
        error: e
      },
      error: true
    });
  }
}

function* cancelDowngradeSubscriptionReq() {
  try {
    const response = yield call(
      axiosDefault.post,
      '/subscriptions/cancel_downgrade'
    );
    yield put({
      type: CANCEL_DOWNGRADE_SUBSCRIPTION_SUCCESS,
      payload: {
        mySubscription: response.data.my_subscription
      }
    });
  } catch (e) {
    yield put({
      type: CANCEL_DOWNGRADE_SUBSCRIPTION_FAILURE,
      payload: {
        error: e
      },
      error: true
    });
  }
}

function* undoCancellationSubscription({ payload: { subscriptionId } }) {
  try {
    const response = yield call(
      axiosDefault.patch,
      `/subscriptions/${subscriptionId}/undo_cancellation`
    );
    yield put({
      type: UNDO_CANCELLATION_SUBSCRIPTION_SUCCESS,
      payload: {
        mySubscription: response.data.my_subscription
      }
    });
  } catch (e) {
    yield put({
      type: UNDO_CANCELLATION_SUBSCRIPTION_FAILURE,
      payload: {
        error: e
      },
      error: true
    });
  }
}

function* fetchProducts() {
  try {
    const response = yield call(axiosDefault.get, '/products');

    yield put({
      type: FETCH_PRODUCTS_SUCCESS,
      payload: {
        products: response.data
      }
    });
  } catch (e) {
    yield put({
      type: FETCH_PRODUCTS_FAILURE,
      payload: {
        error: e
      },
      error: true
    });
  }
}

function* setDefaultProduct({
  payload: { defaultLeadProductId, defaultClientProductId }
}) {
  try {
    const params = {
      subscription: {
        default_lead_product_id: defaultLeadProductId,
        default_client_product_id: defaultClientProductId
      }
    };

    const response = yield call(
      axiosDefault.post,
      `subscriptions/update_default_product`,
      params
    );

    yield put({
      type: UPDATE_DEFAULT_PRODUCT_SUCCESS,
      payload: {
        mySubscription: response.data
      }
    });
  } catch (e) {
    yield put({
      type: UPDATE_DEFAULT_PRODUCT_FAILURE,
      payload: { error: e, fallbackError: 'Error updating default products.' },
      error: true
    });
  }
}

function* fetchSubscription({ payload: { subscriptionId } }) {
  try {
    const response = yield call(
      axiosDefault.get,
      `/subscriptions/${subscriptionId}`
    );
    yield put({
      type: FETCH_SUBSCRIPTION_SUCCESS,
      payload: {
        currentSubscription: response.data
      }
    });
  } catch (e) {
    yield put({
      type: FETCH_SUBSCRIPTION_FAILURE,
      payload: {
        error: e
      },
      error: true
    });
  }
}

function* updateCardSubscription({
  payload: {
    subscriptionId,
    stripeToken,
    cardBrand,
    cardExpMonth,
    cardExpYear,
    cardLast4
  }
}) {
  try {
    const params = {
      stripe_token: stripeToken,
      subscription_id: subscriptionId,
      card_brand: cardBrand,
      card_last4: cardLast4,
      card_expiration_year: cardExpYear,
      card_expiration_month: cardExpMonth
    };

    const response = yield call(axiosDefault.put, '/billing', params);
    yield put({
      type: UPDATE_CARD_SUBSCRIPTION_SUCCESS,
      payload: {
        currentSubscription: response.data
      }
    });
  } catch (e) {
    yield put({
      type: UPDATE_CARD_SUBSCRIPTION_FAILURE,
      payload: {
        error: e
      },
      error: true
    });
  }
}

function* updateSubscription({
  payload: {
    subscriptionId,
    productSubscriptions = null,
    stripeSubscriptionId = null,
    stripeCustomerId = null,
    expiresAt = null,
    comped = null,
    paymentFailedAt = null,
    becameDelinquentAt = null
  }
}) {
  try {
    const params = {
      subscription: {
        product_subscriptions_attributes: !isEmpty(productSubscriptions)
          ? productSubscriptions
          : [],
        ...(stripeSubscriptionId && {
          stripe_subscription_id: stripeSubscriptionId
        }),
        ...(stripeCustomerId && { stripe_customer_id: stripeCustomerId }),
        ...{ expires_at: expiresAt },
        ...(comped && { comped }),
        ...{ payment_failed_at: paymentFailedAt },
        ...{ became_delinquent_at: becameDelinquentAt }
      }
    };

    const response = yield call(
      axiosDefault.put,
      `/subscriptions/${subscriptionId}`,
      params
    );
    yield put({
      type: UPDATE_SUBSCRIPTION_SUCCESS,
      payload: {
        currentSubscription: response.data
      }
    });
  } catch (e) {
    yield put({
      type: UPDATE_SUBSCRIPTION_FAILURE,
      payload: {
        error: e
      },
      error: true
    });
  }
}

function* upgradeMySubscription({ payload: { newPlanId } }) {
  try {
    const response = yield call(
      axiosDefault.post,
      '/subscriptions/upgrade_plan',
      {
        new_plan_id: newPlanId
      }
    );
    yield put({
      type: UPGRADE_SUBSCRIPTION_SUCCESS,
      payload: {
        mySubscription: response.data.my_subscription
      }
    });
  } catch (e) {
    yield put({
      type: UPGRADE_SUBSCRIPTION_FAILURE,
      payload: {
        error: e
      },
      error: true
    });
  }
}

export function* subscriptionsFetch() {
  yield takeLatest(FETCH_SUBSCRIPTIONS_REQUEST, fetchSubscriptions);
}

export function* subscriptionFetch() {
  yield takeLatest(FETCH_SUBSCRIPTION_REQUEST, fetchSubscription);
}

export function* subscriptionUpdate() {
  yield takeLatest(UPDATE_SUBSCRIPTION_REQUEST, updateSubscription);
}

export function* productsFetch() {
  yield takeLatest(FETCH_PRODUCTS_REQUEST, fetchProducts);
}

export function* mySubscriptionFetch() {
  yield takeLatest(FETCH_MY_SUBSCRIPTION_REQUEST, fetchMySubscription);
}

export function* subscriptionCardUpdate() {
  yield takeLatest(UPDATE_CARD_SUBSCRIPTION_REQUEST, updateCardSubscription);
}

export function* subscriptionsPageChange() {
  yield takeEveryDeduplicate(
    PAGE_CHANGE_SUBSCRIPTIONS_REQUEST,
    fetchSubscriptions
  );
}

export function* upgradeSubscription() {
  yield takeLatest(UPGRADE_SUBSCRIPTION_REQUEST, upgradeMySubscription);
}

export function* downgradeSubscription() {
  yield takeLatest(DOWNGRADE_SUBSCRIPTION_REQUEST, downgradeMySubscription);
}

export function* setDefaultProductAction() {
  yield takeLatest(UPDATE_DEFAULT_PRODUCT_REQUEST, setDefaultProduct);
}

export function* cancelDowngradeSubscription() {
  yield takeEveryDeduplicate(
    CANCEL_DOWNGRADE_SUBSCRIPTION_REQUEST,
    cancelDowngradeSubscriptionReq
  );
}

export function* undoSubscriptionCancellation() {
  yield takeLatest(UNDO_CANCELLATION_SUBSCRIPTION_REQUEST, undoCancellationSubscription)
}
