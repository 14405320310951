import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { createHtmlPortalNode, OutPortal } from 'react-reverse-portal';
import { Provider } from 'react-redux';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import configureStore from 'appState/stores/configureStore';
import { setCurrentAccount } from 'appState/actions/ActionCreators';
import { axiosSetup } from 'lib/utils/axios-setup';
import { EnvVariablesProvider } from 'components/shared/context/EnvVariablesContext';
import { PortalProvider } from 'components/shared/context/PortalContext';
import { CurrentAccountProvider } from 'components/shared/context/CurrentAccountContext';
import { CurrentUserProvider } from 'components/shared/context/CurrentUserContext';
import OverlordLayout from 'components/Layout/OverlordLayout';
import OverlordDashboard from 'components/OverlordDashboard';
import PartnersIndex from 'components/Partners/Index';
import PartnerDashboard from 'components/Partners/Dashboard';
import IncludedFormSettings from 'components/Products/IncludedForms/Settings';
import IncludedFormBuilder from 'components/Products/IncludedForms/Builder';
import StaffEmailNotification from 'components/Products/IncludedForms/Settings/StaffEmailNotification';
import StaffSmsNotification from 'components/Products/IncludedForms/Settings/StaffSmsNotification';
import AddToCampaign from 'components/Products/IncludedForms/Settings/AddToCampaign';
import PlansIndex from './Plans';
import ProductBuilder from './Products/Builder';
import SubscriptionsIndex from './Subscriptions';
import FourOFour from './FourOFour';
import FlashMessages from './FlashMessages';
import BackGroundJobDashboard from './BackGroundJobDashboard';

const DEFAULT_PATH = '/overlord';

const store = configureStore();

const AppOverlord = props => {
  const { env, currentAccount, currentUser } = props;

  const baseUrl = env.API_BASE;
  const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
  // eslint-disable-next-line camelcase
  const { authentication_token } = currentUser;

  axiosSetup({ baseUrl, csrfToken, authentication_token });

  const [portalNodes, setPortalNodes] = useState({
    modalNode: null,
    notificationNode: null
  });
  const modalPortalNode = useMemo(() => createHtmlPortalNode(), []);
  const notificationPortalNode = useMemo(() => createHtmlPortalNode(), []);
  const buttonDropdownPortalNode = useMemo(() => createHtmlPortalNode(), []);

  useEffect(() => {
    if (modalPortalNode && notificationPortalNode && buttonDropdownPortalNode) {
      setPortalNodes({
        modalNode: modalPortalNode,
        notificationNode: notificationPortalNode,
        buttonDropdownNode: buttonDropdownPortalNode
      });
    }
  }, [modalPortalNode, notificationPortalNode, buttonDropdownPortalNode]);

  useEffect(() => {
    store.dispatch(setCurrentAccount(currentAccount));
  }, [currentAccount]);

  return (
    <div id="tailwind-selector-id">
      <div className="theme-app-default">
        <Provider store={store}>
          <EnvVariablesProvider value={env}>
            <PortalProvider value={portalNodes}>
              <FlashMessages />
              <BrowserRouter>
                <OverlordLayout>
                  <Switch>
                    <Redirect
                      exact
                      from={DEFAULT_PATH}
                      to={`${DEFAULT_PATH}/dashboard`}
                    />
                    <Route
                      path={`${DEFAULT_PATH}/dashboard`}
                      render={() => (
                        <CurrentAccountProvider value={currentAccount}>
                          <CurrentUserProvider value={currentUser}>
                            <OverlordDashboard />
                          </CurrentUserProvider>
                        </CurrentAccountProvider>
                      )}
                    />
                    <Route
                      path={`${DEFAULT_PATH}/plans`}
                      render={() => (
                        <CurrentAccountProvider value={currentAccount}>
                          <CurrentUserProvider value={currentUser}>
                            <PlansIndex />
                          </CurrentUserProvider>
                        </CurrentAccountProvider>
                      )}
                    />
                    <Route
                      path={`${DEFAULT_PATH}/partners/:partnerId/products/:productId/included_forms/:id/settings/staff_email_notification`}
                      render={() => (
                        <CurrentAccountProvider value={currentAccount}>
                          <CurrentUserProvider value={currentUser}>
                            <StaffEmailNotification />
                          </CurrentUserProvider>
                        </CurrentAccountProvider>
                      )}
                    />
                    <Route
                      path={`${DEFAULT_PATH}/partners/:partnerId/products/:productId/included_forms/:id/settings/staff_sms_notification`}
                      render={() => (
                        <CurrentAccountProvider value={currentAccount}>
                          <CurrentUserProvider value={currentUser}>
                            <StaffSmsNotification />
                          </CurrentUserProvider>
                        </CurrentAccountProvider>
                      )}
                    />
                    <Route
                      path={`${DEFAULT_PATH}/partners/:partnerId/products/:productId/included_forms/:id/settings/add_to_campaign`}
                      render={() => (
                        <CurrentAccountProvider value={currentAccount}>
                          <CurrentUserProvider value={currentUser}>
                            <AddToCampaign />
                          </CurrentUserProvider>
                        </CurrentAccountProvider>
                      )}
                    />
                    <Route
                      path={`${DEFAULT_PATH}/partners/:partnerId/products/:productId/included_forms/:id/settings`}
                      render={() => (
                        <CurrentAccountProvider value={currentAccount}>
                          <CurrentUserProvider value={currentUser}>
                            <IncludedFormSettings />
                          </CurrentUserProvider>
                        </CurrentAccountProvider>
                      )}
                    />
                    <Route
                      path={`${DEFAULT_PATH}/partners/:partnerId/products/:productId/included_forms/create`}
                      render={() => (
                        <CurrentAccountProvider value={currentAccount}>
                          <CurrentUserProvider value={currentUser}>
                            <IncludedFormBuilder />
                          </CurrentUserProvider>
                        </CurrentAccountProvider>
                      )}
                    />
                    <Route
                      path={`${DEFAULT_PATH}/partners/:partnerId/products/:productId/included_forms/:id`}
                      render={() => (
                        <CurrentAccountProvider value={currentAccount}>
                          <CurrentUserProvider value={currentUser}>
                            <IncludedFormBuilder />
                          </CurrentUserProvider>
                        </CurrentAccountProvider>
                      )}
                    />
                    <Route
                      path={[
                        `${DEFAULT_PATH}/partners/:partnerId/products/create`,
                        `${DEFAULT_PATH}/partners/:partnerId/products/:id`
                      ]}
                      render={() => (
                        <CurrentAccountProvider value={currentAccount}>
                          <CurrentUserProvider value={currentUser}>
                            <ProductBuilder />
                          </CurrentUserProvider>
                        </CurrentAccountProvider>
                      )}
                    />
                    <Route
                      path={`${DEFAULT_PATH}/partners/:id`}
                      render={() => (
                        <CurrentAccountProvider value={currentAccount}>
                          <CurrentUserProvider value={currentUser}>
                            <PartnerDashboard />
                          </CurrentUserProvider>
                        </CurrentAccountProvider>
                      )}
                    />
                    <Route
                      path={`${DEFAULT_PATH}/partners`}
                      render={() => (
                        <CurrentAccountProvider value={currentAccount}>
                          <CurrentUserProvider value={currentUser}>
                            <PartnersIndex />
                          </CurrentUserProvider>
                        </CurrentAccountProvider>
                      )}
                    />
                    <Route
                      path={`${DEFAULT_PATH}/subscriptions`}
                      render={() => <SubscriptionsIndex />}
                    />

                    <Route
                      path={`${DEFAULT_PATH}/background-job`}
                      render={() => (
                        <CurrentAccountProvider value={currentAccount}>
                          <CurrentUserProvider value={currentUser}>
                            <BackGroundJobDashboard />
                          </CurrentUserProvider>
                        </CurrentAccountProvider>
                      )}
                    />
                    <Route component={FourOFour} />
                  </Switch>
                </OverlordLayout>
              </BrowserRouter>
              {modalPortalNode && <OutPortal node={modalPortalNode} />}
              {notificationPortalNode && (
                <OutPortal node={notificationPortalNode} />
              )}
              {buttonDropdownPortalNode && (
                <OutPortal node={buttonDropdownPortalNode} />
              )}
            </PortalProvider>
          </EnvVariablesProvider>
        </Provider>
      </div>
    </div>
  );
};

const currentAccountShape = {
  name: PropTypes.string
};

const currentUserShape = {
  authenticationToken: PropTypes.string
};

AppOverlord.propTypes = {
  env: PropTypes.objectOf(PropTypes.string).isRequired,
  currentAccount: PropTypes.shape(currentAccountShape).isRequired,
  currentUser: PropTypes.shape(currentUserShape).isRequired
};

export default AppOverlord;
