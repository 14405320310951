import React from 'react';
import PropTypes from 'prop-types';
import { textTruncate } from 'lib/utils/string';
import HeroIcon from 'components/Theme/HeroIcon';

const DummyCampaignItem = ({ currentContact }) => {
  return (
    <span>
      <div className="tw-mb-4 md:tw-flex lg:tw-block xl:tw-flex tw-items-center tw-justify-between tw-text-sm tw-leading-5">
        <div className="tw-flex-1 tw-flex tw-items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="tw-h-4 tw-w-4 tw-text-gray-500"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M3 6c0-1.1.9-2 2-2h8l4-4h2v16h-2l-4-4H5a2 2 0 0 1-2-2H1V6h2zm8 9v5H8l-1.67-5H5v-2h8v2h-2z" />
          </svg>
          <div className="tw-flex-1">
            <span className="tw-ml-2 tw-flex-1 tw-truncate tw-block">
              {textTruncate(currentContact.currentProductCampaign, 50)}
            </span>
          </div>
        </div>
        <HeroIcon icon="dots-vertical" color="gray" />
      </div>
    </span>
  );
};

const currentContactShape = {
  currentProductCampaign: PropTypes.string
};

DummyCampaignItem.propTypes = {
  currentContact: PropTypes.shape(currentContactShape).isRequired
};

export default DummyCampaignItem;
