import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import SubscriptionEdit from './Subscription/Edit';
import FourOFour from '../FourOFour';

const SubscriptionsIndex = () => {
  const { path } = useRouteMatch();

  return (
    <div>
      <Switch>
        <Route path={`${path}/:id/edit`} component={SubscriptionEdit} />
        <Route component={FourOFour} />
      </Switch>
    </div>
  );
};

export default SubscriptionsIndex;
