import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { titleize, toCamel } from 'lib/utils/string';
import { CurrentAccountContext } from 'components/shared/context/CurrentAccountContext';
import {
  startFetchProcessingWatcher,
  stopFetchProcessingWorker
} from 'appState/actions/ActionCreators';
import AccountUtilities from './AccountUtilities';
import ContactUtilities from './ContactUtilities';
import StaffMemberUtilities from './StaffMemberUtilities';
import RecentActivities from './RecentActivities';

const overlordTabs = [
  'account',
  'staff-member',
  'contact',
  'recent-activities'
];

const OverlordUtilites = () => {
  const currentAccount = useContext(CurrentAccountContext);
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(startFetchProcessingWatcher());
    return () => {
      dispatch(stopFetchProcessingWorker());
    };
  }, [dispatch]);

  function setSelectedTab(urlTab) {
    return overlordTabs.includes(urlTab) ? urlTab : overlordTabs[0];
  }

  const [activeTab, setActiveTab] = useState(
    setSelectedTab(location.hash.replace('#', ''))
  );

  useEffect(() => {
    window.scrollTo({ top: 0 });
    const selectedTab = setSelectedTab(location.hash.replace('#', ''));
    setActiveTab(selectedTab);
  }, [location]);

  function getActiveComponent() {
    if (activeTab === 'contact') return <ContactUtilities />;

    if (activeTab === 'staff-member') return <StaffMemberUtilities />;

    if (activeTab === 'recent-activities') return <RecentActivities />;

    return <AccountUtilities />;
  }

  function getOverlordTabs() {
    return overlordTabs.map(tab => {
      return (
        <a
          className={`nav-link text-capitalize ${
            activeTab === tab ? 'active' : ''
          }`}
          href={`#${tab}`}
          key={tab}
          id={tab}
          onClick={() => {
            setActiveTab(tab);
          }}
        >
          {toCamel(tab, ' ')}
        </a>
      );
    });
  }

  return (
    <div>
      <header className="header">
        <div className="header-info">
          <h1 className="header-title">
            <strong>
              Overlord Utilities for {titleize(currentAccount.name)}
            </strong>
            <small>
              Use these tools to make changes to the account, its contacts or
              users.
            </small>
          </h1>
        </div>
        <div className="header-action">
          <nav className="nav nav-tabs nav-tabs-info">{getOverlordTabs()}</nav>
        </div>
      </header>
      <div className="main-content container-fluid pt-0 scrollable">
        <div className="row">
          <div className="col-12">
            <div className="tab-content mt-15">{getActiveComponent()}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverlordUtilites;
