import React from 'react';
import { withA11y } from '@storybook/addon-a11y';
import { withKnobs, select, boolean } from '@storybook/addon-knobs';
import BulletSteps from './index';

export default {
  component: BulletSteps,
  title: 'BulletSteps',
  decorators: [withA11y, withKnobs]
};

const steps = [
  { text: 'step one' },
  { text: 'step two' },
  { text: 'step three' },
  { text: 'step four' }
];

let colorSelect;

function includeColorSelect(defaultValue) {
  const label = 'Color';
  const options = [
    'alpha',
    'bravo',
    'charlie',
    'success',
    'warning',
    'error',
    'gray'
  ];
  colorSelect = select(label, options, defaultValue);
}

export const standard = () => {
  includeColorSelect('alpha');

  return (
    <BulletSteps
      color={colorSelect}
      steps={steps}
      completed={1}
      showStepNumbers={boolean('Show Step Numbers?', false)}
    />
  );
};

export const withStepNumbers = () => {
  includeColorSelect('alpha');

  return (
    <BulletSteps
      color={colorSelect}
      steps={steps}
      completed={1}
      showStepNumbers={boolean('Show Step Numbers?', true)}
    />
  );
};
