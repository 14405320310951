/* eslint-disable react/no-danger */
import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import isEmpty from 'lodash.isempty';
import { createLoadedSelector } from 'appState/selectors';
import { readURLOriginalRatio } from 'lib/utils/image';
import loadingGif from 'images/broadcast/loading.gif';
import { CurrentUserContext } from 'components/shared/context/CurrentUserContext';
import { nameInitials } from 'lib/utils/string';

const BroadcastPreview = props => {
  const { isMobile, content } = props;
  const [hasSrc, setHasSrc] = useState(false);
  const currentUser = useContext(CurrentUserContext);
  const imagePreviewEl = useRef(null);

  const loadedSelector = createLoadedSelector([
    'broadcastCenter/BROADCAST_FETCH_PREVIEW'
  ]);
  const structuredSelector = createStructuredSelector({
    emailBody: state => state.broadcastCenter.previewEmailBody,
    emailSubject: state => state.broadcastCenter.previewEmailSubject,
    smsBody: state => state.broadcastCenter.previewSmsBody,
    smsMedia: state => state.broadcastCenter.previewSmsMedia,
    contact: state => state.broadcastCenter.previewContact,
    isLoaded: state => loadedSelector(state)
  });

  const {
    emailBody,
    emailSubject,
    smsBody,
    smsMedia,
    contact,
    isLoaded
  } = useSelector(structuredSelector);

  function loadMediaPreview(url) {
    fetch(url)
      .then(res => {
        return res.blob();
      })
      .then(res => {
        if (res) {
          readURLOriginalRatio(res, imagePreviewEl);
        }
      });
  }

  useEffect(() => {
    if (content.removeTempMedia) {
      imagePreviewEl.current.setAttribute('src', loadingGif);
      setHasSrc(false);
    } else if (!isEmpty(content.smsMediaFile && content.smsMediaFile.name)) {
      setHasSrc(true);
      readURLOriginalRatio(content.smsMediaFile, imagePreviewEl);
    } else if (
      isEmpty(content.smsMediaFile && content.smsMediaFile.name) &&
      content.smsMediaUrl
    ) {
      setHasSrc(true);
      loadMediaPreview(content.smsMediaUrl);
    }
  }, [content.smsMediaUrl, content.smsMediaFile, content.removeTempMedia]);

  useEffect(() => {
    if (smsMedia) {
      setHasSrc(true);
      imagePreviewEl.current.setAttribute('src', smsMedia);
    }
  }, [smsMedia]);

  return (
    <div>
      {(!isEmpty(content) || isLoaded) && (
        <div>
          {isMobile ? (
            <div>
              <div dangerouslySetInnerHTML={{ __html: emailBody }} />
            </div>
          ) : (
            <div>
              {emailBody ? (
                <div>
                  {/* <!-- Email Preview --> */}
                  <div
                    style={{
                      width: '500px',
                      margin: '0 auto',
                      padding: '5px 25px'
                    }}
                  >
                    <h5>
                      <span className="font-weight-bold">
                        Subject:&nbsp;&nbsp;
                      </span>
                      {emailSubject}
                    </h5>
                  </div>
                  <div>
                    <div dangerouslySetInnerHTML={{ __html: emailBody }} />
                  </div>
                </div>
              ) : (
                <div className="d-flex mx-auto w-400px">
                  {/* <!-- SMS Preview --> */}
                  <div className="marvel-device iphone-x">
                    <div className="notch">
                      <div className="camera" />
                      <div className="speaker" />
                    </div>
                    <div className="top-bar" />
                    <div className="sleep" />
                    <div className="bottom-bar" />
                    <div className="volume" />
                    <div className="overflow">
                      <div className="shadow shadow--tr" />
                      <div className="shadow shadow--tl" />
                      <div className="shadow shadow--br" />
                      <div className="shadow shadow--bl" />
                    </div>
                    <div className="inner-shadow" />
                    <div className="screen ">
                      {/* <!-- Phone content starts here --> */}
                      <div
                        className="d-flex flex-column"
                        style={{ height: '100%' }}
                      >
                        <header className="align-items-center p-12 bg-lighter bb-1 border-light pt-45 full-width">
                          <div className="media align-items-center p-0">
                            <a
                              id="full-chat-window-contact-info"
                              className="avatar"
                            >
                              {!isEmpty(content)
                                ? nameInitials(
                                    `${content.contact.first_name} ${content.contact.last_name}`
                                  )
                                : contact &&
                                  nameInitials(
                                    `${contact.first_name} ${contact.last_name}`
                                  )}
                            </a>
                            <div className="media-body">
                              <h6>
                                <a href="#">
                                  {!isEmpty(content)
                                    ? content.contact.first_name
                                    : contact && contact.first_name}{' '}
                                  {!isEmpty(content)
                                    ? content.contact.last_name
                                    : contact && contact.last_name}
                                </a>
                              </h6>
                            </div>
                          </div>
                        </header>
                        <div className="full-width flex-grow">
                          <div className="scrollable" id="chat-content">
                            <div className="media media-chat media-chat-reverse">
                              <div className="media-body">
                                <p
                                  style={{
                                    wordBreak: 'break-word',
                                    minWidth: '96px'
                                  }}
                                >
                                  {!isEmpty(content)
                                    ? content.smsBody
                                    : smsBody}
                                  {!isEmpty(content) && content.smsLink
                                    ? ` ${content.smsLink}`
                                    : ''}
                                  {isEmpty(content.smsBody) &&
                                  isEmpty(smsBody) &&
                                  isEmpty(content.smsLink) &&
                                  !hasSrc
                                    ? '...'
                                    : ''}

                                  {/* MMS Media Preview */}
                                  <span
                                    className={`${
                                      hasSrc ? 'd-block' : 'd-none'
                                    }`}
                                    style={{
                                      maxWidth: '240px',
                                      maxHeight: '240px',
                                      position: 'relative',
                                      overflow: 'hidden'
                                    }}
                                  >
                                    <img
                                      ref={imagePreviewEl}
                                      alt="Preview MMS"
                                      src={loadingGif}
                                    />
                                  </span>
                                </p>
                              </div>
                            </div>
                            <div className="media media-chat">
                              <a
                                id="full-chat-window-contact-info"
                                className="avatar"
                              >
                                {!isEmpty(content)
                                  ? nameInitials(
                                      `${content.contact.first_name} ${content.contact.last_name}`
                                    )
                                  : contact &&
                                    nameInitials(
                                      `${contact.first_name} ${contact.last_name}`
                                    )}
                              </a>
                              <div className="media-body">
                                <p>...</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="publisher mt-auto">
                          <a
                            id="full-chat-window-contact-info"
                            className="avatar"
                          >
                            {nameInitials(`${currentUser.full_name}`)}
                          </a>
                          <input
                            className="publisher-input"
                            rows="1"
                            placeholder="Write something"
                          />
                          <div className="align-self-end gap-items">
                            <a className="publisher-btn" href="#">
                              <i className="fa fa-paper-plane" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

BroadcastPreview.defaultProps = {
  isMobile: false,
  content: {}
};

const contentShape = {
  contact: PropTypes.objectOf(PropTypes.string),
  smsBody: PropTypes.string,
  smsLink: PropTypes.string,
  smsMediaFile: PropTypes.object,
  smsMediaUrl: PropTypes.string,
  removeTempMedia: PropTypes.bool
};

BroadcastPreview.propTypes = {
  isMobile: PropTypes.bool,
  content: PropTypes.shape(contentShape)
};

export default BroadcastPreview;
