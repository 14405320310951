import { call, fork, select, take } from 'redux-saga/effects';

export default function* takeEveryDeduplicate(pattern, worker, ...args) {
  const task = yield fork(function* f() {
    while (true) {
      const action = yield take(pattern);
      yield call(worker, ...args, action);
    }
  });
  return task;
}

// wait for some piece of redux state to exist before executing rest of saga
function* waitFor(selector) {
  if (yield select(selector)) return;

  while (true) {
    yield take('*');
    if (yield select(selector)) return;
  }
}

export { takeEveryDeduplicate, waitFor };
