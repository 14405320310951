/* eslint-disable no-unused-vars */
import { call, put, select, takeLatest } from 'redux-saga/effects';
import get from 'lodash.get';
import { axiosDefault } from 'lib/utils/axios-setup';
import { takeEveryDeduplicate } from 'lib/utils/redux-saga-extensions';
import {
  FETCH_ACTIVITIES_REQUEST,
  FETCH_ACTIVITIES_SUCCESS,
  FETCH_ACTIVITIES_FAILURE,
  ACTIVITIES_PAGE_CHANGE_REQUEST,
  ACTIVITIES_PAGE_CHANGE_SUCCESS,
  FETCH_PINNED_ACTIVITIES_REQUEST,
  FETCH_PINNED_ACTIVITIES_SUCCESS,
  FETCH_PINNED_ACTIVITIES_FAILURE,
  UPDATE_ACTIVITY_REQUEST,
  UPDATE_ACTIVITY_SUCCESS,
  UPDATE_ACTIVITY_FAILURE,
  FETCH_SMS_ACTIVITIES_REQUEST,
  FETCH_SMS_ACTIVITIES_SUCCESS,
  FETCH_SMS_ACTIVITIES_FAILURE,
  SMS_ACTIVITIES_PAGE_CHANGE_REQUEST,
  SMS_ACTIVITIES_PAGE_CHANGE_SUCCESS,
  FETCH_PINNED_SMS_ACTIVITIES_REQUEST,
  FETCH_PINNED_SMS_ACTIVITIES_SUCCESS,
  FETCH_PINNED_SMS_ACTIVITIES_FAILURE,
  UPDATE_SMS_ACTIVITY_REQUEST,
  UPDATE_SMS_ACTIVITY_SUCCESS,
  UPDATE_SMS_ACTIVITY_FAILURE,
  FETCH_EMAIL_ACTIVITIES_REQUEST,
  FETCH_EMAIL_ACTIVITIES_SUCCESS,
  FETCH_EMAIL_ACTIVITIES_FAILURE,
  EMAIL_ACTIVITIES_PAGE_CHANGE_REQUEST,
  EMAIL_ACTIVITIES_PAGE_CHANGE_SUCCESS,
  FETCH_PINNED_EMAIL_ACTIVITIES_REQUEST,
  FETCH_PINNED_EMAIL_ACTIVITIES_SUCCESS,
  FETCH_PINNED_EMAIL_ACTIVITIES_FAILURE,
  UPDATE_EMAIL_ACTIVITY_REQUEST,
  UPDATE_EMAIL_ACTIVITY_SUCCESS,
  UPDATE_EMAIL_ACTIVITY_FAILURE,
  FETCH_APPOINTMENTS_ACTIVITIES_REQUEST,
  FETCH_APPOINTMENTS_ACTIVITIES_SUCCESS,
  FETCH_APPOINTMENTS_ACTIVITIES_FAILURE,
  APPOINTMENTS_ACTIVITIES_PAGE_CHANGE_REQUEST,
  APPOINTMENTS_ACTIVITIES_PAGE_CHANGE_SUCCESS,
  FETCH_PINNED_APPOINTMENTS_ACTIVITIES_REQUEST,
  FETCH_PINNED_APPOINTMENTS_ACTIVITIES_SUCCESS,
  FETCH_PINNED_APPOINTMENTS_ACTIVITIES_FAILURE,
  UPDATE_APPOINTMENTS_ACTIVITY_REQUEST,
  UPDATE_APPOINTMENTS_ACTIVITY_SUCCESS,
  UPDATE_APPOINTMENTS_ACTIVITY_FAILURE,
  FETCH_TASKS_ACTIVITIES_REQUEST,
  FETCH_TASKS_ACTIVITIES_SUCCESS,
  FETCH_TASKS_ACTIVITIES_FAILURE,
  TASKS_ACTIVITIES_PAGE_CHANGE_REQUEST,
  TASKS_ACTIVITIES_PAGE_CHANGE_SUCCESS,
  FETCH_PINNED_TASKS_ACTIVITIES_REQUEST,
  FETCH_PINNED_TASKS_ACTIVITIES_SUCCESS,
  FETCH_PINNED_TASKS_ACTIVITIES_FAILURE,
  UPDATE_TASKS_ACTIVITY_REQUEST,
  UPDATE_TASKS_ACTIVITY_SUCCESS,
  UPDATE_TASKS_ACTIVITY_FAILURE,
  FETCH_NOTES_ACTIVITIES_REQUEST,
  FETCH_NOTES_ACTIVITIES_SUCCESS,
  FETCH_NOTES_ACTIVITIES_FAILURE,
  NOTES_ACTIVITIES_PAGE_CHANGE_REQUEST,
  NOTES_ACTIVITIES_PAGE_CHANGE_SUCCESS,
  FETCH_PINNED_NOTES_ACTIVITIES_REQUEST,
  FETCH_PINNED_NOTES_ACTIVITIES_SUCCESS,
  FETCH_PINNED_NOTES_ACTIVITIES_FAILURE,
  UPDATE_NOTES_ACTIVITY_REQUEST,
  UPDATE_NOTES_ACTIVITY_SUCCESS,
  UPDATE_NOTES_ACTIVITY_FAILURE,
  FETCH_FORMS_ACTIVITIES_REQUEST,
  FETCH_FORMS_ACTIVITIES_SUCCESS,
  FETCH_FORMS_ACTIVITIES_FAILURE,
  FORMS_ACTIVITIES_PAGE_CHANGE_REQUEST,
  FORMS_ACTIVITIES_PAGE_CHANGE_SUCCESS,
  FETCH_PINNED_FORMS_ACTIVITIES_REQUEST,
  FETCH_PINNED_FORMS_ACTIVITIES_SUCCESS,
  FETCH_PINNED_FORMS_ACTIVITIES_FAILURE,
  UPDATE_FORMS_ACTIVITY_REQUEST,
  UPDATE_FORMS_ACTIVITY_SUCCESS,
  UPDATE_FORMS_ACTIVITY_FAILURE,
  FETCH_CONTACT_FORM_REQUEST,
  FETCH_CONTACT_FORM_SUCCESS,
  FETCH_CONTACT_FORM_FAILURE,
  COMPLETE_TASK_REQUEST,
  COMPLETE_TASK_SUCCESS,
  COMPLETE_TASK_FAILURE,
  EDIT_TASK_REQUEST,
  EDIT_TASK_SUCCESS,
  EDIT_TASK_FAILURE,
  REASSIGN_TASK_REQUEST,
  REASSIGN_TASK_SUCCESS,
  REASSIGN_TASK_FAILURE,
  CANCEL_TASK_REQUEST,
  CANCEL_TASK_SUCCESS,
  CANCEL_TASK_FAILURE,
  RESCHEDULE_APPOINTMENT_REQUEST,
  RESCHEDULE_APPOINTMENT_SUCCESS,
  RESCHEDULE_APPOINTMENT_FAILURE,
  MARK_APPOINTMENT_NO_SHOW_REQUEST,
  MARK_APPOINTMENT_NO_SHOW_SUCCESS,
  MARK_APPOINTMENT_NO_SHOW_FAILURE,
  CANCEL_APPOINTMENT_REQUEST,
  CANCEL_APPOINTMENT_SUCCESS,
  CANCEL_APPOINTMENT_FAILURE
} from 'appState/actions/constants/activity.actions';
import {
  decorateActivity,
  decoratedActivities
} from 'lib/services/activityFeedDecorator';
import {
  isDummyContact,
  updateDummyActivity
} from 'lib/services/dummyContactResponses';

function* fetchActivities({ payload: { contactId = null, page = 1 } }) {
  try {
    const { isChangingPage } = yield select(state => state.activity);
    const params = { page };

    const response = yield call(
      axiosDefault.get,
      `/activities?pinned=false${contactId ? `&contact_id=${contactId}` : ''}`,
      { params }
    );
    const total = get(response, 'headers["total-count"]');

    yield put({
      type: isChangingPage
        ? ACTIVITIES_PAGE_CHANGE_SUCCESS
        : FETCH_ACTIVITIES_SUCCESS,
      payload: {
        activities: decoratedActivities(response.data),
        page,
        total
      }
    });
  } catch (e) {
    yield put({
      type: FETCH_ACTIVITIES_FAILURE,
      payload: { error: e },
      error: true
    });
  }
}

function* fetchPinnedActivities({ payload: { contactId } }) {
  try {
    const response = yield call(
      axiosDefault.get,
      `/activities?pinned=true&contact_id=${contactId}`
    );

    yield put({
      type: FETCH_PINNED_ACTIVITIES_SUCCESS,
      payload: {
        pinnedActivities: decoratedActivities(response.data)
      }
    });
  } catch (e) {
    yield put({
      type: FETCH_PINNED_ACTIVITIES_FAILURE,
      payload: { error: e },
      error: true
    });
  }
}

function* updateActivityItem({ payload: { activity } }) {
  try {
    const { activities, pinnedActivities } = yield select(
      state => state.activity
    );
    const params = { pinned: !activity.pinned };

    if (yield call(isDummyContact))
      return yield call(updateDummyActivity, {
        activity,
        activitiesLength: activities.length,
        pinnedActivitiesLength: pinnedActivities.length
      });

    const response = yield call(
      axiosDefault.put,
      `/activities/${activity.id}`,
      params
    );

    const updatedActivity = response.data;

    yield put({
      type: UPDATE_ACTIVITY_SUCCESS,
      payload: {
        updatedActivity: decorateActivity(
          updatedActivity,
          updatedActivity.pinned ? pinnedActivities.length : activities.length
        )
      }
    });
  } catch (e) {
    yield put({
      type: UPDATE_ACTIVITY_FAILURE,
      payload: { error: e },
      error: true
    });
  }
}

function* fetchSmsActivities({
  payload: { contactId = null, page = 1, action = 'sms' }
}) {
  try {
    const { smsIsChangingPage } = yield select(state => state.activity);
    const params = { page };

    const response = yield call(
      axiosDefault.get,
      `/activities?pinned=false&activity_action=${action}${
        contactId ? `&contact_id=${contactId}` : ''
      }`,
      { params }
    );
    const smsTotal = get(response, 'headers["total-count"]');

    yield put({
      type: smsIsChangingPage
        ? SMS_ACTIVITIES_PAGE_CHANGE_SUCCESS
        : FETCH_SMS_ACTIVITIES_SUCCESS,
      payload: {
        smsActivities: decoratedActivities(response.data),
        smsPage: page,
        smsTotal
      }
    });
  } catch (e) {
    yield put({
      type: FETCH_SMS_ACTIVITIES_FAILURE,
      payload: { error: e },
      error: true
    });
  }
}

function* fetchPinnedSmsActivities({ payload: { contactId, action = 'sms' } }) {
  try {
    const response = yield call(
      axiosDefault.get,
      `/activities?pinned=true&activity_action=${action}&contact_id=${contactId}`
    );

    yield put({
      type: FETCH_PINNED_SMS_ACTIVITIES_SUCCESS,
      payload: {
        pinnedSmsActivities: decoratedActivities(response.data)
      }
    });
  } catch (e) {
    yield put({
      type: FETCH_PINNED_SMS_ACTIVITIES_FAILURE,
      payload: { error: e },
      error: true
    });
  }
}

function* updateSmsActivityItem({ payload: { activity } }) {
  try {
    const { smsActivities, pinnedSmsActivities } = yield select(
      state => state.activity
    );
    const params = { pinned: !activity.pinned };
    const response = yield call(
      axiosDefault.put,
      `/activities/${activity.id}`,
      params
    );

    const updatedActivity = response.data;

    yield put({
      type: UPDATE_SMS_ACTIVITY_SUCCESS,
      payload: {
        updatedSmsActivity: decorateActivity(
          updatedActivity,
          updatedActivity.pinned
            ? pinnedSmsActivities.length
            : smsActivities.length
        )
      }
    });
  } catch (e) {
    yield put({
      type: UPDATE_SMS_ACTIVITY_FAILURE,
      payload: { error: e },
      error: true
    });
  }
}

function* fetchEmailActivities({
  payload: { contactId = null, page = 1, action = 'email' }
}) {
  try {
    const { emailIsChangingPage } = yield select(state => state.activity);
    const params = { page };

    const response = yield call(
      axiosDefault.get,
      `/activities?pinned=false&activity_action=${action}${
        contactId ? `&contact_id=${contactId}` : ''
      }`,
      { params }
    );
    const emailTotal = get(response, 'headers["total-count"]');

    yield put({
      type: emailIsChangingPage
        ? EMAIL_ACTIVITIES_PAGE_CHANGE_SUCCESS
        : FETCH_EMAIL_ACTIVITIES_SUCCESS,
      payload: {
        emailActivities: decoratedActivities(response.data),
        emailPage: page,
        emailTotal
      }
    });
  } catch (e) {
    yield put({
      type: FETCH_EMAIL_ACTIVITIES_FAILURE,
      payload: { error: e },
      error: true
    });
  }
}

function* fetchPinnedEmailActivities({
  payload: { contactId, action = 'email' }
}) {
  try {
    const response = yield call(
      axiosDefault.get,
      `/activities?pinned=true&activity_action=${action}&contact_id=${contactId}`
    );

    yield put({
      type: FETCH_PINNED_EMAIL_ACTIVITIES_SUCCESS,
      payload: {
        pinnedEmailActivities: decoratedActivities(response.data)
      }
    });
  } catch (e) {
    yield put({
      type: FETCH_PINNED_EMAIL_ACTIVITIES_FAILURE,
      payload: { error: e },
      error: true
    });
  }
}

function* updateEmailActivityItem({ payload: { activity } }) {
  try {
    const { emailActivities, pinnedEmailActivities } = yield select(
      state => state.activity
    );
    const params = { pinned: !activity.pinned };
    const response = yield call(
      axiosDefault.put,
      `/activities/${activity.id}`,
      params
    );

    const updatedActivity = response.data;

    yield put({
      type: UPDATE_EMAIL_ACTIVITY_SUCCESS,
      payload: {
        updatedEmailActivity: decorateActivity(
          updatedActivity,
          updatedActivity.pinned
            ? pinnedEmailActivities.length
            : emailActivities.length
        )
      }
    });
  } catch (e) {
    yield put({
      type: UPDATE_EMAIL_ACTIVITY_FAILURE,
      payload: { error: e },
      error: true
    });
  }
}

function* fetchAppointmentsActivities({
  payload: { contactId = null, page = 1, action = 'appointment' }
}) {
  try {
    const { appointmentsIsChangingPage } = yield select(
      state => state.activity
    );
    const params = { page };

    const response = yield call(
      axiosDefault.get,
      `/activities?pinned=false&activity_action=${action}${
        contactId ? `&contact_id=${contactId}` : ''
      }`,
      { params }
    );
    const appointmentsTotal = get(response, 'headers["total-count"]');

    yield put({
      type: appointmentsIsChangingPage
        ? APPOINTMENTS_ACTIVITIES_PAGE_CHANGE_SUCCESS
        : FETCH_APPOINTMENTS_ACTIVITIES_SUCCESS,
      payload: {
        appointmentsActivities: decoratedActivities(response.data),
        appointmentsPage: page,
        appointmentsTotal
      }
    });
  } catch (e) {
    yield put({
      type: FETCH_APPOINTMENTS_ACTIVITIES_FAILURE,
      payload: { error: e },
      error: true
    });
  }
}

function* fetchPinnedAppointmentsActivities({
  payload: { contactId, action = 'appointment' }
}) {
  try {
    const response = yield call(
      axiosDefault.get,
      `/activities?pinned=true&activity_action=${action}&contact_id=${contactId}`
    );

    yield put({
      type: FETCH_PINNED_APPOINTMENTS_ACTIVITIES_SUCCESS,
      payload: {
        pinnedAppointmentsActivities: decoratedActivities(response.data)
      }
    });
  } catch (e) {
    yield put({
      type: FETCH_PINNED_APPOINTMENTS_ACTIVITIES_FAILURE,
      payload: { error: e },
      error: true
    });
  }
}

function* updateAppointmentsActivityItem({ payload: { activity } }) {
  try {
    const {
      appointmentsActivities,
      pinnedAppointmentsActivities
    } = yield select(state => state.activity);
    const params = { pinned: !activity.pinned };
    const response = yield call(
      axiosDefault.put,
      `/activities/${activity.id}`,
      params
    );

    const updatedActivity = response.data;

    yield put({
      type: UPDATE_APPOINTMENTS_ACTIVITY_SUCCESS,
      payload: {
        updatedAppointmentsActivity: decorateActivity(
          updatedActivity,
          updatedActivity.pinned
            ? pinnedAppointmentsActivities.length
            : appointmentsActivities.length
        )
      }
    });
  } catch (e) {
    yield put({
      type: UPDATE_APPOINTMENTS_ACTIVITY_FAILURE,
      payload: { error: e },
      error: true
    });
  }
}

function* fetchTasksActivities({
  payload: { contactId = null, page = 1, action = 'task' }
}) {
  try {
    const { tasksIsChangingPage } = yield select(state => state.activity);
    const params = { page };

    const response = yield call(
      axiosDefault.get,
      `/activities?pinned=false&activity_action=${action}${
        contactId ? `&contact_id=${contactId}` : ''
      }`,
      { params }
    );
    const tasksTotal = get(response, 'headers["total-count"]');

    yield put({
      type: tasksIsChangingPage
        ? TASKS_ACTIVITIES_PAGE_CHANGE_SUCCESS
        : FETCH_TASKS_ACTIVITIES_SUCCESS,
      payload: {
        tasksActivities: decoratedActivities(response.data),
        tasksPage: page,
        tasksTotal
      }
    });
  } catch (e) {
    yield put({
      type: FETCH_TASKS_ACTIVITIES_FAILURE,
      payload: { error: e },
      error: true
    });
  }
}

function* fetchPinnedTasksActivities({
  payload: { contactId, action = 'task' }
}) {
  try {
    const response = yield call(
      axiosDefault.get,
      `/activities?pinned=true&activity_action=${action}&contact_id=${contactId}`
    );

    yield put({
      type: FETCH_PINNED_TASKS_ACTIVITIES_SUCCESS,
      payload: {
        pinnedTasksActivities: decoratedActivities(response.data)
      }
    });
  } catch (e) {
    yield put({
      type: FETCH_PINNED_TASKS_ACTIVITIES_FAILURE,
      payload: { error: e },
      error: true
    });
  }
}

function* updateTasksActivityItem({ payload: { activity } }) {
  try {
    const { tasksActivities, pinnedTasksActivities } = yield select(
      state => state.activity
    );
    const params = { pinned: !activity.pinned };
    const response = yield call(
      axiosDefault.put,
      `/activities/${activity.id}`,
      params
    );

    const updatedActivity = response.data;

    yield put({
      type: UPDATE_TASKS_ACTIVITY_SUCCESS,
      payload: {
        updatedTasksActivity: decorateActivity(
          updatedActivity,
          updatedActivity.pinned
            ? pinnedTasksActivities.length
            : tasksActivities.length
        )
      }
    });
  } catch (e) {
    yield put({
      type: UPDATE_TASKS_ACTIVITY_FAILURE,
      payload: { error: e },
      error: true
    });
  }
}

function* fetchNotesActivities({
  payload: { contactId = null, page = 1, action = 'note' }
}) {
  try {
    const { notesIsChangingPage } = yield select(state => state.activity);
    const params = { page };

    const response = yield call(
      axiosDefault.get,
      `/activities?pinned=false&activity_action=${action}${
        contactId ? `&contact_id=${contactId}` : ''
      }`,
      { params }
    );
    const notesTotal = get(response, 'headers["total-count"]');

    yield put({
      type: notesIsChangingPage
        ? NOTES_ACTIVITIES_PAGE_CHANGE_SUCCESS
        : FETCH_NOTES_ACTIVITIES_SUCCESS,
      payload: {
        notesActivities: decoratedActivities(response.data),
        notesPage: page,
        notesTotal
      }
    });
  } catch (e) {
    yield put({
      type: FETCH_NOTES_ACTIVITIES_FAILURE,
      payload: { error: e },
      error: true
    });
  }
}

function* fetchPinnedNotesActivities({
  payload: { contactId, action = 'note' }
}) {
  try {
    const response = yield call(
      axiosDefault.get,
      `/activities?pinned=true&activity_action=${action}&contact_id=${contactId}`
    );

    yield put({
      type: FETCH_PINNED_NOTES_ACTIVITIES_SUCCESS,
      payload: {
        pinnedNotesActivities: decoratedActivities(response.data)
      }
    });
  } catch (e) {
    yield put({
      type: FETCH_PINNED_NOTES_ACTIVITIES_FAILURE,
      payload: { error: e },
      error: true
    });
  }
}

function* updateNotesActivityItem({ payload: { activity } }) {
  try {
    const { notesActivities, pinnedNotesActivities } = yield select(
      state => state.activity
    );
    const params = { pinned: !activity.pinned };
    const response = yield call(
      axiosDefault.put,
      `/activities/${activity.id}`,
      params
    );

    const updatedActivity = response.data;

    yield put({
      type: UPDATE_NOTES_ACTIVITY_SUCCESS,
      payload: {
        updatedNotesActivity: decorateActivity(
          updatedActivity,
          updatedActivity.pinned
            ? pinnedNotesActivities.length
            : notesActivities.length
        )
      }
    });
  } catch (e) {
    yield put({
      type: UPDATE_NOTES_ACTIVITY_FAILURE,
      payload: { error: e },
      error: true
    });
  }
}

function* fetchFormsActivities({
  payload: { contactId = null, page = 1, action = 'form' }
}) {
  try {
    const { formsIsChangingPage } = yield select(state => state.activity);
    const params = { page };

    const response = yield call(
      axiosDefault.get,
      `/activities?pinned=false&activity_action=${action}${
        contactId ? `&contact_id=${contactId}` : ''
      }`,
      { params }
    );
    const formsTotal = get(response, 'headers["total-count"]');

    yield put({
      type: formsIsChangingPage
        ? FORMS_ACTIVITIES_PAGE_CHANGE_SUCCESS
        : FETCH_FORMS_ACTIVITIES_SUCCESS,
      payload: {
        formsActivities: decoratedActivities(response.data),
        formsPage: page,
        formsTotal
      }
    });
  } catch (e) {
    yield put({
      type: FETCH_FORMS_ACTIVITIES_FAILURE,
      payload: { error: e },
      error: true
    });
  }
}

function* fetchPinnedFormsActivities({
  payload: { accountId, contactId, action = 'form' }
}) {
  try {
    const response = yield call(
      axiosDefault.get,
      `/activities?account_id=${accountId}&pinned=true&activity_action=${action}&contact_id=${contactId}`
    );

    yield put({
      type: FETCH_PINNED_FORMS_ACTIVITIES_SUCCESS,
      payload: {
        pinnedFormsActivities: decoratedActivities(response.data)
      }
    });
  } catch (e) {
    yield put({
      type: FETCH_PINNED_FORMS_ACTIVITIES_FAILURE,
      payload: { error: e },
      error: true
    });
  }
}

function* updateFormsActivityItem({ payload: { activity } }) {
  try {
    const { formsActivities, pinnedFormsActivities } = yield select(
      state => state.activity
    );
    const params = { pinned: !activity.pinned };
    const response = yield call(
      axiosDefault.put,
      `/activities/${activity.id}`,
      params
    );

    const updatedActivity = response.data;

    yield put({
      type: UPDATE_FORMS_ACTIVITY_SUCCESS,
      payload: {
        updatedFormsActivity: decorateActivity(
          updatedActivity,
          updatedActivity.pinned
            ? pinnedFormsActivities.length
            : formsActivities.length
        )
      }
    });
  } catch (e) {
    yield put({
      type: UPDATE_FORMS_ACTIVITY_FAILURE,
      payload: { error: e },
      error: true
    });
  }
}

function* fetchContactForm({ payload: { formId } }) {
  try {
    const response = yield call(axiosDefault.get, `/contact_forms/${formId}`);

    yield put({
      type: FETCH_CONTACT_FORM_SUCCESS,
      payload: {
        currentForm: response.data
      }
    });
  } catch (e) {
    yield put({
      type: FETCH_CONTACT_FORM_FAILURE,
      payload: { error: e },
      error: true
    });
  }
}

function* completeTask({ payload: { taskId, note = null } }) {
  try {
    const { tasksActivities } = yield select(state => state.activity);

    const params = { completed_at: new Date(), note };
    const response = yield call(
      axiosDefault.put,
      `/tasks/${taskId}/complete`,
      params
    );
    const updatedTask = response.data;

    yield put({
      type: COMPLETE_TASK_SUCCESS,
      payload: {
        // TO-DO: add new activity based on updated task
        // updatedTasksActivity: decorateActivity(
        //   updatedTask,
        //   tasksActivities.length
        // )
      }
    });
  } catch (e) {
    yield put({
      type: COMPLETE_TASK_FAILURE,
      payload: { error: e },
      error: true
    });
  }
}

function* editTask({ payload: { taskId } }) {
  try {
    const { tasksActivities } = yield select(state => state.activity);

    const params = {
      // TO-DO: add edited fields to params
    };
    const response = yield call(axiosDefault.put, `/tasks/${taskId}`, params);

    const updatedTask = response.data;

    yield put({
      type: EDIT_TASK_SUCCESS,
      payload: {
        // TO-DO: add new activity based on updated task
        // updatedTasksActivity: decorateActivity(
        //   updatedTask,
        //   tasksActivities.length
        // )
      }
    });
  } catch (e) {
    yield put({
      type: EDIT_TASK_FAILURE,
      payload: { error: e },
      error: true
    });
  }
}

function* reassignTask({ payload: { taskId, staffId } }) {
  try {
    const { tasksActivities } = yield select(state => state.activity);

    const params = { staff_id: staffId };
    const response = yield call(
      axiosDefault.put,
      `/tasks/${taskId}/reassign`,
      params
    );
    const updatedTask = response.data;

    yield put({
      type: REASSIGN_TASK_SUCCESS,
      payload: {
        // TO-DO: add new activity based on updated task
        // updatedTasksActivity: decorateActivity(
        //   updatedTask,
        //   tasksActivities.length
        // )
      }
    });
  } catch (e) {
    yield put({
      type: REASSIGN_TASK_FAILURE,
      payload: { error: e },
      error: true
    });
  }
}

function* cancelTask({ payload: { taskId, note = null } }) {
  try {
    const { tasksActivities } = yield select(state => state.activity);

    const params = { canceled_at: new Date(), note };
    const response = yield call(
      axiosDefault.put,
      `/tasks/${taskId}/cancel`,
      params
    );
    const updatedTask = response.data;

    yield put({
      type: CANCEL_TASK_SUCCESS,
      payload: {
        // TO-DO: add new activity based on updated task
        // updatedTasksActivity: decorateActivity(
        //   updatedTask,
        //   tasksActivities.length
        // )
      }
    });
  } catch (e) {
    yield put({
      type: CANCEL_TASK_FAILURE,
      payload: { error: e },
      error: true
    });
  }
}

function* rescheduleAppointment({ payload: { appointmentId } }) {
  try {
    const { appointmentsActivities } = yield select(state => state.activity);

    const params = {
      appointment_id: appointmentId
    };
    const response = yield call(
      axiosDefault.put,
      `/appointment_booker/update_appointment`,
      params
    );
    const updatedAppointment = response.data;

    yield put({
      type: RESCHEDULE_APPOINTMENT_SUCCESS,
      payload: {
        // TO-DO: add new activity based on updated appointment
        // updatedAppointmentsActivity: decorateActivity(
        //   updatedAppointment,
        //   appointmentsActivities.length
        // )
      }
    });
  } catch (e) {
    yield put({
      type: RESCHEDULE_APPOINTMENT_FAILURE,
      payload: { error: e },
      error: true
    });
  }
}

function* markAppointmentNoShow({ payload: { appointmentId, notes } }) {
  try {
    const params = {
      appointment_id: appointmentId,
      notes
    };
    const response = yield call(
      axiosDefault.put,
      '/appointment_booker/no_show',
      params
    );
    const appointment = response.data;

    yield put({
      type: MARK_APPOINTMENT_NO_SHOW_SUCCESS,
      payload: {
        appointment
      }
    });
  } catch (e) {
    yield put({
      type: MARK_APPOINTMENT_NO_SHOW_FAILURE,
      payload: { error: e },
      error: true
    });
  }
}

function* cancelAppointment({ payload: { appointmentId, notes } }) {
  try {
    const params = {
      appointment_id: appointmentId,
      notes
    };
    const response = yield call(
      axiosDefault.put,
      '/appointment_booker/cancel',
      params
    );
    const appointment = response.data;

    yield put({
      type: CANCEL_APPOINTMENT_SUCCESS,
      payload: { appointment }
    });
  } catch (e) {
    yield put({
      type: CANCEL_APPOINTMENT_FAILURE,
      payload: { error: e },
      error: true
    });
  }
}

export function* activitiesFetch() {
  yield takeLatest(FETCH_ACTIVITIES_REQUEST, fetchActivities);
}

export function* activitiesPageChange() {
  yield takeEveryDeduplicate(ACTIVITIES_PAGE_CHANGE_REQUEST, fetchActivities);
}

export function* pinnedActivitiesFetch() {
  yield takeLatest(FETCH_PINNED_ACTIVITIES_REQUEST, fetchPinnedActivities);
}

export function* activityUpdate() {
  yield takeLatest(UPDATE_ACTIVITY_REQUEST, updateActivityItem);
}

export function* smsActivitiesFetch() {
  yield takeLatest(FETCH_SMS_ACTIVITIES_REQUEST, fetchSmsActivities);
}

export function* smsActivitiesPageChange() {
  yield takeEveryDeduplicate(
    SMS_ACTIVITIES_PAGE_CHANGE_REQUEST,
    fetchSmsActivities
  );
}

export function* pinnedSmsActivitiesFetch() {
  yield takeLatest(
    FETCH_PINNED_SMS_ACTIVITIES_REQUEST,
    fetchPinnedSmsActivities
  );
}

export function* smsActivityUpdate() {
  yield takeLatest(UPDATE_SMS_ACTIVITY_REQUEST, updateSmsActivityItem);
}

export function* emailActivitiesFetch() {
  yield takeLatest(FETCH_EMAIL_ACTIVITIES_REQUEST, fetchEmailActivities);
}

export function* emailActivitiesPageChange() {
  yield takeEveryDeduplicate(
    EMAIL_ACTIVITIES_PAGE_CHANGE_REQUEST,
    fetchEmailActivities
  );
}

export function* pinnedEmailActivitiesFetch() {
  yield takeLatest(
    FETCH_PINNED_EMAIL_ACTIVITIES_REQUEST,
    fetchPinnedEmailActivities
  );
}

export function* emailActivityUpdate() {
  yield takeLatest(UPDATE_EMAIL_ACTIVITY_REQUEST, updateEmailActivityItem);
}

export function* appointmentsActivitiesFetch() {
  yield takeLatest(
    FETCH_APPOINTMENTS_ACTIVITIES_REQUEST,
    fetchAppointmentsActivities
  );
}

export function* appointmentsActivitiesPageChange() {
  yield takeEveryDeduplicate(
    APPOINTMENTS_ACTIVITIES_PAGE_CHANGE_REQUEST,
    fetchAppointmentsActivities
  );
}

export function* pinnedAppointmentsActivitiesFetch() {
  yield takeLatest(
    FETCH_PINNED_APPOINTMENTS_ACTIVITIES_REQUEST,
    fetchPinnedAppointmentsActivities
  );
}

export function* appointmentsActivityUpdate() {
  yield takeLatest(
    UPDATE_APPOINTMENTS_ACTIVITY_REQUEST,
    updateAppointmentsActivityItem
  );
}

export function* tasksActivitiesFetch() {
  yield takeLatest(FETCH_TASKS_ACTIVITIES_REQUEST, fetchTasksActivities);
}

export function* tasksActivitiesPageChange() {
  yield takeEveryDeduplicate(
    TASKS_ACTIVITIES_PAGE_CHANGE_REQUEST,
    fetchTasksActivities
  );
}

export function* pinnedTasksActivitiesFetch() {
  yield takeLatest(
    FETCH_PINNED_TASKS_ACTIVITIES_REQUEST,
    fetchPinnedTasksActivities
  );
}

export function* tasksActivityUpdate() {
  yield takeLatest(UPDATE_TASKS_ACTIVITY_REQUEST, updateTasksActivityItem);
}

export function* notesActivitiesFetch() {
  yield takeLatest(FETCH_NOTES_ACTIVITIES_REQUEST, fetchNotesActivities);
}

export function* notesActivitiesPageChange() {
  yield takeEveryDeduplicate(
    NOTES_ACTIVITIES_PAGE_CHANGE_REQUEST,
    fetchNotesActivities
  );
}

export function* pinnedNotesActivitiesFetch() {
  yield takeLatest(
    FETCH_PINNED_NOTES_ACTIVITIES_REQUEST,
    fetchPinnedNotesActivities
  );
}

export function* notesActivityUpdate() {
  yield takeLatest(UPDATE_NOTES_ACTIVITY_REQUEST, updateNotesActivityItem);
}

export function* formsActivitiesFetch() {
  yield takeLatest(FETCH_FORMS_ACTIVITIES_REQUEST, fetchFormsActivities);
}

export function* formsActivitiesPageChange() {
  yield takeEveryDeduplicate(
    FORMS_ACTIVITIES_PAGE_CHANGE_REQUEST,
    fetchFormsActivities
  );
}

export function* pinnedFormsActivitiesFetch() {
  yield takeLatest(
    FETCH_PINNED_FORMS_ACTIVITIES_REQUEST,
    fetchPinnedFormsActivities
  );
}

export function* formsActivityUpdate() {
  yield takeLatest(UPDATE_FORMS_ACTIVITY_REQUEST, updateFormsActivityItem);
}

export function* contactFormFetch() {
  yield takeLatest(FETCH_CONTACT_FORM_REQUEST, fetchContactForm);
}

export function* taskComplete() {
  yield takeLatest(COMPLETE_TASK_REQUEST, completeTask);
}

export function* taskEdit() {
  yield takeLatest(EDIT_TASK_REQUEST, editTask);
}

export function* taskReassign() {
  yield takeLatest(REASSIGN_TASK_REQUEST, reassignTask);
}

export function* taskCancel() {
  yield takeLatest(CANCEL_TASK_REQUEST, cancelTask);
}

export function* appointmentReschedule() {
  yield takeLatest(RESCHEDULE_APPOINTMENT_REQUEST, rescheduleAppointment);
}

export function* appointmentMarkNoShow() {
  yield takeLatest(MARK_APPOINTMENT_NO_SHOW_REQUEST, markAppointmentNoShow);
}

export function* appointmentCancel() {
  yield takeLatest(CANCEL_APPOINTMENT_REQUEST, cancelAppointment);
}
