export const CREATE_CONTACT_FORM = 'contactForm/CREATE_CONTACT_FORM';
export const CREATE_CONTACT_FORM_REQUEST = `${CREATE_CONTACT_FORM}_REQUEST`;
export const CREATE_CONTACT_FORM_SUCCESS = `${CREATE_CONTACT_FORM}_SUCCESS`;
export const CREATE_CONTACT_FORM_FAILURE = `${CREATE_CONTACT_FORM}_FAILURE`;

export const UPDATE_CONTACT_FORM = 'contactForm/UPDATE_CONTACT_FORM';
export const UPDATE_CONTACT_FORM_REQUEST = `${UPDATE_CONTACT_FORM}_REQUEST`;
export const UPDATE_CONTACT_FORM_SUCCESS = `${UPDATE_CONTACT_FORM}_SUCCESS`;
export const UPDATE_CONTACT_FORM_FAILURE = `${UPDATE_CONTACT_FORM}_FAILURE`;

export const DELETE_CONTACT_FORM = 'contactForm/DELETE_CONTACT_FORM';
export const DELETE_CONTACT_FORM_REQUEST = `${DELETE_CONTACT_FORM}_REQUEST`;
export const DELETE_CONTACT_FORM_SUCCESS = `${DELETE_CONTACT_FORM}_SUCCESS`;
export const DELETE_CONTACT_FORM_FAILURE = `${DELETE_CONTACT_FORM}_FAILURE`;
