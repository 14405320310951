import React from 'react';
import { action } from '@storybook/addon-actions';
import { withA11y } from '@storybook/addon-a11y';
import { withKnobs, text, boolean } from '@storybook/addon-knobs';
import TextInputsArray from './index';

export default {
  component: TextInputsArray,
  title: 'Text Inputs Array',
  decorators: [withA11y, withKnobs]
};

const features = ['Feature 1', 'Feature 2', 'Feature 3'];
const featuresWithError = ['Feature 1', 'Feature 2', ''];

export const standard = () => {
  return (
    <div className="tw-grid tw-grid-cols-10 tw-gap-6">
      <div className="tw-col-span-10 sm:tw-col-span-4 tw-grid tw-gap-6">
        <TextInputsArray
          id="features"
          value={features}
          labelText={text('Label Text', 'Features')}
          onChange={action('text-inputs-array-change')}
          showError={boolean('Show Error?', false)}
          error={text('Error Message', 'Feature cannot be empty.')}
          widthClass={text('Width Class', 'tw-flex-1 tw-mr-3')}
        />
      </div>
    </div>
  );
};

export const withError = () => {
  return (
    <div className="tw-grid tw-grid-cols-10 tw-gap-6">
      <div className="tw-col-span-10 sm:tw-col-span-4 tw-grid tw-gap-6">
        <TextInputsArray
          id="features"
          value={featuresWithError}
          labelText={text('Label Text', 'Features')}
          onChange={action('text-inputs-array-change')}
          showError={boolean('Show Error?', true)}
          error={text('Error Message', 'Feature cannot be empty.')}
          widthClass={text('Width Class', 'tw-flex-1 tw-mr-3')}
        />
      </div>
    </div>
  );
};
