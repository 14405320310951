import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { switchOffTestMode } from 'appState/actions/ActionCreators';
import {
  createLoadedSelector,
  createErrorMessageSelector
} from 'appState/selectors';
import isEmpty from 'lodash.isempty';
import Table from 'components/Theme/Table';
import LogoContent from 'components/SyncWizard/shared/LogoContent';
import HeaderComponent from 'components/SyncWizard/shared/HeaderComponent';
import Notification from 'components/Theme/Notification';
import { ZP_STR } from 'config/constants';

const ReviewPage = ({ contactsStats, testMode }) => {
  const exactData = contactsStats.contacts.exactMatch;
  const emailData = contactsStats.contacts.possibleMatchWithSameEmail;
  const nameData = contactsStats.contacts.possibleMatchWithSameName;
  const { recordsCount } = contactsStats.contacts;
  const zenPlannerTotalContacts =
    contactsStats.contacts.recordsCount.zenPlanner;

  let exactMatchCount = 0;
  exactData.forEach(elem => {
    if (elem[1].connect) {
      exactMatchCount += 1;
    }
  });

  function filterData(array, criteria) {
    return array.filter(elem => {
      const queryParams = Object.keys(criteria);
      for (let i = 0; i < queryParams.length; i += 1) {
        const key = queryParams[i];
        if (elem[key] === undefined || elem[key] !== criteria[key])
          return false;
      }
      return true;
    });
  }

  const dispatch = useDispatch();

  let duplicateEmailCount = 0;
  emailData.forEach(elem => {
    const query = { connect: true, source: ZP_STR };
    const filteredArray = filterData(elem, query);
    duplicateEmailCount += filteredArray.length;
  });

  let nameMatchCount = 0;
  nameData.forEach(elem => {
    const query = { connect: true, source: ZP_STR };
    const filteredArray = filterData(elem, query);
    nameMatchCount += filteredArray.length;
  });

  // let zenPlannerCount = 0;
  // contactsStats.contacts.newToZenPlanner.forEach(elem => {
  //   if (elem.connect) {
  //     zenPlannerCount += 1;
  //   }
  // });

  const totalMatchCount =
    exactMatchCount + duplicateEmailCount + nameMatchCount;

  const upLaunchCount = contactsStats.contacts.newToUplaunchContacts.filter(
    s => s.selected !== 'ignore'
  );
  const campaignCount = contactsStats.contacts.campaignMatch.filter(
    s => s[1].selected !== 'ignore'
  );

  const ignoredContactCount =
    zenPlannerTotalContacts -
    (totalMatchCount +
      upLaunchCount.length +
      campaignCount.length +
      recordsCount.alreadySynced);

  const tableRows = [
    {
      id: 'wzrd-0',
      'Wizard Summary': 'Steps 1 & 2: Contacts Connected',
      'Contact Count': totalMatchCount
    },
    {
      id: 'wzrd-1',
      'Wizard Summary': 'Step 3: Campaigns Corrected',
      'Contact Count': campaignCount.length
    },
    {
      id: 'wzrd-2',
      'Wizard Summary': 'Step 4: Contacts Added',
      'Contact Count': upLaunchCount.length
    },
    // {
    //   id: 'wzrd-3',
    //   'Wizard Summary': 'New Contacts to Zen Planner',
    //   'Contact Count': zenPlannerCount
    // },
    {
      id: 'wzrd-4',
      'Wizard Summary': 'Contacts Not Connected or Added',
      'Contact Count': ignoredContactCount
    }
  ];

  return (
    <div>
      <HeaderComponent
        title="Step 5: Review & Sync"
        subtitle="We are now ready to sync your contacts! After you click the “Sync my contacts!” button below, the process will begin. This process will take place in the background and could take up to 5 minutes."
      />
      <div className="tw-mx-10 tw-my-10">
        <LogoContent contactsStats={recordsCount} />
        <div className="tw-container tw-mx-auto tw-w-3/3 md:tw-w-2/3 lg:tw-w-1/3 tw-text-md tw-max-w-7xl tw-my-8">
          <Table
            key="Synced<->ZenPlanner<->Uplaunch-member-stats"
            rows={tableRows}
            includedColumns={['Wizard Summary', 'Contact Count']}
            emptyMessage="Synced<->ZenPlanner <-> Uplaunch Member Stats"
          />
        </div>
      </div>
    </div>
  );
};

ReviewPage.defaultProps = {
  testMode: false,
  contactsStats: {
    contacts: {
      newToUplaunchContact: []
    }
  }
};

ReviewPage.propTypes = {
  contactsStats: PropTypes.shape({
    contacts: PropTypes.shape({
      exactMatch: PropTypes.array.isRequired,
      possibleMatchWithSameEmail: PropTypes.array.isRequired,
      possibleMatchWithSameName: PropTypes.array.isRequired,
      campaignMatch: PropTypes.array,
      newToUplaunch: PropTypes.array.isRequired,
      newToUplaunchContacts: PropTypes.array,
      newToZenPlanner: PropTypes.array.isRequired,
      recordsCount: PropTypes.shape({
        alreadySynced: PropTypes.number.isRequired,
        zenPlanner: PropTypes.number.isRequired,
        upLaunch: PropTypes.number.isRequired,
        exactMatch: PropTypes.number.isRequired,
        possibleMatch: PropTypes.number.isRequired,
        newToUplaunch: PropTypes.number.isRequired,
        newToZenPlanner: PropTypes.number.isRequired
      }).isRequired
    }).isRequired
  }).isRequired,
  testMode: PropTypes.bool
};

export default ReviewPage;
