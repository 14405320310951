import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Styled from './Spinner.styles';

const Spinner = ({ visible, color, size, tint }) => {
  const [radius, setRadius] = useState(30);
  const [borderClass, setBorderClass] = useState('tw-border-4');

  const getSize = useCallback(() => {
    if (size === 'xs') {
      setRadius(15);
      setBorderClass('tw-border-2');
    }
    if (size === 'sm') {
      setRadius(20);
      setBorderClass('tw-border-3');
    }
    if (size === 'lg') {
      setRadius(40);
      setBorderClass('tw-border-5');
    }
  }, [size]);

  useEffect(() => {
    getSize();
  }, [getSize]);

  if (!visible) {
    return null;
  }

  return (
    <Styled.Spinner
      className={`tw-border-solid ${borderClass} tw-border-${color}${
        color === 'white' ? '' : `-${tint}`
      }`}
      style={{
        width: radius,
        height: radius
      }}
    />
  );
};

Spinner.defaultProps = {
  visible: true,
  color: 'alpha',
  size: 'md',
  tint: 'base'
};

Spinner.propTypes = {
  visible: PropTypes.bool,
  color: PropTypes.oneOf([
    'alpha',
    'bravo',
    'charlie',
    'success',
    'warning',
    'error',
    'gray',
    'white'
  ]),
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  tint: PropTypes.oneOf([
    '100',
    '200',
    '300',
    '400',
    '500',
    '600',
    '700',
    '800',
    '900',
    'base'
  ])
};

export default Spinner;
