import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import overlordLogo from 'images/overlord_icon.svg';
import { createStructuredSelector } from 'reselect';
import Skeleton from 'react-loading-skeleton';
import Image from 'components/Theme/Image';
import HeroIcon from 'components/Theme/HeroIcon';

const OverlordLayout = props => {
  const { children } = props;

  const { pathname } = useLocation();

  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const structuredSelector = createStructuredSelector({
    headerText: state => state.overlordLayout.headerText
  });

  const { headerText } = useSelector(structuredSelector);

  return (
    <div className="tw-min-h-screen tw-bg-gray-100">
      <div className="tw-bg-alpha-800 tw-pb-32">
        <nav className="tw-pt-3">
          <div className="tw-max-w-7xl tw-mx-auto tw-px-2 sm:tw-px-4 lg:tw-px-8">
            <div className="tw-relative tw-h-16 tw-flex tw-items-center tw-justify-between lg:tw-border-b lg:tw-border-alpha-400 lg:tw-border-opacity-25">
              <div className="tw-px-2 tw-flex tw-items-center lg:tw-px-0">
                <div className="tw-flex-shrink-0">
                  <Image
                    alt="Overlord Logo"
                    imageURL={overlordLogo}
                    className="tw-block tw-h-12 tw-w-100"
                    maxHeight="40px"
                    maxWidth="40px"
                  />
                </div>
                <div className="tw-hidden lg:tw-block lg:tw-ml-6">
                  <div className="tw-flex">
                    <a
                      href="/overlord/dashboard"
                      className={`tw-rounded-md ${
                        pathname === '/overlord/dashboard'
                          ? 'tw-bg-alpha-900'
                          : ''
                      } tw-py-2 tw-px-3 tw-text-sm tw-leading-5 tw-font-medium tw-text-white hover:tw-bg-alpha-700 focus:tw-outline-none focus:tw-bg-alpha-700 tw-transition tw-duration-150 tw-ease-in-out`}
                    >
                      Dashboard
                    </a>
                    <a
                      href="/overlord/plans"
                      className={`tw-ml-4 ${
                        pathname === '/overlord/plans' ? 'tw-bg-alpha-900' : ''
                      } tw-rounded-md tw-py-2 tw-px-3 tw-text-sm tw-leading-5 tw-font-medium tw-text-white hover:tw-bg-alpha-700 focus:tw-outline-none focus:tw-bg-alpha-700 tw-transition tw-duration-150 tw-ease-in-out`}
                    >
                      Plans
                    </a>
                    <a
                      href="/overlord/partners"
                      className={`tw-ml-4 ${
                        pathname === '/overlord/partners'
                          ? 'tw-bg-alpha-900'
                          : ''
                      } tw-rounded-md tw-py-2 tw-px-3 tw-text-sm tw-leading-5 tw-font-medium tw-text-white hover:tw-bg-alpha-700 focus:tw-outline-none focus:tw-bg-alpha-700 tw-transition tw-duration-150 tw-ease-in-out`}
                    >
                      Partners
                    </a>
                    <a
                      href="/overlord/background-job"
                      className={`tw-ml-4 ${
                        pathname === '/overlord/background-job'
                          ? 'tw-bg-alpha-900'
                          : ''
                      } tw-rounded-md tw-py-2 tw-px-3 tw-text-sm tw-leading-5 tw-font-medium tw-text-white hover:tw-bg-alpha-700 focus:tw-outline-none focus:tw-bg-alpha-700 tw-transition tw-duration-150 tw-ease-in-out`}
                    >
                      Background Job
                    </a>
                  </div>
                </div>
              </div>
              <div className="tw-flex lg:tw-hidden">
                <div
                  className="tw-p-2 tw-rounded-md tw-inline-flex tw-items-center tw-justify-center tw-text-alpha-300 hover:tw-text-white hover:tw-bg-alpha-700 focus:tw-outline-none focus:tw-bg-alpha-700 focus:tw-text-white tw-transition tw-duration-150 tw-ease-in-out"
                  aria-expanded="false"
                >
                  <span className="tw-sr-only">Open main menu</span>
                  {!showMobileMenu ? (
                    <HeroIcon
                      icon="menu"
                      color="gray"
                      height={6}
                      width={6}
                      onClick={() => setShowMobileMenu(!showMobileMenu)}
                    />
                  ) : (
                    <HeroIcon
                      icon="x"
                      color="gray"
                      height={6}
                      width={6}
                      onClick={() => setShowMobileMenu(!showMobileMenu)}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div
            className={
              showMobileMenu ? 'lg:tw-hidden' : 'tw-hidden lg:tw-hidden'
            }
          >
            <div className="tw-px-2 tw-pt-2 tw-pb-3">
              <a
                href="/overlord/dashboard"
                className="tw-block tw-bg-alpha-900 tw-rounded-md tw-py-2 tw-px-3 tw-text-base tw-font-medium tw-text-white focus:tw-outline-none focus:tw-text-white focus:tw-bg-alpha-700 tw-transition tw-duration-150 tw-ease-in-out"
              >
                Dashboard
              </a>
              <a
                href="/overlord/plans"
                className="tw-block tw-mt-1 tw-rounded-md tw-py-2 tw-px-3 tw-text-base tw-font-medium tw-text-white hover:tw-text-white hover:tw-bg-alpha-700 focus:tw-outline-none focus:tw-text-white focus:tw-bg-alpha-700 tw-transition tw-duration-150 tw-ease-in-out"
              >
                Plans
              </a>
              <a
                href="/overlord/background-job"
                className="tw-block tw-mt-1 tw-rounded-md tw-py-2 tw-px-3 tw-text-base tw-font-medium tw-text-white hover:tw-text-white hover:tw-bg-alpha-700 focus:tw-outline-none focus:tw-text-white focus:tw-bg-alpha-700 tw-transition tw-duration-150 tw-ease-in-out"
              >
                Background Job
              </a>
              <a
                href="#"
                className="tw-block tw-mt-1 tw-rounded-md tw-py-2 tw-px-3 tw-text-base tw-font-medium tw-text-white hover:tw-text-white hover:tw-bg-alpha-700 focus:tw-outline-none focus:tw-text-white focus:tw-bg-alpha-700 tw-transition tw-duration-150 tw-ease-in-out"
              >
                Projects
              </a>
              <a
                href="#"
                className="tw-block tw-mt-1 tw-rounded-md tw-py-2 tw-px-3 tw-text-base tw-font-medium tw-text-white hover:tw-text-white hover:tw-bg-alpha-700 focus:tw-outline-none focus:tw-text-white focus:tw-bg-alpha-700 tw-transition tw-duration-150 tw-ease-in-out"
              >
                Calendar
              </a>
            </div>
          </div>
        </nav>
        <header className="tw-py-10">
          <div className="tw-max-w-7xl tw-mx-auto tw-px-4 sm:tw-px-6 lg:tw-px-8">
            <h1 className="tw-text-3xl tw-leading-9 tw-font-bold tw-text-white">
              {headerText || <Skeleton height={30} width={200} />}
            </h1>
          </div>
        </header>
      </div>

      <main className="tw--mt-32">
        <div className="tw-max-w-7xl tw-mx-auto tw-pb-12 tw-px-4 sm:tw-px-6 lg:tw-px-8">
          <div className="tw-bg-white tw-rounded-lg tw-shadow tw-px-5 tw-py-6 sm:tw-px-6">
            {children}
          </div>
        </div>
      </main>
    </div>
  );
};

OverlordLayout.defaultProps = {
  children: null
};

OverlordLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func])
};

export default OverlordLayout;
