import React, { Fragment } from 'react';
import Breadcrumb from 'components/Theme/Breadcrumb';
import HeroIcon from 'components/Theme/HeroIcon';
import PropTypes from 'prop-types';

const Breadcrumbs = ({ crumbs, isBackgroundWhite }) => {
  return (
    <Fragment>
      <nav className="tw-hidden sm:tw-flex tw-items-center tw-text-sm tw-leading-5 tw-font-medium">
        {crumbs.map((crumb, index) => (
          <Fragment key={crumb.name}>
            <Breadcrumb
              key={crumb.name}
              name={crumb.name}
              action={crumb.action}
              isBackgroundWhite={isBackgroundWhite}
            />
            {index !== crumbs.length - 1 ? (
              <div className="tw-mx-2 tw-flex tw-items-center">
                <HeroIcon
                  icon="chevron-right"
                  height={5}
                  width={5}
                  color="gray"
                  strokeWidth={3}
                />
              </div>
            ) : null}
          </Fragment>
        ))}
      </nav>
    </Fragment>
  );
};

const crumbShape = PropTypes.shape({
  name: PropTypes.string,
  action: PropTypes.func
});

Breadcrumbs.defaultProps = {
  isBackgroundWhite: true
};

Breadcrumbs.propTypes = {
  crumbs: PropTypes.arrayOf(crumbShape).isRequired,
  isBackgroundWhite: PropTypes.bool
};

export default Breadcrumbs;
