export const FETCH_ZEN_PLANNER_PROGRAMS =
  'zen_planner/FETCH_ZEN_PLANNER_PROGRAMS';
export const FETCH_ZEN_PLANNER_PROGRAMS_REQUEST = `${FETCH_ZEN_PLANNER_PROGRAMS}_REQUEST`;
export const FETCH_ZEN_PLANNER_PROGRAMS_SUCCESS = `${FETCH_ZEN_PLANNER_PROGRAMS}_SUCCESS`;
export const FETCH_ZEN_PLANNER_PROGRAMS_FAILURE = `${FETCH_ZEN_PLANNER_PROGRAMS}_FAILURE`;
export const FETCH_SELECTED_LINKED_ZEN_PLANNER =
  'zen_planner/FETCH_SELECTED_LINKED_ZEN_PLANNER';
export const FETCH_SELECTED_LINKED_ZEN_PLANNER_REQUEST = `${FETCH_SELECTED_LINKED_ZEN_PLANNER}_REQUEST`;
export const FETCH_SELECTED_LINKED_ZEN_PLANNER_SUCCESS = `${FETCH_SELECTED_LINKED_ZEN_PLANNER}_SUCCESS`;
export const FETCH_SELECTED_LINKED_ZEN_PLANNER_FAILURE = `${FETCH_SELECTED_LINKED_ZEN_PLANNER}_FAILURE`;

export const CREATE_SYNC_ZP_PLANNER_WITH_PRODUCT =
  'zen_planner/CREATE_SYNC_ZP_PLANNER_WITH_PRODUCT';
export const CREATE_SYNC_ZP_PLANNER_WITH_PRODUCT_REQUEST = `${CREATE_SYNC_ZP_PLANNER_WITH_PRODUCT}_REQUEST`;
export const CREATE_SYNC_ZP_PLANNER_WITH_PRODUCT_SUCCESS = `${CREATE_SYNC_ZP_PLANNER_WITH_PRODUCT}_SUCCESS`;
export const CREATE_SYNC_ZP_PLANNER_WITH_PRODUCT_FAILURE = `${CREATE_SYNC_ZP_PLANNER_WITH_PRODUCT}_FAILURE`;
