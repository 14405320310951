const loadedReducer = (state = {}, action) => {
  const { type } = action;
  const resetRequestName = action.payload;
  const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(type);
  const resetIsLoaded = type === 'overlord/RESET_IS_LOADED_REQUEST';

  if (!matches) return state;

  const [, requestName, requestState] = matches;

  let newLoaderState;

  if (resetIsLoaded) {
    newLoaderState = { [resetRequestName]: false };
  } else {
    newLoaderState = { [requestName]: requestState !== 'REQUEST' };
  }

  return {
    ...state,
    ...newLoaderState
  };
};

export default loadedReducer;
