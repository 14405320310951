import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const Pagination = ({
  total,
  totalPages,
  limit,
  action,
  tableIdentifier,
  isScrollFixed
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [tableId, setTableId] = useState('');
  useEffect(() => {
    if (tableIdentifier !== tableId) {
      setCurrentPage(1);
      setTableId(tableIdentifier);
    }
  }, [tableIdentifier, tableId]);

  const onPreviousPageClick = e => {
    if (e && e.code && e.code !== 'Enter') return;
    if (!isScrollFixed) window.scrollTo(0, 0);
    const prevPage = currentPage - 1;
    if (currentPage !== 1) {
      setCurrentPage(prevPage);
      if (action) action({ page: prevPage, totalPages });
    }
  };

  const onNextPageClick = e => {
    if (e && e.code && e.code !== 'Enter') return;
    if (!isScrollFixed) window.scrollTo(0, 0);
    const nextPage = currentPage + 1;
    if (currentPage !== totalPages) {
      setCurrentPage(nextPage);
      if (action) action({ page: nextPage, totalPages });
    }
  };

  return (
    <nav className="tw-bg-white tw-px-4 tw-py-3 tw-flex tw-items-center tw-justify-between sm:tw-px-6">
      <div className="tw-hidden sm:tw-block">
        <p className="tw-font-body tw-text-sm tw-mb-0 tw-leading-5 tw-text-gray-700">
          <span className="tw-mr-1">Showing</span>
          <span className="tw-font-medium tw-mr-1">
            {currentPage * limit - (limit - 1)}
          </span>
          <span className="tw-mr-1">to</span>
          <span className="tw-font-medium tw-mr-1">
            {currentPage * limit > total ? total : currentPage * limit}
          </span>
          <span className="tw-mr-1">of</span>
          <span className="tw-font-medium tw-mr-1">{total}</span>
          results
        </p>
      </div>
      <div className="tw-flex-1 tw-flex tw-justify-between sm:tw-justify-end">
        {currentPage !== 1 && (
          <a
            className="tw-font-body tw-cursor-pointer tw-relative tw-inline-flex tw-items-center tw-px-4 tw-py-2 tw-border-solid tw-border tw-border-gray-300 tw-text-sm tw-leading-5 tw-font-medium tw-rounded-md tw-text-gray-700 tw-bg-white hover:tw-text-gray-500 focus:tw-outline-none focus:tw-shadow-outline-blue focus:tw-border-blue-300 active:tw-bg-gray-100 active:tw-text-gray-700 tw-transition tw-ease-in-out tw-duration-150"
            onClick={onPreviousPageClick}
            onKeyDown={onPreviousPageClick}
            role="button"
            tabIndex={-1}
            data-testid="previous"
          >
            Previous
          </a>
        )}
        {totalPages !== currentPage ? (
          <a
            className="tw-font-body tw-cursor-pointer tw-ml-3 tw-relative tw-inline-flex tw-items-center tw-px-4 tw-py-2 tw-border tw-border-solid tw-border-gray-300 tw-text-sm tw-leading-5 tw-font-medium tw-rounded-md tw-text-gray-700 tw-bg-white hover:tw-text-gray-500 focus:tw-outline-none focus:tw-shadow-outline-blue focus:tw-border-blue-300 active:tw-bg-gray-100 active:tw-text-gray-700 tw-transition tw-ease-in-out tw-duration-150"
            onClick={onNextPageClick}
            onKeyDown={onNextPageClick}
            role="button"
            tabIndex={-1}
            data-testid="next"
          >
            Next
          </a>
        ) : (
          ''
        )}
      </div>
    </nav>
  );
};

Pagination.defaultProps = {
  action: null,
  tableIdentifier: '',
  isScrollFixed: false
};

Pagination.propTypes = {
  total: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  action: PropTypes.func,
  tableIdentifier: PropTypes.string,
  isScrollFixed: PropTypes.bool
};

export default Pagination;
