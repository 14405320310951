/**
 * Plan Builder
 */

/* Plans */
export const FETCH_PLANS = 'plan/FETCH_PLANS';
export const FETCH_PLANS_REQUEST = `${FETCH_PLANS}_REQUEST`;
export const FETCH_PLANS_SUCCESS = `${FETCH_PLANS}_SUCCESS`;
export const FETCH_PLANS_FAILURE = `${FETCH_PLANS}_FAILURE`;

export const FETCH_PLAN_PAGE = 'plan/FETCH_PLAN_PAGE';
export const FETCH_PLAN_PAGE_REQUEST = `${FETCH_PLAN_PAGE}_REQUEST`;
export const FETCH_PLAN_PAGE_SUCCESS = `${FETCH_PLAN_PAGE}_SUCCESS`;
export const FETCH_PLAN_PAGE_FAILURE = `${FETCH_PLAN_PAGE}_FAILURE`;

export const CREATE_PLAN = 'plan/CREATE_PLAN';
export const CREATE_PLAN_REQUEST = `${CREATE_PLAN}_REQUEST`;
export const CREATE_PLAN_SUCCESS = `${CREATE_PLAN}_SUCCESS`;
export const CREATE_PLAN_FAILURE = `${CREATE_PLAN}_FAILURE`;

export const UPDATE_PLAN = 'plan/UPDATE_PLAN';
export const UPDATE_PLAN_REQUEST = `${UPDATE_PLAN}_REQUEST`;
export const UPDATE_PLAN_SUCCESS = `${UPDATE_PLAN}_SUCCESS`;
export const UPDATE_PLAN_FAILURE = `${UPDATE_PLAN}_FAILURE`;

export const DELETE_PLAN = 'plan/DELETE_PLAN';
export const DELETE_PLAN_REQUEST = `${DELETE_PLAN}_REQUEST`;
export const DELETE_PLAN_SUCCESS = `${DELETE_PLAN}_SUCCESS`;
export const DELETE_PLAN_FAILURE = `${DELETE_PLAN}_FAILURE`;

export const AVAILABLE_PLAN = 'plan/AVAILABLE_PLAN';
export const AVAILABLE_PLAN_REQUEST = `${AVAILABLE_PLAN}_REQUEST`;
export const AVAILABLE_PLAN_SUCCESS = `${AVAILABLE_PLAN}_SUCCESS`;
export const AVAILABLE_PLAN_FAILURE = `${AVAILABLE_PLAN}_FAILURE`;

export const RESET_CURRENT_PLAN = 'plan/RESET_CURRENT_PLAN';

/* SMS Credit Packs */
export const FETCH_SMS_CREDIT_PACKS = 'plan/FETCH_SMS_CREDIT_PACKS';
export const FETCH_SMS_CREDIT_PACKS_REQUEST = `${FETCH_SMS_CREDIT_PACKS}_REQUEST`;
export const FETCH_SMS_CREDIT_PACKS_SUCCESS = `${FETCH_SMS_CREDIT_PACKS}_SUCCESS`;
export const FETCH_SMS_CREDIT_PACKS_FAILURE = `${FETCH_SMS_CREDIT_PACKS}_FAILURE`;

export const FETCH_SMS_CREDIT_PACK = 'plan/FETCH_SMS_CREDIT_PACK';
export const FETCH_SMS_CREDIT_PACK_REQUEST = `${FETCH_SMS_CREDIT_PACK}_REQUEST`;
export const FETCH_SMS_CREDIT_PACK_SUCCESS = `${FETCH_SMS_CREDIT_PACK}_SUCCESS`;
export const FETCH_SMS_CREDIT_PACK_FAILURE = `${FETCH_SMS_CREDIT_PACK}_FAILURE`;

export const CREATE_SMS_CREDIT_PACK = 'plan/CREATE_SMS_CREDIT_PACK';
export const CREATE_SMS_CREDIT_PACK_REQUEST = `${CREATE_SMS_CREDIT_PACK}_REQUEST`;
export const CREATE_SMS_CREDIT_PACK_SUCCESS = `${CREATE_SMS_CREDIT_PACK}_SUCCESS`;
export const CREATE_SMS_CREDIT_PACK_FAILURE = `${CREATE_SMS_CREDIT_PACK}_FAILURE`;

export const UPDATE_SMS_CREDIT_PACK = 'plan/UPDATE_SMS_CREDIT_PACK';
export const UPDATE_SMS_CREDIT_PACK_REQUEST = `${UPDATE_SMS_CREDIT_PACK}_REQUEST`;
export const UPDATE_SMS_CREDIT_PACK_SUCCESS = `${UPDATE_SMS_CREDIT_PACK}_SUCCESS`;
export const UPDATE_SMS_CREDIT_PACK_FAILURE = `${UPDATE_SMS_CREDIT_PACK}_FAILURE`;

export const DELETE_SMS_CREDIT_PACK = 'plan/DELETE_SMS_CREDIT_PACK';
export const DELETE_SMS_CREDIT_PACK_REQUEST = `${DELETE_SMS_CREDIT_PACK}_REQUEST`;
export const DELETE_SMS_CREDIT_PACK_SUCCESS = `${DELETE_SMS_CREDIT_PACK}_SUCCESS`;
export const DELETE_SMS_CREDIT_PACK_FAILURE = `${DELETE_SMS_CREDIT_PACK}_FAILURE`;

/* Onboarding Fees */
export const FETCH_ONBOARDING_FEES = 'plan/FETCH_ONBOARDING_FEES';
export const FETCH_ONBOARDING_FEES_REQUEST = `${FETCH_ONBOARDING_FEES}_REQUEST`;
export const FETCH_ONBOARDING_FEES_SUCCESS = `${FETCH_ONBOARDING_FEES}_SUCCESS`;
export const FETCH_ONBOARDING_FEES_FAILURE = `${FETCH_ONBOARDING_FEES}_FAILURE`;

export const FETCH_ONBOARDING_FEE = 'plan/FETCH_ONBOARDING_FEE';
export const FETCH_ONBOARDING_FEE_REQUEST = `${FETCH_ONBOARDING_FEE}_REQUEST`;
export const FETCH_ONBOARDING_FEE_SUCCESS = `${FETCH_ONBOARDING_FEE}_SUCCESS`;
export const FETCH_ONBOARDING_FEE_FAILURE = `${FETCH_ONBOARDING_FEE}_FAILURE`;

export const CREATE_ONBOARDING_FEE = 'plan/CREATE_ONBOARDING_FEE';
export const CREATE_ONBOARDING_FEE_REQUEST = `${CREATE_ONBOARDING_FEE}_REQUEST`;
export const CREATE_ONBOARDING_FEE_SUCCESS = `${CREATE_ONBOARDING_FEE}_SUCCESS`;
export const CREATE_ONBOARDING_FEE_FAILURE = `${CREATE_ONBOARDING_FEE}_FAILURE`;

export const UPDATE_ONBOARDING_FEE = 'plan/UPDATE_ONBOARDING_FEE';
export const UPDATE_ONBOARDING_FEE_REQUEST = `${UPDATE_ONBOARDING_FEE}_REQUEST`;
export const UPDATE_ONBOARDING_FEE_SUCCESS = `${UPDATE_ONBOARDING_FEE}_SUCCESS`;
export const UPDATE_ONBOARDING_FEE_FAILURE = `${UPDATE_ONBOARDING_FEE}_FAILURE`;

export const DELETE_ONBOARDING_FEE = 'plan/DELETE_ONBOARDING_FEE';
export const DELETE_ONBOARDING_FEE_REQUEST = `${DELETE_ONBOARDING_FEE}_REQUEST`;
export const DELETE_ONBOARDING_FEE_SUCCESS = `${DELETE_ONBOARDING_FEE}_SUCCESS`;
export const DELETE_ONBOARDING_FEE_FAILURE = `${DELETE_ONBOARDING_FEE}_FAILURE`;

/* Partner for dropdown */
export const FETCH_PARTNER = 'plan/FETCH_PARTNER';
export const FETCH_PARTNER_REQUEST = `${FETCH_PARTNER}_REQUEST`;
export const FETCH_PARTNER_SUCCESS = `${FETCH_PARTNER}_SUCCESS`;
export const FETCH_PARTNER_FAILURE = `${FETCH_PARTNER}_FAILURE`;
