/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useContext, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createErrorMessageSelector,
  createLoadedSelector,
  createLoadingSelector
} from 'app-state/selectors';
import { createStructuredSelector } from 'reselect';
import {
  journeyCreate,
  fetchProductSubscription,
  journeyUpdate,
  updateProductSubscription,
  flashSuccessMessage
} from 'app-state/actions/ActionCreators';
import PageHeader from 'components/Theme/PageHeader';
import Card from 'components/Theme/Card';
import StepOne from 'components/ProductSubscriptionSetup/StepOne';
import StepTwo from 'components/ProductSubscriptionSetup/StepTwo';
import StepThree from 'components/ProductSubscriptionSetup/StepThree';
import ProgressBar from 'components/Theme/ProgressBar';
import Footer from 'components/SyncWizard/shared/Footer';
import Modal from 'components/Theme/Modal';
import { useFormik } from 'formik';
import {
  productSetupStepOneSchema,
  productSetupStepTwoSchema
} from 'lib/validation/schema';
import * as StepConstants from 'components/ProductSubscriptionSetup/shared/StepConstants';
import { CurrentAccountContext } from 'components/shared/context/CurrentAccountContext';
import isEmpty from 'lodash.isempty';
import { CurrentUserContext } from 'components/shared/context/CurrentUserContext';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment';
import {
  CREATE_JOURNEY,
  UPDATE_JOURNEY
} from 'app-state/actions/constants/journey.actions';
import { UPDATE_PRODUCT_SUBSCRIPTION } from 'app-state/actions/constants/productSubscription.actions';
import ZenSyncPlanner from '../ZenPlannerIntergration/ZenSyncPlanner';

const ProductSubscriptionSetup = () => {
  const currentAccount = useContext(CurrentAccountContext);
  const [currentStep, setCurrentStep] = useState(1);
  const currentUser = useContext(CurrentUserContext);
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const errorSelector = createErrorMessageSelector([CREATE_JOURNEY]);
  const myProductsPath = '/ui/my-products';
  const loadedSelector = createLoadedSelector([
    'productSubscription/FETCH_PRODUCT_SUBSCRIPTION'
  ]);
  const [isZenPlannerModalOpen, setZenPlannerModalOpen] = useState(false);

  const loadingSelector = createLoadingSelector([
    'productSubscription/FETCH_PRODUCT_SUBSCRIPTION'
  ]);

  const createJourneyLoadedSelector = createLoadedSelector([CREATE_JOURNEY]);

  const updateJourneyLoadedSelector = createLoadedSelector([UPDATE_JOURNEY]);

  const updateProductSubscriptionLoadedSelector = createLoadedSelector([
    UPDATE_PRODUCT_SUBSCRIPTION
  ]);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const structuredSelector = createStructuredSelector({
    productSubscription: state =>
      state.productSubscription.currentProductSubscription,
    isCreateJourneyLoaded: state => createJourneyLoadedSelector(state),
    isUpdateJourneyLoaded: state => updateJourneyLoadedSelector(state),
    isUpdateProductSubscriptionLoaded: state =>
      updateProductSubscriptionLoadedSelector(state),
    isLoading: state => loadingSelector(state),
    isLoaded: state => loadedSelector(state),
    error: state => errorSelector(state)
  });

  const {
    productSubscription,
    isCreateJourneyLoaded,
    isUpdateJourneyLoaded,
    isUpdateProductSubscriptionLoaded,
    error
  } = useSelector(structuredSelector);

  useEffect(() => {
    if (
      isEmpty(productSubscription) ||
      productSubscription.id !== parseInt(id)
    ) {
      dispatch(
        fetchProductSubscription({
          productSubscriptionId: id,
          currentAccountId: currentAccount.id
        })
      );
    }
  }, [dispatch, currentAccount, productSubscription, id]);

  function getDefaultProductValue() {
    const {
      product,
      product_id,
      default_lead_product_id,
      default_client_product_id
    } = productSubscription;
    let defaultProduct = false;

    if (product?.lifecycles?.includes('lead')) {
      defaultProduct =
        default_lead_product_id === null ||
        default_lead_product_id === product_id;
    }
    if (!defaultProduct && product?.lifecycles?.includes('client')) {
      defaultProduct =
        default_client_product_id === null ||
        default_client_product_id === product_id;
    }
    return defaultProduct;
  }

  function getAssignedStaffId() {
    if (productSubscription?.journey?.different_lead_staff_assignment_ids[0]) {
      return productSubscription?.journey
        ?.different_lead_staff_assignment_ids[0];
    }

    if (currentUser?.overlord) {
      return currentAccount?.admin_user_id;
    }

    return currentUser?.id;
  }
  const assignedStaffId = getAssignedStaffId();
  const defaultProdutctValue = getDefaultProductValue();
  const formik = useFormik({
    initialValues: {
      name:
        productSubscription?.journey?.name ??
        productSubscription?.product?.name ??
        '',
      landingPageTitle: productSubscription?.journey?.landing_page_title ?? '',
      landingPageCta: productSubscription?.journey?.landing_page_cta ?? '',
      nameFrom: productSubscription?.journey?.name_from ?? 'assigned_staff',
      customNameFrom: '',
      campaignOwnerId: productSubscription?.journey?.campaign_owner_id ?? '',
      assignedStaff: productSubscription?.journey
        ?.assign_to_appointment_type_staff
        ? StepConstants.ASSIGNED_STAFF
        : StepConstants.DIFFERENT_STAFF,
      assignedStaffId,
      bookedStaff:
        productSubscription?.journey?.booked_staff ||
        StepConstants.ANY_ASSIGNED_STAFF,
      appointmentTypeId:
        productSubscription?.journey?.appointment_type_id || '',
      appointmentTypeName: '',
      hasAppointment: productSubscription?.journey?.has_appointment ?? true,
      productId: productSubscription?.product?.id,
      optInRedirectEnabled:
        productSubscription?.journey?.opt_in_redirect_enabled ?? false,
      optInRedirectUrl: productSubscription?.journey?.opt_in_redirect_url || '',
      hasDefaultProduct: defaultProdutctValue
    },
    enableReinitialize: true,
    validationSchema:
      currentStep === 1 ? productSetupStepOneSchema : productSetupStepTwoSchema,
    onSubmit: values => {
      scrollToTop();
      if (currentStep === 3 || (!values.hasAppointment && currentStep === 2)) {
        const journeyParams = {};
        if (Object.keys(values).length)
          Object.keys(values).forEach(function assignParams(key) {
            if (key === 'nameFrom' && values[key] === 'others') {
              journeyParams[key] = values.customNameFrom;
            } else {
              journeyParams[key] = values[key];
            }
          });
        if (isEmpty(productSubscription?.journey?.id?.toString())) {
          dispatch(journeyCreate({ data: journeyParams }));
        } else {
          dispatch(
            journeyUpdate({
              journeyId: productSubscription.journey.id,
              data: journeyParams
            })
          );
        }
      } else setCurrentStep(currentStep + 1);
    }
  });

  const steps = formik.values.hasAppointment
    ? [
        {
          text: 'Step 1'
        },
        {
          text: 'Step 2'
        },
        {
          text: 'Step 3'
        }
      ]
    : [
        {
          text: 'Step 1'
        },
        {
          text: 'Step 2'
        }
      ];

  const nextButtonText = currentStep === steps.length ? 'Submit' : 'Next';

  useEffect(() => {
    if (isCreateJourneyLoaded && isEmpty(error)) {
      dispatch(
        updateProductSubscription({
          productSubscriptionId: productSubscription.id,
          currentAccountId: currentAccount.id,
          params: { setup_at: moment().format() }
        })
      );
    }
  }, [dispatch, isCreateJourneyLoaded, error]);

  useEffect(() => {
    if (
      isUpdateJourneyLoaded &&
      isEmpty(error) &&
      !productSubscription.setup_at
    ) {
      dispatch(
        updateProductSubscription({
          productSubscriptionId: productSubscription.id,
          currentAccountId: currentAccount.id,
          params: { setup_at: moment().format() }
        })
      );
    }
  }, [dispatch, isUpdateJourneyLoaded, error]);

  useEffect(() => {
    if (
      (isUpdateJourneyLoaded || isUpdateProductSubscriptionLoaded) &&
      isEmpty(error)
    ) {
      dispatch(
        flashSuccessMessage('Product setup changes saved successfully.')
      );
      window.location = myProductsPath;
    }
  }, [
    dispatch,
    isUpdateJourneyLoaded,
    isUpdateProductSubscriptionLoaded,
    error
  ]);

  const getSecondaryAction = () => {
    scrollToTop();
    setCurrentStep(currentStep - 1);
  };

  const getCurrentStepComponent = () => {
    if (currentStep === 1)
      return (
        <StepOne
          product={productSubscription?.product}
          formik={formik}
          isDefaultProductReadOnly={defaultProdutctValue}
          setZenPlannerModalOpen={setZenPlannerModalOpen}
        />
      );

    if (currentStep === 2 && formik.values.hasAppointment)
      return <StepTwo product={productSubscription?.product} formik={formik} />;

    return (
      <StepThree
        product={productSubscription?.product}
        data={formik.values}
        handleSuccessRedirect={() => null}
      />
    );
  };

  const getModalBody = () => {
    return <ZenSyncPlanner />;
  };

  return (
    <div>
      <PageHeader
        title={
          isEmpty(productSubscription?.product?.name)
            ? ''
            : `Setup: ${productSubscription?.product?.name}`
        }
        breadcrumbs={[
          {
            name: 'My Products',
            action: () => {
              history.push(myProductsPath);
            }
          },
          {
            name: productSubscription?.name,
            action: () => {
              return null;
            }
          }
        ]}
        backgroundColor="white"
        isLoaded={!isEmpty(productSubscription)}
      />
      <Card
        containerClass="tw-rounded-lg tw-bg-white tw-m-8 tw-mb-20 tw-min-h-screen"
        customMarginContainer
      >
        {!isEmpty(productSubscription?.product) ? (
          <Fragment>
            <div className="tw-pt-10">
              <ProgressBar
                completed={currentStep}
                styled
                showSteps={false}
                steps={steps}
                className="tw-mx-auto"
              />
            </div>
            {getCurrentStepComponent()}
            <div>
              <Footer
                primaryActionText={nextButtonText}
                primaryAction={formik.handleSubmit}
                showSecondaryAction={currentStep > 1}
                secondaryActionText="Back"
                secondaryAction={getSecondaryAction}
              />
            </div>
          </Fragment>
        ) : null}
      </Card>
      {currentAccount.link_products_with_platform && (
        <Modal
          color="error"
          outerContainerClasses="lg:tw-max-w-4xl"
          innerContainerClasses=""
          disableFlex
          showHeaderIcon={false}
          show={isZenPlannerModalOpen}
          showPrimaryAction={false}
          canHandleClickOutside
          setShow={setZenPlannerModalOpen}
        >
          {getModalBody()}
        </Modal>
      )}
    </div>
  );
};

export default ProductSubscriptionSetup;
