import React from 'react';
import { action } from '@storybook/addon-actions';
import { withA11y } from '@storybook/addon-a11y';
import { withKnobs, text, boolean } from '@storybook/addon-knobs';
import TextArea from './index';

export default {
  component: TextArea,
  title: 'Text Area',
  decorators: [withA11y, withKnobs]
};

export const withLabel = () => (
  <TextArea
    id="description"
    defaultValue=""
    placeholder={text('Placeholder', 'you@example.com')}
    labelText={text('Label Text', 'Description')}
    showError={boolean('Show Error', false)}
    error={text('Error Text', 'Description is required.')}
    helpText={text('Help Text', '')}
    hideLabel={boolean('Hide Label', false)}
    widthClass={text('Width Class', 'tw-max-w-xs')}
    onChange={action('text-area-change')}
    onBlur={action('text-area-blur')}
  />
);

export const withLabelAndHelpText = () => (
  <TextArea
    id="description"
    defaultValue=""
    placeholder={text('Placeholder', '')}
    labelText={text('Label Text', 'Description')}
    showError={boolean('Show Error', false)}
    error={text('Error Text', 'Description is required.')}
    helpText={text('Help Text', 'Make your description short and concise.')}
    hideLabel={boolean('Hide Label', false)}
    widthClass={text('Width Class', 'tw-max-w-xs')}
    onChange={action('text-area-change')}
    onBlur={action('text-area-blur')}
  />
);

export const withError = () => (
  <TextArea
    id="description"
    defaultValue=""
    placeholder={text('Placeholder', '')}
    labelText={text('Label Text', 'Description')}
    showError={boolean('Show Error', true)}
    error={text('Error Text', 'Description is required.')}
    helpText={text('Help Text', '')}
    hideLabel={boolean('Hide Label', false)}
    widthClass={text('Width Class', 'tw-max-w-xs')}
    onChange={action('text-area-change')}
    onBlur={action('text-area-blur')}
  />
);

export const withHiddenLabel = () => (
  <TextArea
    id="description"
    defaultValue=""
    placeholder={text('Placeholder', 'you@example.com')}
    labelText={text('Label Text', 'Description')}
    type={text('Type', 'text')}
    showError={boolean('Show Error', false)}
    error={text('Error Text', 'Description is required.')}
    helpText={text('Help Text', '')}
    hideLabel={boolean('Hide Label', true)}
    widthClass={text('Width Class', 'tw-max-w-xs')}
    onChange={action('text-area-change')}
    onBlur={action('text-area-blur')}
  />
);
