/* eslint-disable react/display-name */
import React from 'react';
import { withA11y } from '@storybook/addon-a11y';
import { withKnobs, select } from '@storybook/addon-knobs';
import Tabs from './index';

export default {
  component: Tabs,
  title: 'Tabs',
  decorators: [withA11y, withKnobs]
};

let colorSelect;
let typeSelect;

function includeColorSelect(defaultValue) {
  const label = 'Color';
  const options = [
    'alpha',
    'bravo',
    'charlie',
    'success',
    'warning',
    'error',
    'gray'
  ];
  colorSelect = select(label, options, defaultValue);
}

function includeTypeSelect(defaultValue) {
  const label = 'Type';
  const options = ['primary', 'secondary', 'white'];
  typeSelect = select(label, options, defaultValue);
}

const sampleTabs = [
  {
    text: 'First Tab',
    value: 'firstTab',
    icon: (isSelected, color) => (
      <svg
        className={`tw--ml-0.5 tw-mr-2 tw-h-5 tw-w-5 ${
          isSelected
            ? `tw-text-${color}-500 group-focus:tw-text-${color}-600`
            : 'tw-text-gray-400 group-hover:tw-text-gray-500 tw-group-focus:tw-text-gray-600'
        }`}
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
          clipRule="evenodd"
        />
      </svg>
    )
  },
  {
    text: 'Second Tab',
    value: 'secondTab',
    icon: (isSelected, color) => (
      <svg
        className={`tw--ml-0.5 tw-mr-2 tw-h-5 tw-w-5 ${
          isSelected
            ? `tw-text-${color}-500 group-focus:tw-text-${color}-600`
            : 'tw-text-gray-400 group-hover:tw-text-gray-500 group-focus:tw-text-gray-600'
        }`}
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z"
          clipRule="evenodd"
        />
      </svg>
    )
  }
];

export const primary = () => {
  includeColorSelect('alpha');
  includeTypeSelect('primary');
  return <Tabs tabs={sampleTabs} color={colorSelect} type={typeSelect} />;
};

export const secondary = () => {
  includeColorSelect('alpha');
  includeTypeSelect('secondary');
  return <Tabs tabs={sampleTabs} color={colorSelect} type={typeSelect} />;
};

export const gray = () => {
  includeColorSelect('alpha');
  includeTypeSelect('gray');
  return <Tabs tabs={sampleTabs} color={colorSelect} type={typeSelect} />;
};

export const withIcons = () => {
  includeColorSelect('alpha');
  includeTypeSelect('withIcons');
  return <Tabs tabs={sampleTabs} color={colorSelect} type={typeSelect} />;
};
