import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
  Fragment
} from 'react';
import PropTypes from 'prop-types';
import { InPortal } from 'react-reverse-portal';
import isEmpty from 'lodash.isempty';
import debounce from 'lodash.debounce';
import BulletSteps from 'components/Theme/BulletSteps';
import Transition from 'components/Transition';
import useOnClickOutside from 'components/shared/hooks/useOnClickOutside';
import { PortalContext } from 'components/shared/context/PortalContext';

// tw-top-1/8 is set to 12.5%
const MODAL_TOP_OFFSET = 0.125;

const Modal = ({
  showHeaderIcon,
  HeaderIcon,
  headerText,
  helpText,
  headerClasses,
  bodyClasses,
  bodyText,
  color,
  size,
  children,
  steps,
  hideBullets,
  selectedStep,
  primaryAction,
  primaryActionText,
  showPrimaryAction,
  showSecondaryAction,
  secondaryAction,
  secondaryActionText,
  show,
  setShow,
  canHandleClickOutside,
  innerContainerClasses,
  outerContainerClasses,
  showDivider,
  isPrimaryActionDisabled,
  disableBulletClick,
  shouldPrimaryActionClose,
  disableFlex
}) => {
  const [currentStep, setCurrentStep] = useState(selectedStep);
  const modalRef = useRef();
  const portal = useContext(PortalContext);
  const [isModalTallerThanWindow, setIsModalTallerThanWindow] = useState(false);

  const compareModalToWindow = _modalRef => {
    const modalHeight = _modalRef.current?.offsetHeight;
    const windowHeight = window.innerHeight;
    const windowPaddingOffset = MODAL_TOP_OFFSET * windowHeight;

    setIsModalTallerThanWindow(
      modalHeight > windowHeight - windowPaddingOffset
    );
  };

  const intializeIsModalTallerThanWindow = useCallback(() => {
    if (show) compareModalToWindow(modalRef);
  }, [show, modalRef]);

  const handleResize = useCallback(() => {
    compareModalToWindow(modalRef);
  }, [modalRef]);

  const debouncedHandleResize = debounce(() => handleResize(), 250);

  useEffect(() => {
    intializeIsModalTallerThanWindow();

    window.addEventListener('resize', debouncedHandleResize);

    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, [intializeIsModalTallerThanWindow, debouncedHandleResize]);

  function handleClose() {
    if (setShow) setShow(false);
    setCurrentStep(0);
  }

  function handlePrimaryActionClick() {
    if (!isEmpty(steps) && currentStep !== steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      primaryAction();
      if (shouldPrimaryActionClose) handleClose();
    }

    if (!isEmpty(steps)) {
      const step = steps[currentStep];
      if (step && step.action) step.action();
    }
  }
  const prmaryActionBtnDisable =
    isPrimaryActionDisabled ||
    (!isEmpty(steps) && steps[currentStep].isBtnDisable);

  function handleSecondaryActionClick() {
    if (secondaryAction) secondaryAction(false);
    handleClose();
  }

  useOnClickOutside(modalRef, () => {
    if (canHandleClickOutside) handleClose();
  });

  useEffect(() => {
    if (show) {
      document.body.classList.add('tw-modal-open');
    } else {
      document.body.classList.remove('tw-modal-open');
    }
  }, [show]);

  const getChildContent = () => {
    if (children) {
      return <div className="tw-w-full">{children}</div>;
    }
    if (!isEmpty(steps)) {
      return (
        <div className="tw-w-full">
          {steps.map((step, index) => {
            return index === currentStep ? (
              <div key={`${step.id}-${step.text}`} className="tw-mt-2">
                {step.content}
              </div>
            ) : null;
          })}
          {!hideBullets && (
            <div className="tw-mt-3">
              <BulletSteps
                steps={steps}
                color={color}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                disableClick={disableBulletClick}
              />
            </div>
          )}
        </div>
      );
    }
    return (
      <div className="tw-mt-2">
        <p className="tw-font-body tw-text-sm tw-leading-5 tw-text-gray-500">
          {bodyText}
        </p>
      </div>
    );
  };

  const getPrimaryActionText = () => {
    let text = primaryActionText;

    if (!isEmpty(steps)) {
      const step = steps[currentStep];
      const isLastStep = currentStep === steps.length - 1;

      if (step.buttonText) {
        text = step.buttonText;
      } else if (isLastStep) {
        text = 'Save';
      } else {
        text = 'Next Step';
      }
    }

    return text;
  };

  function getModalWidth() {
    switch (size) {
      case 'xs': {
        return 'tw-max-w-xs lg:tw-max-w-sm';
      }
      case 'sm': {
        return 'tw-max-w-sm lg:tw-max-w-md';
      }
      case 'md': {
        return 'tw-max-w-md lg:tw-max-w-lg';
      }
      case 'lg': {
        return 'tw-max-w-lg lg:tw-max-w-xl';
      }
      case 'xl': {
        return 'tw-max-w-xl lg:tw-max-w-2xl';
      }
      case '2xl': {
        return 'tw-max-w-2xl';
      }
      case 'full': {
        return 'tw-max-w-full';
      }
      default: {
        return 'tw-max-w-lg lg:tw-max-w-xl';
      }
    }
  }

  return (
    <Fragment>
      {portal?.modalNode && (
        <InPortal node={portal.modalNode}>
          <div
            className={`tw-inset-x-0 tw-px-4 tw-pb-4 tw-z-1000 ${
              show
                ? 'tw-fixed tw-bottom-0 xxs:tw-flex tw-modal-open tw-overflow-scroll'
                : 'tw-hidden'
            } xxs:tw-items-center xxs:tw-justify-center xxs:tw-inset-0`}
          >
            <Transition
              show={show}
              enter="tw-transition tw-ease-out tw-duration-300"
              enterFrom="tw-opacity-0"
              enterTo="tw-opacity-100"
              leave="tw-transition tw-ease-in tw-duration-200"
              leaveFrom="tw-opacity-100"
              leaveTo="tw-opacity-0"
            >
              <div className="tw-fixed tw-inset-0 tw-transition-opacity">
                <div className="tw-absolute tw-z-1500 tw-inset-0 tw-bg-gray-500 tw-opacity-75" />
              </div>
            </Transition>

            <Transition
              show={show}
              enter="tw-transform tw-transition tw-ease-out tw-duration-300"
              enterFrom="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
              enterTo="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
              leave="tw-transform tw-transition tw-ease-in tw-duration-200"
              leaveFrom="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
              leaveTo="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
            >
              <div
                ref={modalRef}
                className={`tw-z-2000 tw-bg-white tw-rounded-lg tw-max-h-max ${
                  isModalTallerThanWindow
                    ? 'tw-absolute tw-top-0 sm:tw-top-1/8'
                    : ''
                } ${outerContainerClasses} tw-overflow-visible tw-shadow-xl tw-transform tw-transition-all sm:tw-max-w-lg lg:tw-max-w-xl xs:tw-w-full ${getModalWidth()}`}
                role="dialog"
                aria-modal="true"
                aria-labelledby="modal-headline"
              >
                <div
                  className={`tw-bg-white tw-rounded-tl-lg tw-rounded-tr-lg ${innerContainerClasses}`}
                >
                  <div
                    className={
                      disableFlex ? '' : 'sm:tw-flex xxs:tw-items-start'
                    }
                  >
                    {showHeaderIcon ? (
                      <div
                        className={`${headerClasses} tw-mx-auto tw-flex-shrink-0 tw-flex tw-items-center tw-justify-center tw-h-12 tw-w-12 tw-rounded-full tw-bg-${color}-100 sm:tw-mr-3 sm:tw-h-10 sm:tw-w-10`}
                      >
                        {typeof HeaderIcon === 'function' ? (
                          HeaderIcon(color)
                        ) : (
                          <HeaderIcon color={color} />
                        )}
                      </div>
                    ) : null}
                    <div className="tw-mt-3 tw-flex-1 tw-text-center sm:tw-mt-0 sm:tw-ml-0 sm:tw-text-left">
                      <h3
                        className={`${
                          showHeaderIcon ? '' : headerClasses
                        } tw-font-body tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900`}
                        id="modal-headline"
                      >
                        {headerText}
                      </h3>
                      {helpText && (
                        <span className="tw-text-xs">{helpText}</span>
                      )}
                      {showDivider ? (
                        <div className="tw-border-t tw-border-b-0 tw-border-l-0 tw-border-r-0 tw-border-solid tw-border-gray-200 tw-my-4" />
                      ) : null}
                      <div className={`${bodyClasses} tw-max-h-max`}>
                        {getChildContent()}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tw-rounded-bl-lg tw-rounded-br-lg tw-bg-gray-50 tw-px-4 tw-py-3 sm:tw-px-6 sm:tw-flex sm:tw-flex-row-reverse">
                  {showPrimaryAction ? (
                    <span className="tw-flex tw-w-full tw-rounded-md tw-shadow-sm sm:tw-ml-3 sm:tw-w-auto">
                      <button
                        type="button"
                        style={{ opacity: prmaryActionBtnDisable ? 0.5 : 1 }}
                        className={`tw-font-body ${
                          prmaryActionBtnDisable
                            ? 'tw-cursor-not-allowed'
                            : `tw-cursor-pointer hover:tw-bg-${color}-500 focus:tw-outline-none focus:tw-border-${color}-700 focus:tw-shadow-outline-${color}`
                        } tw-inline-flex tw-justify-center tw-w-full tw-rounded-md tw-border tw-border-transparent tw-px-4 tw-py-2 tw-bg-${color}-600 tw-text-base tw-leading-6 tw-font-medium tw-text-white tw-shadow-sm tw-transition tw-ease-in-out tw-duration-150 sm:tw-text-sm sm:tw-leading-5`}
                        onClick={handlePrimaryActionClick}
                        disabled={prmaryActionBtnDisable}
                      >
                        {getPrimaryActionText()}
                      </button>
                    </span>
                  ) : null}
                  {showSecondaryAction ? (
                    <span className="tw-mt-3 tw-flex tw-w-full tw-rounded-md tw-shadow-sm sm:tw-mt-0 sm:tw-w-auto">
                      <button
                        type="button"
                        className="tw-font-body tw-cursor-pointer tw-inline-flex tw-justify-center tw-w-full tw-rounded-md tw-border tw-border-solid tw-border-gray-300 tw-px-4 tw-py-2 tw-bg-white tw-text-base tw-leading-6 tw-font-medium tw-text-gray-700 tw-shadow-sm hover:tw-text-gray-500 focus:tw-outline-none focus:tw-border-blue-300 focus:tw-shadow-outline-blue tw-transition tw-ease-in-out tw-duration-150 sm:tw-text-sm sm:tw-leading-5"
                        onClick={handleSecondaryActionClick}
                      >
                        {secondaryActionText}
                      </button>
                    </span>
                  ) : null}
                </div>
              </div>
            </Transition>
          </div>
        </InPortal>
      )}
    </Fragment>
  );
};

Modal.defaultProps = {
  showHeaderIcon: true,
  HeaderIcon: () => null,
  helpText: '',
  color: 'alpha',
  size: 'lg',
  children: '',
  headerClasses: '',
  bodyClasses: '',
  innerContainerClasses: 'tw-px-4 tw-pt-5 tw-pb-4 sm:tw-p-6 sm:tw-pb-4',
  outerContainerClasses: '',
  showDivider: false,
  bodyText: '',
  steps: [],
  hideBullets: false,
  selectedStep: 0,
  primaryAction: () => null,
  primaryActionText: 'Save',
  showPrimaryAction: true,
  showSecondaryAction: false,
  secondaryAction: () => null,
  secondaryActionText: 'Cancel',
  show: false,
  setShow: () => null,
  canHandleClickOutside: true,
  isPrimaryActionDisabled: false,
  disableBulletClick: false,
  shouldPrimaryActionClose: true,
  disableFlex: false
};

const nodeType = PropTypes.oneOfType([PropTypes.func, PropTypes.node]);

Modal.propTypes = {
  showHeaderIcon: PropTypes.bool,
  HeaderIcon: nodeType,
  headerText: PropTypes.string.isRequired,
  helpText: PropTypes.string,
  headerClasses: PropTypes.string,
  bodyClasses: PropTypes.string,
  bodyText: PropTypes.string,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      text: PropTypes.string,
      buttonText: PropTypes.string,
      content: nodeType,
      action: PropTypes.function,
      isBtnDisable: PropTypes.bool
    })
  ),
  hideBullets: PropTypes.bool,
  selectedStep: PropTypes.number,
  children: nodeType,
  primaryAction: PropTypes.func,
  primaryActionText: PropTypes.string,
  showPrimaryAction: PropTypes.bool,
  color: PropTypes.oneOf([
    'alpha',
    'bravo',
    'charlie',
    'success',
    'warning',
    'error',
    'gray'
  ]),
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', '2xl', 'full']),
  showSecondaryAction: PropTypes.bool,
  secondaryAction: PropTypes.func,
  secondaryActionText: PropTypes.string,
  show: PropTypes.bool,
  setShow: PropTypes.func,
  canHandleClickOutside: PropTypes.bool,
  innerContainerClasses: PropTypes.string,
  outerContainerClasses: PropTypes.string,
  showDivider: PropTypes.bool,
  isPrimaryActionDisabled: PropTypes.bool,
  disableBulletClick: PropTypes.bool,
  shouldPrimaryActionClose: PropTypes.bool,
  disableFlex: PropTypes.bool
};

export default Modal;
