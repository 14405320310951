import React, { useContext, useState, useRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import isEmpty from 'lodash.isempty';
import {
  deleteContact,
  unsubscribeContact,
  resubscribeContact
} from 'appState/actions/ActionCreators';
import Card from 'components/Theme/Card';
import Modal from 'components/Theme/Modal';
import ShowMore from 'components/Theme/ShowMore';
import HeroIcon from 'components/Theme/HeroIcon';
import { CurrentAccountContext } from 'components/shared/context/CurrentAccountContext';
import useOnClickOutside from 'components/shared/hooks/useOnClickOutside';
import BroadcastIcon from 'components/Theme/Icon/BroadcastIcon';
import SmsUnsubscribedTooltip from 'components/Contact/Profile/SmsUnsubscribedTooltip';
import SmsSubscribedTooltip from 'components/Contact/Profile/SmsSubscribedTooltip';
import EmailSpamTooltip from 'components/Contact/Profile/EmailSpamTooltip';
import EmailUnsubscribedTooltip from 'components/Contact/Profile/EmailUnsubscribedTooltip';
import EmailSubscribedTooltip from 'components/Contact/Profile/EmailSubscribedTooltip';
import PhoneInvalidTooltip from 'components/Contact/Profile/PhoneInvalidTooltip';

const ContactInfoCard = ({
  contact,
  updateContact,
  handleSetUpdate,
  handleSetResetReferrer,
  leadSourceOptions
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUnsubscribeModal, setShowUnsubscribeModal] = useState(false);
  const [showResubscribeModal, setShowResubscribeModal] = useState(false);
  const [coords, setCoords] = useState({});
  const dropdownRef = useRef();
  const dropdownButtonRef = useRef();
  const dispatch = useDispatch();
  const currentAccount = useContext(CurrentAccountContext);

  function toggleDropdown() {
    const dropdownSpanRect = dropdownButtonRef.current?.getBoundingClientRect();

    setCoords({
      left: dropdownSpanRect.x - 200,
      top: dropdownSpanRect.y + dropdownSpanRect.height + window.scrollY
    });
    setShowDropdown(!showDropdown);
  }

  useOnClickOutside(dropdownRef, () => {
    setShowDropdown(false);
  });

  function editContact() {
    handleSetUpdate(!updateContact);
    handleSetResetReferrer(false);
    toggleDropdown();
  }

  function handleUnsubscribeResubscribeClick() {
    if (contact.subscribed) setShowUnsubscribeModal(true);
    else setShowResubscribeModal(true);
  }

  function handleDeleteContact() {
    dispatch(deleteContact({ contactId: contact.id }));
  }

  function handleUnsubscribeClick() {
    dispatch(unsubscribeContact({ contactId: contact.id }));
  }

  function handleResubscribeClick() {
    dispatch(resubscribeContact({ contactId: contact.id }));
  }

  function phoneNumberTooltip() {
    if (!contact.formattedPhone) return null;
    if (!contact.canBeSentSms) {
      if (!contact.subscribedToSms) {
        return <SmsUnsubscribedTooltip contact={contact} />;
      }
      return <PhoneInvalidTooltip />;
    }
    return <SmsSubscribedTooltip />;
  }

  function EmailTooltip() {
    if (contact.spamComplaint) {
      return <EmailSpamTooltip />;
    }
    if (!contact.subscribed) {
      return <EmailUnsubscribedTooltip contact={contact} />;
    }
    return <EmailSubscribedTooltip />;
  }

  function getLeadSource(source) {
    const option = leadSourceOptions.find(opt => opt.value === source);
    return option ? option.displayName : source;
  }

  const contactOptions = [
    {
      value: 'edit',
      displayName: 'Edit Contact',
      onClick: editContact
    },
    {
      value: 'destroy',
      displayName: 'Delete Contact',
      onClick: setShowDeleteModal,
      className: 'tw-text-red-500 hover:tw-text-red-900 hover:tw-bg-red-100'
    }
  ];

  const optionalElement = {
    value: 'subscribe-unsubscribe',
    displayName: contact.subscribed ? 'Unsubscribe' : 'Resubscribe',
    onClick: handleUnsubscribeResubscribeClick
  };

  if (!contact?.under14) contactOptions.splice(1, 0, optionalElement);

  return (
    <Fragment>
      <Card
        title="Contact Information"
        containerClass="tw-rounded-lg tw-bg-white tw-mb-4 tw-mt-2"
        showHeaderAction
        headerAction={() => {
          if (contact) toggleDropdown();
        }}
        headerActionIcon={() => (
          <span ref={dropdownButtonRef}>
            <HeroIcon icon="dots-vertical" color="alpha" />
          </span>
        )}
      >
        <div
          ref={dropdownRef}
          className={`tw-origin-top-right tw-absolute tw-mt-2 tw--mr-1 tw-w-56 tw-rounded-md tw-shadow-lg ${
            showDropdown ? 'tw-block' : 'tw-hidden'
          }`}
          style={{ top: coords.top, left: coords.left }}
        >
          <div className="tw-rounded-md tw-bg-white tw-shadow-xs">
            <div className="tw-py-1">
              {contactOptions.map(option => (
                <a
                  href="#"
                  key={option.value}
                  data-testid={option.value}
                  onClick={option.onClick}
                  className={`tw-block tw-px-4 tw-py-2 tw-text-sm tw-leading-5 tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900 focus:tw-outline-none focus:tw-bg-gray-100 focus:tw-text-gray-900 ${option.className}`}
                >
                  {option.displayName}
                </a>
              ))}
            </div>
          </div>
        </div>

        <div className="tw-p-6 tw-text-gray-900">
          {contact && contact.assignedStaff ? (
            <div className="tw-flex">
              <div className="tw-flex-shrink-0 tw-mr-2">
                <HeroIcon icon="user" color="gray" />
              </div>
              <div className="tw-flex-1 tw-text-sm tw-leading-5 ">
                {`Assigned Staff: ${contact.assignedStaff}`}
              </div>
            </div>
          ) : (
            <div className="tw-block">
              <Skeleton height={14} width={80} />
            </div>
          )}
          {contact && contact.email ? (
            <div className="tw-flex tw-justify-between">
              <div className="tw-flex-1 tw-flex">
                <div className="tw-flex-shrink-0 tw-mr-2">
                  <HeroIcon icon="mail" color="gray" />
                </div>
                <div className="tw-flex-1 tw-text-sm tw-leading-5 ">
                  {contact.email}
                </div>
                <div className="tw-inline-block">{EmailTooltip()}</div>
              </div>
            </div>
          ) : (
            <div className="tw-block">
              <Skeleton height={14} width={130} />
            </div>
          )}
          {!isEmpty(contact) ? (
            <div className="tw-flex tw-justify-between">
              <div className="tw-flex tw-items-center">
                <div className="tw-flex-shrink-0 tw-flex tw-item-center tw-mr-2">
                  <HeroIcon icon="phone" color="gray" />
                </div>
                <div className="tw-flex-1 tw-text-sm tw-leading-5 tw-flex tw-items-center">
                  {contact.formattedPhone
                    ? contact.formattedPhone
                    : 'No Phone Number'}
                </div>
              </div>
              <div className="tw-inline-block">{phoneNumberTooltip()}</div>
            </div>
          ) : (
            <Skeleton height={14} width={180} />
          )}
          <ShowMore>
            <div>
              <div className="tw-flex tw-pt-1">
                <div className="tw-flex-shrink-0 tw-mr-2">
                  <HeroIcon icon="location-marker" color="gray" />
                </div>
                <div className="tw-flex-1 tw-text-sm tw-leading-5 ">
                  {contact.fullAddress
                    ? contact.fullAddress
                    : 'No Address Stored'}
                </div>
              </div>
              {!currentAccount.mod_campaigns_enabled && (
                <div className="tw-flex">
                  <div className="tw-flex-shrink-0 tw-mr-2">
                    <BroadcastIcon height={5} width={5} color="gray" />
                  </div>
                  <div className="tw-flex-1 tw-text-sm tw-leading-5 ">
                    {`Journey: ${contact ? contact.clientJourney : 'None'}`}
                  </div>
                </div>
              )}
              <div className="tw-flex">
                <div className="tw-flex-shrink-0 tw-mr-2">
                  <BroadcastIcon height={5} width={5} color="gray" />
                </div>
                <div className="tw-flex-1 tw-text-sm tw-leading-5 ">
                  {`Initial Campaign: ${
                    contact.initialCampaign ? contact.initialCampaign : 'None'
                  }`}
                </div>
              </div>
              <div className="tw-flex">
                <div className="tw-flex-shrink-0 tw-mr-2">
                  <HeroIcon icon="cake" color="gray" />
                </div>
                <div className="tw-flex-1 tw-text-sm tw-leading-5 ">
                  {contact.birthday
                    ? `Birthday: ${moment(contact.birthday).format('ll')}`
                    : 'No Birthday Stored'}
                </div>
              </div>
              <div className="tw-flex">
                <div className="tw-flex-shrink-0 tw-mr-2">
                  <HeroIcon icon="identification" color="gray" />
                </div>
                <div className="tw-flex-1 tw-text-sm tw-leading-5 ">
                  {contact.gender
                    ? `Gender: ${
                        contact.gender === 'Other'
                          ? 'Not Specified'
                          : contact.gender
                      }`
                    : 'No Gender Stored'}
                </div>
              </div>
              <div className="tw-flex">
                <div className="tw-flex-shrink-0 tw-mr-2">
                  <HeroIcon icon="user-circle" color="gray" />
                </div>
                <div className="tw-flex-1 tw-text-sm tw-leading-5 ">
                  {contact.tshirtSize
                    ? `T-Shirt size: ${contact.tshirtSize}`
                    : 'No T-Shirt Size Stored'}
                </div>
              </div>
              <div className="tw-flex">
                <div className="tw-flex-shrink-0 tw-mr-2">
                  <HeroIcon icon="user-group" color="gray" />
                </div>
                <div className="tw-flex-1 tw-text-sm tw-leading-5 ">
                  {contact.referrerFullName
                    ? `Referred by: ${contact.referrerFullName}`
                    : 'No Referrer Stored'}
                </div>
              </div>
              <div className="tw-flex">
                <div className="tw-flex-shrink-0 tw-mr-2">
                  <HeroIcon icon="star" color="gray" />
                </div>
                <div className="tw-flex-1 tw-text-sm tw-leading-5 ">
                  {contact.membershipStartedAt
                    ? `Joined: ${new Date(
                        contact.membershipStartedAt
                      ).toDateString()}`
                    : 'No Membership Start Date Stored'}
                </div>
              </div>
              {currentAccount.mod_campaigns_enabled &&
                currentAccount.lead_source_enabled &&
                contact.leadSource && (
                  <div className="tw-flex">
                    <div className="tw-flex-shrink-0 tw-mr-2">
                      <HeroIcon icon="funnel" color="gray" />
                    </div>
                    <div className="tw-flex-1 tw-text-sm tw-leading-5 ">
                      {`Lead Source: ${getLeadSource(contact.leadSource)}`}
                    </div>
                  </div>
                )}
            </div>
          </ShowMore>
        </div>
      </Card>
      <Modal
        color="error"
        headerText="Delete Contact?"
        bodyText={
          contact.gdprCompliant
            ? 'This action will permanently delete all of the contact’s information to comply with GDPR guidance. Would you like to continue?'
            : 'Are you sure you want to delete this contact?'
        }
        primaryAction={handleDeleteContact}
        showHeaderIcon={false}
        primaryActionText="Yes. Delete"
        secondaryActionText="Cancel"
        setShow={setShowDeleteModal}
        show={showDeleteModal}
        showSecondaryAction
        canHandleClickOutside={false}
      />
      <Modal
        color="error"
        headerText="Unsubscribe Contact?"
        primaryAction={handleUnsubscribeClick}
        showHeaderIcon={false}
        primaryActionText="Unsubscribe"
        secondaryActionText="Cancel"
        setShow={setShowUnsubscribeModal}
        show={showUnsubscribeModal}
        showSecondaryAction
        canHandleClickOutside={false}
      >
        <p>
          <strong>Are you sure you want to unsubscribe this contact?</strong>
        </p>
        <p>
          Unsubscribing a contact will prevent them from receiving any campaign
          emails or manual emails from your account. It will not prevent them
          from receiving SMS messages. If they no longer wish to receive SMS
          messages, they should reply “STOP” to an SMS from your account.
        </p>
      </Modal>
      <Modal
        color="alpha"
        headerText="Re-subscribe Contact?"
        showPrimaryAction={!contact.spamComplaint}
        primaryAction={handleResubscribeClick}
        showHeaderIcon={false}
        primaryActionText="Resubscribe"
        secondaryActionText="Cancel"
        setShow={setShowResubscribeModal}
        show={showResubscribeModal}
        showSecondaryAction
        canHandleClickOutside={false}
      >
        {contact.spamComplaint ? (
          <Fragment>
            <p>
              <strong>Unable to resubscribe contact.</strong>
            </p>
            <p>
              This contact has reported communication from you as spam. This
              hurts account reputation and email deliverability.
            </p>
          </Fragment>
        ) : (
          <Fragment>
            <p>
              <strong>
                Please read the following message carefully before
                re-subscribing this contact.
              </strong>
            </p>
            <p>
              Re-subscribing a contact is a decision that must be considered
              carefully. If a contact has unsubscribed from your mailings, you
              are bound (ethically and legally) to stop sending them messages.
            </p>
            <p>
              If the contact has <strong>requested to opt back in,</strong> go
              ahead and re-subscribe them. However, if you&apos;re simply
              re-subscribing them to &ldquo;try again&rdquo;, it is a violation
              of the CAN-SPAM Act of 1993 as well as a violation of UpLaunch
              terms of service, and can result in the immediate termination of
              your UpLaunch account and all associated product subscriptions.
            </p>
          </Fragment>
        )}
      </Modal>
    </Fragment>
  );
};

const contactShape = {
  id: PropTypes.number,
  assignedStaff: PropTypes.string,
  email: PropTypes.string,
  subscribed: PropTypes.bool,
  formattedPhone: PropTypes.string,
  subscribedToSms: PropTypes.bool,
  fullAddress: PropTypes.string,
  birthday: PropTypes.string,
  gender: PropTypes.string,
  tshirtSize: PropTypes.string,
  referrerFullName: PropTypes.string,
  membershipStartedAt: PropTypes.string,
  initialCampaign: PropTypes.string,
  spamComplaint: PropTypes.bool
};

ContactInfoCard.defaultProps = {
  updateContact: false,
  handleSetUpdate: () => {},
  handleSetResetReferrer: () => {},
  leadSourceOptions: []
};

const leadSourceShape = {
  id: PropTypes.number,
  name: PropTypes.string,
  key: PropTypes.string
};

ContactInfoCard.propTypes = {
  contact: PropTypes.shape(contactShape).isRequired,
  updateContact: PropTypes.bool,
  handleSetUpdate: PropTypes.func,
  handleSetResetReferrer: PropTypes.func,
  leadSourceOptions: PropTypes.arrayOf(leadSourceShape)
};

export default ContactInfoCard;
