import React, { useContext, useState, Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { createErrorMessageSelector } from 'appState/selectors';
import { fetchAvailablePhoneNumbers } from 'appState/actions/ActionCreators';
import UtilityCard from 'components/OverlordUtilities/shared/UtilityCard';
import { CurrentAccountContext } from 'components/shared/context/CurrentAccountContext';
import useProcessingActivity from 'components/shared/hooks/useProcessingActivity';
import useAccountDisabled from 'components/shared/hooks/useAccountDisabled';
import AvailablePhoneNumberList from './AvailablePhoneNumberList';

const UTILITY_NAMES = ['phone_number_changer'];

const PhoneNumberChanger = () => {
  const currentAccount = useContext(CurrentAccountContext);
  const [areaCode, setAreaCode] = useState();
  const [showTenDlcWarning, setShowTenDlcWarning] = useState(false);
  const [submitActionFired, setSubmitActionFired] = useState(false);
  const processingActivity = useProcessingActivity(UTILITY_NAMES);
  const accountDisabled = useAccountDisabled();

  const errorSelector = createErrorMessageSelector([
    'overlord/FETCH_AVAILABLE_PHONE_NUMBERS',
    'overlord/PURCHASE_NUMBER_FOR_ACCOUNT'
  ]);
  const structuredSelector = createStructuredSelector({
    availableNumbers: state => state.overlordUtilities.availableNumbers,
    error: state => errorSelector(state)
  });

  const { availableNumbers, error } = useSelector(structuredSelector);
  const dispatch = useDispatch();

  const getAvailablePhoneNumbers = () => {
    setSubmitActionFired(true);
    dispatch(
      fetchAvailablePhoneNumbers({
        areaCode
      })
    );
  };

  useEffect(() => {
    setShowTenDlcWarning(
      currentAccount?.country === 'United States' &&
        !currentAccount?.ten_dlc_registration_initiated
    );
  }, [currentAccount]);

  const getCardBody = () => {
    return (
      <Fragment>
        {showTenDlcWarning ? (
          <div>
            <div className="alert alert-secondary">
              <strong>Important: </strong>Please fill out the required A2P 10DLC
              information on the branding page.{' '}
              <a href="/account/branding" className="text-info">
                Update 10DLC Information.
              </a>
              . This information is required to spin up a phone number for a US
              based account.
            </div>
          </div>
        ) : (
          <div>
            <div className="row">
              <div className="col-lg-12">
                {['united states', 'canada'].includes(
                  currentAccount.country.toLowerCase()
                ) ? (
                  <div>
                    <p className="mb-10 text-disabled">
                      Pass in area code to look for, or leave blank to use admin
                      phone area code
                    </p>
                    <label htmlFor="areaCode">Area Code</label>
                    <input
                      id="areaCode"
                      className="form-control"
                      type="text"
                      onKeyUp={e => setAreaCode(e.target.value)}
                    />
                  </div>
                ) : (
                  <p className="mb-0">
                    Click the &ldquo;Find Available Numbers&rdquo; button to get
                    started.
                  </p>
                )}
              </div>
            </div>
            <hr />

            {submitActionFired && (
              <div className="row">
                <div className="col-lg-12" id="available-number-list-wrapper">
                  <AvailablePhoneNumberList
                    availableNumbers={availableNumbers}
                    currentAccount={currentAccount}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </Fragment>
    );
  };

  return (
    <UtilityCard
      title="Phone Number Changer"
      subtitle="Use this tool to change an accounts phone number for SMS."
      submitBtnText="Find Available Numbers"
      submitBtnAction={getAvailablePhoneNumbers}
      bodyComponent={getCardBody}
      processingActivity={processingActivity}
      accountDisabled={accountDisabled}
      error={error}
    />
  );
};

export default PhoneNumberChanger;
