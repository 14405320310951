import React from 'react';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Styled from './Header.styles';

const Header = () => {
  const structuredSelector = createStructuredSelector({
    step: state => state.broadcastCenter.step
  });

  const { step } = useSelector(structuredSelector);

  let wizardNavProcessClasses = {};

  function setWizardNavProcessClasses() {
    if (step === 'select-template') {
      wizardNavProcessClasses = {
        selectTemplate: 'processing',
        selectAudience: '',
        editContent: '',
        schedule: ''
      };
    } else if (step === 'select-audience') {
      wizardNavProcessClasses = {
        selectTemplate: 'complete',
        selectAudience: 'processing',
        editContent: '',
        schedule: ''
      };
    } else if (step === 'email' || step === 'sms') {
      wizardNavProcessClasses = {
        selectTemplate: 'complete',
        selectAudience: 'complete',
        editContent: 'processing',
        schedule: ''
      };
    } else if (step === 'schedule') {
      wizardNavProcessClasses = {
        selectTemplate: 'complete',
        selectAudience: 'complete',
        editContent: 'complete',
        schedule: 'processing'
      };
    }
  }

  setWizardNavProcessClasses();

  return (
    <Styled.Header className="header">
      <div className="header-info mt-10 mb-10">
        <div className="left mt-15">
          <h2 className="header-title ">Broadcast Wizard</h2>
        </div>
        <ul className="w-400px mb-0 nav nav-process nav-process-circle nav-process-info">
          <li
            id="select_template"
            className={`nav-item ${wizardNavProcessClasses.selectTemplate}`}
          >
            <span className="nav-title">Template</span>
            <a className="nav-link" href="#" />
          </li>
          <li
            id="select_audience"
            className={`nav-item ${wizardNavProcessClasses.selectAudience}`}
          >
            <span className="nav-title">Audience</span>
            <a className="nav-link" href="#" />
          </li>
          <li
            id="edit_content"
            className={`nav-item ${wizardNavProcessClasses.editContent}`}
          >
            <span className="nav-title">Message</span>
            <a className="nav-link" href="#" />
          </li>
          <li
            id="send_schedule"
            className={`nav-item ${wizardNavProcessClasses.schedule}`}
          >
            <span className="nav-title">Send</span>
            <a className="nav-link" href="#" />
          </li>
        </ul>
      </div>
    </Styled.Header>
  );
};

export default Header;
