import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';
import { formatTimestamp } from 'lib/utils/dateTime';
import loadingGif from 'images/broadcast/loading.gif';
import { CurrentUserContext } from 'components/shared/context/CurrentUserContext';

function getNormalizedSmsType(smsType) {
  const transactionalTypes = [
    'client_transactional',
    'transactional',
    'uplaunch_action'
  ];
  const outboundTypes = [
    'campaign',
    'customized_campaign',
    'outbound',
    'staff'
  ];
  const bulkTypes = ['bulk', 'broadcast'];

  if (transactionalTypes.indexOf(smsType) !== -1) return 'transactional';
  if (outboundTypes.indexOf(smsType) !== -1) return 'outbound';
  if (bulkTypes.indexOf(smsType) !== -1) return 'broadcast';
  return smsType;
}

const SmsMessage = props => {
  const { sms } = props;
  const smsType = getNormalizedSmsType(sms.sms_type);
  const currentUser = useContext(CurrentUserContext);
  const userTimeZone = currentUser.account_time_zone;

  function getSmsBodyClass() {
    if (smsType === 'inbound') return 'd-inline-block';
    if (smsType === 'outbound') return 'bg-info';
    if (smsType === 'transactional') return 'bg-pale-dark';
    if (smsType === 'broadcast') return 'bg-dark';
    return '';
  }

  return (
    <Fragment>
      {(sms.body || !isEmpty(sms.media_urls)) && (
        <div
          className={`media media-chat pl-4 ${
            smsType === 'inbound' ? '' : 'media-chat-reverse'
          }`}
        >
          <div
            className={`media-body ${
              smsType === 'inbound' ? '' : 'full-width'
            }`}
          >
            {sms.body && (
              <Fragment>
                <p className={`${getSmsBodyClass()}`}>
                  {['broadcast', 'transactional'].includes(smsType) ? (
                    <span className="mr-10">
                      <small>
                        <strong
                          className={
                            smsType === 'transactional' ? 'text-white' : ''
                          }
                        >
                          {smsType && smsType.toUpperCase()} MESSAGE
                        </strong>
                      </small>
                    </span>
                  ) : null}
                  {sms.body}
                </p>
              </Fragment>
            )}
            {!isEmpty(sms.media_urls) && (
              <p
                className="row gap-1 big-rounded d-inline-block pt-10"
                data-provide="photoswipe"
              >
                {sms.media_urls.map(media => {
                  return (
                    <Fragment key={media.url}>
                      {media.content_type.includes('image') && (
                        <a
                          className={`col-${
                            sms.media_urls.count > 3
                              ? '4'
                              : 12 / sms.media_urls.count
                          } ${
                            sms.media_urls.count >= 2 ? '' : 'd-inline-block'
                          }`}
                          href="#"
                        >
                          <img
                            src={media.url}
                            className="preloaded-picture mms-image"
                            style={{
                              maxHeight: '400px',
                              maxWidth: '400px',
                              paddingBottom: '6px'
                            }}
                            onError={() => {
                              // eslint-disable-next-line react/no-this-in-sfc
                              this.src = loadingGif;
                              return null;
                            }}
                            alt="MMS Attachment"
                          />
                        </a>
                      )}
                      {media.content_type.includes('video') && (
                        // eslint-disable-next-line jsx-a11y/media-has-caption
                        <video controls width="320" height="240">
                          <source src={media.url} type="video/mp4" />
                        </video>
                      )}
                    </Fragment>
                  );
                })}
              </p>
            )}
            <p className="meta">
              <time dateTime="2017">
                {sms.read_at === null && smsType === 'inbound' ? (
                  <span className="badge badge-info mr-10">Unread</span>
                ) : null}
                <small>
                  {smsType === 'inbound' ? 'Received' : 'Sent'}{' '}
                  {formatTimestamp(sms.created_at, userTimeZone)}{' '}
                  {smsType === 'inbound' ? 'From' : 'By'}{' '}
                  {smsType === 'inbound'
                    ? `${sms.contact.first_name} ${sms.contact.last_name}`
                    : `${sms.user.first_name} ${sms.user.last_name}`}
                </small>{' '}
                {sms.status ? ' - ' : null}
                {sms.status}
              </time>
            </p>
          </div>
        </div>
      )}
    </Fragment>
  );
};

const smsShape = {
  body: PropTypes.string,
  sms_type: PropTypes.string,
  media_urls: PropTypes.arrayOf(PropTypes.object),
  contact: PropTypes.object,
  user: PropTypes.object,
  created_at: PropTypes.string
};

SmsMessage.propTypes = {
  sms: PropTypes.shape(smsShape).isRequired
};

export default SmsMessage;
