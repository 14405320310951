import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/Theme/Modal';
import EmailPreview from 'components/Theme/ActivityFeed/EmailPreview';

let handleCloseTimeout;

const EmailPreviewModal = ({ activeItem, show, setShow }) => {
  function handleClose() {
    setShow(false);
  }

  useEffect(() => {
    setShow(show);
  }, [show, setShow]);

  useEffect(() => {
    return () => {
      clearTimeout(handleCloseTimeout);
    };
  }, []);

  return (
    <Modal
      color="alpha"
      size="2xl"
      headerText="Preview"
      showHeaderIcon={false}
      setShow={setShow}
      show={show}
      showPrimaryAction={false}
      secondaryAction={handleClose}
      secondaryActionText="Close"
      showSecondaryAction
      headerClasses="tw-px-4"
      innerContainerClasses="tw-px-0 tw-pt-5 tw-pb-4"
      showDivider
    >
      <EmailPreview
        emailSubject={activeItem.email_subject}
        emailBody={activeItem.email_body}
      />
    </Modal>
  );
};

EmailPreviewModal.defaultProps = {
  show: false,
  setShow: () => null
};

const itemShape = PropTypes.shape({
  email_subject: PropTypes.string,
  email_body: PropTypes.string
});

EmailPreviewModal.propTypes = {
  activeItem: itemShape.isRequired,
  show: PropTypes.bool,
  setShow: PropTypes.func
};

export default EmailPreviewModal;
