import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Theme/Button';
import MyPlan from './MyPlan';
import MyProduct from './MyProduct';

const MyPlanAndProduct = ({ myProducts, mySubscription }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  function toggleDropdown() {
    setShowDropdown(!showDropdown);
  }
  const getNonLeadProductCount = () => {
    return mySubscription?.products?.filter(
      f => f.lifecycles && f.lifecycles.join('') !== ['lead'].join('')
    ).length;
  };

  return (
    <div className="tw-relative tw-inline-flex tw-ml-12 tw-block">
      <Button
        text="My Plans & Products"
        type="white"
        layout="left-button"
        isButtonWithDropdown
      />
      <div className="tw--ml-px tw-relative tw-block">
        <Button
          text=""
          type="white"
          layout="right-button"
          onClick={toggleDropdown}
          showSvg
          isButtonWithDropdown
        />
        <div
          className={`tw-origin-top-right tw-absolute tw-right-0 tw-mt-2 tw--mr-1 tw-w-72 tw-rounded-md tw-shadow-lg ${
            showDropdown ? 'tw-block' : 'tw-hidden'
          }`}
          style={{ zIndex: 100 }}
        >
          <div className="tw-rounded-md tw-bg-white tw-shadow-xs">
            <div className="tw-py-1">
              <MyPlan
                myPlan={mySubscription.plan}
                noOfProducts={mySubscription.products_purchased_count}
                noOfNonLeadProducts={getNonLeadProductCount()}
              />
              <MyProduct products={myProducts} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

MyPlanAndProduct.defaultProps = {
  myProducts: [],
  mySubscription: {}
};

MyPlanAndProduct.propTypes = {
  myProducts: PropTypes.arrayOf(PropTypes.object),
  // eslint-disable-next-line react/forbid-prop-types
  mySubscription: PropTypes.object
};

export default MyPlanAndProduct;
