/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

const Label = ({ text, labelClasses }) => {
  return (
    <label
      className={`tw-font-body tw-block tw-text-sm tw-font-medium tw-leading-5 ${labelClasses}`}
    >
      {text}
    </label>
  );
};

const RadioInput = ({ id, error, labelText, showError, ...props }) => {
  return (
    <div className="tw-block">
      <div className="tw-flex">
        <span className="tw-flex-initial tw-mt-0.5 tw-mr-3">
          <input id={id} data-testid={id} type="radio" {...props} />
        </span>
        <span className="tw-flex-1">
          <label className="tw-cursor-pointer tw-text-gray-500" htmlFor={id}>
            {labelText}
          </label>
        </span>
      </div>
      {showError ? (
        <p className="tw-font-body tw-mb-2 tw-text-sm tw-text-red-600">
          {error}
        </p>
      ) : null}
    </div>
  );
};

const CheckInput = ({ id, error, labelText, showError, ...props }) => {
  return (
    <div className="tw-block">
      <div className="tw-flex">
        <span className={`tw-flex-initial tw-mt-0.5 tw-mr-3 `}>
          <input type="checkbox" id={id} {...props} />
        </span>
        <span className="tw-flex-1">
          <label
            className={`tw-cursor-pointer tw-text-${
              showError ? 'red' : 'gray'
            }-500 tw-text-sm`}
            htmlFor={id}
          >
            {labelText}
          </label>
        </span>
      </div>
      {showError ? (
        <p className="tw-font-body tw-mb-2 tw-text-sm tw-text-red-600">
          {error}
        </p>
      ) : null}
    </div>
  );
};

Label.defaultProps = {
  labelClasses: ''
};

Label.propTypes = {
  text: PropTypes.string.isRequired,
  labelClasses: PropTypes.string
};

const checkRadioDefault = {
  checked: false,
  onChange: () => {},
  onBlur: () => {},
  disabled: false,
  showError: false,
  error: ''
};

const checkRadioProps = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  labelText: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  showError: PropTypes.bool,
  error: PropTypes.string
};

RadioInput.defaultProps = checkRadioDefault;

RadioInput.propTypes = checkRadioProps;

CheckInput.defaultProps = checkRadioDefault;

CheckInput.propTypes = checkRadioProps;

export { Label, RadioInput, CheckInput };
