/* eslint-disable no-param-reassign */
import produce from 'immer';
import { FETCH_OPT_IN_FORM_SUCCESS } from 'appState/actions/constants/optinform.actions';

const initialState = {
  accountFullAddress: '',
  accountLogo: '',
  accountName: '',
  googleApiPublicKey: '',
  landingPageButtonText: '',
  latitude: 0,
  longitude: 0,
  newLeadLandingPageTitle: '',
  privacyPolicyUrl: '',
  recaptchaKey: '',
  repactchaEnabled: false,
  showGdprCompliantCheckboxes: true
};

export default function OptInFormReducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case FETCH_OPT_IN_FORM_SUCCESS:
        {
          const {
            accountFullAddress,
            accountLogo,
            accountName,
            googleApiPublicKey,
            landingPageButtonText,
            latitude,
            longitude,
            newLeadLandingPageTitle,
            privacyPolicyUrl,
            recaptchaKey,
            repactchaEnabled,
            showGdprCompliantCheckboxes
          } = action.payload;
          draft.accountFullAddress = accountFullAddress;
          draft.accountLogo = accountLogo;
          draft.accountName = accountName;
          draft.googleApiPublicKey = googleApiPublicKey;
          draft.landingPageButtonText = landingPageButtonText;
          draft.latitude = latitude;
          draft.longitude = longitude;
          draft.newLeadLandingPageTitle = newLeadLandingPageTitle;
          draft.privacyPolicyUrl = privacyPolicyUrl;
          draft.recaptchaKey = recaptchaKey;
          draft.repactchaEnabled = repactchaEnabled;
          draft.showGdprCompliantCheckboxes = showGdprCompliantCheckboxes;
        }
        return;
      default:
        return draft;
    }
  });
}
