import React, { Fragment, useReducer, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { createStructuredSelector } from 'reselect';
import {
  fetchLeadSources,
  createLeadSource,
  flashErrorMessage,
  flashSuccessMessage
} from 'appState/actions/ActionCreators';
import {
  createLoadedSelector,
  createErrorMessageSelector
} from 'appState/selectors';
import {
  FETCH_LEAD_SOURCES,
  CREATE_LEAD_SOURCE
} from 'appState/actions/constants/leadSources.actions';
import { leadSourceSchema } from 'lib/validation/schema';
import PageHeader from 'components/Theme/PageHeader';
import Card from 'components/Theme/Card';
import Modal from 'components/Theme/Modal';
import TextInput from 'components/Theme/TextInput';
import isEmpty from 'lodash.isempty';
import Table from 'components/Theme/Table';

const CREATE = 'Create';
const UPDATE = 'Update';
const HIDE = 'hide';

const modalInitialState = {
  show: false,
  headerText: '',
  actionText: '',
  name: '',
  key: ''
};

const modalReducer = (state, action) => {
  switch (action.type) {
    case CREATE: {
      return {
        ...state,
        show: true,
        headerText: 'Create New Lead Source',
        actionText: CREATE
      };
    }
    case UPDATE: {
      return {
        ...state,
        show: true,
        headerText: 'Update Lead Source',
        actionText: UPDATE,
        name: action.name,
        key: action.key
      };
    }
    case HIDE: {
      return {
        ...state,
        show: false
      };
    }
    default:
      return state;
  }
};

const LeadSourcesList = () => {
  const dispatch = useDispatch();
  const [modal, dispatchModal] = useReducer(modalReducer, modalInitialState);
  const loadedSelector = createLoadedSelector([FETCH_LEAD_SOURCES]);
  const leadSourcesErrorSelector = createErrorMessageSelector([
    FETCH_LEAD_SOURCES
  ]);
  const createLeadSourceLoadedSelector = createLoadedSelector([
    CREATE_LEAD_SOURCE
  ]);
  const errorCreateLeadSourceSelecor = createErrorMessageSelector([
    CREATE_LEAD_SOURCE
  ]);

  function handleSubmit(values) {
    dispatch(createLeadSource(values));
  }

  const formik = useFormik({
    initialValues: {
      name: '',
      key: ''
    },
    enableReinitialize: true,
    validationSchema: leadSourceSchema,
    validateOnMount: true,
    onSubmit: values => {
      handleSubmit(values);
    }
  });

  function handleNameChange(e) {
    const leadSourceName = e.target.value;
    formik.handleChange(e);
    const leadSourceKey = leadSourceName
      .trim()
      .replace(/[\W_]+/g, '-')
      .toLowerCase();
    formik.setFieldValue('key', leadSourceKey);
  }

  const structuredSelector = createStructuredSelector({
    defaultLeadSources: state => state.leadSource.defaultLeadSources,
    customLeadSources: state => state.leadSource.customLeadSources,
    leadSourcesLoaded: state => loadedSelector(state),
    leadSourcesError: state => leadSourcesErrorSelector(state),
    createLeadSourceLoaded: state => createLeadSourceLoadedSelector(state),
    errorCreateLeadSource: state => errorCreateLeadSourceSelecor(state)
  });

  const {
    defaultLeadSources,
    customLeadSources,
    leadSourcesLoaded,
    leadSourcesError,
    createLeadSourceLoaded,
    errorCreateLeadSource
  } = useSelector(structuredSelector);

  useEffect(() => {
    if (isEmpty(defaultLeadSources)) dispatch(fetchLeadSources());
  }, [dispatch, defaultLeadSources]);

  useEffect(() => {
    if (leadSourcesLoaded) {
      if (!isEmpty(leadSourcesError)) {
        dispatch(flashErrorMessage(leadSourcesError));
      }
    }
  }, [leadSourcesLoaded, leadSourcesError]);

  useEffect(() => {
    if (createLeadSourceLoaded) {
      if (isEmpty(errorCreateLeadSource)) {
        dispatch(flashSuccessMessage('Lead Source created'));
        formik.resetForm();
      } else {
        dispatch(flashErrorMessage(errorCreateLeadSource));
      }
    }
  }, [createLeadSourceLoaded, errorCreateLeadSource]);

  function handleModalSetShow(show) {
    if (!show) dispatchModal({ type: HIDE });
  }

  return (
    <Fragment>
      <PageHeader
        title="Lead Sources"
        breadcrumbs={[
          {
            name: 'Settings',
            action: () => {
              window.location = '/account/account_settings';
            }
          },
          {
            name: 'Lead Sources',
            action: () => {}
          }
        ]}
        primaryAction={() => {
          window.location.href = '/ui/lead-sources/bulk-update';
        }}
        primaryActionText="Update Existing Contacts"
      />
      <Card
        containerClass="tw-rounded-lg tw-bg-white tw-m-8 tw-mb-20"
        customMarginContainer
        showPrimaryAction
        primaryActionText="Add New Lead Source"
        primaryAction={() =>
          dispatchModal({
            type: CREATE
          })
        }
      >
        <div className="tw-p-4">
          <h3 className="tw-font-bold tw-pb-2">Default</h3>
          <Table rows={defaultLeadSources} includedColumns={['name', 'key']} />
        </div>
        {!isEmpty(customLeadSources) && (
          <div className="tw-p-4">
            <h3 className="tw-font-bold tw-pb-2">Custom</h3>
            <Table rows={customLeadSources} includedColumns={['name', 'key']} />
          </div>
        )}
      </Card>
      <Modal
        headerText={modal.headerText}
        primaryActionText={modal.actionText}
        primaryAction={formik.handleSubmit}
        secondaryActionText="Cancel"
        secondaryAction={() => dispatchModal({ type: HIDE })}
        show={modal.show}
        setShow={handleModalSetShow}
        showHeaderIcon={false}
        canHandleClickOutside
        showSecondaryAction
        isPrimaryActionDisabled={!formik.isValid}
      >
        <TextInput
          id="name"
          required
          value={formik.values.name}
          labelText="Name"
          onChange={e => handleNameChange(e)}
          onBlur={formik.handleBlur}
          showError={formik.touched.name && !!formik.errors.name}
          error={formik.errors.name}
        />
        <TextInput
          id="key"
          value={formik.values.key}
          labelText="Key"
          widthClass="tw-mt-5"
          readOnly
        />
      </Modal>
    </Fragment>
  );
};

export default LeadSourcesList;
