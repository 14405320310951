import React, { Fragment } from 'react';
import fourOFourImg from 'images/404-not-found.svg';

const fourOFour = () => {
  return (
    <Fragment>
      <div className="row no-margin">
        <div className="col-12">
          <div
            className="card mx-auto text-center"
            style={{
              paddingTop: '40px',
              marginTop: '50px',
              maxWidth: '85vw',
              boxShadow:
                '0 5px 10px rgba(154, 160, 185, 0.05), 0 15px 40px rgba(166, 173, 201, 0.2)',
              borderRadius: '10px'
            }}
          >
            <div className="card-body">
              <img
                src={fourOFourImg}
                style={{ minWidth: '350px', maxWidth: '80vw' }}
                className="mx-auto"
                alt="404-svg"
              />
              <h1 className="text-uppercase pt-30">Page not found!</h1>
              <p className="lead pb-40">
                I think you ventured a little too far out into the forest there,
                friend. Hit the &apos;back&apos; button to jump back in bounds!
              </p>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default fourOFour;
