import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import PageHeader from 'components/Theme/PageHeader';
import ProductSubscriptionsCards from 'components/MyProducts/ProductSubscriptionsCards';
import isEmpty from 'lodash.isempty';
import { createStructuredSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import {
  createErrorMessageSelector,
  createLoadedSelector
} from 'appState/selectors';
import DefaultProducts from 'components/MyProducts/DefaultProducts';
import { UPDATE_DEFAULT_PRODUCT } from 'appState/actions/constants/subscription.actions';
import {
  mySubscriptionFetch,
  updateDefaultProduct,
  flashSuccessMessage,
  flashErrorMessage
} from 'appState/actions/ActionCreators';
import Modal from 'components/Theme/Modal';

const MyProducts = () => {
  const dispatch = useDispatch();
  const updateDefaultProductLoadedSelector = createLoadedSelector([
    UPDATE_DEFAULT_PRODUCT
  ]);
  const updateDefaultProductErrorSelector = createErrorMessageSelector([
    UPDATE_DEFAULT_PRODUCT
  ]);
  const [saveDefaultsBtnDisabled, setSaveDefaultsBtnDisabled] = useState(true);

  const structuredSelector = createStructuredSelector({
    mySubscription: state => state.subscription.mySubscription,
    isUpdateDefaultProductLoaded: state =>
      updateDefaultProductLoadedSelector(state),
    updateDefaultProductError: state => updateDefaultProductErrorSelector(state)
  });

  const {
    mySubscription,
    isUpdateDefaultProductLoaded,
    updateDefaultProductError
  } = useSelector(structuredSelector);

  const [showDefaultProductsModal, setShowDefaultProductsModal] = useState(
    false
  );

  useEffect(() => {
    if (isUpdateDefaultProductLoaded) {
      if (isEmpty(updateDefaultProductError)) {
        dispatch(flashSuccessMessage('Successfully updated default products.'));
        setShowDefaultProductsModal(false);
      } else {
        dispatch(flashErrorMessage(updateDefaultProductError));
      }
    }
  }, [isUpdateDefaultProductLoaded, updateDefaultProductError]);

  const defaultProductFormik = useFormik({
    initialValues: {
      /* eslint-disable camelcase */
      defaultLeadProductId: mySubscription?.default_lead_product_id,
      defaultClientProductId: mySubscription?.default_client_product_id
      /* eslint-enable camelcase */
    },
    enableReinitialize: true,
    onSubmit: values => {
      setSaveDefaultsBtnDisabled(true);
      dispatch(updateDefaultProduct(values));
    }
  });

  function getLeadClientList() {
    const clientList = [];
    const newLeadList = [];
    if (!mySubscription || !mySubscription.products)
      return { clientList, newLeadList };

    mySubscription.product_subscriptions.forEach(prodSub => {
      const { lifecycles, name: displayName, id: value } = prodSub.product;
      const setupAt = prodSub.setup_at;
      if (lifecycles) {
        const newListEntry = { displayName, value };
        if (lifecycles.includes('lead') && setupAt !== null)
          newLeadList.push(newListEntry);
        if (lifecycles.includes('client') && setupAt !== null)
          clientList.push(newListEntry);
      }
    });

    return { clientList, newLeadList };
  }

  const { clientList, newLeadList } = getLeadClientList();

  useEffect(() => {
    if (isEmpty(mySubscription)) {
      dispatch(mySubscriptionFetch());
    }
  }, [dispatch, mySubscription]);

  const handleLeadProductSelect = leadProductId => {
    defaultProductFormik.setFieldValue(
      'defaultLeadProductId',
      leadProductId,
      false
    );
    setSaveDefaultsBtnDisabled(false);
  };
  const handleClientProductSelect = clientProductId => {
    defaultProductFormik.setFieldValue(
      'defaultClientProductId',
      clientProductId,
      false
    );
    setSaveDefaultsBtnDisabled(false);
  };

  return (
    <div>
      <PageHeader
        title="My Products"
        primaryActionText="Set Defaults"
        primaryAction={() => setShowDefaultProductsModal(true)}
        isLoaded={!isEmpty(mySubscription)}
      />
      <div className="tw-px-4 tw-py-5 sm:tw-p-6">
        <ProductSubscriptionsCards />
      </div>
      <Modal
        color="alpha"
        headerText="Set Default Products"
        innerContainerClasses="tw-px-4 tw-pt-5 sm:tw-pt-6 tw-pb-0"
        showHeaderIcon={false}
        showSecondaryAction
        showPrimaryAction
        secondaryActionText="Cancel"
        primaryActionText="Save"
        primaryAction={defaultProductFormik.handleSubmit}
        isPrimaryActionDisabled={saveDefaultsBtnDisabled}
        secondaryAction={() => {
          setShowDefaultProductsModal(false);
        }}
        show={showDefaultProductsModal}
        overflowHidden={false}
        bodyClasses=""
        canHandleClickOutside={false}
      >
        <div className="tw-mt-5 md:tw-mt-0 md:tw-col-span-3">
          <div className="sm:tw-rounded-md sm:tw-overflow-hidden">
            <DefaultProducts
              clientList={clientList}
              newLeadList={newLeadList}
              handleLeadProduct={handleLeadProductSelect}
              handleClientProduct={handleClientProductSelect}
              defaultClientProduct={mySubscription.default_client_product_id}
              defaultLeadProduct={mySubscription.default_lead_product_id}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default MyProducts;
