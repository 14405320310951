/**
 * Overlord Utilities
 */

/* General */
export const USERS_FETCH_REQUEST = 'overlord/USERS_FETCH_REQUEST';
export const USERS_FETCH_SUCCESS = 'overlord/USERS_FETCH_SUCCESS';
export const USERS_FETCH_FAILURE = 'overlord/USERS_FETCH_FAILURE';
export const RESET_IS_LOADED_REQUEST = 'overlord/RESET_IS_LOADED_REQUEST';

/* Account Utilities */
// phone number changer
export const FETCH_AVAILABLE_PHONE_NUMBERS_REQUEST =
  'overlord/FETCH_AVAILABLE_PHONE_NUMBERS_REQUEST';
export const FETCH_AVAILABLE_PHONE_NUMBERS_SUCCESS =
  'overlord/FETCH_AVAILABLE_PHONE_NUMBERS_SUCCESS';
export const FETCH_AVAILABLE_PHONE_NUMBERS_FAILURE =
  'overlord/FETCH_AVAILABLE_PHONE_NUMBERS_FAILURE';
export const PURCHASE_NUMBER_FOR_ACCOUNT_REQUEST =
  'overlord/PURCHASE_NUMBER_FOR_ACCOUNT_REQUEST';
export const PURCHASE_NUMBER_FOR_ACCOUNT_SUCCESS =
  'overlord/PURCHASE_NUMBER_FOR_ACCOUNT_SUCCESS';
export const PURCHASE_NUMBER_FOR_ACCOUNT_FAILURE =
  'overlord/PURCHASE_NUMBER_FOR_ACCOUNT_FAILURE';
// phone number assign
export const ASSIGN_NUMBER_FOR_ACCOUNT = 'overlord/ASSIGN_NUMBER_FOR_ACCOUNT';
export const ASSIGN_NUMBER_FOR_ACCOUNT_REQUEST = `${ASSIGN_NUMBER_FOR_ACCOUNT}_REQUEST`;
export const ASSIGN_NUMBER_FOR_ACCOUNT_SUCCESS = `${ASSIGN_NUMBER_FOR_ACCOUNT}_SUCCESS`;
export const ASSIGN_NUMBER_FOR_ACCOUNT_FAILURE = `${ASSIGN_NUMBER_FOR_ACCOUNT}_FAILURE`;
// campaign pauser
export const CAMPAIGN_PAUSER_PAUSE_REQUEST =
  'overlord/CAMPAIGN_PAUSER_PAUSE_REQUEST';
export const CAMPAIGN_PAUSER_PAUSE_SUCCESS =
  'overlord/CAMPAIGN_PAUSER_PAUSE_SUCCESS';
export const CAMPAIGN_PAUSER_PAUSE_FAILURE =
  'overlord/CAMPAIGN_PAUSER_PAUSE_FAILURE';
export const CAMPAIGN_PAUSER_UNPAUSE_REQUEST =
  'overlord/CAMPAIGN_PAUSER_UNPAUSE_REQUEST';
export const CAMPAIGN_PAUSER_UNPAUSE_SUCCESS =
  'overlord/CAMPAIGN_PAUSER_UNPAUSE_SUCCESS';
export const CAMPAIGN_PAUSER_UNPAUSE_FAILURE =
  'overlord/CAMPAIGN_PAUSER_UNPAUSE_FAILURE';
// account disabler
export const ACCOUNT_DISABLER_DISABLE_REQUEST =
  'overlord/ACCOUNT_DISABLER_DISABLE_REQUEST';
export const ACCOUNT_DISABLER_DISABLE_SUCCESS =
  'overlord/ACCOUNT_DISABLER_DISABLE_SUCCESS';
export const ACCOUNT_DISABLER_DISABLE_FAILURE =
  'overlord/ACCOUNT_DISABLER_DISABLE_FAILURE';
export const ACCOUNT_ENABLER_ENABLE_REQUEST =
  'overlord/ACCOUNT_ENABLER_ENABLE_REQUEST';
export const ACCOUNT_ENABLER_ENABLE_SUCCESS =
  'overlord/ACCOUNT_ENABLER_ENABLE_SUCCESS';
export const ACCOUNT_ENABLER_ENABLE_FAILURE =
  'overlord/ACCOUNT_ENABLER_ENABLE_FAILURE';
// recaptcha disabler
export const RECAPTCHA_DISABLER_DISABLE_REQUEST =
  'overlord/RECAPTCHA_DISABLER_DISABLE_REQUEST';
export const RECAPTCHA_DISABLER_DISABLE_SUCCESS =
  'overlord/RECAPTCHA_DISABLER_DISABLE_SUCCESS';
export const RECAPTCHA_DISABLER_DISABLE_FAILURE =
  'overlord/RECAPTCHA_DISABLER_DISABLE_FAILURE';
export const RECAPTCHA_ENABLER_ENABLE_REQUEST =
  'overlord/RECAPTCHA_ENABLER_ENABLE_REQUEST';
export const RECAPTCHA_ENABLER_ENABLE_SUCCESS =
  'overlord/RECAPTCHA_ENABLER_ENABLE_SUCCESS';
export const RECAPTCHA_ENABLER_ENABLE_FAILURE =
  'overlord/RECAPTCHA_ENABLER_ENABLE_FAILURE';
export const SET_RECAPTCHA_DISABLED = 'overlord/SET_RECAPTCHA_DISABLED';

// account admin changer
export const ACCOUNT_ADMIN_CHANGER_REQUEST =
  'overlord/ACCOUNT_ADMIN_CHANGER_REQUEST';
export const ACCOUNT_ADMIN_CHANGER_SUCCESS =
  'overlord/ACCOUNT_ADMIN_CHANGER_SUCCESS';
export const ACCOUNT_ADMIN_CHANGER_FAILURE =
  'overlord/ACCOUNT_ADMIN_CHANGER_FAILURE';
// account subdomain changer
export const SUBDOMAIN_CHANGER_REQUEST = 'overlord/SUBDOMAIN_CHANGER_REQUEST';
export const SUBDOMAIN_CHANGER_SUCCESS = 'overlord/SUBDOMAIN_CHANGER_SUCCESS';
export const SUBDOMAIN_CHANGER_FAILURE = 'overlord/SUBDOMAIN_CHANGER_FAILURE';
export const FETCH_SUBDOMAINS_REQUEST = 'overlord/FETCH_SUBDOMAINS_REQUEST';
export const FETCH_SUBDOMAINS_SUCCESS = 'overlord/FETCH_SUBDOMAINS_SUCCESS';
export const FETCH_SUBDOMAINS_FAILURE = 'overlord/FETCH_SUBDOMAINS_FAILURE';

/* Staff Utilities */
// user object reassigner
export const REASSIGN_USER_OBJECTS_REQUEST =
  'overlord/REASSIGN_USER_OBJECTS_REQUEST';
export const REASSIGN_USER_OBJECTS_SUCCESS =
  'overlord/REASSIGN_USER_OBJECTS_SUCCESS';
export const REASSIGN_USER_OBJECTS_FAILURE =
  'overlord/REASSIGN_USER_OBJECTS_FAILURE';
// clone user availability slots
export const CLONE_USER_AVAILABILITY_REQUEST =
  'overlord/CLONE_USER_AVAILABILITY_REQUEST';
export const CLONE_USER_AVAILABILITY_SUCCESS =
  'overlord/CLONE_USER_AVAILABILITY_SUCCESS';
export const CLONE_USER_AVAILABILITY_FAILURE =
  'overlord/CLONE_USER_AVAILABILITY_FAILURE';

/* Contact Utilities */
// custom group assigner
export const CUSTOM_GROUP_ASSIGNER_REQUEST =
  'overlord/CUSTOM_GROUP_ASSIGNER_REQUEST';
export const CUSTOM_GROUP_ASSIGNER_SUCCESS =
  'overlord/CUSTOM_GROUP_ASSIGNER_SUCCESS';
export const CUSTOM_GROUP_ASSIGNER_FAILURE =
  'overlord/CUSTOM_GROUP_ASSIGNER_FAILURE';
export const CUSTOM_GROUPS_FETCH = 'overlord/CUSTOM_GROUPS_FETCH';
export const CUSTOM_GROUPS_FETCH_REQUEST = `${CUSTOM_GROUPS_FETCH}_REQUEST`;
export const CUSTOM_GROUPS_FETCH_SUCCESS = `${CUSTOM_GROUPS_FETCH}_SUCCESS`;
export const CUSTOM_GROUPS_FETCH_FAILURE = `${CUSTOM_GROUPS_FETCH}_FAILURE`;
export const CUSTOM_GROUPS_CREATE = 'overlord/CUSTOM_GROUPS_CREATE';
export const CUSTOM_GROUPS_CREATE_REQUEST = `${CUSTOM_GROUPS_CREATE}_REQUEST`;
export const CUSTOM_GROUPS_CREATE_SUCCESS = `${CUSTOM_GROUPS_CREATE}_SUCCESS`;
export const CUSTOM_GROUPS_CREATE_FAILURE = `${CUSTOM_GROUPS_CREATE}_FAILURE`;
// bulk journey changer
export const BULK_JOURNEY_CHANGER_REQUEST =
  'overlord/BULK_JOURNEY_CHANGER_REQUEST';
export const BULK_JOURNEY_CHANGER_SUCCESS =
  'overlord/BULK_JOURNEY_CHANGER_SUCCESS';
export const BULK_JOURNEY_CHANGER_FAILURE =
  'overlord/BULK_JOURNEY_CHANGER_FAILURE';
export const JOURNEYS_FETCH_REQUEST = 'overlord/JOURNEYS_FETCH_REQUEST';
export const JOURNEYS_FETCH_SUCCESS = 'overlord/JOURNEYS_FETCH_SUCCESS';
export const JOURNEYS_FETCH_FAILURE = 'overlord/JOURNEYS_FETCH_FAILURE';
// bulk campaign changer
export const BULK_CAMPAIGN_CHANGER_REQUEST =
  'overlord/BULK_CAMPAIGN_CHANGER_REQUEST';
export const BULK_CAMPAIGN_CHANGER_SUCCESS =
  'overlord/BULK_CAMPAIGN_CHANGER_SUCCESS';
export const BULK_CAMPAIGN_CHANGER_FAILURE =
  'overlord/BULK_CAMPAIGN_CHANGER_FAILURE';
export const CAMPAIGNS_FETCH_REQUEST = 'overlord/CAMPAIGNS_FETCH_REQUEST';
export const CAMPAIGNS_FETCH_SUCCESS = 'overlord/CAMPAIGNS_FETCH_SUCCESS';
export const CAMPAIGNS_FETCH_FAILURE = 'overlord/CAMPAIGNS_FETCH_FAILURE';
// contact merger
export const CONTACTS_FETCH_REQUEST = 'overlord/CONTACTS_FETCH_REQUEST';
export const CONTACTS_FETCH_SUCCESS = 'overlord/CONTACTS_FETCH_SUCCESS';
export const CONTACTS_FETCH_FAILURE = 'overlord/CONTACTS_FETCH_FAILURE';
export const MERGE_CONTACTS_REQUEST = 'overlord/MERGE_CONTACTS_REQUEST';
export const MERGE_CONTACTS_SUCCESS = 'overlord/MERGE_CONTACTS_SUCCESS';
export const MERGE_CONTACTS_FAILURE = 'overlord/MERGE_CONTACTS_FAILURE';

/* Overlord Recent Activities */
export const FETCH_RECENT_ACTIVITY_REQUEST =
  'overlord/FETCH_RECENT_ACTIVITY_REQUEST';
export const FETCH_RECENT_ACTIVITY_SUCCESS =
  'overlord/FETCH_RECENT_ACTIVITY_SUCCESS';
export const FETCH_RECENT_ACTIVITY_FAILURE =
  'overlord/FETCH_RECENT_ACTIVITY_FAILURE';
export const FETCH_PROCESSING_ACTIVITIES_REQUEST =
  'overlord/FETCH_PROCESSING_ACTIVITIES_REQUEST';
export const FETCH_PROCESSING_ACTIVITIES_SUCCESS =
  'overlord/FETCH_PROCESSING_ACTIVITIES_SUCCESS';
export const FETCH_PROCESSING_ACTIVITIES_FAILURE =
  'overlord/FETCH_PROCESSING_ACTIVITIES_FAILURE';
export const START_FETCH_PROCESSING_WATCHER =
  'overlord/START_FETCH_PROCESSING_WATCHER';
export const STOP_FETCH_PROCESSING_WATCHER =
  'overlord/STOP_FETCH_PROCESSING_WATCHER';

// onboarding disabler
export const ONBOARDING_DISABLER_DISABLE_REQUEST =
  'overlord/ONBOARDING_DISABLER_DISABLE_REQUEST';
export const ONBOARDING_DISABLER_DISABLE_SUCCESS =
  'overlord/ONBOARDING_DISABLER_DISABLE_SUCCESS';
export const ONBOARDING_DISABLER_DISABLE_FAILURE =
  'overlord/ONBOARDING_DISABLER_DISABLE_FAILURE';
export const ONBOARDING_ENABLER_ENABLE_REQUEST =
  'overlord/ONBOARDING_ENABLER_ENABLE_REQUEST';
export const ONBOARDING_ENABLER_ENABLE_SUCCESS =
  'overlord/ONBOARDING_ENABLER_ENABLE_SUCCESS';
export const ONBOARDING_ENABLER_ENABLE_FAILURE =
  'overlord/ONBOARDING_ENABLER_ENABLE_FAILURE';

// bulk contact unsubscriber
export const BULK_CONTACT_UNSUBSCRIBER_REQUEST =
  'overlord/BULK_CONTACT_UNSUBSCRIBER_REQUEST';
export const BULK_CONTACT_UNSUBSCRIBER_SUCCESS =
  'overlord/BULK_CONTACT_UNSUBSCRIBER_SUCCESS';
export const BULK_CONTACT_UNSUBSCRIBER_FAILURE =
  'overlord/BULK_CONTACT_UNSUBSCRIBER_FAILURE';

// Overlord Area
export const SET_OVERLORD_LAYOUT_HEADER_TEXT =
  'overlord/SET_OVERLORD_LAYOUT_HEADER_TEXT';
