import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useParams } from 'react-router-dom';
import isEmpty from 'lodash.isempty';
import camelcaseKeys from 'camelcase-keys';
import { createLoadedSelector } from 'appState/selectors';
import { decorateMessage } from 'lib/services/messageDecorator';
import {
  smsInboxFetch,
  smsInboxPageChange,
  smsInboxFetchConversation,
  smsInboxConversationPageChange,
  smsInboxUpdateCurrentConversation,
  smsInboxUpdateMessageListItem,
  resetIsLoaded
} from 'appState/actions/ActionCreators';
import { scrollToBottom } from 'lib/utils/scroll';
import ActionCable from 'components/ActionCable';
import useInfiniteScroll from 'components/shared/hooks/useInfiniteScroll';
import Toggle from 'components/Theme/Toggle';
import ConversationListItem from 'components/Theme/ConversationListItem';
import Card from 'components/Theme/Card';
import useWindowDimensions from 'components/shared/hooks/useWindowDimensions';
import SkeletonMessageListItem from './MessageListItem/SkeletonMessageListItem';
import MainWindow from './MainWindow';
import MainWindowHeader from './MainWindow/Header';
import MainWindowFooter from './MainWindow/Footer';

const actionTypeSelectorsInbox = [
  'smsInbox/SMS_INBOX_FETCH',
  'smsInbox/SMS_INBOX_PAGE_CHANGE'
];

const actionTypeSelectorsConversation = [
  'smsInbox/SMS_INBOX_FETCH_CONVERSATION',
  'smsInbox/SMS_INBOX_CONVERSATION_PAGE_CHANGE'
];

const MOBILE_BREAKPOINT = 768;

const SmsInbox = () => {
  const [selectedConversationId, setSelectedConversationId] = useState(0);
  const [selectedConversation, setSelectedConversation] = useState({});
  const [addedImage, setAddedImage] = useState(false);
  const [showMessageList, setShowMessageList] = useState(true);
  const [
    showUnreadConversationsOnly,
    setShowUnreadConversationsOnly
  ] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();
  const { contactId } = params;

  const { windowWidth } = useWindowDimensions();
  const isMobile = windowWidth < MOBILE_BREAKPOINT;

  const inboxLoadedSelector = createLoadedSelector(actionTypeSelectorsInbox);
  const conversationLoadedSelector = createLoadedSelector(
    actionTypeSelectorsConversation
  );
  const conversationFetchLoadedSelector = createLoadedSelector([
    'smsInbox/SMS_INBOX_FETCH_CONVERSATION'
  ]);
  const conversationFetchErrorSelector = createLoadedSelector([
    'smsInbox/SMS_INBOX_FETCH_CONVERSATION'
  ]);

  const structuredSelector = createStructuredSelector({
    conversations: state => state.smsInbox.conversations,
    page: state => state.smsInbox.page,
    totalPages: state => state.smsInbox.totalPages,
    conversationPage: state => state.smsInbox.conversationPage,
    conversationTotalPages: state => state.smsInbox.conversationTotalPages,
    isSmsInboxLoaded: state => inboxLoadedSelector(state),
    isConversationLoaded: state => conversationLoadedSelector(state),
    conversationFetchError: state => conversationFetchErrorSelector(state),
    isConversationFetchLoaded: state => conversationFetchLoadedSelector(state)
  });

  const {
    conversations,
    page,
    totalPages,
    conversationPage,
    conversationTotalPages,
    isSmsInboxLoaded,
    isConversationLoaded,
    conversationFetchError,
    isConversationFetchLoaded
  } = useSelector(structuredSelector);

  useEffect(() => {
    dispatch(smsInboxFetch({ unread: showUnreadConversationsOnly }));
  }, [dispatch, showUnreadConversationsOnly]);

  function selectConversation(conversationId, mobile) {
    setSelectedConversationId(conversationId);
    setShowMessageList(mobile);
  }

  useEffect(() => {
    if (
      conversations &&
      conversations.length > 0 &&
      isEmpty(selectedConversation)
    ) {
      const contactFromUrlParam = conversations.filter(
        c => c.id === contactId
      )[0];

      setSelectedConversation(
        !isEmpty(contactFromUrlParam) ? contactFromUrlParam : conversations[0]
      );
      setSelectedConversationId(
        !isEmpty(contactFromUrlParam)
          ? contactFromUrlParam.id
          : conversations[0].id
      );
      dispatch(
        smsInboxFetchConversation({
          contactId: !isEmpty(contactFromUrlParam)
            ? contactFromUrlParam.id
            : conversations[0].id
        })
      );
    }
  }, [conversations, selectedConversation, contactId, dispatch]);

  useEffect(() => {
    if (conversations && conversations.length > 0) {
      const conversation = conversations.filter(
        c => c.id === selectedConversationId
      )[0];
      setSelectedConversation(conversation);
    }
  }, [conversations, selectedConversationId]);

  useEffect(() => {
    if (isConversationFetchLoaded && isEmpty(conversationFetchError))
      scrollToBottom('chat-main-content');
  }, [isConversationFetchLoaded, conversationFetchError]);

  function convoList(mobile = false) {
    if (conversations) {
      return conversations.map(conversation => {
        return (
          <ConversationListItem
            conversation={conversation}
            selectConversation={selectConversation}
            mobile={mobile}
            isActive={selectedConversationId === conversation.id}
            key={conversation.id}
          />
        );
      });
    }
    return [...Array(20).keys()].map(i => {
      return <SkeletonMessageListItem key={i} />;
    });
  }

  const [isFetchingInbox, setIsFetchingInbox] = useInfiniteScroll(
    'sms-inbox-aside-body',
    // eslint-disable-next-line no-use-before-define
    fetchMoreConversations
  );

  function fetchMoreConversations() {
    const hasMore = page < totalPages;
    if (!hasMore) return;

    dispatch(
      smsInboxPageChange({
        page: page + 1,
        unread: showUnreadConversationsOnly
      })
    );
  }

  const resetAllIsLoadedInbox = () => {
    actionTypeSelectorsInbox.forEach(type => dispatch(resetIsLoaded(type)));
  };

  function handleShowOnlyUnreadToggleChange() {
    setShowUnreadConversationsOnly(!showUnreadConversationsOnly);
    resetAllIsLoadedInbox();
  }

  useEffect(() => {
    const hasMore = page < totalPages;

    if (!hasMore) return;

    if (isSmsInboxLoaded) {
      setIsFetchingInbox(false);
      resetAllIsLoadedInbox();
    }
  }, [isSmsInboxLoaded]);

  const [
    isFetchingConversation,
    setIsFetchingConversation,
    conversationLastScrollHeight
  ] = useInfiniteScroll(
    'chat-main-content',
    // eslint-disable-next-line no-use-before-define
    fetchMoreConversationMessages,
    true,
    isMobile
  );

  function fetchMoreConversationMessages() {
    const hasMore = conversationPage < conversationTotalPages;

    if (!hasMore) return;

    if (!isEmpty(selectedConversation))
      dispatch(
        smsInboxConversationPageChange({
          contactId: selectedConversation.id,
          page: conversationPage + 1
        })
      );
  }

  function conversationWindow(mobile = false) {
    return (
      <Card
        header={
          <MainWindowHeader
            contact={selectedConversation}
            showMessageList={setShowMessageList}
          />
        }
        footer={
          <MainWindowFooter
            contact={camelcaseKeys(selectedConversation)}
            setAddedImage={setAddedImage}
          />
        }
        bodyStyle={{
          backgroundColor: 'white',
          overflow: 'scroll',
          height: mobile
            ? 'calc(100vh - 332px)'
            : `calc(100vh - ${addedImage ? '430' : '355'}px)`
        }}
        bodyScrollId="chat-main-content"
        containerClass="tw-shadow sm:tw-rounded-md"
        containerStyle={{
          display: !showMessageList || !mobile ? 'flex' : 'none'
        }}
      >
        {isFetchingConversation && !isConversationLoaded && (
          <div className="h-100px center-vh">
            <div className="spinner-circle spinner-info" />
          </div>
        )}
        <div>
          <MainWindow className="mt-20" contactId={selectedConversationId} />
        </div>
      </Card>
    );
  }

  function conversationListSidebar(mobile = false) {
    return (
      <div
        className="tw-inline-block tw-w-screen tw-bg-white tw-translate-x-0 md:tw-flex-3 md:tw-max-w-xs lg:tw-max-w-md"
        id="sms-inbox-aside-wrapper"
        style={{ display: showMessageList || !mobile ? 'block' : 'none' }}
      >
        <div
          className="tw-h-full"
          id="sms-inbox-aside-body"
          style={{
            overflowY: 'scroll'
          }}
        >
          <div className="tw-my-4 tw-flex tw-items-center tw-ml-8">
            <Toggle
              color="alpha"
              onClick={handleShowOnlyUnreadToggleChange}
              isToggled={showUnreadConversationsOnly}
              size="small"
              withIcons
              withLabel
              label="Show Only Unread Messages?"
            />
          </div>
          <div className="sm:tw-rounded-md">
            <div
              id="sms-sidebar-list"
              style={{
                listStyleType: 'none'
              }}
            >
              <span className="md:tw-block tw-hidden">{convoList(true)}</span>
              <span className="md:tw-hidden tw-block">{convoList()}</span>
              {isFetchingInbox && !isSmsInboxLoaded && (
                <div className="h-70px center-vh">
                  <div className="spinner-circle spinner-info" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  const resetAllIsLoadedConversation = () => {
    actionTypeSelectorsConversation.forEach(type =>
      dispatch(resetIsLoaded(type))
    );
  };

  useEffect(() => {
    const hasMore = conversationPage < conversationTotalPages;

    if (!hasMore) return;

    // maintain scroll position when loading more messages in currentConversation
    const element = document.getElementById('chat-main-content');
    const newScrollHeight = isMobile
      ? document.body.scrollHeight
      : element.scrollHeight;
    element.scrollTop = newScrollHeight - conversationLastScrollHeight - 100;

    if (isConversationLoaded) {
      setIsFetchingConversation(false);
      resetAllIsLoadedConversation();
    }
  }, [isConversationLoaded, isMobile]);

  function handleReceivedSms(response) {
    const decoratedMessage = decorateMessage(response.sms);
    dispatch(smsInboxUpdateCurrentConversation({ sms: decoratedMessage }));
    dispatch(smsInboxUpdateMessageListItem({ sms: decoratedMessage }));
  }

  function handleSocketConnected() {
    // eslint-disable-next-line no-console
    console.log('sms inbox api socket connected...');
  }

  return (
    <main
      className={`md:tw-flex ${
        !showMessageList
          ? 'tw-overflow-hidden'
          : 'tw-overflow-y-scroll md:tw-overflow-hidden'
      }`}
      style={{ height: 'calc(100vh - 120px)' }}
    >
      <ActionCable
        channel={{ channel: 'SmsInboxApiChannel' }}
        onReceived={handleReceivedSms}
        onConnected={handleSocketConnected}
      >
        <span className="md:tw-flex tw-hidden">
          {conversationListSidebar(false)}
        </span>
        <span className="md:tw-hidden tw-flex">
          {conversationListSidebar(true)}
        </span>
        <div className="md:tw-flex-1 tw-translate-x-full sm:tw-p-5">
          <span className="md:tw-block tw-hidden">
            {conversationWindow(false)}
          </span>
          <span className="md:tw-hidden tw-block">
            {conversationWindow(true)}
          </span>
        </div>
      </ActionCable>
    </main>
  );
};

export default SmsInbox;
