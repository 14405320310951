import React from 'react';
import { action } from '@storybook/addon-actions';
import { withA11y } from '@storybook/addon-a11y';
import { withKnobs, text, select, object } from '@storybook/addon-knobs';
import PageHeader from './index';

export default {
  component: PageHeader,
  title: 'Page Header',
  decorators: [withA11y, withKnobs]
};

let bgColorSelect;
let colorSelect;

function includeBgColorSelect(defaultValue) {
  const label = 'Background Color';
  const options = ['white', 'dark'];
  bgColorSelect = select(label, options, defaultValue);
}

function includeColorSelect(defaultValue) {
  const label = 'Color';
  const options = [
    'alpha',
    'bravo',
    'charlie',
    'success',
    'warning',
    'error',
    'gray'
  ];
  colorSelect = select(label, options, defaultValue);
}

export const standard = () => {
  includeBgColorSelect('white');
  return (
    <PageHeader
      title={text('Title', 'Plan Builder')}
      backgroundColor={bgColorSelect}
      breadcrumbs={object('Breadcrumbs', [])}
    />
  );
};

export const withBreadcrumbs = () => {
  includeBgColorSelect('white');
  return (
    <PageHeader
      title={text('Title', 'Plan Builder')}
      backgroundColor={bgColorSelect}
      breadcrumbs={object('Breadcrumbs', [
        {
          name: 'Overlord',
          action: action('page-header-first-breadcrumb-click')
        },
        {
          name: 'Plans Index',
          action: action('page-header-second-breadcrumb-click')
        },
        {
          name: 'Plan Builder',
          action: action('page-header-third-breadcrumb-click')
        }
      ])}
    />
  );
};

export const dark = () => {
  includeBgColorSelect('dark');
  return (
    <PageHeader
      title={text('Title', 'Plan Builder')}
      backgroundColor={bgColorSelect}
      breadcrumbs={object('Breadcrumbs', [
        {
          name: 'Overlord',
          action: action('page-header-first-breadcrumb-click')
        },
        {
          name: 'Plans Index',
          action: action('page-header-second-breadcrumb-click')
        },
        {
          name: 'Plan Builder',
          action: action('page-header-third-breadcrumb-click')
        }
      ])}
    />
  );
};

export const withPrimaryAction = () => {
  includeBgColorSelect('white');
  includeColorSelect('alpha');
  return (
    <PageHeader
      title={text('Title', 'Plan Builder')}
      backgroundColor={bgColorSelect}
      color={colorSelect}
      primaryAction={action('page-header-primary-action-click')}
      primaryActionText={text('Primary Action Text', 'Save')}
      breadcrumbs={object('Breadcrumbs', [
        {
          name: 'Overlord',
          action: action('page-header-first-breadcrumb-click')
        },
        {
          name: 'Plans Index',
          action: action('page-header-second-breadcrumb-click')
        },
        {
          name: 'Plan Builder',
          action: action('page-header-third-breadcrumb-click')
        }
      ])}
    />
  );
};

export const withPrimaryAndSecondaryAction = () => {
  includeBgColorSelect('white');
  includeColorSelect('alpha');
  return (
    <PageHeader
      title={text('Title', 'Plan Builder')}
      backgroundColor={bgColorSelect}
      color={colorSelect}
      primaryAction={action('page-header-primary-action-click')}
      primaryActionText={text('Primary Action Text', 'Save')}
      secondaryAction={action('page-header-secondary-action-click')}
      secondaryActionText={text('Secondary Action Text', 'Cancel')}
      breadcrumbs={object('Breadcrumbs', [
        {
          name: 'Overlord',
          action: action('page-header-first-breadcrumb-click')
        },
        {
          name: 'Plans Index',
          action: action('page-header-second-breadcrumb-click')
        },
        {
          name: 'Plan Builder',
          action: action('page-header-third-breadcrumb-click')
        }
      ])}
    />
  );
};
