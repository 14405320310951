// linting rules disabled for immer js
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import { SET_OVERLORD_LAYOUT_HEADER_TEXT } from 'appState/actions/constants/overlord.actions';

const initialState = {
  headerText: ''
};

export default function OverlordLayoutReducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case SET_OVERLORD_LAYOUT_HEADER_TEXT: {
        draft.headerText = action.payload;
        return;
      }
      default:
        return draft;
    }
  });
}
