import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Appointments from './Appointments';
import Tasks from './Tasks';

const UpcomingSection = ({ currentContact }) => {
  return (
    <Fragment>
      {currentContact && !currentContact.under14 && (
        <div className="tw-flex xxs:tw-flex-col xs:tw-flex-col md:tw-flex-row tw-col-span-12 tw-gap-6">
          <Appointments />
          <Tasks />
        </div>
      )}
    </Fragment>
  );
};

UpcomingSection.defaultProps = {
  currentContact: null
};

UpcomingSection.propTypes = {
  currentContact: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default UpcomingSection;
