/* eslint-disable import/prefer-default-export */
import { call, put, takeLatest } from 'redux-saga/effects';
import { axiosDefault } from 'lib/utils/axios-setup';
import {
  FETCH_OPT_IN_FORM_REQUEST,
  FETCH_OPT_IN_FORM_SUCCESS,
  FETCH_OPT_IN_FORM_FAILURE
} from 'app-state/actions/constants/optinform.actions';

function* fetchOptInFormDetail({ payload: { journeyId } }) {
  try {
    const response = yield call(
      axiosDefault.get,
      `/new_lead_contacts/opt_in_form/?journey_id=${journeyId}`
    );
    yield put({
      type: FETCH_OPT_IN_FORM_SUCCESS,
      payload: {
        accountFullAddress: response.data?.account_full_address,
        accountLogo: response.data?.account_logo,
        accountName: response.data?.account_name,
        googleApiPublicKey: response.data?.google_api_public_key,
        landingPageButtonText: response.data?.landing_page_button_text,
        latitude: response.data?.latitude,
        longitude: response.data?.longitude,
        newLeadLandingPageTitle: response.data?.new_lead_landing_page_title,
        privacyPolicyUrl: response.data?.privacy_policy_url,
        recaptchaKey: response.data?.recaptcha_key,
        repactchaEnabled: response.data?.repactcha_enabled,
        showGdprCompliantCheckboxes:
          response.data?.show_gdpr_compliant_checkboxes
      }
    });
  } catch (e) {
    yield put({
      type: FETCH_OPT_IN_FORM_FAILURE,
      payload: { error: e, fallbackError: 'Error loading optinform' },
      error: true
    });
  }
}

export function* fetchOptInForm() {
  yield takeLatest(FETCH_OPT_IN_FORM_REQUEST, fetchOptInFormDetail);
}
