import React, { useState, useEffect, useContext, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import isEmpty from 'lodash.isempty';
import {
  fetchNextTask,
  fetchActivities,
  resetIsLoaded
} from 'appState/actions/ActionCreators';
import { FETCH_NEXT_TASK } from 'appState/actions/constants/contact.actions';
import {
  CANCEL_TASK,
  COMPLETE_TASK
} from 'app-state/actions/constants/activity.actions';
import { UPDATE_TASK } from 'app-state/actions/constants/task.actions';
import {
  createLoadedSelector,
  createErrorMessageSelector
} from 'appState/selectors';
import { formatTimestamp } from 'lib/utils/dateTime';
import { DATE_TIME_FORMAT_TASK } from 'config/constants';
import { CurrentAccountContext } from 'components/shared/context/CurrentAccountContext';
import Card from 'components/Theme/Card';
import TaskEditModal from 'components/Theme/ActivityFeed/TaskEditModal';
import TaskCancelModal from 'components/Theme/ActivityFeed/TaskCancelModal';
import TaskCompleteModal from 'components/Theme/ActivityFeed/TaskCompleteModal';
import CardHeader from '../shared/cardHeader';
import CardBody from '../shared/cardBody';
import getBadgeContent from '../shared/badgeContent';

const ACTIONS = [
  { displayName: 'Complete Task', value: 'completeTask' },
  { displayName: 'Update Task', value: 'editTask' },
  { displayName: 'Cancel Task', value: 'cancelTask' }
];

const Tasks = () => {
  const [dateStr, setDateStr] = useState();
  const [badgeColor, setBadgeColor] = useState();
  const [badgeValue, setBadgeValue] = useState();
  const [showEditModal, setShowEditModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [taskActivity, setTaskActivity] = useState({});
  const dispatch = useDispatch();

  const currentAccount = useContext(CurrentAccountContext);

  const loadedSelector = createLoadedSelector([FETCH_NEXT_TASK]);

  const taskActionLoadedSelector = createLoadedSelector([
    COMPLETE_TASK,
    UPDATE_TASK,
    CANCEL_TASK
  ]);

  const taskActionErrorMessageSelector = createErrorMessageSelector([
    COMPLETE_TASK,
    UPDATE_TASK,
    CANCEL_TASK
  ]);

  const structuredSelector = createStructuredSelector({
    currentContact: state => state.contact.currentContact,
    nextTask: state => state.contact.nextTask,
    fetchTaskLoaded: state => loadedSelector(state),
    taskActionLoaded: state => taskActionLoadedSelector(state),
    taskActionError: state => taskActionErrorMessageSelector(state)
  });

  const {
    currentContact,
    nextTask,
    fetchTaskLoaded,
    taskActionLoaded,
    taskActionError
  } = useSelector(structuredSelector);

  const handleTaskActions = action => {
    if (action === 'completeTask') setShowCompleteModal(true);
    else if (action === 'editTask') setShowEditModal(true);
    else if (action === 'cancelTask') setShowCancelModal(true);
  };

  useEffect(() => {
    dispatch(fetchNextTask({ contactId: currentContact?.id }));
  }, [dispatch, currentContact]);

  useEffect(() => {
    if (fetchTaskLoaded && !isEmpty(nextTask)) {
      setDateStr(
        formatTimestamp(
          nextTask.due_date,
          currentAccount.time_zone,
          false,
          DATE_TIME_FORMAT_TASK,
          true
        )
      );
      const { color, value } = getBadgeContent(
        nextTask.due_date,
        currentAccount.time_zone
      );
      setBadgeColor(color);
      setBadgeValue(value);

      setTaskActivity({
        task_title: nextTask.title,
        task_description: nextTask.description,
        task_due_date: nextTask.due_date,
        user_id: nextTask.staff_id,
        sourceable_id: nextTask.id,
        parsed_task_description: nextTask.parsed_description
      });
    } else {
      setBadgeValue('');
      setBadgeColor('');
    }
  }, [fetchTaskLoaded, nextTask]);

  useEffect(() => {
    if (taskActionLoaded) {
      if (isEmpty(taskActionError)) {
        dispatch(fetchActivities({ contactId: currentContact.id }));
      }
    }
    dispatch(resetIsLoaded(UPDATE_TASK));
  }, [dispatch, taskActionLoaded, taskActionError]);

  return (
    <Card
      containerClass="tw-w-full tw-border-solid tw-border tw-rounded-md tw-border-gray-200"
      header={
        <CardHeader
          text="Next Task"
          icon="clipboard-check"
          badgeColor={badgeColor}
          badgeValue={badgeValue}
          disableSkeleton
        />
      }
    >
      <CardBody
        resource={nextTask}
        resourceLoaded={fetchTaskLoaded}
        resourceType="task"
        resourceDueDate={dateStr}
        resourceTitle={nextTask?.title}
        resourceActions={ACTIONS}
        handleResourceActions={handleTaskActions}
        contactId={currentContact.id}
      />
      {!isEmpty(taskActivity) && (
        <Fragment>
          <TaskEditModal
            activeItem={taskActivity}
            show={showEditModal}
            setShow={setShowEditModal}
          />
          <TaskCompleteModal
            task={taskActivity}
            show={showCompleteModal}
            setShow={setShowCompleteModal}
          />
          <TaskCancelModal
            task={taskActivity}
            show={showCancelModal}
            setShow={setShowCancelModal}
          />
        </Fragment>
      )}
    </Card>
  );
};

export default Tasks;
