import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useRouteMatch } from 'react-router-dom';
import { createLoadedSelector } from 'appState/selectors';
import {
  broadcastsFetch,
  broadcastsPageChange,
  broadcastsResetPageChange,
  resetIsLoaded
} from 'appState/actions/ActionCreators';
import { capitalize } from 'lib/utils/string';
import { scrollToTop } from 'lib/utils/scroll';
import newEmailBroadcastImage from 'images/broadcast/Email.svg';
import newSmsBroadcastImage from 'images/broadcast/SMS.svg';
import newBroadcastTemplateImage from 'images/broadcast/Template.svg';
import { CurrentAccountContext } from 'components/shared/context/CurrentAccountContext';
import NewBroadcastCard from 'components/BroadcastCenter/Broadcast/Index/NewBroadcastCard';
import useToggleHeaderFooter from 'components/shared/hooks/useToggleHeaderFooter';
import SentListItem from 'components/BroadcastCenter/Broadcast/Index/SentListItem';
import SendingListItem from 'components/BroadcastCenter/Broadcast/Index/SendingListItem';
import DraftListItem from 'components/BroadcastCenter/Broadcast/Index/DraftListItem';
import ScheduledListItem from 'components/BroadcastCenter/Broadcast/Index/ScheduledListItem';
import ErrorListItem from 'components/BroadcastCenter/Broadcast/Index/ErrorListItem';
import BroadcastMetricsModal from 'components/BroadcastCenter/Broadcast/Index/BroadcastMetricsModal';
import BroadcastPreviewModal from 'components/BroadcastCenter/Broadcast/BroadcastPreviewModal';
import EnableOldBroadcastCenterModal from 'components/BroadcastCenter/Broadcast/Index/EnableOldBroadcastCenterModal';
import Pagination from 'components/BroadcastCenter/shared/Pagination';
import SkeletonListItems from '../SkeletonListItems';

const BroadcastIndex = () => {
  const currentAccount = useContext(CurrentAccountContext);

  const loadedSelector = createLoadedSelector([
    'broadcastCenter/BROADCASTS_FETCH',
    'broadcastCenter/BROADCASTS_PAGE_CHANGE'
  ]);
  const structuredSelector = createStructuredSelector({
    broadcasts: state => state.broadcastCenter.broadcasts,
    broadcastCounts: state => state.broadcastCenter.broadcastCounts,
    totalPages: state => state.broadcastCenter.broadcastsTotalPages,
    isLoaded: state => loadedSelector(state)
  });

  const [searchValue, setSearchValue] = useState('');
  const [status, setStatus] = useState('new');
  const [selectedBroadcastId, setSelectedBroadcastId] = useState(0);

  const { broadcasts, broadcastCounts, totalPages, isLoaded } = useSelector(
    structuredSelector
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (status !== 'new') {
      dispatch(
        broadcastsFetch({
          query: searchValue,
          status
        })
      );
    }

    if (status === 'new') {
      dispatch(broadcastsResetPageChange());
    }
  }, [searchValue, status, dispatch]);

  const resetAllIsLoaded = () => {
    dispatch(resetIsLoaded('broadcastCenter/BROADCASTS_FETCH'));
    dispatch(resetIsLoaded('broadcastCenter/BROADCASTS_PAGE_CHANGE'));
  };

  const onTabClick = _status => {
    scrollToTop();
    resetAllIsLoaded();
    dispatch(broadcastsResetPageChange());
    setStatus(_status);
  };

  const pageChange = () => args => {
    scrollToTop();
    resetAllIsLoaded();
    if (searchValue)
      return dispatch(
        broadcastsPageChange({
          query: searchValue,
          status,
          ...args
        })
      );
    return dispatch(
      broadcastsPageChange({
        status,
        ...args
      })
    );
  };

  const { path } = useRouteMatch();
  useToggleHeaderFooter(path);

  function setBroadcast(broadcast) {
    setSelectedBroadcastId(broadcast.id);
  }

  return (
    <div>
      <header className="header mb-0">
        <div className="header-info mt-20 mb-20">
          <div className="left">
            <h2 className="header-title">
              {' '}
              <strong className="fw-800">Broadcast Center</strong>{' '}
              <span className="ti-signal" />
              <small className="subtitle mt-10 no-padding">
                Keep your leads and members engaged!
              </small>
            </h2>
          </div>
        </div>

        <div className="header-action">
          <nav className="nav nav-tabs nav-tabs-info ">
            <a
              className="nav-link text-capitalize active"
              data-toggle="tab"
              href="#new-tab"
              onClick={() => onTabClick('new')}
            >
              New
            </a>
            <a
              className="nav-link text-capitalize"
              data-toggle="tab"
              href="#sent-tab"
              onClick={() => onTabClick('sent')}
            >
              Sent
            </a>
            <a
              className="nav-link text-capitalize"
              data-toggle="tab"
              href="#draft-tab"
              onClick={() => onTabClick('draft')}
            >
              Draft
            </a>
            <a
              className="nav-link text-capitalize"
              data-toggle="tab"
              href="#scheduled-tab"
              onClick={() => onTabClick('scheduled')}
            >
              Scheduled
            </a>
            <a
              className="nav-link text-capitalize"
              data-toggle="tab"
              href="#error-tab"
              onClick={() => onTabClick('error')}
            >
              Error
            </a>
          </nav>
          {status !== 'new' && (
            <div className="lookup  lookup-right d-none d-lg-block">
              <input
                value={searchValue}
                type="text"
                name="s"
                id="broadcast-list-search"
                placeholder={`Search ${capitalize(status)}`}
                onChange={e => setSearchValue(e.target.value)}
              />
            </div>
          )}
        </div>
      </header>
      <div
        className="main-content container-fluid pt-0"
        style={{ maxWidth: '1400px', margin: '0 auto' }}
      >
        <div className="row">
          <div className="col-12">
            <div className="tab-content mt-15">
              <div
                className="tab-pane active pt-20"
                id="new-tab"
                role="tabpanel"
              >
                <div className="row">
                  <NewBroadcastCard
                    title="New Email Broadcast"
                    description="Customize emails to match your brand - Send or schedule for later, and tailor your email content to your audience so that you send the right message to the right people at the right time."
                    image={newEmailBroadcastImage}
                    templateType="email"
                  />
                  {currentAccount.sms_enabled && (
                    <NewBroadcastCard
                      title="New SMS Broadcast"
                      description="Write personalized SMS messages - Send or schedule for later, and tailor your SMS content to your audience so that you send the right message to the right people at the right time."
                      image={newSmsBroadcastImage}
                      templateType="sms"
                    />
                  )}
                  <NewBroadcastCard
                    title="New Broadcast Template"
                    description="Use the template builder to create a reusable starting point for your future Email or SMS Broadcasts. That way you save time when you're ready to send or schedule a new Broadcast."
                    image={newBroadcastTemplateImage}
                    templateType="template"
                  />
                </div>
                <div className="row">
                  <div className="col-12">
                    <div
                      className="float-right"
                      data-toggle="modal"
                      data-target="#enable-old-broacast-center-modal"
                    >
                      <small className="use-pointer">
                        Click here to revert to old Broadcast Center{' '}
                        <span role="img" aria-label="upside-down-smile-emoji">
                          🙃
                        </span>
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane pt-20" id="sent-tab" role="tabpanel">
                <div id="broadcast-sent-list-wrapper">
                  {isLoaded && broadcastCounts.sentAndSending === 0 && (
                    <div className="media align-items-center">
                      <h4>No Sent Broadcasts Found</h4>
                    </div>
                  )}
                  {isLoaded ? (
                    broadcasts.map(broadcast => {
                      return (
                        <div key={broadcast.id}>
                          {broadcast.status === 'sent' ? (
                            <SentListItem
                              broadcast={broadcast}
                              setSelectedBroadcast={setBroadcast}
                            />
                          ) : (
                            broadcast.status === 'sending' && (
                              <SendingListItem broadcast={broadcast} />
                            )
                          )}
                        </div>
                      );
                    })
                  ) : (
                    <SkeletonListItems count={10} />
                  )}
                </div>
              </div>
              <div className="tab-pane pt-20" id="draft-tab" role="tabpanel">
                <div id="broadcast-draft-list-wrapper">
                  {isLoaded && broadcastCounts.draft === 0 ? (
                    <div className="media align-items-center">
                      <h4>No Draft Broadcasts Found</h4>
                    </div>
                  ) : null}
                  {isLoaded ? (
                    broadcasts.map(broadcast => {
                      return (
                        <div key={broadcast.id}>
                          {broadcast.status === 'draft' && (
                            <DraftListItem broadcast={broadcast} />
                          )}
                        </div>
                      );
                    })
                  ) : (
                    <SkeletonListItems count={10} />
                  )}
                </div>
              </div>
              <div
                className="tab-pane pt-20"
                id="scheduled-tab"
                role="tabpanel"
              >
                <div id="broadcast-scheduled-list-wrapper">
                  {isLoaded && broadcastCounts.scheduled === 0 && (
                    <div className="media align-items-center">
                      <h4>No Scheduled Broadcasts Found</h4>
                    </div>
                  )}
                  {isLoaded ? (
                    broadcasts.map(broadcast => {
                      return (
                        <div key={broadcast.id}>
                          {broadcast.status === 'scheduled' && (
                            <ScheduledListItem broadcast={broadcast} />
                          )}
                        </div>
                      );
                    })
                  ) : (
                    <SkeletonListItems count={10} />
                  )}
                </div>
              </div>
              <div className="tab-pane pt-20" id="error-tab" role="tabpanel">
                <div id="broadcast-error-list-wrapper">
                  {isLoaded && broadcastCounts.error === 0 && (
                    <div className="media align-items-center">
                      <h4>No Broadcasts Containing Errors</h4>
                    </div>
                  )}
                  {isLoaded ? (
                    broadcasts.map(broadcast => {
                      return (
                        <div key={broadcast.id}>
                          {broadcast.status === 'error' && (
                            <ErrorListItem broadcast={broadcast} />
                          )}
                        </div>
                      );
                    })
                  ) : (
                    <SkeletonListItems count={10} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {totalPages && totalPages > 1 ? (
          <div className="d-flex justify-content-center">
            <Pagination totalPages={totalPages} action={pageChange()} />
          </div>
        ) : null}
      </div>

      {/* <!-- Start Modals --> */}

      <BroadcastPreviewModal
        selectedBroadcastId={selectedBroadcastId}
        key={selectedBroadcastId}
      />
      <EnableOldBroadcastCenterModal />

      <div
        className="modal fade show"
        id="edit-scheduled-broadcast-confirmation-modal"
        tabIndex="-1"
        aria-modal="true"
        aria-hidden="true"
        style={{ display: 'none' }}
      >
        <div className="modal-dialog modal-sm">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="myModalLabel">
                Edit Scheduled Broadcast?
              </h4>
              <button type="button" className="close" data-dismiss="modal">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <p>
                To ensure this email can be edited safely it will be unscheduled
                and marked as a draft. Make sure to complete the entire flow to
                reschedule your broadcast.
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                id="edit-scheduled-broadcast-confirmation-button"
              >
                Unschedule
              </button>
            </div>
          </div>
        </div>
      </div>

      <BroadcastMetricsModal />
    </div>
  );
};

export default BroadcastIndex;
