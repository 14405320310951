// linting rules disabled for immer js
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  FETCH_CONTACT_SUCCESS,
  FETCH_NEXT_CAMPAIGN_TOUCHPOINT_SUCCESS,
  FETCH_CONTACT_AUTOMATIONS_SUCCESS,
  FETCH_CONTACT_CUSTOM_GROUPS_SUCCESS,
  UPDATE_CONTACT_CUSTOM_GROUPS_SUCCESS,
  FETCH_CURRENT_CONTACT_CTA_SUCCESS,
  SET_CURRENT_CONTACT_TAB,
  FETCH_CURRENT_AUTOMATION_DETAILS_SUCCESS,
  PAUSE_AUTOMATION_SUCCESS,
  UNPAUSE_AUTOMATION_SUCCESS,
  UPDATE_CURRENT_AUTOMATION_COMPLETE_SUCCESS,
  NEW_AUTOMATION_LIFECYCLE_CHECK_SUCCESS,
  FETCH_ACCOUNT_CAMPAIGNS_SUCCESS,
  FETCH_CAMPAIGN_STEP_LIST_SUCCESS,
  CREATE_AUTOMATION_SUCCESS,
  UPDATE_CONTACT_SUCCESS,
  FETCH_CAMPAIGNS_LIST_SUCCESS,
  NEW_LIFECYCLE_CONFIRMATION_SUCCESS,
  UPDATE_CONTACT_LIFECYCLE_SUCCESS,
  CREATE_FILE_UPLOAD_SUCCESS,
  FETCH_APPOINTMENT_TYPES_SUCCESS,
  SET_CURRENT_APPOINTMENT_TYPE_ID,
  CREATE_CONTACT_NOTE_SUCCESS,
  FETCH_UPLOADED_FILES_LIST_SUCCESS,
  CREATE_EMAIL_SUCCESS,
  RESUBSCRIBE_CONTACT_SUCCESS,
  UNSUBSCRIBE_CONTACT_SUCCESS,
  REMOVE_SPAM_COMPLAINT_SUCCESS,
  FETCH_IMPORT_HISTORY_SUCCESS,
  VALIDATE_CONTACTS_CSV_SUCCESS,
  VALIDATE_SINGLE_CONTACT_CSV_SUCCESS,
  SAVE_CONTACTS_CSV_SUCCESS,
  CREATE_CSV_IMPORT_SUCCESS,
  UPDATE_CSV_IMPORT_SUCCESS,
  FETCH_CSV_IMPORT_SUCCESS,
  SET_CSV_VALIDATION_ERROR,
  FETCH_NEXT_TASK_REQUEST,
  FETCH_NEXT_APPOINTMENT_REQUEST,
  FETCH_NEXT_APPOINTMENT_SUCCESS,
  FETCH_NEXT_TASK_SUCCESS
} from 'appState/actions/constants/contact.actions';
import isEmpty from 'lodash.isempty';
import { reassignParamsFor } from 'lib/utils/contact-extensions';

const initialState = {
  currentContact: {},
  nextCampaignTouchpoint: {},
  automations: [],
  appointmentTypes: [],
  currentAppointmentTypeId: '',
  customGroups: [],
  currentContactCta: {},
  currentContactTab: 'feed',
  currentAutomationDetails: [],
  addAutomationConfirmationMessage: '',
  automationCampaigns: [],
  campaigns: [],
  newLifecycle: '',
  willChangeLifecycle: false,
  alreadyBeenInCampaign: false,
  productCampaignStepList: [],
  automationsToBeCompletedIds: [],
  newAutomation: {},
  newLifecycleConfirmationMessage: '',
  willBecomeClient: false,
  campaignToAdd: '',
  selectedLifecycleChangeCampaign: '',
  lastEmailSuccessMessage: '',
  importHistory: [],
  importedContact: [],
  csvImportId: null,
  mappedHeaders: {},
  fileData: [],
  headers: [],
  status: 'mapHeaders',
  filename: '',
  lifecycle: '',
  product: '',
  product_id: '',
  campaign: '',
  campaign_id: '',
  assignedStaffId: '',
  nextAppointment: {},
  nextTask: {}
};

export default function ContactReducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case FETCH_CONTACT_SUCCESS: {
        const { currentContact } = action.payload;
        draft.currentContact = reassignParamsFor(currentContact);
        return;
      }
      case FETCH_NEXT_CAMPAIGN_TOUCHPOINT_SUCCESS: {
        const { nextCampaignTouchpoint } = action.payload;
        draft.nextCampaignTouchpoint = nextCampaignTouchpoint;
        return;
      }
      case FETCH_CONTACT_AUTOMATIONS_SUCCESS: {
        const { automations } = action.payload;
        draft.automations = automations;
        return;
      }
      case FETCH_CONTACT_CUSTOM_GROUPS_SUCCESS: {
        const { customGroups } = action.payload;
        draft.customGroups = customGroups;
        return;
      }
      case UPDATE_CONTACT_CUSTOM_GROUPS_SUCCESS: {
        const { contactCustomGroups } = action.payload;
        draft.customGroups = contactCustomGroups;
        return;
      }
      case FETCH_CURRENT_CONTACT_CTA_SUCCESS: {
        const { currentContactCta } = action.payload;
        draft.currentContactCta = currentContactCta;
        return;
      }
      case SET_CURRENT_CONTACT_TAB: {
        const currentTab = action.payload;
        draft.currentContactTab = currentTab;
        return;
      }
      case FETCH_CURRENT_AUTOMATION_DETAILS_SUCCESS: {
        const { currentAutomationDetails } = action.payload;
        draft.currentAutomationDetails = currentAutomationDetails;
        return;
      }
      case PAUSE_AUTOMATION_SUCCESS: {
        const { automation } = action.payload;
        draft.automations = draft.automations.map(auto => {
          if (auto.id === automation.id) {
            return automation;
          }
          return auto;
        });
        return;
      }
      case UNPAUSE_AUTOMATION_SUCCESS: {
        const { automation } = action.payload;
        draft.automations = draft.automations.map(auto => {
          if (auto.id === automation.id) {
            return automation;
          }
          return auto;
        });
        return;
      }

      case UPDATE_CURRENT_AUTOMATION_COMPLETE_SUCCESS: {
        const { automationId } = action.payload;
        draft.automations = draft.automations.filter(
          a => a.id !== automationId
        );
        return;
      }

      case NEW_AUTOMATION_LIFECYCLE_CHECK_SUCCESS: {
        const {
          addAutomationConfirmationMessage,
          newLifecycle,
          willChangeLifecycle,
          alreadyBeenInCampaign,
          automationsToBeCompletedIds
        } = action.payload;
        draft.addAutomationConfirmationMessage = addAutomationConfirmationMessage;
        draft.newLifecycle = newLifecycle;
        draft.willChangeLifecycle = willChangeLifecycle;
        draft.alreadyBeenInCampaign = alreadyBeenInCampaign;
        draft.automationsToBeCompletedIds = automationsToBeCompletedIds;
        return;
      }
      case FETCH_ACCOUNT_CAMPAIGNS_SUCCESS: {
        draft.automationCampaigns = action.payload;
        return;
      }
      case FETCH_CAMPAIGN_STEP_LIST_SUCCESS: {
        draft.productCampaignStepList = action.payload;
        return;
      }
      case FETCH_APPOINTMENT_TYPES_SUCCESS: {
        draft.appointmentTypes = action.payload;
        return;
      }
      case FETCH_UPLOADED_FILES_LIST_SUCCESS: {
        draft.uploadedFiles = action.payload;
        return;
      }
      case CREATE_AUTOMATION_SUCCESS: {
        const { newAutomation, automationsToBeCompletedIds, newLifecycle } = action.payload;
        draft.currentContact = {
          ...draft.currentContact,
          currentLifecycle: newLifecycle
        };
        draft.automations = [
          ...draft.automations.filter(
            a => !automationsToBeCompletedIds.includes(a.id)
          ),
          newAutomation
        ];
        return;
      }
      case FETCH_CAMPAIGNS_LIST_SUCCESS: {
        const { campaigns } = action.payload;
        draft.campaigns = campaigns;
        return;
      }
      case NEW_LIFECYCLE_CONFIRMATION_SUCCESS: {
        const {
          newLifecycleConfirmationMessage,
          newLifecycle,
          willBecomeClient,
          alreadyBeenInCampaign,
          automationsToBeCompletedIds,
          campaignToAdd
        } = action.payload;
        draft.newLifecycleConfirmationMessage = newLifecycleConfirmationMessage;
        draft.newLifecycle = newLifecycle;
        draft.willBecomeClient = willBecomeClient;
        draft.alreadyBeenInCampaign = alreadyBeenInCampaign;
        draft.automationsToBeCompletedIds = automationsToBeCompletedIds;
        draft.campaignToAdd = campaignToAdd;
        return;
      }
      case UPDATE_CONTACT_LIFECYCLE_SUCCESS: {
        const {
          newAutomation,
          automationsToBeCompletedIds,
          newLifecycle
        } = action.payload;
        draft.newAutomation = newAutomation;
        draft.newLifecycle = newLifecycle;
        draft.alreadyBeenInCampaign = false;
        draft.selectedLifecycleChangeCampaign = '';
        const filteredAutomations = draft.automations.filter(
          a => !automationsToBeCompletedIds.includes(a.id)
        );
        if (!isEmpty(newAutomation)) {
          draft.automations = [...filteredAutomations, newAutomation];
        } else draft.automations = filteredAutomations;
        return;
      }
      case SET_CURRENT_APPOINTMENT_TYPE_ID: {
        draft.currentAppointmentTypeId = action.payload.id;
        return;
      }
      case CREATE_CONTACT_NOTE_SUCCESS: {
        return;
      }
      case UPDATE_CONTACT_SUCCESS: {
        const { contact } = action.payload;
        draft.currentContact = reassignParamsFor(contact);
        return;
      }
      case REMOVE_SPAM_COMPLAINT_SUCCESS: {
        const { contact } = action.payload;
        draft.currentContact = reassignParamsFor(contact);
        return;
      }
      case UNSUBSCRIBE_CONTACT_SUCCESS: {
        const { contact } = action.payload;
        draft.currentContact = reassignParamsFor(contact);
        return;
      }
      case RESUBSCRIBE_CONTACT_SUCCESS: {
        const { contact } = action.payload;
        draft.currentContact = reassignParamsFor(contact);
        return;
      }
      case CREATE_FILE_UPLOAD_SUCCESS: {
        return;
      }
      case CREATE_EMAIL_SUCCESS: {
        const { data } = action.payload;
        draft.lastEmailSuccessMessage = data.message;
        return;
      }
      case FETCH_IMPORT_HISTORY_SUCCESS: {
        const { importHistory } = action.payload;
        draft.importHistory = importHistory;
        return;
      }
      case VALIDATE_CONTACTS_CSV_SUCCESS: {
        const { importedContact } = action.payload;
        draft.importedContact = importedContact;
        return;
      }
      case VALIDATE_SINGLE_CONTACT_CSV_SUCCESS: {
        const { contact, activeIndex } = action.payload;
        draft.importedContact[activeIndex] = contact;
        return;
      }
      case SAVE_CONTACTS_CSV_SUCCESS: {
        const { csvImportData } = action.payload;
        draft.csvImportId = csvImportData?.id;
        return;
      }
      case CREATE_CSV_IMPORT_SUCCESS: {
        const { csvImportData } = action.payload;
        draft.csvImportId = csvImportData?.id;
        draft.mappedHeaders = csvImportData?.mapped_headers;
        draft.fileData = csvImportData?.file_data;
        draft.headers = csvImportData?.headers;
        draft.status = csvImportData?.status;
        draft.importedContact = csvImportData?.csv_rows;
        draft.filename = csvImportData?.filename;
        draft.lifecycle = csvImportData?.lifecycle;
        draft.product = csvImportData?.product;
        draft.product_id = csvImportData?.product_id;
        draft.campaign = csvImportData?.campaign;
        draft.campaign_id = csvImportData?.campaign_id;
        draft.assignedStaffId = csvImportData?.assigned_staff_id;
        draft.gdprCompliant = csvImportData?.gdpr_compliant;
        return;
      }
      case UPDATE_CSV_IMPORT_SUCCESS: {
        const { csvImportData } = action.payload;
        return;
      }
      case FETCH_CSV_IMPORT_SUCCESS: {
        const { csvImportData } = action.payload;
        draft.csvImportId = csvImportData?.id;
        draft.mappedHeaders = csvImportData?.mapped_headers;
        draft.fileData = csvImportData?.file_data;
        draft.headers = csvImportData?.headers;
        draft.status = csvImportData?.status;
        draft.importedContact = csvImportData?.csv_rows;
        draft.filename = csvImportData?.filename;
        draft.lifecycle = csvImportData?.lifecycle;
        draft.product = csvImportData?.product;
        draft.product_id = csvImportData?.product_id;
        draft.campaign = csvImportData?.campaign;
        draft.campaign_id = csvImportData?.campaign_id;
        draft.assignedStaffId = csvImportData?.assigned_staff_id;
        draft.gdprCompliant = csvImportData?.gdpr_compliant;
        return;
      }
      case SET_CSV_VALIDATION_ERROR: {
        const { activeIndex, updatedRow } = action.payload;
        draft.importedContact[activeIndex] = updatedRow;
        return;
      }
      case FETCH_NEXT_TASK_REQUEST: {
        draft.nextTask = {};
        return;
      }
      case FETCH_NEXT_APPOINTMENT_REQUEST: {
        draft.nextAppointment = {};
        return;
      }
      case FETCH_NEXT_APPOINTMENT_SUCCESS: {
        const { nextAppointment } = action.payload;
        draft.nextAppointment = nextAppointment;
        return;
      }
      case FETCH_NEXT_TASK_SUCCESS: {
        const { nextTask } = action.payload;
        draft.nextTask = nextTask;
        return;
      }
      default:
        return draft;
    }
  });
}
