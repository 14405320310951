// linting rules disabled for immer js
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import { ZP_STR, UP_STR, ZP_STATUS_SORT } from 'config/constants';
import {
  MATCHED_CONTACTS_FETCH_REQUEST,
  MATCHED_CONTACTS_FETCH_SUCCESS,
  SYNC_WIZARD_UPDATE_EXACT_MATCH_DATA,
  SYNC_WIZARD_UPDATE_NAME_MATCH_DATA,
  SYNC_WIZARD_UPDATE_EMAIL_MATCH_DATA,
  SYNC_WIZARD_UPDATE_CAMPAIGN_MATCH_DATA,
  SYNC_WIZARD_UPDATE_NEW_UPLAUNCH_DATA,
  SYNC_WIZARD_UPDATE_NEW_ZENPLANNER_DATA,
  SYNC_WIZARD_INITIALIZE_CAMPAIGN_DATA,
  SYNC_WIZARD_INITIALIZE_NEW_CONTACTS_DATA,
  SYNC_WIZARD_FETCH_TEST_LOG_REQUEST,
  SYNC_WIZARD_FETCH_TEST_LOG_SUCCESS,
  FETCH_SYNC_MODE_REQUEST,
  FETCH_SYNC_MODE_SUCCESS,
  SET_SYNC_WIZARD_ACCOUNT,
  FETCH_SYNC_STATUS_SUCCESS,
  STATS_FETCH_SUCCESS,
  LAST_SYNC_OUTPUT_LOG_SUCCESS,
  SET_SYNC_TO_REQUIRED_SUCCESS,
  MEMBERS_SYNC_SUCCESS,
  INITIALIZE_SYNC_WIZARD_SUCCESS,
  SWITCH_OFF_TEST_MODE_SUCCESS
} from 'appState/actions/constants/syncWizard.actions';

const initialState = {
  contacts: {
    areContactsReady: false,
    exactMatch: [],
    possibleMatchWithSameEmail: [],
    requiredCampaignChange: [],
    campaignMatch: [],
    possibleMatchWithSameName: [],
    newToUplaunch: [],
    newToZenPlanner: [],
    testMode: false,
    recordsCount: {
      alreadySynced: 0,
      zenPlanner: 0,
      upLaunch: 0,
      exactMatch: 0,
      possibleMatch: 0,
      newToUplaunch: 0,
      newToZenPlanner: 0
    }
  },
  logger: '',
  lastSyncOutputLog: '',
  syncingStatus: '',
  stats: {},
  currentAccount: {}
};

const lifecycle = {
  Lead: 'Prospect',
  Client: 'Member',
  'Former Client': 'Alumni',
  None: 'None'
};

const initializeCampaignMatchFromData = contacts => {
  const connectPossibleMatches = [];
  const campaignMatch = [];
  const {
    possibleMatchWithSameEmail,
    requiredCampaignChange
  } = contacts;

  requiredCampaignChange.forEach(contact => {
    const upContact = contact[0];
    if (upContact) {
      const zpContact = contact[1];
      const contactTuple = [];
      if (contacts.campaignMatch) {
        const campaignSelect = contacts.campaignMatch.find(element => {
          return element[1].id === zpContact.id;
        });
        if (campaignSelect && campaignSelect !== undefined) {
          contactTuple.push(upContact, campaignSelect[1]);
        } else {
          contactTuple.push(upContact, zpContact);
        }
      } else {
        contactTuple.push(upContact, zpContact);
      }
      campaignMatch.push(contactTuple);
    }
  });

  possibleMatchWithSameEmail.forEach(contact => {
    const upContact = contact[0];
    if (upContact) {
      const zpContact = contact.find(contactHash => {
        return contactHash.source === ZP_STR && contactHash.connect;
      });
      if (
        contacts.campaignMatch &&
        zpContact !== undefined &&
        zpContact.status !== lifecycle[upContact.status]
      ) {
        const campaignSelect = contacts.campaignMatch.find(element => {
          return element[1].id === zpContact.id;
        });
        if (campaignSelect && campaignSelect !== undefined) {
          campaignMatch.push([upContact, campaignSelect[1]]);
        } else {
          campaignMatch.push([upContact, zpContact]);
        }
      } else if (
        zpContact !== undefined &&
        zpContact.status !== lifecycle[upContact.status]
      ) {
        campaignMatch.push([upContact, zpContact]);
      }
    }
  });

  campaignMatch.sort(function(a, b) {
    return ZP_STATUS_SORT[a[1].status] - ZP_STATUS_SORT[b[1].status];
  });
  return { campaignMatch, connectPossibleMatches };
};

const initializeNewContactsData = contacts => {
  const newUplaunchContacts = contacts.newToUplaunchContacts
    ? [...contacts.newToUplaunchContacts]
    : [...contacts.newToUplaunch];
  const newZenPlannerContacts = contacts.newToZenPlannerContacts
    ? [...contacts.newToZenPlannerContacts]
    : [...contacts.newToZenPlanner];

  const { possibleMatchWithSameName } = contacts;
  possibleMatchWithSameName.forEach(contactRow => {
    contactRow.forEach(contact => {
      let zpIndex;
      let ulIndex;
      let shouldConnect;
      switch (contact.source) {
        case UP_STR:
          zpIndex = newZenPlannerContacts.findIndex(
            c =>
              c.email &&
              c.email.length &&
              c.email.length > 1 &&
              c.email === contact.email
          );
          if (zpIndex >= 0 && !contact.connect)
            newZenPlannerContacts.splice(zpIndex, 1);
          else if (zpIndex < 0 && contact.connect)
            newZenPlannerContacts.push(contact);
          break;

        case ZP_STR:
          ulIndex = newUplaunchContacts.findIndex(
            c =>
              c.email &&
              c.email.length &&
              c.email.length > 1 &&
              c.email === contact.email
          );
          shouldConnect = !(
            contact.connect ||
            contacts.campaignMatch.find(
              c =>
                c[1].email.length &&
                c[1].email.length > 1 &&
                c[1].email === contact.email
            )
          );
          if (ulIndex >= 0 && !shouldConnect)
            newUplaunchContacts.splice(ulIndex, 1);
          else if (ulIndex < 0 && shouldConnect)
            newUplaunchContacts.push(contact);
          break;

        default:
      }
    });
  });

  newUplaunchContacts.sort(function(a, b) {
    return ZP_STATUS_SORT[a.status] - ZP_STATUS_SORT[b.status];
  });
  return { newUplaunchContacts, newZenPlannerContacts };
};

export default function SyncWizardReducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case MATCHED_CONTACTS_FETCH_REQUEST: {
        const page =
          (action.payload && action.payload.page) || initialState.page;
        draft.page = page;
        return;
      }
      case MATCHED_CONTACTS_FETCH_SUCCESS: {
        const { contacts } = action.payload;
        draft.contacts = contacts;
        return;
      }
      case SYNC_WIZARD_UPDATE_EXACT_MATCH_DATA: {
        const { exactMatchData } = action.payload;
        draft.contacts.exactMatch = exactMatchData;
        return;
      }
      case SYNC_WIZARD_UPDATE_NAME_MATCH_DATA: {
        const { nameMatchData } = action.payload;
        draft.contacts.possibleMatchWithSameName = nameMatchData;
        return;
      }
      case SYNC_WIZARD_UPDATE_EMAIL_MATCH_DATA: {
        const { emailMatchData } = action.payload;
        draft.contacts.possibleMatchWithSameEmail = emailMatchData;
        return;
      }
      case SYNC_WIZARD_UPDATE_CAMPAIGN_MATCH_DATA: {
        const { matchCampaignData } = action.payload;
        draft.contacts.campaignMatch = matchCampaignData;
        return;
      }
      case SYNC_WIZARD_UPDATE_NEW_UPLAUNCH_DATA: {
        const { newToUplaunchData } = action.payload;
        draft.contacts.newToUplaunchContacts = newToUplaunchData;
        return;
      }
      case SYNC_WIZARD_UPDATE_NEW_ZENPLANNER_DATA: {
        const { newToZenPlannerData } = action.payload;
        draft.contacts.newToZenPlannerContacts = newToZenPlannerData;
        return;
      }
      case SYNC_WIZARD_INITIALIZE_CAMPAIGN_DATA: {
        const { contacts } = state;
        const filteredData = initializeCampaignMatchFromData(contacts);
        draft.contacts.campaignMatch = filteredData.campaignMatch;
        draft.contacts.connectPossibleMatches =
          filteredData.connectPossibleMatches;
        return;
      }
      case SYNC_WIZARD_INITIALIZE_NEW_CONTACTS_DATA: {
        const { contacts } = state;
        const filteredData = initializeNewContactsData(contacts);
        draft.contacts.newToUplaunchContacts = filteredData.newUplaunchContacts;
        draft.contacts.newToZenPlannerContacts =
          filteredData.newZenPlannerContacts;
        return;
      }
      case SYNC_WIZARD_FETCH_TEST_LOG_REQUEST: {
        const page = action.payload && action.payload.page;
        draft.page = page;
        return;
      }
      case SYNC_WIZARD_FETCH_TEST_LOG_SUCCESS: {
        const { logger } = action.payload;
        draft.logger = logger;
        return;
      }
      case LAST_SYNC_OUTPUT_LOG_SUCCESS: {
        const { lastSyncOutputLog, syncingStatus } = action.payload;

        draft.lastSyncOutputLog = lastSyncOutputLog;
        draft.syncingStatus = syncingStatus;
        return;
      }
      case FETCH_SYNC_MODE_REQUEST: {
        const page =
          (action.payload && action.payload.page) || initialState.page;
        draft.page = page;
        return;
      }
      case FETCH_SYNC_MODE_SUCCESS: {
        const { testMode } = action.payload;
        draft.testMode = testMode;
        return;
      }
      case MEMBERS_SYNC_SUCCESS:
      case INITIALIZE_SYNC_WIZARD_SUCCESS:
      case FETCH_SYNC_STATUS_SUCCESS: {
        const { syncStatus } = action.payload;
        draft.syncingStatus = syncStatus;
        return;
      }
      case STATS_FETCH_SUCCESS: {
        const { stats } = action.payload;
        stats.upLaunch = stats.uplaunch_contacts;
        stats.zenPlanner = stats.zen_planner_people;
        draft.stats = stats;
        return;
      }
      case SET_SYNC_WIZARD_ACCOUNT: {
        draft.currentAccount = action.payload;
        return;
      }
      case SET_SYNC_TO_REQUIRED_SUCCESS: {
        const { syncingStatus } = action.payload;
        draft.syncingStatus = syncingStatus;
        return;
      }
      case SWITCH_OFF_TEST_MODE_SUCCESS: {
        draft.testMode = false;
        return;
      }
      default:
        return draft;
    }
  });
}
