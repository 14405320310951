import React, { Fragment } from 'react';
import { withA11y } from '@storybook/addon-a11y';
import { withKnobs, select, boolean } from '@storybook/addon-knobs';
import Spinner from './index';

export default {
  component: Spinner,
  title: 'Spinner',
  decorators: [withA11y, withKnobs]
};

let colorSelect;

function includeColorSelect(defaultValue) {
  const label = 'Color';
  const options = [
    'alpha',
    'bravo',
    'charlie',
    'success',
    'warning',
    'error',
    'gray',
    'white'
  ];
  colorSelect = select(label, options, defaultValue);
}

let sizeSelect;

function includeSizeSelect(defaultValue) {
  const label = 'Size';
  const options = ['xs', 'sm', 'md', 'lg'];
  sizeSelect = select(label, options, defaultValue);
}

let tintSelect;

function includeTintSelect(defaultValue) {
  const label = 'Tint';
  const options = [
    '100',
    '200',
    '300',
    '400',
    '500',
    '600',
    '700',
    '800',
    '900',
    'base'
  ];
  tintSelect = select(label, options, defaultValue);
}

export const standard = () => {
  includeColorSelect('alpha');
  includeSizeSelect('md');
  includeTintSelect('base');
  return (
    <Spinner
      visible={boolean('visible', true)}
      size={sizeSelect}
      color={colorSelect}
      tint={tintSelect}
    />
  );
};

export const sizes = () => {
  includeColorSelect('alpha');
  includeSizeSelect('xs');
  includeTintSelect('base');
  return (
    <Fragment>
      <div className="tw-p-4 tw-inline-block">
        <Spinner
          visible={boolean('visible', true)}
          size={sizeSelect}
          color={colorSelect}
          tint={tintSelect}
        />
      </div>
      <div className="tw-p-4 tw-inline-block">
        <Spinner
          visible={boolean('visible', true)}
          size="sm"
          color={colorSelect}
          tint={tintSelect}
        />
      </div>
      <div className="tw-p-4 tw-inline-block">
        <Spinner
          visible={boolean('visible', true)}
          size="md"
          color={colorSelect}
          tint={tintSelect}
        />
      </div>
      <div className="tw-p-4 tw-inline-block">
        <Spinner
          visible={boolean('visible', true)}
          size="lg"
          color={colorSelect}
          tint={tintSelect}
        />
      </div>
    </Fragment>
  );
};

export const colors = () => {
  includeColorSelect('alpha');
  includeSizeSelect('md');
  includeTintSelect('base');
  return (
    <Fragment>
      <div className="tw-p-4 tw-inline-block">
        <Spinner
          visible={boolean('visible', true)}
          size={sizeSelect}
          color={colorSelect}
          tint={tintSelect}
        />
      </div>
      <div className="tw-p-4 tw-inline-block">
        <Spinner
          visible={boolean('visible', true)}
          size={sizeSelect}
          color="bravo"
          tint={tintSelect}
        />
      </div>
      <div className="tw-p-4 tw-inline-block">
        <Spinner
          visible={boolean('visible', true)}
          size={sizeSelect}
          color="charlie"
          tint={tintSelect}
        />
      </div>
      <div className="tw-p-4 tw-inline-block">
        <Spinner
          visible={boolean('visible', true)}
          size={sizeSelect}
          color="success"
          tint={tintSelect}
        />
      </div>
      <div className="tw-p-4 tw-inline-block">
        <Spinner
          visible={boolean('visible', true)}
          size={sizeSelect}
          color="warning"
          tint={tintSelect}
        />
      </div>
      <div className="tw-p-4 tw-inline-block">
        <Spinner
          visible={boolean('visible', true)}
          size={sizeSelect}
          color="error"
          tint={tintSelect}
        />
      </div>
      <div className="tw-p-4 tw-inline-block">
        <Spinner
          visible={boolean('visible', true)}
          size={sizeSelect}
          color="gray"
          tint="400"
        />
      </div>
    </Fragment>
  );
};
