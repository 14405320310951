import React from 'react';
import Skeleton from 'react-loading-skeleton';
import Styled from './SkeletonTemplateCard.styles';

const SkeletonTemplateCard = () => {
  return (
    <div className="col-md-12 col-lg-6 col-xxxl-4">
      <Styled.Card className="card">
        <header className="card-header">
          <h4 className="card-title mb-0 ml-15">
            <Skeleton width={300} height={20} />
          </h4>
        </header>
        <div className="card-content">
          <div className="card-body" style={{ overflowY: 'hidden' }}>
            <div className="d-flex">
              <div className="col-sm-7" style={{ height: '266px' }}>
                <Skeleton width={225} height={350} />
              </div>
              <div className="col-sm-5 d-none d-sm-block">
                <Skeleton count={5} />
                <div style={{ height: '65px' }} />
                <Skeleton count={3} />
              </div>
            </div>
          </div>
          <footer
            className="card-footer text-right pr-35"
            style={{ borderRadius: '0 0 10px 10px' }}
          >
            <Skeleton width={125} height={30} />
          </footer>
        </div>
      </Styled.Card>
    </div>
  );
};

export default SkeletonTemplateCard;
