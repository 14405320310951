import axios from 'axios';
import qs from 'qs';

const axiosDefault = axios.create();
const axiosMultipartForm = axios.create();

const axiosSetup = ({
  baseUrl,
  csrfToken,
  authentication_token: authenticationToken,
  accountId = null
}) => {
  // axiosDefault config
  axiosDefault.defaults.baseURL = baseUrl;
  axiosDefault.defaults.headers.common['Content-type'] = 'application/json';
  axiosDefault.defaults.headers.common['X-CSRF-Token'] = csrfToken;
  if (authenticationToken)
    axiosDefault.defaults.headers.common.Authorization = authenticationToken;
  if (accountId) {
    axiosDefault.interceptors.request.use(
      function(config) {
        config.params = config.params || {};
        config.params['account_id'] = accountId;
        return config;
      },
      function(error) {
        return Promise.reject(error);
      }
    );
  }
  // for GET calls pass JSON as params rather than data to be serialized to query params
  axiosDefault.defaults.paramsSerializer = params =>
    qs.stringify(params, {
      arrayFormat: 'brackets',
      encode: false
    });

  // axiosMultipartForm config
  // no Content-Type needed for multipart/form-data
  axiosMultipartForm.defaults.baseURL = baseUrl;
  axiosMultipartForm.defaults.headers.common['X-CSRF-Token'] = csrfToken;
  if (authenticationToken)
    axiosMultipartForm.defaults.headers.common.Authorization = authenticationToken;

  if (accountId) {
    axiosMultipartForm.interceptors.request.use(
      function(config) {
        config.params = config.params || {};
        config.params['account_id'] = accountId;
        return config;
      },
      function(error) {
        return Promise.reject(error);
      }
    );
  }

  axiosMultipartForm.defaults.paramsSerializer = params =>
    qs.stringify(params, {
      arrayFormat: 'brackets',
      encode: false
    });
};

export { axiosSetup, axiosDefault, axiosMultipartForm };
