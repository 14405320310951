import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Wizard from './Wizard';
import Logger from './Logger';
import Stats from './Stats';

const SyncWizard = () => {
  const { path } = useRouteMatch();

  return (
    <div>
      <Switch>
        <Route exact path={path} component={Wizard} />
        <Route path={`${path}/test-mode-logs`} component={Logger} />
        <Route path={`${path}/stats`} component={Stats} />
      </Switch>
    </div>
  );
};

export default SyncWizard;
