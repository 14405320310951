// linting rules disabled for immer js
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import { decorateActivity } from 'lib/services/activityFeedDecorator';
import {
  FETCH_ACTIVITIES_REQUEST,
  FETCH_ACTIVITIES_SUCCESS,
  ACTIVITIES_PAGE_CHANGE_REQUEST,
  ACTIVITIES_PAGE_CHANGE_SUCCESS,
  FETCH_SMS_ACTIVITIES_REQUEST,
  FETCH_SMS_ACTIVITIES_SUCCESS,
  SMS_ACTIVITIES_PAGE_CHANGE_REQUEST,
  SMS_ACTIVITIES_PAGE_CHANGE_SUCCESS,
  FETCH_EMAIL_ACTIVITIES_REQUEST,
  FETCH_EMAIL_ACTIVITIES_SUCCESS,
  EMAIL_ACTIVITIES_PAGE_CHANGE_REQUEST,
  EMAIL_ACTIVITIES_PAGE_CHANGE_SUCCESS,
  FETCH_APPOINTMENTS_ACTIVITIES_REQUEST,
  FETCH_APPOINTMENTS_ACTIVITIES_SUCCESS,
  APPOINTMENTS_ACTIVITIES_PAGE_CHANGE_REQUEST,
  APPOINTMENTS_ACTIVITIES_PAGE_CHANGE_SUCCESS,
  FETCH_TASKS_ACTIVITIES_REQUEST,
  FETCH_TASKS_ACTIVITIES_SUCCESS,
  TASKS_ACTIVITIES_PAGE_CHANGE_REQUEST,
  TASKS_ACTIVITIES_PAGE_CHANGE_SUCCESS,
  FETCH_NOTES_ACTIVITIES_REQUEST,
  FETCH_NOTES_ACTIVITIES_SUCCESS,
  NOTES_ACTIVITIES_PAGE_CHANGE_REQUEST,
  NOTES_ACTIVITIES_PAGE_CHANGE_SUCCESS,
  FETCH_FORMS_ACTIVITIES_REQUEST,
  FETCH_FORMS_ACTIVITIES_SUCCESS,
  FORMS_ACTIVITIES_PAGE_CHANGE_REQUEST,
  FORMS_ACTIVITIES_PAGE_CHANGE_SUCCESS,
  FETCH_PINNED_ACTIVITIES_SUCCESS,
  FETCH_PINNED_SMS_ACTIVITIES_SUCCESS,
  FETCH_PINNED_EMAIL_ACTIVITIES_SUCCESS,
  FETCH_PINNED_APPOINTMENTS_ACTIVITIES_SUCCESS,
  FETCH_PINNED_TASKS_ACTIVITIES_SUCCESS,
  FETCH_PINNED_NOTES_ACTIVITIES_SUCCESS,
  FETCH_PINNED_FORMS_ACTIVITIES_SUCCESS,
  UPDATE_ACTIVITY_SUCCESS,
  UPDATE_SMS_ACTIVITY_SUCCESS,
  UPDATE_EMAIL_ACTIVITY_SUCCESS,
  UPDATE_APPOINTMENTS_ACTIVITY_SUCCESS,
  UPDATE_TASKS_ACTIVITY_SUCCESS,
  UPDATE_NOTES_ACTIVITY_SUCCESS,
  UPDATE_FORMS_ACTIVITY_SUCCESS,
  FETCH_CONTACT_FORM_SUCCESS,
  UPDATE_ACTIVITIES_FEED
} from 'appState/actions/constants/activity.actions';

const LIMIT = 20;

const initialState = {
  activities: [],
  smsActivities: [],
  emailActivities: [],
  appointmentsActivities: [],
  tasksActivities: [],
  notesActivities: [],
  formsActivities: [],
  pinnedActivities: [],
  pinnedSmsActivities: [],
  pinnedEmailActivities: [],
  pinnedAppointmentsActivities: [],
  pinnedTasksActivities: [],
  pinnedNotesActivities: [],
  pinnedFormsActivities: [],
  page: 1,
  total: 0,
  isChangingPage: false,
  totalPages: 1,
  smsPage: 1,
  smsTotal: 0,
  smsIsChangingPage: false,
  smsTotalPages: 1,
  emailPage: 1,
  emailTotal: 0,
  emailIsChangingPage: false,
  emailTotalPages: 1,
  appointmentsPage: 1,
  appointmentsTotal: 0,
  appointmentsIsChangingPage: false,
  appointmentsTotalPages: 1,
  tasksPage: 1,
  tasksTotal: 0,
  tasksIsChangingPage: false,
  tasksTotalPages: 1,
  notesPage: 1,
  notesTotal: 0,
  notesIsChangingPage: false,
  notesTotalPages: 1,
  formsPage: 1,
  formsTotal: 0,
  formsIsChangingPage: false,
  formsTotalPages: 1,
  limit: LIMIT,
  currentForm: {}
};

function activityCompareFunction(a, b) {
  return new Date(b.timestamp) - new Date(a.timestamp);
}

export default function AcitivityReducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case FETCH_ACTIVITIES_REQUEST: {
        const page =
          (action.payload && action.payload.page) || initialState.page;
        draft.page = page;
        return;
      }
      case FETCH_ACTIVITIES_SUCCESS: {
        const { activities, page, total } = action.payload;
        const totalPages = Math.ceil(total / LIMIT);

        draft.activities = activities;
        draft.page = page;
        draft.total = total;
        draft.totalPages = totalPages;
        draft.limit = LIMIT;
        return;
      }
      case ACTIVITIES_PAGE_CHANGE_REQUEST: {
        const { page } = action.payload;
        draft.page = page;
        draft.isChangingPage = true;
        return;
      }
      case ACTIVITIES_PAGE_CHANGE_SUCCESS: {
        const { activities, total } = action.payload;

        draft.activities = activities;
        draft.total = total;
        draft.isChangingPage = false;
        return;
      }
      case FETCH_SMS_ACTIVITIES_REQUEST: {
        const smsPage =
          (action.payload && action.payload.smsPage) || initialState.smsPage;
        draft.smsPage = smsPage;
        return;
      }
      case FETCH_SMS_ACTIVITIES_SUCCESS: {
        const { smsActivities, smsPage, smsTotal } = action.payload;
        const totalPages = Math.ceil(smsTotal / LIMIT);

        draft.smsActivities = smsActivities;
        draft.smsPage = smsPage;
        draft.smsTotal = smsTotal;
        draft.smsTotalPages = totalPages;
        draft.limit = LIMIT;
        return;
      }
      case SMS_ACTIVITIES_PAGE_CHANGE_REQUEST: {
        const { smsPage } = action.payload;
        draft.smsPage = smsPage;
        draft.smsIsChangingPage = true;
        return;
      }
      case SMS_ACTIVITIES_PAGE_CHANGE_SUCCESS: {
        const { smsActivities, smsTotal } = action.payload;

        draft.smsActivities = smsActivities;
        draft.smsTotal = smsTotal;
        draft.smsIsChangingPage = false;
        return;
      }
      case FETCH_EMAIL_ACTIVITIES_REQUEST: {
        const emailPage =
          (action.payload && action.payload.emailPage) ||
          initialState.emailPage;
        draft.emailPage = emailPage;
        return;
      }
      case FETCH_EMAIL_ACTIVITIES_SUCCESS: {
        const { emailActivities, emailPage, emailTotal } = action.payload;
        const totalPages = Math.ceil(emailTotal / LIMIT);

        draft.emailActivities = emailActivities;
        draft.emailPage = emailPage;
        draft.emailTotal = emailTotal;
        draft.emailTotalPages = totalPages;
        draft.limit = LIMIT;
        return;
      }
      case EMAIL_ACTIVITIES_PAGE_CHANGE_REQUEST: {
        const { emailPage } = action.payload;
        draft.emailPage = emailPage;
        draft.emailIsChangingPage = true;
        return;
      }
      case EMAIL_ACTIVITIES_PAGE_CHANGE_SUCCESS: {
        const { emailActivities, emailTotal } = action.payload;

        draft.emailActivities = emailActivities;
        draft.emailTotal = emailTotal;
        draft.emailIsChangingPage = false;
        return;
      }
      case FETCH_APPOINTMENTS_ACTIVITIES_REQUEST: {
        const appointmentsPage =
          (action.payload && action.payload.appointmentsPage) ||
          initialState.appointmentsPage;
        draft.appointmentsPage = appointmentsPage;
        return;
      }
      case FETCH_APPOINTMENTS_ACTIVITIES_SUCCESS: {
        const {
          appointmentsActivities,
          appointmentsPage,
          appointmentsTotal
        } = action.payload;
        const totalPages = Math.ceil(appointmentsTotal / LIMIT);

        draft.appointmentsActivities = appointmentsActivities;
        draft.appointmentsPage = appointmentsPage;
        draft.appointmentsTotal = appointmentsTotal;
        draft.appointmentsTotalPages = totalPages;
        draft.limit = LIMIT;
        return;
      }
      case APPOINTMENTS_ACTIVITIES_PAGE_CHANGE_REQUEST: {
        const { appointmentsPage } = action.payload;
        draft.appointmentsPage = appointmentsPage;
        draft.appointmentsIsChangingPage = true;
        return;
      }
      case APPOINTMENTS_ACTIVITIES_PAGE_CHANGE_SUCCESS: {
        const { appointmentsActivities, appointmentsTotal } = action.payload;

        draft.appointmentsActivities = appointmentsActivities;
        draft.appointmentsTotal = appointmentsTotal;
        draft.appointmentsIsChangingPage = false;
        return;
      }
      case FETCH_TASKS_ACTIVITIES_REQUEST: {
        const tasksPage =
          (action.payload && action.payload.tasksPage) ||
          initialState.tasksPage;
        draft.tasksPage = tasksPage;
        return;
      }
      case FETCH_TASKS_ACTIVITIES_SUCCESS: {
        const { tasksActivities, tasksPage, tasksTotal } = action.payload;
        const totalPages = Math.ceil(tasksTotal / LIMIT);

        draft.tasksActivities = tasksActivities;
        draft.tasksPage = tasksPage;
        draft.tasksTotal = tasksTotal;
        draft.tasksTotalPages = totalPages;
        draft.limit = LIMIT;
        return;
      }
      case TASKS_ACTIVITIES_PAGE_CHANGE_REQUEST: {
        const { tasksPage } = action.payload;
        draft.tasksPage = tasksPage;
        draft.tasksIsChangingPage = true;
        return;
      }
      case TASKS_ACTIVITIES_PAGE_CHANGE_SUCCESS: {
        const { tasksActivities, tasksTotal } = action.payload;

        draft.tasksActivities = tasksActivities;
        draft.tasksTotal = tasksTotal;
        draft.tasksIsChangingPage = false;
        return;
      }
      case FETCH_NOTES_ACTIVITIES_REQUEST: {
        const notesPage =
          (action.payload && action.payload.notesPage) ||
          initialState.notesPage;
        draft.notesPage = notesPage;
        return;
      }
      case FETCH_NOTES_ACTIVITIES_SUCCESS: {
        const { notesActivities, notesPage, notesTotal } = action.payload;
        const totalPages = Math.ceil(notesTotal / LIMIT);

        draft.notesActivities = notesActivities;
        draft.notesPage = notesPage;
        draft.notesTotal = notesTotal;
        draft.notesTotalPages = totalPages;
        draft.limit = LIMIT;
        return;
      }
      case NOTES_ACTIVITIES_PAGE_CHANGE_REQUEST: {
        const { notesPage } = action.payload;
        draft.notesPage = notesPage;
        draft.notesIsChangingPage = true;
        return;
      }
      case NOTES_ACTIVITIES_PAGE_CHANGE_SUCCESS: {
        const { notesActivities, notesTotal } = action.payload;

        draft.notesActivities = notesActivities;
        draft.notesTotal = notesTotal;
        draft.notesIsChangingPage = false;
        return;
      }
      case FETCH_FORMS_ACTIVITIES_REQUEST: {
        const formsPage =
          (action.payload && action.payload.formsPage) ||
          initialState.formsPage;
        draft.formsPage = formsPage;
        return;
      }
      case FETCH_FORMS_ACTIVITIES_SUCCESS: {
        const { formsActivities, formsPage, formsTotal } = action.payload;
        const totalPages = Math.ceil(formsTotal / LIMIT);

        draft.formsActivities = formsActivities;
        draft.formsPage = formsPage;
        draft.formsTotal = formsTotal;
        draft.formsTotalPages = totalPages;
        draft.limit = LIMIT;
        return;
      }
      case FORMS_ACTIVITIES_PAGE_CHANGE_REQUEST: {
        const { formsPage } = action.payload;
        draft.formsPage = formsPage;
        draft.formsIsChangingPage = true;
        return;
      }
      case FORMS_ACTIVITIES_PAGE_CHANGE_SUCCESS: {
        const { formsActivities, formsTotal } = action.payload;

        draft.formsActivities = formsActivities;
        draft.formsTotal = formsTotal;
        draft.formsIsChangingPage = false;
        return;
      }
      case FETCH_PINNED_ACTIVITIES_SUCCESS: {
        const { pinnedActivities } = action.payload;
        draft.pinnedActivities = pinnedActivities;
        return;
      }
      case FETCH_PINNED_SMS_ACTIVITIES_SUCCESS: {
        const { pinnedSmsActivities } = action.payload;
        draft.pinnedSmsActivities = pinnedSmsActivities;
        return;
      }
      case FETCH_PINNED_EMAIL_ACTIVITIES_SUCCESS: {
        const { pinnedEmailActivities } = action.payload;
        draft.pinnedEmailActivities = pinnedEmailActivities;
        return;
      }
      case FETCH_PINNED_APPOINTMENTS_ACTIVITIES_SUCCESS: {
        const { pinnedAppointmentsActivities } = action.payload;
        draft.pinnedAppointmentsActivities = pinnedAppointmentsActivities;
        return;
      }
      case FETCH_PINNED_TASKS_ACTIVITIES_SUCCESS: {
        const { pinnedTasksActivities } = action.payload;
        draft.pinnedTasksActivities = pinnedTasksActivities;
        return;
      }
      case FETCH_PINNED_NOTES_ACTIVITIES_SUCCESS: {
        const { pinnedNotesActivities } = action.payload;
        draft.pinnedNotesActivities = pinnedNotesActivities;
        return;
      }
      case FETCH_PINNED_FORMS_ACTIVITIES_SUCCESS: {
        const { pinnedFormsActivities } = action.payload;
        draft.pinnedFormsActivities = pinnedFormsActivities;
        return;
      }
      case UPDATE_ACTIVITY_SUCCESS: {
        const { updatedActivity } = action.payload;

        if (updatedActivity.pinned) {
          draft.activities = draft.activities
            .map(a => {
              if (a.id === updatedActivity.id) {
                a.pinned = !a.pinned;
              }
              return a;
            })
            .filter(a => !a.pinned)
            .map((a, index) => {
              a.index = index;
              return a;
            });
          draft.pinnedActivities = [
            ...draft.pinnedActivities,
            updatedActivity
          ].sort(activityCompareFunction);
        } else {
          draft.activities = [...draft.activities, updatedActivity].sort(
            activityCompareFunction
          );
          draft.pinnedActivities = draft.pinnedActivities
            .map(a => {
              if (a.id === updatedActivity.id) {
                a.pinned = !a.pinned;
              }
              return a;
            })
            .filter(a => a.pinned)
            .map((a, index) => {
              a.index = index;
              return a;
            });
        }
        return;
      }
      case UPDATE_SMS_ACTIVITY_SUCCESS: {
        const { updatedSmsActivity } = action.payload;

        if (updatedSmsActivity.pinned) {
          draft.smsActivities = draft.smsActivities
            .map(a => {
              if (a.id === updatedSmsActivity.id) {
                a.pinned = !a.pinned;
              }
              return a;
            })
            .filter(a => !a.pinned)
            .map((a, index) => {
              a.index = index;
              return a;
            })
            .sort(activityCompareFunction);
          draft.pinnedSmsActivities = [
            ...draft.pinnedSmsActivities,
            updatedSmsActivity
          ].sort(activityCompareFunction);

          draft.activities = draft.activities
            .map(a => {
              if (a.id === updatedSmsActivity.id) {
                a.pinned = !a.pinned;
              }
              return a;
            })
            .filter(a => !a.pinned)
            .map((a, index) => {
              a.index = index;
              return a;
            });
          draft.pinnedActivities = [
            ...draft.pinnedActivities,
            updatedSmsActivity
          ];
        } else {
          draft.smsActivities = [
            ...draft.smsActivities,
            updatedSmsActivity
          ].sort(activityCompareFunction);
          draft.pinnedSmsActivities = draft.pinnedSmsActivities
            .map(a => {
              if (a.id === updatedSmsActivity.id) {
                a.pinned = !a.pinned;
              }
              return a;
            })
            .filter(a => a.pinned)
            .map((a, index) => {
              a.index = index;
              return a;
            })
            .sort(activityCompareFunction);

          draft.activities = [...draft.activities, updatedSmsActivity];
          draft.pinnedActivities = draft.pinnedActivities
            .map(a => {
              if (a.id === updatedSmsActivity.id) {
                a.pinned = !a.pinned;
              }
              return a;
            })
            .filter(a => a.pinned)
            .map((a, index) => {
              a.index = index;
              return a;
            });
        }
        return;
      }
      case UPDATE_EMAIL_ACTIVITY_SUCCESS: {
        const { updatedEmailActivity } = action.payload;

        if (updatedEmailActivity.pinned) {
          draft.emailActivities = draft.emailActivities
            .map(a => {
              if (a.id === updatedEmailActivity.id) {
                a.pinned = !a.pinned;
              }
              return a;
            })
            .filter(a => !a.pinned)
            .map((a, index) => {
              a.index = index;
              return a;
            })
            .sort(activityCompareFunction);
          draft.pinnedEmailActivities = [
            ...draft.pinnedEmailActivities,
            updatedEmailActivity
          ].sort(activityCompareFunction);

          draft.activities = draft.activities
            .map(a => {
              if (a.id === updatedEmailActivity.id) {
                a.pinned = !a.pinned;
              }
              return a;
            })
            .filter(a => !a.pinned)
            .map((a, index) => {
              a.index = index;
              return a;
            });
          draft.pinnedActivities = [
            ...draft.pinnedActivities,
            updatedEmailActivity
          ];
        } else {
          draft.emailActivities = [
            ...draft.emailActivities,
            updatedEmailActivity
          ].sort(activityCompareFunction);
          draft.pinnedEmailActivities = draft.pinnedEmailActivities
            .map(a => {
              if (a.id === updatedEmailActivity.id) {
                a.pinned = !a.pinned;
              }
              return a;
            })
            .filter(a => a.pinned)
            .map((a, index) => {
              a.index = index;
              return a;
            })
            .sort(activityCompareFunction);

          draft.activities = [...draft.activities, updatedEmailActivity];
          draft.pinnedActivities = draft.pinnedActivities
            .map(a => {
              if (a.id === updatedEmailActivity.id) {
                a.pinned = !a.pinned;
              }
              return a;
            })
            .filter(a => a.pinned)
            .map((a, index) => {
              a.index = index;
              return a;
            });
        }
        return;
      }
      case UPDATE_APPOINTMENTS_ACTIVITY_SUCCESS: {
        const { updatedAppointmentsActivity } = action.payload;

        if (updatedAppointmentsActivity.pinned) {
          draft.appointmentsActivities = draft.appointmentsActivities
            .map(a => {
              if (a.id === updatedAppointmentsActivity.id) {
                a.pinned = !a.pinned;
              }
              return a;
            })
            .filter(a => !a.pinned)
            .map((a, index) => {
              a.index = index;
              return a;
            })
            .sort(activityCompareFunction);
          draft.pinnedAppointmentsActivities = [
            ...draft.pinnedAppointmentsActivities,
            updatedAppointmentsActivity
          ].sort(activityCompareFunction);

          draft.activities = draft.activities
            .map(a => {
              if (a.id === updatedAppointmentsActivity.id) {
                a.pinned = !a.pinned;
              }
              return a;
            })
            .filter(a => !a.pinned)
            .map((a, index) => {
              a.index = index;
              return a;
            });
          draft.pinnedActivities = [
            ...draft.pinnedActivities,
            updatedAppointmentsActivity
          ];
        } else {
          draft.appointmentsActivities = [
            ...draft.appointmentsActivities,
            updatedAppointmentsActivity
          ].sort(activityCompareFunction);
          draft.pinnedAppointmentsActivities = draft.pinnedAppointmentsActivities
            .map(a => {
              if (a.id === updatedAppointmentsActivity.id) {
                a.pinned = !a.pinned;
              }
              return a;
            })
            .filter(a => a.pinned)
            .map((a, index) => {
              a.index = index;
              return a;
            })
            .sort(activityCompareFunction);

          draft.activities = [...draft.activities, updatedAppointmentsActivity];
          draft.pinnedActivities = draft.pinnedActivities
            .map(a => {
              if (a.id === updatedAppointmentsActivity.id) {
                a.pinned = !a.pinned;
              }
              return a;
            })
            .filter(a => a.pinned)
            .map((a, index) => {
              a.index = index;
              return a;
            });
        }
        return;
      }
      case UPDATE_TASKS_ACTIVITY_SUCCESS: {
        const { updatedTasksActivity } = action.payload;

        if (updatedTasksActivity.pinned) {
          draft.tasksActivities = draft.tasksActivities
            .map(a => {
              if (a.id === updatedTasksActivity.id) {
                a.pinned = !a.pinned;
              }
              return a;
            })
            .filter(a => !a.pinned)
            .map((a, index) => {
              a.index = index;
              return a;
            })
            .sort(activityCompareFunction);
          draft.pinnedTasksActivities = [
            ...draft.pinnedTasksActivities,
            updatedTasksActivity
          ].sort(activityCompareFunction);

          draft.activities = draft.activities
            .map(a => {
              if (a.id === updatedTasksActivity.id) {
                a.pinned = !a.pinned;
              }
              return a;
            })
            .filter(a => !a.pinned)
            .map((a, index) => {
              a.index = index;
              return a;
            });
          draft.pinnedActivities = [
            ...draft.pinnedActivities,
            updatedTasksActivity
          ];
        } else {
          draft.tasksActivities = [
            ...draft.tasksActivities,
            updatedTasksActivity
          ].sort(activityCompareFunction);
          draft.pinnedTasksActivities = draft.pinnedTasksActivities
            .map(a => {
              if (a.id === updatedTasksActivity.id) {
                a.pinned = !a.pinned;
              }
              return a;
            })
            .filter(a => a.pinned)
            .map((a, index) => {
              a.index = index;
              return a;
            })
            .sort(activityCompareFunction);

          draft.activities = [...draft.activities, updatedTasksActivity];
          draft.pinnedActivities = draft.pinnedActivities
            .map(a => {
              if (a.id === updatedTasksActivity.id) {
                a.pinned = !a.pinned;
              }
              return a;
            })
            .filter(a => a.pinned)
            .map((a, index) => {
              a.index = index;
              return a;
            });
        }
        return;
      }
      case UPDATE_NOTES_ACTIVITY_SUCCESS: {
        const { updatedNotesActivity } = action.payload;

        if (updatedNotesActivity.pinned) {
          draft.notesActivities = draft.notesActivities
            .map(a => {
              if (a.id === updatedNotesActivity.id) {
                a.pinned = !a.pinned;
              }
              return a;
            })
            .filter(a => !a.pinned)
            .map((a, index) => {
              a.index = index;
              return a;
            })
            .sort(activityCompareFunction);
          draft.pinnedNotesActivities = [
            ...draft.pinnedNotesActivities,
            updatedNotesActivity
          ].sort(activityCompareFunction);

          draft.activities = draft.activities
            .map(a => {
              if (a.id === updatedNotesActivity.id) {
                a.pinned = !a.pinned;
              }
              return a;
            })
            .filter(a => !a.pinned)
            .map((a, index) => {
              a.index = index;
              return a;
            });
          draft.pinnedActivities = [
            ...draft.pinnedActivities,
            updatedNotesActivity
          ];
        } else {
          draft.notesActivities = [
            ...draft.notesActivities,
            updatedNotesActivity
          ].sort(activityCompareFunction);
          draft.pinnedNotesActivities = draft.pinnedNotesActivities
            .map(a => {
              if (a.id === updatedNotesActivity.id) {
                a.pinned = !a.pinned;
              }
              return a;
            })
            .filter(a => a.pinned)
            .map((a, index) => {
              a.index = index;
              return a;
            })
            .sort(activityCompareFunction);

          draft.activities = [...draft.activities, updatedNotesActivity];
          draft.pinnedActivities = draft.pinnedActivities
            .map(a => {
              if (a.id === updatedNotesActivity.id) {
                a.pinned = !a.pinned;
              }
              return a;
            })
            .filter(a => a.pinned)
            .map((a, index) => {
              a.index = index;
              return a;
            });
        }
        return;
      }
      case UPDATE_FORMS_ACTIVITY_SUCCESS: {
        const { updatedFormsActivity } = action.payload;

        if (updatedFormsActivity.pinned) {
          draft.formsActivities = draft.formsActivities
            .map(a => {
              if (a.id === updatedFormsActivity.id) {
                a.pinned = !a.pinned;
              }
              return a;
            })
            .filter(a => !a.pinned)
            .map((a, index) => {
              a.index = index;
              return a;
            })
            .sort(activityCompareFunction);
          draft.pinnedFormsActivities = [
            ...draft.pinnedFormsActivities,
            updatedFormsActivity
          ].sort(activityCompareFunction);

          draft.activities = draft.activities
            .map(a => {
              if (a.id === updatedFormsActivity.id) {
                a.pinned = !a.pinned;
              }
              return a;
            })
            .filter(a => !a.pinned)
            .map((a, index) => {
              a.index = index;
              return a;
            });
          draft.pinnedActivities = [
            ...draft.pinnedActivities,
            updatedFormsActivity
          ];
        } else {
          draft.formsActivities = [
            ...draft.formsActivities,
            updatedFormsActivity
          ].sort(activityCompareFunction);
          draft.pinnedFormsActivities = draft.pinnedFormsActivities
            .map(a => {
              if (a.id === updatedFormsActivity.id) {
                a.pinned = !a.pinned;
              }
              return a;
            })
            .filter(a => a.pinned)
            .map((a, index) => {
              a.index = index;
              return a;
            })
            .sort(activityCompareFunction);

          draft.activities = [...draft.activities, updatedFormsActivity];
          draft.pinnedActivities = draft.pinnedActivities
            .map(a => {
              if (a.id === updatedFormsActivity.id) {
                a.pinned = !a.pinned;
              }
              return a;
            })
            .filter(a => a.pinned)
            .map((a, index) => {
              a.index = index;
              return a;
            });
        }
        return;
      }
      case FETCH_CONTACT_FORM_SUCCESS: {
        const { currentForm } = action.payload;
        draft.currentForm = currentForm;
        return;
      }
      case UPDATE_ACTIVITIES_FEED: {
        const decoratedActivity = decorateActivity(action.payload, draft.total);
        const { activities } = draft;

        if (activities.find(act => act.id === decoratedActivity.id)) return;

        draft.activities = [...activities, decoratedActivity].sort(
          activityCompareFunction
        );
        const total = parseInt(draft.total) + 1;
        const totalPages = Math.ceil(total / draft.limit);

        draft.total = total.toString();
        draft.totalPages = totalPages;
        return;
      }
      default:
        return draft;
    }
  });
}
