import isEmpty from 'lodash.isempty';

function buildFormData(formData, data, parentKey) {
  if (
    data &&
    typeof data === 'object' &&
    !(data instanceof Date) &&
    !(data instanceof File) &&
    !(data instanceof Blob)
  ) {
    // this is for multiselect when nothing is selected
    if ((data instanceof Array) && isEmpty(data)) {
      let pk = `${parentKey}[]`;
      buildFormData(formData, null, pk);
    }

    Object.keys(data).forEach(key => {
      let pk = key;
      if (data instanceof Array) {
        pk = parentKey ? `${parentKey}[]` : key;
      } else {
        pk = parentKey ? `${parentKey}[${key}]` : key;
      }
      buildFormData(formData, data[key], pk);
    });
  } else {
    const value = data == null ? '' : data;

    formData.append(parentKey, value);
  }
}

export default buildFormData;
