import React from 'react';
import PauseAllCampaigns from './PauseAllCampaigns';
import PhoneNumberChanger from './PhoneNumberChanger';
import PhoneNumberAssign from './PhoneNumberAssign';
import AdminChanger from './AdminChanger';
import SubdomainChanger from './SubdomainChanger';
import AccountDisabler from './AccountDisabler';
import RecaptchaDisabler from './RecaptchaDisabler';
import OnboardingDisabler from './OnboardingDisabler';
import SubscriptionDetail from './SubscriptionDetail';

const AccountUtilities = () => {
  return (
    <div className="tab-pane active" id="account" role="tabpanel">
      <div className="row">
        <div className="col-lg-6 pl-10">
          <AccountDisabler />
        </div>
        <div className="col-lg-6 pl-10">
          <PauseAllCampaigns />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 pl-10">
          <PhoneNumberAssign />
        </div>
        <div className="col-lg-6 pl-10">
          <PhoneNumberChanger />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 pl-10">
          <AdminChanger />
        </div>
        <div className="col-lg-6 pl-10">
          <SubdomainChanger />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 pl-10">
          <OnboardingDisabler />
        </div>
        <div className="col-lg-6 pl-10">
          <RecaptchaDisabler />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 pl-10">
          <SubscriptionDetail />
        </div>
      </div>
    </div>
  );
};

export default AccountUtilities;
