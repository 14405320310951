import React, { useState, useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  fetchMarketPlace,
  mySubscriptionFetch
} from 'appState/actions/ActionCreators';
import PageHeader from 'components/Theme/PageHeader';
import TextInput from 'components/Theme/TextInput';
import Card from 'components/Theme/Card';
import Badge from 'components/Theme/Badge';
import MyPlanAndProduct from '../MyPlanAndProduct';

const ProductList = () => {
  const structuredSelector = createStructuredSelector({
    productList: state => state.marketPlace.productList,
    featuredProductList: state => state.marketPlace.featuredProductList,
    mySubscription: state => state.subscription.mySubscription,
    myProducts: state => state.subscription.myProducts
  });

  const {
    productList,
    featuredProductList,
    mySubscription,
    myProducts
  } = useSelector(structuredSelector);

  const [searchValue, setSearchValue] = useState('');

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(
      fetchMarketPlace({
        query: searchValue
      })
    );
  }, [searchValue, dispatch]);

  useEffect(() => {
    dispatch(mySubscriptionFetch());
  }, [dispatch]);

  function viewProduct(product) {
    let categoryProducts = '';
    Object.keys(productList).forEach(category => {
      if (product.category === null || category === product.category)
        categoryProducts = category;
    });
    history.push(`/ui/marketplace/product/${product.id}`, {
      product,
      categoryList: categoryProducts
    });
  }

  function showAlreadyAddedProduct(product) {
    return myProducts.findIndex(p => p.id === product.id) > -1;
  }

  function createCard(product, keyHelper) {
    // TODO: add default image here
    const imageUrl = product.hero_image_url
      ? product.hero_image_url
      : 'https://ucarecdn.com/18692cee-646a-473a-aa6f-8c961389a289/';
    return (
      <Card
        key={`${keyHelper}-${product.id}`}
        color="alpha"
        containerClass="tw-bg-white tw-rounded-lg tw-my-5 tw-col-span-12 md:tw-col-span-4 lg:tw-col-span-3"
        customMarginContainer
        imageUrl={imageUrl}
        imageClass="tw-rounded-t-lg"
        headerClass="tw-mb-10"
        primaryAction={() => viewProduct(product)}
        clickable
        showBadge={showAlreadyAddedProduct(product)}
        badgeContent="Added"
      >
        <div className="tw-m-5">
          <div>
            {product.product_lifecycles ? (
              <div className="tw-my-2" key={product.product_lifecycles}>
                <Badge
                  shape="rectangle"
                  size="small"
                  value={product.product_lifecycles}
                  containerStyle={{
                    textTransform: 'uppercase'
                  }}
                />
              </div>
            ) : null}
            <h3 className="tw-font-body tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
              {product.name}
            </h3>
          </div>
          <p className="tw-font-body tw-mt-1 tw-mb-0 tw-text-sm tw-leading-5 tw-text-gray-500">
            {product.campaign_count}
          </p>
        </div>
      </Card>
    );
  }

  function productCards() {
    return Object.entries(productList).map(category => {
      return (
        <div className="tw-p-8" key={category[0]}>
          <div>
            <h3 className="tw-font-bold tw-inline-block">
              {category[0] ? category[0] : 'Without Category'}
              <span className="tw-text-alpha-500 tw-text-base">
                {` (${category[1].length})`}
              </span>
            </h3>
          </div>
          <div className="tw-grid tw-grid-cols-12 tw-gap-6">
            {category[1].map(product => createCard(product, category[0]))}
          </div>
        </div>
      );
    });
  }

  function featuredCards() {
    return (
      <div className="tw-p-8" key="featured">
        <h3 className="tw-font-bold">Featured</h3>
        <div className="tw-grid tw-grid-cols-12 tw-gap-6">
          {featuredProductList.map(product => createCard(product, 'featured'))}
        </div>
      </div>
    );
  }

  return (
    <div className="tw-overflow-hidden">
      <PageHeader
        title="Marketplace"
        backgroundColor="white"
        rightBar={
          <MyPlanAndProduct
            myProducts={myProducts}
            mySubscription={mySubscription}
          />
        }
      >
        <TextInput
          id="search-bar"
          value={searchValue}
          placeholder="Search Products"
          type="text"
          labelText="Search"
          hideLabel
          widthClass="tw-max-w-lg"
          onChange={e => setSearchValue(e.target.value)}
          LeadingIcon={() => (
            <svg
              className="tw-w-5 tw-h-5 tw-text-gray-400"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
          )}
        />
      </PageHeader>
      {featuredProductList.length ? featuredCards() : null}
      {productCards()}
    </div>
  );
};

export default ProductList;
