import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import SkeletonListItem from '../SkeletonListItem';

const SkeletonListItems = props => {
  const { count } = props;

  return (
    <Fragment>
      {[...Array(count).keys()].map(i => {
        return <SkeletonListItem key={i} />;
      })}
    </Fragment>
  );
};

SkeletonListItems.defaultProps = {
  count: 10
};

SkeletonListItems.propTypes = { count: PropTypes.number };

export default SkeletonListItems;
