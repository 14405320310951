import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import isEmpty from 'lodash.isempty';
import {
  createErrorMessageSelector,
  createLoadedSelector
} from 'appState/selectors';
import {
  disableAccount,
  enableAccount,
  resetIsLoaded
} from 'appState/actions/ActionCreators';
import { titleize } from 'lib/utils/string';
import UtilityCard from 'components/OverlordUtilities/shared/UtilityCard';
import { CurrentAccountContext } from 'components/shared/context/CurrentAccountContext';
import useProcessingActivity from 'components/shared/hooks/useProcessingActivity';
import useAccountDisabled from 'components/shared/hooks/useAccountDisabled';

const UTILITY_NAMES = ['account_disabler_enable', 'account_disabler_disable'];

const AccountDisabler = () => {
  const currentAccount = useContext(CurrentAccountContext);
  const processingActivity = useProcessingActivity(UTILITY_NAMES);
  const accountDisabled = useAccountDisabled();

  const errorSelector = createErrorMessageSelector([
    'overlord/ACCOUNT_DISABLER_DISABLE',
    'overlord/ACCOUNT_ENABLER_ENABLE'
  ]);
  const loadedSelector = createLoadedSelector([
    'overlord/ACCOUNT_DISABLER_DISABLE',
    'overlord/ACCOUNT_ENABLER_ENABLE'
  ]);
  const structuredSelector = createStructuredSelector({
    error: state => errorSelector(state),
    isLoaded: state => loadedSelector(state)
  });

  const { error, isLoaded } = useSelector(structuredSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoaded && isEmpty(error)) {
      app.toast(
        `Successfully ${accountDisabled ? 'enabled' : 'disabled'} ${titleize(
          currentAccount.name
        )} account`,
        {
          type: 'primary',
          icon: 'fa-floppy-o'
        }
      );
    }
    return () => {
      dispatch(
        resetIsLoaded(
          accountDisabled
            ? 'overlord/ACCOUNT_ENABLER_ENABLE'
            : 'overlord/ACCOUNT_DISABLER_DISABLE'
        )
      );
    };
  }, [accountDisabled, currentAccount.name, isLoaded, error, dispatch]);

  const getCardBody = () => {
    return <p>Account will be {accountDisabled ? 'enabled' : 'disabled'}.</p>;
  };

  const disableEnableAccount = () => {
    if (accountDisabled) return dispatch(enableAccount());
    return dispatch(disableAccount());
  };

  return (
    <UtilityCard
      title={accountDisabled ? 'Account Enabler' : 'Account Disabler'}
      subtitle="Use this tool to disable/enable an account."
      submitBtnText={accountDisabled ? 'Enable Account' : 'Disable Account'}
      submitBtnAction={disableEnableAccount}
      bodyComponent={getCardBody}
      processingActivity={processingActivity}
      error={error}
    />
  );
};

export default AccountDisabler;
