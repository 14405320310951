import { call, put, takeLatest } from 'redux-saga/effects';
import { axiosDefault } from 'lib/utils/axios-setup';
import {
  FETCH_CONTACT_FORM_TYPES_REQUEST,
  FETCH_CONTACT_FORM_TYPES_SUCCESS,
  FETCH_CONTACT_FORM_TYPES_FAILURE,
  FETCH_CONTACT_FORM_TYPE_REQUEST,
  FETCH_CONTACT_FORM_TYPE_SUCCESS,
  FETCH_CONTACT_FORM_TYPE_FAILURE
} from 'appState/actions/constants/contactFormType.actions';

import contactFormFieldDecorator from 'lib/services/contactFormFieldDecorator';

function* fetchContactFormTypes() {
  try {
    const response = yield call(axiosDefault.get, '/contact_form_types');

    const decoractedContactFormTypes = response.data.map(formType => {
      const formTypeClone = { ...formType };
      if (formTypeClone.fields) {
        formTypeClone.fields = contactFormFieldDecorator(formTypeClone.fields);
      }
      return formTypeClone;
    });

    yield put({
      type: FETCH_CONTACT_FORM_TYPES_SUCCESS,
      payload: {
        contactFormTypes: decoractedContactFormTypes
      }
    });
  } catch (e) {
    yield put({
      type: FETCH_CONTACT_FORM_TYPES_FAILURE,
      payload: { error: e },
      error: true
    });
  }
}

function* fetchContactFormType({ payload: { contactFormTypeId } }) {
  try {
    const response = yield call(
      axiosDefault.get,
      `/contact_form_types/${contactFormTypeId}`
    );

    yield put({
      type: FETCH_CONTACT_FORM_TYPE_SUCCESS,
      payload: {
        currentContactFormType: response.data
      }
    });
  } catch (e) {
    yield put({
      type: FETCH_CONTACT_FORM_TYPE_FAILURE,
      payload: { error: e },
      error: true
    });
  }
}

export function* contactFormTypesFetch() {
  yield takeLatest(FETCH_CONTACT_FORM_TYPES_REQUEST, fetchContactFormTypes);
}

export function* contactFormTypeFetch() {
  yield takeLatest(FETCH_CONTACT_FORM_TYPE_REQUEST, fetchContactFormType);
}
