import styled from 'styled-components';

const Card = styled.div`
  border-radius: 10px;
  box-shadow: 0 5px 10px rgba(154, 160, 185, 0.05),
    0 15px 40px rgba(166, 173, 201, 0.2);
  overflow-y: 'hidden';
`;

const Styled = {
  Card
};

export default Styled;
