import React, { Fragment } from 'react';
import { withA11y } from '@storybook/addon-a11y';
import { withKnobs, text, select, boolean } from '@storybook/addon-knobs';
import Button from './index';

export default {
  component: Button,
  title: 'Button',
  decorators: [withA11y, withKnobs]
};

let sizeSelect;
let typeSelect;
let colorSelect;

function includeSizeSelect(defaultValue) {
  const label = 'Size';
  const options = ['xs', 'sm', 'md', 'lg', 'xl'];
  sizeSelect = select(label, options, defaultValue);
}

function includeTypeSelect(defaultValue) {
  const label = 'Type';
  const options = ['primary', 'secondary', 'white'];
  typeSelect = select(label, options, defaultValue);
}

function includeColorSelect(defaultValue) {
  const label = 'Color';
  const options = [
    'alpha',
    'bravo',
    'charlie',
    'success',
    'warning',
    'error',
    'gray'
  ];
  colorSelect = select(label, options, defaultValue);
}

export const primary = () => {
  includeSizeSelect('xs');
  includeTypeSelect('primary');
  includeColorSelect('alpha');
  return (
    <Fragment>
      <Button
        text={text('Text', 'Create Account')}
        containerStyle={{ marginRight: '5px', marginBottom: '5px' }}
        type={typeSelect}
        size={sizeSelect}
        color={colorSelect}
        disabled={boolean('Disabled', false)}
        isFullWidth={boolean('Is Full Width?', false)}
        isLoaded={boolean('Is Loaded?', true)}
      />
      <Button
        text="Create Account"
        containerStyle={{ marginRight: '5px', marginBottom: '5px' }}
        size="sm"
      />
      <Button
        text="Create Account"
        containerStyle={{ marginRight: '5px', marginBottom: '5px' }}
      />
      <Button
        text="Create Account"
        containerStyle={{ marginRight: '5px', marginBottom: '5px' }}
        size="lg"
      />
      <Button
        text="Create Account"
        containerStyle={{ marginRight: '5px', marginBottom: '5px' }}
        size="xl"
      />
      <Button
        text="Create Account"
        containerStyle={{ marginRight: '5px', marginBottom: '5px' }}
        size="xl"
        isFullWidth
      />
    </Fragment>
  );
};

export const secondary = () => {
  includeSizeSelect('xs');
  includeTypeSelect('secondary');
  includeColorSelect('alpha');
  return (
    <Fragment>
      <Button
        text={text('Text', 'Create Account')}
        containerStyle={{ marginRight: '5px', marginBottom: '5px' }}
        type={typeSelect}
        size={sizeSelect}
        color={colorSelect}
        disabled={boolean('Disabled', false)}
        isFullWidth={boolean('Is Full Width?', false)}
        isLoaded={boolean('Is Loaded?', true)}
      />
      <Button
        text="Create Account"
        containerStyle={{ marginRight: '5px', marginBottom: '5px' }}
        type="secondary"
        size="sm"
      />
      <Button
        text="Create Account"
        containerStyle={{ marginRight: '5px', marginBottom: '5px' }}
        type="secondary"
      />
      <Button
        text="Create Account"
        containerStyle={{ marginRight: '5px', marginBottom: '5px' }}
        type="secondary"
        size="lg"
      />
      <Button
        text="Create Account"
        containerStyle={{ marginRight: '5px', marginBottom: '5px' }}
        type="secondary"
        size="xl"
      />
      <Button
        text="Create Account"
        containerStyle={{ marginRight: '5px', marginBottom: '5px' }}
        type="secondary"
        size="xl"
        isFullWidth
      />
    </Fragment>
  );
};

export const white = () => {
  includeSizeSelect('xs');
  includeTypeSelect('white');
  includeColorSelect('alpha');
  return (
    <Fragment>
      <Button
        text={text('Text', 'Create Account')}
        containerStyle={{ marginRight: '5px', marginBottom: '5px' }}
        type={typeSelect}
        size={sizeSelect}
        color={colorSelect}
        disabled={boolean('Disabled', false)}
        isFullWidth={boolean('Is Full Width?', false)}
        isLoaded={boolean('Is Loaded?', true)}
      />
      <Button
        text="Create Account"
        containerStyle={{ marginRight: '5px', marginBottom: '5px' }}
        type="white"
        size="sm"
      />
      <Button
        text="Create Account"
        containerStyle={{ marginRight: '5px', marginBottom: '5px' }}
        type="white"
      />
      <Button
        text="Create Account"
        containerStyle={{ marginRight: '5px', marginBottom: '5px' }}
        type="white"
        size="lg"
      />
      <Button
        text="Create Account"
        containerStyle={{ marginRight: '5px', marginBottom: '5px' }}
        type="white"
        size="xl"
      />
      <Button
        text="Create Account"
        containerStyle={{ marginRight: '5px', marginBottom: '5px' }}
        type="white"
        size="xl"
        isFullWidth
      />
    </Fragment>
  );
};

export const disabled = () => {
  includeSizeSelect('xs');
  includeTypeSelect('primary');
  includeColorSelect('alpha');
  return (
    <Fragment>
      <Button
        text={text('Text', 'Create Account')}
        containerStyle={{ marginRight: '5px', marginBottom: '5px' }}
        type={typeSelect}
        size={sizeSelect}
        color={colorSelect}
        disabled={boolean('Disabled', true)}
        isFullWidth={boolean('Is Full Width?', false)}
        isLoaded={boolean('Is Loaded?', true)}
      />
      <Button
        text="Create Account"
        containerStyle={{ marginRight: '5px', marginBottom: '5px' }}
        disabled
        size="sm"
      />
      <Button
        text="Create Account"
        containerStyle={{ marginRight: '5px', marginBottom: '5px' }}
        disabled
      />
      <Button
        text="Create Account"
        containerStyle={{ marginRight: '5px', marginBottom: '5px' }}
        disabled
        size="lg"
      />
      <Button
        text="Create Account"
        containerStyle={{ marginRight: '5px', marginBottom: '5px' }}
        disabled
        size="xl"
      />
      <Button
        text="Create Account"
        containerStyle={{ marginRight: '5px', marginBottom: '5px' }}
        disabled
        size="xl"
        isFullWidth
      />
    </Fragment>
  );
};
