import { call, put, select, takeLatest } from 'redux-saga/effects';
import { axiosDefault, axiosMultipartForm } from 'lib/utils/axios-setup';
import get from 'lodash.get';
import isEmpty from 'lodash.isempty';
import { takeEveryDeduplicate, waitFor } from 'lib/utils/redux-saga-extensions';
import {
  isDummyContact,
  sendDummySms
} from 'lib/services/dummyContactResponses';
import {
  SMS_INBOX_FETCH_REQUEST,
  SMS_INBOX_FETCH_SUCCESS,
  SMS_INBOX_FETCH_FAILURE,
  SMS_INBOX_MARK_AS_READ_REQUEST,
  SMS_INBOX_MARK_AS_READ_SUCCESS,
  SMS_INBOX_MARK_AS_READ_FAILURE,
  SMS_INBOX_PAGE_CHANGE_REQUEST,
  SMS_INBOX_PAGE_CHANGE_SUCCESS,
  SMS_INBOX_FETCH_CONVERSATION_REQUEST,
  SMS_INBOX_FETCH_CONVERSATION_SUCCESS,
  SMS_INBOX_FETCH_CONVERSATION_FAILURE,
  SMS_INBOX_SEND_MESSAGE_REQUEST,
  SMS_INBOX_SEND_MESSAGE_SUCCESS,
  SMS_INBOX_SEND_MESSAGE_FAILURE,
  SMS_INBOX_CURRENT_CONVERSATION_ADD_MESSAGE,
  SMS_INBOX_MESSAGE_LIST_ITEM_LAST_MESSAGE_UPDATE,
  SMS_INBOX_CONVERSATION_PAGE_CHANGE_REQUEST,
  SMS_INBOX_CONVERSATION_PAGE_CHANGE_SUCCESS
} from 'appState/actions/constants/smsInbox.actions';
import {
  decorateMessage,
  decorateMessages
} from 'lib/services/messageDecorator';

const LIMIT = 30;

function* fetchSmsInbox({
  payload: {
    page = 1,
    per = LIMIT,
    query = '',
    assignedStaffId = -1,
    unread = false
  } = {}
}) {
  try {
    const { isChangingPage } = yield select(state => state.smsInbox);

    const params = { page, per, unread };

    if (query && query.trim() !== '') {
      params.q = query;
    }

    if (assignedStaffId >= 0) {
      params.assigned_staff_id = assignedStaffId;
    }

    const response = yield call(axiosDefault.get, '/sms_inbox', { params });
    const total = get(response, 'headers["total-count"]');

    yield put({
      type: isChangingPage
        ? SMS_INBOX_PAGE_CHANGE_SUCCESS
        : SMS_INBOX_FETCH_SUCCESS,
      payload: {
        conversations: response.data,
        page,
        per,
        total,
        assignedStaffId
      }
    });
  } catch (e) {
    yield put({
      type: SMS_INBOX_FETCH_FAILURE,
      payload: e,
      error: true
    });
  }
}

function* fetchSmsInboxConversation({
  payload: { contactId, page = 1, per = LIMIT }
}) {
  try {
    const { conversationIsChangingPage } = yield select(
      state => state.smsInbox
    );

    const params = { page, per };
    const response = yield call(
      axiosDefault.get,
      `/contacts/${contactId}/sms`,
      { params }
    );
    const total = get(response, 'headers["total-count"]');

    const decoratedConversationData = decorateMessages(response.data);

    yield put({
      type: conversationIsChangingPage
        ? SMS_INBOX_CONVERSATION_PAGE_CHANGE_SUCCESS
        : SMS_INBOX_FETCH_CONVERSATION_SUCCESS,
      payload: {
        currentConversation: decoratedConversationData,
        conversationPage: page,
        conversationPer: per,
        conversationTotal: total
      }
    });
  } catch (e) {
    yield put({
      type: SMS_INBOX_FETCH_CONVERSATION_FAILURE,
      payload: e,
      error: true
    });
  }
}

function* sendSmsMessage({
  payload: {
    message = '',
    contactId,
    currentUserId,
    file = null,
    removeTempMedia
  }
}) {
  try {
    let response;

    const isDummy = yield call(isDummyContact);

    if (file) {
      const formData = new FormData();
      formData.append(
        'temp_media',
        new Blob([file], { type: file.type }),
        file.name
      );
      formData.append('user_id', currentUserId);
      formData.append('body', message);
      formData.append('remove_temp_media', removeTempMedia);

      if (isDummy) return yield call(sendDummySms, formData);
      response = yield call(
        axiosMultipartForm.post,
        `/contacts/${contactId}/sms/`,
        formData
      );
    } else {
      const formData = {
        sms: {
          body: message,
          user_id: currentUserId,
          remove_temp_media: removeTempMedia
        }
      };

      if (isDummy) return yield call(sendDummySms, formData);
      response = yield call(
        axiosDefault.post,
        `/contacts/${contactId}/sms/`,
        formData
      );
    }

    const decoratedMessage = decorateMessage(response.data);

    yield put({
      type: SMS_INBOX_SEND_MESSAGE_SUCCESS,
      payload: {}
    });
    yield put({
      type: SMS_INBOX_CURRENT_CONVERSATION_ADD_MESSAGE,
      payload: { sms: decoratedMessage }
    });
    yield put({
      type: SMS_INBOX_MESSAGE_LIST_ITEM_LAST_MESSAGE_UPDATE,
      payload: { sms: decoratedMessage }
    });
  } catch (e) {
    yield put({
      type: SMS_INBOX_SEND_MESSAGE_FAILURE,
      payload: e,
      error: true
    });
  }
}

function* markSmsConversationAsRead({ payload: { contactId } }) {
  try {
    yield call(axiosDefault.put, `/contacts/${contactId}/sms/mark_as_read`);

    yield put({
      type: SMS_INBOX_MARK_AS_READ_SUCCESS,
      payload: { contactId }
    });
  } catch (e) {
    yield put({
      type: SMS_INBOX_MARK_AS_READ_FAILURE,
      payload: e,
      error: true
    });
  }
}

export function* smsInboxFetch() {
  yield takeLatest(SMS_INBOX_FETCH_REQUEST, fetchSmsInbox);
}

export function* smsInboxPageChange() {
  yield takeEveryDeduplicate(SMS_INBOX_PAGE_CHANGE_REQUEST, fetchSmsInbox);
}

export function* smsInboxMarkAsRead() {
  yield takeLatest(SMS_INBOX_MARK_AS_READ_REQUEST, markSmsConversationAsRead);
}

export function* smsInboxFetchConversation() {
  yield takeLatest(
    SMS_INBOX_FETCH_CONVERSATION_REQUEST,
    fetchSmsInboxConversation
  );
}

export function* smsInboxConversationPageChange() {
  yield takeEveryDeduplicate(
    SMS_INBOX_CONVERSATION_PAGE_CHANGE_REQUEST,
    fetchSmsInboxConversation
  );
}

export function* sendMessageSmsInbox() {
  yield takeLatest(SMS_INBOX_SEND_MESSAGE_REQUEST, sendSmsMessage);
}
