import { call, put, takeLatest } from 'redux-saga/effects';
import { axiosDefault } from 'lib/utils/axios-setup';
import {
  PURCHASE_PAGE_CREATE_SUBSCRIPTION_REQUEST,
  PURCHASE_PAGE_CREATE_SUBSCRIPTION_SUCCESS,
  PURCHASE_PAGE_CREATE_SUBSCRIPTION_FAILURE,
  PURCHASE_PAGE_FETCH_PRODUCT_PLAN_INFO_REQUEST,
  PURCHASE_PAGE_FETCH_PRODUCT_PLAN_INFO_SUCCESS,
  PURCHASE_PAGE_FETCH_PRODUCT_PLAN_INFO_FAILURE
} from 'appState/actions/constants/purchase.actions';

function* createPurchaseSubscription({
  payload: {
    token,
    planId,
    accountName,
    accountType,
    subdomain,
    firstName,
    lastName,
    email,
    phone,
    password,
    passwordConfirmation,
    coupon,
    planOverride,
    onboardingFeeUuid,
    productId,
    subscriptionIdempotencyKey,
    onboardingIdempotencyKey,
    customerIdempotencyKey
  }
}) {
  try {
    const params = {
      type: accountType,
      subscription: {
        token,
        plan_id: planId,
        coupon,
        plan_override: planOverride,
        onboarding_fee_uuid: onboardingFeeUuid,
        product_id: productId,
        subscription_idempotency_key: subscriptionIdempotencyKey,
        onboarding_idempotency_key: onboardingIdempotencyKey,
        customer_idempotency_key: customerIdempotencyKey,
        account_attributes: {
          name: accountName,
          subdomain,
          new_contact_record: true,
          admin_attributes: {
            first_name: firstName,
            last_name: lastName,
            email,
            phone,
            password,
            password_confirmation: passwordConfirmation
          }
        }
      }
    };

    const response = yield call(axiosDefault.post, '/subscriptions', params);

    yield put({
      type: PURCHASE_PAGE_CREATE_SUBSCRIPTION_SUCCESS,
      payload: {
        subscription: response.data
      }
    });

    $FPROM.trackSignup({
      email,
      uid: response.data.stripe_customer_id
    });
  } catch (e) {
    yield put({
      type: PURCHASE_PAGE_CREATE_SUBSCRIPTION_FAILURE,
      payload: {
        error: e
      },
      error: true
    });
  }
}

function* fetchProductPlanInfo({
  payload: {
    planUuid,
    productId = null,
    accountType = null,
    onboardingFee = null,
    planOverride = null
  }
}) {
  try {
    const params = {};

    if (productId) params.product_id = productId;
    if (onboardingFee) params.onboarding_fee = onboardingFee;
    if (planOverride) params.plan_override = planOverride;
    if (planUuid) params.plan_uuid = planUuid;
    if (accountType) params.type = accountType;

    const response = yield call(axiosDefault.get, '/purchase_pages', {
      params
    });

    yield put({
      type: PURCHASE_PAGE_FETCH_PRODUCT_PLAN_INFO_SUCCESS,
      payload: {
        purchasePage: response.data
      }
    });
  } catch (e) {
    yield put({
      type: PURCHASE_PAGE_FETCH_PRODUCT_PLAN_INFO_FAILURE,
      payload: {
        error: e
      },
      error: true
    });
  }
}

export function* purchasePageCreateSubscription() {
  yield takeLatest(
    PURCHASE_PAGE_CREATE_SUBSCRIPTION_REQUEST,
    createPurchaseSubscription
  );
}

export function* purchasePageFetchProductPlanInfo() {
  yield takeLatest(
    PURCHASE_PAGE_FETCH_PRODUCT_PLAN_INFO_REQUEST,
    fetchProductPlanInfo
  );
}
