import { call, put, takeLatest } from 'redux-saga/effects';
import { axiosDefault } from 'lib/utils/axios-setup';
import {
  FETCH_MY_PRODUCT_SUBSCRIPTIONS_REQUEST,
  FETCH_MY_PRODUCT_SUBSCRIPTIONS_SUCCESS,
  FETCH_MY_PRODUCT_SUBSCRIPTIONS_FAILURE,
  FETCH_PRODUCT_SUBSCRIPTION_REQUEST,
  FETCH_PRODUCT_SUBSCRIPTION_SUCCESS,
  FETCH_PRODUCT_SUBSCRIPTION_FAILURE,
  UPDATE_PRODUCT_SUBSCRIPTION_SUCCESS,
  UPDATE_PRODUCT_SUBSCRIPTION_REQUEST,
  UPDATE_PRODUCT_SUBSCRIPTION_FAILURE,
  UNPAUSE_PRODUCT_SUBSCRIPTION_SUCCESS,
  UNPAUSE_PRODUCT_SUBSCRIPTION_REQUEST,
  UNPAUSE_PRODUCT_SUBSCRIPTION_FAILURE,
  PAUSE_PRODUCT_SUBSCRIPTION_SUCCESS,
  PAUSE_PRODUCT_SUBSCRIPTION_REQUEST,
  PAUSE_PRODUCT_SUBSCRIPTION_FAILURE,
  REMOVE_PRODUCT_SUBSCRIPTION_SUCCESS,
  REMOVE_PRODUCT_SUBSCRIPTION_REQUEST,
  REMOVE_PRODUCT_SUBSCRIPTION_FAILURE,
  CREATE_PRODUCT_SUBSCRIPTION_REQUEST,
  CREATE_PRODUCT_SUBSCRIPTION_SUCCESS,
  CREATE_PRODUCT_SUBSCRIPTION_FAILURE,
  FETCH_ADD_CONTACTS_FILTERS_REQUEST,
  FETCH_ADD_CONTACTS_FILTERS_SUCCESS,
  FETCH_ADD_CONTACTS_FILTERS_FAILURE,
  ADD_CONTACTS_TO_PRODUCT_REQUEST,
  ADD_CONTACTS_TO_PRODUCT_SUCCESS,
  ADD_CONTACTS_TO_PRODUCT_FAILURE,
  VIEW_CONTACTS_IN_PRODUCT_CAMPAIGN_REQUEST,
  VIEW_CONTACTS_IN_PRODUCT_CAMPAIGN_SUCCESS,
  VIEW_CONTACTS_IN_PRODUCT_CAMPAIGN_FAILURE,
  FETCH_CONTACTS_COUNT_REQUEST,
  FETCH_CONTACTS_COUNT_SUCCESS,
  FETCH_CONTACTS_COUNT_FAILURE
} from 'appState/actions/constants/productSubscription.actions';
import get from 'lodash.get';

function* fetchProductSubscription({
  payload: { productSubscriptionId, currentAccountId }
}) {
  try {
    const response = yield call(
      axiosDefault.get,
      `/product_subscriptions/${productSubscriptionId}?account_id=${currentAccountId}`
    );

    yield put({
      type: FETCH_PRODUCT_SUBSCRIPTION_SUCCESS,
      payload: {
        currentProductSubscription: response.data
      }
    });
  } catch (e) {
    yield put({
      type: FETCH_PRODUCT_SUBSCRIPTION_FAILURE,
      payload: {
        error: e
      },
      error: true
    });
  }
}

function* fetchMyProductSubscriptions({ payload: { accountId } }) {
  try {
    const response = yield call(
      axiosDefault.get,
      `/product_subscriptions?account_id=${accountId}`
    );

    yield put({
      type: FETCH_MY_PRODUCT_SUBSCRIPTIONS_SUCCESS,
      payload: {
        myProductSubscriptions: response.data
      }
    });
  } catch (e) {
    yield put({
      type: FETCH_MY_PRODUCT_SUBSCRIPTIONS_FAILURE,
      payload: {
        error: e
      }
    });
  }
}

function* updateProductSubscription({
  payload: { productSubscriptionId, currentAccountId, params }
}) {
  try {
    const updateParams = { product_subscription: params };

    const response = yield call(
      axiosDefault.put,
      `/product_subscriptions/${productSubscriptionId}?account_id=${currentAccountId}`,
      updateParams
    );

    yield put({
      type: UPDATE_PRODUCT_SUBSCRIPTION_SUCCESS,
      payload: {
        currentProductSubscription: response.data
      }
    });
  } catch (e) {
    yield put({
      type: UPDATE_PRODUCT_SUBSCRIPTION_FAILURE,
      payload: {
        error: e
      },
      error: true
    });
  }
}

function* pauseProductSubscription({
  payload: { productSubscriptionId, accountId, pauseOption, xDays, untilXDate }
}) {
  let pauseOptionValue;
  if (pauseOption === 'x-days') {
    pauseOptionValue = xDays;
  } else if (pauseOption === 'until-x-date') {
    pauseOptionValue = untilXDate;
  }
  try {
    const params = {
      product_subscription_id: productSubscriptionId,
      pause_option: pauseOption,
      pause_option_value: pauseOptionValue
    };

    const response = yield call(
      axiosDefault.put,
      `/product_subscriptions/${productSubscriptionId}/pause?account_id=${accountId}`,
      params
    );
    yield put({
      type: PAUSE_PRODUCT_SUBSCRIPTION_SUCCESS,
      payload: {
        productSubscription: response.data
      }
    });
  } catch (e) {
    yield put({
      type: PAUSE_PRODUCT_SUBSCRIPTION_FAILURE,
      payload: {
        error: e
      },
      error: true
    });
  }
}

function* unpauseProductSubscription({
  payload: { productSubscriptionId, accountId }
}) {
  try {
    const params = {
      product_subscription_id: productSubscriptionId
    };
    const response = yield call(
      axiosDefault.put,
      `/product_subscriptions/${productSubscriptionId}/unpause?account_id=${accountId}`,
      params
    );
    yield put({
      type: UNPAUSE_PRODUCT_SUBSCRIPTION_SUCCESS,
      payload: {
        productSubscription: response.data
      }
    });
  } catch (e) {
    yield put({
      type: UNPAUSE_PRODUCT_SUBSCRIPTION_FAILURE,
      payload: {
        error: e
      },
      error: true
    });
  }
}

function* removeProductSubscription({
  payload: { accountId, productSubscriptionId }
}) {
  try {
    const response = yield call(
      axiosDefault.delete,
      `/product_subscriptions/${productSubscriptionId}?account_id=${accountId}`
    );

    yield put({
      type: REMOVE_PRODUCT_SUBSCRIPTION_SUCCESS,
      payload: {
        productSubscription: response.data
      }
    });
  } catch (e) {
    yield put({
      type: REMOVE_PRODUCT_SUBSCRIPTION_FAILURE,
      payload: { error: e },
      error: true
    });
  }
}

function* addProductSubscription({ payload: { productId } }) {
  try {
    const params = {
      product_subscription: {
        product_id: productId
      }
    };

    const response = yield call(
      axiosDefault.post,
      `/product_subscriptions`,
      params
    );

    yield put({
      type: CREATE_PRODUCT_SUBSCRIPTION_SUCCESS,
      payload: {
        productSubscription: response.data
      }
    });
  } catch (e) {
    yield put({
      type: CREATE_PRODUCT_SUBSCRIPTION_FAILURE,
      payload: { error: e },
      error: true
    });
  }
}

function* fetchAddContactsFilters({ payload: { currentAccountId } }) {
  try {
    const response = yield call(
      axiosDefault.get,
      `/filters?account_id=${currentAccountId}&categories[]=lifecycle&categories[]=groups`
    );

    yield put({
      type: FETCH_ADD_CONTACTS_FILTERS_SUCCESS,
      payload: {
        addContactsFilters: response.data
      }
    });
  } catch (e) {
    yield put({
      type: FETCH_ADD_CONTACTS_FILTERS_FAILURE,
      payload: {
        error: e
      },
      error: true
    });
  }
}

function* viewContactsInProductCampaign({
  payload: { accountId, productCampaignId, page = 1 }
}) {
  try {
    const response = yield call(
      axiosDefault.get,
      `/contacts?account_id=${accountId}&product_campaign_id=${productCampaignId}&page=${page}&per=20&sort=${'first_name'}`
    );
    const total = get(response, 'headers["total-count"]');

    yield put({
      type: VIEW_CONTACTS_IN_PRODUCT_CAMPAIGN_SUCCESS,
      payload: {
        contacts: response.data,
        page,
        total
      }
    });
  } catch (e) {
    yield put({
      type: VIEW_CONTACTS_IN_PRODUCT_CAMPAIGN_FAILURE,
      payload: {
        error: e
      }
    });
  }
}

function* addContactsToProduct({
  payload: {
    productSubscriptionId,
    contactSegmentsToCampaigns,
    currentAccountId
  }
}) {
  try {
    const response = yield call(
      axiosDefault.post,
      `/product_subscriptions/${productSubscriptionId}/add_contacts?account_id=${currentAccountId}`,
      {
        contacts_segments_to_campaigns: contactSegmentsToCampaigns
      }
    );

    yield put({
      type: ADD_CONTACTS_TO_PRODUCT_SUCCESS,
      payload: {
        productSubscription: response.data
      }
    });
  } catch (e) {
    yield put({
      type: ADD_CONTACTS_TO_PRODUCT_FAILURE,
      payload: { error: e },
      error: true
    });
  }
}

function* fetchContactsCount({
  payload: { accountId, productSubscriptionId }
}) {
  try {
    const response = yield call(
      axiosDefault.get,
      `/product_subscriptions/${productSubscriptionId}/contacts_count?account_id=${accountId}`
    );

    yield put({
      type: FETCH_CONTACTS_COUNT_SUCCESS,
      payload: {
        contactsCount: response.data
      }
    });
  } catch (e) {
    yield put({
      type: FETCH_CONTACTS_COUNT_FAILURE,
      payload: { error: e },
      error: true
    });
  }
}

export function* productSubscriptionFetch() {
  yield takeLatest(
    FETCH_PRODUCT_SUBSCRIPTION_REQUEST,
    fetchProductSubscription
  );
}

export function* myProductSubscriptionsFetch() {
  yield takeLatest(
    FETCH_MY_PRODUCT_SUBSCRIPTIONS_REQUEST,
    fetchMyProductSubscriptions
  );
}

export function* productSubscriptionsUpdate() {
  yield takeLatest(
    UPDATE_PRODUCT_SUBSCRIPTION_REQUEST,
    updateProductSubscription
  );
}

export function* productSubscriptionPause() {
  yield takeLatest(
    PAUSE_PRODUCT_SUBSCRIPTION_REQUEST,
    pauseProductSubscription
  );
}

export function* productSubscriptionUnpause() {
  yield takeLatest(
    UNPAUSE_PRODUCT_SUBSCRIPTION_REQUEST,
    unpauseProductSubscription
  );
}

export function* productSubscriptionRemove() {
  yield takeLatest(
    REMOVE_PRODUCT_SUBSCRIPTION_REQUEST,
    removeProductSubscription
  );
}

export function* createProductSubscription() {
  yield takeLatest(CREATE_PRODUCT_SUBSCRIPTION_REQUEST, addProductSubscription);
}

export function* AddContactsFiltersFetch() {
  yield takeLatest(FETCH_ADD_CONTACTS_FILTERS_REQUEST, fetchAddContactsFilters);
}

export function* productContactsAdd() {
  yield takeLatest(ADD_CONTACTS_TO_PRODUCT_REQUEST, addContactsToProduct);
}

export function* productContactsView() {
  yield takeLatest(
    VIEW_CONTACTS_IN_PRODUCT_CAMPAIGN_REQUEST,
    viewContactsInProductCampaign
  );
}

export function* contactsCountFetch() {
  yield takeLatest(FETCH_CONTACTS_COUNT_REQUEST, fetchContactsCount);
}
