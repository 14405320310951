import React, { Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setOverlordLayoutHeaderText } from 'appState/actions/ActionCreators';

const OverlordDashboard = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setOverlordLayoutHeaderText('Dashboard'));
  }, []);

  return (
    <Fragment>
      <p>This is the overlord dashboard</p>
      <p>We should add some things that we might want to see here.</p>
    </Fragment>
  );
};

export default OverlordDashboard;
