import React from 'react';
import { withA11y } from '@storybook/addon-a11y';
import { withKnobs, text, select, boolean } from '@storybook/addon-knobs';
import Banner from './index';

export default {
  component: Banner,
  title: 'Banner',
  decorators: [withA11y, withKnobs]
};

let colorSelect;

function includeColorSelect(defaultValue) {
  const label = 'Color';
  const options = [
    'alpha',
    'bravo',
    'charlie',
    'success',
    'warning',
    'error',
    'gray'
  ];
  colorSelect = select(label, options, defaultValue);
}

export const Header = () => {
  includeColorSelect('alpha');
  return (
    <Banner
      color={colorSelect}
      announcement={text('Text', 'Big Announcement! We have a great news.')}
      smallAnnouncement={text('Small Screen Text ', 'We have great news.')}
      showDismissBtn={boolean('Show Dismiss Button', true)}
      primaryBtnText={text('Button Text', 'Learn More')}
    />
  );
};
