import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Table from 'components/Theme/Table';
import LogoContent from 'components/SyncWizard/shared/LogoContent';
import Card from 'components/Theme/Card';
import isEmpty from 'lodash.isempty';
import Button from 'components/Theme/Button';
import TextLoader from 'components/Theme/TextLoader';
import Skeleton from 'react-loading-skeleton';
import {
  createLoadedSelector,
  createErrorMessageSelector
} from 'appState/selectors';

import syncWizardInitBgImage from 'images/uplaunch-email-status-mascot-bg.svg';
import syncWizardInitForegroundImage from 'images/uplaunch-email-status-mascot-foreground.svg';
import { createStructuredSelector } from 'reselect';
import { useSelector } from 'react-redux';

const OverviewPage = ({ contactsStats }) => {
  const progressTextArr = [
    'Calling Zen Planner',
    'Retrieving Contacts',
    'Finalizing Contact Data',
    'Setting Up Sync Wizard'
  ];

  const contactMatchloadedSelector = createLoadedSelector([
    'sync-wizard/MATCHED_CONTACTS_FETCH'
  ]);
  const loadedSelector = createLoadedSelector([
    'sync-wizard/FETCH_SYNC_STATUS'
  ]);
  const errorSelector = createErrorMessageSelector([
    'sync-wizard/FETCH_SYNC_STATUS'
  ]);

  const structuredSelector = createStructuredSelector({
    isContactMatchLoaded: state => contactMatchloadedSelector(state),
    isLoaded: state => loadedSelector(state),
    error: state => errorSelector(state)
  });

  const { isLoaded, isContactMatchLoaded, error } = useSelector(
    structuredSelector
  );

  const recordsCount =
    contactsStats &&
    contactsStats.contacts &&
    contactsStats.contacts.recordsCount;
  const tableRows = [
    {
      id: 'wzrd-0',
      'Contact Status': 'Already Synced',
      'Contact Count': recordsCount.alreadySynced
    },
    {
      id: 'wzrd-3',
      'Contact Status': 'Not Synced',
      'Contact Count': recordsCount.zenPlanner - recordsCount.alreadySynced
    }
  ];

  return (
    <div>
      <div className="tw-py-16 tw-overflow-hidden tw-lg:py-24">
        <div className="tw-relative tw-max-w-xl tw-mx-auto tw-px-10 sm:tw-px-10 lg:tw-px-16 lg:tw-max-w-screen-xl">
          <div className="tw-relative lg:tw-grid lg:tw-grid-cols-2 lg:tw-gap-16 lg:tw-items-center">
            <div className="tw-relative">
              <h4 className="tw-text-2xl tw-leading-8 tw-font-extrabold tw-text-gray-800 tw-tracking-tight sm:tw-text-3xl sm:tw-leading-9">
                Let&apos;s get started!
              </h4>
              <div className="tw-mt-10" style={{ listStyle: 'none' }}>
                <li>
                  <div className="tw-flex">
                    <div className="tw-flex-shrink-0">
                      <div className="tw-flex tw-items-center tw-justify-center tw-h-12 tw-w-12 tw-rounded-md tw-bg-alpha-600 tw-text-white">
                        <svg
                          className="tw-w-6 tw-h-6"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="tw-ml-4">
                      <h5 className="tw-text-md tw-leading-6 tw-font-medium tw-text-gray-700">
                        Run the Sync Wizard to find matches between your two
                        platforms.
                      </h5>
                    </div>
                  </div>
                </li>
                <li className="tw-mt-10">
                  <div className="tw-flex">
                    <div className="tw-flex-shrink-0">
                      <div className="tw-flex tw-items-center tw-justify-center tw-h-12 tw-w-12 tw-rounded-md tw-bg-alpha-600 tw-text-white">
                        <svg
                          className="tw-w-6 tw-h-6"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M5 13l4 4L19 7"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="tw-ml-4">
                      <h5 className="tw-text-md tw-leading-6 tw-font-medium tw-text-gray-700">
                        Decide which contacts you would like to connect.
                      </h5>
                    </div>
                  </div>
                </li>
                <li className="tw-mt-10">
                  <div className="tw-flex">
                    <div className="tw-flex-shrink-0">
                      <div className="tw-flex tw-items-center tw-justify-center tw-h-12 tw-w-12 tw-rounded-md tw-bg-alpha-600 tw-text-white">
                        <svg
                          className="tw-w-6 tw-h-6"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="tw-ml-4">
                      <h5 className="tw-text-md tw-leading-6 tw-font-medium tw-text-gray-700">
                        Find any mismatched information so it can be updated in
                        UpLaunch to match Zen Planner.
                      </h5>
                    </div>
                  </div>
                </li>
                <li className="tw-mt-10">
                  <div className="tw-flex">
                    <div className="tw-flex-shrink-0">
                      <div className="tw-flex tw-items-center tw-justify-center tw-h-12 tw-w-12 tw-rounded-md tw-bg-alpha-600 tw-text-white">
                        <svg
                          className="tw-w-6 tw-h-6"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M14 10h4.764a2 2 0 011.789 2.894l-3.5 7A2 2 0 0115.263 21h-4.017c-.163 0-.326-.02-.485-.06L7 20m7-10V5a2 2 0 00-2-2h-.095c-.5 0-.905.405-.905.905 0 .714-.211 1.412-.608 2.006L7 11v9m7-10h-2M7 20H5a2 2 0 01-2-2v-6a2 2 0 012-2h2.5"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="tw-ml-4">
                      <h5 className="tw-text-md tw-leading-6 tw-font-medium tw-text-gray-700">
                        When finished, your connected contacts will be synced
                        until you disable the integration.
                      </h5>
                    </div>
                  </div>
                </li>
                <li className="tw-mt-10">
                  <div className="tw-flex">
                    <div className="tw-flex-shrink-0">
                      <div className="tw-flex tw-items-center tw-justify-center tw-h-12 tw-w-12 tw-rounded-md tw-bg-alpha-600 tw-text-white">
                        <svg
                          className="tw-w-6 tw-h-6"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="tw-ml-4">
                      <h5 className="tw-text-md tw-leading-6 tw-font-medium tw-text-gray-700">
                        Leads created in UpLaunch will also be created in Zen
                        Planner and then auto-synced.
                      </h5>
                    </div>
                  </div>
                </li>
              </div>
            </div>
            <Card containerClass="tw-bg-white tw-py-10 tw-px-10 tw-w-100 tw-mt-20 lg:tw-mt-0 lg:tw-min-w-500 tw-h-full">
              {isContactMatchLoaded ? (
                <div className="tw-mt-10 tw-relative lg:tw-mt-0">
                  <LogoContent
                    contactsStats={recordsCount}
                    containerClass="tw-container tw-mx-auto tw-text-md tw-table tw-mb-12"
                  />
                  <Table
                    key="ZenPlanner<->Uplaunch-Contact-stats"
                    rows={tableRows}
                    includedColumns={['Contact Status', 'Contact Count']}
                    emptyMessage="ZenPlanner <-> Uplaunch Contact Stats"
                  />
                </div>
              ) : (
                <Fragment>
                  {isLoaded ? (
                    <Fragment>
                      <div
                        className="tw-mx-auto"
                        style={{
                          position: 'relative',
                          height: '350px',
                          width: '390px'
                        }}
                      >
                        <img
                          className="space-man-bounce"
                          src={syncWizardInitForegroundImage}
                          style={{
                            left: 0,
                            top: 0,
                            height: '350px',
                            position: 'absolute',
                            width: '390px',
                            zIndex: 1
                          }}
                          alt="sync-wizard-init-foreground"
                        />
                        <img
                          src={syncWizardInitBgImage}
                          style={{
                            left: 0,
                            top: 0,
                            height: '350px',
                            width: '390px',
                            position: 'absolute'
                          }}
                          alt="sync-wizard-init-bg"
                        />
                      </div>
                      {!isEmpty(error) ? (
                        <div className="tw-text-center">
                          <Button
                            color="alpha"
                            containerStyle={{
                              marginBottom: '5px',
                              marginTop: '25px'
                            }}
                            isFullWidth={false}
                            isLoaded
                            onClick={() => window.location.reload()}
                            size="sm"
                            text="Refresh Wizard"
                            type="primary"
                          />
                        </div>
                      ) : (
                        <h3 className="tw-mt-10 tw-text-center">
                          <TextLoader loaderText={progressTextArr} />
                        </h3>
                      )}
                    </Fragment>
                  ) : (
                    <span className="tw-text-center">
                      <Skeleton height={300} width={400} />
                      <br />
                      <br />
                      <Skeleton height={60} width={350} />
                    </span>
                  )}
                </Fragment>
              )}
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

OverviewPage.propTypes = {
  contactsStats: PropTypes.shape({
    contacts: PropTypes.shape({
      exactMatch: PropTypes.array.isRequired,
      possibleMatchWithSameEmail: PropTypes.array.isRequired,
      possibleMatchWithSameName: PropTypes.array.isRequired,
      newToUplaunch: PropTypes.array.isRequired,
      newToZenPlanner: PropTypes.array.isRequired,
      recordsCount: PropTypes.shape({
        alreadySynced: PropTypes.number.isRequired,
        zenPlanner: PropTypes.number.isRequired,
        upLaunch: PropTypes.number.isRequired,
        exactMatch: PropTypes.number.isRequired,
        possibleMatch: PropTypes.number.isRequired,
        newToUplaunch: PropTypes.number.isRequired,
        newToZenPlanner: PropTypes.number.isRequired
      }).isRequired
    }).isRequired
  }).isRequired
};

export default OverviewPage;
