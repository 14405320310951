import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const sweep = keyframes`
  0% {
    clip-path: polygon(0% 0%, 0% 0%, 0% 0%, 50% 50%, 0% 0%, 0% 0%, 0% 0%);
  }
  50% {
    clip-path: polygon(
      0% 0%,
      0% 100%,
      0% 100%,
      50% 50%,
      100% 0%,
      100% 0%,
      0% 0%
    );
  }
  100% {
    clip-path: polygon(
      0% 0%,
      0% 100%,
      100% 100%,
      50% 50%,
      100% 100%,
      100% 0%,
      0% 0%
    );
  }
`;

const Spinner = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 10px solid #333;
  box-sizing: border-box;
  animation: ${sweep} 1s linear alternate infinite,
    ${rotate} 0.8s linear infinite;
`;

const Styled = {
  Spinner
};

export default Styled;
