// linting rules disabled for immer js
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  PURCHASE_PAGE_CREATE_SUBSCRIPTION_SUCCESS,
  PURCHASE_PAGE_FETCH_PRODUCT_PLAN_INFO_SUCCESS
} from 'appState/actions/constants/purchase.actions';

const initialState = {
  subscriptionId: 0,
  purchasePage: {}
};

export default function PurchaseReducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case PURCHASE_PAGE_CREATE_SUBSCRIPTION_SUCCESS: {
        const { subscription } = action.payload;
        draft.subscriptionId = subscription.id;
        return;
      }
      case PURCHASE_PAGE_FETCH_PRODUCT_PLAN_INFO_SUCCESS: {
        const { purchasePage } = action.payload;
        draft.purchasePage = purchasePage;
        return;
      }
      default:
        return draft;
    }
  });
}
