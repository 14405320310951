/* eslint-disable no-param-reassign */
import produce from 'immer';
import { FETCH_BACKGROUNDJOB_DATA_SUCCESS } from 'appState/actions/constants/backGroundJob.actions';

const initialState = {
  busy: 0,
  dead: 0,
  default_latency: 0,
  enqueued: 0,
  failed: 0,
  processed: 0,
  processes: 0,
  retries: 0,
  scheduled: 0
};

export default function BackGroundJobReducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case FETCH_BACKGROUNDJOB_DATA_SUCCESS:
        {
          const {
            busy,
            dead,
            default_latency,
            enqueued,
            failed,
            processed,
            processes,
            retries,
            scheduled
          } = action.payload;
          draft.busy = busy;
          draft.dead = dead;
          draft.default_latency = default_latency;
          draft.enqueued = enqueued;
          draft.failed = failed;
          draft.processed = processed;
          draft.processes = processes;
          draft.retries = retries;
          draft.scheduled = scheduled;
        }
        return;
      default:
        return draft;
    }
  });
}
