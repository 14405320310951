import React, { Fragment } from 'react';
import Card from 'components/Theme/Card';
import Skeleton from 'react-loading-skeleton';

const AppointmentTypeCardSkeleton = () => {
  function getBodyItem(contentLength) {
    return (
      <div className="tw-flex tw-justify-between">
        <div className="tw-flex tw-items-center">
          <div className="tw-flex tw-item-center tw-mr-2">
            <Skeleton width={20} height={20} circle />
          </div>
          <Skeleton height={20} width={contentLength} />
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      <Card
        title={<Skeleton width={225} height={25} />}
        containerClass="tw-w-full tw-bg-white tw-rounded-lg"
        innerContainerClass=""
        showHeaderAction
        headerAction={() => null}
        headerActionIcon={() => <Skeleton width={15} height={25} />}
        headerStyle={{ borderTopWidth: '10px', borderTopColor: '#dddddd' }}
      >
        <div className="tw-p-6 tw-text-gray-900">
          {getBodyItem(100)}
          {getBodyItem(125)}
          {getBodyItem(150)}
        </div>
      </Card>
    </Fragment>
  );
};

export default AppointmentTypeCardSkeleton;
