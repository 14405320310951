import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import Modal from 'components/Theme/Modal';
import DatePicker from 'components/Theme/DatePicker';
import Select from 'components/Theme/Select';
import TextInput from 'components/Theme/TextInput';
import { getPreviousDay } from 'lib/utils/dateTime';
import { pauseOptionSchema } from 'lib/validation/schema';
import { updateCurrentAutomationPause } from 'appState/actions/ActionCreators';

const PauseAutomationModal = ({
  currentContact,
  currentAutomation,
  showPauseAutomationModal,
  setShowPauseAutomationModal
}) => {
  const dispatch = useDispatch();

  function handlePauseSubmit(values) {
    const automationParams = {
      automationId: currentAutomation.id,
      ...(values || {})
    };
    formik.resetForm();
    return dispatch(updateCurrentAutomationPause(automationParams));
  }

  const formik = useFormik({
    initialValues: {
      pauseOption: '',
      xDays: '',
      untilXDate: ''
    },
    enableReinitialize: true,
    validationSchema: pauseOptionSchema,
    onSubmit: values => {
      handlePauseSubmit(values);
      setShowPauseAutomationModal(false);
    }
  });

  function handlePauseDate(updatedValue) {
    formik.setFieldValue('untilXDate', updatedValue);
  }

  return (
    <Modal
      color="alpha"
      headerText="Pause Campaign?"
      showHeaderIcon={false}
      primaryActionText="Pause"
      secondaryActionText="Cancel"
      primaryAction={formik.handleSubmit}
      secondaryAction={formik.resetForm}
      setShow={setShowPauseAutomationModal}
      show={showPauseAutomationModal}
      showSecondaryAction
      canHandleClickOutside={false}
      shouldPrimaryActionClose={false}
    >
      <p className="tw-font-body tw-text-sm tw-leading-5 tw-text-gray-500">
        {`You are about to pause ${currentContact.firstName} from ${currentAutomation.title}`}
      </p>
      <div className="tw-mb-4">
        <Select
          labelText="Pause Options"
          value={formik.values.pauseOption}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          showError={formik.touched.pauseOption && !!formik.errors.pauseOption}
          error={formik.errors.pauseOption}
          id="pause_option"
          options={[
            {
              displayName: 'Indefinitely',
              value: 'indefinite'
            },
            {
              displayName: 'For Set Number of Days',
              value: 'x-days'
            },
            {
              displayName: 'Until Specific Date',
              value: 'until-x-date'
            }
          ]}
        />
      </div>
      {formik.values.pauseOption === 'x-days' ? (
        <TextInput
          id="x_days"
          value={formik.values.xDays}
          labelText="Number of Days"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          showError={formik.touched.xDays && !!formik.errors.xDays}
          error={formik.errors.xDays}
          widthClass="tw-col-span-12 sm:tw-col-span-6 xxs:tw-my-4 xs:tw-my-0"
        />
      ) : null}
      {formik.values.pauseOption === 'until-x-date' ? (
        <DatePicker
          id="until_x_date"
          value={formik.values.untilXDate}
          labelText="Until Specific Date"
          onChange={handlePauseDate}
          onBlur={formik.handleBlur}
          showError={formik.touched.untilXDate && !!formik.errors.untilXDate}
          widthClass="tw-col-span-12 sm:tw-col-span-6 xxs:tw-my-4 xs:tw-my-0"
          minDate={getPreviousDay()}
        />
      ) : null}
    </Modal>
  );
};

PauseAutomationModal.defaultProps = {
  currentContact: {},
  currentAutomation: {},
  showPauseAutomationModal: false,
  setShowPauseAutomationModal: () => null
};

const contactShape = {
  firstName: PropTypes.string
};

const automationShape = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.string
};

PauseAutomationModal.propTypes = {
  currentContact: PropTypes.shape(contactShape),
  currentAutomation: PropTypes.shape(automationShape),
  showPauseAutomationModal: PropTypes.bool,
  setShowPauseAutomationModal: PropTypes.func
};

export default PauseAutomationModal;
