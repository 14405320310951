import React, { useEffect, useContext, useState } from 'react';
import isEmpty from 'lodash.isempty';
import { createStructuredSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import BeePlugin from '@mailupinc/bee-plugin';
import { EnvVariablesContext } from 'components/shared/context/EnvVariablesContext';
import {
  fetchIncludedForm,
  setOverlordLayoutHeaderText,
  updateIncludedForm,
  fetchStaffEmailMergeFields,
  flashErrorMessage,
  flashSuccessMessage
} from 'appState/actions/ActionCreators';
import { UPDATE_INCLUDED_FORM } from 'app-state/actions/constants/product.actions';
import {
  createErrorMessageSelector,
  createLoadedSelector
} from 'appState/selectors';
import { useParams } from 'react-router-dom';
import Breadcrumbs from 'components/Theme/Breadcrumbs';
import Toggle from 'components/Theme/Toggle';
import Button from 'components/Theme/Button';
import { useFormik } from 'formik';
import { staffEmailNotificationSchema } from 'lib/validation/schema';
import Alert from 'components/Theme/Alert';
import TextInput from 'components/Theme/TextInput';
import RadioButtons from 'components/Theme/RadioButtons';
import { STAFF_NOTIFICATION_RECIPIENT_OPTIONS } from 'config/constants';

const StaffEmailNotification = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { id, partnerId, productId } = params;
  const [beeEditor, setBeeEditor] = useState(null);
  const [staffEmailJson, setStaffEmailJson] = useState(null);
  const [beeEditorStarted, setBeeEditorStarted] = useState(false);
  const env = useContext(EnvVariablesContext);

  const updateErrorSelector = createErrorMessageSelector([
    UPDATE_INCLUDED_FORM
  ]);

  const loadedSelectorUpdate = createLoadedSelector([UPDATE_INCLUDED_FORM]);

  const structuredSelector = createStructuredSelector({
    currentIncludedForm: state => state.product.currentIncludedForm,
    mergeFields: state => state.product.staffEmailMergeFields,
    updateError: state => updateErrorSelector(state),
    isUpdateLoaded: state => loadedSelectorUpdate(state)
  });

  const {
    currentIncludedForm,
    mergeFields,
    updateError,
    isUpdateLoaded
  } = useSelector(structuredSelector);

  useEffect(() => {
    dispatch(fetchIncludedForm({ includedFormId: id }));
  }, [id, dispatch]);

  useEffect(() => {
    if (!isEmpty(currentIncludedForm)) {
      dispatch(
        setOverlordLayoutHeaderText(`${currentIncludedForm.name} Settings`)
      );
    } else {
      dispatch(setOverlordLayoutHeaderText('Settings'));
    }
  }, [currentIncludedForm]);

  useEffect(() => {
    if (isEmpty(mergeFields)) {
      dispatch(fetchStaffEmailMergeFields());
    }
  }, [mergeFields, dispatch]);

  useEffect(() => {
    if (isUpdateLoaded) {
      if (isEmpty(updateError)) {
        dispatch(
          flashSuccessMessage('Successfully updated staff email notification.')
        );
      } else if (!isEmpty(updateError)) {
        dispatch(flashErrorMessage(updateError));
      }
    }
  }, [isUpdateLoaded, updateError]);

  function handleSubmit(values) {
    const staffEmailNotificationParams = {};
    if (Object.keys(values).length)
      Object.keys(values).forEach(function assignParams(key) {
        staffEmailNotificationParams[key] = values[key];
      });
    if (currentIncludedForm?.uplaunch_action) {
      staffEmailNotificationParams.id = currentIncludedForm.uplaunch_action.id;
    }
    dispatch(
      updateIncludedForm({
        includedFormId: id,
        staffEmailNotificationParams
      })
    );
  }

  useEffect(() => {
    if (currentIncludedForm?.uplaunch_action?.staff_email_json) {
      const emailJson = currentIncludedForm.uplaunch_action.staff_email_json;
      let emailTemplate;
      try {
        emailTemplate = JSON.parse(emailJson);
      } catch (e) {
        emailTemplate = emailJson;
      }
      setStaffEmailJson(emailTemplate);
    }
  }, [currentIncludedForm]);

  const formik = useFormik({
    initialValues: {
      hasStaffNotificationEmail:
        currentIncludedForm?.uplaunch_action?.has_staff_notification_email,
      staffNotificationEmailRecipients:
        currentIncludedForm?.uplaunch_action
          ?.staff_notification_email_recipients || 'admin',
      staffEmailSubject:
        currentIncludedForm?.uplaunch_action?.staff_email_subject || '',
      staffEmailHtml: currentIncludedForm?.uplaunch_action?.staff_email_html,
      staffEmailJson
    },
    validationSchema: staffEmailNotificationSchema,
    enableReinitialize: true,
    onSubmit: values => {
      handleSubmit(values);
    }
  });

  const mergeContents = [
    {
      name: 'Account Logo',
      value: '{{ account_logo.block }}'
    },
    {
      name: 'Social Media Footer',
      value: '{{ social_media.block }}'
    },
    {
      name: 'Compliance Footer',
      value: '{{ compliance_footer.block }}'
    },
    {
      name: 'Call to Action',
      value: '{{ journey_cta.block }}'
    }
  ];

  const CLIENT_ID = env.BEE_CLIENT_ID;
  const CLIENT_SECRET = env.BEE_CLIENT_SECRET;

  const onSave = (emailJson, emailHtml) => {
    formik.setFieldValue('staffEmailJson', emailJson);
    formik.setFieldValue('staffEmailHtml', emailHtml);
    formik.handleSubmit();
  };
  const onSend = () => null;
  const onSaveAsTemplate = emailJson => {
    console.log('---onSaveAsTemplate---', emailJson, '---onSaveAsTemplate---');
  };
  const onChange = () => {};
  const onLoad = () => {};
  const onError = errorMessage => console.log(errorMessage);

  const DEFAULT_CONFIGURATION = {
    container: 'email-bee-container', // Identifies the id of div element that contains BEE Plugin.
    language: 'en-US',
    preventClose: false,
    mergeContents,
    trackChanges: true,
    onSave,
    onChange,
    onSaveAsTemplate,
    onSend,
    onLoad,
    onError,
    uid: 'uplaunch-overlord',
    roleHash: 'uplaunch-staff'
  };

  function onFetchBeeToken(clientId, clientSecret, _beeEditor) {
    return _beeEditor.getToken(clientId, clientSecret);
  }

  useEffect(() => {
    if (staffEmailJson && !isEmpty(mergeFields)) {
      const beeFinalConfig = {
        ...DEFAULT_CONFIGURATION,
        mergeTags: mergeFields,
        specialLinks: []
      };
      if (!beeEditor) {
        setBeeEditor(new BeePlugin());
      }
      if (beeEditor) {
        // ensure fresh token is requested on each load of the Bee plugin
        delete beeEditor.token;
        onFetchBeeToken(CLIENT_ID, CLIENT_SECRET, beeEditor).then(() => {
          if (!beeEditorStarted) {
            beeEditor.start(beeFinalConfig, staffEmailJson);
            setBeeEditorStarted(true);
          }
        });
      }
    }
  }, [staffEmailJson, mergeFields, beeEditor]);

  function handleStaffEmailNotificationToggle() {
    formik.setFieldValue(
      'hasStaffNotificationEmail',
      !formik.values.hasStaffNotificationEmail
    );
  }

  const breadcrumbs = [
    {
      name: 'Overlord',
      action: () => {
        window.location = '/overlord/';
      }
    },
    {
      name: 'Partner',
      action: () => {
        window.location = `/overlord/partners/${partnerId}`;
      }
    },
    {
      name: 'Product',
      action: () => {
        window.location = `/overlord/partners/${partnerId}/products/${productId}`;
      }
    },
    {
      name: 'Included Form',
      action: () => {
        window.location = `/overlord/partners/${partnerId}/products/${productId}/included_forms/${id}`;
      }
    },
    {
      name: 'Settings',
      action: () => {
        window.location = `/overlord/partners/${partnerId}/products/${productId}/included_forms/${id}/settings`;
      }
    },
    {
      name: 'Staff Email Notification',
      action: () => console.log('staff email notification clicked')
    }
  ];

  return (
    <div className="tw-px-4 tw-py-4 sm:tw-px-6">
      <div className="tw-mb-5">
        <div className="tw-mb-5">
          {breadcrumbs && breadcrumbs.length ? (
            <div>
              <Breadcrumbs crumbs={breadcrumbs} isBackgroundWhite />
            </div>
          ) : null}
        </div>
        <div className="tw-mt-2 md:tw-flex md:tw-items-center md:tw-justify-between">
          <div className="tw-flex-1 tw-min-w-0">
            <Toggle
              color="alpha"
              onClick={handleStaffEmailNotificationToggle}
              isToggled={formik.values.hasStaffNotificationEmail}
              size="large"
              withIcons
              withLabel
              label="Send Staff Email Notification?"
            />
          </div>
          <div className="tw-mt-4 tw-flex tw-flex-shrink-0 md:tw-mt-0 md:tw-ml-4">
            <Button
              text="Update"
              color="alpha"
              onClick={() => {
                beeEditor.save();
              }}
              disabled={isEmpty(currentIncludedForm)}
              isLoaded={!isEmpty(currentIncludedForm)}
            />
          </div>
        </div>
      </div>
      <div className="tw-border-b tw-border-t-0 tw-border-l-0 tw-border-r-0 tw-border-solid tw-border-gray-200" />
      <div className="tw-my-5">
        <div className="tw-my-10">
          <RadioButtons
            id="staff_notification_Email_recipients"
            label="Send A Staff Email Notification To:"
            type="horizontal"
            options={STAFF_NOTIFICATION_RECIPIENT_OPTIONS}
            onChange={value => {
              formik.setFieldValue('staffNotificationEmailRecipients', value);
            }}
            onBlur={formik.handleBlur}
            selected={formik.values.staffNotificationEmailRecipients}
          />
        </div>
        <TextInput
          id="staff_email_subject"
          labelText="Email Subject"
          placeholder="Enter Subject"
          value={formik.values.staffEmailSubject}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          showError={
            formik.touched.staffEmailSubject &&
            !!formik.errors.staffEmailSubject
          }
          error={formik.errors.staffEmailSubject}
          widthClass="tw-col-span-12"
          required
        />
      </div>
      <div className="tw-hidden md:tw-block tw-relative tw-h-screen tw-overflow-scroll">
        <div
          id="email-bee-container"
          style={{
            border: 0,
            height: '100%',
            position: 'absolute',
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            width: '100%'
          }}
        />
      </div>
      <div className="tw-block md:tw-hidden">
        <Alert
          color="gray"
          headerText="Screen Size To Small"
          bodyText="The email editor is not optimized for use on mobile device sized screens. Please increase the screen width to continue."
          icon="information-circle"
        />
      </div>
    </div>
  );
};

export default StaffEmailNotification;
