/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import isEmpty from 'lodash.isempty';
import {
  createErrorMessageSelector,
  createLoadingSelector,
  createLoadedSelector
} from 'appState/selectors';
import {
  planPageFetch,
  planCreate,
  planUpdate,
  resetIsLoaded,
  subscriptionsPageChange,
  subscriptionsFetch,
  fetchPartners,
  flashSuccessMessage,
  flashErrorMessage,
  setOverlordLayoutHeaderText,
  currentPlanReset,
  currentSubscriptionsReset,
  onboardingFeesFetch
} from 'appState/actions/ActionCreators';
import SmsEnabledCountry from 'components/Plans/Plan/SmsEnabledCountry';
import Card from 'components/Theme/Card';
import Modal from 'components/Theme/Modal';
import PageHeader from 'components/Theme/PageHeader';
import Select from 'components/Theme/Select';
import Table from 'components/Theme/Table';
import TextArea from 'components/Theme/TextArea';
import TextInput from 'components/Theme/TextInput';
import TextInputsArray from 'components/Theme/TextInputsArray';
import Pagination from 'components/Theme/Pagination';
import { planSchema } from 'lib/validation/schema';
import { formatTimestamp } from 'lib/utils/dateTime';
import { capitalize } from 'lib/utils/string';
import { TIERS } from 'config/constants';

const PlanBuilder = props => {
  const [showModal, setShowModal] = useState(false);
  const [currentSmsEnabledCountry, setCurrentSmsEnabledCountry] = useState({});
  const [isEditingSmsEnabledCountry, setIsEditingSmsEnabledCountry] = useState(
    false
  );
  const [
    isCreatingSmsEnabledCountry,
    setIsCreatingSmsEnabledCountry
  ] = useState(false);
  const [localSmsEnabledCountries, setLocalSmsEnabledCountries] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const { path } = useRouteMatch();
  const isCreating = path.includes('create');
  const params = useParams();
  const { id } = params;
  const currentTier = props?.location?.state?.currentTier;

  const createErrorSelector = createErrorMessageSelector(['plan/CREATE_PLAN']);

  const updateErrorSelector = createErrorMessageSelector(['plan/UPDATE_PLAN']);

  const loadingSelector = createLoadingSelector([
    'plan/CREATE_PLAN',
    'plan/UPDATE_PLAN'
  ]);
  const loadedSelector = createLoadedSelector([
    'plan/CREATE_PLAN',
    'plan/UPDATE_PLAN'
  ]);

  const structuredSelector = createStructuredSelector({
    currentPlan: state => state.plan.currentPlan,
    onboardingFees: state => state.plan.onboardingFees,
    smsEnabledCountries: state => state.plan.currentPlan.sms_enabled_countries,
    partners: state => state.partner.partners,
    createError: state => createErrorSelector(state),
    updateError: state => updateErrorSelector(state),
    isLoading: state => loadingSelector(state),
    isLoaded: state => loadedSelector(state),
    total: state => state.subscription.total,
    totalPages: state => state.subscription.totalPages,
    subscriptions: state => state.subscription.subscriptions
  });

  const {
    currentPlan,
    onboardingFees,
    smsEnabledCountries,
    partners,
    createError,
    updateError,
    isLoading,
    isLoaded,
    total,
    totalPages,
    subscriptions
  } = useSelector(structuredSelector);

  useEffect(() => {
    if (!isEmpty(smsEnabledCountries)) {
      setLocalSmsEnabledCountries(smsEnabledCountries);
    }
  }, [smsEnabledCountries]);

  useEffect(() => {
    if (id) {
      dispatch(planPageFetch({ planId: id }));
    }
    dispatch(onboardingFeesFetch());
    dispatch(fetchPartners());
  }, [dispatch, id]);

  useEffect(() => {
    if (id) dispatch(subscriptionsFetch({ planId: id }));
  }, [dispatch, id]);

  function handleEdit(subscriptionsId) {
    history.push(`/overlord/subscriptions/${subscriptionsId}/edit`);
  }

  function handleSubmit(values) {
    const planParams = { history };
    if (id) planParams.planId = id;
    if (Object.keys(values).length)
      Object.keys(values).forEach(function assignParams(key) {
        planParams[key] = values[key];
      });
    if (localSmsEnabledCountries)
      planParams.smsEnabledCountries = localSmsEnabledCountries;
    if (planParams.type === 'LegacyPlan') planParams.maxProducts = 1;
    if (planParams.type === 'BundlePlan') planParams.maxJourneys = 0;

    if (isCreating) return dispatch(planCreate(planParams));
    return dispatch(planUpdate(planParams));
  }

  const formik = useFormik({
    initialValues: {
      tier: currentTier || '',
      name: currentPlan?.name || '',
      type: currentPlan?.type || '',
      maxUsers: currentPlan?.max_users || 0,
      maxProducts: currentPlan?.max_products || 0,
      partnerId: currentPlan.partner_id || '',
      maxJourneys: currentPlan?.max_journeys || 0,
      maxAppointmentTypes: currentPlan?.max_appointment_types || 0,
      chargeCampaignSms: currentPlan?.charge_campaign_sms || false,
      description: currentPlan?.description || '',
      typeformId: currentPlan?.typeform_id || '',
      contactImportTypeformId: currentPlan?.contact_import_typeform_id || '',
      visible: currentPlan?.visible,
      features: (!isEmpty(currentPlan?.features) &&
        currentPlan.features.map(f => ({ name: f }))) || [{ name: '' }],
      price: currentPlan?.pricing_information?.price || '',
      stripePlan: currentPlan?.pricing_information?.stripe_plan || '',
      onboardingFeeId: currentPlan?.pricing_information?.onboarding_fee_id || ''
    },
    enableReinitialize: true,
    validationSchema: planSchema,
    onSubmit: values => {
      handleSubmit(values);
    }
  });

  useEffect(() => {
    if (isLoaded && (isEmpty(updateError) && isEmpty(createError))) {
      dispatch(
        flashSuccessMessage(
          `Successfully ${isCreating ? 'created' : 'updated'} the ${
            formik.values.name
          } plan.`
        )
      );
    }

    if (isLoaded && !(isEmpty(updateError) && isEmpty(createError))) {
      if (updateError) {
        dispatch(flashErrorMessage(updateError));
      } else {
        dispatch(flashErrorMessage(createError));
      }
    }

    dispatch(resetIsLoaded('plan/UPDATE_PLAN'));
    dispatch(resetIsLoaded('plan/CREATE_PLAN'));

    return () => {
      dispatch(resetIsLoaded('plan/UPDATE_PLAN'));
      dispatch(resetIsLoaded('plan/CREATE_PLAN'));
    };
  }, [updateError, createError, isLoaded, dispatch]);

  useEffect(() => {
    dispatch(setOverlordLayoutHeaderText('Plans'));

    return () => {
      dispatch(currentPlanReset());
      dispatch(currentSubscriptionsReset());
    };
  }, []);

  const handleEditOrNewSmsEnabledCountry = _isCreating => countryId => {
    const smsEnabledCountry = localSmsEnabledCountries.filter(
      c => c.id === countryId
    )[0];
    setCurrentSmsEnabledCountry(smsEnabledCountry);

    setIsEditingSmsEnabledCountry(true);
    setIsCreatingSmsEnabledCountry(_isCreating);
  };

  function handleDeleteSmsEnabledCountry() {
    const updatedLocalSmsEnabledCountries = localSmsEnabledCountries
      .filter(
        smsEnabledCountry =>
          // eslint-disable-next-line no-underscore-dangle
          (currentSmsEnabledCountry._id &&
            currentSmsEnabledCountry._id !== smsEnabledCountry._id) ||
          smsEnabledCountry.id
      )
      .map(smsEnabledCountry => {
        if (
          currentSmsEnabledCountry.id &&
          currentSmsEnabledCountry.id === smsEnabledCountry.id
        )
          return {
            id: currentSmsEnabledCountry.id,
            _destroy: true
          };
        return smsEnabledCountry;
      });
    setLocalSmsEnabledCountries(updatedLocalSmsEnabledCountries);
  }

  function handleFeaturesChange(e, updatedValues) {
    formik.setFieldValue('features', updatedValues);
  }

  async function handleDeleteClick(countryId) {
    const smsEnabledCountry = localSmsEnabledCountries.filter(
      c => c.id === countryId
    )[0];
    await setCurrentSmsEnabledCountry(smsEnabledCountry);
    setShowModal(true);
  }

  function handleModalSetShow(show) {
    setShowModal(show);
  }

  function handleModalSecondaryAction(show) {
    handleModalSetShow(show);
  }

  function pageChange(pageParams) {
    dispatch(subscriptionsPageChange({ planId: id, page: pageParams.page }));
  }

  return (
    <div className="tw-min-h-screen">
      <PageHeader
        title="Plan Builder"
        primaryActionText={isCreating ? 'Save' : 'Update'}
        primaryAction={formik.handleSubmit}
        isLoaded={!isLoading}
        breadcrumbs={[
          {
            name: 'Overlord',
            action: () => history.push('/overlord/')
          },
          {
            name: 'Plans Index',
            action: () => history.push('/overlord/plans')
          },
          {
            name: 'Plan Builder',
            // eslint-disable-next-line no-console
            action: () => console.log('Plan Builder clicked...')
          }
        ]}
      />

      <form action="#" method="POST">
        <div className="tw-max-w-7xl tw-mx-auto tw-py-20 sm:tw-px-6 lg:tw-px-8">
          <div className="tw-mt-10 sm:tw-mt-0">
            <div className="md:tw-grid md:tw-grid-cols-4 md:tw-gap-6">
              <div className="md:tw-col-span-1">
                <div className="tw-px-4 sm:tw-px-0">
                  <h3 className="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900">
                    Tiers
                  </h3>
                  <p className="tw-mt-1 tw-text-sm tw-leading-5 tw-text-gray-600">
                    Select a tier
                  </p>
                </div>
              </div>
              <div className="tw-mt-5 md:tw-mt-0 md:tw-col-span-3">
                <Card containerClass="tw-shadow sm:tw-rounded-md sm:tw-overflow-hidden">
                  <div className="tw-px-4 tw-py-5 tw-bg-white sm:tw-p-6 tw-h-36">
                    <div className="tw-grid tw-grid-cols-10 tw-gap-6">
                      <Select
                        id="tier"
                        value={formik.values.tier}
                        options={TIERS.map(tier => ({
                          value: tier,
                          displayName: capitalize(tier)
                        }))}
                        labelText="Tiers"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        showError={formik.touched.tier && !!formik.errors.tier}
                        error={formik.errors.tier}
                        widthClass="tw-col-span-10 sm:tw-col-span-5"
                      />
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </div>

          <div className="tw-hidden sm:tw-block">
            <div className="tw-py-5">
              <div className="tw-border-t tw-border-b-0 tw-border-l-0 tw-border-r-0 tw-border-solid tw-border-gray-200" />
            </div>
          </div>

          <div>
            <div className="md:tw-grid md:tw-grid-cols-4 md:tw-gap-6">
              <div className="md:tw-col-span-1">
                <div className="tw-px-4 sm:tw-px-0">
                  <h3 className="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900">
                    Plan Details
                  </h3>
                  <p className="tw-mt-1 tw-text-sm tw-leading-5 tw-text-gray-600">
                    Some of this information will be displayed publicly on the
                    purchase page so be careful what you share.
                  </p>
                </div>
              </div>
              <div className="tw-mt-5 md:tw-mt-0 md:tw-col-span-3">
                <Card containerClass="tw-shadow sm:tw-rounded-md sm:tw-overflow-hidden">
                  <div className="tw-px-4 tw-py-5 tw-bg-white sm:tw-p-6">
                    <div className="tw-grid tw-grid-cols-12 tw-gap-6">
                      <TextInput
                        id="name"
                        value={formik.values.name}
                        labelText="Name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        showError={formik.touched.name && !!formik.errors.name}
                        error={formik.errors.name}
                        widthClass="tw-col-span-12 sm:tw-col-span-6"
                      />

                      <Select
                        id="type"
                        value={formik.values.type}
                        options={[
                          { displayName: 'Legacy', value: 'LegacyPlan' },
                          { displayName: 'Bundle', value: 'BundlePlan' },
                          { displayName: 'Add-on', value: 'AddOnPlan' }
                        ]}
                        labelText="Type"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        showError={formik.touched.type && !!formik.errors.type}
                        error={formik.errors.type}
                        widthClass="tw-col-span-12 sm:tw-col-span-6"
                      />

                      <Select
                        id="partnerId"
                        value={formik.values.partnerId}
                        options={
                          !isEmpty(partners)
                            ? partners.map(partner => ({
                                value: partner.id,
                                displayName: partner.name
                              }))
                            : [{ value: '' }]
                        }
                        labelText="Partner"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        showError={
                          formik.touched.partnerId && !!formik.errors.partnerId
                        }
                        error={formik.errors.partnerId}
                        widthClass="tw-col-span-12 sm:tw-col-span-6"
                      />

                      <Select
                        id="charge_campaign_sms"
                        value={formik.values.chargeCampaignSms}
                        options={[
                          { displayName: 'True', value: true },
                          { displayName: 'False', value: false }
                        ]}
                        labelText="Charge Campaign SMS?"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        showError={
                          formik.touched.chargeCampaignSms &&
                          !!formik.errors.chargeCampaignSms
                        }
                        error={formik.errors.chargeCampaignSms}
                        widthClass="tw-col-span-12 sm:tw-col-span-6"
                      />

                      {formik.values.tier === 'standard' &&
                        formik.values.type !== 'LegacyPlan' && (
                          <TextInput
                            id="max_products"
                            value={formik.values.maxProducts}
                            labelText="Max Products"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            showError={
                              formik.touched.maxProducts &&
                              !!formik.errors.maxProducts
                            }
                            helpText="A value should be greater than zero"
                            error={formik.errors.maxProducts}
                            widthClass="tw-col-span-12 sm:tw-col-span-6"
                          />
                        )}

                      <TextInput
                        id="max_users"
                        value={formik.values.maxUsers}
                        labelText="Max Users"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        showError={
                          formik.touched.maxUsers && !!formik.errors.maxUsers
                        }
                        error={formik.errors.maxUsers}
                        helpText="A value of zero equates to unlimited"
                        widthClass="tw-col-span-12 sm:tw-col-span-6"
                      />

                      {formik.values.type !== 'BundlePlan' && (
                        <TextInput
                          id="max_journeys"
                          value={formik.values.maxJourneys}
                          labelText="Max Journeys"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          showError={
                            formik.touched.maxJourneys &&
                            !!formik.errors.maxJourneys
                          }
                          helpText="A value of zero equates to unlimited"
                          error={formik.errors.maxJourneys}
                          widthClass="tw-col-span-12 sm:tw-col-span-6"
                        />
                      )}

                      <TextInput
                        id="max_appointment_types"
                        value={formik.values.maxAppointmentTypes}
                        labelText="Max Appointment Types"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        showError={
                          formik.touched.maxAppointmentTypes &&
                          !!formik.errors.maxAppointmentTypes
                        }
                        helpText="A value of zero equates to unlimited"
                        error={formik.errors.maxAppointmentTypes}
                        widthClass="tw-col-span-12 sm:tw-col-span-6"
                      />

                      <TextInput
                        id="typeform_id"
                        value={formik.values.typeformId}
                        labelText="TypeForm ID"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        showError={
                          formik.touched.typeformId &&
                          !!formik.errors.typeformId
                        }
                        helpText="This will be used to redirect the user to the correct onboarding form after purchase"
                        error={formik.errors.typeformId}
                        widthClass="tw-col-span-12 sm:tw-col-span-6"
                      />

                      <TextInput
                        id="contact_import_typeform_id"
                        value={formik.values.contactImportTypeformId}
                        labelText="Contact Import TypeForm ID"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        showError={
                          formik.touched.contactImportTypeformId &&
                          !!formik.errors.contactImportTypeformId
                        }
                        helpText="This will be used to redirect the user to the correct contact import form when clicking 'Import Contacts' on the contact index."
                        error={formik.errors.contactImportTypeformId}
                        widthClass="tw-col-span-12 sm:tw-col-span-6"
                      />

                      <TextArea
                        id="description"
                        value={formik.values.description}
                        labelText="Description"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        showError={
                          formik.touched.description &&
                          !!formik.errors.description
                        }
                        error={formik.errors.description}
                        helpText="Brief description of the plan. This will be displayed on the purchase page."
                        widthClass="tw-col-span-12"
                      />

                      <TextInputsArray
                        id="features"
                        value={formik.values.features}
                        labelText="Features"
                        onChange={handleFeaturesChange}
                        showError={
                          formik.touched.features && !!formik.errors.features
                        }
                        error={formik.errors.features}
                      />
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </div>

          <div className="tw-hidden sm:tw-block">
            <div className="tw-py-5">
              <div className="tw-border-t tw-border-b-0 tw-border-l-0 tw-border-r-0 tw-border-solid tw-border-gray-200" />
            </div>
          </div>

          <div className="tw-mt-10 sm:tw-mt-0">
            <div className="md:tw-grid md:tw-grid-cols-4 md:tw-gap-6">
              <div className="md:tw-col-span-1">
                <div className="tw-px-4 sm:tw-px-0">
                  <h3 className="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900">
                    Pricing Information
                  </h3>
                  <p className="tw-mt-1 tw-text-sm tw-leading-5 tw-text-gray-600">
                    The price, onboarding fee and stripe plan tied to this plan.
                  </p>
                </div>
              </div>
              <div className="tw-mt-5 md:tw-mt-0 md:tw-col-span-3">
                <Card containerClass="tw-shadow sm:tw-rounded-md sm:tw-overflow-hidden">
                  <div className="tw-px-4 tw-py-5 tw-bg-white sm:tw-p-6">
                    <div className="tw-grid tw-grid-cols-10 tw-gap-6">
                      <TextInput
                        id="price"
                        value={formik.values.price}
                        labelText="Price"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        showError={
                          formik.touched.price && !!formik.errors.price
                        }
                        error={formik.errors.price}
                        widthClass="tw-col-span-10 sm:tw-col-span-5"
                      />
                      {!isEmpty(onboardingFees) ? (
                        <Select
                          id="onboardingFeeId"
                          value={formik.values.onboardingFeeId}
                          options={onboardingFees.map(fee => ({
                            value: fee.id,
                            displayName: fee.amount
                          }))}
                          labelText="Onboarding Fee"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          showError={
                            formik.touched.onboardingFeeId &&
                            !!formik.errors.onboardingFeeId
                          }
                          error={formik.errors.onboardingFeeId}
                          widthClass="tw-col-span-10 sm:tw-col-span-5"
                        />
                      ) : null}

                      <TextInput
                        id="stripe_plan"
                        value={formik.values.stripePlan}
                        labelText="Stripe Plan"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        showError={
                          formik.touched.stripePlan &&
                          !!formik.errors.stripePlan
                        }
                        error={formik.errors.stripePlan}
                      />
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </div>

          <div className="tw-hidden sm:tw-block">
            <div className="tw-py-5">
              <div className="tw-border-t tw-border-b-0 tw-border-l-0 tw-border-r-0 tw-border-solid tw-border-gray-200" />
            </div>
          </div>

          <div className="tw-mt-10 sm:tw-mt-0">
            <div className="md:tw-grid md:tw-grid-cols-4 md:tw-gap-6">
              <div className="md:tw-col-span-1">
                <div className="tw-px-4 sm:tw-px-0">
                  <h3 className="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900">
                    SMS Enabled Countries
                  </h3>
                  <p className="tw-mt-1 tw-text-sm tw-leading-5 tw-text-gray-600">
                    This includes credits and phone number surcharge tied to any
                    SMS-enabled countries supported by this plan.
                  </p>
                </div>
              </div>
              <div className="tw-mt-5 md:tw-mt-0 md:tw-col-span-3">
                {isEditingSmsEnabledCountry ? (
                  <SmsEnabledCountry
                    planId={currentPlan?.id || 0}
                    smsEnabledCountryId={
                      (currentSmsEnabledCountry &&
                        currentSmsEnabledCountry.id) ||
                      0
                    }
                    isCreating={isCreatingSmsEnabledCountry}
                    setIsEditing={isEditing =>
                      setIsEditingSmsEnabledCountry(isEditing)
                    }
                    smsEnabledCountries={localSmsEnabledCountries}
                    setSmsEnabledCountries={updatedSmsEnabledCountries =>
                      setLocalSmsEnabledCountries(updatedSmsEnabledCountries)
                    }
                  />
                ) : (
                  <Table
                    rows={localSmsEnabledCountries}
                    rowConditionals={value => ({
                      free_credit_pack: !isEmpty(value) && value.credits,
                      additional_credit_pack:
                        !isEmpty(value) && `${value.credits}/$${value.price}`,
                      gdpr: value === true ? 'True' : 'False'
                    })}
                    includedColumns={[
                      'name',
                      'phone_number_surcharge',
                      'free_credit_pack',
                      'additional_credit_pack',
                      'gdpr'
                    ]}
                    editable
                    onEdit={handleEditOrNewSmsEnabledCountry(false)}
                    deletable
                    onDelete={handleDeleteClick}
                    emptyMessage="No SMS-enabled countries tied to this plan"
                    hasFooter
                    footerAction={handleEditOrNewSmsEnabledCountry(true)}
                    footerActionText="Add New"
                  />
                )}
              </div>
            </div>
          </div>

          <div className="tw-hidden sm:tw-block">
            <div className="tw-py-5">
              <div className="tw-border-t tw-border-b-0 tw-border-l-0 tw-border-r-0 tw-border-solid tw-border-gray-200" />
            </div>
          </div>

          <div className="tw-mt-10 sm:tw-mt-0">
            <div className="md:tw-grid md:tw-grid-cols-4 md:tw-gap-6">
              <div className="md:tw-col-span-1">
                <div className="tw-px-4 sm:tw-px-0">
                  <h3 className="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900">
                    Subscriptions
                  </h3>
                  <p className="tw-mt-1 tw-text-sm tw-leading-5 tw-text-gray-600">
                    This lists out the subscriptions that are currently on this
                    plan.
                  </p>
                </div>
              </div>
              <div className="tw-mt-5 md:tw-mt-0 md:tw-col-span-3">
                <Table
                  rows={subscriptions}
                  rowConditionals={value => ({
                    expires_at:
                      !isEmpty(value) &&
                      formatTimestamp(
                        value,
                        Intl.DateTimeFormat().resolvedOptions().timeZone,
                        false,
                        'MMMM Do YYYY'
                      )
                  })}
                  includedColumns={['account_name', 'status', 'expires_at']}
                  editable
                  onEdit={handleEdit}
                  emptyMessage="No Subscriptions tied to this plan"
                  hasFooter
                  FooterComponent={() =>
                    totalPages && totalPages > 1 ? (
                      <Pagination
                        totalPages={totalPages}
                        action={pageChange}
                        total={parseInt(total, 10)}
                        limit={20}
                      />
                    ) : null
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </form>

      <Modal
        headerText="Delete SMS-enabled Country"
        bodyText={`Are you sure you want to delete ${
          currentSmsEnabledCountry && currentSmsEnabledCountry.name
            ? ` the ${currentSmsEnabledCountry.name}`
            : ' this'
        } SMS-enabled Country?`}
        primaryAction={handleDeleteSmsEnabledCountry}
        primaryActionText="Delete"
        showSecondaryAction
        secondaryAction={handleModalSecondaryAction}
        show={showModal}
        setShow={handleModalSetShow}
        color="error"
        HeaderIcon={color => {
          return (
            <svg
              className={`tw-h-6 tw-w-6 tw-text-${color}-600`}
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
              />
            </svg>
          );
        }}
      />
    </div>
  );
};

PlanBuilder.defaultProps = {
  location: {
    state: {
      currentTier: ''
    }
  }
};

PlanBuilder.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      currentTier: PropTypes.string
    })
  })
};

export default PlanBuilder;
