import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const Header = ({
  imageUrl,
  imageClass,
  title,
  subtitle,
  showHeaderAction,
  noTitleBorder,
  headerClass,
  headerActionText,
  headerAction,
  headerActionIcon,
  header,
  headerStyle
}) => {
  return (
    <Fragment>
      {imageUrl && (
        <img src={imageUrl} alt="Not Found" className={imageClass} />
      )}
      {title || subtitle || showHeaderAction ? (
        <div
          className={`tw-px-4 tw-py-3 ${
            imageUrl
              ? ''
              : `tw-border-b tw-border-t-0 tw-border-l-0 tw-border-r-0 ${
                  noTitleBorder ? null : 'tw-border-solid'
                } tw-border-gray-200 tw-rounded-t-lg`
          } sm:tw-px-6`}
          style={headerStyle}
        >
          <div className="tw--ml-4 tw-flex tw-justify-between tw-items-center tw-flex-wrap sm:tw-flex-no-wrap">
            <div className={`tw-ml-4 tw-mt-2 ${headerClass}`}>
              {title && (
                <h3 className="tw-font-body tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
                  {title}
                </h3>
              )}
              {subtitle && (
                <p className="tw-font-body tw-mt-1 tw-mb-0 tw-text-sm tw-leading-5 tw-text-gray-500">
                  {subtitle}
                </p>
              )}
            </div>
            {showHeaderAction ? (
              <div className="tw-flex-shrink-0">
                <span className="tw-inline-flex tw-rounded-md tw-shadow-sm">
                  {headerActionText ? (
                    <button
                      type="button"
                      onClick={headerAction}
                      className="tw-relative tw-inline-flex tw-items-center tw-px-4 tw-py-2 tw-border tw-border-transparent tw-text-sm tw-leading-5 tw-font-medium tw-rounded-md tw-text-white tw-bg-indigo-600 hover:tw-bg-indigo-500 focus:tw-outline-none focus:tw-shadow-outline-indigo focus:tw-border-indigo-700 active:tw-bg-indigo-700"
                    >
                      {headerActionText}
                    </button>
                  ) : null}
                </span>
                {headerActionIcon ? (
                  <span
                    className="tw-cursor-pointer"
                    tabIndex="-1"
                    role="button"
                    data-testid="headerAction"
                    onKeyDown={e => {
                      if (e.code === 'Enter') headerAction();
                    }}
                    onClick={headerAction}
                  >
                    {typeof headerActionIcon === 'function' ? (
                      headerActionIcon()
                    ) : (
                      <headerActionIcon />
                    )}
                  </span>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
      {header}
    </Fragment>
  );
};

Header.defaultProps = {
  imageUrl: '',
  imageClass: '',
  title: '',
  subtitle: '',
  showHeaderAction: false,
  noTitleBorder: false,
  headerClass: '',
  headerActionText: '',
  headerAction: () => {},
  headerActionIcon: null,
  header: null,
  headerStyle: null
};

Header.propTypes = {
  imageUrl: PropTypes.string,
  imageClass: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  showHeaderAction: PropTypes.bool,
  noTitleBorder: PropTypes.bool,
  headerClass: PropTypes.string,
  headerActionText: PropTypes.string,
  headerAction: PropTypes.func,
  headerActionIcon: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  header: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  headerStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default Header;
