import React, { Fragment, useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { formikShape } from 'lib/utils/prop-types-extensions';
import PropTypes from 'prop-types';
import InfoPanel from 'components/Theme/InfoPanel';
import Card from 'components/Theme/Card';
import Table from 'components/Theme/Table';
import {
  validateSingleContactCsv,
  setCsvValidationError
} from 'appState/actions/ActionCreators';
import Modal from 'components/Theme/Modal';
import TextInput from 'components/Theme/TextInput';
import { createStructuredSelector } from 'reselect';
import Skeleton from 'react-loading-skeleton';
import { createLoadingSelector } from 'appState/selectors';
import isEmpty from 'lodash.isempty';
import { VALIDATE_HEADERS_INFO_PANEL } from '../common/constant';

const VALIDATE_KEY_NAME = 'validation_errors';

const ImportModal = ({
  isModelOpen,
  columnName,
  row,
  setModalOpen,
  handleRowValidation,
  activeIndex
}) => {
  const [activeRow, setActiveRow] = useState(row);

  useEffect(() => {
    setActiveRow(row);
  }, [row]);

  function updateActiveRow(key, value) {
    const updatedRow = { ...activeRow, [key]: value };
    setActiveRow(updatedRow);
  }

  function getModal() {
    return (
      <Modal
        color="alpha"
        show={isModelOpen}
        primaryAction={() => handleRowValidation(activeRow)}
        primaryActionText="Change"
        secondaryAction={() => setModalOpen(false)}
        showSecondaryAction
        secondaryActionText="Cancel"
        showHeaderIcon={false}
        shouldPrimaryActionClose={false}
        headerText="Validate Data"
      >
        <div className="tw-mx-auto tw-max-w-7xl">
          <div className="tw-shadow sm:tw-rounded-md sm:tw-overflow-hidden">
            <div className="tw-px-4 tw-py-5 tw-bg-white sm:tw-p-6">
              <div className="tw-grid tw-grid-cols-10 tw-gap-6">
                {columnName.map(data => {
                  if (data === VALIDATE_KEY_NAME) {
                    return;
                  }
                  return (
                    <TextInput
                      key={data}
                      id={`${activeIndex}-${data}`}
                      value={activeRow[data] || ''}
                      labelText={data}
                      onChange={e => updateActiveRow(data, e.target.value)}
                      widthClass="tw-col-span-10 sm:tw-col-span-5"
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  return <Fragment>{getModal()}</Fragment>;
};

ImportModal.propTypes = {
  isModelOpen: PropTypes.bool,
  columnName: PropTypes.arrayOf(PropTypes.string),
  row: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  setModalOpen: PropTypes.func,
  handleRowValidation: PropTypes.func,
  activeIndex: PropTypes.number
};

ImportModal.defaultProps = {
  isModelOpen: false,
  columnName: [],
  row: {},
  setModalOpen: () => {},
  handleRowValidation: () => {},
  activeIndex: 0
};
const ImportValidate = ({ formik }) => {
  const [isModelOpen, setModalOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const dispatch = useDispatch();
  const DUPLICATE_PHONE =
    'There is another contact in this import with this phone number';

  const csvLoadingSelector = createLoadingSelector([
    'custom_group/VALIDATE_CONTACTS_CSV'
  ]);
  const validateCsvLoadingSelector = createLoadingSelector([
    'custom_group/VALIDATE_SINGLE_CONTACT_CSV'
  ]);

  const structuredSelector = createStructuredSelector({
    importedContact: state => state.contact.importedContact,
    csvLoading: state => csvLoadingSelector(state),
    validateCsvLoading: state => validateCsvLoadingSelector(state)
  });

  const { importedContact, csvLoading, validateCsvLoading } = useSelector(
    structuredSelector
  );

  function handleRowValidation(activeRow) {
    setModalOpen(false);
    const duplicatePhoneExist = importedContact.filter(
      (row, index) => row.phone === activeRow.phone && index !== activeIndex
    );
    if (duplicatePhoneExist && duplicatePhoneExist.length >= 1) {
      let validationError = activeRow.validation_errors;
      if (!validationError.indexOf(DUPLICATE_PHONE) > -1) {
        validationError = isEmpty(validationError)
          ? `${validationError}, And ${DUPLICATE_PHONE}`
          : DUPLICATE_PHONE;
      }
      const updatedRow = { ...activeRow, validation_errors: validationError };
      return dispatch(
        setCsvValidationError({
          activeIndex,
          updatedRow
        })
      );
    }
    dispatch(
      validateSingleContactCsv({
        data: activeRow,
        assignedStaffId: formik.values.assignedStaffId,
        activeIndex
      })
    );
  }

  function getColumnName() {
    if (importedContact?.length) {
      return Object.keys(importedContact[0]);
    }
  }

  const columnName = useMemo(() => getColumnName(), [importedContact]);

  function getTable() {
    if (csvLoading || validateCsvLoading) {
      return <Skeleton height={60} />;
    }

    return (
      <Table
        rows={importedContact}
        customTableClass="fixedTable tw-overflow-auto"
        validationKey={VALIDATE_KEY_NAME}
        fixedColumnKey={VALIDATE_KEY_NAME}
        includedColumns={columnName}
        onRowClick={(row, index) => {
          setModalOpen(true);
          setActiveIndex(index);
        }}
        fixedColumnWidth={500}
        emptyMessage="No values present."
        showColumnValueTitle
      />
    );
  }

  return (
    <Fragment>
      <InfoPanel
        title="Things to Know"
        infoItems={VALIDATE_HEADERS_INFO_PANEL}
      />
      <div key="header">
        <Card containerClass="tw-rounded-md tw-mt-5 tw-border-2" noTitleBorder>
          {getTable()}
        </Card>
      </div>
      {columnName?.length && (
        <ImportModal
          isModelOpen={isModelOpen}
          columnName={columnName}
          row={importedContact[activeIndex]}
          setModalOpen={setModalOpen}
          activeIndex={activeIndex}
          handleRowValidation={handleRowValidation}
        />
      )}
    </Fragment>
  );
};

ImportValidate.propTypes = {
  formik: PropTypes.shape(formikShape).isRequired
};
export default ImportValidate;
