import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { CurrentAccountContext } from 'components/shared/context/CurrentAccountContext';
import {
  createErrorMessageSelector,
  createLoadedSelector
} from 'appState/selectors';
import {
  fetchPinnedActivities,
  fetchActivities,
  activitiesPageChange,
  updateActivitiesFeed
} from 'appState/actions/ActionCreators';
import { FETCH_ACTIVITIES } from 'appState/actions/constants/activity.actions';
import isEmpty from 'lodash.isempty';
import ActionCable from 'components/ActionCable';
import PinnedActivities from 'components/Contact/Profile/PinnedActivities';
import RecentActivities from 'components/Contact/Profile/RecentActivities';
import CallToAction from './CallToAction';
import UpcomingSection from './Upcoming';

const FeedTab = ({ setShowAddAutomationModal }) => {
  const dispatch = useDispatch();
  const errorSelector = createErrorMessageSelector([FETCH_ACTIVITIES]);
  const loadedSelector = createLoadedSelector([FETCH_ACTIVITIES]);

  const structuredSelector = createStructuredSelector({
    currentContact: state => state.contact.currentContact,
    pinnedActivities: state => state.activity.pinnedActivities,
    activities: state => state.activity.activities,
    total: state => state.activity.total,
    totalPages: state => state.activity.totalPages,
    limit: state => state.activity.limit,
    error: state => errorSelector(state),
    isLoaded: state => loadedSelector(state)
  });

  const {
    currentContact,
    pinnedActivities,
    activities,
    total,
    totalPages,
    limit,
    isLoaded
  } = useSelector(structuredSelector);

  const currentAccount = useContext(CurrentAccountContext);

  useEffect(() => {
    if (isEmpty(pinnedActivities) && !isEmpty(currentContact))
      dispatch(
        fetchPinnedActivities({
          contactId: currentContact.id
        })
      );
  }, [dispatch, currentContact]);

  useEffect(() => {
    if (isEmpty(activities) && !isEmpty(currentContact))
      dispatch(
        fetchActivities({
          contactId: currentContact.id
        })
      );
  }, [dispatch, currentContact]);

  const handleUpdatedActivityFeed = activity => {
    dispatch(updateActivitiesFeed(activity));
  };

  return (
    <React.Fragment>
      {!isEmpty(currentContact) ? (
        <ActionCable
          channel={{
            channel: 'ActivityFeedApiChannel',
            contactId: currentContact.id
          }}
          onReceived={handleUpdatedActivityFeed}
        >
          <div className="tw-py-5">
            <div className="tw-grid tw-grid-cols-12 tw-gap-6">
              <div className="tw-col-span-12">
                <CallToAction
                  setShowAddAutomationModal={setShowAddAutomationModal}
                />
              </div>
              <UpcomingSection currentContact={currentContact} />
              <div className="tw-col-span-12 tw-px-4">
                <PinnedActivities activities={pinnedActivities} />
                <RecentActivities
                  activities={activities}
                  total={total}
                  totalPages={totalPages}
                  limit={limit}
                  currentContact={currentContact}
                  currentAccount={currentAccount}
                  isLoaded={isLoaded}
                  resetAction={FETCH_ACTIVITIES}
                  pageChangeAction={activitiesPageChange}
                />
              </div>
            </div>
          </div>
        </ActionCable>
      ) : null}
    </React.Fragment>
  );
};

FeedTab.defaultProps = {
  setShowAddAutomationModal: () => null
};

FeedTab.propTypes = {
  setShowAddAutomationModal: PropTypes.func
};

export default FeedTab;
