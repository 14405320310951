// eslint-disable-next-line react/no-danger
import React from 'react';
import PropTypes from 'prop-types';

const Link = ({ color, text, onClick, icon, iconBefore, href, target }) => {
  function handleOnKeyDown(e) {
    if (e && e.code !== 'Enter') return;
    onClick();
  }

  const optionalProps = {};
  if (href) optionalProps.href = href;

  return (
    <div className="tw-text-sm tw-leading-5">
      <a
        className={`tw-font-body tw-cursor-pointer tw-font-medium tw-text-${color}-600 hover:tw-text-${color}-500 tw-transition tw-ease-in-out tw-duration-150`}
        onClick={onClick}
        onKeyDown={handleOnKeyDown}
        role="button"
        tabIndex={-1}
        target={target}
        data-testid="link"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...optionalProps}
      >
        {iconBefore ? (
          <span dangerouslySetInnerHTML={{ __html: icon }} />
        ) : null}
        {iconBefore ? ' ' : null}
        {text}
        {iconBefore ? null : ' '}
        {iconBefore ? null : (
          <span dangerouslySetInnerHTML={{ __html: icon }} />
        )}
      </a>
    </div>
  );
};

Link.defaultProps = {
  onClick: null,
  color: 'alpha',
  icon: '&rarr;',
  iconBefore: false,
  href: '',
  target: '_self'
};

Link.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  icon: PropTypes.string,
  iconBefore: PropTypes.bool,
  color: PropTypes.oneOf([
    'alpha',
    'bravo',
    'charlie',
    'success',
    'warning',
    'error',
    'gray'
  ]),
  href: PropTypes.string,
  target: PropTypes.string
};

export default Link;
