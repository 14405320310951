import React, { useContext, useEffect, useRef, useState } from 'react';
import Button from 'components/Theme/Button';
import { useDispatch, useSelector } from 'react-redux';
import { TABLE_ROW_LIMIT } from 'config/constants';
import {
  createFileUpload,
  resetIsLoaded,
  fetchUploadedFilesList
} from 'appState/actions/ActionCreators';

import {
  createErrorMessageSelector,
  createLoadingSelector,
  createLoadedSelector
} from 'appState/selectors';
import isEmpty from 'lodash.isempty';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import FileIcons from 'components/Theme/Icon/FileIcons';
import { CurrentAccountContext } from 'components/shared/context/CurrentAccountContext';
import Pagination from 'components/Theme/Pagination';
import Table from 'components/Theme/Table';
import get from 'lodash.get';
import Notification from 'components/Theme/Notification';
import NewDropZone from 'components/Theme/DropZone';

const DocumentTab = ({ contactId, isDummy }) => {
  const dispatch = useDispatch();
  const currentAccount = useContext(CurrentAccountContext);
  const MAX_SIZE = 1024 * 1024 * 25;
  const [showSuccess, setShowSuccess] = useState(false);
  const [acceptedFiles, setAcceptedFile] = useState([]);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [headerTextVal, setHeaderTextVal] = useState('');
  const [colorVal, setColorVal] = useState('success');

  const errorSelector = createErrorMessageSelector([
    'contact/CREATE_FILE_UPLOAD'
  ]);
  const loadingSelector = createLoadingSelector(['contact/CREATE_FILE_UPLOAD']);
  const loadedSelector = createLoadedSelector(['contact/CREATE_FILE_UPLOAD']);

  const structuredSelector = createStructuredSelector({
    uploadedFiles: state => state.contact.uploadedFiles,
    error: state => errorSelector(state),
    isLoading: state => loadingSelector(state),
    isLoaded: state => loadedSelector(state)
  });

  const includedColumns = {
    fileName: 'File Name',
    uploadedAt: 'Uploaded At'
  };

  const { uploadedFiles, error, isLoaded } = useSelector(structuredSelector);
  let uploadedFilesList = [];

  const handleDateFormat = date => {
    const d = new Date(date);
    const dformat = `${[d.getMonth() + 1, d.getDate(), d.getFullYear()].join(
      '/'
    )} ${[d.getHours(), d.getMinutes(), d.getSeconds()].join(':')}`;
    return dformat;
  };

  const handleDocumentClick = url => {
    if (url) {
      window.open(url, '_blank');
    }
  };

  if (uploadedFiles && uploadedFiles.length) {
    uploadedFilesList = uploadedFiles.map(fileObj => {
      return {
        id: fileObj.id,
        fileName:
          fileObj && fileObj.file.url
            ? fileObj.file.url.split('/')[5].split('?')[0]
            : 'Demo File',
        uploadedAt:
          fileObj && fileObj.updated_at
            ? handleDateFormat(fileObj.updated_at)
            : '',
        fileUrl: fileObj && fileObj.file.url ? fileObj.file.url : null
      };
    });
  }

  const [totalItems, setTotalItems] = useState(
    uploadedFilesList.length ? uploadedFilesList.length : 0
  );
  const totalPages = Math.ceil(totalItems / TABLE_ROW_LIMIT);
  const prevDisplayRef = useRef();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState(
    uploadedFilesList.length
      ? uploadedFilesList.slice(0, TABLE_ROW_LIMIT)
      : uploadedFiles
  );

  const pageChange = (data = null) => {
    const pageData = get(data, 'page');
    let pageNum = 1;
    pageNum = pageData || currentPage;
    const pageCal = (pageNum - 1) * TABLE_ROW_LIMIT;
    const multipleRows = uploadedFiles
      ? uploadedFilesList.slice(pageCal, pageCal + TABLE_ROW_LIMIT)
      : '';
    setTotalItems(uploadedFilesList ? uploadedFilesList.length : 0);
    if (!isEmpty(multipleRows)) {
      setCurrentPage(pageNum);
      setCurrentData(multipleRows);
    }
  };

  const handleNotificationText = (notMessage, headerVal, colorValue) => {
    setNotificationMessage(notMessage);
    setHeaderTextVal(headerVal);
    setColorVal(colorValue);
  };

  function handleSetShowSuccess(show, file) {
    if (file && file[0]) {
      if (file[0].size < MAX_SIZE) {
        handleNotificationText('File attached', 'Success!', 'success');
        setAcceptedFile([...acceptedFiles, file[0]]);
      } else {
        handleNotificationText('File size exceeded.', 'Error!', 'error');
      }
      setShowSuccess(show);
      setTimeout(() => {
        setShowSuccess(false);
      }, 2000);
    }
  }
  const handleSubmit = values => {
    if (!values) {
      return;
    }
    const fileParams = { file: values };
    dispatch(createFileUpload({ contactId, fileParams }));
  };

  const handleSuccessfullyUpload = () => {
    handleNotificationText('File uploaded', 'Success!', 'success');
    setShowSuccess(true);
    setAcceptedFile([]);
    setTimeout(() => {
      setShowSuccess(false);
    }, 2000);
    if (!isDummy) dispatch(fetchUploadedFilesList({ contactId }));
  };
  const handleUploadFailed = () => {
    handleNotificationText('File not uploaded', 'Error!', 'error');
    setShowSuccess(true);
    setAcceptedFile([]);
    setTimeout(() => {
      setShowSuccess(false);
    }, 4000);
  };
  useEffect(() => {
    if (isLoaded && isEmpty(error)) {
      handleSuccessfullyUpload();
    } else if (isLoaded && !isEmpty(error)) {
      handleUploadFailed(error);
    }
    dispatch(resetIsLoaded('contact/CREATE_FILE_UPLOAD'));

    return () => {
      dispatch(resetIsLoaded('contact/CREATE_FILE_UPLOAD'));
    };
  }, [error, isLoaded, dispatch]);

  useEffect(() => {
    prevDisplayRef.current = true;
    pageChange();
  }, [uploadedFiles]);

  useEffect(() => {
    if (isEmpty(uploadedFiles)) dispatch(fetchUploadedFilesList({ contactId }));
  }, [dispatch, currentAccount]);

  return (
    <div>
      <NewDropZone handleSetShowSuccess={handleSetShowSuccess} />
      <Notification
        message={notificationMessage}
        setShowAction={handleSetShowSuccess}
        show={showSuccess}
        type="colored"
        headerText={headerTextVal}
        color={colorVal}
        HeaderIcon={color => {
          return headerTextVal === 'Success!' ? (
            <svg
              className={`tw-h-5 tw-w-5 tw-text-${color}-400`}
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          ) : (
            <svg
              className={`tw-h-5 tw-w-5 tw-text-${color}-400`}
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                clipRule="evenodd"
              />
            </svg>
          );
        }}
      />
      <div className="tw-py-5">
        <div className="" style={{ float: 'right', position: 'relative' }}>
          <Button
            buttonRef={null}
            color="alpha"
            disabled={!acceptedFiles.length}
            onClick={() => {
              handleSubmit(acceptedFiles);
            }}
            text="Upload File"
            type="primary"
          />
        </div>
        <div className="tw-flex tw-justify-between">
          <div className="tw-grid tw-grid-cols-12 tw-gap-5">
            {acceptedFiles.length
              ? acceptedFiles.map((file, index) => (
                  <FileIcons key={index} acceptedFile={file} />
                ))
              : ''}
          </div>
        </div>
        <div
          className={`flex flex-col${acceptedFiles.length ? '' : ' tw-py-20'}`}
        >
          <div className="tw-font-body tw-mt-1 tw-mb-0 tw-text-sm tw-leading-5 tw-text-gray-500">
            <h3>Uploaded Documents</h3>
          </div>
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="tw-py-5">
              <div className="tw-border-t tw-border-b-0 tw-border-l-0 tw-border-r-0 tw-border-solid tw-border-gray-200" />
            </div>
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <Table
                  title=""
                  subtitle=""
                  rows={currentData}
                  handleDocumentClick={handleDocumentClick}
                  includedColumns={includedColumns}
                  hasFooter
                  viewable
                  FooterComponent={() =>
                    totalPages && totalPages > 1 ? (
                      <Pagination
                        total={totalItems}
                        totalPages={totalPages}
                        limit={TABLE_ROW_LIMIT}
                        action={pageChange}
                      />
                    ) : null
                  }
                  emptyMessage="No File Uploaded"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

DocumentTab.defaultProps = {
  contactId: '',
  isDummy: false
};
DocumentTab.propTypes = {
  contactId: PropTypes.string,
  isDummy: PropTypes.bool
};

export default DocumentTab;
