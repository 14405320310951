import React from 'react';
import PropTypes from 'prop-types';

const PortalContext = React.createContext();

const PortalProvider = props => {
  const { children, value } = props;
  return (
    <PortalContext.Provider value={value}>{children}</PortalContext.Provider>
  );
};

PortalProvider.defaultProps = {
  children: null,
  value: null
};

PortalProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  value: PropTypes.objectOf(PropTypes.object)
};

export { PortalContext, PortalProvider };
