import styled from 'styled-components';

const Footer = styled.footer`
  position: fixed;
  width: calc(100% - 68px);
  margin-top: -30px;
`;

const Styled = {
  Footer
};

export default Styled;
