import React from 'react';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import DataTable from 'react-data-table-component';
import {
  createErrorMessageSelector,
  createLoadedSelector
} from 'appState/selectors';
import { titleize } from 'lib/utils/string';
import InfoPanel from 'components/Theme/InfoPanel';

const BroadcastMetricsModal = () => {
  const errorSelector = createErrorMessageSelector([
    'broadcastCenter/BROADCASTS_FETCH_EMAILS',
    'broadcastCenter/BROADCASTS_FETCH_CONTACTS'
  ]);
  const loadedSelector = createLoadedSelector([
    'broadcastCenter/BROADCASTS_FETCH_EMAILS',
    'broadcastCenter/BROADCASTS_FETCH_CONTACTS'
  ]);
  const structuredSelector = createStructuredSelector({
    emails: state => state.broadcastCenter.emails,
    contacts: state => state.broadcastCenter.contacts,
    broadcastMetric: state => state.broadcastCenter.broadcastMetric,
    broadcastType: state => state.broadcastCenter.broadcastType,
    error: state => errorSelector(state),
    isLoaded: state => loadedSelector(state)
  });

  const {
    emails,
    contacts,
    broadcastMetric,
    broadcastType,
    isLoaded
  } = useSelector(structuredSelector);

  const hasErrors = emails && emails[0] && emails[0].error_message;
  const isErred = broadcastMetric === 'erred';
  const metricsData = isErred ? emails : contacts;

  const columns = [
    {
      name: 'First Name',
      selector: row => (isErred ? row.contact.first_name : row.first_name),
      sortable: true,
      grow: hasErrors ? 0 : 1
    },
    {
      name: 'Last Name',
      selector: row => (isErred ? row.contact.last_name : row.last_name),
      sortable: true,
      grow: hasErrors ? 0 : 1
    },
    {
      name: `${broadcastType === 'email' ? 'Email' : 'Phone'}`,
      selector: row =>
        isErred
          ? row.contact[broadcastType === 'email' ? 'email' : 'formatted_phone']
          : row[broadcastType === 'email' ? 'email' : 'formatted_phone'],
      sortable: true,
      grow: 1,
      wrap: true
    },
    {
      name: 'Error Message',
      selector: row => row.error_message,
      omit: !hasErrors,
      grow: 2,
      wrap: true
    },
    {
      name: 'Error Description',
      selector: row => row.error_description,
      omit: !hasErrors,
      grow: 2,
      wrap: true
    }
  ];

  const customStyles = {
    header: {
      style: {
        minHeight: '0px'
      }
    },
    headCells: {
      style: {
        fontSize: '13px',
        fontWeight: '400 !important'
      }
    },
    cells: {
      style: {
        fontWeight: '300 !important'
      }
    }
  };

  const CustomLoader = () => {
    return (
      <svg className="spinner-circle-material-svg" viewBox="0 0 50 50">
        <circle className="circle" cx="25" cy="25" r="20" />
      </svg>
    );
  };

  const modalHeaderTitles = {
    email: {
      scheduled: 'Email Schedule Details',
      sent: 'Email Audience Details',
      opened: 'Email Open Details',
      clicked: 'Email Click Details',
      unsubscribed: 'Email Unsubscribe Details',
      erred: 'Email Error Details'
    },
    sms: { scheduled: 'SMS Schedule Details', sent: 'SMS Audience Details' }
  };

  return (
    <div
      className="modal fade show modal-center"
      id="broadcast-center-metrics-modal"
      tabIndex="-1"
      aria-modal="true"
    >
      <div className="modal-dialog modal-lg" style={{ maxHeight: '90vh' }}>
        <div className="modal-content">
          <div className="modal-header">
            {broadcastType && broadcastMetric && (
              <h4 className="modal-title" id="broadcast-metrics-title">
                {titleize(modalHeaderTitles[broadcastType][broadcastMetric])}
              </h4>
            )}
            <button type="button" className="close" data-dismiss="modal">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div>
              {broadcastType &&
                broadcastMetric &&
                modalHeaderTitles[broadcastType][broadcastMetric] ===
                  'Email Unsubscribe Details' && (
                  <div>
                    <InfoPanel
                      color="alpha"
                      infoItems={[
                        'Recently deleted contacts will not appear on this list.'
                      ]}
                      orderedList={false}
                      showSvg
                      title="Note"
                      withColon={false}
                    />
                  </div>
                )}
            </div>
            <div
              className="scrollable p-4 center-vh"
              id="broadcast-metrics-body"
              style={{
                overflowY: 'scroll'
              }}
            >
              <DataTable
                data={metricsData}
                columns={columns}
                customStyles={customStyles}
                pagination
                highlightOnHover
                pointerOnHover
                progressPending={!isLoaded}
                progressComponent={<CustomLoader />}
              />
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-info" data-dismiss="modal">
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BroadcastMetricsModal;
