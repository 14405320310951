import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { resetAutoCompleteSuggestions } from 'appState/actions/ActionCreators';
import { createLoadingSelector } from 'appState/selectors';
import Autosuggest from 'react-autosuggest';
import isEmpty from 'lodash.isempty';
import useDebounce from 'components/shared/hooks/useDebounce';
import { theme } from './styles';

function escapeRegexCharacters(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

const getSuggestionValue = suggestion =>
  `${suggestion.displayName}\t${suggestion.value}`;

function renderSuggestionList(suggestion) {
  return (
    <span className="tw-flex tw-items-center">
      <span className="tw-ml-2 tw-p-1">{suggestion.displayName}</span>
    </span>
  );
}

const EXTERNAL_LEFT_PADDING = '2rem';

const AutoCompleteDropdown = props => {
  const {
    suggestions,
    widthClass,
    handleChange,
    handleSelection,
    displayValue,
    labelText,
    DisplayIcon,
    id,
    placeholder
  } = props;

  const dispatch = useDispatch();

  const loadingSelector = createLoadingSelector([
    'auto_complete/FETCH_AUTO_COMPLETE_SUGGESTIONS'
  ]);

  const structuredSelector = createStructuredSelector({
    isLoading: state => loadingSelector(state)
  });

  const { isLoading } = useSelector(structuredSelector);

  const [_displayValue, setDisplayValue] = useState(displayValue || '');

  const [query, setQuery] = useState(_displayValue || '');
  const debouncedQuery = useDebounce(query, 400);

  const getDefaultTheme = () => {
    if (!DisplayIcon) return theme;
    return {
      ...theme,
      input: { ...theme.input, paddingLeft: EXTERNAL_LEFT_PADDING }
    };
  };

  const extractValue = val => {
    return val && val.split('\t')[1] ? val.split('\t')[1] : '';
  };

  const extractQuery = val => {
    return val && val.split('\t')[0] ? val.split('\t')[0] : '';
  };

  const onChange = (event, { newValue }) => {
    const q = extractQuery(escapeRegexCharacters(newValue));
    const selectionValue = extractValue(newValue);

    if (isEmpty(selectionValue)) {
      setDisplayValue(q);
      setQuery(q);
    }
  };

  useEffect(() => {
    if (debouncedQuery.trim() !== '') {
      handleChange(debouncedQuery);
    }
  }, [debouncedQuery]);

  useEffect(() => {
    // set _displayValue state whenever displayValue prop from parent changes
    if (displayValue) {
      setDisplayValue(displayValue);
    }
  }, [displayValue]);

  function renderSuggestion() {
    return true;
  }

  function onSuggestionSelected(event, { suggestion }) {
    setDisplayValue(suggestion.displayName);
    handleSelection(suggestion.value);
    dispatch(resetAutoCompleteSuggestions());
  }

  const inputProps = {
    placeholder,
    className: isLoading ? 'tw-input-loading' : '',
    value: (_displayValue && _displayValue.toString()) || '',
    onChange
  };

  // eslint-disable-next-line react/prop-types
  const renderSuggestionsContainer = ({ containerProps, children }) => {
    const noResultsHeader = (
      <div className="tw-py-5 tw-font-medium tw-text-sm tw-text-gray-700 tw-text-center">
        No results found for: {query}
      </div>
    );
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <div {...containerProps}>
        {!isEmpty(suggestions) && children}
        {isEmpty(suggestions) && query.length > 0 && noResultsHeader}
      </div>
    );
  };

  const renderInputComponent = _inputProps => (
    <div className="tw-flex tw-relative">
      {DisplayIcon && (
        <div className="tw-absolute tw-inset-y-0 tw-left-0 tw-pl-2 tw-flex tw-items-center tw-pointer-events-none">
          {typeof DisplayIcon === 'function' ? DisplayIcon() : DisplayIcon}
        </div>
      )}
      <input
        // eslint-disable-next-line react/jsx-props-no-spreading
        {..._inputProps}
        className={`${
          DisplayIcon ? 'tw-pl-10' : ''
        }  tw-font-body tw-form-input tw-block tw-w-full tw-py-2 tw-px-3 tw-border tw-border-solid tw-border-gray-300 tw-shadow-sm focus:tw-outline-none focus:tw-shadow-outline-blue focus:tw-border-blue-300 tw-transition tw-duration-150 tw-ease-in-out tw-text-sm tw-leading-5 tw-rounded-md`}
      />
    </div>
  );

  return (
    <div className={`tw-relative ${widthClass}`}>
      <label
        htmlFor={id}
        className="tw-font-body tw-block tw-text-sm tw-font-medium tw-leading-5 tw-text-gray-700"
      >
        {labelText}
      </label>
      <Autosuggest
        suggestions={
          isEmpty(suggestions) && !isLoading ? ['invalid'] : suggestions
        }
        onSuggestionsFetchRequested={() => null}
        onSuggestionsClearRequested={() => null}
        onSuggestionSelected={onSuggestionSelected}
        getSuggestionValue={getSuggestionValue}
        shouldRenderSuggestions={renderSuggestion}
        renderSuggestion={renderSuggestionList}
        highlightFirstSuggestion
        renderSuggestionsContainer={renderSuggestionsContainer}
        inputProps={inputProps}
        theme={getDefaultTheme()}
        renderInputComponent={renderInputComponent}
      />
    </div>
  );
};

AutoCompleteDropdown.defaultProps = {
  widthClass: 'tw-col-span-6',
  displayValue: '',
  labelText: 'Referred By',
  DisplayIcon: null,
  id: 'referredId',
  placeholder: 'Select Contact'
};

const suggestionShape = PropTypes.shape({
  displayName: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ])
});

AutoCompleteDropdown.propTypes = {
  suggestions: PropTypes.arrayOf(suggestionShape).isRequired,
  handleSelection: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  widthClass: PropTypes.string,
  displayValue: PropTypes.string,
  labelText: PropTypes.string,
  DisplayIcon: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  id: PropTypes.string,
  placeholder: PropTypes.string
};
export default AutoCompleteDropdown;
