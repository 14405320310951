import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';
import { textTruncate } from 'lib/utils/string';
import Modal from 'components/Theme/Modal';
import Image from 'components/Theme/Image';

const SmsPreviewModal = ({ smsBody, smsMedia, show, setShow }) => {
  const [trimmedSmsBody, setTrimmedSmsBody] = useState(smsBody);

  function handleClose() {
    setShow(false);
  }

  useEffect(() => {
    setTrimmedSmsBody(smsBody.trim());
  }, [smsBody]);

  return (
    <Modal
      color="alpha"
      size="2xl"
      headerText="Preview"
      showHeaderIcon={false}
      setShow={setShow}
      show={show}
      showPrimaryAction={false}
      secondaryAction={handleClose}
      secondaryActionText="Close"
      showSecondaryAction
      headerClasses="tw-px-4"
      innerContainerClasses="tw-px-0 tw-pt-5 tw-pb-4"
      showDivider
    >
      <div className="tw-pt-4">
        <div className="tw-flex tw-px-8 tw-flex-row-reverse">
          {!isEmpty(trimmedSmsBody) && (
            <p
              className={`tw-bg-alpha-600 tw-mb-1 tw-text-white tw-float-right tw-clear-both tw-inline-block tw-mb-1 tw-px-6 tw-py-4 tw-text-sm tw-leading-4 ${
                smsMedia
                  ? 'tw-rounded-l-full tw-rounded-tr-full tw-rounded-br'
                  : 'tw-rounded-full'
              }`}
            >
              {isEmpty(trimmedSmsBody) && isEmpty(smsMedia)
                ? 'Template has no saved sms body. Update template to add an sms body...'
                : textTruncate(trimmedSmsBody)}
            </p>
          )}
        </div>
        <div
          className={`tw-flex tw-px-8 tw-flex-row-reverse ${
            // eslint-disable-next-line camelcase
            !isEmpty(smsMedia) && smsMedia?.content_type?.includes('image')
              ? 'tw-block'
              : 'tw-hidden'
          }`}
        >
          {/* MMS Media Preview */}
          <p
            className={`tw-gap-1 tw-rounded-l-full tw-inline-block tw-pt-1 tw-clear-both tw-float-right ${
              isEmpty(trimmedSmsBody)
                ? 'tw-rounded-br-full'
                : 'tw-rounded-r-full'
            }`}
          >
            <span
              style={{
                maxWidth: '240px',
                maxHeight: '240px',
                position: 'relative',
                overflow: 'hidden'
              }}
            >
              <Image
                alt="MMS Attachment"
                imageURL={smsMedia?.url}
                className={`tw-rounded-l-lg ${
                  isEmpty(trimmedSmsBody)
                    ? 'tw-rounded-r-lg'
                    : 'tw-rounded-br-lg'
                }`}
              />
            </span>
          </p>
          {/* eslint-disable-next-line camelcase */}
          {!isEmpty(smsMedia) && smsMedia?.content_type?.includes('video') && (
            // eslint-disable-next-line jsx-a11y/media-has-caption
            <video controls width="320" height="240">
              <source src={smsMedia?.url} type="video/mp4" />
            </video>
          )}
        </div>
        <div className="tw-flex tw-px-8">
          <p className="tw-bg-gray-200 tw-rounded-full tw-mb-1 tw-inline-block tw-mb-1 tw-px-6 tw-py-4 tw-text-sm tw-leading-4">
            ...
          </p>
        </div>
      </div>
    </Modal>
  );
};

SmsPreviewModal.defaultProps = {
  smsBody: '',
  smsMedia: {},
  show: false,
  setShow: () => null
};

SmsPreviewModal.propTypes = {
  smsBody: PropTypes.string,
  smsMedia: PropTypes.objectOf(PropTypes.string),
  show: PropTypes.bool,
  setShow: PropTypes.func
};

export default SmsPreviewModal;
