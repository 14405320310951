// linting rules disabled for immer js
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  FETCH_AUTO_COMPLETE_SUGGESTIONS_SUCCESS,
  RESET_AUTO_COMPLETE_SUGGESTIONS
} from 'appState/actions/constants/autoComplete.actions';

const initialState = {
  suggestions: []
};

export default function AutoCompleteReducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case FETCH_AUTO_COMPLETE_SUGGESTIONS_SUCCESS: {
        const { suggestions } = action.payload;
        draft.suggestions = suggestions;
        return;
      }
      case RESET_AUTO_COMPLETE_SUGGESTIONS: {
        draft.suggestions = [];
        return;
      }
      default:
        return draft;
    }
  });
}
