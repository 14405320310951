import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { formikShape } from 'lib/utils/prop-types-extensions';
import { UsersContext } from 'components/shared/context/UsersContext';
import {
  Label,
  RadioInput
} from 'components/ProductSubscriptionSetup/shared/FormInputs';
import * as StepConstants from 'components/ProductSubscriptionSetup/shared/StepConstants';
import { createStructuredSelector } from 'reselect';
import { useSelector } from 'react-redux';
import Select from 'components/Theme/Select';
import get from 'lodash.get';

const StepTwo = ({ formik }) => {
  const { usersError, isUsersLoaded, users } = useContext(UsersContext);
  const staffSelectOptions = users.map(user => {
    return { displayName: user.full_name, value: user.id };
  });
  const [showSelectLead, setShowSelectLead] = useState(
    formik.values.assignedStaff === StepConstants.DIFFERENT_STAFF
  );

  const structuredSelector = createStructuredSelector({
    appointmentTypes: state => state.contact.appointmentTypes
  });

  const { appointmentTypes } = useSelector(structuredSelector);

  useEffect(() => {
    // debugger;
    formik.setFieldValue(
      'appointmentTypeName',
      get(
        appointmentTypes.find(
          appt =>
            appt.id.toString() === formik.values.appointmentTypeId?.toString()
        ),
        'name'
      )
    );
  }, []);

  const changeLeadAssignedTo = value => {
    formik.setFieldValue('assignedStaff', value);
    setShowSelectLead(!showSelectLead);
  };

  return (
    <div className="tw-p-10 tw-pt-0">
      <form action="#" method="POST">
        <div className="tw-grid tw-grid-cols-12 tw-gap-6">
          <div className="tw-col-span-12">
            <Label text="Who should leads be assigned to?" />
            <RadioInput
              name="assignedStaff"
              id={StepConstants.ASSIGNED_STAFF}
              value={StepConstants.ASSIGNED_STAFF}
              labelText={`The staff member(s) assigned to "${formik.values.appointmentTypeName}" appointment type`}
              checked={
                formik.values.assignedStaff === StepConstants.ASSIGNED_STAFF
              }
              onChange={() =>
                changeLeadAssignedTo(StepConstants.ASSIGNED_STAFF)
              }
              onBlur={formik.handleBlur}
            />
            <RadioInput
              name="assignedStaff"
              id={StepConstants.DIFFERENT_STAFF}
              value={StepConstants.DIFFERENT_STAFF}
              labelText="A different staff member"
              checked={
                formik.values.assignedStaff === StepConstants.DIFFERENT_STAFF
              }
              onChange={() =>
                changeLeadAssignedTo(StepConstants.DIFFERENT_STAFF)
              }
              onBlur={formik.handleBlur}
            />
            {showSelectLead && (
              <Select
                id="assignedStaffId"
                value={formik.values.assignedStaffId}
                options={isUsersLoaded && !usersError ? staffSelectOptions : []}
                labelText="Select Staff Member"
                placeholder="Select Staff Member"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                widthClass="lg:tw-w-1/3 sm:tw-w-1/2 xxs:tw-w-full"
              />
            )}
          </div>
          <div className="tw-col-span-12">
            <Label text="Who should leads be allowed to book with?" />
            <RadioInput
              name="bookedStaff"
              id={StepConstants.ANY_ASSIGNED_STAFF}
              value={StepConstants.ANY_ASSIGNED_STAFF}
              labelText={`ANY of the staff members assigned to your "${formik.values.appointmentTypeName}" appointment type`}
              checked={
                formik.values.bookedStaff === StepConstants.ANY_ASSIGNED_STAFF
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <RadioInput
              name="bookedStaff"
              id={StepConstants.ANY_ASSIGNED_STAFF_REASSIGN}
              value={StepConstants.ANY_ASSIGNED_STAFF_REASSIGN}
              labelText={`ANY of the staff members assigned to your "${formik.values.appointmentTypeName}" appointment type, AND reassign the contact to the staff member handling the appointment`}
              checked={
                formik.values.bookedStaff ===
                StepConstants.ANY_ASSIGNED_STAFF_REASSIGN
              }
              onChange={formik.handleChange}
              onBlur={formik.handleblur}
            />
            <RadioInput
              name="bookedStaff"
              id={StepConstants.ONLY_ASSINGNED_STAFF}
              value={StepConstants.ONLY_ASSINGNED_STAFF}
              labelText="Only their assigned staff member"
              checked={
                formik.values.bookedStaff === StepConstants.ONLY_ASSINGNED_STAFF
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

StepTwo.propTypes = {
  formik: PropTypes.shape(formikShape).isRequired
};

export default StepTwo;
