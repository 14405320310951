import React, { useContext, useEffect, useState } from 'react';
import UtilityCard from 'components/OverlordUtilities/shared/UtilityCard';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import get from 'lodash.get';
import isEmpty from 'lodash.isempty';
import { CurrentAccountContext } from 'components/shared/context/CurrentAccountContext';
import { toSentence } from 'lib/utils/string';
import {
  createErrorMessageSelector,
  createLoadedSelector
} from 'appState/selectors';
import {
  changeSubdomain,
  fetchSubdomains,
  resetIsLoaded
} from 'appState/actions/ActionCreators';
import useProcessingActivity from 'components/shared/hooks/useProcessingActivity';
import useAccountDisabled from 'components/shared/hooks/useAccountDisabled';

const UTILITY_NAMES = ['subdomain_changer'];

const SubdomainChanger = () => {
  const currentAccount = useContext(CurrentAccountContext);
  const currentSubdomain = currentAccount.full_domain.split('.')[0];
  const processingActivity = useProcessingActivity(UTILITY_NAMES);
  const accountDisabled = useAccountDisabled();
  const [newSubdomain, setNewSubdomain] = useState();
  const [newSubdomainForView, setNewSubdomainForView] = useState();

  const errorSelector = createErrorMessageSelector([
    'overlord/SUBDOMAIN_CHANGER'
  ]);
  const loadedSelector = createLoadedSelector(['overlord/SUBDOMAIN_CHANGER']);
  const structuredSelector = createStructuredSelector({
    previousSubdomains: state => state.overlordUtilities.previousSubdomains,
    isLoaded: state => loadedSelector(state),
    error: state => errorSelector(state)
  });

  const { previousSubdomains, isLoaded, error } = useSelector(
    structuredSelector
  );

  const dispatch = useDispatch();

  const setNewSubdomainChanger = () => {
    setNewSubdomainForView(newSubdomain);
    dispatch(
      changeSubdomain({
        newSubdomain
      })
    );
  };

  useEffect(() => {
    if (isLoaded && isEmpty(error)) {
      app.toast(`Successfully changed subdomain to ${newSubdomainForView}.`, {
        type: 'primary',
        icon: 'fa-floppy-o'
      });
    }

    return () => {
      dispatch(resetIsLoaded('overlord/SUBDOMAIN_CHANGER'));
    };
  }, [newSubdomainForView, isLoaded, error, dispatch]);

  useEffect(() => {
    dispatch(fetchSubdomains());
  }, [dispatch]);

  const getCardBody = () => {
    return (
      <div>
        <div className="alert alert-secondary">
          <strong>Important: </strong>When changing an account&apos;s subdomain
          the previous subdomain will still be used to point to the account so
          that old links in emails and on web pages will still be valid. All new
          links will come from the updated subdomain.
          <hr />
          <div>
            <strong>Current subdomain: </strong>{' '}
            {newSubdomainForView || currentSubdomain}{' '}
          </div>
          {get(previousSubdomains, 'length') ? (
            <div>
              <strong>Previous subdomains:</strong>{' '}
              {toSentence(previousSubdomains)}
            </div>
          ) : null}
        </div>
        <div>
          <label htmlFor="newSubdomain">Enter New Subdomain</label>
          <input
            id="subdomain"
            className="form-control"
            type="text"
            onKeyUp={e => setNewSubdomain(e.target.value)}
          />
          <div>
            {/* <h5 className="text-info">Enter New Subdomain</h5> */}
            {/* <%= text_field_tag :new_account_subdomain, nil, class: 'form-control', placeholder: 'Enter the new subdomain' %> */}
          </div>
        </div>
      </div>
    );
  };

  return (
    <UtilityCard
      title="Account Subdomain Changer"
      subtitle="Use this tool to change the subdomain of an account."
      submitBtnText="Change Subdomain"
      submitBtnAction={setNewSubdomainChanger}
      bodyComponent={getCardBody}
      processingActivity={processingActivity}
      accountDisabled={accountDisabled}
      error={error}
    />
  );
};

export default SubdomainChanger;
