import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import isEmpty from 'lodash.isempty';
import {
  broadcastUpdate,
  broadcastSetHasContentChanged,
  broadcastSetBeeHasLoaded
} from 'appState/actions/ActionCreators';
import Bee from '../Bee';

const BeePage = props => {
  const { saveAction, previewAction, sendTestAction } = props;
  const dispatch = useDispatch();

  const structuredSelector = createStructuredSelector({
    currentBroadcast: state => state.broadcastCenter.currentBroadcast,
    isTemplate: state => state.broadcastCenter.isTemplate,
    hasContentChanged: state => state.broadcastCenter.hasContentChanged
  });

  const { currentBroadcast, isTemplate, hasContentChanged } = useSelector(
    structuredSelector
  );

  const beeStyles = {
    border: 0,
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    width: '100%'
  };

  return (
    <div>
      <Bee
        className="containerClassName"
        style={beeStyles}
        onSave={(emailJson, emailHtml) =>
          saveAction()({ emailJson, emailHtml, isAutoSaving: false })
        }
        onAutoSave={emailJson =>
          saveAction()({ emailJsonDraft: emailJson, isAutoSaving: true })
        }
        onSend={emailHtml => {
          const beeContainerElement = document.getElementById(
            'email-bee-container'
          );
          const wasPreviewClicked =
            beeContainerElement.dataset.buttonClicked === 'preview';
          const wasSendTestClicked =
            beeContainerElement.dataset.buttonClicked === 'send-test-email';
          if (wasPreviewClicked) previewAction(emailHtml);
          if (wasSendTestClicked) sendTestAction(emailHtml);
        }}
        onSaveAsTemplate={emailJson =>
          console.log(
            '---onSaveAsTemplate---',
            emailJson,
            '---onSaveAsTemplate---'
          )
        }
        onChange={() => {
          if (
            !isTemplate &&
            !isEmpty(currentBroadcast) &&
            !currentBroadcast.has_been_edited
          ) {
            dispatch(
              broadcastUpdate({
                broadcastId: currentBroadcast.id,
                hasBeenEdited: true
              })
            );
          }
          if (!hasContentChanged) dispatch(broadcastSetHasContentChanged(true));
        }}
        onLoad={() => {
          dispatch(broadcastSetBeeHasLoaded(true));
        }}
        onError={errorMessage => console.log(errorMessage)}
      />
    </div>
  );
};

BeePage.propTypes = {
  saveAction: PropTypes.func.isRequired,
  previewAction: PropTypes.func.isRequired,
  sendTestAction: PropTypes.func.isRequired
};

export default BeePage;
