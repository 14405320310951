import { call, put, select, takeLatest, delay } from 'redux-saga/effects';
import { axiosDefault, axiosMultipartForm } from 'lib/utils/axios-setup';
import get from 'lodash.get';
import isEmpty from 'lodash.isempty';
import { takeEveryDeduplicate, waitFor } from 'lib/utils/redux-saga-extensions';
import {
  BROADCAST_SEND_TEST_EMAIL_REQUEST,
  BROADCAST_SEND_TEST_EMAIL_SUCCESS,
  BROADCAST_SEND_TEST_EMAIL_FAILURE,
  BROADCASTS_FETCH_REQUEST,
  BROADCASTS_FETCH_SUCCESS,
  BROADCASTS_FETCH_FAILURE,
  BROADCASTS_PAGE_CHANGE_REQUEST,
  BROADCASTS_PAGE_CHANGE_SUCCESS,
  BROADCASTS_FETCH_CURRENT_BROADCAST_REQUEST,
  BROADCASTS_FETCH_CURRENT_BROADCAST_SUCCESS,
  BROADCASTS_FETCH_CURRENT_BROADCAST_FAILURE,
  BROADCASTS_FETCH_CURRENT_BROADCAST_TEMPLATE_REQUEST,
  BROADCASTS_FETCH_CURRENT_BROADCAST_TEMPLATE_SUCCESS,
  BROADCASTS_FETCH_CURRENT_BROADCAST_TEMPLATE_FAILURE,
  BROADCASTS_FETCH_CONTACTS_REQUEST,
  BROADCASTS_FETCH_CONTACTS_SUCCESS,
  BROADCASTS_FETCH_CONTACTS_FAILURE,
  BROADCASTS_FETCH_EMAILS_REQUEST,
  BROADCASTS_FETCH_EMAILS_SUCCESS,
  BROADCASTS_FETCH_EMAILS_FAILURE,
  BROADCASTS_FETCH_SMS_REQUEST,
  BROADCASTS_FETCH_SMS_SUCCESS,
  BROADCASTS_FETCH_SMS_FAILURE,
  BROADCAST_FETCH_PREVIEW_REQUEST,
  BROADCAST_FETCH_PREVIEW_SUCCESS,
  BROADCAST_FETCH_PREVIEW_FAILURE,
  BROADCAST_DELETE_REQUEST,
  BROADCAST_DELETE_SUCCESS,
  BROADCAST_DELETE_FAILURE,
  BROADCAST_TEMPLATE_DELETE_REQUEST,
  BROADCAST_TEMPLATE_DELETE_SUCCESS,
  BROADCAST_TEMPLATE_DELETE_FAILURE,
  BROADCAST_TEMPLATES_FETCH_REQUEST,
  BROADCAST_TEMPLATES_FETCH_SUCCESS,
  BROADCAST_TEMPLATES_FETCH_FAILURE,
  BROADCAST_TEMPLATES_PAGE_CHANGE_REQUEST,
  BROADCAST_TEMPLATES_PAGE_CHANGE_SUCCESS,
  BROADCAST_TEMPLATES_CREATE_BROADCAST_REQUEST,
  BROADCAST_TEMPLATES_CREATE_BROADCAST_SUCCESS,
  BROADCAST_TEMPLATES_CREATE_BROADCAST_FAILURE,
  BROADCASTS_FETCH_EMAIL_MERGE_TAGS_REQUEST,
  BROADCASTS_FETCH_EMAIL_MERGE_TAGS_SUCCESS,
  BROADCASTS_FETCH_EMAIL_MERGE_TAGS_FAILURE,
  BROADCASTS_FETCH_SMS_MERGE_TAGS_REQUEST,
  BROADCASTS_FETCH_SMS_MERGE_TAGS_SUCCESS,
  BROADCASTS_FETCH_SMS_MERGE_TAGS_FAILURE,
  BROADCASTS_TEMPLATE_FETCH_SMS_MERGE_TAGS_REQUEST,
  BROADCASTS_TEMPLATE_FETCH_SMS_MERGE_TAGS_SUCCESS,
  BROADCASTS_TEMPLATE_FETCH_SMS_MERGE_TAGS_FAILURE,
  BROADCAST_SAVE_SMS_REQUEST,
  BROADCAST_SAVE_SMS_SUCCESS,
  BROADCAST_SAVE_SMS_FAILURE,
  BROADCAST_SAVE_AS_TEMPLATE_REQUEST,
  BROADCAST_SAVE_AS_TEMPLATE_SUCCESS,
  BROADCAST_SAVE_AS_TEMPLATE_FAILURE,
  BROADCAST_SEND_AGAIN_REQUEST,
  BROADCAST_SEND_AGAIN_SUCCESS,
  BROADCAST_SEND_AGAIN_FAILURE,
  BROADCAST_EDIT_SCHEDULED_BROADCAST_REQUEST,
  BROADCAST_EDIT_SCHEDULED_BROADCAST_SUCCESS,
  BROADCAST_EDIT_SCHEDULED_BROADCAST_FAILURE,
  BROADCAST_UPDATE_BROADCAST_REQUEST,
  BROADCAST_UPDATE_BROADCAST_SUCCESS,
  BROADCAST_UPDATE_BROADCAST_FAILURE,
  BROADCAST_RESET_EMAIL_SAVE_ACTION,
  BROADCAST_SAVE_EMAIL_REQUEST,
  BROADCAST_SAVE_EMAIL_SUCCESS,
  BROADCAST_SAVE_EMAIL_FAILURE,
  BROADCAST_RESET_IS_AUTOSAVING,
  BROADCAST_SCHEDULE_REQUEST,
  BROADCAST_SCHEDULE_SUCCESS,
  BROADCAST_SCHEDULE_FAILURE,
  BROADCAST_FETCH_AUDIENCE_FILTERS_REQUEST,
  BROADCAST_FETCH_AUDIENCE_FILTERS_SUCCESS,
  BROADCAST_FETCH_AUDIENCE_FILTERS_FAILURE,
  BROADCAST_UPDATE_AUDIENCE_REQUEST,
  BROADCAST_UPDATE_AUDIENCE_SUCCESS,
  BROADCAST_UPDATE_AUDIENCE_FAILURE,
  BROADCAST_FETCH_AUDIENCE_COUNT_REQUEST,
  BROADCAST_FETCH_AUDIENCE_COUNT_SUCCESS,
  BROADCAST_FETCH_AUDIENCE_COUNT_FAILURE,
  BROADCAST_FETCH_FILTER_CONTACTS_COUNT_REQUEST,
  BROADCAST_FETCH_FILTER_CONTACTS_COUNT_SUCCESS,
  BROADCAST_FETCH_FILTER_CONTACTS_COUNT_FAILURE,
  BROADCAST_TEMPLATE_SAVE_DETAILS_REQUEST,
  BROADCAST_TEMPLATE_SAVE_DETAILS_SUCCESS,
  BROADCAST_TEMPLATE_SAVE_DETAILS_FAILURE,
  BROADCAST_TEMPLATE_CREATE_REQUEST,
  BROADCAST_TEMPLATE_CREATE_SUCCESS,
  BROADCAST_TEMPLATE_CREATE_FAILURE,
  BROADCAST_UPDATE_TEMPLATE_REQUEST,
  BROADCAST_UPDATE_TEMPLATE_SUCCESS,
  BROADCAST_UPDATE_TEMPLATE_FAILURE,
  BROADCAST_RESET_TO_INITIAL_STATE,
  ENABLE_OLD_BROADCAST_CENTER_REQUEST,
  ENABLE_OLD_BROADCAST_CENTER_SUCCESS,
  ENABLE_OLD_BROADCAST_CENTER_FAILURE,
  BROADCAST_SEARCH_CONTACTS_BY_FILE_REQUEST,
  BROADCAST_SEARCH_CONTACTS_BY_FILE_SUCCESS,
  BROADCAST_SEARCH_CONTACTS_BY_FILE_FAILURE,
  BROADCAST_CREATE_CUSTOM_GROUP_REQUEST,
  BROADCAST_CREATE_CUSTOM_GROUP_SUCCESS,
  BROADCAST_CREATE_CUSTOM_GROUP_FAILURE,
  BROADCAST_PRODUCTS_FETCH_REQUEST,
  BROADCAST_PRODUCTS_FETCH_SUCCESS,
  BROADCAST_PRODUCTS_FETCH_FAILURE,
  BROADCAST_TEMPLATE_REASSIGN_REQUEST,
  BROADCAST_TEMPLATE_REASSIGN_SUCCESS,
  BROADCAST_TEMPLATE_REASSIGN_FAILURE
} from 'appState/actions/constants/broadcastCenter.actions';

function getScheduleOrSaveDetailsRoute(isTemplate) {
  return isTemplate ? 'save-details' : 'schedule';
}

function getBroadcastOrTemplateUrl(isTemplate) {
  return isTemplate ? 'broadcast_templates' : 'broadcasts';
}

function getBroadcastOrTemplateString(isTemplate, isPlural = true) {
  return `${isTemplate ? 'broadcast template' : 'broadcast'}${
    isPlural ? 's' : ''
  }`;
}

function getBroadcastCenterOrBroadcastTemplatesRoute(isTemplate) {
  return isTemplate ? 'broadcast-templates' : 'broadcast-center';
}

function* sendBroadcastTestEmail({
  payload: { currentUserId, emailBody } = {}
}) {
  try {
    const { emailSubject, testEmailAddress } = yield select(
      state => state.broadcastCenter
    );
    const params = {
      current_user_id: currentUserId,
      test_email_address: testEmailAddress,
      subject: emailSubject,
      body: emailBody
    };
    yield call(axiosDefault.post, '/test_emails', params);

    yield put({
      type: BROADCAST_SEND_TEST_EMAIL_SUCCESS,
      payload: {
        testEmailSent: true
      }
    });
  } catch (e) {
    yield put({
      type: BROADCAST_SEND_TEST_EMAIL_FAILURE,
      payload: {
        error: e,
        fallbackError: 'Error test broadcast email.'
      },
      error: true
    });
  }
}

function* fetchBroadcasts({ payload: { page = 1, query = '', status } = {} }) {
  try {
    const { isChangingPage, isTemplate } = yield select(
      state => state.broadcastCenter
    );
    const params = { page };

    if (query && query.trim() !== '') {
      params.q = query;
    }

    if (status) {
      if (status === 'sent') {
        params.status = ['sent', 'sending'];
      } else {
        params.status = status;
      }
    }

    const response = yield call(
      axiosDefault.get,
      `${getBroadcastOrTemplateUrl(isTemplate)}`,
      { params }
    );
    const total = get(response, 'headers["total-count"]');

    yield put({
      type: isChangingPage
        ? BROADCASTS_PAGE_CHANGE_SUCCESS
        : BROADCASTS_FETCH_SUCCESS,
      payload: {
        broadcasts: response.data,
        page,
        total
      }
    });
  } catch (e) {
    yield put({
      type: BROADCASTS_FETCH_FAILURE,
      payload: { error: e },
      error: true
    });
  }
}

function* fetchCurrentBroadcast({ payload: { broadcastId } }) {
  try {
    const { isTemplate } = yield select(state => state.broadcastCenter);

    const response = yield call(
      axiosDefault.get,
      `/${getBroadcastOrTemplateUrl(isTemplate)}/${broadcastId}`
    );

    yield put({
      type: BROADCASTS_FETCH_CURRENT_BROADCAST_SUCCESS,
      payload: {
        currentBroadcast: response.data
      }
    });
  } catch (e) {
    yield put({
      type: BROADCASTS_FETCH_CURRENT_BROADCAST_FAILURE,
      payload: { error: e },
      error: true
    });
  }
}

function* fetchCurrentBroadcastTemplate({ payload: { broadcastTemplateId } }) {
  try {
    const response = yield call(
      axiosDefault.get,
      `/broadcast_templates/${broadcastTemplateId}`
    );

    yield put({
      type: BROADCASTS_FETCH_CURRENT_BROADCAST_TEMPLATE_SUCCESS,
      payload: {
        currentBroadcastTemplate: response.data
      }
    });
  } catch (e) {
    yield put({
      type: BROADCASTS_FETCH_CURRENT_BROADCAST_TEMPLATE_FAILURE,
      payload: { error: e },
      error: true
    });
  }
}

function* fetchBroadcastContacts({
  payload: {
    broadcastId = null,
    broadcastMetric = null,
    query = null,
    contactIds = null,
    ageRestriction = null
  } = {}
}) {
  try {
    const params = {
      ...(broadcastId && { broadcast_id: broadcastId }),
      ...(broadcastMetric && { broadcast_metric: broadcastMetric }),
      ...(query && { q: query }),
      ...(contactIds && { contact_ids: contactIds }),
      ...(ageRestriction && { age_restriction: ageRestriction })
    };

    const response = yield call(axiosDefault.get, '/contacts', { params });

    yield put({
      type: BROADCASTS_FETCH_CONTACTS_SUCCESS,
      payload: {
        contacts: response.data,
        byContactIds: contactIds && contactIds.length
      }
    });
  } catch (e) {
    yield put({
      type: BROADCASTS_FETCH_CONTACTS_FAILURE,
      payload: { error: e },
      error: true
    });
  }
}

function* fetchBroadcastEmails({ payload: { broadcastId, broadcastMetric } }) {
  try {
    const params = {
      broadcast_id: broadcastId,
      broadcast_metric: broadcastMetric
    };
    const response = yield call(axiosDefault.get, '/emails', { params });

    yield put({
      type: BROADCASTS_FETCH_EMAILS_SUCCESS,
      payload: {
        emails: response.data
      }
    });
  } catch (e) {
    yield put({
      type: BROADCASTS_FETCH_EMAILS_FAILURE,
      payload: { error: e },
      error: true
    });
  }
}

function* fetchBroadcastSms({ payload: { broadcastId, broadcastMetric } }) {
  try {
    const params = {
      broadcast_id: broadcastId,
      broadcast_metric: broadcastMetric
    };
    const response = yield call(axiosDefault.get, '/contacts/sms', { params });

    yield put({
      type: BROADCASTS_FETCH_SMS_SUCCESS,
      payload: {
        sms: response.data
      }
    });
  } catch (e) {
    yield put({
      type: BROADCASTS_FETCH_SMS_FAILURE,
      payload: { error: e },
      error: true
    });
  }
}

function* fetchBroadcastPreview({
  payload: { broadcastId, userId, emailBody = null }
}) {
  const { emailSubject, isTemplate } = yield select(
    state => state.broadcastCenter
  );
  try {
    const params = {
      user_id: userId
    };
    if (emailBody && emailSubject) {
      params.body = emailBody;
      params.subject = emailSubject;
    }

    const response = yield call(
      axiosDefault.post,
      `/${getBroadcastOrTemplateUrl(isTemplate)}/${broadcastId}/preview`,
      params
    );

    yield put({
      type: BROADCAST_FETCH_PREVIEW_SUCCESS,
      payload: {
        emailBody: response.data.email_body,
        emailSubject: response.data.email_subject,
        smsBody: response.data.sms_body,
        smsMedia: get(response, 'data.media_urls[0].url'),
        contact: response.data.contact
      }
    });
  } catch (e) {
    yield put({
      type: BROADCAST_FETCH_PREVIEW_FAILURE,
      payload: {
        error: e,
        fallbackError: `Error previewing ${getBroadcastOrTemplateString(
          isTemplate
        )}.`
      },
      error: true
    });
  }
}

function* deleteBroadcast({ payload: { broadcastId } }) {
  const { isTemplate } = yield select(state => state.broadcastCenter);
  try {
    yield call(
      axiosDefault.delete,
      `/${getBroadcastOrTemplateUrl(isTemplate)}/${broadcastId}`
    );

    yield put({
      type: BROADCAST_DELETE_SUCCESS,
      payload: {
        broadcastId
      }
    });
  } catch (e) {
    yield put({
      type: BROADCAST_DELETE_FAILURE,
      payload: {
        error: e,
        fallbackError: `Error deleting ${getBroadcastOrTemplateString(
          isTemplate
        )}.`
      },
      error: true
    });
  }
}

function* enableOldBC() {
  try {
    yield call(
      axiosDefault.post,
      `/feature_flags?&segment=broadcast-center-be`
    );

    yield put({
      type: ENABLE_OLD_BROADCAST_CENTER_SUCCESS,
      payload: {}
    });
  } catch (e) {
    yield put({
      type: ENABLE_OLD_BROADCAST_CENTER_FAILURE,
      payload: {
        error: e,
        fallbackError: 'Error Enabling Old Broadcast Center'
      },
      error: true
    });
  }
}

function* deleteBroadcastTemplate({ payload: { broadcastTemplateId } }) {
  try {
    yield call(
      axiosDefault.delete,
      `/broadcast_templates/${broadcastTemplateId}`
    );

    yield put({
      type: BROADCAST_TEMPLATE_DELETE_SUCCESS,
      payload: {
        broadcastTemplateId
      }
    });
  } catch (e) {
    yield put({
      type: BROADCAST_TEMPLATE_DELETE_FAILURE,
      payload: {
        error: e,
        fallbackError: 'Error deleting broadcast template.'
      },
      error: true
    });
  }
}

function* fetchBroadcastTemplates({
  payload: { page = 1, query = '', type } = {}
}) {
  try {
    const { isChangingPage } = yield select(state => state.broadcastCenter);

    const params = {
      page,
      type: type.toLowerCase()
    };

    if (query && query.trim() !== '') {
      params.q = query;
    }

    const response = yield call(axiosDefault.get, '/broadcast_templates', {
      params
    });
    const total = get(response, 'headers["total-count"]');

    yield put({
      type: isChangingPage
        ? BROADCAST_TEMPLATES_PAGE_CHANGE_SUCCESS
        : BROADCAST_TEMPLATES_FETCH_SUCCESS,
      payload: {
        broadcastTemplates: response.data,
        page,
        total
      }
    });
  } catch (e) {
    yield put({
      type: BROADCAST_TEMPLATES_FETCH_FAILURE,
      payload: { error: e },
      error: true
    });
  }
}

function* createBroadcastFromTemplate({
  payload: {
    broadcastTemplateId = null,
    templateType = null,
    currentUserId,
    history
  } = {}
}) {
  try {
    const params = {
      template_type: templateType,
      broadcast_template_id: broadcastTemplateId,
      current_user_id: currentUserId
    };
    const response = yield call(axiosDefault.post, `/broadcasts`, params);
    const currentBroadcast = response.data;

    yield put({
      type: BROADCAST_TEMPLATES_CREATE_BROADCAST_SUCCESS,
      payload: {
        currentBroadcast
      }
    });

    history.push(`/ui/broadcast-center/${currentBroadcast.id}/select-audience`);
  } catch (e) {
    yield put({
      type: BROADCAST_TEMPLATES_CREATE_BROADCAST_FAILURE,
      payload: {
        error: e,
        fallbackError: 'Error selecting broadcast template.'
      },
      error: true
    });
  }
}

function* fetchBroadcastEmailMergeTags({ payload: { broadcastId } = {} }) {
  try {
    const { isTemplate } = yield select(state => state.broadcastCenter);

    const response = yield call(
      axiosDefault.get,
      `/${getBroadcastOrTemplateUrl(
        isTemplate
      )}/${broadcastId}/email_merge_fields`
    );

    yield put({
      type: BROADCASTS_FETCH_EMAIL_MERGE_TAGS_SUCCESS,
      payload: {
        mergeFields: response.data.email_merge_fields,
        specialLinks: response.data.email_special_links
      }
    });
  } catch (e) {
    yield put({
      type: BROADCASTS_FETCH_EMAIL_MERGE_TAGS_FAILURE,
      payload: {
        error: e,
        fallbackError: 'Error fetching email merge fields.'
      },
      error: true
    });
  }
}

function* fetchBroadcastSmsMergeTags({ payload: { broadcastId } = {} }) {
  try {
    const { isTemplate } = yield select(state => state.broadcastCenter);

    const response = yield call(
      axiosDefault.get,
      `/${getBroadcastOrTemplateUrl(
        isTemplate
      )}/${broadcastId}/sms_merge_fields`
    );

    yield put({
      type: BROADCASTS_FETCH_SMS_MERGE_TAGS_SUCCESS,
      payload: {
        mergeFields: response.data
      }
    });
  } catch (e) {
    yield put({
      type: BROADCASTS_FETCH_SMS_MERGE_TAGS_FAILURE,
      payload: {
        error: e,
        fallbackError: 'Error fetching sms merge fields.'
      },
      error: true
    });
  }
}

function* fetchBroadcastTemplateSmsMergeTags({
  payload: { broadcastTemplateId } = {}
}) {
  try {
    const { isTemplate } = yield select(state => state.broadcastCenter);

    const response = yield call(
      axiosDefault.get,
      `/${getBroadcastOrTemplateUrl(
        isTemplate
      )}/${broadcastTemplateId}/sms_merge_fields`
    );

    yield put({
      type: BROADCASTS_TEMPLATE_FETCH_SMS_MERGE_TAGS_SUCCESS,
      payload: {
        mergeFields: response.data
      }
    });
  } catch (e) {
    yield put({
      type: BROADCASTS_TEMPLATE_FETCH_SMS_MERGE_TAGS_FAILURE,
      payload: {
        error: e,
        fallbackError: 'Error fetching sms merge fields.'
      },
      error: true
    });
  }
}

function* saveBroadcastSms({
  payload: {
    broadcastId,
    smsBody = '',
    smsLink = '',
    history,
    file = null,
    removeTempMedia,
    overrideNavigation = false,
    isTemplate
  } = {}
}) {
  try {
    let response;

    if (file) {
      const formData = new FormData();
      formData.append(
        'temp_media',
        new Blob([file], { type: file.type }),
        file.name
      );
      formData.append('sms_body', smsBody);
      formData.append('sms_link', smsLink);
      formData.append('remove_temp_media', removeTempMedia);
      response = yield call(
        axiosMultipartForm.patch,
        `/${getBroadcastOrTemplateUrl(isTemplate)}/${broadcastId}`,
        formData
      );
    } else {
      const params = {
        sms_body: smsBody,
        sms_link: smsLink,
        remove_temp_media: removeTempMedia
      };
      response = yield call(
        axiosDefault.patch,
        `/${getBroadcastOrTemplateUrl(isTemplate)}/${broadcastId}`,
        params
      );
    }

    yield put({
      type: BROADCAST_SAVE_SMS_SUCCESS,
      payload: {
        currentBroadcast: response.data
      }
    });
    if (!overrideNavigation)
      history.push(
        `/ui/${getBroadcastCenterOrBroadcastTemplatesRoute(
          isTemplate
        )}/${broadcastId}/${getScheduleOrSaveDetailsRoute(isTemplate)}`
      );
  } catch (e) {
    yield put({
      type: BROADCAST_SAVE_SMS_FAILURE,
      payload: {
        error: e,
        fallbackError: `Error saving SMS ${getBroadcastOrTemplateString(
          isTemplate
        )}.`
      },
      error: true
    });
  }
}

function* saveBroadcastAsTemplate({
  payload: {
    broadcastId,
    title = null,
    description = null,
    history = null,
    createdFromSchedule = false
  } = {}
}) {
  try {
    const params = {
      ...(title && { title }),
      ...(description && { description })
    };

    const response = yield call(
      axiosDefault.post,
      `/broadcasts/${broadcastId}/convert_to_template`,
      params
    );
    const currentTemplate = response.data;
    const payload = {};
    if (!createdFromSchedule) payload.currentBroadcast = currentTemplate;

    yield put({
      type: BROADCAST_SAVE_AS_TEMPLATE_SUCCESS,
      payload
    });
    if (history)
      history.push(
        `/ui/broadcast-templates/${currentTemplate.id}/select-audience`
      );
  } catch (e) {
    yield put({
      type: BROADCAST_SAVE_AS_TEMPLATE_FAILURE,
      payload: {
        error: e,
        fallbackError: 'Error saving broadcast as template.'
      },
      error: true
    });
  }
}

function* duplicateBroadcast({ payload: { broadcastId, history } = {} }) {
  try {
    const response = yield call(
      axiosDefault.post,
      `/broadcasts/${broadcastId}/duplicate`
    );
    const currentBroadcast = response.data;

    yield put({
      type: BROADCAST_SEND_AGAIN_SUCCESS,
      payload: {
        currentBroadcast: response.data
      }
    });
    history.push(`/ui/broadcast-center/${currentBroadcast.id}/select-audience`);
  } catch (e) {
    yield put({
      type: BROADCAST_SEND_AGAIN_FAILURE,
      payload: {
        error: e,
        fallbackError:
          'Error while duplicating your broadcast to send it again.'
      },
      error: true
    });
  }
}

function* editScheduledBroadcast({ payload: { broadcastId, history } }) {
  const params = { status: 'draft' };
  try {
    const response = yield call(
      axiosDefault.patch,
      `/broadcasts/${broadcastId}`,
      params
    );

    const currentBroadcast = response.data;

    yield put({
      type: BROADCAST_EDIT_SCHEDULED_BROADCAST_SUCCESS,
      payload: { currentBroadcast }
    });

    history.push(`/ui/broadcast-center/${currentBroadcast.id}/select-audience`);
  } catch (e) {
    yield put({
      type: BROADCAST_EDIT_SCHEDULED_BROADCAST_FAILURE,
      payload: {
        error: e,
        fallbackError: 'Error while trying to edit your scheduled broadcast.'
      },
      error: true
    });
  }
}

function* updateBroadcast({
  payload: {
    broadcastId,
    selectAudienceBy = null,
    hasBeenEdited = false,
    deleteDraft = false,
    includeStaffMembers = false
  }
}) {
  yield call(waitFor, state => !isEmpty(state.broadcastCenter.currentAccount));

  const { currentAccount, isTemplate } = yield select(
    state => state.broadcastCenter
  );
  try {
    const params = {
      account_id: currentAccount.id,
      include_staff_members: includeStaffMembers
    };
    if (selectAudienceBy) params.select_audience_by = selectAudienceBy;
    if (hasBeenEdited) params.has_been_edited = hasBeenEdited;
    if (deleteDraft) params.delete_draft = deleteDraft;

    const response = yield call(
      axiosDefault.patch,
      `/${getBroadcastOrTemplateUrl(isTemplate)}/${broadcastId}`,
      params
    );

    const currentBroadcast = response.data;

    yield put({
      type: BROADCAST_UPDATE_BROADCAST_SUCCESS,
      payload: { currentBroadcast }
    });
  } catch (e) {
    yield put({
      type: BROADCAST_UPDATE_BROADCAST_FAILURE,
      payload: {
        error: e,
        fallbackError: `Error while trying to update your ${getBroadcastOrTemplateString(
          isTemplate
        )}.`
      },
      error: true
    });
  }
}

function* saveBroadcastEmail({
  payload: {
    broadcastId,
    emailJson = null,
    emailHtml = null,
    emailJsonDraft = null,
    history
  } = {}
}) {
  yield call(waitFor, state => !isEmpty(state.broadcastCenter.currentAccount));

  const {
    currentAccount,
    emailSubject,
    isTemplate,
    isAutoSaving,
    overrideNavigation
  } = yield select(state => state.broadcastCenter);
  if (emailSubject.trim() !== '' || isAutoSaving) {
    try {
      const params = {
        account_id: currentAccount.id,
        email_subject: emailSubject
      };
      if (emailJson) params.email_json = emailJson;
      if (emailHtml) params.email_html = emailHtml;
      if (emailJsonDraft) params.email_json_draft = emailJsonDraft;

      const response = yield call(
        axiosDefault.patch,
        `/${getBroadcastOrTemplateUrl(isTemplate)}/${broadcastId}`,
        params
      );

      yield put({
        type: BROADCAST_SAVE_EMAIL_SUCCESS,
        payload: {
          currentBroadcast: response.data
        }
      });

      if (!isAutoSaving && !overrideNavigation)
        history.push(
          `/ui/${getBroadcastCenterOrBroadcastTemplatesRoute(
            isTemplate
          )}/${broadcastId}/${getScheduleOrSaveDetailsRoute(isTemplate)}`
        );

      yield put({ type: BROADCAST_RESET_EMAIL_SAVE_ACTION });
      yield delay(5000);
      yield put({
        type: BROADCAST_RESET_IS_AUTOSAVING
      });
    } catch (e) {
      yield put({
        type: BROADCAST_SAVE_EMAIL_FAILURE,
        payload: {
          error: e,
          fallbackError: `Error saving ${getBroadcastOrTemplateString(
            isTemplate
          )} email.`
        },
        error: true
      });
    }
  }
}

function* scheduleBroadcast({
  payload: {
    broadcastId,
    currentUserId,
    templateTitle = null,
    templateDescription = null,
    scheduleForDateTime,
    sendingType,
    history,
    overrideNavigation = false
  }
}) {
  yield call(waitFor, state => !isEmpty(state.broadcastCenter.currentAccount));

  const { currentAccount } = yield select(state => state.broadcastCenter);

  const params = {
    account_id: currentAccount.id,
    current_user_id: currentUserId,
    scheduled_for: scheduleForDateTime,
    sending_type: sendingType
  };
  if (templateTitle) params.template_title = templateTitle;
  if (templateDescription) params.template_description = templateDescription;

  try {
    const response = yield call(
      axiosDefault.patch,
      `/broadcasts/${broadcastId}/schedule`,
      params
    );

    const currentBroadcast = response.data;

    yield put({
      type: BROADCAST_SCHEDULE_SUCCESS,
      payload: { currentBroadcast }
    });

    yield put({
      type: BROADCAST_RESET_TO_INITIAL_STATE
    });

    if (!overrideNavigation) history.push(`/ui/broadcast-center/`);
  } catch (e) {
    yield put({
      type: BROADCAST_SCHEDULE_FAILURE,
      payload: {
        error: e,
        fallbackError: 'Error while trying to schedule your broadcast.'
      },
      error: true
    });
  }
}

function* fetchBroadcastAudienceFilters() {
  try {
    yield call(
      waitFor,
      state => !isEmpty(state.broadcastCenter.currentAccount)
    );

    const { currentAccount } = yield select(state => state.broadcastCenter);
    const params = { account_id: currentAccount.id };

    const response = yield call(axiosDefault.get, '/filters', { params });

    yield put({
      type: BROADCAST_FETCH_AUDIENCE_FILTERS_SUCCESS,
      payload: {
        audienceFilters: response.data
      }
    });
  } catch (e) {
    yield put({
      type: BROADCAST_FETCH_AUDIENCE_FILTERS_FAILURE,
      payload: {
        error: e,
        fallbackError: 'Error fetching audience filters.'
      },
      error: true
    });
  }
}

function* updateBroadcastAudience({
  payload: {
    broadcast,
    selectAudienceBy,
    filterIds = null,
    manuallySelectedContactIds = null,
    name = null,
    history,
    overrideNavigation = false,
    excludePausedContacts
  }
}) {
  yield call(waitFor, state => !isEmpty(state.broadcastCenter.currentAccount));

  const { currentAccount, isTemplate } = yield select(
    state => state.broadcastCenter
  );
  try {
    const params = {
      account_id: currentAccount.id,
      select_audience_by: selectAudienceBy,
      exclude_paused_contacts: excludePausedContacts
    };
    if (filterIds) params.filter_ids = filterIds;
    if (manuallySelectedContactIds)
      params.manually_selected_contact_ids = manuallySelectedContactIds;
    if (name) params.name = name;

    const audienceId = broadcast.audience.id;

    const response = yield call(
      axiosDefault.patch,
      `/audiences/${audienceId}`,
      params
    );

    yield put({
      type: BROADCAST_UPDATE_AUDIENCE_SUCCESS,
      payload: {
        audience: response.data
      }
    });

    if (broadcast.template_type === 'Email' && !overrideNavigation) {
      history.push(
        `/ui/${getBroadcastCenterOrBroadcastTemplatesRoute(isTemplate)}/${
          broadcast.id
        }/email`
      );
    } else if (broadcast.template_type === 'SMS' && !overrideNavigation) {
      history.push(
        `/ui/${getBroadcastCenterOrBroadcastTemplatesRoute(isTemplate)}/${
          broadcast.id
        }/sms`
      );
    }
  } catch (e) {
    yield put({
      type: BROADCAST_UPDATE_AUDIENCE_FAILURE,
      payload: {
        error: e,
        fallbackError: 'Error updating audience.'
      },
      error: true
    });
  }
}

function* fetchBroadcastAudienceCount({ payload: { audienceId, filterIds } }) {
  try {
    yield call(
      waitFor,
      state => !isEmpty(state.broadcastCenter.currentAccount)
    );

    const { currentAccount, currentBroadcast } = yield select(
      state => state.broadcastCenter
    );
    const params = {
      account_id: currentAccount.id,
      filter_ids: filterIds,
      template_type: currentBroadcast.template_type
    };
    const response = yield call(
      axiosDefault.get,
      `/audiences/${audienceId}/contacts_count`,
      {
        params
      }
    );

    yield put({
      type: BROADCAST_FETCH_AUDIENCE_COUNT_SUCCESS,
      payload: {
        audienceCount: response.data.contacts_count
      }
    });
  } catch (e) {
    yield put({
      type: BROADCAST_FETCH_AUDIENCE_COUNT_FAILURE,
      payload: {
        error: e,
        fallbackError: 'Error fetching audience count.'
      },
      error: true
    });
  }
}

function* fetchBroadcastFilterContactsCounts({
  payload: { audienceId, filterIds, perFilter = false }
}) {
  try {
    yield call(
      waitFor,
      state => !isEmpty(state.broadcastCenter.currentAccount)
    );

    const { currentAccount, currentBroadcast } = yield select(
      state => state.broadcastCenter
    );
    const params = {
      account_id: currentAccount.id,
      filter_ids: filterIds,
      template_type: currentBroadcast.template_type
    };
    if (perFilter) params.count_type = 'per_filter';

    const response = yield call(
      axiosDefault.get,
      `/audiences/${audienceId}/contacts_count`,
      {
        params
      }
    );

    yield put({
      type: BROADCAST_FETCH_FILTER_CONTACTS_COUNT_SUCCESS,
      payload: {
        contactsCounts: response.data
      }
    });
  } catch (e) {
    yield put({
      type: BROADCAST_FETCH_FILTER_CONTACTS_COUNT_FAILURE,
      payload: {
        error: e,
        fallbackError: 'Error fetching filter contacts counts.'
      },
      error: true
    });
  }
}

function* saveDetailsBroadcastTemplate({
  payload: { templateTitle, templateDescription, history }
}) {
  try {
    yield call(
      waitFor,
      state => !isEmpty(state.broadcastCenter.currentAccount)
    );

    const { currentAccount, currentBroadcast } = yield select(
      state => state.broadcastCenter
    );
    const params = {
      account_id: currentAccount.id,
      title: templateTitle,
      description: templateDescription
    };

    const response = yield call(
      axiosDefault.patch,
      `/broadcast_templates/${currentBroadcast.id}`,
      params
    );

    yield put({
      type: BROADCAST_TEMPLATE_SAVE_DETAILS_SUCCESS,
      payload: {
        currentBroadcast: response.data
      }
    });

    yield put({
      type: BROADCAST_RESET_TO_INITIAL_STATE
    });

    history.push(
      `/ui/broadcast-center/${currentBroadcast.template_type.toLowerCase()}/select-template`
    );
  } catch (e) {
    yield put({
      type: BROADCAST_TEMPLATE_SAVE_DETAILS_FAILURE,
      payload: {
        error: e,
        fallbackError: 'Error updating your broadcast template.'
      },
      error: true
    });
  }
}

function* createBroadcastTemplate({ payload: { templateType, history } }) {
  try {
    yield call(
      waitFor,
      state => !isEmpty(state.broadcastCenter.currentAccount)
    );

    const { currentAccount } = yield select(state => state.broadcastCenter);
    const params = {
      account_id: currentAccount.id,
      template_type: templateType
    };

    const response = yield call(
      axiosDefault.post,
      `/broadcast_templates/`,
      params
    );

    yield put({
      type: BROADCAST_TEMPLATE_CREATE_SUCCESS,
      payload: {
        currentBroadcast: response.data
      }
    });

    history.push(`/ui/broadcast-templates/${response.data.id}/select-audience`);
  } catch (e) {
    yield put({
      type: BROADCAST_TEMPLATE_CREATE_FAILURE,
      payload: {
        error: e,
        fallbackError: 'Error creating your new broadcast template.'
      },
      error: true
    });
  }
}

function* findContactsByFile({ payload: { file, ageRestriction } }) {
  try {
    yield call(
      waitFor,
      state => !isEmpty(state.broadcastCenter.currentAccount)
    );
    const { currentAccount } = yield select(state => state.broadcastCenter);
    const formData = new FormData();
    formData.append('file', new Blob([file], { type: 'text/csv' }), file.name);
    if (!!ageRestriction) formData.append('age_restriction', ageRestriction);

    const response = yield call(
      axiosMultipartForm.post,
      `/broadcasts/find_contacts_by_file?account_id=${currentAccount.id}`,
      formData
    );

    yield put({
      type: BROADCAST_SEARCH_CONTACTS_BY_FILE_SUCCESS,
      payload: {
        contactsFromFile: response.data
      }
    });
  } catch (e) {
    yield put({
      type: BROADCAST_SEARCH_CONTACTS_BY_FILE_FAILURE,
      payload: {
        error: e,
        fallbackError: 'Error processing the csv file.'
      },
      error: true
    });
  }
}

function* createCustomGroup({ payload: { selectedContactIds, name } }) {
  try {
    yield call(
      waitFor,
      state => !isEmpty(state.broadcastCenter.currentAccount)
    );
    const { currentAccount } = yield select(state => state.broadcastCenter);

    const response = yield call(
      axiosMultipartForm.post,
      `/broadcasts/create_custom_group?account_id=${currentAccount.id}`,
      { selected_contact_ids: selectedContactIds, name }
    );

    yield put({
      type: BROADCAST_CREATE_CUSTOM_GROUP_SUCCESS,
      payload: {
        contactsFromFile: response.data
      }
    });
  } catch (e) {
    yield put({
      type: BROADCAST_CREATE_CUSTOM_GROUP_FAILURE,
      payload: {
        error: e,
        fallbackError: 'Error creating your new custom group.'
      },
      error: true
    });
  }
}

function* updateTemplateFromBroadcast({
  payload: {
    broadcastTemplateId,
    broadcastId,
    title = null,
    description = null
  }
}) {
  try {
    yield call(
      waitFor,
      state => !isEmpty(state.broadcastCenter.currentAccount)
    );

    const { currentAccount } = yield select(state => state.broadcastCenter);
    const params = { account_id: currentAccount.id, broadcast_id: broadcastId };
    if (title) params.title = title;
    if (description) params.description = description;

    yield call(
      axiosDefault.patch,
      `/broadcast_templates/${broadcastTemplateId}/update_from_broadcast`,
      params
    );

    yield put({
      type: BROADCAST_UPDATE_TEMPLATE_SUCCESS
    });
  } catch (e) {
    yield put({
      type: BROADCAST_UPDATE_TEMPLATE_FAILURE,
      payload: {
        error: e,
        fallbackError: 'Error updating your broadcast template.'
      },
      error: true
    });
  }
}

function* fetchProducts() {
  try {
    yield call(
      waitFor,
      state => !isEmpty(state.broadcastCenter.currentAccount)
    );

    const { currentAccount } = yield select(state => state.broadcastCenter);
    const params = { account_id: currentAccount.id };
    const response = yield call(axiosDefault.get, '/products', { params });

    yield put({
      type: BROADCAST_PRODUCTS_FETCH_SUCCESS,
      payload: {
        products: response.data
      }
    });
  } catch (e) {
    yield put({
      type: BROADCAST_PRODUCTS_FETCH_FAILURE,
      payload: { error: e },
      error: true
    });
  }
}

function* reassignBroadcastTemplate({
  payload: { broadcastTemplateId, productId }
}) {
  try {
    yield call(
      waitFor,
      state => !isEmpty(state.broadcastCenter.currentAccount)
    );

    const { currentAccount } = yield select(state => state.broadcastCenter);
    const params = { account_id: currentAccount.id, product_id: productId };
    const response = yield call(
      axiosDefault.put,
      `/broadcast_templates/${broadcastTemplateId}/reassign`,
      params
    );

    yield put({
      type: BROADCAST_TEMPLATE_REASSIGN_SUCCESS,
      payload: {
        broadcastTemplate: response.data
      }
    });
  } catch (e) {
    yield put({
      type: BROADCAST_TEMPLATE_REASSIGN_FAILURE,
      payload: { error: e },
      error: true
    });
  }
}

export function* broadcastProductFetch() {
  yield takeLatest(BROADCAST_PRODUCTS_FETCH_REQUEST, fetchProducts);
}

export function* broadcastTemplateReassign() {
  yield takeLatest(
    BROADCAST_TEMPLATE_REASSIGN_REQUEST,
    reassignBroadcastTemplate
  );
}

export function* broadcastTemplateCreate() {
  yield takeLatest(BROADCAST_TEMPLATE_CREATE_REQUEST, createBroadcastTemplate);
}

export function* broadcastUpdateTemplate() {
  yield takeLatest(
    BROADCAST_UPDATE_TEMPLATE_REQUEST,
    updateTemplateFromBroadcast
  );
}

export function* broadcastTemplateSaveDetails() {
  yield takeLatest(
    BROADCAST_TEMPLATE_SAVE_DETAILS_REQUEST,
    saveDetailsBroadcastTemplate
  );
}

export function* broadcastSendTestEmail() {
  yield takeLatest(BROADCAST_SEND_TEST_EMAIL_REQUEST, sendBroadcastTestEmail);
}

export function* broadcastsFetch() {
  yield takeLatest(BROADCASTS_FETCH_REQUEST, fetchBroadcasts);
}

export function* broadcastsPageChange() {
  yield takeEveryDeduplicate(BROADCASTS_PAGE_CHANGE_REQUEST, fetchBroadcasts);
}

export function* broadcastsFetchCurrentBroadcast() {
  yield takeLatest(
    BROADCASTS_FETCH_CURRENT_BROADCAST_REQUEST,
    fetchCurrentBroadcast
  );
}

export function* broadcastsFetchCurrentBroadcastTemplate() {
  yield takeLatest(
    BROADCASTS_FETCH_CURRENT_BROADCAST_TEMPLATE_REQUEST,
    fetchCurrentBroadcastTemplate
  );
}

export function* broadcastContactsFetch() {
  yield takeLatest(BROADCASTS_FETCH_CONTACTS_REQUEST, fetchBroadcastContacts);
}

export function* broadcastEmailsFetch() {
  yield takeLatest(BROADCASTS_FETCH_EMAILS_REQUEST, fetchBroadcastEmails);
}

export function* broadcastSmsFetch() {
  yield takeLatest(BROADCASTS_FETCH_SMS_REQUEST, fetchBroadcastSms);
}

export function* broadcastPreviewFetch() {
  yield takeLatest(BROADCAST_FETCH_PREVIEW_REQUEST, fetchBroadcastPreview);
}

export function* broadcastDelete() {
  yield takeLatest(BROADCAST_DELETE_REQUEST, deleteBroadcast);
}

export function* broadcastTemplateDelete() {
  yield takeLatest(BROADCAST_TEMPLATE_DELETE_REQUEST, deleteBroadcastTemplate);
}

export function* broadcastTemplatesFetch() {
  yield takeLatest(BROADCAST_TEMPLATES_FETCH_REQUEST, fetchBroadcastTemplates);
}

export function* broadcastTemplatesPageChange() {
  yield takeEveryDeduplicate(
    BROADCAST_TEMPLATES_PAGE_CHANGE_REQUEST,
    fetchBroadcastTemplates
  );
}

export function* broadcastTemplatesCreateBroadcast() {
  yield takeLatest(
    BROADCAST_TEMPLATES_CREATE_BROADCAST_REQUEST,
    createBroadcastFromTemplate
  );
}

export function* broadcastFetchEmailMergeTags() {
  yield takeLatest(
    BROADCASTS_FETCH_EMAIL_MERGE_TAGS_REQUEST,
    fetchBroadcastEmailMergeTags
  );
}

export function* broadcastFetchSmsMergeTags() {
  yield takeLatest(
    BROADCASTS_FETCH_SMS_MERGE_TAGS_REQUEST,
    fetchBroadcastSmsMergeTags
  );
}

export function* broadcastTemplateFetchSmsMergeTags() {
  yield takeLatest(
    BROADCASTS_TEMPLATE_FETCH_SMS_MERGE_TAGS_REQUEST,
    fetchBroadcastTemplateSmsMergeTags
  );
}

export function* broadcastSaveSms() {
  yield takeLatest(BROADCAST_SAVE_SMS_REQUEST, saveBroadcastSms);
}

export function* broadcastSaveAsTemplate() {
  yield takeLatest(BROADCAST_SAVE_AS_TEMPLATE_REQUEST, saveBroadcastAsTemplate);
}

export function* broadcastSendAgain() {
  yield takeLatest(BROADCAST_SEND_AGAIN_REQUEST, duplicateBroadcast);
}

export function* broadcastEditScheduledBroadcast() {
  yield takeLatest(
    BROADCAST_EDIT_SCHEDULED_BROADCAST_REQUEST,
    editScheduledBroadcast
  );
}

export function* broadcastUpdate() {
  yield takeLatest(BROADCAST_UPDATE_BROADCAST_REQUEST, updateBroadcast);
}

export function* broadcastSaveEmail() {
  yield takeLatest(BROADCAST_SAVE_EMAIL_REQUEST, saveBroadcastEmail);
}

export function* broadcastSchedule() {
  yield takeLatest(BROADCAST_SCHEDULE_REQUEST, scheduleBroadcast);
}

export function* broadcastFetchAudienceFilters() {
  yield takeLatest(
    BROADCAST_FETCH_AUDIENCE_FILTERS_REQUEST,
    fetchBroadcastAudienceFilters
  );
}

export function* broadcastUpdateAudience() {
  yield takeLatest(BROADCAST_UPDATE_AUDIENCE_REQUEST, updateBroadcastAudience);
}

export function* broadcastFetchAudienceCount() {
  yield takeLatest(
    BROADCAST_FETCH_AUDIENCE_COUNT_REQUEST,
    fetchBroadcastAudienceCount
  );
}

export function* broadcastFetchFilterContactsCounts() {
  yield takeLatest(
    BROADCAST_FETCH_FILTER_CONTACTS_COUNT_REQUEST,
    fetchBroadcastFilterContactsCounts
  );
}

export function* enableOldBroadcastCenter() {
  yield takeLatest(ENABLE_OLD_BROADCAST_CENTER_REQUEST, enableOldBC);
}

export function* contactsByFileFetch() {
  yield takeLatest(
    BROADCAST_SEARCH_CONTACTS_BY_FILE_REQUEST,
    findContactsByFile
  );
}

export function* createCustomGroupFromList() {
  yield takeLatest(BROADCAST_CREATE_CUSTOM_GROUP_REQUEST, createCustomGroup);
}
