/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import Badge from 'components/Theme/Badge';
import Header from './Header';
import Footer from './Footer';

const Card = ({
  children,
  header,
  footer,
  title,
  subtitle,
  color,
  containerStyle,
  bodyStyle,
  bodyScrollId,
  showPrimaryAction,
  disablePrimaryAction,
  primaryAction,
  primaryActionText,
  showSecondaryAction,
  secondaryAction,
  secondaryActionText,
  showTertiaryAction,
  tertiaryAction,
  tertiaryActionText,
  disableTertiaryAction,
  showHeaderAction,
  headerAction,
  headerActionText,
  headerActionIcon,
  containerClass,
  innerContainerClass,
  hasDetachedActions,
  disableSecondaryAction,
  customMarginContainer,
  imageUrl,
  imageClass,
  headerClass,
  clickable,
  showBadge,
  badgeContent,
  noTitleBorder,
  headerStyle
}) => {
  function handlePrimaryActionClick() {
    primaryAction();
  }

  return (
    <div
      className={`tw-flex tw-flex-col ${
        customMarginContainer ? '' : 'tw-mx-auto'
      } tw-shadow-lg ${containerClass} ${showBadge ? 'tw-relative' : ''} ${
        clickable ? 'tw-cursor-pointer' : ''
      }`}
      style={containerStyle}
    >
      {showBadge && (
        <div className="tw-z-10 tw-absolute tw-top-1 tw-left-1">
          <Badge
            shape="round"
            size="small"
            value={badgeContent}
            badgeClass="text-white"
            badgeStyle={{ backgroundColor: 'rgb(0, 0, 0)' }}
          />
        </div>
      )}
      <div
        className={innerContainerClass}
        onClick={clickable ? handlePrimaryActionClick : null}
      >
        <div className="tw-align-middle tw-min-w-full tw-rounded-lg tw-border-b tw-border-gray-200">
          <Header
            imageUrl={imageUrl}
            imageClass={imageClass}
            title={title}
            subtitle={subtitle}
            showHeaderAction={showHeaderAction}
            noTitleBorder={noTitleBorder}
            headerClass={headerClass}
            headerActionText={headerActionText}
            headerAction={headerAction}
            headerActionIcon={headerActionIcon}
            header={header}
            headerStyle={headerStyle}
          />
          <div style={bodyStyle} id={bodyScrollId}>
            {children}
          </div>
          <Footer
            footer={footer}
            showPrimaryAction={showPrimaryAction}
            showSecondaryAction={showSecondaryAction}
            color={color}
            handlePrimaryActionClick={handlePrimaryActionClick}
            disablePrimaryAction={disablePrimaryAction}
            primaryActionText={primaryActionText}
            showTertiaryAction={showTertiaryAction}
            tertiaryActionText={tertiaryActionText}
            tertiaryAction={tertiaryAction}
            disableTertiaryAction={disableTertiaryAction}
            hasDetachedActions={hasDetachedActions}
            disableSecondaryAction={disableSecondaryAction}
            secondaryAction={secondaryAction}
            secondaryActionText={secondaryActionText}
          />
        </div>
      </div>
    </div>
  );
};

Card.defaultProps = {
  title: '',
  subtitle: '',
  children: null,
  header: null,
  footer: null,
  color: 'alpha',
  containerStyle: {},
  bodyStyle: {},
  bodyScrollId: '',
  showPrimaryAction: false,
  disablePrimaryAction: false,
  primaryAction: () => {},
  primaryActionText: 'OK',
  showSecondaryAction: false,
  secondaryAction: () => {},
  showTertiaryAction: false,
  tertiaryAction: () => {},
  tertiaryActionText: 'Reset',
  secondaryActionText: 'Cancel',
  showHeaderAction: false,
  headerAction: () => {},
  headerActionText: null,
  headerActionIcon: null,
  containerClass: 'tw-w-full',
  innerContainerClass:
    'tw--my-2 tw-py-2 sm:tw--mx-6 sm:tw-px-6 lg:tw--mx-6 lg:tw-px-6',
  hasDetachedActions: false,
  disableSecondaryAction: false,
  disableTertiaryAction: false,
  customMarginContainer: false,
  imageUrl: '',
  imageClass: '',
  headerClass: '',
  clickable: false,
  badgeContent: '',
  showBadge: false,
  noTitleBorder: false,
  headerStyle: null
};

const styleProps = PropTypes.objectOf(
  PropTypes.oneOfType([PropTypes.string, PropTypes.number])
);

Card.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  header: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  footer: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  containerStyle: styleProps,
  bodyStyle: styleProps,
  bodyScrollId: PropTypes.string,
  showPrimaryAction: PropTypes.bool,
  disablePrimaryAction: PropTypes.bool,
  primaryAction: PropTypes.func,
  primaryActionText: PropTypes.string,
  color: PropTypes.oneOf([
    'alpha',
    'bravo',
    'charlie',
    'success',
    'warning',
    'error',
    'gray'
  ]),
  showSecondaryAction: PropTypes.bool,
  secondaryAction: PropTypes.func,
  secondaryActionText: PropTypes.string,
  showTertiaryAction: PropTypes.bool,
  tertiaryActionText: PropTypes.string,
  tertiaryAction: PropTypes.func,
  showHeaderAction: PropTypes.bool,
  headerAction: PropTypes.func,
  headerActionText: PropTypes.string,
  headerActionIcon: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  containerClass: PropTypes.string,
  innerContainerClass: PropTypes.string,
  hasDetachedActions: PropTypes.bool,
  disableSecondaryAction: PropTypes.bool,
  disableTertiaryAction: PropTypes.bool,
  customMarginContainer: PropTypes.bool,
  imageUrl: PropTypes.string,
  imageClass: PropTypes.string,
  headerClass: PropTypes.string,
  clickable: PropTypes.bool,
  badgeContent: PropTypes.string,
  showBadge: PropTypes.bool,
  noTitleBorder: PropTypes.bool,
  headerStyle: styleProps
};

export default Card;
