import styled from 'styled-components';

const SubjectInputWrapper = styled.div`
  max-height: 36px;
  z-index: 101;
`;

const SubjectInputForm = styled.form`
  & .form-type-material .form-control {
    background-image: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(#48b0f7),
        to(#48b0f7)
      ),
      -webkit-gradient(linear, left top, left bottom, from(#ebebeb), to(#ebebeb));
    background-image: -webkit-linear-gradient(#48b0f7, #48b0f7),
      -webkit-linear-gradient(#ebebeb, #ebebeb);
    background-image: linear-gradient(#48b0f7, #48b0f7),
      linear-gradient(#ebebeb, #ebebeb);
  }

  & .form-type-material .form-control.is-invalid {
    background-image: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(#f96868),
        to(#f96868)
      ),
      -webkit-gradient(linear, left top, left bottom, from(#ebebeb), to(#ebebeb)) !important;
    background-image: -webkit-linear-gradient(#f96868, #f96868),
      -webkit-linear-gradient(#ebebeb, #ebebeb) !important;
    background-image: linear-gradient(#f96868, #f96868),
      linear-gradient(#ebebeb, #ebebeb) !important;
  }

  & .form-type-material .form-control::selection {
    background-color: #48b0f7;
  }
`;

const Styled = {
  SubjectInputWrapper,
  SubjectInputForm
};

export default Styled;
