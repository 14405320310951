import React from 'react';
import PageHeader from 'components/Theme/PageHeader';
import ZenSyncPlanner from './ZenSyncPlanner';

const ZenPlannerUlProducts = () => {
  return (
    <div className="tw-min-h-screen">
      <PageHeader
        breadcrumbs={[
          {
            name: 'Zen Planner Integration',
            action: () => {
              window.location = '/account/zen_planner_settings';
            }
          },
          {
            name: 'Linking Uplaunch Products',
            action: () => {}
          }
        ]}
      />
      <ZenSyncPlanner
        customClass="tw-mx-7 tw-mt-7 tw-mb-24"
        customClassForCard="tw-bg-white tw-rounded-md"
      />
    </div>
  );
};

export default ZenPlannerUlProducts;
