/* This component only use for show functionlity of multiselect in story book. Please don't reuse it. */
import React, { useState } from 'react';
import MultiSelect from './index';
import PropTypes from 'prop-types';

const MultiSelectWrapper = props => {
  const [value, onChange] = useState([]);

  return <MultiSelect {...props} value={value} onChange={onChange} />;
};

MultiSelectWrapper.defaultProps = {
  value: [],
  color: 'alpha',
  placeholder: 'Please Select Your Options',
  required: false,
  containerStyle: {},
  labelStyle: {},
  showError: false,
  error: '',
  helpText: '',
  hideLabel: false,
  widthClass: 'tw-w-full',
  showAddNewOption: false,
  addNewOptionText: '+ Add New Item',
  onAddNewOptionClick: () => null
};

const styleProps = PropTypes.objectOf(
  PropTypes.oneOfType([PropTypes.string, PropTypes.number])
);
const optionShape = PropTypes.shape({
  displayName: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ])
});

MultiSelectWrapper.propTypes = {
  id: PropTypes.string.isRequired,
  color: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
    )
  ]),
  options: PropTypes.arrayOf(optionShape).isRequired,
  labelText: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  containerStyle: styleProps,
  labelStyle: styleProps,
  showError: PropTypes.bool,
  error: PropTypes.string,
  helpText: PropTypes.string,
  hideLabel: PropTypes.bool,
  widthClass: PropTypes.string,
  showAddNewOption: PropTypes.bool,
  addNewOptionText: PropTypes.string,
  onAddNewOptionClick: PropTypes.func
};

export default MultiSelectWrapper;
