import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import isEmpty from 'lodash.isempty';
import {
  createErrorMessageSelector,
  createLoadedSelector
} from 'appState/selectors';
import { broadcastTemplateSchema } from 'lib/validation/schema';
import { capitalize } from 'lib/utils/string';
import { broadcastTemplateSaveDetails } from 'appState/actions/ActionCreators';
import useToggleHeaderFooter from 'components/shared/hooks/useToggleHeaderFooter';
import BroadcastStep from '../BroadcastStep';
import Card from '../Card';

const SaveDetails = () => {
  const { path } = useRouteMatch();
  useToggleHeaderFooter(path);
  const dispatch = useDispatch();
  const history = useHistory();
  const errorSelector = createErrorMessageSelector([
    'broadcastCenter/BROADCAST_TEMPLATE_SAVE_DETAILS'
  ]);
  const loadedSelector = createLoadedSelector([
    'broadcastCenter/BROADCAST_TEMPLATE_SAVE_DETAILS'
  ]);

  const structuredSelector = createStructuredSelector({
    currentBroadcast: state => state.broadcastCenter.currentBroadcast,
    error: state => errorSelector(state),
    isLoaded: state => loadedSelector(state)
  });

  const { currentBroadcast, error, isLoaded } = useSelector(structuredSelector);
  const [templateTitle, setTemplateTitle] = useState('');
  const [templateDescription, setTemplateDescription] = useState('');

  useEffect(() => {
    if (!isEmpty(currentBroadcast)) {
      setTemplateTitle(currentBroadcast.title);
      setTemplateDescription(currentBroadcast.description);
    }
  }, [currentBroadcast]);

  useEffect(() => {
    if (isLoaded && isEmpty(error)) {
      app.toast('Your broadcast template details have been saved', {
        type: 'primary',
        icon: 'fa-floppy-o'
      });
    }
    if (isLoaded && !isEmpty(error)) {
      app.toast(capitalize(error), {
        type: 'danger',
        icon: 'fa-floppy-o'
      });
    }
  }, [isLoaded, error]);

  function saveDetails(_templateTitle, _templateDescription) {
    dispatch(
      broadcastTemplateSaveDetails({
        templateTitle: _templateTitle,
        templateDescription: _templateDescription,
        history
      })
    );
  }

  const formik = useFormik({
    initialValues: {
      templateTitle,
      templateDescription
    },
    enableReinitialize: true,
    validationSchema: broadcastTemplateSchema,
    onSubmit: values => {
      saveDetails(values.templateTitle, values.templateDescription);
    }
  });

  return (
    <div>
      <BroadcastStep saveAction={formik.handleSubmit}>
        <Card
          title="Save Details"
          subtitle="What should this template be called?"
        >
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <label htmlFor="templateTitle" className="require">
                  Template Title
                </label>
                <input
                  type="text"
                  className={`form-control required ${
                    formik.touched.templateTitle && formik.errors.templateTitle
                      ? 'is-invalid'
                      : ''
                  }`}
                  placeholder="Template Title"
                  id="templateTitle"
                  name="templateTitle"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.templateTitle}
                />
                {formik.touched.templateTitle && formik.errors.templateTitle ? (
                  <span className="invalid-feedback d-block">
                    {formik.errors.templateTitle}
                  </span>
                ) : null}
              </div>
              <div className="form-group">
                <label htmlFor="templateDescription" className="require">
                  Template Description
                </label>
                <input
                  type="text"
                  className={`form-control required ${
                    formik.touched.templateDescription &&
                    formik.errors.templateDescription
                      ? 'is-invalid'
                      : ''
                  }`}
                  placeholder="Template Description"
                  id="templateDescription"
                  name="templateDescription"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.templateDescription}
                />
                {formik.touched.templateDescription &&
                formik.errors.templateDescription ? (
                  <span className="invalid-feedback d-block">
                    {formik.errors.templateDescription}
                  </span>
                ) : null}
              </div>
            </div>
          </div>
        </Card>
      </BroadcastStep>
    </div>
  );
};

export default SaveDetails;
