import React from 'react';
import PropTypes from 'prop-types';
import { toCamel } from 'lib/utils/string';
import HeroIcon from 'components/Theme/HeroIcon';
import Tooltip from 'components/Theme/Tooltip/index';

const Select = ({
  id,
  value,
  options,
  labelText,
  placeholder,
  required,
  selectStyle,
  optionStyle,
  containerStyle,
  labelStyle,
  onChange,
  onBlur,
  showError,
  error,
  helpText,
  hideLabel,
  widthClass,
  toolTipText,
  helpTextFontClass
}) => {
  function getName() {
    return toCamel(id);
  }

  function selectOption(option) {
    return (
      <option
        key={option.value}
        value={option.value}
        style={optionStyle}
        disabled={option.disabled || false}
      >
        {option.displayName}
      </option>
    );
  }

  function selectOptGroup(opt) {
    return opt.options.length ? (
      <optgroup key={opt.label} label={opt.label}>
        {opt.options.map(option => selectOption(option))}
      </optgroup>
    ) : null;
  }

  function getLabel() {
    return (
      <label
        htmlFor={id}
        className={`${
          hideLabel
            ? 'tw-sr-only'
            : 'tw-font-body tw-block tw-text-sm tw-font-medium tw-leading-5 tw-text-gray-700'
        }`}
        style={labelStyle}
      >
        {labelText}
        <span className="tw-text-error-700">{required ? '*' : null}</span>
        {toolTipText && (
          <Tooltip
            id={id}
            place="right"
            classNames="tw-bg-white"
            TooltipElement={() => {
              return (
                <span
                  className="tw-absolute tw-items-center tw-cursor-pointer tw-ml-1 tw-mt-px"
                  data-tip
                  data-for={id}
                >
                  <HeroIcon
                    icon="question-mark-circle"
                    width="4"
                    height="4"
                    color="alpha"
                  />
                </span>
              );
            }}
          >
            <p className="tw-text-gray-500 tw-bg-white tw-mb-0">
              {toolTipText}
            </p>
          </Tooltip>
        )}
      </label>
    );
  }

  return (
    <div className={widthClass} style={containerStyle}>
      {getLabel()}
      <div className="tw-mt-1">
        <div className="tw-rounded-md tw-shadow-sm">
          <select
            id={id}
            data-testid={id}
            name={getName()}
            className={`tw-font-body tw-block tw-form-select tw-w-full tw-transition tw-duration-150 tw-ease-in-out sm:tw-text-sm sm:tw-leading-5 tw-border tw-border-solid tw-border-${
              showError ? 'red' : 'gray'
            }-300 focus:tw-outline-none focus:tw-shadow-outline-${
              showError ? 'red' : 'blue'
            } focus:tw-border-${showError ? 'red' : 'blue'}-300`}
            style={selectStyle}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
          >
            <option value="" style={optionStyle}>
              {placeholder}
            </option>
            {options.map(opt =>
              opt.options
                ? // special case: when otpgroup not have any options
                  selectOptGroup(opt)
                : selectOption(opt)
            )}
          </select>
        </div>
      </div>
      {helpText ? (
        <p
          className={`tw-font-body tw-my-2 tw-text-gray-500 ${helpTextFontClass}`}
        >
          {helpText}
        </p>
      ) : null}
      {showError ? (
        <p className="tw-font-body tw-my-2 tw-text-sm tw-text-red-600">
          {error}
        </p>
      ) : null}
    </div>
  );
};

Select.defaultProps = {
  value: '',
  placeholder: 'Nothing Selected',
  required: false,
  selectStyle: {},
  optionStyle: {},
  containerStyle: {},
  labelStyle: {},
  onChange: null,
  onBlur: null,
  showError: false,
  error: '',
  helpText: '',
  hideLabel: false,
  widthClass: 'tw-col-span-10',
  toolTipText: null,
  labelText: '',
  helpTextFontClass: 'tw-text-sm'
};

const styleProps = PropTypes.objectOf(
  PropTypes.oneOfType([PropTypes.string, PropTypes.number])
);
const optionShape = PropTypes.shape({
  displayName: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ])
});

Select.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]),
  options: PropTypes.arrayOf(optionShape).isRequired,
  labelText: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  selectStyle: styleProps,
  optionStyle: styleProps,
  containerStyle: styleProps,
  labelStyle: styleProps,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  showError: PropTypes.bool,
  error: PropTypes.string,
  helpText: PropTypes.string,
  hideLabel: PropTypes.bool,
  widthClass: PropTypes.string,
  toolTipText: PropTypes.string,
  helpTextFontClass: PropTypes.string
};

export default Select;
