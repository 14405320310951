import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

const TextLoader = ({ loaderText, intervalSeconds }) => {
  const [index, setIndex] = useState(0);
  const [timeoutCount, setTimeoutCount] = useState(1);
  let loadingText = `${loaderText[index]}`;
  const [progressText, setProgressText] = useState(loadingText);

  const indexRef = useRef(index);
  indexRef.current = index;

  const timeoutCountRef = useRef(timeoutCount);
  timeoutCountRef.current = timeoutCount;

  useEffect(() => {
    const timeout = setTimeout(() => {
      loadingText = `${progressText}.`;
      if (timeoutCount % 3 === 0) {
        if (loaderText.length - 1 > index) setIndex(indexRef.current + 1);
        loadingText = `${loaderText[index]}.`;
      }

      setProgressText(loadingText);
      setTimeoutCount(timeoutCountRef.current + 1);
    }, intervalSeconds * 1000);

    return () => clearTimeout(timeout);
  }, [progressText, intervalSeconds]);

  return <div>{progressText}</div>;
};

TextLoader.defaultProps = {
  intervalSeconds: 1
};

TextLoader.propTypes = {
  loaderText: PropTypes.arrayOf(PropTypes.string).isRequired,
  intervalSeconds: PropTypes.number
};

export default TextLoader;
