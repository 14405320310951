import { call, put, takeLatest } from 'redux-saga/effects';
import { axiosDefault } from 'lib/utils/axios-setup';
import {
  FETCH_BLOGS_REQUEST,
  FETCH_BLOGS_SUCCESS,
  FETCH_BLOGS_FAILURE
} from 'appState/actions/constants/blog.actions';

function* fetchBlogs() {
  try {
    const response = yield call(axiosDefault.get, `/posts`);

    yield put({
      type: FETCH_BLOGS_SUCCESS,
      payload: {
        activities: response.data
      }
    });
  } catch (e) {
    yield put({
      type: FETCH_BLOGS_FAILURE,
      payload: { error: e },
      error: true
    });
  }
}

export default function* blogTypesFetch() {
  yield takeLatest(FETCH_BLOGS_REQUEST, fetchBlogs);
}
