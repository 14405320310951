import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import {
  createLoadedSelector,
  createErrorMessageSelector
} from 'appState/selectors';
import {
  appointmentTypeCreateSchema,
  GroupAppointmentTypeCreateSchema
} from 'lib/validation/schema';
import {
  appointmentTypeUpdate,
  appointmentTypeFetch,
  flashErrorMessage,
  flashSuccessMessage
} from 'appState/actions/ActionCreators';
import { CurrentAccountContext } from 'components/shared/context/CurrentAccountContext';
import Card from 'components/Theme/Card';
import PageHeader from 'components/Theme/PageHeader';
import {
  FETCH_APPOINTMENT_TYPE,
  UPDATE_APPOINTMENT_TYPE
} from 'appState/actions/constants/appointmentType.actions';
import isEmpty from 'lodash.isempty';
import AppointmentTypeForm from './_form';

const EditAppointmentType = () => {
  const currentAccount = useContext(CurrentAccountContext);
  const params = useParams();
  const { id } = params;
  const dispatch = useDispatch();
  const fetchApptTypeLoadedSelector = createLoadedSelector([
    FETCH_APPOINTMENT_TYPE
  ]);
  const updateApptTypeLoadedSelector = createLoadedSelector([
    UPDATE_APPOINTMENT_TYPE
  ]);
  const updateApptTypeErrorSelector = createErrorMessageSelector([
    UPDATE_APPOINTMENT_TYPE
  ]);
  const structuredSelector = createStructuredSelector({
    currentAppointmentType: state =>
      state.appointmentType.currentAppointmentType,
    fetchApptTypeLoaded: state => fetchApptTypeLoadedSelector(state),
    updateApptTypeLoaded: state => updateApptTypeLoadedSelector(state),
    updateApptTypeError: state => updateApptTypeErrorSelector(state)
  });

  const {
    currentAppointmentType,
    fetchApptTypeLoaded,
    updateApptTypeLoaded,
    updateApptTypeError
  } = useSelector(structuredSelector);

  const updateAppointmentType = (values, userArray) => {
    dispatch(
      appointmentTypeUpdate({ params: values, permittedStaffIds: userArray })
    );
  };

  const handleCancelAction = () => {
    window.location = '/ui/appointment-types';
  };

  const appointmentFormik = useFormik({
    initialValues: {
      id: currentAppointmentType?.id,
      name: currentAppointmentType?.name,
      permittedStaffIds: currentAppointmentType?.permitted_staff_ids?.map(
        staffId => staffId.toString()
      ),
      allowSchedulling: currentAppointmentType?.allow_self_scheduling,
      duration: `${currentAppointmentType?.duration}_min`,
      padding: `${currentAppointmentType?.padding}_min`,
      notice: `${currentAppointmentType?.advanced_notice}_hrs`,
      hasFutureBookingRestricted:
        currentAppointmentType?.future_booking_restricted,
      futureBookingRestrictionDays:
        currentAppointmentType?.future_booking_restriction_days,
      url: currentAppointmentType?.booking_redirect_url,
      hasBookingRedirect: currentAppointmentType?.booking_redirect,
      contactFormType: currentAppointmentType?.booking_form_id,
      hasBookingForm: currentAppointmentType?.has_booking_form,
      maxAttendees: currentAppointmentType?.max_attendees
    },
    enableReinitialize: true,
    validationSchema: currentAccount.group_scheduling_enabled
      ? GroupAppointmentTypeCreateSchema
      : appointmentTypeCreateSchema,
    onSubmit: values => {
      updateAppointmentType(values);
    }
  });

  useEffect(() => {
    dispatch(appointmentTypeFetch({ appointmentTypeId: id }));
  }, []);

  useEffect(() => {
    if (updateApptTypeLoaded) {
      if (isEmpty(updateApptTypeError)) {
        dispatch(flashSuccessMessage('Appointment Type Updated.'));
        window.location = '/ui/appointment-types';
      } else {
        dispatch(flashErrorMessage(updateApptTypeError));
      }
    }
  }, [updateApptTypeError, updateApptTypeLoaded]);

  return (
    <div>
      <PageHeader
        title="Edit Appointment Type"
        breadcrumbs={[
          {
            name: 'Settings',
            action: () => {
              window.location = '/account/account_settings';
            }
          },
          {
            name: 'Appointment Types',
            action: () => {
              window.location = '/ui/appointment-types';
            }
          },
          {
            name: 'Edit Appointment Type',
            action: () => {}
          }
        ]}
        backgroundColor="white"
        isLoaded={fetchApptTypeLoaded}
      />
      <Card
        containerClass="tw-rounded-lg tw-bg-white tw-m-8 tw-mb-20"
        customMarginContainer
        showPrimaryAction
        showSecondaryAction
        hasDetachedActions
        primaryAction={appointmentFormik.handleSubmit}
        primaryActionText="Update"
        secondaryAction={handleCancelAction}
      >
        <AppointmentTypeForm type="edit" formik={appointmentFormik} />
      </Card>
    </div>
  );
};

export default EditAppointmentType;
