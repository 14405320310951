const defaultProps = {
  onClick: null,
  color: 'alpha',
  tint: '400',
  filled: false,
  pathDefinition: 'M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z', // check-circle
  height: '5',
  width: '5',
  IconComponent: null,
  icon: null,
  strokeWidth: '2'
};

export default defaultProps;
