import styled from 'styled-components';

const PhoneContentContainer = styled.div`
  margin-top: 20px;
  & .block-grid,
  & .block-grid .col.num12,
  & .col,
  & .nl-container {
    min-width: 278px !important;
    max-width: 278px !important;
    width: 278px !important;
  }
`;

const Label = styled.label`
  color: ${props => (props.disabled ? '#8b95a5' : '#4d5259')};
`;

const Styled = {
  PhoneContentContainer,
  Label
};

export default Styled;
