/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {
  Fragment,
  useState,
  useContext,
  useEffect,
  useMemo
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { formikShape } from 'lib/utils/prop-types-extensions';
import { createStructuredSelector } from 'reselect';
import get from 'lodash.get';
import {
  fetchAppointmentTypes,
  setCurrentAppointmentTypeId,
  resetIsLoaded
} from 'appState/actions/ActionCreators';
import {
  createErrorMessageSelector,
  createLoadedSelector
} from 'appState/selectors';
import { scrollToTop } from 'lib/utils/scroll';
import TextInput from 'components/Theme/TextInput';
import NewAppointmentType from 'components/AppointmentTypes/New';
import Button from 'components/Theme/Button';
import Toggle from 'components/Theme/Toggle';
import isEmpty from 'lodash.isempty';
import Select from 'components/Theme/Select';
import { CurrentAccountContext } from 'components/shared/context/CurrentAccountContext';
import { UsersContext } from 'components/shared/context/UsersContext';
import {
  Label,
  RadioInput
} from 'components/ProductSubscriptionSetup/shared/FormInputs';
import * as StepConstants from 'components/ProductSubscriptionSetup/shared/StepConstants';
import { CREATE_APPOINTMENT_TYPE } from 'appState/actions/constants/appointmentType.actions';

const StepOne = ({
  product,
  formik,
  isDefaultProductReadOnly,
  setZenPlannerModalOpen
}) => {
  const currentAccount = useContext(CurrentAccountContext);
  const { users } = useContext(UsersContext);
  const dispatch = useDispatch();
  const [showAppointmentTypeForm, setShowAppointmentTypeForm] = useState(false);
  const errorSelector = createErrorMessageSelector([CREATE_APPOINTMENT_TYPE]);
  const loadedSelector = createLoadedSelector([CREATE_APPOINTMENT_TYPE]);
  const structuredSelector = createStructuredSelector({
    appointmentTypes: state => state.contact.appointmentTypes,
    currentProductSubscription: state =>
      state.productSubscription.currentProductSubscription,
    currentAppointmentTypeId: state => state.contact.currentAppointmentTypeId,
    currentAppointmentType: state =>
      state.appointmentType.currentAppointmentType,
    error: state => errorSelector(state),
    isLoaded: state => loadedSelector(state)
  });

  const {
    appointmentTypes,
    currentAppointmentType,
    currentProductSubscription,
    error,
    isLoaded
  } = useSelector(structuredSelector);

  const appointmentTypeOptions = appointmentTypes.map(apptType => {
    return { displayName: apptType.name, value: apptType.id };
  });

  const fromNameOptions = [
    { displayName: 'Assigned Staff', value: 'assigned_staff' },
    { displayName: currentAccount.name, value: currentAccount.name },
    { displayName: 'Other', value: 'other' }
  ];

  const userSelectOptions = useMemo(() => {
    return users.map(user => ({ displayName: user.email, value: user.id }));
  }, [users]);

  function handleChangeAppointmentTypeSelect(apptTypeId) {
    formik.setFieldValue('appointmentTypeId', apptTypeId.toString());
    dispatch(setCurrentAppointmentTypeId({ id: apptTypeId }));
    formik.setFieldValue(
      'appointmentTypeName',
      get(
        appointmentTypes.find(appt => appt.id.toString() === apptTypeId),
        'name'
      )
    );
  }

  function onToggleChange(_ref, featured) {
    formik.setFieldValue('optInRedirectEnabled', featured);
  }

  const handleChangeHasAppointment = value => {
    formik.setFieldValue('hasAppointment', value);

    if (!formik.values.hasAppointment) setShowAppointmentTypeForm(false);
  };

  const handleChangeHasDefaultProduct = value => {
    if (isDefaultProductReadOnly) return;
    formik.setFieldValue('hasDefaultProduct', value);
  };

  const hideCreateAppointmentTypeForm = () => {
    setShowAppointmentTypeForm(!showAppointmentTypeForm);
    scrollToTop();
  };

  useEffect(() => {
    if (
      !isEmpty(
        currentProductSubscription?.journey?.appointment_type_id?.toString()
      ) &&
      !isEmpty(appointmentTypes)
    )
      formik.setFieldValue(
        'appointmentTypeName',
        get(
          appointmentTypes.find(
            appt =>
              appt.id.toString() ===
              currentProductSubscription?.journey?.appointment_type_id?.toString()
          ),
          'name'
        )
      );
  }, [dispatch, appointmentTypes, currentProductSubscription]);

  useEffect(() => {
    if (isEmpty(appointmentTypes))
      dispatch(fetchAppointmentTypes({ accountId: currentAccount.id }));
  }, [dispatch, appointmentTypes, currentAccount]);

  useEffect(() => {
    if (isLoaded && isEmpty(error)) {
      dispatch(fetchAppointmentTypes({ accountId: currentAccount.id }));
      dispatch(resetIsLoaded(CREATE_APPOINTMENT_TYPE));
      handleChangeAppointmentTypeSelect(currentAppointmentType.id);
      hideCreateAppointmentTypeForm();
    }
  }, [dispatch, isLoaded, error, currentAccount]);

  return (
    <Fragment>
      <form action="#" method="POST">
        <div className="tw-mx-auto sm:tw-px-6 lg:tw-px-8">
          <div className="tw-mb-8">
            <div className="tw-grid tw-grid-cols-12 tw-gap-6">
              <div className="tw-grid tw-gap-6 tw-col-span-12">
                <TextInput
                  id="name"
                  value={formik.values.name}
                  labelText="Name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  showError={formik.touched.name && !!formik.errors.name}
                  error={formik.errors.name}
                  widthClass="lg:tw-w-1/3 sm:tw-w-1/2 xxs:tw-w-full "
                />
                {product.lifecycles && product.lifecycles.includes('lead') ? (
                  <Fragment>
                    <TextInput
                      id="landingPageTitle"
                      value={formik.values.landingPageTitle}
                      labelText="Call to Action Title on Landing Page"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      showError={
                        formik.touched.landingPageTitle &&
                        !!formik.errors.landingPageTitle
                      }
                      error={formik.errors.landingPageTitle}
                      widthClass="lg:tw-w-1/3 sm:tw-w-1/2 xxs:tw-w-full "
                    />
                    <TextInput
                      id="landingPageCta"
                      value={formik.values.landingPageCta}
                      labelText="Call to Action Text on Button"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      showError={
                        formik.touched.landingPageCta &&
                        !!formik.errors.landingPageCta
                      }
                      error={formik.errors.landingPageCta}
                      widthClass="lg:tw-w-1/6 sm:tw-w-1/2 xxs:tw-w-full "
                    />
                  </Fragment>
                ) : null}
                <Select
                  value={formik.values.nameFrom}
                  id="nameFrom"
                  labelText="From Name"
                  options={fromNameOptions}
                  onChange={formik.handleChange}
                  widthClass="lg:tw-w-1/6 sm:tw-w-1/2 xxs:tw-w-full "
                />
                {formik.values.nameFrom === 'others' && (
                  <TextInput
                    id="customNameFrom"
                    value={formik.values.customNameFrom}
                    labelText="Custom From Name"
                    onChange={formik.handleChange}
                    widthClass="lg:tw-w-1/6 sm:tw-w-1/2 xxs:tw-w-full"
                  />
                )}
                <Select
                  value={formik.values.campaignOwnerId}
                  id="campaignOwnerId"
                  labelText="From Email (Product Owner)"
                  options={userSelectOptions}
                  onChange={formik.handleChange}
                  widthClass="lg:tw-w-1/6 sm:tw-w-1/2 xxs:tw-w-full "
                  toolTipText="Any campaign automation from this product will be assigned to the staff associated with this “From Email.” This staff member will override the contact’s assigned staff for this product’s automations."
                />
              </div>
              <div className="tw-grid tw-col-span-12 tw-gap-6">
                <div className="tw-mx-2 tw-col-span-12 lg:tw-grid-cols-12">
                  <Label text="Default Product" />
                  <Label
                    text="Make this your default product?"
                    labelClasses="tw-text-gray-500"
                  />
                  <RadioInput
                    name="hasDefaultProduct"
                    id={StepConstants.SHOW_DEFAULT_PRODUCT}
                    value
                    onChange={() => handleChangeHasDefaultProduct(true)}
                    onBlur={formik.handleBlur}
                    checked={formik.values.hasDefaultProduct}
                    labelText="Yes"
                  />
                  <RadioInput
                    name="hasDefaultProduct"
                    id={StepConstants.HIDE_DEFAULT_PRODUCT}
                    value={false}
                    onChange={() => handleChangeHasDefaultProduct(false)}
                    onBlur={formik.handleBlur}
                    checked={formik.values.hasDefaultProduct === false}
                    labelText="No"
                  />
                </div>
                {currentAccount.link_products_with_platform && (
                  <div className="tw-mx-2 tw-col-span-12 lg:tw-grid-cols-12">
                    <span
                      onClick={() => {
                        setZenPlannerModalOpen(true);
                      }}
                      className="tw-cursor-pointer tw-group tw-inline-flex tw-items-center tw-px-1 tw-border-t-0 tw-border-l-0 tw-border-r-0 tw-font-medium tw-text-sm tw-leading-5 focus:tw-outline-none  tw-border-alpha-500 tw-text-alpha-600 focus:tw-text-alpha-800 focus:tw-border-alpha-700"
                    >
                      Linking Uplaunch Products
                    </span>
                  </div>
                )}
                <div className="tw-mx-2 tw-col-span-12 lg:tw-grid-cols-12">
                  <Label text="Appointment Type" />
                  <Label
                    text="Is an Appointment required?"
                    labelClasses="tw-text-gray-500"
                  />
                  <RadioInput
                    name="hasAppointment"
                    id={StepConstants.SHOW_APPOINTMENT}
                    value
                    onChange={() => handleChangeHasAppointment(true)}
                    onBlur={formik.handleBlur}
                    checked={formik.values.hasAppointment}
                    labelText="Yes"
                  />
                  <RadioInput
                    name="hasAppointment"
                    id={StepConstants.HIDE_APPOINTMENT}
                    value={false}
                    onChange={() => handleChangeHasAppointment(false)}
                    onBlur={formik.handleBlur}
                    checked={formik.values.hasAppointment === false}
                    labelText="No"
                  />
                </div>
                <div className="tw-mx-2 tw-grid tw-gap-6 tw-col-span-12">
                  {formik.values.hasAppointment && !showAppointmentTypeForm ? (
                    <Fragment>
                      <div className="tw-flex">
                        <Select
                          value={formik.values.appointmentTypeId}
                          id="appointmentTypeId"
                          labelText="Select an Appointment Type"
                          options={appointmentTypeOptions}
                          onChange={e =>
                            handleChangeAppointmentTypeSelect(e.target.value)
                          }
                          onBlur={formik.handleBlur}
                          widthClass="tw-flex-auto"
                          showError={
                            formik.touched.appointmentTypeId &&
                            !!formik.errors.appointmentTypeId
                          }
                          error={formik.errors.appointmentTypeId}
                        />
                        <div className="tw-flex-auto tw-self-end tw-ml-5">
                          <Button
                            text="New Appointment Type"
                            onClick={() =>
                              setShowAppointmentTypeForm(
                                !showAppointmentTypeForm
                              )
                            }
                            type="white"
                          />
                        </div>
                      </div>
                    </Fragment>
                  ) : null}
                  <div className="tw-block">
                    <Toggle
                      color="alpha"
                      onClick={onToggleChange}
                      isToggled={formik.values.optInRedirectEnabled}
                      withLabel
                      label="Redirect to custom page after successful opt-in?"
                    />
                    <div className="tw-mt-2">
                      If enabled, this will show a custom page after the opt-in
                      INSTEAD of the scheduler.
                      <br />
                      The emails will still have a call-to-action that drives
                      the contact to book a appointment type.
                    </div>
                  </div>
                  {formik.values.optInRedirectEnabled && (
                    <Fragment>
                      <div className="tw-flex">
                        <TextInput
                          id="optInRedirectUrl"
                          value={formik.values.optInRedirectUrl}
                          labelText="Custom redirect Url (including http:// and/or www)"
                          onChange={formik.handleChange}
                          widthClass="lg:tw-w-1/3 sm:tw-w-1/2 xxs:tw-w-full"
                        />
                      </div>
                    </Fragment>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      {formik.values.hasAppointment && showAppointmentTypeForm ? (
        <NewAppointmentType
          isNestedForm
          onCancelAction={hideCreateAppointmentTypeForm}
        />
      ) : null}
    </Fragment>
  );
};

StepOne.defaultProps = {
  product: {
    lifecycles: []
  },
  formik: {},
  isDefaultProductReadOnly: false,
  setZenPlannerModalOpen: () => {}
};

const productShape = {
  lifecycles: PropTypes.array
};

StepOne.propTypes = {
  product: PropTypes.shape(productShape),
  formik: PropTypes.shape(formikShape),
  isDefaultProductReadOnly: PropTypes.bool,
  setZenPlannerModalOpen: PropTypes.func
};

export default StepOne;
