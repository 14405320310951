import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { broadcastSetSelected } from 'appState/actions/ActionCreators';

const BroadcastListItemActions = props => {
  const { broadcast } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const selectBroadcast = () => {
    dispatch(broadcastSetSelected(broadcast.id));
  };

  function handleEditButton() {
    history.push(`/ui/broadcast-center/${broadcast.id}/select-audience`);
  }

  return (
    <div className="d-flex justify-content-end align-self-center">
      <div
        className={`dropdown ${
          broadcast.status === 'scheduled' ? 'ml-15' : 'mr-15'
        }`}
      >
        <a
          className="text-lighter"
          href="#"
          data-toggle="dropdown"
          onClick={() => selectBroadcast()}
        >
          <i className="ti-more-alt rotate-90" />
        </a>
        <div className="dropdown-menu dropdown-menu-right">
          {['draft', 'error'].includes(broadcast.status) ? (
            <a
              className="dropdown-item cursor-pointer"
              href="#"
              onClick={handleEditButton}
            >
              <i className="fa fa-fw fa-pencil" /> Edit
            </a>
          ) : null}
          {broadcast.status === 'scheduled' ? (
            <a
              className="dropdown-item cursor-pointer"
              data-toggle="modal"
              data-target="#edit-scheduled-broadcast-confirmation-modal"
              href="#"
            >
              <i className="fa fa-fw fa-pencil" /> Edit
            </a>
          ) : null}
          <div className="dropdown-divider" />
          <a
            className="dropdown-item delete-template-button cursor-pointer"
            data-toggle="modal"
            data-target="#delete-template-modal"
            data-broadcast-template-id={broadcast.id}
            href="#"
          >
            <i className="fa fa-fw fa-trash" /> Delete
          </a>
        </div>
      </div>
    </div>
  );
};

const broadcastShape = {
  updated_at: PropTypes.string
};

BroadcastListItemActions.propTypes = {
  broadcast: PropTypes.shape(broadcastShape).isRequired
};

export default BroadcastListItemActions;
