import React from 'react';
import { action } from '@storybook/addon-actions';
import { withA11y } from '@storybook/addon-a11y';
import { withKnobs, text, object, select } from '@storybook/addon-knobs';
import RadioButtons from './index';

let colorSelect;

function includeColorSelect(defaultValue) {
  const label = 'Color';
  const options = [
    'alpha',
    'bravo',
    'charlie',
    'success',
    'warning',
    'error',
    'gray'
  ];
  colorSelect = select(label, options, defaultValue);
}

export default {
  component: RadioButtons,
  title: 'Radio Buttons',
  decorators: [withA11y, withKnobs]
};

export const horizontal = () => {
  includeColorSelect('alpha');
  return (
    <RadioButtons
      id="radio-buttons"
      selected={text('selected', 'legacy')}
      options={object('RadioButtons Options', [
        { displayName: 'Legacy', value: 'legacy' },
        { displayName: 'Bundle', value: 'bundle' },
        { displayName: 'Add-on', value: 'add_on' }
      ])}
      label={text('Label Text', 'Type')}
      color={colorSelect}
      type="horizontal"
      onChange={action('select-change')}
      onBlur={action('select-blur')}
    />
  );
};

export const vertical = () => {
  includeColorSelect('alpha');
  return (
    <RadioButtons
      id="radio-buttons"
      selected={text('selected', 'legacy')}
      options={object('RadioButtons Options', [
        { displayName: 'Legacy', value: 'legacy' },
        { displayName: 'Bundle', value: 'bundle' },
        { displayName: 'Add-on', value: 'add_on' }
      ])}
      label={text('Label Text', 'Type')}
      color={colorSelect}
      type="vertical"
      onChange={action('select-change')}
      onBlur={action('select-blur')}
    />
  );
};
