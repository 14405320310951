import React, { useContext, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  createErrorMessageSelector,
  createLoadedSelector
} from 'appState/selectors';
import {
  assignNumberForAccount,
  resetIsLoaded
} from 'appState/actions/ActionCreators';
import { ASSIGN_NUMBER_FOR_ACCOUNT } from 'appState/actions/constants/overlord.actions';
import UtilityCard from 'components/OverlordUtilities/shared/UtilityCard';
import { CurrentAccountContext } from 'components/shared/context/CurrentAccountContext';
import useProcessingActivity from 'components/shared/hooks/useProcessingActivity';
import useAccountDisabled from 'components/shared/hooks/useAccountDisabled';
import isEmpty from 'lodash.isempty';

const UTILITY_NAMES = ['phone_number_assigner'];

const PhoneNumberAssign = () => {
  const currentAccount = useContext(CurrentAccountContext);
  const [accountPhoneNumber, setAccountPhoneNumber] = useState('');
  const [localError, setLocalError] = useState(false);
  const processingActivity = useProcessingActivity(UTILITY_NAMES);
  const accountDisabled = useAccountDisabled();

  const loadedSelector = createLoadedSelector([ASSIGN_NUMBER_FOR_ACCOUNT]);
  const errorSelector = createErrorMessageSelector([ASSIGN_NUMBER_FOR_ACCOUNT]);
  const structuredSelector = createStructuredSelector({
    accountPhoneNumberForView: state =>
      state.overlordUtilities.accountPhoneNumber,
    isLoaded: state => loadedSelector(state),
    error: state => errorSelector(state)
  });
  const { accountPhoneNumberForView, isLoaded, error } = useSelector(
    structuredSelector
  );
  const dispatch = useDispatch();

  const assignPhoneNumber = () => {
    const phoneNumberPattern = /\d{10}\d*/;
    const match = accountPhoneNumber.match(phoneNumberPattern);
    if (accountPhoneNumber && match && accountPhoneNumber === match[0]) {
      setLocalError(false);
      dispatch(
        assignNumberForAccount({
          accountPhoneNumber
        })
      );
      return;
    }
    setLocalError('Invalid Number');
  };

  useEffect(() => {
    if (localError)
      app.toast(`${localError}`, {
        type: 'danger',
        icon: 'fa-floppy-o'
      });
  }, [localError]);

  useEffect(() => {
    if (isLoaded && isEmpty(error)) {
      app.toast(
        `Successfully Assigned phone number: ${accountPhoneNumberForView}`,
        {
          type: 'primary',
          icon: 'fa-floppy-o'
        }
      );
    } else if (isLoaded && !isEmpty(error)) {
      app.toast(`Error: ${error}`, {
        type: 'danger',
        icon: 'fa-floppy-o'
      });
    }
    return () => {
      dispatch(resetIsLoaded(ASSIGN_NUMBER_FOR_ACCOUNT));
    };
  }, [accountPhoneNumberForView, isLoaded, error, dispatch]);

  const getCardBody = () => {
    return (
      <div>
        <div className="row">
          <div className="col-lg-12">
            <p className="mb-10">
              Enter number to be assigned to this account. Important: The number
              must be ready or fully ported in Twilio. Input phone number with
              country code without + sign
            </p>
            <label htmlFor="phoneNumber">Phone Number</label>
            <input
              id="phoneNumber"
              className="form-control"
              type="text"
              onKeyUp={e => setAccountPhoneNumber(e.target.value)}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <UtilityCard
      title="Phone Number Manually Assign"
      subtitle="Use this tool to manually assign or reassign a specific phone number."
      submitBtnText="Assign Phone Number"
      submitBtnAction={assignPhoneNumber}
      bodyComponent={getCardBody}
      processingActivity={processingActivity}
      accountDisabled={accountDisabled}
      error={localError || error}
    />
  );
};

export default PhoneNumberAssign;
