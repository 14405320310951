import React from 'react';
import { withA11y } from '@storybook/addon-a11y';
import { withKnobs } from '@storybook/addon-knobs';
import PopOver from './index';

export default {
  component: PopOver,
  title: 'PopOver',
  decorators: [withA11y, withKnobs]
};

export const QuestionMark = () => {
  return <PopOver />;
};
