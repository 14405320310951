export const SUB_TITLES = [
  'Import contacts into your account.',
  'Now let’s match the columns in your uploaded csv to a field in UpLaunch.',
  'Review details of your processed csv file and fix any errors.',
  'Please review your selections below before importing your data.'
];

export const TITLES = [
  'New Import',
  'Map Headers to Columns',
  'Review Processed File',
  'Import Status'
];

export const REVIEW_HEADERS_INFO_PANEL = [
  'First Name, Last Name, and Email are required column mappings.',
  'All headers must be mapped to a column or skipped.',
  "All skipped headers' data will not be imported into the platform."
];

export const VALIDATE_HEADERS_INFO_PANEL = [
  'All the rows in the csv file have been processed based on your map settings and results are displayed below.',
  'Please fix all error rows if you want them to be imported into the platform.',
  'Click on the data that needs to be updated, and click Update to save the changes.'
];

export const MANDATORY_HEADERS = [
  { displayName: 'First Name', value: 'first_name', disabled: false },
  { displayName: 'Last Name', value: 'last_name', disabled: false },
  { displayName: 'Email', value: 'email', disabled: false },
  { displayName: 'Phone', value: 'phone', disabled: false },
  { displayName: 'Birthday', value: 'birthday', disabled: false },
  { displayName: 'Gender', value: 'gender', disabled: false },
  { displayName: 'Address 1', value: 'address_1', disabled: false },
  { displayName: 'Address 2', value: 'address_2', disabled: false },
  { displayName: 'City', value: 'city', disabled: false },
  { displayName: 'State', value: 'state', disabled: false },
  { displayName: 'Postal Code', value: 'postal_code', disabled: false },
  { displayName: 'Skip - Will Not Be Imported', value: 'skip', disabled: false }
];

export const MANDATORY_FIELDS = ['first_name', 'last_name', 'email'];
