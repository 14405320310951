import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import createFlashMessageMiddleware from 'app-state/middleware/flashMessageMiddleware';
import rootSaga from 'app-state/sagas';
import reducers from 'app-state/reducers';

const sagaMiddleware = createSagaMiddleware();
const flashMessageMiddleware = createFlashMessageMiddleware();

const initialState = {
  smsInbox: {
    allReadConversations: [
      {
        id: 1,
        name: 'Test Name',
        last_message: 'Allo allo'
      }
    ]
  }
};

const middleware = [flashMessageMiddleware, sagaMiddleware];
/* eslint-disable no-underscore-dangle */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const enhancer = composeEnhancers(applyMiddleware(...middleware));
/* eslint-enable */

const store = createStore(reducers, initialState, enhancer);

export default function configureStore() {
  sagaMiddleware.run(rootSaga);
  return store;
}
