/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useFormik } from 'formik';
import isEmpty from 'lodash.isempty';
import {
  createErrorMessageSelector,
  createLoadingSelector,
  createLoadedSelector
} from 'appState/selectors';
import {
  subscriptionFetch,
  subscriptionUpdate
} from 'appState/actions/ActionCreators';
import ProductSubscription from 'components/Subscriptions/Subscription/ProductSubscription';
import Card from 'components/Theme/Card';
import DatePicker from 'components/Theme/DatePicker';
import Modal from 'components/Theme/Modal';
import NavBar from 'components/Theme/NavBar';
import PageHeader from 'components/Theme/PageHeader';
import Select from 'components/Theme/Select';
import Table from 'components/Theme/Table';
import TextInput from 'components/Theme/TextInput';
import Notification from 'components/Theme/Notification';
import { subscriptionSchema } from 'lib/validation/schema';
import { formatTimestamp } from 'lib/utils/dateTime';

const SubscriptionEdit = () => {
  const [showModal, setShowModal] = useState(false);
  const [currentProductSubscription, setCurrentProductSubscription] = useState(
    {}
  );
  const [
    isEditingProductSubscription,
    setIsEditingProductSubscription
  ] = useState(false);
  const [
    isCreatingProductSubscription,
    setIsCreatingProductSubscription
  ] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [localProductSubscriptions, setLocalProductSubscriptions] = useState(
    []
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const { path } = useRouteMatch();
  const params = useParams();
  const { id } = params;

  function handleSetShowSuccess(show) {
    setShowSuccess(show);
  }

  const errorSelector = createErrorMessageSelector([
    'subscription/FETCH_SUBSCRIPTION',
    'subscription/UPDATE_SUBSCRIPTION'
  ]);
  const loadingSelector = createLoadingSelector([
    'subscription/FETCH_SUBSCRIPTION',
    'subscription/UPDATE_SUBSCRIPTION'
  ]);
  const loadedSelector = createLoadedSelector([
    'subscription/FETCH_SUBSCRIPTION',
    'subscription/UPDATE_SUBSCRIPTION'
  ]);
  const subscriptionUpdatedSelector = createLoadedSelector([
    'subscription/UPDATE_SUBSCRIPTION'
  ]);
  const structuredSelector = createStructuredSelector({
    currentSubscription: state => state.subscription.currentSubscription,
    error: state => errorSelector(state),
    isLoading: state => loadingSelector(state),
    isLoaded: state => loadedSelector(state),
    subscriptionUpdated: state => subscriptionUpdatedSelector(state)
  });

  const {
    currentSubscription,
    subscriptionUpdated,
    error,
    isLoading
  } = useSelector(structuredSelector);

  function handleSubmit(values) {
    const subscriptionParams = { subscriptionId: id };
    if (Object.keys(values).length)
      Object.keys(values).forEach(function assignParams(key) {
        subscriptionParams[key] = values[key];
      });
    if (localProductSubscriptions)
      subscriptionParams.productSubscriptions = localProductSubscriptions;
    return dispatch(subscriptionUpdate(subscriptionParams));
  }

  useEffect(() => {
    dispatch(subscriptionFetch({ subscriptionId: id }));
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(currentSubscription))
      setLocalProductSubscriptions(currentSubscription.product_subscriptions);
  }, [currentSubscription]);

  const formik = useFormik({
    initialValues: {
      stripeCustomerId: currentSubscription?.stripe_customer_id || '',
      stripeSubscriptionId: currentSubscription?.stripe_subscription_id || '',
      expiresAt: currentSubscription?.expires_at || '',
      comped: currentSubscription?.comped || false,
      paymentFailedAt: currentSubscription?.payment_failed_at || '',
      becameDelinquentAt: currentSubscription?.became_delinquent_at || ''
    },
    enableReinitialize: true,
    validationSchema: subscriptionSchema,
    onSubmit: values => {
      handleSubmit(values);
    }
  });

  function handleExpiresAtChange(updatedValue) {
    formik.setFieldValue('expiresAt', updatedValue);
  }

  function handlePaymentFailedAtChange(updatedValue) {
    formik.setFieldValue('paymentFailedAt', updatedValue);
  }

  function handleBecameDelinquentAtChange(updatedValue) {
    formik.setFieldValue('becameDelinquentAt', updatedValue);
  }

  const handleNewProductSubscription = _isCreating => productSubscriptionId => {
    const productSubscription = localProductSubscriptions.filter(
      s => s.id === productSubscriptionId
    )[0];
    setCurrentProductSubscription(productSubscription);

    setIsEditingProductSubscription(true);
    setIsCreatingProductSubscription(_isCreating);
  };

  function handleDeleteProductSubscription() {
    const updatedLocalProductSubscriptions = localProductSubscriptions
      .filter(
        productSubscription =>
          (currentProductSubscription._id &&
            currentProductSubscription._id !== productSubscription._id) ||
          productSubscription.id
      )
      .map(productSubscription => {
        if (
          currentProductSubscription.id &&
          currentProductSubscription.id === productSubscription.id
        )
          return {
            id: currentProductSubscription.id,
            _destroy: true
          };
        return productSubscription;
      });
    setLocalProductSubscriptions(updatedLocalProductSubscriptions);
  }

  async function handleDeleteClick(productSubscriptionId) {
    const productSubscription = localProductSubscriptions.filter(
      s => s.id === productSubscriptionId || s._id === productSubscriptionId
    )[0];
    await setCurrentProductSubscription(productSubscription);
    setShowModal(true);
  }

  useEffect(() => {
    if (subscriptionUpdated && isEmpty(error)) {
      setShowSuccess(true);
    }
  }, [error, subscriptionUpdated, dispatch]);

  function handleModalSetShow(show) {
    setShowModal(show);
  }

  function handleModalSecondaryAction(show) {
    handleModalSetShow(show);
  }

  return (
    <div className="tw-min-h-screen">
      <NavBar />
      <PageHeader
        title="Subscription Edit"
        primaryActionText="Update"
        primaryAction={formik.handleSubmit}
        isLoaded={!isLoading}
        breadcrumbs={[
          {
            name: 'Overlord',
            action: () => history.push('/overlord/')
          },
          {
            name: 'Plans Index',
            action: () => history.push('/overlord/plans')
          },
          {
            name: 'Plan Builder',
            action: () => history.push(`/overlord/plans/${id}/edit`)
          }
        ]}
      />

      <form action="#" method="POST">
        <div className="tw-max-w-7xl tw-mx-auto tw-py-20 sm:tw-px-6 lg:tw-px-8">
          <div>
            <div className="md:tw-grid md:tw-grid-cols-4 md:tw-gap-6">
              <div className="md:tw-col-span-1">
                <div className="tw-px-4 sm:tw-px-0">
                  <h3 className="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900">
                    Subscription Details
                  </h3>
                  <p className="tw-mt-1 tw-text-sm tw-leading-5 tw-text-gray-600">
                    Edit details like stripe customer id, stripe subscription
                    id, and expires at.
                  </p>
                </div>
              </div>
              <div className="tw-mt-5 md:tw-mt-0 md:tw-col-span-3">
                <Card containerClass="tw-rounded-md">
                  <div className="tw-rounded-md tw-px-4 tw-py-5 tw-bg-white sm:tw-p-6">
                    {!isEmpty(currentSubscription) ? (
                      <div className="tw-grid tw-grid-cols-12 tw-gap-6">
                        <TextInput
                          id="stripe_customer_id"
                          value={formik.values.stripeCustomerId}
                          labelText="Stripe Customer ID"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          showError={
                            formik.touched.stripeCustomerId &&
                            !!formik.errors.stripeCustomerId
                          }
                          error={formik.errors.stripeCustomerId}
                          widthClass="tw-col-span-12 sm:tw-col-span-6"
                        />

                        <TextInput
                          id="stripe_subscription_id"
                          value={formik.values.stripeSubscriptionId}
                          labelText="Stripe Subscription ID"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          showError={
                            formik.touched.stripeSubscriptionId &&
                            !!formik.errors.stripeSubscriptionId
                          }
                          error={formik.errors.stripeSubscriptionId}
                          widthClass="tw-col-span-12 sm:tw-col-span-6"
                        />

                        <DatePicker
                          id="expires_at"
                          value={formik.values.expiresAt}
                          labelText="Expires At"
                          onChange={handleExpiresAtChange}
                          onBlur={formik.handleBlur}
                          showError={
                            formik.touched.expiresAt &&
                            !!formik.errors.expiresAt
                          }
                          error={formik.errors.expiresAt}
                          widthClass="tw-col-span-12 sm:tw-col-span-6"
                        />

                        <Select
                          id="comped"
                          value={formik.values.comped}
                          options={[
                            { displayName: 'True', value: true },
                            { displayName: 'False', value: false }
                          ]}
                          labelText="Comped"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          showError={
                            formik.touched.comped && !!formik.errors.comped
                          }
                          error={formik.errors.comped}
                          widthClass="tw-col-span-12 sm:tw-col-span-6"
                        />

                        <DatePicker
                          id="payment_failed_at"
                          value={formik.values.paymentFailedAt}
                          labelText="Payment Failed At"
                          onChange={handlePaymentFailedAtChange}
                          onBlur={formik.handleBlur}
                          showError={
                            formik.touched.paymentFailedAt &&
                            !!formik.errors.paymentFailedAt
                          }
                          error={formik.errors.paymentFailedAt}
                          widthClass="tw-col-span-12 sm:tw-col-span-6"
                        />

                        <DatePicker
                          id="became_delinquent_at"
                          value={formik.values.becameDelinquentAt}
                          labelText="Became Delinquent At"
                          onChange={handleBecameDelinquentAtChange}
                          onBlur={formik.handleBlur}
                          showError={
                            formik.touched.becameDelinquentAt &&
                            !!formik.errors.becameDelinquentAt
                          }
                          error={formik.errors.becameDelinquentAt}
                          widthClass="tw-col-span-12 sm:tw-col-span-6"
                        />
                      </div>
                    ) : null}
                  </div>
                </Card>
              </div>
            </div>
          </div>

          <div className="tw-hidden sm:tw-block">
            <div className="tw-py-5">
              <div className="tw-border-t tw-border-b-0 tw-border-l-0 tw-border-r-0 tw-border-solid tw-border-gray-200" />
            </div>
          </div>

          <div className="tw-mt-10 sm:tw-mt-0">
            <div className="md:tw-grid md:tw-grid-cols-4 md:tw-gap-6">
              <div className="md:tw-col-span-1">
                <div className="tw-px-4 sm:tw-px-0">
                  <h3 className="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900">
                    Product Subscriptions
                  </h3>
                  <p className="tw-mt-1 tw-text-sm tw-leading-5 tw-text-gray-600">
                    This lists out all the products assigned to a subscription.
                  </p>
                </div>
              </div>
              <div className="tw-mt-5 md:tw-mt-0 md:tw-col-span-3">
                {isEditingProductSubscription ? (
                  <ProductSubscription
                    subscriptionId={
                      (currentSubscription && currentSubscription.id) || 0
                    }
                    productSubscriptionId={
                      (currentProductSubscription &&
                        currentProductSubscription.id) ||
                      0
                    }
                    isCreating={isCreatingProductSubscription}
                    setIsEditing={isEditing =>
                      setIsEditingProductSubscription(isEditing)
                    }
                    productSubscriptions={localProductSubscriptions}
                    setProductSubscriptions={updatedProductSubscriptions =>
                      setLocalProductSubscriptions(updatedProductSubscriptions)
                    }
                  />
                ) : (
                  <Table
                    rows={localProductSubscriptions}
                    rowConditionals={value => ({
                      created_at:
                        !isEmpty(value) &&
                        formatTimestamp(
                          value,
                          Intl.DateTimeFormat().resolvedOptions().timeZone,
                          false,
                          'MMMM Do YYYY',
                          true
                        )
                    })}
                    includedColumns={['name', 'created_at']}
                    deletable
                    onDelete={handleDeleteClick}
                    emptyMessage="No products tied to this subscription"
                    hasFooter
                    footerAction={handleNewProductSubscription(true)}
                    footerActionText="Add New"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </form>

      <Modal
        headerText="Delete Product Subscription"
        bodyText={`Are you sure you want to delete ${
          currentProductSubscription && currentProductSubscription.name
            ? ` the ${currentProductSubscription.name}`
            : ' this'
        } product subscription?`}
        primaryAction={handleDeleteProductSubscription}
        primaryActionText="Delete"
        secondaryAction={handleModalSecondaryAction}
        show={showModal}
        setShow={handleModalSetShow}
        color="error"
        HeaderIcon={color => {
          return (
            <svg
              className={`tw-h-6 tw-w-6 tw-text-${color}-600`}
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
              />
            </svg>
          );
        }}
      />

      <Notification
        message="Successfully updated subscription."
        show={showSuccess}
        setShowAction={handleSetShowSuccess}
        type="colored"
        headerText="Success!"
        color="success"
        HeaderIcon={color => {
          return (
            <svg
              className={`tw-h-5 tw-w-5 tw-text-${color}-400`}
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                clipRule="evenodd"
              />
            </svg>
          );
        }}
      />
    </div>
  );
};

export default SubscriptionEdit;
