import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { broadcastCallSaveEmailAction } from 'appState/actions/ActionCreators';
import getPathName from 'lib/utils/router';
import Styled from './Footer.styles';

const Footer = props => {
  const { saveAction } = props;
  const history = useHistory();
  const { path } = useRouteMatch();

  const pathName = getPathName(path);

  function handleCancelButton() {
    history.push('/ui/broadcast-center');
  }
  const dispatch = useDispatch();

  function onClickSave() {
    const isEmail = pathName === 'email';
    if (isEmail) dispatch(broadcastCallSaveEmailAction(saveAction()));
    if (saveAction && !isEmail) saveAction();
  }

  return (
    <Styled.Footer>
      <div className="row">
        <div className="col-12">
          <div className="card mt-0 mb-0">
            <div className="card-body shadow-1 ">
              <div className="d-flex justify-content-end">
                {/* <% if @step == "edit_content" %> */}
                <div id="save-indicator" className="ml-20 flex-grow-1">
                  <div
                    id="auto-save-indicator-saving"
                    style={{ display: 'none' }}
                  >
                    {/* <%= image_tag('broadcast/saving.png', size:"15x24")%> */}
                    <span className="ml-2 fs-16 align-sub ">
                      Saving content
                    </span>
                  </div>
                  <div
                    id="auto-save-indicator-saved"
                    style={{ display: 'none' }}
                  >
                    {/* <%= image_tag('broadcast/saved.png', size:"15x20", class:"mt-1")%> */}
                    <span className="ml-2 fs-16 align-sub ">Email Saved</span>
                  </div>
                </div>
                {/* <% end %> */}

                <a
                  className="mr-10 btn btn-danger"
                  id="close-broadcast-content-btn"
                  href="#"
                  style={{ color: 'white' }}
                  data-provide="cancel tooltip"
                  title="Back to Broadcast Center"
                  onClick={handleCancelButton}
                >
                  Cancel
                </a>

                {pathName !== 'select-template' ? (
                  <a
                    className="mr-10 btn btn-info"
                    id="broadcast-save-continue-button"
                    href="#"
                    onClick={onClickSave}
                  >
                    Save &amp; Continue
                  </a>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Styled.Footer>
  );
};

Footer.defaultProps = {
  saveAction: null
};

Footer.propTypes = {
  saveAction: PropTypes.func
};

export default Footer;
