// linting rules disabled for immer js
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  FETCH_PRODUCT_SUCCESS,
  FETCH_INCLUDED_FORM_SUCCESS,
  CREATE_INCLUDED_FORM_SUCCESS,
  UPDATE_INCLUDED_FORM_SUCCESS,
  FETCH_STAFF_SMS_MERGE_FIELDS_SUCCESS,
  FETCH_STAFF_EMAIL_MERGE_FIELDS_SUCCESS,
  FETCH_PRODUCTS_CATEGORIES_SUCCESS,
  CREATE_PRODUCT_SUCCESS
} from 'appState/actions/constants/product.actions';

const initialState = {
  currentProduct: {
    product_campaigns: [],
    product_categories: [],
    currentProductId: null
  },
  currentIncludedForm: {},
  staffSmsMergeFields: [],
  staffEmailMergeFields: []
};

export default function ProductReducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case FETCH_PRODUCT_SUCCESS: {
        const { currentProduct } = action.payload;
        draft.currentProduct = currentProduct;
        return;
      }
      case UPDATE_INCLUDED_FORM_SUCCESS:
      case CREATE_INCLUDED_FORM_SUCCESS:
      case FETCH_INCLUDED_FORM_SUCCESS: {
        const { currentIncludedForm } = action.payload;
        draft.currentIncludedForm = currentIncludedForm;
        return;
      }
      case FETCH_STAFF_SMS_MERGE_FIELDS_SUCCESS: {
        const { staffSmsMergeFields } = action.payload;
        draft.staffSmsMergeFields = staffSmsMergeFields;
        return;
      }
      case FETCH_STAFF_EMAIL_MERGE_FIELDS_SUCCESS: {
        const { staffEmailMergeFields } = action.payload;
        draft.staffEmailMergeFields = staffEmailMergeFields;
        return;
      }
      case FETCH_PRODUCTS_CATEGORIES_SUCCESS: {
        const { productsCategories } = action.payload;
        draft.currentProduct.product_categories = productsCategories;
        return;
      }
      case CREATE_PRODUCT_SUCCESS: {
        const { currentProduct } = action.payload;
        draft.currentProduct.currentProductId = currentProduct.id;
        return;
      }
      default:
        return draft;
    }
  });
}
