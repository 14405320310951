import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import isEmpty from 'lodash.isempty';
import { smsInboxFetchConversation } from 'appState/actions/ActionCreators';
import {
  createErrorMessageSelector,
  createLoadedSelector
} from 'appState/selectors';
import SmsMessage from 'components/Theme/SmsMessage';
import useWindowDimensions from 'components/shared/hooks/useWindowDimensions';

const MainWindow = props => {
  const { contactId } = props;
  const dispatch = useDispatch();

  const { windowWidth } = useWindowDimensions();

  const errorSelector = createErrorMessageSelector([
    'smsInbox/SMS_INBOX_FETCH_CONVERSATION',
    'smsInbox/SMS_INBOX_CONVERSATION_PAGE_CHANGE'
  ]);
  const loadedSelector = createLoadedSelector([
    'smsInbox/SMS_INBOX_FETCH_CONVERSATION',
    'smsInbox/SMS_INBOX_CONVERSATION_PAGE_CHANGE'
  ]);

  const structuredSelector = createStructuredSelector({
    currentConversation: state => state.smsInbox.currentConversation,
    error: state => errorSelector(state),
    isLoaded: state => loadedSelector(state)
  });

  const { currentConversation, error, isLoaded } = useSelector(
    structuredSelector
  );

  useEffect(() => {
    if (contactId) dispatch(smsInboxFetchConversation({ contactId }));
  }, [contactId, dispatch]);

  return (
    <Fragment>
      {!isLoaded && isEmpty(error) && isEmpty(currentConversation) ? (
        <div className="center-vh">
          <div className="spinner-dots">
            <span className="dot1" />
            <span className="dot2" />
            <span className="dot3" />
          </div>
        </div>
      ) : (
        <div className="tw-pt-10">
          {isEmpty(currentConversation) ? (
            <p className="tw-text-center">
              No SMS messages have been sent to, or received from this contact.
            </p>
          ) : (
            currentConversation &&
            currentConversation.length > 0 &&
            currentConversation.map(sms => (
              <SmsMessage sms={sms} key={sms.id} windowWidth={windowWidth} />
            ))
          )}
        </div>
      )}
    </Fragment>
  );
};

MainWindow.propTypes = {
  contactId: PropTypes.number.isRequired
};

export default MainWindow;
