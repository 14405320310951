import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useFormik } from 'formik';
import isEmpty from 'lodash.isempty';
import { smsCreditPacksFetch } from 'appState/actions/ActionCreators';
import Button from 'components/Theme/Button';
import Select from 'components/Theme/Select';
import TextInput from 'components/Theme/TextInput';
import Toggle from 'components/Theme/Toggle';
import { smsEnabledCountrySchema } from 'lib/validation/schema';

const SmsEnabledCountry = ({
  planId,
  smsEnabledCountryId,
  isCreating,
  setIsEditing,
  smsEnabledCountries,
  setSmsEnabledCountries
}) => {
  const [freeCreditPackOptions, setFreeCreditPackOptions] = useState([]);
  const [
    additionalCreditPackOptions,
    setAdditionalCreditPackOptions
  ] = useState([]);
  const [currentSmsEnabledCountry, setCurrentSmsEnabledCountry] = useState({});
  const dispatch = useDispatch();

  const structuredSelector = createStructuredSelector({
    smsCreditPacks: state => state.plan.smsCreditPacks
  });

  const { smsCreditPacks } = useSelector(structuredSelector);

  useEffect(() => {
    if (isEmpty(smsCreditPacks)) dispatch(smsCreditPacksFetch());

    if (smsEnabledCountryId) {
      setCurrentSmsEnabledCountry(
        smsEnabledCountries.filter(country => {
          return smsEnabledCountryId === country.id;
        })[0]
      );
    }
  }, [smsEnabledCountryId, smsEnabledCountries, smsCreditPacks, dispatch]);

  useEffect(() => {
    if (!isEmpty(smsCreditPacks)) {
      setFreeCreditPackOptions(
        smsCreditPacks.map(scp => ({
          id: scp.id,
          displayName: scp.credits,
          value: scp.id
        }))
      );
      setAdditionalCreditPackOptions(
        smsCreditPacks.map(scp => ({
          id: scp.id,
          displayName: `${scp.credits}/$${scp.price}`,
          value: scp.id
        }))
      );
    }
  }, [smsCreditPacks]);

  function handleSubmit(values) {
    const newSmsEnabledCountry = {};
    if (planId) newSmsEnabledCountry.plan_id = planId;
    if (smsEnabledCountryId) newSmsEnabledCountry.id = smsEnabledCountryId;
    if (!isEmpty(values)) {
      // eslint-disable-next-line no-underscore-dangle
      newSmsEnabledCountry._id =
        smsEnabledCountries.length > 0
          ? smsEnabledCountries[smsEnabledCountries.length - 1] + 1
          : 1;
      if (values.name) newSmsEnabledCountry.name = values.name;
      if (values.phoneNumberSurcharge)
        newSmsEnabledCountry.phone_number_surcharge =
          values.phoneNumberSurcharge;
      if (values.freeCreditPack) {
        newSmsEnabledCountry.free_credit_pack_id = values.freeCreditPack;
        // eslint-disable-next-line prefer-destructuring
        newSmsEnabledCountry.free_credit_pack = smsCreditPacks.filter(
          scp => scp.id.toString() === values.freeCreditPack.toString()
        )[0];
      }
      newSmsEnabledCountry.gdpr = values.gdpr;
      if (values.additionalCreditPack) {
        newSmsEnabledCountry.additional_credit_pack_id =
          values.additionalCreditPack;
        // eslint-disable-next-line prefer-destructuring
        newSmsEnabledCountry.additional_credit_pack = smsCreditPacks.filter(
          scp => scp.id.toString() === values.additionalCreditPack.toString()
        )[0];
      }
    }

    if (isCreating) {
      setSmsEnabledCountries([...smsEnabledCountries, newSmsEnabledCountry]);
    } else {
      setSmsEnabledCountries(
        smsEnabledCountries.map(country => {
          if (smsEnabledCountryId === country.id) return newSmsEnabledCountry;
          return country;
        })
      );
    }

    setIsEditing(false);
  }

  const formik = useFormik({
    initialValues: {
      name: currentSmsEnabledCountry.name || '',
      freeCreditPack: currentSmsEnabledCountry.free_credit_pack_id || '',
      additionalCreditPack:
        currentSmsEnabledCountry.additional_credit_pack_id || '',
      phoneNumberSurcharge:
        currentSmsEnabledCountry.phone_number_surcharge || '',
      gdpr: currentSmsEnabledCountry.gdpr
    },
    enableReinitialize: true,
    validationSchema: smsEnabledCountrySchema,
    onSubmit: values => {
      handleSubmit(values);
    }
  });

  function onGdprChange(_ref, gdpr) {
    formik.setFieldValue('gdpr', gdpr);
  }

  return (
    <div className="tw-mx-auto tw-max-w-7xl">
      <div className="tw-shadow sm:tw-rounded-md sm:tw-overflow-hidden">
        <div className="tw-px-4 tw-py-5 tw-bg-white sm:tw-p-6">
          <div className="tw-grid tw-grid-cols-10 tw-gap-6">
            <TextInput
              id="name"
              value={formik.values.name}
              labelText="Name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              showError={formik.touched.name && !!formik.errors.name}
              error={formik.errors.name}
              widthClass="tw-col-span-10 sm:tw-col-span-5"
            />

            <TextInput
              id="phone_number_surcharge"
              value={formik.values.phoneNumberSurcharge}
              labelText="Phone Number Surcharge"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              showError={
                formik.touched.phoneNumberSurcharge &&
                !!formik.errors.phoneNumberSurcharge
              }
              error={formik.errors.phoneNumberSurcharge}
              widthClass="tw-col-span-10 sm:tw-col-span-5"
            />

            <Select
              id="free_credit_pack"
              value={formik.values.freeCreditPack}
              options={freeCreditPackOptions}
              labelText="Free Credit Pack"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              showError={
                formik.touched.freeCreditPack && !!formik.errors.freeCreditPack
              }
              error={formik.errors.freeCreditPack}
              widthClass="tw-col-span-10 sm:tw-col-span-5"
            />

            <Select
              id="additional_credit_pack"
              value={formik.values.additionalCreditPack}
              options={additionalCreditPackOptions}
              labelText="Additional Credit Pack"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              showError={
                formik.touched.additionalCreditPack &&
                !!formik.errors.additionalCreditPack
              }
              error={formik.errors.additionalCreditPack}
              widthClass="tw-col-span-10 sm:tw-col-span-5"
            />

            <Toggle
              color="alpha"
              size="small"
              onClick={onGdprChange}
              withLabel
              label="GDPR"
              isToggled={formik.values.gdpr}
            />
          </div>
        </div>
        <div className="tw-px-4 tw-py-3 tw-bg-gray-50 tw-text-right sm:tw-px-6">
          <span className="tw-mr-3 tw-inline-flex tw-rounded-md tw-shadow-sm">
            <Button
              text="Cancel"
              type="white"
              onClick={() => setIsEditing(false)}
            />
          </span>
          <span className="tw-inline-flex tw-rounded-md tw-shadow-sm">
            <Button
              text={isCreating ? 'Add' : 'Change'}
              onClick={formik.handleSubmit}
            />
          </span>
        </div>
      </div>
    </div>
  );
};

SmsEnabledCountry.defaultProps = {
  planId: 0,
  smsEnabledCountryId: 0,
  smsEnabledCountries: [],
  setSmsEnabledCountries: () => null
};

const smsEnabledCountryShape = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string,
  plan_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  free_credit_pack_id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  additional_credit_pack_id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  phone_number_surcharge: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ])
});

SmsEnabledCountry.propTypes = {
  planId: PropTypes.number,
  smsEnabledCountryId: PropTypes.number,
  isCreating: PropTypes.bool.isRequired,
  setIsEditing: PropTypes.func.isRequired,
  smsEnabledCountries: PropTypes.arrayOf(smsEnabledCountryShape),
  setSmsEnabledCountries: PropTypes.func
};

export default SmsEnabledCountry;
