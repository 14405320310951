import React, { Fragment } from 'react';
import { withA11y } from '@storybook/addon-a11y';
import { withKnobs, boolean, select, text } from '@storybook/addon-knobs';
import Pagination from 'components/Theme/Pagination';
import ActivityFeed from './index';

export default {
  component: ActivityFeed,
  title: 'ActivityFeed',
  decorators: [withA11y, withKnobs]
};

const sampleItems = [
  {
    id: 1,
    index: 0,
    headerText: 'I am an activity feed item',
    bodyText:
      'Just confirming that I am still, in fact, an activity feed item.',
    timestamp: '2019-06-13 14:45:37.216013',
    isPinned: false
  },
  {
    id: 2,
    index: 1,
    headerText: 'I am also an activity feed item',
    email_subject: 'I am also an activity feed item',
    bodyText:
      'Just confirming that I am still, in fact, an activity feed item.',
    timestamp: '2019-06-13 14:45:37.216013',
    isPinned: false
  },
  {
    id: 3,
    index: 2,
    headerText: "Hey, don't forget about me",
    bodyText:
      'Just confirming that I am still, in fact, an activity feed item.',
    timestamp: '2019-06-13 14:45:37.216013',
    isPinned: false
  }
];

function getSampleIcon(color) {
  return (
    <svg
      className={`tw-h-5 tw-w-5 tw-align-middle tw-mx-auto tw-text-${color}-600`}
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
    </svg>
  );
}

const sampleItemsWithIcons = sampleItems.map(item => {
  return { ...item, icon: color => getSampleIcon(color) };
});

const sampleItemsWithActions = sampleItems.map(item => {
  return {
    ...item,
    action: () => null,
    actionText: 'Click Here'
  };
});

const samplePinnedItems = sampleItems.map(item => {
  return { ...item, icon: color => getSampleIcon(color), isPinned: true };
});

const sampleItemsWithInfoAlertStaffNoteAndBadge = sampleItems.map(
  (item, index) => {
    return index === 1
      ? {
          ...item,
          icon: color => getSampleIcon(color),
          isPinned: true,
          action: () => null,
          actionText: 'Click Here',
          status: 'Sent',
          infoAlert: {
            message: 'This action was manually triggered by Chang Brakus',
            color: 'alpha',
            type: 'colored',
            // eslint-disable-next-line react/display-name
            HeaderIcon: color => {
              return (
                <svg
                  className={`tw-h-5 tw-w-5 tw-text-${color}-400`}
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                    clipRule="evenodd"
                  />
                </svg>
              );
            }
          },
          staffNote: {
            headerText: 'Staff Note',
            message:
              'Please give Chang Brakus a call sometime manana por favor.',
            color: 'gray',
            type: 'colored',
            // eslint-disable-next-line react/display-name
            HeaderIcon: color => {
              return (
                <svg
                  className={`identification tw-w-6 tw-h-6 tw-text-${color}-400`}
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 2a1 1 0 00-1 1v1a1 1 0 002 0V3a1 1 0 00-1-1zM4 4h3a3 3 0 006 0h3a2 2 0 012 2v9a2 2 0 01-2 2H4a2 2 0 01-2-2V6a2 2 0 012-2zm2.5 7a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm2.45 4a2.5 2.5 0 10-4.9 0h4.9zM12 9a1 1 0 100 2h3a1 1 0 100-2h-3zm-1 4a1 1 0 011-1h2a1 1 0 110 2h-2a1 1 0 01-1-1z"
                    clipRule="evenodd"
                  />
                </svg>
              );
            }
          },
          statusObject: {
            status: 'Message Sent',
            title: 'We have touchdown!',
            friendly_message:
              'This message has been sent, but the contact’s SMS provider did not confirm delivery.',
            bounce_code: '40',
            bounce_class: 'Generic Bounce',
            bounce_description: 'The message failed for unspecified reasons',
            reason:
              '554 5.7.1 [internal] recipient address was suppressed due to customer policy'
          }
        }
      : { ...item, icon: color => getSampleIcon(color), isPinned: true };
  }
);

let colorSelect;
let actionColorSelect;

function includeColorSelect(defaultValue) {
  const label = 'Item Color';
  const options = [
    'alpha',
    'bravo',
    'charlie',
    'success',
    'warning',
    'error',
    'gray'
  ];
  colorSelect = select(label, options, defaultValue);
}

function includeActionColorSelect(defaultValue) {
  const label = 'Action Color';
  const options = [
    'alpha',
    'bravo',
    'charlie',
    'success',
    'warning',
    'error',
    'gray'
  ];
  actionColorSelect = select(label, options, defaultValue);
}

export const standard = () => {
  includeColorSelect('gray');
  return (
    <Fragment>
      <ActivityFeed
        items={sampleItems}
        itemColor={colorSelect}
        showIcons={boolean('Show Icons?', false)}
        containerStyle={{ paddingTop: '40px', paddingBottom: '40px' }}
        widthClass="tw-max-w-2xl"
        hasFooter={boolean('Has Footer?', false)}
      />
    </Fragment>
  );
};

export const withIcons = () => {
  includeColorSelect('gray');
  return (
    <Fragment>
      <ActivityFeed
        items={sampleItemsWithIcons}
        itemColor={colorSelect}
        showIcons={boolean('Show Icons?', true)}
        containerStyle={{ paddingTop: '40px', paddingBottom: '40px' }}
        widthClass="tw-max-w-2xl"
        hasFooter={boolean('Has Footer?', false)}
      />
    </Fragment>
  );
};

export const withActions = () => {
  includeColorSelect('gray');
  includeActionColorSelect('alpha');
  return (
    <Fragment>
      <ActivityFeed
        items={sampleItemsWithActions}
        itemColor={colorSelect}
        actionColor={actionColorSelect}
        showIcons={boolean('Show Icons?', false)}
        containerStyle={{ paddingTop: '40px', paddingBottom: '40px' }}
        widthClass="tw-max-w-2xl"
        hasFooter={boolean('Has Footer?', false)}
      />
    </Fragment>
  );
};

export const withPinnedItems = () => {
  includeColorSelect('gray');
  return (
    <Fragment>
      <ActivityFeed
        items={samplePinnedItems}
        itemColor={colorSelect}
        showIcons={boolean('Show Icons?', true)}
        containerStyle={{ paddingTop: '40px', paddingBottom: '40px' }}
        widthClass="tw-max-w-2xl"
      />
    </Fragment>
  );
};

export const withPagination = () => {
  includeColorSelect('gray');
  return (
    <Fragment>
      <ActivityFeed
        items={samplePinnedItems}
        itemColor={colorSelect}
        showIcons={boolean('Show Icons?', true)}
        containerStyle={{
          paddingTop: '40px',
          paddingBottom: '36px',
          paddingLeft: '0px',
          paddingRight: '0px'
        }}
        widthClass="tw-max-w-2xl"
        hasFooter={boolean('Has Footer?', true)}
        FooterComponent={() => (
          <Pagination
            total={text('Total Items', 30)}
            totalPages={text('Total Pages', 3)}
            limit={text('Per Page Limit', 10)}
          />
        )}
      />
    </Fragment>
  );
};

export const withInfoAlertAndStaffNoteAndBadge = () => {
  includeColorSelect('gray');
  return (
    <Fragment>
      <ActivityFeed
        items={sampleItemsWithInfoAlertStaffNoteAndBadge}
        itemColor={colorSelect}
        showIcons={boolean('Show Icons?', true)}
        containerStyle={{
          paddingTop: '40px',
          paddingBottom: '36px',
          paddingLeft: '0px',
          paddingRight: '0px'
        }}
        widthClass="tw-max-w-2xl"
        hasFooter={boolean('Has Footer?', false)}
      />
    </Fragment>
  );
};
