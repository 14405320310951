import React, { useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 5,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  marginTop: '70px'
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

function NewDropzone(props) {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    acceptedFiles,
    fileRejections,
    open
  } = useDropzone({
    onDrop: files => {
      props.handleSetShowSuccess(true, files);
    }
  });
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragActive, isDragReject, isDragAccept]
  );
  return (
    <div>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <div
          style={{ height: 200 }}
          className="tw-flex tw-justify-center tw-px-6 tw-pt-2 tw-pb-6 tw-border-2 tw-border-gray-300 tw-border-dashed tw-rounded-md"
        >
          <div className="tw-text-center">
            <svg
              style={{ height: 100 }}
              className="tw-mx-auto tw-h-12 tw-w-12 tw-text-gray-400"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 48 48"
            >
              <path
                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <p>
              <span className="tw-text-alpha-500">Upload a file</span> or drag
              and drop
            </p>
            <p>PNG,JPG,GIF up to 10MB</p>
          </div>
        </div>
      </div>
      {/* <aside> */}
      {/*    <h4>Accepted files</h4> */}
      {/*    <ul>{acceptedFileItems}</ul> */}
      {/* </aside> */}
    </div>
  );
}
export default NewDropzone;
