import React, { Fragment, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import isEmpty from 'lodash.isempty';
import { taskShape, appointmentShape } from 'lib/utils/prop-types-extensions';
import { capitalize } from 'lib/utils/string';
import Button from 'components/Theme/Button';
import HeroIcon from 'components/Theme/HeroIcon';
import useOnClickOutside from 'components/shared/hooks/useOnClickOutside';

const CardBody = ({
  resource,
  resourceLoaded,
  resourceType,
  resourceDueDate,
  resourceTitle,
  resourceActions,
  handleResourceActions,
  contactId
}) => {
  const history = useHistory();
  const [coords, setCoords] = useState({});
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef();
  const dropdownButtonRef = useRef();

  function toggleDropdown() {
    const dropdownSpanRect = dropdownButtonRef.current?.getBoundingClientRect();

    setCoords({
      left: dropdownSpanRect.x - 200,
      top: dropdownSpanRect.y + dropdownSpanRect.height + window.scrollY
    });
    setShowDropdown(!showDropdown);
  }

  useOnClickOutside(dropdownRef, () => {
    setShowDropdown(false);
  });

  return (
    <div className="tw-px-4 tw-py-5">
      {isEmpty(resource) ? (
        <Fragment>
          <div className="tw-h-6" />
          <div className="tw-text-base tw-font-medium tw-text-gray-400">
            {resourceLoaded ? (
              `No upcoming ${resourceType}s`
            ) : (
              <Skeleton height={24} width={180} />
            )}
          </div>
          <div className="tw-h-6" />
          <div className="tw-mt-1 tw-flex tw-flex-row-reverse">
            {resourceLoaded ? (
              <Button
                type="white"
                onClick={() =>
                  history.push(`/ui/contacts/${contactId}#${resourceType}s`)
                }
                size="md"
                text={`Create ${capitalize(resourceType)}`}
              />
            ) : (
              <Skeleton height={28} width={100} />
            )}
          </div>
        </Fragment>
      ) : (
        <div className="tw-flex tw-items-center tw-justify-between">
          <div className="tw-flex-1 tw-items-center">
            <div>{resourceDueDate}</div>
            <div className="tw-text-base tw-font-medium">{resourceTitle}</div>
            <div className="tw-pt-1">
              <span className="tw-font-medium">Assigned To:</span>{' '}
              {resource.user}
            </div>
          </div>
          <span ref={dropdownButtonRef}>
            <HeroIcon
              icon="dots-vertical"
              color="alpha"
              onClick={toggleDropdown}
            />
          </span>
          <div
            ref={dropdownRef}
            className={`tw-origin-top-right tw-absolute tw-mt-2 tw--mr-1 tw-w-56 tw-rounded-md tw-shadow-lg tw-z-10 ${
              showDropdown ? 'tw-block' : 'tw-hidden'
            }`}
            style={{ top: coords.top, left: coords.left }}
          >
            <div className="tw-rounded-md tw-bg-white tw-shadow-xs">
              <div className="tw-py-1">
                {resourceActions.map(option => (
                  <a
                    href="#"
                    key={option.value}
                    data-testid={option.value}
                    onClick={() => {
                      handleResourceActions(option.value);
                    }}
                    className={`tw-block tw-px-4 tw-py-2 tw-text-sm tw-leading-5 tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900 focus:tw-outline-none focus:tw-bg-gray-100 focus:tw-text-gray-900 ${option.className}`}
                  >
                    {option.displayName}
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const actionShape = {
  displayName: PropTypes.string,
  value: PropTypes.string,
  action: PropTypes.oneOfType([PropTypes.node, PropTypes.func])
};

CardBody.defaultProps = {
  resource: null,
  resourceDueDate: '',
  resourceTitle: '',
  handleResourceActions: () => null
};

CardBody.propTypes = {
  resource: PropTypes.oneOfType([
    PropTypes.shape(taskShape),
    PropTypes.shape(appointmentShape)
  ]),
  resourceLoaded: PropTypes.bool.isRequired,
  resourceType: PropTypes.oneOf(['appointment', 'task']).isRequired,
  resourceDueDate: PropTypes.string,
  resourceTitle: PropTypes.string,
  resourceActions: PropTypes.arrayOf(PropTypes.shape(actionShape)).isRequired,
  handleResourceActions: PropTypes.func,
  contactId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired
};

export default CardBody;
