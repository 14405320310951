import React, { useContext, useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { InPortal } from 'react-reverse-portal';
import Button from 'components/Theme/Button';
import useOnClickOutside from 'components/shared/hooks/useOnClickOutside';
import { PortalContext } from 'components/shared/context/PortalContext';

const BUTTON_MARGIN = 12;
const ButtonWithDropdown = ({
  value,
  options,
  buttonText,
  color,
  buttonStyle,
  containerStyle,
  dropdownButtonStyle,
  type,
  showSvg,
  onClick,
  containerClass,
  dropdownPosition,
  leftBtnDropdownToggle
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [coords, setCoords] = useState({});
  const buttonRef = useRef();
  const dropdownButtonRef = useRef();
  const dropdownRef = useRef();
  const portal = useContext(PortalContext);

  function toggleDropdown() {
    setShowDropdown(!showDropdown);
  }

  useLayoutEffect(() => {
    if (!showDropdown) return;

    const dropdownButtonRect = dropdownButtonRef.current?.getBoundingClientRect();
    const dropdownRect = dropdownRef.current?.getBoundingClientRect();
    const buttonRect = buttonRef.current?.getBoundingClientRect();

    const { y, height, x } = dropdownButtonRect;
    const remainingSpace = window.innerHeight - (y + height + BUTTON_MARGIN);

    let top = y + height + window.scrollY;
    if (dropdownRect.height > remainingSpace) {
      top = y + window.scrollY - dropdownRect.height - BUTTON_MARGIN;
    }
    setCoords({
      left: x - buttonRect.width,
      top
    });
  }, [showDropdown]);

  function handleOnClick(val) {
    if (onClick) {
      onClick(val);
    }
    toggleDropdown();
  }

  function handleOnClickLeftBtn() {
    onClick(value);
    if (leftBtnDropdownToggle) toggleDropdown();
  }

  useOnClickOutside(dropdownRef, () => {
    setShowDropdown(false);
  });

  return (
    <div
      style={containerStyle}
      className={`tw-relative tw-inline-flex ${containerClass}`}
    >
      <Button
        testId="left-button"
        text={buttonText}
        type={type}
        color={color}
        buttonStyle={buttonStyle}
        layout="left-button"
        isButtonWithDropdown
        onClick={handleOnClickLeftBtn}
        buttonRef={buttonRef}
      />
      <div className="tw-inline-flex tw--ml-px tw-block">
        <Button
          testId="right-button"
          text=""
          type={type}
          color={color}
          buttonStyle={dropdownButtonStyle}
          layout="right-button"
          onClick={toggleDropdown}
          showSvg={showSvg}
          isButtonWithDropdown
          buttonRef={dropdownButtonRef}
        />
        {portal?.buttonDropdownNode && (
          <InPortal node={portal.buttonDropdownNode}>
            <div
              ref={dropdownRef}
              className={`tw-origin-top-${
                dropdownPosition === 'left' ? 'right' : 'left'
              } tw-absolute tw-z-10 tw-mt-2 tw--mr-1 tw-w-56 tw-rounded-md tw-shadow-lg ${
                showDropdown ? 'tw-block' : 'tw-hidden'
              }`}
              style={{ top: coords.top, left: coords.left }}
            >
              <div className="tw-rounded-md tw-bg-white tw-shadow-xs">
                <div className="tw-py-1">
                  {options.map(option =>
                    option.disabled === true ? (
                      <span className="tw-block tw-px-4 tw-py-2 tw-text-sm tw-leading-5 tw-text-gray-300">
                        {option.displayName}
                      </span>
                    ) : (
                      <a
                        href="#"
                        data-testid={option.value}
                        key={option.value}
                        onClick={() => handleOnClick(option.value)}
                        className="tw-block tw-px-4 tw-py-2 tw-text-sm tw-leading-5 tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900 focus:tw-outline-none focus:tw-bg-gray-100 focus:tw-text-gray-900"
                      >
                        {option.displayName}
                      </a>
                    )
                  )}
                </div>
              </div>
            </div>
          </InPortal>
        )}
      </div>
    </div>
  );
};

ButtonWithDropdown.defaultProps = {
  value: '',
  buttonText: 'Select',
  type: 'white',
  color: 'alpha',
  buttonStyle: {},
  containerStyle: {},
  dropdownButtonStyle: {},
  showSvg: false,
  containerClass: '',
  dropdownPosition: 'left',
  leftBtnDropdownToggle: false
};

const styleProps = PropTypes.objectOf(
  PropTypes.oneOfType([PropTypes.string, PropTypes.number])
);

const optionShape = PropTypes.shape({
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ])
});

ButtonWithDropdown.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]),
  options: PropTypes.arrayOf(optionShape).isRequired,
  buttonText: PropTypes.string,
  type: PropTypes.string,
  color: PropTypes.oneOf([
    'alpha',
    'bravo',
    'charlie',
    'success',
    'warning',
    'error',
    'gray'
  ]),
  buttonStyle: styleProps,
  containerStyle: styleProps,
  dropdownButtonStyle: styleProps,
  showSvg: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  containerClass: PropTypes.string,
  dropdownPosition: PropTypes.oneOf(['left', 'right']),
  leftBtnDropdownToggle: PropTypes.bool
};

export default ButtonWithDropdown;
