import React, { useEffect, useRef, useState } from 'react';
import { useRouteMatch, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useFormik } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import isEmpty from 'lodash.isempty';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import uplaunchLogo from 'images/uplaunch-orange.svg';
import {
  purchasePageCreateSubscription,
  purchasePageFetchProductPlanInfo
} from 'appState/actions/ActionCreators';
// import ActionPanel from 'components/Theme/ActionPanel';
import Badge from 'components/Theme/Badge';
import Button from 'components/Theme/Button';
import Card from 'components/Theme/Card';
import Notification from 'components/Theme/Notification';
import TextInput from 'components/Theme/TextInput';
import { purchasePageSchema } from 'lib/validation/schema';
import {
  createErrorMessageSelector,
  createLoadedSelector,
  createLoadingSelector
} from 'appState/selectors';

const stripeFormStyles = {
  base: {
    color: '#000',
    lineHeight: '1.25rem',
    fontFamily: 'Rubik, sans-serif',
    fontSmoothing: 'antialiased',
    fontSize: '14px',
    '::placeholder': {
      color: '#a0aec0'
    }
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a'
  }
};

const Purchase = () => {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();
  const stripe = useStripe();
  const elements = useElements();

  const dispatch = useDispatch();
  const submitBtnRef = useRef();

  const { path } = useRouteMatch();

  const [subscriptionIdempotencyKey, setSubscriptionIdempotencyKey] = useState(
    uuidv4()
  );
  const [onboardingIdempotencyKey, setOnboardingIdempotencyKey] = useState(
    uuidv4()
  );
  const [customerIdempotencyKey, setCustomerIdempotencyKey] = useState(
    uuidv4()
  );
  const [coupon, setCoupon] = useState('');
  const [planOverride] = useState(query.get('plan_override'));
  const { planUuid, accountType } = useParams();
  const [onboardingFee] = useState(query.get('obf'));
  const [productId] = useState(query.get('product'));
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const errorSelector = createErrorMessageSelector([
    'purchase/PURCHASE_PAGE_CREATE_SUBSCRIPTION'
  ]);
  const loadedSelector = createLoadedSelector([
    'purchase/PURCHASE_PAGE_CREATE_SUBSCRIPTION'
  ]);
  const loadingSelector = createLoadingSelector([
    'purchase/PURCHASE_PAGE_CREATE_SUBSCRIPTION'
  ]);
  const structuredSelector = createStructuredSelector({
    subscriptionId: state => state.purchase.subscriptionId,
    purchasePage: state => state.purchase.purchasePage,
    subscriptionError: state => errorSelector(state),
    subscriptionCreated: state => loadedSelector(state),
    subscriptionLoading: state => loadingSelector(state)
  });

  const {
    subscriptionId,
    purchasePage,
    subscriptionError,
    subscriptionCreated,
    subscriptionLoading
  } = useSelector(structuredSelector);

  useEffect(() => {
    if (subscriptionId && subscriptionCreated && isEmpty(subscriptionError)) {
      const fullUrl = window.location.href;
      const urlParts = fullUrl.split('/');
      const currentSubdomain = urlParts[2];
      const subdomainName = currentSubdomain.split('.')[0];
      const purchaseSubdomain = currentSubdomain.replace(
        subdomainName,
        'purchase'
      );

      window.location = `${
        urlParts[0]
      }//${purchaseSubdomain}/subscriptions/${subscriptionId}`;
    }
  }, [subscriptionId, subscriptionError, subscriptionCreated]);

  useEffect(() => {
    if (subscriptionCreated && !isEmpty(subscriptionError)) {
      setShowError(true);
      setSubscriptionIdempotencyKey(uuidv4());
      setCustomerIdempotencyKey(uuidv4());
      setOnboardingIdempotencyKey(uuidv4());
    }
  }, [subscriptionError, subscriptionCreated]);

  useEffect(() => {
    dispatch(
      purchasePageFetchProductPlanInfo({
        planUuid,
        planOverride,
        productId,
        onboardingFee,
        accountType
      })
    );
  }, []);

  async function handleSubmit(e) {
    if (e) e.preventDefault();
    const card = elements.getElement(CardElement);
    const { error, token } = await stripe.createToken(card);
    submitBtnRef.current.setAttribute('disabled', true);
    if (error) {
      const errorElement = document.getElementById('card-errors');
      errorElement.textContent = error.message;
      submitBtnRef.current.removeAttribute('disabled');
    } else {
      // eslint-disable-next-line no-use-before-define
      stripeTokenHandler(token);
    }
  }

  const formik = useFormik({
    initialValues: {
      businessName: '',
      subdomain: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      password: '',
      passwordConfirmation: ''
    },
    validationSchema: purchasePageSchema,
    onSubmit: () => {
      handleSubmit();
    }
  });

  function stripeTokenHandler(token) {
    const {
      businessName,
      subdomain,
      firstName,
      lastName,
      email,
      phone,
      password,
      passwordConfirmation
    } = formik.values;
    dispatch(
      purchasePageCreateSubscription({
        token,
        planId: purchasePage.plan.id,
        accountName: businessName,
        accountType,
        subdomain,
        firstName,
        lastName,
        email,
        phone,
        password,
        passwordConfirmation,
        coupon,
        planOverride,
        onboardingFeeUuid:
          purchasePage.onboarding_fee && purchasePage.onboarding_fee.uuid,
        productId,
        subscriptionIdempotencyKey,
        onboardingIdempotencyKey,
        customerIdempotencyKey
      })
    );
  }

  function handleSubdomainChange(e, acctName = null) {
    let subdomainSuggestion;
    if (acctName) {
      subdomainSuggestion = acctName.replace(/[\W_]+/g, '').toLowerCase();
    } else {
      subdomainSuggestion = e.target.value;
    }
    formik.setFieldValue('subdomain', subdomainSuggestion);
  }

  function handleBusinessNameChange(e) {
    const acctName = e.target.value;
    formik.handleChange(e);
    handleSubdomainChange(e, acctName);
  }

  useEffect(() => {
    if (subscriptionCreated && isEmpty(subscriptionError)) {
      setShowSuccess(true);
    }

    if (subscriptionCreated && !isEmpty(subscriptionError)) {
      setShowError(true);
      submitBtnRef.current.removeAttribute('disabled');
    }
  }, [subscriptionError, subscriptionCreated, dispatch]);

  function handleSetShowSuccess(show) {
    setShowSuccess(show);
  }

  function handleSetShowError(show) {
    setShowError(show);
  }

  function bulletPoint(bulletText) {
    return (
      <li
        className="tw-mt-5 tw-flex tw-items-start lg:tw-col-span-1 lg:tw-mt-0"
        key={bulletText}
      >
        <div className="tw-flex-shrink-0">
          <svg
            className="tw-h-5 tw-w-5 tw-text-success-base"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <p className="tw-font-body tw-ml-3 tw-mb-0 tw-text-sm tw-leading-5 tw-text-gray-700">
          {bulletText}
        </p>
      </li>
    );
  }

  return (
    <div>
      {purchasePage && Object.keys(purchasePage).length ? (
        <div
          id="ul-purchase-page-container"
          className="tw-font-body tw-bg-charlie-base"
        >
          <div className="tw-pt-12 sm:tw-pt-16 lg:tw-pt-20">
            <div className="tw-max-w-screen-xl tw-mx-auto tw-px-4 md:tw-px-6 lg:tw-px-8">
              <div className="tw-text-center">
                <img
                  className="tw-mx-auto tw-mb-6 tw-h-16 tw-w-auto"
                  src={uplaunchLogo}
                  alt="UpLaunch"
                />
                <h2 className="tw-font-body tw-text-xl xs:tw-text-3xl tw-leading-9 tw-font-bold tw-text-white md:tw-text-4xl sm:tw-leading-10 lg:tw-text-5xl lg:tw-leading-none">
                  Fully transparent pricing
                </h2>
                <p className="tw-font-body tw-mt-4 tw-text-base xs:tw-text-xl tw-leading-7 tw-text-gray-300">
                  If you&apos;re not satisfied, contact us and we&apos;ll send
                  you a full refund. It&apos;s that simple.
                </p>
                <div className="tw-font-body tw-mt-3 tw-flex tw-items-center tw-justify-center tw-text-xl xs:tw-text-2xl md:tw-text-3xl tw-leading-none tw-font-bold tw-text-white">
                  <span>${purchasePage.price}</span>
                  <span className="tw-ml-3 tw-text-sm xs:tw-text-base md:tw-text-lg tw-leading-7 tw-font-medium tw-text-gray-300">
                    /month
                  </span>
                  <span className="tw-ml-3">
                    + $
                    {purchasePage.onboarding_fee &&
                    purchasePage.onboarding_fee.amount
                      ? purchasePage.onboarding_fee.amount
                      : null}
                  </span>
                  <span className="tw-ml-3 tw-text-sm xs:tw-text-base md:tw-text-lg tw-leading-7 tw-font-medium tw-text-gray-300">
                    setup fee
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="tw-mt-12 tw-pb-16 md:tw-mt-16 md:tw-pb-20 lg:tw-pb-28">
            <form onSubmit={formik.handleSubmit}>
              <div className="tw-relative">
                <Card containerClass="tw-relative tw-max-w-screen-xl tw-mx-auto tw-px-4 sm:tw-px-6 lg:tw-px-8">
                  <div className="tw-max-w-xl tw-mx-auto tw-rounded-lg tw-shadow-lg tw-overflow-hidden">
                    <div className="tw-bg-white tw-px-6 tw-py-8 lg:tw-p-12">
                      <h3 className="tw-font-body tw-text-2xl tw-leading-8 tw-font-bold tw-text-gray-900 sm:tw-text-3xl sm:tw-leading-9">
                        Purchase UpLaunch
                      </h3>
                      <Badge value={purchasePage.name} hasDot />
                      <p className="tw-font-body tw-mt-6 tw-text-base tw-leading-6 tw-text-gray-500">
                        {purchasePage.description}
                      </p>
                      <div className="tw-mt-8">
                        <div className="tw-flex tw-items-center">
                          <h4 className="tw-font-body tw-flex-shrink-0 tw-pr-4 tw-bg-white tw-text-sm tw-leading-5 tw-tracking-wider tw-font-semibold tw-uppercase tw-text-bravo-base tw-mb-0">
                            What&apos;s Included
                          </h4>
                          <div className="tw-font-body tw-flex-1 tw-border-t-2 tw-border-b-0 tw-border-r-0 tw-border-l-0 tw-border-solid tw-border-gray-200" />
                        </div>
                        <ul className="tw-mt-8 lg:tw-grid lg:tw-grid-cols-2 lg:tw-col-gap-8 lg:tw-row-gap-5 tw-pl-0">
                          {purchasePage.features.map(feature =>
                            bulletPoint(feature)
                          )}
                        </ul>
                      </div>
                      <div className="tw-mt-8">
                        <div className="tw-flex tw-items-center">
                          <h4 className="tw-font-body tw-flex-shrink-0 tw-pr-4 tw-bg-white tw-text-sm tw-leading-5 tw-tracking-wider tw-font-semibold tw-uppercase tw-text-bravo-base tw-mb-0">
                            Business Information
                          </h4>
                          <div className="tw-flex-1 tw-border-t-2 tw-border-b-0 tw-border-r-0 tw-border-l-0 tw-border-solid tw-border-gray-200" />
                        </div>
                        <div className="tw-mt-10 tw-mb-11 md:tw-col-span-2">
                          <div className="tw-rounded-md">
                            <div className="tw-grid tw-grid-cols-6 tw-gap-6">
                              <TextInput
                                id="business_name"
                                value={formik.values.businessName}
                                labelText="Business Name"
                                onChange={e => handleBusinessNameChange(e)}
                                onBlur={formik.handleBlur}
                                showError={
                                  formik.touched.businessName &&
                                  !!formik.errors.businessName
                                }
                                error={formik.errors.businessName}
                              />

                              <TextInput
                                id="subdomain"
                                value={formik.values.subdomain}
                                labelText="Subdomain"
                                onChange={e => handleSubdomainChange(e)}
                                onBlur={formik.handleBlur}
                                showError={
                                  formik.touched.subdomain &&
                                  !!formik.errors.subdomain
                                }
                                error={formik.errors.subdomain}
                                trailingAddonText=".uplaunch.com"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="tw-mt-8">
                        <div className="tw-flex tw-items-center">
                          <h4 className="tw-font-body tw-flex-shrink-0 tw-pr-4 tw-bg-white tw-text-sm tw-leading-5 tw-tracking-wider tw-font-semibold tw-uppercase tw-text-bravo-base tw-mb-0">
                            Admin User
                          </h4>
                          <div className="tw-flex-1 tw-border-t-2 tw-border-b-0 tw-border-r-0 tw-border-l-0 tw-border-solid tw-border-gray-200" />
                        </div>
                        <div className="tw-mt-10 tw-mb-11 md:tw-col-span-2">
                          <div className="tw-rounded-md">
                            <div className="tw-grid tw-grid-cols-6 tw-gap-6">
                              <TextInput
                                id="first_name"
                                value={formik.values.firstName}
                                labelText="First Name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                showError={
                                  formik.touched.firstName &&
                                  !!formik.errors.firstName
                                }
                                error={formik.errors.firstName}
                              />

                              <TextInput
                                id="last_name"
                                value={formik.values.lastName}
                                labelText="Last Name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                showError={
                                  formik.touched.lastName &&
                                  !!formik.errors.lastName
                                }
                                error={formik.errors.lastName}
                              />

                              <TextInput
                                id="email"
                                value={formik.values.email}
                                labelText="Email Address"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                showError={
                                  formik.touched.email && !!formik.errors.email
                                }
                                error={formik.errors.email}
                              />

                              <TextInput
                                id="phone"
                                value={formik.values.phone}
                                labelText="Phone Number"
                                placeholder="+1 (555) 987-6543"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                showError={
                                  formik.touched.phone && !!formik.errors.phone
                                }
                                error={formik.errors.phone}
                              />

                              <TextInput
                                id="password"
                                value={formik.values.password}
                                autocomplete="new-password"
                                type="password"
                                labelText="Password"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                showError={
                                  formik.touched.password &&
                                  !!formik.errors.password
                                }
                                error={formik.errors.password}
                              />

                              <TextInput
                                id="password_confirmation"
                                value={formik.values.passwordConfirmation}
                                autocomplete="new-password"
                                type="password"
                                labelText="Password (confirm)"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                showError={
                                  formik.touched.passwordConfirmation &&
                                  !!formik.errors.passwordConfirmation
                                }
                                error={formik.errors.passwordConfirmation}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="tw-mt-8">
                        <div className="tw-flex tw-items-center">
                          <h4 className="tw-font-body tw-flex-shrink-0 tw-pr-4 tw-bg-white tw-text-sm tw-leading-5 tw-tracking-wider tw-font-semibold tw-uppercase tw-text-bravo-base tw-mb-0">
                            Payment Information
                          </h4>
                          <div className="tw-font-body tw-flex-1 tw-border-t-2 tw-border-b-0 tw-border-r-0 tw-border-l-0 tw-border-solid tw-border-gray-200" />
                        </div>
                        <div className="tw-mt-10 tw-mb-11 md:tw-col-span-2">
                          <div className="tw-rounded-md">
                            <div className="tw-grid tw-grid-cols-6 tw-gap-6">
                              <TextInput
                                id="coupon_code"
                                onChange={e => setCoupon(e.target.value)}
                                value={coupon}
                                labelText="Coupon Code"
                              />

                              <div
                                id="stripe-container"
                                className="tw-col-span-10"
                              >
                                <fieldset>
                                  <legend className="tw-font-body tw-block tw-text-sm tw-font-medium tw-leading-5 tw-text-gray-700">
                                    Card Details
                                  </legend>
                                  <CardElement
                                    options={{ style: stripeFormStyles }}
                                  />
                                  <p
                                    id="card-errors"
                                    className="tw-mt-2 tw-text-sm tw-text-red-600"
                                  />
                                </fieldset>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="tw-col-span-2">
                        <Button
                          text="Create Account"
                          buttonRef={submitBtnRef}
                          color="alpha"
                          disabled={!stripe}
                          isLoaded={!subscriptionLoading}
                          size="xl"
                          isFullWidth
                          onClick={formik.handleSubmit}
                        />
                      </div>
                    </div>
                  </div>
                </Card>
              </div>

              {/* TO-DO: show this once we offer additional products/plans */}
              {/* <div className="tw-relative tw-px-4 sm:tw-px-6 lg:tw-px-8">
                <div className="tw-max-w-xl tw-mt-14 tw-mx-auto">
                  <ActionPanel
                    headerText="Doesn't look like what you wanted?"
                    bodyText="We offer a number of other products, add-ons and bundles
                      that might suit you better. We strive to perfectly tailor
                      UpLaunch to your needs."
                    actionText="Learn more about our other product options"
                    onClick={() => null}
                  />
                </div>
              </div> */}
            </form>
          </div>

          <Notification
            message={subscriptionError}
            show={showError}
            setShowAction={handleSetShowError}
            type="colored"
            headerText="Error!"
            color="error"
            HeaderIcon={color => {
              return (
                <svg
                  className={`tw-h-5 tw-w-5 tw-text-${color}-400`}
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                    clipRule="evenodd"
                  />
                </svg>
              );
            }}
          />

          <Notification
            message={`Successfully purchased the ${purchasePage.name} UpLaunch plan.`}
            show={showSuccess}
            setShowAction={handleSetShowSuccess}
            type="colored"
            headerText="Success!"
            color="success"
            HeaderIcon={color => {
              return (
                <svg
                  className={`tw-h-5 tw-w-5 tw-text-${color}-400`}
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                    clipRule="evenodd"
                  />
                </svg>
              );
            }}
          />
        </div>
      ) : null}
    </div>
  );
};

export default Purchase;
