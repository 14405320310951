import { call, put, takeLatest } from 'redux-saga/effects';
import { axiosDefault } from 'lib/utils/axios-setup';
import {
  FETCH_APPOINTMENT_BOOKER_REQUEST,
  FETCH_APPOINTMENT_BOOKER_SUCCESS,
  FETCH_APPOINTMENT_BOOKER_FAILURE,
  FETCH_APPOINTMENT_REQUEST,
  FETCH_APPOINTMENT_SUCCESS,
  FETCH_APPOINTMENT_FAILURE,
  CREATE_APPOINTMENT_FROM_BOOKER_REQUEST,
  CREATE_APPOINTMENT_FROM_BOOKER_SUCCESS,
  CREATE_APPOINTMENT_FROM_BOOKER_FAILURE,
  UPDATE_APPOINTMENT_REQUEST,
  UPDATE_APPOINTMENT_SUCCESS,
  UPDATE_APPOINTMENT_FAILURE
} from 'appState/actions/constants/appointmentBooker.actions';

import contactFormFieldDecorator from 'lib/services/contactFormFieldDecorator';
import {
  isDummyContact,
  createDummyAppointmentFromBooker
} from 'lib/services/dummyContactResponses';

function* fetchAppointmentBooker({
  payload: {
    contactToken,
    accountId,
    appointmentTypeId,
    journeyId = null,
    week = 0,
    appointmentId
  }
}) {
  try {
    const params = {
      week,
      contact_token: contactToken,
      appointment_type_id: appointmentTypeId,
      account_id: accountId,
      appointment_id: appointmentId
    };
    if (journeyId) params.journey_id = journeyId;

    const response = yield call(axiosDefault.get, '/appointment_booker', {
      params
    });
    if (
      response.data.appointment_type &&
      response.data.appointment_type.booking_form &&
      response.data.appointment_type.booking_form.fields
    ) {
      response.data.appointment_type.booking_form.fields = contactFormFieldDecorator(
        response.data.appointment_type.booking_form.fields
      );
    }

    yield put({
      type: FETCH_APPOINTMENT_BOOKER_SUCCESS,
      payload: {
        availabilities: response.data.availabilities,
        currentWeekOffset: response.data.current_week_offset,
        currentAppointmentType: response.data.appointment_type,
        currentBookingForm: response.data.appointment_type.booking_form,
        currentContact: response.data.contact,
        currentAppointment: response.data.current_appointment
      }
    });
  } catch (e) {
    yield put({
      type: FETCH_APPOINTMENT_BOOKER_FAILURE,
      payload: { error: e },
      error: true
    });
  }
}

function* createAppointmentFromBooker({ payload: { appointmentParams } }) {
  if (yield call(isDummyContact))
    return yield call(createDummyAppointmentFromBooker, appointmentParams);

  try {
    const response = yield call(
      axiosDefault.post,
      '/appointment_booker/create_appointment',
      appointmentParams
    );

    if (response.data.should_redirect && appointmentParams.client_facing) {
      window.location = response.data.redirect_url;
    } else if (appointmentParams.client_facing) {
      window.location = `/public/bookings/${response.data.id}/confirmation`;
    }

    yield put({
      type: CREATE_APPOINTMENT_FROM_BOOKER_SUCCESS,
      payload: {
        currentAppointment: response.data
      }
    });
  } catch (e) {
    yield put({
      type: CREATE_APPOINTMENT_FROM_BOOKER_FAILURE,
      payload: { error: e, fallbackError: 'Error creating appointment' },
      error: true
    });
  }
}

function* fetchAppointment({ payload: { accountId, appointmentId } }) {
  try {
    const response = yield call(
      axiosDefault.get,
      `/appointments/${appointmentId}?account_id=${accountId}`
    );

    yield put({
      type: FETCH_APPOINTMENT_SUCCESS,
      payload: {
        currentAppointment: response.data
      }
    });
  } catch (e) {
    yield put({
      type: FETCH_APPOINTMENT_FAILURE,
      payload: { error: e },
      error: true
    });
  }
}

function* updateAppointment({ payload: { appointmentParams } }) {
  try {
    const response = yield call(
      axiosDefault.put,
      '/appointment_booker/update_appointment',
      appointmentParams
    );

    yield put({
      type: UPDATE_APPOINTMENT_SUCCESS,
      payload: {
        currentAppointment: response.data
      }
    });
  } catch (e) {
    yield put({
      type: UPDATE_APPOINTMENT_FAILURE,
      payload: { error: e },
      error: true
    });
  }
}

export function* appointmentBookerFetch() {
  yield takeLatest(FETCH_APPOINTMENT_BOOKER_REQUEST, fetchAppointmentBooker);
}

export function* AppointmentBookerCreate() {
  yield takeLatest(
    CREATE_APPOINTMENT_FROM_BOOKER_REQUEST,
    createAppointmentFromBooker
  );
}

export function* AppointmentFetch() {
  yield takeLatest(FETCH_APPOINTMENT_REQUEST, fetchAppointment);
}

export function* AppointmentUpdate() {
  yield takeLatest(UPDATE_APPOINTMENT_REQUEST, updateAppointment);
}
