import { call, put, takeLatest } from 'redux-saga/effects';
import { axiosDefault, axiosMultipartForm } from 'lib/utils/axios-setup';
import {
  FETCH_LEAD_SOURCES_REQUEST,
  FETCH_LEAD_SOURCES_SUCCESS,
  FETCH_LEAD_SOURCES_FAILURE,
  CREATE_LEAD_SOURCE_REQUEST,
  CREATE_LEAD_SOURCE_FAILURE,
  CREATE_LEAD_SOURCE_SUCCESS,
  BULK_UPDATE_CONTACTS_REQUEST,
  BULK_UPDATE_CONTACTS_SUCCESS,
  BULK_UPDATE_CONTACTS_FAILURE
} from '../actions/constants/leadSources.actions';

function* fetchAllLeadSources() {
  try {
    const response = yield call(axiosDefault.get, '/lead_sources');

    yield put({
      type: FETCH_LEAD_SOURCES_SUCCESS,
      payload: response.data
    });
  } catch (e) {
    yield put({
      type: FETCH_LEAD_SOURCES_FAILURE,
      payload: { error: e },
      error: true,
      fallbackError: 'Error fetching Lead Sources'
    });
  }
}

function* createLeadSource({ payload: { name, key } }) {
  try {
    const params = {
      lead_source: {
        name,
        key
      }
    };

    const response = yield call(axiosDefault.post, '/lead_sources', params);
    yield put({
      type: CREATE_LEAD_SOURCE_SUCCESS,
      payload: response.data
    });
  } catch (e) {
    yield put({
      type: CREATE_LEAD_SOURCE_FAILURE,
      payload: { error: e },
      error: true,
      fallbackError: 'Error creating Lead Source'
    });
  }
}

function* bulkUpdateContacts({ payload: { file, leadSource } }) {
  const formData = new FormData();
  formData.append('file', new Blob([file], { type: file.type }), file.name);
  formData.append('lead_source', leadSource);

  try {
    const response = yield call(
      axiosMultipartForm.post,
      '/bulk_lead_source_from_csv/update_contacts',
      formData
    );
    yield put({
      type: BULK_UPDATE_CONTACTS_SUCCESS,
      payload: response.data.rejected_rows
    });
  } catch (e) {
    yield put({
      type: BULK_UPDATE_CONTACTS_FAILURE,
      payload: {
        error: e,
        fallbackError: 'Error while updating contacts'
      },
      error: true
    });
  }
}

export function* leadSourcesFetch() {
  yield takeLatest(FETCH_LEAD_SOURCES_REQUEST, fetchAllLeadSources);
}

export function* leadSourceCreate() {
  yield takeLatest(CREATE_LEAD_SOURCE_REQUEST, createLeadSource);
}

export function* bulkUpdateContactsForLeadSource() {
  yield takeLatest(BULK_UPDATE_CONTACTS_REQUEST, bulkUpdateContacts);
}
