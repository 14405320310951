import React, { useEffect, Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PageHeader from 'components/Theme/PageHeader';
import {
  fetchAllAppointmentType,
  appointmentTypeDelete,
  flashErrorMessage,
  flashSuccessMessage
} from 'appState/actions/ActionCreators';
import {
  createLoadedSelector,
  createErrorMessageSelector
} from 'appState/selectors';
import {
  DELETE_APPOINTMENT_TYPE,
  FETCH_ALL_APPOINTMENT_TYPE
} from 'appState/actions/constants/appointmentType.actions';
import AppointmentTypeCardSkeleton from 'components/AppointmentTypes/AppointmentTypeCardSkeleton';
import Modal from 'components/Theme/Modal';
import isEmpty from 'lodash.isempty';
import AppointmentTypeCard from './AppointmentTypeCard';

const AppointmentTypes = () => {
  const loadedSelector = createLoadedSelector([FETCH_ALL_APPOINTMENT_TYPE]);
  const deleteApptTypeLoadedSelector = createLoadedSelector([
    DELETE_APPOINTMENT_TYPE
  ]);
  const deleteApptTypeErrorSelector = createErrorMessageSelector([
    DELETE_APPOINTMENT_TYPE
  ]);

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [currentAppointmentTypeID, setAppointmentTypeID] = useState(0);

  const structuredSelector = createStructuredSelector({
    appointmentTypes: state => state.appointmentType.appointmentTypes,
    isLoaded: state => loadedSelector(state),
    deleteApptTypeLoaded: state => deleteApptTypeLoadedSelector(state),
    deleteApptTypeError: state => deleteApptTypeErrorSelector(state)
  });

  const {
    appointmentTypes,
    isLoaded,
    deleteApptTypeLoaded,
    deleteApptTypeError
  } = useSelector(structuredSelector);
  const dispatch = useDispatch();
  const activeAppointmentType = appointmentTypes.find(
    obj => obj.id === currentAppointmentTypeID
  );

  useEffect(() => dispatch(fetchAllAppointmentType()), [dispatch]);

  useEffect(() => {
    if (deleteApptTypeLoaded) {
      if (isEmpty(deleteApptTypeError)) {
        dispatch(flashSuccessMessage('The appointment type has been deleted'));
      } else {
        dispatch(flashErrorMessage(deleteApptTypeError));
      }
    }
  }, [deleteApptTypeLoaded, deleteApptTypeError]);

  function getAppointmentTypeCards() {
    if (!isLoaded) {
      return [1, 2, 3, 4, 5, 6, 7, 8].map(() => (
        <AppointmentTypeCardSkeleton key={Math.random()} />
      ));
    }

    return (
      <Fragment>
        {appointmentTypes.map(type => {
          const {
            id,
            name_titleized,
            duration,
            permitted_staff_names,
            max_attendees
          } = type;
          return (
            <AppointmentTypeCard
              title={name_titleized}
              key={id}
              id={id}
              duration={duration}
              users={permitted_staff_names}
              maxAttendees={max_attendees}
              onDeleteClick={() => {
                setDeleteModalOpen(true);
                setAppointmentTypeID(id);
              }}
            />
          );
        })}
      </Fragment>
    );
  }

  function onDeleteAppointmentType() {
    dispatch(
      appointmentTypeDelete({ appointmentTypeId: currentAppointmentTypeID })
    );
  }

  function getProductDetail(activeJourneys) {
    return activeJourneys.map(m => {
      return (
        <p key={m} className="tw-mb-0">
          {m}
        </p>
      );
    });
  }

  function getModalBody() {
    if (!activeAppointmentType) return;
    const { deleable_msg, active_journeys_names } = activeAppointmentType;
    return (
      <>
        <p>{deleable_msg}</p>
        {getProductDetail(active_journeys_names)}
      </>
    );
  }

  return (
    <Fragment>
      <PageHeader
        title="Appointment Types"
        primaryActionText="New Appointment Type"
        primaryAction={() => {
          window.location = '/ui/appointment-types/new';
        }}
        breadcrumbs={[
          {
            name: 'Settings',
            action: () => {
              window.location = '/account/account_settings';
            }
          },
          {
            name: 'Appointment Types',
            action: () => {}
          }
        ]}
      />
      <div className="tw-px-4 tw-py-5 sm:tw-p-6">
        <div className="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 xl:tw-grid-cols-4 xxl:tw-grid-cols-4 tw-gap-4">
          {getAppointmentTypeCards()}
        </div>
      </div>
      <Modal
        color="error"
        headerText="Are you sure you would like to delete this Appointment Type?"
        innerContainerClasses="tw-px-4 tw-pt-5 tw-pb-4 sm:tw-p-6 sm:tw-pb-4"
        isOverflowHidden
        primaryAction={onDeleteAppointmentType}
        primaryActionText="Delete"
        secondaryActionText="Cancel"
        setShow={setDeleteModalOpen}
        show={isDeleteModalOpen}
        showHeaderIcon={false}
        showPrimaryAction
        showSecondaryAction
        isPrimaryActionDisabled={
          activeAppointmentType?.active_journeys_names.length > 0
        }
      >
        {getModalBody()}
      </Modal>
    </Fragment>
  );
};
export default AppointmentTypes;
