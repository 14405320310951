import isEmpty from 'lodash.isempty';
import React, { Fragment } from 'react';
import Skeleton from 'react-loading-skeleton';
import PropTypes from 'prop-types';

const Breadcrumb = ({ name, action, isBackgroundWhite, isLoaded }) => {
  return (
    <Fragment key={name}>
      {isEmpty(name) && !isLoaded ? (
        <Skeleton width={200} height={20} />
      ) : (
        <a
          className={`tw-font-body tw-cursor-pointer tw-text-gray-${
            isBackgroundWhite ? '500' : '200'
          } hover:tw-text-gray-${
            isBackgroundWhite ? '700' : '200'
          } tw-transition tw-duration-150 tw-ease-in-out`}
          onClick={action}
          onKeyDown={action}
          role="button"
          tabIndex={-1}
        >
          {name}
        </a>
      )}
    </Fragment>
  );
};

Breadcrumb.defaultProps = {
  action: null,
  isBackgroundWhite: true,
  isLoaded: true
};

Breadcrumb.propTypes = {
  name: PropTypes.string.isRequired,
  action: PropTypes.func,
  isBackgroundWhite: PropTypes.bool,
  isLoaded: PropTypes.bool
};

export default Breadcrumb;
