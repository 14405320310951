import React from 'react';
import PropTypes from 'prop-types';
import BroadcastListItem from '../BroadcastListItem';

const ErrorListItem = props => {
  const { broadcast } = props;

  return (
    <BroadcastListItem
      broadcast={broadcast}
      badgeType="danger"
      timestampAttr={broadcast.updated_at}
      timestampPretext="created "
    />
  );
};

const broadcastShape = {
  updated_at: PropTypes.string
};

ErrorListItem.propTypes = {
  broadcast: PropTypes.shape(broadcastShape).isRequired
};

export default ErrorListItem;
