import React, { useMemo, Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { subscriptionCardUpdate } from 'appState/actions/ActionCreators';
import { createStructuredSelector } from 'reselect';
import isEmpty from 'lodash.isempty';
import PropTypes from 'prop-types';
import {
  createErrorMessageSelector,
  createLoadedSelector
} from 'appState/selectors';
import Button from 'components/Theme/Button';

const useOptions = () => {
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize: '14px',
          color: '#424770',
          letterSpacing: '0.025em',
          fontFamily: 'Source Code Pro, monospace',
          '::placeholder': {
            color: '#aab7c4'
          }
        },
        invalid: {
          color: '#9e2146'
        }
      }
    }),
    []
  );

  return options;
};

const CardForm = props => {
  const errorSelector = createErrorMessageSelector([
    'subscription/UPDATE_CARD_SUBSCRIPTION'
  ]);
  const loadedSelector = createLoadedSelector([
    'subscription/UPDATE_CARD_SUBSCRIPTION'
  ]);

  const structuredSelector = createStructuredSelector({
    error: state => errorSelector(state),
    isLoaded: state => loadedSelector(state)
  });

  const { error, isLoaded } = useSelector(structuredSelector);
  const [modalCalled, setModalCalled] = useState(false);

  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const dispatch = useDispatch();

  const handleSubmit = async event => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const card = elements.getElement(CardElement);
    const payload = await stripe.createToken(card);
    card.clear();

    if (payload.error) {
      props.onCloseModal(true);
      props.onCloseNotification('error', payload.error.message);
      setModalCalled(true);
      return;
    }
    if (payload.token) {
      props.onCloseModal(true);
      setModalCalled(true);
      dispatch(
        subscriptionCardUpdate({
          subscriptionId: props.subscriptionId,
          stripeToken: payload.token.id,
          cardBrand: payload.token.card.brand,
          cardExpMonth: payload.token.card.exp_month,
          cardExpYear: payload.token.card.exp_year,
          cardLast4: payload.token.card.last4
        })
      );
    }
  };

  useEffect(() => {
    if (isLoaded && isEmpty(error) && modalCalled) {
      props.onCloseNotification('success', 'Your card details have been saved');
      setModalCalled(false);
    }

    if (isLoaded && !isEmpty(error) && modalCalled) {
      props.onCloseNotification('error', error);
      setModalCalled(false);
    }
  }, [props, error, isLoaded, modalCalled]);

  return (
    <Fragment>
      <label className="tw-w-full">
        <p className="tw-mb-4">
          {' '}
          Please enter your updated credit card information below:{' '}
        </p>
        <CardElement options={options} />
      </label>
      <div className="tw-px-4 tw-pt-3 sm:tw-px-6 sm:tw-flex sm:tw-flex-row-reverse">
        <span className="tw-flex tw-w-full tw-rounded-md tw-shadow-sm sm:tw-ml-3 sm:tw-w-auto">
          <Button
            text="Update Credit Card"
            onClick={handleSubmit}
            disabled={!stripe}
          />
        </span>
        <span className="tw-mt-3 tw-flex tw-w-full tw-rounded-md tw-shadow-sm sm:tw-mt-0 sm:tw-w-auto">
          <Button
            text="Cancel"
            onClick={props.onCloseModal}
            type="white"
          />
        </span>
      </div>
    </Fragment>
  );
};

CardForm.defaultProps = {
  onCloseNotification: null,
  subscriptionId: null,
  onCloseModal: null
};
CardForm.propTypes = {
  onCloseNotification: PropTypes.func,
  subscriptionId: PropTypes.number,
  onCloseModal: PropTypes.func
};

export default CardForm;
