import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Theme/Button';
import Badge from 'components/Theme/Badge';
import Skeleton from 'react-loading-skeleton';
import isEmpty from 'lodash.isempty';

const PageHeader = ({
  title,
  backgroundColor,
  color,
  breadcrumbs,
  primaryAction,
  primaryActionText,
  secondaryAction,
  secondaryActionText,
  isLoaded,
  children,
  rightBar,
  subTitle,
  actionGroupText,
  actionGroupTextClass,
  showSecondaryButton,
  isPrimaryActionDisabled,
  primaryType,
  secondaryType
}) => {
  const isBackgroundWhite = backgroundColor === 'white';

  function breadcrumbArrow() {
    return (
      <svg
        className={`tw-flex-shrink-0 tw-mx-2 tw-h-5 tw-w-5 tw-text-gray-${
          isBackgroundWhite ? '400' : '500'
        }`}
        fill="currentColor"
        viewBox="0 0 20 20"
      >
        <path
          fillRule="evenodd"
          d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
          clipRule="evenodd"
        />
      </svg>
    );
  }

  return (
    <div
      className={`tw-p-8 tw-bg-${
        isBackgroundWhite ? 'white' : 'gray-800'
      } tw-shadow-sm`}
    >
      {breadcrumbs && breadcrumbs.length ? (
        <div>
          <nav className="sm:tw-hidden">
            <a
              className={`tw-font-body tw-flex tw-items-center tw-text-sm tw-leading-5 tw-font-medium tw-text-gray-${
                isBackgroundWhite ? '500' : '400'
              } hover:tw-text-gray-${
                isBackgroundWhite ? '700' : '200'
              } tw-transition tw-duration-150 tw-ease-in-out`}
            >
              Back
            </a>
          </nav>
          <nav className="tw-hidden sm:tw-flex tw-items-center tw-text-sm tw-leading-5 tw-font-medium">
            {breadcrumbs.map((breadcrumb, index) => (
              <Fragment key={breadcrumb.name}>
                {isEmpty(breadcrumb.name) && !isLoaded ? (
                  <Skeleton width={200} height={20} />
                ) : (
                  <a
                    className={`tw-font-body tw-cursor-pointer tw-text-gray-${
                      isBackgroundWhite ? '500' : '400'
                    } hover:tw-text-gray-${
                      isBackgroundWhite ? '700' : '200'
                    } tw-transition tw-duration-150 tw-ease-in-out`}
                    onClick={breadcrumb.action}
                    onKeyDown={breadcrumb.action}
                    role="button"
                    tabIndex={-1}
                    data-testid={breadcrumb.name}
                  >
                    {breadcrumb.name}
                  </a>
                )}
                {index !== breadcrumbs.length - 1 ? breadcrumbArrow() : null}
              </Fragment>
            ))}
          </nav>
        </div>
      ) : null}
      <div className="tw-mt-2 md:tw-flex md:tw-items-center md:tw-justify-between">
        <div className="tw-flex-1 tw-min-w-0">
          {rightBar && <span className="tw-float-right">{rightBar}</span>}
          <h5
            className={`${
              isBackgroundWhite ? 'tw-text-gray-700' : 'tw-text-white'
            }`}
          >
            {subTitle}
          </h5>
          {isEmpty(title) && !isLoaded ? (
            <Skeleton width={250} height={30} />
          ) : (
            <h2
              className={`tw-font-body tw-text-2xl tw-font-bold tw-leading-7 ${
                isBackgroundWhite ? 'tw-text-gray-700' : 'tw-text-white'
              } sm:tw-text-3xl sm:tw-leading-9 sm:tw-truncate`}
            >
              {title}
            </h2>
          )}
          {children}
        </div>
        {primaryActionText || secondaryActionText ? (
          <div className="tw-mt-4 tw-flex md:tw-mt-0 md:tw-ml-4">
            {secondaryActionText ? (
              <Fragment>
                {showSecondaryButton ? (
                  <Button
                    text={secondaryActionText}
                    type={secondaryType}
                    onClick={secondaryAction}
                    disabled={!isLoaded}
                    isLoaded={isLoaded}
                  />
                ) : (
                  <Badge
                    shape="rectangle"
                    size="large"
                    value={secondaryActionText}
                    containerStyle={{ marginTop: '8px' }}
                  />
                )}
              </Fragment>
            ) : null}
            {primaryActionText ? (
              <Button
                text={primaryActionText}
                color={color}
                type={primaryType}
                onClick={primaryAction}
                disabled={isPrimaryActionDisabled || !isLoaded}
                isLoaded={isLoaded}
                containerStyle={{ marginLeft: '0.75rem' }}
              />
            ) : null}
          </div>
        ) : null}
      </div>
      {actionGroupText && (
        <p className={`tw-float-right tw-font-medium ${actionGroupTextClass}`}>
          {actionGroupText}
        </p>
      )}
    </div>
  );
};

PageHeader.defaultProps = {
  breadcrumbs: [],
  backgroundColor: 'white',
  color: 'alpha',
  primaryAction: () => {},
  primaryActionText: '',
  secondaryAction: () => {},
  secondaryActionText: '',
  showSecondaryButton: true,
  isLoaded: true,
  children: null,
  rightBar: null,
  actionGroupText: null,
  subTitle: null,
  isPrimaryActionDisabled: false,
  primaryType: 'primary',
  secondaryType: 'white',
  actionGroupTextClass: ''
};

const breadcrumbShape = PropTypes.shape({
  name: PropTypes.string,
  action: PropTypes.func
});

const nodeType = PropTypes.oneOfType([PropTypes.func, PropTypes.node]);

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  backgroundColor: PropTypes.oneOf(['white', 'dark']),
  color: PropTypes.oneOf([
    'alpha',
    'bravo',
    'charlie',
    'success',
    'warning',
    'error',
    'gray'
  ]),
  breadcrumbs: PropTypes.arrayOf(breadcrumbShape),
  primaryAction: PropTypes.func,
  primaryActionText: PropTypes.string,
  secondaryAction: PropTypes.func,
  secondaryActionText: PropTypes.string,
  showSecondaryButton: PropTypes.bool,
  isLoaded: PropTypes.bool,
  children: nodeType,
  rightBar: nodeType,
  subTitle: PropTypes.string,
  actionGroupText: PropTypes.string,
  isPrimaryActionDisabled: PropTypes.bool,
  primaryType: PropTypes.oneOf([
    'primary',
    'secondary',
    'secondaryWithBorder',
    'white',
    'gamma'
  ]),
  secondaryType: PropTypes.oneOf([
    'primary',
    'secondary',
    'secondaryWithBorder',
    'white',
    'gamma'
  ]),
  actionGroupTextClass: PropTypes.string
};

export default PageHeader;
