import React, { useEffect } from 'react';
import isEmpty from 'lodash.isempty';
import { createStructuredSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import HeroIcon from 'components/Theme/HeroIcon';
import {
  fetchIncludedForm,
  setOverlordLayoutHeaderText
} from 'appState/actions/ActionCreators';
import { useParams } from 'react-router-dom';
import Breadcrumbs from 'components/Theme/Breadcrumbs';

const Settings = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { id, partnerId, productId } = params;

  const structuredSelector = createStructuredSelector({
    currentIncludedForm: state => state.product.currentIncludedForm
  });

  const { currentIncludedForm } = useSelector(structuredSelector);

  useEffect(() => {
    dispatch(fetchIncludedForm({ includedFormId: id }));
  }, [id, dispatch]);

  useEffect(() => {
    if (!isEmpty(currentIncludedForm)) {
      dispatch(
        setOverlordLayoutHeaderText(`${currentIncludedForm.name} Settings`)
      );
    } else {
      dispatch(setOverlordLayoutHeaderText('Settings'));
    }
  }, [currentIncludedForm]);

  const breadcrumbs = [
    {
      name: 'Overlord',
      action: () => {
        window.location = '/overlord/';
      }
    },
    {
      name: 'Partner',
      action: () => {
        window.location = `/overlord/partners/${partnerId}`;
      }
    },
    {
      name: 'Product',
      action: () => {
        window.location = `/overlord/partners/${partnerId}/products/${productId}`;
      }
    },
    {
      name: 'Included Form',
      action: () => {
        window.location = `/overlord/partners/${partnerId}/products/${productId}/included_forms/${id}`;
      }
    },
    {
      name: 'Settings',
      action: () => console.log('settings clicked')
    }
  ];

  return (
    <div className="tw-px-4 tw-py-4 sm:tw-px-6">
      <div className="tw-mb-10">
        {breadcrumbs && breadcrumbs.length ? (
          <div>
            <Breadcrumbs crumbs={breadcrumbs} isBackgroundWhite />
          </div>
        ) : null}
      </div>
      <div className="tw-border-b tw-border-t-0 tw-border-l-0 tw-border-r-0 tw-border-solid tw-border-gray-200" />
      <div>
        <a
          href={`/overlord/partners/${partnerId}/products/${productId}/included_forms/${id}/settings/staff_email_notification`}
          className="tw-block hover:tw-bg-gray-50 tw-border-b tw-border-t-0 tw-border-l-0 tw-border-r-0 tw-border-solid tw-border-gray-200"
        >
          <div className="tw-flex tw-items-center tw-px-4 tw-py-4 sm:tw-px-6">
            <div className="tw-min-w-0 tw-flex-1 tw-flex tw-items-center">
              <div className="tw-flex-shrink-0">
                <HeroIcon
                  icon="mail"
                  color="gray"
                  type="solid"
                  width={10}
                  height={10}
                />
              </div>
              <div className="tw-min-w-0 tw-flex-1 tw-px-4 md:tw-grid md:tw-grid-cols-2 md:tw-gap-4">
                <div>
                  <p className="tw-mt-1 tw-mb-1 tw-text-sm tw-font-medium tw-text-alpha-600 tw-truncate">
                    Staff Email Notification
                  </p>
                  <p className="tw-mt-1 tw-mb-1 tw-flex tw-items-center tw-text-sm tw-text-gray-500">
                    <span>
                      Send a email notification to a staff member when a contact
                      fills out this form.
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div>
              <HeroIcon
                icon="chevron-right"
                color="gray"
                type="solid"
                width={5}
                height={5}
              />
            </div>
          </div>
        </a>
      </div>
      <div>
        <a
          href={`/overlord/partners/${partnerId}/products/${productId}/included_forms/${id}/settings/staff_sms_notification`}
          className="tw-block hover:tw-bg-gray-50 tw-border-b tw-border-t-0 tw-border-l-0 tw-border-r-0 tw-border-solid tw-border-gray-200"
        >
          <div className="tw-flex tw-items-center tw-px-4 tw-py-4 sm:tw-px-6">
            <div className="tw-min-w-0 tw-flex-1 tw-flex tw-items-center">
              <div className="tw-flex-shrink-0">
                <HeroIcon
                  icon="chat"
                  color="gray"
                  type="solid"
                  width={10}
                  height={10}
                />
              </div>
              <div className="tw-min-w-0 tw-flex-1 tw-px-4 md:tw-grid md:tw-grid-cols-2 md:tw-gap-4">
                <div>
                  <p className="tw-mt-1 tw-mb-1 tw-text-sm tw-font-medium tw-text-alpha-600 tw-truncate">
                    Staff SMS Notification
                  </p>
                  <p className="tw-mt-1 tw-mb-1 tw-flex tw-items-center tw-text-sm tw-text-gray-500">
                    <span>
                      Send a sms notification to a staff member when a contact
                      fills out this form.
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div>
              <HeroIcon
                icon="chevron-right"
                color="gray"
                type="solid"
                width={5}
                height={5}
              />
            </div>
          </div>
        </a>
      </div>
      <div>
        <a
          href={`/overlord/partners/${partnerId}/products/${productId}/included_forms/${id}/settings/add_to_campaign`}
          className="tw-block hover:tw-bg-gray-50"
        >
          <div className="tw-flex tw-items-center tw-px-4 tw-py-4 sm:tw-px-6">
            <div className="tw-min-w-0 tw-flex-1 tw-flex tw-items-center">
              <div className="tw-flex-shrink-0">
                <HeroIcon
                  icon="mail"
                  color="gray"
                  type="solid"
                  width={10}
                  height={10}
                />
              </div>
              <div className="tw-min-w-0 tw-flex-1 tw-px-4 md:tw-grid md:tw-grid-cols-2 md:tw-gap-4">
                <div>
                  <p className="tw-mt-1 tw-mb-1 tw-text-sm tw-font-medium tw-text-alpha-600 tw-truncate">
                    Add To Campaign Overlay
                  </p>
                  <p className="tw-mt-1 tw-mb-1 tw-flex tw-items-center tw-text-sm tw-text-gray-500">
                    <span>
                      Add the contact automatically to a campaign overlay when
                      they fill out this form.
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div>
              <HeroIcon
                icon="chevron-right"
                color="gray"
                type="solid"
                width={5}
                height={5}
              />
            </div>
          </div>
        </a>
      </div>
    </div>
  );
};

export default Settings;
