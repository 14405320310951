import React, { useState, useEffect, useContext, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import isEmpty from 'lodash.isempty';
import {
  fetchNextAppointment,
  fetchAppointment,
  fetchActivities
} from 'appState/actions/ActionCreators';
import { FETCH_NEXT_APPOINTMENT } from 'appState/actions/constants/contact.actions';
import {
  CANCEL_APPOINTMENT,
  MARK_APPOINTMENT_NO_SHOW
} from 'app-state/actions/constants/activity.actions';
import {
  createLoadedSelector,
  createErrorMessageSelector
} from 'appState/selectors';
import { formatTimestamp } from 'lib/utils/dateTime';
import { DATE_TIME_FORMAT } from 'config/constants';
import { CurrentAccountContext } from 'components/shared/context/CurrentAccountContext';
import Card from 'components/Theme/Card';
import AppointmentCancelModal from 'components/Theme/ActivityFeed/AppointmentCancelModal';
import AppointmentNoShowModal from 'components/Theme/ActivityFeed/AppointmentNoShowModal';
import CardHeader from '../shared/cardHeader';
import CardBody from '../shared/cardBody';
import getBadgeContent from '../shared/badgeContent';

const ACTIONS = [
  { displayName: 'Reschedule', value: 'reschedule' },
  { displayName: 'No-show', value: 'noShow' },
  { displayName: 'Cancel', value: 'cancel' }
];

const Appointments = () => {
  const [dateStr, setDateStr] = useState();
  const [badgeColor, setBadgeColor] = useState();
  const [badgeValue, setBadgeValue] = useState();
  const [appointmentActivity, setAppointmentActivity] = useState({});
  const [showAppNoShowModal, setShowAppNoShowModal] = useState(false);
  const [showAppCancelModal, setShowAppCancelModal] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const currentAccount = useContext(CurrentAccountContext);

  const loadedSelector = createLoadedSelector([FETCH_NEXT_APPOINTMENT]);

  const appointmentActionLoadedSelector = createLoadedSelector([
    CANCEL_APPOINTMENT,
    MARK_APPOINTMENT_NO_SHOW
  ]);

  const appointmentActionErrorSelector = createErrorMessageSelector([
    CANCEL_APPOINTMENT,
    MARK_APPOINTMENT_NO_SHOW
  ]);

  const structuredSelector = createStructuredSelector({
    currentContact: state => state.contact.currentContact,
    nextAppointment: state => state.contact.nextAppointment,
    fetchAppointmentLoaded: state => loadedSelector(state),
    appointmentActionLoaded: state => appointmentActionLoadedSelector(state),
    appointmentActionError: state => appointmentActionErrorSelector(state)
  });

  const {
    currentContact,
    nextAppointment,
    fetchAppointmentLoaded,
    appointmentActionLoaded,
    appointmentActionError
  } = useSelector(structuredSelector);

  const handleAppointmentActions = action => {
    if (action === 'reschedule') {
      history.push(`/ui/contacts/${currentContact?.id}#appointments`);
      dispatch(
        fetchAppointment({
          appointmentId: nextAppointment?.id,
          accountId: currentAccount.id
        })
      );
    } else if (action === 'noShow') setShowAppNoShowModal(true);
    else if (action === 'cancel') setShowAppCancelModal(true);
  };

  useEffect(() => {
    dispatch(fetchNextAppointment({ contactId: currentContact?.id }));
  }, [dispatch, currentContact]);

  useEffect(() => {
    if (fetchAppointmentLoaded && !isEmpty(nextAppointment)) {
      setDateStr(
        formatTimestamp(
          nextAppointment.starts_at,
          currentAccount.time_zone,
          false,
          DATE_TIME_FORMAT,
          true
        )
      );
      const { color, value } = getBadgeContent(
        nextAppointment.starts_at,
        currentAccount.time_zone
      );
      setBadgeColor(color);
      setBadgeValue(value);

      setAppointmentActivity({
        sourceable_id: nextAppointment.id,
        appointment_type: nextAppointment.appointment_type,
        appointment_starts_at: nextAppointment.starts_at,
        created_at: nextAppointment.created_at
      });
    } else {
      setBadgeColor('');
      setBadgeValue('');
    }
  }, [fetchAppointmentLoaded, nextAppointment]);

  useEffect(() => {
    if (appointmentActionLoaded) {
      if (isEmpty(appointmentActionError)) {
        dispatch(fetchActivities({ contactId: currentContact.id }));
      }
    }
  }, [dispatch, appointmentActionLoaded, appointmentActionError]);

  return (
    <Card
      containerClass="tw-w-full tw-border-solid tw-border tw-rounded-md tw-border-gray-200"
      header={
        <CardHeader
          text="Next Appointment"
          icon="calendar"
          badgeColor={badgeColor}
          badgeValue={badgeValue}
          disableSkeleton
        />
      }
    >
      <CardBody
        resource={nextAppointment}
        resourceLoaded={fetchAppointmentLoaded}
        resourceType="appointment"
        resourceDueDate={dateStr}
        resourceTitle={nextAppointment?.appointment_type}
        resourceActions={ACTIONS}
        handleResourceActions={handleAppointmentActions}
        contactId={currentContact.id}
      />
      {!isEmpty(appointmentActivity) && (
        <Fragment>
          <AppointmentNoShowModal
            appointment={appointmentActivity}
            show={showAppNoShowModal}
            setShow={setShowAppNoShowModal}
          />
          <AppointmentCancelModal
            appointment={appointmentActivity}
            show={showAppCancelModal}
            setShow={setShowAppCancelModal}
          />
        </Fragment>
      )}
    </Card>
  );
};

export default Appointments;
