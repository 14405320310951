import styled from 'styled-components';

const Header = styled.header`
  top: 0px;
  left: 70px;
  right: 0;
  position: fixed;
  width: calc(100% - 70px);
  border-bottom: ${props => (props.isTemplate ? '3px solid #926dde' : 'none')};
  box-shadow: ${props =>
    props.isTemplate
      ? '0 5px 10px rgba(154, 160, 185, 0.05), 0 10px 30px rgba(146, 109, 222, 0.15)'
      : 'none'};

  @media (max-width: 991.98px) {
    left: 0;
    width: 100%;
  }
`;

const TruncatedTitle = styled.h2`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
`;

const Styled = {
  Header,
  TruncatedTitle
};

export default Styled;
