import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';
import ActivityFeed from 'components/Theme/ActivityFeed';
import Pagination from 'components/Theme/Pagination';
import { resetIsLoaded } from 'appState/actions/ActionCreators';

const RecentActivities = ({
  tabName,
  activities,
  total,
  totalPages,
  limit,
  currentContact,
  isLoaded,
  resetAction,
  pageChangeAction
}) => {
  const dispatch = useDispatch();

  const pageChange = () => args => {
    if (!isEmpty(currentContact)) {
      dispatch(resetIsLoaded(resetAction));
      return dispatch(
        pageChangeAction({
          contactId: currentContact.id,
          ...args
        })
      );
    }
    return null;
  };

  return (
    <Fragment>
      <h3 className="tw-font-body tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900 tw-pb-4 tw-pt-2">
        {isLoaded && isEmpty(activities) ? 'No ' : ''}Recent Activity
      </h3>
      <ActivityFeed
        actionColor="alpha"
        secondaryActionColor="error"
        containerStyle={{
          paddingBottom: '10px'
        }}
        itemColor="gray"
        items={activities}
        showIcons
        widthClass="tw-w-full"
        tabName={tabName}
        isLoaded={isLoaded}
        hasFooter
        FooterComponent={() => (
          <Fragment>
            {totalPages && totalPages > 1 ? (
              <Pagination
                total={parseInt(total, 10)}
                totalPages={totalPages}
                limit={limit}
                action={pageChange()}
              />
            ) : null}
          </Fragment>
        )}
      />
    </Fragment>
  );
};

const colorPropType = PropTypes.oneOf([
  'alpha',
  'bravo',
  'charlie',
  'success',
  'warning',
  'error',
  'gray'
]);

const infoAlertShape = {
  message: PropTypes.string,
  type: PropTypes.oneOf(['standard', 'colored']),
  headerText: PropTypes.string,
  HeaderIcon: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  color: colorPropType
};

const statusObjectShape = {
  status: PropTypes.string,
  title: PropTypes.string,
  friendly_message: PropTypes.string,
  failed_code: PropTypes.string,
  failed_message: PropTypes.string,
  failed_link: PropTypes.string
};

const actionShape = {
  displayName: PropTypes.string,
  value: PropTypes.string
};

const activityShape = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  sourceable_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  index: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  headerText: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.string
  ]),
  bodyText: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.string
  ]),
  action: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  actionText: PropTypes.string,
  secondaryActionText: PropTypes.string,
  secondaryAction: PropTypes.arrayOf(PropTypes.shape(actionShape)),
  actions: PropTypes.arrayOf(PropTypes.shape(actionShape)),
  timestamp: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  isPinned: PropTypes.bool.isRequired,
  pinnedAction: PropTypes.func,
  infoAlert: PropTypes.shape(infoAlertShape),
  statusObject: PropTypes.shape(statusObjectShape)
});

const currentContactShape = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

RecentActivities.defaultProps = {
  tabName: 'feed',
  activities: [],
  isLoaded: false
};

RecentActivities.propTypes = {
  tabName: PropTypes.string,
  activities: PropTypes.arrayOf(activityShape),
  total: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  totalPages: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  limit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  currentContact: PropTypes.shape(currentContactShape).isRequired,
  isLoaded: PropTypes.bool,
  resetAction: PropTypes.string.isRequired,
  pageChangeAction: PropTypes.func.isRequired
};

export default RecentActivities;
