/* eslint-disable react/display-name */
import React, {
  useEffect,
  useState,
  useContext,
  Fragment,
  useMemo
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { useFormik } from 'formik';
import { createStructuredSelector } from 'reselect';
import { UsersContext } from 'components/shared/context/UsersContext';
import { CurrentAccountContext } from 'components/shared/context/CurrentAccountContext';
import { CurrentUserContext } from 'components/shared/context/CurrentUserContext';
import ReactTags from 'react-tag-autocomplete';
import {
  contactNewSchema,
  modularContactNewSchema
} from 'lib/validation/schema';
import isEmpty from 'lodash.isempty';
import groupBy from 'lodash.groupby';
import {
  createErrorMessageSelector,
  createLoadingSelector,
  createLoadedSelector,
  createAllLoadedSelector
} from 'appState/selectors';
import {
  fetchContact,
  fetchContactAutomations,
  fetchContactCustomGroups,
  fetchCustomGroups,
  updateContactCustomGroups,
  fetchCurrentAutomationDetails,
  updateCurrentAutomationUnpause,
  updateCurrentAutomationComplete,
  newAutomationLifecycleCheck,
  fetchAccountCampaigns,
  fetchStepList,
  createNewAutomation,
  fetchJourneys,
  updateContactForm,
  newLifecycleSummaryConfirmation,
  updateContactLifecycle,
  enableNewContactProfile,
  fetchAutoCompleteSuggestions,
  setCurrentContactTab,
  resetAppointmentBooker,
  setCurrentAppointmentTypeId,
  fetchActivities,
  removeSpamComplaint,
  flashMessage,
  flashErrorMessage,
  fetchLeadSources
} from 'appState/actions/ActionCreators';
import { formatTimestamp } from 'lib/utils/dateTime';
import Badge from 'components/Theme/Badge';
import Banner from 'components/Theme/Banner';
import Card from 'components/Theme/Card';
import Tabs from 'components/Theme/Tabs';
import Modal from 'components/Theme/Modal';
import Select from 'components/Theme/Select';
import TextInput from 'components/Theme/TextInput';
import DatePicker from 'components/Theme/DatePicker';
import Notification from 'components/Theme/Notification';
import AutoCompleteDropdown from 'components/Theme/AutoCompleteDropDown';
import ZenPlannerLogo from 'images/zen-planner-logo.svg';
import {
  FETCH_CONTACT,
  UPDATE_CONTACT,
  DELETE_CONTACT,
  CREATE_AUTOMATION,
  FETCH_CONTACT_AUTOMATIONS,
  FETCH_CONTACT_CUSTOM_GROUPS,
  FETCH_CURRENT_AUTOMATION_DETAILS,
  UNSUBSCRIBE_CONTACT,
  RESUBSCRIBE_CONTACT,
  REMOVE_SPAM_COMPLAINT
} from 'appState/actions/constants/contact.actions';
import Alert from 'components/Theme/Alert';
import Button from 'components/Theme/Button';
import HeroIcon from 'components/Theme/HeroIcon';
import ShowMore from 'components/Theme/ShowMore';
import { capitalize } from 'lib/utils/string';
import AutomationListItem from './AutomationListItem';
import FeedTab from './FeedTab';
import SmsTab from './SmsTab';
import EmailTab from './EmailTab';
import AppointmentsTab from './AppointmentsTab';
import NotesTab from './NotesTab';
import TasksTab from './TasksTab';
import FormsTab from './FormsTab';
import DocumentTab from './DocumentTab';
import PauseAutomationModal from './PauseAutomationModal';
import ContactInfoCard from './ContactInfoCard';
import PastAutomation from './AutomationListItem/PastAutomation';
import DummyCampaignItem from './AutomationListItem/DummyCampaignItem';
import FourOFour from '../../FourOFour';

const ContactProfile = () => {
  const currentAccount = useContext(CurrentAccountContext);
  const currentUser = useContext(CurrentUserContext);
  const accountId = currentAccount.id;
  const dispatch = useDispatch();
  const errorSelector = createErrorMessageSelector([FETCH_CONTACT]);
  const contactUpdateErrorSelector = createErrorMessageSelector([
    UPDATE_CONTACT
  ]);
  const loadingSelector = createLoadingSelector([FETCH_CONTACT]);
  const loadedSelector = createLoadedSelector([FETCH_CONTACT]);
  const updateContactLoadedSelector = createLoadedSelector([UPDATE_CONTACT]);
  const createAutomationLoadedSelector = createLoadedSelector([
    CREATE_AUTOMATION
  ]);
  const loadedAutomationsSelector = createLoadedSelector([
    FETCH_CONTACT_AUTOMATIONS
  ]);
  const loadedCustomGroupsSelector = createAllLoadedSelector([
    FETCH_CONTACT_CUSTOM_GROUPS,
    'overlord/CUSTOM_GROUPS_FETCH'
  ]);

  const loadedAutomationDetailsSelector = createLoadedSelector([
    FETCH_CURRENT_AUTOMATION_DETAILS
  ]);

  const loadedEnableNewProfileSeletor = createLoadedSelector([
    'overlord/ENABLE_NEW_CONTACT_PROFILE'
  ]);

  const spamComplaintRemovedLoadedSelector = createLoadedSelector([
    REMOVE_SPAM_COMPLAINT
  ]);
  const spamComplaintRemovedErrorSelector = createErrorMessageSelector([
    REMOVE_SPAM_COMPLAINT
  ]);

  const deleteContactLoadedSelector = createLoadedSelector([DELETE_CONTACT]);
  const deleteContactErrorSelector = createErrorMessageSelector([
    DELETE_CONTACT
  ]);

  const unsubscribeContactLoadedSelector = createLoadedSelector([
    UNSUBSCRIBE_CONTACT
  ]);
  const resubscribeContactLoadedSelector = createLoadedSelector([
    RESUBSCRIBE_CONTACT
  ]);
  const unsubResubErrorSelector = createErrorMessageSelector([
    UNSUBSCRIBE_CONTACT,
    RESUBSCRIBE_CONTACT
  ]);

  const structuredSelector = createStructuredSelector({
    currentContact: state => state.contact.currentContact,
    selectedTab: state => state.contact.currentContactTab,
    automations: state => state.contact.automations,
    contactCustomGroups: state => state.contact.customGroups,
    customGroups: state => state.overlordUtilities.customGroups,
    automationCampaigns: state => state.contact.automationCampaigns,
    campaigns: state => state.contact.campaigns,
    addAutomationConfirmationMessage: state =>
      state.contact.addAutomationConfirmationMessage,
    newLifecycle: state => state.contact.newLifecycle,
    willChangeLifecycle: state => state.contact.willChangeLifecycle,
    alreadyBeenInCampaign: state => state.contact.alreadyBeenInCampaign,
    automationsToBeCompletedIds: state =>
      state.contact.automationsToBeCompletedIds,
    productCampaignStepList: state => state.contact.productCampaignStepList,
    currentAutomationDetails: state => state.contact.currentAutomationDetails,
    error: state => errorSelector(state),
    contactUpdateError: state => contactUpdateErrorSelector(state),
    isLoading: state => loadingSelector(state),
    isLoaded: state => loadedSelector(state),
    isUpdateContactLoaded: state => updateContactLoadedSelector(state),
    isCreateAutomationLoaded: state => createAutomationLoadedSelector(state),
    isAutomationsLoaded: state => loadedAutomationsSelector(state),
    newLifecycleConfirmationMessage: state =>
      state.contact.newLifecycleConfirmationMessage,
    campaignToAdd: state => state.contact.campaignToAdd,
    isCustomGroupsLoaded: state => loadedCustomGroupsSelector(state),
    isCurrentAutomationDetailsLoaded: state =>
      loadedAutomationDetailsSelector(state),
    journeys: state => state.overlordUtilities.journeys,
    isLoadedEnableNewProfle: state => loadedEnableNewProfileSeletor(state),
    referralSuggestions: state => state.autoComplete.suggestions,
    isDeleteContactLoaded: state => deleteContactLoadedSelector(state),
    deleteContactError: state => deleteContactErrorSelector(state),
    unsubscribeContactLoaded: state => unsubscribeContactLoadedSelector(state),
    resubscribeContactLoaded: state => resubscribeContactLoadedSelector(state),
    unsubResubError: state => unsubResubErrorSelector(state),
    isSpamComplaintRemovedLoaded: state =>
      spamComplaintRemovedLoadedSelector(state),
    isSpamComplaintRemovedError: state =>
      spamComplaintRemovedErrorSelector(state),
    defaultLeadSources: state => state.leadSource.defaultLeadSources,
    customLeadSources: state => state.leadSource.customLeadSources
  });

  const params = useParams();
  const { id } = params;
  const { users, isUsersLoaded, usersError } = useContext(UsersContext);
  const userSelectOptions = users.map(user => {
    return { displayName: user.full_name, value: user.id };
  });
  const [editingCustomGroups, setEditingCustomGroups] = useState(false);
  const [updateContact, setUpdateContact] = useState(false);
  const [currentAutomation, setCurrentAutomation] = useState({});
  const [addingAutomation, setAddingAutomation] = useState(false);
  const [
    viewingCurrentAutomationDetails,
    setViewingCurrentAutomationDetails
  ] = useState(false);
  const [activeAutomationId, setActiveAutoMationId] = useState(0);
  const [showPauseAutomationModal, setShowPauseAutomationModal] = useState(
    false
  );
  const [showUnpauseAutomationModal, setShowUnpauseAutomationModal] = useState(
    false
  );
  const [showRemoveAutomationModal, setShowRemoveAutomationModal] = useState(
    false
  );
  const [localCustomGroups, setLocalCustomGroups] = useState([]);
  const [formattedCustomGroups, setFormattedCustomGroups] = useState([]);
  const [showHoldBadge, setShowHoldBadge] = useState(false);
  const [showPendingBadge, setShowPendingBadge] = useState(false);
  const [showReactivationBadge, setShowReactivationBadge] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState('');
  const [
    selectedRestartCampaignOption,
    setSelectedRestartCampaignOption
  ] = useState('');
  const [selectedCampaignStep, setSelectedCampaignStep] = useState('');
  const [markAsSale, setMarkAsSale] = useState(false);
  const [notificationType, setNotificationType] = useState('gray');
  const [notificationHeader, setNotificationHeader] = useState('');
  const [notificationMessage, setNotificationMessage] = useState('');
  const [showNotification, setShowNotification] = useState(false);
  const [selectedLifecycle, setSelectedLifecycle] = useState('');
  const [changingLifecycle, setChangingLifecycle] = useState(false);
  const [showEnableNewProfile, setShowEnableNewProfile] = useState(false);
  const [resetReferrerDropdown, setResetReferrerDropdown] = useState(false);
  const [leadSourceOptions, setLeadSourceOptions] = useState([]);
  const {
    currentContact,
    selectedTab,
    automations,
    customGroups,
    contactCustomGroups,
    currentAutomationDetails,
    error,
    contactUpdateError,
    isUpdateContactLoaded,
    isAutomationsLoaded,
    isCustomGroupsLoaded,
    isCurrentAutomationDetailsLoaded,
    automationCampaigns,
    campaigns,
    addAutomationConfirmationMessage,
    newLifecycle,
    willChangeLifecycle,
    alreadyBeenInCampaign,
    productCampaignStepList,
    automationsToBeCompletedIds,
    isCreateAutomationLoaded,
    journeys,
    newLifecycleConfirmationMessage,
    campaignToAdd,
    isLoadedEnableNewProfle,
    referralSuggestions,
    isDeleteContactLoaded,
    deleteContactError,
    unsubscribeContactLoaded,
    resubscribeContactLoaded,
    unsubResubError,
    isSpamComplaintRemovedLoaded,
    isSpamComplaintRemovedError,
    defaultLeadSources,
    customLeadSources
  } = useSelector(structuredSelector);

  let journeysSelectOptions;
  if (!isEmpty(currentContact)) {
    journeysSelectOptions = journeys
      .filter(
        journey =>
          journey.id === currentContact.clientJourneyId || !journey.archived
      )
      .map(journey => {
        return {
          displayName: journey.name,
          value: journey.id,
          disabled: journey.archived
        };
      });
  }

  const contactInfo = {
    id: currentContact.id,
    assignedStaff: currentContact.assignedStaff,
    email: currentContact.email,
    subscribed: currentContact.subscribed,
    formattedPhone: currentContact.formattedPhone,
    subscribedToSms: currentContact.subscribedToSms,
    canBeSentSms: currentContact.canBeSentSms,
    fullAddress: currentContact.fullAddress,
    birthday: currentContact.birthday,
    gender: currentContact.gender,
    tshirtSize: currentContact.tshirtSize,
    referrerFullName: currentContact.referrerFullName,
    membershipStartedAt: currentContact.membershipStartedAt,
    clientJourney: currentContact.clientJourney,
    initialCampaign: currentContact.initialCampaign,
    spamComplaint: currentContact.spamComplaint,
    under14: currentContact.under14,
    gdprCompliant: currentContact.gdprCompliant,
    leadSource: currentContact.leadSource
  };

  const pageTabs = [
    'feed',
    'notes',
    'tasks',
    'forms',
    'documents',
    'email',
    'sms',
    'appointments'
  ];

  const setSelectedTab = urlTab => {
    return pageTabs.includes(urlTab) ? urlTab : 'feed';
  };
  const location = useLocation();
  useEffect(() => {
    window.scrollTo({ top: 0 });
    dispatch(
      setCurrentContactTab(setSelectedTab(location.hash.replace('#', '')))
    );
  }, [location]);

  useEffect(() => {
    const contactParams = {
      contactId: id
    };
    if (!currentAccount.new_profile_enabled) contactParams.dummy = true;
    dispatch(fetchContact(contactParams));
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchLeadSources());
  }, [dispatch]);

  useEffect(() => {
    const defaultOptions = defaultLeadSources.map(leadSource => {
      return { displayName: leadSource.name, value: leadSource.key };
    });

    let customOptions = [];
    if (!isEmpty(customLeadSources)) {
      customOptions = customLeadSources.map(leadSource => {
        return { displayName: leadSource.name, value: leadSource.key };
      });
    }

    setLeadSourceOptions([...defaultOptions, ...customOptions]);
  }, [defaultLeadSources, customLeadSources]);

  useEffect(() => {
    if (selectedRestartCampaignOption !== 'restart_from_specific_step')
      setSelectedCampaignStep('');
  }, [selectedRestartCampaignOption, dispatch]);

  useEffect(() => {
    if (!isEmpty(currentContact))
      dispatch(fetchContactAutomations({ contactId: currentContact.id }));
  }, [dispatch, currentContact]);

  useEffect(() => {
    if (!isEmpty(currentContact))
      dispatch(fetchContactCustomGroups({ contactId: currentContact.id }));
  }, [dispatch, currentContact]);

  useEffect(() => {
    dispatch(fetchCustomGroups());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchAccountCampaigns());
  }, [dispatch]);

  useEffect(() => {
    if (isSpamComplaintRemovedLoaded) {
      if (isEmpty(isSpamComplaintRemovedError)) {
        dispatch(flashMessage('Successfully Removed Spam Complaint'));
      } else {
        dispatch(flashErrorMessage(isSpamComplaintRemovedError));
      }
    }
  }, [isSpamComplaintRemovedLoaded, isSpamComplaintRemovedError]);

  useEffect(() => {
    if (!isEmpty(contactCustomGroups))
      setLocalCustomGroups(
        contactCustomGroups.map(c => {
          return { id: c.id, name: c.name };
        })
      );
  }, [dispatch, contactCustomGroups]);

  useEffect(() => {
    if (!isEmpty(customGroups))
      setFormattedCustomGroups(
        customGroups.map(c => {
          return {
            id: c.id,
            name: c.name,
            disabled: localCustomGroups.filter(l => l.id === c.id).length > 0
          };
        })
      );
  }, [dispatch, customGroups, localCustomGroups]);

  useEffect(() => {
    if (currentContact && isAutomationsLoaded && automations) {
      const inProgressAutomations = automations.filter(
        item => item.completed_at === null
      );
      const holdAutomations = inProgressAutomations.filter(item =>
        item.title.toLowerCase().includes('hold')
      );
      if (!isEmpty(holdAutomations)) setShowHoldBadge(true);
      else setShowHoldBadge(false);
    }
  }, [dispatch, currentContact, isAutomationsLoaded, automations]);

  useEffect(() => {
    if (currentContact && isAutomationsLoaded && automations) {
      const inProgressAutomations = automations.filter(
        item => item.completed_at === null
      );
      const pendingAutomations = inProgressAutomations.some(auto =>
        auto.title.toLowerCase().includes('pending cancellation')
      );
      if (pendingAutomations) setShowPendingBadge(true);
      else setShowPendingBadge(false);
    }
  }, [dispatch, currentContact, isAutomationsLoaded, automations]);

  useEffect(() => {
    if (currentContact && isAutomationsLoaded && automations) {
      const inProgressAutomations = automations.filter(
        item => item.completed_at === null
      );
      const leadAndInProgressAutomations = inProgressAutomations.filter(
        item => item.lifecycle === 'lead'
      );
      if (
        currentContact.currentLifecycle === 'former_client' &&
        !isEmpty(leadAndInProgressAutomations)
      )
        setShowReactivationBadge(true);
    }
  }, [dispatch, currentContact, isAutomationsLoaded, automations]);

  useEffect(() => {
    if (!isEmpty(currentAccount))
      dispatch(fetchJourneys({ accountId, active: true }));
  }, [dispatch, accountId, currentAccount]);

  useEffect(() => {
    if (isEmpty(error) && isEmpty(contactUpdateError)) {
      if (isUpdateContactLoaded) {
        setShowNotification(true);
        setNotificationType('success');
        setNotificationHeader('Success!');
        setUpdateContact(false);
        setNotificationMessage('Contact updated');
      } else setShowNotification(false);
    } else {
      if (!isEmpty(error)) setNotificationMessage(error);
      else setNotificationMessage(contactUpdateError);

      setNotificationType('error');
      setNotificationHeader('Error!');
      setShowNotification(true);
    }
  }, [error, contactUpdateError, isUpdateContactLoaded, dispatch]);

  useEffect(() => {
    if (isDeleteContactLoaded) {
      if (isEmpty(deleteContactError)) {
        window.location.href = '/contacts';
      } else {
        setNotificationType('error');
        setNotificationHeader('Error!');
        setShowNotification(true);
        setNotificationMessage(deleteContactError);
      }
    }
  }, [isDeleteContactLoaded, deleteContactError]);

  useEffect(() => {
    if (unsubscribeContactLoaded || resubscribeContactLoaded) {
      const message = unsubscribeContactLoaded
        ? 'unsubscribed'
        : 're-subscribed';
      if (isEmpty(unsubResubError)) {
        setNotificationType('success');
        setNotificationHeader('Success!');
        setNotificationMessage(`Contact ${message} successfully!`);
        dispatch(
          fetchActivities({
            contactId: currentContact.id
          })
        );
      } else {
        setNotificationType('error');
        setNotificationHeader('Error!');
        setNotificationMessage(unsubResubError);
      }
      setShowNotification(true);
    }
  }, [unsubscribeContactLoaded, resubscribeContactLoaded, unsubResubError]);

  useEffect(() => {
    if (isLoadedEnableNewProfle)
      window.location.href = `/contacts/${currentContact.id}`;
  }, [isLoadedEnableNewProfle]);

  function redirectToOldProfile() {
    window.location.href = `/contacts/${currentContact.id}`;
  }

  function handleEnableNowContactProfile() {
    dispatch(enableNewContactProfile());
  }

  function handleContactUpdate(values) {
    const updateContactParams = {};
    if (Object.keys(values).length)
      Object.keys(values).forEach(function assignParams(key) {
        updateContactParams[key] = values[key];
      });
    dispatch(updateContactForm(updateContactParams));
  }

  const contactFormik = useFormik({
    initialValues: currentContact,
    enableReinitialize: true,
    validationSchema: currentAccount.mod_campaigns_enabled
      ? modularContactNewSchema
      : contactNewSchema,
    onSubmit: values => {
      handleContactUpdate(values);
    }
  });

  const handleContactUpdateModalClose = () => {
    contactFormik.resetForm();
  };

  function handleBirthdayChange(updatedValue) {
    contactFormik.setFieldValue('birthday', updatedValue);
  }

  const handleReferrerSelection = value => {
    contactFormik.setFieldValue('referrerId', value);
  };

  const handleReferrerChange = query => {
    dispatch(
      fetchAutoCompleteSuggestions({
        url: 'contacts',
        query,
        suggestionDataMap: { displayName: 'full_name', value: 'id' }
      })
    );
  };

  function handleMembershipStartDateChange(updatedValue) {
    contactFormik.setFieldValue('membershipStartedAt', updatedValue);
  }

  function resetStateFormCreateAutomationConfirmationModal() {
    setSelectedCampaign('');
    setSelectedRestartCampaignOption('');
    setSelectedCampaignStep('');
    setMarkAsSale(false);
  }

  useEffect(() => {
    if (isCreateAutomationLoaded) {
      resetStateFormCreateAutomationConfirmationModal();
    }
  }, [isCreateAutomationLoaded]);

  useEffect(() => {
    if (
      selectedLifecycle &&
      selectedLifecycle !==
        (!isEmpty(newLifecycle)
          ? newLifecycle
          : currentContact.currentLifecycle)
    ) {
      setChangingLifecycle(true);
    }
  }, [currentContact, selectedLifecycle]);

  function handleLifecycleSubmit() {
    dispatch(
      newLifecycleSummaryConfirmation({
        contactId: currentContact.id,
        journeyId: currentContact.clientJourneyId,
        newLifecycle: selectedLifecycle,
        selectedCampaign
      })
    );
  }

  useEffect(() => {
    if (!isEmpty(selectedLifecycle) && selectedLifecycle === 'no_lifecycle') {
      handleLifecycleSubmit();
    }
  }, [selectedLifecycle]);

  function fetchAutomationDetails(automationId) {
    setViewingCurrentAutomationDetails(true);
    setActiveAutoMationId(automationId);
    dispatch(
      fetchCurrentAutomationDetails({
        automationId
      })
    );
  }

  function updateAutomationUnpause() {
    dispatch(
      updateCurrentAutomationUnpause({
        automationId: currentAutomation.id
      })
    );
  }

  function updateAutomationComplete() {
    dispatch(
      updateCurrentAutomationComplete({
        automationId: currentAutomation.id
      })
    );
  }

  function handleOnTabChange(tab) {
    if (selectedTab === 'appointments' && tab.value !== 'appointments') {
      dispatch(resetAppointmentBooker());
      dispatch(
        setCurrentAppointmentTypeId({
          id: ''
        })
      );
    }
    dispatch(setCurrentContactTab(tab.value));
  }

  function handlePauseButtonClick(automation) {
    setShowPauseAutomationModal(true);
    setCurrentAutomation(automation);
  }

  function handleUnpauseButtonClick(automation) {
    setShowUnpauseAutomationModal(true);
    setCurrentAutomation(automation);
  }
  function handleRemoveButtonClick(automation) {
    setShowRemoveAutomationModal(true);
    setCurrentAutomation(automation);
  }
  function handleModalSecondaryAction() {
    setAddingAutomation(false);
    resetStateFormCreateAutomationConfirmationModal();
  }

  const automationListItems = automations.map(automation => {
    if (automation.completed_at) return;
    return (
      <AutomationListItem
        key={automation.id}
        automation={automation}
        handleDetailsButtonClick={fetchAutomationDetails}
        handlePauseButtonClick={handlePauseButtonClick}
        handleUnpauseButtonClick={handleUnpauseButtonClick}
        handleRemoveButtonClick={handleRemoveButtonClick}
      />
    );
  });

  function campaignsToBeAddedOptions() {
    const campaignsArray = campaigns.map(campaign => {
      return {
        displayName: campaign.name,
        value: campaign.id
      };
    });
    campaignsArray.push({ displayName: 'No Campaign', value: 'no_campaign' });
    return campaignsArray;
  }

  function handleMarkAsSaleSelect(e) {
    setMarkAsSale(e.target.value);
  }

  function handleCustomGroupSaveButton() {
    dispatch(
      updateContactCustomGroups({
        contactId: currentContact.id,
        customGroups: localCustomGroups.map(cg => cg.id)
      })
    );
  }

  function handleCampaignSelect(e) {
    setSelectedCampaign(e.target.value);
    dispatch(
      newAutomationLifecycleCheck({
        contactId: currentContact.id,
        journeyId: currentContact.clientJourneyId,
        newCampaign: e.target.value
      })
    );
  }

  function handleCampaignStepSelect(e) {
    setSelectedCampaignStep(e.target.value);
  }

  function handleCampaignStartSelect(e) {
    setSelectedRestartCampaignOption(e.target.value);
    if (e.target.value === 'restart_from_specific_step') {
      dispatch(
        fetchStepList({
          productCampaignId: selectedCampaign
        })
      );
    }
  }

  const fetch_product_journey = currentSelectedCampaign => {
    const campaign = automationCampaigns.find(
      element => element.id.toString() === currentSelectedCampaign
    );
    const selectedJourney = campaign
      ? journeys.find(elem => elem.product_id === campaign.product_id)
      : null;
    return selectedJourney?.id;
  };

  function handleAutomationSubmit() {
    const journeyId = currentAccount.mod_campaigns_enabled
      ? fetch_product_journey(selectedCampaign)
      : currentContact.clientJourneyId;

    dispatch(
      createNewAutomation({
        campaignStepId: parseInt(selectedCampaignStep, 10),
        campaignId: parseInt(selectedCampaign, 10),
        newLifecycle,
        markAsSale,
        automationsToBeCompletedIds,
        contactId: currentContact.id,
        journeyId,
        restartCampaignOption: selectedRestartCampaignOption
      })
    );
  }

  function handleLifecycleModalSecondaryAction() {
    setChangingLifecycle(false);
    setSelectedLifecycle('');
    resetStateFormCreateAutomationConfirmationModal();
  }

  function handleNewLifecycleSubmit() {
    dispatch(
      updateContactLifecycle({
        contactId: currentContact.id,
        journeyId: currentContact.clientJourneyId,
        newLifecycle: selectedLifecycle,
        selectedCampaign,
        markAsSale: Boolean(markAsSale),
        campaignToAdd,
        restartCampaignOption: selectedRestartCampaignOption,
        campaignStepId: parseInt(selectedCampaignStep, 10),
        automationsToBeCompletedIds
      })
    );
    resetStateFormCreateAutomationConfirmationModal();
  }

  function handleRemoveSpamComplaint() {
    dispatch(removeSpamComplaint({ currentContactId: currentContact.id }));
  }

  const campaignSelectOptions = useMemo(() => {
    const options = automationCampaigns.map(campaign => {
      return {
        displayName: campaign.name,
        value: campaign.id,
        label: campaign.product_name
      };
    });

    const groupOptions = groupBy(options, 'label');

    const campaignOptions = Object.keys(groupOptions).map(label => {
      return {
        label,
        options: groupOptions[label]
      };
    });
    return campaignOptions;
  }, [automationCampaigns]);

  const stepListOptions = productCampaignStepList.map(step => {
    return {
      displayName: step.name,
      value: step.id
    };
  });

  const lifecycleModalHeaderText =
    currentContact &&
    currentContact.currentLifecycle &&
    currentContact.currentLifecycle !== 'no_lifecycle'
      ? 'Change Lifecycle'
      : 'Add Lifecycle';

  function fetchLifecycleChangeModalSteps() {
    const steps = [];
    if (selectedLifecycle !== 'no_lifecycle') {
      steps.push({
        action: handleLifecycleSubmit,
        buttonText: lifecycleModalHeaderText,
        content: (
          <div>
            <div className="tw-mb-5">
              <Select
                value={selectedCampaign}
                id="selectedCampaign"
                labelText="Choose Campaign"
                onChange={handleCampaignSelect}
                options={campaignsToBeAddedOptions()}
              />
            </div>
            {selectedLifecycle === 'client' ? (
              <div className="tw-mb-5">
                <Select
                  value={markAsSale}
                  id="markAsSale"
                  labelText="Would you like to mark this as a sale?"
                  onChange={handleMarkAsSaleSelect}
                  options={[
                    {
                      displayName: 'Yes',
                      value: true
                    },
                    {
                      displayName: 'No',
                      value: false
                    }
                  ]}
                />
              </div>
            ) : null}
          </div>
        ),
        id: 1,
        text: 'step one'
      });
    }
    steps.push({
      action: handleNewLifecycleSubmit,
      buttonText: 'Confirm',
      content: (
        <div className="tw-font-body tw-text-sm tw-leading-5 tw-text-gray-500">
          {!isEmpty(newLifecycleConfirmationMessage) ? (
            <div className="tw-mb-5">{newLifecycleConfirmationMessage}</div>
          ) : (
            <Skeleton height={24} width={180} />
          )}
          {alreadyBeenInCampaign && selectedLifecycle !== 'no_lifecycle' ? (
            <div className="tw-mb-10">
              <Select
                value={selectedRestartCampaignOption}
                id="restartCampaign"
                labelText="Where would you like to restart this campaign?"
                onChange={handleCampaignStartSelect}
                options={[
                  {
                    displayName: 'Restart from Beginning',
                    value: 'restart_from_beginning'
                  },
                  {
                    displayName: 'Restart from Last Step',
                    value: 'restart_from_last_step'
                  },
                  {
                    displayName: 'Restart from Specific Step',
                    value: 'restart_from_specific_step'
                  }
                ]}
              />
            </div>
          ) : null}
          {selectedRestartCampaignOption === 'restart_from_specific_step' ? (
            <div className="tw-mb-10">
              <Select
                value={selectedCampaignStep}
                id="select_campaign_step"
                labelText="Select a step to restart at."
                onChange={handleCampaignStepSelect}
                options={stepListOptions}
              />
            </div>
          ) : null}
        </div>
      ),
      id: 2,
      text: 'step two'
    });
    return steps;
  }

  // eslint-disable-next-line react/prop-types
  function selectedCustomGroupBadge({ tag, onDelete }) {
    return (
      <Badge
        color="alpha"
        badgeClass="tw-mr-2"
        deletable
        hasDot={false}
        onClick={null}
        onDelete={onDelete}
        shape="round"
        size="large"
        textStyle={{}}
        value={tag?.name}
      />
    );
  }

  const customGroupsListItems = contactCustomGroups.map(customGroup => {
    return (
      <Badge
        key={customGroup.id}
        badgeClass="tw-text-gray-500 tw-mr-2"
        clickable={false}
        color="gray"
        containerStyle={{}}
        hasDot={false}
        onClick={null}
        onDelete={null}
        shape="round"
        size="small"
        textStyle={{}}
        value={customGroup.name}
      />
    );
  });

  function handleOnCustomGroupDelete(i) {
    const groups = localCustomGroups.slice(0);
    groups.splice(i, 1);
    setLocalCustomGroups(groups);
  }

  function handleOnCustomGroupAdd(group) {
    setLocalCustomGroups([...localCustomGroups, group]);
  }

  function handleCustomGroupCancelButton() {
    setLocalCustomGroups(contactCustomGroups);
  }

  function contactProfileTabs() {
    const tabs = [
      {
        icon: () => (
          <span className="tw-mr-1 tw-h-5">
            <HeroIcon
              icon="information-circle"
              color={selectedTab === 'feed' ? 'alpha' : 'gray'}
            />
          </span>
        ),
        text: 'Feed',
        value: 'feed',
        content: () => (
          <FeedTab setShowAddAutomationModal={setAddingAutomation} />
        )
      },
      {
        icon: () => (
          <svg
            className={`tw-h-5 tw-w-5 tw-mr-1 tw-text-${
              selectedTab === 'tasks' ? 'alpha' : 'gray'
            }-400`}
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-4 9l2 2 4-4" />
          </svg>
        ),
        text: 'Tasks',
        value: 'tasks',
        content: () => <TasksTab contactId={id} />
      },
      {
        icon: () => (
          <span className="tw-mr-1 tw-h-5">
            <HeroIcon
              icon="annotation"
              color={selectedTab === 'notes' ? 'alpha' : 'gray'}
            />
          </span>
        ),
        text: 'Notes',
        value: 'notes',
        content: () => <NotesTab contactId={id} />
      },
      {
        icon: () => (
          <span className="tw-mr-1 tw-h-5">
            <HeroIcon
              icon="document-add"
              color={selectedTab === 'forms' ? 'alpha' : 'gray'}
            />
          </span>
        ),
        text: 'Forms',
        value: 'forms',
        content: () => <FormsTab contactId={id} />
      },
      {
        icon: () => (
          <span className="tw-mr-1 tw-h-5">
            <HeroIcon
              icon="paper-clip"
              color={selectedTab === 'documents' ? 'alpha' : 'gray'}
            />
          </span>
        ),
        text: 'Documents',
        value: 'documents',
        content: () => (
          <DocumentTab
            contactId={id}
            isDummy={!currentAccount.new_profile_enabled}
          />
        )
      }
    ];

    const optionalTabs = [
      {
        icon: () => (
          <span className="tw-mr-1 tw-h-5">
            <HeroIcon
              icon="chat"
              color={selectedTab === 'sms' ? 'alpha' : 'gray'}
            />
          </span>
        ),
        text: 'SMS',
        value: 'sms',
        content: () => <SmsTab contact={currentContact} />
      },
      {
        icon: () => (
          <span className="tw-mr-1 tw-h-5">
            <HeroIcon
              icon="calendar"
              color={selectedTab === 'appointments' ? 'alpha' : 'gray'}
            />
          </span>
        ),
        text: 'Appointments',
        value: 'appointments',
        content: () => <AppointmentsTab />
      },
      {
        icon: () => (
          <span className="tw-mr-1 tw-h-5">
            <HeroIcon
              icon="mail"
              color={selectedTab === 'email' ? 'alpha' : 'gray'}
            />
          </span>
        ),
        text: 'Email',
        value: 'email',
        content: () => <EmailTab />
      }
    ];

    if (!currentContact?.under14) tabs.splice(1, 0, ...optionalTabs);

    return tabs;
  }

  const noCampaignsText = () => {
    if (currentContact?.under14)
      return "No Campaings in progress. This contact is 13 or younger and can't be added to campaigns.";
    return 'No Campaigns in progress.';
  };
  const automation = automations.find(a => a.id === activeAutomationId);

  return (
    <Fragment>
      {!isEmpty(error) ? (
        <FourOFour />
      ) : (
        <Fragment>
          {currentContact?.dummy && (
            <Fragment>
              <Banner
                showDismissBtn={false}
                announcement="Like the layout you see? If you want to permanently convert all your contacts to this new design, click ‘Convert Now!’."
                smallAnnouncement="Like what you see?"
                primaryBtnText="Convert Now!"
                primaryBtnAction={() => setShowEnableNewProfile(true)}
                secondaryBtnText="Cancel and Return"
                secondaryBtnAction={() => redirectToOldProfile()}
              />
              <Banner
                announcement="This test profile is intended to show you design and layout changes. Full function of the activity feed and other actions may not be available on this test profile."
                smallAnnouncement="Full function of the activity feed and other actions may not be available on this test profile."
                color="gray"
                hideIcon
              />
            </Fragment>
          )}
          {currentContact?.under14 && (
            <Banner
              showDismissBtn={false}
              announcement="This contact is 13 or younger. Communications from UpLaunch are not allowed."
              smallAnnouncement="Communcations to contact 13 or younger are not allowed."
              color="gray"
            />
          )}
          <div className="tw-p-6 tw-grid tw-grid-cols-12 tw-gap-6 tw-bg-grey-100">
            <div className="xxs:tw-col-span-12 md:tw-col-span-12 lg:tw-col-span-5">
              {currentContact && currentContact.fullName ? (
                <div className="tw-flex tw-items-center">
                  <h1 className="tw-text-4xl tw-font-bold tw-text-gray-900 tw-mb-0 tw-inline">
                    {currentContact.fullName}
                  </h1>
                  {currentContact.contactRemoteIdentities.filter(
                    cri => cri.provider === 'zen_planner'
                  ).length > 0 && (
                    <img
                      className=""
                      src={ZenPlannerLogo}
                      style={{
                        height: '25px',
                        width: '25px',
                        marginLeft: '10px'
                      }}
                      alt=""
                    />
                  )}
                </div>
              ) : (
                <div className="tw-mb-4">
                  <Skeleton height={24} width={180} />
                </div>
              )}
              {currentContact?.currentLifecycle && (
                <div className="tw-inline-block">
                  <Badge
                    badgeStyle={{ marginTop: '0' }}
                    color="charlie"
                    badgeClass="tw-mr-1 tw-mb-3"
                    hasDot={false}
                    onClick={null}
                    shape="round"
                    size="small"
                    textStyle={{}}
                    value={capitalize(currentContact?.currentLifecycle)}
                  />
                </div>
              )}
              {showHoldBadge && (
                <div className="tw-inline-block">
                  <Badge
                    badgeStyle={{ marginTop: '0' }}
                    color="warning"
                    badgeClass="tw-mr-1 tw-mb-3"
                    hasDot={false}
                    onClick={null}
                    shape="round"
                    size="small"
                    textStyle={{}}
                    value="Membership Hold"
                  />
                </div>
              )}
              {showPendingBadge && (
                <div className="tw-inline-block">
                  <Badge
                    badgeStyle={{ marginTop: '0' }}
                    color="error"
                    badgeClass="tw-mr-1 tw-mb-3"
                    hasDot={false}
                    onClick={null}
                    shape="round"
                    size="small"
                    textStyle={{}}
                    value="Pending Cancellation"
                  />
                </div>
              )}
              {showReactivationBadge && (
                <div className="tw-inline-block">
                  <Badge
                    badgeStyle={{ marginTop: '0' }}
                    color="charlie"
                    badgeClass="tw-mr-1 tw-mb-3"
                    hasDot={false}
                    onClick={null}
                    shape="round"
                    size="small"
                    textStyle={{}}
                    value="Reactivation"
                  />
                </div>
              )}
              <ContactInfoCard
                contact={contactInfo}
                updateContact={updateContact}
                handleSetUpdate={setUpdateContact}
                handleSetResetReferrer={setResetReferrerDropdown}
                leadSourceOptions={leadSourceOptions}
              />
              <Card
                title="Campaigns"
                showHeaderAction
                headerAction={() => {
                  if (isAutomationsLoaded)
                    setAddingAutomation(!addingAutomation);
                }}
                QA
                headerActionIcon={() =>
                  !currentContact?.under14 && (
                    <HeroIcon icon="plus-circle" color="alpha" />
                  )
                }
                containerClass="tw-rounded-lg tw-bg-white tw-mb-4"
              >
                <div className="tw-p-6">
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {currentContact.dummy ? (
                    <span>
                      {currentContact.currentProductCampaign ? (
                        <DummyCampaignItem currentContact={currentContact} />
                      ) : (
                        noCampaignsText()
                      )}
                    </span>
                  ) : isAutomationsLoaded ? (
                    <span>
                      {automationListItems && automationListItems.length
                        ? automationListItems
                        : noCampaignsText()}
                    </span>
                  ) : (
                    <Skeleton height={24} width={280} />
                  )}
                  <div className="tw-pt-3">
                    <ShowMore>
                      <h3 className="tw-font-body tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-500">
                        Past Campaigns
                      </h3>
                      <PastAutomation
                        automations={automations}
                        timezone={currentAccount.time_zone}
                      />
                    </ShowMore>
                  </div>
                </div>
              </Card>
              <Card
                title="Custom Groups"
                showHeaderAction
                headerAction={() => {
                  if (isCustomGroupsLoaded)
                    setEditingCustomGroups(!editingCustomGroups);
                }}
                headerActionIcon={() => (
                  <HeroIcon icon="pencil-alt" color="alpha" />
                )}
                containerClass="tw-rounded-lg tw-bg-white tw-mb-4"
              >
                <div className="tw-p-6 tw-pt-2">
                  {isCustomGroupsLoaded ? (
                    <span>
                      {customGroupsListItems && customGroupsListItems.length
                        ? customGroupsListItems
                        : 'No custom groups added.'}
                    </span>
                  ) : (
                    <Skeleton height={24} width={280} />
                  )}
                </div>
              </Card>
            </div>
            <div className="xxs:tw-col-span-12 md:tw-col-span-12 lg:tw-col-span-7">
              <Card
                containerClass={`tw-rounded-lg tw-bg-white tw-mb-4 ${
                  selectedTab === 'sms' ? '' : 'tw-min-h-screen'
                }`}
              >
                <div className="tw-px-6 tw-py-3">
                  <Tabs
                    type="withIcons"
                    color="alpha"
                    onTabChange={handleOnTabChange}
                    tabs={contactProfileTabs()}
                    tabSelected={selectedTab}
                    appendTabHash
                  />
                </div>
              </Card>

              {updateContact && (
                <Modal
                  color="alpha"
                  show={updateContact}
                  setShow={setUpdateContact}
                  headerText="Edit Contact"
                  primaryAction={contactFormik.handleSubmit}
                  primaryActionText="Update"
                  secondaryAction={handleContactUpdateModalClose}
                  showSecondaryAction
                  secondaryActionText="Cancel"
                  showHeaderIcon={false}
                  shouldPrimaryActionClose={false}
                >
                  <div className="tw-mt-5 md:tw-mt-0 md:tw-col-span-3">
                    <div className="sm:tw-rounded-md sm:tw-overflow-hidden">
                      {currentUser.overlord && currentContact.spamComplaint ? (
                        <Fragment>
                          <Alert
                            color="gray"
                            headerText="Spam Complaint"
                            bodyText="This contact has reported this account for spam. If this was done in error, you can remove the spam complaint by clicking the button below."
                            icon="information-circle"
                          />
                          <Button
                            text="Remove Spam Complaint"
                            isFullWidth
                            type="primary"
                            color="error"
                            size="xs"
                            onClick={handleRemoveSpamComplaint}
                          />
                        </Fragment>
                      ) : null}
                      <div className="tw-px-4 tw-py-5 tw-bg-white sm:tw-p-6">
                        <label className="tw-font-body tw-block tw-font-normal tw-text-base tw-text-gray-700 tw-mb-5">
                          Required Fields
                          <span className="tw-font-body tw-block tw-font-normal tw-text-xs tw-text-gray-400">
                            Update the contact’s required information.
                          </span>
                        </label>
                        <div className="xs:tw-grid xs:tw-grid-cols-12 xs:tw-gap-6">
                          <TextInput
                            id="firstName"
                            required
                            value={contactFormik.values.firstName}
                            labelText="First Name"
                            onChange={contactFormik.handleChange}
                            onBlur={contactFormik.handleBlur}
                            showError={
                              contactFormik.touched.firstName &&
                              !!contactFormik.errors.firstName
                            }
                            error={contactFormik.errors.firstName}
                            widthClass="tw-col-span-12 sm:tw-col-span-6 xxs:tw-my-4 xs:tw-my-0"
                          />
                          <TextInput
                            id="lastName"
                            required
                            value={contactFormik.values.lastName}
                            labelText="Last Name"
                            onChange={contactFormik.handleChange}
                            onBlur={contactFormik.handleBlur}
                            showError={
                              contactFormik.touched.lastName &&
                              !!contactFormik.errors.lastName
                            }
                            error={contactFormik.errors.lastName}
                            widthClass="tw-col-span-12 sm:tw-col-span-6 xxs:tw-my-4 xs:tw-my-0"
                          />

                          <TextInput
                            id="email"
                            required
                            value={contactFormik.values.email}
                            labelText="Email Address"
                            onChange={contactFormik.handleChange}
                            onBlur={contactFormik.handleBlur}
                            showError={
                              contactFormik.touched.email &&
                              !!contactFormik.errors.email
                            }
                            error={contactFormik.errors.email}
                            widthClass="tw-col-span-12 sm:tw-col-span-6 xxs:tw-my-4 xs:tw-my-0"
                          />

                          <Select
                            id="assignedStaffId"
                            required
                            value={contactFormik.values.assignedStaffId}
                            options={
                              isUsersLoaded && !usersError
                                ? userSelectOptions
                                : []
                            }
                            labelText="Assigned Staff"
                            placeholder="Select Assigned Staff"
                            onChange={contactFormik.handleChange}
                            onBlur={contactFormik.handleBlur}
                            showError={
                              contactFormik.touched.assignedStaffId &&
                              !!contactFormik.errors.assignedStaffId
                            }
                            error={contactFormik.errors.assignedStaffId}
                            widthClass="tw-col-span-12 sm:tw-col-span-6 xxs:tw-my-4 xs:tw-my-0"
                          />

                          {!currentAccount.mod_campaigns_enabled && (
                            <Select
                              id="journeyId"
                              required
                              value={contactFormik.values.journeyId}
                              options={journeysSelectOptions || []}
                              labelText="Journey"
                              placeholder="Select Journey"
                              onChange={contactFormik.handleChange}
                              onBlur={contactFormik.handleBlur}
                              showError={
                                contactFormik.touched.journeyId &&
                                !!contactFormik.errors.journeyId
                              }
                              error={contactFormik.errors.journeyId}
                              widthClass="tw-col-span-12 sm:tw-col-span-6 xxs:tw-my-4 xs:tw-my-0"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="tw-mt-5 md:tw-mt-0 md:tw-col-span-3">
                    <div className="sm:tw-rounded-md">
                      <div className="tw-px-4 tw-py-5 tw-bg-white sm:tw-p-6">
                        <label className="tw-font-body tw-block tw-font-normal tw-text-base tw-text-gray-700 tw-mb-5">
                          Optional Fields
                          <span className="tw-font-body tw-block tw-font-normal tw-text-xs tw-text-gray-400">
                            Update the contact’s other information.
                          </span>
                        </label>
                        <div className="xs:tw-grid xs:tw-grid-cols-12 xs:tw-gap-6">
                          <TextInput
                            id="phone"
                            value={contactFormik.values.phone}
                            labelText="Phone"
                            type="number"
                            onChange={contactFormik.handleChange}
                            onBlur={contactFormik.handleBlur}
                            onKeyDown={e => {
                              if (e.which === 38 || e.which === 40) {
                                e.preventDefault();
                              }
                            }}
                            inputStyle={{ MozAppearance: 'textfield' }}
                            showError={
                              contactFormik.touched.phone &&
                              !!contactFormik.errors.phone
                            }
                            error={contactFormik.errors.phone}
                            widthClass="tw-col-span-12 sm:tw-col-span-6 xxs:tw-my-4 xs:tw-my-0"
                            helpText="Please enter phone number with country code"
                          />

                          <Select
                            id="gender"
                            value={contactFormik.values.gender}
                            options={[
                              { displayName: 'Male', value: 'Male' },
                              { displayName: 'Female', value: 'Female' },
                              { displayName: 'Not Specified', value: 'Other' }
                            ]}
                            labelText="Gender"
                            placeholder="Select Gender"
                            onChange={contactFormik.handleChange}
                            onBlur={contactFormik.handleBlur}
                            showError={
                              contactFormik.touched.gender &&
                              !!contactFormik.errors.gender
                            }
                            error={contactFormik.errors.gender}
                            widthClass="tw-col-span-12 sm:tw-col-span-6 xxs:tw-my-4 xs:tw-my-0"
                          />

                          <DatePicker
                            id="birthday"
                            value={contactFormik.values.birthday}
                            labelText="Birthday"
                            onChange={handleBirthdayChange}
                            onBlur={contactFormik.handleBlur}
                            showError={
                              contactFormik.touched.birthday &&
                              !!contactFormik.errors.birthday
                            }
                            error={contactFormik.errors.birthday}
                            widthClass="tw-col-span-12 sm:tw-col-span-6 xxs:tw-my-4 xs:tw-my-0"
                            showYearDropdown
                          />

                          <TextInput
                            id="tshirtSize"
                            value={contactFormik.values.tshirtSize}
                            labelText="T-Shirt Size"
                            onChange={contactFormik.handleChange}
                            onBlur={contactFormik.handleBlur}
                            showError={
                              contactFormik.touched.tshirtSize &&
                              !!contactFormik.errors.tshirtSize
                            }
                            error={contactFormik.errors.tshirtSize}
                            helpText="i.e. XS, S, M, L, XL, XXL"
                            widthClass="tw-col-span-12 sm:tw-col-span-6 xxs:tw-my-4 xs:tw-my-0"
                          />

                          <AutoCompleteDropdown
                            suggestions={referralSuggestions}
                            labelText="Referred By"
                            id="referrerIdForUpdate"
                            handleChange={handleReferrerChange}
                            handleSelection={handleReferrerSelection}
                            displayValue={contactFormik.values.referrerFullName}
                            resetSelection={resetReferrerDropdown}
                            widthClass="tw-col-span-12 sm:tw-col-span-6 xxs:tw-my-4 xs:tw-my-0"
                          />

                          <DatePicker
                            id="membershipStartedAt"
                            value={contactFormik.values.membershipStartedAt}
                            labelText="Membership Start Date"
                            onChange={handleMembershipStartDateChange}
                            onBlur={contactFormik.handleBlur}
                            showError={
                              contactFormik.touched.membershipStartedAt &&
                              !!contactFormik.errors.membershipStartedAt
                            }
                            error={contactFormik.errors.membershipStartedAt}
                            widthClass="tw-col-span-12 sm:tw-col-span-6 xxs:tw-my-4 xs:tw-my-0"
                            showYearDropdown
                          />

                          {currentAccount.mod_campaigns_enabled &&
                            currentAccount.lead_source_enabled && (
                              <Select
                                id="leadSource"
                                options={leadSourceOptions}
                                value={contactFormik.values.leadSource}
                                labelText="Lead Source"
                                onChange={contactFormik.handleChange}
                                onBlur={contactFormik.handleBlur}
                                widthClass="tw-col-span-12 sm:tw-col-span-6 xxs:tw-my-4 xs:tw-my-0"
                              />
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tw-mt-5 md:tw-mt-0 md:tw-col-span-3">
                    <div className="sm:tw-rounded-md sm:tw-overflow-hidden">
                      <div className="tw-px-4 tw-py-5 tw-bg-white sm:tw-p-6">
                        <label className="tw-font-body tw-block tw-font-normal tw-text-base tw-text-gray-700 tw-mb-5">
                          Address Fields
                          <span className="tw-font-body tw-block tw-font-normal tw-text-xs tw-text-gray-400">
                            Update the contact&apos;s address.
                          </span>
                        </label>
                        <div className="xs:tw-grid xs:tw-grid-cols-12 xs:tw-gap-6">
                          <TextInput
                            id="address1"
                            value={contactFormik.values.address1}
                            labelText="Address (Line 1)"
                            onChange={contactFormik.handleChange}
                            onBlur={contactFormik.handleBlur}
                            showError={
                              contactFormik.touched.address1 &&
                              !!contactFormik.errors.address1
                            }
                            error={contactFormik.errors.address1}
                            widthClass="tw-col-span-12 sm:tw-col-span-6 xxs:tw-my-4 xs:tw-my-0"
                          />

                          <TextInput
                            id="address2"
                            value={contactFormik.values.address2}
                            labelText="Address (Line 2)"
                            onChange={contactFormik.handleChange}
                            onBlur={contactFormik.handleBlur}
                            showError={
                              contactFormik.touched.address2 &&
                              !!contactFormik.errors.address2
                            }
                            error={contactFormik.errors.address2}
                            widthClass="tw-col-span-12 sm:tw-col-span-6 xxs:tw-my-4 xs:tw-my-0"
                          />

                          <TextInput
                            id="city"
                            value={contactFormik.values.city}
                            labelText="City"
                            onChange={contactFormik.handleChange}
                            onBlur={contactFormik.handleBlur}
                            showError={
                              contactFormik.touched.city &&
                              !!contactFormik.errors.city
                            }
                            error={contactFormik.errors.city}
                            widthClass="tw-col-span-12 sm:tw-col-span-6 xxs:tw-my-4 xs:tw-my-0"
                          />

                          <TextInput
                            id="state"
                            value={contactFormik.values.state}
                            labelText="State"
                            onChange={contactFormik.handleChange}
                            onBlur={contactFormik.handleBlur}
                            showError={
                              contactFormik.touched.state &&
                              !!contactFormik.errors.state
                            }
                            error={contactFormik.errors.state}
                            widthClass="tw-col-span-12 sm:tw-col-span-6 xxs:tw-my-4 xs:tw-my-0"
                          />

                          <TextInput
                            id="postalCode"
                            value={contactFormik.values.postalCode}
                            labelText="Postal Code"
                            onChange={contactFormik.handleChange}
                            onBlur={contactFormik.handleBlur}
                            showError={
                              contactFormik.touched.postalCode &&
                              !!contactFormik.errors.postalCode
                            }
                            error={contactFormik.errors.postalCode}
                            widthClass="tw-col-span-12 sm:tw-col-span-6 xxs:tw-my-4 xs:tw-my-0"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
              )}

              <Modal
                color="alpha"
                headerText="Edit Custom Groups"
                primaryAction={handleCustomGroupSaveButton}
                secondaryAction={handleCustomGroupCancelButton}
                showHeaderIcon={false}
                primaryActionText="Save"
                secondaryActionText="Cancel"
                setShow={setEditingCustomGroups}
                show={editingCustomGroups}
                showSecondaryAction
                canHandleClickOutside={false}
              >
                <div className="tw-font-body tw-text-sm tw-leading-5 tw-text-gray-500 tw-mt-2 tw-mb-2 tw-text-left">
                  <span>
                    Select group(s) you would like to add or remove for this
                    contact. Create new custom groups by visiting:
                  </span>
                  <a
                    className="tw-font-body tw-cursor-pointer tw-font-medium tw-text-gray-500 hover:tw-text-gray-700 tw-transition tw-ease-in-out tw-duration-150 tw-pl-1"
                    role="button"
                    href="/account/custom_groups"
                  >
                    Account Settings &gt; Segmentation &gt; Custom Groups
                  </a>
                </div>
                <ReactTags
                  tags={localCustomGroups}
                  suggestions={formattedCustomGroups}
                  onDelete={handleOnCustomGroupDelete}
                  onAddition={handleOnCustomGroupAdd}
                  allowBackspace={false}
                  noSuggestionsText="No custom group matches your search"
                  tagComponent={selectedCustomGroupBadge}
                  autoresize={false}
                  onBlur={() => null}
                  inputAttributes={{ autoFocus: true }}
                  minQueryLength={1}
                  classNames={{
                    root:
                      'tw-relative tw-pt-1 tw-pl-1 tw-font-body tw-form-input tw-block tw-py-2 tw-px-3',
                    rootFocused: 'is-focused',
                    selected: 'tw-inline',
                    selectedTag:
                      'tw-inline-block tw-mr-2 tw-mt-2 tw-py-2 tw-px-3 tw-bg-gray-200 tw-rounded-md',
                    selectedTagName: '',
                    search:
                      'tw-relative tw-inline-block tw-px-1 tw-py-3 tw-mb-2 tw-max-w-full',
                    searchWrapper: '',
                    searchInput: 'tw-inline tw-border-0 focus:tw-outline-none',
                    suggestions:
                      'tw-absolute tw-left-0 tw-bg-white tw-shadow-lg tw-rounded-md custom-group-suggestions tw-border-solid tw-border tw-border-gray-100 tw-min-w-300',
                    suggestionActive: 'tw-text-gray-700 tw-bg-gray-200',
                    suggestionDisabled: 'is-disabled'
                  }}
                  placeholderText="Group name"
                />
              </Modal>
              <Modal
                headerText={`Campaign Details : ${automation?.title}`}
                innerContainerClasses="tw-px-4 tw-pt-5 sm:tw-pt-6 tw-pb-0"
                showHeaderIcon={false}
                showSecondaryAction
                showPrimaryAction={false}
                secondaryActionText="Close"
                setShow={setViewingCurrentAutomationDetails}
                show={viewingCurrentAutomationDetails}
                canHandleClickOutside={false}
              >
                <span>
                  <span className="tw-block">
                    <strong>Triggered:</strong> {automation?.initiation_event}
                  </span>
                  <span className="tw-block">
                    <strong>Start Date:</strong>{' '}
                    {formatTimestamp(
                      automation?.campaign_started_at,
                      currentAccount.timezone,
                      false,
                      'MMMM Do YYYY',
                      true
                    )}
                  </span>
                  <span className="tw-block">
                    <strong>End Date:</strong>{' '}
                    {formatTimestamp(
                      automation?.estimated_completion_date,
                      currentAccount.timezone,
                      false,
                      'MMMM Do YYYY',
                      true
                    )}
                  </span>
                  {automation?.last_engagement_at ? (
                    <span className="tw-block">
                      <strong>Last Engagement At:</strong>{' '}
                      {formatTimestamp(
                        automation?.last_engagement_at,
                        currentAccount.timezone,
                        true,
                        'MMMM Do YYYY',
                        false
                      )}
                    </span>
                  ) : null}
                </span>
                <div className="tw-max-h-96 tw-overflow-y-auto">
                  <ul className="tw-list-none tw-pl-0 tw-mt-2">
                    {isCurrentAutomationDetailsLoaded
                      ? currentAutomationDetails.map(step => {
                          return (
                            <li key={step.step_id}>
                              <a
                                href={
                                  step.processed_at || currentContact.dummy
                                    ? '#'
                                    : `/contacts/${currentContact.id}/campaign_steps/${step.step_id}`
                                }
                                className={`tw-block ${
                                  step.processed_at
                                    ? 'tw-cursor-not-allowed'
                                    : 'hover:tw-bg-gray-50 focus:tw-outline-none focus:tw-bg-gray-50'
                                } tw-transition tw-duration-150 tw-ease-in-out tw-border tw-border-r-0 tw-border-b-0 tw-border-l-0 tw-border-solid tw-border-gray-100`}
                              >
                                <div className="tw-py-4 tw-flex tw-items-center ">
                                  <div className="tw-min-w-0 tw-flex-1 sm:tw-flex sm:tw-items-center sm:tw-justify-between">
                                    <div>
                                      <div
                                        className={`tw-text-sm tw-leading-5 tw-font-medium tw-text-${
                                          step.processed_at
                                            ? 'gray-300'
                                            : 'alpha-600'
                                        } tw-truncate`}
                                      >
                                        {step.processed_at && step.skipped
                                          ? 'Skipped On: '
                                          : null}
                                        {step.processed_at && !step.skipped
                                          ? 'Processed On: '
                                          : null}
                                        {formatTimestamp(
                                          step.processed_at
                                            ? step.processed_at
                                            : step.processing_date,
                                          currentAccount.timezone,
                                          false,
                                          'MMMM Do YYYY',
                                          true
                                        )}
                                        :
                                        <span
                                          className={`tw-ml-1 tw-font-normal tw-text-gray-${
                                            step.processed_at ? '300' : '500'
                                          }`}
                                        >
                                          {step.name}
                                        </span>
                                      </div>
                                      <div className="tw-flex">
                                        <div className="tw-flex tw-items-center tw-text-sm tw-leading-5 tw-text-gray-500">
                                          {step.badges.map(badge => {
                                            return (
                                              <Badge
                                                key={badge}
                                                color={
                                                  step.processed_at
                                                    ? 'gray'
                                                    : 'alpha'
                                                }
                                                badgeClass="tw-mr-2"
                                                hasDot={false}
                                                onClick={null}
                                                shape="round"
                                                size="small"
                                                textStyle={{}}
                                                value={badge}
                                              />
                                            );
                                          })}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="tw-ml-5 tw-flex-shrink-0">
                                    {step.processed_at ? null : (
                                      <svg
                                        className="tw-h-5 tw-w-5 tw-text-gray-400"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                          clipRule="evenodd"
                                        />
                                      </svg>
                                    )}
                                  </div>
                                </div>
                              </a>
                            </li>
                          );
                        })
                      : 'Loading...'}
                  </ul>
                </div>
              </Modal>
              <PauseAutomationModal
                currentContact={currentContact}
                currentAutomation={currentAutomation}
                showPauseAutomationModal={showPauseAutomationModal}
                setShowPauseAutomationModal={setShowPauseAutomationModal}
              />
              <Modal
                color="alpha"
                headerText="Resume Campaign?"
                primaryAction={updateAutomationUnpause}
                showHeaderIcon={false}
                primaryActionText="Resume"
                secondaryActionText="Cancel"
                setShow={setShowUnpauseAutomationModal}
                show={showUnpauseAutomationModal}
                showSecondaryAction
                canHandleClickOutside={false}
              >
                <div>
                  <p className="tw-font-medium">
                    Are you sure you want to unpause the campaign? The next
                    campaign step will pick up from where you paused.
                  </p>
                  <p className="tw-italic tw-text-gray-500">
                    For example, if the next campaign step was scheduled for two
                    days out when you paused, it will process two days after you
                    unpause.
                  </p>
                </div>
              </Modal>
              <Modal
                color="alpha"
                headerText="Remove Campaign?"
                bodyText={`You are about to remove ${currentContact.firstName} from ${currentAutomation.title}`}
                primaryAction={updateAutomationComplete}
                showHeaderIcon={false}
                primaryActionText="Remove"
                secondaryActionText="Cancel"
                setShow={setShowRemoveAutomationModal}
                show={showRemoveAutomationModal}
                showSecondaryAction
                canHandleClickOutside={false}
              />
              <Modal
                bodyClasses="tw-overflow-y-scroll"
                color="alpha"
                headerText="Add Campaign"
                innerContainerClasses="tw-px-4 tw-pt-5 tw-pb-4 sm:tw-p-6 sm:tw-pb-4"
                primaryAction={handleAutomationSubmit}
                primaryActionText="Confirm"
                secondaryActionText="Cancel"
                secondaryAction={handleModalSecondaryAction}
                setShow={setAddingAutomation}
                show={addingAutomation}
                showHeaderIcon={false}
                showSecondaryAction
              >
                <>
                  {currentAccount.mod_campaigns_enabled && (
                    <p>
                      Note: Only campaigns from products that have gone through
                      set up are able to be added to a contact.
                    </p>
                  )}
                  <div className="tw-my-3">
                    <Select
                      value={selectedCampaign}
                      id="selectedCampaign"
                      labelText="Choose Campaign"
                      onChange={handleCampaignSelect}
                      options={campaignSelectOptions}
                    />
                    <div className="tw-font-body tw-text-sm tw-leading-5 tw-text-gray-500">
                      {!isEmpty(addAutomationConfirmationMessage) ? (
                        <div className="tw-mt-5 tw-mb-8">
                          {addAutomationConfirmationMessage}
                        </div>
                      ) : null}
                      {willChangeLifecycle && newLifecycle === 'client' ? (
                        <div className="tw-mb-10">
                          <Select
                            value={markAsSale}
                            id="markAsSale"
                            labelText="Would you like to mark this as a sale?"
                            onChange={handleMarkAsSaleSelect}
                            options={[
                              {
                                displayName: 'Yes',
                                value: true
                              },
                              {
                                displayName: 'No',
                                value: false
                              }
                            ]}
                          />
                        </div>
                      ) : null}
                      {alreadyBeenInCampaign ? (
                        <div className="tw-mb-10">
                          <Select
                            value={selectedRestartCampaignOption}
                            id="restartCampaign"
                            labelText="Where would you like to restart this campaign?"
                            onChange={handleCampaignStartSelect}
                            options={[
                              {
                                displayName: 'Restart from Beginning',
                                value: 'restart_from_beginning'
                              },
                              {
                                displayName: 'Restart from Last Step',
                                value: 'restart_from_last_step'
                              },
                              {
                                displayName: 'Restart from Specific Step',
                                value: 'restart_from_specific_step'
                              }
                            ]}
                          />
                        </div>
                      ) : null}
                      {selectedRestartCampaignOption ===
                      'restart_from_specific_step' ? (
                        <div className="tw-mb-10">
                          <Select
                            value={selectedCampaignStep}
                            id="select_campaign_step"
                            labelText="Select a step to restart at."
                            onChange={handleCampaignStepSelect}
                            options={stepListOptions}
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </>
              </Modal>
              <Modal
                bodyClasses="tw-overflow-y-scroll"
                canHandleClickOutside={false}
                color="alpha"
                headerClasses=""
                headerText={lifecycleModalHeaderText}
                innerContainerClasses="tw-px-4 tw-pt-5 tw-pb-4 sm:tw-p-6 sm:tw-pb-4"
                primaryAction={handleLifecycleSubmit}
                primaryActionText="Next Step"
                secondaryActionText="Cancel"
                secondaryAction={handleLifecycleModalSecondaryAction}
                selectedStep={0}
                setShow={setChangingLifecycle}
                show={changingLifecycle}
                showHeaderIcon={false}
                showSecondaryAction
                steps={fetchLifecycleChangeModalSteps()}
                disableBulletClick
              />
              <Notification
                HeaderIcon={color => {
                  return notificationType === 'success' ? (
                    <svg
                      className={`tw-h-5 tw-w-5 tw-text-${color}-400`}
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  ) : (
                    <svg
                      className={`tw-h-5 tw-w-5 tw-text-${color}-400`}
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                        clipRule="evenodd"
                      />
                    </svg>
                  );
                }}
                color={notificationType}
                headerText={notificationHeader}
                message={notificationMessage}
                setShowAction={setShowNotification}
                show={showNotification}
                type="colored"
              />
            </div>
          </div>
          <Modal
            color="alpha"
            headerText="Convert All Profiles to New Design"
            showHeaderIcon={false}
            primaryAction={handleEnableNowContactProfile}
            primaryActionText="Yes, Convert Now!"
            showSecondaryAction
            secondaryAction={redirectToOldProfile}
            secondaryActionText="Cancel and Return"
            show={showEnableNewProfile}
            setShow={setShowEnableNewProfile}
          >
            <p>
              This change is permanent for all profiles! Once your account is
              converted, it can’t be undone.
            </p>
            <p>
              Clicking &lsquo;Cancel&rsquo; will return you to your original
              profile.
            </p>
            <p>Are you positive you want to convert to this new design?</p>
          </Modal>
        </Fragment>
      )}
    </Fragment>
  );
};

export default ContactProfile;
