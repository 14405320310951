import React from 'react';
import { withA11y } from '@storybook/addon-a11y';
import { withKnobs, select, boolean } from '@storybook/addon-knobs';
import Accordion from './index';

export default {
  component: Accordion,
  title: 'Accordion',
  decorators: [withA11y, withKnobs]
};

let colorSelect;
function withColorSelect(defaultValue) {
  const label = 'Color';
  const options = [
    'alpha',
    'bravo',
    'charlie',
    'success',
    'warning',
    'error',
    'gray'
  ];

  colorSelect = select(label, options, defaultValue);
}

export const SingleAccordion = () => {
  withColorSelect('alpha');
  return (
    <Accordion
      label="Accordion Label"
      color={colorSelect}
      coloredBg={boolean('Colored Background', false)}
      contentCentered={boolean('Center Align Content', false)}
    >
      This is going to be awesome!!
    </Accordion>
  );
};
