import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import ShowMore from 'components/Theme/ShowMore';
import Item from './Item';
import SkeletonItem from './SkeletonItem';

const SIZE = 3;
const SKELETON_ARRAY = [0, 1, 2, 3, 4];

const RenderItem = ({
  item,
  itemColor,
  actionColor,
  secondaryActionColor,
  lastItemIndex,
  showIcons,
  widthClass,
  tabName,
  enableShowMore,
  showMore
}) => {
  return (
    <div>
      <Item
        key={item.id}
        item={item}
        itemColor={itemColor}
        actionColor={actionColor}
        secondaryActionColor={secondaryActionColor}
        isLastItem={item.index === lastItemIndex}
        showIcons={showIcons}
        widthClass={widthClass}
        tabName={tabName}
        showMoreIndex={enableShowMore && SIZE - 1 > 0 ? SIZE - 1 : 0}
        showMoreOpened={showMore}
      />
    </div>
  );
};

const ActivityFeed = ({
  items,
  itemColor,
  actionColor,
  secondaryActionColor,
  containerStyle,
  showIcons,
  widthClass,
  tabName,
  isLoaded,
  hasFooter,
  FooterComponent,
  enableShowMore,
  showMoreProps
}) => {
  const [showMore, setShowMore] = useState(false);
  const lastItemIndex = items.length - 1;

  const RenderItems = () => {
    if (enableShowMore) {
      const sortedItems = items.slice().sort((a, b) => a.index - b.index);
      const firstXItems = sortedItems.slice(0, SIZE);
      const remainingItems = sortedItems.slice(SIZE);
      const { maxHeight } = showMoreProps;
      return (
        <Fragment>
          {firstXItems.map(item => (
            <RenderItem
              key={item.id}
              item={item}
              itemColor={itemColor}
              actionColor={actionColor}
              secondaryActionColor={secondaryActionColor}
              lastItemIndex={lastItemIndex}
              showIcons={showIcons}
              widthClass={widthClass}
              tabName={tabName}
              enableShowMore={enableShowMore}
              showMore={showMore}
            />
          ))}
          {remainingItems && remainingItems.length ? (
            <Fragment>
              <ShowMore
                maxHeight={maxHeight}
                show={showMore}
                setShow={setShowMore}
              >
                {remainingItems.map(item => (
                  <RenderItem
                    key={item.id}
                    item={item}
                    itemColor={itemColor}
                    actionColor={actionColor}
                    lastItemIndex={lastItemIndex}
                    showIcons={showIcons}
                    widthClass={widthClass}
                    tabName={tabName}
                    enableShowMore={enableShowMore}
                    showMore={showMore}
                  />
                ))}
              </ShowMore>
            </Fragment>
          ) : null}
        </Fragment>
      );
    }

    return isLoaded ? (
      items.map(item => (
        <RenderItem
          key={item.id}
          item={item}
          itemColor={itemColor}
          actionColor={actionColor}
          lastItemIndex={lastItemIndex}
          showIcons={showIcons}
          widthClass={widthClass}
          tabName={tabName}
          enableShowMore={enableShowMore}
          showMore={showMore}
        />
      ))
    ) : (
      <div className="tw-mt-6">
        {SKELETON_ARRAY.map(x => (
          <SkeletonItem
            key={x}
            isLastItem={x === SKELETON_ARRAY[SKELETON_ARRAY.length - 1]}
          />
        ))}
      </div>
    );
  };

  return (
    <div className={widthClass}>
      <div style={containerStyle}>
        <RenderItems />
      </div>
      {hasFooter ? (
        <div className="tw-mt-5" data-testid="footer-test-id">
          {typeof FooterComponent === 'function' ? (
            FooterComponent()
          ) : (
            <FooterComponent />
          )}
        </div>
      ) : null}
    </div>
  );
};

const colorPropType = PropTypes.oneOf([
  'alpha',
  'bravo',
  'charlie',
  'success',
  'warning',
  'error',
  'gray'
]);

const infoAlertShape = {
  message: PropTypes.string,
  type: PropTypes.oneOf(['standard', 'colored']),
  headerText: PropTypes.string,
  HeaderIcon: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  color: colorPropType
};

const statusObjectShape = {
  status: PropTypes.string,
  title: PropTypes.string,
  friendly_message: PropTypes.string,
  failed_code: PropTypes.string,
  failed_message: PropTypes.string,
  failed_link: PropTypes.string
};

const actionShape = {
  displayName: PropTypes.string,
  value: PropTypes.string
};

const itemShape = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  sourceable_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  index: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  headerText: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.string
  ]),
  bodyText: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.string
  ]),
  action: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  actionText: PropTypes.string,
  actions: PropTypes.arrayOf(PropTypes.shape(actionShape)),
  timestamp: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  isPinned: PropTypes.bool.isRequired,
  pinnedAction: PropTypes.func,
  infoAlert: PropTypes.shape(infoAlertShape),
  statusObject: PropTypes.shape(statusObjectShape)
});

const styleProps = PropTypes.objectOf(
  PropTypes.oneOfType([PropTypes.string, PropTypes.number])
);

const idIndexTypes = PropTypes.oneOfType([PropTypes.number, PropTypes.string]);

const renderItemShape = {
  id: idIndexTypes.isRequired,
  index: idIndexTypes.isRequired
};

const sharedDefaultProps = {
  itemColor: 'gray',
  actionColor: 'alpha',
  showIcons: false,
  widthClass: 'tw-w-full',
  tabName: 'feed',
  enableShowMore: false
};

RenderItem.defaultProps = {
  lasItemIndex: 0,
  showMore: false,
  ...sharedDefaultProps
};

RenderItem.propTypes = {
  item: PropTypes.shape(renderItemShape).isRequired,
  itemColor: colorPropType,
  actionColor: colorPropType,
  secondaryActionColor: colorPropType,
  lastItemIndex: PropTypes.number,
  showIcons: PropTypes.bool,
  widthClass: PropTypes.string,
  tabName: PropTypes.string,
  enableShowMore: PropTypes.bool,
  showMore: PropTypes.bool
};

ActivityFeed.defaultProps = {
  containerStyle: {},
  isLoaded: false,
  hasFooter: false,
  FooterComponent: () => {},
  showMoreProps: {},
  ...sharedDefaultProps
};

ActivityFeed.propTypes = {
  items: PropTypes.arrayOf(itemShape).isRequired,
  itemColor: colorPropType,
  actionColor: colorPropType,
  secondaryActionColor: colorPropType,
  containerStyle: styleProps,
  showIcons: PropTypes.bool,
  widthClass: PropTypes.string,
  isLoaded: PropTypes.bool,
  hasFooter: PropTypes.bool,
  tabName: PropTypes.string,
  FooterComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  enableShowMore: PropTypes.bool,
  showMoreProps: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.func,
      PropTypes.bool,
      PropTypes.string
    ])
  )
};

export default ActivityFeed;
