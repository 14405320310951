// linting rules disabled for immer js
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  CREATE_TASK_SUCCESS,
  UPDATE_TASK_SUCCESS,
  FETCH_TASK_SUCCESS
} from 'appState/actions/constants/task.actions';

const initialState = {
  currentTask: {}
};

export default function TaskReducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case CREATE_TASK_SUCCESS: {
        const { currentTask } = action.payload;
        draft.currentTask = currentTask;
        return;
      }
      case UPDATE_TASK_SUCCESS: {
        const { currentTask } = action.payload;
        draft.currentTask = currentTask;
        return;
      }
      case FETCH_TASK_SUCCESS: {
        const { currentTask } = action.payload;
        draft.currentTask = currentTask;
        return;
      }
      default:
        return draft;
    }
  });
}
