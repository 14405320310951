/* eslint-disable no-nested-ternary */
import React, { Fragment, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import get from 'lodash.get';
import isEmpty from 'lodash.isempty';
import {
  createErrorMessageSelector,
  createLoadedSelector
} from 'appState/selectors';
import {
  broadcastCallSaveEmailAction,
  broadcastDelete,
  broadcastUpdate
} from 'appState/actions/ActionCreators';
import getPathName from 'lib/utils/router';
import savedImg from 'images/broadcast/saved.png';
import DraftSavedModal from 'components/BroadcastCenter/Broadcast/DraftSavedModal';
import SaveAsDraftModal from 'components/BroadcastCenter/Broadcast/SaveAsDraftModal';
import Styled from './Footer.styles';

const Footer = props => {
  const { saveAction, sendingType, smsMediaLoaded } = props;
  const [modalTarget, setModalTarget] = useState('');
  const [noFiltersSelected, setNoFiltersSelected] = useState('');
  const [cancelBackModalTarget, setCancelBackModalTarget] = useState('');
  const [footerButtonClicked, setFooterButtonClicked] = useState('');
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);
  const [saveButtonText, setSaveButtonText] = useState('Save & Continue');
  const [modalSaveButtonClicked, setModalSaveButtonClicked] = useState(false);
  const [
    originalEmailSubjectInitialized,
    setOriginalEmailSubjectInitialized
  ] = useState(false);
  const originalEmailSubject = useRef();
  const history = useHistory();
  const { path } = useRouteMatch();
  const pathName = getPathName(path);
  const dispatch = useDispatch();

  const errorSelector = createErrorMessageSelector([
    'broadcastCenter/BROADCAST_SAVE_EMAIL'
  ]);
  const loadedSelector = createLoadedSelector([
    'broadcastCenter/BROADCAST_SAVE_EMAIL'
  ]);
  const broadcastContentSavedSelector = createLoadedSelector([
    'broadcastCenter/BROADCAST_UPDATE'
  ]);
  const broadcastAudienceContentSavedSelector = createLoadedSelector([
    'broadcastCenter/BROADCAST_UPDATE_AUDIENCE'
  ]);
  const broadcastEmailContentSavedSelector = createLoadedSelector([
    'broadcastCenter/BROADCAST_SAVE_EMAIL'
  ]);
  const broadcastSmsContentSavedSelector = createLoadedSelector([
    'broadcastCenter/BROADCAST_SAVE_SMS'
  ]);
  const broadcastScheduleContentSavedSelector = createLoadedSelector([
    'broadcastCenter/BROADCAST_SCHEDULE'
  ]);
  const structuredSelector = createStructuredSelector({
    isAutoSaving: state => state.broadcastCenter.isAutoSaving,
    emailSubject: state => state.broadcastCenter.emailSubject,
    currentBroadcast: state => state.broadcastCenter.currentBroadcast,
    audienceCount: state => state.broadcastCenter.audienceCount,
    isTemplate: state => state.broadcastCenter.isTemplate,
    hasContentChanged: state => state.broadcastCenter.hasContentChanged,
    hasBeeLoaded: state => state.broadcastCenter.hasBeeLoaded,
    selectedFilters: state => state.broadcastCenter.selectedFilters,
    selectedSuggestions: state => state.broadcastCenter.selectedSuggestions,
    isEmailSaving: state => state.broadcastCenter.isEmailSaving,
    isSmsSaving: state => state.broadcastCenter.isSmsSaving,
    error: state => errorSelector(state),
    isLoaded: state => loadedSelector(state),
    broadcastContentSaved: state => broadcastContentSavedSelector(state),
    broadcastAudienceContentSaved: state =>
      broadcastAudienceContentSavedSelector(state),
    broadcastEmailContentSaved: state =>
      broadcastEmailContentSavedSelector(state),
    broadcastSmsContentSaved: state => broadcastSmsContentSavedSelector(state),
    broadcastScheduleContentSaved: state =>
      broadcastScheduleContentSavedSelector(state)
  });

  const {
    isAutoSaving,
    emailSubject,
    currentBroadcast,
    audienceCount,
    isTemplate,
    hasContentChanged,
    hasBeeLoaded,
    selectedFilters,
    selectedSuggestions,
    isEmailSaving,
    isSmsSaving,
    error,
    isLoaded,
    broadcastContentSaved,
    broadcastAudienceContentSaved,
    broadcastEmailContentSaved,
    broadcastSmsContentSaved,
    broadcastScheduleContentSaved
  } = useSelector(structuredSelector);

  const broadcastOrTemplateUrl = `/ui/${
    isTemplate ? 'broadcast-templates' : 'broadcast-center'
  }`;
  const backButtonMappings = {
    'select-audience': `/ui/broadcast-center/${
      currentBroadcast.template_type === 'Email' ? 'email' : 'sms'
    }/select-template`,
    email: `${broadcastOrTemplateUrl}/${currentBroadcast.id}/select-audience`,
    sms: `${broadcastOrTemplateUrl}/${currentBroadcast.id}/select-audience`,
    schedule: `/ui/broadcast-center/${currentBroadcast.id}/${
      currentBroadcast.template_type === 'Email' ? 'email' : 'sms'
    }`,
    'save-details': `/ui/broadcast-templates/${currentBroadcast.id}/${
      currentBroadcast.template_type === 'Email' ? 'email' : 'sms'
    }`
  };

  function navigateToNextRoute() {
    if (footerButtonClicked === 'cancel') {
      history.push('/ui/broadcast-center');
    } else if (footerButtonClicked === 'back') {
      history.push(backButtonMappings[pathName]);
    }
  }

  function deleteBroadcast() {
    dispatch(broadcastDelete({ broadcastId: currentBroadcast.id }));
    navigateToNextRoute();
  }

  function deleteBroadcastDraft() {
    const isEmail = pathName === 'email';
    if (isEmail) {
      dispatch(
        broadcastUpdate({ broadcastId: currentBroadcast.id, deleteDraft: true })
      );
    }
    navigateToNextRoute();
  }

  function handleCancelButton() {
    setFooterButtonClicked('cancel');
    if (
      !get(currentBroadcast, 'has_been_edited') &&
      !hasContentChanged &&
      pathName !== 'select-template' &&
      !isTemplate
    ) {
      deleteBroadcast();
    }
    if (cancelBackModalTarget === '') history.push('/ui/broadcast-center');
  }

  function handleBackButton() {
    setFooterButtonClicked('back');
    if (
      !get(currentBroadcast, 'has_been_edited') &&
      !hasContentChanged &&
      pathName === 'select-audience' &&
      !isTemplate
    ) {
      deleteBroadcast();
    }
    if (cancelBackModalTarget === '')
      history.push(backButtonMappings[pathName]);
  }

  function onClickSave(
    e,
    overrideNavigation = false,
    _saveButtonClicked = false
  ) {
    if (e) e.preventDefault();
    setModalSaveButtonClicked(!_saveButtonClicked);
    const isEmail = pathName === 'email';
    if (isEmail && isEmpty(modalTarget)) {
      dispatch(
        broadcastCallSaveEmailAction(overrideNavigation && !_saveButtonClicked)
      );
    }
    if (saveAction && !isEmail)
      saveAction(overrideNavigation && !_saveButtonClicked);
  }

  function handleSaveClicked(e) {
    if (isSaveButtonDisabled || noFiltersSelected) {
      return null;
    }
    return onClickSave(e, false, true);
  }

  useEffect(() => {
    let saveText = '';

    if (pathName === 'save-details') {
      saveText = 'Save Template';
    } else if (sendingType === 'schedule') {
      saveText = 'Schedule Broadcast';
    } else if (sendingType === 'send_now') {
      saveText = 'Send Broadcast';
    } else if (pathName === 'schedule') {
      saveText = 'Send Broadcast';
    } else if (pathName === 'email' && isEmailSaving) {
      saveText = 'Saving...';
    } else if (pathName === 'sms' && isSmsSaving) {
      saveText = 'Saving...';
    } else {
      saveText = 'Save & Continue';
    }

    setSaveButtonText(saveText);
  }, [pathName, sendingType, isEmailSaving, isSmsSaving]);

  useEffect(() => {
    setIsSaveButtonDisabled(
      (pathName === 'schedule' && audienceCount === 0 && !isTemplate) ||
        (pathName === 'email' && (!hasBeeLoaded || isEmailSaving)) ||
        (pathName === 'sms' && (!smsMediaLoaded || isSmsSaving))
    );
  }, [
    audienceCount,
    pathName,
    isTemplate,
    smsMediaLoaded,
    hasBeeLoaded,
    isSmsSaving,
    isEmailSaving
  ]);

  useEffect(() => {
    if (
      currentBroadcast &&
      currentBroadcast.email_subject &&
      !originalEmailSubjectInitialized
    ) {
      originalEmailSubject.current = currentBroadcast.email_subject;
      setOriginalEmailSubjectInitialized(true);
    }
  }, [currentBroadcast]);

  useEffect(() => {
    // navigate to next route if broadcast is saved via modal save button
    // rather than footer save button, and wait for the content to save prior to navigating
    if (
      modalSaveButtonClicked &&
      (broadcastContentSaved ||
        broadcastAudienceContentSaved ||
        broadcastEmailContentSaved ||
        broadcastSmsContentSaved ||
        broadcastScheduleContentSaved)
    )
      navigateToNextRoute();
  }, [
    modalSaveButtonClicked,
    broadcastContentSaved,
    broadcastAudienceContentSaved,
    broadcastEmailContentSaved,
    broadcastSmsContentSaved,
    broadcastScheduleContentSaved
  ]);

  useEffect(() => {
    if (pathName !== 'email') {
      return;
    }
    if (
      !isEmpty(currentBroadcast.email_subject) &&
      (emailSubject === originalEmailSubject.current ||
        (emailSubject && emailSubject.trim() === ''))
    ) {
      setModalTarget('#email-subject-modal');
    } else {
      setModalTarget('');
    }
  }, [originalEmailSubject, emailSubject, currentBroadcast.email_subject]);

  useEffect(() => {
    if (pathName !== 'schedule') {
      return;
    }
    if (!isEmpty(sendingType) && !isTemplate) {
      setModalTarget('#send-schedule-confirmation-modal');
    } else {
      setModalTarget('');
    }
  }, [sendingType]);

  useEffect(() => {
    if (pathName !== 'select-audience') {
      return;
    }
    if (isEmpty(selectedFilters) && isEmpty(selectedSuggestions)) {
      setNoFiltersSelected(true);
      setModalTarget('#audience-error-alert-modal');
    } else {
      setNoFiltersSelected(false);
      setModalTarget('');
    }
  }, [selectedFilters, selectedSuggestions]);

  useEffect(() => {
    if (currentBroadcast && currentBroadcast.has_been_edited) {
      if (hasContentChanged) {
        setCancelBackModalTarget('#draft-was-saved-modal');
      } else {
        setCancelBackModalTarget('');
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (hasContentChanged) {
        setCancelBackModalTarget('#save-as-draft-modal');
      } else {
        setCancelBackModalTarget('');
      }
    }
  }, [hasContentChanged]);

  return (
    <Fragment>
      <Styled.Footer>
        <div className="row">
          <div className="col-12">
            <div className="card mt-0 mb-0">
              <div className="card-body shadow-1 ">
                <div className="d-flex justify-content-end">
                  {pathName === 'email' && (
                    <div id="save-indicator" className="ml-20 flex-grow-1">
                      <div
                        id="auto-save-indicator-saved"
                        className={
                          !error && isLoaded && isAutoSaving
                            ? 'd-block'
                            : 'd-none'
                        }
                      >
                        <img src={savedImg} alt="" width="15" height="20" />
                        <span className="ml-2 fs-16 align-sub ">
                          Email Autosaved
                        </span>
                      </div>
                    </div>
                  )}

                  <a
                    className="mr-10 btn btn-danger"
                    id="close-broadcast-content-btn"
                    href="#"
                    style={{ color: 'white' }}
                    data-provide="cancel tooltip"
                    data-target={cancelBackModalTarget}
                    data-toggle="modal"
                    title="Back to Broadcast Center"
                    onClick={handleCancelButton}
                  >
                    Cancel
                  </a>

                  {pathName !== 'select-template' && (
                    <a
                      className="mr-10 btn btn-secondary"
                      id="broadcast-back-btn"
                      href="#"
                      data-provide="back tooltip"
                      data-target={cancelBackModalTarget}
                      data-toggle="modal"
                      title="Go Back"
                      onClick={handleBackButton}
                    >
                      Back
                    </a>
                  )}

                  {pathName !== 'select-template' ? (
                    <button
                      className={`mr-10 d-flex align-items-center btn btn-${
                        isTemplate ? 'purple' : 'info'
                      }`}
                      id="broadcast-save-continue-button"
                      href="#"
                      type="submit"
                      data-target={modalTarget}
                      data-toggle="modal"
                      onClick={e => handleSaveClicked(e)}
                      disabled={isSaveButtonDisabled}
                    >
                      {(pathName === 'email' && isEmailSaving) ||
                        (pathName === 'sms' && isSmsSaving && (
                          <div
                            className="spinner-circle mr-2"
                            style={{
                              width: '16px',
                              height: '16px',
                              borderColor: 'white',
                              borderTopColor: 'transparent'
                            }}
                          />
                        ))}
                      {saveButtonText}
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Styled.Footer>

      <DraftSavedModal
        saveAction={() => onClickSave(null, true)}
        deleteAction={deleteBroadcastDraft}
      />
      <SaveAsDraftModal
        saveAction={() => onClickSave(null, true)}
        deleteAction={deleteBroadcast}
      />
    </Fragment>
  );
};

Footer.defaultProps = {
  saveAction: null,
  sendingType: null,
  smsMediaLoaded: true
};

Footer.propTypes = {
  saveAction: PropTypes.func,
  sendingType: PropTypes.oneOf(['send_now', 'schedule', 'save_as_template']),
  smsMediaLoaded: PropTypes.bool
};

export default Footer;
