import { Controller } from 'stimulus';
import { FETCH_DEFAULT_HEADERS } from 'controllers/constants';

export default class extends Controller {
  static targets = ['wrapper', 'pagination'];

  refresh(event) {
    event.stopPropagation();
    const refresh_url = this.wrapperTarget.dataset.refreshUrl;
    fetch(refresh_url, {
      method: 'GET',
      headers: FETCH_DEFAULT_HEADERS,
      credentials: 'same-origin'
    })
      .then(response => response.json())
      .then(json => {
        this.wrapperTarget.innerHTML = json.notifications;
        this.paginationTarget.dataset.nextUrl = json.next_page_url;
        this.paginationTarget.dataset.lastPage = json.last_page;
        this.paginationTarget.dataset.totalPages = json.total_pages;
        if (
          document.querySelectorAll('.top-bar-notifications.media-new').length >
          0
        ) {
          document
            .getElementById('top-bar-notification-icon')
            .classList.add('has-new');
        }
      })
      .catch(function(error) {
        console.error(error);
      });
  }

  markAsRead(event) {
    event.stopPropagation();
    const markAsReadUrl = this.paginationTarget.dataset.markAsReadUrl;
    fetch(markAsReadUrl, {
      method: 'PUT',
      headers: FETCH_DEFAULT_HEADERS,
      credentials: 'same-origin'
    })
      .then(() => {
        Array.from(document.querySelectorAll('.top-bar-notifications')).forEach(
          function(el) {
            el.classList.remove('media-new');
          }
        );
        document
          .getElementById('top-bar-notification-icon')
          .classList.remove('has-new');
      })
      .catch(function(error) {
        console.error(error);
      });
  }
}
