import React from 'react';
import { FileIcon, defaultStyles } from 'react-file-icon';

const FileIcons = ({ acceptedFile }) => {
  return (
    <div className="tw-col-span-1">
      <FileIcon extension={acceptedFile.path ? acceptedFile.path.split('.')[1] : ''} {...defaultStyles.docx} />
      <p>{acceptedFile.path}</p>
    </div>
  );
};

export default FileIcons;
