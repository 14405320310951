import React, { useEffect, useContext } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import isEmpty from 'lodash.isempty';
import {
  fetchEmailActivities,
  createEmailCommunication,
  resetIsLoaded,
  fetchContactFormTypes,
  fetchAppointmentTypes,
  fetchBlogTypes,
  flashErrorMessage,
  flashSuccessMessage
} from 'appState/actions/ActionCreators';
import {
  createErrorMessageSelector,
  createLoadingSelector,
  createLoadedSelector
} from 'appState/selectors';
import { emailSchema } from 'lib/validation/schema';
import { CREATE_EMAIL } from 'appState/actions/constants/contact.actions';
import { CurrentAccountContext } from '../../../../shared/context/CurrentAccountContext';
import Button from '../../../../Theme/Button';
import DraftEditor from '../../../../Theme/DraftEditor';
import TextInput from '../../../../Theme/TextInput';

const EmailEditor = () => {
  const dispatch = useDispatch();

  const currentAccount = useContext(CurrentAccountContext);
  const customDropDownData = {};

  const createEmailErrorSelector = createErrorMessageSelector([CREATE_EMAIL]);
  const createEmailLoadingSelector = createLoadingSelector([CREATE_EMAIL]);
  const createEmailLoadedSelector = createLoadedSelector([CREATE_EMAIL]);

  const structuredSelector = createStructuredSelector({
    currentContact: state => state.contact.currentContact,
    createEmailLoading: state => createEmailLoadingSelector(state),
    createEmailLoaded: state => createEmailLoadedSelector(state),
    createEmailError: state => createEmailErrorSelector(state),
    lastEmailSuccessMessage: state => state.contact.lastEmailSuccessMessage,
    contactFormTypes: state => state.contactFormType.contactFormTypes,
    appointmentTypes: state => state.contact.appointmentTypes,
    blogTypes: state => state.blog.blogTypes
  });

  const {
    currentContact,
    createEmailLoading,
    createEmailLoaded,
    createEmailError,
    lastEmailSuccessMessage,
    contactFormTypes,
    appointmentTypes,
    blogTypes
  } = useSelector(structuredSelector);

  function getAnchorLink(id, subUrl) {
    return `https://${currentAccount.full_domain}/${subUrl}/${id}?token=${currentContact.token}`;
  }

  const contactFormTypeOptions = contactFormTypes?.map(formType => {
    return {
      key: formType.id,
      value: formType.name,
      text: formType.name,
      url: getAnchorLink(formType.id, 'client_forms')
    };
  });

  const appointmentTypeOptions = appointmentTypes?.map(apptType => {
    return {
      key: apptType.id,
      value: apptType.name,
      text: apptType.name,
      url: getAnchorLink(apptType.id, 'client_bookings')
    };
  });

  const blogTypeOptions = blogTypes?.map(blogType => {
    return {
      key: blogType.id,
      value: blogType.title,
      text: blogType.title,
      url: getAnchorLink(blogType.id, 'content')
    };
  });

  const handleCustomDropDownData = (dataKey, dataOption) => {
    if (dataOption.length) {
      customDropDownData[dataKey] = dataOption;
    }
  };

  if (contactFormTypeOptions) {
    handleCustomDropDownData('contactFormTypeOptions', contactFormTypeOptions);
  }

  if (appointmentTypeOptions) {
    handleCustomDropDownData('appointmentTypeOptions', appointmentTypeOptions);
  }

  if (blogTypeOptions) {
    handleCustomDropDownData('blogTypeOptions', blogTypeOptions);
  }

  function handleSubmit(values) {
    const emailParams = values;
    emailParams.contactId = currentContact.id;
    return dispatch(createEmailCommunication(emailParams));
  }

  const formik = useFormik({
    initialValues: {
      emailSubject: '',
      emailBody: ''
    },
    validationSchema: emailSchema,
    onSubmit: handleSubmit
  });

  const handleEmailBodyChange = textValue => {
    formik.setFieldValue('emailBody', textValue);
  };

  useEffect(() => {
    if (createEmailLoaded) {
      if (isEmpty(createEmailError)) {
        dispatch(
          fetchEmailActivities({
            accountId: currentAccount.id,
            contactId: currentContact.id
          })
        );

        formik.resetForm();

        dispatch(flashSuccessMessage(lastEmailSuccessMessage));
      } else dispatch(flashErrorMessage(createEmailError));
    }

    dispatch(resetIsLoaded(CREATE_EMAIL));

    return () => {
      dispatch(resetIsLoaded(CREATE_EMAIL));
    };
  }, [createEmailLoaded, createEmailError, dispatch]);

  useEffect(() => {
    if (isEmpty(contactFormTypes))
      dispatch(fetchContactFormTypes({ accountId: currentAccount.id }));
  }, [dispatch, currentAccount.id, contactFormTypes]);

  useEffect(() => {
    if (isEmpty(appointmentTypes))
      dispatch(fetchAppointmentTypes({ accountId: currentAccount.id }));
  }, [dispatch, currentAccount.id, appointmentTypes]);

  useEffect(() => {
    dispatch(fetchBlogTypes());
  }, [dispatch]);

  return (
    <div className="tw-container tw-mx-auto tw-pt-6">
      <div className="tw-py-5">
        <div className="tw-col-span-12 sm:tw-col-span-6 tw-relative tw-bottom-5">
          <div className="tw-mt-3 tw-relative">
            <TextInput
              id="emailSubject"
              labelText="Email Subject"
              placeholder="Enter Subject"
              value={formik.values.emailSubject}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              showError={
                formik.touched.emailSubject && !!formik.errors.emailSubject
              }
              error={formik.errors.emailSubject}
              widthClass="tw-col-span-12"
              required
            />
          </div>
        </div>
        <div className="tw-col-span-12 sm:tw-col-span-6">
          <DraftEditor
            editorStateString={formik.values.emailBody}
            handleEditorTextVal={handleEmailBodyChange}
            customDropDownData={customDropDownData}
            customDropDownName={['Form Link', 'Appointment Link', 'Blog Link']}
            labelText="Email Body"
            onBlur={formik.handleBlur}
            showError={formik.touched.emailBody && !!formik.errors.emailBody}
            error={formik.errors.emailBody}
          />
        </div>
        <div className="tw-relative tw-mt-4 ">
          <Button
            buttonRef={null}
            color="alpha"
            containerStyle={{
              marginBottom: '5px',
              float: 'right'
            }}
            disabled={false}
            isLoaded={!createEmailLoading}
            onClick={formik.handleSubmit}
            text="Send Email"
            type="primary"
          />
        </div>
      </div>
    </div>
  );
};

export default EmailEditor;
