// linting rules disabled for immer js
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import isEmpty from 'lodash.isempty';
import {
  FETCH_AVAILABLE_PHONE_NUMBERS_REQUEST,
  FETCH_AVAILABLE_PHONE_NUMBERS_SUCCESS,
  PURCHASE_NUMBER_FOR_ACCOUNT_REQUEST,
  ASSIGN_NUMBER_FOR_ACCOUNT_REQUEST,
  ASSIGN_NUMBER_FOR_ACCOUNT_SUCCESS,
  CAMPAIGN_PAUSER_PAUSE_REQUEST,
  CAMPAIGN_PAUSER_PAUSE_SUCCESS,
  CAMPAIGN_PAUSER_UNPAUSE_REQUEST,
  CAMPAIGN_PAUSER_UNPAUSE_SUCCESS,
  ACCOUNT_DISABLER_DISABLE_REQUEST,
  ACCOUNT_DISABLER_DISABLE_SUCCESS,
  ACCOUNT_ENABLER_ENABLE_REQUEST,
  ACCOUNT_ENABLER_ENABLE_SUCCESS,
  RECAPTCHA_DISABLER_DISABLE_SUCCESS,
  RECAPTCHA_ENABLER_ENABLE_SUCCESS,
  SET_RECAPTCHA_DISABLED,
  BULK_JOURNEY_CHANGER_REQUEST,
  JOURNEYS_FETCH_REQUEST,
  JOURNEYS_FETCH_SUCCESS,
  CUSTOM_GROUP_ASSIGNER_REQUEST,
  CUSTOM_GROUPS_FETCH_SUCCESS,
  USERS_FETCH_SUCCESS,
  SUBDOMAIN_CHANGER_REQUEST,
  FETCH_SUBDOMAINS_REQUEST,
  FETCH_SUBDOMAINS_SUCCESS,
  CONTACTS_FETCH_SUCCESS,
  BULK_CAMPAIGN_CHANGER_REQUEST,
  CAMPAIGNS_FETCH_SUCCESS,
  REASSIGN_USER_OBJECTS_REQUEST,
  CLONE_USER_AVAILABILITY_REQUEST,
  FETCH_RECENT_ACTIVITY_SUCCESS,
  FETCH_PROCESSING_ACTIVITIES_SUCCESS,
  START_FETCH_PROCESSING_WATCHER,
  STOP_FETCH_PROCESSING_WATCHER,
  ONBOARDING_DISABLER_DISABLE_REQUEST,
  ONBOARDING_ENABLER_ENABLE_REQUEST,
  ONBOARDING_DISABLER_DISABLE_SUCCESS,
  ONBOARDING_ENABLER_ENABLE_SUCCESS,
  CUSTOM_GROUPS_CREATE_SUCCESS
} from 'appState/actions/constants/overlord.actions';

const initialState = {
  availableNumbers: [],
  journeys: [],
  users: [],
  customGroups: [],
  contacts: [],
  previousSubdomains: [],
  recentActivities: [],
  processingActivities: [],
  isPollingProcessingActivities: false,
  isCampaignPaused: null,
  isAccountDisabled: null,
  isRecaptchaDisabled: null,
  campaigns: [],
  isOnboardingDisabled: null,
  accountPhoneNumber: ''
};

export default function OverlordUtilitiesReducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case FETCH_AVAILABLE_PHONE_NUMBERS_REQUEST: {
        const { areaCode } = action.payload;
        draft.areaCode = areaCode;
        return;
      }
      case FETCH_AVAILABLE_PHONE_NUMBERS_SUCCESS: {
        const availableNumbers = action.payload;
        draft.availableNumbers = availableNumbers;
        return;
      }
      case PURCHASE_NUMBER_FOR_ACCOUNT_REQUEST: {
        const { phoneNumber } = action.payload;
        draft.phoneNumber = phoneNumber;
        return;
      }
      case ASSIGN_NUMBER_FOR_ACCOUNT_REQUEST: {
        return;
      }
      case ASSIGN_NUMBER_FOR_ACCOUNT_SUCCESS: {
        const { accountPhoneNumber } = action.payload;
        draft.accountPhoneNumber = accountPhoneNumber;
        return;
      }
      case CAMPAIGN_PAUSER_PAUSE_REQUEST: {
        return;
      }
      case CAMPAIGN_PAUSER_PAUSE_SUCCESS: {
        draft.isCampaignPaused = true;
        return;
      }
      case CAMPAIGN_PAUSER_UNPAUSE_REQUEST: {
        return;
      }
      case CAMPAIGN_PAUSER_UNPAUSE_SUCCESS: {
        draft.isCampaignPaused = false;
        return;
      }
      case BULK_JOURNEY_CHANGER_REQUEST: {
        const { journeyId, file } = action.payload;
        draft.journeyId = journeyId;
        draft.file = file;
        return;
      }
      case JOURNEYS_FETCH_REQUEST: {
        return;
      }
      case JOURNEYS_FETCH_SUCCESS: {
        draft.journeys = action.payload;
        return;
      }
      case CUSTOM_GROUP_ASSIGNER_REQUEST: {
        const { customGroupIds, file } = action.payload;
        draft.customGroupIds = customGroupIds;
        draft.file = file;
        return;
      }
      case CUSTOM_GROUPS_FETCH_SUCCESS: {
        draft.customGroups = action.payload;
        return;
      }
      case CUSTOM_GROUPS_CREATE_SUCCESS: {
        const { newCustomGroups } = action.payload;
        draft.newCustomGroups = newCustomGroups;
        draft.customGroups = [...draft.customGroups, ...newCustomGroups];
        return;
      }
      case ACCOUNT_DISABLER_DISABLE_REQUEST: {
        return;
      }
      case ACCOUNT_DISABLER_DISABLE_SUCCESS: {
        draft.isAccountDisabled = true;
        return;
      }
      case ACCOUNT_ENABLER_ENABLE_REQUEST: {
        return;
      }
      case ACCOUNT_ENABLER_ENABLE_SUCCESS: {
        draft.isAccountDisabled = false;
        return;
      }
      case RECAPTCHA_DISABLER_DISABLE_SUCCESS: {
        draft.isRecaptchaDisabled = true;
        return;
      }
      case RECAPTCHA_ENABLER_ENABLE_SUCCESS: {
        draft.isRecaptchaDisabled = false;
        return;
      }
      case USERS_FETCH_SUCCESS: {
        draft.users = action.payload;
        return;
      }
      case SUBDOMAIN_CHANGER_REQUEST: {
        const { newSubdomain } = action.payload;
        draft.subdomain = newSubdomain;
        return;
      }
      case FETCH_SUBDOMAINS_REQUEST: {
        return;
      }
      case FETCH_SUBDOMAINS_SUCCESS: {
        if (!isEmpty(action.payload)) draft.previousSubdomains = action.payload;
        return;
      }
      case CONTACTS_FETCH_SUCCESS: {
        draft.contacts = action.payload;
        return;
      }
      case BULK_CAMPAIGN_CHANGER_REQUEST: {
        const { journeyId, file } = action.payload;
        draft.journeyId = journeyId;
        draft.file = file;
        return;
      }
      case CAMPAIGNS_FETCH_SUCCESS: {
        draft.campaigns = action.payload;
        return;
      }
      case REASSIGN_USER_OBJECTS_REQUEST: {
        const {
          toUserIds,
          fromUserId,
          objects,
          selectedLifecycles
        } = action.payload;
        draft.toUserIds = toUserIds;
        draft.fromUserId = fromUserId;
        draft.objects = objects;
        draft.selectedLifecycles = selectedLifecycles;
        return;
      }
      case CLONE_USER_AVAILABILITY_REQUEST: {
        const { toUserId, fromUserId, cloneMethod } = action.payload;
        draft.toUserId = toUserId;
        draft.fromUserId = fromUserId;
        draft.cloneMethod = cloneMethod;
        return;
      }
      case FETCH_RECENT_ACTIVITY_SUCCESS: {
        draft.recentActivities = action.payload;
        return;
      }
      case FETCH_PROCESSING_ACTIVITIES_SUCCESS: {
        if (!isEmpty(action.payload))
          draft.processingActivities = action.payload;
        return;
      }
      case START_FETCH_PROCESSING_WATCHER: {
        draft.isPollingProcessingActivities = true;
        return;
      }
      case STOP_FETCH_PROCESSING_WATCHER: {
        draft.isPollingProcessingActivities = false;
        return;
      }
      case ONBOARDING_DISABLER_DISABLE_REQUEST: {
        return;
      }
      case ONBOARDING_DISABLER_DISABLE_SUCCESS: {
        draft.isOnboardingDisabled = true;
        return;
      }
      case ONBOARDING_ENABLER_ENABLE_REQUEST: {
        return;
      }
      case ONBOARDING_ENABLER_ENABLE_SUCCESS: {
        draft.isOnboardingDisabled = false;
        return;
      }
      case SET_RECAPTCHA_DISABLED: {
        draft.isRecaptchaDisabled = action.payload;
        return;
      }
      default:
        return draft;
    }
  });
}
