import React from 'react';
import Icon from '../Icon';
import iconPropTypes from '../Icon/iconPropTypes';
import iconDefaultProps from '../Icon/iconDefaultProps';

const HeroIcon = ({
  color,
  tint,
  filled,
  onClick,
  width,
  height,
  icon,
  strokeWidth,
  pathDefinition
}) => {
  return (
    <Icon
      color={color}
      tint={tint}
      onClick={onClick}
      filled={filled}
      width={width}
      height={height}
      icon={icon}
      strokeWidth={strokeWidth}
      pathDefinition={pathDefinition}
    />
  );
};

HeroIcon.defaultProps = iconDefaultProps;
HeroIcon.propTypes = iconPropTypes;

export default HeroIcon;
