import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import SelectAudienceBySkeletonItem from '../SelectAudienceBySkeletonItem';

const SelectAudienceBySkeletonItems = props => {
  const { count } = props;

  return (
    <Fragment>
      {[...Array(count).keys()].map(i => {
        return <SelectAudienceBySkeletonItem key={i} />;
      })}
    </Fragment>
  );
};

SelectAudienceBySkeletonItems.defaultProps = {
  count: 4
};

SelectAudienceBySkeletonItems.propTypes = { count: PropTypes.number };

export default SelectAudienceBySkeletonItems;
