import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { broadcastDelete } from 'appState/actions/ActionCreators';
import { createStructuredSelector } from 'reselect';
import isEmpty from 'lodash.isempty';
import {
  createErrorMessageSelector,
  createLoadedSelector
} from 'appState/selectors';
import { capitalize } from 'lib/utils/string';

const DeleteModal = () => {
  const [deleteActionCalled, setDeleteActionCalled] = useState(false);
  const dispatch = useDispatch();

  const errorSelector = createErrorMessageSelector([
    'broadcastCenter/BROADCAST_DELETE'
  ]);
  const loadedSelector = createLoadedSelector([
    'broadcastCenter/BROADCAST_DELETE'
  ]);
  const structuredSelector = createStructuredSelector({
    broadcastId: state => state.broadcastCenter.selectedBroadcastId,
    error: state => errorSelector(state),
    isLoaded: state => loadedSelector(state)
  });

  const { broadcastId, error, isLoaded } = useSelector(structuredSelector);

  useEffect(() => {
    if (isLoaded && isEmpty(error) && deleteActionCalled) {
      app.toast('Your broadcast has been deleted.', {
        type: 'primary',
        icon: 'fa-floppy-o'
      });
    }
    return () => {
      setDeleteActionCalled(false);
    };
  }, [isLoaded, error]);

  useEffect(() => {
    if (isLoaded && !isEmpty(error) && deleteActionCalled) {
      app.toast(capitalize(error), {
        type: 'danger',
        icon: 'fa-floppy-o'
      });
    }
    return () => {
      setDeleteActionCalled(false);
    };
  }, [isLoaded, error]);

  const deleteBroadcast = () => {
    dispatch(broadcastDelete({ broadcastId }));
    setDeleteActionCalled(true);
  };

  return (
    <div
      className="modal fade show"
      id="delete-template-modal"
      tabIndex="-1"
      aria-modal="true"
      aria-hidden="true"
      style={{ display: 'none' }}
    >
      <div className="modal-dialog modal-sm">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="myModalLabel">
              Delete Broadcast
            </h4>
            <button type="button" className="close" data-dismiss="modal">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <p>Are you sure you want to delete this broadcast?</p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-danger"
              id="delete-template-confirmation-button"
              data-dismiss="modal"
              onClick={() => deleteBroadcast()}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
