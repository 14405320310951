import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { fetchContactForm } from 'appState/actions/ActionCreators';
import Modal from 'components/Theme/Modal';
import DocumentAddIcon from 'components/Theme/Icon/DocumentAddIcon';
import UserIcon from 'components/Theme/Icon/UserIcon';
import { capitalize } from 'lib/utils/string';

const FormPreviewModal = ({ formId, show, setShow, onCloseCallback }) => {
  const dispatch = useDispatch();

  const structuredSelector = createStructuredSelector({
    contact: state => state.contact.currentContact,
    form: state => state.activity.currentForm
  });

  const { contact, form } = useSelector(structuredSelector);

  function handleClose() {
    setShow(false);
    if (onCloseCallback) onCloseCallback();
  }

  useEffect(() => {
    setShow(show);
  }, [show, setShow]);

  useEffect(() => {
    if (show && formId) dispatch(fetchContactForm({ formId }));
  }, [show, formId, dispatch]);

  return (
    <Modal
      color="alpha"
      headerText={form?.name || 'Form Preview'}
      setShow={setShow}
      show={show}
      showPrimaryAction={false}
      secondaryAction={handleClose}
      secondaryActionText="Close"
      showSecondaryAction
      showHeaderIcon={false}
      headerClasses="tw-px-4"
      innerContainerClasses="tw-px-0 tw-pt-5 tw-pb-4"
      bodyClasses="tw-px-8 tw-py-4"
      showDivider
    >
      <div className="tw-grid tw-grid-cols-12 tw-gap-6">
        <div className="tw-col-span-12">
          <div className="tw-text-sm tw-font-medium tw-text-gray-500">
            <div className="tw-flex">
              <div className="tw-flex-shrink-0 tw-mr-1">
                <UserIcon color="gray" tint="500" />
              </div>
              <div className="tw-flex-1 tw-leading-5">Contact Information</div>
            </div>
          </div>
          <div className="tw-ml-6">
            <div className="tw-text-sm tw-font-medium tw-text-gray-700 tw-mt-2">
              {contact?.fullName}
            </div>
            <div className="tw-text-sm tw-text-gray-500">
              {capitalize(contact?.currentLifecycle)} /{' '}
              {contact?.currentProductCampaign}
            </div>
          </div>
        </div>
        <div className="tw-col-span-12">
          <div className="tw-text-sm tw-font-medium tw-text-gray-500">
            <div className="tw-flex">
              <div className="tw-flex-shrink-0 tw-mr-1">
                <DocumentAddIcon color="gray" tint="500" />
              </div>
              <div className="tw-flex-1 tw-leading-5">Form Q &amp; A</div>
            </div>
          </div>
          <div className="tw-ml-6">
            {form &&
              form.questions_answers &&
              Object.keys(form.questions_answers).map(qaKey => {
                return (
                  <div key={qaKey} className="tw-my-2">
                    <div className="tw-text-sm tw-font-medium tw-text-gray-700 tw-block">
                      {form.questions_answers[qaKey].question}
                    </div>
                    <div className="tw-text-sm tw-text-gray-500 tw-block">
                      {form.questions_answers[qaKey].answer}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </Modal>
  );
};

FormPreviewModal.defaultProps = {
  formId: '',
  show: true,
  setShow: () => null,
  onCloseCallback: () => null
};

FormPreviewModal.propTypes = {
  formId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  show: PropTypes.bool,
  setShow: PropTypes.func,
  onCloseCallback: PropTypes.func
};

export default FormPreviewModal;
