import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { broadcastEditScheduledBroadcast } from 'appState/actions/ActionCreators';
import { createStructuredSelector } from 'reselect';
import isEmpty from 'lodash.isempty';
import {
  createErrorMessageSelector,
  createLoadedSelector
} from 'appState/selectors';
import { capitalize } from 'lib/utils/string';

const EditScheduledBroadcastModal = () => {
  const [
    EditScheduledBroadcastActionCalled,
    setEditScheduledBroadcastActionCalled
  ] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const errorSelector = createErrorMessageSelector([
    'broadcastCenter/BROADCAST_EDIT_SCHEDULED_BROADCAST'
  ]);
  const loadedSelector = createLoadedSelector([
    'broadcastCenter/BROADCAST_EDIT_SCHEDULED_BROADCAST'
  ]);
  const structuredSelector = createStructuredSelector({
    broadcastId: state => state.broadcastCenter.selectedBroadcastId,
    error: state => errorSelector(state),
    isLoaded: state => loadedSelector(state)
  });

  const { broadcastId, error, isLoaded } = useSelector(structuredSelector);

  useEffect(() => {
    if (isLoaded && isEmpty(error) && EditScheduledBroadcastActionCalled) {
      app.toast(
        'Your broadcast has been unscheduled and set back as a draft.',
        {
          type: 'primary',
          icon: 'fa-floppy-o'
        }
      );
    }
    return () => {
      setEditScheduledBroadcastActionCalled(false);
    };
  }, [isLoaded, error]);

  useEffect(() => {
    if (isLoaded && !isEmpty(error) && EditScheduledBroadcastActionCalled) {
      app.toast(capitalize(error), {
        type: 'danger',
        icon: 'fa-floppy-o'
      });
    }
    return () => {
      setEditScheduledBroadcastActionCalled(false);
    };
  }, [isLoaded, error]);

  const handleEditScheduledBroadcastButton = () => {
    dispatch(broadcastEditScheduledBroadcast({ broadcastId, history }));
    setEditScheduledBroadcastActionCalled(true);
  };

  return (
    <div
      className="modal fade show"
      id="edit-scheduled-broadcast-confirmation-modal"
      tabIndex="-1"
      aria-modal="true"
      aria-hidden="true"
      style={{ display: 'none' }}
    >
      <div className="modal-dialog modal-md">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="myModalLabel">
              Edit Scheduled Broadcast?
            </h4>
            <button type="button" className="close" data-dismiss="modal">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <p>
              To ensure this broadcast can be edited safely, it will be
              unscheduled and marked as a draft. Make sure to complete the
              entire flow to reschedule your broadcast.
            </p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-danger"
              id="delete-template-confirmation-button"
              data-dismiss="modal"
              onClick={() => handleEditScheduledBroadcastButton()}
            >
              Unschedule
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditScheduledBroadcastModal;
