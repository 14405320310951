import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import SkeletonTemplateCard from '../SkeletonTemplateCard';

const SkeletonTemplateCards = props => {
  const { count } = props;

  return (
    <Fragment>
      {[...Array(count).keys()].map(i => {
        return <SkeletonTemplateCard key={i} />;
      })}
    </Fragment>
  );
};

SkeletonTemplateCards.defaultProps = {
  count: 10
};

SkeletonTemplateCards.propTypes = { count: PropTypes.number };

export default SkeletonTemplateCards;
