import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import isEmpty from 'lodash.isempty';
import {
  createErrorMessageSelector,
  createLoadedSelector
} from 'appState/selectors';
import {
  deactivateOnboarding,
  activateOnboarding,
  resetIsLoaded
} from 'appState/actions/ActionCreators';
import { titleize } from 'lib/utils/string';
import UtilityCard from 'components/OverlordUtilities/shared/UtilityCard';
import { CurrentAccountContext } from 'components/shared/context/CurrentAccountContext';
import useProcessingActivity from 'components/shared/hooks/useProcessingActivity';
import useOnboardingDisabled from 'components/shared/hooks/useOnboardingDisabled';

const UTILITY_NAMES = [
  'account_onboarding_activate',
  'account_onboarding_deactivate'
];

const OnboardingDisabler = () => {
  const currentAccount = useContext(CurrentAccountContext);
  const processingActivity = useProcessingActivity(UTILITY_NAMES);
  const onboardingDisabled = useOnboardingDisabled();

  const errorSelector = createErrorMessageSelector([
    'overlord/ONBOARDING_DISABLER_DISABLE',
    'overlord/ONBOARDING_ENABLER_ENABLE'
  ]);
  const loadedSelector = createLoadedSelector([
    'overlord/ONBOARDING_DISABLER_DISABLE',
    'overlord/ONBOARDING_ENABLER_ENABLE'
  ]);
  const structuredSelector = createStructuredSelector({
    error: state => errorSelector(state),
    isLoaded: state => loadedSelector(state)
  });

  const { error, isLoaded } = useSelector(structuredSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoaded && isEmpty(error)) {
      app.toast(
        `Successfully ${
          onboardingDisabled ? 'activated' : 'deactivated'
        } onboarding for ${titleize(currentAccount.name)} account`,
        {
          type: 'primary',
          icon: 'fa-floppy-o'
        }
      );
    }
    return () => {
      dispatch(
        resetIsLoaded(
          onboardingDisabled
            ? 'overlord/ONBOARDING_DISABLER_DISABLE'
            : 'overlord/ONBOARDING_ENABLER_ENABLE'
        )
      );
    };
  }, [onboardingDisabled, currentAccount.name, isLoaded, error, dispatch]);

  const getCardBody = () => {
    return (
      <p>
        {onboardingDisabled
          ? 'Place account back in Onboarding mode'
          : 'Turn off Onboarding mode'}
        .
      </p>
    );
  };

  const disableEnableOnboarding = () => {
    if (onboardingDisabled) return dispatch(activateOnboarding());
    return dispatch(deactivateOnboarding());
  };

  return (
    <UtilityCard
      title={
        onboardingDisabled ? 'Onboarding Activator' : 'Onboarding Deactivator'
      }
      subtitle="Use this tool to deactivate/activate onboarding for an account."
      submitBtnText={
        onboardingDisabled ? 'Activate Onboarding' : 'Deactivate Onboarding'
      }
      submitBtnAction={disableEnableOnboarding}
      bodyComponent={getCardBody}
      processingActivity={processingActivity}
      error={error}
    />
  );
};

export default OnboardingDisabler;
