import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Sms from './BroadcastTemplate/Sms';
import FourOFour from '../FourOFour';

const BroadcastCenter = () => {
  const { path } = useRouteMatch();
  return (
    <div>
      <Switch>
        <Route path={`${path}/:id/sms`} component={Sms} />
        <Route component={FourOFour} />
      </Switch>
    </div>
  );
};

export default BroadcastCenter;
