import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import get from 'lodash.get';
import { CurrentUserContext } from 'components/shared/context/CurrentUserContext';
import { fetchRecentActivity } from 'appState/actions/ActionCreators';
import { capitalize, parseUnderscores } from 'lib/utils/string';
import { formatTimestamp } from 'lib/utils/dateTime';
import isEmpty from 'lodash.isempty';

const RecentActivities = () => {
  const currentUser = useContext(CurrentUserContext);
  const userTimeZone = currentUser.account_time_zone;

  const structuredSelector = createStructuredSelector({
    recentActivities: state => state.overlordUtilities.recentActivities
  });

  const { recentActivities } = useSelector(structuredSelector);

  const dispatch = useDispatch();
  useEffect(() => {
    if (isEmpty(recentActivities)) dispatch(fetchRecentActivity());
  }, [dispatch]);

  const STATUS_BADGE_CLASS = {
    processing: 'badge badge-info',
    processed: 'badge badge-success',
    error: 'badge badge-danger'
  };

  const activityList = recentActivities.map(activity => {
    return (
      <tr key={activity.id}>
        <td>
          <span>{parseUnderscores(activity.utility)}</span>
        </td>
        <td>
          <div className={STATUS_BADGE_CLASS[activity.status]}>
            <span>{capitalize(activity.status)}</span>
          </div>
        </td>
        <td data-sort={activity.updated_at}>
          <span>{formatTimestamp(activity.updated_at, userTimeZone)}</span>
        </td>
        <td>
          <span>{activity.metadata.current_user_full_name}</span>
        </td>
        <td>
          <span>{get(activity, 'error_message.message') || 'N/A'}</span>
        </td>
      </tr>
    );
  });

  return (
    <div className="tab-pane active" id="recent-activity" role="tabpanel">
      <div className="card">
        <header className="card-header">
          <h4 className="card-title mb-0">
            Recent Activities
            <small className="sidetitle">
              View a history of overlord utility actions.
            </small>
          </h4>
        </header>
        <div className="card-body p-25">
          {get(recentActivities, 'length') ? (
            <div>
              <table
                id="overlord-datatable"
                className="table table-hover"
                data-provide="datatables"
              >
                <thead>
                  <tr>
                    <th>Activity</th>
                    <th>Status</th>
                    <th>Date</th>
                    <th>Performed By</th>
                    <th>Error Message</th>
                  </tr>
                </thead>
                <tbody>{activityList}</tbody>
              </table>
            </div>
          ) : (
            <div className="spinner-dots spinner-info">
              <span className="dot1" />
              <span className="dot2" />
              <span className="dot3" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RecentActivities;
