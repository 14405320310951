import React, { useContext, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
  broadcastTemplatesCreateBroadcast,
  broadcastTemplateSetSelected
} from 'appState/actions/ActionCreators';
import isEmpty from 'lodash.isempty';
import get from 'lodash.get';
import loadingGif from 'images/broadcast/loading.gif';
import { textTruncate } from 'lib/utils/string';
import { readURLOriginalRatio } from 'lib/utils/image';
import { CurrentUserContext } from 'components/shared/context/CurrentUserContext';
import { buildAudienceShortSummary } from 'lib/utils/audienceSummary';
import templatePreviewFallback from 'images/broadcast/email-template.png';
import Styled from './TemplateCard.styles';

const TemplateCard = props => {
  const { template } = props;
  const imagePreviewEl = useRef();
  const currentUser = useContext(CurrentUserContext);
  const dispatch = useDispatch();
  const history = useHistory();

  function selectTemplate() {
    dispatch(
      broadcastTemplatesCreateBroadcast({
        broadcastTemplateId: template.id,
        currentUserId: currentUser.id,
        history
      })
    );
  }

  function setSelectedTemplate() {
    dispatch(broadcastTemplateSetSelected(template));
  }

  function handleEditTemplateButton() {
    history.push(`/ui/broadcast-templates/${template.id}/select-audience`);
  }

  // random upper case letter between A-W
  const randomLetter = () =>
    String.fromCharCode(65 + Math.floor(Math.random() * 23));

  function randomInitials() {
    return `${randomLetter()}${randomLetter()}`;
  }

  const smsMedia = get(template, 'media_urls[0].url');

  function loadMediaPreview(url) {
    fetch(url)
      .then(res => {
        return res.blob();
      })
      .then(res => {
        if (res) {
          readURLOriginalRatio(res, imagePreviewEl);
        }
      });
  }

  useEffect(() => {
    if (template.media_urls && template.media_urls.length > 0)
      loadMediaPreview(smsMedia);
  }, [template.media_urls]);

  return (
    <div className="col-md-12 col-lg-6 col-xxxl-4">
      <Styled.Card
        className={`card ${
          template.title && template.title.toUpperCase().includes('COVID')
            ? 'covid-card'
            : ''
        }`}
      >
        <header className="card-header">
          <h4 className="card-title truncate-text mb-0">
            <strong>{template.title || 'No Title'}</strong>
          </h4>
          {template.owner_type === 'account' ? (
            <ul className="card-controls">
              <li className="dropdown">
                <a
                  data-toggle="dropdown"
                  href="#"
                  onClick={setSelectedTemplate}
                >
                  <i className="ti-more-alt rotate-90" />
                </a>
                <div className="dropdown-menu dropdown-menu-right">
                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={handleEditTemplateButton}
                  >
                    <i className="fa fa-fw fa-pencil" />
                    Edit
                  </a>
                  {currentUser?.overlord && (
                    <a
                      className="dropdown-item"
                      href="#"
                      data-toggle="modal"
                      data-target="#reassign-template-modal"
                    >
                      <i className="fa fa-fw fa-random" />
                      Reassign
                    </a>
                  )}
                  <a
                    className="dropdown-item"
                    data-toggle="modal"
                    data-target="#delete-template-modal"
                    href="#"
                  >
                    <i className="fa fa-fw fa-trash" /> Delete
                  </a>
                </div>
              </li>
            </ul>
          ) : null}
        </header>
        <div className="card-content">
          <div className="card-body" style={{ overflowY: 'hidden' }}>
            <div className="d-flex">
              <div className="col-sm-7" style={{ height: '266px' }}>
                <Styled.Phone>
                  <Styled.Screen>
                    {template.template_type === 'Email' ? (
                      <img
                        src={
                          template.thumbnail && template.thumbnail.url
                            ? template.thumbnail.url
                            : templatePreviewFallback
                        }
                        alt="Broadcast Template Preview"
                        width="200"
                      />
                    ) : (
                      <div
                        className="d-flex flex-column"
                        style={{ height: '100%' }}
                      >
                        <div className="full-width flex-grow">
                          <div id="chat-content">
                            <div className="media media-chat media-chat-reverse pr-0 pl-20">
                              <div className="media-body">
                                <p
                                  style={{
                                    wordBreak: 'break-word'
                                  }}
                                >
                                  {isEmpty(template.sms_body) &&
                                  isEmpty(smsMedia)
                                    ? 'Template has no saved sms body. Update template to add an sms body...'
                                    : textTruncate(template.sms_body)}
                                  {/* MMS Media Preview */}
                                  <span
                                    className={`${
                                      template &&
                                      template.media_urls &&
                                      smsMedia
                                        ? 'd-block'
                                        : 'd-none'
                                    }`}
                                    style={{
                                      maxWidth: '200px',
                                      maxHeight: '200px',
                                      position: 'relative',
                                      overflow: 'hidden'
                                    }}
                                  >
                                    <img
                                      alt="Preview MMS"
                                      ref={imagePreviewEl}
                                      src={loadingGif}
                                    />
                                  </span>
                                </p>
                              </div>
                            </div>
                            <div className="media media-chat pl-0">
                              <a
                                id="full-chat-window-contact-info"
                                className="avatar"
                              >
                                {randomInitials()}
                              </a>
                              <div className="media-body">
                                <p>...</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Styled.Screen>
                  <Styled.Speaker />
                  <Styled.Home />
                </Styled.Phone>
              </div>
              <div className="col-sm-5 d-none d-sm-block">
                {template.description && template.description.length && (
                  <div>
                    <h6>Description</h6>
                    <p>{textTruncate(template.description)}</p>
                  </div>
                )}
                <h6>Audience Summary</h6>
                <p>
                  {buildAudienceShortSummary(template)}{' '}
                  {template.select_audience_by !== 'everyone' && (
                    <a
                      className="text-info"
                      style={{ borderBottom: '1px dotted' }}
                      data-toggle="modal"
                      data-target="#audience-summary-modal"
                      href="#"
                      onClick={setSelectedTemplate}
                    >
                      View More
                    </a>
                  )}
                </p>
              </div>
            </div>
          </div>
          <footer
            className={`card-footer text-right ${
              template.title && template.title.toUpperCase().includes('COVID')
                ? 'covid-footer'
                : ''
            }`}
            style={{ borderRadius: '0 0 10px 10px' }}
          >
            {template.select_audience_by !== 'everyone' && (
              <a
                className="btn btn-flat btn-secondary d-inline-block d-sm-none"
                data-toggle="modal"
                data-target="#audience-summary-modal"
                href="#"
                onClick={setSelectedTemplate}
              >
                View Details
              </a>
            )}
            <a
              className="btn btn-flat btn-info new-broadcast-button"
              href="#"
              onClick={selectTemplate}
            >
              Use Template
            </a>
          </footer>
        </div>
      </Styled.Card>
    </div>
  );
};

const templateShape = {
  title: PropTypes.string,
  description: PropTypes.string,
  thumbnail: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  )
};

TemplateCard.propTypes = {
  template: PropTypes.shape(templateShape).isRequired
};

export default TemplateCard;
