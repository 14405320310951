import React from 'react';

const HeaderContent = () => {
  return (
    <div>
      <table className="tw-w-64">
        <thead className="tw-border tw-border-b tw-border-t-0 tw-border-l-0 tw-border-r-0 tw-border-solid tw-border-gray-200">
          <tr className="tw-bg-gray-100">
            <th className="tw-py-2 tw-pl-4 tw-font-medium">
              UPLAUNCH LIFECYCLE
            </th>
            <th className="tw-py-2 tw-font-medium">ZEN PLANNER STATUS</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="tw-pl-4 tw-py-2 tw-text-gray-500">Lead</td>
            <td className="tw-pl-4 tw-py-2 tw-text-gray-500">Prospect</td>
          </tr>
          <tr>
            <td className="tw-pl-4 tw-py-2 tw-text-gray-500">Client</td>
            <td className="tw-pl-4 tw-py-2 tw-text-gray-500">Member</td>
          </tr>
          <tr>
            <td className="tw-pl-4 tw-py-2 tw-text-gray-500">Former Client</td>
            <td className="tw-pl-4 tw-py-2 tw-text-gray-500">Alumni</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default HeaderContent;
