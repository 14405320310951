import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { CurrentUserContext } from 'components/shared/context/CurrentUserContext';
import { textTruncate } from 'lib/utils/string';
import { formatTimestamp } from 'lib/utils/dateTime';
import { smsInboxFetchConversation } from 'appState/actions/ActionCreators';
import Badge from 'components/Theme/Badge';

const ConversationListItem = props => {
  const dispatch = useDispatch();
  const { conversation, isActive, mobile, selectConversation } = props;
  const unsubscribedConversation = !conversation.subscribed_to_sms;
  const hasUnreadMessages = conversation.unread_count > 0;
  const currentUser = useContext(CurrentUserContext);
  const userTimeZone = currentUser.account_time_zone;

  function handleMessageListItemClick() {
    selectConversation(conversation.id, mobile);
    dispatch(smsInboxFetchConversation({ contactId: conversation.id }));
  }

  return (
    <li className="tw-border-solid tw-border-b tw-border-t-0 tw-border-l-0 tw-border-r-0 tw-border-gray-100">
      <a
        href="#"
        data-testid={`conversation_${conversation.id}`}
        onClick={handleMessageListItemClick}
        className={`tw-block ${
          isActive ? 'tw-bg-gray-100' : null
        } hover:tw-bg-gray-100 focus:tw-outline-none focus:tw-bg-gray-100 tw-transition tw-duration-150 tw-ease-in-out`}
      >
        <div className="tw-flex tw-items-center tw-px-4 tw-py-4">
          <div className="tw-min-w-0 tw-flex-1 tw-flex tw-items-center">
            <div className="tw-min-w-0 tw-flex-1 tw-pl-4">
              <div>
                <div className="tw-text-sm tw-leading-5 tw-font-medium tw-text-gray-700">
                  {textTruncate(conversation.name, 18)}
                  {unsubscribedConversation ? (
                    <Badge
                      badgeStyle={{ backgroundColor: '#F56565' }}
                      clickable={false}
                      color="error"
                      containerStyle={{ marginLeft: '4px' }}
                      deletable={false}
                      hasDot={false}
                      onClick={null}
                      onDelete={null}
                      shape="rectangle"
                      size="small"
                      textStyle={{ color: 'white' }}
                      value="U"
                    />
                  ) : null}
                </div>
                <div className="tw-pt-2 tw-flex tw-items-center tw-text-sm tw-leading-5 tw-text-gray-500">
                  <span className="">
                    {`${
                      conversation.last_message
                        ? textTruncate(conversation.last_message)
                        : conversation.media_count
                    } - ${formatTimestamp(
                      conversation.last_message_timestamp,
                      userTimeZone,
                      false,
                      'MMM Do',
                      true,
                      true
                    )}`}
                  </span>
                </div>
              </div>
            </div>
            <div className="tw-self-start">
              <div>
                {hasUnreadMessages ? (
                  <Badge
                    badgeStyle={{}}
                    clickable={false}
                    color="alpha"
                    containerStyle={{}}
                    deletable={false}
                    hasDot={false}
                    onClick={null}
                    onDelete={null}
                    size="small"
                    textStyle={{}}
                    value={conversation.unread_count.toString()}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </a>
    </li>
  );
};

const conversationShape = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string,
  assigned_staff_id: PropTypes.number,
  media_count: PropTypes.number,
  last_message: PropTypes.string,
  last_message_timestamp: PropTypes.string,
  unread_count: PropTypes.number,
  subscribed: PropTypes.bool,
  subscribed_to_sms: PropTypes.bool,
  phone_number_valid_for_sms: PropTypes.bool
};

ConversationListItem.defaultProps = {
  isActive: false,
  mobile: false
};

ConversationListItem.propTypes = {
  conversation: PropTypes.shape(conversationShape).isRequired,
  selectConversation: PropTypes.func.isRequired,
  mobile: PropTypes.bool,
  isActive: PropTypes.bool
};

export default ConversationListItem;
