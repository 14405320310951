import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  createErrorMessageSelector,
  createLoadingSelector,
  createLoadedSelector
} from 'appState/selectors';
import {
  fetchPinnedNotesActivities,
  fetchNotesActivities,
  notesActivitiesPageChange,
  createContactNote,
  resetIsLoaded
} from 'appState/actions/ActionCreators';
import { FETCH_NOTES_ACTIVITIES } from 'appState/actions/constants/activity.actions';
import { CREATE_CONTACT_NOTE } from 'appState/actions/constants/contact.actions';
import isEmpty from 'lodash.isempty';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import Button from 'components/Theme/Button';
import TextArea from 'components/Theme/TextArea';
import Notification from 'components/Theme/Notification';
import PinnedActivities from 'components/Contact/Profile/PinnedActivities';
import RecentActivities from 'components/Contact/Profile/RecentActivities';
import { CurrentAccountContext } from 'components/shared/context/CurrentAccountContext';
import { noteSchema } from 'lib/validation/schema';

const NotesTab = ({ contactId }) => {
  const dispatch = useDispatch();
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const currentAccount = useContext(CurrentAccountContext);

  const notesActivitiesLoadedSelector = createLoadedSelector([
    FETCH_NOTES_ACTIVITIES
  ]);

  const errorSelector = createErrorMessageSelector([CREATE_CONTACT_NOTE]);
  const loadingSelector = createLoadingSelector([CREATE_CONTACT_NOTE]);
  const loadedSelector = createLoadedSelector([CREATE_CONTACT_NOTE]);

  const structuredSelector = createStructuredSelector({
    currentContact: state => state.contact.currentContact,
    pinnedNotesActivities: state => state.activity.pinnedNotesActivities,
    notesActivities: state => state.activity.notesActivities,
    notesTotal: state => state.activity.notesTotal,
    notesTotalPages: state => state.activity.notesTotalPages,
    limit: state => state.activity.limit,
    error: state => errorSelector(state),
    isLoading: state => loadingSelector(state),
    isLoaded: state => loadedSelector(state),
    notesActivitiesIsLoaded: state => notesActivitiesLoadedSelector(state)
  });

  const {
    currentContact,
    pinnedNotesActivities,
    notesActivities,
    notesTotal,
    notesTotalPages,
    limit,
    error,
    isLoading,
    isLoaded,
    notesActivitiesIsLoaded
  } = useSelector(structuredSelector);

  function handleSetShowSuccess(show) {
    setShowSuccess(show);
  }

  function handleSetShowError(show) {
    setShowError(show);
  }

  function handleSubmit(values) {
    const noteParams = values;
    noteParams.contactId = contactId;
    return dispatch(createContactNote(noteParams));
  }

  const formik = useFormik({
    initialValues: {
      note: ''
    },
    enableReinitialize: true,
    validationSchema: noteSchema,
    onSubmit: values => {
      handleSubmit(values);
    }
  });

  useEffect(() => {
    if (isLoaded && isEmpty(error)) {
      setShowSuccess(true);
      if (!currentContact.dummy)
        dispatch(
          fetchNotesActivities({
            contactId: currentContact.id
          })
        );
      formik.setFieldValue('note', '', false);
    }

    if (isLoaded && !isEmpty(error)) {
      setShowError(true);
    }

    dispatch(resetIsLoaded(CREATE_CONTACT_NOTE));

    return () => {
      dispatch(resetIsLoaded(CREATE_CONTACT_NOTE));
    };
  }, [error, isLoaded, dispatch]);

  useEffect(() => {
    if (isEmpty(pinnedNotesActivities) && !isEmpty(currentContact))
      dispatch(
        fetchPinnedNotesActivities({
          contactId: currentContact.id
        })
      );
  }, [dispatch, currentContact]);

  useEffect(() => {
    if (isEmpty(notesActivities) && !isEmpty(currentContact))
      dispatch(
        fetchNotesActivities({
          contactId: currentContact.id
        })
      );
  }, [dispatch, currentContact]);

  return (
    <span>
      <div className="tw-py-5">
        <div className="tw-grid tw-grid-cols-12 tw-gap-6">
          <TextArea
            id="note"
            rows={6}
            value={formik.values.note}
            labelText="Note"
            hideLabel
            placeholder="Create Note"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            showError={formik.touched.note && !!formik.errors.note}
            error={formik.errors.note}
            widthClass="tw-col-span-12"
          />

          <div className="tw-col-span-12">
            <Button
              buttonRef={null}
              buttonStyle={{}}
              color="alpha"
              containerStyle={{
                marginBottom: '5px',
                float: 'right'
              }}
              disabled={false}
              disabledStyle={{}}
              disabledTitleStyle={{}}
              isLoaded={!isLoading}
              onClick={formik.handleSubmit}
              text="Create Note"
              titleStyle={{}}
              type="primary"
            />
          </div>

          <div className="tw-border-t tw-border-b-0 tw-border-l-0 tw-border-r-0 tw-border-solid tw-border-gray-200 tw-col-span-12 tw-col-span-12" />

          <div className="tw-col-span-12 tw-px-4">
            <PinnedActivities
              tabName="notes"
              activities={pinnedNotesActivities}
            />
            <RecentActivities
              activities={notesActivities}
              total={notesTotal}
              totalPages={notesTotalPages}
              limit={limit}
              currentContact={currentContact}
              currentAccount={currentAccount}
              isLoaded={notesActivitiesIsLoaded}
              resetAction={FETCH_NOTES_ACTIVITIES}
              pageChangeAction={notesActivitiesPageChange}
            />
          </div>
        </div>
      </div>

      <Notification
        message="Note not created"
        show={showError}
        setShowAction={handleSetShowError}
        type="colored"
        headerText="Error!"
        color="error"
        HeaderIcon={color => {
          return (
            <svg
              className={`tw-h-5 tw-w-5 tw-text-${color}-400`}
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                clipRule="evenodd"
              />
            </svg>
          );
        }}
      />

      <Notification
        message="Note created"
        show={showSuccess}
        setShowAction={handleSetShowSuccess}
        type="colored"
        headerText="Success!"
        color="success"
        HeaderIcon={color => {
          return (
            <svg
              className={`tw-h-5 tw-w-5 tw-text-${color}-400`}
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          );
        }}
      />
    </span>
  );
};

NotesTab.defaultProps = { contactId: null };
NotesTab.propTypes = {
  contactId: PropTypes.string
};

export default NotesTab;
