/* eslint-disable react/jsx-props-no-spreading */
import React, { Fragment } from 'react';
import iconPropTypes from './iconPropTypes';
import iconDefaultProps from './iconDefaultProps';
import iconNames from '../HeroIcon/iconNames';

const Icon = ({
  color,
  tint,
  filled,
  pathDefinition,
  onClick,
  width,
  height,
  IconComponent,
  icon,
  strokeWidth
}) => {
  const filledSvgProps = {
    fill: 'currentColor',
    viewBox: '0 0 20 20'
  };

  const outlinedSvgProps = {
    fill: 'none',
    viewBox: '0 0 24 24',
    stroke: 'currentColor'
  };

  const filledPathProps = {
    fillRule: 'evenodd',
    clipRule: 'evenodd'
  };

  const outlinedPathProps = {
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeWidth
  };

  const svgProps = filled ? filledSvgProps : outlinedSvgProps;
  const pathProps = filled ? filledPathProps : outlinedPathProps;
  const setTint = color !== 'white' ? `-${tint}` : '';
  const paths = icon ? iconNames[icon] : [];

  return (
    <Fragment>
      {IconComponent ? (
        <IconComponent />
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          data-testid={icon}
          onClick={onClick}
          className={`tw-h-${height} tw-w-${width} tw-text-${color}${setTint}`}
          {...svgProps}
        >
          {pathDefinition && !icon ? (
            <path d={pathDefinition} {...pathProps} />
          ) : (
            paths.map(p => <path d={p} key={p} {...pathProps} />)
          )}
        </svg>
      )}
    </Fragment>
  );
};

Icon.defaultProps = iconDefaultProps;
Icon.propTypes = iconPropTypes;

export default Icon;
