export function readURLCrop(file, _imageRef) {
  const imageRef = _imageRef;
  const reader = new FileReader();
  if (file) {
    // addListeners(reader);
    if (file.type && file.type.match('image')) {
      reader.onload = function fileLoaded(e) {
        const img = new Image();
        img.onload = function imgLoaded() {
          // Scale image to canvas size (100x100) and crop whichever is larger between width/height
          const canvasSize = 100;
          const sourceWidth = this.width;
          const sourceHeight = this.height;
          const scaleX = sourceWidth / canvasSize;
          const scaleY = sourceHeight / canvasSize;
          const smallerScale = scaleX < scaleY ? scaleX : scaleY;
          const scaledSourceWidth = sourceWidth / smallerScale;
          const scaledSourceHeight = sourceHeight / smallerScale;
          if (imageRef && imageRef.current) {
            imageRef.current.style.width = `${scaledSourceWidth}px`;
            imageRef.current.style.height = `${scaledSourceHeight}px`;
            imageRef.current.style.maxWidth = `${scaledSourceWidth}px`;
            imageRef.current.style.maxHeight = `${scaledSourceHeight}px`;
            imageRef.current.setAttribute('src', e.target.result);
          }
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    } else if (file.type && file.type.match('video')) {
      reader.onload = function fileLoaded() {
        const blob = new Blob([reader.result], { type: file.type });
        const url = URL.createObjectURL(blob);
        const video = document.createElement('video');

        const snapImage = function snapImage() {
          const canvas = document.createElement('canvas');
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          canvas
            .getContext('2d')
            .drawImage(video, 0, 0, canvas.width, canvas.height);
          const image = canvas.toDataURL();
          const success = image.length > 100000;
          if (success) {
            const canvasSize = 100;
            const sourceWidth = canvas.width;
            const sourceHeight = canvas.height;
            const scaleX = sourceWidth / canvasSize;
            const scaleY = sourceHeight / canvasSize;
            const smallerScale = scaleX < scaleY ? scaleX : scaleY;
            const scaledSourceWidth = sourceWidth / smallerScale;
            const scaledSourceHeight = sourceHeight / smallerScale;
            if (imageRef && imageRef.current) {
              imageRef.current.style.width = `${scaledSourceWidth}px`;
              imageRef.current.style.height = `${scaledSourceHeight}px`;
              imageRef.current.style.maxWidth = `${scaledSourceWidth}px`;
              imageRef.current.style.maxHeight = `${scaledSourceHeight}px`;
              imageRef.current.setAttribute('src', image);
            }
          }
          return success;
        };

        const timeupdate = function timeUpdate() {
          if (snapImage()) {
            video.removeEventListener('timeupdate', timeupdate);
            video.pause();
          }
        };
        video.addEventListener('loadeddata', function loadedVideo() {
          if (snapImage()) {
            video.removeEventListener('timeupdate', timeupdate);
          }
        });

        video.addEventListener('timeupdate', timeupdate);
        video.preload = 'metadata';
        video.src = url;
        // Load video in Safari / IE11
        video.muted = true;
        video.playsInline = true;
        video.play();
      };
      reader.readAsArrayBuffer(file);
    }
  }
  return imageRef;
}

export function readURLOriginalRatio(file, _imageRef) {
  const imageRef = _imageRef;
  if (file) {
    const reader = new FileReader();
    if (file.type && file.type.match('image')) {
      reader.onload = function fileLoaded(e) {
        const img = new Image();
        img.onload = function imgLoaded() {
          if (imageRef && imageRef.current) {
            imageRef.current.style.width = this.width;
            imageRef.current.style.height = this.height;
            imageRef.current.setAttribute('src', e.target.result);
          }
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    } else if (file.type && file.type.match('video')) {
      reader.onload = function fileLoaded() {
        const blob = new Blob([reader.result], { type: file.type });
        const url = URL.createObjectURL(blob);
        const video = document.createElement('video');

        const snapImage = function snapImage() {
          const canvas = document.createElement('canvas');
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          canvas
            .getContext('2d')
            .drawImage(video, 0, 0, canvas.width, canvas.height);
          const image = canvas.toDataURL();
          const success = image.length > 100000;
          if (success && image && imageRef.current) {
            imageRef.current.setAttribute('src', image);
          }
          return success;
        };

        const timeupdate = function timeUpdate() {
          if (snapImage()) {
            video.removeEventListener('timeupdate', timeupdate);
            video.pause();
          }
        };
        video.addEventListener('loadeddata', function loadedVideo() {
          if (snapImage()) {
            video.removeEventListener('timeupdate', timeupdate);
          }
        });

        video.addEventListener('timeupdate', timeupdate);
        video.preload = 'metadata';
        video.src = url;
        // Load video in Safari / IE11
        video.muted = true;
        video.playsInline = true;
        video.play();
      };
      reader.readAsArrayBuffer(file);
    }
  }
}
