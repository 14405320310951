import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Theme/Button';
import Badge from 'components/Theme/Badge';
import DowngradePlan from 'components/Subscriptions/DowngradePlan';
import TwoStepUpgrade from 'components/Stripe/TwoStepUpgrade';
import HeroIcon from 'components/Theme/HeroIcon';

const PlanDetail = ({
  plan,
  currentPlan,
  productSubscriptions,
  currentTimezone,
  mySubscription
}) => {
  const isCurrenPlan = plan.id === currentPlan.id;
  const heroIconColor = isCurrenPlan ? 'success' : 'gray';
  const heroTint = isCurrenPlan ? '400' : '200';

  const borderClass = plan.highest_plan
    ? 'tw-border-solid tw-border-alpha-500'
    : '';

  const getPriceItem = (
    textLabel,
    price,
    textColorClassName = '',
    textLineClass = ''
  ) => {
    return (
      <div className={`tw-mb-5 tw-mt-5 ${textColorClassName}`}>
        <p className="tw-font-body tw-font-medium tw-mb-1 tw-leading-5">
          {textLabel}
        </p>
        <span className={`tw-font-bold tw-text-xl ${textLineClass}`}>
          {price}
        </span>
        <span>/mo</span>
      </div>
    );
  };

  const getPlanPrice = () => {
    if (
      isCurrenPlan &&
      mySubscription?.stripe_subscription_amount <
        plan?.pricing_information?.price
    ) {
      return (
        <>
          {getPriceItem(
            'Regular',
            plan?.pricing_information?.price_with_currency,
            'tw-text-gray-400',
            'tw-line-through'
          )}
          {getPriceItem(
            'Your discounted rate',
            `$${mySubscription?.stripe_subscription_amount}`,
            'tw-text-success-400'
          )}
        </>
      );
    }
    return getPriceItem(
      'Price',
      plan?.pricing_information?.price_with_currency
    );
  };
  return (
    <div
      className={`tw-rounded-lg tw-bg-white tw-flex tw-flex-col tw-shadow-lg ${borderClass}`}
    >
      {plan.highest_plan ? (
        <div className="tw-mx-auto tw--mt-3">
          <Badge
            color="success"
            shape="round"
            size="large"
            value="Best Value"
            bgTint={400}
            txTint={100}
          />
        </div>
      ) : (
        <div className="tw-mx-auto tw-mt-3" />
      )}
      <div className="tw-align-middle tw-inline-block tw-min-w-full tw-rounded-lg tw-border-b tw-border-gray-200">
        <div className="tw-px-4 tw-py-4 tw-border-b tw-border-t-0 tw-border-l-0 tw-border-r-0 tw-border-solid tw-border-gray-200 sm:tw-px-6">
          <div className="tw-flex tw-justify-between tw-items-center tw-flex-wrap sm:tw-flex-no-wrap">
            <h3 className="tw-font-body tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900 tw-capitalize">
              {plan.name}
            </h3>
            <div className="tw-flex-shrink-0">
              <HeroIcon
                icon="check-circle-filled"
                color={heroIconColor}
                tint={heroTint}
                filled
              />
            </div>
          </div>
          <p className="tw-font-body tw-mt-1 tw-mb-0 tw-text-sm tw-leading-5 tw-text-gray-500">
            {plan.description}
          </p>
          {getPlanPrice()}
          <div className="tw-mb-5 tw-mt-5">
            {isCurrenPlan ? (
              <Button
                text="Current Plan"
                type="secondary"
                color="gray"
                isFullWidth
                disabled
              />
            ) : null}
            {plan.priority > currentPlan.priority ? (
              <DowngradePlan
                plan={plan}
                currentPlan={currentPlan}
                mySubscription={mySubscription}
                productSubscriptions={productSubscriptions}
                currentTimezone={currentTimezone}
                isFullWidth
              />
            ) : null}
            {plan.priority < currentPlan.priority ? (
              <TwoStepUpgrade
                myPlan={currentPlan}
                mySubscription={mySubscription}
                buttonText="Upgrade Now"
                isFullWidth
                disabled={mySubscription.comped}
              />
            ) : null}
          </div>
        </div>
      </div>
      {plan.features ? (
        <div className="tw-ml-6 tw-mt-4 tw-mb-4">
          <span className="tw-font-medium tw-text-gray-500">
            WHAT&apos;S INCLUDED
          </span>
          {plan &&
            plan.features.map((feature, index) => {
              const key = `{feature_${index}_${plan.id}}`;
              return (
                <div key={key}>
                  <span>
                    <svg
                      className="tw-w-5 tw-h-5 tw-text-green-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 8"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </span>
                  <span>{feature}</span>
                </div>
              );
            })}
        </div>
      ) : null}
    </div>
  );
};

PlanDetail.defaultProps = {
  plan: () => null,
  currentPlan: () => null,
  mySubscription: () => null,
  productSubscriptions: [],
  currentTimezone: ''
};

PlanDetail.propTypes = {
  plan: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  ),
  currentPlan: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  ),
  mySubscription: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  ),
  productSubscriptions: PropTypes.arrayOf(PropTypes.object),
  currentTimezone: PropTypes.string
};

export default PlanDetail;
